/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TabContainer,
  TabHeader,
  TabRow,
  capitalizeFirstLetter,
  filterData,
  TabLeft,
  TabRight,
} from '@/Flows/analytics/logs';
import { VideoButtonB } from '@/Videos/Videos';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { useFetchAssistant } from '../Dialogue/BotSummaryAPI';
import {
  AppTriggerFilterIcon,
  DeleteIcon,
  DropdownWithCircle,
  FolderIcon,
  GroupIcon,
  ListviewIcon,
  SearchIcon,
  SortIcon,
  VideoIcon,
} from '@/common/Icons/Icons';
import {
  DropdownUlWrapper,
  CancelButton,
  InputContainer,
  DropdownLi,
  DropdownUl,
  Input,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { DropDown } from '../Common/Effective';
import { AssistantT } from '../Construction/kind';
import AssistantIntent from '../Intent';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSubTabHook } from './SubTabHook';
import { ProfileTabs } from '@/SidePanel/Sidebar'

export function HeaderForCanvasPage(props:any) {
  const params = useParams();

  return (
    <>
      <Header>
        <HeaderChild type="first">
          <div className="indent_left">
            <div className="indent_title">Chatbot Studio</div>
          </div>
        </HeaderChild>
        <HeaderChild type="second">
          <>
            <ProfileTabs {...props}/>
          </>
          {/* <VideoButtonB id={'READ_CHATBOT_LOGS'} /> */}
        </HeaderChild>
      </Header>
      {params['dialogue_node'] && <Outlet />}
    </>
  );
}

export function BotHome(props: any) {
  const { match } = props;
  const { workspacename } = match.params;
  const { params } = match
  const navigate = useNavigate()

  useEffect(()=>{
    if(props.location.pathname == `/workspace/${workspacename}/assistant`){
      navigate(`/workspace/${workspacename}/assistant/homepage`,{ replace:true })
    }
  },[props.location.pathname])
  
  return params['dialogue_node']?<HeaderForCanvasPage {...props}/>:<BotHomeTab {...props}/>
}

function BotHomeTab(props:any){
  const { history, match } = props;
  const { workspacename, assistantname } = match.params;
  const [currentTab, setCurrentTab] = useState<'home' | string>('home');
  const [chooser, setChooser] = useState({ show: false, bot: '', name: '' });
  const navigate = useNavigate()
  const { botsAPIStatus, botsOutput, retry } = useFetchAssistant(workspacename);

  useEffect(() => {
    callBotSelection()
  }, [botsAPIStatus]);

  useEffect(()=>{
    if(props.location.pathname.includes("chat-channels")){
      setCurrentTab("chat-channels")
    }else if(props.location.pathname.includes("knowledge")){
      setCurrentTab("knowledge")
    }else if(props.location.pathname.includes(`/workspace/${workspacename}/assistant/${assistantname}`)){
      setCurrentTab("assistant")
    }else{
      setCurrentTab("home")
    }

    callBotSelection()
  },[props.location.pathname])


  function callBotSelection(){
    if (botsAPIStatus === 'success' && match.params.assistantname) {
      const selectedDialog = (botsOutput as AssistantT[]).filter(
        (b) => b.name === match.params.assistantname
      )[0];
      setChooser({
        ...chooser,
        bot: selectedDialog ? selectedDialog.id : '',
        name: selectedDialog ? selectedDialog.name : '',
      });
    }
  }

  return (
    <>
      <HeaderForCanvasPage {...props}/>
      <div className="header_loader_height_change">
        {botsAPIStatus === 'loading' ? (
          <Loader.PageLoader show={true} className="analytics_loader" />
        ) : (
          <PageWrapper>
            <TabContainer className="main_menu_section">
              <TabRow className="main_menu">
                <TabLeft style={{top: 10}}>
                  <TabHeader
                    active={
                      currentTab == 'home'
                    }
                    onClick={() => {
                      setCurrentTab('home');
                    }}
                    className={
                      currentTab == 'home'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link to={`/workspace/${workspacename}/assistant/homepage`}>
                      Home
                    </Link>
                  </TabHeader>
                  <TabHeader
                    active={(chooser.bot.length > 0 && currentTab == 'assistant')}
                    style={{ display: 'flex', gap: '4px' }}
                    // active={currentTab == 'agent'}
                    // onClick={() => setCurrentTab('agent')}
                    onClick={() => {
                      if(chooser.bot.length > 0 && currentTab != 'assistant'){
                        setCurrentTab("assistant")
                        navigate(`/workspace/${workspacename}/assistant/${chooser.name}/conversations`)
                      }else{
                        setChooser({ ...chooser, show: true });
                      }
                    }}
                    className={
                     (chooser.bot.length > 0 && currentTab == 'assistant') ? 'active' : ''
                    }
                  >
                    {chooser.bot.length ? chooser.name : 'Bots'}
                    <span
                      onClick={(e:any) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setChooser({ ...chooser, show: true });
                      }}
                    >
                      <DropdownWithCircle />
                    </span>
                    {chooser.show ? (
                      <DropdownUlWrapper style={{ position: 'absolute' }}>
                        <DropDown
                          isOpen={chooser.show}
                          onOuterClick={() =>
                            setChooser({ ...chooser, show: false })
                          }
                        >
                          <div
                            className="automation_scroly"
                            style={{ top: 32, width: '160px' }}
                          >
                            <DropdownUl>
                              {(botsOutput as AssistantT[]).map(
                                (bot: AssistantT, index: number) => (
                                  <DropdownLi
                                    key={index}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setChooser({
                                        bot: bot.id,
                                        show: false,
                                        name: bot.name,
                                      });
                                      setCurrentTab("assistant")
                                      navigate(`/workspace/${workspacename}/assistant/${bot.name}/conversations`)
                                    }}
                                  >
                                    {/* <Link to={`${bot.name}/conversations`}> */}
                                      {bot.name}
                                    {/* </Link> */}
                                  </DropdownLi>
                                )
                              )}
                            </DropdownUl>
                          </div>
                        </DropDown>
                      </DropdownUlWrapper>
                    ) : null}

                    {/* <InputContainer className="menu_drop_down">
                      <Input
                        type="text"
                        value={chooser.bot.length ? chooser.name : 'Select bot'}
                        onChange={(e: any) => {}}
                        onClick={(e: any) => {
                          setChooser({ ...chooser, show: true });
                        }}
                      />

                      <CancelButton
                        onClick={(e: any) => {
                          setChooser({ ...chooser, show: true });
                        }}
                        className="menu_drop_down_cancel_btn"
                      >
                        <DropdownWithCircle />
                      </CancelButton>
                      <DropdownUlWrapper>
                        <DropDown
                          isOpen={chooser.show}
                          onOuterClick={() =>
                            setChooser({ ...chooser, show: false })
                          }
                        >
                          <div className="automation_scroly" style={{ top: 6 }}>
                            <DropdownUl>
                              {(botsOutput as AssistantT[]).map(
                                (bot: AssistantT, index: number) => (
                                  <DropdownLi
                                    key={index}
                                    onClick={(e: any) => {
                                      setChooser({
                                        bot: bot.id,
                                        show: false,
                                        name: bot.name,
                                      });
                                    }}
                                  >
                                    <Link
                                      to={`${bot.name}/conversations`}
                                    >
                                      {bot.name}
                                    </Link>
                                  </DropdownLi>
                                )
                              )}
                            </DropdownUl>
                          </div>
                        </DropDown>
                      </DropdownUlWrapper>
                    </InputContainer> */}
                  </TabHeader>
                  <TabHeader
                    active={
                      currentTab == 'knowledge'
                    }
                    onClick={() => {
                      setCurrentTab('knowledge');
                      navigate(`/workspace/${workspacename}/assistant/knowledge/external-kb`)

                    }}
                    className={
                      currentTab == 'knowledge'? 'active'
                        : ''
                    }
                  >
                      Knowledge AI
                  </TabHeader>
                  <TabHeader
                    active={
                      currentTab == 'chat-channels'
                    }
                    onClick={() => {
                      setCurrentTab('chat-channels');
                      navigate(`/workspace/${workspacename}/assistant/chat-channels/slack`)
                    }}
                    className={
                      currentTab == 'chat-channels'? 'active'
                        : ''
                    }
                  >
                      Chat Channels
                  </TabHeader>
                </TabLeft>
                <TabRight>
                  <div id="assistant-main-header"></div>
                </TabRight>
              </TabRow>
              {/* <TabRow>
                <TabLeft></TabLeft>
                <TabRight>
                  <div id="assistant-home-sub-header"></div>
                </TabRight>
              </TabRow> */}
            </TabContainer>
            <Outlet />
          </PageWrapper>
        )}
      </div>
    </>
  );
}
