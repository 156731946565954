import axios, { AxiosPromise, AxiosResponse } from 'axios'
import { fromNullable } from 'fp-ts/lib/Either'
import { TaskEither, map, tryCatch } from 'fp-ts/lib/TaskEither'
import { fromEither } from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/pipeable'

import { withAuth } from './auth-token-api-utils'

export const getJsonP = <T>(url: string): AxiosPromise<T> => withAuth(() => axios.get(url))

export const getJsonTE = <T>(url: string): TaskEither<Error, T> =>
    pipe(
        tryCatch(
            () => getJsonP<T>(url),
            reason => new Error(String(reason))
        ),
        map<AxiosResponse, T>(res => res.data)
    )

export const postReq = <D, T>(url: string) => (data: D) =>
    tryCatch(
        () => withAuth(() => axios.post<T>(url, data)),
        reason => new Error(String(reason))
    )

export const deleteReq = <D>(url: string) => (data: D) =>
    tryCatch(
        () => withAuth(() => axios.delete(url, { data })),
        reason => new Error(String(reason))
    )

export const patchReq = <D>(url: string) => (data: D) =>
    tryCatch(
        () => withAuth(() => axios.patch(url, data)),
        reason => new Error(String(reason))
    )

export const fromNullableToTE = <E>(def: E) => <T>(nullable: T) => fromEither(fromNullable(def)(nullable))

export function sleeper<T>(ms: number) {
    return function(x: () => T) {
        const val = x()
        return new Promise<T>(resolve => setTimeout(() => resolve(val), ms))
    }
}
