/*eslint-disable*/
import React, { useEffect, useReducer, useState, useRef } from 'react'
// import { useQuery } from 'react-query'

// type FetchStates = 'loading' | "error" | "success"

type LoadingT = {
    type: 'loading'
}

type ErrorT = {
    type: 'error'
    error: any
}

type SuccessT = {
    type: 'success'
    data: any
}
type DataT = {
    type: 'data'
    value: any
}
export type FetchReducerT = LoadingT | ErrorT | SuccessT

export type FetchActionT = ErrorT | DataT | LoadingT

export const initialLoadingData = { type: 'loading' } as LoadingT

function reducer(_: FetchReducerT, action: FetchActionT): FetchReducerT {
    // console.log('redecer')
    switch (action.type) {
        case 'data':
            return { type: 'success', data: action.value }
        case 'error':
            return { type: 'error', error: action.error }
        case 'loading':
            return { type: 'loading' }
    }
}

export const useFetchFromApi = (
    mountedPromise: () => Promise<any>
): [
    FetchReducerT,
    React.Dispatch<React.SetStateAction<Promise<any> | null>>,
    React.MutableRefObject<Promise<any> | null>,
    () => void,
    React.Dispatch<FetchActionT>
] => {
    const [state, dispatch] = useReducer(reducer, initialLoadingData)
    const promiseRef = useRef<null | Promise<any>>(null)
    const [promiseState, setPromise] = useState<null | Promise<any>>(promiseRef.current)
    const retry = () => {
        const setStateDispatch = setPromise as React.Dispatch<any>
        // fallback for errored promise
        setStateDispatch(
            promiseRef.current
                ? promiseRef.current.catch(_ => mountedPromise()).then((response: any) => response.data)
                : null
        )
    }

    useEffect(() => {
        promiseRef.current = mountedPromise()
        const setStateDispatch = setPromise as React.Dispatch<any>
        setStateDispatch(promiseRef.current)
    }, []) // will be called whenever promise has changed

    useEffect(() => {
        dispatch({ type: 'loading' })
        // console.log("promise changed")
        // fallback for errored promise
        callApi(promiseRef.current ? promiseRef.current.catch(_ => mountedPromise()) : null)
    }, [promiseState])

    async function callApi(fromPromise: Promise<any> | null) {
        if (fromPromise != null) {
            const p = fromPromise as Promise<any>
            // console.log("proise",p)
            // p.then(console.log)
            await p
                .then(response => {
                    dispatch({
                        type: 'data',
                        value: response
                    })
                })
                .catch(error => {
                    dispatch({ type: 'error', error: error })
                })
        }
    }

    return [state, setPromise, promiseRef, retry, dispatch]
}

const cache = {}

export const useFetchFromApiWithCache = (
    mountedPromise: () => Promise<any>,
    query: string
): [
    FetchReducerT,
    React.Dispatch<React.SetStateAction<Promise<any> | null>>,
    React.MutableRefObject<Promise<any> | null>,
    () => void
] => {
    const [state, dispatch] = useReducer(reducer, initialLoadingData)
    const promiseRef = useRef<null | Promise<any>>(null)
    const [promiseState, setPromise] = useState<null | Promise<any>>(promiseRef.current)
    const retry = () => {
        const setStateDispatch = setPromise as React.Dispatch<any>
        // fallback for errored promise
        setStateDispatch(
            promiseRef.current
                ? promiseRef.current.catch(_ => mountedPromise()).then((response: any) => response.data)
                : null
        )
    }

    useEffect(() => {
        promiseRef.current = mountedPromise()
        const setStateDispatch = setPromise as React.Dispatch<any>
        setStateDispatch(promiseRef.current)
    }, []) // will be called whenever promise has changed

    useEffect(() => {
        dispatch({ type: 'loading' })
        // console.log("promise changed")
        // fallback for errored promise
        callApi(promiseRef.current ? promiseRef.current.catch(_ => mountedPromise()) : null)
    }, [promiseState])

    async function callApi(fromPromise: Promise<any> | null) {
        if (fromPromise != null) {
            const p = fromPromise as Promise<any>
            // console.log("proise",p)
            // p.then(console.log)
            if (!cache[query]) {
                await p
                    .then(response => {
                        cache[query] = response
                        dispatch({
                            type: 'data',
                            value: response
                        })
                    })
                    .catch(error => {
                        dispatch({ type: 'error', error: error })
                    })
            } else {
                dispatch({
                    type: 'data',
                    value: cache[query]
                })
                await p
                    .then(response => {
                        cache[query] = response
                        dispatch({
                            type: 'data',
                            value: response
                        })
                    })
                    .catch(error => {
                        dispatch({ type: 'error', error: error })
                    })
            }
        }
    }

    return [state, setPromise, promiseRef, retry]
}

// export function withRouteDataLoaderV4<U, V, P>(
//     queryId: string,
//     mountedPromise: () => Promise<any>,
//     routerProps: any = {}
// ) {
//     return () => {
//         const { data, error, status, refetch } = useQuery(queryId, mountedPromise, {
//             cacheTime: 0,
//             refetchOnWindowFocus: false,
//             refetchOnReconnect: true
//         })

//         const [state, dispatch] = useReducer(reducer, initialData)
//         const promiseRef = useRef<null | Promise<any>>(null)
//         const [promiseState, setPromise] = useState<null | Promise<any>>(promiseRef.current)
//         const retry = () => {
//             const setStateDispatch = setPromise as React.Dispatch<any>
//             // fallback for errored promise
//             setStateDispatch(
//                 promiseRef.current
//                     ? promiseRef.current.catch(_ => mountedPromise()).then((response: any) => response.data)
//                     : null
//             )
//         }

//         useEffect(() => {
//             promiseRef.current = mountedPromise()
//             const setStateDispatch = setPromise as React.Dispatch<any>
//             setStateDispatch(promiseRef.current)
//         }, []) // will be called whenever promise has changed

//         useEffect(() => {
//             dispatch({ type: 'loading' })
//             // console.log("promise changed")
//             // fallback for errored promise
//             callApi(promiseRef.current ? promiseRef.current.catch(_ => mountedPromise()) : null)
//         }, [promiseState])

//         async function callApi(fromPromise: Promise<any> | null) {
//             if (fromPromise != null) {
//                 const p = fromPromise as Promise<any>
//                 // console.log("proise",p)
//                 // p.then(console.log)
//                 await p
//                     .then(response => {
//                         dispatch({
//                             type: 'data',
//                             value: response
//                         })
//                     })
//                     .catch(error => {
//                         dispatch({ type: 'error', error: error })
//                     })
//             }
//         }

//         return [state, setPromise, promiseRef, retry]
//     }
// }
