/*eslint-disable*/
import React, { useContext } from 'react'

import { constructHowItWorks } from '../MarketPlacePanel'
import { NonCanvasPanelContext } from './MarketPlaceFlowCanvas'

export const HowItWorks = (props: any) => {
    return <ConditionActualComponent {...props} />
}

const ConditionActualComponent = (props: any) => {
    const { treeData, rightPanelState, setRightPanelState } = props
    const { parentAutomation } = useContext(NonCanvasPanelContext)

    return (
        <React.Fragment>
            <div className="automation_canvas_right">
                <Wrapper>
                    <div className="error_handle_autoamtion_bg step_three_error edit_action_height">
                        <div className="automation_nodes">
                            <div className="automation_nodes_p">
                                <h4>How it works</h4>
                                {constructHowItWorks(treeData.tree, parentAutomation).map(
                                    (item: any, index: number) => (
                                        <p key={item.index}>
                                            <span style={{ fontWeight: 'bold', color: '#000' }}>[{item.index}]</span>{' '}
                                            {item.description}
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="automation_condition_button ">
                            <div className="automation_condition_button_center">
                                <button
                                    className="automation_condition_button_btn"
                                    onClick={() => {
                                        setRightPanelState({ ...rightPanelState, show: false })
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </React.Fragment>
    )
}

const Wrapper = (props: any) => (
    <div className="automation_canvas_right_popup automation_condition_popup">
        <div className="automation_canvas_right_popup_form">
            <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                {props.children}
            </div>
        </div>
    </div>
)
