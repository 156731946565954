// import { InviteFormState } from './types.manageUsersMachine'
import { fold } from 'fp-ts/lib/Either';
import URLTemplate from 'url-template';

import {
  getJson,
  loginApiURL,
  postJson,
  subscriptionApiURL,
} from '@/common/utils/api-utils';
import { deleteReq, patchReq } from '@/common/utils/axios-fp.utils';

enum APIList {
  numberOfAllowedAdmins = '/{ws}/resource/admin/limit',
  workspaceUsers = '/access/{ws}/users',
  inviteUsers = '/access/invite/{ws}',
  revoke = '/access/revoke/{ws}',
  enable = '/access/enable/{ws}',
  remove = '/access/remove/{ws}',
}

type userIdBody = { id: number };

const getUsersURL = URLTemplate.parse(APIList.workspaceUsers);
const inviteUserURL = URLTemplate.parse(APIList.inviteUsers);
const revokeURL = URLTemplate.parse(APIList.revoke);
const enableUserURL = URLTemplate.parse(APIList.enable);
const removeUserURL = URLTemplate.parse(APIList.remove);
const numberOfAllowedAdminsURL = URLTemplate.parse(
  APIList.numberOfAllowedAdmins
);

export const getUsersAPI = (ws: string) => {
  return getJson(
    loginApiURL(
      getUsersURL.expand({
        ws,
      })
    )
  );
};

export const getAllowedNumberOfAdminsApi: (
  ws: string
) => Promise<number> = async (ws: string) => {
  console.log('ws ::: ', ws);
  const {
    output: { resourceLimit },
  } = await getJson(
    subscriptionApiURL(numberOfAllowedAdminsURL.expand({ ws }))
  );
  console.log('resourceLimit ::: ', resourceLimit);
  return resourceLimit;
};

export type BasicUserInfo = {
  first_name?: string;
  last_name?: string;
  email: string;
};

export const inviteUser = (ws: string, user: BasicUserInfo) => {
  return postJson(
    loginApiURL(
      inviteUserURL.expand({
        ws,
      })
    )
  )(user);
};

export const disableUser = (ws: string) => (userId: number) =>
  deleteReq<userIdBody>(loginApiURL(revokeURL.expand({ ws })))({
    id: userId,
  })().then((deleteRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(deleteRes);
  });

export const enableUser = (ws: string) => (userId: number) =>
  patchReq<userIdBody>(loginApiURL(enableUserURL.expand({ ws })))({
    id: userId,
  })().then((enableRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(enableRes);
  });

export const removeUser = (ws: string) => (userId: number) =>
  deleteReq<userIdBody>(loginApiURL(removeUserURL.expand({ ws })))({
    id: userId,
  })().then((removeRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(removeRes);
  });
