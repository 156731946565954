import * as R from 'ramda'
import { isEmpty } from 'ramda'
/*eslint-disable*/
import React, { useState } from 'react'

import { CloseIcon, DeleteIcon, EditIcon, SearchIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { FetchReducerT, useFetchFromApi } from '@/common/hooks/usefetch-from-api'
import { SVGicons } from '@/common/styled/Marketplace.Dumb'

import { MarketPlaceApi, automationAPIURLWithWorkspace, wrapperGetJson } from '@/common/utils/api-utils'
import { Wrapper } from './Applications'
import { deleteUploads, editUploads } from './ModalActions'
import { MarketPlaceCardNew } from './common'
import { AutomationIconMap, MyUploadActions, MyUpload as MyUploadT } from './types'

export function MyUploadsHeader() {
    const [nI] = useState(false)
    return (
        <div className="marketplace_head">
            <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Automations</div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    {nI && (
                        <>
                            <span className="search_icon_svg">
                                <input type="search" placeholder="Search" />
                                <SearchIcon />
                            </span>

                            <span className="search_click">
                                <input className="search_click" type="search" placeholder="Search" autoFocus={true} />
                                <span>
                                    <CloseIcon />
                                </span>
                            </span>
                        </>
                    )}
                </HeaderChild>
            </Header>
        </div>
    )
}

type MyUploadsCardP = {
    myUploads: { myUploads: MyUploadT[] } | undefined
    workspaceName: string
    redirectToMarketplaceDetail: (id: string) => void
}

export function MyUploadsCard(props: MyUploadsCardP) {
    const { workspaceName } = props
    const [uploadsS, setUploads] = useState<MyUploadT[]>([])
    const cards = uploadsS.map(myUpload => {
        return {
            automationName: myUpload.name,
            count: myUpload.download_count,
            date: new Date(myUpload.modified_date),
            description: myUpload.description,
            type: myUpload.type,
            id: myUpload.id,
            time: ''
        }
    })

    const [userAction, setUserAction] = useState<MyUploadActions>({ kind: 'View' })
    const deleteCallback = (id: string) => {
        const newUploads = uploadsS.filter(x => x.id !== id)
        setUploads(newUploads)
        setUserAction({ kind: 'View' })
    }
    const edit = (id: string, name: string, description: string) => {
        const newUploads = uploadsS.map(x => {
            if (x.id === id) {
                x.name = name
                x.description = description
            }
            return x
        })
        setUploads(newUploads)
        setUserAction({ kind: 'View' })
    }

    const [renderDeleteModal] = deleteUploads([userAction, setUserAction], deleteCallback, workspaceName)
    const [renderEditModal] = editUploads([userAction, setUserAction], edit, workspaceName)
    React.useEffect(() => {
        const sortByNameCaseInsensitive = R.compose(R.toLower, R.prop<string, any>('name'))

        setUploads(props.myUploads ? R.sortBy(sortByNameCaseInsensitive, props.myUploads.myUploads) : [])
    }, [props.myUploads])

    return (
        <div className="market_place">
            {renderDeleteModal()}
            {renderEditModal()}
            <MyUploadsHeader />
            <Wrapper paddingWrapper="30px 45px 0px 45px">
                <React.Fragment>
                    {cards.map(card => (
                        <MarketPlaceCardNew
                            key={card.id}
                            {...card}
                            image={AutomationIconMap[card.type]}
                            EditAndDeleteIcons={true}
                            onClick={() => props.redirectToMarketplaceDetail(card.id)}
                        >
                            <SVGicons
                                onClick={(e: any) => {
                                    setUserAction({
                                        kind: 'Edit',
                                        id: card.id,
                                        name: card.automationName,
                                        description: card.description
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <EditIcon />
                            </SVGicons>
                            <SVGicons
                                onClick={(e: any) => {
                                    setUserAction({ kind: 'Delete', id: card.id })
                                    e.stopPropagation()
                                }}
                            >
                                <DeleteIcon />
                            </SVGicons>
                        </MarketPlaceCardNew>
                    ))}
                </React.Fragment>
            </Wrapper>
        </div>
    )
}
export const loadMyUploads = async (wName: string) => {
    return wrapperGetJson(automationAPIURLWithWorkspace(wName, MarketPlaceApi.GetMyUploads)).then(
        (response: any) => response.data
    )
}

export function MyUploadsWrapper(props: any) {
    const workspacename = props.match.params.workspacename
    const [state, , , retry] = useFetchFromApi(() => loadMyUploads(workspacename))
    const newState = state as FetchReducerT

    return (
        <PageStateHandler
            state={newState.type === 'success' ? { ...newState, data: newState.data.output } : newState}
            retry={retry}
            {...props}
        />
    )
}

export function PageStateHandler(props: any) {
    const workspaceName = props.match.params.workspacename
    const LoadingError = {
        error: true,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    }
    const NoUploadsData = {
        show: props.state.type === 'success' && isEmpty(props.state.data.myUploads),
        noDatainfo: `<p>Zero, zip, zilch, nada.No Automations available!</p>`,
        onClick: () => {}
    }

    const redirectToMarketplaceDetail = (id: string) => {
        props.history.push(`/workspace/${workspaceName}/marketplace/automation/detail/${id}`)
    }

    return (
        <ErrorBoundary
            noDataFound={NoUploadsData}
            error={props.state.type === 'error' ? LoadingError : { error: false, info: null }}
            render={(err: any, info: any) => {
                return err && <Error.PageLoadingError onClick={props.retry} info={info} btnName="Retry" />
            }}
        >
            <MyUploadsCard
                myUploads={props.state.data}
                workspaceName={workspaceName}
                redirectToMarketplaceDetail={redirectToMarketplaceDetail}
            />
        </ErrorBoundary>
    )
}
