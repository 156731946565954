/*eslint-disable*/
import React, { createContext } from 'react'

export type ErrorContextT = {
    formValid: any
    setValid: (object: any) => undefined
}

export const ErrorContext = createContext<ErrorContextT>({
    formValid: false,
    setValid: (object: any) => undefined
})

type ValidateP = {
    defaultValue: any
}

const Validate: React.FunctionComponent<ValidateP> = props => {
    const [formValidationObj, setFormValidation] = React.useState({
        ...props.defaultValue,
        formValid: false
    })

    const setValid: (obj: any) => any = (validationObj: any) => {
        const isFormValid = Object.entries(validationObj)
            .filter(item => item[0] != 'formValid')
            .map(item => item[1])
            .reduce((acc, curr) => acc && curr, true)

        const formValidationObj = { ...validationObj, formValid: isFormValid }

        setFormValidation(formValidationObj)
    }

    React.useEffect(() => {
        setValid({ ...props.defaultValue, formValid: false })
    }, [])

    return (
        <ErrorContext.Provider value={{ formValid: formValidationObj, setValid }}>
            {props.children}
        </ErrorContext.Provider>
    )
}
export const useErrorContext = () => React.useContext(ErrorContext)

export default Validate

//class Validate extends React.Component<p> {
//setValid = (object: any) => {
//let length = Object.keys(object).length - 1
//let valus: number = 0
//Object.keys(object).map(value => {
//if (value !== 'formValid') {
//valus += object[value]
//}
//})
//try {
//let validObject: any = Object.assign({}, object)
//if (valus === length) {
//validObject.formValid = true
//this.setState({ formValid: validObject })
//} else {
//validObject.formValid = false
//this.setState({ formValid: validObject })
//}
//} catch (err) {}
//return undefined
//}

//state = {
//formValid: { ...this.props.defaultValue, formValid: false },
//setValid: this.setValid,
//}

//componentDidMount() {
//this.setValid({ ...this.props.defaultValue, formValid: false })
//}

//render() {
//return (
//<ErrorsContext.Provider value={this.state}>
//{this.props.children}
//</ErrorsContext.Provider>
//)
//}
//}
