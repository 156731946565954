import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React, { useState } from 'react'
import styled from 'styled-components'

import { RightArrowWithOutCircle } from '@/common/Icons/Icons'

import image1 from '@/common/images/banner-1.png'
import image2 from '@/common/images/marketplace_nov_apps.png'

const SliderWrapper = styled.div`
    width: 100%;
    float: left;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    padding-right: 65%;
    @media only screen and (max-width: 1500px) {
        padding-right: 50%;
    }
`
const Header = styled.h2`
    width: 100%;
    float: left;
    font-size: 24px;
    color: ${(props: any) => (props.color ? props.color : '')};
    font-family: 'Gordita-Medium';
    padding-left: 70px;
    line-height: 30px;
    margin-bottom: 10px;
`
const Content = styled.p`
    width: 100%;
    float: left;
    font-size: 14px;
    color: ${(props: any) => (props.color ? props.color : '')};
    font-family: 'Gordita-Regular';
    padding-left: 70px;
    padding-right: 30px;
`

export function CarosoulSlider(props: any) {
    const ImageSlider = [
        {
            index: 0,
            image: image1,
            header: 'A brand new marketplace for automations',
            content:
                'Introducing a marketplace for all automations. Easily select and download required automations in a click. ',
            color: '#214b54'
        },
        {
            index: 1,
            image: image2,
            header: '',
            content: '',
            color: ''
        }
    ]

    const [isPlaying, setIsPlaying] = useState<boolean>(true)

    return (
        <CarouselProvider
            className="image_slider"
            naturalSlideWidth={100}
            naturalSlideHeight={1}
            totalSlides={ImageSlider.length}
            interval={10000}
            isPlaying={isPlaying}
            touchEnabled={true}
            infinite={true}
        >
            <Slider>
                {ImageSlider.map(data => (
                    <Slide className="slider_wrapper" index={data.index}>
                        <div
                            className="slider_wrapper_img"
                            onMouseEnter={() => setIsPlaying(false)}
                            onMouseLeave={() => setIsPlaying(true)}
                        >
                            <img src={data.image}></img>
                            <SliderWrapper>
                                <Header color={data.color}>{data.header}</Header>
                                <Content color={data.color}>{data.content}</Content>
                            </SliderWrapper>
                        </div>
                    </Slide>
                ))}
            </Slider>
            <ButtonBack className="buttonback " disabled={false}>
                <span>
                    <RightArrowWithOutCircle />
                </span>
            </ButtonBack>

            <ButtonNext className="buttonnext" disabled={false}>
                <span>
                    <RightArrowWithOutCircle />
                </span>
            </ButtonNext>
        </CarouselProvider>
    )
}
