import React from 'react'
import Tooltip from 'react-tooltip-lite'
import styled, { StyledComponent } from 'styled-components/macro'

// import { PaymentMethod } from '@stripe/stripe-js'
import { User } from '@/ManageUsers/types.user'
import {
    BotsIcon,
    ConversationMessageIcon,
    DownloadFromMarketplace,
    InfoIcon,
    InformationIcon,
    StarIcon,
    UserIconAnalytics,
    WorkflowExecutionIcon,
    WorkflowIcon
} from '@/common/Icons/Icons'
import visa_img from '@/common/images/visa.png'
import { NoCreditCard } from '@/common/styled/Subscription.Xtra.Dumb'

import { formatDate } from '@/common/utils/utils'
import {
    Button,
    ContainerV2,
    CurrentPlanCards,
    CurrentPlanCardsV2,
    MessageHeader,
    PlansHeaderV2,
    QuestionPlan,
    WrapperV2
} from './Plans-v2'
import { EstimateSummary } from './Subscription'
import { PaymentMethod } from './SubsriptionResponse'
import { InvoiceHistoryT, ResourceConsumptionForDisplay, ResourceDetails } from './kind'
import { toTitleCase } from './util-stripe'

const PlanWithMoney = styled.div`
    font-size: 14px;
    color: #000;
    font-family: ${props => (props.fontFamily ? 'Gordita-Medium' : 'Gordita-Regular')};
    display: flex;
    justify-content: space-between;
    margin-bottom:7px;
    :last-child{
        margin-bottom:0px;
    }
    span{
        display:flex;
    }
    .info_icon {
        position: relative;
        width: auto;
        display: flex;
        align-items: center;
    }
    .target{
        margin-left:7px;
    }
    .info_icon {
        svg {
            width: 14px;
            height: 14px;
            color: #333333;
            cursor: pointer;
            margin-left: 0px;
            transition: all 0.8s ease 0s;
        }
        .side_menu_hvr {
            position: absolute;
            width: max-content;
            background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
            box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;
            padding: 6px 12px;
            font-size: 16px;
            border-radius: 4px;
            color: rgb(49, 81, 118);
            max-width: 315px;
            bottom: 30px;
            left: -150px;
            font-family: Gordita-Regular;
            display: none;
            :before {
                position: absolute;
                content: ' ';
                background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
                bottom: -11px;
                border-width: 6px;
                border-style: solid;
                border-color: transparent transparent white;
                top: inherit;
                left: 50%;
                transform: rotate(180deg);
            }
        }
    }
    .info_icon:hover .side_menu_hvr {
        display: flex;
    }
    @media (max-width:1600px){
        font-size: 14px;
        .info_icon {
            svg {
                width: 15px;
                height: 15px;
            }
            .side_menu_hvr {
                left:-130px;
                font-size: 14px;
                max-width: 290px;
            }
    }
`
const PlanWithMoneyWrapper = styled.div`
    width:  ${props => (props.widthWrapper ? '85%' : '100%')};
    float: left;
    margin-right: ${props => (props.widthWrapper ? '20px' : '0px')};
}
`
const CurrentPlanRightWrapper = styled.div`
    width: ${props => (props.widthWrapper ? '480px' : '24%')};
    float: left;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    button {
       
        :hover {
        background: #466af3;
        }
    }
}

    @media (max-width: 1600px) {
        padding: 20px;
    }
`

const CurrentPlanWrapper = styled.section`
    width: 100%;
    float: left;
    padding: ${props => (props.isPlanRight ? '10px 20px' : '20px')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c6c6c6;
    ${QuestionPlan} {
        width: 22%;
    }
    ${Button} {
        margin-right: 0px;

        label{
            top: 0px;
        }
        label.upgrade_btn{
            top: -1px;
        }
    }
`
export const CurrentPlan = () => {
    return (
        <div className="current_plan_wrapper">
            <BodyWrapperV2>
                <WrapperV2>
                    <ContainerV2>
                        <BillingWrapper>
                            <PlanD />
                            <DisplayCards />
                        </BillingWrapper>
                        <BillingAndAccountWrapperV2 />
                    </ContainerV2>
                </WrapperV2>
            </BodyWrapperV2>
        </div>
    )
}

export const PlanD = () => {
    return (
        <CurrentPlanWrapper>
            <CurrentPlanCards
                upgradeButton
                plan={'Current Plan '}
                CurrentPlan={'Standard'}
                CurrentPlanCounts={''}
                btnName={'Upgrade'}
            />

            <CurrentPlanRightWrapper>
                <PlanWithMoneyWrapper>
                    <PlanWithMoney>
                        <span>Yearly Plan</span> <span>$6589.00</span>
                    </PlanWithMoney>
                    <PlanWithMoney>
                        <span>
                            {' '}
                            Add-On Consumptions{' '}
                            <Tooltip
                                className="target"
                                tipContentClassName=""
                                tipContentHover
                                zIndex={5000}
                                arrowSize={5}
                                tagName="span"
                                content="Current month add-on consumption charges are included in next billing cycle."
                            >
                                <span>
                                    <InfoIcon />
                                </span>
                            </Tooltip>
                            {/* <span className="info_icon">
                                <InfoIcon />
                                <span className="side_menu_hvr">
                                </span>
                            </span> */}
                        </span>
                        <span>$0.36</span>
                    </PlanWithMoney>
                    <PlanWithMoney fontFamily>
                        <span>Next Billing 14 Mar 2021</span> <span> $0.36</span>
                    </PlanWithMoney>
                </PlanWithMoneyWrapper>
                <Button bgColor>Pay Now</Button>
            </CurrentPlanRightWrapper>
        </CurrentPlanWrapper>
    )
}

export type PlanV2HeadProps = {
    currentPlan: string
    currentPeriodStart: Date
    currentPeriodEnd: Date
    gotoPlans: Function
    pricing: EstimateSummary
    planReferenceName: string
}

export const OverviewPlanV2 = (props: PlanV2HeadProps) => {
    console.log('OverviewPlanV2', props)

    return (
        <CurrentPlanWrapper isPlanRight={props.pricing.tag == 'PAY'}>
            <CurrentPlanCardsV2 {...props} />
            {props.pricing.tag == 'PAY' ? (
                <CurrentPlanRightWrapper>
                    <PlanWithMoneyWrapper>
                        <PlanWithMoney>
                            <span>{props.pricing.planType} Plan</span>{' '}
                            <span>${parseFloat(props.pricing.planAmount).toFixed(2)}</span>
                        </PlanWithMoney>
                        <PlanWithMoney>
                            <span>
                                {' '}
                                Add-On Consumptions{' '}
                                <Tooltip
                                    className="target"
                                    tipContentClassName=""
                                    tipContentHover
                                    zIndex={5000}
                                    arrowSize={5}
                                    tagName="span"
                                    content="Current month add-on consumption charges are included in next billing cycle."
                                >
                                    <span className="info_icon">
                                        <InfoIcon />
                                    </span>
                                </Tooltip>
                            </span>
                            <span>${parseFloat(props.pricing.addOnAmount).toFixed(2)}</span>
                        </PlanWithMoney>
                        <PlanWithMoney fontFamily>
                            <span>Next Billing {props.pricing.nextBillingDate}</span>{' '}
                            <span> ${parseFloat(props.pricing.toPay).toFixed(2)}</span>
                        </PlanWithMoney>
                    </PlanWithMoneyWrapper>
                    {/* <Button bgColor>Pay Now</Button> */}
                </CurrentPlanRightWrapper>
            ) : null}
        </CurrentPlanWrapper>
    )
}

const LinkWrapper = styled.div`
    width: 100%;
    float: left;
    border-top: ${props => (props.linkBorder ? '1px solid #d3d3d3' : 'none')};
    padding: ${props => (props.linkBorder ? '20px' : '0px 20px 20px 20px')};

    @media (max-width: 1700px) {
        padding: ${props => (props.linkBorder ? '15px 20px' : ' 0px 20px 15px 20px;')};
    }
    // @media (max-width: 1500px) {
    //     padding: ${props => (props.linkBorder ? '15px' : ' 0px 12px 15px 15px;')};
    // }
`
const LinkContent = styled.div`
    width: auto;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #466af3;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`
export const CardsWrapper = styled.div`
    width: 100%;
    float: left;
    background: #fff;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    grid-gap: 0px;
    padding-top: 15px;
    column-gap: 20px;
    row-gap: 15px;
    border-radius: 0px 0px 10px 10px;
    @media (max-width: 1700px) {
        grid-gap: 0px;
        padding-top: 15px;
        column-gap: 20px;
        row-gap: 15px;
    }
    ${LinkWrapper} {
        padding: 0px;
    }
`

const ListContent = styled.h6`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    ${MessageHeader} {
        font-size: 14px;
        color: #000;

        span {
            margin-top: 0px;
        }
    }
    span {
        font-size: 14px;
        width: auto;
        float: right;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    @media (max-width: 1500px) {
        span {
            font-size: 12px;
        }
    }
    @media (max-width: 1400px) {
        span {
            font-size: 11px;
        }
    }
`
const ListCount = styled.div`
    width: auto;
    float: right;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 14px;
`
const ListPlan = styled.div`
    width: 100%;
    float: left;
    display: flex;

    ${ListContent} {
        justify-content: flex-start;
    }
`
export const Listwrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    position: relative;
    ${ListContent}:first-child {
        font-family: 'Gordita-Regular';
        ${ListCount} {
            font-family: 'Gordita-Regular';
        }
    }
    [data-tooltip] {
        position: relative;
        bottom: 2px;
    }
    @media (max-width: 1700px) {
        padding: 15px 20px;
    }
`
export const CurrentPlanHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding: 20px 20px 12px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #000;
    line-height: initial;
    span {
        width: 32px;
        height: 32px;
        float: left;
        margin-right: 8px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
    label {
        position: relative;
        top: 2px;
        margin-bottom: 0;
    }
`
export const Card = styled.div`
    width: 100%;
    float: left;
    border-radius: 10px;

    :nth-child(1) {
        background: #f7f4ff;
        svg {
            fill: #451eb280;
        }
    }
    :nth-child(2) {
        background: #ebf8fe;
        svg {
            fill: #2a78aa;
        }
    }
    :nth-child(3) {
        background: #fff3ed;
        svg {
            fill: #b95d2f;
        }
    }
    :nth-child(4) {
        background: #eaffe5;
        svg {
            fill: #257412;
        }
    }
`

const PlanDetails = [
    {
        header: 'App Workflows',
        image: <WorkflowIcon />,
        contentMessage: [
            {
                header: 'Active Workflows Quota',
                count: '5'
            },
            {
                header: 'Used Workflows ',
                count: '4'
            },
            {
                header: ' Remaining Workflows ',
                count: '1'
            }
        ]
    },
    {
        header: 'Workflow Executions',
        image: <WorkflowExecutionIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '1500'
            },
            {
                header: 'Used Executions ',
                count: '250'
            },
            {
                header: 'Remaining Executions ',
                count: '1250'
            },
            {
                header: 'Add-On Executions',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded Executions'
            }
        ]
    },
    {
        header: 'FAQ Answers',
        image: <BotsIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '2000'
            },
            {
                header: 'Used FAQ Executions ',
                count: '200'
            },
            {
                header: 'Remaining FAQ Executions ',
                count: '1800'
            },
            {
                header: 'Add-On FAQ Answers',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded FAQ Executions'
            }
        ]
    },
    {
        header: 'Conversation Messages',
        image: <ConversationMessageIcon />,
        contentMessage: [
            {
                header: 'Montly Quota ',
                count: '7000'
            },
            {
                header: 'Used Messages ',
                count: '1200'
            },
            {
                header: 'Remaining Messages ',
                count: '5800'
            },
            {
                header: 'Add-On Messages',
                count: '0',
                image: <InfoIcon />,
                hoverContent: 'Exceeded Messages'
            }
        ]
    }
]

const CalculationWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    border-top: 1px solid #e0e0e0;
    ${ListContent} {
        font-family: 'Gordita-Medium';
        margin-bottom: 0px;
    }
    ${ListCount} {
        font-family: 'Gordita-Medium';
    }

    @media (max-width: 1700px) {
        padding: 15px;
    }

    @media (max-width: 1500px) {
        padding: 12px;
    }
`
export const DisplayCards = (props: any) => {
    return (
        <>
            <CardHeader>Current Consumptions</CardHeader>
            <CardsWrapper>
                {PlanDetails.map(data => (
                    <Card>
                        <CurrentPlanHeader>
                            <span>{data.image}</span>
                            <label>{data.header}</label>
                        </CurrentPlanHeader>
                        <Listwrapper>
                            {data.contentMessage.map(data => (
                                <>
                                    {data.hoverContent && data.image ? (
                                        <ListContent>
                                            <MessageHeader>
                                                <label>
                                                    {data.header}
                                                    <span data-tooltip={data.hoverContent}>
                                                        <InfoIcon />
                                                    </span>
                                                </label>
                                            </MessageHeader>
                                            <ListCount>{data.count}</ListCount>
                                        </ListContent>
                                    ) : (
                                        <ListContent>
                                            {data.header} <ListCount>{data.count}</ListCount>
                                        </ListContent>
                                    )}
                                </>
                            ))}
                        </Listwrapper>
                    </Card>
                ))}
            </CardsWrapper>
        </>
    )
}

export const DisplayCardsV2 = (props: { resources: ResourceConsumptionForDisplay[] }) => {
    const resourceWithDisplayDetails = props.resources
        .map(resource => {
            const displayDetails = ResourceDetails.find(displayDetail => displayDetail.name === resource.id)
            if (displayDetails === undefined) {
                return undefined
            }

            return {
                ...resource,
                ...displayDetails
            }
        })
        .filter(x => x !== undefined)
    // .filter((item): item is ResourceConsumptionForDisplay => !!item)

    resourceWithDisplayDetails.sort((resource1, resource2) => {
        if (resource1 && resource2 && resource1.priority < resource2.priority) {
            return -1
        }
        if (resource1 && resource2 && resource1.priority > resource2.priority) {
            return 1
        }
        return 0
    })

    return (
        <>
            <CardHeader>Current Consumptions</CardHeader>
            <CardsWrapper>
                {resourceWithDisplayDetails.map(data =>
                    data ? (
                        <Card>
                            <CurrentPlanHeader>
                                <span>{data.icon}</span>
                                <label>{data.title}</label>
                            </CurrentPlanHeader>
                            <Listwrapper>
                                <ListContent>
                                    {data.totalText} <ListCount>{data.resourceLimit}</ListCount>
                                </ListContent>
                                <ListContent>
                                    {data.userText} <ListCount>{data.consumed}</ListCount>
                                </ListContent>
                                <ListContent>
                                    {data.remainingText}{' '}
                                    <ListCount>{Math.max(data.resourceLimit - parseInt(data.consumed), 0)}</ListCount>
                                </ListContent>
                                {data.type === 'INFINITE' && data.consumptionType === 'INFINITE' ? (
                                    <ListContent>
                                        <MessageHeader>
                                            <label>
                                                {data.addOntext.text}
                                                <span data-tooltip={data.addOntext.hoverContent}>
                                                    <InfoIcon />
                                                </span>
                                            </label>
                                        </MessageHeader>
                                        <ListCount>
                                            {Math.max(parseInt(data.consumed) - data.resourceLimit, 0)}
                                        </ListCount>
                                    </ListContent>
                                ) : null}
                            </Listwrapper>
                        </Card>
                    ) : null
                )}
                {/* <Link link={'View Previous Consumptions'} /> */}
            </CardsWrapper>
        </>
    )
}

export const BillingWrapper = styled.section`
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 5px #16161629;
    padding-bottom: 10px;
`
const Link = (props: any) => {
    return (
        <LinkWrapper linkBorder={props.border} onClick={props.onClick ? props.onClick : () => {}}>
            <LinkContent>{props.link}</LinkContent>
        </LinkWrapper>
    )
}

export const AccountWrapper = styled.div`
    width: 100%;
    float: left;
    background: #fff;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    position: relative;

    ${CurrentPlanHeader} {
        padding: 20px 20px 12px 20px;
    }
`

export const AdminWrappers = styled.section`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 24% 24% auto;
    margin-top: 30px;
    grid-gap: 30px;

    @media (max-width: 1700px) {
        grid-gap: 20px;
        margin-top: 20px;
    }
`
// Main Page
export const BillingAndAccountWrapperV2 = () => {
    return (
        <AdminWrappers>
            <CardUserDetials />
            <AdminDetails />
            <AccountWrapper>
                <CurrentPlanHeader>Payment & Invoice History</CurrentPlanHeader>
                <BillingHistoryD />
            </AccountWrapper>
        </AdminWrappers>
    )
}

const ImgContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e0e0e0;
`
const AdminIcon = styled.div`
    position: absolute;
    top: -3px;
    right: 0;
    width: 15px;
    height: 15px;
    @media (max-width: 1700px) {
        top: -6px;
        right: -3px;
    }
`
const ProfileImg = styled.span`
    width: 48px;
    height: 48px;
    float: left;
    border-radius: 50%;
    border: 1px solid #e0f6ff;
    padding: 12px;
    position: relative;
    background: #e0f6ff;
    img {
        width: 100%;
        height: 100%;
    }
    svg {
        width: 100%;
        height: 100%;
        fill: #355d8b;
        color: #355d8b;
    }
    ${AdminIcon} {
        svg {
            width: 15px;
            height: 15px;
            fill: #eb974e;
        }
    }
`
const UserID = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #466af3;
    font-size: 14px;
`
const UserName = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 14px;
`
const EllipsisContent = styled.span`
    width: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
`
const EmailWrapper = styled.div`
    width: 85%;
    float: left;
    padding-left: 20px;
    @media (max-width: 1700px) {
        padding-left: 15px;
    }
    @media (max-width: 1500px) {
        padding-left: 12px;
    }
`

export const UserDetails = (props: any) => {
    return (
        <ImgContainer>
            <ProfileImg>
                <UserIconAnalytics /> {/* svg Image*/}
                {props.adminIcon ? (
                    <>
                        <AdminIcon className="">
                            <span>
                                <StarIcon />
                            </span>
                        </AdminIcon>{' '}
                    </>
                ) : null}
            </ProfileImg>
            <EmailWrapper>
                <UserName>
                    <EllipsisContent>Arunachalamoorthy.s</EllipsisContent>
                </UserName>
                <UserID>
                    {' '}
                    <EllipsisContent>Arunachalamoorthy.s@skitter.in</EllipsisContent>
                </UserID>
            </EmailWrapper>
        </ImgContainer>
    )
}

export const UserDetailsV2 = (props: any) => {
    return (
        <ImgContainer>
            <ProfileImg>
                <UserIconAnalytics /> {/* svg Image*/}
                {props.adminIcon ? (
                    <>
                        <AdminIcon className="">
                            <span>
                                <StarIcon />
                            </span>
                        </AdminIcon>{' '}
                    </>
                ) : null}
            </ProfileImg>
            <EmailWrapper>
                <UserName>
                    <EllipsisContent>{props.name}</EllipsisContent>
                </UserName>
                <UserID>
                    {' '}
                    <EllipsisContent>{props.email}</EllipsisContent>
                </UserID>
            </EmailWrapper>
        </ImgContainer>
    )
}

const CardDetails = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    @media (max-width: 1700px) {
        margin-bottom: 0px;
    }
`
const CardNumber = styled.div`
    width: 80%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 14px;
`
const VisaImg = styled.img`
    width: 56px;
    height: auto;
    float: left;
`
const Billing = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
`
export const CardExpiryWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 15px 20px;

    ${CurrentPlanHeader} {
        padding: 0px;
        border: none;
        margin-bottom: 10px;
    }
`
const CardDetailsWrapper = () => {
    return (<CardExpiryWrapper>
                <CurrentPlanHeader>Credit Card</CurrentPlanHeader>
                <CardDetails>
                    <CardNumber>**** **** **** 4444</CardNumber>
                    <VisaImg src={visa_img}></VisaImg>
                </CardDetails>
                <UserName>
                    <EllipsisContent>Arunachalamoorthy.s</EllipsisContent>
                </UserName>
                <Billing>Expires: 12/2024</Billing>
            </CardExpiryWrapper>
    )
}

const CardMainWrapper = styled.div`
    width: 100%;
    float: left;

    ${Billing} {
        padding-top: 12px;
        @media (max-width: 1700px) {
            padding-top: 4px;
        }
    }

    ${LinkWrapper} {
        // padding: 10px 15px 20px 15px;

        // @media (max-width: 1700px) {
        //     padding: 0px 15px 15px 15px;
        // }
    }
`

export const CardUserDetials = () => {
    return (
        <AccountWrapper>
            <CurrentPlanHeader>Account & Billing</CurrentPlanHeader>
            <CardMainWrapper>
                <UserDetails />
                <CardDetailsWrapper />
                <Link link={'Manage Details'} />
            </CardMainWrapper>
        </AccountWrapper>
    )
}
export const CardUserDetailsV2 = ({
    goPaymentPage,
    card,
    user,
    planReferenceName
}: {
    goPaymentPage: Function
    card: PaymentMethod | undefined
    user: User | undefined
    planReferenceName: string
}) => {
    const name = user ? `${user.first_name} ${user.last_name}` : 'Unknown user'
    const email = user ? user.email : 'unknown@unknown.un'
    // card = undefined as any

    // console.log('planReferenceName', planReferenceName)

    if (card === undefined) {
        return (<AccountWrapper>
                    <CurrentPlanHeader>Account & Billing</CurrentPlanHeader>
                    <CardMainWrapper>
                        <ImgContainer>
                            <ProfileImg>
                                <UserIconAnalytics />
                            </ProfileImg>
                            <EmailWrapper>
                                <UserName>
                                    <EllipsisContent>{name}</EllipsisContent>
                                </UserName>
                                <UserID>
                                    {' '}
                                    <EllipsisContent>{email}</EllipsisContent>
                                </UserID>
                            </EmailWrapper>
                        </ImgContainer>
                        <CardExpiryWrapper>
                            <NoCreditCard>No Credit Card details found</NoCreditCard>
                        </CardExpiryWrapper>
                        {planReferenceName !== 'FREE' ? <Link link={'Manage Details'} onClick={goPaymentPage} /> : null}
                    </CardMainWrapper>
                </AccountWrapper>)
    }

    // return null as never

    return (
        <AccountWrapper>
            <CurrentPlanHeader>Account & Billing</CurrentPlanHeader>
            <CardMainWrapper>
                <ImgContainer>
                    <ProfileImg>
                        <UserIconAnalytics />
                    </ProfileImg>
                    <EmailWrapper>
                        <UserName>
                            <EllipsisContent>{name}</EllipsisContent>
                        </UserName>
                        <UserID>
                            {' '}
                            <EllipsisContent>{email}</EllipsisContent>
                        </UserID>
                    </EmailWrapper>
                </ImgContainer>
                <CardExpiryWrapper>
                    <CurrentPlanHeader>Credit Card</CurrentPlanHeader>
                    <CardDetails>
                        <CardNumber>**** **** **** {card.card && card.card.last4}</CardNumber>
                        <VisaImg src={visa_img}></VisaImg>
                    </CardDetails>
                    <UserName>
                        <EllipsisContent>{card.billing_details.name}</EllipsisContent>
                    </UserName>
                    <Billing>
                        Expires:{' '}
                        {card.card && card.card.exp_month.toLocaleString(undefined, { minimumIntegerDigits: 2 })}/
                        {card.card && card.card.exp_year}
                    </Billing>
                </CardExpiryWrapper>
                <Link link={'Manage Details'} onClick={goPaymentPage} />
            </CardMainWrapper>
        </AccountWrapper>
    )
}

export const Paragraph = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 18px;
    margin-bottom: 0px;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
export const AdminDetailWrapper = styled.div`
    width: 100%;
    float: left;
    ${Paragraph} {
        padding: 20px 10%;
        text-align: center;
        font-size: 14px;
        color: #355D8B;

        @media (max-width: 1700px) {
            padding: 15px 6% 15px 6%;
        }
        @media (max-width: 1500px) {
            padding: 10px 6% 10px 6%;
            font-size: 14px;
        }
        @media (max-width: 1400px) {
            padding: 10px 6% 10px 6%;
            font-size: 13px;
        }
    }
`
export const AdminDetails = () => {
    return (
        <AccountWrapper>
            <AdminDetailWrapper>
                <CurrentPlanHeader>Admin Details</CurrentPlanHeader>
                <UserDetails adminIcon />
                <UserDetails />
                <Paragraph>
                    Current plan includes workspace access for two admins. Upgrade your plan to include more number of
                    admins.
                </Paragraph>
            </AdminDetailWrapper>
        </AccountWrapper>
    )
}

export const Table = styled.table`
    width: 100%;
    float: left;
    position: relative;
`
export const Tr = styled.tr`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    color: #000;
    span {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
            fill: #466af3;
        }
    }
`
export const Thead: StyledComponent<'thead', any, any, never> = styled.thead`
    border: ${(props: any) => (props.primary ? '1px solid #c6c6c6' : 'none')};
    border-bottom: ${(props: any) => (props.primary ? 'none' : 'none')};
    width: 100%;
    float: left;
    display: flex;
    margin: ${(props: any) => (props.primary ? '0px 0px' : '0px')};
    padding: ${(props: any) => (props.primary ? '20px 20px 0px' : '0px')};
    @media (max-width: 1700px) {
        padding: ${(props: any) => (props.primary ? '20px 20px 0px' : '0px')};
    }
    @media (max-width: 1500px) {
        padding: ${(props: any) => (props.primary ? '15px 20px 0px' : '0px')};
    }

    ${Tr} {
        padding-bottom: 0px;
    }
`

export const Tbody: StyledComponent<'tbody', any, any, never> = styled.tbody`
    width: 100%;
    float: left;
    display: ${(props: any) => (props.primary ? 'block' : 'flex')};
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px;
    position: relative;
    ${Tr} {
        height: 36px;
        align-items: center;
    }
`

export const BillingHistoryWrapper = styled.div`
    width: 100%;
    float: left;
    ${LinkWrapper} {
        position: absolute;
        bottom: 0px;
    }
`
export const Th: StyledComponent<'th', any, any, never> = styled.th`
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    float: left;
    font-size: 14px;
    display: flex;
    justify-content: ${(props: any) => (props.primary ? 'flex-start' : 'center')};
    font-family: 'Gordita-Medium';
    color: #000;
`
export const TdSpan = styled.label`
    width: max-content;
    padding: 0px 20px;
    height: 24px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;

    background: ${(props: any) => (props.bgPending ? '#FFF4E8' : '#E0FFF9')};
    border-radius: 16px;
    color: ${(props: any) => (props.bgPending ? '#DB6E01' : '#27B99D')};
    font-size: 12px;
    font-family: 'Gordita-Medium';

    span {
        position: relative;
        top: 2px;
        height: auto;
        width: auto;
    }
`
export const Td: StyledComponent<'td', any, any, never> = styled.td`
    width: auto;
    float: left;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    display: flex;
    justify-content: ${(props: any) => (props.ContentRight ? 'flex-start' : 'center')};

    align-items: center;
    color: ${(props: any) => (props.primary ? '#ff7900' : '#000')};
`

export const TbodyWrapper = styled.div`
    width: 100%;
    float: left;
    dispaly: flex;
`
export const NoDataWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(50vh);
    overflow-y: hidden;
    position: relative;
`
export const NoDataHeader = styled.h3`
    width: 100%;
    float: left;
    dispaly: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    text-align: center;

    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1500px) {
        font-size: 14px;
    }
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`
const CardHeader = styled.h3`
    width: 100%;
    float: left;
    dispaly: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-family: 'Gordita-Medium';
    margin-bottom: 0px;
    padding: 20px 20px 0px 22px;
`
export const BillingTableWrapper = styled.div`
    width: 100%;
    float: left;
    height: 100%;
    display: flex;

    ${AccountWrapper} {
        ${Thead} {
            border-top: none;
            border-left: none;
            border-right: none;
        }
    }
`

const BillingHistoryD = () => {
    return (
        <ContainerV2>
            <BillingHistoryWrapper>
                <Table>
                    <Thead primary>
                        <Tr>
                            <Th primary size={2}>
                                Date
                            </Th>
                            <Th primary size={3.5}>
                                Invoice Number
                            </Th>
                            {/* <Th primary size={1.5}>
                                Type
                            </Th> */}

                            <Th primary size={2.5}>
                                Amount
                            </Th>
                            <Th size={2}>Download</Th>
                            <Th primary size={2}>
                                Payment Status
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3.5}>
                                F6420A06-0010
                            </Td>
                            {/* <Td ContentRight size={1.5}>
                                Monthly
                            </Td> */}
                            <Td ContentRight size={2.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan bgPending>
                                    <span>Pending</span>
                                </TdSpan>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3}>
                                F6420A06-0010
                            </Td>
                            <Td ContentRight size={1.5}>
                                Monthly
                            </Td>
                            <Td ContentRight size={1.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan>
                                    <span>Paid</span>
                                </TdSpan>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td ContentRight size={2}>
                                14 Feb 2021
                            </Td>
                            <Td ContentRight size={3}>
                                F6420A06-0010
                            </Td>
                            <Td ContentRight size={1.5}>
                                Monthly
                            </Td>
                            <Td ContentRight size={1.5}>
                                $606.20
                            </Td>
                            <Td size={2}>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>
                            </Td>
                            <Td size={2}>
                                <TdSpan>Paid</TdSpan>
                            </Td>
                        </Tr>
                    </Tbody>
                    <Link link={'View All Invoices'} />
                </Table>
            </BillingHistoryWrapper>
        </ContainerV2>
    )
}
export const BillingHistoryV2 = ({
    invoices: invoiceHistory,
    gotoHistory
}: {
    invoices: InvoiceHistoryT[]
    gotoHistory: Function
}) => {

    if (invoiceHistory.length === 0) {
      return (
              <NoCreditCard style={{marginTop : "120px"}}>No invoice details found</NoCreditCard> 
      );
    }
    return (
        <ContainerV2>
            <BillingHistoryWrapper>
                <Table>
                    <Thead primary>
                        <Tr>
                            <Th primary size={2}>
                                Date
                            </Th>
                            <Th primary size={4}>
                                Invoice Number
                            </Th>
                            {/* <Th primary size={1.5}>
                                Type
                            </Th> */}

                            <Th primary size={2}>
                                Amount
                            </Th>
                            <Th size={2}>Download</Th>
                            <Th primary size={2}>
                                Payment Status
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {invoiceHistory
                            .filter((_, index) => index < 3)
                            .map((data: InvoiceHistoryT, index: number) => (
                                <Tr key={index}>
                                    <Td ContentRight size={2}>
                                        {formatDate(new Date(data.endDate * 1000))}
                                    </Td>
                                    <Td ContentRight size={4}>
                                        {data.invoiceId}
                                    </Td>
                                    {/* <Td ContentRight size={1.5}>
                                        Monthly
                                    </Td> */}
                                    <Td ContentRight size={2}>
                                        ${data.amount / 100}
                                    </Td>
                                    <Td size={2}>
                                        <span onClick={() => window.open(data.invoicePdf, '_blank')}>
                                            <DownloadFromMarketplace />
                                        </span>
                                    </Td>
                                    <Td size={2}>
                                        <TdSpan bgPending={data.status == 'paid' ? false : true}>
                                            <span>{toTitleCase(data.status)}</span>
                                        </TdSpan>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
                <Link border link={'View All Invoices'} onClick={gotoHistory} />
            </BillingHistoryWrapper>
        </ContainerV2>
    )
}
const InformationContent = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    margin-bottom: 0px;
    display:flex;
    align-items:center;

    span {
        width: 18px;
        height: 18px;
        float:left;
        margin-right:5px;
        position:relative;
        top:-1px;
       
        svg{
            width:18px:
            height:18px;
            fill:#E88600;
        }
    }
    @media (max-width: 1500px) {
        font-size: 13px;

        span {
            width: 15px;
            height: 15px;
            float:left;
           
            svg{
                width:15px:
                height:15px;
                fill:#E88600;
            }
        }
    }
`
const Information = styled.div`
    background: #fff5ef 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #16161629;
    border-radius: 10px;
    width: 100%;
    float: left;
    margin-top: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    @media (max-width: 1500px) {
        padding: 0px 15px;
    }
`

const AlertWrapper = () => {
    return (
        <Information>
            <InformationContent>
                <span>
                    <InformationIcon />
                </span>
                “Your payment is past due date. Please pay before 28 March 2021 to avoid account suspension”
            </InformationContent>
        </Information>
    )
}
export const PaidUserWrapper = styled.div`
width:100%:
float:left;
`
export const PaidUserPlans = () => {
    return (
        <>
            <PaidUserWrapper>
                <PlansHeaderV2 name={'Billing Overview'} />
                <CurrentPlan />
            </PaidUserWrapper>
        </>
    )
}

const BodyWrapperV2 = styled.section`
    width: 100%;
    float: left;
    padding: 0px 20px 20px 20px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    padding-top: 20px;
`
