import * as R from 'ramda'
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { FormatwithUTCDate, FormatwithUTCTime } from '@/Assistant/utils'
import { BotDetailsT } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney'
import { DialogModalWrapper } from '@/DialogEditor/Home/BotMarketplaces/BotMarketplace'
import { CanAccess, GetAllCategories, MyUploads, UploadBot } from '@/DialogEditor/Home/BotMarketplaces/MarketPlaceUtils'
import { DropdownWithSearch, SerachInput, SerachInputWrapper } from '@/DialogEditor/TrainingModule/TrainingModule'
import { VideoButtonB } from '@/Videos/Videos'
import {
    AddIcon,
    DeleteIcon,
    DialogHomeIcon,
    DownloadFromMarketplace,
    EditIcon,
    InfoIcon,
    UploadToMarketplace,
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import { Input, InputContainer, InputContent, InputWrapper } from '@/common/styled/Workflow.Analytics.Dumb'
import { sortAnArrayByDate } from '@/common/utils/common.utils'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
//COMMON MODULE IMPORTS
import { stripeGetApi } from '@/common/utils/api-utils'
import { MixChild } from '../Dialogue/Dialogue'
import { DialogueT } from '../Dialogue/kind'
import { validateNode, validateResponseNode } from '../DialogueCrew/DialogueMap/Descendant'
import { EntityT } from '../Entity/kind'
import { IntentT } from '../Intent/kind'
import BotCreation from './BotCreation'
import BotPopup from './BotPopup'
import { PublishButton, PublishPopup } from './PublishBot'
//TYPE IMPORTS
import { ActionT, AssistantT, PageStateT, fetchWorkspaces } from './kind'
import { DialogDetailsT } from '.'
import { usePermissions } from '@/common/utils/auth-token-api-utils'
import { AssistantMainHeader, AssistantHomeSubHeader } from '../BotHome/PortalElement'
import { PlusIcon } from '@/common/Icons/Workflow.Icons'

//STYLES
const Count = styled.label`
    width: 16px;
    height: 16px;
    margin: 0px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #333;
    font-size: 8px;
    font-family: 'Gordita-MEdium';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -9px;
    right: -3px;
    line-height: normal;
`
const ChannelContainer = styled.div`
    width: auto;
    float: left;

    span {
        width: 30px;
        height: 30px;
        margin-left: 7px;
        position: relative;
        float: left;
        :first-child {
            margin-left: 0px;
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }
`
const BotChannelsWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`
const CountWrapper = styled.div`
    width: auto;
    float: left;
    font-size: 16px;
    font-family: 'Gordita-Medium';
    color: #000;
    display: flex;
    align-items: flex-end;

    span {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #355d8b;
        margin-left: 7px;
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;
    }
`
const CountNumber = styled.div`
    width: 100%;
    float: left;
    font-size: 24px;
    font-family: 'Gordita-Medium';
    color: #000;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
`
const CardHeader = styled.div`
    width: 100%;
    float: left;
    font-size: 10px;
    font-family: 'Gordita-Medium';
    color: #355d8b;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
const CardBox = styled.div`
    width: 100%;
    float: left;
    // background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 2px 4px #0000001a;
    // border: 1px solid #cecece;
    // border-radius: 10px;
    padding: 12px 12px 9px 12px;
    position: relative;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :nth-child(1), :nth-child(2){
        border-bottom: 1px solid #cecece;
    }
    :nth-child(1), :nth-child(3){
        border-right: 1px solid #cecece;
    }

    .target_bot {
        width: 10px;
        height: 10px;
        cursor: pointer;
        padding-left: 6px;
        bottom: 1px;
        // margin-left: auto;
        span {
            width: 10px;
            height: 10px;
            svg {
                position: absolute;
                width: 10px;
                height: 10px;
                color: #999999;
                fill: #999999;
            }
        }
    }
`
const CardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(42%, 1fr));
    grid-gap: 0px;
    margin-top: 10px;
    box-shadow: 0px 2px 4px #0000001a;
    border: 1px solid #cecece;
    border-radius: 10px;
`

const Wrapper = styled.section`
    width: 100%;
    float: left;
    overflow-y: hidden;
    height: calc(100vh - 20px);
    padding: 20px 0;
`
const CardWrapper = styled.div`
    width: 100%;
    float: left;
    overflow-y: ${props => (props.gridScroll ? 'hidden' : 'scroll')};
    display: grid;
    height: calc(100vh - 210px);
    grid-template-columns: ${(props: any) =>
        props.gridWidth ? 'repeat(auto-fill, minmax(100%, 1fr))' : 'repeat(auto-fill, minmax(24%, 1fr))'};
    grid-gap: 20px;
    place-content: flex-start;

    .nodata_found {
        height: 100%;
    }

    @media (min-width: 1750px) and (max-width: 2000px) {
        grid-template-columns: ${(props: any) =>
            props.gridWidth ? 'repeat(auto-fill, minmax(100%, 1fr))' : 'repeat(auto-fill, minmax(22%, 1fr))'};
    }
`
const HeaderCard = styled.h4`
    width: 100%;
    float: left;
    font-size: 18px;
    margin: 0px;
    font-family: 'Gordita-Medium';
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
`
export const Paragraph = styled.p`
    font-family: 'Gordita-Regular';
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000000;
    margin-bottom: 0px;
    width: 100%;
    float: left;
    margin-top: 4px;
`
const ParagraphSpan = styled.span`
    font-family: 'Gordita-Regular';
    font-size: 10px;
    color: #355d8b;
`
const EditImg = styled.span`
    width: 16px;
    height: 16px;
    color: #494949;
    position: relative;
    top: 15px;
    right: 20px;
    cursor: pointer;
    display: none;

    $ svg {
        width: 16px;
        height: 16px;
    }
`
const Avatar = styled.span`
    width: 24px;
    height: 24px;
    fill: #333333;
`
const CardTopRight = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    padding-right: 10px;

    ${SmallButton} {
        width: max-content;
        label {
            margin-right: 15px;
        }
    }
    .btn_loader {
        margin-right: 0px;
        position: absolute !important;
        right: 9px;
        top: 0px;
        animation-name: spin;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        img {
            width: 12px;
            height: 12px;
        }

        @-ms-keyframes spin {
            from {
                -ms-transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
            }
        }
        @-moz-keyframes spin {
            from {
                -moz-transform: rotate(0deg);
            }
            to {
                -moz-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            from {
                -webkit-transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
    ${EditImg} {
        margin-left: 10px;
        right: 10px;
    }
    .publish_button {
        position: relative;
        top: 14px;
        display: flex;
        .tick_icon {
            right: 12px;
            position: absolute;
        }
        .target_bot {
            right: 20px;
            top: 2.5px;
        }
        span {
            width: 12px;
            height: 12px;
            right: 0;
            position: relative;
            top: -3px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
        .menu_sendIcon{
            top: 0px;
            right: 2px;
        }
    }
`
const CardSpan = styled.div`
    width: 48px;
    height: 48px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #e0f6ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: relative;

    ${Count} {
        top: 0;
        background: ;
    }
`
const Content = styled.div`
    width: 82%;
    float: left;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 2px;
`
const Card = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    float: left;
    display: flex;
    align-items: center;
    border-radius: 18px;
    background: #fff;
    padding: 20px;
    padding-top: 30px;
    position: relative;
    flex-direction: column;
    border: 1px solid #fff;

    .icon_date_wrapper {
        width: 100%;
        height: auto;
        cursor: pointer;
        float: left;
        display: flex;
        margin-top: 12px;
    }
    .update_date_wrapper {
        width: 100%;
        height: auto;
        cursor: pointer;
        float: left;
        display: flex;
        background: #f4f4f4;
        border-radius: 6px;
        padding: 12px;
        margin-top: 12px;
        padding-bottom: 9px;

        ${Content} {
            width: 100%;
            padding: 0px;
            p {
                margin-top: 0px;
                :first-child {
                    margin-bottom: 4px;
                }
                :first-child, :last-child {
                    display: flex;
                    justify-content: space-between;
                    // font-family: 'Gordita-Medium';
                }
            }
        }
    }
    .card__wrapper {
        width: 100%;
        height: 100%;
        cursor: pointer;
        float: left;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
    }
    &:hover {
        background: #ddfeef;
        transition: 0.1s ease;
        border: 1px solid #009d5f;
    }
    :hover ${HeaderCard} {
        color: #000;
    }
    :hover ${Paragraph} {
        color: #617995;
    }
    :hover ${ParagraphSpan} {
        color: #333;
    }
    :hover ${EditImg} {
        color: #666;
        fill: #666;
        display: block;
    }
    :hover ${Avatar} {
        fill: #000;
    }
    :hover ${CardSpan} {
        background: #fff;
    }

    .create_bot_icon{
        ${Count}{
            top:12px;
            right: -6px;
            border: 1.5px solid #fff;
            width: 18px;
            height: 18px;

            svg{
                width: 8px;
                height: 8px
                fill: #355D8B;
                color: #355D8B;
            }
        }
    }

    h2{
        font-family: 'Gordita-Medium';
        font-size: 18px;
        color: #466AF2;
        line-height: normal;
        margin-bottom: 0;
        margin-top: 8px;
    }

`

const ParagraphSpanTime = styled.span`
    padding-left: 4px;
`

const ModalWrapper = styled.div`
    width: 35%;
    float: left;
`

export const DropdownSearchWrapper = styled.div`
    width: 100%;
    float: left;

    ${SerachInputWrapper} {
        padding: 12px 10px;
        background: #fff;
        border-bottom: 1px solid #e2e2e2;
        span {
            top: 22px;
            left: 22px;
        }
        ${SerachInput} {
            width: 100%;
        }
    }
`


const getLanguage = (lang: string) => {
    switch (lang) {
        case 'spanish':
            return 'Spanish'
        case 'greek':
            return 'Greek'
        case 'portuguese':
            return 'Portuguese'
        default:
            return 'English (United States)'
    }
}

const AssistantHeader = ({
    setCreatebot,
    setAssistantState,
    setOpenPopup,
    botWritePermission,
  }: any) => {
    return (
      <>
        <AssistantMainHeader>
          <>
            <Button primary onClick={() => setOpenPopup(true)}>
              <span className="header_button_icon">
                {' '}
                <DownloadFromMarketplace />
              </span>
              <label>Download</label>
            </Button>
          </>
        </AssistantMainHeader>
        {/* <AssistantHomeSubHeader>
            <>
                <VideoButtonB id={'CREATE_A_CHATBOT_FROM_SCRATCH'} showOnlyIcon/>
            </>
        </AssistantHomeSubHeader> */}
      </>
    );
  };

interface AssistantCardsP {
    summary: BotDetailsT[]
    createbot: boolean
    setCreatebot: Function
    retry: Function
    Assistants: AssistantT[]
    canpublish: any
    openAssistant: Function
    updateAssistant: Function
    createAssistant: Function
    deleteBot: Function
    publishbot: Function
    unpublishbot: Function
    props: any
    chatChannelWS: any
    checkNull: Array<{ id: string; nullable: boolean }>
    params: any
}
enum ErrorState {
    Valid,
    InValid
}

type HasError = {
    type: ErrorState.Valid
    error: boolean
    info: string
}

type NoError = {
    type: ErrorState.InValid
}

const NoErrorV: NoError = { type: ErrorState.InValid }

type ErrorT = NoError | HasError
export type UploadbotT = {
    workspace: string
    botId: string
    botName: string
    category: string
    replaceBotId?: string
}
function AssistantCards({
    summary,
    createbot,
    setCreatebot,
    Assistants,
    openAssistant,
    updateAssistant,
    createAssistant,
    deleteBot,
    publishbot,
    unpublishbot,
    canpublish,
    retry,
    checkNull,
    chatChannelWS,
    props,
    params
}: AssistantCardsP) {
    const { workspacename } = props.match.params

    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [uploadId, setUploadId] = useState('')
    const [publishId, setPublishId] = useState('')

    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState({ name: '', type: '' })
    const [keycode, setKeycode] = useState<number>(13)
    const [metacode, setMetacode] = useState<boolean>(false)
    const [workSpaces, setWorkSpaces] = useState<any>({ slackWorkspaces: [], teamsWorkspaces: [], agentHandOff: {} })
    const [selectedCategory, setSelectedCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [selectedBot, setSelectedBot] = useState('')
    const [isReplace, setIsReplace] = useState(false)
    const [isSuccess, setIsSucess] = useState({ loading: false, success: false })
    const [canAccess, setCanAccess] = useState(false)
    const [bots, setBots] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }])
    const botName = (id: string): string => {
        const bot = Assistants.find(e => e.id === deleteId)
        if (bot !== undefined) return bot.name
        return 'Bot'
    }

    const languages = [
        { name: 'English (United States)', type: 'english' },
        { name: 'Spanish', type: 'spanish' },
        { name: 'Greek', type: 'greek' },
        { name: 'Portuguese', type: 'portuguese' },
        // { name: 'German', type: 'german' },
        // { name: 'French', type: 'french' }
    ]
    const [validName, setValidName] = useState(false)
    let isEmptyField = !validName && name !== '' && name.length < 30 && selectedLanguage.name !== ''

    const format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/
    const checkSpecialChar = (str: string) => format.test(str)

    const onChangeName = (event: any) => {
        if (keycode != 32 && metacode == false) {
            setName(event.target.value)
            setValidName(checkSpecialChar(event.target.value))
        }
    }

    const onSave = () => {
        setLoading(true)
         CreateAssistant()
    }
    const CreateAssistant = () => {
        createAssistant(name, selectedLanguage.type, (error: any) => {
            const errorMessage =
                error.response &&
                error.response.data &&
                error.response.data.code === 'RESOURCE_CONSUMPTION_LIMIT_EXCEEDED'
                    ? 'Maximum allowed bots limit reached, upgrade now to add more'
                    : error.response.data.message
                    ? error.response.data.message
                    : 'something went wrong'
            setLoading(false)
            setModelError({
                type: ErrorState.Valid,
                error: true,
                info: errorMessage
            })
        })
    }

    useEffect(() => {
        CanAccess(workspacename).then((res: { data: { output: boolean } }) => {
            setCanAccess(res.data.output)
        })
        GetAllCategories(workspacename).then(res => {
            setCategories(res.data.output)
        })
        MyUploads(workspacename).then(res => {
            setBots(res.data.output)
        })
    }, [uploadId])
    const GetBotId = (botName: string) => Assistants.filter(bot => bot.name === botName)[0].id as string
    const GetBotMarketPlaceId = (botName: string) => bots.filter(bot => bot.name === botName)[0].id as string
    const AddNewMarketplace = () => {
        setIsSucess({ loading: true, success: false })
        let uploadNewBot = {
            workspace: workspacename,
            botId: GetBotId(name),
            botName: name,
            category: selectedCategory
        }
        UploadBot(uploadNewBot)
            .then(res => {
                setUploadId('')
                setIsSucess({ loading: false, success: true })
            })
            .catch(error => {
                let message =
                    error && error.response && error.response && error.response.data && error.response.data.message
                const ErrorMessage = message.includes('Following keys are invalid')
                    ? 'Please create a dialog or FAQ to upload the bot.'
                    : message
                    ? message
                    : 'Something went wrong!'
                setIsSucess({ loading: false, success: false })
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: ErrorMessage
                })
            })
    }
    const ReplaceMarketplace = () => {
        setIsSucess({ loading: true, success: false })
        let uploadReplaceBot = {
            workspace: workspacename,
            botId: GetBotMarketPlaceId(selectedBot),
            botName: name,
            category: selectedCategory,
            replaceBotId: GetBotId(name)
        }
        UploadBot(uploadReplaceBot)
            .then(res => {
                setUploadId('')
                setIsSucess({ loading: false, success: true })
            })
            .catch(error => {
                let ErrorMessage = error && error.response && error.response.data.message
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: ErrorMessage
                })
                setIsSucess({ loading: false, success: false })
            })
    }

    function retnFn(i: number, botDetails: BotDetailsT, updateDetail: any) {
        switch (i) {
            case 0:
                return botDetails.user_count
            // case 1:
            //     return botDetails.conversation_count
            case 1:
                return botDetails.top_5_resolved_count.reduce((acc, val) => (acc += val.status_count), 0)
            case 2:
                return (
                    botDetails.top_5_resolved_count.reduce((acc, val) => (acc += val.status_count), 0) *
                    updateDetail.avg_time_spend_by_agent
                ).toFixed(2)
            default:
                return (
                    '$' +
                    botDetails.top_5_resolved_count.reduce((acc, val) => (acc += val.status_count), 0) *
                        updateDetail.avg_cost_to_resolve_an_issue
                )
        }
    }

    return (
        <Wrapper className="tour_automation_canvas">
            {uploadId !== '' ? (
                <Modal error={modelError} showPopupLoader={loading}>
                    {isSuccess.loading ? (
                        <Loader.PopupLoader show={true} className="remove_connection_popuploader" />
                    ) : (
                        <ErrorBoundary
                            error={modelError}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.AddorEdit
                                            onClick={() => {
                                                setModelError(NoErrorV)
                                                setUploadId('')
                                            }}
                                            info={info}
                                        />
                                    )
                                )
                            }}
                        >
                            <h2>Upload to Marketplace</h2>
                            <Paragraph style={{ margin: 0 }}> Upload this Bot to marketplace</Paragraph>
                            <div style={{ marginTop: 12 }}>
                                <InputWrapper>
                                    <InputContainer style={{ marginBottom: 20 }}>
                                        <Input
                                            type="text"
                                            value={name}
                                            onKeyDown={(e: any) => {
                                                setKeycode(e.keyCode)
                                                if (e.metaKey || e.ctrlKey) setMetacode(true)
                                                else setMetacode(false)
                                            }}
                                            onChange={(event: any) => {
                                                if (keycode != 32 && metacode == false) {
                                                    setName(event.target.value)
                                                }
                                            }}
                                            className="editor_btn_disabled"
                                        />
                                        <InputContent>Selected Bot</InputContent>
                                    </InputContainer>
                                </InputWrapper>
                                <DropdownSearchWrapper>
                                    <DropdownWithSearch
                                        header={'Category'}
                                        keys={'name'}
                                        placholder={'Search'}
                                        value={selectedCategory}
                                        content={categories}
                                        onClick={(category: any) => {
                                            setSelectedCategory(category)
                                        }}
                                        size="responsive"
                                    />
                                    {isReplace && (
                                        <DropdownWithSearch
                                            header={'Replace with'}
                                            keys={'name'}
                                            placholder={'Search'}
                                            value={selectedBot}
                                            content={bots}
                                            onClick={(bot: any) => {
                                                setSelectedBot(bot)
                                            }}
                                            size="responsive"
                                        />
                                    )}
                                </DropdownSearchWrapper>
                                <ButtonWrapper>
                                    {!isReplace && (
                                        <Button
                                            className={selectedCategory === '' ? 'editor_btn_disabled' : ''}
                                            style={{ margin: '10px 10px 0px 0px' }}
                                            type="button"
                                            disabled={selectedCategory === ''}
                                            onClick={AddNewMarketplace}
                                        >
                                            <label>Add new</label>
                                        </Button>
                                    )}
                                    {isReplace && (
                                        <Button
                                            type="button"
                                            className={selectedBot === '' ? 'editor_btn_disabled' : ''}
                                            disabled={selectedBot === ''}
                                            onClick={(_: any) => {
                                                ReplaceMarketplace()
                                                setName('')
                                                setMetacode(false)
                                                setKeycode(13)
                                            }}
                                        >
                                            <label>Replace</label>
                                        </Button>
                                    )}
                                    {!isReplace && (
                                        <Button
                                            style={{ margin: '0px 10px 0px 0px' }}
                                            primary
                                            type="button"
                                            className={selectedCategory === '' ? 'editor_btn_disabled' : ''}
                                            disabled={selectedCategory === ''}
                                            onClick={(_: any) => {
                                                setIsReplace(true)
                                            }}
                                        >
                                            <label>Replace</label>
                                        </Button>
                                    )}
                                    <Button
                                        style={{ margin: '0px 10px 0px 0px' }}
                                        primary
                                        type="button"
                                        disabled={name === ''}
                                        onClick={(_: any) => {
                                            setUploadId('')
                                            setName('')
                                            setMetacode(false)
                                            setKeycode(13)
                                        }}
                                    >
                                        <label>Cancel</label>
                                    </Button>
                                </ButtonWrapper>
                            </div>
                        </ErrorBoundary>
                    )}
                </Modal>
            ) : null}
            {isSuccess.success ? (
                <Modal error={modelError} showPopupLoader={loading}>
                    <ErrorBoundary
                        error={modelError}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.AddorEdit
                                        onClick={() => {
                                            setModelError(NoErrorV)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Paragraph style={{ margin: 0 }}>Bot uploaded to marketplace</Paragraph>
                        <br />
                        <ButtonWrapper>
                            <Button
                                style={{ margin: '0px 10px 0px 0px' }}
                                type="button"
                                onClick={() => setIsSucess({ loading: false, success: false })}
                            >
                                <label>Ok</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            ) : null}
            {editId !== '' ? (
                <BotPopup
                    modelError={modelError}
                    setModelError={setModelError}
                    loading={loading}
                    setLoading={setLoading}
                    NoErrorV={NoErrorV}
                    keycode={keycode}
                    setKeycode={setKeycode}
                    setMetacode={setMetacode}
                    metacode={metacode}
                    name={name}
                    setName={setName}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    ErrorState={ErrorState}
                    editId={editId}
                    setEditId={setEditId}
                    assistantAction={updateAssistant}
                />
            ) : null}
            {createbot ? (
                <BotPopup
                    modelError={modelError}
                    setModelError={setModelError}
                    loading={loading}
                    setLoading={setLoading}
                    NoErrorV={NoErrorV}
                    keycode={keycode}
                    setKeycode={setKeycode}
                    setMetacode={setMetacode}
                    metacode={metacode}
                    name={name}
                    setName={setName}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    ErrorState={ErrorState}
                    assistantAction={createAssistant}
                    onCancel={() => {
                        setCreatebot(false)
                        setName('')
                        setSelectedLanguage({ name: '', type: '' })
                    }}
                />
            ) : null}
            {deleteId !== '' ? (
                <Modal error={modelError} showPopupLoader={false}>
                    {loading ? (
                        <Loader.PopupLoader show={true} className="remove_connection_popuploader" />
                    ) : (
                        <ErrorBoundary
                            error={modelError}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.AddorEdit
                                            onClick={() => {
                                                setModelError(NoErrorV)
                                                setDeleteId('')
                                            }}
                                            info={info}
                                        />
                                    )
                                )
                            }}
                        >
                            <div className="alert_popup_message">
                                {workSpaces.slackWorkspaces.length == 0 &&
                                workSpaces.teamsWorkspaces.length == 0 &&
                                workSpaces.agentHandOff &&
                                (R.isEmpty(workSpaces.agentHandOff) || workSpaces.agentHandOff.teams_deploy == null) ? (
                                    <>
                                        <h2>{`Delete ${botName(deleteId)}?`}</h2>

                                        <Paragraph style={{ margin: 0, whiteSpace: 'normal' }}>
                                            Chatbot will be permanently deleted and chatbot will not work if it is
                                            deployed in any chat channel.
                                        </Paragraph>
                                    </>
                                ) : (
                                    <>
                                        <h2>{`Are you sure to delete ${botName(deleteId)}?`}</h2>
                                        <p>
                                            This chatbot is deployed on following chat channels. Please remove the
                                            integration to delete the assistant.
                                        </p>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {workSpaces.slackWorkspaces.map((ws: any, i: number) => (
                                                <li key={i} className="automations_with_connections bots">
                                                    Slack Channel - {ws.slack_deploy.slack_workspace}
                                                </li>
                                            ))}
                                        </ul>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {workSpaces.teamsWorkspaces.map((ws: any, i: number) => (
                                                <li key={i} className="automations_with_connections bots">
                                                    Teams Channel - {ws.teams_deploy.team_name}
                                                </li>
                                            ))}
                                        </ul>
                                        {!R.isEmpty(workSpaces.agentHandOff) &&
                                            workSpaces.agentHandOff.teams_deploy != null && (
                                                <ul style={{ listStyleType: 'none' }}>
                                                    {workSpaces.agentHandOff.teams_deploy && (
                                                        <li key="" className="automations_with_connections bots">
                                                            Agent HandOff -{' '}
                                                            {workSpaces.agentHandOff.teams_deploy.team_name}
                                                        </li>
                                                    )}
                                                </ul>
                                            )}
                                    </>
                                )}

                                {workSpaces.slackWorkspaces.length == 0 &&
                                    workSpaces.teamsWorkspaces.length == 0 &&
                                    (R.isEmpty(workSpaces.agentHandOff) ||
                                        workSpaces.agentHandOff.teams_deploy == null) && (
                                        <ButtonWrapper style={{ marginTop: 10 }}>
                                            <Button
                                                primary
                                                type="button"
                                                onClick={(_: any) => {
                                                    setDeleteId('')
                                                }}
                                            >
                                                <label>No</label>
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={(_: any) => {
                                                    setLoading(true)
                                                    deleteBot(
                                                        deleteId,
                                                        () => {
                                                            setDeleteId('')
                                                            setLoading(false)
                                                        },
                                                        () => {
                                                            setLoading(false)
                                                            setModelError({
                                                                type: ErrorState.Valid,
                                                                error: true,
                                                                info: 'something went wrong'
                                                            })
                                                        }
                                                    )
                                                }}
                                            >
                                                <label>Yes</label>
                                            </Button>
                                        </ButtonWrapper>
                                    )}
                                {workSpaces.slackWorkspaces.length > 0 ||
                                workSpaces.teamsWorkspaces.length > 0 ||
                                (!R.isEmpty(workSpaces.agentHandOff) &&
                                    workSpaces.agentHandOff.teams_deploy != null) ? (
                                    <ButtonWrapper>
                                        <Button
                                            primary
                                            type="button"
                                            onClick={() => {
                                                setDeleteId('')
                                            }}
                                        >
                                            <label>Cancel</label>
                                        </Button>
                                    </ButtonWrapper>
                                ) : null}
                            </div>
                        </ErrorBoundary>
                    )}
                </Modal>
            ) : null}
            {publishId !== '' ? (
                <PublishPopup
                    modelError={modelError}
                    setModelError={setModelError}
                    NoErrorV={NoErrorV}
                    publishbot={publishbot}
                    unpublishbot={unpublishbot}
                    publishId={publishId}
                    setPublishId={setPublishId}
                    setLoading={setLoading}
                    ErrorState={ErrorState}
                    loading={loading}
                    workSpaces={workSpaces}
                    setWorkSpaces={setWorkSpaces}
                    workspacename={workspacename}
                    Assistants={Assistants}
                />
            ) : null}
            <CardWrapper>
            <Card>
                <BotCreationCard {...{ modelError, setModelError, selectedLanguage,setSelectedLanguage,languages, validName, onChangeName, isEmptyField, onSave, name, publishId }}/>
            </Card>
                {Assistants.map((Assistant: AssistantT, index: number) => (
                    <ReturnCardsUI Assistant={Assistant} index={index} params={params} key={index}/>
                ))}
            </CardWrapper>
        </Wrapper>
    )

    

    function ReturnCardsUI(props: { Assistant: AssistantT; index: number; params: any }) {
        const { Assistant, index, params } = props

        const writePermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT/${Assistant.id}/ROOT_DIALOG_NODE`,"WRITE")
        const deletePermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT/${Assistant.id}/ROOT_DIALOG_NODE`,"DELETE")
        const uploadPermission = usePermissions(workspacename,`CHAT_BOT_MODULE/BOT_MARKET_PLACE/BOT`,"WRITE")

        const publishButtonPermission = usePermissions(workspacename,`ENGINE/PUBLIC_BOT`,"WRITE")

        const filterNullValue = checkNull.filter(n => n.id == Assistant.id)[0]
        const isLive = chatChannelWS.find(
            (data: any) => data.assistance_id === Assistant.id || data.bot_id === Assistant.id
        )

        let disableDeployBtn = false

        if (params.dialogues.length > 0) {
            const { intents, entities, dialogues, welcomeNode, fallbackNode, defaultNodes } = params.dialogues.filter(
                (ass: DialogDetailsT) => ass.assistantId == Assistant.id
            )[0]

            const fetchChild = useCallback(() => MixChild(dialogues), [dialogues])()

            const rootNodes = [welcomeNode, ...defaultNodes, fallbackNode]

            const showErrorOnWelcomeNode = showInputError(welcomeNode, intents, entities, fetchChild, rootNodes)
            const showErrorOnFallbackNode = showInputError(fallbackNode, intents, entities, fetchChild, rootNodes)

            disableDeployBtn =
                showErrorOnWelcomeNode ||
                showErrorOnFallbackNode ||
                defaultNodes
                    .map((dialogue: DialogueT, i: number) => showInputError(dialogue, intents, entities, fetchChild, rootNodes))
                    .some((err: boolean) => err == true)
        }

        return (
            <Card key={index} onClick={() => openAssistant(Assistant)}>
                <CardTopRight>
                    {uploadPermission.value&&<EditImg
                        onClick={(e: any) => {
                            e.stopPropagation()
                            setUploadId(Assistant.id)
                            setName(Assistant.name)
                            setSelectedCategory('')
                            setSelectedBot('')
                            setIsReplace(false)
                        }}
                    >
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Upload'}
                            distance={5}
                        >
                        {canAccess && <UploadToMarketplace />}
                        </Tooltip>
                    </EditImg>}
                    {writePermission.value && <EditImg
                        onClick={(e: any) => {
                            e.stopPropagation()
                            setEditId(Assistant.id)
                            setName(Assistant.name)
                            setSelectedLanguage({
                                type: Assistant.lang_type,
                                name: getLanguage(Assistant.lang_type)
                            })
                        }}
                    >
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Edit'}
                            distance={5}
                        >
                            <EditIcon />
                        </Tooltip>
                    </EditImg>}
                    {deletePermission.value&&<EditImg
                        onClick={(e: any) => {
                            e.stopPropagation()
                            setLoading(true)
                            setDeleteId(Assistant.id)
                            fetchWorkspaces(workspacename, Assistant.id)
                                .then((op: any) => {
                                    let state = op.slackWorkspaces
                                        ? op
                                        : {
                                              slackWorkspaces: op[0].data.filter(
                                                  (w: any) => w.slack_deploy && w.assistance_id == Assistant.id
                                              ),
                                              teamsWorkspaces: op[1].data.filter(
                                                  (w: any) => w.teams_deploy && w.bot_id == Assistant.id
                                              ),
                                              agentHandOff: op[2].data
                                          }

                                    setLoading(false)
                                    setWorkSpaces(state)
                                })
                                .catch(err => {
                                    setLoading(false)
                                    setModelError({
                                        type: ErrorState.Valid,
                                        error: true,
                                        info: 'Something went wrong. Please try again!'
                                    })
                                })
                        }}
                    >
                        <Tooltip
                            className="target customTip "
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Delete'}
                            distance={5}
                        >
                            <DeleteIcon />
                        </Tooltip>
                    </EditImg>}

                    {publishButtonPermission.value && <PublishButton
                        assistant={Assistant}
                        canpublish={canpublish}
                        className={'publish_button'}
                        isInCard={true}
                        setPublishId={setPublishId}
                        isNull={filterNullValue && filterNullValue.nullable}
                        isLive={isLive ? true : false}
                        isIntentEntityEmpty={disableDeployBtn}
                        from="botsHome"
                    />}
                </CardTopRight>
                <div className="icon_date_wrapper">
                    <CardSpan onClick={() => openAssistant(Assistant)}>
                        <Avatar>
                            <DialogHomeIcon />
                        </Avatar>
                        <Count>{index + 1}</Count>
                    </CardSpan>

                    <Content onClick={() => openAssistant(Assistant)}>
                        <HeaderCard>{Assistant.name}</HeaderCard>
                        <Paragraph>
                            Bot Language: <ParagraphSpan>{getLanguage(Assistant.lang_type)}</ParagraphSpan>
                        </Paragraph>
                    </Content>
                </div>
                <div className="update_date_wrapper">
                    <Content>
                        <Paragraph>
                            Last Updated:{' '}
                            <ParagraphSpan>
                                {formatDateInCard(Assistant.updated)}
                                <ParagraphSpanTime>{formatAMPM(Assistant.updated)}</ParagraphSpanTime>
                            </ParagraphSpan>
                        </Paragraph>
                        <Paragraph>
                            Published:{' '}
                            {Assistant.published_at !== null ? (
                                <ParagraphSpan>
                                    {FormatwithUTCDate(Assistant.published_at)}
                                    <ParagraphSpanTime>{FormatwithUTCTime(Assistant.published_at)}</ParagraphSpanTime>
                                </ParagraphSpan>
                            ) : (
                                <ParagraphSpan>Not published</ParagraphSpan>
                            )}
                        </Paragraph>
                    </Content>
                </div>
                <div className="card__wrapper">
                    <CardContainer>
                        {StatusDetails.map((data, i) => (
                            <CardBox key={i}>
                                <CardHeader>
                                    {data.header}
                                    <Tooltip
                                        className="target_bot"
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        tagName="span"
                                        content={data.hoverContent}
                                    >
                                        <span>{data.image}</span>
                                    </Tooltip>
                                </CardHeader>
                                <CountNumber>
                                    <CountWrapper>
                                        {retnFn(i, summary.filter(data => data.botId === Assistant.id)[0], Assistant)}
                                    </CountWrapper>
                                    {/* <BotChannelsWrapper>
                                                <ChannelContainer>
                                                    <span>
                                                        <SlackIconCC />
                                                        <Count>1</Count>
                                                    </span>
                                                    <span>
                                                        <TeamsIcon />
                                                        <Count>1</Count>
                                                    </span>
                                                    <span>
                                                        <WidgetIcon />
                                                        <Count>1</Count>
                                                    </span>
                                                </ChannelContainer>
                                            </BotChannelsWrapper> */}
                                </CountNumber>
                            </CardBox>
                        ))}
                    </CardContainer>
                </div>
            </Card>
        )
    }
}

const showInputError = (dialog: DialogueT, intents: IntentT[], entities: EntityT[], fetchChild: any, dialogues:any) => {
    const parentNode =
        dialog.conditions &&
        (dialog.conditions.type == 'FallBackExpression')
            ? false
            : validateNode({ intents, entities }, dialog.conditions)
    const responses: any = !parentNode
        ? dialog.type === 'if_node'
            ? dialog.responses
                  .filter(
                      dg =>
                          dg.type == 'slot_container' ||
                          dg.type == 'automation' ||
                          dg.type == 'agent_handoff' ||
                          dg.type == 'file_upload'
                  )
                  .map(dg => {
                      return validateResponseNode({ intents, entities }, dg, dialogues)
                  })
                  .concat(
                      fetchChild(dialog).map((c: DialogueT) => {
                          return showInputError(c, intents, entities, fetchChild, dialogues)
                      }) as any
                  )
            : []
        : []

    return parentNode || responses.some((r: any) => r == true)
}

function BotCreationCard(props:any){
    const { modelError, setModelError, selectedLanguage,setSelectedLanguage,languages, validName, onChangeName, isEmptyField, onSave, name, publishId } = props

    return(
        <ErrorBoundary
            error={ publishId != ''? { error:null , info : ""} : modelError}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.AddorEdit
                            onClick={() => {
                                setModelError(NoErrorV)
                            }}
                            info={info}
                        />
                    )
                )
            }}
        >
           
            <CardSpan className="create_bot_icon">
                <Avatar>
                    <DialogHomeIcon />
                </Avatar>
                <Count>
                    <AddIcon />
                </Count>
            </CardSpan>
            <h2>Create Bot</h2>
            {/* <Paragraph style={{ margin: 0 }}>Create chatbot name</Paragraph> */}
            <div style={{ marginTop: 12 }}>
                <DropdownSearchWrapper>
                        <DropdownWithSearch
                            header={'Select language'}
                            keys={'name'}
                            returnAllData
                            placholder={'Search'}
                            value={selectedLanguage.name}
                            content={languages}
                            onClick={(language: any) => {
                                setSelectedLanguage(language)
                            }}
                            size={"responsive"}
                        />
                    </DropdownSearchWrapper>
                {validName ? (
                    <small style={{ float: 'right', color: 'red' }}>Special character will not be accepted</small>
                ) : null}
                <InputWrapper>
                    <InputContainer>
                        <Input
                            type="text"
                            value={name}
                            // onKeyDown={(e: any) => {
                            //     setKeycode(e.keyCode)
                            //     if (e.metaKey || e.ctrlKey) setMetacode(true)
                            //     else setMetacode(false)
                            // }}
                            onChange={(event: any) => onChangeName(event)}
                        />
                        {/* <span><DialogHomeIcon /></span> */}
                        <InputContent>Bot name</InputContent>
                    </InputContainer>
                </InputWrapper>
                   
                <ButtonWrapper>
                    {/* <Button primary type="button" onClick={() => CancelPopup()}>
                        <label>Cancel</label>
                    </Button> */}
                    <Button
                        className={!isEmptyField ? 'editor_btn_disabled' : 'home_create_btn'}
                        type="button"
                        disabled={!isEmptyField}
                        onClick={() => onSave()}
                    >
                        <label>Create</label>
                    </Button>
                </ButtonWrapper>
            </div>
        </ErrorBoundary>
    )
}

const StatusDetails = [
    {
        header: 'Total Bot Users',
        count: '248',
        image: <InfoIcon />,
        hoverContent: 'Total number of unique users who interacted with the bot in the specific time period.'
    },
    {
        header: 'Bot Auto Resolved',
        count: '698',
        image: <InfoIcon />,
        hoverContent: 'Total number of bot sessions that are auto resolved by the bot in the specific time period.'
    },
    {
        header: 'Agent Hours Saved',
        count: '5863',
        hours: 'hrs',
        image: <InfoIcon />,
        hoverContent: 'Agent time saved by the bot by auto resolving the user issues in the specific time period.'
    },
    {
        header: 'Cost Savings',
        count: '$10819',
        image: <InfoIcon />,
        hoverContent:
            'Cost saved to the company by the bot by auto resolving the user issues in the specific time period.'
    }
]

interface AssistantModalP {
    cancelPopup: Function
    createAssistant: Function
    setOpenPopup: Function
}

function AssistantModal({ cancelPopup, createAssistant, setOpenPopup }: AssistantModalP) {
    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)
    const [name, setName] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState({ name: '', type: '' })
    const [keycode, setKeycode] = useState<number>(13)
    const [loading, setloading] = useState<boolean>(false)

    const [metacode, setMetacode] = useState<boolean>(false)
    const onCancel = () => {
        cancelPopup()
    }
    return (
        // <Modal error={modelError} showPopupLoader={loading}>
        <ErrorBoundary
            error={modelError}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.AddorEdit
                            onClick={() => {
                                setModelError(NoErrorV)
                            }}
                            info={info}
                        />
                    )
                )
            }}
        >
            <BotCreation
                modelError={modelError}
                setModelError={setModelError}
                loading={loading}
                setLoading={setloading}
                NoErrorV={NoErrorV}
                keycode={keycode}
                setKeycode={setKeycode}
                setMetacode={setMetacode}
                metacode={metacode}
                name={name}
                setName={setName}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                ErrorState={ErrorState}
                assistantAction={createAssistant}
                onCancel={onCancel}
                setOpenPopup={setOpenPopup}
            />
        </ErrorBoundary>
        // </Modal>
    )
}

interface ConstructorP {
    state: PageStateT
    setState: React.Dispatch<React.SetStateAction<PageStateT>>
    retry: Function
    updateAssistant: Function
    openAssistant: Function
    newAssistant: Function
    deleteBot: Function
    publishbot: Function
    unpublishbot: Function
    props: any
    chatChannelWS: any
    checkNull: {
        id: string
        nullable: boolean
    }[]
    dialogues: DialogDetailsT[]
}

const BotHomeListing = (params: ConstructorP) => {
    const {
        state,
        setState,
        retry,
        openAssistant,
        newAssistant,
        updateAssistant,
        deleteBot,
        publishbot,
        unpublishbot,
        props,
        checkNull,
        chatChannelWS
    } = params
    const crateFromMarketplace = new URLSearchParams(window.location.search).get('download-from-marketplace')
    // console.log('crateFromMarketplace', crateFromMarketplace)
    const [openPopup, setOpenPopup] = useState(crateFromMarketplace === 'true' ? true : false)

    const botWritePermission = usePermissions(props.match.params.workspacename,`CHAT_BOT_MODULE/BOT`,"WRITE")

    return state.action === ActionT.Loading ? (
        <Loader.PageLoader show={true} />
    ) : (
        <React.Fragment>
            <ErrorBoundary
                unauthorized={state.action === ActionT.Unauthorized}
                error={{ error: null, info: null }}
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={retry} info={info} btnName={'Retry'} />
                }}
            >
                <div className="market_place center__wrapper">
                    {state.action === ActionT.View ? (
                        <CardWrapperView
                            setState={setState}
                            setOpenPopup={setOpenPopup}
                            Assistants={state.Assistants}
                            summary={state.summary}
                            openAssistant={openAssistant}
                            createAssistant={newAssistant}
                            updateAssistant={updateAssistant}
                            deleteBot={deleteBot}
                            publishbot={publishbot}
                            unpublishbot={unpublishbot}
                            openPopup={openPopup}
                            retry={retry}
                            checkNull={checkNull}
                            chatChannelWS={chatChannelWS}
                            props={props}
                            params={params}
                            botWritePermission={botWritePermission.value}
                        />
                    ) : null}
                    {state.action === ActionT.NoDataFound && crateFromMarketplace !== 'true' ? (
                        <AssistantModal
                            cancelPopup={retry}
                            createAssistant={newAssistant}
                            setOpenPopup={setOpenPopup}
                        />
                    ) : null}
                    <div>                   
                    </div>
                    {openPopup && <DialogModalWrapper setOpenPopup={setOpenPopup} retry={retry} botWritePermission={botWritePermission.value} {...props} />}
                </div>
            </ErrorBoundary>
        </React.Fragment>
    )
}
function CardWrapperView({
    summary,
    setState,
    setOpenPopup,
    Assistants,
    openAssistant,
    updateAssistant,
    createAssistant,
    deleteBot,
    publishbot,
    unpublishbot,
    openPopup,
    retry,
    props,
    checkNull,
    chatChannelWS,
    botWritePermission,
    params
}: any) {
    const [canpublish, setCanpublish] = useState({ resourceLimit: 0, limit: false })
    const [createbot, setCreatebot] = useState(false)
    let publishedbots =
        Assistants && Assistants.length !== 0 ? Assistants.filter((bot: any) => bot.published_at !== null).length : 0
    useEffect(() => {
        stripeGetApi(`${props.match.params.workspacename}/resource/BOT/limit`)
            .then(res => {
                const { resourceLimit } = res.output
                setCanpublish({ resourceLimit, limit: resourceLimit > publishedbots })
            })
            .catch(err => console.log('Error', err))
    }, [])
    const sortAssistant = sortAnArrayByDate(Assistants, 'created')

    return (
        <>
            <AssistantHeader setCreatebot={setCreatebot} setAssistantState={setState} setOpenPopup={setOpenPopup} botWritePermission={botWritePermission}/>
            <AssistantCards
                createbot={createbot}
                setCreatebot={setCreatebot}
                Assistants={sortAssistant}
                summary={summary}
                openAssistant={openAssistant}
                updateAssistant={updateAssistant}
                createAssistant={createAssistant}
                deleteBot={deleteBot}
                publishbot={publishbot}
                unpublishbot={unpublishbot}
                canpublish={canpublish}
                retry={retry}
                checkNull={checkNull}
                chatChannelWS={chatChannelWS}
                props={props}
                params={params}
            />
            {openPopup && <DialogModalWrapper setOpenPopup={setOpenPopup} retry={retry} {...props} botWritePermission={botWritePermission} />}
        </>
    )
}
export default BotHomeListing