/*eslint-disable*/
import * as React from 'react'

type SelectBoxP = {
    show: boolean
    isSelected: boolean
    handleSelected: () => void
}

export class SelectBox extends React.Component<SelectBoxP> {
    handleSelected = (event: React.FormEvent<HTMLInputElement>) => {
        event.stopPropagation()
        this.props.handleSelected()
    }
    render() {
        return (
            <label className='card_multiselect_option'>
                <input
                    type="radio"
                    className="option-input radio"
                    checked={this.props.isSelected}
                    onClick={this.handleSelected}
                    onChange={() => {}}
                    style={{
                        pointerEvents: this.props.show ? 'auto' : 'none',
                        visibility: this.props.show ? 'visible' : 'hidden',
                        border: this.props.show && !this.props.isSelected ? '#494949 solid 1px' : '#fff'
                    }}
                />
            </label>
        )
    }
}
