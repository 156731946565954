import * as DateFns from 'date-fns';

import { getJson, loginApiURL } from './api-utils';

// common
/* eslint-disable */

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const greet = ['Morning', 'Good morning', 'Good afternoon', 'Good evening', 'Go to bed']


export function generateUniqueId() {
  function getRandomId(length: number) {
    if (!length) {
      return '';
    }

    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    let array;

    if ('Uint8Array' in self && 'crypto' in self && length <= 65536) {
      const s = self as any;
      array = new Uint8Array(length);
      s.crypto.getRandomValues(array);
    } else {
      array = new Array(length);

      for (let i = 0; i < length; i++) {
        array[i] = Math.floor(Math.random() * 62);
      }
    }

    for (let i = 0; i < length; i++) {
      result += possible.charAt(array[i] % 62);
    }

    return result;
  }
  return (
    getRandomId(5) +
    '_' +
    (1 + Math.random() * 4294967295).toString(16).split('.').join('_')
  );
}

export const pendingPromise = () =>
  new Promise((res, rej) => setTimeout(() => {}, 30000));

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export const removeURLParams = (param: string) => {
  const url = new URL(window.location.toString());
  url.searchParams.delete(param);
  history.replaceState(null, 'Workativ', url.toString());
};

export const reloadWithoutParams = () => {
  console.log('reloadWithoutParams');
  if (window.location.href.includes('?')) {
    var redirect_url = window.location.href.split('?')[0];
    window.location.replace(redirect_url);
    return;
  }

  window.location.reload();
};
export const getCurrentUserInfo = () => {
  return getJson(loginApiURL('/user_info'));
  //   return Promise.resolve({
  //     "phone_number": {
  //         "id": 41,
  //         "mobileNo": "9566999583",
  //         "verified": true,
  //         "valid": true,
  //         "createdAt": "2021-11-09T05:01:48.000Z",
  //         "updatedAt": "2021-11-13T17:53:10.000Z",
  //         "code": "91",
  //         "userId": 139
  //     },
  //     "id": 139,
  //     "first_name": "Abinaya",
  //     "last_name": "Kan",
  //     "email": "steve.smith@workativ.com",
  //     "org": "Workativ",
  //     "role": "QA",
  //     "country": "IN",
  //     "verified": false
  // })
};
export function convertObjectToArray(object: object) {
  if (!object) return [];

  return Object.keys(object).map(function (key) {
    return { name: key, value: object[key] };
  });
}
export const formatDate = function (dateInString: Date) {
  const date = new Date(DateFns.format(dateInString, 'MM/dd/yyyy,'));
  // console.log("DOING::: date" ,date)

  var day = date.getDate();
  var month_index = date.getMonth();
  var year = date.getFullYear();

  return '' + day + ' ' + month_names[month_index] + ' ' + year;
};

export function formatAMPM(dateInString: string, withSeconds?: boolean) {
  // const date = new Date(DateFns.format(dateInString, 'DD MMM, YYYY HH:mm:ss'))
  const date = new Date(dateInString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let secs = date.getSeconds();

  const ampm = (hours >= 12 ? 'pm' : 'am').toUpperCase();
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesInString = minutes < 10 ? '0' + minutes : minutes.toString();
  const secsInString = secs < 10 ? '0' + secs : secs.toString();

  var strTime = hours + ':' + minutesInString + ' ' + ampm;
  var strTimeWithSecs =
    hours + ':' + minutesInString + ':' + secsInString + ' ' + ampm;

  return withSeconds ? strTimeWithSecs : strTime;
}

var month_names = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatDateInCard(dateInString: string, format?: boolean) {
  // const date = new Date(DateFns.format(dateInString, 'MM/DD/YYYY'))
  const date = new Date(dateInString);

  var day = date.getDate();
  var month_index = date.getMonth();
  var year = date.getFullYear();

  return format
    ? `${day}/${
        month_index < 10 ? '0' + (month_index + 1) : month_index + 1
      }/${year}`
    : `${day} ${month_names[month_index]}  ${year}`;
}

export function formatAreaChartDate(dateInString: string) {
  // const date = new Date(DateFns.format(dateInString, 'MM/DD/YYYY'))

  const seperatedDate = dateInString.split('-');

  if (seperatedDate.length > 1) {
    const date1 = seperatedDate[0].split('/');
    const date2 = seperatedDate[1].split('/');
    return `${date1[0]} ${month_names[parseInt(date1[1]) - 1]} - ${date2[0]} ${
      month_names[parseInt(date2[1]) - 1]
    }`;
  } else {
    return `${dateInString.split('/')[0]} ${
      month_names[parseInt(dateInString.split('/')[1])]
    }`;
  }
}

export function convertFromToDate(dateInString: string) {
  const date = new Date(dateInString);
  var day = date.getDate();
  var month_index = date.getMonth() + 1;
  var year = date.getFullYear();
  return `${month_index < 10 ? '0' + month_index : month_index}-${
    day < 10 ? '0' + day : day
  }-${year}`;
}

export function convertFromToDateForWorkFlow(dateInString: string) {
  const date = new Date(dateInString);
  var day = date.getDate();
  var month_index = date.getMonth() + 1;
  var year = date.getFullYear();
  return(`${year}/${month_index < 10 ? '0' + month_index : month_index}/${day < 10 ? '0' + day : day}`)
}

export const redirectWorkspace = () => {
  const url = process.env['REACT_APP_LOGIN_API'];
  window.location.href = url
    ? url + '/redirect_workspace'
    : '/redirect_workspace';
};

export type ProductId = 'ASSISTANT' | 'AUTOMATE';
export const getProductId = (): ProductId => {
  const productId = process.env['REACT_APP_PRODUCT_ID'];
  // console.log('DOING :: process.env', process.env, productId);
  if (productId === 'ASSISTANT') return 'ASSISTANT';
  else if (productId === 'AUTOMATE') return 'AUTOMATE';
  return 'ASSISTANT';
};

export const isAutomate = () => {
  const productId = getProductId();
  return productId === 'AUTOMATE' ? true : false;
};

export const isNotAutomate = () => (isAutomate() ? false : true);

export const MOCK_USER_API = false;
export const MOCK_TRYIT = false;
export const MOCK_BOT_SUMMARY = false;
export const MOCK_WIDGET = false;

export const convertToMDText = (md: any): any => {
  console.log(md, 'checkmd');
  if (md.markdown_text) {
    return md;
  }
  if (typeof md == 'string') {
    return {
      markdown_text: 'normal_text',
      normal_text: md,
    };
  } else if (
    md.md_type === 'md_bold' ||
    md.md_type === 'md_italic' ||
    md.md_type === 'md_underline'
  ) {
    return {
      markdown_text: md.md_type.replace(/^md_/, ''),
      [`${md.md_type.replace(/^md_/, '')}`]: {
        value: convertToMDText(md.value),
      },
    };
  } else if (md.md_type === 'md_normal') {
    return {
      markdown_text: 'normal_text',
      normal_text: md.value,
    };
  } else if (md.md_type === 'md_newline') {
    return {
      markdown_text: 'newline',
      normal_text: md.value,
    };
  } else if (md.md_type == 'md_hyperlink') {
    return {
      markdown_text: md.md_type.replace(/^md_/, ''),
      hyperlink: {
        link: md.link,
        value: convertToMDText(md.value),
      },
    };
  } else if (md.md_type == 'md_ordred_list') {
    return {
      markdown_text: 'ordered_list',
      ordered_list: {
        list: md.list.map((l: any) => convertToMDText(l)),
      },
    };
  } else if (md.md_type == 'md_unordred_list') {
    return {
      markdown_text: 'unordered_list',
      unordered_list: {
        list: md.list.map((l: any) => convertToMDText(l)),
      },
    };
  } else if (md.md_type === 'md_mention') {
    return {
      markdown_text: 'bold',
      bold: {
        value: {
          markdown_text: 'normal_text',
          normal_text: `@${md.value.name}`,
        },
      },
    };
  } else if(md.md_type == 'md_path_expr'){
    return {
      markdown_text: 'normal_text',
      normal_text: "",
    };
  } else return updateLhsRhs(md);
};

const updateLhsRhs = (obj: any) => {
  if (obj.md_type != 'md_join') {
    obj = { ...convertToMDText(obj) };
  } else {
    obj = {
      join: { lhs: convertToMDText(obj.lhs), rhs: updateLhsRhs(obj.rhs) },
      markdown_text: 'join',
    };
  }

  return obj;
};

export const convertToSingleStringforTryme = (
  obj: any,
  output: string
): string => {
  if (obj.markdown_text != 'join') {
    return converttostring(obj, output);
  } else {
    return (
      converttostring(obj.join.lhs, output) +
      converttostring(obj.join.rhs, output)
    );
  }
};

const converttostring = (md: any, output: string): string => {
  if (typeof md == 'string') {
    return output.concat(md);
  } else if (md.markdown_text == 'normal_text') {
    return output.concat(md.normal_text);
  }
  return convertToSingleStringforTryme(md, output);
};
