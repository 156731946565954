/*eslint-disable*/
import Downshift from 'downshift';
import {
  EditorState,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntityRange,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import produce from 'immer';
import * as ramda from 'ramda';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Tooltip from 'react-tooltip-lite';

import { VideoLinkSimple } from '@/Videos/Videos';
import {
  AddIcon,
  CloseIcon,
  DownarrowWithCircle,
  DropdownWithCircle,
  InfoIcon,
  QaIconWithCircle,
  SlackIcon,
  SlackIconURI,
  TeamsIcon,
  TeamsIconURI,
} from '@/common/Icons/Icons';
import {
  NoTriggerVariable,
  WarningAlertIcon,
} from '@/common/Icons/Workflow.Icons';
import { Error } from '@/common/components/ErrorBoundary';
import { Loader } from '@/common/components/Loader';
import {
  Button,
  ButtonContainer,
  CancelButton,
  DraftInputWrapper,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
  DropdownWrapper,
  EmptyDiv,
  Header,
  HeaderLeft,
  HeaderRight,
  HoverInputContainer,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  LinkTag,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  Wrapper,
} from '@/common/styled/Workflow.Dumb';

import {
  automationAPIURLWithWorkspace,
  getJson,
  getProductId,
  slackIntegrationUrl,
  teamsIntegrationGetListUrl,
} from '@/common/utils/api-utils';
import { CancelButtonInput, seperateOptionalFields } from './ActionPanel';
import { SidePanelContext } from './Canvas';
import { ContextPopup, configApiCall } from './ContextPopup';
import { Editor, decorator } from './InputandVariable';
import {
  ContextPopupStateT,
  InputFieldT,
  NodeAddT,
  NodeEditT,
  NodeMode,
  NodeModes,
  NodeT,
  NodeView,
  PopupError,
  ResponseOutput,
  RightPanelStateContext,
  Tree,
  TreeT,
  emptyInputSchema,
  updateNode,
  ActionInitialState,
  NotificationResponseT,
  NotificationInitialState,
  AppsListT,
  ActionInputsListT,
  AuthType,
  dictionaryFieldT,
} from './types';
import {
  InsertContext,
  configKinds,
  converToEditorFormat,
  convertAsaSingleString,
  convertToServerFormat,
  convertToServerFormat2,
  emptyEditorState,
} from './utils';
import {
  SmallButton,
  Textarea,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { DropDown } from '@/Assistant/Common/Effective';
import { CanvasRightPanel } from './CanvasRightPanel';
import { TeamsWorkspaceT, WorkspaceT } from '@/Integrations/types';
import { CommonInputs } from './CommonInputs';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { AssistantT } from '@/Assistant/Construction/kind';
import {
  converterToServer,
  workflowAndDialogconverterToEditor,
} from '@/Assistant/Editor/Action';
import { MessageType } from './types';

const SendNotfiTip = () => (
  <>
    {/* <p>
      Use{' '}
      <a
        className="sidepanel_href_color"
        target="_blank"
        href="https://help.workativ.com/knowledgebase/managing-approvals/"
      >
        Approval
      </a>{' '}
      if you require permission or <i>approval</i> to perform task or actions in
      your workflow.
    </p>
    <p>
      E.g., If you want the bot to seek approval from manager to unlock a user
      account in okta.
    </p> */}
    <p>Dummy Content</p>
  </>
);

var IsValidURL = (url: string) =>
  url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );

const PopupHeader = (props: any) => {
  return (
    <>
      <Header>
        <HeaderLeft>
          Send notification
          {/* <Tooltip
            className="target customTip"
            zIndex={10000}
            arrowSize={8}
            tagName="span"
            content={<SendNotfiTip />}
            distance={5}
          >
            <InfoIcon />
          </Tooltip> */}
          <LinkTag
            href={'https://help.workativ.com/knowledgebase/managing-approvals/'}
            target="_blank"
          >
            <QaIconWithCircle />
          </LinkTag>
          {/* </Tooltip> */}
        </HeaderLeft>
        <HeaderRight>
          <VideoLinkSimple
            id={'CREATE_APPROVAL_APP_WORKFLOW'}
            text={'How to'}
          />
        </HeaderRight>
      </Header>
      <Paragraph>Send notification in chat channels.</Paragraph>
    </>
  );
};

const ButtonWrapper = (props: any) => {
  const { hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext)

  const isAssistant = getProductId() === 'ASSISTANT'

  return (
    <ButtonContainer className="footer_button_fixed">
      <div className="popup_footer_button_container">
        <Button primary props onClick={props.cancelAction}>
          <label>Cancel</label>
        </Button>
        {(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation) && (
          <Button className={props.className} onClick={props.onClick}>
            <label>Save</label>
          </Button>
        )}
      </div>
    </ButtonContainer>
  );
};

export type NotificationPanelStateT = {
  show: boolean;
  parentNode: TreeT<NodeT>;
  mode: NodeModes;
  currentNode: NotificationResponseT;
};

export type NotificationStateContextT = {
  notificationPanelState: NotificationPanelStateT;
  setNotificationPanelState: (object: any) => void;
  setContextPopupValues: (object: any) => void;
  contextPopupValues: ContextPopupStateT;
};

export const NotificationStateContext =
  createContext<NotificationStateContextT>({
    notificationPanelState: {
      show: false,
      mode: NodeAddT,
      parentNode: { value: Tree, children: [] },
      currentNode: NotificationInitialState,
    },
    setNotificationPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
      show: false,
      inputWithValues: [],
      currentInput: '',
      isMultiple: { path: '', parentKey: '' },
      collectionPicker: {
        isCollectionPicker: false,
        collectionType: emptyInputSchema,
        selectedCollection: {
          name: '',
          value: {
            $id: '',
            type: 'collection',
            item: {
              type: 'object',
              properties: {},
            },
            title: '',
            properties: {},
          },
        },
        app: ResponseOutput,
      },
    },
  });
const notificationType = [{ type: 'Send and forget', id: 'send-and-forget' }];

const channel = [
  { channel: 'Teams', id: 'teams', value: [{ icon: TeamsIconURI }] },
  { channel: 'Slack', id: 'slack', value: [{ icon: SlackIconURI }] },
  //   { channel: 'Widget', id: 'widget', value: [{ icon: SlackIconURI }] },
];
const messageType = [
  { type: 'Message', id: 'message' },
  { type: 'Menu option', id: 'option' },
  { type: 'Adaptive card', id: 'adaptive_card' },
];
const userTypes = [
  { type: 'All the Users', id: 'all' },
  { type: 'User', id: 'user' },
];
const SendNotificationPopup = (props: any) => {
  const NotificationElement = useRef<any>(null);

  const [loading, setLoading] = useState(false);

  const {
    notificationPanelState,
    setNotificationPanelState,
    contextPopupValues,
    setContextPopupValues,
  } = useContext(NotificationStateContext);

  const {
    rightPanelState,
    setRightPanelState,
    setEditTreeData,
    treeData,
    active_automation,
    saveTree,
    hasLinkedDialogs,
    automationMeta
  } = useContext(SidePanelContext);

  const workspaceName = props.match.params.workspacename;

  const [botsOutput, setBotsOutput] = useState<AssistantT[]>([]);
  const [teamsWorkspaces, setTeamsWorkspaces] = useState([]);
  const [slackWorkspaces, setSlackWorkspaces] = useState([]);

  const mode = notificationPanelState.mode;

  useEffect(() => {
    const currentMode = rightPanelState.mode;

    // compare props and update changes based on following condition
    if (
      !NotificationElement.current ||
      (NotificationElement.current &&
        (rightPanelState.mode.mode !== NotificationElement.current.mode.mode ||
          (currentMode.mode === NodeMode.Edit &&
            currentMode.nodeEditInput.id !==
              NotificationElement.current.mode.nodeEditInput.id)))
    ) {
      if (
        currentMode.mode === NodeMode.Add ||
        currentMode.mode === NodeMode.Edit
      ) {
        // next time to compare with previous props
        NotificationElement.current = rightPanelState;

        //show loader while changing modes (such as edit->add or add->edit)
        setLoading(true);

        // update current action panel context state for further child usage
        setNotificationPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as NotificationResponseT,
        });

        // call initial API everytime mode changed
        callApplistAPI({
          ...rightPanelState,
          currentNode:
            mode.mode == NodeMode.Edit
              ? ({
                  ...rightPanelState.currentNode,
                  subflowMetaData:
                    mode.nodeEditInput.meta_data &&
                    mode.nodeEditInput.meta_data.subflow
                      ? { subflow: mode.nodeEditInput.meta_data.subflow }
                      : { subflow: { hasSubFlow: false } },
                } as NotificationResponseT)
              : (rightPanelState.currentNode as NotificationResponseT),
        });
      } else {
        NotificationElement.current = rightPanelState;
        currentMode.mode === NodeMode.Loading
          ? setLoading(true)
          : setLoading(false);

        setNotificationPanelState({
          ...rightPanelState,
          currentNode: rightPanelState.currentNode as NotificationResponseT,
        });
      }
    } else if (
      rightPanelState.mode.mode === NodeMode.Edit &&
      NotificationElement.current.mode.mode === NodeMode.Edit &&
      rightPanelState.mode.nodeEditInput.id ===
        NotificationElement.current.mode.nodeEditInput.id
    ) {
      setNotificationPanelState({
        ...notificationPanelState,
        show: true,
      });
    }
  }, [rightPanelState.mode]);

  useEffect(() => {
    NotificationElement.current = notificationPanelState;

    // to see the details in left side panel while adding or editing
    setRightPanelState({
      ...rightPanelState,
      currentNode: notificationPanelState.currentNode,
    });
  }, [notificationPanelState.currentNode]);

  async function callApplistAPI(state: NotificationPanelStateT) {
    const workspaceName = props.match.params.workspacename;
    getAssistants(workspaceName)
      .then((botsOutput) => {
         getJson(slackIntegrationUrl(`/clients/${workspaceName}`)).then((slackWorkspaces)=>{
        // Promise.resolve([
        //   {
        //     workspace: 'manjulaworkspace',
        //     assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        //     name: 'Workativ bot',
        //     description: 'Workativ botfadfADFa',
        //     client_id: '1273945647696.1369712299237',
        //     client_secrect: '8fbdeeae036b3e71acc912bc014b4e1f',
        //     verification_token: 'ELH5tnGVFvGJ5EtnsNtwmuw6',
        //     slack_deploy: {
        //       mail_id: 'manjula.m@workativ.com',
        //       added_by: 'manjula.m',
        //       integrated_at: '2022-12-19T11:20:29.000Z',
        //       is_active: true,
        //       slack_workspace: 'SlackWorkativ',
        //       slack_id: 'T0181TTK1LG',
        //     },
        //   },
        //   {
        //     workspace: 'manjulaworkspace',
        //     assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        //     name: '',
        //     description: '',
        //     client_id: '2582793266338.2839876897653',
        //     client_secrect: 'b4c3b3b3ae8f49a796b903cba0db3c38',
        //     verification_token: 'Z1dS54xO7BRjcqE0i0JcJ9Ia',
        //     slack_deploy: {
        //       mail_id: 'manjula.m@workativ.com',
        //       added_by: 'manjula.m',
        //       integrated_at: '2022-11-11T11:04:49.000Z',
        //       is_active: true,
        //       slack_workspace: 'ManjulaSlackWorkspace',
        //       slack_id: 'T02H4PB7U9Y',
        //     },
        //   },
        //   {
        //     workspace: 'manjulaworkspace',
        //     assistance_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        //     name: 'Workativ Assistant',
        //     description: 'Workativ Assistant',
        //     client_id: '3596146069459.3596154452083',
        //     client_secrect: 'f9360a836652581883b273b508457c96',
        //     verification_token: 'LSEQtOh9fSrZHKEYc4mPmS9C',
        //     slack_deploy: {
        //       mail_id: 'manjula.m@workativ.com',
        //       added_by: 'Manjula Workativ',
        //       integrated_at: '2022-05-30T14:59:59.000Z',
        //       is_active: true,
        //       slack_workspace: 'Development',
        //       slack_id: 'T03HJ4A21DH',
        //     },
        //   },
        // ] as any).then((slackWorkspaces) => {
        //   Promise.resolve([
        //     {
        //       workspace: 'manjulaworkspace',
        //       bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        //       app_id: '3d99765c-d8e2-4080-bd15-cb7d36428fdf',
        //       app_password: 'e2Ge7JAsqHY._80Rl12542l~yq~_-vbw17',
        //       is_authorized: true,
        //       tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
        //       tenant_type: 'multi_tenant',
        //       name: '',
        //       description: '',
        //       teams_deploy: {
        //         team_name:
        //           'manjulaworkspace_3d99765c-d8e2-4080-bd15-cb7d36428fdf',
        //         team_id:
        //           'manjulaworkspace_3d99765c-d8e2-4080-bd15-cb7d36428fdf',
        //         is_active: true,
        //         date_added: '2022-11-30T09:39:32.000Z',
        //         added_by: 'Manjula Mani',
        //         email_id: 'manjula.m@workativ.com',
        //       },
        //       bot_data: {
        //         bot_name: 'TestOne_Manjula',
        //         bot_full_name: 'dx',
        //         bot_short_desc: 'a',
        //         bot_full_desc: 'dc',
        //         is_generated: true,
        //         is_active: true,
        //       },
        //       is_org_wide: true,
        //     },
        //     {
        //       workspace: 'manjulaworkspace',
        //       bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        //       app_id: '9bedb5cd-5e5e-47cd-b9af-9d08fd9cf2a0',
        //       app_password: 'KCK8Q~EKw0lE-OHQoOKIGU8k_82-7fc4DavQVbJW',
        //       is_authorized: true,
        //       tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
        //       tenant_type: 'multi_tenant',
        //       name: '',
        //       description: '',
        //       teams_deploy: {
        //         team_name:
        //           'manjulaworkspace_9bedb5cd-5e5e-47cd-b9af-9d08fd9cf2a0',
        //         team_id:
        //           'manjulaworkspace_9bedb5cd-5e5e-47cd-b9af-9d08fd9cf2a0',
        //         is_active: true,
        //         date_added: '2022-11-25T13:10:49.000Z',
        //         added_by: 'Manjula Mani',
        //         email_id: 'manjula.m@workativ.com',
        //       },
        //       bot_data: {
        //         bot_name: 'DevOne-Manjula',
        //         bot_full_name: 'slack test app',
        //         bot_short_desc: 'a',
        //         bot_full_desc: 'b',
        //         is_generated: true,
        //         is_active: true,
        //       },
        //       is_org_wide: true,
        //     },
        //     {
        //       workspace: 'manjulaworkspace',
        //       bot_id: '94c7cb6a-982d-4803-938f-ac0363b929cb',
        //       app_id: '58804caa-b5ff-46af-9ff8-9e56a0693158',
        //       app_password: 'gWV7Q~1TpM4CmnJtB9sk0xzik-rAZnE.eMyaP',
        //       is_authorized: true,
        //       tenant: 'fe8f0076-8dbf-4684-9f73-b389c33b2158',
        //       tenant_type: 'multi_tenant',
        //       name: 'Workativ Assistant',
        //       description: 'Your workplace assistant',
        //       teams_deploy: null,
        //       bot_data: {
        //         bot_name: 'BlockBot',
        //         bot_full_name: 'AutoBlock',
        //         bot_short_desc: 'teams auto block',
        //         bot_full_desc: 'teams block ',
        //         is_generated: true,
        //         is_active: false,
        //       },
        //       is_org_wide: false,
        //     },
        //   ] as any).then((teamsWorkspaces) => {
            getJson(teamsIntegrationGetListUrl(workspaceName, '/credentialList/')).then((teamsWorkspaces)=>{
            getJson(
              automationAPIURLWithWorkspace(workspaceName, '/actions')
            ).then((unfiltertedAppList: AppsListT) => {
              const actions = unfiltertedAppList.output;
              const actionsLens = ramda.lensProp('output');
              const data = ramda.set(
                actionsLens,
                actions,
                unfiltertedAppList
              ) as AppsListT;

              if (state.mode.mode === NodeMode.Edit) {
                const currentNode = state.currentNode as NotificationResponseT;

                const mode = state.mode as NodeEditT;

                const nextState = produce(currentNode, (draftState) => {
                  draftState.appsList = data;
                });
                const triggerNodeOutput = treeData.tree.value;

                const parentNode =
                  triggerNodeOutput.kind === 'AppTrigger' ||
                  triggerNodeOutput.kind === 'EventTrigger'
                    ? ({
                        ...state.parentNode,
                        value: {
                          ...state.parentNode.value,
                          path: state.parentNode.value.path
                            ? [
                                {
                                  actionId:
                                    triggerNodeOutput.meta_data.action_id,
                                  nodeId: triggerNodeOutput.id,
                                },
                              ].concat(state.parentNode.value.path)
                            : [
                                {
                                  actionId:
                                    triggerNodeOutput.meta_data.action_id,
                                  nodeId: triggerNodeOutput.id,
                                },
                              ],
                        },
                      } as TreeT<NodeT>)
                    : state.parentNode;

                configApiCall(
                  data,
                  parentNode,
                  rightPanelState.mode.mode === NodeMode.Edit,
                  workspaceName,
                  currentNode.addingInsideForEach
                ).then((configResponse: ActionInputsListT[]) => {
                  const mode = state.mode as NodeEditT;

                  let combiningResponseWithTriggerOutput: any = [];

                  if (
                    triggerNodeOutput.kind !== 'AppTrigger' &&
                    triggerNodeOutput.kind !== 'EventTrigger'
                  ) {
                    const triggerOutputResponse: ActionInputsListT[] = [
                      {
                        type: 'success',
                        output: {
                          name: triggerNodeOutput.name,
                          input: [],
                          app: {
                            appName: triggerNodeOutput.name,
                            action: triggerNodeOutput.kind,
                            pathId: {
                              nodeId: triggerNodeOutput.id,
                              actionId: '',
                            },
                            appId: '',
                            createdDate: '',
                            type: 'trigger',
                            file: '',
                            authentication_type: AuthType.None,
                            id: '',
                            scope: '',
                            icon: NoTriggerVariable,
                            latest: false,
                            version: '',
                          },
                          output: triggerNodeOutput.output,
                        },
                      },
                    ];
                    combiningResponseWithTriggerOutput =
                      triggerOutputResponse.concat(configResponse);
                  } else {
                    combiningResponseWithTriggerOutput = configResponse;
                  }
                  const inputs = mode.nodeEditInput.input as any;
                  const inps =
                    (mode.nodeEditInput as any).notification_input_type ===
                      'message' ||
                    (mode.nodeEditInput as any).notification_input_type ===
                      'option'
                      ? inputs.slice(0, 1).map((x: any) => {
                          const { value } = x;
                          return {
                            ...x,
                            value: value
                              ? EditorState.createWithContent(
                                  convertFromRaw(
                                    workflowAndDialogconverterToEditor(
                                      value,
                                      combiningResponseWithTriggerOutput,
                                      triggerNodeOutput,
                                      x,
                                      {
                                        inputs: inputs as InputFieldT[],
                                        contextPopupValues,
                                        setContextPopupValues,
                                      }
                                    ) as any
                                  ),
                                  decorator
                                )
                              : emptyEditorState,
                          };
                        })
                      : [
                          {
                            config: { kind: 'text' },
                            description: '',
                            displayTitle: 'Message to user',
                            inputHasValue: true,
                            key: 'message',
                            required: true,
                            value: emptyEditorState,
                          },
                        ];

                  const user =
                    (mode.nodeEditInput as any).notification_recipient.type ===
                    'user'
                      ? (converToEditorFormat(
                          (mode.nodeEditInput as any).notification_recipient
                            .user_email as string,
                          combiningResponseWithTriggerOutput,
                          triggerNodeOutput,
                          {
                            config: { kind: 'text' },
                            description: '',
                            displayTitle: '',
                            key: 'user',
                            required: true,
                            value: (mode.nodeEditInput as any)
                              .notification_recipient.user_email,
                          },
                          {
                            inputs: [] as InputFieldT[],
                            contextPopupValues,
                            setContextPopupValues,
                          }
                        ) as EditorState)
                      : emptyEditorState;

                  const notification_title = converToEditorFormat(
                    (mode.nodeEditInput as any).notification_title as string,
                    combiningResponseWithTriggerOutput,
                    triggerNodeOutput,
                    {
                      config: { kind: 'text' },
                      description: '',
                      displayTitle: '',
                      key: 'notification_title',
                      required: true,
                      value: (mode.nodeEditInput as any).notification_title,
                    },
                    {
                      inputs: [] as InputFieldT[],
                      contextPopupValues,
                      setContextPopupValues,
                    }
                  ) as EditorState;

                  const isAdaptiveCard =
                    (mode.nodeEditInput as any).notification_input_type ==
                    'adaptive_card';
                  const node = mode.nodeEditInput as any;

                  const addedContextValue = produce(state, (draft) => {
                    const currentChannel = channel.filter(
                      (b) =>
                        b.id === (mode.nodeEditInput as any).chat_channel_type
                    )[0];

                    const workspaces =
                      currentChannel.id === 'slack'
                        ? slackWorkspaces
                        : teamsWorkspaces;

                    mode.nodeOutputConfig = combiningResponseWithTriggerOutput;
                    draft.mode = mode;
                    draft.currentNode = {
                      ...nextState,
                      actionInputsList: { output: { input: inps } },
                      options:
                        node.notification_input_type === 'option'
                          ? inputs.slice(1, 2)[0].value.map((x: any) => {
                              return {
                                key: x.label.value,
                                value: x.value
                                  ? EditorState.createWithContent(
                                      convertFromRaw(
                                        workflowAndDialogconverterToEditor(
                                          x.value,
                                          combiningResponseWithTriggerOutput,
                                          triggerNodeOutput,
                                          inputs.slice(1, 2)[0],
                                          {
                                            inputs: inputs as InputFieldT[],
                                            contextPopupValues,
                                            setContextPopupValues,
                                          }
                                        ) as any
                                      ),
                                      decorator
                                    )
                                  : emptyEditorState,
                              };
                            })
                          : [{ key: '', value: emptyEditorState }],
                      adaptivecard: {
                        title: isAdaptiveCard ? inputs[0].value : '',
                        description: isAdaptiveCard
                          ? EditorState.createWithContent(
                              convertFromRaw(
                                workflowAndDialogconverterToEditor(
                                  inputs[1].value,
                                  combiningResponseWithTriggerOutput,
                                  triggerNodeOutput,
                                  inputs[1],
                                  {
                                    inputs: inputs as InputFieldT[],
                                    contextPopupValues,
                                    setContextPopupValues,
                                  }
                                ) as any
                              ),
                              decorator
                            )
                          : emptyEditorState,
                        url: isAdaptiveCard ? inputs[2].value : '',
                        fields: isAdaptiveCard
                          ? inputs.slice(3, 4)[0].value.map((x: any) => {
                              return {
                                key: x.label.value,
                                value: x.value
                                  ? EditorState.createWithContent(
                                      convertFromRaw(
                                        workflowAndDialogconverterToEditor(
                                          x.value,
                                          combiningResponseWithTriggerOutput,
                                          triggerNodeOutput,
                                          inputs.slice(2, 3)[0],
                                          {
                                            inputs: inputs as InputFieldT[],
                                            contextPopupValues,
                                            setContextPopupValues,
                                          }
                                        ) as any
                                      ),
                                      decorator
                                    )
                                  : emptyEditorState,
                              };
                            })
                          : [{ key: '', value: emptyEditorState }],
                        buttons: isAdaptiveCard
                          ? inputs.slice(4, 5)[0].value.map((x: any) => {
                              return {
                                key: x.label.value,
                                value: x.value
                                  ? 
                                  converToEditorFormat(
                                          x.value,
                                          combiningResponseWithTriggerOutput,
                                          triggerNodeOutput,
                                          inputs.slice(3, 4)[0],
                                          {
                                            inputs: inputs as InputFieldT[],
                                            contextPopupValues,
                                            setContextPopupValues,
                                          }
                                        ) as any
                                     
                                    
                                  : emptyEditorState,
                              };
                            })
                          : [{ key: '', value: emptyEditorState }],
                      },
                      user: user,
                      notification_title: notification_title,
                      message_type: messageType.filter(
                        (m) => m.id === node.notification_input_type
                      )[0],
                      notification_type: notificationType[0],
                      selectedBot: botsOutput
                        ? botsOutput.filter(
                            (b) => b.id === (mode.nodeEditInput as any).bot_id
                          )[0]
                        : null,
                      selectedChatChannel: currentChannel,
                      user_type: userTypes.filter(
                        (u) =>
                          u.id ===
                          ((mode.nodeEditInput as any).notification_recipient
                            .type == 'broadcast'
                            ? 'all'
                            : 'user')
                      )[0],
                      selectedWorkspaceChannel: workspaces
                        .filter(
                          (s: any) =>
                            s[
                              `${
                                currentChannel.id === 'teams'
                                  ? 'teams_deploy'
                                  : 'slack_deploy'
                              }`
                            ]
                        )
                        .map(
                          (s: any) =>
                            s[
                              `${
                                currentChannel.id === 'teams'
                                  ? 'teams_deploy'
                                  : 'slack_deploy'
                              }`
                            ]
                        )
                        .filter((s: any) => {
                          return (
                            s['id'] === (mode.nodeEditInput as any).channel_id
                          );
                        })[0],
                    };
                  });
                  setNotificationPanelState({
                    ...addedContextValue,
                  });
                  setLoading(false);
                });
              } else {
                const currentNode = state.currentNode as NotificationResponseT;

                const nextState = produce(currentNode, (draftState) => {
                  draftState.appsList = {
                    ...data,
                  };
                });

                setNotificationPanelState({
                  ...state,
                  currentNode: nextState,
                });

                setLoading(false);
              }
              setBotsOutput(botsOutput);
              setTeamsWorkspaces(teamsWorkspaces);
              setSlackWorkspaces(slackWorkspaces);
            });
          });
        });
      })
      .catch((err) => {
        setLoading(false);

        setNotificationPanelState({
          ...state,
          mode: {
            ...PopupError,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br /> Please try again afer sometime.',
          },
        });
      });
  }

  function updateInputValuesWithContext(props: {
    path: string;
    value: string;
    valuePath: string[];
    icon: string;
    examples: string;
  }) {
    const { path, value, valuePath, icon, examples } = props;

    if (contextPopupValues.currentInput === 'user') {
      const editorState = currentNode.user as any;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.user = InsertContext(
          value,
          {
            dataType: 'string',
            path: valuePath,
            icon: icon,
            variable: value,
            id: path,
            examples,
          },
          editorState
        ) as any;
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    } else if (contextPopupValues.currentInput === 'notification_title') {
      const editorState = currentNode.notification_title as any;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.notification_title = InsertContext(
          value,
          {
            dataType: 'string',
            path: valuePath,
            icon: icon,
            variable: value,
            id: path,
            examples,
          },
          editorState
        ) as any;
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    } else if (contextPopupValues.currentInput.includes('Option')) {
      const currentIndex = contextPopupValues.currentInput.split('Option')[1];
      const editorState = currentNode.options[currentIndex]
        .value as EditorState;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.options[currentIndex] = {
          ...draftState.options[currentIndex],
          value: InsertContext(
            value,
            {
              dataType: 'string',
              path: valuePath,
              icon: icon,
              variable: value,
              id: path,
              examples,
            },
            editorState
          ) as any,
        };
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    } else if (
      contextPopupValues.currentInput.includes('Field') ||
      contextPopupValues.currentInput.includes('Button')
    ) {
      const currentKey = contextPopupValues.currentInput.includes('Field')
        ? 'fields'
        : 'buttons';

      const currentIndex = contextPopupValues.currentInput.split(
        contextPopupValues.currentInput.includes('Field') ? 'Field' : 'Button'
      )[1];
      const editorState = currentNode.adaptivecard[currentKey][currentIndex]
        .value as EditorState;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.adaptivecard[currentKey][currentIndex] = {
          ...draftState.adaptivecard[currentKey][currentIndex],
          value: InsertContext(
            value,
            {
              dataType: 'string',
              path: valuePath,
              icon: icon,
              variable: value,
              id: path,
              examples,
            },
            editorState
          ) as any,
        };
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    }else if(contextPopupValues.currentInput==='adaptive_description'){
      const editorState = currentNode.adaptivecard.description as EditorState;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.adaptivecard.description =  InsertContext(
            value,
            {
              dataType: 'string',
              path: valuePath,
              icon: icon,
              variable: value,
              id: path,
              examples,
            },
            editorState
          ) as any
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    } else {
      const textInputvalue = currentNode.actionInputsList.output.input.filter(
        (x) => x.key === contextPopupValues.currentInput
      )[0];

      const config = textInputvalue.config;

      const editorState = textInputvalue.value as any;

      const nextValue = produce(currentNode, (draftState) => {
        draftState.actionInputsList.output.input =
          draftState.actionInputsList.output.input.map(
            (inputs: InputFieldT) => {
              if (inputs.key === contextPopupValues.currentInput) {
                return {
                  ...inputs,
                  inputHasValue: true,
                  value: InsertContext(
                    value,
                    {
                      dataType: 'string',
                      path: valuePath,
                      icon: icon,
                      variable: value,
                      id: path,
                      examples,
                    },
                    editorState
                  ) as any,
                };
              }
              return inputs;
            }
          );
      });
      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: nextValue,
      });
    }
  }

  const replaceBetween2 = (
    str: string,
    start: number,
    end: number,
    what: string,
    isLast: boolean
  ) => {
    const replaced = isLast
      ? str.substring(0, start) + what + str.substring(end)
      : str.substring(0, start) + what + str.substring(end);
    // console.log('replace between', str, start, end, isLast, str.substring(0, start), 'what', str.substring(end))
    // console.log('replaced', replaced)
    return replaced;
  };

  function returnModifiedVariable(
    inpValue: RawDraftContentState,
    text: any,
    old: any
  ) {
    let omittedMap = {};

    // items not needed to edit in omitted map
    const newMap = Object.entries(inpValue.entityMap).reduce(
      (acc: any, curr: any) => {
        // console.log(curr, 'curr')
        if (
          (curr[1].data.variable
            ? curr[1].data.variable
            : curr[1].data.value) != old.title
        ) {
          const { [`${curr[0]}`]: omit, ...rest } = acc;
          omittedMap[`${curr[0]}`] = omit;
          return rest;
        }
        curr[1].data.path[curr[1].data.path.length - 1] = text.title;

        acc[`${curr[0]}`] = curr[1].data.variable
          ? {
              ...curr[1],
              data: { ...curr[1].data, variable: text.title },
            }
          : {
              ...curr[1],
              data: {
                ...curr[1].data,
                value: text.title,
                visible: text.title,
              },
            };
        return acc;
      },
      inpValue.entityMap
    );

    const minus = text.title.length < old.title.length;

    const newLength = minus
      ? old.title.length - text.title.length
      : text.title.length - old.title.length;
    let itemsProcessed = 0;
    const newBlocks = inpValue.blocks.map((b) => {
      const filtered = b.entityRanges.filter((e) => {
        return Object.keys(newMap).indexOf(e.key.toString()) != -1;
      });

      // console.log(filtered, 'filtered', newMap, 'b.entityRanges', b.entityRanges, value, text, old)
      return {
        ...b,
        text: filtered.reduce((acc, curr, i) => {
          if (i == 0) {
            const start =
              curr.offset - (old.title.length - text.title.length) * i;
            return replaceBetween2(
              acc,
              curr.offset,
              start + old.title.length,
              text.title,
              filtered.length == i + 1
            );
          } else {
            const start =
              curr.offset - (old.title.length - text.title.length) * i;
            return replaceBetween2(
              acc,
              start,
              start + old.title.length,
              text.title,
              filtered.length == i + 1
            );

            // return acc.substr(curr.offset - text.title.length, text.title.length)
          }
        }, b.text),
        entityRanges: b.entityRanges
          .reduce((acc: any, curr: any, i: number) => {
            if (
              Object.keys(newMap)
                .map((x) => parseInt(x))
                .some((x) => curr.key >= x)
            ) {
              if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                const newOffsetDifference =
                  itemsProcessed *
                  (Object.keys(newMap).length > 0
                    ? old.title.length - text.title.length
                    : 0);
                // console.log( "entity itemsProcessed is not::" ,itemsProcessed,newOffsetDifference,i == 0 ? curr.offset : curr.offset - newOffsetDifference)
                itemsProcessed = itemsProcessed + 1;
                return acc.concat({
                  ...curr,
                  offset:
                    i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                  length: curr.length - (old.title.length - text.title.length),
                });
              } else {
                const newOffsetDifference =
                  itemsProcessed *
                  (Object.keys(newMap).length > 0
                    ? old.title.length - text.title.length
                    : 0);
                // console.log( "entity itemsProcessed is::" ,itemsProcessed,newOffsetDifference,i == 0 ? curr.offset : curr.offset - newOffsetDifference)
                return acc.concat({
                  ...curr,
                  offset:
                    i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                  length: curr.length,
                });
              }
            }

            return acc.concat(curr);
          }, [])
          .filter((x: any) => x.length > 0),
      };
    });

    return { omittedMap, newBlocks, newMap };
  }

  async function updateVariableFromInput(text: any, old: any, cb: Function) {
    const inputHasValues = currentNode.actionInputsList.output.input
      .map((inp, i) => {
        const value = inp.value as EditorState;

        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(old.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const optionsHasValues = currentNode.options
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(old.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const adaptiveFieldsHasValues = currentNode.adaptivecard.fields
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(old.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const adaptiveButtonsHasValues = currentNode.adaptivecard.buttons
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(old.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const notificationTitleHasValues = convertAsaSingleString(
      convertToRaw(
        (currentNode.notification_title as EditorState).getCurrentContent()
      ).blocks
    ).includes(old.title);

    if (
      inputHasValues.length > 0 ||
      optionsHasValues.length > 0 ||
      adaptiveFieldsHasValues.length > 0 ||
      adaptiveButtonsHasValues.length > 0 ||
      notificationTitleHasValues
    ) {
      await cb('edit');

      const newValue = produce(currentNode, (draft) => {
        if (notificationTitleHasValues) {
          const inpValue = convertToRaw(
            (draft.notification_title as EditorState).getCurrentContent()
          );

          const { newBlocks, newMap, omittedMap } = returnModifiedVariable(
            inpValue,
            text,
            old
          );
          draft.notification_title = EditorState.createWithContent(
            convertFromRaw({
              blocks: newBlocks,
              entityMap: { ...omittedMap, ...newMap },
            }),
            decorator
          );
        }
        if (inputHasValues.length > 0) {
          draft.actionInputsList.output.input =
            draft.actionInputsList.output.input.map((inp) => {
              const value = inp.value as EditorState;
              const hasValue = convertAsaSingleString(
                convertToRaw(value.getCurrentContent()).blocks
              ).includes(old.title);
              if (
                typeof value != 'string' &&
                Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                  .length > 0 &&
                hasValue
              ) {
                const inpValue = convertToRaw(value.getCurrentContent());

                const { newBlocks, newMap, omittedMap } =
                  returnModifiedVariable(inpValue, text, old);

                return {
                  ...inp,
                  value: EditorState.createWithContent(
                    convertFromRaw({
                      blocks: newBlocks,
                      entityMap: { ...omittedMap, ...newMap },
                    }),
                    decorator
                  ),
                } as any;
              }
              return inp as any;
            });
        }

        if (
          currentNode.message_type?.id == 'option' &&
          optionsHasValues.length > 0
        ) {
          draft.options = draft.options.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(old.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap, omittedMap } = returnModifiedVariable(
                inpValue,
                text,
                old
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: { ...omittedMap, ...newMap },
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
        }

        if (
          currentNode.message_type?.id == 'adaptive_card' &&
          (adaptiveFieldsHasValues.length > 0 ||
            adaptiveButtonsHasValues.length > 0)
        ) {
          draft.adaptivecard.fields = draft.adaptivecard.fields.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(old.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap, omittedMap } = returnModifiedVariable(
                inpValue,
                text,
                old
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: { ...omittedMap, ...newMap },
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
          draft.adaptivecard.buttons = draft.adaptivecard.buttons.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(old.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap, omittedMap } = returnModifiedVariable(
                inpValue,
                text,
                old
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: { ...omittedMap, ...newMap },
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
        }
      });

      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: newValue,
      });
    } else {
      await cb('no-edit');
    }
  }

  function returnRemovedVariable(inpValue: RawDraftContentState, text: any) {
    const newMap = Object.entries(inpValue.entityMap).reduce(
      (acc: any, curr: any) => {
        if (
          (curr[1].data.variable
            ? curr[1].data.variable
            : curr[1].data.value) == text.title
        ) {
          const { [`${curr[0]}`]: omit, ...rest } = acc;
          return rest;
        }
        return acc;
      },
      inpValue.entityMap
    );

    const newBlocks = inpValue.blocks.map((b) => {
      const filtered = b.entityRanges.filter((e) => {
        return Object.keys(newMap).indexOf(e.key.toString()) == -1;
      });

      return {
        ...b,
        text: b.entityRanges
          .filter((e) => {
            return Object.keys(newMap).indexOf(e.key.toString()) == -1;
          })
          .reduce((acc, curr, i) => {
            if (i == 0) {
              return replaceBetween2(
                acc,
                curr.offset,
                curr.offset + text.title.length,
                '',
                curr.offset + text.title.length >= acc
              );
            } else {
              const start = curr.offset - text.title.length * i;
              return replaceBetween2(
                acc,
                start,
                start + text.title.length,
                '',
                filtered.length == i + 1
              );

              // return acc.substr(curr.offset - text.title.length, text.title.length)
            }
          }, b.text),
        entityRanges: b.entityRanges
          .reduce((acc, current, i: number) => {
            if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
              const newOffsetDifference =
                acc.filter((x) => x.length === 0).length *
                (Object.keys(newMap).length > 0 ? text.title.length : 0);
              // console.log('newOffsetDifference', newOffsetDifference, newMap.length, newMap)
              return [
                ...acc,
                {
                  ...current,
                  offset:
                    i == 0
                      ? current.offset
                      : current.offset - newOffsetDifference,
                },
              ];
            } else {
              return [
                ...acc,
                {
                  ...current,
                  // offset: i == 0 ? current.offset : acc[i - 1].offset,
                  length: 0,
                },
              ];
            }
            return [...acc, current];
          }, [] as RawDraftEntityRange[])

          .filter((e) => e.length !== 0),
        // .map((r, i: number) => {
        //     return { ...r, offset: i == 0 ? r.offset : r.offset - text.title.length }
        // })
      };
    });

    return { newMap, newBlocks };
  }

  async function removeVariableFromInput(text: any, cb: Function) {
    const inputHasValues = currentNode.actionInputsList.output.input
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(text.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const optionsHasValues = currentNode.options
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(text.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const adaptiveFieldsHasValues = currentNode.adaptivecard.fields
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(text.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const adaptiveButtonsHasValues = currentNode.adaptivecard.buttons
      .map((inp, i) => {
        const value = inp.value as EditorState;
        return value &&
          typeof value != 'string' &&
          convertAsaSingleString(
            convertToRaw(value.getCurrentContent()).blocks
          ).includes(text.title)
          ? i
          : null;
      })
      .filter((inp) => inp != null);

    const notificationTitleHasValues = convertAsaSingleString(
      convertToRaw(
        (currentNode.notification_title as EditorState).getCurrentContent()
      ).blocks
    ).includes(text.title);

    if (
      inputHasValues.length > 0 ||
      optionsHasValues.length > 0 ||
      adaptiveFieldsHasValues.length > 0 ||
      adaptiveButtonsHasValues.length > 0 ||
      notificationTitleHasValues
    ) {
      await cb('delete');
      const newValue = produce(currentNode, (draft) => {
        if (notificationTitleHasValues) {
          const inpValue = convertToRaw(
            (draft.notification_title as EditorState).getCurrentContent()
          );

          const { newBlocks, newMap } = returnRemovedVariable(inpValue, text);
          draft.notification_title = EditorState.createWithContent(
            convertFromRaw({
              blocks: newBlocks,
              entityMap: newMap,
            }),
            decorator
          );
        }
        if (inputHasValues.length > 0) {
          draft.actionInputsList.output.input =
            draft.actionInputsList.output.input.map((inp) => {
              const value = inp.value as EditorState;
              const hasValue = convertAsaSingleString(
                convertToRaw(value.getCurrentContent()).blocks
              ).includes(text.title);
              if (
                typeof value != 'string' &&
                Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                  .length > 0 &&
                hasValue
              ) {
                const inpValue = convertToRaw(value.getCurrentContent());

                const { newBlocks, newMap } = returnRemovedVariable(
                  inpValue,
                  text
                );

                return {
                  ...inp,
                  value: EditorState.createWithContent(
                    convertFromRaw({
                      blocks: newBlocks,
                      entityMap: newMap,
                    }),
                    decorator
                  ),
                } as any;
              }
              return inp as any;
            });
        }
        if (
          currentNode.message_type?.id == 'option' &&
          optionsHasValues.length > 0
        ) {
          draft.options = draft.options.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(text.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap } = returnRemovedVariable(
                inpValue,
                text
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: newMap,
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
        }

        if (
          currentNode.message_type?.id == 'adaptive_card' &&
          (adaptiveFieldsHasValues.length > 0 ||
            adaptiveButtonsHasValues.length > 0)
        ) {
          draft.adaptivecard.fields = draft.adaptivecard.fields.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(text.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap } = returnRemovedVariable(
                inpValue,
                text
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: newMap,
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
          draft.adaptivecard.buttons = draft.adaptivecard.buttons.map((inp) => {
            const value = inp.value as EditorState;
            const hasValue = convertAsaSingleString(
              convertToRaw(value.getCurrentContent()).blocks
            ).includes(text.title);
            if (
              typeof value != 'string' &&
              Object.keys(convertToRaw(value.getCurrentContent()).entityMap)
                .length > 0 &&
              hasValue
            ) {
              const inpValue = convertToRaw(value.getCurrentContent());

              const { newBlocks, newMap } = returnRemovedVariable(
                inpValue,
                text
              );

              return {
                ...inp,
                value: EditorState.createWithContent(
                  convertFromRaw({
                    blocks: newBlocks,
                    entityMap: newMap,
                  }),
                  decorator
                ),
              } as any;
            }
            return inp as any;
          });
        }
      });

      setNotificationPanelState({
        ...notificationPanelState,
        currentNode: newValue,
      });
    } else {
      await cb('delete');
    }
  }

  function saveNode() {
    const updatedNodeValue = produce(
      notificationPanelState.currentNode,
      (draftState) => {
        // draftState.actionInputsList.output.input =
        //   currentNode.actionInputsList.output.input.map((y) => {
        //     const { inputHasValue, value, ...rest } = y;

        //     const convertedValue = value
        //       ? typeof value == 'string' || Array.isArray(value)
        //         ? value
        //         : convertAsaSingleString(
        //             convertToRaw(value.getCurrentContent()).blocks
        //           )
        //       : '';
        //     if (!value || convertedValue.length === 0) {
        //       return {
        //         ...rest,
        //       } as InputFieldT;
        //     }

        //     const convertedBlocks =
        //       typeof value == 'string' || Array.isArray(value)
        //         ? value
        //         : convertToRaw(value.getCurrentContent())
        //             .blocks.map((b) =>
        //               convertToServerFormat2(
        //                 convertToRaw(value.getCurrentContent()).entityMap,
        //                 b
        //               )
        //             )
        //             .reduce(
        //               (acc, ele, i) =>
        //                 i == 0 ? (acc += ele) : (acc += '\n' + ele),
        //               ''
        //             );

        //     return {
        //       ...rest,
        //       value: convertedBlocks,
        //     } as InputFieldT;
        //   });

        draftState.actionInputsList.output.input =
          currentNode.actionInputsList.output.input.map((y) => {
            return {
              ...y,
              value: converterToServer(
                convertToRaw(
                  (y.value as EditorState).getCurrentContent()
                ) as any,
                true
              ),
            } as any;
          });

        draftState.user = convertToRaw(
          (draftState.user as EditorState).getCurrentContent()
        )
          .blocks.map((b) =>
            convertToServerFormat2(
              convertToRaw((draftState.user as EditorState).getCurrentContent())
                .entityMap,
              b
            )
          )
          .reduce(
            (acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)),
            ''
          );

        draftState.notification_title = convertToRaw(
          (draftState.notification_title as EditorState).getCurrentContent()
        )
          .blocks.map((b) =>
            convertToServerFormat2(
              convertToRaw(
                (
                  draftState.notification_title as EditorState
                ).getCurrentContent()
              ).entityMap,
              b
            )
          )
          .reduce(
            (acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)),
            ''
          );

        if (currentNode.message_type?.id === 'option') {
          draftState.actionInputsList.output.input = [
            ...draftState.actionInputsList.output.input,
            {
              config: {
                kind: 'option',
              },
              description: '',
              displayTitle: 'Message to user',
              inputHasValue: true,
              key: 'option',
              required: true,
              value: currentNode.options.map((op) => ({
                label: { type: 'text', value: op.key },
                value: converterToServer(
                  convertToRaw(
                    (op.value as EditorState).getCurrentContent()
                  ) as any,
                  true
                ),
              })) as any,
            },
          ];
        } else if (currentNode.message_type?.id === 'adaptive_card') {
          draftState.actionInputsList.output.input = [
            {
              config: {
                kind: 'text',
              },
              description: '',
              displayTitle: 'title',
              inputHasValue: true,
              key: 'title',
              required: true,
              value: currentNode.adaptivecard.title,
            },
            {
              config: {
                kind: 'text',
              },
              description: '',
              displayTitle: 'description',
              inputHasValue: true,
              key: 'description',
              required: true,
              value: converterToServer(
                convertToRaw(
                  (
                    currentNode.adaptivecard.description as EditorState
                  ).getCurrentContent()
                ) as any,
                true
              ) as any,
            },
            {
              config: {
                kind: 'text',
              },
              description: '',
              displayTitle: 'Logo',
              inputHasValue: true,
              key: 'logo',
              required: true,
              value: currentNode.adaptivecard.url,
            },
            {
              config: {
                kind: 'adaptive_card_field',
              },
              description: '',
              displayTitle: 'Fields',
              inputHasValue: true,
              key: 'fields',
              required: true,
              value: currentNode.adaptivecard.fields.map((op) => ({
                label: { type: 'text', value: op.key },
                value: converterToServer(
                  convertToRaw(
                    (op.value as EditorState).getCurrentContent()
                  ) as any,
                  true
                ),
              })) as any,
            },
            {
              config: {
                kind: 'adaptive_card_button',
              },
              description: '',
              displayTitle: 'Button',
              inputHasValue: true,
              key: 'buttons',
              required: true,
              value: currentNode.adaptivecard.buttons.map((op) => ({
                label: { type: 'text', value: op.key },
                value: convertToServerFormat(op.value as EditorState),
              })) as any,
            },
          ];
        }
      }
    );
    saveTree(updatedNodeValue);
  }

  function disableSave() {
    function hasValue(currentValue: any) {
      return currentValue.value !== undefined &&
      convertAsaSingleString(convertToRaw(
        currentValue.value.getCurrentContent()
      ).blocks as RawDraftContentBlock[])
          .length > 0 &&
        convertAsaSingleString(convertToRaw(
          currentValue.value.getCurrentContent()
        ).blocks as RawDraftContentBlock[]).replace(/\s/g, '').length
        ? true
        : false;
    }

    const basicInputs =
      currentNode.message_type == null ||
      currentNode.notification_type == null ||
      currentNode.selectedBot == null ||
      currentNode.selectedChatChannel == null ||
      currentNode.selectedWorkspaceChannel == null ||
      currentNode.user_type !== null && (currentNode.user_type as MessageType).id === 'user' && !hasValue({value:currentNode.user}) ||
      convertAsaSingleString(convertToRaw(
        (currentNode.notification_title as EditorState).getCurrentContent()
      ).blocks as RawDraftContentBlock[]).length == 0

    let messageHasNotvalue = false;
    let optionsHasNotValue = false;
    let adaptiveFieldHasNotValue = false;
    let adaptiveButtonHasNotValue = false;
    let adaptiveBaseInputs = false;
    if (currentNode.message_type?.id == 'message') {
      messageHasNotvalue =
        !currentNode.actionInputsList.output.input.every(hasValue);
    } else if (currentNode.message_type?.id == 'option') {
      messageHasNotvalue =
        !currentNode.actionInputsList.output.input.every(hasValue);
      optionsHasNotValue =
        currentNode.options.filter((op) => {
          return (
            (op.key.length == 0 && !hasValue(op)) ||
            (op.key.length > 0 && hasValue(op))
          );
        }).length === 0;
    } else if (currentNode.message_type?.id == 'adaptive_card') {
      adaptiveFieldHasNotValue =
        currentNode.adaptivecard.fields.filter((op) => {
          return (
            (op.key.length == 0 && !hasValue(op)) ||
            (op.key.length > 0 && hasValue(op))
          );
        }).length === 0;
      adaptiveButtonHasNotValue =
        currentNode.adaptivecard.buttons.filter((op) => {
          return (
            (op.key.length == 0 && !hasValue(op)) ||
            (op.key.length > 0 && hasValue(op))
          );
        }).length === 0;
      adaptiveBaseInputs =
        currentNode.adaptivecard.title.length === 0 ||
        !hasValue({ value: currentNode.adaptivecard.description }) ||
        (currentNode.adaptivecard.url &&
          IsValidURL(currentNode.adaptivecard.url) === null)
          ? true
          : false;
    }

    return (
      basicInputs ||
      messageHasNotvalue ||
      optionsHasNotValue ||
      adaptiveFieldHasNotValue ||
      adaptiveButtonHasNotValue ||
      adaptiveBaseInputs
    );
  }

  const resetRightPanel = () => {
    setRightPanelState({
      ...rightPanelState,
      currentNode: ActionInitialState,
      show: false,
      mode: NodeView,
      parentNode: { value: Tree, children: [] },
    });
    setEditTreeData(treeData);
  };

  const currentNode = notificationPanelState.currentNode;

  const currentChannel =
    currentNode.selectedChatChannel &&
    currentNode.selectedChatChannel.id === 'slack';

  const workspaces = currentChannel ? slackWorkspaces : teamsWorkspaces;

  if (loading) {
    return (
      <PopupWrapper>
        <PopupContainer>
          <div className="popup_inside_loader">
            <Loader.PopupLoader show={true} />
          </div>
        </PopupContainer>
      </PopupWrapper>
    );
  } else if (notificationPanelState.mode.mode === NodeMode.Error) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          <div className="flow_canvas_popup_error">
            <Error.AddorEdit
              info={
                notificationPanelState.mode.mode === NodeMode.Error
                  ? notificationPanelState.mode.error
                  : ''
              }
              onClick={() => {}}
              buttonName={'Okay'}
            />
          </div>
        </Wrapper>
      </div>
    );
  }
  function returnClass() {
    return currentNode.message_type == null ||
      currentNode.message_type.id == 'message'
      ? 'notification_message'
      : currentNode.message_type.id == 'option'
      ? 'notification_option'
      : 'notification_adaptive';
  }

  const isAssistant = getProductId() === 'ASSISTANT'

  return (
    <PopupWrapper className={`send_notification_popup ${returnClass()}`}>
      <PopupContainer>
        <PopupHeader />
        <PopupScrollHeight>
          <EmptyDiv>
            <InputWrapper>
              <div
                className="automation_condition_form"
                style={{ marginBottom: 10 }}
              >
                <div className="select_application">
                  <div className="select_work_form">
                    <CanvasRightPanel.AppsDropDown
                      inputs={{
                        inputs: notificationType,
                        key: 'type',
                        idKey: 'id',
                        showIcon: false,
                      }}
                      addDropDown
                      updateSelection={(
                        selectedOperatorValue: string,
                        id: string
                      ) => {
                        const updatedValue = produce(currentNode, (draft) => {
                          draft.notification_type = notificationType.filter(
                            (c) => c.id === id
                          )[0];
                        });

                        setNotificationPanelState({
                          ...notificationPanelState,
                          currentNode: updatedValue,
                        });
                      }}
                      render={(makeDropDownVisible: () => void) => (
                        <DropdownWrapper>
                          <Input
                            type="text"
                            value={
                              currentNode.notification_type &&
                              currentNode.notification_type.type
                                ? currentNode.notification_type.type
                                : 'Select'
                            }
                            onClick={makeDropDownVisible}
                            onChange={() => {}}
                            className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input':"without_caret_input editor_btn_disabled"}
                          />
                          <InputContent>Notification type</InputContent>

                          <CancelButtonInput
                            rotate
                            onClick={makeDropDownVisible}
                          >
                            <DownarrowWithCircle />
                          </CancelButtonInput>
                        </DropdownWrapper>
                      )}
                    />
                  </div>
                </div>
              </div>
              {currentNode.notification_type && (
                <div
                  className="automation_condition_form"
                  style={{ marginBottom: 20 }}
                >
                  <div className="select_application">
                    <div className="select_work_form">
                      <CanvasRightPanel.AppsDropDown
                        inputs={{
                          inputs: botsOutput
                            ? botsOutput.filter((b) => b.published_at != null)
                            : [],
                          key: 'name',
                          idKey: 'id',
                          showIcon: false,
                        }}
                        addDropDown
                        updateSelection={(
                          selectedOperatorValue: string,
                          id: string
                        ) => {
                          const updatedValue = produce(currentNode, (draft) => {
                            draft.selectedBot = botsOutput
                              ? botsOutput.filter((c) => c.id === id)[0]
                              : null;
                            draft.selectedWorkspaceChannel = null;
                            draft.selectedChatChannel = null;
                            draft.message_type = null;
                            draft.user_type = null;
                            draft.notification_title = emptyEditorState;
                          });

                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: updatedValue,
                          });
                        }}
                        render={(makeDropDownVisible: () => void) => (
                          <DropdownWrapper>
                            <Input
                              type="text"
                              value={
                                currentNode.selectedBot &&
                                currentNode.selectedBot.name
                                  ? currentNode.selectedBot.name
                                  : 'Select'
                              }
                              onClick={makeDropDownVisible}
                              onChange={() => {}}
                              className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input':"without_caret_input editor_btn_disabled"}
                            />
                            <InputContent>Select bot</InputContent>

                            <CancelButtonInput
                              rotate
                              onClick={makeDropDownVisible}
                            >
                              <DownarrowWithCircle />
                            </CancelButtonInput>
                          </DropdownWrapper>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentNode.selectedBot && (
                <div
                  className="automation_condition_form"
                  style={{ marginBottom: 20 }}
                >
                  <div className="select_application">
                    <div className="select_work_form">
                      <CanvasRightPanel.AppsDropDown
                        inputs={{
                          inputs: channel,
                          key: 'channel',
                          idKey: 'id',
                          showIcon: true,
                        }}
                        addDropDown
                        showNotConfigured={
                          (workspaces
                            ? currentChannel
                              ? workspaces
                                  .filter((s: WorkspaceT) => {
                                    return (
                                      currentNode.selectedBot?.id ===
                                      s.assistance_id
                                    );
                                  })
                                  .filter(
                                    (s: WorkspaceT) =>
                                      s.slack_deploy && s.slack_deploy.is_active
                                  )
                                  .map((s: WorkspaceT) => s.slack_deploy)
                              : workspaces
                                  .filter((s: TeamsWorkspaceT) => {
                                    return (
                                      currentNode.selectedBot?.id === s.bot_id
                                    );
                                  })
                                  .filter(
                                    (t: TeamsWorkspaceT) =>
                                      t.teams_deploy && t.teams_deploy.is_active
                                  )
                                  .map((t: TeamsWorkspaceT) => t.teams_deploy)
                            : []
                          ).length === 0
                        }
                        updateSelection={(
                          selectedOperatorValue: string,
                          id: string
                        ) => {
                          const updatedValue = produce(currentNode, (draft) => {
                            draft.selectedChatChannel = channel.filter(
                              (c) => c.id === id
                            )[0];
                            draft.selectedWorkspaceChannel = null;
                          });

                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: updatedValue,
                          });
                        }}
                        render={(makeDropDownVisible: () => void) => (
                          <React.Fragment>
                            <DropdownWrapper>
                              <Input
                                type="text"
                                value={
                                  currentNode.selectedChatChannel &&
                                  currentNode.selectedChatChannel.channel
                                    ? currentNode.selectedChatChannel.channel
                                    : 'Select'
                                }
                                onClick={makeDropDownVisible}
                                onChange={() => {}}
                                className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input':"without_caret_input editor_btn_disabled"}
                                style={
                                  currentNode.selectedChatChannel &&
                                  currentNode.selectedChatChannel.channel
                                    ? { paddingLeft: 28, paddingTop: 29 }
                                    : {}
                                }
                              />
                              <InputContent>Select chat channel</InputContent>

                              <CancelButtonInput
                                rotate
                                onClick={makeDropDownVisible}
                              >
                                <DownarrowWithCircle />
                              </CancelButtonInput>
                            </DropdownWrapper>
                            {currentNode.selectedChatChannel && (
                              <div className="input_img_left">
                                <img
                                  src={
                                    currentNode.selectedChatChannel.value[0]
                                      .icon
                                  }
                                />
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentNode.selectedChatChannel && (
                <div
                  className="automation_condition_form"
                  style={{ marginBottom: 20 }}
                >
                  <div className="select_application">
                    <div className="select_work_form send_notification">
                      <CanvasRightPanel.AppsDropDown
                        inputs={{
                          inputs: workspaces
                            ? currentChannel
                              ? workspaces
                                  .filter((s: WorkspaceT) => {
                                    return (
                                      currentNode.selectedBot?.id ===
                                      s.assistance_id
                                    );
                                  })
                                  .filter(
                                    (s: WorkspaceT) =>
                                      s.slack_deploy && s.slack_deploy.is_active
                                  )
                                  .map((s: WorkspaceT) => s.slack_deploy)
                              : workspaces
                                  .filter((s: TeamsWorkspaceT) => {
                                    return (
                                      currentNode.selectedBot?.id === s.bot_id
                                    );
                                  })
                                  .filter(
                                    (t: TeamsWorkspaceT) =>
                                      t.teams_deploy && t.teams_deploy.is_active
                                  )
                                  .map((t: TeamsWorkspaceT) => t.teams_deploy)
                            : [],
                          key: currentChannel ? 'slack_workspace' : 'team_name',
                          idKey: 'id',
                          showIcon: false,
                        }}
                        addDropDown
                        updateSelection={(
                          selectedOperatorValue: string,
                          id: string
                        ) => {
                          const updatedValue = produce(currentNode, (draft) => {
                            draft.selectedWorkspaceChannel = workspaces
                              ? workspaces
                                  .filter(
                                    (c: TeamsWorkspaceT | WorkspaceT) =>
                                      c[
                                        `${
                                          currentChannel
                                            ? 'slack_deploy'
                                            : 'teams_deploy'
                                        }`
                                      ]
                                  )
                                  .map(
                                    (c: TeamsWorkspaceT | WorkspaceT) =>
                                      c[
                                        `${
                                          currentChannel
                                            ? 'slack_deploy'
                                            : 'teams_deploy'
                                        }`
                                      ]
                                  )
                                  .filter((c: any) => c['id'] === id)[0]
                              : null;
                          });

                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: updatedValue,
                          });
                        }}
                        render={(makeDropDownVisible: () => void) => (
                          <DropdownWrapper>
                            <Input
                              type="text"
                              value={
                                currentNode.selectedWorkspaceChannel
                                  ? currentChannel
                                    ? (
                                        currentNode.selectedWorkspaceChannel as any
                                      ).slack_workspace
                                    : (
                                        currentNode.selectedWorkspaceChannel as any
                                      ).team_name
                                  : 'Select'
                              }
                              onClick={makeDropDownVisible}
                              onChange={() => {}}
                              className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input select_channel':"without_caret_input select_channel editor_btn_disabled"}

                            />
                            <InputContent>
                              Select channel in{' '}
                              {currentNode.selectedChatChannel &&
                              currentNode.selectedChatChannel.id === 'slack'
                                ? 'Slack'
                                : 'Teams'}
                            </InputContent>

                            <CancelButtonInput
                              rotate
                              onClick={makeDropDownVisible}
                            >
                              <DownarrowWithCircle />
                            </CancelButtonInput>
                          </DropdownWrapper>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentNode.selectedWorkspaceChannel && (
                <div
                  className="automation_condition_form"
                  style={{ marginBottom: 0 }}
                >
                  <div className="select_application">
                    <div className="select_work_form">
                      <CanvasRightPanel.AppsDropDown
                        inputs={{
                          inputs: userTypes,
                          key: 'type',
                          idKey: 'id',
                          showIcon: false,
                        }}
                        addDropDown
                        updateSelection={(
                          selectedOperatorValue: string,
                          id: string
                        ) => {
                          const updatedValue = produce(currentNode, (draft) => {
                            draft.user_type = userTypes.filter(
                              (c) => c.id === id
                            )[0];
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: updatedValue,
                          });
                        }}
                        render={(makeDropDownVisible: () => void) => (
                          <DropdownWrapper>
                            <Input
                              type="text"
                              value={
                                currentNode.user_type &&
                                currentNode.user_type.type
                                  ? currentNode.user_type.type
                                  : 'Select'
                              }
                              onClick={makeDropDownVisible}
                              onChange={() => {}}
                              className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input':"without_caret_input editor_btn_disabled"}
                            />
                            <InputContent>Send notification to</InputContent>

                            <CancelButtonInput
                              rotate
                              onClick={makeDropDownVisible}
                            >
                              <DownarrowWithCircle />
                            </CancelButtonInput>
                          </DropdownWrapper>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentNode.user_type && currentNode.user_type.id === 'user' && (
                <DraftInputWrapper className="send_notifi_focus">
                  <div className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'select_work_form variable_content_button':"select_work_form variable_content_button editor_btn_disabled"}>
                    <Editor
                      focus={
                        convertAsaSingleString(
                          currentNode.user
                            ? (convertToRaw(
                                (currentNode.user as any).getCurrentContent()
                              ).blocks as RawDraftContentBlock[])
                            : (convertToRaw(
                                EditorState.createEmpty().getCurrentContent()
                              ).blocks as RawDraftContentBlock[])
                        ).replace(/\s/g, '').length
                          ? 'focus_input'
                          : ''
                      }
                      focusContent={'Provide user email id'}
                      infocus={contextPopupValues.currentInput === 'user'}
                      disabled={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
                      value={currentNode.user as EditorState}
                      onFocus={() => {
                        setContextPopupValues({
                          ...contextPopupValues,
                          show: true,
                          currentInput: 'user',
                        });
                      }}
                      setValue={(editorState: EditorState) => {
                        const nextValue = produce(currentNode, (draft) => {
                          draft.user = editorState;
                        });
                        setNotificationPanelState({
                          ...notificationPanelState,
                          currentNode: nextValue,
                        });
                      }}
                      // style={{marginTop: 0}}
                    />
                  </div>
                </DraftInputWrapper>
              )}

              {currentNode.user_type && (
                <div
                  className="automation_condition_form"
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <div className="select_application">
                    <div className="select_work_form">
                      <CanvasRightPanel.AppsDropDown
                        inputs={{
                          inputs: messageType,
                          key: 'type',
                          idKey: 'id',
                          showIcon: false,
                        }}
                        addDropDown
                        updateSelection={(
                          selectedOperatorValue: string,
                          id: string
                        ) => {
                          const updatedValue = produce(currentNode, (draft) => {
                            draft.message_type = messageType.filter(
                              (c) => c.id === id
                            )[0];
                            draft.actionInputsList.output.input = [
                              {
                                config: { kind: 'text' },
                                description: '',
                                displayTitle: 'Message to user',
                                inputHasValue: true,
                                key: 'message',
                                required: true,
                                value: emptyEditorState,
                              },
                            ];
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: updatedValue,
                          });
                        }}
                        render={(makeDropDownVisible: () => void) => (
                          <DropdownWrapper>
                            <Input
                              type="text"
                              value={
                                currentNode.message_type &&
                                currentNode.message_type.type
                                  ? currentNode.message_type.type
                                  : 'Select'
                              }
                              onClick={makeDropDownVisible}
                              onChange={() => {}}
                              className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'without_caret_input':"without_caret_input editor_btn_disabled"}
                            />
                            <InputContent>
                              Select notification type
                            </InputContent>

                            <CancelButtonInput
                              rotate
                              onClick={makeDropDownVisible}
                            >
                              <DownarrowWithCircle />
                            </CancelButtonInput>
                          </DropdownWrapper>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}

              {currentNode.message_type && (
                // <InputContainer>
                //   <Input
                //     type="text"
                //     onChange={(e: any) => {
                //       const updatedValue = produce(currentNode, (draft) => {
                //         draft.notification_title = e.target.value;
                //       });
                //       setNotificationPanelState({
                //         ...notificationPanelState,
                //         currentNode: updatedValue,
                //       });
                //     }}
                //     value={currentNode.notification_title}
                //   />
                //   <InputContent>Notification title</InputContent>
                // </InputContainer>

                <DraftInputWrapper className="send_notifi_focus">
                  <div
                    className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'select_work_form':"select_work_form editor_btn_disabled"}
                    style={{ marginBottom: 20 }}
                  >
                    <Editor
                      focus={
                        convertAsaSingleString(
                          currentNode.notification_title
                            ? (convertToRaw(
                                (
                                  currentNode.notification_title as any
                                ).getCurrentContent()
                              ).blocks as RawDraftContentBlock[])
                            : (convertToRaw(
                                EditorState.createEmpty().getCurrentContent()
                              ).blocks as RawDraftContentBlock[])
                        ).replace(/\s/g, '').length
                          ? 'focus_input'
                          : ''
                      }
                      focusContent={'Notification title'}
                      infocus={
                        contextPopupValues.currentInput === 'notification_title'
                      }
                      disabled={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
                      value={currentNode.notification_title as EditorState}
                      onFocus={() => {
                        setContextPopupValues({
                          ...contextPopupValues,
                          show: true,
                          currentInput: 'notification_title',
                        });
                      }}
                      setValue={(editorState: EditorState) => {
                        const nextValue = produce(currentNode, (draft) => {
                          draft.notification_title = editorState;
                        });
                        setNotificationPanelState({
                          ...notificationPanelState,
                          currentNode: nextValue,
                        });
                      }}
                    />
                  </div>
                </DraftInputWrapper>
              )}
              {currentNode.message_type &&
                (currentNode.message_type.id == 'message' ||
                currentNode.message_type.id == 'option' ? (
                  <>
                    <span className="notification_message_input">
                      <CommonInputs
                        state={notificationPanelState}
                        setContextPopupValues={setContextPopupValues}
                        contextPopupValues={contextPopupValues}
                        setPanelState={setNotificationPanelState}
                        title={''}
                        description=""
                        inputsList={
                          currentNode.actionInputsList &&
                          currentNode.actionInputsList.output
                            ? currentNode.actionInputsList.output.input
                            : []
                        }
                        workspacename={props.match.params.workspacename}
                        updateInputValuesWithContext={
                          updateInputValuesWithContext
                        }
                        showToolbar={true}
                      />
                    </span>
                    {currentNode.message_type &&
                      currentNode.message_type.id == 'option' && (
                        <>
                          {currentNode.options.map((op, i) => (
                            <Field
                              onKeyChange={(e: any) => {
                                const nextValue = produce(
                                  currentNode,
                                  (draft) => {
                                    draft.options[i] = {
                                      ...op,
                                      key: e.target.value,
                                    };
                                  }
                                );
                                setNotificationPanelState({
                                  ...notificationPanelState,
                                  currentNode: nextValue,
                                });
                              }}
                              onFocus={() => {
                                setContextPopupValues({
                                  ...contextPopupValues,
                                  show: true,
                                  currentInput: `Option${i}`,
                                });
                              }}
                              currentInput={contextPopupValues.currentInput}
                              showDelete={currentNode.options.length > 1}
                              index={i}
                              keyValue={op.key}
                              keyText={'Option'}
                              option={op}
                              setValue={(editorState: EditorState) => {
                                const nextValue = produce(
                                  currentNode,
                                  (draft) => {
                                    draft.options[i] = {
                                      ...op,
                                      value: editorState,
                                    };
                                  }
                                );
                                setNotificationPanelState({
                                  ...notificationPanelState,
                                  currentNode: nextValue,
                                });
                              }}
                              onDelete={() => {
                                const nextValue = produce(
                                  currentNode,
                                  (draft) => {
                                    draft.options = draft.options.filter(
                                      (op, j) => i !== j
                                    );
                                  }
                                );
                                setNotificationPanelState({
                                  ...notificationPanelState,
                                  currentNode: nextValue,
                                });
                              }}
                            />
                          ))}
                          {currentNode.options.filter(
                            (data) =>
                              !convertAsaSingleString(
                                convertToRaw(
                                  (
                                    data.value as EditorState
                                  ).getCurrentContent()
                                ).blocks
                              ).replace(/\s/g, '').length || data.key == ''
                          ).length == 0 && currentNode.options.length < 20 ? (
                            <div 
                            // className="plus_addmore"
                             className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?"plus_addmore":"plus_addmore editor_btn_disabled"}>
                              <div
                                className="plus_add_more"
                                onClick={() => {
                                  const nextValue = produce(
                                    currentNode,
                                    (draft) => {
                                      draft.options = [
                                        ...currentNode.options,
                                        { key: '', value: emptyEditorState },
                                      ];
                                    }
                                  );
                                  setNotificationPanelState({
                                    ...notificationPanelState,
                                    currentNode: nextValue,
                                  });
                                }}
                              >
                                <SmallButton primary>
                                  <span>
                                    <AddIcon />
                                  </span>
                                  <label>Add More</label>
                                </SmallButton>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <InputContainer style={{ marginBottom: 0 }}>
                      <Input
                        type="text"
                        onChange={(e: any) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard = {
                              ...draft.adaptivecard,
                              title: e.target.value,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        value={currentNode.adaptivecard.title}
                      />
                      <InputContent>Card title</InputContent>
                    </InputContainer>
                    <DraftInputWrapper style={{ marginBottom: 20 }}>
                      <div className="select_work_form variable_content_button card_disc">
                        <Editor
                          focus={
                            convertAsaSingleString(
                              currentNode.adaptivecard.description
                                ? (convertToRaw(
                                    (
                                      currentNode.adaptivecard
                                        .description as any
                                    ).getCurrentContent()
                                  ).blocks as RawDraftContentBlock[])
                                : (convertToRaw(
                                    EditorState.createEmpty().getCurrentContent()
                                  ).blocks as RawDraftContentBlock[])
                            ).replace(/\s/g, '').length
                              ? 'focus_input'
                              : ''
                          }
                          focusContent={'Card description'}
                          infocus={
                            contextPopupValues.currentInput ===
                            `adaptive_description`
                          }
                          disabled={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
                          value={
                            currentNode.adaptivecard.description as EditorState
                          }
                          onFocus={() => {
                            setContextPopupValues({
                              ...contextPopupValues,
                              show: true,
                              currentInput: `adaptive_description`,
                            });
                          }}
                          setValue={(editorState: EditorState) => {
                            const nextValue = produce(currentNode, (draft) => {
                              draft.adaptivecard = {
                                ...draft.adaptivecard,
                                description: editorState,
                              };
                            });

                            setNotificationPanelState({
                              ...notificationPanelState,
                              currentNode: nextValue,
                            });
                          }}
                          showToolbar
                        />
                      </div>
                    </DraftInputWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e: any) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard = {
                              ...draft.adaptivecard,
                              url: e.target.value,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        value={currentNode.adaptivecard.url}
                      />
                      <InputContent>
                        Logo URL (Optional){' '}
                        <Tooltip
                          className="target customTip"
                          zIndex={10000}
                          arrowSize={8}
                          tagName="span"
                          content={'Display logo or image in the card'}
                          distance={5}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputContent>
                      {currentNode.adaptivecard.url &&
                        IsValidURL(currentNode.adaptivecard.url) === null && (
                          <span className="info_icon info_icon_Adaptive">
                            <Tooltip
                              className="target customTip"
                              zIndex={10000}
                              arrowSize={8}
                              tagName="span"
                              content={'Invalid Image Attachment URL.'}
                              distance={5}
                            >
                              <WarningAlertIcon />
                            </Tooltip>
                          </span>
                        )}
                    </InputContainer>
                    <div className="opations_label">
                      <Header style={{ fontSize: 14, marginTop: '-10px' }}>
                        Add fields to the card:{' '}
                      </Header>
                    </div>
                    {currentNode.adaptivecard.fields.map((op, i) => (
                      <Field
                        onKeyChange={(e: any) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.fields[i] = {
                              ...op,
                              key: e.target.value,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        onFocus={() => {
                          setContextPopupValues({
                            ...contextPopupValues,
                            show: true,
                            currentInput: `Field${i}`,
                          });
                        }}
                        currentInput={contextPopupValues.currentInput}
                        showDelete={currentNode.adaptivecard.fields.length > 1}
                        index={i}
                        keyValue={op.key}
                        keyText={'Field name'}
                        option={op}
                        setValue={(editorState: EditorState) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.fields[i] = {
                              ...op,
                              value: editorState,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        onDelete={() => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.fields =
                              draft.adaptivecard.fields.filter(
                                (op, j) => i !== j
                              );
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        showToolbar={true}
                      />
                    ))}
                    {currentNode.adaptivecard.fields.filter(
                      (data) =>
                        !convertAsaSingleString(
                          convertToRaw(
                            (data.value as EditorState).getCurrentContent()
                          ).blocks
                        ).replace(/\s/g, '').length || data.key == ''
                    ).length == 0 &&
                    currentNode.adaptivecard.fields.length < 20 ? (
                      <div className="plus_addmore">
                        <div
                          className="plus_add_more"
                          onClick={() => {
                            const nextValue = produce(currentNode, (draft) => {
                              draft.adaptivecard.fields = [
                                ...draft.adaptivecard.fields,
                                { key: '', value: emptyEditorState },
                              ];
                            });
                            setNotificationPanelState({
                              ...notificationPanelState,
                              currentNode: nextValue,
                            });
                          }}
                        >
                          <SmallButton primary>
                            <span>
                              <AddIcon />
                            </span>
                            <label>Add More</label>
                          </SmallButton>
                        </div>
                      </div>
                    ) : null}
                    <div className="opations_label">
                      <Header style={{ fontSize: 14, marginTop: 10 }}>
                        Add buttons to the card:{' '}
                      </Header>
                    </div>
                    {currentNode.adaptivecard.buttons.map((op, i) => (
                      <Field
                        onKeyChange={(e: any) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.buttons[i] = {
                              ...op,
                              key: e.target.value,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        onFocus={() => {
                          setContextPopupValues({
                            ...contextPopupValues,
                            show: true,
                            currentInput: `Button${i}`,
                          });
                        }}
                        currentInput={contextPopupValues.currentInput}
                        showDelete={currentNode.adaptivecard.buttons.length > 1}
                        index={i}
                        keyValue={op.key}
                        keyText={'Button name'}
                        option={op}
                        setValue={(editorState: EditorState) => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.buttons[i] = {
                              ...op,
                              value: editorState,
                            };
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                        onDelete={() => {
                          const nextValue = produce(currentNode, (draft) => {
                            draft.adaptivecard.buttons =
                              draft.adaptivecard.buttons.filter(
                                (op, j) => i !== j
                              );
                          });
                          setNotificationPanelState({
                            ...notificationPanelState,
                            currentNode: nextValue,
                          });
                        }}
                      />
                    ))}
                    {currentNode.adaptivecard.buttons.filter(
                      (data) =>
                        !convertAsaSingleString(
                          convertToRaw(
                            (data.value as EditorState).getCurrentContent()
                          ).blocks
                        ).replace(/\s/g, '').length || data.key == ''
                    ).length == 0 &&
                    currentNode.adaptivecard.buttons.length < 20 ? (
                      <div className="plus_addmore">
                        <div
                          className="plus_add_more"
                          onClick={() => {
                            const nextValue = produce(currentNode, (draft) => {
                              draft.adaptivecard.buttons = [
                                ...draft.adaptivecard.buttons,
                                { key: '', value: emptyEditorState },
                              ];
                            });
                            setNotificationPanelState({
                              ...notificationPanelState,
                              currentNode: nextValue,
                            });
                          }}
                        >
                          <SmallButton primary>
                            <span>
                              <AddIcon />
                            </span>
                            <label>Add More</label>
                          </SmallButton>
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
            </InputWrapper>
          </EmptyDiv>

          <ButtonWrapper
            cancelAction={resetRightPanel}
            onClick={() => saveNode()}
            className={disableSave() ? 'editor_btn_disabled' : ''}
          />
          {contextPopupValues.show && (
            <ContextPopup
              {...props}
              showDialogVariables={
                contextPopupValues.currentInput === 'message' ||
                contextPopupValues.currentInput.includes('Option') ||
                contextPopupValues.currentInput.includes('Field') 
                // ||
                // contextPopupValues.currentInput.includes('Button')
              }
              tree={treeData.tree}
              mode={rightPanelState.mode}
              parentNode={rightPanelState.parentNode}
              actionAppsList={currentNode.appsList}
              triggerNode={treeData.tree.value}
              isFilePicker={contextPopupValues.isFilePicker}
              addingFlowInsideForEach={currentNode.addingInsideForEach}
              updateInputValuesWithContext={updateInputValuesWithContext}
              removeVariableFromInput={(text: any, cb: Function) =>
                removeVariableFromInput(text, cb)
              }
              updateVariableFromInput={(
                newText: any,
                oldText: any,
                cb: Function
              ) => updateVariableFromInput(newText, oldText, cb)}
              closeContextPopup={() =>
                setContextPopupValues({
                  ...contextPopupValues,
                  show: false,
                  currentInput: '',
                  isFilePicker: false,
                })
              }
            />
          )}
        </PopupScrollHeight>
      </PopupContainer>
    </PopupWrapper>
  );
};

const DraftEditor = (props: {
  value: EditorState;
  setValue: Function;
  infocus: boolean;
  disabled: boolean;
  focus?: string;
  focusContent?: string;
  optional?: boolean;
}) => {
  const { infocus, optional, disabled } = props;

  return (
    <Editor
      focus={props.focus}
      optional={optional}
      focusContent={props.focusContent}
      infocus={infocus}
      disabled={disabled ? disabled : false}
      value={props.value}
      onFocus={() => {}}
      setValue={(editorState: EditorState) => {
        props.setValue(editorState);
        //to update state without changing other value. produce is to use instead of doing {...something}
      }}
    />
  );
};

export const SendNotificationWrapperPopup = (props: any) => {
  const { rightPanelState } = useContext(SidePanelContext);

  const [notificationPanelState, setNotificationPanelState] = useState({
    ...rightPanelState,
    currentNode: rightPanelState.currentNode as NotificationResponseT,
  });

  const [contextPopupValues, setContextPopupValues] =
    useState<ContextPopupStateT>({
      show: false,
      inputWithValues: [],
      currentInput: '',
      isMultiple: { path: '', parentKey: '' },
      collectionPicker: {
        isCollectionPicker: false,
        collectionType: emptyInputSchema,
        selectedCollection: {
          name: '',
          value: {
            $id: '',
            type: 'collection',
            item: {
              type: 'object',
              properties: {},
            },
            title: '',
            properties: {},
          },
        },
        app: ResponseOutput,
      },
    });

  return (
    <NotificationStateContext.Provider
      value={{
        notificationPanelState,
        setNotificationPanelState,
        contextPopupValues,
        setContextPopupValues,
      }}
    >
      <Wrapper className="approval_popup">
        <SendNotificationPopup {...props} />
      </Wrapper>
    </NotificationStateContext.Provider>
  );
};

function Field(props: {
  onKeyChange: (e: any) => void;
  option: dictionaryFieldT;
  index: number;
  keyText: string;
  keyValue: string;
  currentInput: string;
  onDelete: () => void;
  onFocus: () => void;
  showDelete: boolean;
  setValue: (editorState: EditorState) => void;
  showToolbar?: boolean;
}) {
  const {
    onKeyChange,
    index,
    keyText,
    currentInput,
    option,
    onDelete,
    onFocus,
    showDelete,
    setValue,
    showToolbar,
    keyValue,
  } = props;
  const { hasLinkedDialogs, active_automation, automationMeta } = useContext(SidePanelContext);
  const isAssistant = getProductId() === 'ASSISTANT'

  return (
    <div className="send_notifi_two_column" key={index}>
      <InputContainer>
        <Input type="text" onChange={onKeyChange} value={keyValue}
        className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
        />
        <InputContent>
          {keyText}
          {/* <Tooltip
            className="target customTip"
            zIndex={10000}
            arrowSize={8}
            tagName="span"
            content={''}
            distance={5}
          >
            <InfoIcon />
          </Tooltip> */}
        </InputContent>
      </InputContainer>
      <InputContainer>
        <DraftInputWrapper>
          <div className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'select_work_form':"select_work_form editor_btn_disabled"}>
            <Editor
              focus={
                convertAsaSingleString(
                  option.value
                    ? (convertToRaw((option.value as any).getCurrentContent())
                        .blocks as RawDraftContentBlock[])
                    : (convertToRaw(
                        EditorState.createEmpty().getCurrentContent()
                      ).blocks as RawDraftContentBlock[])
                ).replace(/\s/g, '').length
                  ? 'focus_input'
                  : ''
              }
              focusContent={'Value'}
              infocus={currentInput === `${keyValue}${index}`}
              disabled={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
              value={option.value as EditorState}
              onFocus={onFocus}
              setValue={(editorState: EditorState) => {
                setValue(editorState);
              }}
              showToolbar={showToolbar}
            />
          </div>
        </DraftInputWrapper>
      </InputContainer>
      {showDelete &&(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)&& (
        <div className="parent_node_no">
          <span
            className="parent_svg"
            onClick={() => {
              onDelete();
            }}
          >
            <CloseIcon />
          </span>
        </div>
      )}
    </div>
  );
}
