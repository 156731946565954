/*eslint-disable*/
import Downshift from 'downshift'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import AssistantDialogueCanvas from '@/Assistant/DialogueCrew'
import { DownarrowWithCircle, NextDetailICon } from '@/common/Icons/Icons'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { DropdownLi, DropdownUlWrapper, InputContainer } from '@/common/styled/Workflow.Analytics.Dumb'

import logo from '@/common/images/logo.png'
import { BotT, ModalPopup } from './BotMarketPlacePopup'
import { GetBotDetails } from './MarketPlaceUtils'

const Wrapper = styled.section`
    position: fixed;
    top: 64px;
    bottom: 0px;
    left: 0;
    right: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
    display: grid;
    overflow: hidden;
`
export const DialogModalWrapper = (props: any) => {
    return (
        <div>
            <Wrapper>
                <Modalbg />
                <Modal {...props} />
            </Wrapper>
        </div>
    )
}

const ModalbgColor = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0px 0px 0px 0px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: auto;
`

export const Modalbg = () => {
    return <ModalbgColor />
}
// PopupRight css Start

export const Paragraph = styled.p`
    text-align: left;
    color: #355d8b;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    margin-bottom: 6px;
    margin-left: 3px;
    width: 100%;
    float: left;
    line-height: 20px;
    cursor: ${props => (props.cursor ? 'pointer' : 'inherit')};
    padding: 0px 20px;

    svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        position: relative;
        cursor: pointer;
        // top: 4px;
    }
`
export const HeaderLeft = styled.div`
    width: max-content;
    float: left;
`
export const HeaderRight = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;
    ${Paragraph} {
        color: #007bff;
        margin-bottom: 0px;
    }
`
export const Header = styled.h4`
    text-align: left;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    margin-bottom: 0px;
    width: 100%;
    float: left;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;

    span {
        margin-left: 6px;
        svg {
            width: 12px;
            height: 12px;
            fill: #999;
            color: #999;
            margin-left: 0px;
            cursor: pointer;
        }
    }
`

const DropdownImage = styled.div`
    width: 14px;
    height: 14px;
    cursor: pointer;
    float: left;
    position: absolute;
    margin-top: 12px;
    left: 12px;
    display: flex;

    img {
        width: 14px;
        height: 14px;
    }
    svg {
        width: 14px;
        height: 14px;
        fill: #999999;
    }
`

const ButtonHoverContent = styled.div`
    width: 100%;
    float: left;
    padding-bottom: 3px;
    border-bottom: 1px solid #bababa;
    display: flex;
    flex-direction: column;
    :last-child {
        border-bottom: none;
    }
    h5 {
        font-family: 'Gordita-Medium';
        font-size: 14px;
        color: #000;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 1px;

        span {
            color: #f07f31;
        }
    }
    p {
        font-family: 'Gordita-Regular';
        font-size: 12px;
        color: #000;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 7px;
        }
    }
`
const TabsWrapper = styled.div`
width:100%:
float:left;
border-bottom:1px solid #BABABA;
    h5 {
        font-family: 'Gordita-Regular';
        font-size: 12px;
        color: #000;
        text-align: left;
        margin-bottom: 8px;
        margin-top: 11px;
        display: flex;
        align-items: center;
        label {
            position: relative;
            top: 1px;
            margin: 0px;
            margin-left: 6px;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
        }

        span {
            margin-left: 3px;

            svg {
                width: 10px;
                height: 8px;
                fill: #707070;
                color: #707070;
                margin: 0px;
                transform: rotate(0deg);
            }
        }
        div {
            width: 18px;
            float: left;
            height: 18px;
            background: #d5ffee 0% 0% no-repeat padding-box;
            border-radius: 24px;
            margin-bottom: 0px;
            font-family: 'Gordita-Medium';
            font-size: 7px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;

            label {
                position: relative;
                top: 0px;
                margin: 0px;
                line-height: initial;
            }
        }
    }
    .tabs_dropdown {
        width: 100%;
        float: left;
        overflo-x: hidden;
        padding-left: 24px;
        h6 {
            font-family: 'Gordita-Medium';
            font-size: 10px;
            color: #000;
            text-align: left;
            margin-bottom: 6px;

            span {
                color: #f07f31;
            }
        }
        p {
            font-size: 10px;
            color: #000000;
            margin-bottom: 7px;
            :last-child{
                margin-bottom: 10px;
            }
            label {
                margin: 0;
                position: relative;
                top: 1px;
            }
            div {
                width: 11px;
                float: left;
                height: 11px;
                background: #c7e3ff 0% 0% no-repeat padding-box;
                border-radius: 24px;
                margin-bottom: 0px;
                font-family: 'Gordita-Medium';
                font-size: 6px;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 3px;

                label {
                    position: relative;
                    top: 1px;
                    margin: 0px;
                }
            }
        }
    }
    ${({ isActive }) =>
        isActive &&
        `h5 { 
                span {

                    svg{
                        transform: rotate(90deg);
                    }
                }
            }
`}
`
const ButtonRow = styled.div`
    width: 100%;
    float: left;
    ${ButtonHoverContent} {
        h5 {
            margin-top: 12px;
            margin-bottom: 0px;
        }
        ${TabsWrapper} {
            h5 {
                margin-bottom: 10px;
            }
        }
    }
    :first-child {
        margin-top: 0px;
        ${ButtonHoverContent} {
            h5 {
                margin-top: 12px;
                margin-bottom: 0px;
            }
            ${TabsWrapper} {
                h5 {
                    margin-bottom: 10px;
                }
            }
        }
    }
`
const ButtonHover = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 19px;
    position: absolute;
    top: 32px;
    width: 200px;
    max-height: 360px;
    overflow-y: scroll;
    right: 0px;
    padding: 0px 12px 12px;
    z-index: 1111;
    padding-top: 8px;
`
const ButtonLeft = styled.button`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 19px;
    width: max-content;
    position: relative;
    padding: 0px 12px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gordita-Medium';
    font-size: 12px;
    color: #000;
    cursor: pointer;
    transition: 0.5s ease;
    line-height: normal;
    margin-right: 20px;
    cursor: pointer;

    .buton_top {
        display: flex;
        align-items: center;
    }

    svg {
        width: 16px;
        height: 16px;
        fill: #000;
        margin-right: 5px;
    }
    label {
        margin: 0;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
    :hover {
        border: 1px solid #009d5f;
        background: #ddfeef;
        color: #333;
        fill: #333;
        outline: 0;
        svg {
            fill: #333;
        }
    }
    span {
        width: 12px;
        height: 12px;

        svg {
            width: 12px;
            height: 12px;
            margin-right: 0px;
            margin-left: 3px;
        }
    }
    ${({ isActive }) =>
        isActive &&
        `
        background: #ddfeef 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #009d5f;
        color: #000;
        outline: 0;
        svg {
            fill: #333;
        }
        span {
            svg {
             transform:rotate(-90deg);
            }
        }
        
    `}
`
export const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #fff;
    ${Button} {
        margin: 0px;
    }
`

export const SerachInputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;

    span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 10px;
        left: 12px;
        svg {
            width: 18px;
            height: 18px;
            color: #999;
            fill: #999;
        }
    }
`
export const SerachInput = styled.input`
    width: 100%;
    height: 38px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    padding: 14px 12px 11px 36px;
    font-size: 12px;
    font-family: Gordita-Regular;
    color: #000;
    :placeholder {
        color: #999;
    }
    :hover {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #009d5f;
    }
`
export const HeaderCount = styled.h4`
    text-align: left;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    margin-bottom: 12px;
    width: 100%;
    float: left;
    line-height: 18px;
    span {
        color: #e57700;
    }
`
export const BoxWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;

    ${InputContainer} {
        margin-bottom: 10px;
    }

    .child_bot{
        height: calc(100vh - 400px);
    }
`
// card back wrapper

export const BackWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    background: #f4f4f4;
    padding: 0px 25px;
`
export const BackWrapperLeft = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;
    margin-top: 1px;

    ${Header} {
        padding: 0px;
        font-size: 14px;
        line-height: 14px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: 1px;
    }
    ${Paragraph} {
        padding: 0px;
        font-size: 10px;
        line-height: 10px;
        margin-left: 10px;
        margin-bottom: 0px;
    }
`
export const BackWrapperRight = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;
    gap: 5px;

    ${Button} {
        display: flex;
        align-items: center;

        .header_button{
            width: 16px;
            height: 16px;
            margin-right: 3px;
            
            svg{
                width: 14px;
                height: 14px;
                margin-right: 0;
            }
        }
        label{
            top: 1px;
        }
    }
    .button_with_error {
        position: relative;
        right: 5px;
    }
    .botmarketplace_error {
        svg {
            top: -38px;
            right: -45px;
        }
        .error_menu_hvr {
            right: -45px;
            top: -75px;
            left: unset;
        }
    }
    .target.customTip {
        width:16px;
        height:16px;
        display:block;
        cursor:pointer;
    }
    .nonClickable_btn {
        opacity:0.2;
        cursor:default;
       
        label{
            cursor:default;
        }
        svg{
            cursor:default;
        }
    :hover {
        background:#2468f6;
        border:1px solid #2468f6;
        cursor:default;

    label{
        color:#fff;
    }
    svg{
        fill:#fff;
    }
    }}
`

export const BgBox = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4;
    height: calc(100vh - 227px);
    position: relative;
    .shadow_bottom {
        background: transparent linear-gradient(180deg, #f4f4f400 0%, #dedede 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        bottom: 0;
        height: 32px;
        width: 100%;
        left: 0px;
    }
    .bot_market_place_loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100vh - 227px);
    }
    ${DropdownUlWrapper} {
        margin-bottom: 0px;
        ${InputContainer} {
            margin-bottom: 10px;
        }

        ${DropdownLi}{
            padding: 4px 10px 0 10px;
        }
    }
`
export const PopUpRight = styled.div`
    width: 100%;
    height: calc(100vh - 70px);
    background: #ffffff 0% 0% no-repeat padding-box;
    text-align: center;
    display: flex;
    padding: 20px 0px;
    place-content: flex-start;
    flex-wrap: wrap;
    border-radius: ${props => (props.primary ? '20px' : '0px 20px 20px 0px')};
`

// PopupRight css End

// Automation left css Start
const HeaderContentRight = styled.div`
    width: 40%;
    float: left;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row-reverse;
`
const HeaderContentLeft = styled.div`
    width: 60%;
    float: left;
`
const HeaderContent = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    ${Paragraph} {
        margin-bottom: 0px;
    }
`
const CanvasMarketpalce = styled.div`
    display: flex;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    float: left;
    height: calc(100vh - 175px);
    padding: 0px;
    overflow: hidden;
    position: relative;

    @media (max-width: 1536px) {
        width: calc(100% - -168px);
        height: calc(100vh - 175px);
        transform: scale(0.84);
        transform-origin: top left;
        -webkit-transform-origin: left top;
        -moz-transform-origin: left top;

        div#preloader {
            width: 128px;
            height: 128px;
            top: 30px;
        }
    }

    .dialog_editor.dialog_detail .zontal_lines {
        padding-top: 0px;
        padding-left: 24px;
    }
    .dialog__header {
        display: none;
    }
    .tree.zontal_lines .first_add_node {
        margin-bottom: 40px;
    }
    .flex_side_menu_dialog.dialog_model_overlay {
        display: none;
    }
    .dialog_canvas_startup_page .flex_side_menu {
        display: none !important;
    }
    .dialog_entity_cards .entity_cards {
        padding: 0px;
        position: relative;
        left: -20px;
        height: 100%;
        padding: 0px;
        display: flex;
        align-items: flex-start;
    }
    .tryme_popup {
        display: none;
    }
    .flow_buttons {
        display: none;
    }
    .send_message_details.send_message_hover {
        padding-right: 0px;
    }
    .dialog_tree_next {
        pointer-events: none;
    }
    .error_input_show.parent {
        display: none;
    }
    .error_input_show.dialog_node.child_svg {
        display: none;
    }
`
const AutomationBg = styled.div`
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    float: left;
    height: calc(100vh - 175px);
    padding: 20px 10px 20px 30px;
    overflow: hidden;

    @media (max-width: 1536px) {
        padding: 20px 20px 20px 25px;
    }

    .automation_canvas .cogni_cards {
        display: none;
    }
    .entity_cards .col-md-12 {
        padding: 0px;
    }
    .automation_canvas_left {
        height: 100%;
    }
    .flow_structure {
        padding-top: 0px;
    }
    .automation_entity_cards {
        height: calc(100vh - 300px);
        overflow: scroll;
    }
    .flow_popup2 {
        display: none;
    }
    div#preloader {
        width: 128px;
        height: 128px;
        top: -10px;
        position: relative;
    }
`
export const AutomationWrapper = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 100%;
    float: left;
    height: 100%;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
`
export const PopUpLeft = styled.div`
    width: 100%;
    float: left;
    visibility: ${props => (props.hideLeft ? 'hidden' : 'visible')};
`
const Modal = (props: any) => {
    const [leftPopup, setLeftPopup] = useState({ metaData: { botId: '' }, loading: false })
    const [isOpenLeft, setIsOpenLeft] = useState(false)
    const [bots, setBots] = useState<BotT[]>([])
    return (
        <>
            <ModalRow>
                <ModalDialog hidden={!isOpenLeft ? 'hidden' : null} bots={bots} leftPopup={leftPopup} {...props} />
                <ModalPopup
                    bots={bots}
                    setBots={setBots}
                    setLeftPopup={(meta: any) => {
                        setLeftPopup(meta)
                    }}
                    isOpenLeft={isOpenLeft}
                    setIsOpenLeft={setIsOpenLeft}
                    workspace={props.match.params.workspacename}
                    {...props}
                />
            </ModalRow>
        </>
    )
}

// Automation left css End

const ModalDialog = (props: any) => {
    return props.hidden ? (
        <PopUpLeft hideLeft>
            <AutomationWrapper />
        </PopUpLeft>
    ) : (
        <PopUpLeft>
            <AutomationWrappers bots={props.bots} leftPopup={props.leftPopup} {...props} />
        </PopUpLeft>
    )
}
// const ModalDialog = (props: any) => {
//     return (
//         <PopUpLeft>
//             <AutomationWrappers bots={props.bots} leftPopup={props.leftPopup} {...props} />
//         </PopUpLeft>
//     )
// }
type EntityT = {
    id: string
    entity: string
    values: ValueT[]
}
type ValueT = {
    id: string
    value: string
}
type IntentT = {
    id: string
    intent: string
    examples: ExampleT[]
}
type ExampleT = {
    id: string
    text: string
}
type InsideT = {
    entities: EntityT[]
    intents: IntentT[]
}
const AutomationWrappers = (props: any) => {
    const [buttonOpen, setButtonOpen] = useState(false)
    const [insides, setInsides] = useState<InsideT>({ entities: [], intents: [] })

    const isWhatsNewClicked = React.useRef<boolean>(false)
    useEffect(() => {
        if (props.leftPopup.metaData.botId) GetBotDetailList()
    }, [props])
    const GetBotDetailList = () => {
        GetBotDetails(props.match.params.workspacename, props.leftPopup.metaData.botId).then(res => {
            const { entities, intents } = res.data.output
            setInsides({ entities, intents })
        })
    }
    let findBot = props.bots.find((data: BotT) => data.id === props.leftPopup.metaData.botId)

    const whatsInsideClick = (event: any) => {
        if (buttonOpen) {
            isWhatsNewClicked.current = false
            setButtonOpen(false)
        } else {
            isWhatsNewClicked.current = true
            setButtonOpen(true)
        }

        console.log('buttonOpen event', buttonOpen, isWhatsNewClicked.current)
        event.stopPropagation()
        event.preventDefault()
    }
    // console.log('buttonOpen in render', buttonOpen)
    return (
        <AutomationWrapper>
            <HeaderContent>
                <HeaderContentLeft>
                    {' '}
                    <Header>{findBot && findBot.name}</Header>
                    <Paragraph>{findBot && findBot.description}</Paragraph>
                </HeaderContentLeft>
                <HeaderContentRight>
                    <ButtonLeft isActive={buttonOpen ? 'isActive' : null}>
                        <div className="buton_top" onClick={whatsInsideClick}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 12 12"
                                xmlSpace="preserve"
                            >
                                <path d="M4.6 2.8h6.6c.3 0 .6-.3.6-.6s-.3-.6-.6-.6H4.6c-.3 0-.6.3-.6.6s.3.6.6.6zM11.2 5.4H4.6c-.3 0-.6.3-.6.6s.3.6.6.6h6.6c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM1.3 3.2c.1.1.2.1.4.1h.1c.2 0 .3-.1.4-.2l1-1.4c.1-.1.1-.3.1-.4S3.2.9 3.1.8C2.9.7 2.8.7 2.6.7s-.3.1-.4.2l-.6.9-.4-.2c-.1-.1-.3-.2-.4-.2-.2 0-.3.1-.4.2-.1.2-.2.3-.2.5s.1.3.2.4l.9.7zM1.8 4.5C.9 4.5.2 5.2.2 6s.7 1.5 1.5 1.5S3.2 6.8 3.2 6c.1-.8-.6-1.5-1.4-1.5zM2.4 6c0 .3-.3.6-.6.6s-.7-.3-.7-.6.3-.6.6-.6c.4 0 .7.3.7.6zM11.2 9.2H4.6c-.3 0-.6.2-.6.6 0 .3.3.6.6.6h6.6c.3 0 .6-.3.6-.6 0-.4-.3-.6-.6-.6zM1.8 8.2C1 8.2.3 8.9.3 9.7s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zm.6 1.6c0 .4-.3.7-.7.7s-.7-.3-.7-.7c0-.4.3-.7.7-.7s.7.3.7.7z" />
                            </svg>
                            <label>What’s inside</label>
                            <span>
                                <DownarrowWithCircle />
                            </span>
                        </div>
                        {buttonOpen && (
                            <Downshift
                                isOpen
                                onOuterClick={(event: any) => {
                                    console.log('ourter click evnets', event)
                                    if (
                                        isWhatsNewClicked.current === true &&
                                        event.id &&
                                        event.id.includes('downshift')
                                    ) {
                                        isWhatsNewClicked.current = false
                                    } else {
                                        setButtonOpen(false)
                                        isWhatsNewClicked.current = false
                                    }
                                    // setButtonOpen(false)
                                }}
                            >
                                {() => (
                                    <div>
                                        <IntentEntity insides={insides} />
                                    </div>
                                )}
                            </Downshift>
                        )}
                    </ButtonLeft>
                </HeaderContentRight>
            </HeaderContent>
            <AutomationBg>
                <CanvasMarketpalce>
                    {props.leftPopup.metaData.botId && (
                        <AssistantDialogueCanvas
                            {...props}
                            match={{
                                params: {
                                    ...props.match.params,
                                    assistantname: findBot ? findBot.name : '',
                                    dialogue_node: props.leftPopup.metaData.id
                                }
                            }}
                            assistantId={props.leftPopup.metaData.botId}
                        />
                    )}
                </CanvasMarketpalce>
            </AutomationBg>
        </AutomationWrapper>
    )
}
const IntentEntity = ({ insides }: { insides: InsideT }) => {
    const { entities, intents } = insides
    return (
        <ButtonHover>
            <ButtonRow>
                <ButtonHoverContent>
                    <h5>
                        Intent <span>({intents.length})</span>
                    </h5>
                    {intents.map((intent: IntentT, index: number) => (
                        <IntentWrapper intent={intent} index={index} />
                    ))}
                </ButtonHoverContent>
            </ButtonRow>
            <ButtonRow>
                <ButtonHoverContent>
                    <h5>
                        Entity <span>({entities.length})</span>
                    </h5>
                    {entities.map((entity: EntityT, index: number) => (
                        <EntityWrapper entity={entity} index={index} />
                    ))}
                </ButtonHoverContent>
            </ButtonRow>
        </ButtonHover>
    )
}
type IntentWrapperT = {
    intent: IntentT
    index: number
}
type IntentBody = IntentWrapperT & {
    openIntent: boolean
}
const IntentWrapper = ({ intent, index }: IntentWrapperT) => {
    const [openIntent, setOpenIntent] = useState(false)
    return (
        <TabsWrapper isActive={openIntent ? 'isActive' : null} onClick={() => setOpenIntent(!openIntent)}>
            <IntentBody intent={intent} index={index} openIntent={openIntent} />
        </TabsWrapper>
    )
}
const IntentBody = ({ intent, index, openIntent }: IntentBody) => {
    return (
        <>
            <h5>
                <div>
                    <label>{index + 1}</label>
                </div>
                <label>{intent.intent} </label>
                <span>
                    <NextDetailICon />
                </span>
            </h5>
            {openIntent && (
                <div className="tabs_dropdown">
                    <h6>
                        Examples <span>({intent.examples.length})</span>
                    </h6>
                    {intent.examples.map((example: ExampleT, index: number) => {
                        return (
                            <p>
                                <div>
                                    <label className="label_padding">{index + 1}</label>
                                </div>{' '}
                                <label>{example.text}</label>
                            </p>
                        )
                    })}
                </div>
            )}
        </>
    )
}
type EntityWrapperT = {
    entity: EntityT
    index: number
}
const EntityWrapper = ({ entity, index }: EntityWrapperT) => {
    const [openEntity, setOpenEntity] = useState(false)
    return (
        <TabsWrapper isActive={openEntity ? 'isActive' : null} onClick={() => setOpenEntity(!openEntity)}>
            <EntityBody entity={entity} index={index} openEntity={openEntity} />
        </TabsWrapper>
    )
}
type EntityBodyT = EntityWrapperT & {
    openEntity: boolean
}
const EntityBody = ({ entity, index, openEntity }: EntityBodyT) => {
    return (
        <>
            <h5>
                <div>
                    <label>{index + 1}</label>
                </div>
                <label>{entity.entity} </label>
                <span>
                    <NextDetailICon />
                </span>
            </h5>
            {openEntity && (
                <div className="tabs_dropdown">
                    <h6>
                        Values <span>({entity.values.length})</span>
                    </h6>
                    {entity.values.map((value: ValueT, index: number) => {
                        return (
                            <p>
                                <div>
                                    <label className="label_padding">{index + 1}</label>
                                </div>{' '}
                                <label>{value.value}</label>
                            </p>
                        )
                    })}
                </div>
            )}
        </>
    )
}
export const ModalRow = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 480px;
    position: absolute;
    box-shadow: 0px 10px 20px #00000029;
    padding: 0px 20px 20px;
`
export const EmptyDiv = styled.div`
    width: 87%;
    float: left;
`
export const HeaderWithImage = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    border-bottom: 0.5px solid #ccc;
    font-size: 18px;
    padding: 0px 10px 10px 10px;
    word-break: break-word;
    padding-top: 40px;
    min-height: 112px;
`

export const ImageCircle = styled.span`
    width: 24px;
    height: 24px;
    float: left;
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7e3ff;
    border-radius: 50%;
    position: relative;
    top: -2px;

    svg {
        width: 12px;
        height: 12px;
        fill: #367af2;
    }
`

export const CardBottom = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 10px;
    height: 36px;
`

export const SVGicons = styled.span`
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-left: 0px;
    display: flex;
    float: left;
`
export const DateTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;

    ${SVGicons} {
        position: relative;
        top: 1px;
        svg {
            fill: #ff7900;
            width: 10px;
            height: 10px;
        }
    }
`

export const CardTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    margin-left: 0px;
    align-items: baseline;
`
export const CardDate = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    align-items: baseline;
`
export const Downloads = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    display: flex;
    align-items: baseline;

    span {
        margin-right: 2px;
        svg {
            width: 10px;
            height: 10px;
            fill: #e57700;
            position: relative;
            top: 1px;
        }
    }
`

export const Count = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 9px;
    margin-left: 2px;
`
export const Card = styled.div`
    height: 150px;
    float: left;
    margin-right: 0px;
    border: 0.5px solid #fff;
    position: relative;
    justify-content: left;
    display: flex;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 10px;
    
    :hover {
        background: #ddfeef;
        color: #333;
        cursor: pointer;
        border: 0.5px solid #ddfeef;
    }

    ${({ isActive }) =>
        isActive &&
        `
    background: #ddfeef;
    color: #333;
    cursor: pointer;
    border: 0.5px solid #ddfeef;
    ${CardBottom} {
        color: #333;
    }
    ${DateTime} {
        color: #333;
    
    }
    ${CardDate} {
        color: #333;
    }
    ${CardTime} {
        color: #333;
    }
    ${Downloads} {
        color: #333;
    }
    ${ImageCircle} {
        background: #fff;
        svg {
            fill: #333;
        }
    `}

    &:hover ${HeaderWithImage} {
        color: #333;
    }
    &:hover ${CardBottom} {
        color: #333;
    }
    &:hover ${DateTime} {
        color: #333;
     
    }
    &:hover ${CardDate} {
        color: #333;
    }
    &:hover ${CardTime} {
        color: #333;
    }
    &:hover ${Downloads} {
        color: #333;
    }
    // &:hover ${Count} {
    //     color: #333;
    // }

    &:hover ${ImageCircle} {
        background: #fff;
        svg {
            fill: #333;
        }
    }
`
export const CardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 10px;
    overflow-y: scroll;
    height: calc(100vh - 360px);
    margin-top: 10px;
    place-content: baseline;
    padding-bottom: 30px;

    .no_data_available {
        width: calc(100% - -220px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 390px);

        img {
            width: 48px;
            height: 48px;
            animation-name: spin;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @-ms-keyframes spin {
            from {
                -ms-transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
            }
        }
        @-moz-keyframes spin {
            from {
                -moz-transform: rotate(0deg);
            }
            to {
                -moz-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            from {
                -webkit-transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        p {
            font-family: 'Gordita-Regular';
            font-size: 14px;
            color: #000;
            margin-bottom: 0px;
        }
    }

    ${Header} {
        color: #000;
        font-family: 'Gordita-Medium';
        font-size: 14px;
        margin-left: 7px;
        margin-top: 2px;
        width: 100%;
        padding: 0px;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
    ${Paragraph} {
        color: #000;
        font-family: 'Gordita-Regular';
        font-size: 10px;
        margin-left: 7px;
        width: 100%;
        padding: 0px;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        margin-bottom: 0px;
        margin-top: 0px;
    }
`
