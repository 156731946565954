import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import {
  Content,
  Editor,
  EditorContent,
  EditorProvider,
  useCurrentEditor,
  useEditor,
} from '@tiptap/react';
import Link from '@tiptap/extension-link';
import Focus from '@tiptap/extension-focus';
import StarterKit from '@tiptap/starter-kit';
import React, { forwardRef, useCallback, useContext, useState } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { EmojiPopup } from '@/Assistant/Editor/EmojiPopup';
import Mention from '@tiptap/extension-mention';
import Downshift from 'downshift';
import {
  CheckColorIcon,
  CloseColorIcon,
  CloseIcon,
  CloseIconWithCircle,
  EmojiIcon,
} from '@/common/Icons/Icons';
import { EmojiObjPopup } from './EmojiObject';
import { mentions } from './LiveagentMockFile';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { useEffect, useImperativeHandle } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';
import { TipTapContext, useAgentList } from './LivaAgentApi';
import Underline from '@tiptap/extension-underline';
import { tiptapJsonConverter } from './MarkDown';
import {
  Input,
  InputContainer,
  InputContent,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';

const Attachment = () => {
  const { attachmentFiles, removeFiles } = useContext(TipTapContext);
  const deleteAttachment = (index: number) => {
    removeFiles(index);
  };
  return (
    <div className="live_chat_attach_box flex">
      {attachmentFiles.map((file: string, index: number) => (
        <div className="live_chat_attach flex">
          <p>{file}</p>
          <button
            onClick={() => {
              deleteAttachment(index);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      ))}
    </div>
  );
};

export const MenuBar = (props: any) => {
  // const { editor } = useCurrentEditor();
  const { editor, setPopup } = props;

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="la_chat_area_icon">
      <div>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={
            editor.isActive('bold')
              ? 'chat_icon_btn is-active'
              : 'chat_icon_btn'
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M18.25 25H9V7h8.5a5.25 5.25 0 014 8.65A5.25 5.25 0 0118.25 25zM12 22h6.23a2.25 2.25 0 100-4.5H12zm0-7.5h5.5a2.25 2.25 0 100-4.5H12z" />
            <path
              data-name="&lt;Transparent Rectangle&gt;"
              fill="none"
              d="M0 0H32V32H0z"
            />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={
            editor.isActive('italic')
              ? 'chat_icon_btn is-active'
              : 'chat_icon_btn'
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M25 9L25 7 12 7 12 9 17.14 9 12.77 23 7 23 7 25 20 25 20 23 14.86 23 19.23 9 25 9z" />
            <path
              data-name="&lt;Transparent Rectangle&gt;"
              fill="none"
              d="M0 0H32V32H0z"
            />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={
            editor.isActive('underline')
              ? 'chat_icon_btn is-active'
              : 'chat_icon_btn'
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M4 26H28V28H4z" />
            <path d="M16 23a7 7 0 01-7-7V5h2v11a5 5 0 0010 0V5h2v11a7 7 0 01-7 7z" />
            <path
              data-name="&lt;Transparent Rectangle&gt;"
              fill="none"
              d="M0 0H32V32H0z"
            />
          </svg>
        </button>
      </div>
      <div>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive('bulletList')
              ? 'chat_icon_btn is-active'
              : 'chat_icon_btn'
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <circle cx={7} cy={9} r={3} />
            <circle cx={7} cy={23} r={3} />
            <path d="M16 22H30V24H16z" />
            <path d="M16 8H30V10H16z" />
            <path
              data-name="&lt;Transparent Rectangle&gt;"
              fill="none"
              d="M0 0H32V32H0z"
            />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive('orderedList')
              ? 'chat_icon_btn is-active'
              : 'chat_icon_btn'
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
          >
            <path d="M16 22H30V24H16z" />
            <path d="M16 8H30V10H16z" />
            <path d="M8 12L8 4 6 4 6 5 4 5 4 7 6 7 6 12 4 12 4 14 6 14 8 14 10 14 10 12 8 12z" />
            <path d="M10 28H4v-4a2 2 0 012-2h2v-2H4v-2h4a2 2 0 012 2v2a2 2 0 01-2 2H6v2h4z" />
            <path
              data-name="&lt;Transparent Rectangle&gt;"
              fill="none"
              d="M0 0H32V32H0z"
            />
          </svg>
        </button>
      </div>
      <button
        onClick={() => {
          // setLink();
          setPopup();
        }}
        className={
          editor.isActive('link')
            ? 'chat_icon_btn link_btn is-active'
            : 'chat_icon_btn link_btn'
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M29.25 6.76a6 6 0 00-8.5 0l1.42 1.42a4 4 0 115.67 5.67l-8 8a4 4 0 11-5.67-5.66l1.41-1.42-1.41-1.42-1.42 1.42a6 6 0 000 8.5A6 6 0 0017 25a6 6 0 004.27-1.76l8-8a6 6 0 00-.02-8.48z" />
          <path d="M4.19 24.82a4 4 0 010-5.67l8-8a4 4 0 015.67 0A3.94 3.94 0 0119 14a4 4 0 01-1.17 2.85L15.71 19l1.42 1.42 2.12-2.12a6 6 0 00-8.51-8.51l-8 8a6 6 0 000 8.51A6 6 0 007 28a6.07 6.07 0 004.28-1.76l-1.42-1.42a4 4 0 01-5.67 0z" />
          <path
            data-name="&lt;Transparent Rectangle&gt;"
            fill="none"
            d="M0 0H32V32H0z"
          />
        </svg>
      </button>
      <button
        onClick={() => props.setFontType(false)}
        className="chat_icon_btn close_btn"
        // disabled={!editor.can().chain().focus().redo().run()}
      >
        <CloseIconWithCircle />
      </button>
    </div>
  );
};

const content = '';

export const MentionList = forwardRef((props: any, ref: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.id, label: item.first_name + item.last_name });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));
  return (
    <div className="items">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.first_name + item.last_name}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});

const suggest = (agentList: any[]) => {
  // const agentNameList = agentList.map(ag=>ag.display_name)
  const items = ({ query }) => {
    return agentList;
  };

  const render = () => {
    let component: ReactRenderer<unknown, unknown>;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          // theme
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        // component.destroy();
      },
    };
  };
  return { items, render };
};

export const TiptapEditor = (props: any) => {
  const {
    tipTapValue,
    setTipTapValue,
    fontType,
    setFontType,
    showEmojiPopup,
    setShowEmojiPopup,
    clearContent,
    setClearContent,
    onEnter,
    feedback,
    falseFeedback,
    isAttachment,
  } = props;
  const [linkPopUp, setLinkPopUp] = useState<boolean>(false);
  const setPopup = () => setLinkPopUp(true);
  const [linkText, setLinkText] = useState('');
  const [hyperLink, setHyperLink] = useState('');

  const IsValidURL = (url: string) =>
    url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
  // const { editor } = useCurrentEditor();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        placeholder: 'Type a message',
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Link.configure({
        openOnClick: false,
        autolink: false,
      }),
      Focus.configure({
        className: 'has-focus',
        mode: 'all',
      }),
    ],
    content: tipTapValue,
    onUpdate: ({ editor }) => {
      const value = editor.getJSON();
      setTipTapValue(value);
      console.log(
        value,
        JSON.stringify(value),
        tiptapJsonConverter(value),
        'editorvalue'
      );
    },
  });
  const handleFocusChange = () => {
    if (editor && editor.isFocused) {
      setLinkPopUp(false)
    }
  };
  editor && editor.on('focus', handleFocusChange)
  const selectedText =
    editor &&
    editor.view.state.doc.cut(
      editor.view.state.selection.from,
      editor.view.state.selection.to
    ).textContent;

  useEffect(() => {
    if (linkPopUp && selectedText) {
      setHyperLink(
        editor.getAttributes('link')['href']
          ? editor.getAttributes('link')['href']
          : ''
      );
      setLinkText(selectedText);
    }
  }, [linkPopUp]);

  useEffect(() => {
    if (feedback) {
      editor && editor.commands.clearContent(true);
      editor &&
        editor.commands.insertContent({
          type: 'doc',
          content: [
            {
              type: 'codeBlock',
              attrs: {
                language: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Collect user feedback',
                },
              ],
            },
          ],
        });
      editor && editor.setEditable(false);
    } else {
      editor && editor.commands.clearContent(true);
      editor && editor.setEditable(true);
    }
  }, [feedback]);
  useEffect(() => {
    setTimeout(() => {
      editor && editor.commands.setContent(content);
    });
  }, [content]);

  useEffect(() => {
    if (clearContent && editor) {
      editor.commands.clearContent(true);
      setClearContent(false);
    }
  }, [clearContent]);

  const pressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!fontType && e.key === 'Enter' && e.shiftKey && editor) {
      // editor.commands.enter();
    } else if (!fontType && e.key === 'Enter' && editor) {
      onEnter();
      // setClearContent(true);
    }
  };

  const generateLink = (hyperLink: any, linkDisplayText: any) => {
    editor &&
      editor.commands.insertContent(
        `<a href='${hyperLink}' class="custom-link">${linkDisplayText}</a>`,
        {
          parseOptions: {
            preserveWhitespace: false,
          },
        }
      );
    setHyperLink('');
    setLinkText('');
  };

  const [showAttachment, setShowAttachment] = useState<boolean>(false);

  useEffect(() => {
    if (isAttachment) {
      setShowAttachment(true);
    }
  }, [isAttachment]);

  return (
    <>
      {fontType && (
        <MenuBar
          setFontType={setFontType}
          editor={editor}
          setPopup={setPopup}
        />
      )}
      <EditorContent
        // style={{minHeight: '48px', maxHeight: '60px'}}
        className="chat_message"
        editor={editor}
        onKeyDown={pressEnter}
      />
      {showAttachment && <Attachment />}
      {showEmojiPopup && editor && (
        <Downshift isOpen={true} onOuterClick={() => setShowEmojiPopup(false)}>
          {() => (
            <span>
              <EmojiObjPopup
                onSelected={(emoji: any) => {
                  editor.chain().focus().setEmoji(emoji).run();
                  // insertEmoji(emoji,{emojiUnicode:emoji})
                }}
              />
            </span>
          )}
        </Downshift>
      )}
      {linkPopUp && editor && (
        <div className="message_tool_link">
          <InputContainer
            className="url_input_box"
            style={{ marginBottom: 10 }}
          >
            <Input
              type="text"
              value={linkText}
              onChange={(e: any) => setLinkText(e.target.value)}
            />
            <InputContent>Text</InputContent>
          </InputContainer>

          <InputContainer className="url_input_box" style={{ marginBottom: 0 }}>
            <Input
              type="text"
              value={hyperLink}
              onChange={(e: any) => setHyperLink(e.target.value)}
            />
            <InputContent>URL </InputContent>
          </InputContainer>

          <div className="tool_link_btn">
            <span
              className="liveAt_link"
              // {
              //   linkText.length == 0 || IsValidURL(hyperLink) === null
              //     ? 'liveAt_filter_delete editor_btn_disabled'
              //     : 'liveAt_filter_delete'
              // }
              // style={{
              //   position: 'absolute',
              //   bottom: 86,
              //   zIndex: 1000,
              //   cursor: 'pointer',
              //   left: -20,
              // }}
              onClick={() => {
                setLinkPopUp(false);
                setHyperLink('');
                setLinkText('');
              }}
            >
              <CloseColorIcon />
            </span>
            <span
              onClick={() => {
                setLinkPopUp(false);
                generateLink(hyperLink, linkText);
              }}
              className={
                linkText.length == 0 || IsValidURL(hyperLink) === null
                  ? 'liveAt_link editor_btn_disabled'
                  : 'liveAt_link'
              }
              // style={{
              //   position: 'absolute',
              //   bottom: 86,
              //   zIndex: 1000,
              //   cursor: 'pointer',
              //   left: 5,
              // }}
            >
              <CheckColorIcon />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export const CommentEditor = (props: any) => {
  const {
    tipTapValue,
    setTipTapValue,
    fontType,
    setFontType,
    showEmojiPopup,
    setShowEmojiPopup,
    clearContent,
    setClearContent,
    onEnter,
    agentList,
  } = props;
  // const { editor } = useCurrentEditor();
  const suggestion = suggest(agentList);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: `Write a comment and @mention teammates.
This is only visible to your team.`,
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${
            node.attrs['label'] ?? node.attrs['id']
          }`;
        },
        suggestion,
      }),
      Focus.configure({
        className: 'has-focus',
        mode: 'all',
      }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      const value = editor.getJSON();
      setTipTapValue(value);
    },
  });
  useEffect(() => {
    setTimeout(() => {
      editor && editor.commands.setContent(content);
    });
  }, [content]);
  useEffect(() => {
    if (clearContent && editor) {
      editor.commands.clearContent(true);
      setClearContent(false);
    }
  }, [clearContent]);
  const pressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!fontType && e.key === 'Enter' && e.shiftKey) {
    } else if (!fontType && e.key === 'Enter' && editor) {
      onEnter();
      // setClearContent(true);
    }
  };
  return (
    <div className="editor">
      {fontType && <MenuBar setFontType={setFontType} editor={editor} />}
      <EditorContent editor={editor} onKeyDown={pressEnter} />

      {showEmojiPopup && editor && (
        <Downshift isOpen={true} onOuterClick={() => setShowEmojiPopup(false)}>
          {() => (
            <span>
              <EmojiObjPopup
                onSelected={(emoji: any) => {
                  editor.chain().focus().setEmoji(emoji).run();
                }}
              />
            </span>
          )}
        </Downshift>
      )}
    </div>
  );
};
