/*eslint-disable*/

import lazyGA from '@/common/google-analytics/tracking'
import { deleteJson, getJson, postJson } from '@/common/utils/axios-utils'

import { automationAPIURLWithWorkspace } from '@/common/utils/api-utils'
import {
    ActionDialogT,
    AskQueryDialogT,
    AutomationDialogT,
    DialogT,
    ExitDialogT,
    JumpDialogT,
    NewRecognizeDialogT,
    OptionsResponseDialogT,
    RecognizeDialogT,
    SlotContainerDialogT,
    SlotDialogT,
    TextResponseDialogT
} from '../Types/WatsonT'

export const watsonApiURL = (workspace: string, api: string) => process.env.REACT_APP_WATSON_API + '/' + workspace + api

export const watsonApiURLWithoutWorkspace = (api: string) => process.env.REACT_APP_WATSON_API + api
export const socketApiURLWithoutWorkspace = (api: string) => {
    console.log(process.env, api)
    return window.location.origin + api
}

const settingsApiURL = (workspace: string, api: string) => process.env.REACT_APP_SETTINGS_API + '/' + workspace + api

enum SettingsApi {
    settings = '/settings'
}

export enum EntityApi {
    AllEntity = '/entities',
    Create = '/entity/create/',
    Update = '/entity/update/',
    ListDelete = '/entities/delete'
}
export enum IntentApi {
    AllIntents = '/intents',
    AddIntent = '/intent/create/',
    UpdateIntent = '/intent/update/',
    DeleteIntent = '/intent/delete/',
    DeleteMultipleIntent = '/intents/delete/'
}

export const createIntent = (workspace: string, data: any) =>
    postJson(watsonApiURL(workspace, `${IntentApi.AddIntent}${data.intent}`), data).then(res => res.data)

export const UpdateIntent = (workspace: string, data: any) =>
    postJson(watsonApiURL(workspace, `${IntentApi.UpdateIntent}${data.intent}`), data).then(res => res.data)

export const fetchSettings = (workspacename: string) => getJson(settingsApiURL(workspacename, SettingsApi.settings))

export enum DialogApi {
    Dialogs = '/dialog_nodes',

    createActionDialog = '/action_node/create',
    updateActionDialog = '/action_node/update/',
    removeActionDialog = '/action_node/remove/',

    createRecogniseDialog = '/if_node/create',
    updateRecogniseDialog = '/if_node/update/',
    removeRecogniseDialog = '/if_node/delete/',

    parseCondition = '/parse_condition',

    createTextResponseDialog = '/text_node/create',
    updateTextResponseDialog = '/text_node/update/',
    removeTextResponseDialog = '/text_node/remove/',

    createOptionalResponseDialog = '/option_node/create',
    updateOptionalResponseDialog = '/option_node/update/',
    removeOptionalResponseDialog = '/option_node/remove/',

    createQueryDialog = '/qa_node/create',
    updateQueryDialog = '/qa_node/update/',
    removeQueryDialog = '/qa_node/remove/',

    createSlotContainerDialog = '/slot_container/create',
    updateSlotContainerDialog = '/slot_container/update/',
    removeSlotContainerDialog = '/slot_container/remove/',

    createSlotDialog = '/slot_node/create',
    updateSlotDialog = '/slot_node/update/',
    removeSlotDialog = '/slot_node/remove/',

    flowOutputVariable = '/generateFlowVarible',

    Message = '/tryme/message.io'
}

export const fetchDialogs = (workspacename: string) => getJson(watsonApiURL(workspacename, DialogApi.Dialogs))

// Automation Dialog Api's

export const fetchAutomationList = (workspacename: string) =>
    getJson(automationAPIURLWithWorkspace(workspacename, `/automation/fetch/all`))

export const fetchUIJsonForFlows = (workspacename: string, flowsId: string[]) => {
    console.log('flowsId:::::', flowsId)
    return postJson(automationAPIURLWithWorkspace(workspacename, `/automation/details`), { automations: flowsId }).then(
        res => res.data
    )
}

export const fetchActionConfigs = (workspacename: string, actionIds: string[]) =>
    postJson(automationAPIURLWithWorkspace(workspacename, `/actions/configs`), { actions: actionIds }).then(
        res => res.data
    )

export const fetchFlowOutputVariable = () => getJson(watsonApiURLWithoutWorkspace(DialogApi.flowOutputVariable))

export const fetchAutomationConfig = (workspacename: string, automation: string) =>
    getJson(automationAPIURLWithWorkspace(workspacename, `/automation/${automation}/config`))

export const fetchActionConfig = (workspacename: string, actionId: string) =>
    getJson(automationAPIURLWithWorkspace(workspacename, `/actions/${actionId}/config`))

// Recognise Dialog Api's

export const createRecogniseDialog = (workspacename: string, data: NewRecognizeDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createRecogniseDialog), data).then(res => res.data)

export const updateRecogniseDialog = (workspacename: string, dialog_node: string, data: RecognizeDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateRecogniseDialog + dialog_node), data).then(res => res.data)

const removeRecogniseDialog = (workspacename: string, dialog_node: string, data: RecognizeDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeRecogniseDialog + dialog_node), data)

export const conditionParser = (condition: string) =>
    postJson(watsonApiURLWithoutWorkspace(DialogApi.parseCondition), { condition }).then(res => res.data)

// Action Dialog Api's

export const createActionDialog = (workspacename: string, data: ActionDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createActionDialog), data).then(res => res.data)

export const updateActionDialog = (workspacename: string, dialog_node: string, data: ActionDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateActionDialog + dialog_node), data).then(res => res.data)

const removeActionDialog = (workspacename: string, dialog_node: string, data: ActionDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeActionDialog + dialog_node), data)

// Text Response Dialog Api's

export const createTextResponseDialog = (workspacename: string, data: TextResponseDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createTextResponseDialog), data).then(res => res.data)

export const updateTextResponseDialog = (workspacename: string, dialog_node: string, data: TextResponseDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateTextResponseDialog + dialog_node), data).then(res => res.data)

const removeTextResponseDialog = (workspacename: string, dialog_node: string, data: TextResponseDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeTextResponseDialog + dialog_node), data)

// Optional Response Dialog Api's

export const createOptionalResponseDialog = (workspacename: string, data: OptionsResponseDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createOptionalResponseDialog), data).then(res => res.data)

export const updateOptionalResponseDialog = (
    workspacename: string,
    dialog_node: string,
    data: OptionsResponseDialogT
) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateOptionalResponseDialog + dialog_node), data).then(
        res => res.data
    )

const removeOptionalResponseDialog = (workspacename: string, dialog_node: string, data: OptionsResponseDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeOptionalResponseDialog + dialog_node), data)

// Slot Dialog Api's

export const createSlotDialog = (workspacename: string, data: SlotDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createSlotDialog), data).then(res => res.data)

export const updateSlotDialog = (workspacename: string, dialog_node: string, data: SlotDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateSlotDialog + dialog_node), data).then(res => res.data)

export const removeSlotDialog = (workspacename: string, dialog_node: string, data: SlotDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeSlotDialog + dialog_node), data)

// Slot Container Dialog Api's

export const createSlotContainerDialog = (workspacename: string, data: SlotContainerDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createSlotContainerDialog), {
        slots: data.slots,
        previous_sibling: data.previous_sibling,
        parent: data.parent
    }).then(res => res.data)

export const updateSlotContainerDialog = (workspacename: string, dialog_node: string, data: SlotContainerDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateSlotContainerDialog + dialog_node), data).then(res => res.data)

const removeSlotContainerDialog = (workspacename: string, dialog_node: string, data: SlotContainerDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeSlotContainerDialog + dialog_node), data)

// Query Dialog Api's

export const createQueryDialog = (workspacename: string, data: AskQueryDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.createQueryDialog), data).then(res => res.data)

export const updateQueryDialog = (workspacename: string, dialog_node: string, data: AskQueryDialogT) =>
    postJson(watsonApiURL(workspacename, DialogApi.updateQueryDialog + dialog_node), data).then(res => res.data)

const removeQueryDialog = (workspacename: string, dialog_node: string, data: AskQueryDialogT) =>
    deleteJson(watsonApiURL(workspacename, DialogApi.removeQueryDialog + dialog_node), data)

// Update All Dialogs
export const updateDailouge = async (
    workspacename: string,
    node:
        | RecognizeDialogT
        | TextResponseDialogT
        | OptionsResponseDialogT
        | AskQueryDialogT
        | SlotDialogT
        | SlotContainerDialogT
        | AutomationDialogT
        | JumpDialogT
        | ExitDialogT
) => {
    switch (node.type) {
        case 'action':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Jump to Node Deleted'
            })
            return await updateActionDialog(workspacename, node.dialog_node, node as ActionDialogT)
        case 'if':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Recognise Dialog Deleted'
            })
            return await updateRecogniseDialog(workspacename, node.dialog_node, node as RecognizeDialogT)
        case 'text-response':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Send Message Node Deleted'
            })
            return await updateTextResponseDialog(workspacename, node.dialog_node, node as TextResponseDialogT)
        case 'option-response':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Options Node Deleted'
            })
            return await updateOptionalResponseDialog(workspacename, node.dialog_node, node as OptionsResponseDialogT)
        case 'qa':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Ask a question Node Deleted'
            })
            return await updateQueryDialog(workspacename, node.dialog_node, node as AskQueryDialogT)
        case 'slot-container':
            lazyGA().event({
                category: 'Dialog Editor',
                action: 'Get Details Node Deleted'
            })
            return await updateSlotContainerDialog(workspacename, node.dialog_node, node as SlotContainerDialogT)
        default:
            console.error(`Sorry, we are find this type of node :(`)
    }
}

// delete All Dialogs
export const deleteDailouge = async (
    workspacename: string,
    node:
        | DialogT
        | RecognizeDialogT
        | TextResponseDialogT
        | OptionsResponseDialogT
        | AskQueryDialogT
        | SlotContainerDialogT
) => {
    switch (node.type) {
        case 'action':
            return await removeActionDialog(workspacename, node.dialog_node, node as ActionDialogT)
        case 'if':
            return await removeRecogniseDialog(workspacename, node.dialog_node, node as RecognizeDialogT)
        case 'text-response':
            return await removeTextResponseDialog(workspacename, node.dialog_node, node as TextResponseDialogT)
        case 'option-response':
            return await removeOptionalResponseDialog(workspacename, node.dialog_node, node as OptionsResponseDialogT)
        case 'qa':
            return await removeQueryDialog(workspacename, node.dialog_node, node as AskQueryDialogT)
        case 'slot-container':
            return await removeSlotContainerDialog(workspacename, node.dialog_node, node as SlotContainerDialogT)
        default:
            console.error(`Sorry, we are find this type of node :(`)
            return null
    }
}

export const findCaption = (node: DialogT) => {
    if (node && node.context && node.context.SYS_ACTIONS && node.context.SYS_ACTIONS.length == 2) {
        return 'Delete Automation'
    } else if (node && node.metadata && node.metadata.hide_jump_to == false) {
        if (node && node.jump_to && node.jump_to.dialog_node == 'feedback') {
            return 'Delete Exit'
        } else if (node && node.jump_to && node.jump_to.dialog_node == 'exit') {
            return 'Delete Exit'
        } else {
            return 'Delete Jump'
        }
    }
    switch (node.type) {
        case 'if':
            return 'Delete Dialog'
        case 'text-response':
            return 'Delete Message'
        case 'option-response':
            return 'Delete Options'
        case 'qa':
            return 'Delete Question'
        case 'slot-container':
            return 'Delete Verify user details'
        default:
            return ':('
    }
}

export const findSubtitle = (node: DialogT) => {
    if (node && node.context && node.context.SYS_ACTIONS && node.context.SYS_ACTIONS.length == 2) {
        return 'Are you sure to delete this workflow automation? '
    } else if (node && node.metadata && node.metadata.hide_jump_to == false) {
        if (node && node.jump_to && node.jump_to.dialog_node == 'feedback') {
            return 'Are you sure to delete Exit?'
        } else if (node && node.jump_to && node.jump_to.dialog_node == 'exit') {
            return 'Are you sure to delete Exit?'
        } else {
            return 'Are you sure to delete jump?'
        }
    }
    switch (node.type) {
        case 'if':
            return 'Are you sure to delete the dialog?'
        case 'text-response':
            return 'Are you sure to delete the message? '
        case 'option-response':
            return 'Are you sure to delete the options?'
        case 'qa':
            return 'Are you sure to delete the question?'
        case 'slot-container':
            return 'Are you sure to delete Verify user details?'
        default:
            return ':('
    }
}
