export const tipTapEmoji = [
    {
      emoji: '😀',
      name: 'grinning',
      shortcodes: ['grinning', 'grinning_face'],
      tags: ['face', 'grin'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f600.png',
    },
    {
      emoji: '😃',
      name: 'smiley',
      shortcodes: ['grinning_face_with_big_eyes', 'smiley'],
      tags: ['face', 'mouth', 'open', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f603.png',
    },
    {
      emoji: '😄',
      name: 'smile',
      shortcodes: ['grinning_face_with_closed_eyes', 'smile'],
      tags: ['eye', 'face', 'mouth', 'open', 'smile'],
      group: 'smileys & emotion',
      emoticons: [':D'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f604.png',
    },
    {
      emoji: '😁',
      name: 'grin',
      shortcodes: ['beaming_face', 'grin'],
      tags: ['eye', 'face', 'grin', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f601.png',
    },
    {
      emoji: '😆',
      name: 'laughing',
      shortcodes: ['laughing', 'lol', 'satisfied', 'squinting_face'],
      tags: ['face', 'laugh', 'mouth', 'satisfied', 'smile'],
      group: 'smileys & emotion',
      emoticons: ['xD', 'XD'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f606.png',
    },
    {
      emoji: '😅',
      name: 'sweat_smile',
      shortcodes: ['grinning_face_with_sweat', 'sweat_smile'],
      tags: ['cold', 'face', 'open', 'smile', 'sweat'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f605.png',
    },
    {
      emoji: '🤣',
      name: 'rofl',
      shortcodes: ['rofl'],
      tags: ['face', 'floor', 'laugh', 'rofl', 'rolling', 'rotfl'],
      group: 'smileys & emotion',
      emoticons: [":'D"],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f923.png',
    },
    {
      emoji: '😂',
      name: 'joy',
      shortcodes: ['joy', 'lmao', 'tears_of_joy'],
      tags: ['face', 'joy', 'laugh', 'tear'],
      group: 'smileys & emotion',
      emoticons: [":')"],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f602.png',
    },
    {
      emoji: '🙂',
      name: 'slightly_smiling_face',
      shortcodes: ['slightly_smiling_face'],
      tags: ['face', 'smile'],
      group: 'smileys & emotion',
      emoticons: [':)'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f642.png',
    },
    {
      emoji: '🙃',
      name: 'upside_down_face',
      shortcodes: ['upside_down_face'],
      tags: ['face', 'upside-down'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f643.png',
    },
    {
      emoji: '😉',
      name: 'wink',
      shortcodes: ['wink', 'winking_face'],
      tags: ['face', 'wink'],
      group: 'smileys & emotion',
      emoticons: [';)'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f609.png',
    },
    {
      emoji: '😊',
      name: 'blush',
      shortcodes: ['blush', 'smiling_face_with_closed_eyes'],
      tags: ['blush', 'eye', 'face', 'smile'],
      group: 'smileys & emotion',
      emoticons: [':>'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60a.png',
    },
    {
      emoji: '😇',
      name: 'innocent',
      shortcodes: ['halo', 'innocent'],
      tags: ['angel', 'face', 'fantasy', 'halo', 'innocent'],
      group: 'smileys & emotion',
      emoticons: ['o:)', 'O:)'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f607.png',
    },
    {
      emoji: '🥰',
      name: 'smiling_face_with_three_hearts',
      shortcodes: ['smiling_face_with_3_hearts'],
      tags: ['adore', 'crush', 'hearts', 'in love'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f970.png',
    },
    {
      emoji: '😍',
      name: 'heart_eyes',
      shortcodes: ['heart_eyes', 'smiling_face_with_heart_eyes'],
      tags: ['eye', 'face', 'love', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60d.png',
    },
    {
      emoji: '🤩',
      name: 'star_struck',
      shortcodes: ['star_struck'],
      tags: ['eyes', 'face', 'grinning', 'star'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f929.png',
    },
    {
      emoji: '😘',
      name: 'kissing_heart',
      shortcodes: ['blowing_a_kiss', 'kissing_heart'],
      tags: ['face', 'kiss'],
      group: 'smileys & emotion',
      emoticons: [':x', ':X'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f618.png',
    },
    {
      emoji: '😗',
      name: 'kissing',
      shortcodes: ['kissing', 'kissing_face'],
      tags: ['face', 'kiss'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f617.png',
    },
    {
      emoji: '☺',
      name: 'relaxed',
      shortcodes: ['relaxed', 'smiling_face'],
      tags: ['face', 'outlined', 'relaxed', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/263a-fe0f.png',
    },
    {
      emoji: '😚',
      name: 'kissing_closed_eyes',
      shortcodes: ['kissing_closed_eyes', 'kissing_face_with_closed_eyes'],
      tags: ['closed', 'eye', 'face', 'kiss'],
      group: 'smileys & emotion',
      emoticons: [':*'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61a.png',
    },
    {
      emoji: '😙',
      name: 'kissing_smiling_eyes',
      shortcodes: ['kissing_face_with_smiling_eyes', 'kissing_smiling_eyes'],
      tags: ['eye', 'face', 'kiss', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f619.png',
    },
    {
      emoji: '🥲',
      name: 'smiling_face_with_tear',
      shortcodes: ['smiling_face_with_tear'],
      tags: ['grateful', 'proud', 'relieved', 'smiling', 'tear', 'touched'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f972.png',
    },
    {
      emoji: '😋',
      name: 'yum',
      shortcodes: ['savoring_food', 'yum'],
      tags: ['delicious', 'face', 'savouring', 'smile', 'yum'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60b.png',
    },
    {
      emoji: '😛',
      name: 'stuck_out_tongue',
      shortcodes: ['face_with_tongue', 'stuck_out_tongue'],
      tags: ['face', 'tongue'],
      group: 'smileys & emotion',
      emoticons: [':p', ':P'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61b.png',
    },
    {
      emoji: '😜',
      name: 'stuck_out_tongue_winking_eye',
      shortcodes: ['stuck_out_tongue_winking_eye'],
      tags: ['eye', 'face', 'joke', 'tongue', 'wink'],
      group: 'smileys & emotion',
      emoticons: [';p', ';P'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61c.png',
    },
    {
      emoji: '🤪',
      name: 'zany_face',
      shortcodes: ['zany', 'zany_face'],
      tags: ['eye', 'goofy', 'large', 'small'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92a.png',
    },
    {
      emoji: '😝',
      name: 'stuck_out_tongue_closed_eyes',
      shortcodes: ['stuck_out_tongue_closed_eyes'],
      tags: ['eye', 'face', 'horrible', 'taste', 'tongue'],
      group: 'smileys & emotion',
      emoticons: ['xp', 'xP', 'XP'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61d.png',
    },
    {
      emoji: '🤑',
      name: 'money_mouth_face',
      shortcodes: ['money_mouth_face'],
      tags: ['face', 'money', 'mouth'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f911.png',
    },
    {
      emoji: '🤗',
      name: 'hugs',
      shortcodes: ['hug', 'hugging', 'hugging_face'],
      tags: ['face', 'hug', 'hugging', 'open hands', 'smiling face'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f917.png',
    },
    {
      emoji: '🤭',
      name: 'hand_over_mouth',
      shortcodes: ['face_with_hand_over_mouth', 'hand_over_mouth'],
      tags: ['whoops'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92d.png',
    },
    {
      emoji: '🤫',
      name: 'shushing_face',
      shortcodes: ['shush', 'shushing_face'],
      tags: ['quiet', 'shush'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92b.png',
    },
    {
      emoji: '🤔',
      name: 'thinking',
      shortcodes: ['thinking', 'thinking_face', 'wtf'],
      tags: ['face', 'thinking'],
      group: 'smileys & emotion',
      emoticons: [':l', ':L'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f914.png',
    },
    {
      emoji: '🤐',
      name: 'zipper_mouth_face',
      shortcodes: ['zipper_mouth', 'zipper_mouth_face'],
      tags: ['face', 'mouth', 'zipper'],
      group: 'smileys & emotion',
      emoticons: [':z', ':Z'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f910.png',
    },
    {
      emoji: '🤨',
      name: 'raised_eyebrow',
      shortcodes: ['face_with_raised_eyebrow', 'raised_eyebrow'],
      tags: ['distrust', 'skeptic'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f928.png',
    },
    {
      emoji: '😐',
      name: 'neutral_face',
      shortcodes: ['neutral', 'neutral_face'],
      tags: ['deadpan', 'face', 'meh', 'neutral'],
      group: 'smileys & emotion',
      emoticons: [':|'],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f610.png',
    },
    {
      emoji: '😑',
      name: 'expressionless',
      shortcodes: ['expressionless', 'expressionless_face'],
      tags: ['expressionless', 'face', 'inexpressive', 'meh', 'unexpressive'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f611.png',
    },
    {
      emoji: '😶',
      name: 'no_mouth',
      shortcodes: ['no_mouth'],
      tags: ['face', 'mouth', 'quiet', 'silent'],
      group: 'smileys & emotion',
      emoticons: [':#'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f636.png',
    },
    {
      emoji: '😶‍🌫',
      name: 'face_in_clouds',
      shortcodes: ['in_clouds'],
      tags: ['absentminded', 'face in the fog', 'head in clouds'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f636-200d-1f32b-fe0f.png',
    },
    {
      emoji: '😏',
      name: 'smirk',
      shortcodes: ['smirk', 'smirking', 'smirking_face'],
      tags: ['face', 'smirk'],
      group: 'smileys & emotion',
      emoticons: [':j'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60f.png',
    },
    {
      emoji: '😒',
      name: 'unamused',
      shortcodes: ['unamused', 'unamused_face'],
      tags: ['face', 'unamused', 'unhappy'],
      group: 'smileys & emotion',
      emoticons: [':?'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f612.png',
    },
    {
      emoji: '🙄',
      name: 'roll_eyes',
      shortcodes: ['rolling_eyes'],
      tags: ['eyeroll', 'eyes', 'face', 'rolling'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f644.png',
    },
    {
      emoji: '😬',
      name: 'grimacing',
      shortcodes: ['grimacing', 'grimacing_face'],
      tags: ['face', 'grimace'],
      group: 'smileys & emotion',
      emoticons: ['8D'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62c.png',
    },
    {
      emoji: '😮‍💨',
      name: 'face_exhaling',
      shortcodes: ['exhale', 'exhaling'],
      tags: ['exhale', 'gasp', 'groan', 'relief', 'whisper', 'whistle'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62e-200d-1f4a8.png',
    },
    {
      emoji: '🤥',
      name: 'lying_face',
      shortcodes: ['lying', 'lying_face'],
      tags: ['face', 'lie', 'pinocchio'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f925.png',
    },
    {
      emoji: '😌',
      name: 'relieved',
      shortcodes: ['relieved', 'relieved_face'],
      tags: ['face', 'relieved'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60c.png',
    },
    {
      emoji: '😔',
      name: 'pensive',
      shortcodes: ['pensive', 'pensive_face'],
      tags: ['dejected', 'face', 'pensive'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f614.png',
    },
    {
      emoji: '😪',
      name: 'sleepy',
      shortcodes: ['sleepy', 'sleepy_face'],
      tags: ['face', 'good night', 'sleep'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62a.png',
    },
    {
      emoji: '🤤',
      name: 'drooling_face',
      shortcodes: ['drooling', 'drooling_face'],
      tags: ['drooling', 'face'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f924.png',
    },
    {
      emoji: '😴',
      name: 'sleeping',
      shortcodes: ['sleeping', 'sleeping_face'],
      tags: ['face', 'good night', 'sleep', 'zzz'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f634.png',
    },
    {
      emoji: '😷',
      name: 'mask',
      shortcodes: ['mask', 'medical_mask'],
      tags: ['cold', 'doctor', 'face', 'mask', 'sick'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f637.png',
    },
    {
      emoji: '🤒',
      name: 'face_with_thermometer',
      shortcodes: ['face_with_thermometer'],
      tags: ['face', 'ill', 'sick', 'thermometer'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f912.png',
    },
    {
      emoji: '🤕',
      name: 'face_with_head_bandage',
      shortcodes: ['face_with_head_bandage'],
      tags: ['bandage', 'face', 'hurt', 'injury'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f915.png',
    },
    {
      emoji: '🤢',
      name: 'nauseated_face',
      shortcodes: ['nauseated', 'nauseated_face'],
      tags: ['face', 'nauseated', 'vomit'],
      group: 'smileys & emotion',
      emoticons: ['%('],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f922.png',
    },
    {
      emoji: '🤮',
      name: 'vomiting_face',
      shortcodes: ['face_vomiting', 'vomiting'],
      tags: ['puke', 'sick', 'vomit'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92e.png',
    },
    {
      emoji: '🤧',
      name: 'sneezing_face',
      shortcodes: ['sneezing', 'sneezing_face'],
      tags: ['face', 'gesundheit', 'sneeze'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f927.png',
    },
    {
      emoji: '🥵',
      name: 'hot_face',
      shortcodes: ['hot', 'hot_face'],
      tags: ['feverish', 'heat stroke', 'hot', 'red-faced', 'sweating'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f975.png',
    },
    {
      emoji: '🥶',
      name: 'cold_face',
      shortcodes: ['cold', 'cold_face'],
      tags: ['blue-faced', 'cold', 'freezing', 'frostbite', 'icicles'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f976.png',
    },
    {
      emoji: '🥴',
      name: 'woozy_face',
      shortcodes: ['woozy', 'woozy_face'],
      tags: ['dizzy', 'intoxicated', 'tipsy', 'uneven eyes', 'wavy mouth'],
      group: 'smileys & emotion',
      emoticons: [':&'],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f974.png',
    },
    {
      emoji: '😵',
      name: 'dizzy_face',
      shortcodes: ['dizzy_face', 'knocked_out'],
      tags: ['crossed-out eyes', 'dead', 'face', 'knocked out'],
      group: 'smileys & emotion',
      emoticons: ['xo', 'XO'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f635.png',
    },
    {
      emoji: '😵‍💫',
      name: 'face_with_spiral_eyes',
      shortcodes: ['dizzy_eyes'],
      tags: ['dizzy', 'hypnotized', 'spiral', 'trouble', 'whoa'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f635-200d-1f4ab.png',
    },
    {
      emoji: '🤯',
      name: 'exploding_head',
      shortcodes: ['exploding_head'],
      tags: ['mind blown', 'shocked'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92f.png',
    },
    {
      emoji: '🤠',
      name: 'cowboy_hat_face',
      shortcodes: ['cowboy', 'cowboy_face'],
      tags: ['cowboy', 'cowgirl', 'face', 'hat'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f920.png',
    },
    {
      emoji: '🥳',
      name: 'partying_face',
      shortcodes: ['hooray', 'partying', 'partying_face'],
      tags: ['celebration', 'hat', 'horn', 'party'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f973.png',
    },
    {
      emoji: '🥸',
      name: 'disguised_face',
      shortcodes: ['disguised', 'disguised_face'],
      tags: ['disguise', 'face', 'glasses', 'incognito', 'nose'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f978.png',
    },
    {
      emoji: '😎',
      name: 'sunglasses',
      shortcodes: [
        'smiling_face_with_sunglasses',
        'sunglasses_cool',
        'too_cool',
      ],
      tags: ['bright', 'cool', 'face', 'sun', 'sunglasses'],
      group: 'smileys & emotion',
      emoticons: ['8)'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60e.png',
    },
    {
      emoji: '🤓',
      name: 'nerd_face',
      shortcodes: ['nerd', 'nerd_face'],
      tags: ['face', 'geek', 'nerd'],
      group: 'smileys & emotion',
      emoticons: [':B'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f913.png',
    },
    {
      emoji: '🧐',
      name: 'monocle_face',
      shortcodes: ['face_with_monocle'],
      tags: ['face', 'monocle', 'stuffy'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d0.png',
    },
    {
      emoji: '😕',
      name: 'confused',
      shortcodes: ['confused', 'confused_face'],
      tags: ['confused', 'face', 'meh'],
      group: 'smileys & emotion',
      emoticons: [':/'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f615.png',
    },
    {
      emoji: '😟',
      name: 'worried',
      shortcodes: ['worried', 'worried_face'],
      tags: ['face', 'worried'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61f.png',
    },
    {
      emoji: '🙁',
      name: 'slightly_frowning_face',
      shortcodes: ['slightly_frowning_face'],
      tags: ['face', 'frown'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f641.png',
    },
    {
      emoji: '☹',
      name: 'frowning_face',
      shortcodes: ['white_frowning_face'],
      tags: ['face', 'frown'],
      group: 'smileys & emotion',
      emoticons: [':('],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2639-fe0f.png',
    },
    {
      emoji: '😮',
      name: 'open_mouth',
      shortcodes: ['face_with_open_mouth', 'open_mouth'],
      tags: ['face', 'mouth', 'open', 'sympathy'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62e.png',
    },
    {
      emoji: '😯',
      name: 'hushed',
      shortcodes: ['hushed', 'hushed_face'],
      tags: ['face', 'hushed', 'stunned', 'surprised'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62f.png',
    },
    {
      emoji: '😲',
      name: 'astonished',
      shortcodes: ['astonished', 'astonished_face'],
      tags: ['astonished', 'face', 'shocked', 'totally'],
      group: 'smileys & emotion',
      emoticons: [':o', ':O'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f632.png',
    },
    {
      emoji: '😳',
      name: 'flushed',
      shortcodes: ['flushed', 'flushed_face'],
      tags: ['dazed', 'face', 'flushed'],
      group: 'smileys & emotion',
      emoticons: [':$'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f633.png',
    },
    {
      emoji: '🥺',
      name: 'pleading_face',
      shortcodes: ['pleading', 'pleading_face'],
      tags: ['begging', 'mercy', 'puppy eyes'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97a.png',
    },
    {
      emoji: '😦',
      name: 'frowning',
      shortcodes: ['frowning', 'frowning_face'],
      tags: ['face', 'frown', 'mouth', 'open'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f626.png',
    },
    {
      emoji: '😧',
      name: 'anguished',
      shortcodes: ['anguished', 'anguished_face'],
      tags: ['anguished', 'face'],
      group: 'smileys & emotion',
      emoticons: [':s', ':S'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f627.png',
    },
    {
      emoji: '😨',
      name: 'fearful',
      shortcodes: ['fearful', 'fearful_face'],
      tags: ['face', 'fear', 'fearful', 'scared'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f628.png',
    },
    {
      emoji: '😰',
      name: 'cold_sweat',
      shortcodes: ['anxious', 'anxious_face', 'cold_sweat'],
      tags: ['blue', 'cold', 'face', 'rushed', 'sweat'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f630.png',
    },
    {
      emoji: '😥',
      name: 'disappointed_relieved',
      shortcodes: ['disappointed_relieved', 'sad_relieved_face'],
      tags: ['disappointed', 'face', 'relieved', 'whew'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f625.png',
    },
    {
      emoji: '😢',
      name: 'cry',
      shortcodes: ['cry', 'crying_face'],
      tags: ['cry', 'face', 'sad', 'tear'],
      group: 'smileys & emotion',
      emoticons: [":'("],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f622.png',
    },
    {
      emoji: '😭',
      name: 'sob',
      shortcodes: ['loudly_crying_face', 'sob'],
      tags: ['cry', 'face', 'sad', 'sob', 'tear'],
      group: 'smileys & emotion',
      emoticons: [":'o"],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62d.png',
    },
    {
      emoji: '😱',
      name: 'scream',
      shortcodes: ['scream', 'screaming_in_fear'],
      tags: ['face', 'fear', 'munch', 'scared', 'scream'],
      group: 'smileys & emotion',
      emoticons: ['Dx'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f631.png',
    },
    {
      emoji: '😖',
      name: 'confounded',
      shortcodes: ['confounded', 'confounded_face'],
      tags: ['confounded', 'face'],
      group: 'smileys & emotion',
      emoticons: ['x(', 'X('],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f616.png',
    },
    {
      emoji: '😣',
      name: 'persevere',
      shortcodes: ['persevere', 'persevering_face'],
      tags: ['face', 'persevere'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f623.png',
    },
    {
      emoji: '😞',
      name: 'disappointed',
      shortcodes: ['disappointed', 'disappointed_face'],
      tags: ['disappointed', 'face'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61e.png',
    },
    {
      emoji: '😓',
      name: 'sweat',
      shortcodes: ['downcast_face', 'sweat'],
      tags: ['cold', 'face', 'sweat'],
      group: 'smileys & emotion',
      emoticons: [':<'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f613.png',
    },
    {
      emoji: '😩',
      name: 'weary',
      shortcodes: ['weary', 'weary_face'],
      tags: ['face', 'tired', 'weary'],
      group: 'smileys & emotion',
      emoticons: ['D:'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f629.png',
    },
    {
      emoji: '😫',
      name: 'tired_face',
      shortcodes: ['tired', 'tired_face'],
      tags: ['face', 'tired'],
      group: 'smileys & emotion',
      emoticons: [':c', ':C'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f62b.png',
    },
    {
      emoji: '🥱',
      name: 'yawning_face',
      shortcodes: ['yawn', 'yawning', 'yawning_face'],
      tags: ['bored', 'tired', 'yawn'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f971.png',
    },
    {
      emoji: '😤',
      name: 'triumph',
      shortcodes: ['nose_steam', 'triumph'],
      tags: ['face', 'triumph', 'won'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f624.png',
    },
    {
      emoji: '😡',
      name: 'pout',
      shortcodes: ['pout', 'pouting_face', 'rage'],
      tags: ['angry', 'enraged', 'face', 'mad', 'pouting', 'rage', 'red'],
      group: 'smileys & emotion',
      emoticons: ['>:/'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f621.png',
    },
    {
      emoji: '😠',
      name: 'angry',
      shortcodes: ['angry', 'angry_face'],
      tags: ['anger', 'angry', 'face', 'mad'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f620.png',
    },
    {
      emoji: '🤬',
      name: 'cursing_face',
      shortcodes: ['censored', 'face_with_symbols_on_mouth'],
      tags: ['swearing'],
      group: 'smileys & emotion',
      emoticons: [':@'],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f92c.png',
    },
    {
      emoji: '😈',
      name: 'smiling_imp',
      shortcodes: ['smiling_imp'],
      tags: ['face', 'fairy tale', 'fantasy', 'horns', 'smile'],
      group: 'smileys & emotion',
      emoticons: ['>:)'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f608.png',
    },
    {
      emoji: '👿',
      name: 'imp',
      shortcodes: ['angry_imp', 'imp'],
      tags: ['demon', 'devil', 'face', 'fantasy', 'imp'],
      group: 'smileys & emotion',
      emoticons: ['>:('],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47f.png',
    },
    {
      emoji: '💀',
      name: 'skull',
      shortcodes: ['skull'],
      tags: ['death', 'face', 'fairy tale', 'monster'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f480.png',
    },
    {
      emoji: '☠',
      name: 'skull_and_crossbones',
      shortcodes: ['skull_and_crossbones'],
      tags: ['crossbones', 'death', 'face', 'monster', 'skull'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2620-fe0f.png',
    },
    {
      emoji: '💩',
      name: 'hankey',
      shortcodes: ['poop', 'shit'],
      tags: ['dung', 'face', 'monster', 'poo', 'poop'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a9.png',
    },
    {
      emoji: '🤡',
      name: 'clown_face',
      shortcodes: ['clown', 'clown_face'],
      tags: ['clown', 'face'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f921.png',
    },
    {
      emoji: '👹',
      name: 'japanese_ogre',
      shortcodes: ['japanese_ogre', 'ogre'],
      tags: ['creature', 'face', 'fairy tale', 'fantasy', 'monster'],
      group: 'smileys & emotion',
      emoticons: ['>0)'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f479.png',
    },
    {
      emoji: '👺',
      name: 'japanese_goblin',
      shortcodes: ['goblin', 'japanese_goblin'],
      tags: ['creature', 'face', 'fairy tale', 'fantasy', 'monster'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47a.png',
    },
    {
      emoji: '👻',
      name: 'ghost',
      shortcodes: ['ghost'],
      tags: ['creature', 'face', 'fairy tale', 'fantasy', 'monster'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47b.png',
    },
    {
      emoji: '👽',
      name: 'alien',
      shortcodes: ['alien'],
      tags: ['creature', 'extraterrestrial', 'face', 'fantasy', 'ufo'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47d.png',
    },
    {
      emoji: '👾',
      name: 'space_invader',
      shortcodes: ['alien_monster', 'space_invader'],
      tags: ['alien', 'creature', 'extraterrestrial', 'face', 'monster', 'ufo'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47e.png',
    },
    {
      emoji: '🤖',
      name: 'robot',
      shortcodes: ['robot', 'robot_face'],
      tags: ['face', 'monster'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f916.png',
    },
    {
      emoji: '😺',
      name: 'smiley_cat',
      shortcodes: ['grinning_cat', 'smiley_cat'],
      tags: ['cat', 'face', 'grinning', 'mouth', 'open', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63a.png',
    },
    {
      emoji: '😸',
      name: 'smile_cat',
      shortcodes: ['grinning_cat_with_closed_eyes', 'smile_cat'],
      tags: ['cat', 'eye', 'face', 'grin', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f638.png',
    },
    {
      emoji: '😹',
      name: 'joy_cat',
      shortcodes: ['joy_cat', 'tears_of_joy_cat'],
      tags: ['cat', 'face', 'joy', 'tear'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f639.png',
    },
    {
      emoji: '😻',
      name: 'heart_eyes_cat',
      shortcodes: ['heart_eyes_cat', 'smiling_cat_with_heart_eyes'],
      tags: ['cat', 'eye', 'face', 'heart', 'love', 'smile'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63b.png',
    },
    {
      emoji: '😼',
      name: 'smirk_cat',
      shortcodes: ['smirk_cat', 'wry_smile_cat'],
      tags: ['cat', 'face', 'ironic', 'smile', 'wry'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63c.png',
    },
    {
      emoji: '😽',
      name: 'kissing_cat',
      shortcodes: ['kissing_cat'],
      tags: ['cat', 'eye', 'face', 'kiss'],
      group: 'smileys & emotion',
      emoticons: [':3'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63d.png',
    },
    {
      emoji: '🙀',
      name: 'scream_cat',
      shortcodes: ['scream_cat', 'weary_cat'],
      tags: ['cat', 'face', 'oh', 'surprised', 'weary'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f640.png',
    },
    {
      emoji: '😿',
      name: 'crying_cat_face',
      shortcodes: ['crying_cat'],
      tags: ['cat', 'cry', 'face', 'sad', 'tear'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63f.png',
    },
    {
      emoji: '😾',
      name: 'pouting_cat',
      shortcodes: ['pouting_cat'],
      tags: ['cat', 'face', 'pouting'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f63e.png',
    },
    {
      emoji: '🙈',
      name: 'see_no_evil',
      shortcodes: ['see_no_evil'],
      tags: ['evil', 'face', 'forbidden', 'monkey', 'see'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f648.png',
    },
    {
      emoji: '🙉',
      name: 'hear_no_evil',
      shortcodes: ['hear_no_evil'],
      tags: ['evil', 'face', 'forbidden', 'hear', 'monkey'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f649.png',
    },
    {
      emoji: '🙊',
      name: 'speak_no_evil',
      shortcodes: ['speak_no_evil'],
      tags: ['evil', 'face', 'forbidden', 'monkey', 'speak'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64a.png',
    },
    {
      emoji: '💋',
      name: 'kiss',
      shortcodes: ['kiss'],
      tags: ['kiss', 'lips'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48b.png',
    },
    {
      emoji: '💌',
      name: 'love_letter',
      shortcodes: ['love_letter'],
      tags: ['heart', 'letter', 'love', 'mail'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48c.png',
    },
    {
      emoji: '💘',
      name: 'cupid',
      shortcodes: ['cupid', 'heart_with_arrow'],
      tags: ['arrow', 'cupid'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f498.png',
    },
    {
      emoji: '💝',
      name: 'gift_heart',
      shortcodes: ['gift_heart', 'heart_with_ribbon'],
      tags: ['ribbon', 'valentine'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49d.png',
    },
    {
      emoji: '💖',
      name: 'sparkling_heart',
      shortcodes: ['sparkling_heart'],
      tags: ['excited', 'sparkle'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f496.png',
    },
    {
      emoji: '💗',
      name: 'heartpulse',
      shortcodes: ['growing_heart', 'heartpulse'],
      tags: ['excited', 'growing', 'nervous', 'pulse'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f497.png',
    },
    {
      emoji: '💓',
      name: 'heartbeat',
      shortcodes: ['beating_heart', 'heartbeat'],
      tags: ['beating', 'heartbeat', 'pulsating'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f493.png',
    },
    {
      emoji: '💞',
      name: 'revolving_hearts',
      shortcodes: ['revolving_hearts'],
      tags: ['revolving'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49e.png',
    },
    {
      emoji: '💕',
      name: 'two_hearts',
      shortcodes: ['two_hearts'],
      tags: ['love'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f495.png',
    },
    {
      emoji: '💟',
      name: 'heart_decoration',
      shortcodes: ['heart_decoration'],
      tags: ['heart'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49f.png',
    },
    {
      emoji: '❣',
      name: 'heavy_heart_exclamation',
      shortcodes: ['heart_exclamation'],
      tags: ['exclamation', 'mark', 'punctuation'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2763-fe0f.png',
    },
    {
      emoji: '💔',
      name: 'broken_heart',
      shortcodes: ['broken_heart'],
      tags: ['break', 'broken'],
      group: 'smileys & emotion',
      emoticons: ['</3'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f494.png',
    },
    {
      emoji: '❤‍🔥',
      name: 'heart_on_fire',
      shortcodes: ['heart_on_fire'],
      tags: ['burn', 'heart', 'love', 'lust', 'sacred heart'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2764-fe0f-200d-1f525.png',
    },
    {
      emoji: '❤‍🩹',
      name: 'mending_heart',
      shortcodes: ['mending_heart'],
      tags: [
        'healthier',
        'improving',
        'mending',
        'recovering',
        'recuperating',
        'well',
      ],
      group: 'smileys & emotion',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2764-fe0f-200d-1fa79.png',
    },
    {
      emoji: '❤',
      name: 'heart',
      shortcodes: ['heart', 'red_heart'],
      tags: ['heart'],
      group: 'smileys & emotion',
      emoticons: ['<3'],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2764-fe0f.png',
    },
    {
      emoji: '🧡',
      name: 'orange_heart',
      shortcodes: ['orange_heart'],
      tags: ['orange'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e1.png',
    },
    {
      emoji: '💛',
      name: 'yellow_heart',
      shortcodes: ['yellow_heart'],
      tags: ['yellow'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49b.png',
    },
    {
      emoji: '💚',
      name: 'green_heart',
      shortcodes: ['green_heart'],
      tags: ['green'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49a.png',
    },
    {
      emoji: '💙',
      name: 'blue_heart',
      shortcodes: ['blue_heart'],
      tags: ['blue'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f499.png',
    },
    {
      emoji: '💜',
      name: 'purple_heart',
      shortcodes: ['purple_heart'],
      tags: ['purple'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f49c.png',
    },
    {
      emoji: '🤎',
      name: 'brown_heart',
      shortcodes: ['brown_heart'],
      tags: ['brown', 'heart'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f90e.png',
    },
    {
      emoji: '🖤',
      name: 'black_heart',
      shortcodes: ['black_heart'],
      tags: ['black', 'evil', 'wicked'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5a4.png',
    },
    {
      emoji: '🤍',
      name: 'white_heart',
      shortcodes: ['white_heart'],
      tags: ['heart', 'white'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f90d.png',
    },
    {
      emoji: '💯',
      name: '100',
      shortcodes: ['100'],
      tags: ['100', 'full', 'hundred', 'score'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4af.png',
    },
    {
      emoji: '💢',
      name: 'anger',
      shortcodes: ['anger'],
      tags: ['angry', 'comic', 'mad'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a2.png',
    },
    {
      emoji: '💥',
      name: 'boom',
      shortcodes: ['boom', 'collision'],
      tags: ['boom', 'comic'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a5.png',
    },
    {
      emoji: '💫',
      name: 'dizzy',
      shortcodes: ['dizzy'],
      tags: ['comic', 'star'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ab.png',
    },
    {
      emoji: '💦',
      name: 'sweat_drops',
      shortcodes: ['sweat_drops'],
      tags: ['comic', 'splashing', 'sweat'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a6.png',
    },
    {
      emoji: '💨',
      name: 'dash',
      shortcodes: ['dash', 'dashing_away'],
      tags: ['comic', 'dash', 'running'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a8.png',
    },
    {
      emoji: '🕳',
      name: 'hole',
      shortcodes: ['hole'],
      tags: ['hole'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f573-fe0f.png',
    },
    {
      emoji: '💣',
      name: 'bomb',
      shortcodes: ['bomb'],
      tags: ['comic'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a3.png',
    },
    {
      emoji: '💬',
      name: 'speech_balloon',
      shortcodes: ['speech_balloon'],
      tags: ['balloon', 'bubble', 'comic', 'dialog', 'speech'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ac.png',
    },
    {
      emoji: '👁‍🗨️',
      name: 'eye_speech_bubble',
      shortcodes: ['eye_in_speech_bubble'],
      tags: ['balloon', 'bubble', 'eye', 'speech', 'witness'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f441-fe0f-200d-1f5e8-fe0f.png',
    },
    {
      emoji: '🗨',
      name: 'left_speech_bubble',
      shortcodes: ['left_speech_bubble'],
      tags: ['balloon', 'bubble', 'dialog', 'speech'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5e8-fe0f.png',
    },
    {
      emoji: '🗯',
      name: 'right_anger_bubble',
      shortcodes: ['right_anger_bubble'],
      tags: ['angry', 'balloon', 'bubble', 'mad'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5ef-fe0f.png',
    },
    {
      emoji: '💭',
      name: 'thought_balloon',
      shortcodes: ['thought_balloon'],
      tags: ['balloon', 'bubble', 'comic', 'thought'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ad.png',
    },
    {
      emoji: '💤',
      name: 'zzz',
      shortcodes: ['zzz'],
      tags: ['comic', 'good night', 'sleep', 'zzz'],
      group: 'smileys & emotion',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a4.png',
    },
    {
      emoji: '👋',
      name: 'wave',
      shortcodes: ['wave', 'waving_hand'],
      tags: ['hand', 'wave', 'waving'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44b.png',
    },
    {
      emoji: '🤚',
      name: 'raised_back_of_hand',
      shortcodes: ['raised_back_of_hand'],
      tags: ['backhand', 'raised'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91a.png',
    },
    {
      emoji: '🖐',
      name: 'raised_hand_with_fingers_splayed',
      shortcodes: ['raised_hand_with_fingers_splayed'],
      tags: ['finger', 'hand', 'splayed'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f590-fe0f.png',
    },
    {
      emoji: '✋',
      name: 'hand',
      shortcodes: ['high_five', 'raised_hand'],
      tags: ['hand', 'high 5', 'high five'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/270b.png',
    },
    {
      emoji: '🖖',
      name: 'vulcan_salute',
      shortcodes: ['vulcan'],
      tags: ['finger', 'hand', 'spock', 'vulcan'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f596.png',
    },
    {
      emoji: '👌',
      name: 'ok_hand',
      shortcodes: ['ok_hand'],
      tags: ['hand', 'ok'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44c.png',
    },
    {
      emoji: '🤌',
      name: 'pinched_fingers',
      shortcodes: ['pinch', 'pinched_fingers'],
      tags: [
        'fingers',
        'hand gesture',
        'interrogation',
        'pinched',
        'sarcastic',
      ],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f90c.png',
    },
    {
      emoji: '🤏',
      name: 'pinching_hand',
      shortcodes: ['pinching_hand'],
      tags: ['small amount'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f90f.png',
    },
    {
      emoji: '✌',
      name: 'v',
      shortcodes: ['v', 'victory'],
      tags: ['hand', 'v', 'victory'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/270c-fe0f.png',
    },
    {
      emoji: '🤞',
      name: 'crossed_fingers',
      shortcodes: ['fingers_crossed'],
      tags: ['cross', 'finger', 'hand', 'luck'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91e.png',
    },
    {
      emoji: '🤟',
      name: 'love_you_gesture',
      shortcodes: ['love_you_gesture'],
      tags: ['hand', 'ily'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91f.png',
    },
    {
      emoji: '🤘',
      name: 'metal',
      shortcodes: ['metal', 'sign_of_the_horns'],
      tags: ['finger', 'hand', 'horns', 'rock-on'],
      group: 'people & body',
      emoticons: ['\\m/', '\\M/'],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f918.png',
    },
    {
      emoji: '🤙',
      name: 'call_me_hand',
      shortcodes: ['call_me_hand'],
      tags: ['call', 'hand', 'hang loose', 'shaka'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f919.png',
    },
    {
      emoji: '👈',
      name: 'point_left',
      shortcodes: ['point_left'],
      tags: ['backhand', 'finger', 'hand', 'index', 'point'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f448.png',
    },
    {
      emoji: '👉',
      name: 'point_right',
      shortcodes: ['point_right'],
      tags: ['backhand', 'finger', 'hand', 'index', 'point'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f449.png',
    },
    {
      emoji: '👆',
      name: 'point_up_2',
      shortcodes: ['point_up'],
      tags: ['backhand', 'finger', 'hand', 'point', 'up'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f446.png',
    },
    {
      emoji: '🖕',
      name: 'fu',
      shortcodes: ['middle_finger'],
      tags: ['finger', 'hand'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f595.png',
    },
    {
      emoji: '👇',
      name: 'point_down',
      shortcodes: ['point_down'],
      tags: ['backhand', 'down', 'finger', 'hand', 'point'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f447.png',
    },
    {
      emoji: '☝',
      name: 'point_up',
      shortcodes: ['point_up_2'],
      tags: ['finger', 'hand', 'index', 'point', 'up'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/261d-fe0f.png',
    },
    {
      emoji: '👍',
      name: '+1',
      shortcodes: ['+1', 'thumbsup', 'yes'],
      tags: ['+1', 'hand', 'thumb', 'up'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png',
    },
    {
      emoji: '👎',
      name: '-1',
      shortcodes: ['-1', 'no', 'thumbsdown'],
      tags: ['-1', 'down', 'hand', 'thumb'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44e.png',
    },
    {
      emoji: '✊',
      name: 'fist',
      shortcodes: ['fist'],
      tags: ['clenched', 'fist', 'hand', 'punch'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/270a.png',
    },
    {
      emoji: '👊',
      name: 'facepunch',
      shortcodes: ['punch'],
      tags: ['clenched', 'fist', 'hand', 'punch'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44a.png',
    },
    {
      emoji: '🤛',
      name: 'fist_left',
      shortcodes: ['left_facing_fist'],
      tags: ['fist', 'leftwards'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91b.png',
    },
    {
      emoji: '🤜',
      name: 'fist_right',
      shortcodes: ['right_facing_fist'],
      tags: ['fist', 'rightwards'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91c.png',
    },
    {
      emoji: '👏',
      name: 'clap',
      shortcodes: ['clap', 'clapping_hands'],
      tags: ['clap', 'hand'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44f.png',
    },
    {
      emoji: '🙌',
      name: 'raised_hands',
      shortcodes: ['raised_hands'],
      tags: ['celebration', 'gesture', 'hand', 'hooray', 'raised'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64c.png',
    },
    {
      emoji: '👐',
      name: 'open_hands',
      shortcodes: ['open_hands'],
      tags: ['hand', 'open'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f450.png',
    },
    {
      emoji: '🤲',
      name: 'palms_up_together',
      shortcodes: ['palms_up_together'],
      tags: ['prayer'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f932.png',
    },
    {
      emoji: '🤝',
      name: 'handshake',
      shortcodes: ['handshake'],
      tags: ['agreement', 'hand', 'meeting', 'shake'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f91d.png',
    },
    {
      emoji: '🙏',
      name: 'pray',
      shortcodes: ['folded_hands', 'pray'],
      tags: ['ask', 'hand', 'high 5', 'high five', 'please', 'pray', 'thanks'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64f.png',
    },
    {
      emoji: '✍',
      name: 'writing_hand',
      shortcodes: ['writing_hand'],
      tags: ['hand', 'write'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/270d-fe0f.png',
    },
    {
      emoji: '💅',
      name: 'nail_care',
      shortcodes: ['nail_care', 'nail_polish'],
      tags: ['care', 'cosmetics', 'manicure', 'nail', 'polish'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f485.png',
    },
    {
      emoji: '🤳',
      name: 'selfie',
      shortcodes: ['selfie'],
      tags: ['camera', 'phone'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f933.png',
    },
    {
      emoji: '💪',
      name: 'muscle',
      shortcodes: ['muscle', 'right_bicep'],
      tags: ['biceps', 'comic', 'flex', 'muscle'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4aa.png',
    },
    {
      emoji: '🦾',
      name: 'mechanical_arm',
      shortcodes: ['mechanical_arm'],
      tags: ['accessibility', 'prosthetic'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9be.png',
    },
    {
      emoji: '🦿',
      name: 'mechanical_leg',
      shortcodes: ['mechanical_leg'],
      tags: ['accessibility', 'prosthetic'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9bf.png',
    },
    {
      emoji: '🦵',
      name: 'leg',
      shortcodes: ['leg'],
      tags: ['kick', 'limb'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b5.png',
    },
    {
      emoji: '🦶',
      name: 'foot',
      shortcodes: ['foot'],
      tags: ['kick', 'stomp'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b6.png',
    },
    {
      emoji: '👂',
      name: 'ear',
      shortcodes: ['ear'],
      tags: ['body'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f442.png',
    },
    {
      emoji: '🦻',
      name: 'ear_with_hearing_aid',
      shortcodes: ['ear_with_hearing_aid', 'hearing_aid'],
      tags: ['accessibility', 'hard of hearing'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9bb.png',
    },
    {
      emoji: '👃',
      name: 'nose',
      shortcodes: ['nose'],
      tags: ['body'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f443.png',
    },
    {
      emoji: '🧠',
      name: 'brain',
      shortcodes: ['brain'],
      tags: ['intelligent'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e0.png',
    },
    {
      emoji: '🫀',
      name: 'anatomical_heart',
      shortcodes: ['anatomical_heart'],
      tags: ['anatomical', 'cardiology', 'heart', 'organ', 'pulse'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fac0.png',
    },
    {
      emoji: '🫁',
      name: 'lungs',
      shortcodes: ['lungs'],
      tags: ['breath', 'exhalation', 'inhalation', 'organ', 'respiration'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fac1.png',
    },
    {
      emoji: '🦷',
      name: 'tooth',
      shortcodes: ['tooth'],
      tags: ['dentist'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b7.png',
    },
    {
      emoji: '🦴',
      name: 'bone',
      shortcodes: ['bone'],
      tags: ['skeleton'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b4.png',
    },
    {
      emoji: '👀',
      name: 'eyes',
      shortcodes: ['eyes'],
      tags: ['eye', 'face'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f440.png',
    },
    {
      emoji: '👁',
      name: 'eye',
      shortcodes: ['eye'],
      tags: ['body'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f441-fe0f.png',
    },
    {
      emoji: '👅',
      name: 'tongue',
      shortcodes: ['tongue'],
      tags: ['body'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f445.png',
    },
    {
      emoji: '👄',
      name: 'lips',
      shortcodes: ['lips', 'mouth'],
      tags: ['lips'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f444.png',
    },
    {
      emoji: '👶',
      name: 'baby',
      shortcodes: ['baby'],
      tags: ['young'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f476.png',
    },
    {
      emoji: '🧒',
      name: 'child',
      shortcodes: ['child'],
      tags: ['gender-neutral', 'unspecified gender', 'young'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d2.png',
    },
    {
      emoji: '👦',
      name: 'boy',
      shortcodes: ['boy'],
      tags: ['young'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f466.png',
    },
    {
      emoji: '👧',
      name: 'girl',
      shortcodes: ['girl'],
      tags: ['virgo', 'young', 'zodiac'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f467.png',
    },
    {
      emoji: '🧑',
      name: 'adult',
      shortcodes: ['adult'],
      tags: ['adult', 'gender-neutral', 'unspecified gender'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1.png',
    },
    {
      emoji: '👱',
      name: 'blond_haired_person',
      shortcodes: ['blond_haired'],
      tags: ['blond', 'blond-haired person', 'hair'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f471.png',
    },
    {
      emoji: '👨',
      name: 'man',
      shortcodes: ['man'],
      tags: ['adult'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468.png',
    },
    {
      emoji: '🧔',
      name: 'bearded_person',
      shortcodes: ['person_bearded'],
      tags: ['beard', 'person'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d4.png',
    },
    {
      emoji: '🧔‍♂',
      name: 'man_beard',
      shortcodes: ['man_bearded'],
      tags: ['beard', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d4-200d-2642-fe0f.png',
    },
    {
      emoji: '🧔‍♀',
      name: 'woman_beard',
      shortcodes: ['woman_bearded'],
      tags: ['beard', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 13.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d4-200d-2640-fe0f.png',
    },
    {
      emoji: '👨‍🦰',
      name: 'red_haired_man',
      shortcodes: ['man_red_haired'],
      tags: ['adult', 'man', 'red hair'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9b0.png',
    },
    {
      emoji: '👨‍🦱',
      name: 'curly_haired_man',
      shortcodes: ['man_curly_haired'],
      tags: ['adult', 'curly hair', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9b1.png',
    },
    {
      emoji: '👨‍🦳',
      name: 'white_haired_man',
      shortcodes: ['man_white_haired'],
      tags: ['adult', 'man', 'white hair'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9b3.png',
    },
    {
      emoji: '👨‍🦲',
      name: 'bald_man',
      shortcodes: ['man_bald'],
      tags: ['adult', 'bald', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9b2.png',
    },
    {
      emoji: '👩',
      name: 'woman',
      shortcodes: ['woman'],
      tags: ['adult'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469.png',
    },
    {
      emoji: '👩‍🦰',
      name: 'red_haired_woman',
      shortcodes: ['woman_red_haired'],
      tags: ['adult', 'red hair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9b0.png',
    },
    {
      emoji: '🧑‍🦰',
      name: 'person_red_hair',
      shortcodes: ['red_haired'],
      tags: [
        'adult',
        'gender-neutral',
        'person',
        'red hair',
        'unspecified gender',
      ],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9b0.png',
    },
    {
      emoji: '👩‍🦱',
      name: 'curly_haired_woman',
      shortcodes: ['woman_curly_haired'],
      tags: ['adult', 'curly hair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9b1.png',
    },
    {
      emoji: '🧑‍🦱',
      name: 'person_curly_hair',
      shortcodes: ['curly_haired'],
      tags: [
        'adult',
        'curly hair',
        'gender-neutral',
        'person',
        'unspecified gender',
      ],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9b1.png',
    },
    {
      emoji: '👩‍🦳',
      name: 'white_haired_woman',
      shortcodes: ['woman_white_haired'],
      tags: ['adult', 'white hair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9b3.png',
    },
    {
      emoji: '🧑‍🦳',
      name: 'person_white_hair',
      shortcodes: ['white_haired'],
      tags: [
        'adult',
        'gender-neutral',
        'person',
        'unspecified gender',
        'white hair',
      ],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9b3.png',
    },
    {
      emoji: '👩‍🦲',
      name: 'bald_woman',
      shortcodes: ['woman_bald'],
      tags: ['adult', 'bald', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9b2.png',
    },
    {
      emoji: '🧑‍🦲',
      name: 'person_bald',
      shortcodes: ['bald'],
      tags: ['adult', 'bald', 'gender-neutral', 'person', 'unspecified gender'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9b2.png',
    },
    {
      emoji: '👱‍♀',
      name: 'blond_haired_woman',
      shortcodes: ['woman_blond_haired'],
      tags: ['blond-haired woman', 'blonde', 'hair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f471-200d-2640-fe0f.png',
    },
    {
      emoji: '👱‍♂',
      name: 'blond_haired_man',
      shortcodes: ['man_blond_haired'],
      tags: ['blond', 'blond-haired man', 'hair', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f471-200d-2642-fe0f.png',
    },
    {
      emoji: '🧓',
      name: 'older_adult',
      shortcodes: ['older_adult'],
      tags: ['adult', 'gender-neutral', 'old', 'unspecified gender'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d3.png',
    },
    {
      emoji: '👴',
      name: 'older_man',
      shortcodes: ['older_man'],
      tags: ['adult', 'man', 'old'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f474.png',
    },
    {
      emoji: '👵',
      name: 'older_woman',
      shortcodes: ['older_woman'],
      tags: ['adult', 'old', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f475.png',
    },
    {
      emoji: '🙍',
      name: 'frowning_person',
      shortcodes: ['person_frowning'],
      tags: ['frown', 'gesture'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64d.png',
    },
    {
      emoji: '🙍‍♂',
      name: 'frowning_man',
      shortcodes: ['man_frowning'],
      tags: ['frowning', 'gesture', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64d-200d-2642-fe0f.png',
    },
    {
      emoji: '🙍‍♀',
      name: 'frowning_woman',
      shortcodes: ['woman_frowning'],
      tags: ['frowning', 'gesture', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64d-200d-2640-fe0f.png',
    },
    {
      emoji: '🙎',
      name: 'pouting_face',
      shortcodes: ['person_pouting', 'pouting'],
      tags: ['gesture', 'pouting'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64e.png',
    },
    {
      emoji: '🙎‍♂',
      name: 'pouting_man',
      shortcodes: ['man_pouting'],
      tags: ['gesture', 'man', 'pouting'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64e-200d-2642-fe0f.png',
    },
    {
      emoji: '🙎‍♀',
      name: 'pouting_woman',
      shortcodes: ['woman_pouting'],
      tags: ['gesture', 'pouting', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64e-200d-2640-fe0f.png',
    },
    {
      emoji: '🙅',
      name: 'no_good',
      shortcodes: ['no_good', 'person_gesturing_no'],
      tags: [
        'forbidden',
        'gesture',
        'hand',
        'person gesturing no',
        'prohibited',
      ],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f645.png',
    },
    {
      emoji: '🙅‍♂',
      name: 'ng_man',
      shortcodes: ['man_gesturing_no'],
      tags: [
        'forbidden',
        'gesture',
        'hand',
        'man',
        'man gesturing no',
        'prohibited',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f645-200d-2642-fe0f.png',
    },
    {
      emoji: '🙅‍♀',
      name: 'ng_woman',
      shortcodes: ['woman_gesturing_no'],
      tags: [
        'forbidden',
        'gesture',
        'hand',
        'prohibited',
        'woman',
        'woman gesturing no',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f645-200d-2640-fe0f.png',
    },
    {
      emoji: '🙆',
      name: 'ok_person',
      shortcodes: ['all_good', 'person_gesturing_ok'],
      tags: ['gesture', 'hand', 'ok', 'person gesturing ok'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f646.png',
    },
    {
      emoji: '🙆‍♂',
      name: 'ok_man',
      shortcodes: ['man_gesturing_ok'],
      tags: ['gesture', 'hand', 'man', 'man gesturing ok', 'ok'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f646-200d-2642-fe0f.png',
    },
    {
      emoji: '🙆‍♀',
      name: 'ok_woman',
      shortcodes: ['woman_gesturing_ok'],
      tags: ['gesture', 'hand', 'ok', 'woman', 'woman gesturing ok'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f646-200d-2640-fe0f.png',
    },
    {
      emoji: '💁',
      name: 'information_desk_person',
      shortcodes: ['person_tipping_hand'],
      tags: ['hand', 'help', 'information', 'sassy', 'tipping'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f481.png',
    },
    {
      emoji: '💁‍♂',
      name: 'sassy_man',
      shortcodes: ['man_tipping_hand'],
      tags: ['man', 'sassy', 'tipping hand'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f481-200d-2642-fe0f.png',
    },
    {
      emoji: '💁‍♀',
      name: 'sassy_woman',
      shortcodes: ['woman_tipping_hand'],
      tags: ['sassy', 'tipping hand', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f481-200d-2640-fe0f.png',
    },
    {
      emoji: '🙋',
      name: 'raising_hand',
      shortcodes: ['person_raising_hand'],
      tags: ['gesture', 'hand', 'happy', 'raised'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64b.png',
    },
    {
      emoji: '🙋‍♂',
      name: 'raising_hand_man',
      shortcodes: ['man_raising_hand'],
      tags: ['gesture', 'man', 'raising hand'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64b-200d-2642-fe0f.png',
    },
    {
      emoji: '🙋‍♀',
      name: 'raising_hand_woman',
      shortcodes: ['woman_raising_hand'],
      tags: ['gesture', 'raising hand', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f64b-200d-2640-fe0f.png',
    },
    {
      emoji: '🧏',
      name: 'deaf_person',
      shortcodes: ['deaf_person'],
      tags: ['accessibility', 'deaf', 'ear', 'hear'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cf.png',
    },
    {
      emoji: '🧏‍♂',
      name: 'deaf_man',
      shortcodes: ['deaf_man'],
      tags: ['deaf', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cf-200d-2642-fe0f.png',
    },
    {
      emoji: '🧏‍♀',
      name: 'deaf_woman',
      shortcodes: ['deaf_woman'],
      tags: ['deaf', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cf-200d-2640-fe0f.png',
    },
    {
      emoji: '🙇',
      name: 'bow',
      shortcodes: ['bow', 'person_bowing'],
      tags: ['apology', 'bow', 'gesture', 'sorry'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f647.png',
    },
    {
      emoji: '🙇‍♂',
      name: 'bowing_man',
      shortcodes: ['man_bowing'],
      tags: ['apology', 'bowing', 'favor', 'gesture', 'man', 'sorry'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f647-200d-2642-fe0f.png',
    },
    {
      emoji: '🙇‍♀',
      name: 'bowing_woman',
      shortcodes: ['woman_bowing'],
      tags: ['apology', 'bowing', 'favor', 'gesture', 'sorry', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f647-200d-2640-fe0f.png',
    },
    {
      emoji: '🤦',
      name: 'facepalm',
      shortcodes: ['facepalm', 'person_facepalming'],
      tags: ['disbelief', 'exasperation', 'face', 'palm'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f926.png',
    },
    {
      emoji: '🤦‍♂',
      name: 'man_facepalming',
      shortcodes: ['man_facepalming'],
      tags: ['disbelief', 'exasperation', 'facepalm', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f926-200d-2642-fe0f.png',
    },
    {
      emoji: '🤦‍♀',
      name: 'woman_facepalming',
      shortcodes: ['woman_facepalming'],
      tags: ['disbelief', 'exasperation', 'facepalm', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f926-200d-2640-fe0f.png',
    },
    {
      emoji: '🤷',
      name: 'shrug',
      shortcodes: ['person_shrugging', 'shrug'],
      tags: ['doubt', 'ignorance', 'indifference', 'shrug'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f937.png',
    },
    {
      emoji: '🤷‍♂',
      name: 'man_shrugging',
      shortcodes: ['man_shrugging'],
      tags: ['doubt', 'ignorance', 'indifference', 'man', 'shrug'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f937-200d-2642-fe0f.png',
    },
    {
      emoji: '🤷‍♀',
      name: 'woman_shrugging',
      shortcodes: ['woman_shrugging'],
      tags: ['doubt', 'ignorance', 'indifference', 'shrug', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f937-200d-2640-fe0f.png',
    },
    {
      emoji: '🧑‍⚕',
      name: 'health_worker',
      shortcodes: ['health_worker'],
      tags: ['doctor', 'healthcare', 'nurse', 'therapist'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-2695-fe0f.png',
    },
    {
      emoji: '👨‍⚕',
      name: 'man_health_worker',
      shortcodes: ['man_health_worker'],
      tags: ['doctor', 'healthcare', 'man', 'nurse', 'therapist'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-2695-fe0f.png',
    },
    {
      emoji: '👩‍⚕',
      name: 'woman_health_worker',
      shortcodes: ['woman_health_worker'],
      tags: ['doctor', 'healthcare', 'nurse', 'therapist', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2695-fe0f.png',
    },
    {
      emoji: '🧑‍🎓',
      name: 'student',
      shortcodes: ['student'],
      tags: ['graduate'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f393.png',
    },
    {
      emoji: '👨‍🎓',
      name: 'man_student',
      shortcodes: ['man_student'],
      tags: ['graduate', 'man', 'student'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f393.png',
    },
    {
      emoji: '👩‍🎓',
      name: 'woman_student',
      shortcodes: ['woman_student'],
      tags: ['graduate', 'student', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f393.png',
    },
    {
      emoji: '🧑‍🏫',
      name: 'teacher',
      shortcodes: ['teacher'],
      tags: ['instructor', 'professor'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f3eb.png',
    },
    {
      emoji: '👨‍🏫',
      name: 'man_teacher',
      shortcodes: ['man_teacher'],
      tags: ['instructor', 'man', 'professor', 'teacher'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f3eb.png',
    },
    {
      emoji: '👩‍🏫',
      name: 'woman_teacher',
      shortcodes: ['woman_teacher'],
      tags: ['instructor', 'professor', 'teacher', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f3eb.png',
    },
    {
      emoji: '🧑‍⚖',
      name: 'judge',
      shortcodes: ['judge'],
      tags: ['justice', 'scales'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-2696-fe0f.png',
    },
    {
      emoji: '👨‍⚖',
      name: 'man_judge',
      shortcodes: ['man_judge'],
      tags: ['judge', 'justice', 'man', 'scales'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-2696-fe0f.png',
    },
    {
      emoji: '👩‍⚖',
      name: 'woman_judge',
      shortcodes: ['woman_judge'],
      tags: ['judge', 'justice', 'scales', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2696-fe0f.png',
    },
    {
      emoji: '🧑‍🌾',
      name: 'farmer',
      shortcodes: ['farmer'],
      tags: ['gardener', 'rancher'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f33e.png',
    },
    {
      emoji: '👨‍🌾',
      name: 'man_farmer',
      shortcodes: ['man_farmer'],
      tags: ['farmer', 'gardener', 'man', 'rancher'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f33e.png',
    },
    {
      emoji: '👩‍🌾',
      name: 'woman_farmer',
      shortcodes: ['woman_farmer'],
      tags: ['farmer', 'gardener', 'rancher', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f33e.png',
    },
    {
      emoji: '🧑‍🍳',
      name: 'cook',
      shortcodes: ['cook'],
      tags: ['chef'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f373.png',
    },
    {
      emoji: '👨‍🍳',
      name: 'man_cook',
      shortcodes: ['man_cook'],
      tags: ['chef', 'cook', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f373.png',
    },
    {
      emoji: '👩‍🍳',
      name: 'woman_cook',
      shortcodes: ['woman_cook'],
      tags: ['chef', 'cook', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f373.png',
    },
    {
      emoji: '🧑‍🔧',
      name: 'mechanic',
      shortcodes: ['mechanic'],
      tags: ['electrician', 'plumber', 'tradesperson'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f527.png',
    },
    {
      emoji: '👨‍🔧',
      name: 'man_mechanic',
      shortcodes: ['man_mechanic'],
      tags: ['electrician', 'man', 'mechanic', 'plumber', 'tradesperson'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f527.png',
    },
    {
      emoji: '👩‍🔧',
      name: 'woman_mechanic',
      shortcodes: ['woman_mechanic'],
      tags: ['electrician', 'mechanic', 'plumber', 'tradesperson', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f527.png',
    },
    {
      emoji: '🧑‍🏭',
      name: 'factory_worker',
      shortcodes: ['factory_worker'],
      tags: ['assembly', 'factory', 'industrial', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f3ed.png',
    },
    {
      emoji: '👨‍🏭',
      name: 'man_factory_worker',
      shortcodes: ['man_factory_worker'],
      tags: ['assembly', 'factory', 'industrial', 'man', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f3ed.png',
    },
    {
      emoji: '👩‍🏭',
      name: 'woman_factory_worker',
      shortcodes: ['woman_factory_worker'],
      tags: ['assembly', 'factory', 'industrial', 'woman', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f3ed.png',
    },
    {
      emoji: '🧑‍💼',
      name: 'office_worker',
      shortcodes: ['office_worker'],
      tags: ['architect', 'business', 'manager', 'white-collar'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f4bc.png',
    },
    {
      emoji: '👨‍💼',
      name: 'man_office_worker',
      shortcodes: ['man_office_worker'],
      tags: ['architect', 'business', 'man', 'manager', 'white-collar'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f4bc.png',
    },
    {
      emoji: '👩‍💼',
      name: 'woman_office_worker',
      shortcodes: ['woman_office_worker'],
      tags: ['architect', 'business', 'manager', 'white-collar', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f4bc.png',
    },
    {
      emoji: '🧑‍🔬',
      name: 'scientist',
      shortcodes: ['scientist'],
      tags: ['biologist', 'chemist', 'engineer', 'physicist'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f52c.png',
    },
    {
      emoji: '👨‍🔬',
      name: 'man_scientist',
      shortcodes: ['man_scientist'],
      tags: [
        'biologist',
        'chemist',
        'engineer',
        'man',
        'physicist',
        'scientist',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f52c.png',
    },
    {
      emoji: '👩‍🔬',
      name: 'woman_scientist',
      shortcodes: ['woman_scientist'],
      tags: [
        'biologist',
        'chemist',
        'engineer',
        'physicist',
        'scientist',
        'woman',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f52c.png',
    },
    {
      emoji: '🧑‍💻',
      name: 'technologist',
      shortcodes: ['technologist'],
      tags: ['coder', 'developer', 'inventor', 'software'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f4bb.png',
    },
    {
      emoji: '👨‍💻',
      name: 'man_technologist',
      shortcodes: ['man_technologist'],
      tags: [
        'coder',
        'developer',
        'inventor',
        'man',
        'software',
        'technologist',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f4bb.png',
    },
    {
      emoji: '👩‍💻',
      name: 'woman_technologist',
      shortcodes: ['woman_technologist'],
      tags: [
        'coder',
        'developer',
        'inventor',
        'software',
        'technologist',
        'woman',
      ],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f4bb.png',
    },
    {
      emoji: '🧑‍🎤',
      name: 'singer',
      shortcodes: ['singer'],
      tags: ['actor', 'entertainer', 'rock', 'star'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f3a4.png',
    },
    {
      emoji: '👨‍🎤',
      name: 'man_singer',
      shortcodes: ['man_singer'],
      tags: ['actor', 'entertainer', 'man', 'rock', 'singer', 'star'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f3a4.png',
    },
    {
      emoji: '👩‍🎤',
      name: 'woman_singer',
      shortcodes: ['woman_singer'],
      tags: ['actor', 'entertainer', 'rock', 'singer', 'star', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f3a4.png',
    },
    {
      emoji: '🧑‍🎨',
      name: 'artist',
      shortcodes: ['artist'],
      tags: ['palette'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f3a8.png',
    },
    {
      emoji: '👨‍🎨',
      name: 'man_artist',
      shortcodes: ['man_artist'],
      tags: ['artist', 'man', 'palette'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f3a8.png',
    },
    {
      emoji: '👩‍🎨',
      name: 'woman_artist',
      shortcodes: ['woman_artist'],
      tags: ['artist', 'palette', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f3a8.png',
    },
    {
      emoji: '🧑‍✈',
      name: 'pilot',
      shortcodes: ['pilot'],
      tags: ['plane'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-2708-fe0f.png',
    },
    {
      emoji: '👨‍✈',
      name: 'man_pilot',
      shortcodes: ['man_pilot'],
      tags: ['man', 'pilot', 'plane'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-2708-fe0f.png',
    },
    {
      emoji: '👩‍✈',
      name: 'woman_pilot',
      shortcodes: ['woman_pilot'],
      tags: ['pilot', 'plane', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2708-fe0f.png',
    },
    {
      emoji: '🧑‍🚀',
      name: 'astronaut',
      shortcodes: ['astronaut'],
      tags: ['rocket'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f680.png',
    },
    {
      emoji: '👨‍🚀',
      name: 'man_astronaut',
      shortcodes: ['man_astronaut'],
      tags: ['astronaut', 'man', 'rocket'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f680.png',
    },
    {
      emoji: '👩‍🚀',
      name: 'woman_astronaut',
      shortcodes: ['woman_astronaut'],
      tags: ['astronaut', 'rocket', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f680.png',
    },
    {
      emoji: '🧑‍🚒',
      name: 'firefighter',
      shortcodes: ['firefighter'],
      tags: ['firetruck'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f692.png',
    },
    {
      emoji: '👨‍🚒',
      name: 'man_firefighter',
      shortcodes: ['man_firefighter'],
      tags: ['firefighter', 'firetruck', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f692.png',
    },
    {
      emoji: '👩‍🚒',
      name: 'woman_firefighter',
      shortcodes: ['woman_firefighter'],
      tags: ['firefighter', 'firetruck', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f692.png',
    },
    {
      emoji: '👮',
      name: 'cop',
      shortcodes: ['cop', 'police_officer'],
      tags: ['cop', 'officer', 'police'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46e.png',
    },
    {
      emoji: '👮‍♂',
      name: 'policeman',
      shortcodes: ['man_police_officer'],
      tags: ['cop', 'man', 'officer', 'police'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46e-200d-2642-fe0f.png',
    },
    {
      emoji: '👮‍♀',
      name: 'policewoman',
      shortcodes: ['woman_police_officer'],
      tags: ['cop', 'officer', 'police', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46e-200d-2640-fe0f.png',
    },
    {
      emoji: '🕵',
      name: 'detective',
      shortcodes: ['detective'],
      tags: ['sleuth', 'spy'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f575-fe0f.png',
    },
    {
      emoji: '🕵‍♂️',
      name: 'male_detective',
      shortcodes: ['man_detective'],
      tags: ['detective', 'man', 'sleuth', 'spy'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f575-fe0f-200d-2642-fe0f.png',
    },
    {
      emoji: '🕵‍♀️',
      name: 'female_detective',
      shortcodes: ['woman_detective'],
      tags: ['detective', 'sleuth', 'spy', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f575-fe0f-200d-2640-fe0f.png',
    },
    {
      emoji: '💂',
      name: 'guard',
      shortcodes: ['guard'],
      tags: ['guard'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f482.png',
    },
    {
      emoji: '💂‍♂',
      name: 'guardsman',
      shortcodes: ['man_guard'],
      tags: ['guard', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f482-200d-2642-fe0f.png',
    },
    {
      emoji: '💂‍♀',
      name: 'guardswoman',
      shortcodes: ['woman_guard'],
      tags: ['guard', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f482-200d-2640-fe0f.png',
    },
    {
      emoji: '🥷',
      name: 'ninja',
      shortcodes: ['ninja'],
      tags: ['fighter', 'hidden', 'stealth'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f977.png',
    },
    {
      emoji: '👷',
      name: 'construction_worker',
      shortcodes: ['construction_worker'],
      tags: ['construction', 'hat', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f477.png',
    },
    {
      emoji: '👷‍♂',
      name: 'construction_worker_man',
      shortcodes: ['man_construction_worker'],
      tags: ['construction', 'man', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f477-200d-2642-fe0f.png',
    },
    {
      emoji: '👷‍♀',
      name: 'construction_worker_woman',
      shortcodes: ['woman_construction_worker'],
      tags: ['construction', 'woman', 'worker'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f477-200d-2640-fe0f.png',
    },
    {
      emoji: '🤴',
      name: 'prince',
      shortcodes: ['prince'],
      tags: ['prince'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f934.png',
    },
    {
      emoji: '👸',
      name: 'princess',
      shortcodes: ['princess'],
      tags: ['fairy tale', 'fantasy'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f478.png',
    },
    {
      emoji: '👳',
      name: 'person_with_turban',
      shortcodes: ['person_wearing_turban'],
      tags: ['turban'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f473.png',
    },
    {
      emoji: '👳‍♂',
      name: 'man_with_turban',
      shortcodes: ['man_wearing_turban'],
      tags: ['man', 'turban'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f473-200d-2642-fe0f.png',
    },
    {
      emoji: '👳‍♀',
      name: 'woman_with_turban',
      shortcodes: ['woman_wearing_turban'],
      tags: ['turban', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f473-200d-2640-fe0f.png',
    },
    {
      emoji: '👲',
      name: 'man_with_gua_pi_mao',
      shortcodes: ['person_with_skullcap'],
      tags: ['cap', 'gua pi mao', 'hat', 'person', 'skullcap'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f472.png',
    },
    {
      emoji: '🧕',
      name: 'woman_with_headscarf',
      shortcodes: ['woman_with_headscarf'],
      tags: ['headscarf', 'hijab', 'mantilla', 'tichel'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d5.png',
    },
    {
      emoji: '🤵',
      name: 'person_in_tuxedo',
      shortcodes: ['person_in_tuxedo'],
      tags: ['groom', 'person', 'tuxedo'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f935.png',
    },
    {
      emoji: '🤵‍♂',
      name: 'man_in_tuxedo',
      shortcodes: ['man_in_tuxedo'],
      tags: ['man', 'tuxedo'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f935-200d-2642-fe0f.png',
    },
    {
      emoji: '🤵‍♀',
      name: 'woman_in_tuxedo',
      shortcodes: ['woman_in_tuxedo'],
      tags: ['tuxedo', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f935-200d-2640-fe0f.png',
    },
    {
      emoji: '👰',
      name: 'person_with_veil',
      shortcodes: ['person_with_veil'],
      tags: ['bride', 'person', 'veil', 'wedding'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f470.png',
    },
    {
      emoji: '👰‍♂',
      name: 'man_with_veil',
      shortcodes: ['man_with_veil'],
      tags: ['man', 'veil'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f470-200d-2642-fe0f.png',
    },
    {
      emoji: '👰‍♀',
      name: 'bride_with_veil',
      shortcodes: ['woman_with_veil'],
      tags: ['veil', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f470-200d-2640-fe0f.png',
    },
    {
      emoji: '🤰',
      name: 'pregnant_woman',
      shortcodes: ['pregnant_woman'],
      tags: ['pregnant', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f930.png',
    },
    {
      emoji: '🤱',
      name: 'breast_feeding',
      shortcodes: ['breast_feeding'],
      tags: ['baby', 'breast', 'nursing'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f931.png',
    },
    {
      emoji: '👩‍🍼',
      name: 'woman_feeding_baby',
      shortcodes: ['woman_feeding_baby'],
      tags: ['baby', 'feeding', 'nursing', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f37c.png',
    },
    {
      emoji: '👨‍🍼',
      name: 'man_feeding_baby',
      shortcodes: ['man_feeding_baby'],
      tags: ['baby', 'feeding', 'man', 'nursing'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f37c.png',
    },
    {
      emoji: '🧑‍🍼',
      name: 'person_feeding_baby',
      shortcodes: ['person_feeding_baby'],
      tags: ['baby', 'feeding', 'nursing', 'person'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f37c.png',
    },
    {
      emoji: '👼',
      name: 'angel',
      shortcodes: ['angel'],
      tags: ['angel', 'baby', 'face', 'fairy tale', 'fantasy'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47c.png',
    },
    {
      emoji: '🎅',
      name: 'santa',
      shortcodes: ['santa'],
      tags: [
        'celebration',
        'christmas',
        'claus',
        'father',
        'santa',
        'santa claus',
      ],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f385.png',
    },
    {
      emoji: '🤶',
      name: 'mrs_claus',
      shortcodes: ['mrs_claus'],
      tags: [
        'celebration',
        'christmas',
        'claus',
        'mother',
        'mrs.',
        'mrs. claus',
      ],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f936.png',
    },
    {
      emoji: '🧑‍🎄',
      name: 'mx_claus',
      shortcodes: ['mx_claus'],
      tags: ['claus, christmas'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f384.png',
    },
    {
      emoji: '🦸',
      name: 'superhero',
      shortcodes: ['superhero'],
      tags: ['good', 'hero', 'heroine', 'superpower'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b8.png',
    },
    {
      emoji: '🦸‍♂',
      name: 'superhero_man',
      shortcodes: ['man_superhero'],
      tags: ['good', 'hero', 'man', 'superpower'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b8-200d-2642-fe0f.png',
    },
    {
      emoji: '🦸‍♀',
      name: 'superhero_woman',
      shortcodes: ['woman_superhero'],
      tags: ['good', 'hero', 'heroine', 'superpower', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b8-200d-2640-fe0f.png',
    },
    {
      emoji: '🦹',
      name: 'supervillain',
      shortcodes: ['supervillain'],
      tags: ['criminal', 'evil', 'superpower', 'villain'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b9.png',
    },
    {
      emoji: '🦹‍♂',
      name: 'supervillain_man',
      shortcodes: ['man_supervillain'],
      tags: ['criminal', 'evil', 'man', 'superpower', 'villain'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b9-200d-2642-fe0f.png',
    },
    {
      emoji: '🦹‍♀',
      name: 'supervillain_woman',
      shortcodes: ['woman_supervillain'],
      tags: ['criminal', 'evil', 'superpower', 'villain', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9b9-200d-2640-fe0f.png',
    },
    {
      emoji: '🧙',
      name: 'mage',
      shortcodes: ['mage'],
      tags: ['sorcerer', 'sorceress', 'witch', 'wizard'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d9.png',
    },
    {
      emoji: '🧙‍♂',
      name: 'mage_man',
      shortcodes: ['man_mage'],
      tags: ['sorcerer', 'wizard'],
      group: 'people & body',
      emoticons: [':{>'],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d9-200d-2642-fe0f.png',
    },
    {
      emoji: '🧙‍♀',
      name: 'mage_woman',
      shortcodes: ['woman_mage'],
      tags: ['sorceress', 'witch'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d9-200d-2640-fe0f.png',
    },
    {
      emoji: '🧚',
      name: 'fairy',
      shortcodes: ['fairy'],
      tags: ['oberon', 'puck', 'titania'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9da.png',
    },
    {
      emoji: '🧚‍♂',
      name: 'fairy_man',
      shortcodes: ['man_fairy'],
      tags: ['oberon', 'puck'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9da-200d-2642-fe0f.png',
    },
    {
      emoji: '🧚‍♀',
      name: 'fairy_woman',
      shortcodes: ['woman_fairy'],
      tags: ['titania'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9da-200d-2640-fe0f.png',
    },
    {
      emoji: '🧛',
      name: 'vampire',
      shortcodes: ['vampire'],
      tags: ['dracula', 'undead'],
      group: 'people & body',
      emoticons: [':E'],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9db.png',
    },
    {
      emoji: '🧛‍♂',
      name: 'vampire_man',
      shortcodes: ['man_vampire'],
      tags: ['dracula', 'undead'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9db-200d-2642-fe0f.png',
    },
    {
      emoji: '🧛‍♀',
      name: 'vampire_woman',
      shortcodes: ['woman_vampire'],
      tags: ['undead'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9db-200d-2640-fe0f.png',
    },
    {
      emoji: '🧜',
      name: 'merperson',
      shortcodes: ['merperson'],
      tags: ['mermaid', 'merman', 'merwoman'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dc.png',
    },
    {
      emoji: '🧜‍♂',
      name: 'merman',
      shortcodes: ['merman'],
      tags: ['triton'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dc-200d-2642-fe0f.png',
    },
    {
      emoji: '🧜‍♀',
      name: 'mermaid',
      shortcodes: ['mermaid'],
      tags: ['merwoman'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dc-200d-2640-fe0f.png',
    },
    {
      emoji: '🧝',
      name: 'elf',
      shortcodes: ['elf'],
      tags: ['magical'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dd.png',
    },
    {
      emoji: '🧝‍♂',
      name: 'elf_man',
      shortcodes: ['man_elf'],
      tags: ['magical'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dd-200d-2642-fe0f.png',
    },
    {
      emoji: '🧝‍♀',
      name: 'elf_woman',
      shortcodes: ['woman_elf'],
      tags: ['magical'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9dd-200d-2640-fe0f.png',
    },
    {
      emoji: '🧞',
      name: 'genie',
      shortcodes: ['genie'],
      tags: ['djinn'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9de.png',
    },
    {
      emoji: '🧞‍♂',
      name: 'genie_man',
      shortcodes: ['man_genie'],
      tags: ['djinn'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9de-200d-2642-fe0f.png',
    },
    {
      emoji: '🧞‍♀',
      name: 'genie_woman',
      shortcodes: ['woman_genie'],
      tags: ['djinn'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9de-200d-2640-fe0f.png',
    },
    {
      emoji: '🧟',
      name: 'zombie',
      shortcodes: ['zombie'],
      tags: ['undead', 'walking dead'],
      group: 'people & body',
      emoticons: ['8#'],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9df.png',
    },
    {
      emoji: '🧟‍♂',
      name: 'zombie_man',
      shortcodes: ['man_zombie'],
      tags: ['undead', 'walking dead'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9df-200d-2642-fe0f.png',
    },
    {
      emoji: '🧟‍♀',
      name: 'zombie_woman',
      shortcodes: ['woman_zombie'],
      tags: ['undead', 'walking dead'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9df-200d-2640-fe0f.png',
    },
    {
      emoji: '💆',
      name: 'massage',
      shortcodes: ['massage', 'person_getting_massage'],
      tags: ['face', 'massage', 'salon'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f486.png',
    },
    {
      emoji: '💆‍♂',
      name: 'massage_man',
      shortcodes: ['man_getting_massage'],
      tags: ['face', 'man', 'massage'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f486-200d-2642-fe0f.png',
    },
    {
      emoji: '💆‍♀',
      name: 'massage_woman',
      shortcodes: ['woman_getting_massage'],
      tags: ['face', 'massage', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f486-200d-2640-fe0f.png',
    },
    {
      emoji: '💇',
      name: 'haircut',
      shortcodes: ['haircut', 'person_getting_haircut'],
      tags: ['barber', 'beauty', 'haircut', 'parlor'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f487.png',
    },
    {
      emoji: '💇‍♂',
      name: 'haircut_man',
      shortcodes: ['man_getting_haircut'],
      tags: ['haircut', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f487-200d-2642-fe0f.png',
    },
    {
      emoji: '💇‍♀',
      name: 'haircut_woman',
      shortcodes: ['woman_getting_haircut'],
      tags: ['haircut', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f487-200d-2640-fe0f.png',
    },
    {
      emoji: '🚶',
      name: 'walking',
      shortcodes: ['person_walking', 'walking'],
      tags: ['hike', 'walk', 'walking'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b6.png',
    },
    {
      emoji: '🚶‍♂',
      name: 'walking_man',
      shortcodes: ['man_walking'],
      tags: ['hike', 'man', 'walk'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b6-200d-2642-fe0f.png',
    },
    {
      emoji: '🚶‍♀',
      name: 'walking_woman',
      shortcodes: ['woman_walking'],
      tags: ['hike', 'walk', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b6-200d-2640-fe0f.png',
    },
    {
      emoji: '🧍',
      name: 'standing_person',
      shortcodes: ['person_standing', 'standing'],
      tags: ['stand', 'standing'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cd.png',
    },
    {
      emoji: '🧍‍♂',
      name: 'standing_man',
      shortcodes: ['man_standing'],
      tags: ['man', 'standing'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cd-200d-2642-fe0f.png',
    },
    {
      emoji: '🧍‍♀',
      name: 'standing_woman',
      shortcodes: ['woman_standing'],
      tags: ['standing', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cd-200d-2640-fe0f.png',
    },
    {
      emoji: '🧎',
      name: 'kneeling_person',
      shortcodes: ['kneeling', 'person_kneeling'],
      tags: ['kneel', 'kneeling'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ce.png',
    },
    {
      emoji: '🧎‍♂',
      name: 'kneeling_man',
      shortcodes: ['man_kneeling'],
      tags: ['kneeling', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ce-200d-2642-fe0f.png',
    },
    {
      emoji: '🧎‍♀',
      name: 'kneeling_woman',
      shortcodes: ['woman_kneeling'],
      tags: ['kneeling', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ce-200d-2640-fe0f.png',
    },
    {
      emoji: '🧑‍🦯',
      name: 'person_with_probing_cane',
      shortcodes: ['person_with_probing_cane', 'person_with_white_cane'],
      tags: ['accessibility', 'blind'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9af.png',
    },
    {
      emoji: '👨‍🦯',
      name: 'man_with_probing_cane',
      shortcodes: ['man_with_probing_cane', 'man_with_white_cane'],
      tags: ['accessibility', 'blind', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9af.png',
    },
    {
      emoji: '👩‍🦯',
      name: 'woman_with_probing_cane',
      shortcodes: ['woman_with_probing_cane', 'woman_with_white_cane'],
      tags: ['accessibility', 'blind', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9af.png',
    },
    {
      emoji: '🧑‍🦼',
      name: 'person_in_motorized_wheelchair',
      shortcodes: ['person_in_motorized_wheelchair'],
      tags: ['accessibility', 'wheelchair'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9bc.png',
    },
    {
      emoji: '👨‍🦼',
      name: 'man_in_motorized_wheelchair',
      shortcodes: ['man_in_motorized_wheelchair'],
      tags: ['accessibility', 'man', 'wheelchair'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9bc.png',
    },
    {
      emoji: '👩‍🦼',
      name: 'woman_in_motorized_wheelchair',
      shortcodes: ['woman_in_motorized_wheelchair'],
      tags: ['accessibility', 'wheelchair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9bc.png',
    },
    {
      emoji: '🧑‍🦽',
      name: 'person_in_manual_wheelchair',
      shortcodes: ['person_in_manual_wheelchair'],
      tags: ['accessibility', 'wheelchair'],
      group: 'people & body',
      emoticons: [],
      version: 12.1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f9bd.png',
    },
    {
      emoji: '👨‍🦽',
      name: 'man_in_manual_wheelchair',
      shortcodes: ['man_in_manual_wheelchair'],
      tags: ['accessibility', 'man', 'wheelchair'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f9bd.png',
    },
    {
      emoji: '👩‍🦽',
      name: 'woman_in_manual_wheelchair',
      shortcodes: ['woman_in_manual_wheelchair'],
      tags: ['accessibility', 'wheelchair', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f9bd.png',
    },
    {
      emoji: '🏃',
      name: 'runner',
      shortcodes: ['person_running', 'running'],
      tags: ['marathon', 'running'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c3.png',
    },
    {
      emoji: '🏃‍♂',
      name: 'running_man',
      shortcodes: ['man_running'],
      tags: ['man', 'marathon', 'racing', 'running'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c3-200d-2642-fe0f.png',
    },
    {
      emoji: '🏃‍♀',
      name: 'running_woman',
      shortcodes: ['woman_running'],
      tags: ['marathon', 'racing', 'running', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c3-200d-2640-fe0f.png',
    },
    {
      emoji: '💃',
      name: 'dancer',
      shortcodes: ['dancer', 'woman_dancing'],
      tags: ['dance', 'dancing', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f483.png',
    },
    {
      emoji: '🕺',
      name: 'man_dancing',
      shortcodes: ['man_dancing'],
      tags: ['dance', 'dancing', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f57a.png',
    },
    {
      emoji: '🕴',
      name: 'business_suit_levitating',
      shortcodes: ['levitate', 'levitating', 'person_in_suit_levitating'],
      tags: ['business', 'person', 'suit'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f574-fe0f.png',
    },
    {
      emoji: '👯',
      name: 'dancers',
      shortcodes: ['dancers', 'people_with_bunny_ears_partying'],
      tags: ['bunny ear', 'dancer', 'partying'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46f.png',
    },
    {
      emoji: '👯‍♂',
      name: 'dancing_men',
      shortcodes: ['men_with_bunny_ears_partying'],
      tags: ['bunny ear', 'dancer', 'men', 'partying'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46f-200d-2642-fe0f.png',
    },
    {
      emoji: '👯‍♀',
      name: 'dancing_women',
      shortcodes: ['women_with_bunny_ears_partying'],
      tags: ['bunny ear', 'dancer', 'partying', 'women'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46f-200d-2640-fe0f.png',
    },
    {
      emoji: '🧖',
      name: 'sauna_person',
      shortcodes: ['person_in_steamy_room'],
      tags: ['sauna', 'steam room'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d6.png',
    },
    {
      emoji: '🧖‍♂',
      name: 'sauna_man',
      shortcodes: ['man_in_steamy_room'],
      tags: ['sauna', 'steam room'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d6-200d-2642-fe0f.png',
    },
    {
      emoji: '🧖‍♀',
      name: 'sauna_woman',
      shortcodes: ['woman_in_steamy_room'],
      tags: ['sauna', 'steam room'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d6-200d-2640-fe0f.png',
    },
    {
      emoji: '🧗',
      name: 'climbing',
      shortcodes: ['climbing', 'person_climbing'],
      tags: ['climber'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d7.png',
    },
    {
      emoji: '🧗‍♂',
      name: 'climbing_man',
      shortcodes: ['man_climbing'],
      tags: ['climber'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d7-200d-2642-fe0f.png',
    },
    {
      emoji: '🧗‍♀',
      name: 'climbing_woman',
      shortcodes: ['woman_climbing'],
      tags: ['climber'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d7-200d-2640-fe0f.png',
    },
    {
      emoji: '🤺',
      name: 'person_fencing',
      shortcodes: ['fencer', 'fencing', 'person_fencing'],
      tags: ['fencer', 'fencing', 'sword'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93a.png',
    },
    {
      emoji: '🏇',
      name: 'horse_racing',
      shortcodes: ['horse_racing'],
      tags: ['horse', 'jockey', 'racehorse', 'racing'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c7.png',
    },
    {
      emoji: '⛷',
      name: 'skier',
      shortcodes: ['person_skiing', 'skier', 'skiing'],
      tags: ['ski', 'snow'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f7-fe0f.png',
    },
    {
      emoji: '🏂',
      name: 'snowboarder',
      shortcodes: ['person_snowboarding', 'snowboarder', 'snowboarding'],
      tags: ['ski', 'snow', 'snowboard'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c2.png',
    },
    {
      emoji: '🏌',
      name: 'golfing',
      shortcodes: ['golfer', 'golfing', 'person_golfing'],
      tags: ['ball', 'golf'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cc-fe0f.png',
    },
    {
      emoji: '🏌‍♂️',
      name: 'golfing_man',
      shortcodes: ['man_golfing'],
      tags: ['golf', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cc-fe0f-200d-2642-fe0f.png',
    },
    {
      emoji: '🏌‍♀️',
      name: 'golfing_woman',
      shortcodes: ['woman_golfing'],
      tags: ['golf', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cc-fe0f-200d-2640-fe0f.png',
    },
    {
      emoji: '🏄',
      name: 'surfer',
      shortcodes: ['person_surfing', 'surfer', 'surfing'],
      tags: ['surfing'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c4.png',
    },
    {
      emoji: '🏄‍♂',
      name: 'surfing_man',
      shortcodes: ['man_surfing'],
      tags: ['man', 'surfing'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c4-200d-2642-fe0f.png',
    },
    {
      emoji: '🏄‍♀',
      name: 'surfing_woman',
      shortcodes: ['woman_surfing'],
      tags: ['surfing', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c4-200d-2640-fe0f.png',
    },
    {
      emoji: '🚣',
      name: 'rowboat',
      shortcodes: ['person_rowing_boat', 'rowboat'],
      tags: ['boat', 'rowboat'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a3.png',
    },
    {
      emoji: '🚣‍♂',
      name: 'rowing_man',
      shortcodes: ['man_rowing_boat'],
      tags: ['boat', 'man', 'rowboat'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a3-200d-2642-fe0f.png',
    },
    {
      emoji: '🚣‍♀',
      name: 'rowing_woman',
      shortcodes: ['woman_rowing_boat'],
      tags: ['boat', 'rowboat', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a3-200d-2640-fe0f.png',
    },
    {
      emoji: '🏊',
      name: 'swimmer',
      shortcodes: ['person_swimming', 'swimmer', 'swimming'],
      tags: ['swim'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ca.png',
    },
    {
      emoji: '🏊‍♂',
      name: 'swimming_man',
      shortcodes: ['man_swimming'],
      tags: ['man', 'swim'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ca-200d-2642-fe0f.png',
    },
    {
      emoji: '🏊‍♀',
      name: 'swimming_woman',
      shortcodes: ['woman_swimming'],
      tags: ['swim', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ca-200d-2640-fe0f.png',
    },
    {
      emoji: '⛹',
      name: 'bouncing_ball_person',
      shortcodes: ['person_bouncing_ball'],
      tags: ['ball'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f9-fe0f.png',
    },
    {
      emoji: '⛹‍♂️',
      name: 'basketball_man',
      shortcodes: ['man_bouncing_ball'],
      tags: ['ball', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f9-fe0f-200d-2642-fe0f.png',
    },
    {
      emoji: '⛹‍♀️',
      name: 'basketball_woman',
      shortcodes: ['woman_bouncing_ball'],
      tags: ['ball', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f9-fe0f-200d-2640-fe0f.png',
    },
    {
      emoji: '🏋',
      name: 'weight_lifting',
      shortcodes: ['person_lifting_weights', 'weight_lifter', 'weight_lifting'],
      tags: ['lifter', 'weight'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cb-fe0f.png',
    },
    {
      emoji: '🏋‍♂️',
      name: 'weight_lifting_man',
      shortcodes: ['man_lifting_weights'],
      tags: ['man', 'weight lifter'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cb-fe0f-200d-2642-fe0f.png',
    },
    {
      emoji: '🏋‍♀️',
      name: 'weight_lifting_woman',
      shortcodes: ['woman_lifting_weights'],
      tags: ['weight lifter', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cb-fe0f-200d-2640-fe0f.png',
    },
    {
      emoji: '🚴',
      name: 'bicyclist',
      shortcodes: ['bicyclist', 'biking', 'person_biking'],
      tags: ['bicycle', 'biking', 'cyclist'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b4.png',
    },
    {
      emoji: '🚴‍♂',
      name: 'biking_man',
      shortcodes: ['man_biking'],
      tags: ['bicycle', 'biking', 'cyclist', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b4-200d-2642-fe0f.png',
    },
    {
      emoji: '🚴‍♀',
      name: 'biking_woman',
      shortcodes: ['woman_biking'],
      tags: ['bicycle', 'biking', 'cyclist', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b4-200d-2640-fe0f.png',
    },
    {
      emoji: '🚵',
      name: 'mountain_bicyclist',
      shortcodes: [
        'mountain_bicyclist',
        'mountain_biking',
        'person_mountain_biking',
      ],
      tags: ['bicycle', 'bicyclist', 'bike', 'cyclist', 'mountain'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b5.png',
    },
    {
      emoji: '🚵‍♂',
      name: 'mountain_biking_man',
      shortcodes: ['man_mountain_biking'],
      tags: ['bicycle', 'bike', 'cyclist', 'man', 'mountain'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b5-200d-2642-fe0f.png',
    },
    {
      emoji: '🚵‍♀',
      name: 'mountain_biking_woman',
      shortcodes: ['woman_mountain_biking'],
      tags: ['bicycle', 'bike', 'biking', 'cyclist', 'mountain', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b5-200d-2640-fe0f.png',
    },
    {
      emoji: '🤸',
      name: 'cartwheeling',
      shortcodes: ['cartwheeling', 'person_cartwheel'],
      tags: ['cartwheel', 'gymnastics'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f938.png',
    },
    {
      emoji: '🤸‍♂',
      name: 'man_cartwheeling',
      shortcodes: ['man_cartwheeling'],
      tags: ['cartwheel', 'gymnastics', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f938-200d-2642-fe0f.png',
    },
    {
      emoji: '🤸‍♀',
      name: 'woman_cartwheeling',
      shortcodes: ['woman_cartwheeling'],
      tags: ['cartwheel', 'gymnastics', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f938-200d-2640-fe0f.png',
    },
    {
      emoji: '🤼',
      name: 'wrestling',
      shortcodes: ['people_wrestling', 'wrestlers', 'wrestling'],
      tags: ['wrestle', 'wrestler'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93c.png',
    },
    {
      emoji: '🤼‍♂',
      name: 'men_wrestling',
      shortcodes: ['men_wrestling'],
      tags: ['men', 'wrestle'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93c-200d-2642-fe0f.png',
    },
    {
      emoji: '🤼‍♀',
      name: 'women_wrestling',
      shortcodes: ['women_wrestling'],
      tags: ['women', 'wrestle'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93c-200d-2640-fe0f.png',
    },
    {
      emoji: '🤽',
      name: 'water_polo',
      shortcodes: ['person_playing_water_polo', 'water_polo'],
      tags: ['polo', 'water'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93d.png',
    },
    {
      emoji: '🤽‍♂',
      name: 'man_playing_water_polo',
      shortcodes: ['man_playing_water_polo'],
      tags: ['man', 'water polo'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93d-200d-2642-fe0f.png',
    },
    {
      emoji: '🤽‍♀',
      name: 'woman_playing_water_polo',
      shortcodes: ['woman_playing_water_polo'],
      tags: ['water polo', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93d-200d-2640-fe0f.png',
    },
    {
      emoji: '🤾',
      name: 'handball_person',
      shortcodes: ['handball', 'person_playing_handball'],
      tags: ['ball', 'handball'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93e.png',
    },
    {
      emoji: '🤾‍♂',
      name: 'man_playing_handball',
      shortcodes: ['man_playing_handball'],
      tags: ['handball', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93e-200d-2642-fe0f.png',
    },
    {
      emoji: '🤾‍♀',
      name: 'woman_playing_handball',
      shortcodes: ['woman_playing_handball'],
      tags: ['handball', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93e-200d-2640-fe0f.png',
    },
    {
      emoji: '🤹',
      name: 'juggling_person',
      shortcodes: ['juggler', 'juggling', 'person_juggling'],
      tags: ['balance', 'juggle', 'multitask', 'skill'],
      group: 'people & body',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f939.png',
    },
    {
      emoji: '🤹‍♂',
      name: 'man_juggling',
      shortcodes: ['man_juggling'],
      tags: ['juggling', 'man', 'multitask'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f939-200d-2642-fe0f.png',
    },
    {
      emoji: '🤹‍♀',
      name: 'woman_juggling',
      shortcodes: ['woman_juggling'],
      tags: ['juggling', 'multitask', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f939-200d-2640-fe0f.png',
    },
    {
      emoji: '🧘',
      name: 'lotus_position',
      shortcodes: ['person_in_lotus_position'],
      tags: ['meditation', 'yoga'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d8.png',
    },
    {
      emoji: '🧘‍♂',
      name: 'lotus_position_man',
      shortcodes: ['man_in_lotus_position'],
      tags: ['meditation', 'yoga'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d8-200d-2642-fe0f.png',
    },
    {
      emoji: '🧘‍♀',
      name: 'lotus_position_woman',
      shortcodes: ['woman_in_lotus_position'],
      tags: ['meditation', 'yoga'],
      group: 'people & body',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d8-200d-2640-fe0f.png',
    },
    {
      emoji: '🛀',
      name: 'bath',
      shortcodes: ['bath', 'person_taking_bath'],
      tags: ['bath', 'bathtub'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c0.png',
    },
    {
      emoji: '🛌',
      name: 'sleeping_bed',
      shortcodes: ['person_in_bed', 'sleeping_accommodation'],
      tags: ['good night', 'hotel', 'sleep'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6cc.png',
    },
    {
      emoji: '🧑‍🤝‍🧑',
      name: 'people_holding_hands',
      shortcodes: ['people_holding_hands'],
      tags: ['couple', 'hand', 'hold', 'holding hands', 'person'],
      group: 'people & body',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9d1-200d-1f91d-200d-1f9d1.png',
    },
    {
      emoji: '👭',
      name: 'two_women_holding_hands',
      shortcodes: ['two_women_holding_hands'],
      tags: ['couple', 'hand', 'holding hands', 'women'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46d.png',
    },
    {
      emoji: '👫',
      name: 'couple',
      shortcodes: ['couple'],
      tags: ['couple', 'hand', 'hold', 'holding hands', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46b.png',
    },
    {
      emoji: '👬',
      name: 'two_men_holding_hands',
      shortcodes: ['two_men_holding_hands'],
      tags: [
        'couple',
        'gemini',
        'holding hands',
        'man',
        'men',
        'twins',
        'zodiac',
      ],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46c.png',
    },
    {
      emoji: '💏',
      name: 'couplekiss',
      shortcodes: ['couple_kiss', 'couplekiss'],
      tags: ['couple'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48f.png',
    },
    {
      emoji: '👩‍❤‍💋‍👨',
      name: 'couplekiss_man_woman',
      shortcodes: ['kiss_mw', 'kiss_wm'],
      tags: ['couple', 'kiss', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png',
    },
    {
      emoji: '👨‍❤‍💋‍👨',
      name: 'couplekiss_man_man',
      shortcodes: ['kiss_mm'],
      tags: ['couple', 'kiss', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png',
    },
    {
      emoji: '👩‍❤‍💋‍👩',
      name: 'couplekiss_woman_woman',
      shortcodes: ['kiss_ww'],
      tags: ['couple', 'kiss', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png',
    },
    {
      emoji: '💑',
      name: 'couple_with_heart',
      shortcodes: ['couple_with_heart'],
      tags: ['couple', 'love'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f491.png',
    },
    {
      emoji: '👩‍❤‍👨',
      name: 'couple_with_heart_woman_man',
      shortcodes: ['couple_with_heart_mw', 'couple_with_heart_wm'],
      tags: ['couple', 'couple with heart', 'love', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2764-fe0f-200d-1f468.png',
    },
    {
      emoji: '👨‍❤‍👨',
      name: 'couple_with_heart_man_man',
      shortcodes: ['couple_with_heart_mm'],
      tags: ['couple', 'couple with heart', 'love', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-2764-fe0f-200d-1f468.png',
    },
    {
      emoji: '👩‍❤‍👩',
      name: 'couple_with_heart_woman_woman',
      shortcodes: ['couple_with_heart_ww'],
      tags: ['couple', 'couple with heart', 'love', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-2764-fe0f-200d-1f469.png',
    },
    {
      emoji: '👪',
      name: 'family',
      shortcodes: ['family'],
      tags: ['family'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f46a.png',
    },
    {
      emoji: '👨‍👩‍👦',
      name: 'family_man_woman_boy',
      shortcodes: ['family_mwb'],
      tags: ['boy', 'family', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f469-200d-1f466.png',
    },
    {
      emoji: '👨‍👩‍👧',
      name: 'family_man_woman_girl',
      shortcodes: ['family_mwg'],
      tags: ['family', 'girl', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f469-200d-1f467.png',
    },
    {
      emoji: '👨‍👩‍👧‍👦',
      name: 'family_man_woman_girl_boy',
      shortcodes: ['family_mwgb'],
      tags: ['boy', 'family', 'girl', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f469-200d-1f467-200d-1f466.png',
    },
    {
      emoji: '👨‍👩‍👦‍👦',
      name: 'family_man_woman_boy_boy',
      shortcodes: ['family_mwbb'],
      tags: ['boy', 'family', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f469-200d-1f466-200d-1f466.png',
    },
    {
      emoji: '👨‍👩‍👧‍👧',
      name: 'family_man_woman_girl_girl',
      shortcodes: ['family_mwgg'],
      tags: ['family', 'girl', 'man', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f469-200d-1f467-200d-1f467.png',
    },
    {
      emoji: '👨‍👨‍👦',
      name: 'family_man_man_boy',
      shortcodes: ['family_mmb'],
      tags: ['boy', 'family', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f468-200d-1f466.png',
    },
    {
      emoji: '👨‍👨‍👧',
      name: 'family_man_man_girl',
      shortcodes: ['family_mmg'],
      tags: ['family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f468-200d-1f467.png',
    },
    {
      emoji: '👨‍👨‍👧‍👦',
      name: 'family_man_man_girl_boy',
      shortcodes: ['family_mmgb'],
      tags: ['boy', 'family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f468-200d-1f467-200d-1f466.png',
    },
    {
      emoji: '👨‍👨‍👦‍👦',
      name: 'family_man_man_boy_boy',
      shortcodes: ['family_mmbb'],
      tags: ['boy', 'family', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f468-200d-1f466-200d-1f466.png',
    },
    {
      emoji: '👨‍👨‍👧‍👧',
      name: 'family_man_man_girl_girl',
      shortcodes: ['family_mmgg'],
      tags: ['family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f468-200d-1f467-200d-1f467.png',
    },
    {
      emoji: '👩‍👩‍👦',
      name: 'family_woman_woman_boy',
      shortcodes: ['family_wwb'],
      tags: ['boy', 'family', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f469-200d-1f466.png',
    },
    {
      emoji: '👩‍👩‍👧',
      name: 'family_woman_woman_girl',
      shortcodes: ['family_wwg'],
      tags: ['family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f469-200d-1f467.png',
    },
    {
      emoji: '👩‍👩‍👧‍👦',
      name: 'family_woman_woman_girl_boy',
      shortcodes: ['family_wwgb'],
      tags: ['boy', 'family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f469-200d-1f467-200d-1f466.png',
    },
    {
      emoji: '👩‍👩‍👦‍👦',
      name: 'family_woman_woman_boy_boy',
      shortcodes: ['family_wwbb'],
      tags: ['boy', 'family', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f469-200d-1f466-200d-1f466.png',
    },
    {
      emoji: '👩‍👩‍👧‍👧',
      name: 'family_woman_woman_girl_girl',
      shortcodes: ['family_wwgg'],
      tags: ['family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f469-200d-1f467-200d-1f467.png',
    },
    {
      emoji: '👨‍👦',
      name: 'family_man_boy',
      shortcodes: ['family_mb'],
      tags: ['boy', 'family', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f466.png',
    },
    {
      emoji: '👨‍👦‍👦',
      name: 'family_man_boy_boy',
      shortcodes: ['family_mbb'],
      tags: ['boy', 'family', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f466-200d-1f466.png',
    },
    {
      emoji: '👨‍👧',
      name: 'family_man_girl',
      shortcodes: ['family_mg'],
      tags: ['family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f467.png',
    },
    {
      emoji: '👨‍👧‍👦',
      name: 'family_man_girl_boy',
      shortcodes: ['family_mgb'],
      tags: ['boy', 'family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f467-200d-1f466.png',
    },
    {
      emoji: '👨‍👧‍👧',
      name: 'family_man_girl_girl',
      shortcodes: ['family_mgg'],
      tags: ['family', 'girl', 'man'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f468-200d-1f467-200d-1f467.png',
    },
    {
      emoji: '👩‍👦',
      name: 'family_woman_boy',
      shortcodes: ['family_wb'],
      tags: ['boy', 'family', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f466.png',
    },
    {
      emoji: '👩‍👦‍👦',
      name: 'family_woman_boy_boy',
      shortcodes: ['family_wbb'],
      tags: ['boy', 'family', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f466-200d-1f466.png',
    },
    {
      emoji: '👩‍👧',
      name: 'family_woman_girl',
      shortcodes: ['family_wg'],
      tags: ['family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f467.png',
    },
    {
      emoji: '👩‍👧‍👦',
      name: 'family_woman_girl_boy',
      shortcodes: ['family_wgb'],
      tags: ['boy', 'family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f467-200d-1f466.png',
    },
    {
      emoji: '👩‍👧‍👧',
      name: 'family_woman_girl_girl',
      shortcodes: ['family_wgg'],
      tags: ['family', 'girl', 'woman'],
      group: 'people & body',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f469-200d-1f467-200d-1f467.png',
    },
    {
      emoji: '🗣',
      name: 'speaking_head',
      shortcodes: ['speaking_head'],
      tags: ['face', 'head', 'silhouette', 'speak', 'speaking'],
      group: 'people & body',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5e3-fe0f.png',
    },
    {
      emoji: '👤',
      name: 'bust_in_silhouette',
      shortcodes: ['bust_in_silhouette'],
      tags: ['bust', 'silhouette'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f464.png',
    },
    {
      emoji: '👥',
      name: 'busts_in_silhouette',
      shortcodes: ['busts_in_silhouette'],
      tags: ['bust', 'silhouette'],
      group: 'people & body',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f465.png',
    },
    {
      emoji: '🫂',
      name: 'people_hugging',
      shortcodes: ['people_hugging'],
      tags: ['goodbye', 'hello', 'hug', 'thanks'],
      group: 'people & body',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fac2.png',
    },
    {
      emoji: '👣',
      name: 'footprints',
      shortcodes: ['footprints'],
      tags: ['clothing', 'footprint', 'print'],
      group: 'people & body',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f463.png',
    },
    {
      emoji: '🏻',
      name: 'tone1',
      shortcodes: ['tone1', 'tone_light'],
      tags: ['skin tone', 'type 1–2'],
      group: 'components',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3fb.png',
    },
    {
      emoji: '🏼',
      name: 'tone2',
      shortcodes: ['tone2', 'tone_medium_light'],
      tags: ['skin tone', 'type 3'],
      group: 'components',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3fc.png',
    },
    {
      emoji: '🏽',
      name: 'tone3',
      shortcodes: ['tone3', 'tone_medium'],
      tags: ['skin tone', 'type 4'],
      group: 'components',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3fd.png',
    },
    {
      emoji: '🏾',
      name: 'tone4',
      shortcodes: ['tone4', 'tone_medium_dark'],
      tags: ['skin tone', 'type 5'],
      group: 'components',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3fe.png',
    },
    {
      emoji: '🏿',
      name: 'tone5',
      shortcodes: ['tone5', 'tone_dark'],
      tags: ['skin tone', 'type 6'],
      group: 'components',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ff.png',
    },
    {
      emoji: '🦰',
      name: 'red_hair',
      shortcodes: ['red_hair'],
      tags: ['ginger', 'redhead'],
      group: 'components',
      emoticons: [],
      version: 11,
    },
    {
      emoji: '🦱',
      name: 'curly_hair',
      shortcodes: ['curly_hair'],
      tags: ['afro', 'curly', 'ringlets'],
      group: 'components',
      emoticons: [],
      version: 11,
    },
    {
      emoji: '🦳',
      name: 'white_hair',
      shortcodes: ['white_hair'],
      tags: ['gray', 'hair', 'old', 'white'],
      group: 'components',
      emoticons: [],
      version: 11,
    },
    {
      emoji: '🦲',
      name: 'no_hair',
      shortcodes: ['no_hair'],
      tags: ['chemotherapy', 'hairless', 'no hair', 'shaven'],
      group: 'components',
      emoticons: [],
      version: 11,
    },
    {
      emoji: '🐵',
      name: 'monkey_face',
      shortcodes: ['monkey_face'],
      tags: ['face', 'monkey'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f435.png',
    },
    {
      emoji: '🐒',
      name: 'monkey',
      shortcodes: ['monkey'],
      tags: ['monkey'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f412.png',
    },
    {
      emoji: '🦍',
      name: 'gorilla',
      shortcodes: ['gorilla'],
      tags: ['gorilla'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98d.png',
    },
    {
      emoji: '🦧',
      name: 'orangutan',
      shortcodes: ['orangutan'],
      tags: ['ape'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a7.png',
    },
    {
      emoji: '🐶',
      name: 'dog',
      shortcodes: ['dog_face'],
      tags: ['dog', 'face', 'pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f436.png',
    },
    {
      emoji: '🐕',
      name: 'dog2',
      shortcodes: ['dog'],
      tags: ['pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f415.png',
    },
    {
      emoji: '🦮',
      name: 'guide_dog',
      shortcodes: ['guide_dog'],
      tags: ['accessibility', 'blind', 'guide'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ae.png',
    },
    {
      emoji: '🐕‍🦺',
      name: 'service_dog',
      shortcodes: ['service_dog'],
      tags: ['accessibility', 'assistance', 'dog', 'service'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f415-200d-1f9ba.png',
    },
    {
      emoji: '🐩',
      name: 'poodle',
      shortcodes: ['poodle'],
      tags: ['dog'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f429.png',
    },
    {
      emoji: '🐺',
      name: 'wolf',
      shortcodes: ['wolf', 'wolf_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43a.png',
    },
    {
      emoji: '🦊',
      name: 'fox_face',
      shortcodes: ['fox', 'fox_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98a.png',
    },
    {
      emoji: '🦝',
      name: 'raccoon',
      shortcodes: ['raccoon'],
      tags: ['curious', 'sly'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99d.png',
    },
    {
      emoji: '🐱',
      name: 'cat',
      shortcodes: ['cat_face'],
      tags: ['cat', 'face', 'pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f431.png',
    },
    {
      emoji: '🐈',
      name: 'cat2',
      shortcodes: ['cat'],
      tags: ['pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f408.png',
    },
    {
      emoji: '🐈‍⬛',
      name: 'black_cat',
      shortcodes: ['black_cat'],
      tags: ['black', 'cat', 'unlucky'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f408-200d-2b1b.png',
    },
    {
      emoji: '🦁',
      name: 'lion',
      shortcodes: ['lion', 'lion_face'],
      tags: ['face', 'leo', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f981.png',
    },
    {
      emoji: '🐯',
      name: 'tiger',
      shortcodes: ['tiger_face'],
      tags: ['face', 'tiger'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42f.png',
    },
    {
      emoji: '🐅',
      name: 'tiger2',
      shortcodes: ['tiger'],
      tags: ['tiger'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f405.png',
    },
    {
      emoji: '🐆',
      name: 'leopard',
      shortcodes: ['leopard'],
      tags: ['leopard'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f406.png',
    },
    {
      emoji: '🐴',
      name: 'horse',
      shortcodes: ['horse_face'],
      tags: ['face', 'horse'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f434.png',
    },
    {
      emoji: '🐎',
      name: 'racehorse',
      shortcodes: ['horse', 'racehorse'],
      tags: ['equestrian', 'racehorse', 'racing'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40e.png',
    },
    {
      emoji: '🦄',
      name: 'unicorn',
      shortcodes: ['unicorn', 'unicorn_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f984.png',
    },
    {
      emoji: '🦓',
      name: 'zebra',
      shortcodes: ['zebra'],
      tags: ['stripe'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f993.png',
    },
    {
      emoji: '🦌',
      name: 'deer',
      shortcodes: ['deer'],
      tags: ['deer'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98c.png',
    },
    {
      emoji: '🦬',
      name: 'bison',
      shortcodes: ['bison'],
      tags: ['buffalo', 'herd', 'wisent'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ac.png',
    },
    {
      emoji: '🐮',
      name: 'cow',
      shortcodes: ['cow_face'],
      tags: ['cow', 'face'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42e.png',
    },
    {
      emoji: '🐂',
      name: 'ox',
      shortcodes: ['ox'],
      tags: ['bull', 'taurus', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f402.png',
    },
    {
      emoji: '🐃',
      name: 'water_buffalo',
      shortcodes: ['water_buffalo'],
      tags: ['buffalo', 'water'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f403.png',
    },
    {
      emoji: '🐄',
      name: 'cow2',
      shortcodes: ['cow'],
      tags: ['cow'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f404.png',
    },
    {
      emoji: '🐷',
      name: 'pig',
      shortcodes: ['pig_face'],
      tags: ['face', 'pig'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f437.png',
    },
    {
      emoji: '🐖',
      name: 'pig2',
      shortcodes: ['pig'],
      tags: ['sow'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f416.png',
    },
    {
      emoji: '🐗',
      name: 'boar',
      shortcodes: ['boar'],
      tags: ['pig'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f417.png',
    },
    {
      emoji: '🐽',
      name: 'pig_nose',
      shortcodes: ['pig_nose'],
      tags: ['face', 'nose', 'pig'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43d.png',
    },
    {
      emoji: '🐏',
      name: 'ram',
      shortcodes: ['ram'],
      tags: ['aries', 'male', 'sheep', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40f.png',
    },
    {
      emoji: '🐑',
      name: 'sheep',
      shortcodes: ['ewe', 'sheep'],
      tags: ['female', 'sheep'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f411.png',
    },
    {
      emoji: '🐐',
      name: 'goat',
      shortcodes: ['goat'],
      tags: ['capricorn', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f410.png',
    },
    {
      emoji: '🐪',
      name: 'dromedary_camel',
      shortcodes: ['dromedary_camel'],
      tags: ['dromedary', 'hump'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42a.png',
    },
    {
      emoji: '🐫',
      name: 'camel',
      shortcodes: ['camel'],
      tags: ['bactrian', 'camel', 'hump'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42b.png',
    },
    {
      emoji: '🦙',
      name: 'llama',
      shortcodes: ['llama'],
      tags: ['alpaca', 'guanaco', 'vicuña', 'wool'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f999.png',
    },
    {
      emoji: '🦒',
      name: 'giraffe',
      shortcodes: ['giraffe'],
      tags: ['spots'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f992.png',
    },
    {
      emoji: '🐘',
      name: 'elephant',
      shortcodes: ['elephant'],
      tags: ['elephant'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f418.png',
    },
    {
      emoji: '🦣',
      name: 'mammoth',
      shortcodes: ['mammoth'],
      tags: ['extinction', 'large', 'tusk', 'woolly'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a3.png',
    },
    {
      emoji: '🦏',
      name: 'rhinoceros',
      shortcodes: ['rhino', 'rhinoceros'],
      tags: ['rhinoceros'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98f.png',
    },
    {
      emoji: '🦛',
      name: 'hippopotamus',
      shortcodes: ['hippo'],
      tags: ['hippo'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99b.png',
    },
    {
      emoji: '🐭',
      name: 'mouse',
      shortcodes: ['mouse_face'],
      tags: ['face', 'mouse'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42d.png',
    },
    {
      emoji: '🐁',
      name: 'mouse2',
      shortcodes: ['mouse'],
      tags: ['mouse'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f401.png',
    },
    {
      emoji: '🐀',
      name: 'rat',
      shortcodes: ['rat'],
      tags: ['rat'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f400.png',
    },
    {
      emoji: '🐹',
      name: 'hamster',
      shortcodes: ['hamster', 'hamster_face'],
      tags: ['face', 'pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f439.png',
    },
    {
      emoji: '🐰',
      name: 'rabbit',
      shortcodes: ['rabbit_face'],
      tags: ['bunny', 'face', 'pet', 'rabbit'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f430.png',
    },
    {
      emoji: '🐇',
      name: 'rabbit2',
      shortcodes: ['rabbit'],
      tags: ['bunny', 'pet'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f407.png',
    },
    {
      emoji: '🐿',
      name: 'chipmunk',
      shortcodes: ['chipmunk'],
      tags: ['squirrel'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43f-fe0f.png',
    },
    {
      emoji: '🦫',
      name: 'beaver',
      shortcodes: ['beaver'],
      tags: ['dam'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ab.png',
    },
    {
      emoji: '🦔',
      name: 'hedgehog',
      shortcodes: ['hedgehog'],
      tags: ['spiny'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f994.png',
    },
    {
      emoji: '🦇',
      name: 'bat',
      shortcodes: ['bat'],
      tags: ['vampire'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f987.png',
    },
    {
      emoji: '🐻',
      name: 'bear',
      shortcodes: ['bear', 'bear_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43b.png',
    },
    {
      emoji: '🐻‍❄',
      name: 'polar_bear',
      shortcodes: ['polar_bear', 'polar_bear_face'],
      tags: ['arctic', 'bear', 'white'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43b-200d-2744-fe0f.png',
    },
    {
      emoji: '🐨',
      name: 'koala',
      shortcodes: ['koala', 'koala_face'],
      tags: ['face', 'marsupial'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f428.png',
    },
    {
      emoji: '🐼',
      name: 'panda_face',
      shortcodes: ['panda', 'panda_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43c.png',
    },
    {
      emoji: '🦥',
      name: 'sloth',
      shortcodes: ['sloth'],
      tags: ['lazy', 'slow'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a5.png',
    },
    {
      emoji: '🦦',
      name: 'otter',
      shortcodes: ['otter'],
      tags: ['fishing', 'playful'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a6.png',
    },
    {
      emoji: '🦨',
      name: 'skunk',
      shortcodes: ['skunk'],
      tags: ['stink'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a8.png',
    },
    {
      emoji: '🦘',
      name: 'kangaroo',
      shortcodes: ['kangaroo'],
      tags: ['australia', 'joey', 'jump', 'marsupial'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f998.png',
    },
    {
      emoji: '🦡',
      name: 'badger',
      shortcodes: ['badger'],
      tags: ['honey badger', 'pester'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a1.png',
    },
    {
      emoji: '🐾',
      name: 'feet',
      shortcodes: ['paw_prints'],
      tags: ['feet', 'paw', 'print'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f43e.png',
    },
    {
      emoji: '🦃',
      name: 'turkey',
      shortcodes: ['turkey'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f983.png',
    },
    {
      emoji: '🐔',
      name: 'chicken',
      shortcodes: ['chicken', 'chicken_face'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f414.png',
    },
    {
      emoji: '🐓',
      name: 'rooster',
      shortcodes: ['rooster'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f413.png',
    },
    {
      emoji: '🐣',
      name: 'hatching_chick',
      shortcodes: ['hatching_chick'],
      tags: ['baby', 'bird', 'chick', 'hatching'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f423.png',
    },
    {
      emoji: '🐤',
      name: 'baby_chick',
      shortcodes: ['baby_chick'],
      tags: ['baby', 'bird', 'chick'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f424.png',
    },
    {
      emoji: '🐥',
      name: 'hatched_chick',
      shortcodes: ['hatched_chick'],
      tags: ['baby', 'bird', 'chick'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f425.png',
    },
    {
      emoji: '🐦',
      name: 'bird',
      shortcodes: ['bird', 'bird_face'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f426.png',
    },
    {
      emoji: '🐧',
      name: 'penguin',
      shortcodes: ['penguin', 'penguin_face'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f427.png',
    },
    {
      emoji: '🕊',
      name: 'dove',
      shortcodes: ['dove'],
      tags: ['bird', 'fly', 'peace'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f54a-fe0f.png',
    },
    {
      emoji: '🦅',
      name: 'eagle',
      shortcodes: ['eagle'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f985.png',
    },
    {
      emoji: '🦆',
      name: 'duck',
      shortcodes: ['duck'],
      tags: ['bird'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f986.png',
    },
    {
      emoji: '🦢',
      name: 'swan',
      shortcodes: ['swan'],
      tags: ['bird', 'cygnet', 'ugly duckling'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a2.png',
    },
    {
      emoji: '🦉',
      name: 'owl',
      shortcodes: ['owl'],
      tags: ['bird', 'wise'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f989.png',
    },
    {
      emoji: '🦤',
      name: 'dodo',
      shortcodes: ['dodo'],
      tags: ['extinction', 'large', 'mauritius'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a4.png',
    },
    {
      emoji: '🪶',
      name: 'feather',
      shortcodes: ['feather'],
      tags: ['bird', 'flight', 'light', 'plumage'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab6.png',
    },
    {
      emoji: '🦩',
      name: 'flamingo',
      shortcodes: ['flamingo'],
      tags: ['flamboyant', 'tropical'],
      group: 'animals & nature',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a9.png',
    },
    {
      emoji: '🦚',
      name: 'peacock',
      shortcodes: ['peacock'],
      tags: ['bird', 'ostentatious', 'peahen', 'proud'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99a.png',
    },
    {
      emoji: '🦜',
      name: 'parrot',
      shortcodes: ['parrot'],
      tags: ['bird', 'pirate', 'talk'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99c.png',
    },
    {
      emoji: '🐸',
      name: 'frog',
      shortcodes: ['frog', 'frog_face'],
      tags: ['face'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f438.png',
    },
    {
      emoji: '🐊',
      name: 'crocodile',
      shortcodes: ['crocodile'],
      tags: ['crocodile'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40a.png',
    },
    {
      emoji: '🐢',
      name: 'turtle',
      shortcodes: ['turtle'],
      tags: ['terrapin', 'tortoise'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f422.png',
    },
    {
      emoji: '🦎',
      name: 'lizard',
      shortcodes: ['lizard'],
      tags: ['reptile'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98e.png',
    },
    {
      emoji: '🐍',
      name: 'snake',
      shortcodes: ['snake'],
      tags: ['bearer', 'ophiuchus', 'serpent', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40d.png',
    },
    {
      emoji: '🐲',
      name: 'dragon_face',
      shortcodes: ['dragon_face'],
      tags: ['dragon', 'face', 'fairy tale'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f432.png',
    },
    {
      emoji: '🐉',
      name: 'dragon',
      shortcodes: ['dragon'],
      tags: ['fairy tale'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f409.png',
    },
    {
      emoji: '🦕',
      name: 'sauropod',
      shortcodes: ['sauropod'],
      tags: ['brachiosaurus', 'brontosaurus', 'diplodocus'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f995.png',
    },
    {
      emoji: '🦖',
      name: 't-rex',
      shortcodes: ['t-rex', 'trex'],
      tags: ['t-rex', 'tyrannosaurus rex'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f996.png',
    },
    {
      emoji: '🐳',
      name: 'whale',
      shortcodes: ['spouting_whale'],
      tags: ['face', 'spouting', 'whale'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f433.png',
    },
    {
      emoji: '🐋',
      name: 'whale2',
      shortcodes: ['whale'],
      tags: ['whale'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40b.png',
    },
    {
      emoji: '🐬',
      name: 'dolphin',
      shortcodes: ['dolphin'],
      tags: ['flipper'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f42c.png',
    },
    {
      emoji: '🦭',
      name: 'seal',
      shortcodes: ['seal'],
      tags: ['sea lion'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ad.png',
    },
    {
      emoji: '🐟',
      name: 'fish',
      shortcodes: ['fish'],
      tags: ['pisces', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41f.png',
    },
    {
      emoji: '🐠',
      name: 'tropical_fish',
      shortcodes: ['tropical_fish'],
      tags: ['fish', 'tropical'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f420.png',
    },
    {
      emoji: '🐡',
      name: 'blowfish',
      shortcodes: ['blowfish'],
      tags: ['fish'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f421.png',
    },
    {
      emoji: '🦈',
      name: 'shark',
      shortcodes: ['shark'],
      tags: ['fish'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f988.png',
    },
    {
      emoji: '🐙',
      name: 'octopus',
      shortcodes: ['octopus'],
      tags: ['octopus'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f419.png',
    },
    {
      emoji: '🐚',
      name: 'shell',
      shortcodes: ['shell'],
      tags: ['shell', 'spiral'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41a.png',
    },
    {
      emoji: '🐌',
      name: 'snail',
      shortcodes: ['snail'],
      tags: ['snail'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f40c.png',
    },
    {
      emoji: '🦋',
      name: 'butterfly',
      shortcodes: ['butterfly'],
      tags: ['insect', 'pretty'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f98b.png',
    },
    {
      emoji: '🐛',
      name: 'bug',
      shortcodes: ['bug'],
      tags: ['insect'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41b.png',
    },
    {
      emoji: '🐜',
      name: 'ant',
      shortcodes: ['ant'],
      tags: ['insect'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41c.png',
    },
    {
      emoji: '🐝',
      name: 'bee',
      shortcodes: ['bee'],
      tags: ['bee', 'insect'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41d.png',
    },
    {
      emoji: '🪲',
      name: 'beetle',
      shortcodes: ['beetle'],
      tags: ['bug', 'insect'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab2.png',
    },
    {
      emoji: '🐞',
      name: 'lady_beetle',
      shortcodes: ['lady_beetle'],
      tags: ['beetle', 'insect', 'ladybird', 'ladybug'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f41e.png',
    },
    {
      emoji: '🦗',
      name: 'cricket',
      shortcodes: ['cricket'],
      tags: ['grasshopper'],
      group: 'animals & nature',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f997.png',
    },
    {
      emoji: '🪳',
      name: 'cockroach',
      shortcodes: ['cockroach'],
      tags: ['insect', 'pest', 'roach'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab3.png',
    },
    {
      emoji: '🕷',
      name: 'spider',
      shortcodes: ['spider'],
      tags: ['insect'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f577-fe0f.png',
    },
    {
      emoji: '🕸',
      name: 'spider_web',
      shortcodes: ['spider_web'],
      tags: ['spider', 'web'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f578-fe0f.png',
    },
    {
      emoji: '🦂',
      name: 'scorpion',
      shortcodes: ['scorpion'],
      tags: ['scorpio', 'zodiac'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f982.png',
    },
    {
      emoji: '🦟',
      name: 'mosquito',
      shortcodes: ['mosquito'],
      tags: ['disease', 'fever', 'malaria', 'pest', 'virus'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99f.png',
    },
    {
      emoji: '🪰',
      name: 'fly',
      shortcodes: ['fly'],
      tags: ['disease', 'maggot', 'pest', 'rotting'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab0.png',
    },
    {
      emoji: '🪱',
      name: 'worm',
      shortcodes: ['worm'],
      tags: ['annelid', 'earthworm', 'parasite'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab1.png',
    },
    {
      emoji: '🦠',
      name: 'microbe',
      shortcodes: ['microbe'],
      tags: ['amoeba', 'bacteria', 'virus'],
      group: 'animals & nature',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a0.png',
    },
    {
      emoji: '💐',
      name: 'bouquet',
      shortcodes: ['bouquet'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f490.png',
    },
    {
      emoji: '🌸',
      name: 'cherry_blossom',
      shortcodes: ['cherry_blossom'],
      tags: ['blossom', 'cherry', 'flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f338.png',
    },
    {
      emoji: '💮',
      name: 'white_flower',
      shortcodes: ['white_flower'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ae.png',
    },
    {
      emoji: '🏵',
      name: 'rosette',
      shortcodes: ['rosette'],
      tags: ['plant'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f5-fe0f.png',
    },
    {
      emoji: '🌹',
      name: 'rose',
      shortcodes: ['rose'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f339.png',
    },
    {
      emoji: '🥀',
      name: 'wilted_flower',
      shortcodes: ['wilted_flower'],
      tags: ['flower', 'wilted'],
      group: 'animals & nature',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f940.png',
    },
    {
      emoji: '🌺',
      name: 'hibiscus',
      shortcodes: ['hibiscus'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33a.png',
    },
    {
      emoji: '🌻',
      name: 'sunflower',
      shortcodes: ['sunflower'],
      tags: ['flower', 'sun'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33b.png',
    },
    {
      emoji: '🌼',
      name: 'blossom',
      shortcodes: ['blossom'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33c.png',
    },
    {
      emoji: '🌷',
      name: 'tulip',
      shortcodes: ['tulip'],
      tags: ['flower'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f337.png',
    },
    {
      emoji: '🌱',
      name: 'seedling',
      shortcodes: ['seedling'],
      tags: ['young'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f331.png',
    },
    {
      emoji: '🪴',
      name: 'potted_plant',
      shortcodes: ['potted_plant'],
      tags: ['boring', 'grow', 'house', 'nurturing', 'plant', 'useless'],
      group: 'animals & nature',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab4.png',
    },
    {
      emoji: '🌲',
      name: 'evergreen_tree',
      shortcodes: ['evergreen_tree'],
      tags: ['tree'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f332.png',
    },
    {
      emoji: '🌳',
      name: 'deciduous_tree',
      shortcodes: ['deciduous_tree'],
      tags: ['deciduous', 'shedding', 'tree'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f333.png',
    },
    {
      emoji: '🌴',
      name: 'palm_tree',
      shortcodes: ['palm_tree'],
      tags: ['palm', 'tree'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f334.png',
    },
    {
      emoji: '🌵',
      name: 'cactus',
      shortcodes: ['cactus'],
      tags: ['plant'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f335.png',
    },
    {
      emoji: '🌾',
      name: 'ear_of_rice',
      shortcodes: ['ear_of_rice', 'sheaf_of_rice'],
      tags: ['ear', 'grain', 'rice'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33e.png',
    },
    {
      emoji: '🌿',
      name: 'herb',
      shortcodes: ['herb'],
      tags: ['leaf'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33f.png',
    },
    {
      emoji: '☘',
      name: 'shamrock',
      shortcodes: ['shamrock'],
      tags: ['plant'],
      group: 'animals & nature',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2618-fe0f.png',
    },
    {
      emoji: '🍀',
      name: 'four_leaf_clover',
      shortcodes: ['four_leaf_clover'],
      tags: ['4', 'clover', 'four', 'four-leaf clover', 'leaf'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f340.png',
    },
    {
      emoji: '🍁',
      name: 'maple_leaf',
      shortcodes: ['maple_leaf'],
      tags: ['falling', 'leaf', 'maple'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f341.png',
    },
    {
      emoji: '🍂',
      name: 'fallen_leaf',
      shortcodes: ['fallen_leaf'],
      tags: ['falling', 'leaf'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f342.png',
    },
    {
      emoji: '🍃',
      name: 'leaves',
      shortcodes: ['leaves'],
      tags: ['blow', 'flutter', 'leaf', 'wind'],
      group: 'animals & nature',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f343.png',
    },
    {
      emoji: '🍇',
      name: 'grapes',
      shortcodes: ['grapes'],
      tags: ['fruit', 'grape'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f347.png',
    },
    {
      emoji: '🍈',
      name: 'melon',
      shortcodes: ['melon'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f348.png',
    },
    {
      emoji: '🍉',
      name: 'watermelon',
      shortcodes: ['watermelon'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f349.png',
    },
    {
      emoji: '🍊',
      name: 'mandarin',
      shortcodes: ['orange', 'tangerine'],
      tags: ['fruit', 'orange'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34a.png',
    },
    {
      emoji: '🍋',
      name: 'lemon',
      shortcodes: ['lemon'],
      tags: ['citrus', 'fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34b.png',
    },
    {
      emoji: '🍌',
      name: 'banana',
      shortcodes: ['banana'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34c.png',
    },
    {
      emoji: '🍍',
      name: 'pineapple',
      shortcodes: ['pineapple'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34d.png',
    },
    {
      emoji: '🥭',
      name: 'mango',
      shortcodes: ['mango'],
      tags: ['fruit', 'tropical'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96d.png',
    },
    {
      emoji: '🍎',
      name: 'apple',
      shortcodes: ['apple', 'red_apple'],
      tags: ['apple', 'fruit', 'red'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34e.png',
    },
    {
      emoji: '🍏',
      name: 'green_apple',
      shortcodes: ['green_apple'],
      tags: ['apple', 'fruit', 'green'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f34f.png',
    },
    {
      emoji: '🍐',
      name: 'pear',
      shortcodes: ['pear'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f350.png',
    },
    {
      emoji: '🍑',
      name: 'peach',
      shortcodes: ['peach'],
      tags: ['fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f351.png',
    },
    {
      emoji: '🍒',
      name: 'cherries',
      shortcodes: ['cherries'],
      tags: ['berries', 'cherry', 'fruit', 'red'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f352.png',
    },
    {
      emoji: '🍓',
      name: 'strawberry',
      shortcodes: ['strawberry'],
      tags: ['berry', 'fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f353.png',
    },
    {
      emoji: '🫐',
      name: 'blueberries',
      shortcodes: ['blueberries'],
      tags: ['berry', 'bilberry', 'blue', 'blueberry'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad0.png',
    },
    {
      emoji: '🥝',
      name: 'kiwi_fruit',
      shortcodes: ['kiwi'],
      tags: ['food', 'fruit', 'kiwi'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95d.png',
    },
    {
      emoji: '🍅',
      name: 'tomato',
      shortcodes: ['tomato'],
      tags: ['fruit', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f345.png',
    },
    {
      emoji: '🫒',
      name: 'olive',
      shortcodes: ['olive'],
      tags: ['food'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad2.png',
    },
    {
      emoji: '🥥',
      name: 'coconut',
      shortcodes: ['coconut'],
      tags: ['palm', 'piña colada'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f965.png',
    },
    {
      emoji: '🥑',
      name: 'avocado',
      shortcodes: ['avocado'],
      tags: ['food', 'fruit'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f951.png',
    },
    {
      emoji: '🍆',
      name: 'eggplant',
      shortcodes: ['eggplant'],
      tags: ['aubergine', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f346.png',
    },
    {
      emoji: '🥔',
      name: 'potato',
      shortcodes: ['potato'],
      tags: ['food', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f954.png',
    },
    {
      emoji: '🥕',
      name: 'carrot',
      shortcodes: ['carrot'],
      tags: ['food', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f955.png',
    },
    {
      emoji: '🌽',
      name: 'corn',
      shortcodes: ['corn', 'ear_of_corn'],
      tags: ['corn', 'ear', 'maize', 'maze'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f33d.png',
    },
    {
      emoji: '🌶',
      name: 'hot_pepper',
      shortcodes: ['hot_pepper'],
      tags: ['hot', 'pepper'],
      group: 'food & drink',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f336-fe0f.png',
    },
    {
      emoji: '🫑',
      name: 'bell_pepper',
      shortcodes: ['bell_pepper'],
      tags: ['capsicum', 'pepper', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad1.png',
    },
    {
      emoji: '🥒',
      name: 'cucumber',
      shortcodes: ['cucumber'],
      tags: ['food', 'pickle', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f952.png',
    },
    {
      emoji: '🥬',
      name: 'leafy_green',
      shortcodes: ['leafy_green'],
      tags: ['bok choy', 'cabbage', 'kale', 'lettuce'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96c.png',
    },
    {
      emoji: '🥦',
      name: 'broccoli',
      shortcodes: ['broccoli'],
      tags: ['wild cabbage'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f966.png',
    },
    {
      emoji: '🧄',
      name: 'garlic',
      shortcodes: ['garlic'],
      tags: ['flavoring'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c4.png',
    },
    {
      emoji: '🧅',
      name: 'onion',
      shortcodes: ['onion'],
      tags: ['flavoring'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c5.png',
    },
    {
      emoji: '🍄',
      name: 'mushroom',
      shortcodes: ['mushroom'],
      tags: ['toadstool'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f344.png',
    },
    {
      emoji: '🥜',
      name: 'peanuts',
      shortcodes: ['peanuts'],
      tags: ['food', 'nut', 'peanut', 'vegetable'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95c.png',
    },
    {
      emoji: '🌰',
      name: 'chestnut',
      shortcodes: ['chestnut'],
      tags: ['plant'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f330.png',
    },
    {
      emoji: '🍞',
      name: 'bread',
      shortcodes: ['bread'],
      tags: ['loaf'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35e.png',
    },
    {
      emoji: '🥐',
      name: 'croissant',
      shortcodes: ['croissant'],
      tags: ['bread', 'breakfast', 'food', 'french', 'roll'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f950.png',
    },
    {
      emoji: '🥖',
      name: 'baguette_bread',
      shortcodes: ['baguette_bread'],
      tags: ['baguette', 'bread', 'food', 'french'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f956.png',
    },
    {
      emoji: '🫓',
      name: 'flatbread',
      shortcodes: ['flatbread'],
      tags: ['arepa', 'lavash', 'naan', 'pita'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad3.png',
    },
    {
      emoji: '🥨',
      name: 'pretzel',
      shortcodes: ['pretzel'],
      tags: ['twisted'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f968.png',
    },
    {
      emoji: '🥯',
      name: 'bagel',
      shortcodes: ['bagel'],
      tags: ['bakery', 'breakfast', 'schmear'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96f.png',
    },
    {
      emoji: '🥞',
      name: 'pancakes',
      shortcodes: ['pancakes'],
      tags: ['breakfast', 'crêpe', 'food', 'hotcake', 'pancake'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95e.png',
    },
    {
      emoji: '🧇',
      name: 'waffle',
      shortcodes: ['waffle'],
      tags: ['breakfast', 'indecisive', 'iron'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c7.png',
    },
    {
      emoji: '🧀',
      name: 'cheese',
      shortcodes: ['cheese'],
      tags: ['cheese'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c0.png',
    },
    {
      emoji: '🍖',
      name: 'meat_on_bone',
      shortcodes: ['meat_on_bone'],
      tags: ['bone', 'meat'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f356.png',
    },
    {
      emoji: '🍗',
      name: 'poultry_leg',
      shortcodes: ['poultry_leg'],
      tags: ['bone', 'chicken', 'drumstick', 'leg', 'poultry'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f357.png',
    },
    {
      emoji: '🥩',
      name: 'cut_of_meat',
      shortcodes: ['cut_of_meat'],
      tags: ['chop', 'lambchop', 'porkchop', 'steak'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f969.png',
    },
    {
      emoji: '🥓',
      name: 'bacon',
      shortcodes: ['bacon'],
      tags: ['breakfast', 'food', 'meat'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f953.png',
    },
    {
      emoji: '🍔',
      name: 'hamburger',
      shortcodes: ['hamburger'],
      tags: ['burger'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f354.png',
    },
    {
      emoji: '🍟',
      name: 'fries',
      shortcodes: ['french_fries', 'fries'],
      tags: ['french', 'fries'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35f.png',
    },
    {
      emoji: '🍕',
      name: 'pizza',
      shortcodes: ['pizza'],
      tags: ['cheese', 'slice'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f355.png',
    },
    {
      emoji: '🌭',
      name: 'hotdog',
      shortcodes: ['hotdog'],
      tags: ['frankfurter', 'hotdog', 'sausage'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32d.png',
    },
    {
      emoji: '🥪',
      name: 'sandwich',
      shortcodes: ['sandwich'],
      tags: ['bread'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96a.png',
    },
    {
      emoji: '🌮',
      name: 'taco',
      shortcodes: ['taco'],
      tags: ['mexican'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32e.png',
    },
    {
      emoji: '🌯',
      name: 'burrito',
      shortcodes: ['burrito'],
      tags: ['mexican', 'wrap'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32f.png',
    },
    {
      emoji: '🫔',
      name: 'tamale',
      shortcodes: ['tamale'],
      tags: ['mexican', 'wrapped'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad4.png',
    },
    {
      emoji: '🥙',
      name: 'stuffed_flatbread',
      shortcodes: ['stuffed_flatbread'],
      tags: ['falafel', 'flatbread', 'food', 'gyro', 'kebab', 'stuffed'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f959.png',
    },
    {
      emoji: '🧆',
      name: 'falafel',
      shortcodes: ['falafel'],
      tags: ['chickpea', 'meatball'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c6.png',
    },
    {
      emoji: '🥚',
      name: 'egg',
      shortcodes: ['egg'],
      tags: ['breakfast', 'food'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95a.png',
    },
    {
      emoji: '🍳',
      name: 'fried_egg',
      shortcodes: ['cooking', 'fried_egg'],
      tags: ['breakfast', 'egg', 'frying', 'pan'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f373.png',
    },
    {
      emoji: '🥘',
      name: 'shallow_pan_of_food',
      shortcodes: ['shallow_pan_of_food'],
      tags: ['casserole', 'food', 'paella', 'pan', 'shallow'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f958.png',
    },
    {
      emoji: '🍲',
      name: 'stew',
      shortcodes: ['pot_of_food', 'stew'],
      tags: ['pot', 'stew'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f372.png',
    },
    {
      emoji: '🫕',
      name: 'fondue',
      shortcodes: ['fondue'],
      tags: ['cheese', 'chocolate', 'melted', 'pot', 'swiss'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad5.png',
    },
    {
      emoji: '🥣',
      name: 'bowl_with_spoon',
      shortcodes: ['bowl_with_spoon'],
      tags: ['breakfast', 'cereal', 'congee'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f963.png',
    },
    {
      emoji: '🥗',
      name: 'green_salad',
      shortcodes: ['green_salad', 'salad'],
      tags: ['food', 'green', 'salad'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f957.png',
    },
    {
      emoji: '🍿',
      name: 'popcorn',
      shortcodes: ['popcorn'],
      tags: ['popcorn'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37f.png',
    },
    {
      emoji: '🧈',
      name: 'butter',
      shortcodes: ['butter'],
      tags: ['dairy'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c8.png',
    },
    {
      emoji: '🧂',
      name: 'salt',
      shortcodes: ['salt'],
      tags: ['condiment', 'shaker'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c2.png',
    },
    {
      emoji: '🥫',
      name: 'canned_food',
      shortcodes: ['canned_food'],
      tags: ['can'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96b.png',
    },
    {
      emoji: '🍱',
      name: 'bento',
      shortcodes: ['bento', 'bento_box'],
      tags: ['bento', 'box'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f371.png',
    },
    {
      emoji: '🍘',
      name: 'rice_cracker',
      shortcodes: ['rice_cracker'],
      tags: ['cracker', 'rice'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f358.png',
    },
    {
      emoji: '🍙',
      name: 'rice_ball',
      shortcodes: ['rice_ball'],
      tags: ['ball', 'japanese', 'rice'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f359.png',
    },
    {
      emoji: '🍚',
      name: 'rice',
      shortcodes: ['cooked_rice', 'rice'],
      tags: ['cooked', 'rice'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35a.png',
    },
    {
      emoji: '🍛',
      name: 'curry',
      shortcodes: ['curry', 'curry_rice'],
      tags: ['curry', 'rice'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35b.png',
    },
    {
      emoji: '🍜',
      name: 'ramen',
      shortcodes: ['ramen', 'steaming_bowl'],
      tags: ['bowl', 'noodle', 'ramen', 'steaming'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35c.png',
    },
    {
      emoji: '🍝',
      name: 'spaghetti',
      shortcodes: ['spaghetti'],
      tags: ['pasta'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f35d.png',
    },
    {
      emoji: '🍠',
      name: 'sweet_potato',
      shortcodes: ['sweet_potato'],
      tags: ['potato', 'roasted', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f360.png',
    },
    {
      emoji: '🍢',
      name: 'oden',
      shortcodes: ['oden'],
      tags: ['kebab', 'seafood', 'skewer', 'stick'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f362.png',
    },
    {
      emoji: '🍣',
      name: 'sushi',
      shortcodes: ['sushi'],
      tags: ['sushi'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f363.png',
    },
    {
      emoji: '🍤',
      name: 'fried_shrimp',
      shortcodes: ['fried_shrimp'],
      tags: ['fried', 'prawn', 'shrimp', 'tempura'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f364.png',
    },
    {
      emoji: '🍥',
      name: 'fish_cake',
      shortcodes: ['fish_cake'],
      tags: ['cake', 'fish', 'pastry', 'swirl'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f365.png',
    },
    {
      emoji: '🥮',
      name: 'moon_cake',
      shortcodes: ['moon_cake'],
      tags: ['autumn', 'festival', 'yuèbǐng'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f96e.png',
    },
    {
      emoji: '🍡',
      name: 'dango',
      shortcodes: ['dango'],
      tags: ['dessert', 'japanese', 'skewer', 'stick', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f361.png',
    },
    {
      emoji: '🥟',
      name: 'dumpling',
      shortcodes: ['dumpling'],
      tags: ['empanada', 'gyōza', 'jiaozi', 'pierogi', 'potsticker'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95f.png',
    },
    {
      emoji: '🥠',
      name: 'fortune_cookie',
      shortcodes: ['fortune_cookie'],
      tags: ['prophecy'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f960.png',
    },
    {
      emoji: '🥡',
      name: 'takeout_box',
      shortcodes: ['takeout_box'],
      tags: ['oyster pail'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f961.png',
    },
    {
      emoji: '🦀',
      name: 'crab',
      shortcodes: ['crab'],
      tags: ['cancer', 'zodiac'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f980.png',
    },
    {
      emoji: '🦞',
      name: 'lobster',
      shortcodes: ['lobster'],
      tags: ['bisque', 'claws', 'seafood'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f99e.png',
    },
    {
      emoji: '🦐',
      name: 'shrimp',
      shortcodes: ['shrimp'],
      tags: ['food', 'shellfish', 'small'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f990.png',
    },
    {
      emoji: '🦑',
      name: 'squid',
      shortcodes: ['squid'],
      tags: ['food', 'molusc'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f991.png',
    },
    {
      emoji: '🦪',
      name: 'oyster',
      shortcodes: ['oyster'],
      tags: ['diving', 'pearl'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9aa.png',
    },
    {
      emoji: '🍦',
      name: 'icecream',
      shortcodes: ['icecream', 'soft_serve'],
      tags: ['cream', 'dessert', 'ice', 'icecream', 'soft', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f366.png',
    },
    {
      emoji: '🍧',
      name: 'shaved_ice',
      shortcodes: ['shaved_ice'],
      tags: ['dessert', 'ice', 'shaved', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f367.png',
    },
    {
      emoji: '🍨',
      name: 'ice_cream',
      shortcodes: ['ice_cream'],
      tags: ['cream', 'dessert', 'ice', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f368.png',
    },
    {
      emoji: '🍩',
      name: 'doughnut',
      shortcodes: ['doughnut'],
      tags: ['breakfast', 'dessert', 'donut', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f369.png',
    },
    {
      emoji: '🍪',
      name: 'cookie',
      shortcodes: ['cookie'],
      tags: ['dessert', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36a.png',
    },
    {
      emoji: '🎂',
      name: 'birthday',
      shortcodes: ['birthday', 'birthday_cake'],
      tags: ['birthday', 'cake', 'celebration', 'dessert', 'pastry', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f382.png',
    },
    {
      emoji: '🍰',
      name: 'cake',
      shortcodes: ['cake', 'shortcake'],
      tags: ['cake', 'dessert', 'pastry', 'slice', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f370.png',
    },
    {
      emoji: '🧁',
      name: 'cupcake',
      shortcodes: ['cupcake'],
      tags: ['bakery', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c1.png',
    },
    {
      emoji: '🥧',
      name: 'pie',
      shortcodes: ['pie'],
      tags: ['filling', 'pastry'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f967.png',
    },
    {
      emoji: '🍫',
      name: 'chocolate_bar',
      shortcodes: ['chocolate_bar'],
      tags: ['bar', 'chocolate', 'dessert', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36b.png',
    },
    {
      emoji: '🍬',
      name: 'candy',
      shortcodes: ['candy'],
      tags: ['dessert', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36c.png',
    },
    {
      emoji: '🍭',
      name: 'lollipop',
      shortcodes: ['lollipop'],
      tags: ['candy', 'dessert', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36d.png',
    },
    {
      emoji: '🍮',
      name: 'custard',
      shortcodes: ['custard'],
      tags: ['dessert', 'pudding', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36e.png',
    },
    {
      emoji: '🍯',
      name: 'honey_pot',
      shortcodes: ['honey_pot'],
      tags: ['honey', 'honeypot', 'pot', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f36f.png',
    },
    {
      emoji: '🍼',
      name: 'baby_bottle',
      shortcodes: ['baby_bottle'],
      tags: ['baby', 'bottle', 'drink', 'milk'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37c.png',
    },
    {
      emoji: '🥛',
      name: 'milk_glass',
      shortcodes: ['glass_of_milk', 'milk'],
      tags: ['drink', 'glass', 'milk'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f95b.png',
    },
    {
      emoji: '☕',
      name: 'coffee',
      shortcodes: ['coffee'],
      tags: ['beverage', 'coffee', 'drink', 'hot', 'steaming', 'tea'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2615.png',
    },
    {
      emoji: '🫖',
      name: 'teapot',
      shortcodes: ['teapot'],
      tags: ['drink', 'pot', 'tea'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fad6.png',
    },
    {
      emoji: '🍵',
      name: 'tea',
      shortcodes: ['tea'],
      tags: ['beverage', 'cup', 'drink', 'tea', 'teacup'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f375.png',
    },
    {
      emoji: '🍶',
      name: 'sake',
      shortcodes: ['sake'],
      tags: ['bar', 'beverage', 'bottle', 'cup', 'drink'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f376.png',
    },
    {
      emoji: '🍾',
      name: 'champagne',
      shortcodes: ['champagne'],
      tags: ['bar', 'bottle', 'cork', 'drink', 'popping'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37e.png',
    },
    {
      emoji: '🍷',
      name: 'wine_glass',
      shortcodes: ['wine_glass'],
      tags: ['bar', 'beverage', 'drink', 'glass', 'wine'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f377.png',
    },
    {
      emoji: '🍸',
      name: 'cocktail',
      shortcodes: ['cocktail'],
      tags: ['bar', 'cocktail', 'drink', 'glass'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f378.png',
    },
    {
      emoji: '🍹',
      name: 'tropical_drink',
      shortcodes: ['tropical_drink'],
      tags: ['bar', 'drink', 'tropical'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f379.png',
    },
    {
      emoji: '🍺',
      name: 'beer',
      shortcodes: ['beer'],
      tags: ['bar', 'beer', 'drink', 'mug'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37a.png',
    },
    {
      emoji: '🍻',
      name: 'beers',
      shortcodes: ['beers'],
      tags: ['bar', 'beer', 'clink', 'drink', 'mug'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37b.png',
    },
    {
      emoji: '🥂',
      name: 'clinking_glasses',
      shortcodes: ['clinking_glasses'],
      tags: ['celebrate', 'clink', 'drink', 'glass'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f942.png',
    },
    {
      emoji: '🥃',
      name: 'tumbler_glass',
      shortcodes: ['tumbler_glass', 'whisky'],
      tags: ['glass', 'liquor', 'shot', 'tumbler', 'whisky'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f943.png',
    },
    {
      emoji: '🥤',
      name: 'cup_with_straw',
      shortcodes: ['cup_with_straw'],
      tags: ['juice', 'soda'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f964.png',
    },
    {
      emoji: '🧋',
      name: 'bubble_tea',
      shortcodes: ['boba_drink', 'bubble_tea'],
      tags: ['bubble', 'milk', 'pearl', 'tea'],
      group: 'food & drink',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9cb.png',
    },
    {
      emoji: '🧃',
      name: 'beverage_box',
      shortcodes: ['beverage_box', 'juice_box'],
      tags: ['beverage', 'box', 'juice', 'straw', 'sweet'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c3.png',
    },
    {
      emoji: '🧉',
      name: 'mate',
      shortcodes: ['mate'],
      tags: ['drink'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9c9.png',
    },
    {
      emoji: '🧊',
      name: 'ice_cube',
      shortcodes: ['ice', 'ice_cube'],
      tags: ['cold', 'ice cube', 'iceberg'],
      group: 'food & drink',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ca.png',
    },
    {
      emoji: '🥢',
      name: 'chopsticks',
      shortcodes: ['chopsticks'],
      tags: ['hashi'],
      group: 'food & drink',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f962.png',
    },
    {
      emoji: '🍽',
      name: 'plate_with_cutlery',
      shortcodes: ['fork_knife_plate'],
      tags: ['cooking', 'fork', 'knife', 'plate'],
      group: 'food & drink',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f37d-fe0f.png',
    },
    {
      emoji: '🍴',
      name: 'fork_and_knife',
      shortcodes: ['fork_and_knife'],
      tags: ['cooking', 'cutlery', 'fork', 'knife'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f374.png',
    },
    {
      emoji: '🥄',
      name: 'spoon',
      shortcodes: ['spoon'],
      tags: ['tableware'],
      group: 'food & drink',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f944.png',
    },
    {
      emoji: '🔪',
      name: 'hocho',
      shortcodes: ['knife'],
      tags: ['cooking', 'hocho', 'knife', 'tool', 'weapon'],
      group: 'food & drink',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52a.png',
    },
    {
      emoji: '🏺',
      name: 'amphora',
      shortcodes: ['amphora'],
      tags: ['aquarius', 'cooking', 'drink', 'jug', 'zodiac'],
      group: 'food & drink',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3fa.png',
    },
    {
      emoji: '🌍',
      name: 'earth_africa',
      shortcodes: ['earth_africa', 'earth_europe'],
      tags: [
        'africa',
        'earth',
        'europe',
        'globe',
        'globe showing europe-africa',
        'world',
      ],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30d.png',
    },
    {
      emoji: '🌎',
      name: 'earth_americas',
      shortcodes: ['earth_americas'],
      tags: ['americas', 'earth', 'globe', 'globe showing americas', 'world'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30e.png',
    },
    {
      emoji: '🌏',
      name: 'earth_asia',
      shortcodes: ['earth_asia'],
      tags: [
        'asia',
        'australia',
        'earth',
        'globe',
        'globe showing asia-australia',
        'world',
      ],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30f.png',
    },
    {
      emoji: '🌐',
      name: 'globe_with_meridians',
      shortcodes: ['globe_with_meridians'],
      tags: ['earth', 'globe', 'meridians', 'world'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f310.png',
    },
    {
      emoji: '🗺',
      name: 'world_map',
      shortcodes: ['world_map'],
      tags: ['map', 'world'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5fa-fe0f.png',
    },
    {
      emoji: '🗾',
      name: 'japan',
      shortcodes: ['japan_map'],
      tags: ['japan', 'map', 'map of japan'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5fe.png',
    },
    {
      emoji: '🧭',
      name: 'compass',
      shortcodes: ['compass'],
      tags: ['magnetic', 'navigation', 'orienteering'],
      group: 'travel & places',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ed.png',
    },
    {
      emoji: '🏔',
      name: 'mountain_snow',
      shortcodes: ['mountain_snow'],
      tags: ['cold', 'mountain', 'snow'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d4-fe0f.png',
    },
    {
      emoji: '⛰',
      name: 'mountain',
      shortcodes: ['mountain'],
      tags: ['mountain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f0-fe0f.png',
    },
    {
      emoji: '🌋',
      name: 'volcano',
      shortcodes: ['volcano'],
      tags: ['eruption', 'mountain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30b.png',
    },
    {
      emoji: '🗻',
      name: 'mount_fuji',
      shortcodes: ['mount_fuji'],
      tags: ['fuji', 'mountain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5fb.png',
    },
    {
      emoji: '🏕',
      name: 'camping',
      shortcodes: ['camping'],
      tags: ['camping'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d5-fe0f.png',
    },
    {
      emoji: '🏖',
      name: 'beach_umbrella',
      shortcodes: ['beach', 'beach_with_umbrella'],
      tags: ['beach', 'umbrella'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d6-fe0f.png',
    },
    {
      emoji: '🏜',
      name: 'desert',
      shortcodes: ['desert'],
      tags: ['desert'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3dc-fe0f.png',
    },
    {
      emoji: '🏝',
      name: 'desert_island',
      shortcodes: ['desert_island', 'island'],
      tags: ['desert', 'island'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3dd-fe0f.png',
    },
    {
      emoji: '🏞',
      name: 'national_park',
      shortcodes: ['national_park'],
      tags: ['park'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3de-fe0f.png',
    },
    {
      emoji: '🏟',
      name: 'stadium',
      shortcodes: ['stadium'],
      tags: ['stadium'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3df-fe0f.png',
    },
    {
      emoji: '🏛',
      name: 'classical_building',
      shortcodes: ['classical_building'],
      tags: ['classical'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3db-fe0f.png',
    },
    {
      emoji: '🏗',
      name: 'building_construction',
      shortcodes: ['building_construction', 'construction_site'],
      tags: ['construction'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d7-fe0f.png',
    },
    {
      emoji: '🧱',
      name: 'bricks',
      shortcodes: ['bricks'],
      tags: ['bricks', 'clay', 'mortar', 'wall'],
      group: 'travel & places',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f1.png',
    },
    {
      emoji: '🪨',
      name: 'rock',
      shortcodes: ['rock'],
      tags: ['boulder', 'heavy', 'solid', 'stone'],
      group: 'travel & places',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa8.png',
    },
    {
      emoji: '🪵',
      name: 'wood',
      shortcodes: ['wood'],
      tags: ['log', 'lumber', 'timber'],
      group: 'travel & places',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fab5.png',
    },
    {
      emoji: '🛖',
      name: 'hut',
      shortcodes: ['hut'],
      tags: ['house', 'roundhouse', 'yurt'],
      group: 'travel & places',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d6.png',
    },
    {
      emoji: '🏘',
      name: 'houses',
      shortcodes: ['homes', 'houses'],
      tags: ['houses'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d8-fe0f.png',
    },
    {
      emoji: '🏚',
      name: 'derelict_house',
      shortcodes: ['derelict_house', 'house_abandoned'],
      tags: ['derelict', 'house'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3da-fe0f.png',
    },
    {
      emoji: '🏠',
      name: 'house',
      shortcodes: ['house'],
      tags: ['home'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e0.png',
    },
    {
      emoji: '🏡',
      name: 'house_with_garden',
      shortcodes: ['house_with_garden'],
      tags: ['garden', 'home', 'house'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e1.png',
    },
    {
      emoji: '🏢',
      name: 'office',
      shortcodes: ['office'],
      tags: ['building'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e2.png',
    },
    {
      emoji: '🏣',
      name: 'post_office',
      shortcodes: ['post_office'],
      tags: ['japanese', 'japanese post office', 'post'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e3.png',
    },
    {
      emoji: '🏤',
      name: 'european_post_office',
      shortcodes: ['european_post_office'],
      tags: ['european', 'post'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e4.png',
    },
    {
      emoji: '🏥',
      name: 'hospital',
      shortcodes: ['hospital'],
      tags: ['doctor', 'medicine'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e5.png',
    },
    {
      emoji: '🏦',
      name: 'bank',
      shortcodes: ['bank'],
      tags: ['building'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e6.png',
    },
    {
      emoji: '🏨',
      name: 'hotel',
      shortcodes: ['hotel'],
      tags: ['building'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e8.png',
    },
    {
      emoji: '🏩',
      name: 'love_hotel',
      shortcodes: ['love_hotel'],
      tags: ['hotel', 'love'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e9.png',
    },
    {
      emoji: '🏪',
      name: 'convenience_store',
      shortcodes: ['convenience_store'],
      tags: ['convenience', 'store'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ea.png',
    },
    {
      emoji: '🏫',
      name: 'school',
      shortcodes: ['school'],
      tags: ['building'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3eb.png',
    },
    {
      emoji: '🏬',
      name: 'department_store',
      shortcodes: ['department_store'],
      tags: ['department', 'store'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ec.png',
    },
    {
      emoji: '🏭',
      name: 'factory',
      shortcodes: ['factory'],
      tags: ['building'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ed.png',
    },
    {
      emoji: '🏯',
      name: 'japanese_castle',
      shortcodes: ['japanese_castle'],
      tags: ['castle', 'japanese'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ef.png',
    },
    {
      emoji: '🏰',
      name: 'european_castle',
      shortcodes: ['castle', 'european_castle'],
      tags: ['european'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f0.png',
    },
    {
      emoji: '💒',
      name: 'wedding',
      shortcodes: ['wedding'],
      tags: ['chapel', 'romance'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f492.png',
    },
    {
      emoji: '🗼',
      name: 'tokyo_tower',
      shortcodes: ['tokyo_tower'],
      tags: ['tokyo', 'tower'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5fc.png',
    },
    {
      emoji: '🗽',
      name: 'statue_of_liberty',
      shortcodes: ['statue_of_liberty'],
      tags: ['liberty', 'statue', 'statue of liberty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5fd.png',
    },
    {
      emoji: '⛪',
      name: 'church',
      shortcodes: ['church'],
      tags: ['christian', 'cross', 'religion'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26ea.png',
    },
    {
      emoji: '🕌',
      name: 'mosque',
      shortcodes: ['mosque'],
      tags: ['islam', 'muslim', 'religion'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f54c.png',
    },
    {
      emoji: '🛕',
      name: 'hindu_temple',
      shortcodes: ['hindu_temple'],
      tags: ['hindu', 'temple'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d5.png',
    },
    {
      emoji: '🕍',
      name: 'synagogue',
      shortcodes: ['synagogue'],
      tags: ['jew', 'jewish', 'religion', 'temple'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f54d.png',
    },
    {
      emoji: '⛩',
      name: 'shinto_shrine',
      shortcodes: ['shinto_shrine'],
      tags: ['religion', 'shinto', 'shrine'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26e9-fe0f.png',
    },
    {
      emoji: '🕋',
      name: 'kaaba',
      shortcodes: ['kaaba'],
      tags: ['islam', 'muslim', 'religion'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f54b.png',
    },
    {
      emoji: '⛲',
      name: 'fountain',
      shortcodes: ['fountain'],
      tags: ['fountain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f2.png',
    },
    {
      emoji: '⛺',
      name: 'tent',
      shortcodes: ['tent'],
      tags: ['camping'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26fa.png',
    },
    {
      emoji: '🌁',
      name: 'foggy',
      shortcodes: ['foggy'],
      tags: ['fog'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f301.png',
    },
    {
      emoji: '🌃',
      name: 'night_with_stars',
      shortcodes: ['night_with_stars'],
      tags: ['night', 'star'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f303.png',
    },
    {
      emoji: '🏙',
      name: 'cityscape',
      shortcodes: ['cityscape'],
      tags: ['city'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d9-fe0f.png',
    },
    {
      emoji: '🌄',
      name: 'sunrise_over_mountains',
      shortcodes: ['sunrise_over_mountains'],
      tags: ['morning', 'mountain', 'sun', 'sunrise'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f304.png',
    },
    {
      emoji: '🌅',
      name: 'sunrise',
      shortcodes: ['sunrise'],
      tags: ['morning', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f305.png',
    },
    {
      emoji: '🌆',
      name: 'city_sunset',
      shortcodes: ['city_dusk'],
      tags: ['city', 'dusk', 'evening', 'landscape', 'sunset'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f306.png',
    },
    {
      emoji: '🌇',
      name: 'city_sunrise',
      shortcodes: ['city_sunrise', 'city_sunset'],
      tags: ['dusk', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f307.png',
    },
    {
      emoji: '🌉',
      name: 'bridge_at_night',
      shortcodes: ['bridge_at_night'],
      tags: ['bridge', 'night'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f309.png',
    },
    {
      emoji: '♨',
      name: 'hotsprings',
      shortcodes: ['hotsprings'],
      tags: ['hot', 'hotsprings', 'springs', 'steaming'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2668-fe0f.png',
    },
    {
      emoji: '🎠',
      name: 'carousel_horse',
      shortcodes: ['carousel_horse'],
      tags: ['carousel', 'horse'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a0.png',
    },
    {
      emoji: '🎡',
      name: 'ferris_wheel',
      shortcodes: ['ferris_wheel'],
      tags: ['amusement park', 'ferris', 'wheel'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a1.png',
    },
    {
      emoji: '🎢',
      name: 'roller_coaster',
      shortcodes: ['roller_coaster'],
      tags: ['amusement park', 'coaster', 'roller'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a2.png',
    },
    {
      emoji: '💈',
      name: 'barber',
      shortcodes: ['barber', 'barber_pole'],
      tags: ['barber', 'haircut', 'pole'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f488.png',
    },
    {
      emoji: '🎪',
      name: 'circus_tent',
      shortcodes: ['circus_tent'],
      tags: ['circus', 'tent'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3aa.png',
    },
    {
      emoji: '🚂',
      name: 'steam_locomotive',
      shortcodes: ['steam_locomotive'],
      tags: ['engine', 'railway', 'steam', 'train'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f682.png',
    },
    {
      emoji: '🚃',
      name: 'railway_car',
      shortcodes: ['railway_car'],
      tags: ['car', 'electric', 'railway', 'train', 'tram', 'trolleybus'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f683.png',
    },
    {
      emoji: '🚄',
      name: 'bullettrain_side',
      shortcodes: ['bullettrain_side'],
      tags: ['railway', 'shinkansen', 'speed', 'train'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f684.png',
    },
    {
      emoji: '🚅',
      name: 'bullettrain_front',
      shortcodes: ['bullettrain_front'],
      tags: ['bullet', 'railway', 'shinkansen', 'speed', 'train'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f685.png',
    },
    {
      emoji: '🚆',
      name: 'train2',
      shortcodes: ['train'],
      tags: ['railway'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f686.png',
    },
    {
      emoji: '🚇',
      name: 'metro',
      shortcodes: ['metro'],
      tags: ['subway'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f687.png',
    },
    {
      emoji: '🚈',
      name: 'light_rail',
      shortcodes: ['light_rail'],
      tags: ['railway'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f688.png',
    },
    {
      emoji: '🚉',
      name: 'station',
      shortcodes: ['station'],
      tags: ['railway', 'train'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f689.png',
    },
    {
      emoji: '🚊',
      name: 'tram',
      shortcodes: ['tram'],
      tags: ['trolleybus'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68a.png',
    },
    {
      emoji: '🚝',
      name: 'monorail',
      shortcodes: ['monorail'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69d.png',
    },
    {
      emoji: '🚞',
      name: 'mountain_railway',
      shortcodes: ['mountain_railway'],
      tags: ['car', 'mountain', 'railway'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69e.png',
    },
    {
      emoji: '🚋',
      name: 'train',
      shortcodes: ['tram_car'],
      tags: ['car', 'tram', 'trolleybus'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68b.png',
    },
    {
      emoji: '🚌',
      name: 'bus',
      shortcodes: ['bus'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68c.png',
    },
    {
      emoji: '🚍',
      name: 'oncoming_bus',
      shortcodes: ['oncoming_bus'],
      tags: ['bus', 'oncoming'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68d.png',
    },
    {
      emoji: '🚎',
      name: 'trolleybus',
      shortcodes: ['trolleybus'],
      tags: ['bus', 'tram', 'trolley'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68e.png',
    },
    {
      emoji: '🚐',
      name: 'minibus',
      shortcodes: ['minibus'],
      tags: ['bus'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f690.png',
    },
    {
      emoji: '🚑',
      name: 'ambulance',
      shortcodes: ['ambulance'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f691.png',
    },
    {
      emoji: '🚒',
      name: 'fire_engine',
      shortcodes: ['fire_engine'],
      tags: ['engine', 'fire', 'truck'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f692.png',
    },
    {
      emoji: '🚓',
      name: 'police_car',
      shortcodes: ['police_car'],
      tags: ['car', 'patrol', 'police'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f693.png',
    },
    {
      emoji: '🚔',
      name: 'oncoming_police_car',
      shortcodes: ['oncoming_police_car'],
      tags: ['car', 'oncoming', 'police'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f694.png',
    },
    {
      emoji: '🚕',
      name: 'taxi',
      shortcodes: ['taxi'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f695.png',
    },
    {
      emoji: '🚖',
      name: 'oncoming_taxi',
      shortcodes: ['oncoming_taxi'],
      tags: ['oncoming', 'taxi'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f696.png',
    },
    {
      emoji: '🚗',
      name: 'car',
      shortcodes: ['car', 'red_car'],
      tags: ['car'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f697.png',
    },
    {
      emoji: '🚘',
      name: 'oncoming_automobile',
      shortcodes: ['oncoming_automobile'],
      tags: ['automobile', 'car', 'oncoming'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f698.png',
    },
    {
      emoji: '🚙',
      name: 'blue_car',
      shortcodes: ['blue_car', 'suv'],
      tags: ['recreational', 'sport utility'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f699.png',
    },
    {
      emoji: '🛻',
      name: 'pickup_truck',
      shortcodes: ['pickup_truck'],
      tags: ['pick-up', 'pickup', 'truck'],
      group: 'travel & places',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6fb.png',
    },
    {
      emoji: '🚚',
      name: 'truck',
      shortcodes: ['delivery_truck', 'truck'],
      tags: ['delivery', 'truck'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69a.png',
    },
    {
      emoji: '🚛',
      name: 'articulated_lorry',
      shortcodes: ['articulated_lorry'],
      tags: ['lorry', 'semi', 'truck'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69b.png',
    },
    {
      emoji: '🚜',
      name: 'tractor',
      shortcodes: ['tractor'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69c.png',
    },
    {
      emoji: '🏎',
      name: 'racing_car',
      shortcodes: ['racing_car'],
      tags: ['car', 'racing'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ce-fe0f.png',
    },
    {
      emoji: '🏍',
      name: 'motorcycle',
      shortcodes: ['motorcycle'],
      tags: ['racing'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cd-fe0f.png',
    },
    {
      emoji: '🛵',
      name: 'motor_scooter',
      shortcodes: ['motor_scooter'],
      tags: ['motor', 'scooter'],
      group: 'travel & places',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f5.png',
    },
    {
      emoji: '🦽',
      name: 'manual_wheelchair',
      shortcodes: ['manual_wheelchair'],
      tags: ['accessibility'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9bd.png',
    },
    {
      emoji: '🦼',
      name: 'motorized_wheelchair',
      shortcodes: ['motorized_wheelchair'],
      tags: ['accessibility'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9bc.png',
    },
    {
      emoji: '🛺',
      name: 'auto_rickshaw',
      shortcodes: ['auto_rickshaw'],
      tags: ['tuk tuk'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6fa.png',
    },
    {
      emoji: '🚲',
      name: 'bike',
      shortcodes: ['bicycle', 'bike'],
      tags: ['bike'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b2.png',
    },
    {
      emoji: '🛴',
      name: 'kick_scooter',
      shortcodes: ['scooter'],
      tags: ['kick', 'scooter'],
      group: 'travel & places',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f4.png',
    },
    {
      emoji: '🛹',
      name: 'skateboard',
      shortcodes: ['skateboard'],
      tags: ['board'],
      group: 'travel & places',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f9.png',
    },
    {
      emoji: '🛼',
      name: 'roller_skate',
      shortcodes: ['roller_skate'],
      tags: ['roller', 'skate'],
      group: 'travel & places',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6fc.png',
    },
    {
      emoji: '🚏',
      name: 'busstop',
      shortcodes: ['busstop'],
      tags: ['bus', 'stop'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f68f.png',
    },
    {
      emoji: '🛣',
      name: 'motorway',
      shortcodes: ['motorway'],
      tags: ['highway', 'road'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e3-fe0f.png',
    },
    {
      emoji: '🛤',
      name: 'railway_track',
      shortcodes: ['railway_track'],
      tags: ['railway', 'train'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e4-fe0f.png',
    },
    {
      emoji: '🛢',
      name: 'oil_drum',
      shortcodes: ['oil_drum'],
      tags: ['drum', 'oil'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e2-fe0f.png',
    },
    {
      emoji: '⛽',
      name: 'fuelpump',
      shortcodes: ['fuelpump'],
      tags: ['diesel', 'fuel', 'fuelpump', 'gas', 'pump', 'station'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26fd.png',
    },
    {
      emoji: '🚨',
      name: 'rotating_light',
      shortcodes: ['rotating_light'],
      tags: ['beacon', 'car', 'light', 'police', 'revolving'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a8.png',
    },
    {
      emoji: '🚥',
      name: 'traffic_light',
      shortcodes: ['traffic_light'],
      tags: ['light', 'signal', 'traffic'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a5.png',
    },
    {
      emoji: '🚦',
      name: 'vertical_traffic_light',
      shortcodes: ['vertical_traffic_light'],
      tags: ['light', 'signal', 'traffic'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a6.png',
    },
    {
      emoji: '🛑',
      name: 'stop_sign',
      shortcodes: ['octagonal_sign', 'stop_sign'],
      tags: ['octagonal', 'sign', 'stop'],
      group: 'travel & places',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d1.png',
    },
    {
      emoji: '🚧',
      name: 'construction',
      shortcodes: ['construction'],
      tags: ['barrier'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a7.png',
    },
    {
      emoji: '⚓',
      name: 'anchor',
      shortcodes: ['anchor'],
      tags: ['ship', 'tool'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2693.png',
    },
    {
      emoji: '⛵',
      name: 'boat',
      shortcodes: ['sailboat'],
      tags: ['boat', 'resort', 'sea', 'yacht'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f5.png',
    },
    {
      emoji: '🛶',
      name: 'canoe',
      shortcodes: ['canoe'],
      tags: ['boat'],
      group: 'travel & places',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f6.png',
    },
    {
      emoji: '🚤',
      name: 'speedboat',
      shortcodes: ['speedboat'],
      tags: ['boat'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a4.png',
    },
    {
      emoji: '🛳',
      name: 'passenger_ship',
      shortcodes: ['cruise_ship', 'passenger_ship'],
      tags: ['passenger', 'ship'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f3-fe0f.png',
    },
    {
      emoji: '⛴',
      name: 'ferry',
      shortcodes: ['ferry'],
      tags: ['boat', 'passenger'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f4-fe0f.png',
    },
    {
      emoji: '🛥',
      name: 'motor_boat',
      shortcodes: ['motorboat'],
      tags: ['boat', 'motorboat'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e5-fe0f.png',
    },
    {
      emoji: '🚢',
      name: 'ship',
      shortcodes: ['ship'],
      tags: ['boat', 'passenger'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a2.png',
    },
    {
      emoji: '✈',
      name: 'airplane',
      shortcodes: ['airplane'],
      tags: ['aeroplane'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2708-fe0f.png',
    },
    {
      emoji: '🛩',
      name: 'small_airplane',
      shortcodes: ['small_airplane'],
      tags: ['aeroplane', 'airplane'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e9-fe0f.png',
    },
    {
      emoji: '🛫',
      name: 'flight_departure',
      shortcodes: ['airplane_departure'],
      tags: ['aeroplane', 'airplane', 'check-in', 'departure', 'departures'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6eb.png',
    },
    {
      emoji: '🛬',
      name: 'flight_arrival',
      shortcodes: ['airplane_arriving'],
      tags: ['aeroplane', 'airplane', 'arrivals', 'arriving', 'landing'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ec.png',
    },
    {
      emoji: '🪂',
      name: 'parachute',
      shortcodes: ['parachute'],
      tags: ['hang-glide', 'parasail', 'skydive'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa82.png',
    },
    {
      emoji: '💺',
      name: 'seat',
      shortcodes: ['seat'],
      tags: ['chair'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ba.png',
    },
    {
      emoji: '🚁',
      name: 'helicopter',
      shortcodes: ['helicopter'],
      tags: ['vehicle'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f681.png',
    },
    {
      emoji: '🚟',
      name: 'suspension_railway',
      shortcodes: ['suspension_railway'],
      tags: ['railway', 'suspension'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f69f.png',
    },
    {
      emoji: '🚠',
      name: 'mountain_cableway',
      shortcodes: ['mountain_cableway'],
      tags: ['cable', 'gondola', 'mountain'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a0.png',
    },
    {
      emoji: '🚡',
      name: 'aerial_tramway',
      shortcodes: ['aerial_tramway'],
      tags: ['aerial', 'cable', 'car', 'gondola', 'tramway'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a1.png',
    },
    {
      emoji: '🛰',
      name: 'artificial_satellite',
      shortcodes: ['satellite'],
      tags: ['space'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f0-fe0f.png',
    },
    {
      emoji: '🚀',
      name: 'rocket',
      shortcodes: ['rocket'],
      tags: ['space'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f680.png',
    },
    {
      emoji: '🛸',
      name: 'flying_saucer',
      shortcodes: ['flying_saucer'],
      tags: ['ufo'],
      group: 'travel & places',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f8.png',
    },
    {
      emoji: '🛎',
      name: 'bellhop_bell',
      shortcodes: ['bellhop'],
      tags: ['bell', 'bellhop', 'hotel'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ce-fe0f.png',
    },
    {
      emoji: '🧳',
      name: 'luggage',
      shortcodes: ['luggage'],
      tags: ['packing', 'travel'],
      group: 'travel & places',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f3.png',
    },
    {
      emoji: '⌛',
      name: 'hourglass',
      shortcodes: ['hourglass'],
      tags: ['sand', 'timer'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/231b.png',
    },
    {
      emoji: '⏳',
      name: 'hourglass_flowing_sand',
      shortcodes: ['hourglass_flowing_sand'],
      tags: ['hourglass', 'sand', 'timer'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f3.png',
    },
    {
      emoji: '⌚',
      name: 'watch',
      shortcodes: ['watch'],
      tags: ['clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/231a.png',
    },
    {
      emoji: '⏰',
      name: 'alarm_clock',
      shortcodes: ['alarm_clock'],
      tags: ['alarm', 'clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f0.png',
    },
    {
      emoji: '⏱',
      name: 'stopwatch',
      shortcodes: ['stopwatch'],
      tags: ['clock'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f1-fe0f.png',
    },
    {
      emoji: '⏲',
      name: 'timer_clock',
      shortcodes: ['timer_clock'],
      tags: ['clock', 'timer'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f2-fe0f.png',
    },
    {
      emoji: '🕰',
      name: 'mantelpiece_clock',
      shortcodes: ['clock'],
      tags: ['clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f570-fe0f.png',
    },
    {
      emoji: '🕛',
      name: 'clock12',
      shortcodes: ['clock12'],
      tags: ['00', '12', '12:00', 'clock', 'o’clock', 'twelve'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55b.png',
    },
    {
      emoji: '🕧',
      name: 'clock1230',
      shortcodes: ['clock1230'],
      tags: ['12', '12:30', 'clock', 'thirty', 'twelve'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f567.png',
    },
    {
      emoji: '🕐',
      name: 'clock1',
      shortcodes: ['clock1'],
      tags: ['00', '1', '1:00', 'clock', 'one', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f550.png',
    },
    {
      emoji: '🕜',
      name: 'clock130',
      shortcodes: ['clock130'],
      tags: ['1', '1:30', 'clock', 'one', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55c.png',
    },
    {
      emoji: '🕑',
      name: 'clock2',
      shortcodes: ['clock2'],
      tags: ['00', '2', '2:00', 'clock', 'o’clock', 'two'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f551.png',
    },
    {
      emoji: '🕝',
      name: 'clock230',
      shortcodes: ['clock230'],
      tags: ['2', '2:30', 'clock', 'thirty', 'two'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55d.png',
    },
    {
      emoji: '🕒',
      name: 'clock3',
      shortcodes: ['clock3'],
      tags: ['00', '3', '3:00', 'clock', 'o’clock', 'three'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f552.png',
    },
    {
      emoji: '🕞',
      name: 'clock330',
      shortcodes: ['clock330'],
      tags: ['3', '3:30', 'clock', 'thirty', 'three'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55e.png',
    },
    {
      emoji: '🕓',
      name: 'clock4',
      shortcodes: ['clock4'],
      tags: ['00', '4', '4:00', 'clock', 'four', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f553.png',
    },
    {
      emoji: '🕟',
      name: 'clock430',
      shortcodes: ['clock430'],
      tags: ['4', '4:30', 'clock', 'four', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55f.png',
    },
    {
      emoji: '🕔',
      name: 'clock5',
      shortcodes: ['clock5'],
      tags: ['00', '5', '5:00', 'clock', 'five', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f554.png',
    },
    {
      emoji: '🕠',
      name: 'clock530',
      shortcodes: ['clock530'],
      tags: ['5', '5:30', 'clock', 'five', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f560.png',
    },
    {
      emoji: '🕕',
      name: 'clock6',
      shortcodes: ['clock6'],
      tags: ['00', '6', '6:00', 'clock', 'o’clock', 'six'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f555.png',
    },
    {
      emoji: '🕡',
      name: 'clock630',
      shortcodes: ['clock630'],
      tags: ['6', '6:30', 'clock', 'six', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f561.png',
    },
    {
      emoji: '🕖',
      name: 'clock7',
      shortcodes: ['clock7'],
      tags: ['00', '7', '7:00', 'clock', 'o’clock', 'seven'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f556.png',
    },
    {
      emoji: '🕢',
      name: 'clock730',
      shortcodes: ['clock730'],
      tags: ['7', '7:30', 'clock', 'seven', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f562.png',
    },
    {
      emoji: '🕗',
      name: 'clock8',
      shortcodes: ['clock8'],
      tags: ['00', '8', '8:00', 'clock', 'eight', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f557.png',
    },
    {
      emoji: '🕣',
      name: 'clock830',
      shortcodes: ['clock830'],
      tags: ['8', '8:30', 'clock', 'eight', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f563.png',
    },
    {
      emoji: '🕘',
      name: 'clock9',
      shortcodes: ['clock9'],
      tags: ['00', '9', '9:00', 'clock', 'nine', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f558.png',
    },
    {
      emoji: '🕤',
      name: 'clock930',
      shortcodes: ['clock930'],
      tags: ['9', '9:30', 'clock', 'nine', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f564.png',
    },
    {
      emoji: '🕙',
      name: 'clock10',
      shortcodes: ['clock10'],
      tags: ['00', '10', '10:00', 'clock', 'o’clock', 'ten'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f559.png',
    },
    {
      emoji: '🕥',
      name: 'clock1030',
      shortcodes: ['clock1030'],
      tags: ['10', '10:30', 'clock', 'ten', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f565.png',
    },
    {
      emoji: '🕚',
      name: 'clock11',
      shortcodes: ['clock11'],
      tags: ['00', '11', '11:00', 'clock', 'eleven', 'o’clock'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f55a.png',
    },
    {
      emoji: '🕦',
      name: 'clock1130',
      shortcodes: ['clock1130'],
      tags: ['11', '11:30', 'clock', 'eleven', 'thirty'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f566.png',
    },
    {
      emoji: '🌑',
      name: 'new_moon',
      shortcodes: ['new_moon'],
      tags: ['dark', 'moon'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f311.png',
    },
    {
      emoji: '🌒',
      name: 'waxing_crescent_moon',
      shortcodes: ['waxing_crescent_moon'],
      tags: ['crescent', 'moon', 'waxing'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f312.png',
    },
    {
      emoji: '🌓',
      name: 'first_quarter_moon',
      shortcodes: ['first_quarter_moon'],
      tags: ['moon', 'quarter'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f313.png',
    },
    {
      emoji: '🌔',
      name: 'moon',
      shortcodes: ['waxing_gibbous_moon'],
      tags: ['gibbous', 'moon', 'waxing'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f314.png',
    },
    {
      emoji: '🌕',
      name: 'full_moon',
      shortcodes: ['full_moon'],
      tags: ['full', 'moon'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f315.png',
    },
    {
      emoji: '🌖',
      name: 'waning_gibbous_moon',
      shortcodes: ['waning_gibbous_moon'],
      tags: ['gibbous', 'moon', 'waning'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f316.png',
    },
    {
      emoji: '🌗',
      name: 'last_quarter_moon',
      shortcodes: ['last_quarter_moon'],
      tags: ['moon', 'quarter'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f317.png',
    },
    {
      emoji: '🌘',
      name: 'waning_crescent_moon',
      shortcodes: ['waning_crescent_moon'],
      tags: ['crescent', 'moon', 'waning'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f318.png',
    },
    {
      emoji: '🌙',
      name: 'crescent_moon',
      shortcodes: ['crescent_moon'],
      tags: ['crescent', 'moon'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f319.png',
    },
    {
      emoji: '🌚',
      name: 'new_moon_with_face',
      shortcodes: ['new_moon_with_face'],
      tags: ['face', 'moon'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31a.png',
    },
    {
      emoji: '🌛',
      name: 'first_quarter_moon_with_face',
      shortcodes: ['first_quarter_moon_with_face'],
      tags: ['face', 'moon', 'quarter'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31b.png',
    },
    {
      emoji: '🌜',
      name: 'last_quarter_moon_with_face',
      shortcodes: ['last_quarter_moon_with_face'],
      tags: ['face', 'moon', 'quarter'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31c.png',
    },
    {
      emoji: '🌡',
      name: 'thermometer',
      shortcodes: ['thermometer'],
      tags: ['weather'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f321-fe0f.png',
    },
    {
      emoji: '☀',
      name: 'sunny',
      shortcodes: ['sun'],
      tags: ['bright', 'rays', 'sunny'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2600-fe0f.png',
    },
    {
      emoji: '🌝',
      name: 'full_moon_with_face',
      shortcodes: ['full_moon_with_face'],
      tags: ['bright', 'face', 'full', 'moon'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31d.png',
    },
    {
      emoji: '🌞',
      name: 'sun_with_face',
      shortcodes: ['sun_with_face'],
      tags: ['bright', 'face', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31e.png',
    },
    {
      emoji: '🪐',
      name: 'ringed_planet',
      shortcodes: ['ringed_planet', 'saturn'],
      tags: ['saturn', 'saturnine'],
      group: 'travel & places',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa90.png',
    },
    {
      emoji: '⭐',
      name: 'star',
      shortcodes: ['star'],
      tags: ['star'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b50.png',
    },
    {
      emoji: '🌟',
      name: 'star2',
      shortcodes: ['glowing_star', 'star2'],
      tags: ['glittery', 'glow', 'shining', 'sparkle', 'star'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f31f.png',
    },
    {
      emoji: '🌠',
      name: 'stars',
      shortcodes: ['shooting_star', 'stars'],
      tags: ['falling', 'shooting', 'star'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f320.png',
    },
    {
      emoji: '🌌',
      name: 'milky_way',
      shortcodes: ['milky_way'],
      tags: ['space'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30c.png',
    },
    {
      emoji: '☁',
      name: 'cloud',
      shortcodes: ['cloud'],
      tags: ['weather'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2601-fe0f.png',
    },
    {
      emoji: '⛅',
      name: 'partly_sunny',
      shortcodes: ['partly_sunny', 'sun_behind_cloud'],
      tags: ['cloud', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26c5.png',
    },
    {
      emoji: '⛈',
      name: 'cloud_with_lightning_and_rain',
      shortcodes: ['stormy', 'thunder_cloud_and_rain'],
      tags: ['cloud', 'rain', 'thunder'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26c8-fe0f.png',
    },
    {
      emoji: '🌤',
      name: 'sun_behind_small_cloud',
      shortcodes: ['sun_behind_small_cloud', 'sunny'],
      tags: ['cloud', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f324-fe0f.png',
    },
    {
      emoji: '🌥',
      name: 'sun_behind_large_cloud',
      shortcodes: ['cloudy', 'sun_behind_large_cloud'],
      tags: ['cloud', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f325-fe0f.png',
    },
    {
      emoji: '🌦',
      name: 'sun_behind_rain_cloud',
      shortcodes: ['sun_and_rain', 'sun_behind_rain_cloud'],
      tags: ['cloud', 'rain', 'sun'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f326-fe0f.png',
    },
    {
      emoji: '🌧',
      name: 'cloud_with_rain',
      shortcodes: ['cloud_with_rain', 'rainy'],
      tags: ['cloud', 'rain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f327-fe0f.png',
    },
    {
      emoji: '🌨',
      name: 'cloud_with_snow',
      shortcodes: ['cloud_with_snow', 'snowy'],
      tags: ['cloud', 'cold', 'snow'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f328-fe0f.png',
    },
    {
      emoji: '🌩',
      name: 'cloud_with_lightning',
      shortcodes: ['cloud_with_lightning', 'lightning'],
      tags: ['cloud', 'lightning'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f329-fe0f.png',
    },
    {
      emoji: '🌪',
      name: 'tornado',
      shortcodes: ['tornado'],
      tags: ['cloud', 'whirlwind'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32a-fe0f.png',
    },
    {
      emoji: '🌫',
      name: 'fog',
      shortcodes: ['fog'],
      tags: ['cloud'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32b-fe0f.png',
    },
    {
      emoji: '🌬',
      name: 'wind_face',
      shortcodes: ['wind_blowing_face'],
      tags: ['blow', 'cloud', 'face', 'wind'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f32c-fe0f.png',
    },
    {
      emoji: '🌀',
      name: 'cyclone',
      shortcodes: ['cyclone'],
      tags: ['dizzy', 'hurricane', 'twister', 'typhoon'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f300.png',
    },
    {
      emoji: '🌈',
      name: 'rainbow',
      shortcodes: ['rainbow'],
      tags: ['rain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f308.png',
    },
    {
      emoji: '🌂',
      name: 'closed_umbrella',
      shortcodes: ['closed_umbrella'],
      tags: ['clothing', 'rain', 'umbrella'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f302.png',
    },
    {
      emoji: '☂',
      name: 'open_umbrella',
      shortcodes: ['umbrella'],
      tags: ['clothing', 'rain'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2602-fe0f.png',
    },
    {
      emoji: '☔',
      name: 'umbrella',
      shortcodes: ['umbrella_with_rain'],
      tags: ['clothing', 'drop', 'rain', 'umbrella'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2614.png',
    },
    {
      emoji: '⛱',
      name: 'parasol_on_ground',
      shortcodes: ['beach_umbrella', 'umbrella_on_ground'],
      tags: ['rain', 'sun', 'umbrella'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f1-fe0f.png',
    },
    {
      emoji: '⚡',
      name: 'zap',
      shortcodes: ['high_voltage', 'zap'],
      tags: ['danger', 'electric', 'lightning', 'voltage', 'zap'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26a1.png',
    },
    {
      emoji: '❄',
      name: 'snowflake',
      shortcodes: ['snowflake'],
      tags: ['cold', 'snow'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2744-fe0f.png',
    },
    {
      emoji: '☃',
      name: 'snowman_with_snow',
      shortcodes: ['snowman2'],
      tags: ['cold', 'snow'],
      group: 'travel & places',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2603-fe0f.png',
    },
    {
      emoji: '⛄',
      name: 'snowman',
      shortcodes: ['snowman'],
      tags: ['cold', 'snow', 'snowman'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26c4.png',
    },
    {
      emoji: '☄',
      name: 'comet',
      shortcodes: ['comet'],
      tags: ['space'],
      group: 'travel & places',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2604-fe0f.png',
    },
    {
      emoji: '🔥',
      name: 'fire',
      shortcodes: ['fire'],
      tags: ['flame', 'tool'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f525.png',
    },
    {
      emoji: '💧',
      name: 'droplet',
      shortcodes: ['droplet'],
      tags: ['cold', 'comic', 'drop', 'sweat'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a7.png',
    },
    {
      emoji: '🌊',
      name: 'ocean',
      shortcodes: ['ocean', 'water_wave'],
      tags: ['ocean', 'water', 'wave'],
      group: 'travel & places',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f30a.png',
    },
    {
      emoji: '🎃',
      name: 'jack_o_lantern',
      shortcodes: ['jack_o_lantern'],
      tags: ['celebration', 'halloween', 'jack', 'lantern'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f383.png',
    },
    {
      emoji: '🎄',
      name: 'christmas_tree',
      shortcodes: ['christmas_tree'],
      tags: ['celebration', 'christmas', 'tree'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f384.png',
    },
    {
      emoji: '🎆',
      name: 'fireworks',
      shortcodes: ['fireworks'],
      tags: ['celebration'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f386.png',
    },
    {
      emoji: '🎇',
      name: 'sparkler',
      shortcodes: ['sparkler'],
      tags: ['celebration', 'fireworks', 'sparkle'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f387.png',
    },
    {
      emoji: '🧨',
      name: 'firecracker',
      shortcodes: ['firecracker'],
      tags: ['dynamite', 'explosive', 'fireworks'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e8.png',
    },
    {
      emoji: '✨',
      name: 'sparkles',
      shortcodes: ['sparkles'],
      tags: ['*', 'sparkle', 'star'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2728.png',
    },
    {
      emoji: '🎈',
      name: 'balloon',
      shortcodes: ['balloon'],
      tags: ['celebration'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f388.png',
    },
    {
      emoji: '🎉',
      name: 'tada',
      shortcodes: ['party', 'party_popper', 'tada'],
      tags: ['celebration', 'party', 'popper', 'tada'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f389.png',
    },
    {
      emoji: '🎊',
      name: 'confetti_ball',
      shortcodes: ['confetti_ball'],
      tags: ['ball', 'celebration', 'confetti'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38a.png',
    },
    {
      emoji: '🎋',
      name: 'tanabata_tree',
      shortcodes: ['tanabata_tree'],
      tags: ['banner', 'celebration', 'japanese', 'tree'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38b.png',
    },
    {
      emoji: '🎍',
      name: 'bamboo',
      shortcodes: ['bamboo'],
      tags: ['bamboo', 'celebration', 'japanese', 'pine'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38d.png',
    },
    {
      emoji: '🎎',
      name: 'dolls',
      shortcodes: ['dolls'],
      tags: ['celebration', 'doll', 'festival', 'japanese', 'japanese dolls'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38e.png',
    },
    {
      emoji: '🎏',
      name: 'flags',
      shortcodes: ['carp_streamer', 'flags'],
      tags: ['carp', 'celebration', 'streamer'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38f.png',
    },
    {
      emoji: '🎐',
      name: 'wind_chime',
      shortcodes: ['wind_chime'],
      tags: ['bell', 'celebration', 'chime', 'wind'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f390.png',
    },
    {
      emoji: '🎑',
      name: 'rice_scene',
      shortcodes: ['moon_ceremony', 'rice_scene'],
      tags: ['celebration', 'ceremony', 'moon'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f391.png',
    },
    {
      emoji: '🧧',
      name: 'red_envelope',
      shortcodes: ['red_envelope'],
      tags: ['gift', 'good luck', 'hóngbāo', 'lai see', 'money'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e7.png',
    },
    {
      emoji: '🎀',
      name: 'ribbon',
      shortcodes: ['ribbon'],
      tags: ['celebration'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f380.png',
    },
    {
      emoji: '🎁',
      name: 'gift',
      shortcodes: ['gift'],
      tags: ['box', 'celebration', 'gift', 'present', 'wrapped'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f381.png',
    },
    {
      emoji: '🎗',
      name: 'reminder_ribbon',
      shortcodes: ['reminder_ribbon'],
      tags: ['celebration', 'reminder', 'ribbon'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f397-fe0f.png',
    },
    {
      emoji: '🎟',
      name: 'tickets',
      shortcodes: ['admission_tickets', 'tickets'],
      tags: ['admission', 'ticket'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f39f-fe0f.png',
    },
    {
      emoji: '🎫',
      name: 'ticket',
      shortcodes: ['ticket'],
      tags: ['admission'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ab.png',
    },
    {
      emoji: '🎖',
      name: 'medal_military',
      shortcodes: ['military_medal'],
      tags: ['celebration', 'medal', 'military'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f396-fe0f.png',
    },
    {
      emoji: '🏆',
      name: 'trophy',
      shortcodes: ['trophy'],
      tags: ['prize'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c6.png',
    },
    {
      emoji: '🏅',
      name: 'medal_sports',
      shortcodes: ['sports_medal'],
      tags: ['medal'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c5.png',
    },
    {
      emoji: '🥇',
      name: '1st_place_medal',
      shortcodes: ['1st', 'first_place_medal'],
      tags: ['first', 'gold', 'medal'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f947.png',
    },
    {
      emoji: '🥈',
      name: '2nd_place_medal',
      shortcodes: ['2nd', 'second_place_medal'],
      tags: ['medal', 'second', 'silver'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f948.png',
    },
    {
      emoji: '🥉',
      name: '3rd_place_medal',
      shortcodes: ['3rd', 'third_place_medal'],
      tags: ['bronze', 'medal', 'third'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f949.png',
    },
    {
      emoji: '⚽',
      name: 'soccer',
      shortcodes: ['soccer'],
      tags: ['ball', 'football', 'soccer'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26bd.png',
    },
    {
      emoji: '⚾',
      name: 'baseball',
      shortcodes: ['baseball'],
      tags: ['ball'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26be.png',
    },
    {
      emoji: '🥎',
      name: 'softball',
      shortcodes: ['softball'],
      tags: ['ball', 'glove', 'underarm'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94e.png',
    },
    {
      emoji: '🏀',
      name: 'basketball',
      shortcodes: ['basketball'],
      tags: ['ball', 'hoop'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c0.png',
    },
    {
      emoji: '🏐',
      name: 'volleyball',
      shortcodes: ['volleyball'],
      tags: ['ball', 'game'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d0.png',
    },
    {
      emoji: '🏈',
      name: 'football',
      shortcodes: ['football'],
      tags: ['american', 'ball', 'football'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c8.png',
    },
    {
      emoji: '🏉',
      name: 'rugby_football',
      shortcodes: ['rugby_football'],
      tags: ['ball', 'football', 'rugby'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c9.png',
    },
    {
      emoji: '🎾',
      name: 'tennis',
      shortcodes: ['tennis'],
      tags: ['ball', 'racquet'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3be.png',
    },
    {
      emoji: '🥏',
      name: 'flying_disc',
      shortcodes: ['flying_disc'],
      tags: ['ultimate'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94f.png',
    },
    {
      emoji: '🎳',
      name: 'bowling',
      shortcodes: ['bowling'],
      tags: ['ball', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b3.png',
    },
    {
      emoji: '🏏',
      name: 'cricket_game',
      shortcodes: ['cricket_game'],
      tags: ['ball', 'bat', 'game'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3cf.png',
    },
    {
      emoji: '🏑',
      name: 'field_hockey',
      shortcodes: ['field_hockey'],
      tags: ['ball', 'field', 'game', 'hockey', 'stick'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d1.png',
    },
    {
      emoji: '🏒',
      name: 'ice_hockey',
      shortcodes: ['hockey'],
      tags: ['game', 'hockey', 'ice', 'puck', 'stick'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d2.png',
    },
    {
      emoji: '🥍',
      name: 'lacrosse',
      shortcodes: ['lacrosse'],
      tags: ['ball', 'goal', 'stick'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94d.png',
    },
    {
      emoji: '🏓',
      name: 'ping_pong',
      shortcodes: ['ping_pong'],
      tags: ['ball', 'bat', 'game', 'paddle', 'table tennis'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3d3.png',
    },
    {
      emoji: '🏸',
      name: 'badminton',
      shortcodes: ['badminton'],
      tags: ['birdie', 'game', 'racquet', 'shuttlecock'],
      group: 'activities',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f8.png',
    },
    {
      emoji: '🥊',
      name: 'boxing_glove',
      shortcodes: ['boxing_glove'],
      tags: ['boxing', 'glove'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94a.png',
    },
    {
      emoji: '🥋',
      name: 'martial_arts_uniform',
      shortcodes: ['martial_arts_uniform'],
      tags: ['judo', 'karate', 'martial arts', 'taekwondo', 'uniform'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94b.png',
    },
    {
      emoji: '🥅',
      name: 'goal_net',
      shortcodes: ['goal_net'],
      tags: ['goal', 'net'],
      group: 'activities',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f945.png',
    },
    {
      emoji: '⛳',
      name: 'golf',
      shortcodes: ['golf'],
      tags: ['golf', 'hole'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f3.png',
    },
    {
      emoji: '⛸',
      name: 'ice_skate',
      shortcodes: ['ice_skate'],
      tags: ['ice', 'skate'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26f8-fe0f.png',
    },
    {
      emoji: '🎣',
      name: 'fishing_pole_and_fish',
      shortcodes: ['fishing_pole', 'fishing_pole_and_fish'],
      tags: ['fish', 'pole'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a3.png',
    },
    {
      emoji: '🤿',
      name: 'diving_mask',
      shortcodes: ['diving_mask'],
      tags: ['diving', 'scuba', 'snorkeling'],
      group: 'activities',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f93f.png',
    },
    {
      emoji: '🎽',
      name: 'running_shirt_with_sash',
      shortcodes: ['running_shirt', 'running_shirt_with_sash'],
      tags: ['athletics', 'running', 'sash', 'shirt'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3bd.png',
    },
    {
      emoji: '🎿',
      name: 'ski',
      shortcodes: ['ski'],
      tags: ['ski', 'snow'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3bf.png',
    },
    {
      emoji: '🛷',
      name: 'sled',
      shortcodes: ['sled'],
      tags: ['sledge', 'sleigh'],
      group: 'activities',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6f7.png',
    },
    {
      emoji: '🥌',
      name: 'curling_stone',
      shortcodes: ['curling_stone'],
      tags: ['game', 'rock'],
      group: 'activities',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f94c.png',
    },
    {
      emoji: '🎯',
      name: 'dart',
      shortcodes: ['bullseye', 'dart', 'direct_hit'],
      tags: ['dart', 'direct hit', 'game', 'hit', 'target'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3af.png',
    },
    {
      emoji: '🪀',
      name: 'yo_yo',
      shortcodes: ['yo_yo'],
      tags: ['fluctuate', 'toy'],
      group: 'activities',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa80.png',
    },
    {
      emoji: '🪁',
      name: 'kite',
      shortcodes: ['kite'],
      tags: ['fly', 'soar'],
      group: 'activities',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa81.png',
    },
    {
      emoji: '🎱',
      name: '8ball',
      shortcodes: ['8ball', 'billiards'],
      tags: ['8', 'ball', 'billiard', 'eight', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b1.png',
    },
    {
      emoji: '🔮',
      name: 'crystal_ball',
      shortcodes: ['crystal_ball'],
      tags: ['ball', 'crystal', 'fairy tale', 'fantasy', 'fortune', 'tool'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52e.png',
    },
    {
      emoji: '🪄',
      name: 'magic_wand',
      shortcodes: ['magic_wand'],
      tags: ['magic', 'witch', 'wizard'],
      group: 'activities',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa84.png',
    },
    {
      emoji: '🧿',
      name: 'nazar_amulet',
      shortcodes: ['nazar_amulet'],
      tags: ['bead', 'charm', 'evil-eye', 'nazar', 'talisman'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ff.png',
    },
    {
      emoji: '🎮',
      name: 'video_game',
      shortcodes: ['controller', 'video_game'],
      tags: ['controller', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ae.png',
    },
    {
      emoji: '🕹',
      name: 'joystick',
      shortcodes: ['joystick'],
      tags: ['game', 'video game'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f579-fe0f.png',
    },
    {
      emoji: '🎰',
      name: 'slot_machine',
      shortcodes: ['slot_machine'],
      tags: ['game', 'slot'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b0.png',
    },
    {
      emoji: '🎲',
      name: 'game_die',
      shortcodes: ['game_die'],
      tags: ['dice', 'die', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b2.png',
    },
    {
      emoji: '🧩',
      name: 'jigsaw',
      shortcodes: ['jigsaw', 'puzzle_piece'],
      tags: ['clue', 'interlocking', 'jigsaw', 'piece', 'puzzle'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e9.png',
    },
    {
      emoji: '🧸',
      name: 'teddy_bear',
      shortcodes: ['teddy_bear'],
      tags: ['plaything', 'plush', 'stuffed', 'toy'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f8.png',
    },
    {
      emoji: '🪅',
      name: 'pinata',
      shortcodes: ['pinata'],
      tags: ['celebration', 'party'],
      group: 'activities',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa85.png',
    },
    {
      emoji: '🪆',
      name: 'nesting_dolls',
      shortcodes: ['nesting_dolls'],
      tags: ['doll', 'nesting', 'russia'],
      group: 'activities',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa86.png',
    },
    {
      emoji: '♠',
      name: 'spades',
      shortcodes: ['spades'],
      tags: ['card', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2660-fe0f.png',
    },
    {
      emoji: '♥',
      name: 'hearts',
      shortcodes: ['hearts'],
      tags: ['card', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2665-fe0f.png',
    },
    {
      emoji: '♦',
      name: 'diamonds',
      shortcodes: ['diamonds'],
      tags: ['card', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2666-fe0f.png',
    },
    {
      emoji: '♣',
      name: 'clubs',
      shortcodes: ['clubs'],
      tags: ['card', 'game'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2663-fe0f.png',
    },
    {
      emoji: '♟',
      name: 'chess_pawn',
      shortcodes: ['chess_pawn'],
      tags: ['chess', 'dupe', 'expendable'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/265f-fe0f.png',
    },
    {
      emoji: '🃏',
      name: 'black_joker',
      shortcodes: ['black_joker'],
      tags: ['card', 'game', 'wildcard'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f0cf.png',
    },
    {
      emoji: '🀄',
      name: 'mahjong',
      shortcodes: ['mahjong'],
      tags: ['game', 'mahjong', 'red'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f004.png',
    },
    {
      emoji: '🎴',
      name: 'flower_playing_cards',
      shortcodes: ['flower_playing_cards'],
      tags: ['card', 'flower', 'game', 'japanese', 'playing'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b4.png',
    },
    {
      emoji: '🎭',
      name: 'performing_arts',
      shortcodes: ['performing_arts'],
      tags: ['art', 'mask', 'performing', 'theater', 'theatre'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ad.png',
    },
    {
      emoji: '🖼',
      name: 'framed_picture',
      shortcodes: ['frame_with_picture', 'framed_picture'],
      tags: ['art', 'frame', 'museum', 'painting', 'picture'],
      group: 'activities',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5bc-fe0f.png',
    },
    {
      emoji: '🎨',
      name: 'art',
      shortcodes: ['art', 'palette'],
      tags: ['art', 'museum', 'painting', 'palette'],
      group: 'activities',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a8.png',
    },
    {
      emoji: '🧵',
      name: 'thread',
      shortcodes: ['thread'],
      tags: ['needle', 'sewing', 'spool', 'string'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f5.png',
    },
    {
      emoji: '🪡',
      name: 'sewing_needle',
      shortcodes: ['sewing_needle'],
      tags: [
        'embroidery',
        'needle',
        'sewing',
        'stitches',
        'sutures',
        'tailoring',
      ],
      group: 'activities',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa1.png',
    },
    {
      emoji: '🧶',
      name: 'yarn',
      shortcodes: ['yarn'],
      tags: ['ball', 'crochet', 'knit'],
      group: 'activities',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f6.png',
    },
    {
      emoji: '🪢',
      name: 'knot',
      shortcodes: ['knot'],
      tags: ['rope', 'tangled', 'tie', 'twine', 'twist'],
      group: 'activities',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa2.png',
    },
    {
      emoji: '👓',
      name: 'eyeglasses',
      shortcodes: ['eyeglasses', 'glasses'],
      tags: ['clothing', 'eye', 'eyeglasses', 'eyewear'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f453.png',
    },
    {
      emoji: '🕶',
      name: 'dark_sunglasses',
      shortcodes: ['sunglasses'],
      tags: ['dark', 'eye', 'eyewear', 'glasses'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f576-fe0f.png',
    },
    {
      emoji: '🥽',
      name: 'goggles',
      shortcodes: ['goggles'],
      tags: ['eye protection', 'swimming', 'welding'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97d.png',
    },
    {
      emoji: '🥼',
      name: 'lab_coat',
      shortcodes: ['lab_coat'],
      tags: ['doctor', 'experiment', 'scientist'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97c.png',
    },
    {
      emoji: '🦺',
      name: 'safety_vest',
      shortcodes: ['safety_vest'],
      tags: ['emergency', 'safety', 'vest'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ba.png',
    },
    {
      emoji: '👔',
      name: 'necktie',
      shortcodes: ['necktie'],
      tags: ['clothing', 'tie'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f454.png',
    },
    {
      emoji: '👕',
      name: 'shirt',
      shortcodes: ['shirt'],
      tags: ['clothing', 'shirt', 'tshirt'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f455.png',
    },
    {
      emoji: '👖',
      name: 'jeans',
      shortcodes: ['jeans'],
      tags: ['clothing', 'pants', 'trousers'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f456.png',
    },
    {
      emoji: '🧣',
      name: 'scarf',
      shortcodes: ['scarf'],
      tags: ['neck'],
      group: 'objects',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e3.png',
    },
    {
      emoji: '🧤',
      name: 'gloves',
      shortcodes: ['gloves'],
      tags: ['hand'],
      group: 'objects',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e4.png',
    },
    {
      emoji: '🧥',
      name: 'coat',
      shortcodes: ['coat'],
      tags: ['jacket'],
      group: 'objects',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e5.png',
    },
    {
      emoji: '🧦',
      name: 'socks',
      shortcodes: ['socks'],
      tags: ['stocking'],
      group: 'objects',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e6.png',
    },
    {
      emoji: '👗',
      name: 'dress',
      shortcodes: ['dress'],
      tags: ['clothing'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f457.png',
    },
    {
      emoji: '👘',
      name: 'kimono',
      shortcodes: ['kimono'],
      tags: ['clothing'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f458.png',
    },
    {
      emoji: '🥻',
      name: 'sari',
      shortcodes: ['sari'],
      tags: ['clothing', 'dress'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97b.png',
    },
    {
      emoji: '🩱',
      name: 'one_piece_swimsuit',
      shortcodes: ['one_piece_swimsuit'],
      tags: ['bathing suit'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa71.png',
    },
    {
      emoji: '🩲',
      name: 'swim_brief',
      shortcodes: ['briefs'],
      tags: ['bathing suit', 'one-piece', 'swimsuit', 'underwear'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa72.png',
    },
    {
      emoji: '🩳',
      name: 'shorts',
      shortcodes: ['shorts'],
      tags: ['bathing suit', 'pants', 'underwear'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa73.png',
    },
    {
      emoji: '👙',
      name: 'bikini',
      shortcodes: ['bikini'],
      tags: ['clothing', 'swim'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f459.png',
    },
    {
      emoji: '👚',
      name: 'womans_clothes',
      shortcodes: ['womans_clothes'],
      tags: ['clothing', 'woman'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45a.png',
    },
    {
      emoji: '👛',
      name: 'purse',
      shortcodes: ['purse'],
      tags: ['clothing', 'coin'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45b.png',
    },
    {
      emoji: '👜',
      name: 'handbag',
      shortcodes: ['handbag'],
      tags: ['bag', 'clothing', 'purse'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45c.png',
    },
    {
      emoji: '👝',
      name: 'pouch',
      shortcodes: ['clutch_bag', 'pouch'],
      tags: ['bag', 'clothing', 'pouch'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45d.png',
    },
    {
      emoji: '🛍',
      name: 'shopping',
      shortcodes: ['shopping_bags'],
      tags: ['bag', 'hotel', 'shopping'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6cd-fe0f.png',
    },
    {
      emoji: '🎒',
      name: 'school_satchel',
      shortcodes: ['backpack', 'school_satchel'],
      tags: ['bag', 'rucksack', 'satchel', 'school'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f392.png',
    },
    {
      emoji: '🩴',
      name: 'thong_sandal',
      shortcodes: ['thong_sandal'],
      tags: ['beach sandals', 'sandals', 'thong sandals', 'thongs', 'zōri'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa74.png',
    },
    {
      emoji: '👞',
      name: 'mans_shoe',
      shortcodes: ['mans_shoe'],
      tags: ['clothing', 'man', 'shoe'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45e.png',
    },
    {
      emoji: '👟',
      name: 'athletic_shoe',
      shortcodes: ['athletic_shoe', 'sneaker'],
      tags: ['athletic', 'clothing', 'shoe', 'sneaker'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f45f.png',
    },
    {
      emoji: '🥾',
      name: 'hiking_boot',
      shortcodes: ['hiking_boot'],
      tags: ['backpacking', 'boot', 'camping', 'hiking'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97e.png',
    },
    {
      emoji: '🥿',
      name: 'flat_shoe',
      shortcodes: ['flat_shoe', 'womans_flat_shoe'],
      tags: ['ballet flat', 'slip-on', 'slipper'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f97f.png',
    },
    {
      emoji: '👠',
      name: 'high_heel',
      shortcodes: ['high_heel'],
      tags: ['clothing', 'heel', 'shoe', 'woman'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f460.png',
    },
    {
      emoji: '👡',
      name: 'sandal',
      shortcodes: ['sandal'],
      tags: ['clothing', 'sandal', 'shoe', 'woman'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f461.png',
    },
    {
      emoji: '🩰',
      name: 'ballet_shoes',
      shortcodes: ['ballet_shoes'],
      tags: ['ballet', 'dance'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa70.png',
    },
    {
      emoji: '👢',
      name: 'boot',
      shortcodes: ['boot'],
      tags: ['boot', 'clothing', 'shoe', 'woman'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f462.png',
    },
    {
      emoji: '👑',
      name: 'crown',
      shortcodes: ['crown'],
      tags: ['clothing', 'king', 'queen'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f451.png',
    },
    {
      emoji: '👒',
      name: 'womans_hat',
      shortcodes: ['womans_hat'],
      tags: ['clothing', 'hat', 'woman'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f452.png',
    },
    {
      emoji: '🎩',
      name: 'tophat',
      shortcodes: ['top_hat', 'tophat'],
      tags: ['clothing', 'hat', 'top', 'tophat'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a9.png',
    },
    {
      emoji: '🎓',
      name: 'mortar_board',
      shortcodes: ['graduation_cap', 'mortar_board'],
      tags: ['cap', 'celebration', 'clothing', 'graduation', 'hat'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f393.png',
    },
    {
      emoji: '🧢',
      name: 'billed_cap',
      shortcodes: ['billed_cap'],
      tags: ['baseball cap'],
      group: 'objects',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9e2.png',
    },
    {
      emoji: '🪖',
      name: 'military_helmet',
      shortcodes: ['military_helmet'],
      tags: ['army', 'helmet', 'military', 'soldier', 'warrior'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa96.png',
    },
    {
      emoji: '⛑',
      name: 'rescue_worker_helmet',
      shortcodes: ['helmet_with_cross', 'rescue_worker_helmet'],
      tags: ['aid', 'cross', 'face', 'hat', 'helmet'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26d1-fe0f.png',
    },
    {
      emoji: '📿',
      name: 'prayer_beads',
      shortcodes: ['prayer_beads'],
      tags: ['beads', 'clothing', 'necklace', 'prayer', 'religion'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ff.png',
    },
    {
      emoji: '💄',
      name: 'lipstick',
      shortcodes: ['lipstick'],
      tags: ['cosmetics', 'makeup'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f484.png',
    },
    {
      emoji: '💍',
      name: 'ring',
      shortcodes: ['ring'],
      tags: ['diamond'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48d.png',
    },
    {
      emoji: '💎',
      name: 'gem',
      shortcodes: ['gem'],
      tags: ['diamond', 'gem', 'jewel'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48e.png',
    },
    {
      emoji: '🔇',
      name: 'mute',
      shortcodes: ['mute', 'no_sound'],
      tags: ['mute', 'quiet', 'silent', 'speaker'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f507.png',
    },
    {
      emoji: '🔈',
      name: 'speaker',
      shortcodes: ['low_volume', 'quiet_sound', 'speaker'],
      tags: ['soft'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f508.png',
    },
    {
      emoji: '🔉',
      name: 'sound',
      shortcodes: ['medium_volumne', 'sound'],
      tags: ['medium'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f509.png',
    },
    {
      emoji: '🔊',
      name: 'loud_sound',
      shortcodes: ['high_volume', 'loud_sound'],
      tags: ['loud'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50a.png',
    },
    {
      emoji: '📢',
      name: 'loudspeaker',
      shortcodes: ['loudspeaker'],
      tags: ['loud', 'public address'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e2.png',
    },
    {
      emoji: '📣',
      name: 'mega',
      shortcodes: ['mega', 'megaphone'],
      tags: ['cheering'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e3.png',
    },
    {
      emoji: '📯',
      name: 'postal_horn',
      shortcodes: ['postal_horn'],
      tags: ['horn', 'post', 'postal'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ef.png',
    },
    {
      emoji: '🔔',
      name: 'bell',
      shortcodes: ['bell'],
      tags: ['bell'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f514.png',
    },
    {
      emoji: '🔕',
      name: 'no_bell',
      shortcodes: ['no_bell'],
      tags: ['bell', 'forbidden', 'mute', 'quiet', 'silent'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f515.png',
    },
    {
      emoji: '🎼',
      name: 'musical_score',
      shortcodes: ['musical_score'],
      tags: ['music', 'score'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3bc.png',
    },
    {
      emoji: '🎵',
      name: 'musical_note',
      shortcodes: ['musical_note'],
      tags: ['music', 'note'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b5.png',
    },
    {
      emoji: '🎶',
      name: 'notes',
      shortcodes: ['musical_notes', 'notes'],
      tags: ['music', 'note', 'notes'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b6.png',
    },
    {
      emoji: '🎙',
      name: 'studio_microphone',
      shortcodes: ['studio_microphone'],
      tags: ['mic', 'microphone', 'music', 'studio'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f399-fe0f.png',
    },
    {
      emoji: '🎚',
      name: 'level_slider',
      shortcodes: ['level_slider'],
      tags: ['level', 'music', 'slider'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f39a-fe0f.png',
    },
    {
      emoji: '🎛',
      name: 'control_knobs',
      shortcodes: ['control_knobs'],
      tags: ['control', 'knobs', 'music'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f39b-fe0f.png',
    },
    {
      emoji: '🎤',
      name: 'microphone',
      shortcodes: ['microphone'],
      tags: ['karaoke', 'mic'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a4.png',
    },
    {
      emoji: '🎧',
      name: 'headphones',
      shortcodes: ['headphones'],
      tags: ['earbud'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a7.png',
    },
    {
      emoji: '📻',
      name: 'radio',
      shortcodes: ['radio'],
      tags: ['video'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4fb.png',
    },
    {
      emoji: '🎷',
      name: 'saxophone',
      shortcodes: ['saxophone'],
      tags: ['instrument', 'music', 'sax'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b7.png',
    },
    {
      emoji: '🪗',
      name: 'accordion',
      shortcodes: ['accordion'],
      tags: ['concertina', 'squeeze box'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa97.png',
    },
    {
      emoji: '🎸',
      name: 'guitar',
      shortcodes: ['guitar'],
      tags: ['instrument', 'music'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b8.png',
    },
    {
      emoji: '🎹',
      name: 'musical_keyboard',
      shortcodes: ['musical_keyboard'],
      tags: ['instrument', 'keyboard', 'music', 'piano'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3b9.png',
    },
    {
      emoji: '🎺',
      name: 'trumpet',
      shortcodes: ['trumpet'],
      tags: ['instrument', 'music'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ba.png',
    },
    {
      emoji: '🎻',
      name: 'violin',
      shortcodes: ['violin'],
      tags: ['instrument', 'music'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3bb.png',
    },
    {
      emoji: '🪕',
      name: 'banjo',
      shortcodes: ['banjo'],
      tags: ['music', 'stringed'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa95.png',
    },
    {
      emoji: '🥁',
      name: 'drum',
      shortcodes: ['drum'],
      tags: ['drumsticks', 'music'],
      group: 'objects',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f941.png',
    },
    {
      emoji: '🪘',
      name: 'long_drum',
      shortcodes: ['long_drum'],
      tags: ['beat', 'conga', 'drum', 'rhythm'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa98.png',
    },
    {
      emoji: '📱',
      name: 'iphone',
      shortcodes: ['android', 'iphone', 'mobile_phone'],
      tags: ['cell', 'mobile', 'phone', 'telephone'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f1.png',
    },
    {
      emoji: '📲',
      name: 'calling',
      shortcodes: ['calling', 'mobile_phone_arrow'],
      tags: ['arrow', 'cell', 'mobile', 'phone', 'receive'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f2.png',
    },
    {
      emoji: '☎',
      name: 'phone',
      shortcodes: ['telephone'],
      tags: ['phone'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/260e-fe0f.png',
    },
    {
      emoji: '📞',
      name: 'telephone_receiver',
      shortcodes: ['telephone_receiver'],
      tags: ['phone', 'receiver', 'telephone'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4de.png',
    },
    {
      emoji: '📟',
      name: 'pager',
      shortcodes: ['pager'],
      tags: ['pager'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4df.png',
    },
    {
      emoji: '📠',
      name: 'fax',
      shortcodes: ['fax', 'fax_machine'],
      tags: ['fax'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e0.png',
    },
    {
      emoji: '🔋',
      name: 'battery',
      shortcodes: ['battery'],
      tags: ['battery'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50b.png',
    },
    {
      emoji: '🔌',
      name: 'electric_plug',
      shortcodes: ['electric_plug'],
      tags: ['electric', 'electricity', 'plug'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50c.png',
    },
    {
      emoji: '💻',
      name: 'computer',
      shortcodes: ['laptop'],
      tags: ['computer', 'pc', 'personal'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4bb.png',
    },
    {
      emoji: '🖥',
      name: 'desktop_computer',
      shortcodes: ['computer', 'desktop_computer'],
      tags: ['computer', 'desktop'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5a5-fe0f.png',
    },
    {
      emoji: '🖨',
      name: 'printer',
      shortcodes: ['printer'],
      tags: ['computer'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5a8-fe0f.png',
    },
    {
      emoji: '⌨',
      name: 'keyboard',
      shortcodes: ['keyboard'],
      tags: ['computer'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2328-fe0f.png',
    },
    {
      emoji: '🖱',
      name: 'computer_mouse',
      shortcodes: ['computer_mouse'],
      tags: ['computer'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5b1-fe0f.png',
    },
    {
      emoji: '🖲',
      name: 'trackball',
      shortcodes: ['trackball'],
      tags: ['computer'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5b2-fe0f.png',
    },
    {
      emoji: '💽',
      name: 'minidisc',
      shortcodes: ['computer_disk', 'minidisc'],
      tags: ['computer', 'disk', 'minidisk', 'optical'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4bd.png',
    },
    {
      emoji: '💾',
      name: 'floppy_disk',
      shortcodes: ['floppy_disk'],
      tags: ['computer', 'disk', 'floppy'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4be.png',
    },
    {
      emoji: '💿',
      name: 'cd',
      shortcodes: ['cd', 'optical_disk'],
      tags: ['cd', 'computer', 'disk', 'optical'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4bf.png',
    },
    {
      emoji: '📀',
      name: 'dvd',
      shortcodes: ['dvd'],
      tags: ['blu-ray', 'computer', 'disk', 'optical'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c0.png',
    },
    {
      emoji: '🧮',
      name: 'abacus',
      shortcodes: ['abacus'],
      tags: ['calculation'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ee.png',
    },
    {
      emoji: '🎥',
      name: 'movie_camera',
      shortcodes: ['movie_camera'],
      tags: ['camera', 'cinema', 'movie'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a5.png',
    },
    {
      emoji: '🎞',
      name: 'film_strip',
      shortcodes: ['film_frames'],
      tags: ['cinema', 'film', 'frames', 'movie'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f39e-fe0f.png',
    },
    {
      emoji: '📽',
      name: 'film_projector',
      shortcodes: ['film_projector'],
      tags: ['cinema', 'film', 'movie', 'projector', 'video'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4fd-fe0f.png',
    },
    {
      emoji: '🎬',
      name: 'clapper',
      shortcodes: ['clapper'],
      tags: ['clapper', 'movie'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ac.png',
    },
    {
      emoji: '📺',
      name: 'tv',
      shortcodes: ['tv'],
      tags: ['tv', 'video'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4fa.png',
    },
    {
      emoji: '📷',
      name: 'camera',
      shortcodes: ['camera'],
      tags: ['video'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f7.png',
    },
    {
      emoji: '📸',
      name: 'camera_flash',
      shortcodes: ['camera_with_flash'],
      tags: ['camera', 'flash', 'video'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f8.png',
    },
    {
      emoji: '📹',
      name: 'video_camera',
      shortcodes: ['video_camera'],
      tags: ['camera', 'video'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f9.png',
    },
    {
      emoji: '📼',
      name: 'vhs',
      shortcodes: ['vhs', 'videocassette'],
      tags: ['tape', 'vhs', 'video'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4fc.png',
    },
    {
      emoji: '🔍',
      name: 'mag',
      shortcodes: ['mag'],
      tags: ['glass', 'magnifying', 'search', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50d.png',
    },
    {
      emoji: '🔎',
      name: 'mag_right',
      shortcodes: ['mag_right'],
      tags: ['glass', 'magnifying', 'search', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50e.png',
    },
    {
      emoji: '🕯',
      name: 'candle',
      shortcodes: ['candle'],
      tags: ['light'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f56f-fe0f.png',
    },
    {
      emoji: '💡',
      name: 'bulb',
      shortcodes: ['bulb', 'light_bulb'],
      tags: ['bulb', 'comic', 'electric', 'idea', 'light'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a1.png',
    },
    {
      emoji: '🔦',
      name: 'flashlight',
      shortcodes: ['flashlight'],
      tags: ['electric', 'light', 'tool', 'torch'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f526.png',
    },
    {
      emoji: '🏮',
      name: 'izakaya_lantern',
      shortcodes: ['izakaya_lantern', 'red_paper_lantern'],
      tags: ['bar', 'lantern', 'light', 'red'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3ee.png',
    },
    {
      emoji: '🪔',
      name: 'diya_lamp',
      shortcodes: ['diya_lamp'],
      tags: ['diya', 'lamp', 'oil'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa94.png',
    },
    {
      emoji: '📔',
      name: 'notebook_with_decorative_cover',
      shortcodes: ['notebook_with_decorative_cover'],
      tags: ['book', 'cover', 'decorated', 'notebook'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d4.png',
    },
    {
      emoji: '📕',
      name: 'closed_book',
      shortcodes: ['closed_book'],
      tags: ['book', 'closed'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d5.png',
    },
    {
      emoji: '📖',
      name: 'book',
      shortcodes: ['book', 'open_book'],
      tags: ['book', 'open'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d6.png',
    },
    {
      emoji: '📗',
      name: 'green_book',
      shortcodes: ['green_book'],
      tags: ['book', 'green'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d7.png',
    },
    {
      emoji: '📘',
      name: 'blue_book',
      shortcodes: ['blue_book'],
      tags: ['blue', 'book'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d8.png',
    },
    {
      emoji: '📙',
      name: 'orange_book',
      shortcodes: ['orange_book'],
      tags: ['book', 'orange'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d9.png',
    },
    {
      emoji: '📚',
      name: 'books',
      shortcodes: ['books'],
      tags: ['book'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4da.png',
    },
    {
      emoji: '📓',
      name: 'notebook',
      shortcodes: ['notebook'],
      tags: ['notebook'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d3.png',
    },
    {
      emoji: '📒',
      name: 'ledger',
      shortcodes: ['ledger'],
      tags: ['notebook'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d2.png',
    },
    {
      emoji: '📃',
      name: 'page_with_curl',
      shortcodes: ['page_with_curl'],
      tags: ['curl', 'document', 'page'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c3.png',
    },
    {
      emoji: '📜',
      name: 'scroll',
      shortcodes: ['scroll'],
      tags: ['paper'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4dc.png',
    },
    {
      emoji: '📄',
      name: 'page_facing_up',
      shortcodes: ['page_facing_up'],
      tags: ['document', 'page'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c4.png',
    },
    {
      emoji: '📰',
      name: 'newspaper',
      shortcodes: ['newspaper'],
      tags: ['news', 'paper'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f0.png',
    },
    {
      emoji: '🗞',
      name: 'newspaper_roll',
      shortcodes: ['rolled_up_newspaper'],
      tags: ['news', 'newspaper', 'paper', 'rolled'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5de-fe0f.png',
    },
    {
      emoji: '📑',
      name: 'bookmark_tabs',
      shortcodes: ['bookmark_tabs'],
      tags: ['bookmark', 'mark', 'marker', 'tabs'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d1.png',
    },
    {
      emoji: '🔖',
      name: 'bookmark',
      shortcodes: ['bookmark'],
      tags: ['mark'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f516.png',
    },
    {
      emoji: '🏷',
      name: 'label',
      shortcodes: ['label'],
      tags: ['label'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f7-fe0f.png',
    },
    {
      emoji: '💰',
      name: 'moneybag',
      shortcodes: ['moneybag'],
      tags: ['bag', 'dollar', 'money', 'moneybag'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b0.png',
    },
    {
      emoji: '🪙',
      name: 'coin',
      shortcodes: ['coin'],
      tags: ['gold', 'metal', 'money', 'silver', 'treasure'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa99.png',
    },
    {
      emoji: '💴',
      name: 'yen',
      shortcodes: ['yen'],
      tags: ['banknote', 'bill', 'currency', 'money', 'note', 'yen'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b4.png',
    },
    {
      emoji: '💵',
      name: 'dollar',
      shortcodes: ['dollar'],
      tags: ['banknote', 'bill', 'currency', 'dollar', 'money', 'note'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b5.png',
    },
    {
      emoji: '💶',
      name: 'euro',
      shortcodes: ['euro'],
      tags: ['banknote', 'bill', 'currency', 'euro', 'money', 'note'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b6.png',
    },
    {
      emoji: '💷',
      name: 'pound',
      shortcodes: ['pound'],
      tags: ['banknote', 'bill', 'currency', 'money', 'note', 'pound'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b7.png',
    },
    {
      emoji: '💸',
      name: 'money_with_wings',
      shortcodes: ['money_with_wings'],
      tags: ['banknote', 'bill', 'fly', 'money', 'wings'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b8.png',
    },
    {
      emoji: '💳',
      name: 'credit_card',
      shortcodes: ['credit_card'],
      tags: ['card', 'credit', 'money'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b3.png',
    },
    {
      emoji: '🧾',
      name: 'receipt',
      shortcodes: ['receipt'],
      tags: ['accounting', 'bookkeeping', 'evidence', 'proof'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9fe.png',
    },
    {
      emoji: '💹',
      name: 'chart',
      shortcodes: ['chart'],
      tags: ['chart', 'graph', 'growth', 'money', 'yen'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b9.png',
    },
    {
      emoji: '✉',
      name: 'envelope',
      shortcodes: ['envelope'],
      tags: ['email', 'letter'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2709-fe0f.png',
    },
    {
      emoji: '📧',
      name: 'e-mail',
      shortcodes: ['e-mail', 'email'],
      tags: ['email', 'letter', 'mail'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e7.png',
    },
    {
      emoji: '📨',
      name: 'incoming_envelope',
      shortcodes: ['incoming_envelope'],
      tags: ['e-mail', 'email', 'envelope', 'incoming', 'letter', 'receive'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e8.png',
    },
    {
      emoji: '📩',
      name: 'envelope_with_arrow',
      shortcodes: ['envelope_with_arrow'],
      tags: ['arrow', 'e-mail', 'email', 'envelope', 'outgoing'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e9.png',
    },
    {
      emoji: '📤',
      name: 'outbox_tray',
      shortcodes: ['outbox_tray'],
      tags: ['box', 'letter', 'mail', 'outbox', 'sent', 'tray'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e4.png',
    },
    {
      emoji: '📥',
      name: 'inbox_tray',
      shortcodes: ['inbox_tray'],
      tags: ['box', 'inbox', 'letter', 'mail', 'receive', 'tray'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e5.png',
    },
    {
      emoji: '📦',
      name: 'package',
      shortcodes: ['package'],
      tags: ['box', 'parcel'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e6.png',
    },
    {
      emoji: '📫',
      name: 'mailbox',
      shortcodes: ['mailbox'],
      tags: ['closed', 'mail', 'mailbox', 'postbox'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4eb.png',
    },
    {
      emoji: '📪',
      name: 'mailbox_closed',
      shortcodes: ['mailbox_closed'],
      tags: ['closed', 'lowered', 'mail', 'mailbox', 'postbox'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ea.png',
    },
    {
      emoji: '📬',
      name: 'mailbox_with_mail',
      shortcodes: ['mailbox_with_mail'],
      tags: ['mail', 'mailbox', 'open', 'postbox'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ec.png',
    },
    {
      emoji: '📭',
      name: 'mailbox_with_no_mail',
      shortcodes: ['mailbox_with_no_mail'],
      tags: ['lowered', 'mail', 'mailbox', 'open', 'postbox'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ed.png',
    },
    {
      emoji: '📮',
      name: 'postbox',
      shortcodes: ['postbox'],
      tags: ['mail', 'mailbox'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ee.png',
    },
    {
      emoji: '🗳',
      name: 'ballot_box',
      shortcodes: ['ballot_box'],
      tags: ['ballot', 'box'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5f3-fe0f.png',
    },
    {
      emoji: '✏',
      name: 'pencil2',
      shortcodes: ['pencil'],
      tags: ['pencil'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/270f-fe0f.png',
    },
    {
      emoji: '✒',
      name: 'black_nib',
      shortcodes: ['black_nib'],
      tags: ['nib', 'pen'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2712-fe0f.png',
    },
    {
      emoji: '🖋',
      name: 'fountain_pen',
      shortcodes: ['fountain_pen'],
      tags: ['fountain', 'pen'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f58b-fe0f.png',
    },
    {
      emoji: '🖊',
      name: 'pen',
      shortcodes: ['pen'],
      tags: ['ballpoint'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f58a-fe0f.png',
    },
    {
      emoji: '🖌',
      name: 'paintbrush',
      shortcodes: ['paintbrush'],
      tags: ['painting'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f58c-fe0f.png',
    },
    {
      emoji: '🖍',
      name: 'crayon',
      shortcodes: ['crayon'],
      tags: ['crayon'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f58d-fe0f.png',
    },
    {
      emoji: '📝',
      name: 'memo',
      shortcodes: ['memo'],
      tags: ['pencil'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4dd.png',
    },
    {
      emoji: '💼',
      name: 'briefcase',
      shortcodes: ['briefcase'],
      tags: ['briefcase'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4bc.png',
    },
    {
      emoji: '📁',
      name: 'file_folder',
      shortcodes: ['file_folder'],
      tags: ['file', 'folder'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c1.png',
    },
    {
      emoji: '📂',
      name: 'open_file_folder',
      shortcodes: ['open_file_folder'],
      tags: ['file', 'folder', 'open'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c2.png',
    },
    {
      emoji: '🗂',
      name: 'card_index_dividers',
      shortcodes: ['card_index_dividers'],
      tags: ['card', 'dividers', 'index'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5c2-fe0f.png',
    },
    {
      emoji: '📅',
      name: 'date',
      shortcodes: ['date'],
      tags: ['date'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c5.png',
    },
    {
      emoji: '📆',
      name: 'calendar',
      shortcodes: ['calendar'],
      tags: ['calendar'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c6.png',
    },
    {
      emoji: '🗒',
      name: 'spiral_notepad',
      shortcodes: ['notepad_spiral'],
      tags: ['note', 'pad', 'spiral'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5d2-fe0f.png',
    },
    {
      emoji: '🗓',
      name: 'spiral_calendar',
      shortcodes: ['calendar_spiral'],
      tags: ['calendar', 'pad', 'spiral'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5d3-fe0f.png',
    },
    {
      emoji: '📇',
      name: 'card_index',
      shortcodes: ['card_index'],
      tags: ['card', 'index', 'rolodex'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c7.png',
    },
    {
      emoji: '📈',
      name: 'chart_with_upwards_trend',
      shortcodes: ['chart_increasing', 'chart_with_upwards_trend'],
      tags: ['chart', 'graph', 'growth', 'trend', 'upward'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c8.png',
    },
    {
      emoji: '📉',
      name: 'chart_with_downwards_trend',
      shortcodes: ['chart_decreasing', 'chart_with_downwards_trend'],
      tags: ['chart', 'down', 'graph', 'trend'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4c9.png',
    },
    {
      emoji: '📊',
      name: 'bar_chart',
      shortcodes: ['bar_chart'],
      tags: ['bar', 'chart', 'graph'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ca.png',
    },
    {
      emoji: '📋',
      name: 'clipboard',
      shortcodes: ['clipboard'],
      tags: ['clipboard'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4cb.png',
    },
    {
      emoji: '📌',
      name: 'pushpin',
      shortcodes: ['pushpin'],
      tags: ['pin'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4cc.png',
    },
    {
      emoji: '📍',
      name: 'round_pushpin',
      shortcodes: ['round_pushpin'],
      tags: ['pin', 'pushpin'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4cd.png',
    },
    {
      emoji: '📎',
      name: 'paperclip',
      shortcodes: ['paperclip'],
      tags: ['paperclip'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4ce.png',
    },
    {
      emoji: '🖇',
      name: 'paperclips',
      shortcodes: ['paperclips'],
      tags: ['link', 'paperclip'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f587-fe0f.png',
    },
    {
      emoji: '📏',
      name: 'straight_ruler',
      shortcodes: ['straight_ruler'],
      tags: ['ruler', 'straight edge'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4cf.png',
    },
    {
      emoji: '📐',
      name: 'triangular_ruler',
      shortcodes: ['triangular_ruler'],
      tags: ['ruler', 'set', 'triangle'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4d0.png',
    },
    {
      emoji: '✂',
      name: 'scissors',
      shortcodes: ['scissors'],
      tags: ['cutting', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2702-fe0f.png',
    },
    {
      emoji: '🗃',
      name: 'card_file_box',
      shortcodes: ['card_file_box'],
      tags: ['box', 'card', 'file'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5c3-fe0f.png',
    },
    {
      emoji: '🗄',
      name: 'file_cabinet',
      shortcodes: ['file_cabinet'],
      tags: ['cabinet', 'file', 'filing'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5c4-fe0f.png',
    },
    {
      emoji: '🗑',
      name: 'wastebasket',
      shortcodes: ['trashcan', 'wastebasket'],
      tags: ['wastebasket'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5d1-fe0f.png',
    },
    {
      emoji: '🔒',
      name: 'lock',
      shortcodes: ['lock', 'locked'],
      tags: ['closed'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f512.png',
    },
    {
      emoji: '🔓',
      name: 'unlock',
      shortcodes: ['unlock', 'unlocked'],
      tags: ['lock', 'open', 'unlock'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f513.png',
    },
    {
      emoji: '🔏',
      name: 'lock_with_ink_pen',
      shortcodes: ['lock_with_ink_pen', 'locked_with_pen'],
      tags: ['ink', 'lock', 'nib', 'pen', 'privacy'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f50f.png',
    },
    {
      emoji: '🔐',
      name: 'closed_lock_with_key',
      shortcodes: ['closed_lock_with_key', 'locked_with_key'],
      tags: ['closed', 'key', 'lock', 'secure'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f510.png',
    },
    {
      emoji: '🔑',
      name: 'key',
      shortcodes: ['key'],
      tags: ['lock', 'password'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f511.png',
    },
    {
      emoji: '🗝',
      name: 'old_key',
      shortcodes: ['old_key'],
      tags: ['clue', 'key', 'lock', 'old'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5dd-fe0f.png',
    },
    {
      emoji: '🔨',
      name: 'hammer',
      shortcodes: ['hammer'],
      tags: ['tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f528.png',
    },
    {
      emoji: '🪓',
      name: 'axe',
      shortcodes: ['axe'],
      tags: ['chop', 'hatchet', 'split', 'wood'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa93.png',
    },
    {
      emoji: '⛏',
      name: 'pick',
      shortcodes: ['pick'],
      tags: ['mining', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26cf-fe0f.png',
    },
    {
      emoji: '⚒',
      name: 'hammer_and_pick',
      shortcodes: ['hammer_and_pick'],
      tags: ['hammer', 'pick', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2692-fe0f.png',
    },
    {
      emoji: '🛠',
      name: 'hammer_and_wrench',
      shortcodes: ['hammer_and_wrench'],
      tags: ['hammer', 'spanner', 'tool', 'wrench'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e0-fe0f.png',
    },
    {
      emoji: '🗡',
      name: 'dagger',
      shortcodes: ['dagger'],
      tags: ['knife', 'weapon'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5e1-fe0f.png',
    },
    {
      emoji: '⚔',
      name: 'crossed_swords',
      shortcodes: ['crossed_swords'],
      tags: ['crossed', 'swords', 'weapon'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2694-fe0f.png',
    },
    {
      emoji: '🔫',
      name: 'gun',
      shortcodes: ['gun', 'pistol'],
      tags: ['gun', 'handgun', 'pistol', 'revolver', 'tool', 'water', 'weapon'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52b.png',
    },
    {
      emoji: '🪃',
      name: 'boomerang',
      shortcodes: ['boomerang'],
      tags: ['australia', 'rebound', 'repercussion'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa83.png',
    },
    {
      emoji: '🏹',
      name: 'bow_and_arrow',
      shortcodes: ['bow_and_arrow'],
      tags: ['archer', 'arrow', 'bow', 'sagittarius', 'zodiac'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f9.png',
    },
    {
      emoji: '🛡',
      name: 'shield',
      shortcodes: ['shield'],
      tags: ['weapon'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6e1-fe0f.png',
    },
    {
      emoji: '🪚',
      name: 'carpentry_saw',
      shortcodes: ['carpentry_saw'],
      tags: ['carpenter', 'lumber', 'saw', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9a.png',
    },
    {
      emoji: '🔧',
      name: 'wrench',
      shortcodes: ['wrench'],
      tags: ['spanner', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f527.png',
    },
    {
      emoji: '🪛',
      name: 'screwdriver',
      shortcodes: ['screwdriver'],
      tags: ['screw', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9b.png',
    },
    {
      emoji: '🔩',
      name: 'nut_and_bolt',
      shortcodes: ['nut_and_bolt'],
      tags: ['bolt', 'nut', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f529.png',
    },
    {
      emoji: '⚙',
      name: 'gear',
      shortcodes: ['gear'],
      tags: ['cog', 'cogwheel', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2699-fe0f.png',
    },
    {
      emoji: '🗜',
      name: 'clamp',
      shortcodes: ['clamp', 'compression'],
      tags: ['compress', 'tool', 'vice'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5dc-fe0f.png',
    },
    {
      emoji: '⚖',
      name: 'balance_scale',
      shortcodes: ['scales'],
      tags: ['balance', 'justice', 'libra', 'scale', 'zodiac'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2696-fe0f.png',
    },
    {
      emoji: '🦯',
      name: 'probing_cane',
      shortcodes: ['probing_cane', 'white_cane'],
      tags: ['accessibility', 'blind'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9af.png',
    },
    {
      emoji: '🔗',
      name: 'link',
      shortcodes: ['link'],
      tags: ['link'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f517.png',
    },
    {
      emoji: '⛓',
      name: 'chains',
      shortcodes: ['chains'],
      tags: ['chain'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26d3-fe0f.png',
    },
    {
      emoji: '🪝',
      name: 'hook',
      shortcodes: ['hook'],
      tags: ['catch', 'crook', 'curve', 'ensnare', 'selling point'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9d.png',
    },
    {
      emoji: '🧰',
      name: 'toolbox',
      shortcodes: ['toolbox'],
      tags: ['chest', 'mechanic', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f0.png',
    },
    {
      emoji: '🧲',
      name: 'magnet',
      shortcodes: ['magnet'],
      tags: ['attraction', 'horseshoe', 'magnetic'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f2.png',
    },
    {
      emoji: '🪜',
      name: 'ladder',
      shortcodes: ['ladder'],
      tags: ['climb', 'rung', 'step'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9c.png',
    },
    {
      emoji: '⚗',
      name: 'alembic',
      shortcodes: ['alembic'],
      tags: ['chemistry', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2697-fe0f.png',
    },
    {
      emoji: '🧪',
      name: 'test_tube',
      shortcodes: ['test_tube'],
      tags: ['chemist', 'chemistry', 'experiment', 'lab', 'science'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ea.png',
    },
    {
      emoji: '🧫',
      name: 'petri_dish',
      shortcodes: ['petri_dish'],
      tags: ['bacteria', 'biologist', 'biology', 'culture', 'lab'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9eb.png',
    },
    {
      emoji: '🧬',
      name: 'dna',
      shortcodes: ['dna', 'double_helix'],
      tags: ['biologist', 'evolution', 'gene', 'genetics', 'life'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ec.png',
    },
    {
      emoji: '🔬',
      name: 'microscope',
      shortcodes: ['microscope'],
      tags: ['science', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52c.png',
    },
    {
      emoji: '🔭',
      name: 'telescope',
      shortcodes: ['telescope'],
      tags: ['science', 'tool'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52d.png',
    },
    {
      emoji: '📡',
      name: 'satellite',
      shortcodes: ['satellite_antenna'],
      tags: ['antenna', 'dish', 'satellite'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4e1.png',
    },
    {
      emoji: '💉',
      name: 'syringe',
      shortcodes: ['syringe'],
      tags: ['medicine', 'needle', 'shot', 'sick'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f489.png',
    },
    {
      emoji: '🩸',
      name: 'drop_of_blood',
      shortcodes: ['drop_of_blood'],
      tags: ['bleed', 'blood donation', 'injury', 'medicine', 'menstruation'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa78.png',
    },
    {
      emoji: '💊',
      name: 'pill',
      shortcodes: ['pill'],
      tags: ['doctor', 'medicine', 'sick'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f48a.png',
    },
    {
      emoji: '🩹',
      name: 'adhesive_bandage',
      shortcodes: ['adhesive_bandage', 'bandaid'],
      tags: ['bandage'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa79.png',
    },
    {
      emoji: '🩺',
      name: 'stethoscope',
      shortcodes: ['stethoscope'],
      tags: ['doctor', 'heart', 'medicine'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa7a.png',
    },
    {
      emoji: '🚪',
      name: 'door',
      shortcodes: ['door'],
      tags: ['door'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6aa.png',
    },
    {
      emoji: '🛗',
      name: 'elevator',
      shortcodes: ['elevator'],
      tags: ['accessibility', 'hoist', 'lift'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d7.png',
    },
    {
      emoji: '🪞',
      name: 'mirror',
      shortcodes: ['mirror'],
      tags: ['reflection', 'reflector', 'speculum'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9e.png',
    },
    {
      emoji: '🪟',
      name: 'window',
      shortcodes: ['window'],
      tags: ['frame', 'fresh air', 'opening', 'transparent', 'view'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa9f.png',
    },
    {
      emoji: '🛏',
      name: 'bed',
      shortcodes: ['bed'],
      tags: ['hotel', 'sleep'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6cf-fe0f.png',
    },
    {
      emoji: '🛋',
      name: 'couch_and_lamp',
      shortcodes: ['couch_and_lamp'],
      tags: ['couch', 'hotel', 'lamp'],
      group: 'objects',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6cb-fe0f.png',
    },
    {
      emoji: '🪑',
      name: 'chair',
      shortcodes: ['chair'],
      tags: ['seat', 'sit'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa91.png',
    },
    {
      emoji: '🚽',
      name: 'toilet',
      shortcodes: ['toilet'],
      tags: ['toilet'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6bd.png',
    },
    {
      emoji: '🪠',
      name: 'plunger',
      shortcodes: ['plunger'],
      tags: ['force cup', 'plumber', 'suction', 'toilet'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa0.png',
    },
    {
      emoji: '🚿',
      name: 'shower',
      shortcodes: ['shower'],
      tags: ['water'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6bf.png',
    },
    {
      emoji: '🛁',
      name: 'bathtub',
      shortcodes: ['bathtub'],
      tags: ['bath'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c1.png',
    },
    {
      emoji: '🪤',
      name: 'mouse_trap',
      shortcodes: ['mouse_trap'],
      tags: ['bait', 'mousetrap', 'snare', 'trap'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa4.png',
    },
    {
      emoji: '🪒',
      name: 'razor',
      shortcodes: ['razor'],
      tags: ['sharp', 'shave'],
      group: 'objects',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1fa92.png',
    },
    {
      emoji: '🧴',
      name: 'lotion_bottle',
      shortcodes: ['lotion_bottle'],
      tags: ['lotion', 'moisturizer', 'shampoo', 'sunscreen'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f4.png',
    },
    {
      emoji: '🧷',
      name: 'safety_pin',
      shortcodes: ['safety_pin'],
      tags: ['diaper', 'punk rock'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f7.png',
    },
    {
      emoji: '🧹',
      name: 'broom',
      shortcodes: ['broom'],
      tags: ['cleaning', 'sweeping', 'witch'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9f9.png',
    },
    {
      emoji: '🧺',
      name: 'basket',
      shortcodes: ['basket'],
      tags: ['farming', 'laundry', 'picnic'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9fa.png',
    },
    {
      emoji: '🧻',
      name: 'roll_of_paper',
      shortcodes: ['roll_of_paper', 'toilet_paper'],
      tags: ['paper towels', 'toilet paper'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9fb.png',
    },
    {
      emoji: '🪣',
      name: 'bucket',
      shortcodes: ['bucket'],
      tags: ['cask', 'pail', 'vat'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa3.png',
    },
    {
      emoji: '🧼',
      name: 'soap',
      shortcodes: ['soap'],
      tags: ['bar', 'bathing', 'cleaning', 'lather', 'soapdish'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9fc.png',
    },
    {
      emoji: '🪥',
      name: 'toothbrush',
      shortcodes: ['toothbrush'],
      tags: ['bathroom', 'brush', 'clean', 'dental', 'hygiene', 'teeth'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa5.png',
    },
    {
      emoji: '🧽',
      name: 'sponge',
      shortcodes: ['sponge'],
      tags: ['absorbing', 'cleaning', 'porous'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9fd.png',
    },
    {
      emoji: '🧯',
      name: 'fire_extinguisher',
      shortcodes: ['fire_extinguisher'],
      tags: ['extinguish', 'fire', 'quench'],
      group: 'objects',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9ef.png',
    },
    {
      emoji: '🛒',
      name: 'shopping_cart',
      shortcodes: ['shopping_cart'],
      tags: ['cart', 'shopping', 'trolley'],
      group: 'objects',
      emoticons: [],
      version: 3,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d2.png',
    },
    {
      emoji: '🚬',
      name: 'smoking',
      shortcodes: ['cigarette', 'smoking'],
      tags: ['smoking'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ac.png',
    },
    {
      emoji: '⚰',
      name: 'coffin',
      shortcodes: ['coffin'],
      tags: ['death'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26b0-fe0f.png',
    },
    {
      emoji: '🪦',
      name: 'headstone',
      shortcodes: ['headstone'],
      tags: ['cemetery', 'grave', 'graveyard', 'tombstone'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa6.png',
    },
    {
      emoji: '⚱',
      name: 'funeral_urn',
      shortcodes: ['funeral_urn'],
      tags: ['ashes', 'death', 'funeral', 'urn'],
      group: 'objects',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26b1-fe0f.png',
    },
    {
      emoji: '🗿',
      name: 'moyai',
      shortcodes: ['moai', 'moyai'],
      tags: ['face', 'moyai', 'statue'],
      group: 'objects',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5ff.png',
    },
    {
      emoji: '🪧',
      name: 'placard',
      shortcodes: ['placard'],
      tags: ['demonstration', 'picket', 'protest', 'sign'],
      group: 'objects',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1faa7.png',
    },
    {
      emoji: '🏧',
      name: 'atm',
      shortcodes: ['atm'],
      tags: ['atm', 'atm sign', 'automated', 'bank', 'teller'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3e7.png',
    },
    {
      emoji: '🚮',
      name: 'put_litter_in_its_place',
      shortcodes: ['litter_bin', 'put_litter_in_its_place'],
      tags: ['litter', 'litter bin'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ae.png',
    },
    {
      emoji: '🚰',
      name: 'potable_water',
      shortcodes: ['potable_water'],
      tags: ['drinking', 'potable', 'water'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b0.png',
    },
    {
      emoji: '♿',
      name: 'wheelchair',
      shortcodes: ['handicapped', 'wheelchair'],
      tags: ['access'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/267f.png',
    },
    {
      emoji: '🚹',
      name: 'mens',
      shortcodes: ['mens'],
      tags: ['bathroom', 'lavatory', 'man', 'restroom', 'toilet', 'wc'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b9.png',
    },
    {
      emoji: '🚺',
      name: 'womens',
      shortcodes: ['womens'],
      tags: ['bathroom', 'lavatory', 'restroom', 'toilet', 'wc', 'woman'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ba.png',
    },
    {
      emoji: '🚻',
      name: 'restroom',
      shortcodes: ['bathroom', 'restroom'],
      tags: ['bathroom', 'lavatory', 'toilet', 'wc'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6bb.png',
    },
    {
      emoji: '🚼',
      name: 'baby_symbol',
      shortcodes: ['baby_symbol'],
      tags: ['baby', 'changing'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6bc.png',
    },
    {
      emoji: '🚾',
      name: 'wc',
      shortcodes: ['water_closet', 'wc'],
      tags: [
        'bathroom',
        'closet',
        'lavatory',
        'restroom',
        'toilet',
        'water',
        'wc',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6be.png',
    },
    {
      emoji: '🛂',
      name: 'passport_control',
      shortcodes: ['passport_control'],
      tags: ['control', 'passport'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c2.png',
    },
    {
      emoji: '🛃',
      name: 'customs',
      shortcodes: ['customs'],
      tags: ['customs'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c3.png',
    },
    {
      emoji: '🛄',
      name: 'baggage_claim',
      shortcodes: ['baggage_claim'],
      tags: ['baggage', 'claim'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c4.png',
    },
    {
      emoji: '🛅',
      name: 'left_luggage',
      shortcodes: ['left_luggage'],
      tags: ['baggage', 'locker', 'luggage'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6c5.png',
    },
    {
      emoji: '⚠',
      name: 'warning',
      shortcodes: ['warning'],
      tags: ['warning'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26a0-fe0f.png',
    },
    {
      emoji: '🚸',
      name: 'children_crossing',
      shortcodes: ['children_crossing'],
      tags: ['child', 'crossing', 'pedestrian', 'traffic'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b8.png',
    },
    {
      emoji: '⛔',
      name: 'no_entry',
      shortcodes: ['no_entry'],
      tags: ['entry', 'forbidden', 'no', 'not', 'prohibited', 'traffic'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26d4.png',
    },
    {
      emoji: '🚫',
      name: 'no_entry_sign',
      shortcodes: ['no_entry_sign'],
      tags: ['entry', 'forbidden', 'no', 'not'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ab.png',
    },
    {
      emoji: '🚳',
      name: 'no_bicycles',
      shortcodes: ['no_bicycles'],
      tags: ['bicycle', 'bike', 'forbidden', 'no', 'prohibited'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b3.png',
    },
    {
      emoji: '🚭',
      name: 'no_smoking',
      shortcodes: ['no_smoking'],
      tags: ['forbidden', 'no', 'not', 'prohibited', 'smoking'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6ad.png',
    },
    {
      emoji: '🚯',
      name: 'do_not_litter',
      shortcodes: ['do_not_litter', 'no_littering'],
      tags: ['forbidden', 'litter', 'no', 'not', 'prohibited'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6af.png',
    },
    {
      emoji: '🚱',
      name: 'non-potable_water',
      shortcodes: ['non-potable_water'],
      tags: ['non-drinking', 'non-potable', 'water'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b1.png',
    },
    {
      emoji: '🚷',
      name: 'no_pedestrians',
      shortcodes: ['no_pedestrians'],
      tags: ['forbidden', 'no', 'not', 'pedestrian', 'prohibited'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6b7.png',
    },
    {
      emoji: '📵',
      name: 'no_mobile_phones',
      shortcodes: ['no_mobile_phones'],
      tags: ['cell', 'forbidden', 'mobile', 'no', 'phone'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f5.png',
    },
    {
      emoji: '🔞',
      name: 'underage',
      shortcodes: ['no_one_under_18', 'underage'],
      tags: ['18', 'age restriction', 'eighteen', 'prohibited', 'underage'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51e.png',
    },
    {
      emoji: '☢',
      name: 'radioactive',
      shortcodes: ['radioactive'],
      tags: ['sign'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2622-fe0f.png',
    },
    {
      emoji: '☣',
      name: 'biohazard',
      shortcodes: ['biohazard'],
      tags: ['sign'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2623-fe0f.png',
    },
    {
      emoji: '⬆',
      name: 'arrow_up',
      shortcodes: ['arrow_up'],
      tags: ['arrow', 'cardinal', 'direction', 'north'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b06-fe0f.png',
    },
    {
      emoji: '↗',
      name: 'arrow_upper_right',
      shortcodes: ['arrow_upper_right'],
      tags: ['arrow', 'direction', 'intercardinal', 'northeast'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2197-fe0f.png',
    },
    {
      emoji: '➡',
      name: 'arrow_right',
      shortcodes: ['arrow_right'],
      tags: ['arrow', 'cardinal', 'direction', 'east'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/27a1-fe0f.png',
    },
    {
      emoji: '↘',
      name: 'arrow_lower_right',
      shortcodes: ['arrow_lower_right'],
      tags: ['arrow', 'direction', 'intercardinal', 'southeast'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2198-fe0f.png',
    },
    {
      emoji: '⬇',
      name: 'arrow_down',
      shortcodes: ['arrow_down'],
      tags: ['arrow', 'cardinal', 'direction', 'down', 'south'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b07-fe0f.png',
    },
    {
      emoji: '↙',
      name: 'arrow_lower_left',
      shortcodes: ['arrow_lower_left'],
      tags: ['arrow', 'direction', 'intercardinal', 'southwest'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2199-fe0f.png',
    },
    {
      emoji: '⬅',
      name: 'arrow_left',
      shortcodes: ['arrow_left'],
      tags: ['arrow', 'cardinal', 'direction', 'west'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b05-fe0f.png',
    },
    {
      emoji: '↖',
      name: 'arrow_upper_left',
      shortcodes: ['arrow_upper_left'],
      tags: ['arrow', 'direction', 'intercardinal', 'northwest'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2196-fe0f.png',
    },
    {
      emoji: '↕',
      name: 'arrow_up_down',
      shortcodes: ['arrow_up_down'],
      tags: ['arrow'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2195-fe0f.png',
    },
    {
      emoji: '↔',
      name: 'left_right_arrow',
      shortcodes: ['left_right_arrow'],
      tags: ['arrow'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2194-fe0f.png',
    },
    {
      emoji: '↩',
      name: 'leftwards_arrow_with_hook',
      shortcodes: ['arrow_left_hook', 'leftwards_arrow_with_hook'],
      tags: ['arrow'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/21a9-fe0f.png',
    },
    {
      emoji: '↪',
      name: 'arrow_right_hook',
      shortcodes: ['arrow_right_hook', 'rightwards_arrow_with_hook'],
      tags: ['arrow'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/21aa-fe0f.png',
    },
    {
      emoji: '⤴',
      name: 'arrow_heading_up',
      shortcodes: ['arrow_heading_up'],
      tags: ['arrow'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2934-fe0f.png',
    },
    {
      emoji: '⤵',
      name: 'arrow_heading_down',
      shortcodes: ['arrow_heading_down'],
      tags: ['arrow', 'down'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2935-fe0f.png',
    },
    {
      emoji: '🔃',
      name: 'arrows_clockwise',
      shortcodes: ['arrows_clockwise', 'clockwise'],
      tags: ['arrow', 'clockwise', 'reload'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f503.png',
    },
    {
      emoji: '🔄',
      name: 'arrows_counterclockwise',
      shortcodes: ['arrows_counterclockwise', 'counterclockwise'],
      tags: ['anticlockwise', 'arrow', 'counterclockwise', 'withershins'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f504.png',
    },
    {
      emoji: '🔙',
      name: 'back',
      shortcodes: ['back'],
      tags: ['arrow', 'back'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f519.png',
    },
    {
      emoji: '🔚',
      name: 'end',
      shortcodes: ['end'],
      tags: ['arrow', 'end'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51a.png',
    },
    {
      emoji: '🔛',
      name: 'on',
      shortcodes: ['on'],
      tags: ['arrow', 'mark', 'on', 'on!'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51b.png',
    },
    {
      emoji: '🔜',
      name: 'soon',
      shortcodes: ['soon'],
      tags: ['arrow', 'soon'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51c.png',
    },
    {
      emoji: '🔝',
      name: 'top',
      shortcodes: ['top'],
      tags: ['arrow', 'top', 'up'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51d.png',
    },
    {
      emoji: '🛐',
      name: 'place_of_worship',
      shortcodes: ['place_of_worship'],
      tags: ['religion', 'worship'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6d0.png',
    },
    {
      emoji: '⚛',
      name: 'atom_symbol',
      shortcodes: ['atom', 'atom_symbol'],
      tags: ['atheist', 'atom'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/269b-fe0f.png',
    },
    {
      emoji: '🕉',
      name: 'om',
      shortcodes: ['om'],
      tags: ['hindu', 'religion'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f549-fe0f.png',
    },
    {
      emoji: '✡',
      name: 'star_of_david',
      shortcodes: ['star_of_david'],
      tags: ['david', 'jew', 'jewish', 'religion', 'star', 'star of david'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2721-fe0f.png',
    },
    {
      emoji: '☸',
      name: 'wheel_of_dharma',
      shortcodes: ['wheel_of_dharma'],
      tags: ['buddhist', 'dharma', 'religion', 'wheel'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2638-fe0f.png',
    },
    {
      emoji: '☯',
      name: 'yin_yang',
      shortcodes: ['yin_yang'],
      tags: ['religion', 'tao', 'taoist', 'yang', 'yin'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/262f-fe0f.png',
    },
    {
      emoji: '✝',
      name: 'latin_cross',
      shortcodes: ['latin_cross'],
      tags: ['christian', 'cross', 'religion'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/271d-fe0f.png',
    },
    {
      emoji: '☦',
      name: 'orthodox_cross',
      shortcodes: ['orthodox_cross'],
      tags: ['christian', 'cross', 'religion'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2626-fe0f.png',
    },
    {
      emoji: '☪',
      name: 'star_and_crescent',
      shortcodes: ['star_and_crescent'],
      tags: ['islam', 'muslim', 'religion'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/262a-fe0f.png',
    },
    {
      emoji: '☮',
      name: 'peace_symbol',
      shortcodes: ['peace', 'peace_symbol'],
      tags: ['peace'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/262e-fe0f.png',
    },
    {
      emoji: '🕎',
      name: 'menorah',
      shortcodes: ['menorah'],
      tags: ['candelabrum', 'candlestick', 'religion'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f54e.png',
    },
    {
      emoji: '🔯',
      name: 'six_pointed_star',
      shortcodes: ['six_pointed_star'],
      tags: ['fortune', 'star'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f52f.png',
    },
    {
      emoji: '♈',
      name: 'aries',
      shortcodes: ['aries'],
      tags: ['aries', 'ram', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2648.png',
    },
    {
      emoji: '♉',
      name: 'taurus',
      shortcodes: ['taurus'],
      tags: ['bull', 'ox', 'taurus', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2649.png',
    },
    {
      emoji: '♊',
      name: 'gemini',
      shortcodes: ['gemini'],
      tags: ['gemini', 'twins', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264a.png',
    },
    {
      emoji: '♋',
      name: 'cancer',
      shortcodes: ['cancer'],
      tags: ['cancer', 'crab', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264b.png',
    },
    {
      emoji: '♌',
      name: 'leo',
      shortcodes: ['leo'],
      tags: ['leo', 'lion', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264c.png',
    },
    {
      emoji: '♍',
      name: 'virgo',
      shortcodes: ['virgo'],
      tags: ['virgo', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264d.png',
    },
    {
      emoji: '♎',
      name: 'libra',
      shortcodes: ['libra'],
      tags: ['balance', 'justice', 'libra', 'scales', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264e.png',
    },
    {
      emoji: '♏',
      name: 'scorpius',
      shortcodes: ['scorpius'],
      tags: ['scorpio', 'scorpion', 'scorpius', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/264f.png',
    },
    {
      emoji: '♐',
      name: 'sagittarius',
      shortcodes: ['sagittarius'],
      tags: ['archer', 'sagittarius', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2650.png',
    },
    {
      emoji: '♑',
      name: 'capricorn',
      shortcodes: ['capricorn'],
      tags: ['capricorn', 'goat', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2651.png',
    },
    {
      emoji: '♒',
      name: 'aquarius',
      shortcodes: ['aquarius'],
      tags: ['aquarius', 'bearer', 'water', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2652.png',
    },
    {
      emoji: '♓',
      name: 'pisces',
      shortcodes: ['pisces'],
      tags: ['fish', 'pisces', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2653.png',
    },
    {
      emoji: '⛎',
      name: 'ophiuchus',
      shortcodes: ['ophiuchus'],
      tags: ['bearer', 'ophiuchus', 'serpent', 'snake', 'zodiac'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26ce.png',
    },
    {
      emoji: '🔀',
      name: 'twisted_rightwards_arrows',
      shortcodes: ['shuffle', 'twisted_rightwards_arrows'],
      tags: ['arrow', 'crossed'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f500.png',
    },
    {
      emoji: '🔁',
      name: 'repeat',
      shortcodes: ['repeat'],
      tags: ['arrow', 'clockwise', 'repeat'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f501.png',
    },
    {
      emoji: '🔂',
      name: 'repeat_one',
      shortcodes: ['repeat_one'],
      tags: ['arrow', 'clockwise', 'once'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f502.png',
    },
    {
      emoji: '▶',
      name: 'arrow_forward',
      shortcodes: ['arrow_forward', 'play'],
      tags: ['arrow', 'play', 'right', 'triangle'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25b6-fe0f.png',
    },
    {
      emoji: '⏩',
      name: 'fast_forward',
      shortcodes: ['fast_forward'],
      tags: ['arrow', 'double', 'fast', 'forward'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23e9.png',
    },
    {
      emoji: '⏭',
      name: 'next_track_button',
      shortcodes: ['next_track'],
      tags: ['arrow', 'next scene', 'next track', 'triangle'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23ed-fe0f.png',
    },
    {
      emoji: '⏯',
      name: 'play_or_pause_button',
      shortcodes: ['play_pause'],
      tags: ['arrow', 'pause', 'play', 'right', 'triangle'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23ef-fe0f.png',
    },
    {
      emoji: '◀',
      name: 'arrow_backward',
      shortcodes: ['arrow_backward', 'reverse'],
      tags: ['arrow', 'left', 'reverse', 'triangle'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25c0-fe0f.png',
    },
    {
      emoji: '⏪',
      name: 'rewind',
      shortcodes: ['fast_reverse', 'rewind'],
      tags: ['arrow', 'double', 'rewind'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23ea.png',
    },
    {
      emoji: '⏮',
      name: 'previous_track_button',
      shortcodes: ['previous_track'],
      tags: ['arrow', 'previous scene', 'previous track', 'triangle'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23ee-fe0f.png',
    },
    {
      emoji: '🔼',
      name: 'arrow_up_small',
      shortcodes: ['arrow_up_small', 'up'],
      tags: ['arrow', 'button', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f53c.png',
    },
    {
      emoji: '⏫',
      name: 'arrow_double_up',
      shortcodes: ['arrow_double_up', 'fast_up'],
      tags: ['arrow', 'double'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23eb.png',
    },
    {
      emoji: '🔽',
      name: 'arrow_down_small',
      shortcodes: ['arrow_down_small', 'down'],
      tags: ['arrow', 'button', 'down', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f53d.png',
    },
    {
      emoji: '⏬',
      name: 'arrow_double_down',
      shortcodes: ['arrow_double_down', 'fast_down'],
      tags: ['arrow', 'double', 'down'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23ec.png',
    },
    {
      emoji: '⏸',
      name: 'pause_button',
      shortcodes: ['pause'],
      tags: ['bar', 'double', 'pause', 'vertical'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f8-fe0f.png',
    },
    {
      emoji: '⏹',
      name: 'stop_button',
      shortcodes: ['stop'],
      tags: ['square', 'stop'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23f9-fe0f.png',
    },
    {
      emoji: '⏺',
      name: 'record_button',
      shortcodes: ['record'],
      tags: ['circle', 'record'],
      group: 'symbols',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23fa-fe0f.png',
    },
    {
      emoji: '⏏',
      name: 'eject_button',
      shortcodes: ['eject'],
      tags: ['eject'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/23cf-fe0f.png',
    },
    {
      emoji: '🎦',
      name: 'cinema',
      shortcodes: ['cinema'],
      tags: ['camera', 'film', 'movie'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3a6.png',
    },
    {
      emoji: '🔅',
      name: 'low_brightness',
      shortcodes: ['dim_button', 'low_brightness'],
      tags: ['brightness', 'dim', 'low'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f505.png',
    },
    {
      emoji: '🔆',
      name: 'high_brightness',
      shortcodes: ['bright_button', 'high_brightness'],
      tags: ['bright', 'brightness'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f506.png',
    },
    {
      emoji: '📶',
      name: 'signal_strength',
      shortcodes: ['antenna_bars', 'signal_strength'],
      tags: ['antenna', 'bar', 'cell', 'mobile', 'phone'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f6.png',
    },
    {
      emoji: '📳',
      name: 'vibration_mode',
      shortcodes: ['vibration_mode'],
      tags: ['cell', 'mobile', 'mode', 'phone', 'telephone', 'vibration'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f3.png',
    },
    {
      emoji: '📴',
      name: 'mobile_phone_off',
      shortcodes: ['mobile_phone_off'],
      tags: ['cell', 'mobile', 'off', 'phone', 'telephone'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4f4.png',
    },
    {
      emoji: '♀',
      name: 'female_sign',
      shortcodes: ['female', 'female_sign'],
      tags: ['woman'],
      group: 'symbols',
      emoticons: [],
      version: 4,
    },
    {
      emoji: '♂',
      name: 'male_sign',
      shortcodes: ['male', 'male_sign'],
      tags: ['man'],
      group: 'symbols',
      emoticons: [],
      version: 4,
    },
    {
      emoji: '⚧',
      name: 'transgender_symbol',
      shortcodes: ['transgender_symbol'],
      tags: ['transgender'],
      group: 'symbols',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26a7-fe0f.png',
    },
    {
      emoji: '✖',
      name: 'heavy_multiplication_x',
      shortcodes: ['multiplication', 'multiply'],
      tags: ['cancel', 'multiplication', 'sign', 'x', '×'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2716-fe0f.png',
    },
    {
      emoji: '➕',
      name: 'heavy_plus_sign',
      shortcodes: ['plus'],
      tags: ['+', 'math', 'sign'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2795.png',
    },
    {
      emoji: '➖',
      name: 'heavy_minus_sign',
      shortcodes: ['minus'],
      tags: ['-', 'math', 'sign', '−'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2796.png',
    },
    {
      emoji: '➗',
      name: 'heavy_division_sign',
      shortcodes: ['divide', 'division'],
      tags: ['division', 'math', 'sign', '÷'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2797.png',
    },
    {
      emoji: '♾',
      name: 'infinity',
      shortcodes: ['infinity'],
      tags: ['forever', 'unbounded', 'universal'],
      group: 'symbols',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/267e-fe0f.png',
    },
    {
      emoji: '‼',
      name: 'bangbang',
      shortcodes: ['bangbang', 'double_exclamation'],
      tags: ['!', '!!', 'bangbang', 'exclamation', 'mark'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/203c-fe0f.png',
    },
    {
      emoji: '⁉',
      name: 'interrobang',
      shortcodes: ['exclamation_question', 'interrobang'],
      tags: [
        '!',
        '!?',
        '?',
        'exclamation',
        'interrobang',
        'mark',
        'punctuation',
        'question',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2049-fe0f.png',
    },
    {
      emoji: '❓',
      name: 'question',
      shortcodes: ['question'],
      tags: ['?', 'mark', 'punctuation', 'question'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2753.png',
    },
    {
      emoji: '❔',
      name: 'grey_question',
      shortcodes: ['white_question'],
      tags: ['?', 'mark', 'outlined', 'punctuation', 'question'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2754.png',
    },
    {
      emoji: '❕',
      name: 'grey_exclamation',
      shortcodes: ['white_exclamation'],
      tags: ['!', 'exclamation', 'mark', 'outlined', 'punctuation'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2755.png',
    },
    {
      emoji: '❗',
      name: 'exclamation',
      shortcodes: ['exclamation'],
      tags: ['!', 'exclamation', 'mark', 'punctuation'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2757.png',
    },
    {
      emoji: '〰',
      name: 'wavy_dash',
      shortcodes: ['wavy_dash'],
      tags: ['dash', 'punctuation', 'wavy'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/3030-fe0f.png',
    },
    {
      emoji: '💱',
      name: 'currency_exchange',
      shortcodes: ['currency_exchange'],
      tags: ['bank', 'currency', 'exchange', 'money'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b1.png',
    },
    {
      emoji: '💲',
      name: 'heavy_dollar_sign',
      shortcodes: ['heavy_dollar_sign'],
      tags: ['currency', 'dollar', 'money'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4b2.png',
    },
    {
      emoji: '⚕',
      name: 'medical_symbol',
      shortcodes: ['medical', 'medical_symbol'],
      tags: ['aesculapius', 'medicine', 'staff'],
      group: 'symbols',
      emoticons: [],
      version: 4,
    },
    {
      emoji: '♻',
      name: 'recycle',
      shortcodes: ['recycle', 'recycling_symbol'],
      tags: ['recycle'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/267b-fe0f.png',
    },
    {
      emoji: '⚜',
      name: 'fleur_de_lis',
      shortcodes: ['fleur-de-lis'],
      tags: ['fleur-de-lis'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/269c-fe0f.png',
    },
    {
      emoji: '🔱',
      name: 'trident',
      shortcodes: ['trident'],
      tags: ['anchor', 'emblem', 'ship', 'tool', 'trident'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f531.png',
    },
    {
      emoji: '📛',
      name: 'name_badge',
      shortcodes: ['name_badge'],
      tags: ['badge', 'name'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4db.png',
    },
    {
      emoji: '🔰',
      name: 'beginner',
      shortcodes: ['beginner'],
      tags: [
        'beginner',
        'chevron',
        'japanese',
        'japanese symbol for beginner',
        'leaf',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f530.png',
    },
    {
      emoji: '⭕',
      name: 'o',
      shortcodes: ['hollow_red_circle', 'red_o'],
      tags: ['circle', 'large', 'o', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b55.png',
    },
    {
      emoji: '✅',
      name: 'white_check_mark',
      shortcodes: ['check_mark_button', 'white_check_mark'],
      tags: ['button', 'check', 'mark', '✓'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2705.png',
    },
    {
      emoji: '☑',
      name: 'ballot_box_with_check',
      shortcodes: ['ballot_box_with_check'],
      tags: ['box', 'check', '✓'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2611-fe0f.png',
    },
    {
      emoji: '✔',
      name: 'heavy_check_mark',
      shortcodes: ['check_mark', 'heavy_check_mark'],
      tags: ['check', 'mark', '✓'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2714-fe0f.png',
    },
    {
      emoji: '❌',
      name: 'x',
      shortcodes: ['cross_mark', 'x'],
      tags: ['cancel', 'cross', 'mark', 'multiplication', 'multiply', 'x', '×'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/274c.png',
    },
    {
      emoji: '❎',
      name: 'negative_squared_cross_mark',
      shortcodes: ['cross_mark_button', 'negative_squared_cross_mark'],
      tags: ['mark', 'square', 'x', '×'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/274e.png',
    },
    {
      emoji: '➰',
      name: 'curly_loop',
      shortcodes: ['curly_loop'],
      tags: ['curl', 'loop'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/27b0.png',
    },
    {
      emoji: '➿',
      name: 'loop',
      shortcodes: ['double_curly_loop', 'loop'],
      tags: ['curl', 'double', 'loop'],
      group: 'symbols',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/27bf.png',
    },
    {
      emoji: '〽',
      name: 'part_alternation_mark',
      shortcodes: ['part_alternation_mark'],
      tags: ['mark', 'part'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/303d-fe0f.png',
    },
    {
      emoji: '✳',
      name: 'eight_spoked_asterisk',
      shortcodes: ['eight_spoked_asterisk'],
      tags: ['*', 'asterisk'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2733-fe0f.png',
    },
    {
      emoji: '✴',
      name: 'eight_pointed_black_star',
      shortcodes: ['eight_pointed_black_star'],
      tags: ['*', 'star'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2734-fe0f.png',
    },
    {
      emoji: '❇',
      name: 'sparkle',
      shortcodes: ['sparkle'],
      tags: ['*'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2747-fe0f.png',
    },
    {
      emoji: '©',
      name: 'copyright',
      shortcodes: ['copyright'],
      tags: ['c'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/00a9-fe0f.png',
    },
    {
      emoji: '®',
      name: 'registered',
      shortcodes: ['registered'],
      tags: ['r'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/00ae-fe0f.png',
    },
    {
      emoji: '™',
      name: 'tm',
      shortcodes: ['tm', 'trade_mark'],
      tags: ['mark', 'tm', 'trademark'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2122-fe0f.png',
    },
    {
      emoji: '#⃣',
      name: 'hash',
      shortcodes: ['hash', 'number_sign'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0023-fe0f-20e3.png',
    },
    {
      emoji: '*⃣',
      name: 'asterisk',
      shortcodes: ['asterisk'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/002a-fe0f-20e3.png',
    },
    {
      emoji: '0⃣',
      name: 'zero',
      shortcodes: ['zero'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0030-fe0f-20e3.png',
    },
    {
      emoji: '1⃣',
      name: 'one',
      shortcodes: ['one'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0031-fe0f-20e3.png',
    },
    {
      emoji: '2⃣',
      name: 'two',
      shortcodes: ['two'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0032-fe0f-20e3.png',
    },
    {
      emoji: '3⃣',
      name: 'three',
      shortcodes: ['three'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0033-fe0f-20e3.png',
    },
    {
      emoji: '4⃣',
      name: 'four',
      shortcodes: ['four'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0034-fe0f-20e3.png',
    },
    {
      emoji: '5⃣',
      name: 'five',
      shortcodes: ['five'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0035-fe0f-20e3.png',
    },
    {
      emoji: '6⃣',
      name: 'six',
      shortcodes: ['six'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0036-fe0f-20e3.png',
    },
    {
      emoji: '7⃣',
      name: 'seven',
      shortcodes: ['seven'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0037-fe0f-20e3.png',
    },
    {
      emoji: '8⃣',
      name: 'eight',
      shortcodes: ['eight'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0038-fe0f-20e3.png',
    },
    {
      emoji: '9⃣',
      name: 'nine',
      shortcodes: ['nine'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/0039-fe0f-20e3.png',
    },
    {
      emoji: '🔟',
      name: 'keycap_ten',
      shortcodes: ['ten'],
      tags: ['keycap'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f51f.png',
    },
    {
      emoji: '🔠',
      name: 'capital_abcd',
      shortcodes: ['capital_abcd'],
      tags: ['abcd', 'input', 'latin', 'letters', 'uppercase'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f520.png',
    },
    {
      emoji: '🔡',
      name: 'abcd',
      shortcodes: ['abcd'],
      tags: ['abcd', 'input', 'latin', 'letters', 'lowercase'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f521.png',
    },
    {
      emoji: '🔢',
      name: '1234',
      shortcodes: ['1234'],
      tags: ['1234', 'input', 'numbers'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f522.png',
    },
    {
      emoji: '🔣',
      name: 'symbols',
      shortcodes: ['symbols'],
      tags: ['input', '〒♪&%'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f523.png',
    },
    {
      emoji: '🔤',
      name: 'abc',
      shortcodes: ['abc'],
      tags: ['abc', 'alphabet', 'input', 'latin', 'letters'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f524.png',
    },
    {
      emoji: '🅰',
      name: 'a',
      shortcodes: ['a', 'a_blood'],
      tags: ['a', 'a button (blood type)', 'blood type'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f170-fe0f.png',
    },
    {
      emoji: '🆎',
      name: 'ab',
      shortcodes: ['ab', 'ab_blood'],
      tags: ['ab', 'ab button (blood type)', 'blood type'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f18e.png',
    },
    {
      emoji: '🅱',
      name: 'b',
      shortcodes: ['b', 'b_blood'],
      tags: ['b', 'b button (blood type)', 'blood type'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f171-fe0f.png',
    },
    {
      emoji: '🆑',
      name: 'cl',
      shortcodes: ['cl'],
      tags: ['cl', 'cl button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f191.png',
    },
    {
      emoji: '🆒',
      name: 'cool',
      shortcodes: ['cool'],
      tags: ['cool', 'cool button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f192.png',
    },
    {
      emoji: '🆓',
      name: 'free',
      shortcodes: ['free'],
      tags: ['free', 'free button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f193.png',
    },
    {
      emoji: 'ℹ',
      name: 'information_source',
      shortcodes: ['info', 'information_source'],
      tags: ['i'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2139-fe0f.png',
    },
    {
      emoji: '🆔',
      name: 'id',
      shortcodes: ['id'],
      tags: ['id', 'id button', 'identity'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f194.png',
    },
    {
      emoji: 'Ⓜ',
      name: 'm',
      shortcodes: ['m'],
      tags: ['circle', 'circled m', 'm'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/24c2-fe0f.png',
    },
    {
      emoji: '🆕',
      name: 'new',
      shortcodes: ['new'],
      tags: ['new', 'new button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f195.png',
    },
    {
      emoji: '🆖',
      name: 'ng',
      shortcodes: ['ng'],
      tags: ['ng', 'ng button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f196.png',
    },
    {
      emoji: '🅾',
      name: 'o2',
      shortcodes: ['o', 'o_blood'],
      tags: ['blood type', 'o', 'o button (blood type)'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f17e-fe0f.png',
    },
    {
      emoji: '🆗',
      name: 'ok',
      shortcodes: ['ok'],
      tags: ['ok', 'ok button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f197.png',
    },
    {
      emoji: '🅿',
      name: 'parking',
      shortcodes: ['parking'],
      tags: ['p', 'p button', 'parking'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f17f-fe0f.png',
    },
    {
      emoji: '🆘',
      name: 'sos',
      shortcodes: ['sos'],
      tags: ['help', 'sos', 'sos button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f198.png',
    },
    {
      emoji: '🆙',
      name: 'up',
      shortcodes: ['up2'],
      tags: ['mark', 'up', 'up!', 'up! button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f199.png',
    },
    {
      emoji: '🆚',
      name: 'vs',
      shortcodes: ['vs'],
      tags: ['versus', 'vs', 'vs button'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f19a.png',
    },
    {
      emoji: '🈁',
      name: 'koko',
      shortcodes: ['ja_here', 'koko'],
      tags: [
        'japanese',
        'japanese “here” button',
        'katakana',
        '“here”',
        'ココ',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f201.png',
    },
    {
      emoji: '🈂',
      name: 'sa',
      shortcodes: ['ja_service_charge'],
      tags: [
        'japanese',
        'japanese “service charge” button',
        'katakana',
        '“service charge”',
        'サ',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f202-fe0f.png',
    },
    {
      emoji: '🈷',
      name: 'u6708',
      shortcodes: ['ja_monthly_amount'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “monthly amount” button',
        '“monthly amount”',
        '月',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f237-fe0f.png',
    },
    {
      emoji: '🈶',
      name: 'u6709',
      shortcodes: ['ja_not_free_of_carge'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “not free of charge” button',
        '“not free of charge”',
        '有',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f236.png',
    },
    {
      emoji: '🈯',
      name: 'u6307',
      shortcodes: ['ja_reserved'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “reserved” button',
        '“reserved”',
        '指',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f22f.png',
    },
    {
      emoji: '🉐',
      name: 'ideograph_advantage',
      shortcodes: ['ideograph_advantage', 'ja_bargain'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “bargain” button',
        '“bargain”',
        '得',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f250.png',
    },
    {
      emoji: '🈹',
      name: 'u5272',
      shortcodes: ['ja_discount'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “discount” button',
        '“discount”',
        '割',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f239.png',
    },
    {
      emoji: '🈚',
      name: 'u7121',
      shortcodes: ['ja_free_of_charge'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “free of charge” button',
        '“free of charge”',
        '無',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f21a.png',
    },
    {
      emoji: '🈲',
      name: 'u7981',
      shortcodes: ['ja_prohibited'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “prohibited” button',
        '“prohibited”',
        '禁',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f232.png',
    },
    {
      emoji: '🉑',
      name: 'accept',
      shortcodes: ['accept', 'ja_acceptable'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “acceptable” button',
        '“acceptable”',
        '可',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f251.png',
    },
    {
      emoji: '🈸',
      name: 'u7533',
      shortcodes: ['ja_application'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “application” button',
        '“application”',
        '申',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f238.png',
    },
    {
      emoji: '🈴',
      name: 'u5408',
      shortcodes: ['ja_passing_grade'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “passing grade” button',
        '“passing grade”',
        '合',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f234.png',
    },
    {
      emoji: '🈳',
      name: 'u7a7a',
      shortcodes: ['ja_vacancy'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “vacancy” button',
        '“vacancy”',
        '空',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f233.png',
    },
    {
      emoji: '㊗',
      name: 'congratulations',
      shortcodes: ['congratulations', 'ja_congratulations'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “congratulations” button',
        '“congratulations”',
        '祝',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/3297-fe0f.png',
    },
    {
      emoji: '㊙',
      name: 'secret',
      shortcodes: ['ja_secret', 'secret'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “secret” button',
        '“secret”',
        '秘',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/3299-fe0f.png',
    },
    {
      emoji: '🈺',
      name: 'u55b6',
      shortcodes: ['ja_open_for_business'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “open for business” button',
        '“open for business”',
        '営',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f23a.png',
    },
    {
      emoji: '🈵',
      name: 'u6e80',
      shortcodes: ['ja_no_vacancy'],
      tags: [
        'ideograph',
        'japanese',
        'japanese “no vacancy” button',
        '“no vacancy”',
        '満',
      ],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f235.png',
    },
    {
      emoji: '🔴',
      name: 'red_circle',
      shortcodes: ['red_circle'],
      tags: ['circle', 'geometric', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f534.png',
    },
    {
      emoji: '🟠',
      name: 'orange_circle',
      shortcodes: ['orange_circle'],
      tags: ['circle', 'orange'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e0.png',
    },
    {
      emoji: '🟡',
      name: 'yellow_circle',
      shortcodes: ['yellow_circle'],
      tags: ['circle', 'yellow'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e1.png',
    },
    {
      emoji: '🟢',
      name: 'green_circle',
      shortcodes: ['green_circle'],
      tags: ['circle', 'green'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e2.png',
    },
    {
      emoji: '🔵',
      name: 'large_blue_circle',
      shortcodes: ['blue_circle'],
      tags: ['blue', 'circle', 'geometric'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f535.png',
    },
    {
      emoji: '🟣',
      name: 'purple_circle',
      shortcodes: ['purple_circle'],
      tags: ['circle', 'purple'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e3.png',
    },
    {
      emoji: '🟤',
      name: 'brown_circle',
      shortcodes: ['brown_circle'],
      tags: ['brown', 'circle'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e4.png',
    },
    {
      emoji: '⚫',
      name: 'black_circle',
      shortcodes: ['black_circle'],
      tags: ['circle', 'geometric'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26ab.png',
    },
    {
      emoji: '⚪',
      name: 'white_circle',
      shortcodes: ['white_circle'],
      tags: ['circle', 'geometric'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/26aa.png',
    },
    {
      emoji: '🟥',
      name: 'red_square',
      shortcodes: ['red_square'],
      tags: ['red', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e5.png',
    },
    {
      emoji: '🟧',
      name: 'orange_square',
      shortcodes: ['orange_square'],
      tags: ['orange', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e7.png',
    },
    {
      emoji: '🟨',
      name: 'yellow_square',
      shortcodes: ['yellow_square'],
      tags: ['square', 'yellow'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e8.png',
    },
    {
      emoji: '🟩',
      name: 'green_square',
      shortcodes: ['green_square'],
      tags: ['green', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e9.png',
    },
    {
      emoji: '🟦',
      name: 'blue_square',
      shortcodes: ['blue_square'],
      tags: ['blue', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7e6.png',
    },
    {
      emoji: '🟪',
      name: 'purple_square',
      shortcodes: ['purple_square'],
      tags: ['purple', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7ea.png',
    },
    {
      emoji: '🟫',
      name: 'brown_square',
      shortcodes: ['brown_square'],
      tags: ['brown', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 12,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f7eb.png',
    },
    {
      emoji: '⬛',
      name: 'black_large_square',
      shortcodes: ['black_large_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b1b.png',
    },
    {
      emoji: '⬜',
      name: 'white_large_square',
      shortcodes: ['white_large_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2b1c.png',
    },
    {
      emoji: '◼',
      name: 'black_medium_square',
      shortcodes: ['black_medium_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25fc-fe0f.png',
    },
    {
      emoji: '◻',
      name: 'white_medium_square',
      shortcodes: ['white_medium_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25fb-fe0f.png',
    },
    {
      emoji: '◾',
      name: 'black_medium_small_square',
      shortcodes: ['black_medium_small_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25fe.png',
    },
    {
      emoji: '◽',
      name: 'white_medium_small_square',
      shortcodes: ['white_medium_small_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25fd.png',
    },
    {
      emoji: '▪',
      name: 'black_small_square',
      shortcodes: ['black_small_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25aa-fe0f.png',
    },
    {
      emoji: '▫',
      name: 'white_small_square',
      shortcodes: ['white_small_square'],
      tags: ['geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/25ab-fe0f.png',
    },
    {
      emoji: '🔶',
      name: 'large_orange_diamond',
      shortcodes: ['large_orange_diamond'],
      tags: ['diamond', 'geometric', 'orange'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f536.png',
    },
    {
      emoji: '🔷',
      name: 'large_blue_diamond',
      shortcodes: ['large_blue_diamond'],
      tags: ['blue', 'diamond', 'geometric'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f537.png',
    },
    {
      emoji: '🔸',
      name: 'small_orange_diamond',
      shortcodes: ['small_orange_diamond'],
      tags: ['diamond', 'geometric', 'orange'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f538.png',
    },
    {
      emoji: '🔹',
      name: 'small_blue_diamond',
      shortcodes: ['small_blue_diamond'],
      tags: ['blue', 'diamond', 'geometric'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f539.png',
    },
    {
      emoji: '🔺',
      name: 'small_red_triangle',
      shortcodes: ['small_red_triangle'],
      tags: ['geometric', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f53a.png',
    },
    {
      emoji: '🔻',
      name: 'small_red_triangle_down',
      shortcodes: ['small_red_triangle_down'],
      tags: ['down', 'geometric', 'red'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f53b.png',
    },
    {
      emoji: '💠',
      name: 'diamond_shape_with_a_dot_inside',
      shortcodes: ['diamond_shape_with_a_dot_inside', 'diamond_with_a_dot'],
      tags: ['comic', 'diamond', 'geometric', 'inside'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f4a0.png',
    },
    {
      emoji: '🔘',
      name: 'radio_button',
      shortcodes: ['radio_button'],
      tags: ['button', 'geometric', 'radio'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f518.png',
    },
    {
      emoji: '🔳',
      name: 'white_square_button',
      shortcodes: ['white_square_button'],
      tags: ['button', 'geometric', 'outlined', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f533.png',
    },
    {
      emoji: '🔲',
      name: 'black_square_button',
      shortcodes: ['black_square_button'],
      tags: ['button', 'geometric', 'square'],
      group: 'symbols',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f532.png',
    },
    {
      emoji: '🏁',
      name: 'checkered_flag',
      shortcodes: ['checkered_flag'],
      tags: ['checkered', 'chequered', 'racing'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3c1.png',
    },
    {
      emoji: '🚩',
      name: 'triangular_flag_on_post',
      shortcodes: ['triangular_flag', 'triangular_flag_on_post'],
      tags: ['post'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f6a9.png',
    },
    {
      emoji: '🎌',
      name: 'crossed_flags',
      shortcodes: ['crossed_flags'],
      tags: ['celebration', 'cross', 'crossed', 'japanese'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f38c.png',
    },
    {
      emoji: '🏴',
      name: 'black_flag',
      shortcodes: ['black_flag'],
      tags: ['waving'],
      group: 'flags',
      emoticons: [],
      version: 1,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f4.png',
    },
    {
      emoji: '🏳',
      name: 'white_flag',
      shortcodes: ['white_flag'],
      tags: ['waving'],
      group: 'flags',
      emoticons: [],
      version: 0.7,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f3-fe0f.png',
    },
    {
      emoji: '🏳‍🌈',
      name: 'rainbow_flag',
      shortcodes: ['rainbow_flag'],
      tags: ['pride', 'rainbow'],
      group: 'flags',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f3-fe0f-200d-1f308.png',
    },
    {
      emoji: '🏳‍⚧️',
      name: 'transgender_flag',
      shortcodes: ['transgender_flag'],
      tags: ['flag', 'light blue', 'pink', 'transgender', 'white'],
      group: 'flags',
      emoticons: [],
      version: 13,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f3-fe0f-200d-26a7-fe0f.png',
    },
    {
      emoji: '🏴‍☠',
      name: 'pirate_flag',
      shortcodes: ['jolly_roger', 'pirate_flag'],
      tags: ['jolly roger', 'pirate', 'plunder', 'treasure'],
      group: 'flags',
      emoticons: [],
      version: 11,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f4-200d-2620-fe0f.png',
    },
    {
      emoji: '🇦🇨',
      name: 'ascension_island',
      shortcodes: ['ascension_island', 'flag_ac'],
      tags: ['AC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1e8.png',
    },
    {
      emoji: '🇦🇩',
      name: 'andorra',
      shortcodes: ['andorra', 'flag_ad'],
      tags: ['AD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1e9.png',
    },
    {
      emoji: '🇦🇪',
      name: 'united_arab_emirates',
      shortcodes: ['flag_ae', 'united_arab_emirates'],
      tags: ['AE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1ea.png',
    },
    {
      emoji: '🇦🇫',
      name: 'afghanistan',
      shortcodes: ['afghanistan', 'flag_af'],
      tags: ['AF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1eb.png',
    },
    {
      emoji: '🇦🇬',
      name: 'antigua_barbuda',
      shortcodes: ['antigua_barbuda', 'flag_ag'],
      tags: ['AG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1ec.png',
    },
    {
      emoji: '🇦🇮',
      name: 'anguilla',
      shortcodes: ['anguilla', 'flag_ai'],
      tags: ['AI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1ee.png',
    },
    {
      emoji: '🇦🇱',
      name: 'albania',
      shortcodes: ['albania', 'flag_al'],
      tags: ['AL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f1.png',
    },
    {
      emoji: '🇦🇲',
      name: 'armenia',
      shortcodes: ['armenia', 'flag_am'],
      tags: ['AM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f2.png',
    },
    {
      emoji: '🇦🇴',
      name: 'angola',
      shortcodes: ['angola', 'flag_ao'],
      tags: ['AO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f4.png',
    },
    {
      emoji: '🇦🇶',
      name: 'antarctica',
      shortcodes: ['antarctica', 'flag_aq'],
      tags: ['AQ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f6.png',
    },
    {
      emoji: '🇦🇷',
      name: 'argentina',
      shortcodes: ['argentina', 'flag_ar'],
      tags: ['AR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f7.png',
    },
    {
      emoji: '🇦🇸',
      name: 'american_samoa',
      shortcodes: ['american_samoa', 'flag_as'],
      tags: ['AS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f8.png',
    },
    {
      emoji: '🇦🇹',
      name: 'austria',
      shortcodes: ['austria', 'flag_at'],
      tags: ['AT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1f9.png',
    },
    {
      emoji: '🇦🇺',
      name: 'australia',
      shortcodes: ['australia', 'flag_au'],
      tags: ['AU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1fa.png',
    },
    {
      emoji: '🇦🇼',
      name: 'aruba',
      shortcodes: ['aruba', 'flag_aw'],
      tags: ['AW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1fc.png',
    },
    {
      emoji: '🇦🇽',
      name: 'aland_islands',
      shortcodes: ['aland_islands', 'flag_ax'],
      tags: ['AX', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1fd.png',
    },
    {
      emoji: '🇦🇿',
      name: 'azerbaijan',
      shortcodes: ['azerbaijan', 'flag_az'],
      tags: ['AZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e6-1f1ff.png',
    },
    {
      emoji: '🇧🇦',
      name: 'bosnia_herzegovina',
      shortcodes: ['bosnia_herzegovina', 'flag_ba'],
      tags: ['BA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1e6.png',
    },
    {
      emoji: '🇧🇧',
      name: 'barbados',
      shortcodes: ['barbados', 'flag_bb'],
      tags: ['BB', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1e7.png',
    },
    {
      emoji: '🇧🇩',
      name: 'bangladesh',
      shortcodes: ['bangladesh', 'flag_bd'],
      tags: ['BD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1e9.png',
    },
    {
      emoji: '🇧🇪',
      name: 'belgium',
      shortcodes: ['belgium', 'flag_be'],
      tags: ['BE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ea.png',
    },
    {
      emoji: '🇧🇫',
      name: 'burkina_faso',
      shortcodes: ['burkina_faso', 'flag_bf'],
      tags: ['BF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1eb.png',
    },
    {
      emoji: '🇧🇬',
      name: 'bulgaria',
      shortcodes: ['bulgaria', 'flag_bg'],
      tags: ['BG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ec.png',
    },
    {
      emoji: '🇧🇭',
      name: 'bahrain',
      shortcodes: ['bahrain', 'flag_bh'],
      tags: ['BH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ed.png',
    },
    {
      emoji: '🇧🇮',
      name: 'burundi',
      shortcodes: ['burundi', 'flag_bi'],
      tags: ['BI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ee.png',
    },
    {
      emoji: '🇧🇯',
      name: 'benin',
      shortcodes: ['benin', 'flag_bj'],
      tags: ['BJ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ef.png',
    },
    {
      emoji: '🇧🇱',
      name: 'st_barthelemy',
      shortcodes: ['flag_bl', 'st_barthelemy'],
      tags: ['BL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f1.png',
    },
    {
      emoji: '🇧🇲',
      name: 'bermuda',
      shortcodes: ['bermuda', 'flag_bm'],
      tags: ['BM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f2.png',
    },
    {
      emoji: '🇧🇳',
      name: 'brunei',
      shortcodes: ['brunei', 'flag_bn'],
      tags: ['BN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f3.png',
    },
    {
      emoji: '🇧🇴',
      name: 'bolivia',
      shortcodes: ['bolivia', 'flag_bo'],
      tags: ['BO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f4.png',
    },
    {
      emoji: '🇧🇶',
      name: 'caribbean_netherlands',
      shortcodes: ['caribbean_netherlands', 'flag_bq'],
      tags: ['BQ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f6.png',
    },
    {
      emoji: '🇧🇷',
      name: 'brazil',
      shortcodes: ['brazil', 'flag_br'],
      tags: ['BR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f7.png',
    },
    {
      emoji: '🇧🇸',
      name: 'bahamas',
      shortcodes: ['bahamas', 'flag_bs'],
      tags: ['BS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f8.png',
    },
    {
      emoji: '🇧🇹',
      name: 'bhutan',
      shortcodes: ['bhutan', 'flag_bt'],
      tags: ['BT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1f9.png',
    },
    {
      emoji: '🇧🇻',
      name: 'bouvet_island',
      shortcodes: ['bouvet_island', 'flag_bv'],
      tags: ['BV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1fb.png',
    },
    {
      emoji: '🇧🇼',
      name: 'botswana',
      shortcodes: ['botswana', 'flag_bw'],
      tags: ['BW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1fc.png',
    },
    {
      emoji: '🇧🇾',
      name: 'belarus',
      shortcodes: ['belarus', 'flag_by'],
      tags: ['BY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1fe.png',
    },
    {
      emoji: '🇧🇿',
      name: 'belize',
      shortcodes: ['belize', 'flag_bz'],
      tags: ['BZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e7-1f1ff.png',
    },
    {
      emoji: '🇨🇦',
      name: 'canada',
      shortcodes: ['canada', 'flag_ca'],
      tags: ['CA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1e6.png',
    },
    {
      emoji: '🇨🇨',
      name: 'cocos_islands',
      shortcodes: ['cocos_islands', 'flag_cc'],
      tags: ['CC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1e8.png',
    },
    {
      emoji: '🇨🇩',
      name: 'congo_kinshasa',
      shortcodes: ['congo_kinshasa', 'flag_cd'],
      tags: ['CD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1e9.png',
    },
    {
      emoji: '🇨🇫',
      name: 'central_african_republic',
      shortcodes: ['central_african_republic', 'flag_cf'],
      tags: ['CF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1eb.png',
    },
    {
      emoji: '🇨🇬',
      name: 'congo_brazzaville',
      shortcodes: ['congo_brazzaville', 'flag_cg'],
      tags: ['CG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1ec.png',
    },
    {
      emoji: '🇨🇭',
      name: 'switzerland',
      shortcodes: ['flag_ch', 'switzerland'],
      tags: ['CH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1ed.png',
    },
    {
      emoji: '🇨🇮',
      name: 'cote_divoire',
      shortcodes: ['cote_divoire', 'flag_ci'],
      tags: ['CI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1ee.png',
    },
    {
      emoji: '🇨🇰',
      name: 'cook_islands',
      shortcodes: ['cook_islands', 'flag_ck'],
      tags: ['CK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f0.png',
    },
    {
      emoji: '🇨🇱',
      name: 'chile',
      shortcodes: ['chile', 'flag_cl'],
      tags: ['CL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f1.png',
    },
    {
      emoji: '🇨🇲',
      name: 'cameroon',
      shortcodes: ['cameroon', 'flag_cm'],
      tags: ['CM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f2.png',
    },
    {
      emoji: '🇨🇳',
      name: 'cn',
      shortcodes: ['china', 'flag_cn'],
      tags: ['CN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f3.png',
    },
    {
      emoji: '🇨🇴',
      name: 'colombia',
      shortcodes: ['colombia', 'flag_co'],
      tags: ['CO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f4.png',
    },
    {
      emoji: '🇨🇵',
      name: 'clipperton_island',
      shortcodes: ['clipperton_island', 'flag_cp'],
      tags: ['CP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f5.png',
    },
    {
      emoji: '🇨🇷',
      name: 'costa_rica',
      shortcodes: ['costa_rica', 'flag_cr'],
      tags: ['CR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1f7.png',
    },
    {
      emoji: '🇨🇺',
      name: 'cuba',
      shortcodes: ['cuba', 'flag_cu'],
      tags: ['CU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1fa.png',
    },
    {
      emoji: '🇨🇻',
      name: 'cape_verde',
      shortcodes: ['cape_verde', 'flag_cv'],
      tags: ['CV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1fb.png',
    },
    {
      emoji: '🇨🇼',
      name: 'curacao',
      shortcodes: ['curacao', 'flag_cw'],
      tags: ['CW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1fc.png',
    },
    {
      emoji: '🇨🇽',
      name: 'christmas_island',
      shortcodes: ['christmas_island', 'flag_cx'],
      tags: ['CX', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1fd.png',
    },
    {
      emoji: '🇨🇾',
      name: 'cyprus',
      shortcodes: ['cyprus', 'flag_cy'],
      tags: ['CY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1fe.png',
    },
    {
      emoji: '🇨🇿',
      name: 'czech_republic',
      shortcodes: ['czech_republic', 'czechia', 'flag_cz'],
      tags: ['CZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e8-1f1ff.png',
    },
    {
      emoji: '🇩🇪',
      name: 'de',
      shortcodes: ['flag_de', 'germany'],
      tags: ['DE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1ea.png',
    },
    {
      emoji: '🇩🇬',
      name: 'diego_garcia',
      shortcodes: ['diego_garcia', 'flag_dg'],
      tags: ['DG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1ec.png',
    },
    {
      emoji: '🇩🇯',
      name: 'djibouti',
      shortcodes: ['djibouti', 'flag_dj'],
      tags: ['DJ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1ef.png',
    },
    {
      emoji: '🇩🇰',
      name: 'denmark',
      shortcodes: ['denmark', 'flag_dk'],
      tags: ['DK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1f0.png',
    },
    {
      emoji: '🇩🇲',
      name: 'dominica',
      shortcodes: ['dominica', 'flag_dm'],
      tags: ['DM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1f2.png',
    },
    {
      emoji: '🇩🇴',
      name: 'dominican_republic',
      shortcodes: ['dominican_republic', 'flag_do'],
      tags: ['DO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1f4.png',
    },
    {
      emoji: '🇩🇿',
      name: 'algeria',
      shortcodes: ['algeria', 'flag_dz'],
      tags: ['DZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1e9-1f1ff.png',
    },
    {
      emoji: '🇪🇦',
      name: 'ceuta_melilla',
      shortcodes: ['ceuta_melilla', 'flag_ea'],
      tags: ['EA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1e6.png',
    },
    {
      emoji: '🇪🇨',
      name: 'ecuador',
      shortcodes: ['ecuador', 'flag_ec'],
      tags: ['EC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1e8.png',
    },
    {
      emoji: '🇪🇪',
      name: 'estonia',
      shortcodes: ['estonia', 'flag_ee'],
      tags: ['EE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1ea.png',
    },
    {
      emoji: '🇪🇬',
      name: 'egypt',
      shortcodes: ['egypt', 'flag_eg'],
      tags: ['EG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1ec.png',
    },
    {
      emoji: '🇪🇭',
      name: 'western_sahara',
      shortcodes: ['flag_eh', 'western_sahara'],
      tags: ['EH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1ed.png',
    },
    {
      emoji: '🇪🇷',
      name: 'eritrea',
      shortcodes: ['eritrea', 'flag_er'],
      tags: ['ER', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1f7.png',
    },
    {
      emoji: '🇪🇸',
      name: 'es',
      shortcodes: ['flag_es', 'spain'],
      tags: ['ES', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1f8.png',
    },
    {
      emoji: '🇪🇹',
      name: 'ethiopia',
      shortcodes: ['ethiopia', 'flag_et'],
      tags: ['ET', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1f9.png',
    },
    {
      emoji: '🇪🇺',
      name: 'eu',
      shortcodes: ['european_union', 'flag_eu'],
      tags: ['EU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1fa.png',
    },
    {
      emoji: '🇫🇮',
      name: 'finland',
      shortcodes: ['finland', 'flag_fi'],
      tags: ['FI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1ee.png',
    },
    {
      emoji: '🇫🇯',
      name: 'fiji',
      shortcodes: ['fiji', 'flag_fj'],
      tags: ['FJ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1ef.png',
    },
    {
      emoji: '🇫🇰',
      name: 'falkland_islands',
      shortcodes: ['falkland_islands', 'flag_fk'],
      tags: ['FK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1f0.png',
    },
    {
      emoji: '🇫🇲',
      name: 'micronesia',
      shortcodes: ['flag_fm', 'micronesia'],
      tags: ['FM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1f2.png',
    },
    {
      emoji: '🇫🇴',
      name: 'faroe_islands',
      shortcodes: ['faroe_islands', 'flag_fo'],
      tags: ['FO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1f4.png',
    },
    {
      emoji: '🇫🇷',
      name: 'fr',
      shortcodes: ['flag_fr', 'france'],
      tags: ['FR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1eb-1f1f7.png',
    },
    {
      emoji: '🇬🇦',
      name: 'gabon',
      shortcodes: ['flag_ga', 'gabon'],
      tags: ['GA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1e6.png',
    },
    {
      emoji: '🇬🇧',
      name: 'gb',
      shortcodes: ['flag_gb', 'uk', 'united_kingdom'],
      tags: ['GB', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1e7.png',
    },
    {
      emoji: '🇬🇩',
      name: 'grenada',
      shortcodes: ['flag_gd', 'grenada'],
      tags: ['GD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1e9.png',
    },
    {
      emoji: '🇬🇪',
      name: 'georgia',
      shortcodes: ['flag_ge', 'georgia'],
      tags: ['GE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1ea.png',
    },
    {
      emoji: '🇬🇫',
      name: 'french_guiana',
      shortcodes: ['flag_gf', 'french_guiana'],
      tags: ['GF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1eb.png',
    },
    {
      emoji: '🇬🇬',
      name: 'guernsey',
      shortcodes: ['flag_gg', 'guernsey'],
      tags: ['GG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1ec.png',
    },
    {
      emoji: '🇬🇭',
      name: 'ghana',
      shortcodes: ['flag_gh', 'ghana'],
      tags: ['GH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1ed.png',
    },
    {
      emoji: '🇬🇮',
      name: 'gibraltar',
      shortcodes: ['flag_gi', 'gibraltar'],
      tags: ['GI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1ee.png',
    },
    {
      emoji: '🇬🇱',
      name: 'greenland',
      shortcodes: ['flag_gl', 'greenland'],
      tags: ['GL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f1.png',
    },
    {
      emoji: '🇬🇲',
      name: 'gambia',
      shortcodes: ['flag_gm', 'gambia'],
      tags: ['GM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f2.png',
    },
    {
      emoji: '🇬🇳',
      name: 'guinea',
      shortcodes: ['flag_gn', 'guinea'],
      tags: ['GN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f3.png',
    },
    {
      emoji: '🇬🇵',
      name: 'guadeloupe',
      shortcodes: ['flag_gp', 'guadeloupe'],
      tags: ['GP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f5.png',
    },
    {
      emoji: '🇬🇶',
      name: 'equatorial_guinea',
      shortcodes: ['equatorial_guinea', 'flag_gq'],
      tags: ['GQ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f6.png',
    },
    {
      emoji: '🇬🇷',
      name: 'greece',
      shortcodes: ['flag_gr', 'greece'],
      tags: ['GR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f7.png',
    },
    {
      emoji: '🇬🇸',
      name: 'south_georgia_south_sandwich_islands',
      shortcodes: ['flag_gs', 'south_georgia_south_sandwich_islands'],
      tags: ['GS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f8.png',
    },
    {
      emoji: '🇬🇹',
      name: 'guatemala',
      shortcodes: ['flag_gt', 'guatemala'],
      tags: ['GT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1f9.png',
    },
    {
      emoji: '🇬🇺',
      name: 'guam',
      shortcodes: ['flag_gu', 'guam'],
      tags: ['GU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1fa.png',
    },
    {
      emoji: '🇬🇼',
      name: 'guinea_bissau',
      shortcodes: ['flag_gw', 'guinea_bissau'],
      tags: ['GW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1fc.png',
    },
    {
      emoji: '🇬🇾',
      name: 'guyana',
      shortcodes: ['flag_gy', 'guyana'],
      tags: ['GY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ec-1f1fe.png',
    },
    {
      emoji: '🇭🇰',
      name: 'hong_kong',
      shortcodes: ['flag_hk', 'hong_kong'],
      tags: ['HK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1f0.png',
    },
    {
      emoji: '🇭🇲',
      name: 'heard_mcdonald_islands',
      shortcodes: ['flag_hm', 'heard_mcdonald_islands'],
      tags: ['HM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1f2.png',
    },
    {
      emoji: '🇭🇳',
      name: 'honduras',
      shortcodes: ['flag_hn', 'honduras'],
      tags: ['HN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1f3.png',
    },
    {
      emoji: '🇭🇷',
      name: 'croatia',
      shortcodes: ['croatia', 'flag_hr'],
      tags: ['HR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1f7.png',
    },
    {
      emoji: '🇭🇹',
      name: 'haiti',
      shortcodes: ['flag_ht', 'haiti'],
      tags: ['HT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1f9.png',
    },
    {
      emoji: '🇭🇺',
      name: 'hungary',
      shortcodes: ['flag_hu', 'hungary'],
      tags: ['HU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ed-1f1fa.png',
    },
    {
      emoji: '🇮🇨',
      name: 'canary_islands',
      shortcodes: ['canary_islands', 'flag_ic'],
      tags: ['IC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1e8.png',
    },
    {
      emoji: '🇮🇩',
      name: 'indonesia',
      shortcodes: ['flag_id', 'indonesia'],
      tags: ['ID', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1e9.png',
    },
    {
      emoji: '🇮🇪',
      name: 'ireland',
      shortcodes: ['flag_ie', 'ireland'],
      tags: ['IE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1ea.png',
    },
    {
      emoji: '🇮🇱',
      name: 'israel',
      shortcodes: ['flag_il', 'israel'],
      tags: ['IL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f1.png',
    },
    {
      emoji: '🇮🇲',
      name: 'isle_of_man',
      shortcodes: ['flag_im', 'isle_of_man'],
      tags: ['IM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f2.png',
    },
    {
      emoji: '🇮🇳',
      name: 'india',
      shortcodes: ['flag_in', 'india'],
      tags: ['IN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f3.png',
    },
    {
      emoji: '🇮🇴',
      name: 'british_indian_ocean_territory',
      shortcodes: ['british_indian_ocean_territory', 'flag_io'],
      tags: ['IO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f4.png',
    },
    {
      emoji: '🇮🇶',
      name: 'iraq',
      shortcodes: ['flag_iq', 'iraq'],
      tags: ['IQ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f6.png',
    },
    {
      emoji: '🇮🇷',
      name: 'iran',
      shortcodes: ['flag_ir', 'iran'],
      tags: ['IR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f7.png',
    },
    {
      emoji: '🇮🇸',
      name: 'iceland',
      shortcodes: ['flag_is', 'iceland'],
      tags: ['IS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f8.png',
    },
    {
      emoji: '🇮🇹',
      name: 'it',
      shortcodes: ['flag_it', 'italy'],
      tags: ['IT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ee-1f1f9.png',
    },
    {
      emoji: '🇯🇪',
      name: 'jersey',
      shortcodes: ['flag_je', 'jersey'],
      tags: ['JE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ef-1f1ea.png',
    },
    {
      emoji: '🇯🇲',
      name: 'jamaica',
      shortcodes: ['flag_jm', 'jamaica'],
      tags: ['JM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ef-1f1f2.png',
    },
    {
      emoji: '🇯🇴',
      name: 'jordan',
      shortcodes: ['flag_jo', 'jordan'],
      tags: ['JO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ef-1f1f4.png',
    },
    {
      emoji: '🇯🇵',
      name: 'jp',
      shortcodes: ['flag_jp', 'japan'],
      tags: ['JP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ef-1f1f5.png',
    },
    {
      emoji: '🇰🇪',
      name: 'kenya',
      shortcodes: ['flag_ke', 'kenya'],
      tags: ['KE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1ea.png',
    },
    {
      emoji: '🇰🇬',
      name: 'kyrgyzstan',
      shortcodes: ['flag_kg', 'kyrgyzstan'],
      tags: ['KG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1ec.png',
    },
    {
      emoji: '🇰🇭',
      name: 'cambodia',
      shortcodes: ['cambodia', 'flag_kh'],
      tags: ['KH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1ed.png',
    },
    {
      emoji: '🇰🇮',
      name: 'kiribati',
      shortcodes: ['flag_ki', 'kiribati'],
      tags: ['KI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1ee.png',
    },
    {
      emoji: '🇰🇲',
      name: 'comoros',
      shortcodes: ['comoros', 'flag_km'],
      tags: ['KM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1f2.png',
    },
    {
      emoji: '🇰🇳',
      name: 'st_kitts_nevis',
      shortcodes: ['flag_kn', 'st_kitts_nevis'],
      tags: ['KN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1f3.png',
    },
    {
      emoji: '🇰🇵',
      name: 'north_korea',
      shortcodes: ['flag_kp', 'north_korea'],
      tags: ['KP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1f5.png',
    },
    {
      emoji: '🇰🇷',
      name: 'kr',
      shortcodes: ['flag_kr', 'south_korea'],
      tags: ['KR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1f7.png',
    },
    {
      emoji: '🇰🇼',
      name: 'kuwait',
      shortcodes: ['flag_kw', 'kuwait'],
      tags: ['KW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1fc.png',
    },
    {
      emoji: '🇰🇾',
      name: 'cayman_islands',
      shortcodes: ['cayman_islands', 'flag_ky'],
      tags: ['KY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1fe.png',
    },
    {
      emoji: '🇰🇿',
      name: 'kazakhstan',
      shortcodes: ['flag_kz', 'kazakhstan'],
      tags: ['KZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f0-1f1ff.png',
    },
    {
      emoji: '🇱🇦',
      name: 'laos',
      shortcodes: ['flag_la', 'laos'],
      tags: ['LA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1e6.png',
    },
    {
      emoji: '🇱🇧',
      name: 'lebanon',
      shortcodes: ['flag_lb', 'lebanon'],
      tags: ['LB', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1e7.png',
    },
    {
      emoji: '🇱🇨',
      name: 'st_lucia',
      shortcodes: ['flag_lc', 'st_lucia'],
      tags: ['LC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1e8.png',
    },
    {
      emoji: '🇱🇮',
      name: 'liechtenstein',
      shortcodes: ['flag_li', 'liechtenstein'],
      tags: ['LI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1ee.png',
    },
    {
      emoji: '🇱🇰',
      name: 'sri_lanka',
      shortcodes: ['flag_lk', 'sri_lanka'],
      tags: ['LK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1f0.png',
    },
    {
      emoji: '🇱🇷',
      name: 'liberia',
      shortcodes: ['flag_lr', 'liberia'],
      tags: ['LR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1f7.png',
    },
    {
      emoji: '🇱🇸',
      name: 'lesotho',
      shortcodes: ['flag_ls', 'lesotho'],
      tags: ['LS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1f8.png',
    },
    {
      emoji: '🇱🇹',
      name: 'lithuania',
      shortcodes: ['flag_lt', 'lithuania'],
      tags: ['LT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1f9.png',
    },
    {
      emoji: '🇱🇺',
      name: 'luxembourg',
      shortcodes: ['flag_lu', 'luxembourg'],
      tags: ['LU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1fa.png',
    },
    {
      emoji: '🇱🇻',
      name: 'latvia',
      shortcodes: ['flag_lv', 'latvia'],
      tags: ['LV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1fb.png',
    },
    {
      emoji: '🇱🇾',
      name: 'libya',
      shortcodes: ['flag_ly', 'libya'],
      tags: ['LY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f1-1f1fe.png',
    },
    {
      emoji: '🇲🇦',
      name: 'morocco',
      shortcodes: ['flag_ma', 'morocco'],
      tags: ['MA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1e6.png',
    },
    {
      emoji: '🇲🇨',
      name: 'monaco',
      shortcodes: ['flag_mc', 'monaco'],
      tags: ['MC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1e8.png',
    },
    {
      emoji: '🇲🇩',
      name: 'moldova',
      shortcodes: ['flag_md', 'moldova'],
      tags: ['MD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1e9.png',
    },
    {
      emoji: '🇲🇪',
      name: 'montenegro',
      shortcodes: ['flag_me', 'montenegro'],
      tags: ['ME', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1ea.png',
    },
    {
      emoji: '🇲🇫',
      name: 'st_martin',
      shortcodes: ['flag_mf', 'st_martin'],
      tags: ['MF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1eb.png',
    },
    {
      emoji: '🇲🇬',
      name: 'madagascar',
      shortcodes: ['flag_mg', 'madagascar'],
      tags: ['MG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1ec.png',
    },
    {
      emoji: '🇲🇭',
      name: 'marshall_islands',
      shortcodes: ['flag_mh', 'marshall_islands'],
      tags: ['MH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1ed.png',
    },
    {
      emoji: '🇲🇰',
      name: 'macedonia',
      shortcodes: ['flag_mk', 'macedonia'],
      tags: ['MK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f0.png',
    },
    {
      emoji: '🇲🇱',
      name: 'mali',
      shortcodes: ['flag_ml', 'mali'],
      tags: ['ML', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f1.png',
    },
    {
      emoji: '🇲🇲',
      name: 'myanmar',
      shortcodes: ['burma', 'flag_mm', 'myanmar'],
      tags: ['MM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f2.png',
    },
    {
      emoji: '🇲🇳',
      name: 'mongolia',
      shortcodes: ['flag_mn', 'mongolia'],
      tags: ['MN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f3.png',
    },
    {
      emoji: '🇲🇴',
      name: 'macau',
      shortcodes: ['flag_mo', 'macao', 'macau'],
      tags: ['MO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f4.png',
    },
    {
      emoji: '🇲🇵',
      name: 'northern_mariana_islands',
      shortcodes: ['flag_mp', 'northern_mariana_islands'],
      tags: ['MP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f5.png',
    },
    {
      emoji: '🇲🇶',
      name: 'martinique',
      shortcodes: ['flag_mq', 'martinique'],
      tags: ['MQ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f6.png',
    },
    {
      emoji: '🇲🇷',
      name: 'mauritania',
      shortcodes: ['flag_mr', 'mauritania'],
      tags: ['MR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f7.png',
    },
    {
      emoji: '🇲🇸',
      name: 'montserrat',
      shortcodes: ['flag_ms', 'montserrat'],
      tags: ['MS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f8.png',
    },
    {
      emoji: '🇲🇹',
      name: 'malta',
      shortcodes: ['flag_mt', 'malta'],
      tags: ['MT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1f9.png',
    },
    {
      emoji: '🇲🇺',
      name: 'mauritius',
      shortcodes: ['flag_mu', 'mauritius'],
      tags: ['MU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1fa.png',
    },
    {
      emoji: '🇲🇻',
      name: 'maldives',
      shortcodes: ['flag_mv', 'maldives'],
      tags: ['MV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1fb.png',
    },
    {
      emoji: '🇲🇼',
      name: 'malawi',
      shortcodes: ['flag_mw', 'malawi'],
      tags: ['MW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1fc.png',
    },
    {
      emoji: '🇲🇽',
      name: 'mexico',
      shortcodes: ['flag_mx', 'mexico'],
      tags: ['MX', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1fd.png',
    },
    {
      emoji: '🇲🇾',
      name: 'malaysia',
      shortcodes: ['flag_my', 'malaysia'],
      tags: ['MY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1fe.png',
    },
    {
      emoji: '🇲🇿',
      name: 'mozambique',
      shortcodes: ['flag_mz', 'mozambique'],
      tags: ['MZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f2-1f1ff.png',
    },
    {
      emoji: '🇳🇦',
      name: 'namibia',
      shortcodes: ['flag_na', 'namibia'],
      tags: ['NA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1e6.png',
    },
    {
      emoji: '🇳🇨',
      name: 'new_caledonia',
      shortcodes: ['flag_nc', 'new_caledonia'],
      tags: ['NC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1e8.png',
    },
    {
      emoji: '🇳🇪',
      name: 'niger',
      shortcodes: ['flag_ne', 'niger'],
      tags: ['NE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1ea.png',
    },
    {
      emoji: '🇳🇫',
      name: 'norfolk_island',
      shortcodes: ['flag_nf', 'norfolk_island'],
      tags: ['NF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1eb.png',
    },
    {
      emoji: '🇳🇬',
      name: 'nigeria',
      shortcodes: ['flag_ng', 'nigeria'],
      tags: ['NG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1ec.png',
    },
    {
      emoji: '🇳🇮',
      name: 'nicaragua',
      shortcodes: ['flag_ni', 'nicaragua'],
      tags: ['NI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1ee.png',
    },
    {
      emoji: '🇳🇱',
      name: 'netherlands',
      shortcodes: ['flag_nl', 'netherlands'],
      tags: ['NL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1f1.png',
    },
    {
      emoji: '🇳🇴',
      name: 'norway',
      shortcodes: ['flag_no', 'norway'],
      tags: ['NO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1f4.png',
    },
    {
      emoji: '🇳🇵',
      name: 'nepal',
      shortcodes: ['flag_np', 'nepal'],
      tags: ['NP', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1f5.png',
    },
    {
      emoji: '🇳🇷',
      name: 'nauru',
      shortcodes: ['flag_nr', 'nauru'],
      tags: ['NR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1f7.png',
    },
    {
      emoji: '🇳🇺',
      name: 'niue',
      shortcodes: ['flag_nu', 'niue'],
      tags: ['NU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1fa.png',
    },
    {
      emoji: '🇳🇿',
      name: 'new_zealand',
      shortcodes: ['flag_nz', 'new_zealand'],
      tags: ['NZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f3-1f1ff.png',
    },
    {
      emoji: '🇴🇲',
      name: 'oman',
      shortcodes: ['flag_om', 'oman'],
      tags: ['OM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f4-1f1f2.png',
    },
    {
      emoji: '🇵🇦',
      name: 'panama',
      shortcodes: ['flag_pa', 'panama'],
      tags: ['PA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1e6.png',
    },
    {
      emoji: '🇵🇪',
      name: 'peru',
      shortcodes: ['flag_pe', 'peru'],
      tags: ['PE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1ea.png',
    },
    {
      emoji: '🇵🇫',
      name: 'french_polynesia',
      shortcodes: ['flag_pf', 'french_polynesia'],
      tags: ['PF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1eb.png',
    },
    {
      emoji: '🇵🇬',
      name: 'papua_new_guinea',
      shortcodes: ['flag_pg', 'papua_new_guinea'],
      tags: ['PG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1ec.png',
    },
    {
      emoji: '🇵🇭',
      name: 'philippines',
      shortcodes: ['flag_ph', 'philippines'],
      tags: ['PH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1ed.png',
    },
    {
      emoji: '🇵🇰',
      name: 'pakistan',
      shortcodes: ['flag_pk', 'pakistan'],
      tags: ['PK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f0.png',
    },
    {
      emoji: '🇵🇱',
      name: 'poland',
      shortcodes: ['flag_pl', 'poland'],
      tags: ['PL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f1.png',
    },
    {
      emoji: '🇵🇲',
      name: 'st_pierre_miquelon',
      shortcodes: ['flag_pm', 'st_pierre_miquelon'],
      tags: ['PM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f2.png',
    },
    {
      emoji: '🇵🇳',
      name: 'pitcairn_islands',
      shortcodes: ['flag_pn', 'pitcairn_islands'],
      tags: ['PN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f3.png',
    },
    {
      emoji: '🇵🇷',
      name: 'puerto_rico',
      shortcodes: ['flag_pr', 'puerto_rico'],
      tags: ['PR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f7.png',
    },
    {
      emoji: '🇵🇸',
      name: 'palestinian_territories',
      shortcodes: ['flag_ps', 'palestinian_territories'],
      tags: ['PS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f8.png',
    },
    {
      emoji: '🇵🇹',
      name: 'portugal',
      shortcodes: ['flag_pt', 'portugal'],
      tags: ['PT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1f9.png',
    },
    {
      emoji: '🇵🇼',
      name: 'palau',
      shortcodes: ['flag_pw', 'palau'],
      tags: ['PW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1fc.png',
    },
    {
      emoji: '🇵🇾',
      name: 'paraguay',
      shortcodes: ['flag_py', 'paraguay'],
      tags: ['PY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f5-1f1fe.png',
    },
    {
      emoji: '🇶🇦',
      name: 'qatar',
      shortcodes: ['flag_qa', 'qatar'],
      tags: ['QA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f6-1f1e6.png',
    },
    {
      emoji: '🇷🇪',
      name: 'reunion',
      shortcodes: ['flag_re', 'reunion'],
      tags: ['RE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f7-1f1ea.png',
    },
    {
      emoji: '🇷🇴',
      name: 'romania',
      shortcodes: ['flag_ro', 'romania'],
      tags: ['RO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f7-1f1f4.png',
    },
    {
      emoji: '🇷🇸',
      name: 'serbia',
      shortcodes: ['flag_rs', 'serbia'],
      tags: ['RS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f7-1f1f8.png',
    },
    {
      emoji: '🇷🇺',
      name: 'ru',
      shortcodes: ['flag_ru', 'russia'],
      tags: ['RU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f7-1f1fa.png',
    },
    {
      emoji: '🇷🇼',
      name: 'rwanda',
      shortcodes: ['flag_rw', 'rwanda'],
      tags: ['RW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f7-1f1fc.png',
    },
    {
      emoji: '🇸🇦',
      name: 'saudi_arabia',
      shortcodes: ['flag_sa', 'saudi_arabia'],
      tags: ['SA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1e6.png',
    },
    {
      emoji: '🇸🇧',
      name: 'solomon_islands',
      shortcodes: ['flag_sb', 'solomon_islands'],
      tags: ['SB', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1e7.png',
    },
    {
      emoji: '🇸🇨',
      name: 'seychelles',
      shortcodes: ['flag_sc', 'seychelles'],
      tags: ['SC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1e8.png',
    },
    {
      emoji: '🇸🇩',
      name: 'sudan',
      shortcodes: ['flag_sd', 'sudan'],
      tags: ['SD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1e9.png',
    },
    {
      emoji: '🇸🇪',
      name: 'sweden',
      shortcodes: ['flag_se', 'sweden'],
      tags: ['SE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ea.png',
    },
    {
      emoji: '🇸🇬',
      name: 'singapore',
      shortcodes: ['flag_sg', 'singapore'],
      tags: ['SG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ec.png',
    },
    {
      emoji: '🇸🇭',
      name: 'st_helena',
      shortcodes: ['flag_sh', 'st_helena'],
      tags: ['SH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ed.png',
    },
    {
      emoji: '🇸🇮',
      name: 'slovenia',
      shortcodes: ['flag_si', 'slovenia'],
      tags: ['SI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ee.png',
    },
    {
      emoji: '🇸🇯',
      name: 'svalbard_jan_mayen',
      shortcodes: ['flag_sj', 'svalbard_jan_mayen'],
      tags: ['SJ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ef.png',
    },
    {
      emoji: '🇸🇰',
      name: 'slovakia',
      shortcodes: ['flag_sk', 'slovakia'],
      tags: ['SK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f0.png',
    },
    {
      emoji: '🇸🇱',
      name: 'sierra_leone',
      shortcodes: ['flag_sl', 'sierra_leone'],
      tags: ['SL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f1.png',
    },
    {
      emoji: '🇸🇲',
      name: 'san_marino',
      shortcodes: ['flag_sm', 'san_marino'],
      tags: ['SM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f2.png',
    },
    {
      emoji: '🇸🇳',
      name: 'senegal',
      shortcodes: ['flag_sn', 'senegal'],
      tags: ['SN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f3.png',
    },
    {
      emoji: '🇸🇴',
      name: 'somalia',
      shortcodes: ['flag_so', 'somalia'],
      tags: ['SO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f4.png',
    },
    {
      emoji: '🇸🇷',
      name: 'suriname',
      shortcodes: ['flag_sr', 'suriname'],
      tags: ['SR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f7.png',
    },
    {
      emoji: '🇸🇸',
      name: 'south_sudan',
      shortcodes: ['flag_ss', 'south_sudan'],
      tags: ['SS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f8.png',
    },
    {
      emoji: '🇸🇹',
      name: 'sao_tome_principe',
      shortcodes: ['flag_st', 'sao_tome_principe'],
      tags: ['ST', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1f9.png',
    },
    {
      emoji: '🇸🇻',
      name: 'el_salvador',
      shortcodes: ['el_salvador', 'flag_sv'],
      tags: ['SV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1fb.png',
    },
    {
      emoji: '🇸🇽',
      name: 'sint_maarten',
      shortcodes: ['flag_sx', 'sint_maarten'],
      tags: ['SX', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1fd.png',
    },
    {
      emoji: '🇸🇾',
      name: 'syria',
      shortcodes: ['flag_sy', 'syria'],
      tags: ['SY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1fe.png',
    },
    {
      emoji: '🇸🇿',
      name: 'swaziland',
      shortcodes: ['eswatini', 'flag_sz', 'swaziland'],
      tags: ['SZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f8-1f1ff.png',
    },
    {
      emoji: '🇹🇦',
      name: 'tristan_da_cunha',
      shortcodes: ['flag_ta', 'tristan_da_cunha'],
      tags: ['TA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1e6.png',
    },
    {
      emoji: '🇹🇨',
      name: 'turks_caicos_islands',
      shortcodes: ['flag_tc', 'turks_caicos_islands'],
      tags: ['TC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1e8.png',
    },
    {
      emoji: '🇹🇩',
      name: 'chad',
      shortcodes: ['chad', 'flag_td'],
      tags: ['TD', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1e9.png',
    },
    {
      emoji: '🇹🇫',
      name: 'french_southern_territories',
      shortcodes: ['flag_tf', 'french_southern_territories'],
      tags: ['TF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1eb.png',
    },
    {
      emoji: '🇹🇬',
      name: 'togo',
      shortcodes: ['flag_tg', 'togo'],
      tags: ['TG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1ec.png',
    },
    {
      emoji: '🇹🇭',
      name: 'thailand',
      shortcodes: ['flag_th', 'thailand'],
      tags: ['TH', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1ed.png',
    },
    {
      emoji: '🇹🇯',
      name: 'tajikistan',
      shortcodes: ['flag_tj', 'tajikistan'],
      tags: ['TJ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1ef.png',
    },
    {
      emoji: '🇹🇰',
      name: 'tokelau',
      shortcodes: ['flag_tk', 'tokelau'],
      tags: ['TK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f0.png',
    },
    {
      emoji: '🇹🇱',
      name: 'timor_leste',
      shortcodes: ['flag_tl', 'timor_leste'],
      tags: ['TL', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f1.png',
    },
    {
      emoji: '🇹🇲',
      name: 'turkmenistan',
      shortcodes: ['flag_tm', 'turkmenistan'],
      tags: ['TM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f2.png',
    },
    {
      emoji: '🇹🇳',
      name: 'tunisia',
      shortcodes: ['flag_tn', 'tunisia'],
      tags: ['TN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f3.png',
    },
    {
      emoji: '🇹🇴',
      name: 'tonga',
      shortcodes: ['flag_to', 'tonga'],
      tags: ['TO', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f4.png',
    },
    {
      emoji: '🇹🇷',
      name: 'tr',
      shortcodes: ['flag_tr', 'turkey_tr'],
      tags: ['TR', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f7.png',
    },
    {
      emoji: '🇹🇹',
      name: 'trinidad_tobago',
      shortcodes: ['flag_tt', 'trinidad_tobago'],
      tags: ['TT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1f9.png',
    },
    {
      emoji: '🇹🇻',
      name: 'tuvalu',
      shortcodes: ['flag_tv', 'tuvalu'],
      tags: ['TV', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1fb.png',
    },
    {
      emoji: '🇹🇼',
      name: 'taiwan',
      shortcodes: ['flag_tw', 'taiwan'],
      tags: ['TW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1fc.png',
    },
    {
      emoji: '🇹🇿',
      name: 'tanzania',
      shortcodes: ['flag_tz', 'tanzania'],
      tags: ['TZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1f9-1f1ff.png',
    },
    {
      emoji: '🇺🇦',
      name: 'ukraine',
      shortcodes: ['flag_ua', 'ukraine'],
      tags: ['UA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1e6.png',
    },
    {
      emoji: '🇺🇬',
      name: 'uganda',
      shortcodes: ['flag_ug', 'uganda'],
      tags: ['UG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1ec.png',
    },
    {
      emoji: '🇺🇲',
      name: 'us_outlying_islands',
      shortcodes: ['flag_um', 'us_outlying_islands'],
      tags: ['UM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1f2.png',
    },
    {
      emoji: '🇺🇳',
      name: 'united_nations',
      shortcodes: ['flag_un', 'un', 'united_nations'],
      tags: ['UN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 4,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1f3.png',
    },
    {
      emoji: '🇺🇸',
      name: 'us',
      shortcodes: ['flag_us', 'united_states', 'usa'],
      tags: ['US', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 0.6,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1f8.png',
    },
    {
      emoji: '🇺🇾',
      name: 'uruguay',
      shortcodes: ['flag_uy', 'uruguay'],
      tags: ['UY', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1fe.png',
    },
    {
      emoji: '🇺🇿',
      name: 'uzbekistan',
      shortcodes: ['flag_uz', 'uzbekistan'],
      tags: ['UZ', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1ff.png',
    },
    {
      emoji: '🇻🇦',
      name: 'vatican_city',
      shortcodes: ['flag_va', 'vatican_city'],
      tags: ['VA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1e6.png',
    },
    {
      emoji: '🇻🇨',
      name: 'st_vincent_grenadines',
      shortcodes: ['flag_vc', 'st_vincent_grenadines'],
      tags: ['VC', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1e8.png',
    },
    {
      emoji: '🇻🇪',
      name: 'venezuela',
      shortcodes: ['flag_ve', 'venezuela'],
      tags: ['VE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1ea.png',
    },
    {
      emoji: '🇻🇬',
      name: 'british_virgin_islands',
      shortcodes: ['british_virgin_islands', 'flag_vg'],
      tags: ['VG', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1ec.png',
    },
    {
      emoji: '🇻🇮',
      name: 'us_virgin_islands',
      shortcodes: ['flag_vi', 'us_virgin_islands'],
      tags: ['VI', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1ee.png',
    },
    {
      emoji: '🇻🇳',
      name: 'vietnam',
      shortcodes: ['flag_vn', 'vietnam'],
      tags: ['VN', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1f3.png',
    },
    {
      emoji: '🇻🇺',
      name: 'vanuatu',
      shortcodes: ['flag_vu', 'vanuatu'],
      tags: ['VU', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fb-1f1fa.png',
    },
    {
      emoji: '🇼🇫',
      name: 'wallis_futuna',
      shortcodes: ['flag_wf', 'wallis_futuna'],
      tags: ['WF', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fc-1f1eb.png',
    },
    {
      emoji: '🇼🇸',
      name: 'samoa',
      shortcodes: ['flag_ws', 'samoa'],
      tags: ['WS', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fc-1f1f8.png',
    },
    {
      emoji: '🇽🇰',
      name: 'kosovo',
      shortcodes: ['flag_xk', 'kosovo'],
      tags: ['XK', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fd-1f1f0.png',
    },
    {
      emoji: '🇾🇪',
      name: 'yemen',
      shortcodes: ['flag_ye', 'yemen'],
      tags: ['YE', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fe-1f1ea.png',
    },
    {
      emoji: '🇾🇹',
      name: 'mayotte',
      shortcodes: ['flag_yt', 'mayotte'],
      tags: ['YT', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fe-1f1f9.png',
    },
    {
      emoji: '🇿🇦',
      name: 'south_africa',
      shortcodes: ['flag_za', 'south_africa'],
      tags: ['ZA', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ff-1f1e6.png',
    },
    {
      emoji: '🇿🇲',
      name: 'zambia',
      shortcodes: ['flag_zm', 'zambia'],
      tags: ['ZM', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ff-1f1f2.png',
    },
    {
      emoji: '🇿🇼',
      name: 'zimbabwe',
      shortcodes: ['flag_zw', 'zimbabwe'],
      tags: ['ZW', 'flag'],
      group: 'flags',
      emoticons: [],
      version: 2,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ff-1f1fc.png',
    },
    {
      emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
      name: 'england',
      shortcodes: ['england', 'flag_gbeng'],
      tags: ['flag', 'gbeng'],
      group: 'flags',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png',
    },
    {
      emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
      name: 'scotland',
      shortcodes: ['flag_gbsct', 'scotland'],
      tags: ['flag', 'gbsct'],
      group: 'flags',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png',
    },
    {
      emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
      name: 'wales',
      shortcodes: ['flag_gbwls', 'wales'],
      tags: ['flag', 'gbwls'],
      group: 'flags',
      emoticons: [],
      version: 5,
      fallbackImage:
        'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png',
    },
  ]
