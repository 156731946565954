/*eslint-disable*/
import Downshift from 'downshift'
import { EditorState, RawDraftContentBlock, RawDraftEntityRange, convertFromRaw, convertToRaw } from 'draft-js'
import produce from 'immer'
import * as ramda from 'ramda'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { VideoLinkSimple } from '@/Videos/Videos'
import { DropdownWithCircle, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
import { NoTriggerVariable } from '@/common/Icons/Workflow.Icons'
import { Error } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import {
    Button,
    ButtonContainer,
    CancelButton,
    DraftInputWrapper,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    HeaderRight,
    HoverInputContainer,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    LinkTag,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper
} from '@/common/styled/Workflow.Dumb'

import { automationAPIURLWithWorkspace, getJson } from '@/common/utils/api-utils'
import { seperateOptionalFields } from './ActionPanel'
import { SidePanelContext } from './Canvas'
import { ContextPopup, configApiCall } from './ContextPopup'
import { Editor, decorator } from './InputandVariable'
import {
    ActionInputsListT,
    ApprovalInitialState,
    ApprovalResponseT,
    AppsListT,
    AuthType,
    ContextPopupStateT,
    InputFieldT,
    NodeAddT,
    NodeEditT,
    NodeMode,
    NodeModes,
    NodeT,
    NodeView,
    PopupError,
    RemindEvery,
    ResponseOutput,
    RightPanelStateContext,
    Tree,
    TreeT,
    emptyInputSchema,
    updateNode
} from './types'
import {
    InsertContext,
    configKinds,
    converToEditorFormat,
    convertAsaSingleString,
    convertToServerFormat2,
    emptyEditorState
} from './utils'
import { getProductId } from '@/common/utils/utils'

export const parseExpiryText = (expiry: string) => {
    const dayRegex = /\b\d+(d|day|days|\s+d|\s+day|\s+days)\b/
    const hoursRegex = /\b\d+(h|hour|hours|\s+h|\s+hour|\s+hours)\b/
    const minRegex = /\b\d+(m|min|minute|minutes|\s+m|\s+min|\s+minute|\s+minutes)\b/
    const secRegex = /\b\d+(s|sec|second|seconds|\s+s|\s+sec|\s+second|\s+seconds)\b/

    let expiryObj = {}

    if (expiry) {
        const daysArray = expiry.match(dayRegex)
        const hoursArray = expiry.match(hoursRegex)
        const minutesArray = expiry.match(minRegex)
        const secondsArray = expiry.match(secRegex)

        if (daysArray && daysArray.length) {
            expiryObj = {
                ...expiryObj,
                days: parseInt(daysArray[0])
            }
        }
        if (hoursArray && hoursArray.length) {
            expiryObj = {
                ...expiryObj,
                hours: parseInt(hoursArray[0])
            }
        }
        if (minutesArray && minutesArray.length) {
            expiryObj = {
                ...expiryObj,
                minutes: parseInt(minutesArray[0])
            }
        }
        if (secondsArray && secondsArray.length) {
            expiryObj = {
                ...expiryObj,
                seconds: parseInt(secondsArray[0])
            }
        }
    }
    // console.log('Approval Expiry :::::::::::::',expiryObj)
    // console.log(expiryObj,ramda.isEmpty(expiryObj))
    if (ramda.isEmpty(expiryObj)) {
        throw false
    }

    return true

    // return TE.fromOption(() => new Error('Error while parsing Approval Expiry'))(x);
}
const ApprovalTip = () => (
    <>
    {getProductId() === "ASSISTANT" ? (
        <>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/managing-approvals/"
            >
                Approval
            </a>{' '}
            if you require permission or <i>approval</i> to perform task or actions in your workflow.
        </p>
        <p>E.g., If you want the bot to seek approval from manager to unlock a user account in okta.</p>
        </>
     ) : ( 
        <>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/approval/"
            >
                Approval
            </a>{' '}
            if you require permission or <i>approval</i> to perform task or actions in your workflow.
        </p>
        <p>E.g., If you want the bot to seek approval from manager to unlock a user account in okta.</p>
        </>
     ) }
    </>
)


const PopupHeader = (props: any) => {
    return (
        <>
        {getProductId() === "ASSISTANT" ? (
            <Header>
                <HeaderLeft>
                    Approval
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<ApprovalTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    {/* <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="You will need to add the following urls in your azure portal when creating teams app"
                        distance={5}
                    > */}
                    <LinkTag href={'https://help.workativ.com/knowledgebase/managing-approvals/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag>
                    {/* </Tooltip> */}
                </HeaderLeft>
                <HeaderRight>
                {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to add approval'} /> :
                    <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to set approval'} />
                 }

                </HeaderRight>
            </Header>
         ) : (
            <Header>
                <HeaderLeft>
                    Approval
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={<ApprovalTip />}
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <a
                        target="_blank"
                        href="https://help.workativ.com/knowledgebase/approval/"
                        >
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={"Help document"}
                            distance={5}
                        >
                       
                            <QaIconWithCircle />
                        </Tooltip>
                    </a>
                    {/* <LinkTag href={'https://help.workativ.com/knowledgebase/managing-approvals/'} target="_blank">
                        <QaIconWithCircle />
                    </LinkTag> */}
                    {/* </Tooltip> */}
                </HeaderLeft>
                <HeaderRight>
                {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to add approval'} /> :
                    <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to set approval'} />
                 }

                </HeaderRight>
            </Header>
          ) }
            { getProductId() === "ASSISTANT" ? <Paragraph>Setup approval in your workflow.</Paragraph> : 
            <Paragraph>Set approval in your workflow.</Paragraph>
            }
          </>
        // <>
        //     <Header>
        //         <HeaderLeft>
        //             Approval
        //             <Tooltip
        //                 className="target customTip"
        //                 zIndex={10000}
        //                 arrowSize={8}
        //                 tagName="span"
        //                 content={<ApprovalTip />}
        //                 distance={5}
        //             >
        //                 <InfoIcon />
        //             </Tooltip>
        //             {/* <Tooltip
        //                 className="target customTip"
        //                 zIndex={10000}
        //                 arrowSize={8}
        //                 tagName="span"
        //                 content="You will need to add the following urls in your azure portal when creating teams app"
        //                 distance={5}
        //             > */}
        //             <LinkTag href={'https://help.workativ.com/knowledgebase/managing-approvals/'} target="_blank">
        //                 <QaIconWithCircle />
        //             </LinkTag>
        //             {/* </Tooltip> */}
        //         </HeaderLeft>
        //         <HeaderRight>
        //         {  getProductId() === "ASSISTANT" ? <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to add approval'} /> :
        //             <VideoLinkSimple id={'CREATE_APPROVAL_APP_WORKFLOW'} text={'How to set approval'} />
        //          }

        //         </HeaderRight>
        //     </Header>
        //     {  getProductId() === "ASSISTANT" ? <Paragraph>Setup approval in your workflow.</Paragraph> : 
        //         <Paragraph>Set approval in your workflow.</Paragraph>
        //     }
            
        // </>
    )
}

const PopupInputWrapper = (props: any) => {
    return (
        <InputWrapper>
            <InputContainer>
                <Input type="text" />
                <InputContent>Approval name</InputContent>
            </InputContainer>
            <InputContainer>
                <Input type="text" />
                <InputContent>Description</InputContent>
            </InputContainer>
        </InputWrapper>
    )
}

const PopupInputHeaderWrapper = (props: any) => {
    return (
        <InputWrapper>
            <Header>Then answer user with</Header>
            <InputContainer>
                <InputContent>Answer</InputContent>
                <Input
                    value="Hi, check this link “ Printer resolve doc “ to resolve Printer Issues."
                    placeholder="Hi, check this link “ Printer resolve doc “ to resolve Printer Issues."
                />
            </InputContainer>
        </InputWrapper>
    )
}

const PopupDropdownWrapper = (props: any) => {
    const { state } = props
    const { hasLinkedDialogs,active_automation,automationMeta } = useContext(SidePanelContext)

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <InputWrapper>
            {/* <Header>
                Next step{' '}
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="You will need to add the following urls in your azure portal when creating teams app"
                    distance={3}
                >
                    <InfoIcon />
                </Tooltip>
            </Header> */}
            <DropdownWrapper>
                <InputContainer>
                    <Input type="text" value={state.type} style={{color: 'transparent', textShadow: '0 0 0 #000'}}                         
                        className={(isAssistant ? automationMeta.type ==='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'disabled_with_blue' : ''}
                    />
                    <InputContent>Approval type</InputContent>
                    {/* <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton> */}
                    {/* <DropdownUlWrapper>
                        <DropdownUl>
                            <DropdownLi>Start and wait for approval</DropdownLi>
                            <DropdownLi>Start and wait for approval</DropdownLi>
                            <DropdownLi>Start and wait for approval</DropdownLi>
                        </DropdownUl>
                    </DropdownUlWrapper> */}
                </InputContainer>
                {/* <InputContainer>
                    <InputContent>Select dialog</InputContent>
                    <Input value="Welcome" placeholder="Welcome" />
                    <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton>
                </InputContainer> */}
            </DropdownWrapper>
        </InputWrapper>
    )
}

const PopupDropdownWrapperTwo = (props: any) => {
    const { inputs } = props
    const [deopDown, setDropDown] = useState(false)
    const [deopDown1, setDropDown1] = useState(false)

    const [inputs0, inputs1, inputs2] = inputs
    const { approvalPanelState, setApprovalPanelState } = useContext(ApprovalPanelStateContext)
    const currentNode = approvalPanelState.currentNode as ApprovalResponseT
    const { hasLinkedDialogs,active_automation,automationMeta } = useContext(SidePanelContext)

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <InputWrapper>
            <Header>
                Approval reminder and expiry{' '}
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="Set approval reminder and expiry time if no response from approver."
                    distance={3}
                >
                    <InfoIcon />
                </Tooltip>
            </Header>
            <DropdownWrapper>
                <InputContainer>
                    <Input
                        type="text"
                        value={
                            inputs0 &&
                            inputs0.config.values.filter(
                                (v: { text: string; value: string }) => v.value == inputs0.value
                            )[0].text
                        }
                        onClick={() => setDropDown(true)}
                        className={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''}
                    />
                    <InputContent>Send approval reminder</InputContent>
                    <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton>
                    {deopDown && (
                        <Downshift isOpen={deopDown} onOuterClick={() => setDropDown(false)}>
                            {() => (
                                <span>
                                    <DropdownUlWrapper>
                                        <DropdownUl className="automate_product_app_dropdown">
                                            {inputs0 &&
                                                inputs0.config.values.map((val: { text: string; value: string }) => (
                                                    <DropdownLi
                                                        onClick={() => {
                                                            const nextValue = approvalPanelState.currentNode.input.map(
                                                                (inp, j) =>
                                                                    inp.key == inputs0.key
                                                                        ? { ...inp, value: val.value }
                                                                        : inp
                                                            )
                                                            setApprovalPanelState({
                                                                ...approvalPanelState,
                                                                currentNode: { ...currentNode, input: nextValue }
                                                            })

                                                            setDropDown(false)
                                                        }}
                                                    >
                                                        {val.text}
                                                    </DropdownLi>
                                                ))}
                                        </DropdownUl>
                                    </DropdownUlWrapper>
                                </span>
                            )}
                        </Downshift>
                    )}
                </InputContainer>
                <InputContainer>
                    <Input
                        type="text"
                        value={`${inputs1.value} ${inputs0.value.charAt(0).toUpperCase() + inputs0.value.slice(1)}${
                            inputs1.value > 1 ? 's' : ''
                        }`}
                        onClick={() => setDropDown1(true)}
                        className={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''}

                    />
                    <InputContent>Remind every</InputContent>
                    <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton>
                    {deopDown1 && (
                        <Downshift isOpen={deopDown1} onOuterClick={() => setDropDown1(false)}>
                            {() => (
                                <span>
                                    <DropdownUlWrapper>
                                        <DropdownUl className="automate_product_app_dropdown">
                                            {inputs1 &&
                                                RemindEvery.map(val => (
                                                    <DropdownLi
                                                        onClick={() => {
                                                            const nextValue = approvalPanelState.currentNode.input.map(
                                                                (inp, j) =>
                                                                    inp.key == inputs1.key
                                                                        ? { ...inp, value: val }
                                                                        : inp
                                                            )
                                                            setApprovalPanelState({
                                                                ...approvalPanelState,
                                                                currentNode: { ...currentNode, input: nextValue }
                                                            })

                                                            setDropDown1(false)
                                                        }}
                                                    >
                                                        {val}{' '}
                                                        {inputs0.value.charAt(0).toUpperCase() + inputs0.value.slice(1)}
                                                        {val > 1 ? 's' : ''}
                                                    </DropdownLi>
                                                ))}
                                        </DropdownUl>
                                    </DropdownUlWrapper>
                                </span>
                            )}
                        </Downshift>
                    )}
                </InputContainer>
                <InputContainer>
                    <Input
                        type="text"
                        value={inputs2 ? inputs2.value : ''}
                        onChange={(e: any) => {
                            const nextValue = approvalPanelState.currentNode.input.map((inp, j) =>
                                inp.key == inputs2.key ? { ...inp, value: e.target.value } : inp
                            )
                            setApprovalPanelState({
                                ...approvalPanelState,
                                currentNode: { ...currentNode, input: nextValue }
                            })
                        }}
                        className={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'disabled_with_blue' : ''}

                    />
                    <InputContent>Approval expiry</InputContent>
                    <Paragraph margin>'2d 5h' or 2days 5hours 3minutes' or '3min 20sec'</Paragraph>
                </InputContainer>
            </DropdownWrapper>
        </InputWrapper>
    )
}

const PopupHoverInputWrapper = (props: any) => {
    const { inputs } = props
    const { approvalPanelState, setApprovalPanelState, contextPopupValues, setContextPopupValues } = useContext(
        ApprovalPanelStateContext
    )

    const { rightPanelState, setRightPanelState, saveTree, treeData, hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext)

    function updateInputValuesWithContext(props: {
        path: string
        value: string
        valuePath: string[]
        icon: string
        examples: string
    }) {
        const { path, value, valuePath, icon, examples } = props

        const textInputvalue = inputs.filter((x: InputFieldT) => x.key === contextPopupValues.currentInput)[0]

        const config = textInputvalue.config

        const editorState = textInputvalue.value as any

        const nextValue = inputs
            .map((input: InputFieldT) => {
                if (input.key === contextPopupValues.currentInput) {
                    return {
                        ...input,
                        inputHasValue: true,
                        value: InsertContext(
                            value,
                            {
                                dataType: 'string',
                                path: valuePath,
                                icon: icon,
                                variable: value,
                                id: path,
                                examples
                            },
                            editorState
                        ) as any
                    }
                }
                return input
            })
            .concat(approvalPanelState.currentNode.input.slice(7, 10))

        setApprovalPanelState({ ...approvalPanelState, currentNode: { ...currentNode, input: nextValue } })
    }

    const replaceBetween2 = (str: string, start: number, end: number, what: string, isLast: boolean) => {
        const replaced = isLast
            ? str.substring(0, start) + what + str.substring(end)
            : str.substring(0, start) + what + str.substring(end)
        return replaced
    }

    async function updateVariableFromInput(text: any, old: any, cb: Function) {
        const inputHasValues = inputs
            .map((inp: InputFieldT, i: number) => {
                const value = inp.value as EditorState

                return value &&
                    convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(old.title)
                    ? i
                    : null
            })
            .filter((inp: any) => inp != null)
        if (inputHasValues.length > 0) {
            await cb('edit')

            const newValue = produce(inputs as InputFieldT[], draft => {
                return draft.map(inp => {
                    const value = inp.value as EditorState
                    const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
                        old.title
                    )
                    if (
                        typeof value != 'string' &&
                        Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
                        hasValue
                    ) {
                        const inpValue = convertToRaw(value.getCurrentContent())
                        let omittedMap = {}
                        // items not needed to edit in omitted map
                        const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                            // console.log(curr, 'curr')
                            if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) != old.title) {
                                const { [`${curr[0]}`]: omit, ...rest } = acc
                                omittedMap[`${curr[0]}`] = omit
                                return rest
                            }
                            curr[1].data.path[curr[1].data.path.length - 1] = text.title

                            acc[`${curr[0]}`] = curr[1].data.variable
                                ? {
                                      ...curr[1],
                                      data: { ...curr[1].data, variable: text.title }
                                  }
                                : {
                                      ...curr[1],
                                      data: { ...curr[1].data, value: text.title, visible: text.title }
                                  }
                            return acc
                        }, inpValue.entityMap)

                        const minus = text.title.length < old.title.length

                        const newLength = minus
                            ? old.title.length - text.title.length
                            : text.title.length - old.title.length
                        let itemsProcessed = 0
                        const newBlocks = inpValue.blocks.map(b => {
                            const filtered = b.entityRanges.filter(e => {
                                return Object.keys(newMap).indexOf(e.key.toString()) != -1
                            })

                            return {
                                ...b,
                                text: filtered.reduce((acc, curr, i) => {
                                    if (i == 0) {
                                        const start = curr.offset - (old.title.length - text.title.length) * i
                                        return replaceBetween2(
                                            acc,
                                            curr.offset,
                                            start + old.title.length,
                                            text.title,
                                            filtered.length == i + 1
                                        )
                                    } else {
                                        const start = curr.offset - (old.title.length - text.title.length) * i
                                        return replaceBetween2(
                                            acc,
                                            start,
                                            start + old.title.length,
                                            text.title,
                                            filtered.length == i + 1
                                        )
                                    }
                                }, b.text),
                                entityRanges: b.entityRanges
                                    .reduce((acc: any, curr: any, i: number) => {
                                        if (
                                            Object.keys(newMap)
                                                .map(x => parseInt(x))
                                                .some(x => curr.key >= x)
                                        ) {
                                            if (Object.keys(newMap).indexOf(curr.key.toString()) != -1) {
                                                const newOffsetDifference =
                                                    itemsProcessed *
                                                    (Object.keys(newMap).length > 0
                                                        ? old.title.length - text.title.length
                                                        : 0)
                                                itemsProcessed = itemsProcessed + 1

                                                return acc.concat({
                                                    ...curr,
                                                    offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                    length: curr.length - (old.title.length - text.title.length)
                                                })
                                            } else {
                                                const newOffsetDifference =
                                                    itemsProcessed *
                                                    (Object.keys(newMap).length > 0
                                                        ? old.title.length - text.title.length
                                                        : 0)
                                                return acc.concat({
                                                    ...curr,
                                                    offset: i == 0 ? curr.offset : curr.offset - newOffsetDifference,
                                                    length: curr.length
                                                })
                                            }
                                        }

                                        return acc.concat(curr)
                                    }, [])
                                    .filter((x: any) => x.length > 0)
                            }
                        })
                        return {
                            ...inp,
                            value: EditorState.createWithContent(
                                convertFromRaw({
                                    blocks: newBlocks,
                                    entityMap: { ...omittedMap, ...newMap }
                                }),
                                decorator
                            )
                        } as any
                    }
                    return inp as any
                })
            })

            setApprovalPanelState({
                ...approvalPanelState,
                currentNode: {
                    ...currentNode,
                    input: newValue.concat(approvalPanelState.currentNode.input.slice(7, 10))
                }
            })
        } else {
            await cb('no-edit')
        }
    }

    async function removeVariableFromInput(text: any, cb: Function) {
        const inputHasValues = inputs
            .map((inp: InputFieldT, i: number) => {
                const value = inp.value as EditorState

                return value &&
                    convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(text.title)
                    ? i
                    : null
            })
            .filter((inp: any) => inp != null)

        if (inputHasValues.length > 0) {
            await cb('delete')
            const newValue = produce(inputs as InputFieldT[], draft => {
                return draft.map(inp => {
                    const value = inp.value as EditorState
                    const hasValue = convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks).includes(
                        text.title
                    )
                    if (
                        typeof value != 'string' &&
                        Object.keys(convertToRaw(value.getCurrentContent()).entityMap).length > 0 &&
                        hasValue
                    ) {
                        const inpValue = convertToRaw(value.getCurrentContent())

                        const newMap = Object.entries(inpValue.entityMap).reduce((acc: any, curr: any) => {
                            if ((curr[1].data.variable ? curr[1].data.variable : curr[1].data.value) == text.title) {
                                const { [`${curr[0]}`]: omit, ...rest } = acc
                                return rest
                            }
                            return acc
                        }, inpValue.entityMap)

                        const newBlocks = inpValue.blocks.map(b => {
                            const filtered = b.entityRanges.filter(e => {
                                return Object.keys(newMap).indexOf(e.key.toString()) == -1
                            })

                            return {
                                ...b,
                                text: b.entityRanges
                                    .filter(e => {
                                        return Object.keys(newMap).indexOf(e.key.toString()) == -1
                                    })
                                    .reduce((acc, curr, i) => {
                                        if (i == 0) {
                                            return replaceBetween2(
                                                acc,
                                                curr.offset,
                                                curr.offset + text.title.length,
                                                '',
                                                curr.offset + text.title.length >= acc
                                            )
                                        } else {
                                            const start = curr.offset - text.title.length * i
                                            return replaceBetween2(
                                                acc,
                                                start,
                                                start + text.title.length,
                                                '',
                                                filtered.length == i + 1
                                            )
                                        }
                                    }, b.text),
                                entityRanges: b.entityRanges
                                    .reduce((acc, current, i: number) => {
                                        if (Object.keys(newMap).indexOf(current.key.toString()) != -1) {
                                            const newOffsetDifference =
                                                acc.filter(x => x.length === 0).length *
                                                (Object.keys(newMap).length > 0 ? text.title.length : 0)
                                            return [
                                                ...acc,
                                                {
                                                    ...current,
                                                    offset:
                                                        i == 0 ? current.offset : current.offset - newOffsetDifference
                                                }
                                            ]
                                        } else {
                                            return [
                                                ...acc,
                                                {
                                                    ...current,
                                                    // offset: i == 0 ? current.offset : acc[i - 1].offset,
                                                    length: 0
                                                }
                                            ]
                                        }
                                        return [...acc, current]
                                    }, [] as RawDraftEntityRange[])

                                    .filter(e => e.length !== 0)
                            }
                        })

                        return {
                            ...inp,
                            value: EditorState.createWithContent(
                                convertFromRaw({
                                    blocks: newBlocks,
                                    entityMap: newMap
                                }),
                                decorator
                            )
                        } as any
                    }
                    return inp as any
                })
            })

            setApprovalPanelState({
                ...approvalPanelState,
                currentNode: {
                    ...currentNode,
                    input: newValue.concat(approvalPanelState.currentNode.input.slice(7, 10))
                }
            })
        } else {
            await cb('delete')
        }
    }

    const currentNode = approvalPanelState.currentNode as ApprovalResponseT
    const isAssistant = getProductId() === 'ASSISTANT'


    return (
        <>
            <InputWrapper>
                <DraftInputWrapper>
                    <div className="select_work_form">
                        <Header>Approval inputs </Header>
                        {inputs.map((inp: InputFieldT, i: number) => (
                            <InputContainer
                                key={i}
                                onClick={() => {
                                    setContextPopupValues({
                                        ...contextPopupValues,
                                        show:
                                            inp.key == 'approval_details_link' || inp.key == 'approval_link_description'
                                                ? false
                                                : true,
                                        currentInput: inp.key
                                    })
                                }}
                                className={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation) ? 'editor_btn_disabled' : ''}
                            >
                                <DraftEditor
                                    optional={!inp.required}
                                    infocus={contextPopupValues.currentInput === inp.key}
                                    value={inp.value ? (inp.value as EditorState) : emptyEditorState}
                                    setValue={(val: EditorState) => {
                                        const nextValue = approvalPanelState.currentNode.input.map((inps, j) =>
                                            inps.key == inp.key ? { ...inps, value: val } : inps
                                        )
                                        setApprovalPanelState({
                                            ...approvalPanelState,
                                            currentNode: { ...currentNode, input: nextValue }
                                        })
                                    }}
                                    disabled={(isAssistant ? automationMeta.type ==='no_trigger' ?false : active_automation: active_automation)}
                                    focusContent={inp.displayTitle}
                                    focus={
                                        convertAsaSingleString(
                                            convertToRaw(
                                                inp.value
                                                    ? (inp.value as EditorState).getCurrentContent()
                                                    : emptyEditorState.getCurrentContent()
                                            ).blocks as RawDraftContentBlock[]
                                        ).replace(/\s/g, '').length
                                            ? 'focus_input'
                                            : ''
                                    }
                                />
                            </InputContainer>
                        ))}
                    </div>
                </DraftInputWrapper>
            </InputWrapper>
            {contextPopupValues.show && (
                <ContextPopup
                    {...props}
                    tree={treeData.tree}
                    mode={rightPanelState.mode}
                    parentNode={rightPanelState.parentNode}
                    actionAppsList={currentNode.appsList}
                    triggerNode={treeData.tree.value}
                    isFilePicker={contextPopupValues.isFilePicker}
                    addingFlowInsideForEach={currentNode.addingInsideForEach}
                    updateInputValuesWithContext={updateInputValuesWithContext}
                    removeVariableFromInput={(text: any, cb: Function) => removeVariableFromInput(text, cb)}
                    updateVariableFromInput={(newText: any, oldText: any, cb: Function) =>
                        updateVariableFromInput(newText, oldText, cb)
                    }
                    closeContextPopup={() =>
                        setContextPopupValues({
                            ...contextPopupValues,
                            show: false,
                            currentInput: '',
                            isFilePicker: false
                        })
                    }
                />
            )}
        </>
    )
}

const PopupHoverInputWrapperTwo = (props: any) => {
    const { hasLinkedDialogs,automationMeta, active_automation } = useContext(SidePanelContext)
    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <InputWrapper style={{ background: '#F0FFF9', padding: '10px 20px'}}>
            <Header>Approval summary</Header>
            <HoverInputContainer>
                <InputContainer>
                    <Input
                        type="text"
                        value={props.state.description}
                        onChange={(e: any) => props.setState({ ...props.state, description: e.target.value })}
                        className={(isAssistant ? automationMeta.type ==='no_trigger' ?hasLinkedDialogs : active_automation: active_automation) ? 'disabled_with_blue' : ''}
                    />
                    <InputContent>
                        Description
                        <Tooltip
                            className="target customTip _description"
                            zIndex={10000}
                            tagName="span"
                            content="This description will be displayed on your approval info icon in the canvas page."
                            distance={15}
                            forceDirection={true}
                            direction="up-start"
                        >
                            <InfoIcon />
                        </Tooltip>
                    </InputContent>
                </InputContainer>
            </HoverInputContainer>
        </InputWrapper>
    )
}

const ButtonWrapper = (props: any) => {
    const { hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext)

    const isAssistant = getProductId() === 'ASSISTANT'

    return (
        <ButtonContainer className="footer_button_fixed">
            <div className="popup_footer_button_container">
                <Button primary props onClick={props.onCancel}>
                    <label>Cancel</label>
                </Button>
                {(isAssistant ? automationMeta.type ==='no_trigger' ? props.isInputhaveDifferentValues : !active_automation: !active_automation) && (
                    <Button className={props.className} onClick={props.onClick}>
                        <label>Save</label>
                    </Button>
                )}
            </div>
        </ButtonContainer>
    )
}

export type ApprovalPanelStateT = {
    show: boolean
    parentNode: TreeT<NodeT>
    mode: NodeModes
    currentNode: ApprovalResponseT
}

export type ApprovalPanelStateContextT = {
    approvalPanelState: ApprovalPanelStateT
    setApprovalPanelState: (object: any) => void
    setContextPopupValues: (object: any) => void
    contextPopupValues: ContextPopupStateT
}

export const ApprovalPanelStateContext = createContext<ApprovalPanelStateContextT>({
    approvalPanelState: {
        show: false,
        mode: NodeAddT,
        parentNode: { value: Tree, children: [] },
        currentNode: ApprovalInitialState
    },
    setApprovalPanelState: (object: any) => undefined,
    setContextPopupValues: (object: any) => undefined,
    contextPopupValues: {
        show: false,
        inputWithValues: [],
        currentInput: '',
        isMultiple: { path: '', parentKey: '' },
        collectionPicker: {
            isCollectionPicker: false,
            collectionType: emptyInputSchema,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    }
})

const PopupRight = (props: any) => {
    const ApprovalElement = useRef<any>(null)

    const [loading, setLoading] = useState(false)

    const { approvalPanelState, setApprovalPanelState, contextPopupValues, setContextPopupValues } = useContext(
        ApprovalPanelStateContext
    )
    const { rightPanelState, setRightPanelState, saveTree, treeData } = useContext(SidePanelContext)

    const [state, setState] = useState({
        type: 'Start and wait for approval',
        description: ''
    })
    const mode = approvalPanelState.mode

    useEffect(() => {
        const currentMode = rightPanelState.mode

        // compare props and update changes based on following condition
        if (
            !ApprovalElement.current ||
            (ApprovalElement.current &&
                (rightPanelState.mode.mode !== ApprovalElement.current.mode.mode ||
                    (currentMode.mode === NodeMode.Edit &&
                        currentMode.nodeEditInput.id !== ApprovalElement.current.mode.nodeEditInput.id)))
        ) {
            if (currentMode.mode === NodeMode.Add || currentMode.mode === NodeMode.Edit) {
                // next time to compare with previous props
                ApprovalElement.current = rightPanelState

                //show loader while changing modes (such as edit->add or add->edit)
                setLoading(true)

                // update current action panel context state for further child usage
                setApprovalPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ApprovalResponseT
                })

                // call initial API everytime mode changed
                callApplistAPI({
                    ...rightPanelState,
                    currentNode:
                        mode.mode == NodeMode.Edit
                            ? ({
                                  ...rightPanelState.currentNode,
                                  subflowMetaData:
                                      mode.nodeEditInput.meta_data && mode.nodeEditInput.meta_data.subflow
                                          ? { subflow: mode.nodeEditInput.meta_data.subflow }
                                          : { subflow: { hasSubFlow: false } }
                              } as ApprovalResponseT)
                            : (rightPanelState.currentNode as ApprovalResponseT)
                })
            } else {
                ApprovalElement.current = rightPanelState
                currentMode.mode === NodeMode.Loading ? setLoading(true) : setLoading(false)

                setApprovalPanelState({
                    ...rightPanelState,
                    currentNode: rightPanelState.currentNode as ApprovalResponseT
                })
            }
        } else if (
            rightPanelState.mode.mode === NodeMode.Edit &&
            ApprovalElement.current.mode.mode === NodeMode.Edit &&
            rightPanelState.mode.nodeEditInput.id === ApprovalElement.current.mode.nodeEditInput.id
        ) {
            setApprovalPanelState({
                ...approvalPanelState,
                show: true
            })
        }
    }, [rightPanelState.mode])

    useEffect(() => {
        ApprovalElement.current = approvalPanelState

        // to see the details in left side panel while adding or editing
        setRightPanelState({ ...rightPanelState, currentNode: approvalPanelState.currentNode })
    }, [approvalPanelState.currentNode])

    async function callApplistAPI(state: ApprovalPanelStateT) {
        const workspaceName = props.match.params.workspacename

        await getJson(automationAPIURLWithWorkspace(workspaceName, '/actions'))
            .then((unfiltertedAppList: AppsListT) => {
                const actions = unfiltertedAppList.output
                const actionsLens = ramda.lensProp('output')
                const data = ramda.set(actionsLens, actions, unfiltertedAppList) as AppsListT

                if (state.mode.mode === NodeMode.Edit) {
                    const currentNode = state.currentNode as ApprovalResponseT

                    const mode = state.mode as NodeEditT

                    const nextState = produce(currentNode, draftState => {
                        draftState.appsList = data
                        draftState.name = mode.nodeEditInput.name
                        draftState.description = mode.nodeEditInput.description
                    })
                    const triggerNodeOutput = treeData.tree.value

                    const parentNode =
                        triggerNodeOutput.kind === 'AppTrigger' || triggerNodeOutput.kind === 'EventTrigger'
                            ? ({
                                  ...state.parentNode,
                                  value: {
                                      ...state.parentNode.value,
                                      path: state.parentNode.value.path
                                          ? [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ].concat(state.parentNode.value.path)
                                          : [
                                                {
                                                    actionId: triggerNodeOutput.meta_data.action_id,
                                                    nodeId: triggerNodeOutput.id
                                                }
                                            ]
                                  }
                              } as TreeT<NodeT>)
                            : state.parentNode

                    configApiCall(
                        data,
                        parentNode,
                        rightPanelState.mode.mode === NodeMode.Edit,
                        workspaceName,
                        currentNode.addingInsideForEach
                    ).then((configResponse: ActionInputsListT[]) => {
                        const mode = state.mode as NodeEditT

                        let combiningResponseWithTriggerOutput: any = []

                        if (triggerNodeOutput.kind !== 'AppTrigger' && triggerNodeOutput.kind !== 'EventTrigger') {
                            const triggerOutputResponse: ActionInputsListT[] = [
                                {
                                    type: 'success',
                                    output: {
                                        name: triggerNodeOutput.name,
                                        input: [],
                                        app: {
                                            appName: triggerNodeOutput.name,
                                            action: triggerNodeOutput.kind,
                                            pathId: {
                                                nodeId: triggerNodeOutput.id,
                                                actionId: ''
                                            },
                                            appId: '',
                                            createdDate: '',
                                            type: 'trigger',
                                            file: '',
                                            authentication_type: AuthType.None,
                                            id: '',
                                            scope: '',
                                            icon: NoTriggerVariable,
                                            latest: false,
                                            version: ''
                                        },
                                        output: triggerNodeOutput.output
                                    }
                                }
                            ]
                            combiningResponseWithTriggerOutput = triggerOutputResponse.concat(configResponse)
                        } else {
                            combiningResponseWithTriggerOutput = configResponse
                        }
                        const inputs = mode.nodeEditInput.input as any
                        const inps = inputs.slice(0, 7).map((x: any) => {
                            const { value } = x
                            return {
                                ...x,
                                value: value
                                    ? (converToEditorFormat(
                                          value as string,
                                          combiningResponseWithTriggerOutput,
                                          triggerNodeOutput,
                                          x,
                                          {
                                              inputs: inputs.slice(0, 7) as InputFieldT[],
                                              contextPopupValues,
                                              setContextPopupValues
                                          }
                                      ) as EditorState)
                                    : emptyEditorState
                            }
                        })
                        const addedContextValue = produce(state, draft => {
                            mode.nodeOutputConfig = combiningResponseWithTriggerOutput
                            draft.mode = mode
                            draft.currentNode = {
                                ...nextState,
                                input: inps.concat(mode.nodeEditInput.input.slice(7, 10))
                            }
                        })
                        setState({ type: 'Start and wait for approval', description: mode.nodeEditInput.description })
                        setApprovalPanelState({
                            ...addedContextValue
                        })
                    })
                    setLoading(false)
                } else {
                    const currentNode = state.currentNode as ApprovalResponseT

                    const nextState = produce(currentNode, draftState => {
                        draftState.appsList = {
                            ...data
                        }
                    })
                    setApprovalPanelState({
                        ...state,
                        currentNode: nextState
                    })

                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)

                setApprovalPanelState({
                    ...state,
                    mode: {
                        ...PopupError,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong!<br /> Please try again afer sometime.'
                    }
                })
            })
    }

    function saveNode() {
        const updatedNodeValue = approvalPanelState.currentNode.input
            .slice(0, 7)
            .map(y => {
                const { inputHasValue, value, ...rest } = y
                let val = value as EditorState

                if (!value) {
                    return rest as any
                }
                const convertedBlocks = convertToRaw(val.getCurrentContent())
                    .blocks.map(b => convertToServerFormat2(convertToRaw(val.getCurrentContent()).entityMap, b))
                    .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')

                if (convertedBlocks.length == 0) {
                    return rest as any
                }

                return {
                    ...rest,
                    // value: convertToServerFormat(value as any)
                    value: convertedBlocks
                } as InputFieldT
            })
            .concat(approvalPanelState.currentNode.input.slice(7, 10))
        saveTree({
            ...approvalPanelState.currentNode,
            input: updatedNodeValue,
            name: 'Approval',
            description: state.description
        })
    }

    const inputs = approvalPanelState.currentNode.input.slice(0, 7),
        enumDropdown = approvalPanelState.currentNode.input.slice(7, 10) as any

    function disableSave() {
        const currentNode = approvalPanelState.currentNode

        function hasValue(currentValue: any) {
            // to check whether if any context has been selected for this input then enable save
            if (configKinds.some(value => value === currentValue.config.kind)) {
                return typeof currentValue.value == 'number' ? currentValue.value : currentValue.value.length > 0
            }
            return typeof currentValue.value == 'string'
                ? currentValue.value.length > 0
                : currentValue.value !== undefined &&
                  convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.length > 0 &&
                  convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length
                ? true
                : false
        }

        function hasNotValue(currentValue: any) {
            return !currentValue.value ||
                convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.length == 0 ||
                !convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length
                ? true
                : false
        }
        try {
            const isNotValid = !parseExpiryText(enumDropdown[2].value)
            // console.log("before parse" , enumDropdown[2].value,isNotValid)
            const AllrequiredFieldsHasValue = !seperateOptionalFields(inputs as any, true).every(hasValue)
            const AllEnumFieldsHasValue = !enumDropdown.every(hasValue)
            const AllOptionalFieldsHasNotValue = seperateOptionalFields(inputs as any, false)
                .map(hasNotValue)
                .filter(s => s)
            // console.log(AllrequiredFieldsHasValue, AllEnumFieldsHasValue, AllOptionalFieldsHasNotValue.length == 1)
            return (
                AllrequiredFieldsHasValue ||
                AllEnumFieldsHasValue ||
                AllOptionalFieldsHasNotValue.length == 1 ||
                isNotValid
            )
        } catch {
            return true
        }
    }

    function isInputhaveDifferentValues(){
        if(approvalPanelState.mode.mode===NodeMode.Edit){

        const currentNode = approvalPanelState.currentNode

        function hasValue(currentValue: any,i:any) {
             if (configKinds.some(value => value === currentValue.config.kind)) {
                return currentValue.value != ((mode as NodeEditT).nodeEditInput.input[i] as InputFieldT).value
            }
            const value = currentValue.value
            const convertedValue = value
                        ? (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertAsaSingleString(convertToRaw(value.getCurrentContent()).blocks)
                        : ''
                    if (!value || convertedValue.length === 0) {
                        return false
                    }

                    const convertedBlocks =
                        (typeof value == 'string' || Array.isArray(value))
                            ? value
                            : convertToRaw(value.getCurrentContent())
                                  .blocks.map(b =>
                                      convertToServerFormat2(convertToRaw(value.getCurrentContent()).entityMap, b)
                                  )
                                  .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')
            return currentValue.value?convertedBlocks != ((mode as NodeEditT).nodeEditInput.input[i] as InputFieldT).value:false
        }

        if (currentNode.input && currentNode.input) {
            const AllrequiredFieldsHasValue = currentNode.input.some(hasValue)
            return AllrequiredFieldsHasValue
        }

        return false
        }else{
            return true
        }   
    }

    const SidePanelContextValue = useContext(SidePanelContext)
    if (loading) {
        return (
            <PopupWrapper>
                {/* <PopupContainer>
                    <PopupScrollHeight> */}
                <div className="loader_center">
                    <Loader.PopupLoader show={true} />
                </div>
                {/* </PopupScrollHeight>
                </PopupContainer> */}
            </PopupWrapper>
        )
    } else if (approvalPanelState.mode.mode === NodeMode.Error) {
        return (
            <div className="automation_canvas_right">
                <PopupWrapper>
                    <div className="flow_canvas_popup_error">
                        <Error.AddorEdit
                            info={approvalPanelState.mode.error}
                            onClick={() => {
                                cancelCurrentPopup({
                                    approvalPanelState,
                                    SidePanelContext: SidePanelContextValue
                                })
                            }}
                            buttonName={'Okay'}
                        />
                    </div>
                </PopupWrapper>
            </div>
        )
    }
    return (
        <PopupWrapper className="automate_product">
            <PopupContainer>
                <PopupHeader {...props} />
                <PopupScrollHeight>
                    <EmptyDiv>
                        <PopupDropdownWrapper state={state} />
                        <PopupHoverInputWrapper inputs={inputs} {...props} />
                        <PopupDropdownWrapperTwo inputs={enumDropdown} />
                        <PopupHoverInputWrapperTwo state={state} setState={setState} />
                    </EmptyDiv>
                    <ButtonWrapper
                        onClick={saveNode}
                        className={disableSave() ? 'editor_btn_disabled' : ''}
                        onCancel={() =>
                            cancelCurrentPopup({
                                approvalPanelState,
                                SidePanelContext: SidePanelContextValue
                            })
                        }
                        isInputhaveDifferentValues={isInputhaveDifferentValues()}
                    />
                </PopupScrollHeight>
            </PopupContainer>
        </PopupWrapper>
    )

    function cancelCurrentPopup(props: {
        approvalPanelState: ApprovalPanelStateT
        SidePanelContext: RightPanelStateContext
    }) {
        const { approvalPanelState, SidePanelContext } = props

        const { rightPanelState, setRightPanelState, treeData, setEditTreeData, editTreeData } = SidePanelContext

        const currentNode = approvalPanelState.currentNode

        const tree =
            approvalPanelState.parentNode.value.kind == 'ForEach' &&
            approvalPanelState.currentNode.addingInsideForEach &&
            approvalPanelState.parentNode.value.subflow
                ? approvalPanelState.parentNode.value.subflow.children.filter(x => x.value.kind === 'ParallelPath')
                : approvalPanelState.parentNode.children.filter(x => x.value.kind === 'ParallelPath')

        const cancellingNode = currentNode.parallelPathNode.currentParallelPathNode

        if (tree.length < 2) {
            setRightPanelState({
                ...rightPanelState,
                show: false,
                mode: NodeView,
                parentNode: { value: Tree, children: [] },
                currentNode: {
                    ...currentNode,
                    parallelPathNode: {
                        ...currentNode.parallelPathNode,
                        currentParallelPathNode: null
                    }
                }
            })

            const newTree = cancellingNode
                ? updateNode(
                      { ...cancellingNode, name: '' },
                      treeData.tree,
                      cancellingNode.meta_data && cancellingNode.meta_data.subflow
                          ? {
                                addingInsideForEach: currentNode.addingInsideForEach,
                                forEachNodeId: cancellingNode.meta_data.subflow.forEachNodeId,
                                parentNode: cancellingNode.meta_data.subflow.parentId
                            }
                          : undefined
                  )
                : treeData.tree

            setEditTreeData({ ...treeData, tree: newTree })
        } else {
            const node = cancellingNode as NodeT
            const addingFlowInsideForEach = {
                addingInsideForEach: node.meta_data.subflow.hasSubFlow as boolean,
                forEachNodeId: node.meta_data.subflow.forEachNodeId as string,
                parentNode: rightPanelState.parentNode.value
            }
            // for while adding new parallel path
            setRightPanelState({
                ...rightPanelState,
                show: false,
                currentNode: {
                    ...currentNode,
                    parallelPathNode: {
                        ...currentNode.parallelPathNode,
                        currentParallelPathNode: null
                    }
                }
            })

            const newTree = cancellingNode
                ? updateNode({ ...cancellingNode, name: '' }, editTreeData.tree, addingFlowInsideForEach)
                : editTreeData.tree
            setEditTreeData({ ...editTreeData, tree: newTree })
        }
    }
}

const DraftEditor = (props: {
    value: EditorState
    setValue: Function
    infocus: boolean
    disabled: boolean
    focus?: string
    focusContent?: string
    optional?: boolean
}) => {
    const { infocus, optional, disabled } = props

    return (
        <Editor
            focus={props.focus}
            optional={optional}
            focusContent={props.focusContent}
            infocus={infocus}
            disabled={disabled ? disabled : false}
            value={props.value}
            onFocus={() => {}}
            setValue={(editorState: EditorState) => {
                props.setValue(editorState)
                //to update state without changing other value. produce is to use instead of doing {...something}
            }}
        />
    )
}

export const ApprovalPopup = (props: any) => {
    const { rightPanelState } = useContext(SidePanelContext)

    const [approvalPanelState, setApprovalPanelState] = useState({
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as ApprovalResponseT
    })

    const [contextPopupValues, setContextPopupValues] = useState<ContextPopupStateT>({
        show: false,
        inputWithValues: [],
        currentInput: '',
        isMultiple: { path: '', parentKey: '' },
        collectionPicker: {
            isCollectionPicker: false,
            collectionType: emptyInputSchema,
            selectedCollection: {
                name: '',
                value: {
                    $id: '',
                    type: 'collection',
                    item: {
                        type: 'object',
                        properties: {}
                    },
                    title: '',
                    properties: {}
                }
            },
            app: ResponseOutput
        }
    })
    return (
        <ApprovalPanelStateContext.Provider
            value={{
                approvalPanelState,
                setApprovalPanelState,
                contextPopupValues,
                setContextPopupValues
            }}
        >
            <Wrapper className="approval_popup">
                <PopupRight {...props} />
            </Wrapper>
        </ApprovalPanelStateContext.Provider>
    )
}
