/*eslint-disable*/

import * as DateFns from 'date-fns'
import * as R from 'ramda'
import React, { SyntheticEvent, useEffect, useState } from 'react'

import { DialogueT } from '@/Assistant/Dialogue/kind'
import { getAssistants, getDialogueRoots } from '@/Assistant/Mechanisms/action'
import { useJourney } from '@/Journey/JourneyContext'
import { DownloadFromMarketplace, InfoIcon, SearchIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import ImgLoader from '@/common/images/loading.svg'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import { removeURLParams } from '@/common/utils/utils'
import { CardWrapper } from './BotMarketPlaceCard'
import { BotSearchT, BotT, ComboboxCategory, DialogSearchT, DialogT } from './BotMarketPlacePopup'
import {
    BackWrapper,
    BackWrapperLeft,
    BackWrapperRight,
    BoxWrapper,
    CardContainer,
    Header,
    HeaderCount,
    Paragraph,
    SerachInput,
    SerachInputWrapper
} from './BotMarketplace'
import { DownloadBot } from './MarketPlaceUtils'
import { logEvent } from '@/common/utils/api-utils'
import Tooltip from 'react-tooltip-lite'

export const BotSearch = ({
    SearchList,
    setInputValue,
    inputValue,
    renderCards,
    categories,
    getdialogs,
    bots,
    setSearchBot,
    searchBot,
    category,
    onChangeCategory
}: BotSearchT) => {
    return (
        <BoxWrapper>
            <ComboboxCategory
                setInputValue={setInputValue}
                inputValue={inputValue}
                categories={categories}
                value={category}
                onChange={onChangeCategory}
            />
            <SerachInputWrapper>
                <SerachInput
                    type="search"
                    value={searchBot}
                    placeholder="Search bots"
                    onChange={(e: SyntheticEvent) => {
                        const { value } = e.target as HTMLInputElement
                        setSearchBot(value)
                    }}
                />
                <span>
                    <SearchIcon />
                </span>
            </SerachInputWrapper>
            <CardContainer>
                {renderCards.loading ? (
                    <div className="no_data_available">
                        {' '}
                        <img src={ImgLoader} />
                    </div>
                ) : renderCards.error === '' ? (
                    bots && bots.length !== 0 ? (
                        bots.map((data: any) => (
                            <CardWrapper isDialog={renderCards.isDialog} card={data} getCard={getdialogs} />
                        ))
                    ) : (
                        <div className="no_data_available">
                            <p>No bots available</p>
                        </div>
                    )
                ) : (
                    <div className="no_data_available flex-column">
                        <p>{renderCards.error}</p>
                        <Button onClick={() => SearchList()}>
                            <label>Retry</label>
                        </Button>
                    </div>
                )}
            </CardContainer>
        </BoxWrapper>
    )
}
export function DialogSearch({
    renderCards,
    SearchList,
    dialogs,
    isOpenLeft,
    isActiveCard,
    bots,
    workspace,
    setRenderCards,
    getIfNodes,
    setOpenPopup,
    retry,
    botWritePermission
}: DialogSearchT) {
    const [dialog, setDialog] = useState<string>('')
    const [values, setValues] = useState<DialogT[]>(dialogs)
    const [bot, setBot] = useState({ id: '', name: '', description: '' })
    const [isDownload, setIsDownload] = useState({ error: '', loading: false })

    const journeyData = useJourney()

    // const { refetchBots } = useBotsDataProvider()

    useEffect(() => {
        if (dialog === '') {
            setValues(dialogs)
        }
        let filtered = dialogs.filter(data => R.includes(R.toLower(dialog), R.toLower(data.title)))
        setValues(filtered)
    }, [dialog])
    useEffect(() => {
        let botDetails = bots.find((data: BotT) => data.id === dialogs[0].botId)
        if (botDetails !== undefined) {
            setBot({ id: botDetails.id, name: botDetails.name, description: botDetails.decription })
        }
    }, [])
    const DownloadEvent = (botId: string) => {
        setIsDownload({ error: '', loading: true })
        DownloadBot(workspace, botId).then((downloadedResult: any) => {
            // console.log('res', downloadedResult, workspace, journeyData)

            removeURLParams('download-from-marketplace')

            getAssistants(workspace)
                .then(assistantData => {
                    if (assistantData.length > 0) {
                        const updatedRes = assistantData.map(x => ({ ...x, created: DateFns.parseISO(x.created) }))
                        type UnWrapArray<T> = T extends Array<infer U>
                            ? { 0: UnWrapArray<U>; 1: U }[U extends Array<any> ? 0 : 1]
                            : T

                        type AssistantWithDate = UnWrapArray<typeof updatedRes>

                        const sortedBots = R.sort(
                            R.descend<AssistantWithDate>(({ created }) => created.getTime())
                        )(updatedRes)

                        // console.log('sorted', sortedBots)

                        const latestBot = sortedBots[0]

                        logEvent(workspace, {
                            event:"EVENT::ONBOARDING::DOWNLOAD_BOT",event_meta:{
                           name: latestBot.name,
                           description: ""
                        }})
            
                        if (journeyData.type === 'success') {
                            journeyData.value.send({
                                type: 'EVENT::BOT::DOWNLOADED',
                                tag: 'BOT_EVENT',
                                botId: latestBot.id,
                                botName: latestBot.name,
                                journeyType: 'Main'
                            })

                            journeyData.value.send({
                                type: 'EVENT::BOT::CREATED',
                                tag: 'BOT_EVENT',
                                botId: latestBot.id,
                                botName: latestBot.name,
                                journeyType: 'Main'
                            })

                            getDialogueRoots(workspace, latestBot.id).then(async (roots: DialogueT[]) => {
                                const hasIfNode = roots.find(x => x.type === 'if_node') !== undefined
                                const hasFaqNode = roots.find(x => x.type === 'faq_node') !== undefined

                                if (hasFaqNode) {
                                    journeyData.value.send({
                                        type: 'EVENT::FAQ::CREATED',
                                        tag: 'GENERIC',
                                        journeyType: 'FAQ',
                                        refId: latestBot.id
                                    })
                                }

                                if (hasIfNode) {
                                    journeyData.value.send({
                                        type: 'EVENT::DIALOG::CREATED',
                                        tag: 'GENERIC',
                                        journeyType: 'Dialog',
                                        refId: latestBot.id
                                    })
                                }
                            })
                            // setIsDownload({ error: '', loading: false })

                            setTimeout(() => {
                                setIsDownload({ error: '', loading: false })
                                setOpenPopup(false)
    
                                window.location.href = `/workspace/${workspace}/assistant/homepage`
                            }, 2000)
                            // TODO :: tt reloeading page to update Sidebar.needs a fix
                        }
                    }

                    // window.location.href = `/workspace/${workspace}/dialog/homepage?new-bot-from-marketplace=${res.data.bot_id}`
                    // refetchBots({ type: 'downloaded-new-not', bot_id: res.data.bot_id })

                    // retry({ bot_id: res.data.bot_id })
                    // reloadWithoutParams()
                })
                .catch(err => {
                    setIsDownload({
                        error: 'Could not download the bot. Please try again!',
                        loading: false
                    })
                })
        })
    }
    return (
        <>
            {isDownload.loading ? (
                <div className="bot_market_place_loader btn_loader">
                    <img src={ImgLoader} style={{ width: '125px', height: '125px' }} />
                </div>
            ) : (
                <>
                    <BackWrapper>
                        <BackWrapperLeft>
                            <SmallButton onClick={() => setRenderCards({ ...renderCards, isDialog: false })}>
                                <span className="rotate_back_button">
                                    <svg x="0px" y="0px" viewBox="0 0 31.5 31.5">
                                        <path d="M21.2 5c-.4-.4-1.1-.4-1.6 0-.4.4-.4 1.1 0 1.6l8 8H1.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h26.6l-8 8c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l10-10c.4-.4.4-1.1 0-1.6L21.2 5z" />
                                    </svg>{' '}
                                </span>
                                <label>Back</label>
                            </SmallButton>
                            <div>
                                <Header>{bot.name}</Header>
                                <Paragraph>{bot.description}</Paragraph>
                            </div>
                        </BackWrapperLeft>
                        <BackWrapperRight >
                            <Button 
                                className={isDownload.error !== '' ? 'button_with_error' : !botWritePermission ? "nonClickable_btn":""}
                                onClick={() => DownloadEvent(bot.id)}
                                disabled = {!botWritePermission}>
                                <span className="header_button">
                                    <DownloadFromMarketplace />
                                </span>
                                <label>Download</label>
                            </Button>
                            {!botWritePermission && 
                                <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="You don't have access to download the bot."
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            }
                            {isDownload.error !== '' && (
                                <span
                                    className="connect_account_failed botmarketplace_error"
                                    style={{ position: 'absolute' }}
                                >
                                    <div className="error_input_show error_input_show_add">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">{isDownload.error}</span>
                                    </div>
                                </span>
                            )}
                        </BackWrapperRight>
                    </BackWrapper>
                    <BoxWrapper>
                        <HeaderCount>
                            Dialogs <span>({dialogs.length})</span>
                        </HeaderCount>
                        <SerachInputWrapper>
                            <SerachInput
                                type="search"
                                value={dialog}
                                placeholder="Search dialog"
                                onChange={(e: SyntheticEvent) => {
                                    const { value } = e.target as HTMLInputElement
                                    setDialog(value)
                                }}
                            />
                            <span>
                                <SearchIcon />
                            </span>
                        </SerachInputWrapper>
                        <CardContainer className="child_bot">
                            {renderCards.loading ? (
                                <img src={ImgLoader} />
                            ) : renderCards.error === '' ? (
                                values && values.length !== 0 ? (
                                    values.map((data: any) => (
                                        <CardWrapper
                                            isActiveCard={data.id === isActiveCard ? isActiveCard : ''}
                                            isOpenLeft={isOpenLeft}
                                            isDialog={renderCards.isDialog}
                                            card={data}
                                            getCard={getIfNodes}
                                        />
                                    ))
                                ) : (
                                    <div>
                                        <p>No dialogs available</p>
                                    </div>
                                )
                            ) : (
                                <div>
                                    <p>{renderCards.error}</p>
                                    <button onClick={() => SearchList()}>retry</button>
                                </div>
                            )}
                        </CardContainer>
                    </BoxWrapper>
                </>
            )}
        </>
    )
}
