import logo from '@/common/images/logo.png'

import { findActionNode } from '@/Assistant/utils'
import { DraftJST, ExpressionT, converterToEditor, converterToServer } from '../../../Editor/Action'
import { DialogueContextT } from '../../Context'
import { findPathViaRes } from '../../Variable'
import {
    RecogniseServerT,
    combinatorConditionT,
    combinatorValueT,
    conditionT,
    conditionTYPES,
    operatorsT
} from './kind'

const R = require('ramda')

export const generateCond = (cond: conditionT[],isWelcomeDialog?:boolean) => {
    const lastRhs = R.last(cond)

    const remaining = R.compose(R.splitEvery(2), R.reverse, R.init)(cond)

    const toServerFormat = (x: conditionT): RecogniseServerT | any | null => {
        if (x.type == conditionTYPES.FAQ) {
            return {
                type: 'TextMatchExpression',
                prompts: x.queries
            }
        } else if (x.type == conditionTYPES.INTENT) {
            return {
                type: 'IntentExpression',
                value: x.name
            }
        } else if (x.type == conditionTYPES.ENTITY) {
            if (x.value !== '') {
                if (x.operator === operatorsT.IS) {
                    return { type: 'EntityValueExpression', entity: x.name, value: x.value, negation: false }
                } else {
                    return { type: 'EntityValueExpression', entity: x.name, value: x.value, negation: true }
                }
            } else {
                if (x.operator === operatorsT.IS_PRESENT) {
                    return { type: 'EntityExpression', entity: x.name, negation: false }
                } else if (x.operator === operatorsT.IS_NOT_PRESENT) {
                    return { type: 'EntityExpression', entity: x.name, negation: true }
                }

                return {
                    type: 'EntityExpression',
                    entity: x.name
                }
            }
        } else if (x.type == conditionTYPES.CONTEXT) {
            if (x.operator === operatorsT.EQUALS_TEXT) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'equals'
                    }
                }
            } else if (x.operator === operatorsT.DOES_NOT_EQUALS_TEXT) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_equals'
                    }
                }
            }else if (x.operator === operatorsT.CONTAINS) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'contains'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'contains'
                    }
                }
            }else if (x.operator === operatorsT.DOES_NOT_CONTAINS) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_contains'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_contains'
                    }
                }
            } else if (x.operator === operatorsT.EQUALS_NUMBER) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'equals'
                    }
                }
            } else if (x.operator === operatorsT.DOES_NOT_EQUALS_NUMBER) {
                // need to change
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'not_equals'
                    }
                }
            } else if (x.operator === operatorsT.IS_TRUE) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: true,
                        op: 'equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: true,
                        op: 'equals'
                    }
                }
            } else if (x.operator === operatorsT.IS_FALSE) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: false,
                        op: 'equals'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: false,
                        op: 'equals'
                    }
                }
            } else if (x.operator === operatorsT.GREATER_THAN) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'greater_than'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'greater_than'
                    }
                }
            } else if (x.operator === operatorsT.LESS_THAN) {
                if (x.LHS.type == 'context') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'path',
                            path: x.LHS.path
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'less_than'
                    }
                } else if (x.LHS.type === 'entity') {
                    return {
                        type: 'ComparisionExpression',
                        lhs: {
                            type: 'entity',
                            entity_name: x.LHS.name
                        },
                        rhs: converterToServer(x.RHS as DraftJST),
                        op: 'less_than'
                    }
                }
            }
            return null
        }
    }

    const reducer = R.reduce((accum: any, [combinator, value]: any[],index:number) => {
        const serverValue = toServerFormat(value)
        return isWelcomeDialog && accum.type != 'LogicalExpression' ?  {
            type: 'LogicalExpression',
            "or":{
                lhs:{ type:"WelcomeExpression" },
                rhs:{
                    type: 'LogicalExpression',
                    [combinator.value.toLowerCase()]: {
                        lhs: {
                            ...serverValue
                        },
                        rhs: {
                            ...accum
                        }
                    }
            }
        }
        } : {
            type: 'LogicalExpression',
            [combinator.value.toLowerCase()]: {
                lhs: {
                    ...serverValue
                },
                rhs: {
                    ...accum
                }
            }
        }
    }, toServerFormat(lastRhs))

    return isWelcomeDialog && cond.length==1?
    {
            type: 'LogicalExpression',
            "or":{
                lhs:{ type:"WelcomeExpression" },
                rhs:{
                    ...toServerFormat(lastRhs)
                }}
    }:reducer(remaining)
}

export const reverseCond = (cond: any, dialogContext?: DialogueContextT, assistantID?: string): conditionT[] => {
    const clientFormat = (x: RecogniseServerT): conditionT => {
        if (x) {
            if (x.type === 'TextMatchExpression') {
                return {
                    type: conditionTYPES.FAQ,
                    queries: x.prompts
                }
            } else if (x.type === 'IntentExpression') {
                return {
                    type: conditionTYPES.INTENT,
                    name: x.value,
                    operator: operatorsT.IS_PRESENT
                }
            } else if (x.type === 'EntityExpression') {
                return {
                    type: conditionTYPES.ENTITY,
                    name: x.entity,
                    operator: !x.negation ? operatorsT.IS_PRESENT : operatorsT.IS_NOT_PRESENT,
                    value: ''
                }
            } else if (x.type === 'EntityValueExpression') {
                return {
                    type: conditionTYPES.ENTITY,
                    name: x.entity,
                    operator: !x.negation ? operatorsT.IS : operatorsT.IS_NOT,
                    value: x.value
                }
            } else if (x.type === 'ComparisionExpression') {
                const path = x.lhs.path
                let dummypath = path
                let variable = x.lhs.path[x.lhs.path.length - 1]
                if (dialogContext && x.lhs.path.includes('errorContext')) {
                    const automationContext = dialogContext.workspaceDescendents.reduce((acc: any, va: any) => {
                        const result =
                            va.type === 'if_node'
                                ? va.responses.filter((r: any) => r.output_variable == x.lhs.path[0])[0]
                                : null

                        if (result) {
                            return result
                        }
                        return acc
                    }, null)
                    const auto =
                        automationContext &&
                        ((dialogContext.automationContext as any)[(automationContext as any).parent]
                            ? (Object.values(
                                  (dialogContext.automationContext as any)[(automationContext as any).parent]
                              ) as any)[0]
                            : Object.values(dialogContext.automationContext).reduce((acc, v: any) => {
                                  const auto = v[(automationContext as any).id]
                                  if (auto) {
                                      return auto
                                  }
                                  return acc
                              }, null))

                    const action_id =
                        auto && findActionNode(x.lhs.path.slice(-2, -1)[0], auto.custom_json.children[0].children[0])

                    const errors =
                        action_id &&
                        dialogContext.actionMixture[action_id.value.meta_data.action_id].errors[`${R.last(x.lhs.path)}`]

                    // dummypath = errors
                    //     ? x.lhs.path.map((p: any, i: number) => (i + 1 == x.lhs.path.length ? errors.message : p))
                    //     : path
                    dummypath =
                        automationContext && errors
                            ? [
                                  dialogContext.assistant.name,
                                  ...findPathViaRes(
                                      dialogContext.workspaceDescendents,
                                      automationContext.id,
                                      [],
                                      x.lhs.path.includes('errorContext'),
                                      x.lhs.path[0]
                                  ),
                                  automationContext.automation_name
                              ].concat(errors.message)
                            : dummypath

                    variable = errors ? errors.message : x.lhs.path[x.lhs.path.length - 1]
                }
                if (x.op === 'equals') {
                    if (x.rhs === true) {
                        return {
                            type: conditionTYPES.CONTEXT,
                            operator: operatorsT.IS_TRUE,
                            LHS: {
                                type: 'context',
                                variable: variable,
                                path: x.lhs.path,
                                dummypath: dummypath,
                                icon: logo,
                                dataType: 'boolean'
                            },
                            RHS: true
                        }
                    } else if (x.rhs === false) {
                        return {
                            type: conditionTYPES.CONTEXT,
                            operator: operatorsT.IS_FALSE,
                            LHS: {
                                variable: variable,
                                type: 'context',
                                path: x.lhs.path,
                                dummypath: dummypath,
                                icon: logo,
                                dataType: 'boolean'
                            },
                            RHS: false
                        }
                    }
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.EQUALS_TEXT,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'string'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                } else if (x.op === 'not_equals') {
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.DOES_NOT_EQUALS_TEXT,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'string'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                } else if (x.op === 'contains') {
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.CONTAINS,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'string'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                } else if (x.op === 'not_contains') {
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.DOES_NOT_CONTAINS,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'string'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                } else if (x.op === 'greater_than') {
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.GREATER_THAN,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'number'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                } else if (x.op === 'less_than') {
                    return {
                        type: conditionTYPES.CONTEXT,
                        operator: operatorsT.LESS_THAN,
                        LHS: {
                            type: 'context',
                            variable: variable,
                            path: x.lhs.path,
                            dummypath: dummypath,
                            icon: logo,
                            dataType: 'number'
                        },
                        RHS: converterToEditor(x.rhs as ExpressionT, dialogContext, assistantID)
                    }
                }
            }
        }
        return {
            type: conditionTYPES.INTENT,
            name: '',
            operator: operatorsT.SELECT
        }
    }

    const isAnd = R.has('and')

    const fetchBack = (obj: any, data: conditionT[]): conditionT[] => {
        if (obj && obj.type === 'LogicalExpression') {
            if (isAnd(obj) === true) {
                const andCOmbinator: combinatorConditionT = {
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                }
                return fetchBack(obj.and.rhs, [...data, clientFormat(obj.and.lhs), andCOmbinator])
            } else {
                const orCOmbinator: combinatorConditionT = {
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.OR
                }
                return fetchBack(obj.or.rhs, [...data, clientFormat(obj.or.lhs), orCOmbinator])
            }
        }
        return data.concat([clientFormat(obj)])
    }
    return fetchBack(cond, [])
}
