import produce from 'immer'
/*eslint-disable*/
import React, { createContext, useEffect, useState } from 'react'

import { VideoButtonB } from '@/Videos/Videos'
import { ArrowIcon, DialogIcon, DownloadFromMarketplace, FlowCanvasIcons, WebhooksIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { FlowCanvasHeader, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import arrowImage from '@/common/images/arrow_icon.svg'
import { loadAutomations } from '../../FlowWrapper'
import { FlowResponseT, FlowsResponseT } from '../../types'
import { HeaderInner } from '../Canvas'
import { NonCanvasActionModes, NonCanvasActions, ReactSetStateObject } from '../types'
import { CanvasLeftPanel } from './MarketLeftPanel'
import * as MarketPlaceRightPanel from './MarketPlaceRightPanel'
import { NodePosition, Tree as T } from './Tree'
import { acknowledge, automationDownloadAction } from './downloadAction'
import {
    ActionInitialState,
    AppTriggerInitialState,
    AutomationMeta,
    DefaultTree,
    ErrorPage,
    LeftPanelStateContextT,
    LeftPanelStateT,
    LeftSidePanelDefaultValue,
    Loading,
    NoTriggerInitialState,
    NodeEdit,
    NodeMode,
    NodeT,
    NodeView,
    NotFoundPage,
    RightPanelStateContext,
    RightPanelStateT,
    Tree,
    TreeT
} from './types'
import { getProductId } from '@/common/utils/utils'

const RightPanel: React.FC = MarketPlaceRightPanel.MarketPlaceRightPanel.Component

export type UploadToMarketPlaceContext = {
    actionState: ReactSetStateObject<NonCanvasActions>
    parentAutomation: AutomationMeta
    parentId: string
    workspaceName: string
}

export const NonCanvasPanelContext = createContext<UploadToMarketPlaceContext>({
    actionState: [] as any,
    parentId: '',
    workspaceName: '',
    parentAutomation: {
        name: '',
        description: '',
        active_status: false,
        deployed_status: 'saved',
        type: '',
        nodesWithoutConnection: [],
        isLinkedToDialog: false
        // isLinkedTo
    }
})

export const SidePanelContext = createContext<RightPanelStateContext>({
    rightPanelState: {
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: ActionInitialState
    },
    setRightPanelState: (_: any) => undefined,
    editTreeData: { value: Tree, children: [] },
    setEditTreeData: (_: any) => undefined,
    treeData: { value: Tree, children: [] },
    disableDeployButton: [],
    setDisableDeployButton: (value: any) => ({}),
    active_automation: false
})

export const LeftSidePanelContext = createContext<LeftPanelStateContextT>({
    leftPanelState: LeftSidePanelDefaultValue,
    setLeftPanelState: (object: any) => undefined
})

export function MarketPlaceFlowCanvas(props: any) {
    const automationId: string = props.match.params.id
    const workspace: string = props.match.params.workspacename
    const [automationMeta, setAutomationMeta] = useState<AutomationMeta>({
        name: '',
        description: '',
        active_status: false,
        deployed_status: 'saved',
        type: 'no_trigger',
        nodesWithoutConnection: [],
        isLinkedToDialog: false
    })

    const [treeData, setTreeData] = useState({
        firstStepDone: false,
        secondStepDone: DefaultTree.children[0].children.length > 0,
        tree: DefaultTree
    })

    const [editTreeData, setEditTreeData] = useState({
        firstStepDone: false,
        secondStepDone: DefaultTree.children[0].children.length > 0,
        tree: DefaultTree
    })

    const [rightPanelState, setRightPanelStateChange] = useState<RightPanelStateT>({
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: ActionInitialState
    })
    const [leftPanelState, setLeftPanelState] = useState<LeftPanelStateT>(LeftSidePanelDefaultValue)

    const [disableDeployButton, setDisableDeployButton] = useState<boolean[]>([false])

    const setRightPanelState = (x: RightPanelStateT) => {
        setRightPanelStateChange(x)
    }

    const [nonCanvasAction, setNonCanvasAction] = useState<NonCanvasActions>(Loading)

    const [setToDownloadAction, renderDeployAutomation] = automationDownloadAction(
        automationId,
        [nonCanvasAction, setNonCanvasAction],
        workspace
    )

    const [renderAcknowledgeModal] = acknowledge([nonCanvasAction, setNonCanvasAction])

    useEffect(() => {
        setEditTreeData(treeData)
    }, [treeData.tree])

    useEffect(() => {
        const nextProps = props
        if (nonCanvasAction.mode !== nextProps.state.type) {
            if (nextProps.state.type == 'loading') {
                setNonCanvasAction(Loading)
            } else if (nextProps.state.type == 'error') {
                if (nextProps.state.error.response && nextProps.state.error.response.status == 404) {
                    setNonCanvasAction(NotFoundPage)
                    return
                } else {
                    setNonCanvasAction(ErrorPage)
                    return
                }
            } else if (nextProps.state.type == 'success') {
                const flowList = nextProps.state.data

                if (
                    flowList.output &&
                    flowList.output.custom_json &&
                    Object.keys(flowList.output.custom_json).length > 0
                ) {
                    if (flowList.output.deployed_status != 'deployed') {
                        const nextState: {
                            firstStepDone: boolean
                            secondStepDone: boolean
                            tree: TreeT<NodeT>
                        } = produce(treeData, draftState => {
                            draftState.tree = flowList.output.custom_json
                            draftState.secondStepDone = flowList.output.custom_json.children[0].children.length > 0
                            draftState.firstStepDone = true
                        })

                        setAutomationMeta({
                            ...automationMeta,
                            name: flowList.output.name,
                            description: flowList.output.description,
                            type: flowList.output.type
                        })

                        props.setHeaderDetails &&
                            props.setHeaderDetails({
                                title: flowList.output.name,
                                description: flowList.output.description
                            })

                        setTreeData(nextState)
                    } else {
                        loadAutomations(workspace)
                            .then((response: { output: FlowsResponseT; type: string }) => {
                                const filteredAutomation: FlowResponseT = response.output.automations.filter(
                                    automation => automation.id == flowList.output.id
                                )[0]
                                const nextState: {
                                    firstStepDone: boolean
                                    secondStepDone: boolean
                                    tree: TreeT<NodeT>
                                } = produce(treeData, draftState => {
                                    draftState.tree = flowList.output.custom_json
                                    draftState.secondStepDone =
                                        flowList.output.custom_json.children[0].children.length > 0
                                    draftState.firstStepDone = true
                                })

                                setAutomationMeta({
                                    ...automationMeta,
                                    name: flowList.output.name,
                                    description: flowList.output.description,
                                    type: flowList.output.type
                                })

                                setTreeData(nextState)
                            })
                            .catch(() => {
                                setNonCanvasAction(ErrorPage)
                            })
                    }
                    setNonCanvasAction(props.state)

                } else {
                    const nextState: {
                        firstStepDone: boolean
                        secondStepDone: boolean
                        tree: TreeT<NodeT>
                    } = produce(treeData, draftState => {
                        draftState.tree = DefaultTree
                        draftState.secondStepDone = false
                        draftState.firstStepDone = false
                    })

                    setAutomationMeta({
                        ...automationMeta,
                        name: flowList.output.name,
                        description: flowList.output.description,
                        type: flowList.output.type
                    })

                    setTreeData(nextState)

                    setRightPanelState({
                        ...rightPanelState,
                        show: true,
                        currentNode: NoTriggerInitialState,
                        mode: {
                            ...NodeEdit,
                            nodeEditInput: DefaultTree.value
                        }
                    })

                    setNonCanvasAction(props.state)

                }
            }
        }

    }, [props.state])

    const currentTree = rightPanelState.mode.mode == NodeMode.View ? treeData : editTreeData

    if (nonCanvasAction.mode === NonCanvasActionModes.Loading)
        return <Loader.PageLoader className="flow_canvas_page_loader" show={true} />

    return (
        <ErrorBoundary
            resourceNotFound={nonCanvasAction.mode === NonCanvasActionModes.NotFound}
            error={{
                error: nonCanvasAction.mode === NonCanvasActionModes.Error,
                info: 'Something went wrong!<br /> Please try again after sometime.'
            }}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.PageLoadingError
                            onClick={() => {
                                setNonCanvasAction(Loading), props.retry()
                            }}
                            btnName="Retry"
                            info={info}
                        />
                    )
                )
            }}
        >
            <SidePanelContext.Provider
                value={{
                    rightPanelState,
                    setRightPanelState,
                    editTreeData,
                    setEditTreeData,
                    treeData,
                    disableDeployButton,
                    setDisableDeployButton,
                    active_automation: automationMeta.active_status
                }}
            >
                <React.Fragment>
                    <div className="automation_canvas">
                        <div className="flex_canvas_right">
                            {props.showLeftPanel && (
                                <LeftSidePanelContext.Provider
                                    value={{
                                        leftPanelState,
                                        setLeftPanelState
                                    }}
                                >
                                    <div className="flex_side_menu">
                                        <NonCanvasPanelContext.Provider
                                            value={{
                                                actionState: [nonCanvasAction, setNonCanvasAction],
                                                parentAutomation: automationMeta,
                                                workspaceName: workspace,
                                                parentId: automationId
                                            }}
                                        >
                                            <CanvasLeftPanel />
                                        </NonCanvasPanelContext.Provider>
                                    </div>
                                </LeftSidePanelContext.Provider>
                            )}
                            <section className="entity_cards automation_entity_cards">
                                <FlowCanvasHeader>
                                    <HeaderChild type="first" key={0}>
                                        <div className="indent_left">
                                            <div className="indent_title autoamtion_p_l_30">
                                                {/* <h2 className="flow_canvas_header_back_button">
                                                    <span
                                                        className="buton_back inner_pages"
                                                        onClick={() => window.history.back()}
                                                    >
                                                        <ArrowIcon /> Back
                                                    </span>
                                                    {automationMeta.name}
                                                </h2>
                                                <p className="buton_back_para">{automationMeta.description}</p> */}
                                                <div className="indent_title autoamtion_p_l_30">
                                                    <HeaderInner>
                                                        <div className="indent_title_wrapper">
                                                            <div className="indent_title_left">
                                                                <SmallButton onClick={() => window.history.back()}>
                                                                    <span>
                                                                        <ArrowIcon />
                                                                    </span>{' '}
                                                                    <label>Back</label>
                                                                </SmallButton>
                                                            </div>
                                                            <div className="indent_title_right">
                                                                <div className="flow_canvas_header_back_button market_place">
                                                                    {automationMeta.name}
                                                                </div>
                                                                <p>{automationMeta.description}</p>
                                                            </div>
                                                        </div>
                                                    </HeaderInner>
                                                </div>
                                            </div>
                                        </div>
                                    </HeaderChild>
                                    <HeaderChild type="second" key={1}>
                                        <div className="flow_canvas_header_right_automation autoamtion_p_r_40">
                                            <h2 className="flow_canvas_header_back_button">
                                                {/* <Button
                                                    onClick={() => {
                                                        setRightPanelState({
                                                            ...rightPanelState,
                                                            currentNode: HowItWorksState,
                                                            show: true
                                                        })
                                                    }}
                                                >
                                                    <span>
                                                        <MarketPlaceSettingIcon />
                                                    </span>
                                                    <label>How it works</label>
                                                </Button> */}
                                                <Button className='marketplace_btn' onClick={() => setToDownloadAction()}>
                                                    <span className="header_button">
                                                        <DownloadFromMarketplace />
                                                    </span>
                                                    <label>Download</label>
                                                </Button>
                                                <VideoButtonB id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} />
                                            </h2>
                                        </div>{' '}
                                    </HeaderChild>
                                </FlowCanvasHeader>

                                <section className="automation_canvas">
                                    <div className="automation_canvas_left">
                                        <div
                                            className="automation_canvas_left_auto_width"
                                            id="automation_canvas_left_auto_width"
                                        >
                                            <div className="canvas_fullwidth">
                                                {/* <div className="automation_canvas_left_structure"> */}
                                                <div className="flow_structure">
                                                    <ul className="auto_width_panel" id="auto_width_panel">
                                                        <li className="second_step">
                                                            <SecondStepNode
                                                                node={currentTree.tree.children[0]}
                                                                index={0}
                                                                show={currentTree.firstStepDone}
                                                                secondStepChildren={
                                                                    <RenderingAllChildren
                                                                        node={currentTree.tree.children[0]}
                                                                        index={0}
                                                                    />
                                                                }
                                                            >
                                                                <TriggerNode
                                                                    TreeData={currentTree.tree}
                                                                    show={true}
                                                                    onClick={() => {
                                                                        rightPanelState.mode.mode === NodeMode.View &&
                                                                            setRightPanelState({
                                                                                ...rightPanelState,
                                                                                show: true,
                                                                                currentNode:
                                                                                    currentTree.tree.value.kind ==
                                                                                    'NoTrigger'
                                                                                        ? NoTriggerInitialState
                                                                                        : AppTriggerInitialState,
                                                                                mode: {
                                                                                    ...NodeEdit,
                                                                                    nodeEditInput:
                                                                                        currentTree.tree.value
                                                                                }
                                                                            })
                                                                    }}
                                                                />
                                                            </SecondStepNode>

                                                            {/* <EndNode
                                                                show={currentTree.secondStepDone}
                                                                TreeData={currentTree.tree.children[1]}
                                                                color={'#fcded3'}
                                                                onClick={() => {
                                                                    rightPanelState.mode.mode === NodeMode.View &&
                                                                        setRightPanelState({
                                                                            ...rightPanelState,
                                                                            show: true,
                                                                            currentNode: EndResponseInitialState,
                                                                            mode: {
                                                                                ...NodeEdit,
                                                                                nodeEditInput:
                                                                                    currentTree.tree.children[1].value
                                                                            }
                                                                        })
                                                                }}
                                                            /> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <NonCanvasPanelContext.Provider
                                        value={{
                                            actionState: [nonCanvasAction, setNonCanvasAction],
                                            parentAutomation: automationMeta,
                                            workspaceName: workspace,
                                            parentId: automationId
                                        }}
                                    >
                                        <RightPanel
                                            {...props}
                                            showUploadPanel={
                                                nonCanvasAction.mode == NonCanvasActionModes.UploadToMarketPlace
                                            }
                                        />
                                    </NonCanvasPanelContext.Provider>
                                </section>
                            </section>
                        </div>
                        {renderAcknowledgeModal()}
                        {renderDeployAutomation()}
                    </div>
                </React.Fragment>
            </SidePanelContext.Provider>
        </ErrorBoundary>
    )
}

function DefaultStepNodes(props: { TreeData: TreeT<NodeT>; show: boolean; color: string; className: string }) {
    const { TreeData, show, color, className } = props

    return (
        <div
            className={
                show ? `flow_tree flow_parent ${className}` : `flow_tree flow_parent editor_btn_disabled ${className}`
            }
        >
            <div className="first_step">
                <span className="bg_tree_flow" style={{ background: color }}>
                    <img src={TreeData.value.icon} alt="" />
                </span>
                <span className="bg_tree_content">{'Then'}</span>
            </div>
            {(props.className === 'start_step_node' || props.className === 'end_step_node') && (
                <span className="arrow">
                    <img src={arrowImage} />
                </span>
            )}
        </div>
    )
}

function EndNode(props: { TreeData: TreeT<NodeT>; show: boolean; color: string; onClick: () => void }) {
    const { TreeData, show, color } = props

    return (
        <div
            className={
                show ? `flow_tree flow_parent end_step_node` : `flow_tree flow_parent editor_btn_disabled end_step_node`
            }
            onClick={props.onClick}
        >
            <div className="first_step">
                <span className="bg_tree_flow" style={{ background: color }}>
                    <img src={TreeData.value.icon} alt="" />
                </span>
                <span className="bg_tree_content">Execution Report</span>
            </div>
            <span className="arrow">
                <img src={arrowImage} />
            </span>
        </div>
    )
}

const TriggerNode = (props: { TreeData: TreeT<NodeT>; show: boolean; onClick: any }) => {
    const { TreeData, show } = props
    return (
        <div
            className={
                show
                    ? `flow_tree flow_parent start_step_node`
                    : `flow_tree flow_parent editor_btn_disabled start_step_node`
            }
            // onClick={props.onClick}
        >
            <div className="first_step">
                {TreeData.value.kind !== 'AppTrigger' && TreeData.value.kind !== 'EventTrigger' ? (
                    getProductId() !== "ASSISTANT" ?
                        <span className="bg_tree_flow" style={{ background: '#E0F6FF' }}>
                            <WebhooksIcon />
                        </span>
                        :  
                        <span className="bg_tree_flow" style={{ background: 'rgb(224, 246, 255)' }}>
                            <DialogIcon />
                        </span>
                ) : (
                    <span className="tree_node bg_tree_flow">
                        <img src={TreeData.value.icon} alt="" />
                    </span> 
                )}
                
                {getProductId() === "ASSISTANT" ? 
                    <span className="bg_tree_content">                        
                        {!TreeData.value.name.includes('Step 1') ? 'Triggered by Chatbot ' : TreeData.value.name}
                    </span>
                    : 
                    <span className="bg_tree_content">
                        {TreeData.value.name}
                    </span>
                }
            </div>
            {/* <span className="arrow">
                <img src={arrowImage} />
            </span> */}
        </div>
    )
}

function SecondStepNode(props: {
    node: TreeT<NodeT>
    index: number
    show: boolean
    children: React.ReactChild
    secondStepChildren: React.ReactChild
}) {
    const { node, index, show } = props

    return (
        <React.Fragment>
            <div className="step1_vertical_wrapper">
                <div className="start_workflow_if">
                    <div className="start_workflow_if_badge">
                        <p>
                            <i>Start workflow if</i>
                        </p>
                    </div>
                    {props.children}
                </div>
            </div>
            <div className="step2_vertical_wrapper">
                <>
                    <div className="then_vertical">
                        <div className="start_workflow_if_badge">
                            <p>
                                <i>Then</i>
                            </p>
                        </div>
                    </div>

                    {show && secondStepChild(node, index, node)}
                    {props.secondStepChildren}
                </>
            </div>

            {/* {show && node.children.length === 0 ? (
                secondStepChild(node, index, node)
            ) : (
                <DefaultStepNodes show={show} TreeData={node} color={'#e6edf6'} className="" />
            )} */}
        </React.Fragment>
    )
}

function secondStepChild(node: TreeT<NodeT>, index: number, parentNode: TreeT<NodeT>) {
    return (
        <T.SecondStepChildWithWrap
            hasSingleChildern={node.children.length === 1}
            node={node}
            render={(makeDropDownVisible: () => void, MenuElement: React.ReactNode) => {
                return (
                    <div className={`flow_tree flow_parent second_step_node`}>
                        <div className="line_hover_plus_icon">
                            <div className="line_hover_plus_icon_svg" onClick={makeDropDownVisible}>
                                <FlowCanvasIcons.ArrowPlusIcon />
                            </div>
                        </div>
                        <div className="second_node_first_step">
                            <span className="bg_tree_flow" style={{ background: '#e6edf6' }}>
                                <img src={node.value.icon} alt="" />
                            </span>
                            <span className="bg_tree_content">{node.value.name}</span>
                        </div>
                        {MenuElement}
                    </div>
                )
            }}
        />
    )
}

function RenderingAllChildren(props: { node: TreeT<NodeT>; index: number }) {
    const { node, index } = props

    return node.children.length > 0 ? (
        <div className="panel_tree">
            {node.children.length === 1 ? singleChild(node.children[0], index, false) : multiChild(node, index)}
        </div>
    ) : null
}

function singleChild(node: TreeT<NodeT>, index: number, comingUnderParallelPath: boolean) {
    return (
        <T.SingleChildWrap node={node}>
            {constructNode(node, index, { kind: 'single' }, comingUnderParallelPath ? comingUnderParallelPath : false)}
        </T.SingleChildWrap>
    )
}

function populateForEachChilds(subflow: { children: TreeT<NodeT>[] } | undefined, index: number, node: TreeT<NodeT>) {
    if (!subflow || !subflow.children || subflow.children.length == 0) {
        return null
    } else {
        const childs = subflow as TreeT<NodeT>
        return (
            <>
                {childs.children.length == 1
                    ? singleChild(childs.children[0], index, false)
                    : multiChild(childs, index)}
            </>
        )
    }
}

function multiChild(nodes: TreeT<NodeT>, index: number) {
    return (
        <T.MultiChildWrap>
            {nodes.children.map((node: TreeT<NodeT>, index: number) => {
                const position: NodePosition = {
                    kind: 'multi',
                    positionType: index % 2 != 0 ? 'odd' : 'even'
                }
                return constructNode(node, index, position, true)
            })}
        </T.MultiChildWrap>
    )
}

function constructNode(node: TreeT<NodeT>, index: number, position: NodePosition, comingUnderParallelPath: boolean) {
    if (node.children.length === 0) {
        if (node.value.kind === 'ParallelPath') {
            return <T.Node.EmptyParallelNode key={node.value.id} index={index} node={node} />
        } else if (node.value.kind === 'Action' || node.value.kind == 'Function' || node.value.kind === 'Approval' || node.value.kind === 'Timer' || node.value.kind === 'ErrorHandlingCondition'|| node.value.kind === 'Condition') {
            return (
                <T.Node.EndNodeWarp key={node.value.id} node={node}>
                    <T.Node
                        node={node}
                        index={index}
                        position={position}
                        comingUnderParallelPath={comingUnderParallelPath}
                    />
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind == 'ForEach') {
            return (
                <T.Node.EndNodeWarp key={node.value.id} node={node}>
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                </T.Node.EndNodeWarp>
            )
        } else if (node.value.kind === 'Repeat') {
            return (
                <T.Node.RepeatNode key={node.value.id} node={node}>
                    <T.Node node={node} index={index} position={position} />
                </T.Node.RepeatNode>
            )
        } else return null
    } else if (node.children.length === 1) {
        if (node.value.kind == 'ForEach') {
            return (
                <T.Node.Wrap key={node.value.id} node={node}>
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                    {singleChild(node.children[0], index, comingUnderParallelPath)}
                </T.Node.Wrap>
            )
        }
        return (
            <T.Node.Wrap key={node.value.id} node={node}>
                <T.Node
                    node={node}
                    index={index}
                    position={position}
                    comingUnderParallelPath={comingUnderParallelPath}
                />
                {singleChild(node.children[0], index, comingUnderParallelPath)}
            </T.Node.Wrap>
        )
    } else {
        if (node.value.kind === 'ParallelPath' && node.value.name.length > 0 && node.children.length > 1) {
            // for handling error nodes
            return (
                <T.Node.Wrap key={node.value.id}>
                    <T.Node.EmptyNode key={node.value.id} node={node} />
                    {multiChild(node, index)}
                </T.Node.Wrap>
            )
        }
        if (node.value.kind === 'ParallelPath') {
            return (
                <T.Node.Wrap key={node.value.id + index}>
                    <T.Node.EmptyParallelNode key={node.value.id} node={node} />
                    {multiChild(node, index)}
                </T.Node.Wrap>
            )
        } else if (node.value.kind == 'ForEach') {
            return (
                <T.Node.Wrap key={node.value.id} node={node}>
                    <T.ForEachNode
                        node={node}
                        index={index}
                        position={position}
                        comingUnderParallelPath={comingUnderParallelPath}
                    >
                        {populateForEachChilds(node.value.subflow, index, node)}
                    </T.ForEachNode>
                    {multiChild(node, index)}
                </T.Node.Wrap>
            )
        }
        return (
            <T.Node.Wrap key={node.value.id} node={node}>
                <T.Node
                    node={node}
                    index={index}
                    position={position}
                    comingUnderParallelPath={comingUnderParallelPath}
                />
                {multiChild(node, index)}
            </T.Node.Wrap>
        )
    }
}

const MarketPlaceSettingIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <g>
            <g>
                <g>
                    <path
                        d="M15.3,18.5c-0.5,0-0.9-0.4-1-0.9c-0.1-0.7,0.2-2.2,1.7-3.4c0.5-0.4,0.6-0.7,0.6-1.1c0-0.7-0.6-1.3-1.2-1.3
				c-0.7,0-1.2,0.5-1.2,1.2c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.5,3.2,3.3c0,1.1-0.4,1.9-1.3,2.7
				c-1,0.8-0.9,1.6-0.9,1.7C16.3,18,15.9,18.5,15.3,18.5C15.3,18.5,15.3,18.5,15.3,18.5z"
                    />
                </g>
                <g>
                    <path
                        d="M15.3,20.8c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.9-0.6-0.8-1.2c0.1-0.5,0.6-0.9,1.2-0.8c0.5,0.1,0.9,0.6,0.8,1.1l0,0
				C16.2,20.5,15.8,20.8,15.3,20.8z"
                    />
                </g>
            </g>
        </g>
        <g>
            <g>
                <g>
                    <path
                        d="M16.9,27.2H14c-0.5,0-0.9-0.4-1-0.9l-0.3-2.3c-0.5-0.2-1-0.4-1.5-0.6l-1.8,1.4c-0.4,0.3-1,0.3-1.3-0.1l-2-2
				c-0.4-0.3-0.4-0.9-0.1-1.3l1.4-1.9c-0.3-0.5-0.5-1-0.7-1.6l-2.2-0.3c-0.5-0.1-0.9-0.5-0.9-1v-2.9c0-0.5,0.4-0.9,0.9-1l2.3-0.3
				c0.2-0.5,0.4-1.1,0.7-1.6L5.8,9.1c-0.3-0.4-0.3-1,0.1-1.3l2-2c0.4-0.4,0.9-0.4,1.3-0.1L11.1,7c0.5-0.3,1-0.5,1.6-0.7L13,4.1
				c0.1-0.5,0.5-0.9,1-0.9h2.9c0.5,0,0.9,0.4,1,0.9l0.3,2.3c0.5,0.2,1,0.4,1.5,0.6l1.9-1.4c0.4-0.3,1-0.3,1.3,0.1l2,2
				C25.3,8,25.3,8.6,25,9l-1.4,1.8c0.3,0.5,0.5,1,0.7,1.6l2.3,0.3c0.5,0.1,0.9,0.5,0.9,1v2.9c0,0.5-0.4,0.9-0.9,1l-2.3,0.3
				c-0.2,0.5-0.4,1.1-0.6,1.6l1.4,1.8c0.3,0.4,0.3,1-0.1,1.3l-2,2c-0.3,0.4-0.9,0.4-1.3,0.1l-1.9-1.4c-0.5,0.3-1,0.5-1.6,0.7
				l-0.3,2.3C17.8,26.8,17.4,27.2,16.9,27.2z M14.9,25.2H16l0.3-2.1c0.1-0.4,0.4-0.8,0.8-0.8c0.8-0.2,1.5-0.5,2.2-0.9
				c0.3-0.2,0.8-0.2,1.1,0l1.7,1.2l0.8-0.8l-1.3-1.7c-0.3-0.3-0.3-0.8-0.1-1.1c0.4-0.7,0.7-1.4,0.9-2.2c0.1-0.4,0.4-0.7,0.8-0.8
				l2.1-0.3v-1.2l-2.1-0.3c-0.4-0.1-0.8-0.4-0.8-0.8c-0.2-0.8-0.5-1.6-0.9-2.3c-0.2-0.4-0.2-0.8,0-1.1l1.3-1.7l-0.8-0.8L20.4,9
				c-0.3,0.3-0.8,0.3-1.1,0.1c-0.7-0.4-1.4-0.7-2.2-0.9c-0.4-0.1-0.7-0.4-0.8-0.9L16,5.2h-1.2l-0.3,2.1c-0.1,0.4-0.4,0.8-0.8,0.8
				c-0.8,0.2-1.6,0.5-2.3,0.9c-0.4,0.2-0.8,0.2-1.1,0L8.7,7.7L8,8.5l1.3,1.6c0.3,0.3,0.3,0.8,0.1,1.2c-0.4,0.7-0.8,1.5-0.9,2.3
				c-0.1,0.4-0.4,0.7-0.8,0.8l-2,0.3v1.2l2,0.3c0.4,0.1,0.8,0.4,0.9,0.8c0.2,0.8,0.5,1.6,0.9,2.3c0.2,0.3,0.2,0.8,0,1.1L8,21.9
				l0.8,0.8l1.7-1.3c0.3-0.2,0.8-0.3,1.1-0.1c0.7,0.4,1.4,0.7,2.2,0.9c0.4,0.1,0.7,0.4,0.8,0.8L14.9,25.2z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
