/*eslint-disable*/
import React, { ReactChild, useContext, useEffect, useState } from 'react'

import { FlowCanvasIcons, NodeHoverMenuIcons } from '@/common/Icons/Icons'
import { ForEachIcon, WarningAlertIcon } from '@/common/Icons/Workflow.Icons'

import { NodeMode, NodeT, TreeT, checkInputHasValue } from '../types'
import { SidePanelContext } from './MarketPlaceFlowCanvas'

type TreeBranchT = {
    Node?: any
    Arrow?: any
    SecondStepChildWithWrap?: any
    SingleChildWrap?: any
    MultiChildWrap?: any
    EndNodeWarp?: any
    ForEachNode?: any
}

export type NodePosition =
    | {
          kind: 'single'
      }
    | {
          kind: 'multi'
          positionType: 'odd' | 'even'
      }

export const Tree: TreeBranchT = {}

export function useDropDown(show?: boolean): [boolean, () => void, () => void] {
    const [showDropDown, setshowDropDown] = useState(show ? true : false)

    function makeDropDownVisible() {
        setshowDropDown(true)
    }

    function makeDropDownHidden() {
        setshowDropDown(false)
    }

    return [showDropDown, makeDropDownVisible, makeDropDownHidden]
}

Tree.Node = function Node(props: {
    node: TreeT<NodeT>
    index: number
    position: NodePosition
    comingUnderParallelPath: boolean
}) {
    const { node, index, position, comingUnderParallelPath } = props
    let positionClass = ''
    if (position && position.kind == 'single') {
        positionClass = ''
    } else if (position && position.kind == 'multi') {
        positionClass = position.positionType == 'odd' ? 'child_after' : 'child_before'
    }

    const { rightPanelState, setRightPanelState, setDisableDeployButton, disableDeployButton } = useContext(
        SidePanelContext
    )

    const currentParent =
        rightPanelState.mode.mode === NodeMode.Edit
            ? rightPanelState.mode.nodeEditInput.id === node.value.id
                ? 'selected_parent_node'
                : ''
            : ''

    const dontHaveInputValue = !checkInputHasValue(props.node.value)

    useEffect(() => {
        setDisableDeployButton((value: boolean[]) => {
            return value.concat(dontHaveInputValue)
        })
    }, [dontHaveInputValue])

    return (
        <div className={`flow_tree ${positionClass} ${comingUnderParallelPath ? 'parallel_path_child' : ''}`}>
            <div className={`first_step  ${currentParent}`}>
                <span className={node.value.icon ? 'tree_node bg_tree_flow' : 'bg_tree_flow'}>
                    {node.value.icon && node.value.icon.length > 0 ? (
                        <img
                            src={node.value.icon}
                            alt={node.value.kind === 'Condition' ? 'if' : 'A'}
                            style={{ color: !node.value.icon ? '#fff' : '#494949' }}
                        />
                    ) : (
                        <span className="bg_tree_flow_without_image">
                            {node.value.kind === 'Condition' ? 'if' : 'A'}
                        </span>
                    )}
                </span>
                <span className="bg_tree_content">{node.value.name}</span>
                {dontHaveInputValue && node.value.kind !== 'EmptyAction' && node.value.kind !== 'EmptyCondition' && (
                    <span className="no_input_value">
                        <div className="error_input_show">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">Input fields are missing.</span>
                        </div>
                    </span>
                )}
            </div>
            {rightPanelState.mode.mode === NodeMode.View && <Tree.Node.Hover node={node} />}
        </div>
    )
}

Tree.ForEachNode = function Node(props: {
    node: TreeT<NodeT>
    index: number
    position: NodePosition
    comingUnderParallelPath: boolean
    children: ReactChild | null
}) {
    const { node } = props

    const { rightPanelState } = useContext(SidePanelContext)

    const dontHaveInputValue = !checkInputHasValue(props.node.value)

    return (
        <>
            <div className="loop_foreach_condition">
                <div className="loop_autoamtion_header">
                    <h4>
                        {' '}
                        <span>
                            <ForEachIcon />
                        </span>
                        For Each - {node.value.name}
                    </h4>
                    {rightPanelState.mode.mode === NodeMode.View && (
                        <Tree.Node.Hover node={node} className="for_each_hover" />
                    )}
                    {/* <span className="autoamtion_expend_icon">
                        <ExpendIcon />
                    </span> */}
                    {dontHaveInputValue && (
                        <span className="for_each_warning_alert">
                            <span className="no_input_value">
                                <div className="error_input_show">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">Input fields are missing.</span>
                                </div>
                            </span>
                        </span>
                    )}
                </div>

                {props.children}
            </div>
        </>
    )
}

Tree.Node.Wrap = function NodeWrapper(props: any) {
    return <li className="dropdown_icons_li">{props.children}</li>
}

Tree.Node.EndNodeWarp = function EndNodeWrapper(props: {
    node: TreeT<NodeT>
    hasChildren: boolean
    children: React.ReactChild
    parentNode: TreeT<NodeT>
    isParallelPath: boolean
}) {
    return <li className={!props.hasChildren ? 'end_node' : ''}>{props.children}</li>
}

Tree.Node.Hover = function NodeHoverMenu(props: { node: TreeT<NodeT>; parentNode: TreeT<NodeT>; className: string }) {
    return (
        <div className={`flow_buttons market_place ${props.className ? props.className : ''}`}>
            <div className="flow_buttons_icons">
                {props.node.value.description.length > 0 && (
                    <>
                        <div className="flow_buttons_icons_content flow_buttons_icons_content_information">
                            <NodeHoverMenuIcons.InfoIcon />
                        </div>
                        <div className="flow_buttons_information">{props.node.value.description}</div>
                    </>
                )}
            </div>
        </div>
    )
}

Tree.SecondStepChildWithWrap = function SecondStepChildWithWrap(props: {
    hasSingleChildern: boolean
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
    render: (makeDropDownVisible: any, MenuElement: any) => void
}) {
    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown(true)

    return <>{props.render(makeDropDownVisible, null)}</>
}

Tree.SingleChildWrap = function SingleChild(props: {
    hasSingleChildern: boolean
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
    withArrow: boolean
}) {
    return (
        <>
            <ul className={props.hasSingleChildern ? 'tree_left_side single_child' : 'tree_left_side'}>
                {props.children}
            </ul>
        </>
    )
}

Tree.MultiChildWrap = function MultiChild(props: {
    children: React.ReactChild
    node: TreeT<NodeT>
    parentNode: TreeT<NodeT>
}) {
    return (
        <>
            <ul className="multi_child_li">{props.children}</ul>
        </>
    )
}

Tree.Node.Repeat = function RepeatNode(props: any) {
    return (
        <li className="child_after_left">
            <div className="flow_tree_actions">
                <div className="flow_tree_actions_header">
                    <h6>Actions</h6>
                </div>
                <div className="flow_tree_actions_swap">
                    <div className="flow_tree_img">
                        <div className="flow_tree_img_divide">
                            <span>
                                <FlowCanvasIcons.StartIcon />
                            </span>
                            <p>verify user accounts</p>
                        </div>
                        <div className="flow_tree_img_divide repeat_svg">
                            <span>
                                <FlowCanvasIcons.RepeatIcon />
                            </span>
                        </div>
                        <div className="flow_tree_img_divide">
                            <span>
                                <FlowCanvasIcons.StartIcon />
                            </span>
                            <p>verify user accounts</p>
                        </div>
                    </div>
                </div>
                <Tree.Node.Hover node={props.node} parentNode={props.parentNode} />
            </div>
        </li>
    )
}
