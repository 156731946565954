import styled from 'styled-components/macro'

export const BackButton = styled.button`
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #cecece' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    color: ${props => (props.primary ? '#333' : '#fff')};
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    min-width: 64px;
    height: 24px;
    transition: 0.346s ease;
    padding: 0px 10px;
    cursor: pointer;

    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
    :hover {
        border: 1px solid #ddfeef;
        background: #ddfeef;
        color: #333;
        fill: #333;
        outline: 0;
        cursor: pointer;
        span svg {
            fill: ${props => (props.primary ? '#333' : '#333')};
            color: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 3px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        svg {
            width: 10px;
            height: 10px;
            fill: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
            transform: rotate(180deg);
        }
    }
`
const Beta = styled.span`
    background: #ff7900;
    color: #fff;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    border-radius: 12px;
    text-align: center;
    top: 15px;
    padding: 1px 10px;
    border: 0.5px solid #ff7900;
    cursor: default;
    margin-left: 5px;
`

export const SVGicons = styled.span`
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    float: left;

    & svg {
        width: 11px;
        height: 11px;
        color: #fff;
        fill: #fff;
    }
`
export const CardICons = styled.div`
    width: 100%;
    float: left;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
export const HeaderWithImage = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 16px;
    display: flex;
    margin-bottom: 0px;
    border-bottom: 0.5px solid #ccc;
    font-size: 14px;
    padding: 0px 12px 10px 12px;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
    word-break: break-word;
    min-height: 88px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1450px) {
        font-size: 16px;
    }
`
export const Description = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #75797c;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 5px;
    padding-left: 2px;
    min-height: 20px;
`
export const Header = styled.div`
    width: 90%;
    float: left;
    margin-left: 8px;
    position: relative;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0px;
`
export const ImageCircle = styled.span`
    width: 30px;
    height: 30px;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0f6ff;
    border-radius: 50%;

    svg {
        width: 16px;
        height: 16px;
        fill: #355d8b;
    }
`
export const ImageSpan = styled.div`
    width: 30px;
    height: 30px;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
`
export const HeaderImage = styled.img`
    width: 100%;
    height: 100%;
`
export const CardBottom = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 6px;
    position: relative;
    top: 1px;
`
export const DateTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 9px;

    ${SVGicons} {
        margin-right: 2px;
        svg {
            fill: #ff7900;
            position: relative;
            top: 1px;
        }
    }
`
export const CardTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 11px;

    @media (max-width: 1450px) {
        font-size: 10px;
    }
`
export const CardDate = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 10px;

`
export const Downloads = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 10px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 6px;

    @media (max-width: 1450px) {
        // font-size: 9px;
    }
`
export const Count = styled.span`
    font-family: 'Gordita-Regular';
    color: #F07F31;
    font-size: 10px;
    margin-left: 2px;
    margin-right: 5px;

    @media (max-width: 1450px) {
        font-size: 10px;
    }
`
export const Card = styled.div`
    width: 19.6%;
    // flex: 1 0 21%;
    border-radius: 10px;
    min-height: 150px;
    height: 150px;
    // float: left;
    margin-right: 0px;
    margin-bottom: 20px;
    border: 0.5px solid #fff;
    position: relative;
    background: #fff;
    justify-content: left;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    :nth-child(4n) {
        // margin-right: 0px;
    }
    :hover {
        background: #ddfeef;
        color: #333;
        cursor: pointer;
        border: 0.5px solid #ddfeef;
    }

    &:hover ${HeaderWithImage} {
        color: #333;
    }
    &:hover ${CardBottom} {
        color: #333;
    }
    &:hover ${DateTime} {
        color: #333;
        svg {
            fill: #333;
        }
    }
    &:hover ${CardDate} {
        color: #333;
    }
    &:hover ${CardTime} {
        color: #333;
    }
    &:hover ${Downloads} {
        color: #333;
    }
    &:hover ${Count} {
        color: #333;
    }
    &:hover ${Description} {
        color: #333;
    }
    &:hover ${SVGicons} {
        svg {
            color: #333;
            fill: #333;
        }
    }
    &:hover ${ImageCircle} {
        background: #fff;
        svg {
            fill: #333;
        }
    }
    &:hover ${Beta} {
        background: #fff;
        color: #ff7900;
        border: 0.5px solid #ff7900;
    }
    @media (max-width: 1400px) {
        width: 270px;
        flex: 0 0 270px;
        margin-right: 15px;
        :nth-child(4n) {
            margin-right: 15px;
        }
    }
`
