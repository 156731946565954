import React from "react";
import { ToolBox } from "@/common/components/Card";
import { Modal } from "@/common/components/Modal";
import {DropdownWithCircle, FolderColorIcon, NewThumbsDownIcon, NewThumbsUpIcon, PdfFileIcon, UplaodIcon, VideoIcon, WordFileIcon } from "@/common/Icons/Icons";
import { Button } from "@/common/styled/Dialog.BotDetails.Dumb";
import { TabelWrapper } from "@/common/styled/Dialogue.Dumb";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@/common/styled/Workflow.Analytics.Dumb";
import { ButtonContainer, CancelButton, Input, InputContainer, InputContent, InputWrapper } from "@/common/styled/Workflow.Dumb";


export function ItSupportAi(){
    return(
        <>
        
        <section className="entity_cards knowledgeAi_table hide">
            <h3>All Files</h3>
            <div className="col-md-12">
                <div className="row">
                    <TabelWrapper>
                        <TableContainer>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th size="2">Name</Th>
                                        <Th size="1.5">Last Update</Th>
                                        <Th size="1">Author</Th>
                                        <Th size="1">Site</Th>
                                        <Th size="1">Department</Th>
                                        <Th size="1">Category</Th>
                                        <Th size="1">Subcategory</Th>
                                        <Th size="1">Publish</Th>
                                        <Th size="1.5">Feedback</Th>
                                        <Th size="1">Option </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td
                                            size="2"
                                            className={
                                                'list_flow_hr'
                                            }
                                        >
                                            <div className="knowledge_table_name">
                                                <div><FolderColorIcon /></div>
                                                <div>IT Support Document</div>
                                            </div>
                                        
                                        <label>
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                />
                                        </label>               
                                        </Td>
                                        <Td
                                            size="1.5"
                                            className="list_flow_p"
                                        >
                                            <p className="listite_centre">
                                                30 May 2023 | 5:44PM
                                            </p>
                                        </Td>

                                        <Td size="1">
                                            Katherine Smith
                                        </Td>
                                        <Td
                                            size="1"
                                            className="list_flow_span"
                                        >
                                            <span className="entity_span">
                                                Headquaters
                                            </span>
                                        </Td>
                                        <Td
                                            size="1"
                                            className="list_flow_span"
                                        >
                                            IT
                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                Software

                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                Wifi

                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                <div className="show_hide">
                                                    <div className="switch_box box_1">
                                                        <input
                                                        type="checkbox"
                                                        className={`switch_1 disabled`}
                                                        checked={true}
                                                        />
                                                    </div>
                                                </div>

                                        </Td>
                                        <Td  size="1.5">
                                            <div className="knowledge_table_name feedback">
                                                <div><NewThumbsUpIcon /></div>
                                                <div>0</div>
                                            </div>
                                            <div className="knowledge_table_name feedback">
                                                <div><NewThumbsDownIcon /></div>
                                                <div>0</div>
                                            </div>

                                        </Td>
                                        <Td size="1">
                                            <ToolBox>
                                                
                                            </ToolBox>
                                        </Td>
                                            
                                    </Tr>
                                    
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </TabelWrapper>
                </div>
            </div>
        </section>

        <Modal className="knowledge_edit_article">
            <h4>Edit article</h4>
            <p>Enter name and category</p>
               
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            primary
                            type="text"
                        />
                        <InputContent>Article name</InputContent>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Department</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Category</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Subcategory</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer style={{width: '48.5%'}}>
                        <Input
                            type="text"
                        />
                        <InputContent>Site</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                
            <ButtonContainer style={{padding: 0, justifyContent: 'flex-start'}}>
                <Button style={{margin: '0 20px 0 0'}} primary type="button" data-dismiss="modal">
                    Cancel
                </Button>
                <div className="popup_footer_button_container">
                    <Button style={{margin: 0}}
                        // className={false ? 'ditor_btn_disabled' : ''}
                        type="button"
                        data-dismiss="modal"
                    >
                        Create
                    </Button>
                </div>
            </ButtonContainer>
        </Modal>
        
        <Modal className="knowledge_edit_article hide">
            <h4>Create File</h4>
            <p>Enter name and category</p>
               
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            primary
                            type="text"
                        />
                        <InputContent>File name</InputContent>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Department</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Category</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Subcategory</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer style={{width: '48.5%'}}>
                        <Input
                            type="text"
                        />
                        <InputContent>Site</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                
            <ButtonContainer style={{padding: 0, justifyContent: 'flex-start'}}>
                <Button style={{margin: '0 20px 0 0'}} primary type="button" data-dismiss="modal">
                    Cancel
                </Button>
                <div className="popup_footer_button_container">
                    <Button style={{margin: 0}}
                        // className={false ? 'ditor_btn_disabled' : ''}
                        type="button"
                        data-dismiss="modal"
                    >
                        Create
                    </Button>
                </div>
            </ButtonContainer>
        </Modal>
    </>
    )
}