import {
  ArrowIcon,
  CheckColorIcon,
  ConfluenceIcon,
  FreshServiceIcon,
  GoogleDriveIcon,
  RetryErrorIcon,
  S3ConnectionIcon,
  SharePointIcon,
  VivantioIcon,
} from '@/common/Icons/Icons';
import { HeaderWrapper } from '@/common/styled/Dialog.BotDetails.Dumb';
import { TabelWrapper } from '@/common/styled/Dialogue.Dumb';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { Button, ButtonContainer } from '@/common/styled/Workflow.Dumb';
import React, { useEffect, useState } from 'react';
import {
  useExternalCategory,
  useSyncHistory,
  useTrainMode,
} from './KnowledgeApis';
import { Header as PageHeader, HeaderChild } from '@/common/components/Header';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { GreenTickIcon, WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import ImgLoader from '@/common/images/loading.svg';
import { Modal } from '@/common/components/Modal';
import { knowledgeApiUrl, postJson } from '@/common/utils/api-utils';
import { useQueryClient } from 'react-query';
import { ConnectionType, KnowledgeBase, Metrics, Mode, statusButton, statusIcon } from './MockFiles';
import Tooltip from 'react-tooltip-lite';
import { Loader } from '@/common/components/Loader';

export function SyncSummary(props: any) {
  const kbID = props.match.params.id;
  const workspaceName = props.match.params.workspacename;
  const Connection: ConnectionType = props.match.params.connection;

  const { exCatResult } = useExternalCategory(workspaceName);
  // const exCatArray: KnowledgeBase[] = exCatResult.SHAREPOINT_ONLINE;
  const exCatArray: KnowledgeBase[] =
    Connection === 'sharepoint'
      ? exCatResult.SHAREPOINT_ONLINE
        ? exCatResult.SHAREPOINT_ONLINE
        : []
      : Connection === 'confluence'
      ? exCatResult.CONFLUENCE_ONLINE
        ? exCatResult.CONFLUENCE_ONLINE
        : []
      : Connection === 'vivantio'
      ? exCatResult.VIVANTIO
        ? exCatResult.VIVANTIO
        : []
      : Connection === 'freshservice'
      ? exCatResult.FRESHSERVICE
        ? exCatResult.FRESHSERVICE
        : []
      : Connection === 'amazons3'
      ? exCatResult.S3
        ? exCatResult.S3
        : []
      : [];
  const { kbSyncData,kbSyncFetching,dataError } = useSyncHistory(workspaceName, kbID);
  const [pageState, setpageState] = useState<Mode>(Mode.View);

  // const totalAdded =
  //   kbSyncData &&
  //   kbSyncData.syncHistory &&
  //   kbSyncData.syncHistory
  //     .map((item) => item.metrics && parseInt(item.metrics.DocumentsAdded))
  //     .reduce((prev, next) => prev + next);

  const totalScanned = (metrics: Metrics) => {
    const total =
      parseInt(metrics.DocumentsAdded) +
      parseInt(metrics.DocumentsFailed) +
      parseInt(metrics.DocumentsDeleted) +
      parseInt(metrics.DocumentsModified);
    return total < 10 ? 0 + total : total;
  };
  // kbSyncData &&
  // kbSyncData.syncHistory.length > 0 &&
  // kbSyncData.syncHistory[0].metrics !== null
  //   ? parseInt(kbSyncData.syncHistory[0].metrics.DocumentsScanned)
  //   : 0;

  const [trainMode, setTrainMode] = useState<string>('');
  const statusCheck = (status: string) => {
    switch (status) {
      case 'FAILED':
        return 'Failed';
      case 'SUCCEEDED':
        return 'Trained';
      case 'SYNCING':
        return 'Training';
      case 'SYNCING_INDEXING':
        return 'Training';
      case 'INCOMPLETE':
        return 'Incomplete';
      default:
        return 'Trained';
    }
  };

  const queryClient = useQueryClient();
  const [error, setError] = useState<boolean>(false);

  const { trainMutate } = useTrainMode(
    () => {
      setpageState(Mode.View);
    },
    () => {
      setError(true);
    }
  );
  const TrainingMode = () => {
    const selectedID = kbID;
    const workspace = workspaceName;
    const trainBody =
      Connection === 'vivantio'
        ? {
            type: 'VIVANTIO',
          }
        : Connection === 'freshservice'
        ? {
            type: 'FRESHSERVICE',
          }
        :  Connection === 'amazons3'
        ? {
            type: 'S3',
          }
        : {
            connector:
              Connection === 'sharepoint' ? 'SHAREPOINTV2' : 'CONFLUENCEV2',
            mode: trainMode,
          };

    trainMutate({ workspace, selectedID, trainBody, Connection });
  };
  const titleName = (conn: string) => {
    switch (conn) {
      case 'sharepoint':
        return 'Microsoft SharePoint';
      case 'confluence':
        return 'Confluence';
      case 'vivantio':
        return 'Vivantio';
      case 'freshservice':
        return 'Freshservice';
      case 'amazons3':
        return 'S3';
      default:
        return 'Microsoft SharePoint';
    }
  };

  const kbIcon = (conn: string) => {
    console.log(conn,"sakdgfak")
    switch (conn) {
      case 'sharepoint':
        return <SharePointIcon />;
      case 'confluence':
        return <span><ConfluenceIcon /></span>;
      case 'vivantio':
        return <VivantioIcon />;
      case 'freshservice':
        return (
          <span>
            <FreshServiceIcon />
          </span>
        );
      case 'googleDrive':
        return <span style={{width:"16px"}}><GoogleDriveIcon  /></span>
      case 'amazons3':
          return <S3ConnectionIcon />
      default:
        return <SharePointIcon />;
    }
  };
  const onCancel = () => {
    setpageState(Mode.View);
    setTrainMode('');
    setError(false);
  };
  return (
    <>
      {/* <PageHeader>
        {[
          <HeaderChild type="first">
            <span
              className="buton_back inner_pages"
              onClick={() => window.history.back()}
            >
              <ArrowIcon />
              <span>Back</span>
            </span>
            <div className="indent_left">
              <div className="indent_title">{titleName(Connection)}</div>
              <p className="content_header">
                Connections ({exCatArray ? exCatArray.length : 0})
              </p>
            </div>
          </HeaderChild>,
        ]}
      </PageHeader> */}
      {kbSyncFetching ? 
      <div>
        <Loader.PageLoader show={true}/>
      </div>
      :kbSyncData ? (
        <HeaderWrapper
          style={{ margin: '8px 0px 0px', padding: '0' }}
        >
          <section className="external_KB_table">
            <div className="externalKB_title_box" style={{ padding: 0 }}>
              <div className="externalKB_title">
                <div className="externalKB_title_logo">
                  <span>{kbIcon(Connection)}</span>
                </div>
                <div className="externalKB_title_head">
                  <h4>{kbSyncData.name}</h4>
                  <p>
                    <span>Language:</span> English (United States)
                  </p>
                </div>
                <div className="externalKB_menu_box">
                  <SmallButton
                    className={
                      statusButton(kbSyncData.syncHistory[0].status) == 'Train'
                        ? 'train_btn'
                        : statusButton(kbSyncData.syncHistory[0].status) ==
                          'Retry'
                        ? 'retry_btn'
                        : 'training_btn'
                    }
                    primary
                    // style={{ minWidth: 90 }}
                    onClick={() => {
                      if (
                        statusButton(kbSyncData.syncHistory[0].status) ==
                          'Retry' ||
                        statusButton(kbSyncData.syncHistory[0].status) ==
                          'Train'
                      ) {
                        setpageState(Mode.Train);
                      }
                    }}
                  >
                    <label>
                      {kbSyncData.status === 'DELETION_INITIATED'
                        ? statusButton(kbSyncData.status)
                        : statusButton(kbSyncData.syncHistory[0].status)}
                      {statusButton(kbSyncData.syncHistory[0].status) ===
                        'Training' && (
                        <div className="publish_button_dialog btn_loader">
                          <img src={ImgLoader} />
                        </div>
                      )}

                      {kbSyncData.syncHistory.length > 0 &&
                        statusButton(kbSyncData.syncHistory[0].status) ==
                          'Retry' && (
                          <div className="retry_btn_icon">
                            <WarningAlertIcon />
                          </div>
                        )}
                    </label>
                  </SmallButton>
                </div>
              </div>
              <div className="externalKB_title_count_section flex">
                <h5>Documents</h5>
                <div className="externalKB_title_count_box">
                  <div className="externalKB_title_count">
                    <h4>
                      {kbSyncData.syncHistory[0].status === 'SYNCING' ||
                      kbSyncData.syncHistory[0].status === 'SYNCING_INDEXING'
                        ? kbSyncData.syncHistory.length > 1 &&
                          kbSyncData.syncHistory[1].metrics !== null
                          ? parseInt(
                              kbSyncData.syncHistory[1].metrics?.DocumentsAdded
                            ) < 10 &&
                            parseInt(
                              kbSyncData.syncHistory[1].metrics?.DocumentsAdded
                            ) > 0
                            ? 0 +
                              kbSyncData.syncHistory[1].metrics?.DocumentsAdded
                            : kbSyncData.syncHistory[1].metrics?.DocumentsAdded
                          : '0'
                        : kbSyncData.syncHistory[0].metrics !== null
                        ? parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsAdded
                          ) < 10 &&
                          parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsAdded
                          ) > 0
                          ? 0 +
                            kbSyncData.syncHistory[0].metrics?.DocumentsAdded
                          : kbSyncData.syncHistory[0].metrics?.DocumentsAdded
                        : '0'}
                    </h4>
                    <p>Added</p>
                  </div>
                  <div className="externalKB_title_count">
                    <h4>
                      {kbSyncData.syncHistory[0].status === 'SYNCING' ||
                      kbSyncData.syncHistory[0].status === 'SYNCING_INDEXING'
                        ? kbSyncData.syncHistory.length > 1 &&
                          kbSyncData.syncHistory[1].metrics !== null
                          ? Connection === 'vivantio' ||
                            Connection === 'freshservice'||
                          Connection === 'amazons3'
                            ? totalScanned(kbSyncData.syncHistory[1].metrics)
                            : parseInt(
                                kbSyncData.syncHistory[1].metrics
                                  ?.DocumentsScanned
                              ) < 10 &&
                              parseInt(
                                kbSyncData.syncHistory[1].metrics
                                  ?.DocumentsScanned
                              ) > 0
                            ? 0 +
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsScanned
                            : kbSyncData.syncHistory[1].metrics
                                ?.DocumentsScanned
                          : '0'
                        : kbSyncData.syncHistory[0].metrics !== null
                        ? Connection === 'vivantio' ||
                          Connection === 'freshservice' ||
                          Connection === 'amazons3'
                          ? totalScanned(kbSyncData.syncHistory[0].metrics)
                          : parseInt(
                              kbSyncData.syncHistory[0].metrics
                                ?.DocumentsScanned
                            ) < 10 &&
                            parseInt(
                              kbSyncData.syncHistory[0].metrics
                                ?.DocumentsScanned
                            ) > 0
                          ? 0 +
                            kbSyncData.syncHistory[0].metrics?.DocumentsScanned
                          : kbSyncData.syncHistory[0].metrics?.DocumentsScanned
                        : '0'}
                    </h4>
                    <p>Scanned</p>
                  </div>
                  <div className="externalKB_title_count">
                    <h4>
                      {kbSyncData.syncHistory[0].status === 'SYNCING' ||
                      kbSyncData.syncHistory[0].status === 'SYNCING_INDEXING'
                        ? kbSyncData.syncHistory.length > 1 &&
                          kbSyncData.syncHistory[1].metrics !== null
                          ? parseInt(
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsModified
                            ) < 10 &&
                            parseInt(
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsModified
                            ) > 0
                            ? 0 +
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsModified
                            : kbSyncData.syncHistory[1].metrics
                                ?.DocumentsModified
                          : '0'
                        : kbSyncData.syncHistory[0].metrics !== null
                        ? parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsModified
                          ) < 10 &&
                          parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsModified
                          ) > 0
                          ? 0 +
                            kbSyncData.syncHistory[0].metrics?.DocumentsModified
                          : kbSyncData.syncHistory[0].metrics?.DocumentsModified
                        : '0'}
                    </h4>
                    <p>Modified</p>
                  </div>
                  <div className="externalKB_title_count">
                    <h4>
                      {kbSyncData.syncHistory[0].status === 'SYNCING' ||
                      kbSyncData.syncHistory[0].status === 'SYNCING_INDEXING'
                        ? kbSyncData.syncHistory.length > 1 &&
                          kbSyncData.syncHistory[1].metrics !== null
                          ? parseInt(
                              kbSyncData.syncHistory[1].metrics?.DocumentsFailed
                            ) < 10 &&
                            parseInt(
                              kbSyncData.syncHistory[1].metrics?.DocumentsFailed
                            ) > 0
                            ? 0 +
                              kbSyncData.syncHistory[1].metrics?.DocumentsFailed
                            : kbSyncData.syncHistory[1].metrics?.DocumentsFailed
                          : '0'
                        : kbSyncData.syncHistory[0].metrics !== null
                        ? parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsFailed
                          ) < 10 &&
                          parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsFailed
                          ) > 0
                          ? 0 +
                            kbSyncData.syncHistory[0].metrics?.DocumentsFailed
                          : kbSyncData.syncHistory[0].metrics?.DocumentsFailed
                        : '0'}
                    </h4>
                    <p>Failed</p>
                  </div>
                  <div className="externalKB_title_count">
                    <h4>
                      {kbSyncData.syncHistory[0].status === 'SYNCING' ||
                      kbSyncData.syncHistory[0].status === 'SYNCING_INDEXING'
                        ? kbSyncData.syncHistory.length > 1 &&
                          kbSyncData.syncHistory[1].metrics !== null
                          ? parseInt(
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsDeleted
                            ) < 10 &&
                            parseInt(
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsDeleted
                            ) > 0
                            ? 0 +
                              kbSyncData.syncHistory[1].metrics
                                ?.DocumentsDeleted
                            : kbSyncData.syncHistory[1].metrics
                                ?.DocumentsDeleted
                          : '0'
                        : kbSyncData.syncHistory[0].metrics !== null
                        ? parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsDeleted
                          ) < 10 &&
                          parseInt(
                            kbSyncData.syncHistory[0].metrics?.DocumentsDeleted
                          ) > 0
                          ? 0 +
                            kbSyncData.syncHistory[0].metrics?.DocumentsDeleted
                          : kbSyncData.syncHistory[0].metrics?.DocumentsDeleted
                        : '0'}
                    </h4>
                    <p>Deleted</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button onClick={() => window.history.back()}>Close</Button>
            </div>
          </section>
          <section>
            <div className="external_KB_table_title">
              <h5>
                Trained History: <span>{kbSyncData.name}</span>
              </h5>
            </div>
            <TabelWrapper>
              <TableContainer>
                <Table className="external_kb_table">
                  <Thead style={{ borderBottom: '1px solid #E0E0E0' }}>
                    <Tr style={{ background: 'transparent' }}>
                      <Th size={2}>
                        <div className="th_label">Status / Summary</div>
                      </Th>
                      <Th size={2.5}>
                        <div className="th_label">Start Time</div>
                      </Th>
                      <Th size={2.5}>
                        <div className="th_label">End Time</div>
                      </Th>
                      <Th size={1} style={{ justifyContent: 'center' }}>
                        <div className="th_label">Added</div>
                      </Th>
                      <Th size={1} style={{ justifyContent: 'center' }}>
                        <div className="th_label">Scanned</div>
                      </Th>
                      <Th size={1} style={{ justifyContent: 'center' }}>
                        <div className="th_label">Modified</div>
                      </Th>
                      <Th size={1} style={{ justifyContent: 'center' }}>
                        <div className="th_label">Failed</div>
                      </Th>
                      <Th style={{ justifyContent: 'center' }} size={1}>
                        <div className="th_label">Deleted</div>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ paddingBottom: 0 }}>
                    {kbSyncData.syncHistory &&
                      kbSyncData.syncHistory.map((data, index) => (
                        <Tr key={index}>
                          <Td size="2" className={'list_flow_hr'}>
                            <span
                              style={{ marginRight: 5 }}
                              className={
                                data.status === 'FAILED' ? 'failed_icon' : ''
                              }
                            >
                              {data.status === 'FAILED' ? (
                                <Tooltip
                                  className="target customTip"
                                  zIndex={10000}
                                  arrowSize={8}
                                  tagName="span"
                                  content={data.errorMessage}
                                  distance={5}
                                >
                                  {statusIcon(data.status)}
                                </Tooltip>
                              ) : (
                                <div>{statusIcon(data.status)}</div>
                              )}
                            </span>
                            {statusCheck(data.status)}
                          </Td>
                          <Td size="2.5" className="list_flow_p">
                            <p className="listite_centre">
                              <span>
                                {data.startTime
                                  ? formatDateInCard(data.startTime)
                                  : '--'}
                              </span>
                              <span>|</span>
                              <span>
                                {data.startTime
                                  ? formatAMPM(data.startTime)
                                  : '--'}
                              </span>
                            </p>
                          </Td>
                          <Td size="2.5" className="list_flow_p">
                            <p className="listite_centre">
                              <span>
                                {data.endTime
                                  ? formatDateInCard(data.endTime)
                                  : '--'}
                              </span>
                              <span>|</span>
                              <span>
                                {data.endTime ? formatAMPM(data.endTime) : '--'}
                              </span>
                            </p>
                          </Td>
                          <Td
                            size="1"
                            className="list_flow_span"
                            style={{ justifyContent: 'center' }}
                          >
                            {data.metrics
                              ? parseInt(data.metrics.DocumentsAdded) < 10 &&
                                parseInt(data.metrics.DocumentsAdded) > 0
                                ? 0 + data.metrics.DocumentsAdded
                                : data.metrics.DocumentsAdded
                              : 0}
                          </Td>
                          <Td
                            size="1"
                            className="list_flow_span"
                            style={{ justifyContent: 'center' }}
                          >
                            {data.metrics
                              ? Connection === 'vivantio' ||
                                Connection === 'freshservice'||
                          Connection === 'amazons3'
                                ? totalScanned(data.metrics)
                                : parseInt(data.metrics.DocumentsScanned) <
                                    10 &&
                                  parseInt(data.metrics.DocumentsScanned) > 0
                                ? 0 + data.metrics.DocumentsScanned
                                : data.metrics.DocumentsScanned
                              : 0}
                          </Td>
                          <Td
                            size="1"
                            className="list_flow_span"
                            style={{ justifyContent: 'center' }}
                          >
                            {data.metrics
                              ? parseInt(data.metrics.DocumentsModified) < 10 &&
                                parseInt(data.metrics.DocumentsModified) > 0
                                ? 0 + data.metrics.DocumentsModified
                                : data.metrics.DocumentsModified
                              : 0}
                          </Td>
                          <Td
                            size="1"
                            className="list_flow_span"
                            style={{ justifyContent: 'center' }}
                          >
                            {data.metrics
                              ? parseInt(data.metrics.DocumentsFailed) < 10 &&
                                parseInt(data.metrics.DocumentsFailed) > 0
                                ? 0 + data.metrics.DocumentsFailed
                                : data.metrics.DocumentsFailed
                              : 0}
                          </Td>
                          <Td
                            size="1"
                            className="list_flow_span"
                            style={{ justifyContent: 'center' }}
                          >
                            {data.metrics
                              ? parseInt(data.metrics.DocumentsDeleted) < 10 &&
                                parseInt(data.metrics.DocumentsDeleted) > 0
                                ? 0 + data.metrics.DocumentsDeleted
                                : data.metrics.DocumentsDeleted
                              : 0}
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabelWrapper>
          </section>
          {pageState === 'train' &&
            (Connection === 'sharepoint' || Connection === 'confluence'|| Connection === 'googleDrive' ? (
              <Modal className="knowledge_container_popup externalKB">
                <section className="knowledge_container_section externalKB_popup">
                  <h4>Train Mode</h4>
                  <p>
                    Choose how you want to update your index when your data
                    source content changes.
                  </p>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FORCED_FULL_CRAWL')}
                    />
                    <div>
                      <h6>Full train</h6>
                      <p>
                        Train and index all contents in all entities, regardless
                        of the previous trained status.
                      </p>
                    </div>
                  </div>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FULL_CRAWL')}
                    />
                    <div>
                      <h6>Train new or modified content</h6>
                      <p>Only train new or modified content.</p>
                    </div>
                  </div>
                  {Connection === 'sharepoint' && (
                    <div className="externalKB_option">
                      <input
                        type="radio"
                        name="trainmode"
                        className="option-input radio"
                        onClick={() => setTrainMode('CHANGE_LOG')}
                      />
                      <div>
                        <h6>Train new, modified, or deleted content</h6>
                        <p>Only train new, modified, deleted content.</p>
                      </div>
                    </div>
                  )}

                  <ButtonContainer
                    style={{
                      padding: 0,
                      justifyContent: 'flex-start',
                      marginTop: 5,
                    }}
                  >
                    <div className="popup_footer_button_container">
                      <Button
                        primary
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                          onCancel();
                          setpageState(Mode.View);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          trainMode !== '' ? '' : 'editor_btn_disabled'
                        }
                        type="button"
                        data-dismiss="modal"
                        onClick={() => TrainingMode()}
                      >
                        Train
                      </Button>
                      {error && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                    </div>
                  </ButtonContainer>
                </section>
              </Modal>
            ) : (
              <Modal className="knowledge_container_popup externalKB">
                <section className="knowledge_container_section externalKB_popup">
                  <h4>Train Mode</h4>
                  <p>
                    Choose how you want to update your index when your data
                    source content changes.
                  </p>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FORCED_FULL_CRAWL')}
                    />
                    <div>
                      <h6>Full train</h6>
                      <p>
                        Train and index all contents in all entities, regardless
                        of the previous trained status.
                      </p>
                    </div>
                  </div>
                  <ButtonContainer
                    style={{
                      padding: 0,
                      justifyContent: 'flex-start',
                      marginTop: 5,
                    }}
                  >
                    <div className="popup_footer_button_container">
                      <Button
                        primary
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                          onCancel();
                          setpageState(Mode.View);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          trainMode !== '' ? '' : 'editor_btn_disabled'
                        }
                        type="button"
                        data-dismiss="modal"
                        onClick={() => TrainingMode()}
                      >
                        Train
                      </Button>
                      {error && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                    </div>
                  </ButtonContainer>
                </section>
              </Modal>
            ))}
        </HeaderWrapper>
      ):dataError ? (
        <div>Error in displaying data</div>
      ): null}
    </>
  );
}
