import React, { useEffect, useState } from 'react'

import { Input, InputContainer, InputContent, InputWrapper, Textarea } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

const DialogName = ({ dialog, setDialog }: { dialog: any; setDialog: Function }) => {
    const [insertKey, setInsertKey] = useState(13)
    const changeHandler = (e: any) => {
        {
            if (
                (insertKey > 64 && insertKey < 91) ||
                (insertKey > 47 && insertKey < 58) ||
                insertKey == 32 ||
                insertKey == 8
            ) {
                setDialog({ ...dialog, [e.target.name]: e.target.value })
            }
        }
    }

    let title: any

    useEffect(() => {
        title.focus()
    }, [])

    return (
        <div className="test_buton_result p-0">
            <div className="test_buton_view">
                <div className="automation_condition_form">
                    <div className="select_work_form">
                        <InputWrapper>
                            <InputContainer className="dialog_name_alert_content">
                                <Input
                                    type="text"
                                    name="name"
                                    value={dialog.name}
                                    onKeyDown={(e: any) => setInsertKey(e.keyCode)}
                                    onChange={changeHandler}
                                    ref={(el: any) => {
                                        title = el
                                    }}
                                />
                                <InputContent>Dialog name</InputContent>
                            </InputContainer>
                            {dialog.name.length > 80 ? <p className="error_msg">Text limit is Exceeded</p> : null}
                            {/* Textarea */}
                            <InputContainer>
                                <Textarea
                                    type="text"
                                    name="description"
                                    value={dialog.description}
                                    onKeyDown={(e: any) => setInsertKey(e.keyCode)}
                                    onChange={changeHandler}
                                    maxLength="80"
                                />
                                <InputContent>Description (optional)</InputContent>
                            </InputContainer>
                        </InputWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DialogName
