/*eslint-disable*/
import * as React from 'react'
type SelectBoxP = {
    show: boolean
    isSelected: boolean
    handleSelected: () => void
}
export const SelectBox: React.FunctionComponent<SelectBoxP> = ({ show, isSelected, handleSelected }) => (
    <label>
        <input
            type="radio"
            className="option-input radio"
            checked={isSelected}
            onClick={e => {
                e.stopPropagation()
                handleSelected()
            }}
            onChange={() => {}}
            style={{
                visibility: show ? 'visible' : 'hidden',
                border: show && !isSelected ? '#b9b9b9 solid 1px' : '1px solid inherit'
            }}
        />
    </label>
)

type ListSelectBoxP = {
    show: boolean
    isSelected: boolean
    onClick: (value: string) => void
    value: string
}
export const ListSelectBox: React.FunctionComponent<ListSelectBoxP> = ({ show, isSelected, onClick, value }) => (
    <label>
        {show && (
            <input
                type="radio"
                className="option-input radio"
                checked={isSelected}
                onClick={e => {
                    e.stopPropagation()
                    onClick(value)
                }}
                onChange={() => {}}
                style={{
                    visibility: show ? 'visible' : 'hidden',
                    border: show && !isSelected ? '#000 solid 1px' : '#fff'
                }}
            />
        )}
    </label>
)
