import { profileDataT } from '@/Profile/types';
import * as React from 'react';

export const SideBarContext = React.createContext<{
  isSubPanelOpen: boolean;
  setSubPanelOpen: (isSubPanelOpen: boolean) => any;
  userData: profileDataT | null;
  refetchProfileData: () => void;
}>({
  isSubPanelOpen: false,
  setSubPanelOpen: (isSubPanelOpen: boolean) => {
    return null;
  },
  userData: null,
  refetchProfileData: () => {
    return null;
  },
});

export const useSidebar = () => React.useContext(SideBarContext);
