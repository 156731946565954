import React, { useEffect, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'

import { Loader } from '@/common/components/Loader'
import {
    Box,
    BoxOne,
    BoxRight,
    BoxWrapper,
    HeaderLeft,
    HeaderRight,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@/common/styled/Subscription.Xtra.Dumb'

import { formatDate } from '@/common/utils/utils'
import {
    BillingContact,
    BillingEstimation,
    BillingEstimationContainer,
    MonthlySubscriptionV2
} from './AccountAndBillingv2'
import { HeaderPrice } from './BoxLeft'
import { Container, EstimateHeader } from './Headers'
import { BillingWrapper, Plan } from './PaidUsersPlan'
import { BodyWrapperV2, ContainerV2, CurrentPlanHeader, PlansHeaderV2, WrapperV2 } from './Plans-v2'
import { loadCurrentSubscriptionData } from './Subscription'
//TYPE IMPORT
import { ActionT, ErrorPage, PageStateT, TrialStatus } from './kind'
import { billingEmail } from './util-stripe'

export const DateLogs = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    cursor: default;
    font-size: 16px;
    color: #494949;
    display: flex;
    align-items: center;
    margin-top: 15px;
`
export const Logsspan: StyledComponent<'span', any, any, never> = styled.span`
    width: auto;
    float: left;
    font-family: ${(props: any) => (props.primary ? 'Gordita-Regular' : 'Gordita-Medium')}
    cursor: ${(props: any) => (props.primary ? 'pointer' : 'default')}
    font-size: ${(props: any) => (props.primary ? '12px' : '15px')};
    margin-right:${(props: any) => (props.secondary ? '0px' : '5px')};
    margin-left:${(props: any) => (props.secondary ? '3px' : '3px')};
    color: ${(props: any) => (props.primary ? '#0a89e8' : '#494949')};
`

const Headerspan = styled.div`
    width: auto;
    float: left;
    margin: 0px 5px;
    color: #494949;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-left: 5px;
`

const CalcWrapper: StyledComponent<'div', any, any, never> = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: ${(props: any) => (props.secondary ? '30px 0px 0px 0px' : '15px 0px')};
    border-top: ${(props: any) => (props.primary ? '1px solid #ccc' : 'none')};
    border-bottom: ${(props: any) => (props.primary ? '1px solid #ccc' : 'none')};
    margin-top: ${(props: any) => (props.primary ? '15px' : '0px')};
`

const CalcDivider: StyledComponent<'div', any, any, never> = styled.div`
    width: 67%;
    float: left;
    margin-bottom: ${(props: any) => (props.primary ? '15px' : '0px')};
    display: flex;
`

const CalcHeader = styled.h4`
    width: 85%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    color: #494949;
    font-family: 'Gordita-Regular';
    font-size: 22px;
    margin-bottom: 0px;
`
const CalcSpan = styled.span`
    width: auto;
    float: left;
    font-size: 14px;
    color: #494949;
    margin-left: 5px;
    font-family: 'Gordita-Regular';
`
const CalcHeaderRight = styled.h4`
    width: 12%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: #494949;
    font-family: 'Gordita-Regular';
    font-size: 24px;
    margin-bottom: 0px;
`
const Sup = styled.sup`
    width: auto;
    float: left;
    font-size: 16px;
    color: #494949;
    font-family: 'Gordita-Regular';
`
const Amount = styled.span`
    width: auto;
    float: left;
    font-size: 26px;
    color: #494949;
    font-family: 'Gordita-Medium';
`

const BillingLinks = styled.div`
    width: 100%;
    float: left;
    color: #0a89e8;
    font-family: 'Gordita-Regular';
    font-size: 15px;
    margin-top: 10px;
`
const BillingLinksSpan = styled.div`
    width: auto;
    float: left;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`
const InformationIcon = styled.span`
    width: 10px;
    height: 10px;
    position: absolute;
    right: 22px;
    margin-top: 1px;
`
type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any) => Promise<infer U>
    ? U
    : T extends (...args: any) => infer U
    ? U
    : T

const formatStripeDate = (date: number) => {
    return formatDate(new Date(date * 1000))
}

export const Estimate = (props: any) => {
    const { workspacename } = props.match.params

    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }

    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        const upcomingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const planBillingPeriod = PageState.subscription.planDetails.billed
        // console.log('upcomingInvoice', upcomingInvoice)

        const detailsToDisplay = {
            planName: PageState.subscription.displayName,
            startDate: formatStripeDate(upcomingInvoice.period_start),
            endDate: formatStripeDate(upcomingInvoice.period_end),
            toPay: upcomingInvoice.amount_due / 100
        }

        // console.log('upcoming', upcomingInvoice)
        return (
            <>
                <EstimateHeader />
                <BoxWrapper>
                    <Container>
                        <Box>
                            <BoxRight secondary width={6.6}>
                                <HeaderRight>Billing Estimate</HeaderRight>
                                <DateLogs>
                                    <Logsspan>{detailsToDisplay.startDate}</Logsspan>
                                    <Logsspan>-</Logsspan>
                                    <Logsspan>{detailsToDisplay.endDate}</Logsspan>
                                </DateLogs>
                                <HeaderPrice primary>
                                    {detailsToDisplay.planName}
                                    <Headerspan>
                                        (To upgrade the plan, go to{' '}
                                        <Logsspan
                                            primary
                                            secondary
                                            onClick={() =>
                                                props.history.push(
                                                    `/workspace/${workspacename}/billing/plans?from=estimate&planType=${planBillingPeriod.toLowerCase()}`
                                                )
                                            }
                                        >
                                            pricing plans
                                        </Logsspan>
                                        )
                                    </Headerspan>
                                </HeaderPrice>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th size={8} primary>
                                                Description
                                            </Th>

                                            <Th size={4}>Amount</Th>
                                        </Tr>
                                    </Thead>

                                    <Tbody>
                                        {upcomingInvoice.lines.data.map((lineItem, index) => (
                                            <Tr key={index}>
                                                <Td size={8} ContentRight>
                                                    {lineItem.description}
                                                </Td>
                                                <Td size={4}>{lineItem.amount / 100}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <CalcWrapper primary>
                                    <CalcDivider primary>
                                        <CalcHeader>
                                            Total <CalcSpan></CalcSpan>
                                        </CalcHeader>
                                        <CalcHeaderRight>
                                            <Sup>$</Sup>
                                            <Amount>{detailsToDisplay.toPay}</Amount>
                                        </CalcHeaderRight>
                                    </CalcDivider>
                                </CalcWrapper>
                            </BoxRight>

                            <BoxOne secondary BoxWidth={3}>
                                <HeaderLeft>Billing Information</HeaderLeft>
                                <BillingLinks>
                                    <BillingLinksSpan>
                                        <a
                                            href="https://workativ.com/conversational-ai-platform/pricing"
                                            target="blank"
                                        >
                                            Billing FAQs
                                        </a>
                                    </BillingLinksSpan>
                                </BillingLinks>
                                <BillingLinks>
                                    <BillingLinksSpan>
                                        <a href={`mailto:${billingEmail}`}>Contact us about your billing </a>
                                    </BillingLinksSpan>
                                </BillingLinks>
                            </BoxOne>
                        </Box>
                    </Container>
                </BoxWrapper>
            </>
        )
    }
    return <div> Unhandled Scenario </div>
}

export const EstimateV2 = (props: any) => {
    const { workspacename } = props.match.params

    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }

    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        const upcomingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const planBillingPeriod = PageState.subscription.planDetails.billed
        // console.log('upcomingInvoice', upcomingInvoice)

        const detailsToDisplay = {
            planName: PageState.subscription.displayName,
            startDate: formatStripeDate(upcomingInvoice.period_start),
            endDate: formatStripeDate(upcomingInvoice.period_end),
            toPay: upcomingInvoice.amount_due / 100
        }

        // console.log('upcoming', upcomingInvoice)
        return (
            <>
                <div className="plansWrapper">
                    <PlansHeaderV2 backButton name={'Billing Estimate'} goBack={() => window.history.back()} />
                    <CurrentPlan />
                </div>
            </>
        )
    }
    return <div> Unhandled Scenario </div>
}
export const AccountEstimate = () => {
    return (
        <div className="plansWrapper">
            <PlansHeaderV2 backButton name={'Billing Estimate'} />
            <CurrentPlan />
        </div>
    )
}

const CurrentPlan = (props: any) => {
    return (
        <div className="current_plan_wrapper">
            <BodyWrapperV2 paddingTop>
                <WrapperV2>
                    <ContainerV2>
                        <BillingWrapper>
                            <Plan />
                            <BillingContainer>
                                <BillingEstimation upgradePlan />
                                <BillingInformation header={'Billing Estimate'} />
                            </BillingContainer>
                        </BillingWrapper>
                    </ContainerV2>
                </WrapperV2>
            </BodyWrapperV2>
        </div>
    )
}
const BillingEstimateWrapper = styled.section`
    width: 100%;
    float: left;
    padding: 0px 20px;
    padding-top: 10px;
    padding-bottom: 20px;
`

const BillingContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 50% max-content;
    grid-gap: 40px;
    padding: 30px 20px 70px;
    justify-content: flex-start;
`

const BillingInformation = (props: any) => {
    return (
        <BillingContact>
            <BillingEstimationContainer>
                <CurrentPlanHeader>{props.header}</CurrentPlanHeader>
                <BillingEstimateWrapper>
                    <MonthlySubscriptionV2 fontBold>Billing FAQs</MonthlySubscriptionV2>
                    <MonthlySubscriptionV2 fontBold>Contact us about your billing</MonthlySubscriptionV2>
                </BillingEstimateWrapper>
            </BillingEstimationContainer>
        </BillingContact>
    )
}
