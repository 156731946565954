import { Button, Input } from '@/common/styled/Dialog.BotDetails.Dumb';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/rsuite.css';
// import DatePicker, { DateRange, DisabledDateFunction } from 'rsuite/esm/DateRangePicker';
import { DateRangePicker, Range } from "react-date-range";
import Downshift from 'downshift';
// const { afterToday } = DatePicker;

export default function BasicDateRangePicker(props: {
  setDate: (date: Date[]) => void;
  closeCalendar:() => void;
  show:boolean
  customDates:{
      from_date: null|string;
      to_date: null|string;
  }
  minDate?:Date
}) {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(moment().subtract(1, 'days').toString()),
    endDate: new Date(moment().toString()),
    key: "selection"
  });
  const [show, setShow] = useState(false);
  const [date, setDate] = useState<Date[]>([]);
  useEffect(()=>{
    setShow(props.show)

    if(props.customDates.from_date && props.customDates.to_date && props.show){
      const startDate = props.customDates.from_date.replace(/-/g,"/") ,endDate = props.customDates.to_date.replace(/-/g,"/")
      setSelectedDateRange({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key:"selection"
      })
    }
  },[props.show])

  const handleSelect = (ranges:any) => {
    if(props.minDate && ranges.selection.startDate < props.minDate){
      setSelectedDateRange({...ranges.selection,startDate:props.minDate});
    }else{
      setSelectedDateRange(ranges.selection);
    }
  };

  const onClickClear = () => {
    setSelectedDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    });
    setShow(false)
    props.closeCalendar()
  };
  
  return show? <Downshift
  isOpen={true}
  onOuterClick={() => {
    onClickClear()
  }}
>
  {() => (
    <span>
      {/* <Input
        type="text"
        value={date[0]}
        onClick={() => setShow(true)}
        style={{ width: '20%', height: 30, padding: '10px' }}
      />
      <Input
        type={'text'}
        value={date.length > 1 ? date[1] : ''}
        onClick={() => setShow(true)}
        style={{ width: '20%', height: 30, padding: '10px' }}
      /> */}

      {/* <DateRangePicker
        ranges={[]}
        placement="bottomStart"
        format={"MM/dd/yyyy"} 
        defaultCalendarValue={[new Date(_date2), new Date(_date)]}
        onSelect={(d) => {
          console.log(date.length > 1, date, d, 'dateee');
          date.length > 1
            ? setDate([d])
            : setDate(d > date[0] ? [...date, d] : [d, ...date]);
        }}
        disabledDate={(afterToday as (() => DisabledDateFunction))()}
        showOneCalendar
        open={show}
        onChange={(e) => {
          console.log(e, 'dateee');
          setShow(false);
          props.setDate(e as DateRange);
        }}
      /> */}
      <DateRangePicker
                         onChange={handleSelect}
                        //  showSelectionPreview={true}
                         moveRangeOnFirstSelection={false}
                         months={1}
                         ranges={[selectedDateRange]}
                         rangeColors={["#ddfeef"]}
                         direction="horizontal"
                         showMonthAndYearPickers={false}
                         editableDateInputs
                         staticRanges={[]}
                        inputRanges={[]}
                        maxDate={new Date()} 
                        minDate={props.minDate}
                        retainEndDateOnFirstSelection
                    />
                  <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
                    <button
                    className="btn btn-transparent text-danger rounded-0 px-4"
                    onClick={onClickClear}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                    onClick={() => {
                      props.setDate([selectedDateRange.startDate,selectedDateRange.endDate]);
                      setShow(false)
                      // props.closeCalendar()
                    }}
                  >
                    Apply
                  </button>
          
        </div>
      {/* <Button>Cancel</Button> */}
    </span>
  )}
  </Downshift> 
  :null
}
