export const MOCK_SLACK = true;
export const MOCK_TEAMS = true;
export const MOCK_WIDGETV = true;

export const slackResponse = [
  {
    assistance_id: '23078ca7-e46c-440d-b72c-c884d98ceff7',
    client_id: 'fgj',
    client_secrect: 'jnjb',
    description: 'Workativ Assistant',
    name: 'Workativ Assistant',
    slack_deploy: {
      added_by: 'Manjula Workativ',
      integrated_at: '2022-05-30T14:59:59.000Z',
      is_active: true,
      mail_id: 'manjula.m@workativ.com',
      slack_id: 'T03HJ4A21DH',
      slack_workspace: 'Development',
    },
    verification_token: 'knkl',
    workspace: 'nirup',
    client_secret_active:true,
    verification_token_active:false
  },
  {
    assistance_id: '605c076c-0d72-431b-9717-e15346c54353',
    client_id: 'sgfgdfg',
    client_secrect: 'fgdfgdf',
    description: 'Workativ des',
    name: 'Workativ 2',
    slack_deploy: null,
    verification_token: 'dgdfgdf',
    workspace: 'nirup',
    client_secret_active:false,
    verification_token_active:true
  },
  {
    assistance_id: '7d87f7ca-84ff-4336-8ff4-ceaed791b479',
    client_id: 'sfssf',
    client_secrect: 'sfsfs',
    description: 'Workativ describe',
    name: 'Workativ 3',
    slack_deploy: null,
    verification_token: 'sfsffsd',
    workspace: 'nirup',
  },
];

export const TeamResponse: any = [
  {
    app_id: 'Report23456',
    app_password: 'report98765kil',
    bot_data: null,
    bot_id: '67830493-e989-4bb4-a929-71e3fb3309fb',
    description: 'Your workplace assistant',
    is_authorized: false,
    is_org_wide: false,
    name: 'Workativ Assistant',
    teams_deploy: null,
    tenant: 'report34567kil',
    tenant_type: 'multi_tenant',
    workspace: 'nirup',
  },
  {
    app_id: "3d99765c-d8e2-4080-bd15-cb7d36428fdf",
    app_password: "e2Ge7JAsqHY._80Rl12542l~yq~_-vbw17",
    bot_data: {
      bot_full_desc: "dcsghlskfhgldksfh.gsldhfksg.hdghsskhgksjfhgklsfjghlsfdjkgksjdfhgsdjkfhglsdfuhgskljsurehgkjdfghdjkfhgsldfjkdghdkjfalsfjksdh;ksjahjksdhurghsfkjhgsfkjhlgsdhduhjkdfhgsuerghseuihse;riohgsf;hgehsgehg;eihgkfjgjksfdhguierehrihegersjhgjuserugyeuirghurhgfjgeurgheuirghuirghsfjkhghfhgluwerihyiwuehfjsdk",
      bot_full_name: "dx",
      bot_name: "TestOne_Manjula",
      bot_short_desc: "a",
      is_active: true,
      is_generated: true,
    },
    bot_id: "8f43c8f5-7c5f-4903-827c-bcf4b587d24d",
    description: "desc",
    is_authorized: true,
    is_org_wide: true,
    name: "name",
    teams_deploy: {
      added_by: "Manjula Mani",
      date_added: "2022-11-30T09:39:32.000Z",
      email_id: "manjula.m@workativ.com",
      is_active: true,
      team_id: "manjulaworkspace_3d99765c-d8e2-4080-bd15-cb7d36428fdf",
      team_name: "manjulaworkspace_3d99765c-d8e2-4080-bd15-cb7d36428fdf",
    },
    tenant: "fe8f0076-8dbf-4684-9f73-b389c33b2158",
    tenant_type: "multi_tenant",
    workspace: "manjulaworkspace",
    client_secret_active: false,
},
{
    app_id: "3d99765c-d8e2-4080-bd15-cb7d36428",
    app_password: "e2Ge7JAsqHY._80Rl12542l~yq~_-vbw17",
    bot_data: {
      bot_full_desc: "",
      bot_full_name: "",
      bot_name: "",
      bot_short_desc: "",
      is_active: true,
      is_generated: true,
    },
    bot_id: "8f43c8f5-7c5f-4903-827c-bcf4b587d24d",
    created_at:"2023-03-27T13:40:37.000Z",
    description: "desc",
    is_authorized: true,
    is_org_wide: false,
    name: "name",
    teams_deploy: null,
    tenant: "fe8f0076-8dbf-4684-9f73-b389c33b2158",
    tenant_type: "multi_tenant",
    updated_at:"2023-03-27T14:19:14.000Z",
    workspace: "manjulaworkspace",
},
{
  app_id: "7b3b6e38-80bd-4d05-908b-e67d627c1ad9",
    app_password: "e2Ge7JAsqHY._80Rl12542l~yq~_-vbw17",
    bot_data: {
      bot_full_desc: "dc",
      bot_full_name: "dx",
      bot_name: "TestOne_Manjula",
      bot_short_desc: "a",
      is_active: true,
      is_generated: true,
    },
    bot_id: "605c076c-0d72-431b-9717-e15346c54353",
    description: "desc",
    is_authorized: true,
    is_org_wide: true,
    name: "name2",
    teams_deploy: null,
    tenant: "fe8f0076-8dbf-4684-9f73-b389c33b2158",
    tenant_type: "multi_tenant",
    workspace: "manjulaworkspace",
}
];
export const WidegetResponse: any = [
  {
    accent_color: '#FFE5D3',
    bot_id: '158ee0a1-d7cb-430e-9395-a5b1e5792946',
    button_color: '#E3F6FF',
    created_at: '2021-06-04T13:31:32.000Z',
    description: 'Your workplace assistant',
    id: '07f2e032-0b71-4c0d-8908-c49791084004',
    is_active: false,
    is_secured: false,
    logo_url:
      'https://ac-image.s3.amazonaws.com/8/8/7/3/4/1/home/admin/images_for_product/logo.png?r=1663289093',
    name: 'Bug',
    primary_color: '#000000',
    public_key: null,
    secondary_color: '#E3F6FF',
    snippet:
      '<div id="assistant-widget-07f2e032-0b71-4c0d-8908-c49791084004"></div>\n  <script src="https://unpkg.com/assistant-widget@latest/dist/lib.js"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector("#assistant-widget-07f2e032-0b71-4c0d-8908-c49791084004"),\n          endpoint: "http://dev-assistant.workativ.ai/widget",\n          widget_id: "07f2e032-0b71-4c0d-8908-c49791084004",\n          openByDefault: false\n      });\n  </script>\n  ',
    updated_at: '2021-06-04T13:31:32.000Z',
    workspace: 'manjulaworkspace',
  },
  {
    accent_color: "#d3fff1",
    bot_id: "6670d2c2-0d04-4cf2-8c21-752729453dd3",
    button_color: "#E3F6FF",
    created_at: "2021-07-22T09:56:06.000Z",
    description: "Your workplace assistant",
    id: "26369ae4-5431-4b18-820e-e1cd0f4f78ec",
    is_active: false,
    is_secured: false,
    logo_url: "https://assistant-workativ-fileupload.s3.amazonaws.com/1626947693728",
    name: "Dev Manjula Widge",
    primary_color: "#424b4b",
    public_key: null,
    secondary_color: "#15eeb6",
    snippet: 
        "<div id=\"assistant-widget-26369ae4-5431-4b18-820e-e1cd0f4f78ec\"></div>\n  <script src=\"https://unpkg.com/assistant-widget@latest/dist/lib.js\"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector(\"#assistant-widget-26369ae4-5431-4b18-820e-e1cd0f4f78ec\"),\n          endpoint: \"http://dev-assistant.workativ.ai/widget\",\n          widget_id: \"26369ae4-5431-4b18-820e-e1cd0f4f78ec\",\n          openByDefault: false\n      });\n  </script>\n  ",
    updated_at: "2021-07-22T09:56:06.000Z",
    workspace: "manjulaworkspace",
},
{
    accent_color: "#FFFFFF",
    bot_id: "7c9a7d59-7d73-4ddd-a04b-f76ced1b7d43",
    button_color: "#FFFFFF",
    created_at: "2021-11-11T06:07:48.000Z",
    description: "hello",
    id: "a4b98c04-ef5c-4d51-902f-5f73de563991",
    is_active: false,
    is_secured: false,
    logo_url: "",
    name: "Helooooooooooo",
    primary_color: "#FFFFFF",
    public_key: null,
    secondary_color: "#FFFFFF",
    snippet: 
        "<div id=\"assistant-widget-a4b98c04-ef5c-4d51-902f-5f73de563991\"></div>\n  <script src=\"https://unpkg.com/assistant-widget@latest/dist/lib.js\"></script>\n  <script>\n      WAWidget.init({\n          el: document.querySelector(\"#assistant-widget-a4b98c04-ef5c-4d51-902f-5f73de563991\"),\n          endpoint: \"http://dev-assistant.workativ.ai/widget\",\n          widget_id: \"a4b98c04-ef5c-4d51-902f-5f73de563991\",\n          openByDefault: false\n      });\n  </script>\n  ",
    updated_at: "2021-11-11T06:07:48.000Z",
    workspace: "manjulaworkspace",

}
];

export const slackValue = Promise.resolve(slackResponse);

const mockGroupingResp = {
  "group_if_node": {
      "type": "if_node",
      "title": "A Title",
      "description": "",
      "conditions": {
          "type": "LogicalExpression",
          "and": {
              "lhs": {
                  "type": "ComparisionExpression",
                  "lhs": {
                      "path": [
                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                          "output",
                          "executionContext",
                          "output",
                          "success"
                      ]
                  },
                  "rhs": true,
                  "op": "equals"
              },
              "rhs": {
                  "type": "LogicalExpression",
                  "and": {
                      "lhs": {
                          "type": "ComparisionExpression",
                          "lhs": {
                              "path": [
                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                  "output",
                                  "executionContext",
                                  "iGO_7dff0de1_f987f4",
                                  "output",
                                  "domain"
                              ]
                          },
                          "rhs": {
                              "type": "text",
                              "value": "powerholding-intl.com"
                          },
                          "op": "not_equals"
                      },
                      "rhs": {
                          "type": "LogicalExpression",
                          "and": {
                              "lhs": {
                                  "type": "ComparisionExpression",
                                  "lhs": {
                                      "path": [
                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                          "output",
                                          "executionContext",
                                          "iGO_7dff0de1_f987f4",
                                          "output",
                                          "domain"
                                      ]
                                  },
                                  "rhs": {
                                      "type": "text",
                                      "value": "uccholding-intl.com"
                                  },
                                  "op": "not_equals"
                              },
                              "rhs": {
                                  "type": "LogicalExpression",
                                  "and": {
                                      "lhs": {
                                          "type": "ComparisionExpression",
                                          "lhs": {
                                              "path": [
                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                  "output",
                                                  "executionContext",
                                                  "iGO_7dff0de1_f987f4",
                                                  "output",
                                                  "domain"
                                              ]
                                          },
                                          "rhs": {
                                              "type": "text",
                                              "value": "urbacon-intl.com"
                                          },
                                          "op": "not_equals"
                                      },
                                      "rhs": {
                                          "type": "LogicalExpression",
                                          "and": {
                                              "lhs": {
                                                  "type": "ComparisionExpression",
                                                  "lhs": {
                                                      "path": [
                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                          "output",
                                                          "executionContext",
                                                          "iGO_7dff0de1_f987f4",
                                                          "output",
                                                          "domain"
                                                      ]
                                                  },
                                                  "rhs": {
                                                      "type": "text",
                                                      "value": "urbaconpmv.com"
                                                  },
                                                  "op": "not_equals"
                                              },
                                              "rhs": {
                                                  "type": "LogicalExpression",
                                                  "and": {
                                                      "lhs": {
                                                          "type": "ComparisionExpression",
                                                          "lhs": {
                                                              "path": [
                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                  "output",
                                                                  "executionContext",
                                                                  "iGO_7dff0de1_f987f4",
                                                                  "output",
                                                                  "domain"
                                                              ]
                                                          },
                                                          "rhs": {
                                                              "type": "text",
                                                              "value": "assets-intl.com"
                                                          },
                                                          "op": "not_equals"
                                                      },
                                                      "rhs": {
                                                          "type": "LogicalExpression",
                                                          "and": {
                                                              "lhs": {
                                                                  "type": "ComparisionExpression",
                                                                  "lhs": {
                                                                      "path": [
                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                          "output",
                                                                          "executionContext",
                                                                          "iGO_7dff0de1_f987f4",
                                                                          "output",
                                                                          "domain"
                                                                      ]
                                                                  },
                                                                  "rhs": {
                                                                      "type": "text",
                                                                      "value": "aura-hospitality.com"
                                                                  },
                                                                  "op": "not_equals"
                                                              },
                                                              "rhs": {
                                                                  "type": "LogicalExpression",
                                                                  "and": {
                                                                      "lhs": {
                                                                          "type": "ComparisionExpression",
                                                                          "lhs": {
                                                                              "path": [
                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                  "output",
                                                                                  "executionContext",
                                                                                  "iGO_7dff0de1_f987f4",
                                                                                  "output",
                                                                                  "domain"
                                                                              ]
                                                                          },
                                                                          "rhs": {
                                                                              "type": "text",
                                                                              "value": "auraentertainment.com"
                                                                          },
                                                                          "op": "not_equals"
                                                                      },
                                                                      "rhs": {
                                                                          "type": "LogicalExpression",
                                                                          "and": {
                                                                              "lhs": {
                                                                                  "type": "ComparisionExpression",
                                                                                  "lhs": {
                                                                                      "path": [
                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                          "output",
                                                                                          "executionContext",
                                                                                          "iGO_7dff0de1_f987f4",
                                                                                          "output",
                                                                                          "domain"
                                                                                      ]
                                                                                  },
                                                                                  "rhs": {
                                                                                      "type": "text",
                                                                                      "value": "auralifestyle-intl.com"
                                                                                  },
                                                                                  "op": "not_equals"
                                                                              },
                                                                              "rhs": {
                                                                                  "type": "LogicalExpression",
                                                                                  "and": {
                                                                                      "lhs": {
                                                                                          "type": "ComparisionExpression",
                                                                                          "lhs": {
                                                                                              "path": [
                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                  "output",
                                                                                                  "executionContext",
                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                  "output",
                                                                                                  "domain"
                                                                                              ]
                                                                                          },
                                                                                          "rhs": {
                                                                                              "type": "text",
                                                                                              "value": "auragroup-intl.com"
                                                                                          },
                                                                                          "op": "not_equals"
                                                                                      },
                                                                                      "rhs": {
                                                                                          "type": "LogicalExpression",
                                                                                          "and": {
                                                                                              "lhs": {
                                                                                                  "type": "ComparisionExpression",
                                                                                                  "lhs": {
                                                                                                      "path": [
                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                          "output",
                                                                                                          "executionContext",
                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                          "output",
                                                                                                          "domain"
                                                                                                      ]
                                                                                                  },
                                                                                                  "rhs": {
                                                                                                      "type": "text",
                                                                                                      "value": "khayyat-qa.com"
                                                                                                  },
                                                                                                  "op": "not_equals"
                                                                                              },
                                                                                              "rhs": {
                                                                                                  "type": "LogicalExpression",
                                                                                                  "and": {
                                                                                                      "lhs": {
                                                                                                          "type": "ComparisionExpression",
                                                                                                          "lhs": {
                                                                                                              "path": [
                                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                  "output",
                                                                                                                  "executionContext",
                                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                                  "output",
                                                                                                                  "domain"
                                                                                                              ]
                                                                                                          },
                                                                                                          "rhs": {
                                                                                                              "type": "text",
                                                                                                              "value": "sazeli-qatar.com"
                                                                                                          },
                                                                                                          "op": "not_equals"
                                                                                                      },
                                                                                                      "rhs": {
                                                                                                          "type": "LogicalExpression",
                                                                                                          "and": {
                                                                                                              "lhs": {
                                                                                                                  "type": "ComparisionExpression",
                                                                                                                  "lhs": {
                                                                                                                      "path": [
                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                          "output",
                                                                                                                          "executionContext",
                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                          "output",
                                                                                                                          "domain"
                                                                                                                      ]
                                                                                                                  },
                                                                                                                  "rhs": {
                                                                                                                      "type": "text",
                                                                                                                      "value": "palma-intl.com"
                                                                                                                  },
                                                                                                                  "op": "not_equals"
                                                                                                              },
                                                                                                              "rhs": {
                                                                                                                  "type": "LogicalExpression",
                                                                                                                  "and": {
                                                                                                                      "lhs": {
                                                                                                                          "type": "ComparisionExpression",
                                                                                                                          "lhs": {
                                                                                                                              "path": [
                                                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                  "output",
                                                                                                                                  "executionContext",
                                                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                                                  "output",
                                                                                                                                  "domain"
                                                                                                                              ]
                                                                                                                          },
                                                                                                                          "rhs": {
                                                                                                                              "type": "text",
                                                                                                                              "value": "q-flora.com"
                                                                                                                          },
                                                                                                                          "op": "not_equals"
                                                                                                                      },
                                                                                                                      "rhs": {
                                                                                                                          "type": "LogicalExpression",
                                                                                                                          "and": {
                                                                                                                              "lhs": {
                                                                                                                                  "type": "ComparisionExpression",
                                                                                                                                  "lhs": {
                                                                                                                                      "path": [
                                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                          "output",
                                                                                                                                          "executionContext",
                                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                                          "output",
                                                                                                                                          "domain"
                                                                                                                                      ]
                                                                                                                                  },
                                                                                                                                  "rhs": {
                                                                                                                                      "type": "text",
                                                                                                                                      "value": "orientpearlqatar.com"
                                                                                                                                  },
                                                                                                                                  "op": "not_equals"
                                                                                                                              },
                                                                                                                              "rhs": {
                                                                                                                                  "type": "LogicalExpression",
                                                                                                                                  "and": {
                                                                                                                                      "lhs": {
                                                                                                                                          "type": "ComparisionExpression",
                                                                                                                                          "lhs": {
                                                                                                                                              "path": [
                                                                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                  "output",
                                                                                                                                                  "executionContext",
                                                                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                                                                  "output",
                                                                                                                                                  "domain"
                                                                                                                                              ]
                                                                                                                                          },
                                                                                                                                          "rhs": {
                                                                                                                                              "type": "text",
                                                                                                                                              "value": "temasq.com"
                                                                                                                                          },
                                                                                                                                          "op": "not_equals"
                                                                                                                                      },
                                                                                                                                      "rhs": {
                                                                                                                                          "type": "LogicalExpression",
                                                                                                                                          "and": {
                                                                                                                                              "lhs": {
                                                                                                                                                  "type": "ComparisionExpression",
                                                                                                                                                  "lhs": {
                                                                                                                                                      "path": [
                                                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                          "output",
                                                                                                                                                          "executionContext",
                                                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                                                          "output",
                                                                                                                                                          "domain"
                                                                                                                                                      ]
                                                                                                                                                  },
                                                                                                                                                  "rhs": {
                                                                                                                                                      "type": "text",
                                                                                                                                                      "value": "basta23-qa.com"
                                                                                                                                                  },
                                                                                                                                                  "op": "not_equals"
                                                                                                                                              },
                                                                                                                                              "rhs": {
                                                                                                                                                  "type": "LogicalExpression",
                                                                                                                                                  "and": {
                                                                                                                                                      "lhs": {
                                                                                                                                                          "type": "ComparisionExpression",
                                                                                                                                                          "lhs": {
                                                                                                                                                              "path": [
                                                                                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                                  "output",
                                                                                                                                                                  "executionContext",
                                                                                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                                                                                  "output",
                                                                                                                                                                  "domain"
                                                                                                                                                              ]
                                                                                                                                                          },
                                                                                                                                                          "rhs": {
                                                                                                                                                              "type": "text",
                                                                                                                                                              "value": "baladnapark.com"
                                                                                                                                                          },
                                                                                                                                                          "op": "not_equals"
                                                                                                                                                      },
                                                                                                                                                      "rhs": {
                                                                                                                                                          "type": "LogicalExpression",
                                                                                                                                                          "and": {
                                                                                                                                                              "lhs": {
                                                                                                                                                                  "type": "ComparisionExpression",
                                                                                                                                                                  "lhs": {
                                                                                                                                                                      "path": [
                                                                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                                          "output",
                                                                                                                                                                          "executionContext",
                                                                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                                                                          "output",
                                                                                                                                                                          "domain"
                                                                                                                                                                      ]
                                                                                                                                                                  },
                                                                                                                                                                  "rhs": {
                                                                                                                                                                      "type": "text",
                                                                                                                                                                      "value": "unitedfoodservices-intl.com"
                                                                                                                                                                  },
                                                                                                                                                                  "op": "not_equals"
                                                                                                                                                              },
                                                                                                                                                              "rhs": {
                                                                                                                                                                  "type": "LogicalExpression",
                                                                                                                                                                  "and": {
                                                                                                                                                                      "lhs": {
                                                                                                                                                                          "type": "ComparisionExpression",
                                                                                                                                                                          "lhs": {
                                                                                                                                                                              "path": [
                                                                                                                                                                                  "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                                                  "output",
                                                                                                                                                                                  "executionContext",
                                                                                                                                                                                  "iGO_7dff0de1_f987f4",
                                                                                                                                                                                  "output",
                                                                                                                                                                                  "domain"
                                                                                                                                                                              ]
                                                                                                                                                                          },
                                                                                                                                                                          "rhs": {
                                                                                                                                                                              "type": "text",
                                                                                                                                                                              "value": "mokarabia.com.qa"
                                                                                                                                                                          },
                                                                                                                                                                          "op": "not_equals"
                                                                                                                                                                      },
                                                                                                                                                                      "rhs": {
                                                                                                                                                                          "type": "LogicalExpression",
                                                                                                                                                                          "and": {
                                                                                                                                                                              "lhs": {
                                                                                                                                                                                  "type": "ComparisionExpression",
                                                                                                                                                                                  "lhs": {
                                                                                                                                                                                      "path": [
                                                                                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                                                          "output",
                                                                                                                                                                                          "executionContext",
                                                                                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                                                                                          "output",
                                                                                                                                                                                          "domain"
                                                                                                                                                                                      ]
                                                                                                                                                                                  },
                                                                                                                                                                                  "rhs": {
                                                                                                                                                                                      "type": "text",
                                                                                                                                                                                      "value": "thestation-qa.com"
                                                                                                                                                                                  },
                                                                                                                                                                                  "op": "not_equals"
                                                                                                                                                                              },
                                                                                                                                                                              "rhs": {
                                                                                                                                                                                  "type": "ComparisionExpression",
                                                                                                                                                                                  "lhs": {
                                                                                                                                                                                      "path": [
                                                                                                                                                                                          "SYS_a7086508_1456_4110_986a_a42bf2d71c05",
                                                                                                                                                                                          "output",
                                                                                                                                                                                          "executionContext",
                                                                                                                                                                                          "iGO_7dff0de1_f987f4",
                                                                                                                                                                                          "output",
                                                                                                                                                                                          "domain"
                                                                                                                                                                                      ]
                                                                                                                                                                                  },
                                                                                                                                                                                  "rhs": {
                                                                                                                                                                                      "type": "text",
                                                                                                                                                                                      "value": "maia-qatar.com"
                                                                                                                                                                                  },
                                                                                                                                                                                  "op": "not_equals"
                                                                                                                                                                              }
                                                                                                                                                                          }
                                                                                                                                                                      }
                                                                                                                                                                  }
                                                                                                                                                              }
                                                                                                                                                          }
                                                                                                                                                      }
                                                                                                                                                  }
                                                                                                                                              }
                                                                                                                                          }
                                                                                                                                      }
                                                                                                                                  }
                                                                                                                              }
                                                                                                                          }
                                                                                                                      }
                                                                                                                  }
                                                                                                              }
                                                                                                          }
                                                                                                      }
                                                                                                  }
                                                                                              }
                                                                                          }
                                                                                      }
                                                                                  }
                                                                              }
                                                                          }
                                                                      }
                                                                  }
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      },
      "previous_sibling": "80bb1852-ad57-47f0-a028-af63bc3f6ec7"
  },
  "if_node_uuid_to_group": ["0749fd3d-5b9b-4c8b-946a-88577a1c0924", "8662cabe-c595-4e7b-80e9-f14f06cbda7c"]
}
export const MOCK_Notification = [
    {
      id: 34,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        type: 'option',
        title: {
          value: 'test',
          md_type: 'md_normal',
        },
        options: [
          {
            label: {
              value: 'test',
              md_type: 'md_normal',
            },
            value: {
              value: 'test',
              md_type: 'md_normal',
            },
          },
          {
            label: {
              value: 'TEst1',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test2',
              md_type: 'md_normal',
            },
          },
        ],
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T14:08:32.000Z',
      updated_at: '2024-06-19T14:08:32.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 543735,
      success: 0,
      failed: 0,
      inprogress: 543735,
    },
    {
      id: 33,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        type: 'option',
        title: {
          value: 'test',
          md_type: 'md_normal',
        },
        options: [
          {
            label: {
              value: 'test',
              md_type: 'md_normal',
            },
            value: {
              value: 'test',
              md_type: 'md_normal',
            },
          },
          {
            label: {
              value: 'TEst1',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test2',
              md_type: 'md_normal',
            },
          },
        ],
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T13:20:59.000Z',
      updated_at: '2024-06-19T13:20:59.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 554211,
      success: 0,
      failed: 0,
      inprogress: 554211,
    },
    {
      id: 32,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T11:39:50.000Z',
      updated_at: '2024-06-19T11:39:50.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 31,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T11:37:42.000Z',
      updated_at: '2024-06-19T11:37:42.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 30,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T11:35:28.000Z',
      updated_at: '2024-06-19T11:35:28.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 29,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        type: 'option',
        title: {
          value: 'test',
          md_type: 'md_normal',
        },
        options: [
          {
            label: {
              value: 'test',
              md_type: 'md_normal',
            },
            value: {
              value: 'test',
              md_type: 'md_normal',
            },
          },
          {
            label: {
              value: 'TEst1',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test2',
              md_type: 'md_normal',
            },
          },
        ],
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T11:30:57.000Z',
      updated_at: '2024-06-19T11:30:57.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 608244,
      success: 25513,
      failed: 0,
      inprogress: 582731,
    },
    {
      id: 28,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T09:32:50.000Z',
      updated_at: '2024-06-19T09:32:50.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 27,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T08:27:53.000Z',
      updated_at: '2024-06-19T08:27:53.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 26,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T08:25:32.000Z',
      updated_at: '2024-06-19T08:25:32.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
    {
      id: 25,
      bot_id: 'b601ac12-3b97-40d0-83a4-a85fdaa0dc8c',
      message: {
        logo: 'https://media.trustradius.com/product-logos/4x/rj/FQP3TOUS0LIQ.PNG',
        type: 'adaptive_card',
        title: {
          value: 'Notify title',
          md_type: 'md_normal',
        },
        fields: [
          {
            title: {
              value: 'Subject',
              md_type: 'md_normal',
            },
            value: {
              value: 'Test Subject',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'Description',
              md_type: 'md_normal',
            },
            value: {
              value: 'Broken laptop',
              md_type: 'md_normal',
            },
          },
          {
            title: {
              value: 'ID',
              md_type: 'md_normal',
            },
            value: {
              value: '5756',
              md_type: 'md_normal',
            },
          },
        ],
        buttons: [
          {
            url: 'https://workativ.com',
            text: {
              value: 'View Ticket',
              md_type: 'md_normal',
            },
          },
        ],
        description: {
          value: 'Notify Descriotion',
          md_type: 'md_normal',
        },
        notification_title: 'Notification Testing',
      },
      created_at: '2024-06-19T08:24:01.000Z',
      updated_at: '2024-06-19T08:24:01.000Z',
      notification_title: 'Notification',
      type: 'SINGLE',
      totaluser: 1,
      success: 1,
      failed: 0,
      inprogress: 0,
    },
  ];