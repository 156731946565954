import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
/*eslint-disable*/
import ReactTooltip from 'react-tooltip'

import { getAssistants } from '@/Assistant/Mechanisms/action'
import { DateSelectInput } from '@/Flows/analytics/dateDropDown'
import { SideBarContext } from '@/SidePanel/SideBarContext'
import {
    AnalyticsAscendingSortIcon,
    AnalyticsDescendingSortIcon,
    BotIconAnalytics,
    ConversationsMessage,
    ConversationsTotal,
    DownArrowCircle,
    InfoIcon,
    LeftArrowWithCircle,
    RightArrowWithCircle,
    ThumbsDownIcon,
    ThumbsUpIcon,
    UserIconAnalytics,
    UserIconAnalyticsMenu
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { BarExtendedDatum, ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import {
    analyticsGetJson,
    getJson,
    postJson,
    stripeGetApi,
    watsonApiURL,
    watsonLogsApiURL
} from '@/common/utils/api-utils'
import { getDateNumber } from '../Logs/index'
import { TryIt } from '../TrainingModule/DemoTryIt'
import {
    BarchartData,
    Conversation,
    ConversationT,
    DefaultResponse,
    DefaultResponseT,
    MostUsedEntities,
    MostUsedIntents,
    RequestType
} from './types'

const ramda = require('ramda')

const items: DateSelectInput[] = [
    {
        date: 'yesterday',
        label: 'Yesterday'
    },
    {
        date: 'today',
        label: 'Today'
    }
]

export function DialogAnalyticsHome(props: any) {
    const [tooltipData, setTooltipData] = useState(false)
    const [dialogs, setDialogs] = useState(DefaultResponse)
    const [state, setState] = useState({
        showFlowPopup: false,
        selectedAutomation: 'Select',
        showDatePopup: false,
        selectedDate: {
            date: 'today',
            label: 'Today'
        },
        initialCall: true
    })
    const [defaultDialogs, setDefaultdialogs] = useState(DefaultResponse)

    const [overAllConvos, setOverAllConvos] = useState<BarchartData[]>([])

    const [dateOptions, setDateOptions] = useState<DateSelectInput[]>(items)

    const [loading, setLoading] = useState({
        show: false,
        error: { error: false, info: '' }
    })

    const [unauthorized, setUnauthorized] = useState<boolean>(false)

    const [notDeployed, setNotDeployed] = useState<boolean>(false)

    const { workspacename, assistantname } = props.match.params

    const [BOT_ID, setBOT_ID] = useState<string>('')

    const [feedbacks, setFeedbacks] = useState<{ satisfied: number; not_satisfied: number }>({
        satisfied: 0,
        not_satisfied: 0
    })

    useEffect(() => {
        stripeGetApi(`${workspacename}/info/logs_limit`).then((resp: { output: string; type: 'success' }) => {
            const maxDays = Number(resp.output)
            const daysList = [30, 14, 7]
                .filter(x => x <= maxDays)
                .map(value => ({ date: `last ${value} days`, label: `Last ${value} Days` }))

            setDateOptions(daysList.concat(dateOptions))
        })
    }, [])

    //for the first time to get total conversations
    useEffect(() => {
        setLoading({ ...loading, show: true })
        getAssistants(workspacename).then(data => {
            const assistant = data.reduce((accum: any, val: any) => {
                if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                else return accum
            }, null)
            if (assistant !== null && assistant.deployed !== null) {
                setBOT_ID(assistant.id)
                Promise.all([
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/analytics/top_entities/${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}`
                        )
                    ),
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/analytics/top_intents/${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}`
                        )
                    ),
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/analytics/day_wise_report/${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}`
                        )
                    ),
                    getJson(
                        watsonLogsApiURL(
                            `/${workspacename}/analytics/feedbacks/${assistant.id}/${getDateNumber(
                                state.selectedDate.date
                            )}`
                        )
                    ),
                    getJson(watsonLogsApiURL(`/${workspacename}/analytics/day_wise_report/${assistant.id}/${30}`))
                ])

                    .then(([top_entities, top_intents, barchart, feedbacks, overAllConvos]) => {
                        setFeedbacks(feedbacks)
                        setDialogs({
                            ...dialogs,
                            top_entities,
                            top_intents,
                            feedbacks,
                            barchart: barchart
                        })
                        setOverAllConvos(overAllConvos)
                        setDefaultdialogs({
                            ...defaultDialogs
                        })
                        setLoading({ ...loading, show: false })
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status == 403) {
                            setUnauthorized(true)
                        } else {
                            setLoading({
                                show: true,
                                error: {
                                    error: true,
                                    info: 'Server error!<br />Please try again after sometime.'
                                }
                            })
                        }
                    })
            } else if (assistant !== null && assistant.deployed === null) {
                setNotDeployed(true)
                setLoading({ ...loading, show: false })
            }
        })
    }, [])

    function callApi() {
        setLoading({ ...loading, show: true })
        // getJson(
        //     watsonLogsApiURL(`/conversations/${BOT_ID}/${getDateNumber(state.selectedDate.date)}/all`)
        // )
        Promise.all([
            getJson(
                watsonLogsApiURL(
                    `/${workspacename}/analytics/top_entities/${BOT_ID}/${getDateNumber(state.selectedDate.date)}`
                )
            ),
            getJson(
                watsonLogsApiURL(
                    `/${workspacename}/analytics/top_intents/${BOT_ID}/${getDateNumber(state.selectedDate.date)}`
                )
            ),
            getJson(
                watsonLogsApiURL(
                    `/${workspacename}/analytics/day_wise_report/${BOT_ID}/${getDateNumber(state.selectedDate.date)}`
                )
            ),
            getJson(
                watsonLogsApiURL(
                    `/${workspacename}/analytics/feedbacks/${BOT_ID}/${getDateNumber(state.selectedDate.date)}`
                )
            )
        ])
            .then(([top_entities, top_intents, barchart, feedbacks]) => {
                setFeedbacks(feedbacks)
                setDialogs({
                    ...dialogs,
                    top_entities,
                    top_intents,
                    feedbacks,
                    barchart: barchart
                })
                setLoading({ ...loading, show: false })
            })
            .catch(err =>
                setLoading({
                    show: true,
                    error: {
                        error: true,
                        info: 'Server error!<br />Please try again after sometime.'
                    }
                })
            )
    }

    //call Api after changing date
    useEffect(() => {
        if (!state.initialCall) {
            callApi()
        }
    }, [state.selectedDate.date])

    function setSelectedDate(date: any) {
        setState({ ...state, selectedDate: date.date, showDatePopup: false, initialCall: false })
    }

    return (
        <ErrorBoundary
            notDeployed={notDeployed}
            notDeployedFn={() => {
                props.history.push(`/workspace/${workspacename}/dialog/${assistantname}/dialogs`)
            }}
            unauthorized={unauthorized}
            error={loading.error}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.PageLoadingError
                            onClick={() => callApi()}
                            info={info}
                            className={'analytics_page_error'}
                            btnName="Retry"
                        />
                    )
                )
            }}
        >
            <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Chatbot Analytics</div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    <div className="second_intent_right">
                        <Dropdowns
                            state={state}
                            displayItems={dateOptions}
                            setState={setState}
                            setSelectedDate={setSelectedDate}
                            dialogs={dialogs}
                        />
                    </div>
                </HeaderChild>
            </Header>
            <div className="header_loader_height_change">
                {loading.show ? (
                    <Loader.PageLoader show={true} className="analytics_loader" />
                ) : (
                    <PageWrapper>
                        <DefaultInfoHeader overAllConvos={overAllConvos} />

                        <Charts
                            setTooltipData={tooltip => setTooltipData(tooltip)}
                            tooltipData={tooltipData}
                            dialogs={dialogs}
                            feedbacks={feedbacks}
                        />
                        <ConversationListView dialogs={dialogs} />
                    </PageWrapper>
                )}
            </div>
        </ErrorBoundary>
    )
}

function calculateTotalMsgs(converastions: ConversationT[]) {
    return converastions.reduce((acc: any, cur: ConversationT) => acc + cur.num_of_messages, 0)
}

function DefaultInfoHeader(props: { overAllConvos: BarchartData[] }) {
    function sum(arr: BarchartData[]) {
        return arr.reduce(
            (acc, curr) => {
                return {
                    totalMessages: acc.totalMessages + curr.num_of_messages,
                    totalConvos: acc.totalConvos + curr.num_of_conversations
                }
            },
            { totalMessages: 0, totalConvos: 0 }
        )
    }

    const data = sum(props.overAllConvos)

    const higherConversation = props.overAllConvos.reduce(
        function(prev, current) {
            if (+current.num_of_conversations > +prev.num_of_conversations) {
                return current
            } else {
                return prev
            }
        },
        { date: '', num_of_conversations: 0, num_of_messages: 0 }
    )
    const split = higherConversation.date.split('/')
    const date = `${split[1]}/${split[0]}/${split[2]}`

    const { isSubPanelOpen } = useContext(SideBarContext)

    return (
        <div className="conversation_analytics">
            <div className="col-md-12">
                <div className="row">
                    <div className="total_days_overview">
                        <div className="total_days_overview_top">
                            {/* <div className="last_days_overview">
                                <h4>Last 30 days overview:</h4>
                            </div> */}
                            <div className="last_toal_conversation">
                                <div className="last_toal_conversation_container">
                                    <div className="last_toal_conversation_left">
                                        <ConversationsTotal />
                                    </div>
                                    <div className="last_toal_conversation_right">
                                        <h5>Total Sessions</h5>
                                        <h6>{data.totalConvos}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="last_toal_conversation">
                                <div className="last_toal_conversation_container">
                                    <div className="last_toal_conversation_left">
                                        <ConversationsMessage />
                                    </div>
                                    <div className="last_toal_conversation_right">
                                        <h5>Total Messages</h5>
                                        <h6>{data.totalMessages}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="last_toal_conversation">
                                <div className="last_toal_conversation_container">
                                    <div className="last_toal_conversation_left">
                                        <ConversationsMessage />
                                    </div>
                                    <div className="last_toal_conversation_right">
                                        <h5>
                                            <span>Average Messages </span>
                                            <span className="tooltip_analytics" data-tip data-for="tooltiptext">
                                                <InfoIcon />
                                            </span>
                                            <ReactTooltip
                                                className="tooltiptext_hover"
                                                id="tooltiptext"
                                                type="warning"
                                                effect="solid"
                                            >
                                                <span>
                                                    {' '}
                                                    Average no. of messages (For a 30 day period) = Total no. of
                                                    messages during that period divided by total no. of sessions during
                                                    that period.
                                                </span>
                                            </ReactTooltip>
                                        </h5>
                                        <h6>
                                            {data.totalConvos > 0
                                                ? (data.totalMessages / data.totalConvos).toFixed(2)
                                                : '0'}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="last_toal_conversation">
                                <div className="last_toal_conversation_container">
                                    <div className="last_toal_conversation_left">
                                        <ConversationsTotal />
                                    </div>
                                    <div className="last_toal_conversation_right">
                                        <h5>
                                            Sessions in a Day{' '}
                                            <span className="tooltip_analytics" data-tip data-for="tooltiptext1">
                                                <InfoIcon />
                                            </span>
                                            <ReactTooltip
                                                className={
                                                    isSubPanelOpen
                                                        ? 'tooltiptext_hover isSubPanelOpen_left'
                                                        : 'tooltiptext_hover '
                                                }
                                                id="tooltiptext1"
                                                type="warning"
                                                effect="solid"
                                            >
                                                <span>
                                                    {' '}
                                                    Maximum no. of sessions that happened in a day during a 30 day
                                                    period.
                                                </span>
                                            </ReactTooltip>
                                        </h5>
                                        <h6>
                                            {higherConversation.num_of_conversations}
                                            <span className="date_conversation">
                                                {higherConversation && date
                                                    ? `(${new Date(date).toLocaleDateString()})`
                                                    : ''}
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Dropdowns(props: {
    state: {
        showFlowPopup: boolean
        selectedAutomation: string
        showDatePopup: boolean
        selectedDate: { date: string; label: string }
    }
    displayItems: DateSelectInput[]
    setState: any
    setSelectedDate: (date: { date: string; label: string }) => void
    dialogs: DefaultResponseT
}) {
    const { state, setState, setSelectedDate, dialogs } = props

    return (
        <div className="analytics_dropdowns">
            <div className="analytics_dropdowns_left">
                <div className="ana_chart_drpdwn_left_sixty"></div>
                <div className="ana_chart_drpdwn_left_fourty">
                    <div className="form-group">
                        <label className="control-label">Show :</label>
                        <div
                            className="date_dropdown_label"
                            onClick={() =>
                                setState({
                                    ...state,
                                    showDatePopup: true,
                                    showFlowPopup: false
                                })
                            }
                        >
                            {state.selectedDate.label}
                            <div
                                className="dropdown_down_arrowslot"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        showDatePopup: true,
                                        showFlowPopup: false
                                    })
                                }
                            >
                                <DownArrowCircle />
                            </div>
                            <DateDropDown
                                showState={state}
                                displayItems={props.displayItems}
                                selectedDate={setSelectedDate}
                                flows={dialogs}
                                closePopup={() => setState({ ...state, showDatePopup: false })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Charts(props: {
    tooltipData: any
    setTooltipData: (tooltip: any) => void
    dialogs: DefaultResponseT
    feedbacks: {
        satisfied: number
        not_satisfied: number
    }
}) {
    const { setTooltipData, tooltipData, dialogs, feedbacks } = props

    return (
        <div className="analytics_charts">
            <div className="col-md-12">
                <div className="row analytics_charts_row">
                    <div
                        className={
                            dialogs.conversations && dialogs.conversations.length > 0
                                ? 'col-md-8 analytics_charts_left conversation_analytics_chart'
                                : 'col-md-8  analytics_charts_left no-data-analytics conversation_analytics_chart'
                        }
                    >
                        {dialogs.barchart && dialogs.barchart.length > 0 ? (
                            <React.Fragment>
                                <div className=" analytics_charts_left">
                                    <div className="ana_chart_drpdwn">
                                        <div className="ana_chart_drpdwn_left analytics_charts_left_overview">
                                            <div className="ana_chart_drpdwn_left_sixty">
                                                <div className="ana_chart_drpdwn_left_scuus_fails">
                                                    <label>
                                                        <span className="drpdwn_left_scuus_fails" />
                                                        <span className="drpdwn_left_scuus_content">Sessions</span>
                                                    </label>
                                                    <label>
                                                        <span className="drpdwn_left_scuus_fails1" />
                                                        <span className="drpdwn_left_scuus_content">Messages</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analytics_charts_left_img">
                                        <BarCharts
                                            setTooltipData={setTooltipData}
                                            tooltipData={tooltipData}
                                            dialogs={dialogs}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>

                    <div
                        className={
                            feedbacks.satisfied + feedbacks.not_satisfied > 0
                                ? 'col-md-4 analytics_charts_right '
                                : 'col-md-4 analytics_charts_right  no-data-analytics'
                        }
                    >
                        {feedbacks.satisfied + feedbacks.not_satisfied > 0 ? (
                            <React.Fragment>
                                <div className="ana_chart_drpdwn_left analytics_charts_left_overview">
                                    <div className="ana_chart_drpdwn_left_sixty">
                                        <div className="ana_chart_drpdwn_left_scuus_fails">
                                            <label className="automation_exe_left">
                                                <h6>
                                                    Feedback:{' '}
                                                    <span className="analytics_total_left">
                                                        {feedbacks.satisfied + feedbacks.not_satisfied}
                                                    </span>
                                                </h6>
                                            </label>
                                            <div className="success_thumps_wrapper">
                                                <label className="automation_exe_right">
                                                    <span className="drpdwn_left_scuus_fails" />
                                                    <span className="drpdwn_left_scuus_content">Satisfied</span>
                                                </label>
                                                <label className="automation_exe_right">
                                                    <span className="drpdwn_left_scuus_fails1" />
                                                    <span className="drpdwn_left_scuus_content">Unsatisfied</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="analytics_charts_left_img">
                                    <PieCharts feedbacks={feedbacks} />
                                </div>
                                <div className="dialog_analytics_thumps_svg">
                                    <div className="analytics_charts_left_total">
                                        <div className="analytics_charts_left_total_center">
                                            <h6>
                                                <span>
                                                    <ThumbsUpIcon />
                                                </span>
                                                <span className="analytics_total_center">{feedbacks.satisfied}</span>
                                            </h6>
                                        </div>
                                        <div className="analytics_charts_left_total_center">
                                            <h6>
                                                <span>
                                                    <ThumbsDownIcon />
                                                </span>
                                                <span className="analytics_total_right">{feedbacks.not_satisfied}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function PieCharts({
    feedbacks
}: {
    feedbacks: {
        satisfied: number
        not_satisfied: number
    }
}) {
    const successRate = (feedbacks.satisfied / (feedbacks.satisfied + feedbacks.not_satisfied)) * 100
    const failRate = (feedbacks.not_satisfied / (feedbacks.satisfied + feedbacks.not_satisfied)) * 100
    const data = [
        { value: Math.round(successRate), id: 'successRate', label: 'Satisfied' },
        { value: Math.round(failRate), id: 'failRate', label: 'Not Satisfied' }
    ]

    return (
        <div className="analytics_charts_left_img pie-chart">
            <div style={{ height: 200, width: 200, marginBottom: 0 }}>
                <ResponsivePie
                    sortByValue={true}
                    data={data}
                    startAngle={-180}
                    innerRadius={0.65}
                    borderWidth={0}
                    enableSlicesLabels={false}
                    animate={true}
                    tooltip={CustomPieTooltip}
                    colors={['#A1E89B', '#FF8484']}
                    enableRadialLabels={false}
                    theme={{
                        tooltip: {
                            container: {
                                background: 'transparent',
                                padding: 0,
                                borderRadius: 6
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

export const CustomPieTooltip = (props: any) => {
    if (props) {
        return (
            <div className={'tooltiptext'}>
                {props.label}: {props.value}%
            </div>
        )
    }
    return null
}

const BarchartTooltip = (props: BarExtendedDatum) => {
    if (props) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip_date">Date: {props.data.name}</p>
                <p className="desc1">Sessions: {props.data.conversations}</p>
                <p className="desc2">Messages: {props.data.messages}</p>
            </div>
        )
    }
    return null
}

function BarCharts(props: { setTooltipData: (v: any) => void; tooltipData: any; dialogs: DefaultResponseT }) {
    const { dialogs } = props

    function grouping() {
        const filteredValue = ramda.groupBy((x: BarchartData) => {
            const split = x.date.split('/')
            return new Date(`${split[1]}/${split[0]}/${split[2]}`).toLocaleDateString()
        })(dialogs.barchart)
        return filteredValue
    }

    const { isSubPanelOpen } = useContext(SideBarContext)

    function filterDate() {
        return dialogs.barchart.map(chart => {
            console.log(chart)
            return {
                name: chart.date,
                conversations: chart.num_of_conversations,
                messages: chart.num_of_messages
            }
        })
    }

    const datas: any = filterDate()

    return (
        <div className="analytics_charts_left_img">
            <div
                style={{
                    height: 360,
                    width: isSubPanelOpen ? 680 : 790,
                    marginTop: -70,
                    paddingBottom: 10,
                    paddingTop: 20
                }}
            >
                <ResponsiveBar
                    data={datas}
                    keys={['conversations', 'messages']}
                    indexBy="name"
                    margin={{ top: 50, right: 50, bottom: 70, left: 20 }}
                    padding={0.7}
                    innerPadding={10}
                    borderRadius={5}
                    borderWidth={1}
                    groupMode={'grouped'}
                    colors={['#7AE3DC', '#FFAB57']}
                    enableLabel={false}
                    tooltip={BarchartTooltip}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    animate={true}
                    motionStiffness={120}
                    motionDamping={30}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: -40
                    }}
                    axisLeft={{
                        tickSize: 0
                    }}
                    theme={{
                        tooltip: {
                            container: {
                                background: 'transparent',
                                padding: 0,
                                borderRadius: 6
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

function ConversationListView(props: { dialogs: DefaultResponseT }) {
    const { dialogs } = props

    return (
        <div className="most_used_autoamtions">
            <div className="col-md-12">
                <div className="row">
                    <div className="most_used_automationtotal">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th>Top Intents</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dialogs.top_intents && dialogs.top_intents.length > 0 ? (
                                    dialogs.top_intents.length > 4 ? (
                                        dialogs.top_intents.slice(0, 5).map((intent: MostUsedIntents, i: number) => (
                                            <tr key={i}>
                                                <td>{intent.intent}</td>
                                                <td className="text-center">{intent.count}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        dialogs.top_intents.map((intent: MostUsedIntents, i: number) => (
                                            <tr key={i}>
                                                <td>{intent.intent}</td>
                                                <td className="text-center">{intent.count}</td>
                                            </tr>
                                        ))
                                    )
                                ) : (
                                    <p>No data available</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="most_used_automation_least">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th>Top Entities</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dialogs.top_entities && dialogs.top_entities.length > 0 ? (
                                    dialogs.top_entities.length > 4 ? (
                                        dialogs.top_entities.slice(0, 5).map((entity: MostUsedEntities, i: number) => (
                                            <tr key={i}>
                                                <td>{entity.entity}</td>
                                                <td className="text-center">{entity.count}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        dialogs.top_entities.map((entity: MostUsedEntities, i: number) => (
                                            <tr key={i}>
                                                <td>{entity.entity}</td>
                                                <td className="text-center">{entity.count}</td>
                                            </tr>
                                        ))
                                    )
                                ) : (
                                    <p>No data available</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function renderMessages(bot: boolean, options: boolean, data: any) {
    if (bot && !options) {
        return (
            <div className="tryit_understand_parent">
                <span>
                    <BotIconAnalytics />
                </span>
                <p className="tryit_understand">Arunachalamoorthy</p>
            </div>
        )
    } else if (bot && options) {
        return (
            <div className="tryit_understand_parent">
                <span>
                    <BotIconAnalytics />
                </span>
                <p className="tryit_understand">Arunachalamoorthy</p>
                <div className="tryit_option">
                    <button className="btn btn-primary">
                        <span>bdsabkj</span>
                    </button>
                    <button className="btn btn-primary">
                        <span>dsabbjdksa</span>
                    </button>
                    <button className="btn btn-primary">
                        <span>dsadas</span>
                    </button>
                </div>
            </div>
        )
    } else {
        return (
            <React.Fragment>
                <div style={{ float: 'left', clear: 'both' }} />
                <div className="tryit_dropdown_user">
                    <div className="tryit_password_parent">
                        <div className="width_17_user">
                            <span className="entity_name_user_span">
                                <UserIconAnalyticsMenu />
                            </span>
                        </div>
                        <div className="tryit_password tryit_password_p">
                            <p>Arunachalamoorthy Sethutaj</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export function OverviewPopup(props: any) {
    const [search, setSearch] = useState('')

    function sortDataWithApi(id: string) {
        let req = props.request
        req = {
            type: RequestType.IndividualFlow,
            req: { ...req.req, flow: id, page: 1 }
        }
        props.setRequest(req)
        props.callApi(req.req)
        props.closePopup()
        setSearch('')
    }

    function sortAllDataWithApi() {
        let req = props.request
        req = { type: RequestType.AllFlows, req: { ...req.req, page: 1 } }
        req.req.flow && delete req.req.flow
        props.setRequest(req)
        props.callApi(req.req)
        props.closePopup()
        setSearch('')
    }

    function filterBySearch(array: any[], search: string) {
        return array.filter((x: any) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
    }

    return props.showState.showFlowPopup ? (
        <Downshift isOpen={true} onOuterClick={() => props.closePopup()}>
            {() => (
                <div
                    className="dropdown_right_entity"
                    role="combobox"
                    aria-expanded="true"
                    aria-haspopup="listbox"
                    aria-owns="downshift-1-menu"
                    aria-labelledby="downshift-1-label"
                >
                    <label className="form-input">
                        <input
                            type="text"
                            className="form-control ana_chart_drpdwn_left_fourty_form_control"
                            placeholder="Automation name"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                        />
                    </label>
                    <div className="dialog_scroll_list">
                        <ul>
                            <li onClick={sortAllDataWithApi}>All</li>
                            {props.data.flows &&
                                filterBySearch(props.data.flows, search).map((flow: any, i: number) => (
                                    <li key={i} onClick={() => sortDataWithApi(flow.flowId)}>
                                        {flow.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </Downshift>
    ) : null
}

export function DateDropDown(props: {
    showState: {
        showFlowPopup: boolean
        selectedAutomation: string
        showDatePopup: boolean
        selectedDate: { date: string; label: string }
    }
    selectedDate: any
    displayItems?: DateSelectInput[]
    closePopup: any
    flows: any
}) {
    function selectedDate(date: { date: string; label: string }) {
        props.selectedDate({ date })
    }

    const { showState } = props

    const items: DateSelectInput[] = []

    const allItems = props.displayItems ? items.concat(props.displayItems) : items

    return showState.showDatePopup ? (
        <Downshift isOpen={true} onOuterClick={() => props.closePopup()}>
            {() => (
                <div
                    className="dropdown_right_entity"
                    role="combobox"
                    aria-expanded="true"
                    aria-haspopup="listbox"
                    aria-owns="downshift-17-menu"
                    aria-labelledby="downshift-17-label"
                >
                    <ul className="ana_chart_dropdown_right_entity">
                        {allItems.map((item, index) => (
                            <li
                                key={index}
                                onClick={e => {
                                    e.stopPropagation()
                                    selectedDate(item)
                                }}
                            >
                                <p>{item.label}</p>
                                <span>
                                    <input
                                        type="radio"
                                        className="option-input radio"
                                        name="example"
                                        onChange={() => {}}
                                        checked={showState.selectedDate.date === item.date}
                                    />
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Downshift>
    ) : null
}
