import React, { useImperativeHandle, useRef } from 'react'
import PIP from './PIP'
import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'
import classes from './ResizablePIP.module.css'

type ResizablePIPPropsT = {
    width: number
    height: number
    minConstraints: [number, number]
    maxConstraints: [number, number]
}

const ResizablePIP = React.forwardRef<HTMLElement,React.PropsWithChildren<ResizablePIPPropsT> >(
    (
        {
            children,
            width = 500,
            height = 500,
            minConstraints = [300, 300] as [number, number],
            maxConstraints = [800, 800] as [number, number]
        },
        ref
    ) => {
        console.log('ref', ref)
        return (
            <div>
                <PIP ref={ref}>
                    <ResizableBox
                        className={classes.pipResizableContent}
                        width={width}
                        height={height}
                        minConstraints={undefined}
                        maxConstraints={undefined}
                    >
                        <>{children}</>
                    </ResizableBox>
                </PIP>
            </div>
        )
    }
)

export default ResizablePIP
