import * as R from 'ramda'

// Icons Import
import {
    AlphabetIcon,
    BooleanIcon,
    FlowCanvasContextIcons,
    FlowIcon,
    NextIconCanvas,
    NumberIcon,
    RightArrowForAutomationErrors,
    RightArrowWithCircle,
    SearchIcon
} from '@/common/Icons/Icons'
//TYPE IMPORT
import { AutomationT, DialogueT, IfNodeT, ResponseNodeE } from '../Dialogue/kind'
import { DialogueContext, VariableContext, VariableContextT } from './Context'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import { ContextOutputTree } from '@/Flows/canvas/ContextPopup'
import { EntityT } from '../Entity/kind'
import logoImg from '@/common/images/logo.png'
//CONTEXT IMPORTS
import { sortBySibling } from './action'
import userWhite from '@/common/images/user_blue.svg'

const defaultSystemContext = [
    'SYS_FirstName',
    'SYS_LastName',
    'SYS_UserEmail',
    'SYS_Location',
    'SYS_platform',
    'SYS_Anything_Else',
    'SYS_User_Latest_Response',
    "SYS_previous_dialog_title",
    "SYS_current_dialog_title",
    'SYS_user_conversation'
]

const getIcon = (type: string) => {
    if (type == 'string') return <AlphabetIcon />
    else if (type == 'number') return <NumberIcon />
    else if (type == 'boolean') return <BooleanIcon />
    else if (type == 'date')
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
                <g>
                    <path
                        className="st_cal"
                        d="M11.13,3.35c0.56,0,1.01-0.45,1.01-1.01V1.29c0-0.56-0.45-1.01-1.01-1.01s-1.01,0.45-1.01,1.01v1.06
    C10.12,2.9,10.58,3.35,11.13,3.35z"
                    />
                    <path
                        className="st_cal"
                        d="M14.72,2.03h-2.03v0.51c0,0.85-0.69,1.54-1.54,1.54c-0.85,0-1.54-0.69-1.54-1.54V2.03h-4.1v0.51
    c0,0.85-0.69,1.54-1.54,1.54S2.44,3.39,2.44,2.54V2.03L0.38,2v12.84h1.03h12.3l1.02,0L14.72,2.03z M13.71,13.82H1.41v-8.2h12.3
    L13.71,13.82L13.71,13.82z"
                    />
                    <path
                        className="st_cal"
                        d="M3.96,3.35c0.56,0,1.01-0.45,1.01-1.01V1.29c0-0.56-0.45-1.01-1.01-1.01S2.95,0.73,2.95,1.29v1.06
    C2.95,2.9,3.4,3.35,3.96,3.35z"
                    />
                    <rect x="5.51" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="8.13" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="6.72" className="st_cal" width="1.62" height="1.43" />
                    <rect x="5.51" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="8.13" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="9.08" className="st_cal" width="1.62" height="1.43" />
                    <rect x="5.51" y="11.5" className="st_cal" width="1.62" height="1.43" />
                    <rect x="2.95" y="9.08" className="st_cal" width="1.61" height="1.43" />
                    <rect x="2.95" y="11.5" className="st_cal" width="1.61" height="1.43" />
                    <rect x="8.13" y="11.5" className="st_cal" width="1.62" height="1.43" />
                    <rect x="10.55" y="11.5" className="st_cal" width="1.62" height="1.43" />
                </g>
            </svg>
        )
    return null
}

const DialogItem = ({ dialog, index, onclick }: { dialog: DialogueT; index: number; onclick: Function }) => (
    <div className="context_dialog_popup_accordian context_dialog_popup_accordian_connection ">
        <div className="context_dialog_join_output">
            <h4 onClick={e => onclick()}>
                <span className="dialog_context_number">
                    <span className="dialog_context_span_number">{index}</span>
                </span>
                <span className="context_path">{dialog.title}</span>
                <span>
                    <div className="arrow_up_form closed_state_arrow">
                        <RightArrowWithCircle />
                    </div>
                </span>
            </h4>
        </div>
    </div>
)

export const EntityHeaderInPicker = ({ onclick }: { onclick: Function }) => (
    <div className="context_dialog_popup_accordian">
        <div className="context_dialog_join_output">
            <h4 onClick={e => onclick()}>
                {/* <span className="dialog_context_number">
                    <span className="dialog_context_span_number">
                        <img src={userIcon}></img>
                    </span>
                </span> */}
                <span className="context_path">Available Entities</span>
                <span>
                    <div className="arrow_up_form closed_state_arrow">
                        <RightArrowWithCircle />
                    </div>
                </span>
            </h4>
        </div>
    </div>
)

const findMyParPath = (
    data: DialogueT[],
    id: string,
    path: string[],
    errorContext?: boolean,
    output_variable?: string
): string[] => {
    return data.reduce((accum, val) => {
        if (val.id === id) {
            if (val.parent === null) {
                return [val.title, ...accum]
            } else {
                return val.type == 'if_node' &&
                    val.responses.filter((v: any) => v.type == 'automation' && v.output_variable == output_variable)
                    ? findMyParPath(data, val.parent, [...accum], errorContext)
                    : findMyParPath(data, val.parent, [val.title, ...accum], errorContext)
            }
        }
        return accum
    }, path)
}

export const findPathViaRes = (
    data: DialogueT[],
    id: string,
    path: string[],
    errorContext?: boolean,
    output_variable?: string
) => {
    const filtered = data.reduce((accum: DialogueT[], val: DialogueT): DialogueT[] => {
        const data = val.type === 'if_node' ? val.responses.filter((e: any) => e.id === id) : []
        if (data.length > 0) {
            return [val]
        }
        return accum
    }, [])
    if (filtered.length > 0) {
        const ifnode = filtered[0]
        if (ifnode.parent === null) {
            return [ifnode.title, ...path]
        } else {
            return errorContext
                ? [...findMyParPath(data, ifnode.parent, [], errorContext, output_variable), ...path]
                : [...findMyParPath(data, ifnode.parent, []), ifnode.title, ...path]
        }
    }
    return path
}

export const EntityList = (props: { assistantName: string }) => {
    const [search, setSearch] = useState<string>('')

    const { assignVariable } = useContext(VariableContext)

    const data = useContext(DialogueContext)
    const { assistantName } = props

    return (
        <DialogueContext.Consumer>
            {dialogueContext => {
                return (
                    <div className="context_popup_content_without">
                        {/* <div className="context_dailog_popup_search">
                            <input type="search" value={search} onChange={e => setSearch(e.target.value)} />
                        </div> */}

                        {dialogueContext.entities
                            .filter((variable: EntityT) => variable.entity.toLowerCase().includes(search.toLowerCase()))
                            .map((entity: EntityT, index: number) => (
                                <div className="context_popup_buton_content" key={index}>
                                    <div
                                        className="context_popup_buton_content_span"
                                        onClick={() =>
                                            assignVariable({
                                                type: 'entity',
                                                name: entity.entity,
                                                dummypath: [assistantName, 'Entity', entity.entity],
                                                icon: userWhite,
                                                dataType: 'string'
                                            })
                                        }
                                    >
                                        <div className="button_auto">{entity.entity}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                )
            }}
        </DialogueContext.Consumer>
    )
}

const ContextList = ({ context, assistantName }: { context: any[]; assistantName: string }) => {
    const [search, setSearch] = useState<string>('')
    const { assignVariable } = useContext(VariableContext)

    const data = useContext(DialogueContext)

    let filteredArray = R.flatten(context).reduce((acc,val) =>{
        if(val.node.type != 'context_variable'){
         return [...acc,val]
        }else{
          return acc.findIndex((s:any)=>s.variable == val.variable) ==-1?[...acc,val]:acc
        }
     },[]);

    return (
        <div className="context_popup_content_without">
            {/* <div className="context_dailog_popup_search">
                <input type="search" value={search} onChange={e => setSearch(e.target.value)} />
            </div> */}
            {defaultSystemContext.map((context, index) => (
                <div className="context_popup_buton_content" key={index}>
                    <div
                        className="context_popup_buton_content_span"
                        onClick={() => {
                            if (context === 'SYS_Anything_Else') {
                                assignVariable({
                                    type: 'context',
                                    variable: context,
                                    path: [context],
                                    dummypath: [assistantName, context],
                                    icon: logoImg,
                                    dataType: 'boolean'
                                })
                            } else {
                                assignVariable({
                                    type: 'context',
                                    variable: context,
                                    path: [context],
                                    dummypath: [assistantName, context],
                                    icon: logoImg,
                                    dataType: 'string'
                                })
                            }
                        }}
                    >
                        <div className="button_auto">{context}</div>
                    </div>
                </div>
            ))}

            {filteredArray
                .filter((variable: any) => variable.variable.toLowerCase().includes(search.toLowerCase()))
                .map((variable: any, index: number) => (
                    <div className="context_popup_buton_content" key={index}>
                        <div
                            className="context_popup_buton_content_span"
                            onClick={() => {
                                assignVariable({
                                    type: 'context',
                                    variable: variable.variable,
                                    path: [variable.variable],
                                    dummypath: [
                                        assistantName,
                                        ...findPathViaRes(data.workspaceDescendents, variable.node.id, []),
                                        variable.node.type === 'ask_a_question'
                                            ? 'Ask a question'
                                            : variable.node.type == 'file_upload'
                                            ? 'Upload Attachment'
                                            : variable.node.type == 'context_variable'
                                            ? 'Custom Variables'
                                            : 'Verify user details',
                                        variable.variable
                                    ],
                                    icon: logoImg,
                                    dataType: 'string'
                                })
                            }}
                        >
                            <div className="button_auto">{variable.variable}</div>
                        </div>
                    </div>
                ))}
        </div>
    )
}

const FlowItem = ({ automation, onClick,flowName }: { automation: any; onClick: Function,flowName:string }) => (
    <div className="context_dialog_popup_accordian" onClick={e => onClick()}>
        <div className="context_dialog_join_output">
            <h4>
                <span className="dialog_context_number">
                    <span className="dialog_context_span_number">
                        <FlowIcon />
                    </span>
                </span>
                <span className="w_90">
                    {flowName!=''&&<span className="context_path">{flowName}</span>}
                    <span className="context_path">
                       {flowName!=''&& <NextIconCanvas />}
                        <span className='automationName'>{automation.name}</span>
                    </span>
                    <span className="context_path">
                        <NextIconCanvas />
                        <span className="context_path_content">Output</span>
                    </span>
                </span>
                <span className="dialog_open_icon">
                    <div className="arrow_up_form closed_state_arrow">
                        <RightArrowWithCircle />
                    </div>
                </span>
            </h4>
        </div>
    </div>
)

const RenderActionOutput = ({ properties, flowOutputClick }: { properties: any; flowOutputClick: Function }) => {
    return (
        <ul>
            {Object.keys(properties).map((key: string, index: number) =>
                properties[key] != 'collection' ? (
                    <li
                        className={
                            properties[key].properties
                                ? 'treeview_automatiom_li treeview_automatiom_li_nested'
                                : 'treeview_automatiom_li'
                        }
                        key={index}
                    >
                        {properties[key].properties ? (
                            <RenderActionRootOutput
                                name={properties[key].title}
                                value={properties[key]}
                                flowOutputClick={flowOutputClick}
                            />
                        ) : (
                            <label
                                className="treeview_automatiom_label"
                                onClick={() => flowOutputClick(properties[key])}
                            >
                                <div className="button_auto">{properties[key].title}</div>
                                <span className="letter_num">{getIcon(properties[key].type)}</span>
                            </label>
                        )}
                    </li>
                ) : null
            )}
        </ul>
    )
}

const RenderActionRootOutput = ({
    name,
    value,
    flowOutputClick
}: {
    name: string
    value: any
    flowOutputClick: Function
}) => (
    <div className="dialog_context_tree">
        <div className="dialog_tree_structure_body">
            <div className=" dia_content_treeview_automatiom">
                <div className="right_panel_ht_context">
                    <ul className="tree1 treeview_automatiom_ul_context">
                        <div className="treeview_automatiom_ul_hide">
                            <li className="treeview_automatiom_li_parent">
                                <label className="">
                                    <div className="dialog_context_tree_head">
                                        <h4>
                                            <span className="dialog_context_tree_head_left_svg">
                                                <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 43 16">
                                                    <circle cx="11" cy="8" r="4" />
                                                    <circle cx="22" cy="8" r="4" />
                                                    <circle cx="33" cy="8" r="4" />
                                                </svg>
                                            </span>
                                            {name}
                                            <span className="popup_tree_head_right_svg">
                                                <FlowCanvasContextIcons.DownFatArrow />
                                            </span>
                                        </h4>
                                    </div>
                                </label>
                                <RenderActionOutput properties={value.properties} flowOutputClick={flowOutputClick} />
                            </li>
                        </div>
                    </ul>
                    <div className="margin-bottom-75" />
                </div>
            </div>
        </div>
    </div>
)

const ActionOutput = ({
    output,
    selectOutput,
    errors
}: {
    output: any
    selectOutput: Function
    errors: { string: { code: string; message: string } }
}) => {
    const [search, setSearch] = useState<string>('')
    const { properties } = output
    const context: VariableContextT = React.useContext(VariableContext)

    return (
        <React.Fragment>
            <div className="context_dailog_popup_search">
                <input type="search" value={search} onChange={e => setSearch(e.target.value)} />
                <span>
                    <SearchIcon />
                </span>
            </div>
            <div className="context_popup_buton_content">
                {Object.keys(properties)
                    .filter(k => k.toLowerCase().indexOf(search.toLowerCase()) != -1)
                    .filter(key =>
                        context.forAutomationVariables == 'collection' ? properties[key].type == 'collection' : true
                    )
                    .map((key: string, index: number) => {
                        return properties[key].properties ? (
                            properties[key].type != 'collection' && (
                                <div className="context_popup_buton_content_span" key={index}>
                                    <RenderActionRootOutput
                                        name={key}
                                        value={properties[key]}
                                        flowOutputClick={selectOutput}
                                    />
                                </div>
                            )
                        ) : properties[key].type != 'collection' ? (
                            <div
                                className="context_popup_buton_content_span"
                                key={index}
                                onClick={event => {
                                    selectOutput(properties[key])
                                }}
                            >
                                <span className="content_capital">{getIcon(properties[key].type)}</span>
                                <div className="button_auto">{key}</div>
                            </div>
                        ) : (
                            context.forAutomationVariables == 'collection' && (
                                <div
                                    className={
                                        'form_req_step_two_global_form adptive_carousal_card_bg _select_a_variable'
                                    }
                                    style={{ marginTop: 0 }}
                                >
                                    <div className="collection_picker_in_input_page adptive_carousal_card">
                                        <ContextOutputTree
                                            output={{ name: properties[key].title, value: properties[key] }}
                                            showOnlyCollection={
                                                {
                                                    showOnlyCollection: true,
                                                    contextPopupValues: {
                                                        collectionPicker: { isCollectionPicker: true }
                                                    }
                                                } as any
                                            }
                                            onClick={(res: any) => {
                                                console.log(res, 'res')
                                                selectOutput(res)
                                            }}
                                            usingForInputs={false}
                                            forDialog
                                        />
                                    </div>
                                </div>
                            )
                        )
                    })}
                {Object.values(errors).map((e, index) => {
                    return (
                        <div
                            className="context_popup_buton_content_span"
                            key={index}
                            onClick={event => {
                                selectOutput(e)
                            }}
                        >
                            <span className="content_capital">{getIcon('boolean')}</span>
                            <div className="button_auto">{e.message}</div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

const RenderChild = ({
    flowList,
    selectedAction,
    selectAction
}: {
    flowList: any[]
    selectedAction: any
    selectAction: Function
}) => {
    const [expandIds, setExpandIds] = useState<string[]>(flowList.map(e => e.value.id))

    const { actionMixture } = useContext(DialogueContext)

    return (
        <ul>
            {flowList.map((flow: any, index: number) => (
                <li className="treeview_automatiom_li" key={index}>
                    <label
                        className="treeview_automatiom_label"
                        style={
                            flow.value.kind === 'ErrorHandlingCondition'
                                ? {
                                      pointerEvents: 'none'
                                  }
                                : {}
                        }
                    >
                        <div
                            className={
                                actionMixture[flow.value.meta_data.action_id]
                                    ? `button_auto ${
                                          selectedAction.id == flow.value.id ? 'input_selected_dialog_context' : ''
                                      }`
                                    : `button_auto no_click ${
                                          selectedAction.id == flow.value.id ? 'input_selected_dialog' : ''
                                      }`
                            }
                            onClick={() => {
                                if (flow.value.kind !== 'ErrorHandlingCondition') {
                                    selectAction(flow.value)
                                }
                            }}
                        >
                            {flow.children.length > 0 &&
                                <span className={`tree_down_arrow_child ${expandIds.includes(flow.value.id) ? 'arrow_opened' : ''}`}
                                    onClick={e => {

                                        expandIds.includes(flow.value.id) ? setExpandIds(ids => ids.filter(e => e != flow.value.id)) : setExpandIds(ids => ids.concat(flow.value.id))
                                        
                                    }}
                                >
                                    <RightArrowForAutomationErrors />
                                </span>
                            }

                            <span className="dialog_context_popup_img">
                                <img src={flow.value.icon} />
                            </span>
                            <span className="dialog_context_content">{flow.value.name}</span>

                            {/* {flow.children.length > 0 ? (
                                expandIds.includes(flow.value.id) ? (
                                    <span
                                        className="dialog_context_popup_svg onclick_downarrow"
                                        onClick={e => setExpandIds(ids => ids.filter(e => e != flow.value.id))}
                                    >
                                        <FlowCanvasContextIcons.NextIcon />
                                    </span>
                                ) : (
                                    <span
                                        className="dialog_context_popup_svg"
                                        onClick={e => setExpandIds(ids => ids.concat(flow.value.id))}
                                    >
                                        <FlowCanvasContextIcons.NextIcon />
                                    </span>
                                )
                            ) : null} */}
                        </div>
                    </label>
                    {expandIds.includes(flow.value.id) && flow.children && flow.children.length > 0 ? (
                        <RenderChild
                            flowList={flow.children}
                            selectedAction={selectedAction}
                            selectAction={selectAction}
                        />
                    ) : null}
                </li>
            ))}
        </ul>
    )
}

const FlowDetails = ({
    automation,
    selectedAction,
    selectAction,
    output,
    setOutput,
    selectOutput,
    setSelectedFlowNode,
    errors
}: {
    automation: any
    selectedAction: any
    selectAction: Function
    output: any
    setOutput: Function
    selectOutput: Function
    setSelectedFlowNode: Function
    errors: { string: { code: string; message: string } }
}) => {
    const [succesIsOpen, setSuccesIsOpen] = useState(false)
    const [showAction,setShowAction] = useState(true)
    useEffect(() => {
        if (output.properties) {
            setSuccesIsOpen(false)
        }
    }, [output.properties])
    return (
        <React.Fragment>
            <div className="popup_tree dailog_canvas__dropdown_tree">
                <div className="tree_structure_body">
                    <div className=" treeview_automatiom">
                        <div className="right_panel_ht">
                            <ul className="tree1 treeview_automatiom_ul">
                                <div className="dialog_tree_ml_15 ">
                                    <li className="treeview_automatiom_li_parent">
                                        <label className="notrigger_automation_label">
                                            <div className="canvas_popup_tree_head">
                                                <span className="notrigger_head">
                                                    <div className="button_auto button_auto_first">
                                                    <span className={`tree_down_arrow ${showAction?"arrow_opened":""}`} onClick={()=>{
                                                        setShowAction(!showAction)
                                                        }}>
                                                            <RightArrowForAutomationErrors />
                                                        </span>
                                                        <span className="dialog_context_popup_img">
                                                            <img src={automation.custom_json.value.icon} />
                                                        </span>
                                                        <span className="dialog_context_content">
                                                            Triggered by Chatbot
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                        </label>
                                        {showAction &&
                                        <RenderChild
                                            flowList={automation.custom_json.children[0].children}
                                            selectedAction={selectedAction}
                                            selectAction={selectAction}
                                        />
                                        }
                                    </li>
                                    <li>
                                        <label
                                            className="notrigger_automation_label"
                                            onClick={e => {
                                                setOutput({})
                                                setSuccesIsOpen(true)
                                            }}
                                        >
                                            <div className="canvas_popup_tree_head">
                                                <span className="notrigger_head">
                                                    <div className="button_auto button_auto_first button_auto_remove_before">
                                                        <span className="dialog_context_popup_img">
                                                            <img src={automation.custom_json.children[1].value.icon} />
                                                        </span>
                                                        <span className="dialog_context_content">Execution Status</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </label>
                                    </li>
                                </div>
                            </ul>
                            <div className="margin-bottom-75" />
                        </div>
                    </div>
                </div>
            </div>
            {succesIsOpen ? (
                <div className="context_popup_buton_content">
                    <div className="context_popup_buton_content_span" onClick={event => selectOutput('success')}>
                        <span className="content_capital">T/F</span>
                        <div className="button_auto">Success</div>
                    </div>
                </div>
            ) : null}
            {output.properties ? <ActionOutput output={output} selectOutput={selectOutput} errors={errors} /> : null}
        </React.Fragment>
    )
}

const DialogDetail = ({
    dialog,
    index,
    onclick,
    selectedFlow,
    setSelectedFlow,
    selectOutput,
    setSelectedFlowNode,
    output,
    setOutput,
    selectedAction,
    selectAction,
    assistantName,
    errors
}: {
    dialog: DialogueT
    index: number
    onclick: Function
    selectedFlow: string
    setSelectedFlow: Function
    selectOutput: Function
    setSelectedFlowNode: Function
    output: any
    setOutput: Function
    selectedAction: any
    selectAction: Function
    assistantName: string
    errors: { string: { code: string; message: string } }
}) => {
    const { dialogueContext, automationContext, dialogueNode ,fetchChild2, fetchChild} = useContext(DialogueContext)
    const { forAutomationVariables } = useContext(VariableContext)

    const [isContextVariableOpen, setIsContextVariableOpen] = useState<boolean>(dialogueNode === dialog.id)

    const fetchChilds = (dialog:DialogueT, outputChilds:any[], initialCall:boolean)=>{
       const fetchChildsResponses = fetchChild2 as Function

       const fetchedChilds = fetchChild(dialog)

        if(fetchedChilds.length>0){
            outputChilds = initialCall ? R.concat(outputChilds,dialog.responses as any) as any : outputChilds
            const addedChilds = R.concat(outputChilds,R.flatten(fetchChildsResponses(dialog).map((r:IfNodeT)=>(r.responses))))

            return fetchedChilds.map((r:IfNodeT)=>fetchChilds(r, addedChilds, false))
        }
        return outputChilds
    }
    const childResponses = fetchChild2? fetchChilds(dialog, [] , true) :[]
    const uniqAutomationChild = R.uniq(R.flatten(childResponses))
    return (
        <div className="context_popup_dialog_accordian_open node_output_first_child context_dialog_popup_accordian_connection_open">
            <div className="context_dialog_join_output">
                <h4 onClick={e => onclick()}>
                    <span className="dialog_context_number">
                        <span className="dialog_context_span_number">{index}</span>
                    </span>
                    <span className="context_path send_message_variable_content">{dialog.title}</span>
                    <span>
                        <div className="arrow_up_form">
                            <RightArrowWithCircle />
                        </div>
                    </span>
                </h4>
                <div className="context_dailog_popup_search">
                    <input type="search" value="" />
                    <span>
                        <SearchIcon />
                    </span>
                </div>

                {forAutomationVariables != 'collection' && (
                    <div className="context_variables_head_para">
                        <div
                            className="context_variables_head"
                            onClick={e => setIsContextVariableOpen(!isContextVariableOpen)}
                        >
                            Context Variables {dialogueContext[dialog.id].length + defaultSystemContext.length}
                            <span className="context_variables_head_svg">
                                <NextIconCanvas />
                            </span>
                        </div>
                        <p>Data collected from the user through the course of the dialog till this point</p>
                    </div>
                )}

                {isContextVariableOpen
                    ? dialogueContext[dialog.id] &&
                      forAutomationVariables != 'collection' && (
                          <ContextList context={dialogueContext[dialog.id]} assistantName={assistantName} />
                      )
                    : null}

                <div className="child_data_context">
                    {automationContext[dialog.uuid] &&
                        Object.keys(automationContext[dialog.uuid]).map((flowDialogID: string, index: number) => {
                            const flow = automationContext[dialog.uuid][flowDialogID]
                            // const childResponses = fetchChild2? fetchChilds(dialog, [] , true) :[]
                            const flowName = uniqAutomationChild.filter((r:any)=>r.id == flowDialogID)

                            return selectedFlow == flow.id + flowDialogID ? (
                                <div className="context_dialog_child_list" key={index}>
                                    <div className="context_popup_dialog_accordian_open context_popup_dialog_accordian_open_margin_zero">
                                        <FlowItem
                                            automation={flow}
                                            onClick={() => {
                                                setSelectedFlowNode('')
                                                setSelectedFlow('')
                                            }}
                                            flowName={flowName[0]?(flowName[0] as AutomationT).name:""}

                                        />
                                        <FlowDetails
                                            automation={flow}
                                            selectedAction={selectedAction}
                                            selectAction={selectAction}
                                            output={output}
                                            setOutput={setOutput}
                                            selectOutput={selectOutput}
                                            setSelectedFlowNode={setSelectedFlowNode}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <FlowItem
                                    key={index}
                                    automation={flow}
                                    flowName={flowName[0]?(flowName[0] as AutomationT).name:""}
                                    onClick={() => {
                                        setSelectedFlowNode(flowDialogID)
                                        setSelectedFlow(flow.id + flowDialogID)
                                    }}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

type VariableP = {
    displayStatusOfEntityListInVariable: 'SHOW' | 'HIDE'
    props: any
}

const Variable = ({ displayStatusOfEntityListInVariable, props }: VariableP) => {
    const assistantName = props && props.match.params.assistantname
    const [searchDialog, setSearchDialog] = useState<string>('')

    const { workspaceDescendents, actionMixture, dialogueNode } = useContext(DialogueContext)
    const [selectedDialog, setSelectedDialog] = useState<string | 'entity'>(dialogueNode)

    const [selectedFlow, setSelectedFlow] = useState<string>('')

    const [selectedFlowNode, setSelectedFlowNode] = useState<string>('')

    const [selectedAction, setSelectedAction] = useState<any>({})

    const [output, setOutput] = useState<any>({})

    const [errors, setErrors] = useState<any>({})

    const { assignVariable, forAutomationVariables } = useContext(VariableContext)

    const selectAction = (action: any) => {
        const result = actionMixture[action.meta_data.action_id]
        if (result) {
            setSelectedAction(action)
            setOutput(result.output)
            setErrors(result.errors)
        }
    }
    // console.log(workspaceDescendents,'workspaceDescendents')
    const selectOutput = (values: any) => {
        const value = forAutomationVariables == 'collection' ? values.value : values
        const flowDialogNode = workspaceDescendents.reduce((accum: any, val) => {
            const result = val.type === 'if_node' ? val.responses.filter(e => e.id == selectedFlowNode)[0] : null
            if (result) {
                return result
            }
            return accum
        }, null)

        if (flowDialogNode && typeof value === 'string') {
            const automationNode: AutomationT = flowDialogNode as AutomationT

            assignVariable({
                type: 'context',
                variable: 'Success',
                dummypath: [
                    assistantName,
                    ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                    automationNode.automation_name,
                    'success'
                ],
                path: [automationNode.output_variable, 'output', 'executionContext', 'output', 'success'],
                icon: logoImg,
                dataType: 'boolean'
            })
        } else if (flowDialogNode && Object.keys(selectedAction).length && selectedAction.id != undefined) {
            const automationNode: AutomationT = flowDialogNode as AutomationT
            let variableValue
            if (value.code) {
                variableValue = {
                    type: 'context',
                    variable: value.message,
                    dummypath: [
                        assistantName,
                        ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                        automationNode.automation_name
                    ].concat(value.message),
                    path: [automationNode.output_variable, 'output', 'errorContext', selectedAction.id, value.code],
                    icon: logoImg,
                    dataType: 'boolean'
                } as any
            } else {
                const actionOutputPath = value.$id.split('/').slice(2)

                variableValue = {
                    type: 'context',
                    variable: actionOutputPath.slice(actionOutputPath.length - 1).pop(),
                    // dummypath: [rootDialogNode.title, automationNode.automation_name, selectedAction.name].concat(
                    //     actionOutputPath
                    // ),
                    dummypath: [
                        assistantName,
                        ...findPathViaRes(workspaceDescendents, automationNode.id, []),
                        automationNode.automation_name
                    ].concat(actionOutputPath),
                    path: [
                        automationNode.output_variable,
                        'output',
                        'executionContext',
                        selectedAction.id,
                        'output'
                    ].concat(actionOutputPath),
                    icon: logoImg,
                    dataType: value.type
                } as any
            }

            variableValue =
                forAutomationVariables == 'collection'
                    ? {
                          ...variableValue,
                          collection: {
                              values: values,
                              app: {
                                  ...selectedAction,
                                  appName: flowDialogNode.automation_name,
                                  appId: flowDialogNode.id,
                                  action: selectedAction.name
                              }
                          }
                      }
                    : variableValue

            assignVariable(variableValue)
        }
    }

    const isDetailPage = window.location.pathname.includes('/detail')

    function checkDialogInsideFolder(dialog:DialogueT){
        return workspaceDescendents.filter((r)=>r.parent==dialog.uuid)
    }

    return (
        <div className="context_dilaog_popup_width">
            <div className="context_dialog_popup_head">
                <h3>Variable Picker</h3>
            </div>
            {displayStatusOfEntityListInVariable == 'SHOW' &&
                (selectedDialog != 'entity' ? (
                    <React.Fragment>
                        <div className="automation_content_mt" />

                        <EntityHeaderInPicker onclick={() => setSelectedDialog('entity')} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="automation_content_mt" />

                        <div className="context_popup_dialog_accordian_open node_output_first_child">
                            <h4 onClick={e => setSelectedDialog('')}>
                                {/* <span className="dialog_context_number">
                                    <span className="dialog_context_span_number">
                                        <img src={userIcon}></img>
                                    </span>
                                </span> */}
                                <span className="context_path"> Available Entities</span>
                                <span>
                                    <div className="arrow_up_form">
                                        <RightArrowWithCircle />
                                    </div>
                                </span>
                            </h4>

                            <div className="context_variables_head_para">
                                <EntityList assistantName={assistantName} />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            {sortBySibling(workspaceDescendents.filter(dialog => dialog.parent == null)).reduce((acc,val)=>{
                if(val.type=="folder"){
                    const ds = checkDialogInsideFolder(val)
                    return [...acc,...ds]
                }
                return [...acc,val]
            },[] as DialogueT[]).filter(d => d.type === 'if_node' || (!isDetailPage && d.type === 'faq_node'))
                .map((dialog, index) =>
                    dialog.id == selectedDialog ? (
                        <React.Fragment key={index}>
                            {displayStatusOfEntityListInVariable == 'HIDE' && index == 0 ? (
                                <div className="automation_content_mt" />
                            ) : null}
                            <DialogDetail
                                dialog={dialog}
                                index={index}
                                onclick={() => setSelectedDialog('')}
                                selectedFlow={selectedFlow}
                                setSelectedFlow={setSelectedFlow}
                                setSelectedFlowNode={setSelectedFlowNode}
                                selectOutput={selectOutput}
                                output={output}
                                setOutput={setOutput}
                                errors={errors}
                                selectedAction={selectedAction}
                                selectAction={selectAction}
                                assistantName={assistantName}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={index}>
                            {displayStatusOfEntityListInVariable == 'HIDE' && index == 0 ? (
                                <div className="automation_content_mt" />
                            ) : null}
                            <DialogItem dialog={dialog} index={index} onclick={() => setSelectedDialog(dialog.id)} />
                        </React.Fragment>
                    )
                )}
        </div>
    )
}

export default Variable
