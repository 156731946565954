/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  TabContainer,
  TabHeader,
  TabRow,
  TabLeft,
  TabRight,
} from '@/Flows/analytics/logs';
import { Link, Outlet, useParams } from 'react-router-dom';

const channels = [
  {
    name: 'Slack',
    to: '/slack',
    id: 'slack',
  },
  {
    name: 'MS Teams',
    to: '/teams',
    id: 'teams',
  },
  {
    name: 'Chat widget',
    to: '/widget',
    id: 'widget',
  },
];

export function ChatChannelsHome(props: any) {
  const { match } = props;
  const { assistantname, workspacename } = match.params;

  const [active, setActive] = useState('slack');

  const params = useParams();

  useEffect(() => {
    if (props.location.pathname.includes('chat-channels/slack')) {
      setActive('slack');
    } else if (props.location.pathname.includes('chat-channels/teams')) {
      setActive('teams');
    } else if (props.location.pathname.includes('chat-channels/widget')) {
      setActive('widget');
    }
  });

  return (
    <>
      <TabContainer className="sub_menu_section">
        <TabRow className="sub_menu chat_channel">
          <TabLeft>
            {channels.map((cha) => {
              return (
                <TabHeader active={cha.id == active}>
                  <Link
                    className={cha.id == active ? 'active' : ''}
                    to={`/workspace/${workspacename}/assistant/chat-channels${cha.to}`}
                    onClick={() => {
                      setActive(cha.id);
                    }}
                  >
                    {cha.name}
                  </Link>
                </TabHeader>
              );
            })}
          </TabLeft>
          <TabRight>
            <div id="assistant-sub-header"></div>
          </TabRight>
        </TabRow>
      </TabContainer>
      <Outlet />
    </>
  );
}
