/*eslint-disable*/
import React from 'react'
import styled from 'styled-components'

import { AddIcon } from '@/common/Icons/Icons'

import { SelectApplications, Wrapper } from './Applications'
import { MarketPlaceCardNew } from './common'
import { AutomationGroupTypes, AutomationGroups, AutomationGroups2, MarketPlaceAutomation } from './types'
import { padWithZero } from './utils'
import produce from 'immer'

const R = require('ramda')

export const CardWrapper = styled.section`
    width: 100%;
    float: left;
    margin-bottom: 30px;
`
export const MaxHeight = styled.div`
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
`
export const ApplicationHeader = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 16px;
    color: #617995;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    background: #e8ebf0;
    border: 0.5px solid #e8ebf0;
    border-radius: 6px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .mar_apps {
        width: auto;
        float: left;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .apps_wrap {
        margin: 2px 0px;
        display: flex;
        align-items: center;
    }
    .apps_autoamations {
        margin-left: 3px;
        font-size: 14px;
    }
`

export const Avatar = styled.span`
    width: 30px;
    height: 30px;
    margin-right: 5px;
    float: left;
`
export const AppCount = styled.span`
    font-size: 12px;
    color: #ff7900;
    font-family: 'Gordita-Medium';
    margin-left: 5px;
`

export const SvgWidth = styled.svg`
    width: 15px;
    height: 15px;
    fill: #617995;
    border-radius: 50%;
    border: 0.5px solid #617995;
    padding: 3px;
    margin-right: 5px;
    margin-left: 5px;
`

export const AvatarImg = styled.img`
    width: 100%;
    height: 100%;
`

const Beta = styled.span`
    background: #ff7900;
    color: #fff;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    border-radius: 12px;
    text-align: center;
    top: 15px;
    padding: 1px 10px;
    border: 0.5px solid #ff7900;
    cursor: default;
`
const CardICons = styled.div`
    width: 100%;
    float: left;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
`

const HeaderWithImage = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-bottom: 0.5px solid #ccc;
    font-size: 18px;
    padding: 0px 12px 10px 12px;
    flex-wrap: wrap;
    align-content: center;

    @media (max-width: 1450px) {
        font-size: 16px;
    }
`
const Description = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 5px;
    padding-left: 2px;
`
const Header = styled.div`
    width: 83%;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
`
const ImageSpan = styled.div`
    width: 30px;
    height: 30px;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
`
const HeaderImage = styled.img`
    width: 100%;
    height: 100%;
`

const CardBottom = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 12px;
`
const DateTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 12px;
`

const CardTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 12px;
`
const CardDate = styled.span`
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 12px;
`
const Downloads = styled.span`
    font-family: 'Gordita-Regular';
    color: #000;
    font-size: 12px;
    display: flex;
    align-items: center;
`

const Count = styled.span`
    font-family: 'Gordita-Medium';
    color: #ff7900;
    font-size: 12px;
    margin-left: 2px;
`

const Card = styled.div`
    width: 23%;
    flex: 0 0 23%;
    border-radius: 18px;
    height: 140px;
    float: left;
    margin-right: 0px;
    margin-bottom: 20px;
    border: 0.5px solid #fff;
    position: relative;
    background: #fff;

    :hover {
        background: #0a89e8;
        color: #fff;
        cursor: pointer;
        border: 0.5px solid #0a89e8;
    }

    &:hover ${HeaderWithImage} {
        color: #fff;
    }
    &:hover ${CardBottom} {
        color: #fff;
    }
    &:hover ${DateTime} {
        color: #fff;
    }
    &:hover ${CardDate} {
        color: #fff;
    }
    &:hover ${CardTime} {
        color: #fff;
    }
    &:hover ${Downloads} {
        color: #fff;
    }
    &:hover ${Count} {
        color: #fff;
    }
    &:hover ${Beta} {
        background: #fff;
        color: #ff7900;
        border: 0.5px solid #ff7900;
    }
    @media (max-width: 1300px) {
        width: 260px;
        flex: 0 0 260px;
    }
`
type CardsApplicationsP = {
    listItems: MarketPlaceAutomation[][]
    addToWorkSpace?: any
    clearSelection?: () => void
    redirectToMarketplaceDetail: (id: string) => void
    otherWorkFlows: MarketPlaceAutomation[][]
    selectedApps: any
    searchText: string
}

const groupAutomationsChange = (marketAutomations: MarketPlaceAutomation[][]): AutomationGroups2[] => {
    return marketAutomations.map(automations => {
        const first = automations[0]
        return {
            apps: first.apps,
            automations: automations
        }
    })
}

const sortByCount = R.sort(function(a: any, b: any) {
    return b.count - a.count
})

const groupAutomations = (marketAutomations: MarketPlaceAutomation[][]): AutomationGroups[] => {
    return marketAutomations.map(automations => {
        const first = automations[0]
        return {
            group: AutomationGroupTypes.MultiApp,
            apps: first.apps,
            automations: sortByCount(automations)
        } as AutomationGroups
    })
}

const singleApplication = {
    group: AutomationGroupTypes.SingleAppAction,
    apps: [],
    automations: []
}

export const groupAutomationsIntoSingle = (marketAutomations: MarketPlaceAutomation[][], selectedApps: any): any[] => {
    if(selectedApps.length === 1){
    return marketAutomations.map(automations => {
        const filt = automations.map(auto => ({ ...auto, apps: R.uniq(auto.apps) }))
        const head = R.head(filt)

        // if (selectedApps.length === 1) {

        const {
            apps: [onlyApp]
        } = head

        if (onlyApp.actionType === 'trigger'|| onlyApp.actionType === 'event_trigger' || onlyApp.actionType === 'webhook_trigger') {
            return {
                group: AutomationGroupTypes.SingleAppTrigger,
                apps: head.apps,
                automations: sortByCount(automations)
            }
        } else if (onlyApp.actionType === 'action') {
            return {
                group: AutomationGroupTypes.SingleAppAction,
                apps: head.apps,
                automations: sortByCount(automations)
            }
        } else {
            return { group: AutomationGroupTypes.InValid, apps: [], automations: [] }
        }
    })
    } else {
        // } else {
    //     return [
    //         {
    //             group: AutomationGroupTypes.MultiApp,
    //             apps: selectedApps,
    //             automations: sortByCount(marketAutomations)
    //         }
    //     ]
    // }
        const seperateApps = marketAutomations.reduce((accum,val)=>{
            const multiAppAutomation = val.filter((v)=> v.apps.length>1 )
            const singleAppAutomation = val.filter((v)=> v.apps.length === 1 )

                const nextState = produce(accum,(draft)=>{
                    
                    draft[0] = {...draft[0],automations:sortByCount(R.concat(draft[0].automations,multiAppAutomation))}
                    draft[1] = {
                        group: AutomationGroupTypes.SingleAppAction,
                        apps: selectedApps.slice(0,1),
                        automations: sortByCount(R.concat(draft[1].automations,singleAppAutomation.filter((auto)=>auto.apps.findIndex((a)=>a.id==selectedApps[0].id)!=-1))) as any
                    }
                    if(selectedApps.length>1){
                        draft[2] = {
                            group: AutomationGroupTypes.SingleAppAction,
                            apps: selectedApps.slice(1,2),
                            automations: sortByCount(R.concat(draft[2].automations,singleAppAutomation.filter((auto)=>auto.apps.findIndex((a)=>a.id==selectedApps[1].id)!=-1))) as any
                        }
                    }
                    if(selectedApps.length>2){
                        draft[3] = {
                            group: AutomationGroupTypes.SingleAppAction,
                            apps: selectedApps.slice(2,3),
                            automations: sortByCount(R.concat(draft[3].automations,singleAppAutomation.filter((auto)=>auto.apps.findIndex((a)=>a.id==selectedApps[1].id)!=-1))) as any
                        }                    
                    }
                })

                return nextState
           
        }, selectedApps.length === 2 ? [{
            group: AutomationGroupTypes.MultiApp,
            apps: selectedApps,
            automations: []
        }, singleApplication,singleApplication] : [{
            group: AutomationGroupTypes.MultiApp,
            apps: selectedApps,
            automations: []
        }, singleApplication,singleApplication,singleApplication])

        return seperateApps
    }
}

type GroupHeaderP = {
    automationGroup: AutomationGroups2
}
function joinChildren(
    children: any[],
    render: (item: any, index: any) => (props: any) => React.ReactNode,
    renderSeparator: (key: any) => (props: any) => React.ReactNode
) {
    const data = children.reduce((result: React.ReactNode[], child, index) => {
        if (index < children.length - 1) {
            return result.concat([render(child, index), renderSeparator(index + '-separator')])
        }

        return result.concat(render(child, index))
    }, []) as React.SFC[]
    return (
        <React.Fragment>
            {data.map((C, i) => (
                <C key={i} />
            ))}
        </React.Fragment>
    )
}
const renderSingleHead = (item: any, index: any) => (_: any) => (
    <React.Fragment key={index}>
        <span className="apps_wrap">
            <Avatar>
                <AvatarImg src={item.image} />
            </Avatar>
            <span>{item.name}</span>
        </span>
        {/* Two apps integrations */}
        {/* <Avatar>
            <AvatarImg src={item.image} />
        </Avatar>
        <span className="mr-1">+</span>
        <Avatar>
            <AvatarImg src={item.image} />
        </Avatar>
        {item.name} */}
        {/* Two apps integrations */}
    </React.Fragment>
)
const renderAddIcon = (key: any) => (_: any) => (
    <SvgWidth key={key}>
        <AddIcon />
    </SvgWidth>
)

const GroupHeader = (props: GroupHeaderP) => {
    const { automationGroup } = props
    const info = 'Automations'
    const uniqApps = R.uniq(automationGroup.apps.map(x => ({ image: x.image, name: x.name })))
    const automations = automationGroup.automations

    return (
        <ApplicationHeader>
            <span className="mar_apps">
                {joinChildren(uniqApps, renderSingleHead, renderAddIcon)}{' '}
                <span className="apps_autoamations"> - {info} </span>
                <AppCount>({padWithZero(automations.length)})</AppCount>
            </span>
        </ApplicationHeader>
    )
}

export const CardsApplications = (props: CardsApplicationsP) => {
    const automationGroups =
        props.listItems.length > 0 ? groupAutomationsIntoSingle(props.listItems, props.selectedApps) : []
    // const otherWorkFlows = R.uniq(groupAutomations(props.otherWorkFlows.filter(x => x.length > 0)))

    const redirectToMarketplaceDetail = (id: string) => props.redirectToMarketplaceDetail(id)

    return (
        <Wrapper paddingWrapper="0px 20px 0px 20px" heightScroll="calc(100vh - 200px)">
            {automationGroups.length === 0 && (
                <p className="no_match_apps">
                    {' '}
                    {automationGroups.length === 0 ? 'No automations to match selection' : null}
                </p>
            )}
            {automationGroups.map((group: AutomationGroups2, index: number) => (
                <>
                    <CardWrapper key={index}>
                        {/* {group.automations.filter(a =>
                            a.description.toLowerCase().includes(props.searchText.toLowerCase())
                        ).length > 0 && <GroupHeader automationGroup={group} />} */}
                        <SelectApplications marginWrapper="0px" paddingWrapper="0px">
                            <MaxHeight>
                                {group.automations
                                    .filter(a => a.description.toLowerCase().includes(props.searchText.toLowerCase()))
                                    .map(item => (
                                        <MarketPlaceCardNew
                                            // image={AutomationIconMap[item.type]}
                                            description={item.description}
                                            date={item.modified_date}
                                            count={item.count}
                                            automationName={item.name}
                                            key={item.name + item.modified_date.toString()}
                                            onClick={() => redirectToMarketplaceDetail(item.id)}
                                        />
                                    ))}
                            </MaxHeight>
                        </SelectApplications>
                    </CardWrapper>
                </>
            ))}
            {/* {otherWorkFlows.length > 0 && (
                <section className="other_popular_apps">
                    <HeaderContent>Other Popular Workflows</HeaderContent>
                    {otherWorkFlows.map((group: AutomationGroups2, index: number) => (
                        <CardWrapper key={index}>
                            <GroupHeader automationGroup={group} />
                            <SelectApplications marginWrapper="0px" paddingWrapper="0px">
                                <MaxHeight>
                                    {group.automations
                                        .filter(a =>
                                            a.description.toLowerCase().includes(props.searchText.toLowerCase())
                                        )
                                        .map(item => (
                                            <MarketPlaceCardNew
                                                // image={AutomationIconMap[item.type]}
                                                description={item.description}
                                                date={item.modified_date}
                                                count={item.count}
                                                automationName={item.name}
                                                key={item.name + item.modified_date.toString()}
                                                onClick={() => redirectToMarketplaceDetail(item.id)}
                                            />
                                        ))}
                                </MaxHeight>
                            </SelectApplications>
                        </CardWrapper>
                    ))}
                </section>
            )} */}
        </Wrapper>
    )
}

const HeaderContent = styled.h5`
    width: 100%;
    float: left;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    color: #000;
    margin-bottom: 20px;
`
