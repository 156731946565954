import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import {
    AddIcon,
    CheckColorIcon,
    CloseColorIcon,
    DeleteIcon,
    EditIcon,
    InfoIcon,
    QaIconWithCircle
} from '@/common/Icons/Icons'
//ICONS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    EmptyDiv,
    Header,
    HeaderLeft,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'
import { Input, InputContainer, InputContent, InputWrapper } from '@/common/styled/Workflow.Analytics.Dumb'

import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import { VariableValueT } from '../../Dialogue/kind'
import { DraftJST, converterToEditor, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
import { createResponseDialogue, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext } from '../Context'
import { ErrorState, ErrorT, VARIABLE_RESPONSE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'

const InputGrid = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 165px 165px 1fr;
    grid-gap: 10px;

    ${InputContainer} {
        :last-child {
            margin-bottom: 0px;
        }
    }

    .ask_ques_editor_input {
        margin-bottom: 0px;
    }
    .ask_ques_editor_input .target.customTip {
        pointer-events: initial;
        cursor: pointer;
        padding-left: 6px;
        position: relative;
    }
    .ask_ques_editor_input .target.customTip svg {
        width: 9px;
        height: 9px;
        position: relative;
        top: 0px;
        right: 0px;
    }
    .right_pop_icons {
        float: right;
        width: auto;
        display: flex;
    }
    .get_users_icon {
        width: auto;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
    }
    .right_pop_icons span svg {
        fill: #999;
        cursor: pointer;
        width: 18px;
        height: 18px;
        font-size: 8px;
    }
    .right_pop_icons span {
        float: left;
        margin: 0px;
        width: 18px;
        height: 18px;
        background: transparent;
        color: #999;
        // border: 1px solid #999;
        // border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        :last-child {
            margin-left: 6px;
        }
        :hover {
            // background: #ffe5d3;
            // color: #333;
            cursor: pointer;
            // border: 1px solid #ffe5d3;

            svg {
                color: #333;
                fill: #333;
                width: 18px;
                height: 18px;
            }
        }
    }

    .icons_right_close {
        width: auto;
        float: right;
        justify-content: space-between;
        height: 48px;
        display: none;
        align-items: center;
    }
    .icons_right_close span svg {
        width: 8px;
        height: 8px;
    }
    .icons_right_close span {
        float: left;
        margin: 0px;
        width: 18px;
        height: 18px;
        background: #fff;
        color: #999;
        border: 1px solid #999;

        :hover {
            background: #ddfeef;
            color: #333;
            cursor: pointer;
            border: 1px solid #ddfeef;

            svg {
                color: #333;
                fill: #333;
                width: 8px;
                height: 8px;
            }
        }
    }
    :hover {
        .icons_right_close {
            display: flex;
            position: relative;
            right: 6px;
        }
    }
`
const VaraibleBgCard = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    background: #f4f4f4;

    .plus_add_more {
        margin-top: -8px;
    }

    ${InputGrid} {
        margin-bottom: 20px;
        :last-child {
            margin-bottom: 0px !important;
        }
    }
`
const CustomVaraibleTips = () => (
    <>
        <p>
            Use{' '}
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/create-custom-variables/"
            >
                Custom variable
            </a>{' '}
            if you want the bot to capture and save custom input from the user, as custom variable.
        </p>
        <p>
            <b>For Example:</b> If you want the bot to capture "login type" and save as 'computer' from the user input
            or conversation. You can add <b>"Login_Type"</b> as variable name and value as <b>"Computer"</b>.
        </p>
        <p>
            <b>Note:</b> You can add custom variable value as simple text or combination of existing context variables.
        </p>
    </>
)
const VariableResponseNode = () => {
    const context = useContext(DialogueContext)
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName, queryClient, parentIfnode } = context

    const { data } = PageState as VARIABLE_RESPONSE

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [loading, setLoading] = useState<boolean>(false)

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    const [mode, setMode] = useState<{
        mode: string
        currentEditIndex: null | number
        currentVariable: { key: string; value: DraftJST } | null
    }>({
        mode: 'view',
        currentEditIndex: null,
        currentVariable: null
    })

    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    const [variables, setVariables] = useState<
        {
            key: string
            value: DraftJST
        }[]
    >([])

    const [scrollableDivRef, scrollTo] = useScrollHook()

    useEffect(() => {
        if (data.id != '') {
            const reducedData = data.variables.map(obj => ({
                key: obj.name,
                value: converterToEditor(obj.expression, context, context.dialogueNode)
            }))

            setMode({ mode: 'view', currentEditIndex: null, currentVariable: null })

            setVariables(reducedData)
        } else {
            setMode({
                mode: 'edit',
                currentEditIndex: 0,
                currentVariable: { key: '', value: { blocks: [], entityMap: {} } }
            })
            setVariables([{ key: '', value: { blocks: [], entityMap: {} } }])
        }
    }, [])

    const handleSave = async () => {
        try {
            //show Loading
            setLoading(true)
            const opVars = variables.map(val => {
                return { name: val.key, expression: converterToServer(val.value) }
            })

            if (data.id == '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        variables: opVars
                    } as VariableValueT,
                    dialogueName
                )
                await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
                await queryClient.invalidateQueries(['assistants'])
                await queryClient.invalidateQueries(['dialogs', assistantID])

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::CREATE_CUSTOM_VARIABLE", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Context Variable Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        variables: opVars
                    } as VariableValueT,
                    dialogueName
                )

                await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid]) 
                await queryClient.invalidateQueries(['assistants'])
                await queryClient.invalidateQueries(['dialogs', assistantID])

            }

            fetchCallback(() => setLoading(false))
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoading(false)
        }
    }

    return (
        <Wrapper>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Custom variable
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<CustomVaraibleTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/custom-variables" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'CREATE_CUSTOM_VARIABLES'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Create and save custom input as variable.</Paragraph>
                    <div className="custom_varaible">
                        <PopupScrollHeight ref={scrollableDivRef}>
                            <ErrorBoundary
                                error={error}
                                render={(err: any, info: any) => {
                                    return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                                }}
                            >
                                {loading ? (
                                    <div className="popup_loader">
                                        <Loader.PopupLoader show={loading} />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <EmptyDiv>
                                            <div className="">
                                                <VaraibleBgCard>
                                                    {variables.map((option, index) => {
                                                        return (
                                                            <InputGrid key={index}>
                                                                <div className="ask_ques_editor_input">
                                                                    <InputWrapper>
                                                                        <InputContainer>
                                                                            <Input
                                                                                type="text"
                                                                                // className="form-control"
                                                                                onKeyDown={(e: any) => {
                                                                                    setMetacode(
                                                                                        e.metaKey || e.ctrlKey
                                                                                            ? true
                                                                                            : false
                                                                                    )
                                                                                    setKeycode(e.keyCode)
                                                                                }}
                                                                                className={
                                                                                    mode.mode == 'view' ||
                                                                                    mode.currentEditIndex != index
                                                                                        ? 'disable_edit'
                                                                                        : ''
                                                                                }
                                                                                value={
                                                                                    mode.mode == 'edit' &&
                                                                                    mode.currentEditIndex == index &&
                                                                                    mode.currentVariable
                                                                                        ? mode.currentVariable.key
                                                                                        : option.key
                                                                                }
                                                                                onChange={(event: any) => {
                                                                                    keycode !== 32 &&
                                                                                        metacode === false &&
                                                                                        mode.currentVariable &&
                                                                                        setMode({
                                                                                            ...mode,
                                                                                            currentVariable: {
                                                                                                ...mode.currentVariable,
                                                                                                key: event.target.value.replace(
                                                                                                    /[^\w\s]/gi,
                                                                                                    ''
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                }}
                                                                            />
                                                                            {/* <span>{countSet(index + 1)}</span> */}
                                                                            <InputContent>
                                                                                Variable name{' '}
                                                                                {/* <Tooltip
                                                                                    className="target customTip"
                                                                                    zIndex={10000}
                                                                                    arrowSize={8}
                                                                                    tagName="span"
                                                                                    content="Provide the name for this context varible"
                                                                                    distance={5}
                                                                                >
                                                                                    <InfoIcon />
                                                                                </Tooltip> */}
                                                                            </InputContent>
                                                                        </InputContainer>
                                                                    </InputWrapper>
                                                                </div>
                                                                <div className="ask_ques_editor_input">
                                                                    <div className="">
                                                                        <InputWrapper>
                                                                            <DraftInputWrapper>
                                                                                <Editor
                                                                                    focusContent={'Value'}
                                                                                    hideToolbar
                                                                                    focusTooltip
                                                                                    focusTooltipContent={
                                                                                        'Provide a value for this variable (simple text or from existing custom variables)'
                                                                                    }
                                                                                    className={
                                                                                        mode.mode == 'view' ||
                                                                                        mode.currentEditIndex != index
                                                                                            ? 'disable_edit'
                                                                                            : ''
                                                                                    }
                                                                                    updated={isUpdated}
                                                                                    DraftMap={
                                                                                        mode.mode == 'edit' &&
                                                                                        mode.currentEditIndex ==
                                                                                            index &&
                                                                                        mode.currentVariable
                                                                                            ? mode.currentVariable.value
                                                                                            : option.value
                                                                                    }
                                                                                    setDraftMap={(state: any) => {
                                                                                        mode.currentVariable &&
                                                                                            setMode({
                                                                                                ...mode,
                                                                                                currentVariable: {
                                                                                                    ...mode.currentVariable,
                                                                                                    value: state
                                                                                                }
                                                                                            })
                                                                                    }}
                                                                                    focus={
                                                                                        convertAsaSingleString(
                                                                                            (mode.mode == 'edit' &&
                                                                                            mode.currentEditIndex ==
                                                                                                index &&
                                                                                            mode.currentVariable
                                                                                                ? mode.currentVariable
                                                                                                      .value
                                                                                                : option.value
                                                                                            )
                                                                                                .blocks as RawDraftContentBlock[]
                                                                                        ).replace(/\s/g, '').length
                                                                                            ? 'focus_input'
                                                                                            : ''
                                                                                    }
                                                                                />
                                                                            </DraftInputWrapper>
                                                                        </InputWrapper>
                                                                    </div>
                                                                </div>
                                                                {variables.length > 1 || mode.mode == 'edit' ? (
                                                                    mode.currentEditIndex == index ? (
                                                                        <div className="get_users_icon">
                                                                            <div className="right_pop_icons color_icons">
                                                                                <span
                                                                                    className={
                                                                                        mode.currentVariable &&
                                                                                        mode.currentVariable.value
                                                                                            .blocks.length > 0 &&
                                                                                        mode.currentVariable.value
                                                                                            .blocks[0].text.length >
                                                                                            0 &&
                                                                                        mode.currentVariable.key
                                                                                            .length > 0 &&
                                                                                        !/^\d+$/.test(
                                                                                            mode.currentVariable.key
                                                                                        )
                                                                                            ? 'editor_btn'
                                                                                            : 'editor_btn disable_edit'
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setVariables(
                                                                                            variables.map((v, i) =>
                                                                                                i == index &&
                                                                                                mode.currentVariable !=
                                                                                                    null
                                                                                                    ? mode.currentVariable
                                                                                                    : v
                                                                                            )
                                                                                        )
                                                                                        setMode({
                                                                                            mode: 'view',
                                                                                            currentEditIndex: null,
                                                                                            currentVariable: null
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <CheckColorIcon />
                                                                                </span>

                                                                                <span
                                                                                    className="editor_btn"
                                                                                    onClick={() => {
                                                                                        setMode({
                                                                                            mode: 'view',
                                                                                            currentEditIndex: null,
                                                                                            currentVariable: null
                                                                                        })

                                                                                        setIsUpdated(false)
                                                                                    }}
                                                                                >
                                                                                    <CloseColorIcon />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="icons_right_close">
                                                                            <span
                                                                                onClick={() => {
                                                                                    setMode({
                                                                                        mode: 'edit',
                                                                                        currentEditIndex: index,
                                                                                        currentVariable: option
                                                                                    })
                                                                                    setIsUpdated(true)
                                                                                }}
                                                                                className={
                                                                                    mode.mode == 'view'
                                                                                        ? ''
                                                                                        : 'disable_edit'
                                                                                }
                                                                            >
                                                                                <EditIcon />
                                                                            </span>
                                                                            <span
                                                                                className={
                                                                                    mode.mode == 'view'
                                                                                        ? ''
                                                                                        : 'disable_edit'
                                                                                }
                                                                                onClick={() => {
                                                                                        setVariables(
                                                                                            variables.filter(
                                                                                                (v, i) => i != index
                                                                                            )
                                                                                        )
                                                                                        setIsUpdated(true)
                                                                                        setTimeout(()=>{
                                                                                            setIsUpdated(false)
                                                                                        })
                                                                                    }
                                                                                }
                                                                            >
                                                                                <DeleteIcon />
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <div className="icons_right_close">
                                                                        <span
                                                                            onClick={() => {
                                                                                setMode({
                                                                                    mode: 'edit',
                                                                                    currentEditIndex: index,
                                                                                    currentVariable: option
                                                                                })
                                                                                setIsUpdated(true)
                                                                            }}
                                                                            className={
                                                                                mode.mode == 'view'
                                                                                    ? ''
                                                                                    : 'disable_edit'
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </InputGrid>
                                                        )
                                                    })}
                                                    {mode.mode == 'view' &&
                                                    variables.filter(
                                                        data => data.key == '' || data.value.blocks.length == 0
                                                    ).length == 0 &&
                                                    variables.length < 10 ? (
                                                        <div className="plus_addmore plus_addmore_variable">
                                                            <div
                                                                className="plus_add_more"
                                                                onClick={() => {
                                                                    setMode({
                                                                        mode: 'edit',
                                                                        currentEditIndex: variables.length,
                                                                        currentVariable: {
                                                                            key: '',
                                                                            value: { blocks: [], entityMap: {} }
                                                                        }
                                                                    })

                                                                    setVariables([
                                                                        ...variables,
                                                                        {
                                                                            key: '',
                                                                            value: { blocks: [], entityMap: {} }
                                                                        }
                                                                    ])
                                                                    scrollTo('bottom')
                                                                }}
                                                            >
                                                                <SmallButton primary>
                                                                    <span>
                                                                        <AddIcon />
                                                                    </span>
                                                                    <label> Add More</label>
                                                                </SmallButton>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </VaraibleBgCard>
                                            </div>
                                        </EmptyDiv>
                                        <Button
                                            className="footer_button_fixed"
                                            disable={
                                                variables.filter(
                                                    data =>
                                                        data.key.length != 0 &&
                                                        data.value.blocks.length > 0 &&
                                                        data.value.blocks[0].text.length != 0
                                                ).length != variables.length || mode.mode == 'edit'
                                            }
                                            onCancel={() => setPageState(VIEW)}
                                            onSave={handleSave}
                                        />
                                    </React.Fragment>
                                )}
                            </ErrorBoundary>
                        </PopupScrollHeight>{' '}
                    </div>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export default VariableResponseNode
