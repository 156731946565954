import { agentHandoffIntegraionList, getJson, postJson, watsonLogsApiURL } from '@/common/utils/api-utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AssistantT } from '../Construction/kind';
import { DialogApiURLWithoutBotID, getAssistants, updateAssistant } from '../Mechanisms/action';
import { useEffect } from 'react';

const timezone = Intl.DateTimeFormat('en-US', { timeZone: 'UTC' }).resolvedOptions().timeZone


export const useUpdateCostDetails =  (callBack:(response: AssistantT)=>void) => {
  const { mutate, status } = useMutation(
    async ({ workspace, botId, data, name }: any) =>(
      await postJson(DialogApiURLWithoutBotID(workspace, `bot/${botId}?bot_name=${name}`))(data)
    ),
    {
      onSuccess: (response) => {
        callBack(response.data)
      },
    }
  );

  return { mutate, status };
} 

const fetchSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      `/${workspace}/analytics/v2/bot_summary_report/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchBotSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      date.from_date
      ? `/${workspace}/analytics/v2/bot_session_tab/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
      :
      `/${workspace}/analytics/v2/bot_session_tab/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchUserMetricsSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      date.from_date
      ? `/${workspace}/analytics/v2/user_metric_tab_report/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`:
       `/${workspace}/analytics/v2/user_metric_tab_report/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchROISummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      date.from_date
      ? `/${workspace}/analytics/v2/roi_tab_report/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
      :
      `/${workspace}/analytics/v2/roi_tab_report/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchUnAnseredSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      date.from_date
      ? `/${workspace}/analytics/v2/unanswered_query_report/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
      :
      `/${workspace}/analytics/v2/unanswered_query_report/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchAreaBotSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date, api] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(`/${workspace}/analytics/v2/${api}/${botId}/${date}?timezone=${timezone}`)
  );
  return data;
};

const fetchQuerySummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date, currenPagetTab] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      date.from_date
      ? `/${workspace}/analytics/v2/${currenPagetTab === 'user_queries'?"user_query_report":"agent_handover_report"}/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
      :
      `/${workspace}/analytics/v2/${currenPagetTab === 'user_queries'?"user_query_report":"agent_handover_report"}/${botId}/${date}?timezone=${timezone}`
    )
  );
  return data;
};

const fetchBarChartBotSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date, api] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      `/${workspace}/analytics/v2/${api}/${botId}?day_span=${date}&timezone=${timezone}`
    )
  );
  return data;
};

const fetchAreaQueryChartBotSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId, date, api] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      `/${workspace}/analytics/v2/${api}/${botId}?day_span=${date}&timezone=${timezone}`
    )
  );
  return data;
};

const fetchLiveBotSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
      `/${workspace}/analytics/v2/live_bot_status_report/${botId}`
    )
  );
  return data;
};

const fetchLiveBotAgentSummary = async ({ queryKey }: any) => {
  const [_, workspace, botId] = queryKey;
  const data = await getJson(
    agentHandoffIntegraionList(
      workspace,
      botId,
      "/agent/live_agent_report"
    )
  );
  return data;
};

export const useFetchSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any
) => {
  const {
    data: overAllSummary,
    status: overAllSummaryStatus,
    refetch: summaryRetry,
    dataUpdatedAt,
    isRefetching
  } = useQuery(['summary', workspace, botId, date], fetchSummary, options);
  return { overAllSummary, overAllSummaryStatus, summaryRetry, dataUpdatedAt, isRefetching };
};

export const useFetchBotSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any
) => {
  const {
    data: botSummary,
    status: botSummaryStatus,
    refetch: botSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['botsummary', workspace, botId, date],
    fetchBotSummary,
    options
  );
  return { botSummary, botSummaryStatus, botSummaryRetry, dataUpdatedAt, isRefetching,isFetching };
};

export const useFetchUserMetricsSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any
) => {
  const {
    data: userMetricsSummary,
    status: userMetricsSummaryStatus,
    refetch: userMetricsSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['usermetricssummary', workspace, botId, date],
    fetchUserMetricsSummary,
    options
  );
  return { userMetricsSummary, userMetricsSummaryStatus, userMetricsSummaryRetry, dataUpdatedAt,isRefetching,isFetching };
};

export const useFetchROISummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any
) => {
  const {
    data: roiSummary,
    status: roiSummaryStatus,
    refetch: roiSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['roisummary', workspace, botId, date],
    fetchROISummary,
    options
  );
  return { roiSummary, roiSummaryStatus, roiSummaryRetry, dataUpdatedAt, isRefetching,isFetching };
};

export const useFetchUnAnsweredSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any
) => {
  const {
    data: unAnsweredSummary,
    status: unAnsweredSummaryStatus,
    refetch: unAnsweredSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['unansweredsummary', workspace, botId, date],
    fetchUnAnseredSummary,
    options
  );
  return { unAnsweredSummary, unAnsweredSummaryStatus, unAnsweredSummaryRetry, dataUpdatedAt, isRefetching, isFetching};
};

export const useFetchBarchartBotSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any,
  key: string
) => {
  const returnEndPoint = (key:string) =>{
    switch(key){
      case "barchartsummary":
        return 'day_wise_session_report'
      case "queryBarChart":
        return "day_wise_query_report"   
      case "barchartusermetricssummary": 
        return "day_wise_unique_user_report"
      case "roibarchartsummary":
        return "day_wise_auto_resolved_query_report"
      case "roimeantimebarchartsummary":
          return "day_wise_mean_time_resolved_query_report"
      case "roitotalresolvedbarchartsummary":
          return "day_wise_total_resolved_query_report"
      case "barchartunansweredsummary":
          return 'day_wise_unanswered_query_report'
      default:
        return "day_wise_agent_report" 
    }
  }
  const {
    data: barchartBotSummary,
    status: barchartBotSummaryStatus,
    refetch: barchartBotRetry,
  } = useQuery(
    [
      `${key}`,
      workspace,
      botId,
      date,
      returnEndPoint(key)
    ],
    fetchBarChartBotSummary,
    options
  );
  return { barchartBotSummary, barchartBotSummaryStatus, barchartBotRetry };
};

export const useFetchAreaQuerychartBotSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any,
  key: string
) => {
  const {
    data: areaQuerychartBotSummary,
    status: areaQuerychartBotSummaryStatus,
    refetch: areaQuerychartBotRetry,
  } = useQuery(
    [
      `${key}`,
      workspace,
      botId,
      date,
      "day_wise_period_user_query_report",
    ],
    fetchAreaQueryChartBotSummary,
    options
  );
  return { areaQuerychartBotSummary, areaQuerychartBotSummaryStatus, areaQuerychartBotRetry };
};

export const useFetchAreaBotSummary = (
  workspace: string,
  botId: string,
  date: string,
  options: any,
  key: string
) => {

  const returnEndPoint = (key:string) =>{
    switch(key){
      case "areabotsummary":
        return 'week_wise_session_report'
      case "agentAreaChart":
        return "week_wise_agent_report"   
      case "areausermetricssummary": 
        return "week_wise_new_return_user_report"
      case "areaunansweredsummary":
        return 'week_wise_unanswered_user_report'
      default:
        return "week_wise_query_report" 
    }
  }

  const {
    data: areaSummary,
    status: areaBotSummaryStatus,
    refetch: areaBotSummaryRetry,
    dataUpdatedAt,
  } = useQuery(
    [
      `${key}`,
      workspace,
      botId,
      date,
      returnEndPoint(key)
    ],
    fetchAreaBotSummary,
    options
  );
  return {
    areaSummary,
    areaBotSummaryStatus,
    areaBotSummaryRetry,
    dataUpdatedAt,
  };
};

export const useFetchQuryTabSummary = (
  workspace: string,
  botId: string,
  date: string,
  currenPagetTab:"user_queries"|"agent_handover",
  options: any
) => {
  const {
    data: querySummary,
    status: querySummaryStatus,
    refetch: querySummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['querysummary', workspace, botId, date, currenPagetTab],
    fetchQuerySummary,
    options
  );
  return { querySummary, querySummaryStatus, querySummaryRetry, dataUpdatedAt,isRefetching,isFetching };
};

const fetchTop10StatusValues = async ({ queryKey }: any) => {
  const [_, workspace, botId, date, status,api] = queryKey;
  const data = await getJson(
    watsonLogsApiURL(
    date.from_date
      ? `/${workspace}/analytics/v2/${api}/${botId}/custom_date${status.length>0?"/"+status:""}?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
      :
      `/${workspace}/analytics/v2/${api}/${botId}${date!== null? "/" + date : ""}${status.length>0?"/"+status:""}?timezone=${timezone}`
    )
  );
  return data;
};

export const useFetchTop10StatusValues = (
  workspace: string,
  botId: string,
  date: string | null,
  options: any,
  key: string,
  status: 'resolved' | 'escalated' | 'abandoned' | 'unresolved' | 'faq_answered' | ""
) => {
  const returnEndPoint = (key:string) =>{
    switch(key){
      case "top10StatusValues":
        return 'top_status_query_report'
      case "top10LiveBotStatusValues":
        return "top_live_status_report"
      case "top10LiveUnAnsweredValues":
        return 'top_unanswered_query_report'
      default:
        return "top_status_agent_report" 
    }
  }
  const {
    data: top10StatusValues,
    status: top10StatusValuesStatus,
    refetch: top10StatusValuesRetry,
    dataUpdatedAt,
  } = useQuery(
    [`${key}`, workspace, botId, date, status,returnEndPoint(key)],
    fetchTop10StatusValues,
    options
  );
  return {
    top10StatusValues,
    top10StatusValuesStatus,
    top10StatusValuesRetry,
    dataUpdatedAt,
  };
};

export const useFetchLiveBotTabSummary = (
  workspace: string,
  botId: string,
  options: any
) => {
  const {
    data: liveBotSummary,
    status: liveBotSummaryStatus,
    refetch: liveBotSummaryRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useQuery(
    ['livebotsummary', workspace, botId],
    fetchLiveBotSummary,
    options
  );
  return { liveBotSummary, liveBotSummaryStatus, liveBotSummaryRetry, dataUpdatedAt,isRefetching,isFetching };
};

export const useFetchLiveBotTabAgentSummary = (
  workspace: string,
  botId: string,
  options: any
) => {
  const {
    data: liveBotAgentSummary,
    status: liveBotAgentSummaryStatus,
    refetch: liveBotAgentSummaryRetry,
    dataUpdatedAt,
  } = useQuery(
    ['livebotagentsummary', workspace, botId],
    fetchLiveBotAgentSummary,
    options
  );
  return { liveBotAgentSummary, liveBotAgentSummaryStatus, liveBotAgentSummaryRetry, dataUpdatedAt };
};

async function fetchAssistant(workspace: string) {
  const data = await getAssistants(workspace);
  return data;
}

export const useFetchAssistant = (workspace: string) => {
  const {
    data: botsOutput,
    status: botsAPIStatus,
    refetch: retry,
  } = useQuery(['assistants', workspace], () => fetchAssistant(workspace));
  return { botsOutput, botsAPIStatus, retry };
};

export const useUpdateSummary = () => {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    async ({ workspace, botId, date }: any) =>
      await getJson(
        watsonLogsApiURL(
          date.from_date
            ? `/${workspace}/analytics/v2/bot_summary_report/${botId}/custom_date?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
            : 
          `/${workspace}/analytics/v2/bot_summary_report/${botId}/${date}?timezone=${timezone}`
        )
      ),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData(['summary'], response);
      },
    }
  );

  return { mutate, status };
};

export const useUpdateBotSummary = () => {
  const queryClient = useQueryClient();
  const { mutate: botSummaryMutate, status: botSummaryMutateStatus } =
    useMutation(
      async ({ workspace, botId, date }: any) =>
        await getJson(
          watsonLogsApiURL(
            `/${workspace}/analytics/v2/bot_session_tab/${botId}/${date}?timezone=${timezone}`
          )
        ),
      {
        onSuccess: (response) => {
          queryClient.setQueriesData(['botsummary'], response);
        },
      }
    );

  return { botSummaryMutate, botSummaryMutateStatus };
};

export const useUpdateAreaBotSummary = (key: string) => {
  const returnEndPoint = (key:string) =>{
    switch(key){
      case "areabotsummary":
        return 'week_wise_session_report'
      case "agentAreaChart":
        return "week_wise_agent_report"   
      case "areausermetricssummary": 
        return "week_wise_new_return_user_report"
      case "areaunansweredsummary":
        return 'week_wise_unanswered_user_report'  
      default:
        return "week_wise_query_report" 
    }
  }
  const queryClient = useQueryClient();
  const { mutate: areaChartMutate, status: areaChartStatus } = useMutation(
    async ({ workspace, botId, date }: any) =>
      await getJson(
        watsonLogsApiURL(
          `/${workspace}/analytics/v2/${
            returnEndPoint(key)
          }/${botId}/${date}?timezone=${timezone}`
        )
      ),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData([`${key}`], response);
      },
    }
  );

  return { areaChartMutate, areaChartStatus };
};

export const useUpdateAreaQueryBotSummary = (key: string) => {
  const queryClient = useQueryClient();
  const { mutate: areaQueryChartMutate, status: areaChartStatus } = useMutation(
    async ({ workspace, botId, date }: any) =>
      await getJson(
        watsonLogsApiURL(
          date.from_date
            ? `/${workspace}/analytics/v2/${
                "day_wise_period_user_query_report"
              }/${botId}?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
            : `/${workspace}/analytics/v2/${
                "day_wise_period_user_query_report"
              }/${botId}?day_span=${date}&timezone=${timezone}`
        )
      ),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData([`${key}`], response);
      },
    }
  );

  return { areaQueryChartMutate, areaChartStatus };
};

export const useUpdateBarChartSummary = (key: string) => {
  const returnEndPoint = (key:string) =>{
    switch(key){
      case "barchartsummary":
        return 'day_wise_session_report'
      case "queryBarChart":
        return "day_wise_query_report"   
      case "barchartusermetricssummary": 
        return "day_wise_unique_user_report"
      case "roibarchartsummary":
        return "day_wise_auto_resolved_query_report"
      case "roimeantimebarchartsummary":
          return "day_wise_mean_time_resolved_query_report"
      case "roitotalresolvedbarchartsummary":
            return "day_wise_total_resolved_query_report"
      case "barchartunansweredsummary":
          return 'day_wise_unanswered_query_report'
      default:
        return "day_wise_agent_report" 
    }
  }
  const queryClient = useQueryClient();
  const { mutate: barChartMutate, status: barChartStatus } = useMutation(
    async ({ workspace, botId, date }: any) =>
      await getJson(
        watsonLogsApiURL(
          date.from_date
            ? `/${workspace}/analytics/v2/${
                returnEndPoint(key)
              }/${botId}?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`
            : `/${workspace}/analytics/v2/${
                returnEndPoint(key)
              }/${botId}?day_span=${date}&timezone=${timezone}`
        )
      ),
    {
      onSuccess: (response) => {
        console.log(response, 'response', key);
        if (key === "barchartusermetricssummary"){
          const res = response.map((obj:any)=>{
            return {...obj, date:`${obj.date}`}
          }
          )
        queryClient.setQueriesData([`barchartusermetricssummary`], res);
        }
          else
        queryClient.setQueriesData([`${key}`], response);
      },
    }
  );

  return { barChartMutate, barChartStatus };
};

export const useUpdateTop10StatusValues = (key: string) => {
  const queryClient = useQueryClient();
  const { mutate: top10StatusValuesMutate, status: areaChartStatus } =
    useMutation(
      async ({ workspace, botId, date, status }: any) =>
        await getJson(
          watsonLogsApiURL(
            date.from_date
            ? `/${workspace}/analytics/v2/${key === "top10StatusValues" ? "top_status_query_report": key === 'top10LiveBotStatusValues'? "top_live_status_report":'top_status_agent_report'}/${botId}/custom_date${status.length>0?"/"+status:""}?from_date=${date.from_date}&to_date=${date.to_date}&timezone=${timezone}`      
           : `/${workspace}/analytics/v2/${key === "top10StatusValues" ? "top_status_query_report": key === 'top10LiveBotStatusValues'? "top_live_status_report":'top_status_agent_report'}/${botId}${date !== null? "/" + date : ""}/${status}?timezone=${timezone}`
          )
        ),
      {
        onSuccess: (response) => {
          queryClient.setQueriesData([`${key}`], response);
        },
      }
    );

  return { top10StatusValuesMutate, areaChartStatus };
};


export const useCustomLoading = ({isRefetching,isFetching,setTabLoading}:any) =>{
  useEffect(()=>{
    setTabLoading(isRefetching?isRefetching:isFetching?isFetching:false)
  },[isRefetching,isFetching])

  return null
}