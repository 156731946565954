import styled from 'styled-components/macro'

import { Button } from './Dialog.BotDetails.Dumb'
import { ErrorMessage } from './Workflow.Analytics.Dumb'

export interface DownloadContentProps {
    /* This prop is optional, since TypeScript won't know that it's passed by the wrapper */
    top?: 'small'
}

export interface ImageSpanProps {
    /* This prop is optional, since TypeScript won't know that it's passed by the wrapper */
    imagePadding?: 'small'
}

export const Input = styled.input.attrs((boolean: any) => ({
    required: true
}))`
    width: 416px;
    float: left;
    padding: ${props => (props.padding ? '26px 12px 8px 12px;' : '26px 34px 8px 12px')};
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000000;

    :hover {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: 0px 3px 6px #00000029;
    }

    ${({ activeDropdown }) =>
        activeDropdown &&
        `
border: 1px solid #009d5f;

`}
`
export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.26s ease-in;
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
        top: 10px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
`

export const DropdownWrapper = styled.div<any>`
    width: 416px;
    float: left;
    position: relative;
    margin-bottom: ${props => (props.last === true ? '0px' : '20px')};
    display: flex;

    span {
        position: absolute;
        top: 15px;
        width: 16px;
        height: 16px;
        right: 12px;
        transform: rotate(0deg);

        svg {
            width: 16px;
            height: 16px;
            color: #555555;
        }
    }
    ${InputContent} {
        left: 44px;
    }
    ${Input} {
        padding-left: 44px;
    }
`

export const BoxWrapperBg = styled.div`
    width: 892px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin-top: 144px;

    .box_wrapper_top {
        width: 100%;
        display: flex;
        flex-direction: column;

        h2 {
            margin-bottom: 10px;
            line-height: 24px;
        }
        p {
            line-height: 14px;
            color: #355d8b;
            font-family: 'Gordita-Regular';
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
`

export const WrapperBgFixed = styled.section`
    width: 100%;
    float: left;
    background: #00000063;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`
export const DownloadBotHelp = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    .dialog_start_with {
        position: relative;
        bottom: 2px;
    }
`

export const ImageSpan = styled.span<ImageSpanProps>`
    svg {
        width: 18px !important;
        height: 18px !important;
    }
    padding-top: ${props => (props.imagePadding === 'small' ? '1px' : '0px')};
`

export const ButtonWithImage = styled.button`
    min-width: 90px;
    padding: 2.5px 12px 0;
    height: 32px;
    color: #333;
    display: flex;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 19px;
    font-family: 'Gordita-Medium';
    outline: 0;
    font-size: 12px;
    cursor: pointer;
    label {
        position: relative;
        top: 1px;
        margin: 0px;
        cursor: pointer;
    }
    span {
        width: 12px;
        height: 12px;
        margin-right: 6px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        svg {
            width: 12px;
            height: 12px;
            fill: #333;
            color: #333;
        }
    }
    :hover {
        background: #ddfeef;
        color: #333;
        border: 1px solid #009d5f;
    }
    :active {
        background: #fff 0% 0% no-repeat padding-box;
        color: #333;
        border: 1px solid #009d5f;
    }
    :focus {
        background: #ddfeef;
        color: #333;
        border: 1px solid #009d5f;
    }
`
export const DownloadContent = styled.p<DownloadContentProps>`
    font-family: 'Gordita-Medium';
    outline: 0;
    font-size: 12px;
    width: 100%;
    float: left;
    color: #007bff;
    display: flex;
    align-items: center;
    padding: 0px;
    // margin-top: ${props => (props.top === 'small' ? '8px' : '20px')};
    cursor: pointer;
    span svg {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    :hover {
        text-decoration: underline;
    }
    :active {
        color: #eb974e;
    }
    :focus {
        color: #eb974e;
        text-decoration: underline;
    }
    :visited {
        color: #eb974e;
    }
    .width_18 {
        color: rgb(24, 87, 220);
        svg {
            width: 18px;
            height: 18px;
            color: rgb(24, 87, 220);
        }
    }
    label {
        position: relative;
        margin: 0px;
        a {
            color: rgb(24, 87, 220);
            text-decoration: none;
            :hover {
                color: rgb(24, 87, 220);
                text-decoration: underline;
            }
        }
    }
`
export const ButtonWrapper = styled.div`
    width: auto;
    float: left;
    margin-left: 12px;
`
export const OrSticky = styled.div`
    width: 28px;
    height: 28px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    padding-top: 4px;
    :before {
        content: '';
        position: absolute;
        background: #c6c6c6;
        width: 180px;
        height: 1px;
        right: 28px;
    }
    :after {
        content: '';
        position: absolute;
        background: #c6c6c6;
        width: 180px;
        height: 1px;
        left: 28px;
        // height: ${props => (props.afterHeight ? props.afterHeight : '180px')};
    }

    span{
        position: relative;
        top: 2px;
    }
`
export const DropdownContainer = styled.div`
    width: 100%;
    float: left;
    ${DropdownWrapper} {
        :last-child {
            margin-bottom: 0px;
        }
    }
    ${Button} {
        cursor: pointer;
    }
`
export const BotGridsContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px 0;
    margin-top: 14px;

    ${ErrorMessage}{
        position: absolute;
        top: -20px;
    }
`
export const BotWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 40px 20px 30px;
    background: #fff;
    border-radius: 10px;

    .box_wrapper_top p {
        color: #355d8b;
        font-family: 'Gordita-Regular';
        font-size: 14px;
        margin-bottom: 12px;
        width: 365px;
        line-height: 20px;
    }
    ${DropdownWrapper} {
        width: 390px;
    }
`

export const DialogCount = styled.div`
    width: auto;
    height: 48px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 12px;

    svg {
        width: 24px;
        height: 24px;
        fill: #355d8b;
    }
    sup {
        width: 16px;
        height: 16px;
        background: #d5ffee 0% 0% no-repeat padding-box;
        border: 2px solid #d5ffee;
        border-radius: 50%;
        top: 6px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        right: -4px;
        font-size: 8px;
        font-family: 'Gordita-Regular';

        label {
            position: relative;
            top: 1px;
            margin-bottom: 0px;
            right: 0;
            width: auto;
            height: auto;
        }
    }
`
export const HeaderContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
`

export const HeaderTop = styled.h3`
    width: 100%;
    float: left;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Gordita-Medium';
    color: #466AF2;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
`