import {
  deleteJson,
  getJson,
  patchJson,
  postJson,
  putJson,
} from '@/common/utils/api-utils';
import * as R from 'ramda';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BotsP } from './ManageUsers';
import {localAPI} from '@/LiveAgent/LivaAgentApi'
// const localAPI = false
///////////////change api env for "checkDuplicateEmail" tooo
const MockURL = localAPI ? "https://dev-assistant.workativ.ai" : window.location.origin
// 'https://3df9b90f-c8cc-4cb0-90ce-58c55ce3c912.mock.pstmn.io';
// 'https://dev-assistant.workativ.ai/liveagent'
//  'https://1e332c06-c1d4-450a-bc57-9ec958b9be5d.mock.pstmn.io';
const mockAgents = false;
export const MockserverURL = (api: string) => {
  return MockURL + '/liveagent' + api;
};
const MockTeamURl = localAPI ? "https://dev-assistant.workativ.ai" : window.location.origin
  // 'https://3df9b90f-c8cc-4cb0-90ce-58c55ce3c912.mock.pstmn.io';
// 'https://dev-assistant.workativ.ai/liveagent'
const MockTeamsUrl = (api: string) => {
  return MockTeamURl+ '/liveagent' + api;
};
export const initialAgent = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  teams: [],
  role:"",
  deleted: false,
  agent_status: '',
  chat_sources: [],
  auth_user_id:0
};
export const editingInitialAgent = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  teams: [],
  role: "",
  deleted: false,
  agent_status: '',
  chat_sources: [],
};

export const initialTeam = {
  id: '',
  name: '',
  team_members: [],
  chat_sources: [],
};
const mockValues = [
  {
    id: 'e53278fd-a4b2-11ee-aabe-6a4b5d2a749f',
    auth_user_id:235,
    first_name: 'Manjula',
    last_name: 'Ms',
    email: 'manjula.m@skitter.in',
    teams: [
      {
        id: 'teams_01',
        name: 'IT Support',
      },
    ],
    role:'shared_inbox_admin',
    deleted: false,
    agent_status: 'Online',
    chat_sources: [
      {id: 'teams_01',
    name: 'shared_inbox_admin'}],
  },
  {
    id: 'd4a9c178-44a2-450c-a1be-872380d07c5a',
    auth_user_id:23,
    first_name: 'Arunadevi',
    last_name: 'Rathinakumar',
    email: 'arunadevi.r@workativ.com',
    agent_status: 'Offline',
    role:"shared_inbox_agent",
    deleted: false,
    teams: [
      {
        id: 'teams_01',
        name: 'IT Support',
      },
      {
        id: 'teams_02',
        name: 'HR Support',
      },
    ],
    chat_sources: [
      {id: 'teams_01',
    name: 'shared_inbox_admin'},
  {
    id: 'teams_01',
    name: 'sadfasdfasdfasd'
  }],
  },
  {
    id: 'c78a9c178-44a2-450c-a1be-872380d07c5a',
    auth_user_id:25,
    first_name: 'Shri',
    last_name: 'Ghayathri',
    email: 'shri.ghayathri@workativ.com',
    agent_status: 'Invited',
    role:"shared_inbox_agent",
    deleted: false,
    teams: [],
    chat_sources: [
      {id: 'teams_01',
    name: 'Testskd'},
  ],
  },
];
export interface AgentDetailsT {
  id: string;
  auth_user_id:number;
  first_name: string;
  last_name: string;
  email: string;
  teams: {
    id: string;
    name: string;
  }[];
  role: string
  deleted: boolean;
  agent_status: string;
  chat_sources: roleT[];
}
export interface AgentToEditT {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  teams: {
    id: string;
    name: string;
  }[];
  role: string;
  deleted: boolean;
  agent_status: string;
  chat_sources: roleT[];
}
export interface teamMembersT {
  auth_user_id: number;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
}
export interface TeamtoUpdateT {
  id: string;
  name: string;
  team_members: string[];
  chat_sources: string[];
}
export interface  TeamDetailsT{
  id: string;
  name: string;
  team_members: teamMembersT[];
  chat_sources: chatT[];
}
type chatT = {
  id: string;
  name: string;
};
export interface Team {
  id: string;
  name: string;
}
type roleT = {
  id: string;
  name: string;
  displayName:string
};

export type AgentEditT = {
  agentToEdit: AgentToEditT;
  checkRole:(role: string) => "Shared inbox Admin" | "Shared inbox Agent" | "issue";
  workspace: string;
  callbacks: {
    cancel: () => void;
    completed: () => null | void;
    failed: (err: any) => string;
  };
  teamsList: Team[];
  roles: roleT[];
  currentUser:any
};

export type TeamEditT = {
  teamToEdit: TeamDetailsT;
  workspace: string;
  callbacks: {
    cancel: () => void;
    completed: () => null | void;
    failed: (err: any) => string;
  };
  roles: roleT[];
  AllAgentsData: AgentDetailsT[];
  bots: BotsP[];
};

export type AgentCreateT = {
  workspace: string;
  checkRole:(role: string) => "Shared inbox Admin" | "Shared inbox Agent" | "issue";
  setCreatepopup: React.Dispatch<React.SetStateAction<boolean>>;
  callbacks: {
    cancel: () => void;
    completed: () => null | void;
    failed: (err: any) => string;
  };
  currentUser: any;
  teamsList: TeamDetailsT[];
  roles: roleT[];
  AllAgentsData: AgentDetailsT[];
};

export type TeamCreateT = {
  workspace: string;
  closePopup: () => void;
  callbacks: {
    cancel: () => void;
    completed: () => null | void;
    failed: (err: any) => string;
  };
  AllAgentsData: AgentDetailsT[];
  bots: BotsP[];
};

export const fetchAllAgents = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: AgentDetailsT[] = mockAgents
    ? mockValues
    : await getJson(MockserverURL(`/${workspace}/v1/agent`));
  return data;
};

export const useAllAgents = (workspace: string) => {
  const {
    data: AllAgentsData,
    refetch: sessionRetry,
    isFetching: agentsFetching,
    dataUpdatedAt,
    isRefetching,
    isFetched,
    error,
  } = useQuery(
    ['agentLists', workspace],
    fetchAllAgents
    //   {
    //     refetchInterval: 30000,
    //     staleTime: 30000,
    //   }
  );
  return {
    AllAgentsData,
    sessionRetry,
    agentsFetching,
    dataUpdatedAt,
    isRefetching,
    isAllSessionsFetched: isFetched,
    isSessionError: error,
  };
};
export type AgentUpdateT = {
  workspace: string;
  agentID?: string | number;
  body: {
    first_name?: string;
    last_name?: string;
    email?: string;
    teams?: string[];
    role?: string;
    inviter?: number;
    agent_status?:string;
  };
};
export type TeamUpdateT = {
  workspace: string;
  agentID?: string | number;
  body: {
    name: string;
    team_members: string[];
  };
};
export const useAgentUpdate = (success: () => void, error: () => void) => {
  console.log('apicalled');
  const queryClient = useQueryClient();
  const {
    mutate: agentUpdate,
    isError: agentUpdateError,
    isLoading: agentUpdateLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (agentUpdate: AgentUpdateT) =>
      patchJson(
        MockserverURL(
          `/${agentUpdate.workspace}/v1/agent/${agentUpdate.agentID}`
        )
      ,agentUpdate.body),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('agentLists');
    },
    onError: () => {
      error();
    },
  });
  return {
    agentUpdate,
    agentUpdateError,
    agentUpdateLoading,
    ismutationSuccess: isSuccess,
  };
};
export const useAgentCreate = (success: () => void, error: () => void) => {
  console.log('apicalled');
  const queryClient = useQueryClient();
  const {
    mutate: agentCreate,
    isError: agentCreateError,
    isLoading: agentCreateLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (agentUpdate: AgentUpdateT) =>
      postJson(MockserverURL(`/${agentUpdate.workspace}/v1/agent`))(
        agentUpdate.body
      ),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('agentLists');
    },
    onError: () => {
      console.log(error, 'sdfafs');
      error();
    },
  });
  return {
    agentCreate,
    agentCreateError,
    agentCreateLoading,
    ismutationSuccess: isSuccess,
  };
};

export const useAgentDelete = (success: () => void, error: () => void) => {
  console.log('useAgentDeleteapicalled');
  const queryClient = useQueryClient();
  const {
    mutate: agentDelete,
    isError: agentDeleteError,
    isLoading: agentDeleteLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (agentDelete: AgentUpdateT) =>
      deleteJson(
        MockserverURL(
          `/${agentDelete.workspace}/v1/agent/${agentDelete.agentID}`
        )
      )(),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('agentLists');
    },
    onError: () => {
      error();
    },
  });
  return {
    agentDelete,
    agentDeleteError,
    agentDeleteLoading,
    ismutationSuccess: isSuccess,
  };
};
const capitalize = (string: any) =>
  R.ifElse(
    R.isEmpty,
    R.always(''),
    R.compose(R.flip(R.concat)(R.slice(1, Infinity, string)), R.toUpper, R.head)
  )(string);

const replaceUnAlpha = R.replace(/[^a-zA-Z]/g, '');

const normalize = (name: string) =>
  (name.length &&
    name[0] +
      R.compose<string, string, string>(
        R.toLower,
        R.slice(1, Infinity)
      )(name)) ||
  '';

export const allowOnlyAlpha: (val: any) => any = R.ifElse(
  R.isEmpty,
  R.always(''),
  R.compose(normalize, capitalize, replaceUnAlpha)
);

const mockTeams = [
  {
    id: 'b39b46b4-0cd9-4fce-9645-7a327eb0c05c',
    name: 'IT Support Team',
    team_members: [
      'e53278fd-a4b2-11ee-aabe-6a4b5d2a749f',
      'd4a9c178-44a2-450c-a1be-872380d07c5a',
    ],
    chat_sources: [{
      id:'chat_source_id_01',
      name:"skdhfkjdhkjad"
  },
  {
    id:'chat_source_id_01',
    name:"skdhfkjdhkjad"
},
{
  id:'chat_source_id_01',
  name:"skdhfkjdhkjad"
}],
  },
];
export const fetchAllTeams = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: TeamDetailsT[] = mockAgents
    ? mockTeams
    : await getJson(MockTeamsUrl(`/${workspace}/v1/team`));
  console.log(data, 'teams');
  return data;
};
export const useAllTeams = (workspace: string) => {
  const {
    data: AllTeamsData,
    refetch: sessionRetry,
    isFetching: TeamFetching,
    dataUpdatedAt,
    isRefetching,
    isFetched,
    error,
  } = useQuery(['teamLists', workspace], fetchAllTeams);
  return {
    AllTeamsData,
    sessionRetry,
    TeamFetching,
    dataUpdatedAt,
    isRefetching,
    isAllSessionsFetched: isFetched,
    isSessionError: error,
  };
};

export const useTeamCreate = (success: () => void, error: () => void) => {
  console.log('apicalled');
  const queryClient = useQueryClient();
  const {
    mutate: teamCreate,
    isError: agentCreateError,
    isLoading: agentCreateLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (TeamUpdate: TeamUpdateT) =>
      postJson(MockTeamsUrl(`/${TeamUpdate.workspace}/v1/team`))(
        TeamUpdate.body
      ),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('teamLists');
    },
    onError: () => {
      error();
    },
  });
  return {
    teamCreate,
    agentCreateError,
    agentCreateLoading,
    ismutationSuccess: isSuccess,
  };
};

export const useTeamUpdate = (success: () => void, error: () => void) => {
  console.log('apicalled');
  const queryClient = useQueryClient();
  const {
    mutate: teamUpdate,
    isError: agentUpdateError,
    isLoading: agentUpdateLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (teamUpdate: TeamUpdateT) =>
      patchJson(
        MockTeamsUrl(`/${teamUpdate.workspace}/v1/team/${teamUpdate.agentID}`)
      ,teamUpdate.body),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('teamLists');
    },
    onError: () => {
      error();
    },
  });
  return {
    teamUpdate,
    agentUpdateError,
    agentUpdateLoading,
    ismutationSuccess: isSuccess,
  };
};

export const useTeamDelete = (success: () => void, error: () => void) => {
  console.log('useAgentDeleteapicalled');
  const queryClient = useQueryClient();
  const {
    mutate: teamDelete,
    isError: teamDeleteError,
    isLoading: teamDeleteLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (teamDelete: TeamUpdateT) =>
      deleteJson(
        MockTeamsUrl(`/${teamDelete.workspace}/v1/team/${teamDelete.agentID}`)
      )(),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('teamLists');
    },
    onError: () => {
      error();
    },
  });
  return {
    teamDelete,
    teamDeleteError,
    teamDeleteLoading,
    ismutationSuccess: isSuccess,
  };
};
