import Downshift from 'downshift'
/*eslint-disable*/
import React, { Children, useContext, useState } from 'react'
import Tooltip from 'react-tooltip-lite'

import { BotQaICon, DownArrowCircle, InfoIcon, TimerIcon } from '@/common/Icons/Icons'
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb'
import {
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Header,
    HeaderLeft,
    InputContent,
    Paragraph
} from '@/common/styled/Workflow.Dumb'

import { useActionsCached } from '../server-queries'
import { ActionPanel } from './ActionPanel'
import { ApprovalPopup } from './ApprovalPopup'
import { SidePanelContext } from './Canvas'
import { ConditionPanel } from './ConditionPopup'
import { EndPanel } from './EndPanel'
import { ForEachPanel } from './ForEachPopup'
import { FunctionPanel } from './FunctionPopup'
import { MarketPlacePanel } from './MarketPlacePanel'
import { useDropDown } from './Tree'
import { TriggerPanel } from './TriggerPanel'
import { WebhookPanel } from './WebhookPanel'
import { Action, CanvasRightPanelT, Nodes, RightPanelStateT } from './types'
import { SchedularPanel } from './Scheduler'
import { getProductId } from '@/common/utils/api-utils'
import { SendNotificationWrapperPopup } from './SendNotificationPopup'

export const CanvasRightPanel: CanvasRightPanelT = {
    Component: null,
    ToggleArrow: null,
    AppsDropDown: null,
    StepHeader: null,
    Step: Action
}



CanvasRightPanel.Component = (props: any) => {
    const { rightPanelState } = useContext(SidePanelContext)

    const [_unfiltertedAppListCache, _isFetching] = useActionsCached(props.match.params.workspacename)

    const { showUploadPanel } = props
    if (showUploadPanel) {
        return <MarketPlacePanel />
    }

    // return  <WebhookPanel {...props}/>

    return (
        <React.Fragment>
            {rightPanelState.currentNode.nodeType === Nodes.Trigger && rightPanelState.show && (
                <TriggerPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Webhook && (
             <WebhookPanel
                {...props}
                app_trigger={true}
                currentTrigger={"webhook"}
              />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Action && rightPanelState.show && (
                <ActionPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Condition && rightPanelState.show && (
                <ConditionPanel {...props} />
            )}

            {rightPanelState.currentNode.nodeType === Nodes.Approval && rightPanelState.show && (
                <ApprovalPopup {...props} />
            )}

            {rightPanelState.currentNode.nodeType === Nodes.Function && rightPanelState.show && (
                <FunctionPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Foreach && rightPanelState.show && (
                <ForEachPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.End && rightPanelState.show && <EndPanel {...props} />}
            {rightPanelState.currentNode.nodeType === Nodes.Timer && rightPanelState.show && <SchedularPanel {...props}/>}
            {rightPanelState.currentNode.nodeType === Nodes.Notification && rightPanelState.show&& <SendNotificationWrapperPopup {...props}/>}
        </React.Fragment>
    )
}

CanvasRightPanel.ToggleArrow = (props: {
    showDropDown: RightPanelStateT
    showOrHidePanel: (state: RightPanelStateT) => void
}) => {
    const { showOrHidePanel } = props

    return (
        <div
            className={!props.showDropDown.show ? 'closed_menu_arrow_build_close' : 'arrow_build_close'}
            onClick={() =>
                showOrHidePanel({
                    ...props.showDropDown,
                    show: !props.showDropDown.show
                })
            }
        >
            <DownArrowCircle />
        </div>
    )
}

CanvasRightPanel.StepHeader = (props: {
    title: string
    description: string
    hasMinWidth?: boolean
    infocontent?: string
    helpcontent?: string
    helplink?: string
    children: JSX.Element
}) => (
    <>
    {getProductId() === "ASSISTANT" ? ( 
        <Header>
        <HeaderLeft minWidth={props.children || props.hasMinWidth ? '300' : '0'}>
            {props.title}{' '}
            <Tooltip
                className="target customTip"
                zIndex={10000}
                arrowSize={8}
                tagName="span"
                content={
                    props.infocontent
                        ? props.infocontent
                        : 'You will need to add the following urls in your azure portal when creating teams app'
                }
                distance={5}
            >
                <InfoIcon />
            </Tooltip>
            {props.helpcontent && (
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={props.helpcontent}
                    distance={5}
                >
                    <a href={props.helplink} target="_blank">
                        <BotQaICon />
                    </a>
                </Tooltip>
            )}
        </HeaderLeft>{' '}
        <HeaderRight>{props.children}</HeaderRight>
        </Header>
    ) : ( 
        <Header>
            <HeaderLeft minWidth={props.children || props.hasMinWidth ? '300' : '0'}>
                {props.title}{' '}
                {/* <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={
                        props.infocontent
                            ? props.infocontent
                            : 'You will need to add the following urls in your azure portal when creating teams app'
                    }
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip> */}
                {props.helpcontent && (
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={props.helpcontent}
                        distance={5}
                    >
                        <a href={props.helplink} target="_blank">
                            <BotQaICon />
                        </a>
                    </Tooltip>
                )}
            </HeaderLeft>{' '}
            <HeaderRight>{props.children}</HeaderRight>
        </Header>
    ) }

        {/* <Header>
            <HeaderLeft minWidth={props.children || props.hasMinWidth ? '300' : '0'}>
                {props.title}{' '}
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={
                        props.infocontent
                            ? props.infocontent
                            : 'You will need to add the following urls in your azure portal when creating teams app'
                    }
                    distance={5}
                >
                    <InfoIcon />
                </Tooltip>
                {props.helpcontent && (
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content={props.helpcontent}
                        distance={5}
                    >
                        <a href={props.helplink} target="_blank">
                            <BotQaICon />
                        </a>
                    </Tooltip>
                )}
            </HeaderLeft>{' '}
            <HeaderRight>{props.children}</HeaderRight>
        </Header> */}
        <Paragraph>{props.description}</Paragraph>
    </>
)

CanvasRightPanel.AppsDropDown = (props: {
    inputs: { inputs: any[]; key: string; idKey: string; showIcon: boolean }
    addDropDown: boolean
    render: (makeDropDownVisible: any) => void
    updateSelection: (selected: string, id: string) => void
    showVersion?: boolean
    closeCallBack: () => void
    showOnPrem: boolean
    dataForNoData: boolean
    functionPopup: boolean
    description?: string
    showNotConfigured?:boolean
}) => {
    const {
        addDropDown,
        inputs,
        updateSelection,
        showVersion,
        closeCallBack,
        showOnPrem,
        dataForNoData,
        description,
        functionPopup,
        showNotConfigured
    } = props

    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()

    function onClose() {
        makeDropDownHidden()
        closeCallBack && closeCallBack()
    }

    return (
        <React.Fragment>
            {props.render(makeDropDownVisible)}

            {addDropDown && showDropDown ? (
                <DropdownUlWrapper>
                    <Downshift isOpen={showDropDown} onOuterClick={() => onClose()}>
                        {() => (
                            <div className="automation_scroly">
                                <DropdownUl className={getProductId() === "ASSISTANT" ? "dropDownUL": "automate_product_app_dropdown"}>
                                    {inputs.inputs && inputs.inputs.length > 0 ? (
                                        inputs.inputs.map((ele, i: number) => (
                                            <React.Fragment key={i}>
                                                <DropdownLi
                                                    onClick={() => {
                                                        onClose()

                                                        updateSelection(ele[inputs.key], ele[inputs.idKey])
                                                    }}
                                                >
                                                    {ele.value &&
                                                    ele.value.length > 0 &&
                                                    ele.value[0].icon &&
                                                    inputs.showIcon ? (
                                                        <div className="dropdown_app_icon">
                                                            <img src={ele.value[0].icon} />
                                                        </div>
                                                    )
                                                    : ele.icon && inputs.showIcon?(
                                                        <div className="dropdown_app_icon">
                                                            <img src={ele.icon} />
                                                        </div>
                                                    ) 
                                                    : (
                                                        inputs.showIcon && (
                                                            <div
                                                                className="dropdown_app_icon"
                                                                style={{ visibility: 'hidden' }}
                                                            />
                                                        )
                                                    )}
                                                    {functionPopup ? (
                                                        <div className="function_popup_dropdown">
                                                            <div className="application_dropdown_content">
                                                                {ele[inputs.key]}

                                                                {showOnPrem &&
                                                                    ele.value &&
                                                                    ele.value.length > 0 &&
                                                                    ele.value[0] &&
                                                                    ele.value[0].authentication_type == 'on_prem' &&
                                                                    ' (On-Prem)'}
                                                            </div>
                                                            {description && description.length > 0 && (
                                                                <p>{description}</p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="application_dropdown_content">
                                                            {ele[inputs.key]}

                                                            {showOnPrem &&
                                                                ele.value &&
                                                                ele.value.length > 0 &&
                                                                ele.value[0] &&
                                                                ele.value[0].authentication_type == 'on_prem' &&
                                                                ' (On-Prem)'}
                                                        </div>
                                                    )}
                                                    {showVersion && (
                                                        <>
                                                            {ele.type == 'trigger' &&
                                                                <label className="timer_icon_dropdown_li">
                                                                    <TimerIcon />
                                                                </label>
                                                            }
                                                            <span className="application_dropdown_version">
                                                                Version :{' '}
                                                                {parseInt(ele.version.split('v')[1]) < 10
                                                                    ? '0' + ele.version.split('v')[1]
                                                                    : ele.version.split('v')[1]}
                                                            </span>
                                                        </>
                                                    )}
                                                    {showNotConfigured&&(
                                                        <span className="application_dropdown_version">Not configured</span>
                                                    )}
                                                </DropdownLi>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <DropdownLi className="no_data_found_dropdown_li">
                                            {dataForNoData ? dataForNoData : 'No data available'}
                                        </DropdownLi>
                                    )}
                                </DropdownUl>
                            </div>
                        )}
                    </Downshift>
                </DropdownUlWrapper>
            ) : null}
        </React.Fragment>
    )
}
