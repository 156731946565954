import styled, { StyledComponent } from 'styled-components';

export const DateLogs = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  cursor: default;
  font-size: 16px;
  color: #494949;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Logsspan: StyledComponent<'span', any, any, never> = styled.span`
    width: auto;
    float: left;
    font-family: ${(props: any) =>
      props.primary ? 'Gordita-Regular' : 'Gordita-Medium'}
    cursor: ${(props: any) => (props.primary ? 'pointer' : 'default')}
    font-size: ${(props: any) => (props.primary ? '12px' : '15px')};
    margin-right:${(props: any) => (props.secondary ? '0px' : '5px')};
    margin-left:${(props: any) => (props.secondary ? '3px' : '3px')};
    color: ${(props: any) => (props.primary ? '#0a89e8' : '#494949')};
`;

export const BoxWrapper: StyledComponent<'div', any, any, never> = styled.div`
width:100%
float:left;
display: flex;
justify-content: center;
padding-top:60px;
overflow-y: ${(props: any) => (props.primary ? 'scroll' : 'visible')};
height: ${(props: any) =>
  props.primary ? 'calc(100vh - 15vh)' : 'max-content'};
position:relative;

@media (max-width: 1540px) {
    overflow-y: ${(props: any) => (props.primary ? 'scroll' : 'scroll')};
    height: ${(props: any) =>
      props.primary ? 'calc(100vh - 40px)' : 'max-content'};

}

`;
export const Box: StyledComponent<'div', any, any, never> = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props: any) =>
    props.secondary ? 'space-evenly' : 'space-between'};
  align-self: flex-start;
  margin-bottom: ${(props: any) => (props.primary ? '50px' : '0')};
`;

export const BoxRight: StyledComponent<'div', any, any, never> = styled.div`
  width: ${(props: any) => `${props.width * 10}%` || '100%'};
  float: left;
  border-radius: 18px;
  box-shadow: ${(props: any) =>
    props.secondary
      ? 'rgba(0, 0, 0, 0.16) 0 3px 6px'
      : 'rgba(0, 0, 0, 0.16) 0 3px 6px'};
  background: #fff;
  padding: 30px;
  cursor: default;
  margin-right: ${(props: any) => (props.secondary ? '30px' : '0px')};
`;
export const BoxRightContainer = styled.div`
  width: 66.66%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BoxRightWrapper = styled.div`
width:100%
float:left;
display:flex;
justify-content:space-around;
flex-direction: row;
flex-wrap: wrap;
`;

export const HeaderLeft = styled.h4`
  text-align: center;
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  cursor: default;
  font-size: 18px;
  color: #657993;
  margin-bottom: 0px;
`;
export const HeaderRight = styled.h4`
  text-align: left;
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  cursor: default;
  font-size: 20px;
  color: #657993;
  margin-bottom: 0px;
  line-height: 20px;
`;

export const ContetntInformationSpan: StyledComponent<
  'span',
  any,
  any,
  never
> = styled.span`
  width: 100%;
  height: 100%;
  float: left;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-left: 5px;
  color: #494949;
`;

export const HoverSpan = styled.span`
  width: auto;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: #494949;
`;

export const CreditMoney: StyledComponent<'div', any, any, never> = styled.div`
  width: 100%;
  float: left;
  text-align: ${(props: any) => props.CreditsAlign || ''};
  margin: ${(props: any) => props.CreditsMargin || ''};
  color: #494949;
`;
export const DollerSup: StyledComponent<'sup', any, any, never> = styled.sup`
    font-size:${(props: any) => props.SupFont}
    font-family: 'Gordita-Medium';
`;
export const DollerAmount: StyledComponent<
  'span',
  any,
  any,
  never
> = styled.span`
    font-size:${(props: any) => props.DollerFont}
    font-family: 'Gordita-Bold';
    text-align: ${(props: any) => props.alignLeft || 'center'};;
`;
export const DollarMonth: StyledComponent<
  'span',
  any,
  any,
  never
> = styled.span`
    font-size:${(props: any) => props.DollerMonth}
    font-family: 'Gordita-Regular';
`;

export const Tooltip = styled.span`
  // width: 100%;
  // float: left;
  position: relative;
`;

export const AnchorTag = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
`;
export const AnchorTagContent: StyledComponent<
  'span',
  any,
  any,
  never
> = styled.span`
    width: auto;
    float:left;
    text-align:center;
    font-size: 16px;
    color: #2e5ce6;
    padding-bottom: 20px;
    font-family: 'Gordita-Regular';
    cursor: pointer;
    margin ${(props: any) =>
      props.alignText ? '10px 0 0 0' : '10px auto 0 auto'};
    text-decoration ${(props: any) =>
      props.alignUnderline ? 'underline' : ''};

    :hover {
        text-decoration: underline;
    }
`;
export const BoxOne: StyledComponent<'div', any, any, never> = styled.div`
  width: ${(props: any) => `${props.BoxWidth * 10}%` || 'auto'};
  float: left;
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  background: #fff;
  margin-bottom: ${(props: any) => props.BoxMarginBottom || ''};
  padding: 30px;
  cursor: default;
  margin-right: ${(props: any) => props.BoxMarginRight || ''};
  height: max-content;
`;
export const MonthlySubscription = styled.p`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 14px;
  color: #494949;
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const NoCreditCard = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 14px;
  color: #494949;
  margin-top: 10px;
  margin-bottom: 0px;
  height: 75px;
  margin: auto;
  text-align: center;
  vertical-align: top;
  margin-top: 47px;
`;
export const Billing = styled.p`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 13px;
  color: #494949;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
`;

export const Table = styled.table`
  width: 100%;
  float: left;
  margin-top: 10px;
  position: relative;
  margin-bottom: 5px;
`;
export const Thead: StyledComponent<'thead', any, any, never> = styled.thead`
  border-bottom: ${(props: any) => (props.primary ? '1px solid #ccc' : 'none')};
  width: 100%;
  float: left;
  display: flex;
  margin: ${(props: any) => (props.primary ? '10px 0px' : '0px')};
  padding: ${(props: any) => (props.primary ? '10px 0px' : '0px')};
`;

export const Th: StyledComponent<'th', any, any, never> = styled.th`
  width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
  float: left;
  font-size: 13px;
  display: flex;
  justify-content: ${(props: any) =>
    props.primary ? 'flex-start' : 'flex-end'};
  font-family: 'Gordita-Medium';
  color: #494949;
`;
export const Tr = styled.tr`
  width: 95%;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Tbody: StyledComponent<'tbody', any, any, never> = styled.tbody`
  width: 100%;
  float: left;
  display: ${(props: any) => (props.primary ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  height: ${(props: any) => (props.primary ? 'calc(100vh - 35vh)' : '100%')};
  overflow-y: ${(props: any) => (props.primary ? 'scroll' : 'auto')};

  @media only screen and (max-width: 1500px) {
    height: ${(props: any) => (props.primary ? 'calc(100vh - 45vh)' : '100%')};
  }
  @media only screen and (max-width: 1400px) {
    height: ${(props: any) => (props.primary ? 'calc(100vh - 50vh)' : '100%')};
  }
  @media only screen and (max-width: 1300px) {
    max-height: ${(props: any) =>
      props.primary ? 'calc(100vh - 48vh)' : '100%'};
  }
  position: relative;
`;
export const Td: StyledComponent<'td', any, any, never> = styled.td`
  width: auto;
  float: left;
  font-size: 13px;
  font-family: 'Gordita-Regular';
  width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
  display: flex;
  justify-content: ${(props: any) =>
    props.ContentRight ? 'flex-start' : 'flex-end'};

  align-items: center;
  color: ${(props: any) => (props.primary ? '#ff7900' : '#494949')};
  margin-top: 5px;
`;
export const EllipsisContent = styled.span`
  width: 100%;
  float: left;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
`;
export const SvgWidth = styled.span`
  width: 15px;
  height: 15px;
  float: left;
  cursor: pointer;

  :hover {
    fill: #0a89e8;
    color: #0a89e8;
  }
`;
export const ImgContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;
export const ProfileImg = styled.img`
  width: 42px;
  height: 42px;
  float: left;
  border-radius: 50%;
  border: 1px solid #f4f4f4;
  padding: 5px;
`;
export const EmailWrapper = styled.div`
  width: 85%;
  float: left;
  padding-left: 20px;
`;
export const UserID = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  color: #494949;
  font-size: 12px;
`;
export const UserName = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  color: #494949;
  font-size: 14px;
`;
export const CardDetails = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  margin: 10px 0px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 19px;
`;
export const CardNumber = styled.div`
  width: 80%;
  float: left;
  font-family: 'Gordita-Regular';
  color: #494949;
  font-size: 14px;
`;
export const VisaImg = styled.img`
  width: 40px;
  height: auto;
  float: left;
`;

export const InformationIcon = styled.span`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 5px;
  margin-top: 2px;
`;

export const NoEstimate = styled.div`
  height: 245px;
  font-size: 14px;
  font-family: 'Gordita-Regular';
  text-align: center;
  vertical-align: middle;
  padding-top: 40%;
`;
export const DateEstimate = styled.span`
  width: 100%;
  float: left;
  ${DateLogs} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ${Logsspan} {
    font-size: 12px;
    font-family: 'Gordita-Regular';
    margin-right: 0px;
  }
`;
export const CreditWrapper = styled.span`
  width: 100%;
  float: left;
  ${CreditMoney} {
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;
    padding: 5px 0px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${AnchorTag} {
    justify-content: left;
  }
  ${AnchorTagContent} {
    text-align: left;
    margin: 0px;
    padding: 15px 0px 0px;
  }
  ${DollarMonth} {
    width: 70%;
    float: left;
    text-align: left;
  }
`;

