import React, { useState } from 'react';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import styled from 'styled-components/macro';
import {
  AddIcon,
  ArrowIcon,
  CloseIcon,
  ConfluenceIcon,
  DeleteIcon,
  EditIcon,
  InfoColorIcon,
  SearchIcon,
  SharePointIcon,
  VideoIcon,
  VivantioIcon,
  FreshServiceIcon,
  S3ConnectionIcon,
  EyeOpenIcon,
  GoogleDriveIcon
} from '@/common/Icons/Icons';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import knowledgeEmptyImg from '@/common/images/no_kb.svg';
import ImgLoader from '@/common/images/loading.svg';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { ButtonContainer } from '@/common/styled/Workflow.Dumb';
import { Modal } from '@/common/components/Modal';
import {
  useDeleteConnection,
  useExternalCategory,
  useKBCategory,
  useTrainMode,
} from './KnowledgeApis';
import { ConnectionType, KnowledgeBase, Metrics, Mode } from './MockFiles';
import { Header as PageHeader, HeaderChild } from '@/common/components/Header';
import { useQueryClient } from 'react-query';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import SharepointPopup from './SharepointPopup';
import ConfluencePopup from './ConfluencePopup';
import VivantioPopup from './VivantioPopup';
import FreshServicePopup from './FreshServicePopup';
import { AssistantMainHeader, AssistantSubHeader } from '../BotHome/PortalElement';
import S3Popup from './S3Popup';
import SharepointCustomPopup from './SharepointCustomPopup';
import GoogleDrivePopup from './googleDrivePopup';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;
`;

const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

export function SharePoint(props: any) {
  const workspace: string = props.match.params.workspacename;
  const Connection: ConnectionType = props.match.params.connection;
  console.log(Connection, 'sharepointProps');
  // const { kbCategorydata } = useKBCategory(workspace);
  const [pageState, setpageState] = useState<Mode>(Mode.View);
  const [type, setType] = useState<ConnectionType>(ConnectionType.view);
  const [selectedID, setSelectedID] = useState<string>('');
  const [trainMode, setTrainMode] = useState<string>('');
  const { external, externalCategory, exCatResult } =
    useExternalCategory(workspace);
  const exCatArray: KnowledgeBase[] =
    Connection === 'sharepoint'
      ? exCatResult.SHAREPOINT_ONLINE
        ? exCatResult.SHAREPOINT_ONLINE
        : []
      : Connection === 'confluence'
      ? exCatResult.CONFLUENCE_ONLINE
        ? exCatResult.CONFLUENCE_ONLINE
        : []
      : Connection === 'vivantio'
      ? exCatResult.VIVANTIO
        ? exCatResult.VIVANTIO
        : []
      : Connection === 'freshservice'
      ? exCatResult.FRESHSERVICE
        ? exCatResult.FRESHSERVICE
        : []
      : Connection === 'amazons3'
      ? exCatResult.S3
        ? exCatResult.S3
        : []
      : Connection === 'googleDrive'
      ? exCatResult.GOOGLE_DRIVE
        ? exCatResult.GOOGLE_DRIVE
        : []
      : Connection === 'sharepointcustom'
      ? exCatResult.SHAREPOINT_CUSTOM
        ? exCatResult.SHAREPOINT_CUSTOM
        : []
      :[];
  const PageStateUpdate = () => {
    setpageState(Mode.View);
  };
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const totalScanned = (metrics: Metrics) => {
    const total =
      parseInt(metrics.DocumentsAdded) +
      parseInt(metrics.DocumentsFailed) +
      parseInt(metrics.DocumentsDeleted) +
      parseInt(metrics.DocumentsModified);
    return total < 10 ? 0 + total : total;
  };

  const onCancel = () => {
    setpageState(Mode.View);
    setTrainMode('');
    setError(false)
  };
  console.log(type,"type")
  const queryClient = useQueryClient();

  const { deleteConnMutate } = useDeleteConnection(
    () => {
      setpageState(Mode.View);
    },
    () => {
      setError(true);
    }
  );

  const statusCheck = (status: string) => {
    switch (status) {
      case 'CREATED':
        return 'Train';
      case 'DELETION_INITIATED':
        return 'Deleting';
      case 'SYNCING':
        return 'Training';
      case 'SYNCING_INDEXING':
        return 'Training';
      case 'FAILED':
        return 'Retry';
      case 'INCOMPLETE':
        return 'Retry';
      case 'CREATION_INITIATED':
        return 'Creating';
      default:
        return 'Train';
    }
  };

  const kbIcon = (conn: string) => {
    switch (conn) {
      case 'sharepoint':
        return <SharePointIcon />;
      case 'confluence':
        return <ConfluenceIcon />;
      case 'vivantio':
        return <VivantioIcon />;
      case 'freshservice':
        return <span><FreshServiceIcon /></span>;
      case 'amazons3':
        return <S3ConnectionIcon />
      case 'googleDrive':
        return <span style={{width:"16px"}}><GoogleDriveIcon  /></span>
      default:
        return <SharePointIcon />;
    }
  };

  const { trainMutate } = useTrainMode(
    () => {
      setpageState(Mode.View);
    },
    () => {
      setError(true);
    }
  );
  const TrainingMode = () => {
    const trainBody =
      Connection === 'vivantio'
        ? {
            type: 'VIVANTIO',
          }
        : Connection === 'freshservice'
        ? {
            type: 'FRESHSERVICE',
          }
        : Connection === 'amazons3'
        ? {
            type: 'S3',
          }
        : Connection === 'sharepointcustom'
        ?{
            type: 'SHAREPOINT_CUSTOM',
        }:
          {
            connector:
              Connection === 'sharepoint' ? 'SHAREPOINTV2' : 'CONFLUENCEV2',
            mode: trainMode,
          };

    trainMutate({ workspace, selectedID, trainBody, Connection });
  };
  const [searchWord, setSearchWord] = useState<string>('');
  const renderNodes =
    exCatResult && exCatArray && searchWord !== ''
      ? exCatArray.filter((micro) =>
          micro.name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : exCatArray;

  const titleName = (conn: string) => {
    switch (conn) {
      case 'sharepoint':
        return 'Microsoft SharePoint';
      case 'confluence':
        return 'Confluence';
      case 'vivantio':
        return 'Vivantio';
      case 'freshservice':
        return 'Freshservice';
      default:
        return 'Microsoft SharePoint';
    }
  };

  console.log(Connection,pageState,type , 'pageState');
  return (
    <div>
      <AssistantMainHeader>
      <>
        {!inputFocus ? (
                <span className="search_icon_svg knowai_search">
                  <input
                    type="search"
                    placeholder="Search"
                    value=""
                    onFocus={() => setInputFocus(true)}
                  />
                  <SearchIcon />
                </span>
              ) : (
                <span className="search_click">
                  <input
                    className="search_click"
                    type="search"
                    placeholder="Search"
                    value={searchWord}
                    autoFocus={true}
                    onChange={(e) => setSearchWord(e.target.value)}
                  />
                  <span
                    onClick={() => {
                      setSearchWord('');
                      setInputFocus(false);
                    }}
                  >
                    <CloseIcon />
                  </span>
                </span>
              )}
        </>
      </AssistantMainHeader>
      <AssistantSubHeader>
      <>
      {exCatArray && exCatArray.length < 5 && (
                      <Button
                        style={{ marginLeft: 10 }}
                        primary
                        onClick={() => {
                          setpageState(Mode.Add);
                          setType(Connection);
                        }}
                        className="website_link_btn"
                      >
                        <span className="add_teams_header">
                          <AddIcon />
                        </span>
                        <label style={{ top: '1.5px' }}>Add Connection</label>
                      </Button>
                  )}  
                  </>      
      </AssistantSubHeader>
      {/* <PageHeader transparentHeader>
        {[
          <>
            <HeaderChild type="first">
              <span
                className="buton_back inner_pages"
                onClick={() => window.history.back()}
              >
                <ArrowIcon />
                <span>Back</span>
              </span>
              <div className="indent_left">
                <div className="indent_title">{titleName(Connection)}</div>
              </div>
              
            </HeaderChild>
            <HeaderChild type="second">
                  {exCatArray && exCatArray.length < 5 && (
                      <Button
                        style={{ marginLeft: 10 }}
                        primary
                        onClick={() => {
                          setpageState(Mode.Add);
                          setType(Connection);
                        }}
                      >
                        <span className="add_teams_header">
                          <AddIcon />
                        </span>
                        <label style={{ top: '1.5px' }}>Add Connection</label>
                      </Button>
                  )}
            </HeaderChild>
          </>,
        ]}
      </PageHeader> */}
      {(pageState === 'view' && !exCatResult) || exCatArray.length === 0 ? (
        <>
          <div className='knowledge_ai_btn'>
            <SmallButton onClick={() => window.history.back()}>
              <span className="rotate_back_button">
                  <svg x="0px" y="0px" viewBox="0 0 31.5 31.5">
                      <path d="M21.2 5c-.4-.4-1.1-.4-1.6 0-.4.4-.4 1.1 0 1.6l8 8H1.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h26.6l-8 8c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l10-10c.4-.4.4-1.1 0-1.6L21.2 5z" />
                  </svg>{' '}
              </span>
              <label>Back</label>
            </SmallButton>
          </div>
          <section className="knowledge_container">
            <div className="knowledge_content">
              <div>
                <img src={knowledgeEmptyImg} alt="Knowledge Ai img" />
              </div>
              <div>
                <p>Generate answers from External Knowledge Base.</p>
                <p>Empty as wind. Connect your account.</p>
              </div>

              <Button
                style={{ fontSize: 14, padding: '6px 16px' }}
                className="btn btn-primary"
                onClick={() => {
                  setpageState(Mode.Add);
                  setType(Connection);
                }}
              >
                Add Connection
              </Button>
            </div>
            <div className="knowledge_video">
              <span>
                <VideoIcon />
              </span>
            </div>
          </section>
        </>
      ) : (
        <>
        <div className='knowledge_ai_btn'>
            <SmallButton onClick={() => window.history.back()}>
              <span className="rotate_back_button">
                  <svg x="0px" y="0px" viewBox="0 0 31.5 31.5">
                      <path d="M21.2 5c-.4-.4-1.1-.4-1.6 0-.4.4-.4 1.1 0 1.6l8 8H1.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h26.6l-8 8c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l10-10c.4-.4.4-1.1 0-1.6L21.2 5z" />
                  </svg>{' '}
              </span>
              <label>Back</label>
            </SmallButton>
          </div>
          <section className="entity_cards externalKB">
            <div className="col-md-12">
              <div className="row card_grid_view">
                {exCatArray &&
                  renderNodes.map((data, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                    {console.log(data.status,"datastatus")}
                      <div
                        className={
                          data.status === 'DELETION_INITIATED'
                            ? 'col-lg-3 col-md-3 col-sm-3 dialog_cards_layer deleting_box'
                            : 'col-lg-3 col-md-3 col-sm-3 dialog_cards_layer'
                        }
                      >
                        <div className="externalKB_menu_box">
                          {data.status !== 'DELETION_INITIATED' && (
                            <SmallButton
                              className={
                                data.status === 'DELETION_INITIATED' ||
                                data.syncHistory[0]?.status === 'SYNCING' ||
                                data.syncHistory[0]?.status ===
                                  'SYNCING_INDEXING' ||
                                data.status === 'CREATION_INITIATED'
                                  ? 'training_btn'
                                  : data.syncHistory[0]?.status === 'FAILED' ||
                                    data.syncHistory[0]?.status === 'INCOMPLETE'
                                  ? 'retry_btn'
                                  : 'train_btn'
                              }
                              primary
                              style={{ minWidth: 90 }}
                              disable={
                                data.status === 'DELETION_INITIATED' ||
                                data.status === 'CREATION_INITIATED' ||
                                data.syncHistory[0]?.status === 'SYNCING' ||
                                data.syncHistory[0]?.status ===
                                  'SYNCING_INDEXING'
                              }
                              onClick={() => {
                                if (
                                  (data.status !== 'DELETION_INITIATED' &&
                                    data.status !== 'CREATION_INITIATED' &&
                                    data.syncHistory[0]?.status !==
                                      'SYNCING') ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING_INDEXING'
                                ) {
                                  setpageState(Mode.Train);
                                  setSelectedID(data.id);
                                }
                              }}
                            >
                              <label>
                                {data.status === 'DELETION_INITIATED' ||
                                data.status === 'CREATION_INITIATED'
                                  ? statusCheck(data.status)
                                  : data.syncHistory.length === 0
                                  ? statusCheck(data.status)
                                  : statusCheck(data.syncHistory[0].status)}
                                {(data.status === 'DELETION_INITIATED' ||
                                  data.status === 'CREATION_INITIATED' ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING_INDEXING' ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING') && (
                                  <div className="publish_button_dialog btn_loader">
                                    <img src={ImgLoader} />
                                  </div>
                                )}
                                {data.status !== 'DELETION_INITIATED' &&
                                  data.status !== 'CREATION_INITIATED' &&
                                  data.syncHistory.length > 0 &&
                                  statusCheck(data.syncHistory[0].status) ===
                                    'Retry' && (
                                    <div className="retry_btn_icon">
                                      {/* <Tooltip
                                          className="target customTip"
                                          zIndex={10000}
                                          arrowSize={8}
                                          tagName="span"
                                          content={data.errorMessage}
                                          distance={5}
                                      > */}
                                      <WarningAlertIcon />

                                      {/* </Tooltip> */}
                                    </div>
                                  )}
                              </label>
                            </SmallButton>
                          )}
                          {data.status !== 'DELETION_INITIATED' &&
                            data.status !== 'CREATION_INITIATED' &&
                            data.syncHistory[0]?.status !== 'SYNCING' &&
                            data.syncHistory[0]?.status !==
                              'SYNCING_INDEXING' && (
                              <div className="externalKB_menu_tools">
                                <div
                                  onClick={() => {
                                    setpageState(Mode.Edit);
                                    setType(Connection);
                                    setSelectedID(data.id);
                                  }}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  onClick={() => {
                                    setSelectedID(data.id);
                                    setpageState(Mode.Delete);
                                  }}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            )}
                            { (data.syncHistory[0]?.status === 'SYNCING' ||
                            data.syncHistory[0]?.status ===
                              'SYNCING_INDEXING') && (
                              <div className="externalKB_menu_tools">
                                <div
                                  onClick={() => {
                                    setpageState(Mode.show);
                                    setType(Connection);
                                    setSelectedID(data.id);
                                  }}
                                >
                                  <EyeOpenIcon />
                                </div>
                              </div>
                            )}
                        </div>
                        <div
                          className="externalKB_title_box sharepoint_box"
                          onClick={() => {
                            if (
                              data.status !== 'DELETION_INITIATED' &&
                              data.status !== 'CREATION_INITIATED' &&
                              data.syncHistory[0]?.status !== 'SYNCING' &&
                              data.syncHistory[0]?.status !== 'SYNCING_INDEXING'
                            ) {
                              setpageState(Mode.Edit);
                              setType(Connection);
                              setSelectedID(data.id);
                            }
                          }}
                        >
                          <div className="externalKB_title">
                            <div className="externalKB_title_logo">
                              {Connection === 'confluence' ? (
                                <span>{kbIcon(Connection)}</span>
                              ) : (
                                <span>{kbIcon(Connection)}</span>
                              )}
                              {/* {data.type === 'SHAREPOINT_ONLINE' ?  <SharePointIcon /> : <span> <ConfluenceIcon /></span> } */}
                              {/* <SharePointIcon /> */}
                            </div>
                            <div className="externalKB_title_head">
                              <h4>{data.name === "Microsoft Sharepoint Custom" ? "Microsoft Sharepoint Online" : data.name}</h4>
                              <p>
                                <span>Language:</span>{' '}
                                <span>English (United States)</span>
                              </p>
                            </div>
                          </div>
                          <div className="externalKB_title_count_section flex">
                            <h5>Documentss</h5>
                            <div className="externalKB_title_count_box">
                              <div className="externalKB_title_count">
                                <h4>
                                  {data.syncHistory.length === 0
                                    ? 0
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1 &&
                                      data.syncHistory[1].metrics !== null
                                      ? parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsAdded
                                        ) < 10 &&
                                        parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsAdded
                                        ) > 0
                                        ? 0 +
                                          data.syncHistory[1].metrics
                                            ?.DocumentsAdded
                                        : data.syncHistory[1].metrics
                                            ?.DocumentsAdded
                                      : 0
                                    : data.syncHistory[0].metrics !== null
                                    ? parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsAdded
                                      ) < 10 &&
                                      parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsAdded
                                      ) > 0
                                      ? 0 +
                                        data.syncHistory[0].metrics
                                          ?.DocumentsAdded
                                      : data.syncHistory[0].metrics
                                          ?.DocumentsAdded
                                    : '0'}
                                </h4>
                                <p>Added</p>
                              </div>
                              <div className="externalKB_title_count">
                                <h4>
                                  {data.syncHistory.length === 0
                                    ? '0'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1 &&
                                      data.syncHistory[1].metrics !== null
                                      ? Connection === 'vivantio' || Connection === 'freshservice' ||
                                      Connection === 'amazons3'
                                        ? totalScanned(
                                            data.syncHistory[1].metrics
                                          )
                                        : parseInt(
                                            data.syncHistory[1].metrics
                                              ?.DocumentsScanned
                                          ) < 10 &&
                                          parseInt(
                                            data.syncHistory[1].metrics
                                              ?.DocumentsScanned
                                          ) > 0
                                        ? 0 +
                                          data.syncHistory[1].metrics
                                            ?.DocumentsScanned
                                        : data.syncHistory[1].metrics
                                            ?.DocumentsScanned
                                      : '0'
                                    : data.syncHistory[0].metrics !== null
                                    ? Connection === 'vivantio' || Connection === 'freshservice' ||
                                    Connection === 'amazons3'
                                      ? totalScanned(
                                          data.syncHistory[0].metrics
                                        )
                                      : parseInt(
                                          data.syncHistory[0].metrics
                                            ?.DocumentsScanned
                                        ) < 10 &&
                                        parseInt(
                                          data.syncHistory[0].metrics
                                            ?.DocumentsScanned
                                        ) > 0
                                      ? 0 +
                                        data.syncHistory[0].metrics
                                          ?.DocumentsScanned
                                      : data.syncHistory[0].metrics
                                          ?.DocumentsScanned
                                    : '0'}
                                </h4>
                                <p>Scanned</p>
                              </div>
                              <div className="externalKB_title_count">
                                <h4>
                                  {data.syncHistory.length === 0
                                    ? '0'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1 &&
                                      data.syncHistory[1].metrics !== null
                                      ? parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsModified
                                        ) < 10 &&
                                        parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsModified
                                        ) > 0
                                        ? 0 +
                                          data.syncHistory[1].metrics
                                            ?.DocumentsModified
                                        : data.syncHistory[1].metrics
                                            ?.DocumentsModified
                                      : '0'
                                    : data.syncHistory[0].metrics !== null
                                    ? parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsModified
                                      ) < 10 &&
                                      parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsModified
                                      ) > 0
                                      ? 0 +
                                        data.syncHistory[0].metrics
                                          ?.DocumentsModified
                                      : data.syncHistory[0].metrics
                                          ?.DocumentsModified
                                    : '0'}
                                </h4>
                                <p>Modified</p>
                              </div>
                              <div className="externalKB_title_count">
                                <h4>
                                  {data.syncHistory.length === 0
                                    ? '0'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1 &&
                                      data.syncHistory[1].metrics !== null
                                      ? parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsFailed
                                        ) < 10 &&
                                        parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsFailed
                                        ) > 0
                                        ? 0 +
                                          data.syncHistory[1].metrics
                                            ?.DocumentsFailed
                                        : data.syncHistory[1].metrics
                                            ?.DocumentsFailed
                                      : '0'
                                    : data.syncHistory[0].metrics !== null
                                    ? parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsFailed
                                      ) < 10 &&
                                      parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsFailed
                                      ) > 0
                                      ? 0 +
                                        data.syncHistory[0].metrics
                                          ?.DocumentsFailed
                                      : data.syncHistory[0].metrics
                                          ?.DocumentsFailed
                                    : '0'}
                                </h4>
                                <p>Failed</p>
                              </div>
                              <div className="externalKB_title_count">
                                <h4>
                                  {data.syncHistory.length === 0
                                    ? '0'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1 &&
                                      data.syncHistory[1].metrics !== null
                                      ? parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsDeleted
                                        ) < 10 &&
                                        parseInt(
                                          data.syncHistory[1].metrics
                                            ?.DocumentsDeleted
                                        ) > 0
                                        ? 0 +
                                          data.syncHistory[1].metrics
                                            ?.DocumentsDeleted
                                        : data.syncHistory[1].metrics
                                            ?.DocumentsDeleted
                                      : '0'
                                    : data.syncHistory[0].metrics !== null
                                    ? parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsDeleted
                                      ) < 10 &&
                                      parseInt(
                                        data.syncHistory[0].metrics
                                          ?.DocumentsDeleted
                                      ) > 0
                                      ? 0 +
                                        data.syncHistory[0].metrics
                                          ?.DocumentsDeleted
                                      : data.syncHistory[0].metrics
                                          ?.DocumentsDeleted
                                    : '0'}
                                </h4>
                                <p>Deleted</p>
                              </div>
                            </div>
                          </div>

                          <div className="externalKB_status_box flex">
                            <div className="externalKB_status_title flex">
                              <h5>Last Updated:</h5>
                              <div className="error_icon_wrapper_left">
                                <span className="entity_span">
                                  {data.updatedAt
                                    ? formatDateInCard(data.updatedAt)
                                    : '--'}
                                </span>
                                <span className="entity_span">|</span>
                                <span className="entity_span">
                                  {data.updatedAt
                                    ? formatAMPM(data.updatedAt)
                                    : '--'}
                                </span>
                              </div>
                            </div>
                            <div className="externalKB_status_title flex">
                              <h5>Last Trained:</h5>
                              <p>
                                <span className="entity_span">
                                  {data.syncHistory &&
                                  data.syncHistory.length === 0
                                    ? '--'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1
                                      ? formatDateInCard(
                                          data.syncHistory[1].endTime
                                        )
                                      : '--'
                                    : formatDateInCard(
                                        data.syncHistory[0].endTime
                                      )}
                                </span>
                                <span className="entity_span">|</span>
                                <span
                                  className="entity_span"
                                  style={{ color: '#355D8B' }}
                                >
                                  {data.syncHistory &&
                                  data.syncHistory.length === 0
                                    ? '--'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1
                                      ? formatAMPM(data.syncHistory[1].endTime)
                                      : '--'
                                    : formatAMPM(data.syncHistory[0].endTime)}
                                </span>
                              </p>
                            </div>
                            <div className="externalKB_status_title flex">
                              <h5>Trained History:</h5>
                              <p>
                                <u
                                  className={
                                    data.syncHistory.length === 0
                                      ? 'editor_btn_disabled'
                                      : ''
                                  }
                                  onClick={() =>
                                    props.history.push(
                                      `/workspace/${workspace}/assistant/knowledge/external-kb/${Connection}/${data.id}/syncsummary`
                                    )
                                  }
                                >
                                  View History
                                </u>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {data.status === 'DELETION_INITIATED' && (
                        <div className="deleting_btn">
                          <SmallButton>
                            <label>
                              Deleting{' '}
                              <span>(It will take more than 10 mins)</span>
                              <div className="publish_button_dialog btn_loader">
                                <img src={ImgLoader} />
                              </div>
                            </label>
                          </SmallButton>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </section>
          {pageState === 'train' &&
            (Connection === "sharepoint" || Connection === 'confluence' || Connection === 'googleDrive' ? (
              <Modal className="knowledge_container_popup externalKB">
                <section className="knowledge_container_section externalKB_popup">
                  <h4>Train Mode</h4>
                  <p>
                    Choose how you want to update your index when your data
                    source content changes.
                  </p>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FORCED_FULL_CRAWL')}
                    />
                    <div>
                      <h6>Full train</h6>
                      <p>
                        Train and index all contents in all entities, regardless
                        of the previous trained status.
                      </p>
                    </div>
                  </div>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FULL_CRAWL')}
                    />
                    <div>
                      <h6>Train new or modified content</h6>
                      <p>Only train new or modified content.</p>
                    </div>
                  </div>
                  {Connection === 'sharepoint' || Connection === 'googleDrive' && (
                    <div className="externalKB_option">
                      <input
                        type="radio"
                        name="trainmode"
                        className="option-input radio"
                        onClick={() => setTrainMode('CHANGE_LOG')}
                      />
                      <div>
                        <h6>Train new, modified, or deleted content</h6>
                        <p>Only train new, modified, deleted content.</p>
                      </div>
                    </div>
                  )}

                  <ButtonContainer
                    style={{
                      padding: 0,
                      justifyContent: 'flex-start',
                      marginTop: 5,
                    }}
                  >
                    <div className="popup_footer_button_container">
                      <Button
                        primary
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                          onCancel();
                          setpageState(Mode.View);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          trainMode !== '' ? '' : 'editor_btn_disabled'
                        }
                        type="button"
                        data-dismiss="modal"
                        onClick={() => TrainingMode()}
                      >
                        Train
                      </Button>
                      {error && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                    </div>
                  </ButtonContainer>
                </section>
              </Modal>
            ) : (
              <Modal className="knowledge_container_popup externalKB">
                <section className="knowledge_container_section externalKB_popup">
                  <h4>Train Mode</h4>
                  <p>
                    Choose how you want to update your index when your data
                    source content changes.
                  </p>
                  <div className="externalKB_option">
                    <input
                      type="radio"
                      name="trainmode"
                      className="option-input radio"
                      onClick={() => setTrainMode('FORCED_FULL_CRAWL')}
                    />
                    <div>
                      <h6>Full train</h6>
                      <p>
                        Train and index all contents in all entities, regardless
                        of the previous trained status.
                      </p>
                    </div>
                  </div>
                  <ButtonContainer
                    style={{
                      padding: 0,
                      justifyContent: 'flex-start',
                      marginTop: 5,
                    }}
                  >
                    <div className="popup_footer_button_container">
                      <Button
                        primary
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                          onCancel();
                          setpageState(Mode.View);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          trainMode !== '' ? '' : 'editor_btn_disabled'
                        }
                        type="button"
                        data-dismiss="modal"
                        onClick={() => TrainingMode()}
                      >
                        Train
                      </Button>
                      {error && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                    </div>
                  </ButtonContainer>
                </section>
              </Modal>
            ))}
          {pageState === 'delete' && (
            <Modal className="knowledge_container_popup externalKB delete_popup">
              <div className="info_icon">
                <InfoColorIcon />
              </div>
              <h2>Remove Connection?</h2>
              <p>Connection will be removed along with the trained data and can't be undone.</p>{' '}
              <ButtonContainer
                className="knowledge_ms_btn"
                style={{ padding: 0, marginTop: 5 }}
              >
                <div className="popup_footer_button_container">
                  <Button
                    primary
                    type="button"
                    data-dismiss="modal"
                    onClick={() => {
                      setpageState(Mode.View);
                      onCancel()
                      // setState(initialState);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={() => deleteConnMutate({ workspace, selectedID })}
                  >
                    Yes
                  </Button>
                  {error && (
                    <span className="connect_account_failed">
                      <div className="error_input_show error_input_show_add teams_page_alert">
                        <WarningAlertIcon />
                        <span className="error_menu_hvr">
                          Something went wrong
                        </span>
                      </div>
                    </span>
                  )}
                </div>
              </ButtonContainer>
            </Modal>
          )}
        </>
      )}

      {type === 'sharepoint' && (
        <SharepointPopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === 'confluence' && (
        <ConfluencePopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === 'vivantio' && (
        <VivantioPopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === 'freshservice' && (
        <FreshServicePopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === 'amazons3' && (
        <S3Popup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === 'sharepointcustom' && (
        <SharepointCustomPopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
      {type === "googleDrive" &&(
         <GoogleDrivePopup
          pageState={pageState}
          PageStateUpdate={PageStateUpdate}
          workspace={workspace}
          Connection={Connection}
          exCatArray={exCatArray}
          selectedID={selectedID}
        />
      )}
    </div>
  );
}
