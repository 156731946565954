import * as R from 'ramda'

export const validateEmail = (email: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

export const sortAnArrayByDate = (array: any, key: string) => {
    const diff = function(a: any, b: any) {
        let firstDate = a[key].split('Z')[0] as string
        let nextDate = b[key].split('Z')[0] as string
        return new Date(firstDate).getTime() - new Date(nextDate).getTime()
    }
    return R.sort(diff, array).reverse()
}


export function sortAnArrayByName<T>(array: T[], key: string):T[] {
    const sortNamesAsc = R.sortBy(R.compose(R.toLower ,R.prop(key))) // alphabetically
    return sortNamesAsc(array)
}