export enum Action {
    View,
    ErrorPage,
    Loading
}

export enum RequestType {
    AllFlows = 'AllFlows',
    IndividualFlow = 'InduvidualFlow'
}

export type ChartDataT = {
    failed: number
    success: number
    date: string
}

export type ApprovalChartDataT = {
    approved: number
    waiting_for_approval: number
    rejected: number
    date: string
}

export type MostandLeastExecuted = {
    count: string
    id: string
    name: string
}

export type ExecutionDetailsT = {
    automationId: string
    automationName: string
    clientName: string
    coversationId: string
    executionId: string
    executionStartTime: string
    executionStatus: string
    executionTime: string
}

export type AutomationListT = {
    id: string
    name: string
    totalExecutions: string
}

export type LogsAutomationListT = {
    id: string
    name: string
}

enum ResponseTypesT {
    success = 'success',
    error = 'error'
}

export type AnalyticsSuccessResponseT = {
    workflowChartDetails: ChartDataT[]
    approvalChatDetails: ApprovalChartDataT[]
    totalActiveWorkflow: number
    totalWorkflowExecutionCount: number
    totalWorkflowSuccessCount: number
    totalWorkflowFailureCount: number
    mostUsedWorkflows: MostandLeastExecuted[]
    leastUsedWorkflows: MostandLeastExecuted[]
    totalApproval: number
    waitingForApproval: number
    mostUsedApproval: MostandLeastExecuted[]
    leastUsedApproval: MostandLeastExecuted[]
}

export type AnalyticsErrorResponseT = {
    type: ResponseTypesT.error
    error: string
}

export const AnalyticsSuccessResponse: AnalyticsSuccessResponseT = {
    workflowChartDetails: [],
    approvalChatDetails: [],
    totalActiveWorkflow: 0,
    totalWorkflowExecutionCount: 0,
    totalWorkflowSuccessCount: 0,
    totalWorkflowFailureCount: 0,
    mostUsedWorkflows: [],
    leastUsedWorkflows: [],
    totalApproval: 0,
    waitingForApproval: 0,
    mostUsedApproval: [],
    leastUsedApproval: []
}

export type analyticsT = {
    serial_no: string
    execution_id: string
    status: string
    automation_id: string
    platform: string
    conversation_id: string
    bot_id: string
    start_time: string
    end_time: string
    automation_name: string
    automation_type: string
    executionTime: string
    executed_by: string | null
}
export type LogsResponseT = {
    analytics: analyticsT[]
    automationList: LogsAutomationListT[]
    totalExecutionCount: number
}

export const SampleLogsResponse: LogsResponseT = {
    analytics: [],
    automationList: [],
    totalExecutionCount: 0
}

export type ReqT = {
    limit: number
    page: number
    sortOrder: 'ascending' | 'descending'
    sortColumn: 'executionStatus' | 'clientName' | 'executionStartTime' | 'automationName'
    timeInterval: string
    workspace: string
}

type FilterT = {
    field: string
    value: string
}

type ReqFlowT = {
    automationId?: string
    filter?: FilterT[]
}

export type AllRequestTypeT = {
    type: RequestType.AllFlows
    req: ReqT
}
export type IndividualRequestTypeT = {
    type: RequestType.IndividualFlow
    req: ReqT & ReqFlowT
}

export const All: AllRequestTypeT = {
    type: RequestType.AllFlows,
    req: {
        limit: 50,
        page: 1,
        sortOrder: 'descending',
        sortColumn: 'executionStartTime',
        timeInterval: 'today',
        workspace: 'ghayathri'
    }
}

export const Individual: IndividualRequestTypeT = {
    type: RequestType.IndividualFlow,
    req: {
        limit: 50,
        page: 1,
        sortOrder: 'descending',
        sortColumn: 'executionStatus',
        timeInterval: 'last 7 days',
        workspace: ''
    }
}

export type allFlowsT = {
    type: 'allFlows'
}

export type RequestModes = AllRequestTypeT | IndividualRequestTypeT
