/* TODO
1) Events as SUM types
2) Model actions
    Assign Actions
    Redirection actions
    UI actions
3) Entry actions needed? Redirection is a use case
4) Transition should push UI events and state
5) Model context to store history
6) React integration
7) Multi Statemachine to model induvidual journeys
*/

import Axios from 'axios';

import { postJson, userJourneyWithWorkspace } from '@/common/utils/api-utils';
import { getProductId } from '@/common/utils/utils';

const qs = require('qs');
// import { workspace } from '@/Integrations/Widget';

export const makeEventEntry = (
  workspace: string,
  { event, refId }: { event: string; refId: string }
) => {
  console.log('callef', '/event/update', { event, refId })
  if (getProductId() !== 'AUTOMATE') {
  return postJson(
    userJourneyWithWorkspace(workspace, '/journey/bot/event/update')
  )({
    event: event,
    botId: refId,
  })
    .then((_) => {
      console.log('Event updated');
    })
    .catch((_) => {
      console.log('Event update failed');
    });
  }
  return Promise.resolve('DUMMY PROMISE').then((x) => console.log('x'));
};

const makeActiveCampaignEntry = (
  workspace: string,
  { event, refId }: { event: string; refId: string }
) => {
  const eventName =
    event === 'EVENT::BOT::CREATED'
      ? 'Test - Create Bot'
      : 'Test - Create Workflow';
  return Axios.post(
    'https://trackcmp.net/event',
    qs.stringify({
      actId: '91145464',
      key: '4d9a2a2c6e47fd214a8eb0cc20c5a1acec5426e9',
      event: eventName,
      eventdata: {
        workspace,
      },
      visit: { email: 'prasand.r@workativ.com' }, //gave the values directly for testing
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  ).then(function (response) {
    console.log(response);
  });
  // console.log('callef', userJourneyWithWorkspace(workspace, '/journey/bot/event/update'), { event, refId })
  // return postJson(userJourneyWithWorkspace(workspace, '/journey/bot/event/update'))({
  //     event: event,
  //     botId: refId
  // })
  //     .then(_ => {
  //         console.log('Event updated')
  //     })
  //     .catch(_ => {
  //         console.log('Event update failed')
  //     })
};

type KnowledgeEvent = 
  | 'EVENT::KNOWLEDGE_BASE::CONNECTED'

type GenericEvents =
  | 'EVENT::BOT::CREATED'
  | 'EVENT::BOT::DOWNLOAD::INITIATED'
  | 'EVENT::BOT::DOWNLOADED'
  | 'EVENT::BOT::DOWNLOAD::CANCELLED'
  | 'EVENT::JOURNEY::DONE'
  | 'EVENT::DIALOG::CREATED'
  | 'EVENT::FAQ::CREATED'
  | 'EVENT::WORKFLOW::CREATED'
  | 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE';

type DialogGenericEvents =
  | 'EVENT::DIALOG::WELCOME_CLICKED'
  | 'EVENT::DIALOG::FALLBACK_CLICKED'
  | 'EVENT::DIALOG::TRYME_CLICKED'
  | 'EVENT::DIALOG::TRYME_CLOSED'
  | 'EVENT::DIALOG::PUBLISH_CLICKED'
  | 'EVENT::DIALOG::END_JOURNEY';

type WorkflowGenericEvents =
  | 'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED'
  | 'EVENT::WORKFLOW::ACTION_SAVED'
  | 'EVENT::WORKFLOW::TO_BE_TESTED'
  | 'EVENT::WORKFLOW::TO_BE_ACTIVATED'
  | 'EVENT::WORKFLOW::TESTED'
  | 'EVENT::WORKFLOW::ACTIVATED'
  | 'EVENT::WORKFLOW::END_JOURNEY';

type BotSpecificEvents =
  | 'EVENT::BOT::CREATED'
  | 'EVENT::FAQ::CREATED'
  | 'EVENT::BOT::TEST'
  | 'EVENT::BOT::DOWNLOADED';

type MainJourney =
  | 'JOURNEY::NOT_STARTED'
  | 'JOURNEY::BOT_CREATED'
  | 'JOURNEY::BOT::DOWNLOAD::INITIATED'
  | 'JOURNEY::BOT::DOWNLOADED'
  | 'JOURNEY::DONE'
  | 'DIALOG::CREATED_DIALOG'
  | 'FAQ::CREATED_FAQ'
  | 'WORKFLOW::CREATED_WORKFLOW'
  | 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE';

type UserBotEventContext = {
  systemContext: {
    workspace: string;
  };
  executionContext: Record<string, any>;
};

type ActionT<
  TContext extends UserBotEventContext,
  TEvent extends EventObject
> =
  | {
      tag: 'CONTEXT::ASSIGN';
      type: 'pure';
      name: string;
      assignment: (
        current: TContext['executionContext'],
        event: TEvent
      ) => TContext['executionContext'];
    }
  | {
      tag: 'URL::REDIRECT';
      type: 'side-effect';
      name: string;
      url: (event: TEvent, meta?: any) => string;
    }
  | {
      tag: 'SHOW::POPUP';
      type: 'ui';
      name: string;
      text: string;
    }
  | {
      tag: 'SHOW::HIGHLIGHT';
      type: 'ui';
      name: string;
    };

type FAQJourney = 'FAQ::CREATED_FAQ_SUB' | 'FAQ::JOURNEY_STARTED';
type DialogJourney =
  | 'DIALOG::JOURNEY_STARTED'
  | 'DIALOG::CREATED_DIALOG_SUB'
  | 'DIALOG::SHOWN_WELCOME_INFO'
  | 'DIALOG::SHOWN_FALLBACK_INFO'
  | 'DIALOG::SHOWN_TRYME_MESSAGE'
  | 'DIALOG::SHOWN_PUBLISH_MESSAGE'
  | 'DIALOG::SHOWN_CHAT_CHANNEL_POPUP'
  | 'DIALOG::JOURNEY_DONE'
  | FAQJourney;
// | 'DIALOG::SHOW_TRYME_INFO'

type WorkflowJourney =
  | 'WORKFLOW::JOURNEY_STARTED'
  | 'WORKFLOW::CREATED_WORKFLOW_SUB'
  | 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE_SUB'
  | 'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED'
  | 'WORKFLOW::ACTION_SAVED'
  | 'WORKFLOW::TO_BE_TESTED'
  | 'WORKFLOW::TESTED'
  | 'WORKFLOW::TO_BE_ACTIVATED'
  | 'WORKFLOW::ACTIVATED'
  | 'WORKFLOW::JOURNEY_DONE';

type AllUserStates = MainJourney | DialogJourney | WorkflowJourney;

const InitEvent = { type: 'JOURNEY::NOT_STARTED' };

const ASSIGN_ACTION = 'CONTEXT::ASSIGN';

type EventObject =
  | {
      tag: 'GENERIC';
      type: GenericEvents;
      journeyType: 'Main' | 'Dialog' | 'Workflow' | 'FAQ';
      refId: string;
    }
  | {
      tag: 'BOT_EVENT';
      botId: string;
      botName: string;
      type: BotSpecificEvents;
      journeyType: 'Main';
    }
  | {
      tag: 'DIALOG_GENERIC_EVENT';
      type: DialogGenericEvents;
      journeyType: 'Dialog';
      botId: string;
    }
  | {
      tag: 'WORKFLOW_GENERIC_EVENT';
      type: WorkflowGenericEvents;
      journeyType: 'Workflow';
    } 
  | {
      tag: 'KNOWLEDGE_EVENT';
      type: KnowledgeEvent;
      journeyType: 'Knowledge';
      botId: string;
    }

type JourneyStates =
  | { type: 'MAIN'; JOURNEY: MainJourney }
  | {
      type: 'SUB';
      DIALOG: DialogJourney;
      WORKFLOW: WorkflowJourney;
      FAQ: FAQJourney;
    };

function toArray<T>(item: T | T[] | undefined): T[] {
  return item === undefined ? [] : ([] as T[]).concat(item);
}

interface JourneyWithContext<TContext extends UserBotEventContext> {
  value: JourneyStates;
  context: TContext;
}

interface State<
  TContext extends UserBotEventContext,
  TEvent extends EventObject
> {
  value: JourneyWithContext<TContext>['value'];
  context: TContext;
  actions: ActionT<TContext, TEvent>[];
}

type Transition<
  TContext extends UserBotEventContext,
  TEvent extends EventObject
> = {
  target: AllUserStates;
  actions?: Array<ActionT<TContext, TEvent>>;
};

type StateConfig<
  TContext extends UserBotEventContext,
  TEvent extends EventObject
> = {
  on?: {
    [K in TEvent['type']]?: Transition<
      TContext,
      TEvent extends { type: K } ? TEvent : never
    >;
  };
  exit?: Array<ActionT<TContext, TEvent>>;
  entry?: Array<ActionT<TContext, TEvent>>;
};

type JourneyStateConfig<
  TContext extends UserBotEventContext,
  TEvent extends EventObject
> =
  | { type: 'MAIN'; JOURNEY: StateConfig<TContext, TEvent> }
  | {
      type: 'SUB';
      DIALOG: StateConfig<TContext, TEvent>;
      WORKFLOW: StateConfig<TContext, TEvent>;
      FAQ: StateConfig<TContext, TEvent>;
    };

interface Config<
  TContext extends UserBotEventContext,
  TEvent extends EventObject,
  TState extends JourneyWithContext<TContext>
> {
  initial: JourneyStates;
  context: TContext;
  states: {
    [key in AllUserStates]: StateConfig<TContext, TEvent>;
  };
}

function handleActions<
  TContext extends UserBotEventContext,
  TEvent extends EventObject = EventObject
>(
  actions: Array<ActionT<TContext, TEvent>>,
  context: TContext,
  eventObject: TEvent
): [Array<ActionT<TContext, TEvent>>, TContext, boolean] {
  const nextContext = context;

  let assigned = false;

  const effectfulActions = actions.filter((action) => {
    if (action.type === 'pure') {
      return false;
    }
    return true;
  });

  const pureActions = actions.filter((action) => {
    if (action.type === 'pure') {
      return true;
    }
    return false;
  });

  const assignedContext = pureActions.reduce(
    (executionContext, currentAction) => {
      if (currentAction.tag === 'CONTEXT::ASSIGN') {
        assigned = true;
        // // console.log('newExecutionContext', executionContext)
        const newExecutionContext = currentAction.assignment(
          executionContext,
          eventObject
        );
        return newExecutionContext;
      }

      return executionContext;
    },
    nextContext.executionContext
  );

  return [
    effectfulActions,
    { ...context, executionContext: { ...assignedContext } },
    assigned,
  ];
}

interface Machine<
  TContext extends UserBotEventContext,
  TEvent extends EventObject,
  TState extends JourneyWithContext<TContext>
> {
  config: Config<TContext, TEvent, TState>;
  initialState: State<TContext, TEvent>;
  transition: (
    state: State<TContext, TEvent>,
    event: TEvent
  ) => State<TContext, TEvent>;
}

export function createMachine<
  TContext extends UserBotEventContext,
  TEvent extends EventObject = EventObject,
  TState extends JourneyWithContext<TContext> = {
    value: any;
    context: TContext;
  }
>(
  fsmConfig: Config<TContext, TEvent, TState>
): Machine<TContext, TEvent, TState> {
  const [initialActions, initialContext] = handleActions(
    [],
    fsmConfig.context,
    InitEvent as TEvent
  );

  const machine = {
    config: fsmConfig,
    _options: {},
    initialState: {
      value: fsmConfig.initial,
      actions: initialActions,
      context: initialContext,
    },
    transition: (
      state: State<TContext, TEvent>,
      event: TEvent
    ): State<TContext, TEvent> => {
      // console.log('transtion ::::: ', state, event)
      const evT = event as EventObject;

      if (evT.type === 'EVENT::BOT::CREATED' && evT.tag == 'BOT_EVENT') {
        // console.log('called 1')
        const botId = evT.botId ? evT.botId : 'test';
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, {
          event: 'EVENT::BOT::CREATED',
          refId: botId,
        });
        // makeActiveCampaignEntry(workspace, { event: 'EVENT::BOT::CREATED', refId: botId })
      }
      if (evT.type === 'EVENT::DIALOG::CREATED' && evT.tag == 'GENERIC') {
        // console.log('called 2')
        const botId = evT.refId;
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
      }
      if (evT.type === 'EVENT::FAQ::CREATED' && evT.tag == 'GENERIC') {
        // console.log('called 3')
        const botId = evT.refId;
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
      }
      if (evT.type === 'EVENT::WORKFLOW::CREATED' && evT.tag == 'GENERIC') {
        // console.log('called 4')
        const botId = evT.refId;
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
        // makeActiveCampaignEntry(workspace, { event: evT.type, refId: botId })
      }
      if (
        evT.tag === 'DIALOG_GENERIC_EVENT' &&
        evT.type === 'EVENT::DIALOG::TRYME_CLOSED'
      ) {
        // console.log('called 5')
        const botId = evT.botId;
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, {
          event: 'EVENT::DIALOG::TESTED',
          refId: botId,
        });
      }
      if (
        evT.type === 'EVENT::WORKFLOW::ACTIVATED' &&
        evT.tag === 'WORKFLOW_GENERIC_EVENT'
      ) {
        // console.log('called 4')
        const botId = Math.random().toString();
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
        // makeActiveCampaignEntry(workspace, { event: evT.type, refId: botId })
      }
      if (
        evT.type === 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE' &&
        evT.tag === 'GENERIC'
      ) {
        // console.log('called 4')
        const botId = Math.random().toString();
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
        // makeActiveCampaignEntry(workspace, { event: evT.type, refId: botId })
      }
      if (evT.type === 'EVENT::BOT::DOWNLOADED' && evT.tag == 'BOT_EVENT') {
        // console.log('called 4')
        const botId = evT.botId;
        const workspace = state.context.systemContext.workspace;
        makeEventEntry(workspace, { event: evT.type, refId: botId });
        // makeActiveCampaignEntry(workspace, { event: evT.type, refId: botId })
      }
      // if (event.type == 'EVENT::BOT::CREATED' && event.tag == 'GENERIC') {
      //     const botId = event.refId
      //     const workspace = state.context.systemContext.workspace
      // }

      const { value, context } = state;
      const mergedStateConfig: JourneyStateConfig<TContext, TEvent> =
        value.type === 'MAIN'
          ? {
              type: 'MAIN',
              JOURNEY: fsmConfig.states[value.JOURNEY],
            }
          : {
              type: 'SUB',
              DIALOG: fsmConfig.states[value.DIALOG],
              WORKFLOW: fsmConfig.states[value.WORKFLOW],
              FAQ: fsmConfig.states[value.FAQ],
            };
      // // console.log('test', mergedStateConfig.type, event.journeyType === 'Dialog')

      if (mergedStateConfig.type === 'MAIN') {
        // // console.log('1 ::: IN MAIN JOURNEY')
        const stateConfig = mergedStateConfig['JOURNEY'];
        // // console.log('2 ::: IN SUB JOURNEY :: JOURNEY', mergedStateConfig)

        if (stateConfig.on) {
          const transitions: Array<Transition<TContext, TEvent>> = toArray(
            stateConfig.on[event.type]!
          );

          for (const transition of transitions) {
            if (transition === undefined) {
              throw new Error(`UnHandled ::: Empty Transition`);
            }

            const { target, actions = [] } = transition;

            const isTargetless = target === undefined;

            const nextStateValue = target
              ? target
              : value.type === 'MAIN'
              ? value.JOURNEY
              : value.DIALOG;
            const nextStateConfig = fsmConfig.states[nextStateValue];

            const allActions = isTargetless
              ? toArray(actions)
              : ([] as ActionT<TContext, TEvent>[])
                  .concat(
                    stateConfig.exit as ActionT<TContext, TEvent>[],
                    actions,
                    nextStateConfig.entry as ActionT<TContext, TEvent>[]
                  )
                  .filter((a) => a);

            const [nonAssignActions, nextContext] = handleActions(
              allActions,
              context,
              event
            );

            const resolvedTarget = target as unknown as MainJourney;
            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : resolved', resolvedTarget)
            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : resolved', resolvedTarget)
            nextContext.executionContext['pendingActions'] = nonAssignActions;
            if (event.journeyType === 'Dialog' || event.journeyType === 'FAQ') {
              const resolvedTarget = target as unknown as DialogJourney;

              // console.log('1.1 ::: IN MAIN JOURNEY :: Dialog', resolvedTarget, nonAssignActions)
              return {
                value: {
                  type: 'SUB',
                  DIALOG: resolvedTarget,
                  WORKFLOW: 'WORKFLOW::JOURNEY_STARTED',
                  FAQ: 'FAQ::JOURNEY_STARTED',
                } as JourneyStates,
                context: nextContext,
                actions: nonAssignActions,
              };
            }
            // if (event.journeyType === 'FAQ') {
            //     const resolvedTarget = (target as unknown) as DialogJourney
            //     // console.log('1.1 ::: IN MAIN JOURNEY :: Dialog', resolvedTarget, nonAssignActions)
            //     return {
            //         value: {
            //             type: 'SUB',
            //             DIALOG: 'DIALOG::JOURNEY_STARTED',
            //             WORKFLOW: 'WORKFLOW::JOURNEY_STARTED',
            //             FAQ: resolvedTarget
            //         } as JourneyStates,
            //         context: nextContext,
            //         actions: nonAssignActions
            //     }
            // }
            if (event.journeyType === 'Workflow') {
              const resolvedTarget = target as unknown as WorkflowJourney;
              // console.log('1.1 ::: IN MAIN JOURNEY :: Dialog', resolvedTarget, nonAssignActions)
              return {
                value: {
                  type: 'SUB',
                  DIALOG: 'DIALOG::JOURNEY_STARTED',
                  WORKFLOW: resolvedTarget,
                  FAQ: 'FAQ::JOURNEY_STARTED',
                } as JourneyStates,
                context: nextContext,
                actions: nonAssignActions,
              };
            }
            // console.log('1.2 ::: IN MAIN JOURNEY :: Default')

            return {
              value: { type: 'MAIN', JOURNEY: resolvedTarget } as JourneyStates,
              context: nextContext,
              actions: nonAssignActions,
            };
          }
        }
      } else if (
        mergedStateConfig.type === 'SUB' &&
        event.journeyType === 'Dialog'
      ) {
        const stateConfig = mergedStateConfig['DIALOG'];
        // console.log('2 ::: IN SUB JOURNEY :: DIALOG', mergedStateConfig)

        if (stateConfig && stateConfig.on) {
          const transitions: Array<Transition<TContext, TEvent>> = toArray(
            stateConfig.on[event.type]!
          );
          for (const transition of transitions) {
            if (transition === undefined) {
              throw new Error(`UnHandled ::: Empty Transition`);
            }

            const { target, actions = [] } = transition;

            const isTargetless = target === undefined;

            const nextStateValue = target
              ? target
              : value.type === 'MAIN'
              ? value.JOURNEY
              : value.DIALOG;
            const nextStateConfig = fsmConfig.states[nextStateValue];

            const allActions = isTargetless
              ? toArray(actions)
              : ([] as ActionT<TContext, TEvent>[])
                  .concat(
                    stateConfig.exit as ActionT<TContext, TEvent>[],
                    actions,
                    nextStateConfig.entry as ActionT<TContext, TEvent>[]
                  )
                  .filter((a) => a);

            const [nonAssignActions, nextContext] = handleActions(
              allActions,
              context,
              event
            );

            const resolvedTarget = target as unknown as DialogJourney;
            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : resolved', resolvedTarget)
            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : resolved', resolvedTarget)
            nextContext.executionContext['pendingActions'] = nonAssignActions;
            return {
              value: {
                type: 'SUB',
                WORKFLOW: value['WORKFLOW'],
                FAQ: value['FAQ'],
                DIALOG: resolvedTarget,
              } as JourneyStates,
              context: nextContext,
              actions: nonAssignActions,
            };
          }
        }
      } else if (
        mergedStateConfig.type === 'SUB' &&
        event.journeyType === 'Workflow'
      ) {
        // // console.log('2 ::: IN SUB JOURNEY :: Default')
        const stateConfig = mergedStateConfig['WORKFLOW'];
        // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW', mergedStateConfig, event)

        if (stateConfig && stateConfig.on) {
          const transitions: Array<Transition<TContext, TEvent>> = toArray(
            stateConfig.on[event.type]!
          );
          for (const transition of transitions) {
            if (transition === undefined) {
              throw new Error(`UnHandled ::: Empty Transition`);
            }

            const { target, actions = [] } = transition;

            const isTargetless = target === undefined;

            const nextStateValue = target
              ? target
              : value.type === 'MAIN'
              ? value.JOURNEY
              : value.WORKFLOW;
            const nextStateConfig = fsmConfig.states[nextStateValue];

            const allActions = isTargetless
              ? toArray(actions)
              : ([] as ActionT<TContext, TEvent>[])
                  .concat(
                    stateConfig.exit as ActionT<TContext, TEvent>[],
                    actions,
                    nextStateConfig.entry as ActionT<TContext, TEvent>[]
                  )
                  .filter((a) => a);

            const [nonAssignActions, nextContext] = handleActions(
              allActions,
              context,
              event
            );

            const resolvedTarget = target as unknown as WorkflowJourney;

            nextContext.executionContext['pendingActions'] = nonAssignActions;

            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : actions', nonAssignActions, resolvedTarget)

            return {
              value: {
                type: 'SUB',
                DIALOG: value['DIALOG'],
                FAQ: value['FAQ'],
                WORKFLOW: resolvedTarget,
              } as JourneyStates,
              context: nextContext,
              actions: nonAssignActions,
            };
          }
        }
      } else if (
        mergedStateConfig.type === 'SUB' &&
        event.journeyType === 'FAQ'
      ) {
        // // console.log('2 ::: IN SUB JOURNEY :: Default')
        const stateConfig = mergedStateConfig['DIALOG'];
        // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW', mergedStateConfig)

        if (stateConfig && stateConfig.on) {
          const transitions: Array<Transition<TContext, TEvent>> = toArray(
            stateConfig.on[event.type]!
          );
          for (const transition of transitions) {
            if (transition === undefined) {
              throw new Error(`UnHandled ::: Empty Transition`);
            }

            const { target, actions = [] } = transition;

            const isTargetless = target === undefined;

            const nextStateValue = target
              ? target
              : value.type === 'MAIN'
              ? value.JOURNEY
              : value.DIALOG;
            const nextStateConfig = fsmConfig.states[nextStateValue];

            const allActions = isTargetless
              ? toArray(actions)
              : ([] as ActionT<TContext, TEvent>[])
                  .concat(
                    stateConfig.exit as ActionT<TContext, TEvent>[],
                    actions,
                    nextStateConfig.entry as ActionT<TContext, TEvent>[]
                  )
                  .filter((a) => a);

            const [nonAssignActions, nextContext] = handleActions(
              allActions,
              context,
              event
            );

            const resolvedTarget = target as unknown as DialogJourney;
            nextContext.executionContext['pendingActions'] = nonAssignActions;
            // console.log('2 ::: IN SUB JOURNEY :: WORKFLOW : resolved', resolvedTarget)

            return {
              value: {
                type: 'SUB',
                DIALOG: resolvedTarget,
                WORKFLOW: value['WORKFLOW'],
                FAQ: resolvedTarget,
              } as JourneyStates,
              context: nextContext,
              actions: nonAssignActions,
            };
          }
        }
      }

      console.log('no valid transition');

      return state;

      // throw new Error(`UNHANDLED ::: SUB JOURNEYS`)
    },
  };
  return machine;
}

interface InterpretedMachine<
  TContext extends UserBotEventContext,
  TEvent extends EventObject,
  TState extends JourneyWithContext<TContext>
> {
  send: (event: TEvent) => void;
  start: (
    initialState: JourneyWithContext<TContext>
  ) => InterpretedMachine<TContext, TEvent, TState>;
  stop: () => InterpretedMachine<TContext, TEvent, TState>;
  readonly state: () => State<TContext, TEvent>;
  subscribe: (listener: StateListener<State<TContext, TEvent>>) => {
    unsubscribe: () => void;
  };
}

type StateListener<T extends State<any, any>> = (state: T) => void;

const executeSideEffects = <
  TContext extends UserBotEventContext,
  TEvent extends EventObject = any,
  TState extends JourneyWithContext<TContext> = {
    value: any;
    context: TContext;
  }
>(
  state: State<TContext, TEvent>,
  event: TEvent
) => {
  // console.log(executeSideEffects, state.actions)
  state.actions
    .filter((action) => action.type !== 'ui')
    .forEach((action) => {
      if (action.tag === 'URL::REDIRECT') {
        if (action.url) {
          const urlToRedirect = action.url(event, state.context);
          // setTimeout(() => {
          //   window.location.href = urlToRedirect;
          // }, 2000);
        }

        // // console.log('URL::', urlToRedirect, state.context.systemContext.workspace)
      }
    });
};

function interpret<
  TContext extends UserBotEventContext,
  TState extends JourneyWithContext<TContext>,
  TEvent extends EventObject = EventObject
>(
  machine: Machine<TContext, TEvent, TState>
): InterpretedMachine<TContext, TEvent, TState> {
  let state = machine.initialState;
  const actions = [];
  const listeners = new Set<StateListener<typeof state>>();

  const service = {
    _machine: machine,
    send: (event: TEvent): void => {
      console.log('Received event ::: ', JSON.stringify(event))
      // if (getProductId() !== 'AUTOMATE') {
      state = machine.transition(state, event);
      console.log('Received event ::: ', JSON.stringify(event),state)
      executeSideEffects(state, event);
      const doneUIEnvents = Object.keys(state.context.executionContext);
      state.actions = state.actions
        .filter(
          (action) =>
            action.type === 'ui' &&
            !doneUIEnvents.includes(action.name) &&
            action !== undefined
        )
        .concat(state.context.executionContext['pendingActions']);
      listeners.forEach((listener) => listener(state));
      // }
    },
    subscribe: (listener: StateListener<typeof state>) => {
      listeners.add(listener);
      listener(state);

      return {
        unsubscribe: () => listeners.delete(listener),
      };
    },
    start: (initialState: JourneyWithContext<TContext>) => {
      if (initialState) {
        state = {
          value: initialState.value,
          actions: []
            .concat(initialState.context.executionContext['pendingActions'])
            .filter((action) => action !== undefined),
          context: initialState.context,
        };
        return service;
      }
      throw Error('UNHANDLED :: NO INITIAL STATE');
    },
    stop: () => {
      // status = InterpreterStatus.Stopped;
      listeners.clear();
      return service;
    },
    state() {
      return state;
    },
  };

  return service;
}

export const createUserJourney = (workspacename: string) => {
  const journey = createMachine({
    initial: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
    context: {
      systemContext: {
        workspace: workspacename,
      },
      executionContext: {},
    },
    states: {
      'JOURNEY::NOT_STARTED': {
        on: {
          'EVENT::BOT::CREATED': {
            target: 'JOURNEY::BOT_CREATED',
            actions: [
              {
                tag: 'URL::REDIRECT',
                type: 'side-effect',
                name: 'redirect-to-bot',
                url: (event: any, meta: any) => {
                  // console.log('event', event)
                  const workspace = meta.systemContext.workspace;
                  const botName = event.botName;
                  const botId = event.botId;
                  return `/workspace/${workspace}/assistant/${botName}/conversations`;

                  // return `/workspace/${workspace}/assistant/${botName}/conversations?show=workflow`;
                },
              },
            ],
          },
          'EVENT::BOT::DOWNLOAD::INITIATED': {
            target: 'JOURNEY::BOT::DOWNLOAD::INITIATED',
            actions: [],
          },
          'EVENT::BOT::DOWNLOADED': {
            target: 'JOURNEY::BOT::DOWNLOADED',
            actions: [
              // {
              //   tag: 'URL::REDIRECT',
              //   type: 'side-effect',
              //   name: 'redirect-to-bot',
              //   url: (event: any, meta: any) => {
              //     // console.log('event', event)
              //     const workspace = meta.systemContext.workspace;
              //     const botName = event.botName;
              //     const botId = event.botId;
              //     return `/workspace/${workspace}/assistant/${botName}/conversations`;
              //     // return `/workspace/${workspace}/assistant/${botName}/conversations?show=workflow`;
              //   },
              // },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
        },
      },
      'JOURNEY::BOT_CREATED': {
        entry: [],
        on: {
          'EVENT::JOURNEY::DONE': {
            target: 'JOURNEY::DONE',
            actions: [],
          },
          'EVENT::DIALOG::CREATED': {
            target: 'DIALOG::CREATED_DIALOG',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
          'EVENT::FAQ::CREATED': {
            target: 'FAQ::CREATED_FAQ',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
          'EVENT::WORKFLOW::CREATED': {
            target: 'WORKFLOW::CREATED_WORKFLOW',
            actions: [],
          },
          'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
            target: 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'connection-missing',
              //   text: 'show connection missing',
              // },
            ],
          },
        },
      },
      'JOURNEY::BOT::DOWNLOAD::INITIATED': {
        on: {
          'EVENT::BOT::DOWNLOADED': {
            target: 'JOURNEY::BOT::DOWNLOADED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
          'EVENT::BOT::CREATED': {
            target: 'JOURNEY::BOT_CREATED',
            actions: [],
          },
          'EVENT::BOT::DOWNLOAD::CANCELLED': {
            target: 'JOURNEY::NOT_STARTED',
            actions: [
              // {
              //   tag: 'URL::REDIRECT',
              //   type: 'side-effect',
              //   name: 'redirect-to-get-started',
              //   url: (event: any, meta: any) => {
              //     // console.log('event', event)
              //     const workspace = meta.systemContext.workspace;
              //     return `/workspace/${workspace}/get-started`;
              //   },
              // },
            ],
          },
        },
      },
      'JOURNEY::BOT::DOWNLOADED': {
        entry: [],
        on: {
          'EVENT::DIALOG::WELCOME_CLICKED': {
            target: 'DIALOG::SHOWN_WELCOME_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'welcome-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'fallback-dialog',
              //   text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.',
              // },
            ],
          },
        },
      },
      'JOURNEY::DONE': {},
      'DIALOG::CREATED_DIALOG': {
        entry: [],
        on: {
          'EVENT::DIALOG::WELCOME_CLICKED': {
            target: 'DIALOG::SHOWN_WELCOME_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'welcome-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'fallback-dialog',
              //   text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.',
              // },
            ],
          },
        },
      },
      'DIALOG::JOURNEY_STARTED': {
        entry: [],
        on: {
          'EVENT::DIALOG::CREATED': {
            target: 'DIALOG::CREATED_DIALOG_SUB',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
          'EVENT::WORKFLOW::CREATED': {
            target: 'WORKFLOW::ACTION_SAVED',
            actions: [],
          },
        },
      },
      'WORKFLOW::JOURNEY_STARTED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::CREATED': {
            target: 'WORKFLOW::CREATED_WORKFLOW_SUB',
            actions: [],
          },
          'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
            target: 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'connection-missing',
              //   text: 'show connection missing',
              // },
            ],
          },
        },
      },
      'DIALOG::CREATED_DIALOG_SUB': {
        entry: [],
        on: {
          'EVENT::DIALOG::WELCOME_CLICKED': {
            target: 'DIALOG::SHOWN_WELCOME_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'welcome-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'fallback-dialog',
              //   text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.',
              // },
            ],
          },
        },
      },
      'DIALOG::SHOWN_WELCOME_INFO': {
        entry: [],
        on: {
          'EVENT::DIALOG::FALLBACK_CLICKED': {
            target: 'DIALOG::SHOWN_FALLBACK_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'fallback-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'tryme-popup',
              //   text: 'Test your bot with tryme',
              // },
            ],
          },
        },
      },
      'DIALOG::SHOWN_FALLBACK_INFO': {
        entry: [],
        on: {
          'EVENT::DIALOG::TRYME_CLICKED': {
            target: 'DIALOG::SHOWN_TRYME_MESSAGE',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'tryme-popup': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::HIGHLIGHT',
              //   type: 'ui',
              //   name: 'tryme-box',
              // },
            ],
          },
        },
      },
      'DIALOG::SHOWN_TRYME_MESSAGE': {
        entry: [],
        on: {
          'EVENT::DIALOG::TRYME_CLOSED': {
            target: 'DIALOG::SHOWN_PUBLISH_MESSAGE',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'tryme-box': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'publish',
              //   text: '',
              // },
            ],
          },
        },
      },
      'DIALOG::SHOWN_PUBLISH_MESSAGE': {
        entry: [],
        on: {
          'EVENT::DIALOG::PUBLISH_CLICKED': {
            target: 'DIALOG::SHOWN_CHAT_CHANNEL_POPUP',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { publish: 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'chat-channel',
              //   text: '',
              // },
            ],
          },
        },
      },
      'DIALOG::SHOWN_CHAT_CHANNEL_POPUP': {
        entry: [],
        on: {
          'EVENT::DIALOG::END_JOURNEY': {
            target: 'DIALOG::JOURNEY_DONE',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'chat-channel': 'done' });
                },
              },
            ],
          },
        },
      },
      'DIALOG::JOURNEY_DONE': {
        entry: [],
        on: {},
      },
      'FAQ::JOURNEY_STARTED': {
        entry: [],
        on: {
          'EVENT::FAQ::CREATED': {
            target: 'FAQ::CREATED_FAQ_SUB',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'welcome-dialog',
              //   text: 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.',
              // },
            ],
          },
        },
      },
      'FAQ::CREATED_FAQ': {
        entry: [],
        on: {
          'EVENT::DIALOG::WELCOME_CLICKED': {
            target: 'DIALOG::SHOWN_WELCOME_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'welcome-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'fallback-dialog',
              //   text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.',
              // },
            ],
          },
        },
      },
      'FAQ::CREATED_FAQ_SUB': {
        entry: [],
        on: {
          'EVENT::DIALOG::WELCOME_CLICKED': {
            target: 'DIALOG::SHOWN_WELCOME_INFO',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'welcome-dialog': 'done' });
                },
              },
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'fallback-dialog',
              //   text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.',
              // },
            ],
          },
        },
      },
      'WORKFLOW::CREATED_WORKFLOW_SUB': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::ACTION_SAVED': {
            target: 'WORKFLOW::ACTION_SAVED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'test-workflow',
              //   text: 'Show you can test',
              // },
            ],
          },
        },
      },
      'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE_SUB': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED': {
            target: 'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'test-workflow',
              //   text: 'Show you can test',
              // },
            ],
          },
        },
      },
      'WORKFLOW::CREATED_WORKFLOW': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::ACTION_SAVED': {
            target: 'WORKFLOW::ACTION_SAVED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'test-workflow',
              //   text: 'Show you can test',
              // },
            ],
          },
        },
      },
      'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED': {
            target: 'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'conenction-missing',
              //   text: 'show connection missing',
              // },
            ],
          },
        },
      },
      'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::ACTION_SAVED': {
            target: 'WORKFLOW::ACTION_SAVED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'test-workflow',
              //   text: 'Show you can test',
              // },
            ],
          },
        },
      },
      'WORKFLOW::ACTION_SAVED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::TO_BE_TESTED': {
            target: 'WORKFLOW::TO_BE_TESTED',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'test-workflow': 'done' });
                },
              },
            ],
          },
        },
      },
      'WORKFLOW::TO_BE_TESTED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::TESTED': {
            target: 'WORKFLOW::TESTED',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, { 'test-workflow': 'done' });
                },
              },
            ],
          },
        },
      },
      'WORKFLOW::TESTED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::TO_BE_ACTIVATED': {
            target: 'WORKFLOW::TO_BE_ACTIVATED',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'activate-workflow',
              //   text: 'Show you can activate',
              // },
            ],
          },
        },
      },
      'WORKFLOW::TO_BE_ACTIVATED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::ACTIVATED': {
            target: 'WORKFLOW::ACTIVATED',
            actions: [
              {
                tag: 'CONTEXT::ASSIGN',
                type: 'pure',
                name: 'start-journey',
                assignment: (current: any, event: any) => {
                  return Object.assign(current, {
                    'activate-workflow': 'done',
                  });
                },
              },
            ],
          },
        },
      },
      'WORKFLOW::ACTIVATED': {
        entry: [],
        on: {
          'EVENT::WORKFLOW::END_JOURNEY': {
            target: 'WORKFLOW::JOURNEY_DONE',
            actions: [
              // {
              //   tag: 'SHOW::POPUP',
              //   type: 'ui',
              //   name: 'add-to-dialog',
              //   text: 'Show redirect to dialog popup',
              // },
            ],
          },
        },
      },
      'WORKFLOW::JOURNEY_DONE': {
        entry: [],
        on: {},
      },
    },
  });

  const S = interpret(journey);

  return S;
};

// type WorkflowJourney =
//     | 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE'
//     | 'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED'
//     | 'WORKFLOW::ACTION_SAVED'
//     | 'WORKFLOW::DEPLOYED'
//     | 'WORKFLOW::ACTIVATED'

// type WorkflowGenericEvents =
//     | 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE'
//     | 'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED'
//     | 'EVENT::WORKFLOW::ACTION_SAVED'
//     | 'EVENT::WORKFLOW::ACTIVATED'
//     | 'EVENT::WORKFLOW::END_JOURNEY'
// export const S = interpret(usersJourney)

// S.subscribe(x => // console.log('SUBSCRIBED', x))

// S.start({
//     value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
//     context: {
//         systemContext: {
//             workspace: 'nirup'
//         },
//         executionContext: {}
//     }
// })

// export const JourneyContext = React.createContext(S)

// S.send({ type: 'EVENT::BOT::CREATED', tag: 'BOT_EVENT', botId: 'HELLO', botName: 'Hello' })

// S.send({ type: 'EVENT::JOURNEY::DONE', tag: 'GENERIC' })

// function toActionObject<TContext extends UserBotEventContext, TEvent extends EventObject>(
//   action:
//     | string
//     | ActionFunction<TContext, TEvent>
//     | ActionObject<TContext, TEvent>,
//   actionMap: ActionMap<TContext, TEvent> | undefined
// ) {
//   action =
//     typeof action === "string" && actionMap && actionMap[action]
//       ? actionMap[action]
//       : action;
//   return typeof action === "string"
//     ? {
//         type: action
//       }
//     : typeof action === "function"
//     ? {
//         type: action.name,
//         exec: action
//       }
//     : action;
// }

// interface ActionObject<TContext extends UserBotEventContext, TEvent extends EventObject> {
//   type: string;
//   exec?: ActionFunction<TContext, TEvent>;
//   [key: string]: any;
// }

// type ActionFunction<TContext extends UserBotEventContext, TEvent extends EventObject> = (
//   context: TContext,
//   event: TEvent
// ) => void;

// type Action<TContext extends UserBotEventContext, TEvent extends EventObject> =
//   | ActionObject<TContext, TEvent>
//   | ActionFunction<TContext, TEvent>;

// type ActionMap<TContext extends UserBotEventContext, TEvent extends EventObject> = Record<
//   string,
//   Exclude<Action<TContext, TEvent>, string>
// >;
// const usersJourney = createMachine({
//     initial: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
//     context: {
//         systemContext: {
//             workspace: 'indu'
//         },
//         executionContext: {}
//     },
//     states: {
//         'JOURNEY::NOT_STARTED': {
//             on: {
//                 'EVENT::BOT::CREATED': {
//                     target: 'JOURNEY::BOT_CREATED',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, {
//                                     'JOURNEY::BOT_CREATED': 'next'
//                                 })
//                             }
//                         },
//                         {
//                             tag: 'URL::REDIRECT',
//                             type: 'side-effect',
//                             name: 'redirect-to-bot',
//                             url: (event: any, meta: any) => {
//                                 // console.log('event', event)
//                                 const workspace = meta.systemContext.workspace
//                                 const botName = event.botName
//                                 const botId = event.botId
//                                 return `/workspace/${workspace}/dialog/${botName}/dialogs?show=workflow`
//                             }
//                         }
//                     ]
//                 }
//             }
//         },
//         'JOURNEY::BOT_CREATED': {
//             entry: [],
//             on: {
//                 'EVENT::JOURNEY::DONE': {
//                     target: 'JOURNEY::DONE',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'go to bots',
//                             text: 'Welcome'
//                         }
//                     ]
//                 },
//                 'EVENT::DIALOG::CREATED': {
//                     target: 'DIALOG::CREATED_DIALOG',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'DIALOG::CREATED_DIALOG': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'welcome-dialog',
//                             text:
//                                 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.'
//                         }
//                     ]
//                 },
//                 'EVENT::FAQ::CREATED': {
//                     target: 'FAQ::CREATED_FAQ',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'DIALOG::CREATED_DIALOG': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'welcome-dialog',
//                             text:
//                                 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.'
//                         }
//                     ]
//                 },
//                 'EVENT::WORKFLOW::CREATED': {
//                     target: 'WORKFLOW::ACTION_SAVED',
//                     actions: []
//                 },
//                 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
//                     target: 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE',
//                     actions: []
//                 }
//             }
//         },
//         'JOURNEY::DONE': {},
//         'DIALOG::CREATED_DIALOG': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::WELCOME_CLICKED': {
//                     target: 'DIALOG::SHOWN_WELCOME_INFO',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'fallback-dialog',
//                             text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.'
//                         }
//                     ]
//                 }
//             }
//         },
//         'DIALOG::JOURNEY_STARTED': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::CREATED': {
//                     target: 'DIALOG::CREATED_DIALOG_SUB',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'DIALOG::CREATED_DIALOG': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'welcome-dialog',
//                             text:
//                                 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.'
//                         }
//                     ]
//                 },
//                 'EVENT::WORKFLOW::CREATED': {
//                     target: 'WORKFLOW::ACTION_SAVED',
//                     actions: []
//                 }
//             }
//         },
//         'FAQ::JOURNEY_STARTED': {
//             entry: [],
//             on: {
//                 'EVENT::FAQ::CREATED': {
//                     target: 'FAQ::CREATED_FAQ_SUB',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'DIALOG::CREATED_DIALOG': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'welcome-dialog',
//                             text:
//                                 'You can update your bot’s welcome or greeting message (to the user) from this welcome dialog.'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::JOURNEY_STARTED': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::CREATED': {
//                     target: 'WORKFLOW::CREATED_WORKFLOW_SUB',
//                     actions: []
//                 },
//                 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
//                     target: 'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE',
//                     actions: []
//                 }
//             }
//         },
//         'DIALOG::CREATED_DIALOG_SUB': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::WELCOME_CLICKED': {
//                     target: 'DIALOG::SHOWN_WELCOME_INFO',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'fallback-dialog',
//                             text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.'
//                         }
//                     ]
//                 }
//             }
//         },
//         'DIALOG::SHOWN_WELCOME_INFO': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::FALLBACK_CLICKED': {
//                     target: 'DIALOG::SHOWN_FALLBACK_INFO',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'DIALOG::SHOWN_FALLBACK_INFO': 'moved' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'tryme-popup',
//                             text: 'Test your bot with tryme'
//                         }
//                     ]
//                 }
//             }
//         },
//         'DIALOG::SHOWN_FALLBACK_INFO': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::TRYME_CLICKED': {
//                     target: 'DIALOG::SHOWN_TRYME_MESSAGE',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::HIGHLIGHT',
//                             type: 'ui',
//                             name: 'tryme-box'
//                         }
//                     ]
//                 }
//             }
//         },
//         'DIALOG::SHOWN_TRYME_MESSAGE': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::TRYME_CLOSED': {
//                     target: 'DIALOG::SHOWN_CHAT_CHANNEL_POPUP',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'chat-channel',
//                             text: ''
//                         }
//                     ]
//                 }
//             }
//         },
//         'DIALOG::SHOWN_CHAT_CHANNEL_POPUP': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::END_JOURNEY': {
//                     target: 'DIALOG::JOURNEY_DONE',
//                     actions: []
//                 }
//             }
//         },
//         'DIALOG::JOURNEY_DONE': {
//             entry: [],
//             on: {}
//         },
//         'FAQ::JOURNEY_DONE': {
//             entry: [],
//             on: {}
//         },
//         'FAQ::CREATED_FAQ': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::WELCOME_CLICKED': {
//                     target: 'DIALOG::SHOWN_WELCOME_INFO',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'fallback-dialog',
//                             text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.'
//                         }
//                     ]
//                 }
//             }
//         },
//         'FAQ::CREATED_FAQ_SUB': {
//             entry: [],
//             on: {
//                 'EVENT::DIALOG::WELCOME_CLICKED': {
//                     target: 'DIALOG::SHOWN_WELCOME_INFO',
//                     actions: [
//                         {
//                             tag: 'CONTEXT::ASSIGN',
//                             type: 'pure',
//                             name: 'start-journey',
//                             assignment: (current: any, event: any) => {
//                                 return Object.assign(current, { 'JOURNEY::DONE': 'next' })
//                             }
//                         },
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'fallback-dialog',
//                             text: 'You can update your bot’s fallback message (to the user) from this fallback dialog.'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::CREATED_WORKFLOW_SUB': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::ACTION_SAVED': {
//                     target: 'WORKFLOW::ACTION_SAVED',
//                     actions: [
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'conenction-missing',
//                             text: 'show connection missing'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::DOWNLOADED_FROM_MARKETPLACE': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED': {
//                     target: 'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED',
//                     actions: [
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'conenction-missing',
//                             text: 'show connection missing'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::CONNECTION_MISSING_ACKNOWLEDGED': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::ACTION_SAVED': {
//                     target: 'WORKFLOW::ACTION_SAVED',
//                     actions: []
//                 }
//             }
//         },
//         'WORKFLOW::ACTION_SAVED': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::TESTED': {
//                     target: 'WORKFLOW::TESTED',
//                     actions: [
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'test-workflow',
//                             text: 'Show you can test'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::TESTED': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::ACTIVATED': {
//                     target: 'WORKFLOW::ACTIVATED',
//                     actions: [
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'test-workflow',
//                             text: 'Show you can activate'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::ACTIVATED': {
//             entry: [],
//             on: {
//                 'EVENT::WORKFLOW::END_JOURNEY': {
//                     target: 'WORKFLOW::JOURNEY_DONE',
//                     actions: [
//                         {
//                             tag: 'SHOW::POPUP',
//                             type: 'ui',
//                             name: 'test-workflow',
//                             text: 'Show redirect to dialog popup'
//                         }
//                     ]
//                 }
//             }
//         },
//         'WORKFLOW::JOURNEY_DONE': {
//             entry: [],
//             on: {}
//         }
//     }
// })
