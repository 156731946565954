/*eslint-disable*/
import React from 'react'

import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'
import { Input, InputContainer, InputContent, InputWrapper, Textarea } from '@/common/styled/Workflow.Analytics.Dumb'
import { flowValidations, inputTextAreaValidationsForWorkflow, newLine } from '@/common/utils/_validation-utils'

import { MarketPlaceApi, automationAPIURLWithWorkspace, deleteJson, putJson } from '@/common/utils/api-utils'
import { MyUploadActions } from './types'

export type ReactSetStateObject<T> = [T, React.Dispatch<React.SetStateAction<T>>]
export function deleteUploads(
    [myUploadActions, setMyUploadAction]: ReactSetStateObject<MyUploadActions>,
    deleteCallback: (id: string) => void,
    workspaceName: string
) {
    const nonCanvasActionCurrent = myUploadActions as {
        kind: 'Delete'
        id: string
        error?: {
            error: boolean
            info: string
        }
    }
    const deployAction = async (id: string) => {
        try {
            await deleteJson(automationAPIURLWithWorkspace(workspaceName, `${MarketPlaceApi.DeleteUpload}/${id}`))()
            deleteCallback(id)
        } catch (err) {
            setMyUploadAction({
                ...nonCanvasActionCurrent,
                error: {
                    error: true,
                    info: err.response.data.error
                }
            })
        }
    }
    const renderAcknowledgeModal = () => {
        if (myUploadActions.kind === 'Delete') {
            return (
                <Modal>
                    <ErrorBoundary
                        error={myUploadActions.error ? myUploadActions.error : { error: false, info: '' }}
                        render={(err: any, _info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setMyUploadAction({ kind: 'View' })
                                        }}
                                        info={_info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2> Delete Automation</h2>
                        <Paragraph margin>Are you sure to delete the automation from Marketplace ?</Paragraph>
                        <ButtonWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    deployAction(myUploadActions.id)
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setMyUploadAction({ kind: 'View' })
                                }}
                            >
                                <label>No</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        }
        return null
    }
    return [renderAcknowledgeModal]
}

export function editUploads(
    [myUploadActions, setMyUploadAction]: ReactSetStateObject<MyUploadActions>,
    editCallback: (id: string, name: string, description: string) => void,
    workspaceName: string
) {
    const edit = async () => {
        const nonCanvasActionCurrent = myUploadActions as {
            kind: 'Edit'
            id: string
            description: string
            name: string
            error?: {
                error: boolean
                info: string
            }
        }
        const { id, name, description } = nonCanvasActionCurrent
        console.log('edit 1')

        try {
            await putJson(automationAPIURLWithWorkspace(workspaceName, MarketPlaceApi.EditUpload))()({
                id,
                name,
                description
            })
            editCallback(id, name, description)
        } catch (err) {
            setMyUploadAction({
                ...nonCanvasActionCurrent,
                error: {
                    error: true,
                    info: err.response.data.error //err.toString()
                }
            })
        }
        console.log('edit 2')
    }
    const onNameChange = (name: string) => {
        const nonCanvasActionCurrent = myUploadActions as {
            kind: 'Edit'
            id: string
            description: string
            name: string

            error?: {
                error: boolean
                info: string
            }
        }

        setMyUploadAction({
            ...nonCanvasActionCurrent,
            name
        })
    }
    const onDescriptionChange = (description: string) => {
        const nonCanvasActionCurrent = myUploadActions as {
            kind: 'Edit'
            id: string
            description: string
            name: string

            error?: {
                error: boolean
                info: string
            }
        }

        setMyUploadAction({
            ...nonCanvasActionCurrent,
            description
        })
    }
    const renderAcknowledgeModal = () => {
        if (myUploadActions.kind === 'Edit') {
            return (
                <Modal>
                    <Validate defaultValue={{ flowName: true, flowDescription: true }}>
                        <div className="alert_popup_message">
                            <h2>Edit Workflow</h2>
                            <Paragraph margin>Enter name and description</Paragraph>
                            <InputBox
                                validationList={flowValidations}
                                value={myUploadActions.name}
                                name="flowName"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <InputWrapper>
                                                <InputContainer>
                                                    <Input
                                                        type="text"
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowName', event.target.value)
                                                            onNameChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <InputContent>Automation Name</InputContent>
                                                    <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <InputBox
                                validationList={inputTextAreaValidationsForWorkflow}
                                value={myUploadActions.description}
                                name="flowDescription"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <InputWrapper>
                                                <InputContainer>
                                                    <Textarea
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowDescription', event.target.value)
                                                            onDescriptionChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <InputContent>Automation Description</InputContent>
                                                    <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <p className="error_msg">
                                {myUploadActions.error && myUploadActions.error.error
                                    ? myUploadActions.error.info
                                    : newLine}
                            </p>
                            <ButtonWrapper>
                                <Button
                                    primary
                                    onClick={() => {
                                        setMyUploadAction({ kind: 'View' })
                                    }}
                                >
                                    <label>Cancel</label>
                                </Button>
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <Button
                                                disabled={!valid.formValid.formValid}
                                                onClick={() => {
                                                    edit()
                                                }}
                                            >
                                                <label>Save</label>
                                            </Button>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </ButtonWrapper>
                        </div>
                    </Validate>
                </Modal>
            )
        }
        return null
    }
    return [renderAcknowledgeModal]
}
