import React from 'react'

import { DialogIcon, DownloadFromMarketplace, TimerIcon } from '@/common/Icons/Icons'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { CardWrapperT } from './BotMarketPlacePopup'
import {
    Card,
    CardBottom,
    CardDate,
    CardTime,
    Count,
    DateTime,
    Downloads,
    EmptyDiv,
    Header,
    HeaderWithImage,
    ImageCircle,
    Paragraph,
    SVGicons
} from './BotMarketplace'

export const CardWrapper = ({ isDialog, isActiveCard, card, getCard, isOpenLeft }: CardWrapperT) => {
    return isOpenLeft && isActiveCard ? (
        <Card isActive onClick={() => (isDialog ? getCard(card.botId as string, card.id) : getCard(card.id))}>
            <CardBody card={card} isDialog={isDialog} />
        </Card>
    ) : (
        <Card onClick={() => (isDialog ? getCard(card.botId as string, card.id) : getCard(card.id))}>
            <CardBody card={card} isDialog={isDialog} />
        </Card>
    )
}
export const CardBody = ({ card, isDialog }: any) => {
    return (
        <>
            <HeaderWithImage>
                <ImageCircle>
                    <DialogIcon />
                </ImageCircle>
                <EmptyDiv>
                    {isDialog ? <Header>{card.title}</Header> : <Header>{card.name}</Header>}
                    <Paragraph>{card.description}</Paragraph>
                </EmptyDiv>
            </HeaderWithImage>
            <CardBottom>
                <DateTime>
                    <SVGicons>
                        <TimerIcon />
                    </SVGicons>
                    <CardDate>{formatDateInCard(card.updatedTime)} </CardDate> |{' '}
                    <CardTime>{formatAMPM(card.updatedTime)}</CardTime>
                </DateTime>
                {!isDialog && (
                    <Downloads>
                        <span>
                            <DownloadFromMarketplace />
                        </span>{' '}
                        <Count>{card.downloaded_count}</Count>
                    </Downloads>
                )}
            </CardBottom>
        </>
    )
}
