/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { TryItWithButton } from '@/DialogEditor/Canvas/Components/Nodes/TryIt'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
// Common Loaders
import { Loader } from '@/common/components/Loader'

//TYPE IMPORT
import { DialogueT, SlotContainerT } from '../../Dialogue/kind'
import { StatusContext, statusT } from '../../Mechanisms/action'
//CONTENT IMPORT
import { DialogueContext } from '../Context'
import { ActionT, VIEW } from '../kind'
import Descendants, { validateNode, validateResponseNode } from './Descendant'
import Dialogues from './Dialogues'
//COMPONENTS IMPORT
import Header from './Header'
import SidePanel from './SidePanel'
import { AssistantT } from '@/Assistant/Construction/kind'

interface CanvasP {
    deploybot: Function
    isdeployed: any
    isPublish: any
    publishBot: Function
    currentDialog: DialogueT
    index: string
    defaultNodes: DialogueT[]
    pollRef: { current: number | null }
    pollAPI: Function
    status: statusT
    setStatus: Function
    assistants:AssistantT[]
    isNull?: boolean
    props?: any
}
const Canvas = ({
    deploybot,
    isdeployed,
    isPublish,
    publishBot,
    currentDialog,
    index,
    defaultNodes,
    isNull,
    props,
    status,
    assistants
}: CanvasP) => {
    const [sidePanel, setSidePanel] = useState<boolean>(true)

    const { PageState, setPageState, assistant, workspaceName, fetchChild, rootDialogs, fetchChild2, dialogueName } = useContext(
        DialogueContext
    )

    const context = useContext(DialogueContext)

    const { intents, entities } = context

    const [isDetailView, setDetailView] = useState(true)

    const setTryMe = (tryme: boolean) => {
        // console.log('clicking tryme', tryme, PageState)
        if (tryme === true) {
            setPageState({
                action: ActionT.ASSISTANT_VIEW
            })
        } else if (tryme === false) {
            setPageState(VIEW)
        }
    }

    const showInputError = (dialog: DialogueT) => {
        const parentNode =
            dialog.conditions &&
            (dialog.conditions.type == 'FallBackExpression')
                ? false
                : validateNode({ intents, entities }, dialog.conditions)
        const responses = !parentNode
            ? dialog.type === 'if_node'
                ? dialog.responses
                      .filter(
                          dg =>
                              dg.type == 'slot_container' ||
                              dg.type == 'automation' ||
                              dg.type == 'agent_handoff' ||
                              dg.type == 'file_upload'
                      )
                      .map(dg => {
                          return validateResponseNode({ intents, entities }, dg, rootDialogs)
                      })
                      .concat(
                          fetchChild2
                              ? (fetchChild2(dialog).map((c: any) => {
                                    return showInputError(c)
                                }) as any)
                              : []
                      )
                : []
            : []
        return parentNode || responses.some(r => r == true)
    }

    const rootNodeValidation =
        validateNode(context, currentDialog) ||
        rootDialogs.map((dialogue: DialogueT, i: number) => showInputError(dialogue)).some(err => err == true)
    const slotNode =
        currentDialog.type === 'if_node'
            ? (currentDialog.responses.filter(res => res.type == 'slot_container' || res.type == 'automation') as any)
            : []

    const responseNode = slotNode.map((res: SlotContainerT) => validateResponseNode(context, res))

    return (
        <>
            {PageState.action === ActionT.LOADING ? (
                <Loader.PageLoader show={true} />
            ) : (
                <div className="automation_canvas">
                    <div className="flex_canvas_right">
                        <ErrorBoundary
                            error={
                                PageState.action === ActionT.ERROR
                                    ? { error: true, info: PageState.reason }
                                    : { error: null, info: null }
                            }
                            unauthorized={PageState.action === ActionT.UN_AUTHORIZED}
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.PageLoadingError
                                            onClick={() => window.location.reload()}
                                            info={info}
                                            btnName="Retry"
                                        />
                                    )
                                )
                            }}
                        >
                            <div
                                className={
                                    // sidePanel ? 'flex_side_menu_dialog dialog_model_overlay' : 'flex_side_menu_dialog'
                                    sidePanel ? 'flex_side_menu_dialog dialog_model_overlay' : 'flex_side_menu_dialog'
                                }
                                style={sidePanel ? { display: 'none' } : { display: 'none' }}
                            >
                                <section className="dialog_canvas_startup_page">
                                    <SidePanel dialog={currentDialog} />
                                </section>
                            </div>
                            <span
                                className={
                                    sidePanel
                                        ? 'sub_panel_toggle_btn dialog_canvas_close_arrow'
                                        : 'sub_panel_toggle_btn dialog_canvas_open_arrow'
                                }
                                onClick={() => setSidePanel(!sidePanel)}
                            >
                                <svg className="rotate_dropdown" viewBox="0 0 240.811 240.811">
                                    <g>
                                        <path
                                            id="Expand_More"
                                            d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0 c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859 c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
                                        />
                                    </g>
                                </svg>
                            </span>

                            <section className="dialog_entity_cards">
                                <section className="entity_cards">
                                    <Header
                                        currentDialog={currentDialog}
                                        DialogIndex={index}
                                        isDetailView={isDetailView}
                                        setDetailView={setDetailView}
                                    />

                                    <div className="col-md-12">
                                        <section className={'dialog_editor dialog_detail'}>
                                            <Descendants
                                                dialogNode={currentDialog}
                                                isDetailView={isDetailView}
                                                isNull={isNull}
                                                assistants={assistants}
                                            />
                                            <StatusContext.Provider value={status}>
                                                <Dialogues
                                                    deploybot={deploybot}
                                                    isdeployed={isdeployed}
                                                    isPublish={isPublish}
                                                    publishBot={publishBot}
                                                    assistant={assistant}
                                                    props={props}
                                                />
                                            </StatusContext.Provider>
                                        </section>
                                    </div>
                                </section>

                                {/* {PageState.action !== ActionT.ASSISTANT_VIEW ? (
                                    // (status == 'training' || status == 'trained') &&
                                    // assistant.deployed !== null &&
                                    // new Date(assistant.updated) <= new Date(assistant.deployed) ? (
                                    <section>
                                        <div className="tryme_popup">
                                            <button
                                                className={
                                                    isNull ? 'btn btn-primary editor_btn_disabled' : 'btn btn-primary'
                                                }
                                                onClick={() =>
                                                    setPageState({
                                                        action: ActionT.ASSISTANT_VIEW
                                                    })
                                                }
                                            >
                                                <span>Try Me</span>
                                            </button>
                                        </div>
                                    </section>
                                ) : null} */}
                                <StatusContext.Provider value={status}>
                                    <TryItWithButton
                                        deployStatus={isdeployed}
                                        workspacename={workspaceName}
                                        close={() => {
                                            setTryMe(false)
                                        }}
                                        intents={intents}
                                        assistantid={assistant.id}
                                        deployed={isdeployed.success}
                                        deploymentError={isdeployed.error}
                                        deploybot={deploybot}
                                        setTryme={setTryMe}
                                        tryMeStatus={PageState.action === ActionT.ASSISTANT_VIEW}
                                        hideAll={PageState.action === ActionT.NO_DATA_FOUND}
                                        isNull={isNull}
                                        isIntentEnityEmpty={
                                            rootNodeValidation || responseNode.some((n: boolean) => n == true)
                                        }
                                        dialogueName={dialogueName}
                                    />
                                </StatusContext.Provider>
                                {/* <section>
                                            <div className="tryme_popup">
                                                <button
                                                    className={
                                                        rootNodeValidation ||
                                                        responseNode.some((n: boolean) => n == true)
                                                            ? 'btn btn-primary editor_btn_disabled'
                                                            : 'btn btn-primary'
                                                    }
                                                    onClick={() => {
                                                        rootNodeValidation ||
                                                        responseNode.some((n: boolean) => n == true)
                                                            ? {}
                                                            : setPageState({
                                                                  action: ActionT.DEPLOY,
                                                                  loading: false
                                                              })
                                                    }}
                                                >
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 32 32">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M24,30c-0.2,0-0.3,0-0.5-0.1l-12-7c-0.5-0.3-0.6-0.9-0.4-1.4c0.3-0.5,0.9-0.6,1.4-0.4l10.8,6.3l6.1-24.6L3.2,16l5.6,3.1
			c0.5,0.3,0.7,0.9,0.4,1.4c-0.3,0.5-0.9,0.7-1.4,0.4l-7.3-4C0.2,16.7,0,16.4,0,16c0-0.4,0.2-0.7,0.6-0.9l30-15
			c0.3-0.2,0.8-0.1,1.1,0.1c0.3,0.2,0.4,0.6,0.3,1l-7,28c-0.1,0.3-0.3,0.6-0.6,0.7C24.3,30,24.1,30,24,30z"
                                                                />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="M12,32c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.5-0.7-1v-9c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v5.7l2.2-3.3
			c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4l-4,6C12.6,31.8,12.3,32,12,32z"
                                                                />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="M12,23c-0.2,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1-0.1-1.4l19-21c0.4-0.4,1-0.4,1.4-0.1c0.4,0.4,0.4,1,0.1,1.4l-19,21
			C12.5,22.9,12.3,23,12,23z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <span>Try Me</span>
                                                </button>
                                                {status == 'failed' && (
                                                    <span className="connect_account_failed">
                                                        <div className="error_input_show">
                                                            <WarningAlertIcon />
                                                            <span className="error_menu_hvr">
                                                                Something went wrong while training the bot. Please
                                                                deploy again.
                                                            </span>
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        </section>
                                    )
                                ) : null} */}
                            </section>
                        </ErrorBoundary>
                    </div>
                </div>
            )}
        </>
    )
}

export default Canvas
