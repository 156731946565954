import React, { useContext } from 'react'
// import { ButtonWrapper } from '@/Subscription/Plans-v2'
import styled from 'styled-components/macro'

import { SideBarContext } from '@/SidePanel/SideBarContext'
import { ErrorIcon, QaIconWithCircle, SupportIcon } from '@/common/Icons/Icons'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { Button, DropdownWrapper, Input, InputContent } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    BotGridsContainer,
    ButtonWithImage,
    ButtonWrapper,
    DownloadBotHelp,
    DownloadContent,
    DropdownContainer,
    ImageSpan,
    OrSticky
} from '@/common/styled/GetStarted.Dumb'
import {
    ErrorMessage,
    InputContainerWithNoMargin,
    InputErrorIcon,
    InputWrapper
} from '@/common/styled/Workflow.Analytics.Dumb'

import createImg from '@/common/images/create_new_img.png'

export const BotWrapper = styled.div`
    width: 35%;
    float: left;
    padding: 40px 40px 30px;
    background: #fff;
    border-radius: 10px;
    margin: 10px;
    margin-left: 30%;
    margin-top: 30px;
    box-shadow: 3px 3px 3px 0px #e6e6e6;

    .box_wrapper_top{
        display: flex;
        justify-content: center;
    }
    .box_wrapper_top p {
        color: #355d8b;
        font-family: 'Gordita-Regular';
        font-size: 14px;
        margin-bottom: 12px;
        width: 365px;
        line-height: 20px;
    }
    ${DropdownWrapper} {
        width: 390px;
    }
`

export default function WorkflowBuilder(props: any) {
    const { mode, Mode, flowValidations, newLine, onNameChange, saveCard, journey, onDownloadWorkflow } = props
    const { isSubPanelOpen } = useContext(SideBarContext)

    return (
        <BotWrapper>
            <div className="box_wrapper_top">
                <img src={createImg} alt="workflow" />
            </div>
            <BotGridsContainer
                style={{
                    // marginTop: 30,
                    // gridTemplateColumns: !isSubPanelOpen ? '42% 24px 1fr' : '42% 24px 1fr',
                    // gridGap: '5%'
                }}
            >
                <Validate
                    defaultValue={
                        mode.mode == Mode.NoWorkflowsCreatedInitalRediraction || Mode.NoDataFound
                            ? { flowName: false }
                            : { flowName: true }
                    }
                >
                    <DropdownContainer style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <DropdownWrapper style={{ marginBottom: 0, float: 'none' }}>
                            <InputBox
                                validationList={flowValidations}
                                value={
                                    mode.mode == Mode.NoWorkflowsCreatedInitalRediraction ||
                                    mode.mode == Mode.NoDataFound
                                        ? mode.name
                                        : ''
                                }
                                name="flowName"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <InputWrapper marginBottom="10px">
                                                {<ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>}
                                                <InputContainerWithNoMargin>
                                                    <Input
                                                        primary={!valid ? `primary` : ''}
                                                        type="text"
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowName', event.target.value)
                                                            onNameChange(event.target.value)
                                                        }}
                                                        value={value}
                                                        width={'100%'}
                                                        style={{padding: '26px 30px 6px 15px'}}
                                                    />
                                                    <InputContent>Workflow name</InputContent>
                                                    {!valid && (
                                                        <InputErrorIcon>
                                                            <ErrorIcon />
                                                        </InputErrorIcon>
                                                    )}
                                                </InputContainerWithNoMargin>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            {(mode.mode == Mode.NoWorkflowsCreatedInitalRediraction || mode.mode == Mode.NoDataFound) &&
                            mode.error ? (
                                <p className="error_msg">{mode.error.info}</p>
                            ) : null}
                        </DropdownWrapper>
                        <ErrorContext.Consumer>
                            {(valid: any) => {
                                let isDisabledValue = !valid.formValid.formValid

                                return (
                                    <Button
                                        margin={'0px'}
                                        className={isDisabledValue ? 'editor_btn_disabled' : ''}
                                        disabled={isDisabledValue ? true : false}
                                        onClick={async () => {
                                            await saveCard(journey)
                                        }}
                                        style={{marginBottom: 0}}
                                    >
                                        {' '}
                                        Create workflow
                                    </Button>
                                )
                            }}
                        </ErrorContext.Consumer>
                        {/* <DownloadContent style={{ marginTop: 0 }}>
                            <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/creating-an-automation/"
                                target="_blank"
                            >
                                <ImageSpan>
                                    <QaIconWithCircle />
                                </ImageSpan>
                                How to create app workflow
                            </DownloadBotHelp>
                        </DownloadContent> */}
                    </DropdownContainer>
                </Validate>
                <OrSticky afterHeight="48px">Or</OrSticky>
                <ButtonWrapper width="max-content" primary={'primary'}>
                    <ButtonWithImage onClick={onDownloadWorkflow}>
                        <span>
                            <SupportIcon />
                        </span>
                        Download from Marketplace
                    </ButtonWithImage>
                    {/* <DownloadContent top="small">
                        <DownloadBotHelp
                            href="https://help.workativ.com/knowledgebase/download-workflow/"
                            target="_blank"
                        >
                            <ImageSpan>
                                <QaIconWithCircle />
                            </ImageSpan>
                            How to download app workflow
                        </DownloadBotHelp>
                    </DownloadContent> */}
                </ButtonWrapper>
            </BotGridsContainer>
        </BotWrapper>
    )
}
