/*eslint-disable*/
import { RawDraftContentBlock } from 'draft-js'
import * as R from 'ramda'
import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { AdaptiveServerFieldValueT, AdaptiveValueT, DialogueT, FAQT, FolderT } from '@/Assistant/Dialogue/kind'
import { DialogueContext, VariableContextForFAQ, VariableContextForFAQT, VariableContextT } from '@/Assistant/DialogueCrew/Context'
import { NodesWithContext } from '@/Assistant/DialogueCrew/DialogueMap/Dialogues'
import { AdaptiveResponseUI } from '@/Assistant/DialogueCrew/Nodes/AdaptiveCards'
import CombinatorCompoenent from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/CombinatorCompoenent'
import ConditionalStatements from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/ConditionalStatements'
import { generateCond, reverseCond } from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/action'
import {
    combinatorValueT,
    conditionT,
    conditionTYPES,
    operatorsT
} from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/kind'
import { sortBySibling } from '@/Assistant/DialogueCrew/action'
import { DraftJST, ExpressionTypeE, converterToServer } from '@/Assistant/Editor/Action'
import Editor from '@/Assistant/Editor/Editor'
import { createFAQNode, createIfDialogueWithInitial, updateIfDialogue } from '@/Assistant/Mechanisms/action'
import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { useJourney } from '@/Journey/JourneyContext'
import { VideoLinkSimple } from '@/Videos/Videos'
import { AddIcon, CloseBtnWithCircle, InfoIcon, QaIconWithCircle, VideoIcon } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Loader } from '@/common/components/Loader'
import { SelectBox } from '@/common/components/SelectBox'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    CancelButton,
    DropdownWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    HeaderRight,
    HoverInputContainer,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper, FocusContent } from '@/common/styled/DraftJs.Dumb'

import { findDuplicateInSamerray } from '@/DialogEditor/utils'
import { DialogT, FAQType, useFaqState } from '../useFaqState'
import NextStep from './DownshiftFaq'
import { logEvent } from '@/common/utils/api-utils'
import { useQueryClient } from 'react-query'

type FaqPropT = WorkspaceT & {
    assistants: Function
    onCancel: () => void
    state: 'create' | 'update'
    node: { [x: string]: any }
    refresh: Function
    props: any
}
type WorkspaceT = {
    workspacename: string
    dialougeId: string
}

type AdaptiveFieldsT = {
    buttons: AdaptiveValueT[]
    fields: AdaptiveValueT[]
    basicInfo: { title: { type: ExpressionTypeE.TEXT; value: string }; description: DraftJST; image: string }
}
type InputHeaderWrapperT = {
    draft: DraftJST
    setDraft: (value: DraftJST) => void
    handleSave: (input: AdaptiveFieldsT) => void
    answerType: string
    setAnswerType: Function
    adaptiveCardValue: any
}
type ButtonWrapperT = WorkspaceT & {
    onCancel: () => void
    onSave: (name: string, id: string) => void
    AllFields: boolean
}
export type DropDownWrapperT = WorkspaceT & DropDownT
type DropDownT = {
    then: string
    setThen: () => void
    dialogs: DialogueT[]
    GetDialogs: (workspacename: string, dialogId: string) => void
    dialog: DialogT
    setDialog: (e: string) => void
    message: DraftJST
    setMessage: () => void
    updateId: string
    faqId?:string
    showNextStepError?:boolean
    forFaq?:boolean
    isFolder?:boolean
    folderData?:FolderT[],
    getFolderDialogs:(workspacename: string,assistantID:string, dialogId: string) => void
    dialogRootData:DialogueT[]
}
type HoverInputWrapperT = {
    onAddMoreCondition: (conditionIndex: number) => void
    onQueryChange: (id: number, conditionIndex: number) => void
    onRemoveQuery: (id: number, conditionIndex: number) => void
}
type PopupInputWrapperT = {
    faq: FAQType
    onChangeFaqState: (e: SyntheticEvent) => void
}
type PopupHeaderT = {
    state: 'create' | 'update'
}
type FAQPopupRightT = PopupInputWrapperT &
    InputHeaderWrapperT &
    ButtonWrapperT &
    HoverInputWrapperT &
    DropDownT &
    PopupHeaderT & {
        answerType: string
        setAnswerType: Function
        adaptiveCardValue: any
        addMoreNewConditon: Function
        newConditions: conditionT[]
        onConditionChange: Function
        removeCondition: Function
        faqId: string
        isFolder:boolean
        folderData:FolderT[]
    }

export const FaqPopup = (props: FaqPropT) => {
    const queryClient = useQueryClient();

    const { rootDialogs, dialogueNode } = useContext(DialogueContext)

    // console.log('FaqPopup ::: ', props)
    const { onCancel, workspacename, dialougeId, state, node, refresh, assistants} = props
    const{isFolder,folderData,dialogRootData} =props.props
    const [loader, setLoader] = React.useState<boolean>(false)

    const [isUrlOpen, setUrlisOpen] = useState<boolean>(false)
    const journeyData = useJourney()

    const [answerType, setAnswerType] = useState(
        node['responses'].type == 'information_card' ? 'information_card' : 'text_message'
    )

    const {
        faq,
        draft,
        setDraft,
        then,
        setThen,
        dialogs,
        GetDialogs,
        onChangeFaqState,
        dialog,
        setDialog,
        message,
        setMessage,
        basicInfo,
        fields,
        buttons,
        setBasicInfo,
        setFields,
        setButtons,
        newConditions,
        setNewConditions,
        getFolderDialogs
    } = useFaqState(node,folderData,isFolder)
    const onConditionChange = (index: number, condition: any) =>
        setNewConditions(element => element.map((e, i) => (i == index ? condition : e)))

    const removeCondition = (index: number) =>
        setNewConditions(condition =>
            condition.filter((e: any, i: number) => {
                const conditionIndex = index == 0 ? index + 1 : index - 1
                if (i != index && i != conditionIndex) return e
                return false
            })
        )

    const addMoreNewConditon = () =>
        setNewConditions(condition =>
            condition.concat([
                {
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                },
                {
                    type: conditionTYPES.SELECT
                }
            ])
        )

    const onQueryChange = (index: number, conditionIndex: number) => (e: SyntheticEvent) => {
        let target = e.target as HTMLInputElement
        setNewConditions(
            newConditions.map((c, i) => {
                if (c.type == conditionTYPES.FAQ && i == conditionIndex) {
                    const queries = R.set(R.lensIndex(index), target.value, c.queries)
                    return { ...c, queries: queries }
                }
                return c
            })
        )
    }
    const onRemoveQuery = (id: number, conditionIndex: number) => (e: SyntheticEvent) => {
        e.preventDefault()
        // if (conditions.length > 1) {
        //     setConditions([...conditions.slice(0, id), ...conditions.slice(id + 1, conditions.length)])
        // }

        setNewConditions(
            newConditions.map((c, i) =>
                c.type == conditionTYPES.FAQ && i == conditionIndex
                    ? { ...c, queries: [...c.queries.slice(0, id), ...c.queries.slice(id + 1, c.queries.length)] }
                    : c
            )
        )
    }
    const onAddMoreCondition = (conditionIndex: number) => {
        setNewConditions(
            newConditions.map((c, i) =>
                c.type == conditionTYPES.FAQ && i == conditionIndex
                    ? {
                          ...c,
                          queries: c.queries.every(data => data !== '') ? [...c.queries, faq.condition] : c.queries
                      }
                    : c
            )
        )

        // conditions.every(data => data !== '') && setConditions([...conditions, faq.condition])
    }
    function handleSave({ buttons, fields, basicInfo }: AdaptiveFieldsT) {
        setBasicInfo(basicInfo)
        setFields(fields)
        setButtons(buttons)
    }
    var IsValidURL = (url: string) =>
        url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

    const faqs = rootDialogs.filter(n => n.type == 'faq_node') as FAQT[]

    const convertedValues = faqs
        .filter(f => f.uuid != node.uuid)
        .map(f => {
            return Array.isArray(f.conditions) ? f.conditions : reduceFn(reverseCond(f.conditions))
        }) as any

    const previousFAQQuries = convertedValues as any

    const loweredConditions: string[] = R.flatten(previousFAQQuries).map((c: any) => c.toLowerCase())
    const loweredConditions1: string[] = newConditions.reduce(
        (acc: any, element: conditionT) => (element.type == conditionTYPES.FAQ ? [...acc, ...element.queries] : acc),
        []
    )
    const duplicates: any = findDuplicateInSamerray(loweredConditions1)

    const AllFields =
        faq.title !== '' &&
        faq.description !== '' &&
        loweredConditions.filter(s => loweredConditions1.indexOf(s) != -1).length == 0 &&
        duplicates.length == 0 &&
        ((draft.blocks.length > 0 && draft.blocks[0].text !== '') ||
            (buttons.filter(
                data =>
                    (data.value.blocks.length > 0 &&
                        data.value.blocks[0].text.length > 0 &&
                        data.label.value.length == 0) ||
                    (data.label.value.length > 0 &&
                        data.value.blocks.length > 0 &&
                        data.value.blocks[0].text.length == 0)
            ).length == 0 &&
                fields.filter(
                    data =>
                        (data.value.blocks.length > 0 &&
                            data.value.blocks[0].text.length > 0 &&
                            data.label.value.length == 0) ||
                        (data.label.value.length > 0 &&
                            data.value.blocks.length > 0 &&
                            data.value.blocks[0].text.length == 0)
                ).length == 0 &&
                basicInfo.title.value.length > 0 &&
                basicInfo.description.blocks.length > 0 &&
                basicInfo.description.blocks[0].text.length > 0 &&
                (basicInfo.image.length !== 0 || IsValidURL(basicInfo.image) === null))) &&
        // conditions.every(data => data !== '') &&
        ((message.blocks.length > 0 && message.blocks[0].text !== '') || dialog.id !== '') &&
        newConditions.filter(data => {
            if (data.type == conditionTYPES.SELECT) {
                return true
            }
            if (data.type == conditionTYPES.INTENT) {
                return data.name == ''
            } else if (data.type == conditionTYPES.ENTITY) {
                if (data.operator == operatorsT.SELECT) {
                    return true
                }
                if (data.name != '') {
                    if (data.operator == operatorsT.IS || data.operator == operatorsT.IS_NOT) {
                        return data.value == ''
                    }
                } else {
                    return true
                }
            } else if (data.type == conditionTYPES.CONTEXT) {
                if (data.operator == operatorsT.SELECT) {
                    return true
                }
                if (data.LHS.type === 'context' && data.LHS.variable != '') {
                    if (
                        data.operator == operatorsT.IS_TRUE ||
                        data.operator == operatorsT.IS_FALSE ||
                        data.operator == operatorsT.IS_PRESENT ||
                        data.operator == operatorsT.IS_NOT_PRESENT
                    ) {
                        return false
                    } else if (typeof data.RHS !== 'boolean') {
                        return data.RHS.blocks[0].text === ''
                    }
                } else {
                    return true
                }
            }
            return false
        }).length == 0
            ? false
            : true
    const onCreateFAQ = async (workspacename: string, dialogId: string) => {
        const cond = generateCond(newConditions)
        const arr = sortBySibling(dialogs)
        let req = 
        !isFolder ? 
        {
            type: 'faq_node',
            title: faq.title,
            description: faq.description,
            conditions: cond,
            responses:
                answerType == 'text_message'
                    ? { type: 'text_message', textexp: [converterToServer(draft)] }
                    : {
                          type: 'information_card',
                          infoexp: [
                              {
                                  action_buttons: buttons
                                      .filter(
                                          b =>
                                              b.label.value.length > 0 &&
                                              b.value.blocks.length > 0 &&
                                              b.value.blocks[0].text.length > 0
                                      )
                                      .map(button => ({
                                          title: button.label,
                                          value: converterToServer(button.value)
                                      })),
                                  fields: fields
                                      .filter(
                                          b =>
                                              b.label.value.length > 0 &&
                                              b.value.blocks.length > 0 &&
                                              b.value.blocks[0].text.length > 0
                                      )
                                      .map(button => {
                                          return {
                                              title: button.label,
                                              value: converterToServer(button.value)
                                          } as AdaptiveServerFieldValueT
                                      }),
                                  title: { type: 'text', value: basicInfo.title.value },
                                  description: converterToServer(basicInfo.description),
                                  image: basicInfo.image.length > 0 ? { type: 'text', value: basicInfo.image } : null
                              }
                          ]
                      },
            previous_sibling: arr.length > 0 ? arr[arr.length - 2].uuid : null,
            next_step: {}
                    
        } : {
            type: 'faq_node',
            title: faq.title,
            description: faq.description,
            conditions: cond,
            responses:
                answerType == 'text_message'
                    ? { type: 'text_message', textexp: [converterToServer(draft)] }
                    : {
                          type: 'information_card',
                          infoexp: [
                              {
                                  action_buttons: buttons
                                      .filter(
                                          b =>
                                              b.label.value.length > 0 &&
                                              b.value.blocks.length > 0 &&
                                              b.value.blocks[0].text.length > 0
                                      )
                                      .map(button => ({
                                          title: button.label,
                                          value: converterToServer(button.value)
                                      })),
                                  fields: fields
                                      .filter(
                                          b =>
                                              b.label.value.length > 0 &&
                                              b.value.blocks.length > 0 &&
                                              b.value.blocks[0].text.length > 0
                                      )
                                      .map(button => {
                                          return {
                                              title: button.label,
                                              value: converterToServer(button.value)
                                          } as AdaptiveServerFieldValueT
                                      }),
                                  title: { type: 'text', value: basicInfo.title.value },
                                  description: converterToServer(basicInfo.description),
                                  image: basicInfo.image.length > 0 ? { type: 'text', value: basicInfo.image } : null
                              }
                          ]
                      },
            previous_sibling: null,
            parent:folderData[0].id,
            next_step: {}
        }
        setLoader(true)
        try {
            if (state === 'create') {
                if (then === 'jump_to') {
                    req = {
                        ...req,
                        next_step: {
                            jump_to: {
                                to: dialog.uuid,
                                selector: 'body'
                            }
                        }
                    }
                } else if (then === 'exit') {
                    req = {
                        ...req,
                        next_step: {
                            exit: {
                                message: converterToServer(message)
                            }
                        }
                    }
                }

                if (arr.length > 0) {
                    // if(isFolder){
                    //     await createFAQNode(workspacename, folderData[0].id, req)
                    // } else{
                        await createFAQNode(workspacename, dialogId, req).then(()=>{
                            queryClient.invalidateQueries(['dialogRoots', dialogId])
                            queryClient.invalidateQueries(['dialogs', dialogId])
                            queryClient.invalidateQueries(['assistants'])

                            if(isFolder){
                                queryClient.invalidateQueries(['dialogDescendants', folderData[0].uuid])
                            }
                        })
                    // }

                    logEvent(workspacename, {
                        event:"EVENT::FAQ::CREATE_FROM_HOMEPAGE",event_meta:{
                       name: faq.title,
                       description: faq.description
                    }})

                    if (journeyData.type === 'success') {
                        const interopretor = journeyData.value
                        interopretor.send({
                            type: 'EVENT::FAQ::CREATED',
                            tag: 'GENERIC',
                            journeyType: 'FAQ',
                            refId: dialogId
                        })
                    }
                } else {
                    await createIfDialogueWithInitial(workspacename, dialogId, req).then(()=>{
                        queryClient.invalidateQueries(['dialogRoots', dialogId])
                        queryClient.invalidateQueries(['dialogs', dialogId])
                        queryClient.invalidateQueries(['assistants'])

                        if(isFolder){
                            queryClient.invalidateQueries(['dialogDescendants', folderData[0].uuid])
                        }
                    })
                    if (journeyData.type === 'success') {
                        const interopretor = journeyData.value
                        interopretor.send({
                            type: 'EVENT::FAQ::CREATED',
                            tag: 'GENERIC',
                            journeyType: 'FAQ',
                            refId: dialogId
                        })
                    }
                }
            } else if (state === 'update') {
                let update = {
                    ...req,
                    id: node.id,
                    created: node.created,
                    updated: node.updated
                }
                if (then === 'jump_to') {
                    update = {
                        ...update,
                        next_step: {
                            jump_to: {
                                to: dialog.uuid,
                                selector: 'body'
                            }
                        }
                    }
                } else if (then === 'exit') {
                    update = {
                        ...update,
                        next_step: {
                            exit: {
                                message: converterToServer(message)
                            }
                        }
                    }
                }
                await updateIfDialogue(workspacename, dialogId, node.uuid, update as any, faq.title).then(()=>{
                    queryClient.invalidateQueries(['dialogRoots', dialogId])
                    queryClient.invalidateQueries(['dialogs', dialogId])
                    queryClient.invalidateQueries(['assistants'])

                    if(isFolder){
                        queryClient.invalidateQueries(['dialogDescendants', folderData[0].uuid])
                    }
                })
            }
            await assistants()
            window.history.replaceState(null, '', window.location.pathname)
            await refresh(onCancel)
        } catch (_) {
            setLoader(false)
        }
    }
    return (
        <VariableContextForFAQ.Provider value={{isUrlOpen,setUrlisOpen}}>
        <NodesWithContext {...props.props}>
                <Wrapper className="faq_popup">
                    {loader ? (
                        <div className="center_loader">
                            <Loader.PopupLoader show={true} />
                        </div>
                    ) : (
                        <PopupRight
                            faq={faq}
                            draft={draft}
                            setDraft={setDraft as () => void}
                            then={then}
                            setThen={setThen as () => void}
                            dialogs={dialogs}
                            GetDialogs={GetDialogs}
                            getFolderDialogs={getFolderDialogs}
                            onChangeFaqState={onChangeFaqState}
                            onAddMoreCondition={onAddMoreCondition}
                            onQueryChange={onQueryChange}
                            onRemoveQuery={onRemoveQuery}
                            dialog={dialog}
                            setDialog={setDialog as () => void}
                            message={message}
                            setMessage={setMessage as () => void}
                            onCancel={onCancel}
                            workspacename={workspacename}
                            dialougeId={dialougeId}
                            onSave={onCreateFAQ}
                            AllFields={AllFields}
                            state={state}
                            updateId={node.id}
                            handleSave={handleSave}
                            answerType={answerType}
                            setAnswerType={setAnswerType}
                            adaptiveCardValue={{ ...basicInfo, action_buttons: buttons, fields }}
                            addMoreNewConditon={addMoreNewConditon}
                            newConditions={newConditions}
                            onConditionChange={onConditionChange}
                            removeCondition={removeCondition}
                            faqId={node.uuid}
                            isFolder={isFolder}
                            folderData={folderData}
                            dialogRootData={dialogRootData}
                        />
                    )}
                </Wrapper>
        </NodesWithContext>
        </VariableContextForFAQ.Provider>
    )
}

const PopupHeader = ({ state }: PopupHeaderT) => {
    const toCamelCase = (title: string) => title.substring(0, 1).toUpperCase() + title.substring(1)
    return (
        <>
            <Header>
                <HeaderLeft minWidth={300}>
                    {toCamelCase(state)} FAQ
                    <Tooltip
                        className="target customTip"
                        zIndex={10000}
                        arrowSize={8}
                        tagName="span"
                        content="Create simple FAQ to answer user queries without the need to create a Dialog conversation."
                        distance={5}
                    >
                        <InfoIcon />
                    </Tooltip>
                    <a
                        title="faq"
                        href="https://help.workativ.com/knowledgebase/create-faq"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="Click  the help document "
                            distance={5}
                        >
                            <QaIconWithCircle />
                        </Tooltip>
                    </a>
                </HeaderLeft>
                <HeaderRight>
                    <Paragraph className="header_right_popup" cursor>
                        <VideoLinkSimple id={'START_WITH_FAQ'} text={'How to create'} />
                    </Paragraph>
                </HeaderRight>
            </Header>
            <Paragraph>Make your chatbot respond to user FAQs.</Paragraph>
        </>
    )
}

const PopupInputWrapper = ({ faq, onChangeFaqState }: PopupInputWrapperT) => {
    return (
        <InputWrapper>
            <InputContainer>
                <Input type="text" name="title" value={faq.title} onChange={onChangeFaqState} />
                <InputContent>FAQ name</InputContent>
            </InputContainer>
            <InputContainer>
                <Input name="description" value={faq.description} onChange={onChangeFaqState} />
                <InputContent>Description</InputContent>
            </InputContainer>
        </InputWrapper>
    )
}

const PopupInputHeaderWrapper = ({
    draft,
    setDraft,
    handleSave,
    answerType,
    setAnswerType,
    adaptiveCardValue
}: InputHeaderWrapperT) => {
    const data = {
        title: {
            type: ExpressionTypeE.TEXT,
            value: ''
        },
        image: null,
        description: { blocks: [], entityMap: {} },
        fields: [
            {
                label: {
                    type: 'text',
                    value: ''
                },
                value: { blocks: [], entityMap: {} }
            }
        ],
        action_buttons: [
            {
                label: {
                    type: 'text',
                    value: ''
                },
                value: { blocks: [], entityMap: {} }
            }
        ]
    }

    return (
        <InputWrapper>
            <DraftInputWrapper className="draft_focus_content">
                <div className="select_work_form faq_adaptive_card">
                    <h4>Then answer user with</h4>
                    <div className="faq_adaptive_card_radios">
                        <SelectBox
                            show={true}
                            isSelected={answerType == 'text_message'}
                            handleSelected={() => {
                                handleSave({
                                    buttons: data.action_buttons as AdaptiveValueT[],
                                    fields: data.fields as AdaptiveValueT[],
                                    basicInfo: {
                                        title: {
                                            type: ExpressionTypeE.TEXT,
                                            value: ''
                                        },
                                        image: '',
                                        description: data.description
                                    }
                                })
                                setAnswerType('text_message')
                            }}
                        />
                        <p>Text message</p>
                        <SelectBox
                            show={true}
                            isSelected={answerType == 'information_card'}
                            handleSelected={() => {
                                setDraft({ blocks: [], entityMap: {} })
                                setAnswerType('information_card')
                            }}
                        />
                        <p>Information card</p>
                    </div>
                    {answerType == 'text_message' ? (
                        <Editor
                            focusContent={'Answer'}
                            DraftMap={draft}
                            setDraftMap={setDraft}
                            focus={
                                convertAsaSingleString(draft.blocks as RawDraftContentBlock[]).replace(/\s/g, '').length
                                    ? 'focus_input'
                                    : ''
                            }
                        />
                    ) : (
                        <AdaptiveResponseUI
                            data={adaptiveCardValue}
                            handleSave={handleSave}
                            loading={false}
                            setLoading={() => {}}
                            error={{ error: null, info: '' }}
                            setError={() => {}}
                            showSaveButtons={false}
                        />
                    )}
                </div>
            </DraftInputWrapper>
        </InputWrapper>
    )
}

const PopupDropdownWrapper = ({
    then,
    setThen,
    message,
    setMessage,
    dialog,
    dialogs,
    GetDialogs,
    setDialog,
    workspacename,
    dialougeId,
    updateId,
    faqId,
    isFolder,
    folderData,
    getFolderDialogs,
    dialogRootData
}: DropDownWrapperT) => {
    return (
        <InputWrapper className="faq_popup_next_step">
            <Header>
                Next step{' '}
                <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="Choose what you the chatbot to do as a next step after answering the user query."
                    distance={3}
                >
                    <InfoIcon />
                </Tooltip>
            </Header>
            <DropdownWrapper>
                <NextStep
                    then={then}
                    dialog={dialog}
                    setDialog={setDialog}
                    GetDialogs={GetDialogs}
                    dialogs={dialogs}
                    setThen={setThen}
                    message={message}
                    setMessage={setMessage}
                    workspacename={workspacename}
                    dialougeId={dialougeId}
                    updateId={updateId}
                    forFaq
                    showNextStepError={faqId && then.length==0 ? true : false}
                    isFolder={isFolder}
                    folderData={folderData}
                    getFolderDialogs={getFolderDialogs}
                    dialogRootData={dialogRootData}
                />
            </DropdownWrapper>
        </InputWrapper>
    )
}

const reduceFn = (arr: any[]) =>
    arr.reduce((acc: any, val: any) => {
        if (val.type == 'User Queries') {
            return [...acc, ...val.queries]
        }
        return acc
    }, [])

const PopupHoverInputWrapper = ({
    conditions,
    onAddMoreCondition,
    onQueryChange,
    onRemoveQuery,
    index,
    dialougeId
}: HoverInputWrapperT & { index: number; conditions: string[]; dialougeId: string }) => {
    const { rootDialogs, dialogueNode } = useContext(DialogueContext)

    const Focus: any = useRef(null)
    const [isSelect, setIsSelect] = React.useState<boolean>(false)
    useEffect(() => {
        if (isSelect && conditions.some(data => data !== '') && Focus.current) {
            Focus.current.focus()
        }
    }, [conditions.length])

    const faqs = rootDialogs.filter(n => n.type == 'faq_node') as FAQT[]

    const convertedValues = faqs
        .filter(f => f.uuid != dialougeId)
        .map(f => {
            return Array.isArray(f.conditions) ? f.conditions : reduceFn(reverseCond(f.conditions))
        }) as any

    const previousFAQQuries = convertedValues as any

    const loweredConditions: string[] = R.flatten(previousFAQQuries).map((c: any) => c.toLowerCase())
    return (
        <>
            <HoverInputContainer className="faq_popup_card_query">
                {conditions &&
                    conditions.map((data: any, i: any) => {
                        const loweredConditions1: string[] = conditions
                            .map((c: any) => c.toLowerCase())
                            .filter((c, j) => j != i)
                        return (
                            <InputContainer key={i}>
                                <Input
                                    type="text"
                                    ref={Focus}
                                    name="condition"
                                    value={data}
                                    onChange={onQueryChange(i, index)}
                                    key=""
                                    style={{padding: '26px 32px 6px 12px'}}
                                />
                                <InputContent>Query {i + 1}</InputContent>
                                {data.length>0 && (loweredConditions.indexOf(data.toLowerCase()) != -1 ||
                                    loweredConditions1.indexOf(data.toLowerCase()) != -1) && (
                                    <span className="connect_account_failed faq">
                                        <div className="error_input_show error_input_show_add">
                                            <WarningAlertIcon />
                                            <span
                                                className="error_menu_hvr faq_alert_content"
                                                style={{
                                                    top: -5,
                                                    left: 20
                                                }}
                                            >
                                                {'Query already exists'}
                                            </span>
                                        </div>
                                    </span>
                                )}
                                <CancelButton onClick={onRemoveQuery(i, index)}>
                                    <CloseBtnWithCircle />
                                </CancelButton>
                            </InputContainer>
                        )
                    })}
                <SmallButton
                    className="float-left faq_popup_card_query_button"
                    //style={{ marginTop: 12 }}
                    primary
                    disabled={conditions.length === 50}
                    onClick={() => {
                        if (conditions.length < 50) {
                            setIsSelect(true)
                            onAddMoreCondition(index)
                        }
                    }}
                >
                    <span>
                        <AddIcon />
                    </span>
                    <label>Add More</label>
                </SmallButton>
            </HoverInputContainer>
        </>
    )
}

// Recognise Card Wrapper
export const DropdownWrapperRecognise = styled.div`
    width: 100%;
    float: left;
    position: relative;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content:  ${props => (props.align && props.align == 'left' ? 'flex-start' : 'center')};
    padding-top: ${props => (props.padding ? '10px' : '20px')};
    padding-bottom: 20px;
    z-index: 0;
    // margin-left: 35px;
    flex-direction: column;
    padding: 20px;

    ${Input} {
        padding-right: 54px;
        }
    }
    .dialog_scroll_div{
        width:100%;
        float:left;
        margin-bottom:20px;
        :last-child{
            margin-bottom:0px;
        }
    }
    ${SmallButton}{
        margin-top:-8px;
    }
`
export const ConditionExecuteWrapper = styled.div`
    width: 100%;
    float: left;

    ${InputWrapper} {
        padding: 0px;
        margin-bottom: 20px;
        :last-child {
            margin-bottom: 0px;
        }
    }
    h4 {
        margin-bottom: 12px;
    }
    ${CancelButton} {
        display: flex;
        top: 16px;
    }
    .automation_scroly {
        top: -18px;
    }
    .automation_scroly.verify_user_popup{
        top: 0;
    }

    .draft_focus_content{
        .dialog_context_input_edit_true ${FocusContent}{
            top: 18px;
        }
        .input_selected_dialog ${FocusContent} {
            top: 8px;
        }
        .focus_input ${FocusContent} {
            top: 8px;
        }

    }
`


const ButtonWrapper = ({ onCancel, AllFields, onSave, workspacename, dialougeId }: ButtonWrapperT) => {
    const variableContextFAQ: VariableContextForFAQT = React.useContext(VariableContextForFAQ)
    return (
        <ButtonContainer className="footer_button_fixed">
            <div className="popup_footer_button_container">
                <Button primary onClick={onCancel}>
                    <label>Cancel</label>
                </Button>
                <Button
                    className={AllFields||variableContextFAQ.isUrlOpen ? 'editor_btn_disabled' : ''}
                    disabled={AllFields|| variableContextFAQ.isUrlOpen}
                    onClick={() => {
                        onSave(workspacename, dialougeId)
                    }}
                >
                    <label>Save</label>
                </Button>
            </div>
        </ButtonContainer>
    )
}

const PopupRight = ({
    faq,
    draft,
    setDraft,
    then,
    setThen,
    dialogs,
    GetDialogs,
    onChangeFaqState,
    onAddMoreCondition,
    onQueryChange,
    onRemoveQuery,
    dialog,
    setDialog,
    message,
    setMessage,
    onCancel,
    workspacename,
    dialougeId,
    onSave,
    AllFields,
    state,
    updateId,
    handleSave,
    answerType,
    setAnswerType,
    adaptiveCardValue,
    addMoreNewConditon,
    newConditions,
    onConditionChange,
    removeCondition,
    faqId,
    isFolder,
    folderData,
    getFolderDialogs,
    dialogRootData
}: FAQPopupRightT) => {
    return (
        <PopupWrapper>
            <PopupContainer>
                <PopupHeader state={state} />
                <PopupScrollHeight>
                    <EmptyDiv>
                        <PopupInputWrapper faq={faq} onChangeFaqState={onChangeFaqState} />
                        <InputWrapper bgColor="true" className="faq_popup_card">
                            {newConditions.map((element: conditionT, index: number) =>
                                element.type == conditionTYPES.COMBINATOR ? (
                                    <CombinatorCompoenent
                                        key={index}
                                        condition={element}
                                        onChange={(condition: any) => onConditionChange(index, condition)}
                                    />
                                ) : (
                                    <>
                                        <ConditionalStatements
                                            condition={element}
                                            conditions={newConditions}
                                            key={index}
                                            onChange={(condition: any) => onConditionChange(index, condition)}
                                            remove={() => removeCondition(index)}
                                            showUserQuery
                                            index={index > 0 ? index / 2 : index}
                                            showFaq={
                                                newConditions.filter(c => c.type == conditionTYPES.FAQ).length == 0
                                            }
                                        />
                                        {element.type == conditionTYPES.FAQ && (
                                            <PopupHoverInputWrapper
                                                conditions={element.queries}
                                                onAddMoreCondition={onAddMoreCondition}
                                                onQueryChange={onQueryChange}
                                                onRemoveQuery={onRemoveQuery}
                                                index={index}
                                                dialougeId={faqId}
                                            />
                                        )}
                                    </>
                                )
                            )}
                        </InputWrapper>

                        {newConditions.filter(cond => cond.type == conditionTYPES.SELECT).length == 0 ? (
                            <div className="add_connection_automation" style={{ marginBottom: 10, marginTop: 0 }}>
                                <div className="show_options set_advanced">
                                    <SmallButton primary onClick={() => addMoreNewConditon()}>
                                        <span>
                                            <AddIcon />
                                        </span>
                                        <label>Add more conditions</label>
                                    </SmallButton>
                                </div>
                            </div>
                        ) : null}

                        <PopupInputHeaderWrapper
                            draft={draft}
                            setDraft={setDraft}
                            handleSave={handleSave}
                            answerType={answerType}
                            setAnswerType={setAnswerType}
                            adaptiveCardValue={adaptiveCardValue}
                        />
                        <PopupDropdownWrapper
                            then={then}
                            setThen={setThen}
                            message={message}
                            setMessage={setMessage}
                            GetDialogs={GetDialogs}
                            getFolderDialogs={getFolderDialogs}
                            dialogs={dialogs}
                            dialog={dialog}
                            setDialog={setDialog}
                            workspacename={workspacename}
                            dialougeId={dialougeId}
                            updateId={updateId}
                            faqId={faqId}
                            isFolder={isFolder}
                            folderData={folderData}
                            dialogRootData={dialogRootData}
                        />
                    </EmptyDiv>
                    <ButtonWrapper
                        onCancel={onCancel}
                        onSave={onSave}
                        AllFields={AllFields}
                        workspacename={workspacename}
                        dialougeId={dialougeId}
                    />
                </PopupScrollHeight>
            </PopupContainer>
        </PopupWrapper>
    )
}
