import React, { useContext, useEffect, useState } from 'react'
/*eslint-disable*/
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { VideoLink } from '@/Videos/Videos'
import { AddIcon, InfoIcon, MinusIcon, QaIconWithCircle } from '@/common/Icons/Icons'
//ICONS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
// Loader import
import { Loader } from '@/common/components/Loader'
import {
    EmptyDiv,
    Header,
    HeaderLeft,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { HeaderRight } from '@/common/styled/FaqPopup.Dumb'

//COMMON COMPONENTS IMPORT
import { Button } from '../../../Common/Effective'
import { IfNodeT, QueryStatus } from '../../../Dialogue/kind'
//TYPE IMPORT
//ACTIONS IMPORT
import { createIfDialogue, updateIfDialogue } from '../../../Mechanisms/action'
//CONTEXT IMPORT
import { DialogueContext } from '../../Context'
import { ErrorState, ErrorT, IF_DIALOGUE, VIEW } from '../../kind'
import { HeaderWithSwitch } from '../Exit'
import CombinatorCompoenent from './CombinatorCompoenent'
import ConditionalStatements from './ConditionalStatements'
import DialogNameComponent from './DialogName'
import Summary from './Summary'
import { generateCond, reverseCond } from './action'
import { combinatorValueT, conditionT, conditionTYPES, operatorsT } from './kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'
// import { useIsMounted } from '@/common/hooks/useIsMounted'

const Ramda = require('ramda')

// import { Button } from '../../../Assistant/Dialogue/BotDetails'

const DivWrapper = styled.div`
    width: auto;
    float: left;
    display: flex;
    margin-bottom: 12px;
    :last-child {
        margin-bottom: 0px;
    }
`
const SwitchWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 22px 20px;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    ${HeaderWithSwitch} {
        display: flex;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
        display: flex;
        align-items: center;

        span {
            color: #999999;
            width: 12px;
            height: 12px;
            margin-left: 6px;
            top: 2px;
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
`

const DilogTips = () => (
    <div className="">
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/building-a-dialog/"
            >
                Create Dialog
            </a>{' '}
            helps the bot recognize user utterance or intent (to start a conversation or dialog).
        </p>
        <p>
            {' '}
            You can specify conditions on how the dialog is executed using the 'create dialog’ pop up. Based on the
            user's intent and condition, the bot can then initiate an appropriate conversation or take action on user’s
            intent or query.
        </p>
        {/* <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p> */}
    </div>
)

const RecogniseDialog = () => {
    const {
        workspaceName,
        dialogueName,
        assistantID,
        PageState,
        setPageState,
        fetchCallback,
        parentIfnode,
        assistant,
        queryClient
    } = useContext(DialogueContext)

    const { data } = PageState as IF_DIALOGUE

    const isWelcomeNode = data.uuid == assistant.welcome_node

    const context = useContext(DialogueContext)

    const rootIfnode = parentIfnode as IfNodeT

    const [dialog, setDialog] = useState({ name: '', description: '' })

    const [loading, setLoading] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [digression, setDigression] = useState<{
        allowDigression: boolean
        canEnterFromOther: boolean
        returnBack: boolean
    }>({
        allowDigression: false,
        canEnterFromOther: false,
        returnBack: false
    })

    const [showDigression, setShowDigression] = useState<boolean>(false)

    const [conditions, setConditions] = useState<conditionT[]>([])
    const ifNode = (data as IfNodeT)

    const [status , setStatus] = useState(ifNode.update_query_status_on_enter==null?QueryStatus.Ignore:ifNode.update_query_status_on_enter)
    const [collapseStatusSection,setCollapseStatusSection] = useState(ifNode.update_query_status_on_enter==null?false:true)


    const [scrollableDivRef, scrollTo] = useScrollHook()
    // const isMounted = useIsMounted()

    const onChange = (index: number, condition: conditionT) =>
        setConditions(element => element.map((e: conditionT, i: number) => (i == index ? condition : e)))

    const remove = (index: number) =>
        setConditions(condition =>
            condition.filter((e: any, i: number) => {
                const conditionIndex = index == 0 ? index + 1 : index - 1
                if (i != index && i != conditionIndex) return e
                return false
            })
        )
    const addMore = () =>
        setConditions(condition =>
            condition.concat([
                {
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                },
                {
                    type: conditionTYPES.SELECT
                }
            ])
        )

    useEffect(() => {
        const { data } = PageState as IF_DIALOGUE
        if (data.id === '') {
            setConditions([
                {
                    type: conditionTYPES.SELECT
                }
            ])
        } else {
            if (data.conditions === null || data.conditions.type === 'WelcomeExpression' ) {
                setConditions([
                    {
                        type: conditionTYPES.SELECT
                    }
                ])
            } else {
                const reversedCondition = reverseCond(data.conditions, context, context.dialogueNode)
                setConditions(isWelcomeNode? reversedCondition.slice(2,reversedCondition.length):reversedCondition)
            }
            const da = data as IfNodeT
            setDigression({
                allowDigression: da.allow_digression,
                canEnterFromOther: da.allow_digression_into,
                returnBack: da.allow_return_from_here
            })
            setDialog({ name: data.title, description: data.description })
        }
    }, [])

    const onSave = () => {
        setLoading(true)
        const { data } = PageState as IF_DIALOGUE
        const isWelcomeDialog = data.uuid == assistant.welcome_node
        const cond =
        conditions.length == 0
            ? { type: 'WelcomeExpression' }
          : generateCond(conditions, isWelcomeDialog);

        const newConditions =  cond
        if (data.id === '') {
            createIfDialogue(
                workspaceName,
                assistantID,
                {
                    ...data,
                    title: dialog.name,
                    description: dialog.description,
                    conditions: newConditions,
                    allow_digression: digression.allowDigression,
                    allow_digression_into: digression.canEnterFromOther,
                    allow_return_from_here: digression.returnBack,
                    update_query_status_on_enter:
                    collapseStatusSection
                    ? status
                    : null,
                },
                dialogueName
            )
                .then(() => {
                    queryClient.invalidateQueries(['dialogRoots', assistantID])
                    queryClient.invalidateQueries(['dialogs', assistantID])
                    queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
                    queryClient.invalidateQueries(['assistants'])
                    logEvent(workspaceName, {
                        event:"EVENT::DIALOG::CREATE_CONDITION", event_meta:{
                        name: dialog.name,
                        description: dialog.description
                    }})
                    
                    fetchCallback(() => {
                        setLoading(false)
                    }, conditions.length == 0 ? "fromdialog" : undefined)
                })
                .catch(() => {
                    setError({ error: true, info: '' })
                })
        } else {
            updateIfDialogue(
                workspaceName,
                assistantID,
                data.uuid,
                {
                    ...data,
                    title: dialog.name,
                    description: dialog.description,
                    conditions: newConditions,
                    allow_digression: digression.allowDigression,
                    allow_digression_into: digression.canEnterFromOther,
                    allow_return_from_here: digression.returnBack,
                    update_query_status_on_enter:
                    collapseStatusSection
                    ? status
                    : null,

                } as IfNodeT,
                dialogueName
            )
                .then(() => {
                   queryClient.invalidateQueries(['dialogRoots', assistantID])
                   queryClient.invalidateQueries(['dialogs', assistantID])
                   queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
                   queryClient.invalidateQueries(['assistants'])
                   data.conditions == null && logEvent(workspaceName, {
                        event:"EVENT::DIALOG::CREATE_CONDITION", event_meta:{
                        name: dialog.name,
                        description: dialog.description
                    }})
                    fetchCallback(() => {
                        setLoading(false)
                    }, conditions.length == 0? "fromdialog" : undefined )
                })
                .catch(() => {
                    setError({ error: true, info: '' })
                })
        }
    }


    return (
        <Wrapper className="create_dialog_popup">
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Create dialog
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<DilogTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/building-a-dialog/" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'SET_CONDITIONS_FOR_A_DIALOG'} text={'How to create'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Set conditions to execute this dialog.</Paragraph>
                    <PopupScrollHeight ref={scrollableDivRef}>
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            {loading ? (
                                <div className="popup_loader">
                                    <Loader.PopupLoader show={loading} />
                                </div>
                            ) : (
                                <>
                                    <EmptyDiv>
                                        <div className="">
                                            <div className="automation_canvas_right_popup_form">
                                                <div className="recognise_popup_right">
                                                    <div className="automation_rightside_form automation_rightside_step_two">
                                                        <DialogNameComponent dialog={dialog} setDialog={setDialog} />
                                                        <Header style={{ margin: '8px 0px 8px 0px' }}>
                                                            Conditions to execute this dialog
                                                        </Header>
                                                        {dialog.name !== ''
                                                            ? conditions.map((element, index) =>
                                                                  element.type == conditionTYPES.COMBINATOR ? (
                                                                      <CombinatorCompoenent
                                                                          key={index}
                                                                          condition={element}
                                                                          onChange={(condition: any) =>{
                                                                            // console.log("DOING :::")
                                                                            //   scrollTo('bottom', index === conditions.length - 1)
                                                                              onChange(index, condition)
                                                                            }
                                                                          }
                                                                      />
                                                                  ) : (
                                                                      <ConditionalStatements
                                                                          condition={element}
                                                                          conditions={conditions}
                                                                          key={index}
                                                                          index={index > 0 ? index / 2 : index}
                                                                            onChange={(condition: any) => {
                                                                                // console.log("DOING :::",isMounted())
                                                                                // scrollTo('bottom', index === conditions.length - 1)
                                                                                onChange(index, condition)
                                                                            }
                                                                            }
                                                                          remove={() => remove(index)}
                                                                          isWelcomeNode={assistant.welcome_node == data.uuid}
                                                                          conditionEdit={
                                                                              data.conditions
                                                                                  ? reverseCond(data.conditions)[index]
                                                                                  : null
                                                                          }
                                                                          callbackWhenChooserisOpen={() => scrollTo('bottom',index === conditions.length - 1)}
                                                                      />
                                                                  )
                                                              )
                                                            : null}
                                                        {dialog.name !== '' &&
                                                        // conditions.length < 9 &&
                                                        conditions.filter(cond => cond.type == conditionTYPES.SELECT)
                                                            .length == 0 ? (
                                                            <div
                                                                className="add_connection_automation"
                                                                style={{ marginBottom: 20 }}
                                                            >
                                                                <div className="show_options set_advanced">
                                                                    <SmallButton primary onClick={() => {
                                                                        addMore(); 
                                                                        scrollTo('bottom')
                                                                    }
                                                                        }>
                                                                        <span>
                                                                            <AddIcon />
                                                                        </span>
                                                                        <label>Add more conditions</label>
                                                                    </SmallButton>
                                                                    {rootIfnode.id == data.id && (
                                                                        <SmallButton
                                                                            primary
                                                                            onClick={() =>{
                                                                                scrollTo('bottom')
                                                                                setShowDigression(!showDigression)
                                                                            }
                                                                            }
                                                                        >
                                                                            {showDigression ? (
                                                                                <span>
                                                                                    <MinusIcon />
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    <AddIcon />
                                                                                </span>
                                                                            )}
                                                                            <label>Set advanced conditions</label>
                                                                        </SmallButton>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            // to show only for root if node
                                                            rootIfnode.id == data.id && (
                                                                <div
                                                                    className="add_connection_automation"
                                                                    style={{ marginBottom: 20 }}
                                                                >
                                                                    <div className="show_options set_advanced">
                                                                        <SmallButton
                                                                            style={{ visibility: 'hidden' }}
                                                                        ></SmallButton>
                                                                        <SmallButton
                                                                            primary
                                                                            onClick={() =>
                                                                                setShowDigression(!showDigression)
                                                                            }
                                                                        >
                                                                            {showDigression ? (
                                                                                <span>
                                                                                    <MinusIcon />
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    <AddIcon />
                                                                                </span>
                                                                            )}
                                                                            <label>Set advanced conditions</label>
                                                                        </SmallButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                        {rootIfnode.id == data.id && showDigression && (
                                                            <EmptyDiv>
                                                                <EmptyDiv>
                                                                    <div className="row">
                                                                        <div className="get_users_wrapper">
                                                                            <div className="slots_right_bot">
                                                                                <h4 className='title'> Dialog resolution status
                                                                                    <div className="popup_show_hide">
                                                                                        <div className="show_hide">
                                                                                            <div className="switch_box box_1">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="switch_1 widget_switch"
                                                                                                    checked={
                                                                                                        collapseStatusSection
                                                                                                    }
                                                                                                    onChange={() =>{}}
                                                                                                    onClick={()=>setCollapseStatusSection(!collapseStatusSection)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </h4>
                                                                            {collapseStatusSection &&
                                                                                <>
                                                                                <Paragraph className="recognize_dialog_fontsize row first_point">
                                                                                <div className="show_hide">
                                                                                        <div className="switch_box box_1 radio">
                                                                                            <input
                                                                                                type="radio"
                                                                                                // className={`switch_1`}
                                                                                                checked={status=='resolved'}
                                                                                                onChange={() => {
                                                                                                    setStatus(QueryStatus.Resolved)
                                                                                                }}
                                                                                                name="status_jump_or_exit"

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    Mark dialog as resolved                                                          
                                                                                </Paragraph>
                                                                                <Paragraph className="recognize_dialog_fontsize row">
                                                                                <div className="show_hide">
                                                                                        <div className="switch_box box_1 radio">
                                                                                            <input
                                                                                                type="radio"
                                                                                                // className={`switch_1`}
                                                                                                checked={status=='unresolved'}
                                                                                                onChange={() => {
                                                                                                    setStatus(QueryStatus.Unresolved)
                                                                                                }}
                                                                                                name="status_jump_or_exit"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    Mark dialog as unresolved                                                           
                                                                                </Paragraph>
                                                                                <Paragraph className="recognize_dialog_fontsize row single">
                                                                                <div className="show_hide">
                                                                                        <div className="switch_box box_1 radio">
                                                                                            <input
                                                                                                type="radio"
                                                                                                // className={`switch_1`}
                                                                                                checked={status=='ignore'}
                                                                                                onChange={() => {
                                                                                                    setStatus(QueryStatus.Ignore)
                                                                                                }}
                                                                                                name="status_jump_or_exit"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    Do not mark dialog resolution status
                                                                                    {/* <Tooltip
                                                                                        className="target customTip verify_user_tooltips tooltips_color"
                                                                                        zIndex={10000}
                                                                                        arrowSize={4}
                                                                                        tagName="span"
                                                                                        content="Do not mark dialog resolution status"
                                                                                        distance={5}
                                                                                    >
                                                                                        <InfoIcon />
                                                                                    </Tooltip>                                                                */}
                                                                                </Paragraph>
                                                                                </>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </EmptyDiv>
                                                                <SwitchWrapper>
                                                                    <HeaderWithSwitch>
                                                                        <p className="recognize_dialog_fontsize">
                                                                            Allow dialog switching{' '}
                                                                            {/* <a
                                                                                href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                                                                target="_blank"
                                                                            >
                                                                                <Tooltip
                                                                                    className="target customTip"
                                                                                    zIndex={10000}
                                                                                    arrowSize={5}
                                                                                    tagName="span"
                                                                                    content="View help document"
                                                                                    distance={5}
                                                                                >
                                                                                    <QaIconWithCircle />
                                                                                </Tooltip>
                                                                            </a> */}
                                                                        </p>
                                                                        <div className="popup_show_hide">
                                                                            <div className="show_hide">
                                                                                <div className="switch_box box_1">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="switch_1 widget_switch"
                                                                                        checked={
                                                                                            digression.allowDigression
                                                                                        }
                                                                                        onChange={() =>
                                                                                            digression.allowDigression ==
                                                                                            true
                                                                                                ? setDigression({
                                                                                                      ...digression,
                                                                                                      allowDigression: !digression.allowDigression,
                                                                                                      canEnterFromOther: false,
                                                                                                      returnBack: false
                                                                                                  })
                                                                                                : setDigression({
                                                                                                      ...digression,
                                                                                                      allowDigression: !digression.allowDigression
                                                                                                  })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </HeaderWithSwitch>
                                                                </SwitchWrapper>
                                                                <SwitchWrapper>
                                                                    <HeaderWithSwitch className="flex-column">
                                                                        <DivWrapper>
                                                                            <p className="recognize_dialog_fontsize">
                                                                                Can enter this dialog when switching from other dialog
                                                                                {/* <a
                                                                                    href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                                                                    target="_blank"
                                                                                >
                                                                                    <Tooltip
                                                                                        className="target customTip"
                                                                                        zIndex={10000}
                                                                                        arrowSize={5}
                                                                                        tagName="span"
                                                                                        content="View help document"
                                                                                        distance={5}
                                                                                    >
                                                                                        <QaIconWithCircle />
                                                                                    </Tooltip>
                                                                                </a> */}
                                                                            </p>
                                                                            <div className="popup_show_hide">
                                                                                <div className="show_hide">
                                                                                    <div className="switch_box box_1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className={
                                                                                                digression.allowDigression
                                                                                                    ? 'switch_1 widget_switch'
                                                                                                    : 'switch_1 editor_btn_disabled'
                                                                                            }
                                                                                            checked={
                                                                                                digression.allowDigression
                                                                                                    ? digression.canEnterFromOther
                                                                                                    : false
                                                                                            }
                                                                                            onChange={() =>
                                                                                                digression.allowDigression &&
                                                                                                (digression.canEnterFromOther ==
                                                                                                true
                                                                                                    ? setDigression({
                                                                                                          ...digression,
                                                                                                          canEnterFromOther: !digression.canEnterFromOther,
                                                                                                          returnBack: false
                                                                                                      })
                                                                                                    : setDigression({
                                                                                                          ...digression,
                                                                                                          canEnterFromOther: !digression.canEnterFromOther
                                                                                                      }))
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </DivWrapper>
                                                                        <DivWrapper>
                                                                            <p className="recognize_dialog_fontsize">
                                                                                Return back to original dialog (after completing this dialog)
                                                                                {/* <a
                                                                                    href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                                                                    target="_blank"
                                                                                >
                                                                                    <Tooltip
                                                                                        className="target customTip"
                                                                                        zIndex={10000}
                                                                                        arrowSize={5}
                                                                                        tagName="span"
                                                                                        content="View help document"
                                                                                        distance={5}
                                                                                    >
                                                                                        <QaIconWithCircle />
                                                                                    </Tooltip>
                                                                                </a> */}
                                                                            </p>
                                                                            <div className="popup_show_hide">
                                                                                <div className="show_hide">
                                                                                    <div className="switch_box box_1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className={
                                                                                                digression.canEnterFromOther
                                                                                                    ? 'switch_1 widget_switch'
                                                                                                    : 'switch_1 editor_btn_disabled'
                                                                                            }
                                                                                            checked={
                                                                                                digression.canEnterFromOther
                                                                                                    ? digression.returnBack
                                                                                                    : false
                                                                                            }
                                                                                            onChange={() =>
                                                                                                digression.canEnterFromOther &&
                                                                                                setDigression({
                                                                                                    ...digression,
                                                                                                    returnBack: !digression.returnBack
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </DivWrapper>
                                                                    </HeaderWithSwitch>
                                                                </SwitchWrapper>
                                                            </EmptyDiv>
                                                        )}
                                                        {dialog.name !== '' &&
                                                        conditions.filter(cond => cond.type == conditionTYPES.SELECT)
                                                            .length == 0 ? (
                                                            <Summary conditions={conditions} />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </EmptyDiv>
                                    <Button
                                        className="footer_button_fixed"
                                        disable={
                                            dialog.name == '' ||
                                            dialog.name.length > 80 ||
                                            conditions.filter(data => {
                                                if (data.type == conditionTYPES.SELECT) {
                                                    return true
                                                }
                                                if (data.type == conditionTYPES.INTENT) {
                                                    return data.name == ''
                                                } else if (data.type == conditionTYPES.ENTITY) {
                                                    if (data.operator == operatorsT.SELECT) {
                                                        return true
                                                    }
                                                    if (data.name != '') {
                                                        if (
                                                            data.operator == operatorsT.IS ||
                                                            data.operator == operatorsT.IS_NOT
                                                        ) {
                                                            return data.value == ''
                                                        }
                                                    } else {
                                                        return true
                                                    }
                                                } else if (data.type == conditionTYPES.CONTEXT) {
                                                    if (data.operator == operatorsT.SELECT) {
                                                        return true
                                                    }
                                                    if (data.LHS.type === 'context' && data.LHS.variable != '') {
                                                        if (
                                                            data.operator == operatorsT.IS_TRUE ||
                                                            data.operator == operatorsT.IS_FALSE ||
                                                            data.operator == operatorsT.IS_PRESENT ||
                                                            data.operator == operatorsT.IS_NOT_PRESENT
                                                        ) {
                                                            return false
                                                        } else if (typeof data.RHS !== 'boolean') {
                                                            return data.RHS.blocks[0].text === ''
                                                        }
                                                    } else {
                                                        return true
                                                    }
                                                }
                                                return false
                                            }).length > 0
                                        }
                                        onCancel={() => setPageState(VIEW)}
                                        onSave={() => onSave()}
                                    />
                                </>
                            )}
                        </ErrorBoundary>
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export default RecogniseDialog
