import { useQuery } from 'react-query'
import { getJson, automationAPIURLWithWorkspace } from '@/common/utils/api-utils'
import { AppsListT } from './canvas/types'

const getActions = (workspace: string) => {
    // console.log('getActions')
    return getJson(automationAPIURLWithWorkspace(workspace, '/actions'))
}

export const useActionsCached = (workspace: string) => {
    const { data, isFetching } = useQuery<AppsListT>('actions', () => getActions(workspace), {
        refetchOnMount: false,
        staleTime: Infinity
    })
    // console.log('data', data, isFetching)

    return [data, isFetching] as [AppsListT, boolean]
}
