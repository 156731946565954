import React from 'react';
import { Content, Content2, TipTapT } from './LiveAgentTypes';
import * as R from 'ramda';
import { JSONContent } from '@tiptap/react';
import { tipTapEmoji } from './EmojiList';

interface RichTextBlock {
  type: string;
  block_id: string;
  elements: RichTextSection[];
}

interface RichTextSection {
  type: string;
  elements: RichTextElement[];
}

interface RichTextElement {
  type: string;
  url?: string;
  text?: string;
  style?: {
    bold?: boolean;
    italic?: boolean;
    strike?: boolean;
  };
}

type ResBody = (OptionRes | TextRes | AdaptiveCard) & {
  notification_title: string;
};

type OptionRes = { type: 'option'; title: Markdown; options: SingleOption[] };
type SingleOption = { label: Markdown; value: Markdown };
type TextRes = { type: 'text'; text: Markdown };
type AdaptiveCard = {
  type: 'adaptive_card';
  title: Markdown;
  description: Markdown;
  logo: string;
  fields: AdaptiveCardField[];
  buttons: AdaptiveCardBtn[];
};

type AdaptiveCardField = { title: Markdown; value: Markdown };
type AdaptiveCardBtn = { text: Markdown; url: string };

export type Markdown =
  | NewLine
  | NormalText
  | BoldText
  | ItalicText
  | UnderLine
  | OrderedList
  | UnOrderedList
  | HyperLink
  | JoinText
  | PathExpression;

type NewLine = { md_type: 'md_newline' };
type NormalText = { md_type: 'md_normal'; value: string };
type ItalicText = { md_type: 'md_italic'; value: Markdown };
type BoldText = { md_type: 'md_bold'; value: Markdown };
type UnderLine = { md_type: 'md_underline'; value: Markdown };
type OrderedList = { md_type: 'md_ordred_list'; list: Markdown[] };
type UnOrderedList = { md_type: 'md_unordred_list'; list: Markdown[] };
type HyperLink = { md_type: 'md_hyperlink'; link: string; value: Markdown };
type JoinText = { md_type: 'md_join'; lhs: Markdown; rhs: Markdown };
type PathExpression = { md_type: 'md_path_expression'; value: string };

export const jsonFormat: JSONContent = {
  type: 'doc',
  content: [
    {
      type: 'orderedList',
      attrs: { start: 1 },
      content: [
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: 'Hi there,' }],
            },
          ],
        },
        {
          type: 'listItem',
          content: [
            { type: 'paragraph', content: [{ type: 'text', text: 'adjakda' }] },
          ],
        },
        {
          type: 'listItem',
          content: [
            { type: 'paragraph', content: [{ type: 'text', text: 'adasD' }] },
          ],
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', text: 'this is a ' },
        { type: 'text', marks: [{ type: 'italic' }], text: 'basic' },
        { type: 'text', text: ' example of ' },
        { type: 'text', marks: [{ type: 'bold' }], text: 'tiptap' },
        {
          type: 'text',
          text: '. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:',
        },
      ],
    },
    {
      type: 'bulletList',
      content: [
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                { type: 'text', text: 'That’s a bullet list with one …' },
              ],
            },
          ],
        },
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: '… or two list items.' }],
            },
          ],
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:',
        },
      ],
    },
    {
      type: 'codeBlock',
      attrs: { language: 'css' },
      content: [{ type: 'text', text: 'body {\\ndisplay: none;\\n}' }],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.',
        },
      ],
    },
    {
      type: 'blockquote',
      content: [
        {
          type: 'paragraph',
          content: [
            { type: 'text', text: 'Wow, that’s amazing. Good work, boy! ' },
            { type: 'emoji', attrs: { name: 'clap' } },
            { type: 'text', text: ' ' },
            { type: 'hardBreak' },
            { type: 'text', text: '— Mom' },
          ],
        },
      ],
    },
  ],
};

const values = [
  {
    type: 'join',
    lhs: {
      type: 'text',
      value: 'edkhafkjd ',
    },
    rhs: {
      type: 'join',
      lhs: {
        type: 'bold',
        value: {
          type: 'text',
          value: 'sfsd',
        },
      },
      rhs: {
        type: 'italic',
        value: {
          type: 'bold',
          value: {
            type: 'text',
            value: 'fasd',
          },
        },
      },
    },
  },
];
const Heading = {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: { level: 3 },
      content: [{ type: 'text', text: 'What’s the bug you are facing?' }],
    },
  ],
};
const join_ast_result = (mdResult: any[]) => {
  const reversedMd = mdResult.reverse();
  const joinMdArr = reversedMd.reduce((acc, mdata, index) => {
    if (index == 0) {
      return mdata;
    } else {
      return {
        md_type: 'md_join',
        lhs: mdata,
        rhs: acc,
      };
    }
  }, {});
  console.log(mdResult, joinMdArr, 'mdResult');
  return joinMdArr;
};
export const tiptapJsonConverter = (jsonStructure: JSONContent) => {
  //   const jsonStructure = JSON.parse(slackMsg);
  // console.log(jsonStructure, 'jsonStructure');
  //Convert JSON to Markdown types
  const result = jsonStructure.content
    ? jsonStructure.content.map((con) => convertToMarkdown(con))
    : [];
  const flattenres = R.intersperse([{ md_type: 'md_newline' }], result);
  // if (result.length >1){

  // }
  // console.log(result, flattenres, 'resultrich');
  return join_ast_result(R.flatten(flattenres));
};

export const flattenArray = (tiptapJson: JSONContent) => {
  const arrayContent = tiptapJson.content;
  // console.log(arrayContent, 'flattenArr');
};

const convertToMarkdown = (content: JSONContent) => {
  // console.log('mdhook ::230', content);
  const parsed: any[] = [];
  if (content.type === 'bulletList' || content.type === 'orderedList') {
    const value = listConvertion(content);
    parsed.push(value);
  } else if(content.type === 'blockquote'){
    const value = content.content?.map((c) => convertToMarkdown(c))
    parsed.push(value)
  }else {
    if (content.content) {
      const value = content.content?.map((con) => {
        const conValue = mdConvertion(con);
        // console.log(conValue, 'result');
        return conValue;
      });
      parsed.push(value);
    } else {
      parsed.push({
        md_type: 'md_normal',
        value: '',
      });
    }
  }
  // console.log(parsed, 'parsedresult');
  return parsed;
};
const emojiFromName = (name: any) => {
  const emoji = tipTapEmoji.filter((em) => em.name === name.name)[0].emoji;
  // console.log(emoji, name, 'emojifromname');
  return emoji;
};
export const mdConvertion = (content: JSONContent) => {
  // console.log('mdhook ::mdconvert', content);

  if (content.type === 'text' && content.marks) {
    // const text = { type : "md_normal" , value : content.text}
    // return content.marks.reduce((acc:Markdown,current) => {
    //   return {
    //       md_type : `md_${current.type}`,
    //       value : {
    //        ...acc
    //     }
    //   }
    // },text)

    if (content.marks.length > 1) {
      const islink = content.marks.filter((con) => con.type === 'link');
      // console.log(islink,'linkmd')
      if (islink.length > 0) {
        return {
          md_type: 'md_hyperlink',
          link: islink[0].attrs?.['href'],
          value: { md_type: 'md_normal', value: content.text },
        };
      } else {
        const text = { md_type : "md_normal" , value : content.text}
          return content.marks.reduce((acc:Markdown,current) => {
            return {
                md_type : `md_${current.type}`,
                value : {
                ...acc
              }
            }
          },text)
      }
    } else {
      const islink = content.marks.filter((con) => con.type === 'link');
      // console.log(islink,'linkmd')
      if (islink.length > 0) {
        // console.log(islink, islink[0].attrs?.['href'], 'islink');
        return {
          md_type: 'md_hyperlink',
          link: islink[0].attrs?.['href'],
          value: { md_type: 'md_normal', value: content.text },
        };
      } else {
        return {
          md_type: `md_${content.marks[0].type}`,
          value: { md_type: 'md_normal', value: content.text },
        };
      }
    }
    // Handle other styles as needed
  } else if (content.type === 'text') {
    if (content.text == '\n') {
      return { md_type: 'md_newline' };
    } else {
      return { md_type: 'md_normal', value: content.text };
    }
  } else if (content.type === 'emoji') {
    return { md_type: 'md_normal', value: emojiFromName(content.attrs) };
  } else if(content.type === 'hardBreak'){
    return {
      md_type: 'md_newline'
    }
  } else if(content.type === "mention"){
    return { 
      md_type: 'md_mention',
      value: { name: content.attrs?.['label'], id: content.attrs?.['id']}
  }
  }else {
    console.log('else::', content);
    return content;
  }
  //return undefined;
};

export const listConvertion = (content: JSONContent) => {
  // console.log('mdhook ::listconvert', content);

  if (content.type === 'bulletList') {
    const listItem = content.content?.map((c) => tiptapJsonConverter(c));
    console.log(listItem, 'listItem');
    return {
      md_type: 'md_unordred_list',
      list: listItem, 
    };
  } else {
    const listItem = content.content?.map((c) => tiptapJsonConverter(c));
    return {
      md_type: 'md_ordred_list',
      list: listItem,
    };
  }
};
