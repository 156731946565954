import * as React from 'react'
/*eslint-disable*/
import * as ReactDOM from 'react-dom'

import {
    addHandlerForLogout,
    applyOnce,
    initializeAccessTokenElection,
    loadSessionStorage
} from '@/common/user-sessions/across-tabs'

import { AppWithCache } from './App'

import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useNavigationType,
    useLocation
  } from "react-router-dom";

import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: "https://8738410ef6329fcbe61d171ccb96a48c@o4508166189023232.ingest.us.sentry.io/4508166190596096",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

/*eslint-disable*/


// initialize logout across tabs
addHandlerForLogout((_:any) => {
    // console.log('Logging out...')
    location.replace(window.location.origin + '/authentication/u')
})

initializeAccessTokenElection().then(_ => {
    // document.title = '♛ ' + 'Leader'
    console.log('♛')
})

loadSessionStorage()

applyOnce().then((_:any) => {
    console.log("React DOM :: version" , ReactDOM.version,ReactDOM)
    // console.log("VERSION CHECK",(window as any).React1.version === (window as any).React2.version);
    const root = document.getElementById('root') as HTMLElement
    ReactDOM.render(<AppWithCache />, root)
})
