import React from 'react'
import styled from 'styled-components/macro'

import { VideoButtonB } from '@/Videos/Videos'
import { ApptriggerIcon, DialogIcon, FaqICon, FlowIcon, QaIconWithCircle, UserTriggerIcon, WebhooksIcon } from '@/common/Icons/Icons'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { DownloadBotHelp, DownloadContent, ImageSpan } from '@/common/styled/GetStarted.Dumb'

import perfectIcon from '@/common/images/perfect.svg'
import startIcon from '@/common/images/stat.svg'
import { MainHeader } from '../Components/WelcomePageWithJourney'
import { getProductId } from '@/common/utils/utils'

interface WrapperBgProps {
    /* This prop is optional, since TypeScript won't know that it's passed by the wrapper */
    topPadding?: 'small'
}

const WrapperBg = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: ${props => (props.topPadding === 'small' ? '30px 0px' : '40px 0px')};
    width: ${props => (props.bgWidth ? '840px' : '100%')};
    float: left;
`
const Wrapper = styled.section`
    width: 100%;
    float: left;
    padding: 48px 20px 20px;
    height: 100%;
    padding-bottom: 60px;
    scroll-behavior: smooth;
    display: flex;
    justify-content: center;
    align-items: baseline;
`

type AfterBotStartedP = {
    openCreateFaqDialog: () => void
    openCreateDialog: () => void
    redirectToWorkflow: () => void
    botName: string
}

export const AfterBotStarted = (props: AfterBotStartedP) => {
    const botName = props.botName
    return (
        <>
            {getProductId() === "ASSISTANT" ? (
                <><MainHeader header={'Workativ Assistant'} butonWrapper />
                <ThreeWayPick
                    botName={botName}
                    openCreateFaqDialog={props.openCreateFaqDialog}
                    openCreateDialog={props.openCreateDialog}
                    redirectToWorkflow={props.redirectToWorkflow} /></>
            ):
            (
                <>
            <MainHeader header={'Workativ Automate'} butonWrapper />
            <AutomateThreeWayPick
                botName={botName}
                openCreateFaqDialog={props.openCreateFaqDialog}
                openCreateDialog={props.openCreateDialog}
                redirectToWorkflow={props.redirectToWorkflow}
            />
            </>)}
            <HeaderRow>
                {/* <p>
                    Note: It doesn’t matter whether you start with FAQs or conversations, you are building the same bot.
                </p> */}
            </HeaderRow>
            {/* <CardsTwo /> */}
        </>
    )
}

export const ThreeWayPick = (props: AfterBotStartedP) => {
    return (
        <Wrapper>
            <WrapperBg topPadding="small">
                <ThreeHeaderContainer botName={props.botName} />
                <CardsRow>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <FaqICon />
                                </span>
                            </IconWrapper>
                            <h4>FAQ</h4>
                            <p>
                                Start building your bot by automating simple FAQs. Think of it as a quick jump start to
                                your bot that can answer simple FAQ questions. And you can always add simple or complex
                                conversations later using our bot builder.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.openCreateFaqDialog}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_FAQ'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-faq/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                <span className="dialog_start_with">How to start with FAQ</span>
                            </DownloadBotHelp>
                        </DownloadContent>
                    </Card>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <DialogIcon />
                                </span>
                            </IconWrapper>
                            <h4>Conversational</h4>
                            <p>
                                Start building your bot by creating conversations. Conversations are multi-turn dialogs
                                that helps bot engage with end users in any level of complexity and usecase. It's super
                                easy to create conversations using our bot builder.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.openCreateDialog}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_CONVERSATION'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-conversational/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                <span className="dialog_start_with">How to start with conversations</span>
                            </DownloadBotHelp>
                        </DownloadContent>
                    </Card>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <FlowIcon />
                                </span>
                            </IconWrapper>
                            <h4>App Workflows</h4>
                            <p>
                                Start budling app workflows for your bot. Think of app workflows as automations that
                                connect your bot with your apps to perform actions or tasks so you can automate process
                                and end-user requests directly from your bot.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.redirectToWorkflow}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_APP_WORKFLOW'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-app-workflows/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                How to start with app workflows
                            </DownloadBotHelp>
                        </DownloadContent>
                    </Card>
                </CardsRow>
                <HeaderRow>
                    {/* <p style={{ marginTop: '10px' }}>
                        Note: It doesn’t matter whether you start with FAQs or Conversations, you are building the same
                        bot.
                    </p> */}
                </HeaderRow>
            </WrapperBg>
        </Wrapper>
    )
}

// autoamte product
export const AutomateThreeWayPick = (props: AfterBotStartedP) => {
    return (
        <Wrapper>
            <WrapperBg topPadding="small">
                <AutomateHeaderContainer botName={props.botName} />
                <CardsRow>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <ApptriggerIcon />
                                </span>
                            </IconWrapper>
                            <h4>Application Trigger</h4>
                            <p>
                            Create workflows using application triggers. A workflow is triggered automatically when an event occurs in an app. 
                            e.g When a new user is created in ServiceNow (app trigger), execute a workflow to add the new user in Office 365.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.openCreateFaqDialog}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_FAQ'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            {/* <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-faq/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                <span className="dialog_start_with">How to start with FAQ</span>
                            </DownloadBotHelp> */}
                        </DownloadContent>
                    </Card>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <UserTriggerIcon />
                                </span>
                            </IconWrapper>
                            <h4>User Trigger</h4>
                            <p>
                            Create workflows for user to trigger manually. A workflow is triggered when a user or support team 
                            wants to perform an action. e.g When a helpdesk agent wants to add a new user to a 
                            email distribution group in Office 365.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.openCreateDialog}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_CONVERSATION'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            {/* <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-conversational/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                <span className="dialog_start_with">How to start with conversations</span>
                            </DownloadBotHelp> */}
                        </DownloadContent>
                    </Card>
                    <Card>
                        <ContentWrapper>
                            <IconWrapper>
                                <span>
                                    <WebhooksIcon />
                                </span>
                            </IconWrapper>
                            <h4>Webhook</h4>
                            <p>
                            Create workflows as webhooks. A workflow is triggered by a webhook call from 
                            a 3rd party endpoint (a chatbot or an app integration point). 
                            e.g When a chatbot needs to reset a user password in Okta.
                            </p>
                        </ContentWrapper>
                        <ButtonWrapper>
                            <Button onClick={props.redirectToWorkflow}>Get Started</Button>
                            <VideoButtonB id={'START_WITH_APP_WORKFLOW'} />
                        </ButtonWrapper>
                        <DownloadContent top="small">
                            {/* <DownloadBotHelp
                                href="https://help.workativ.com/knowledgebase/start-with-app-workflows/"
                                target="_blank"
                                style={{ margin: 'auto' }}
                            >
                                <ImageSpan imagePadding="small">
                                    <QaIconWithCircle />
                                </ImageSpan>
                                How to start with app workflows
                            </DownloadBotHelp> */}
                        </DownloadContent>
                    </Card>
                </CardsRow>
                <HeaderRow>
                    {/* <p style={{ marginTop: '10px' }}>
                        Note: It doesn’t matter whether you start with FAQs or Conversations, you are building the same
                        bot.
                    </p> */}
                </HeaderRow>
            </WrapperBg>
        </Wrapper>
    )
}

type DialogOrFAQP = { openCreateFaqDialog: () => void; openCreateDialog: () => void }

export const DialogOrFAQ = (props: DialogOrFAQP) => {
    return (
        <>
            <Wrapper>
                <WrapperBg bgWidth>
                    <HeaderContainer headerTwoContainer />
                    <FaqOrDialogCards
                        openCreateFaqDialog={props.openCreateFaqDialog}
                        openCreateDialog={props.openCreateDialog}
                    />
                    <HeaderRow>
                        <p style={{ marginTop: '10px' }}>
                            Note: It doesn’t matter whether you start with FAQs or Conversations, you are building the
                            same bot.
                        </p>
                    </HeaderRow>
                </WrapperBg>
            </Wrapper>
        </>
    )
}

const HeaderH1 = styled.h1`
    width: 100%;
    float: left;
    font-size: 24px;
    line-height: 24px;
    color: #3da27e;
    font-family: 'Gordita-Medium';
    margin-bottom: ${props => (props.margin ? '12px' : '5px')};
    text-align: center;

    span {
        width: 64px;
        height: 64px;

        img {
            width: 64px;
            height: 64px;
        }
    }
`
const HeaderH2 = styled.h2`
    width: 100%;
    float: left;
    font-size: 24px;
    line-height: 40px;
    color: #000000;
    font-family: 'Gordita-Medium';
    margin-bottom: 12px;
    text-align: center;
    span {
        background: #ddfeef;
        line-height: 40px;
        height: 40px;
        padding: 8px 5px;
    }
`
const HeaderH4 = styled.h4`
    width: 100%;
    float: left;
    line-height: 18px;
    font-size: 18px;
    color: #000000;
    font-family: 'Gordita-Regular';
    margin-bottom: 12px;
    text-align: center;
`
const HeaderRow = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        width: 100%;
        float: left;
        line-height: 12px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #355d8b;
        margin-bottom: 0px;
        text-align: center;
    }
`

const ThreeHeaderContainer = (props: { botName: string }) => {
    return (
        <>
            <HeaderRow>
                <HeaderH1>
                    <span style={{ marginTop: '5px', marginRight: '-8px;' }}>Perfect</span>{' '}
                    <span style={{ marginLeft: '3px' }}>
                        <img src={perfectIcon} style={{ paddingBottom: '13px', paddingRight: '13px' }} alt="dialog" />
                    </span>
                </HeaderH1>
                <HeaderH2>
                    Build your <span>{props.botName}</span> now!
                </HeaderH2>
                <HeaderH4>What would you like to build today ?</HeaderH4>
                <p>You can start building your bot by creating FAQs, conversations, or app workflows.</p>
            </HeaderRow>
        </>
    )
}

// automate product header 
const AutomateHeaderContainer = (props: { botName: string }) => {
    return (
        <>
            <HeaderRow>
                <HeaderH1>
                    {/* <span style={{ marginTop: '5px', marginRight: '-8px;' }}>Perfect</span>{' '} */}
                    <span style={{ marginLeft: '3px' }}>
                        <img src={perfectIcon} style={{ paddingBottom: '13px', paddingRight: '13px' }} alt="dialog" />
                    </span>
                </HeaderH1>
                <HeaderH2>
                    Start with
                </HeaderH2>
                {/* <HeaderH4>What would you like to build today ?</HeaderH4> */}
                <p>You can start building your bot by creating FAQs, conversations, or app workflows.</p>
            </HeaderRow>
        </>
    )
}

const HeaderContainer = (props: any) => {
    return (
        <>
            {props.headerThreeContainer && (
                <HeaderRow>
                    <HeaderH1>
                        <span style={{ marginTop: '5px', marginRight: '-8px;' }}>Perfect</span>{' '}
                        <span style={{ marginLeft: '3px' }}>
                            <img
                                src={perfectIcon}
                                style={{ paddingBottom: '13px', paddingRight: '13px' }}
                                alt="dialog"
                            />
                        </span>
                    </HeaderH1>
                    <HeaderH2>
                        Build your <span>{props.botName}</span> now!
                    </HeaderH2>
                    <HeaderH4>What would you like to build today?</HeaderH4>
                    <p>You can start building your bot by creating FAQs, conversations, or app workflows.</p>
                </HeaderRow>
            )}
            {props.headerTwoContainer && (
                <HeaderRow>
                    <HeaderH1 margin>
                        <span>
                            <img src={startIcon} alt="dialog" />
                        </span>
                    </HeaderH1>
                    <HeaderH4>Start with</HeaderH4>
                    <p>You can start building your bot by creating FAQs or conversations.</p>
                </HeaderRow>
            )}
        </>
    )
}

const IconWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    span {
        width: 64px;
        height: 64px;
        background: #DEFFF2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 28px;
            height: 28px;
            fill: #333333;
        }
    }
`
const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    // padding-bottom: 40px;
    ${Button} {
        margin: 0px;
        max-width: max-content;
    }
`
const ContentWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
`
const Card = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #b9b9b9;
    justify-content: space-between;

    :last-child {
        border: none;
    }
    h4 {
        font-size: 12px;
        line-height: 12px;
        color: #111111;
        font-family: 'Gordita-Medium';
        margin-bottom: 6px;
        display: flex;
        justify-content: center;
    }
    p {
        font-size: 12px;
        line-height: 19px;
        color: #355d8b;
        font-family: 'Gordita-Regular';
        margin-bottom: 20px;
        text-align: center;
    }
`
const CardsRow = styled.section`
    width: 100%;
    float: left;
    margin-top: 40px;
    display: grid;
    padding: 0px 10px;
    grid-template-columns: ${props =>
        props.gridWidth ? 'repeat(auto-fill, minmax(44%, 1fr));' : 'repeat(auto-fill, minmax(33%, 1fr));'};
`

const CardsWrapper = () => {
    return (
        <CardsRow>
            <Card>
                <ContentWrapper>
                    <IconWrapper>
                        <span>
                            <FaqICon />
                        </span>
                    </IconWrapper>
                    <h4>FAQ</h4>
                    <p>
                        Start building your bot by automating simple FAQs. Think of it as a quick jump start to your bot
                        that can answer simple FAQ questions. And you can always add simple or complex conversations
                        later using our bot builder.
                    </p>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button>Get Started</Button>
                    <VideoButtonB id={'START_WITH_APP_WORKFLOW'} />
                </ButtonWrapper>
                <DownloadContent>
                    <DownloadBotHelp
                        href="https://help.workativ.com/knowledgebase/start-with-faq/"
                        target="_blank"
                        style={{ margin: 'auto' }}
                    >
                        <ImageSpan>
                            <QaIconWithCircle />
                        </ImageSpan>
                        <span className="dialog_start_with">How to start with FAQ</span>
                    </DownloadBotHelp>
                </DownloadContent>
            </Card>
            <Card>
                <ContentWrapper>
                    <IconWrapper>
                        <span>
                            <DialogIcon />
                        </span>
                    </IconWrapper>
                    <h4>Conversational</h4>
                    <p>
                        Start building your bot by creating conversations. Conversations are multi-turn dialogs that
                        helps bot engage with end users in any level of complexity and usecase. It's super easy to
                        create conversations using our bot builder.
                    </p>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button>Get Started</Button>
                    <VideoButtonB id={'START_WITH_APP_WORKFLOW'} />
                </ButtonWrapper>
                <DownloadContent>
                    <DownloadBotHelp
                        href="https://help.workativ.com/knowledgebase/start-with-conversational/"
                        target="_blank"
                        style={{ margin: 'auto' }}
                    >
                        <ImageSpan>
                            <QaIconWithCircle />
                        </ImageSpan>
                        <span className="dialog_start_with">How to start with conversations</span>
                    </DownloadBotHelp>
                </DownloadContent>
            </Card>
            <Card>
                <ContentWrapper>
                    <IconWrapper>
                        <span>
                            <FlowIcon />
                        </span>
                    </IconWrapper>
                    <h4>App Workflows</h4>
                    <p>
                        Start budling app workflows for your bot. Think of app workflows as automations that connect
                        your bot with your apps to perform actions or tasks so you can automate process and end-user
                        requests directly from your bot.
                    </p>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button>Get Started</Button>
                </ButtonWrapper>
                <DownloadContent>
                    <DownloadBotHelp
                        href="https://help.workativ.com/knowledgebase/start-with-app-workflows/"
                        target="_blank"
                        style={{ margin: 'auto' }}
                    >
                        <ImageSpan>
                            <QaIconWithCircle />
                        </ImageSpan>
                        How to start with app workflows
                    </DownloadBotHelp>
                </DownloadContent>
            </Card>
            <Card>
                <ContentWrapper>
                    <p style={{ margin: 'auto', position: 'relative', left: '197px', marginTop: '10px' }}>
                        Note: it doesn’t matter whether you start with FAQs or conversations, you are building the same
                        bot.
                    </p>
                </ContentWrapper>
            </Card>
        </CardsRow>
    )
}

const FaqOrDialogCards = (props: DialogOrFAQP) => {
    return (
        <CardsRow gridWidth>
            <Card>
                <ContentWrapper>
                    <IconWrapper>
                        <span>
                            <FaqICon />
                        </span>
                    </IconWrapper>
                    <h4>FAQ</h4>
                    <p>
                        Start building your bot by automating simple FAQs. Think of it as a quick jump start to your bot
                        that can answer simple FAQ questions. And you can always add simple or complex conversations
                        later using our bot builder.
                    </p>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button onClick={props.openCreateFaqDialog}>Get Started</Button>
                    <VideoButtonB id={'START_WITH_FAQ'} />
                </ButtonWrapper>
                <DownloadContent top="small">
                    <DownloadBotHelp
                        href="https://help.workativ.com/knowledgebase/start-with-faq/"
                        target="_blank"
                        style={{ margin: 'auto' }}
                    >
                        <ImageSpan imagePadding="small">
                            <QaIconWithCircle />
                        </ImageSpan>
                        <span className="dialog_start_with">How to start with FAQ</span>
                    </DownloadBotHelp>
                </DownloadContent>
            </Card>
            <Card>
                <ContentWrapper>
                    <IconWrapper>
                        <span>
                            <DialogIcon />
                        </span>
                    </IconWrapper>
                    <h4>Conversational</h4>
                    <p>
                        Start building your bot by creating conversations. Conversations are multi-turn dialogs that
                        helps bot engage with end users in any level of complexity and usecase. It's super easy to
                        create conversations using our bot builder.
                    </p>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button onClick={props.openCreateDialog}>Get Started</Button>
                    <VideoButtonB id={'START_WITH_CONVERSATION'} />
                </ButtonWrapper>
                <DownloadContent top="small">
                    <DownloadBotHelp
                        href="https://help.workativ.com/knowledgebase/start-with-conversational/"
                        target="_blank"
                        style={{ margin: 'auto' }}
                    >
                        <ImageSpan imagePadding="small">
                            <QaIconWithCircle />
                        </ImageSpan>
                        <span className="dialog_start_with">How to start with conversations</span>
                    </DownloadBotHelp>
                </DownloadContent>
            </Card>
            {/* <Card>
                <ContentWrapper>
                    <p style={{ margin: 'auto', position: 'relative', left: '197px', marginTop: '10px' }}>
                        Note: it doesn’t matter whether you start with FAQs or conversations, you are building the same
                        bot.
                    </p>
                </ContentWrapper>
            </Card> */}
        </CardsRow>
    )
}
