import Downshift from 'downshift'
import * as ramda from 'ramda'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { ExpressionTypeE } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import {
    AddIcon,
    CrossIcon,
    DownArrowCircle,
    DropdownWithCircle,
    InfoIcon,
    MinusIcon,
    QaIconWithCircle
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
//COMMON MODULE IMPORTS
import lazyGA from '@/common/google-analytics/tracking'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    EmptyDiv,
    Header,
    HeaderLeft,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'
import { Input, InputContainer, InputContent, InputWrapper, Textarea } from '@/common/styled/Workflow.Analytics.Dumb'

// TYPE IMPORTS
import { AttachmentT, AttchValuesT, DialogueT, IfNodeT, SlotExitConditions } from '../../Dialogue/kind'
//ACTION IMPORTS
import { createResponseDialogue, getDialogues, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext } from '../Context'
import { ATTACH_RESPONSE, ErrorState, ErrorT, VIEW } from '../kind'
import { HeaderWithSwitch } from './Exit'
import ConditionalStatements from './RecogniseDialog/ConditionalStatements'
import { generateCond, reverseCond } from './RecogniseDialog/action'
import { conditionT, conditionTYPES, operatorsT } from './RecogniseDialog/kind'
import { logEvent } from '@/common/utils/api-utils'
import { useScrollHook } from '@/common/hooks/useScrollHook'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { sortAnArrayByName } from '@/common/utils/common.utils'

// import { AddIcon, DeleteIcon, EditIcon, CheckIcon, CrossIcon, DownArrowCircle } from '@/common/Icons'

const SwitchWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 24px 20px 22px 20px;
    background: #f4f4f4;
    margin-bottom: 10px;
    ${HeaderWithSwitch} {
        margin-top: 0px;
    }
    h4 {
        font-size: 12px !important;
        font-family: 'Gordita-Regular';
        line-height: normal;
        justify-content: flex-start !important;
        align-items: center;

        .target.customTip {
            height: 14px;
        }
        .popup_show_hide {
            top: 2px;
        }
    }
`
const FocusContent = styled.div`
    width: auto;
    float: left;
    color: #999999;
    font-size: 10px;
    font-family: 'Gordita-Regular';
    position: absolute;
    left: 12px;
    top: 10px;
    line-height: 10px;
    transition: 0.26s ease-in;

    .target.customTip {
        pointer-events: initial;
        cursor: pointer;
        padding-left: 6px;
        position: relative;
        top: 0px;
        width: auto !important;
        float: right !important;
    }
    .target.customTip svg {
        width: 9px;
        height: 9px;
        position: relative;
        top: 0px;
        right: 0px;
    }
`

const FileUploadTips = () => (
    <>
        <p>
            Use{' '}
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/file-upload/"
            >
                Upload attachment
            </a>{' '}
            when you want the user to upload files such as images, pdf, video to the bot. A URL for the file will be
            generated and stored as context variable (for further processing).
        </p>
        <p>
            <b>Note:</b> Bot can receive only one file at a time.
        </p>
    </>
)

const Formats = [
    { type: 'Image', value: 'Image (Format: png,jpeg)', desc: 'Image size should not exceed "25MB"' },
    { type: 'File', value: 'File (Format: Pdf, Excel, Word)', desc: 'File size should not exceed "25MB"' },
    { type: 'Video', value: 'Video (Format: mp4, AVI)', desc: 'Video size should not exceed "25MB"' }
]

type DialogChooserT = {
    show: boolean
    title: string
    dialogId: string
    showDropDown: boolean
    jumpOrExit: string
    respondWith: string
    existing:boolean
}[]

const Slot = ({
    slot,
    setConditions,
    setAttachment,
    showSaveButton,
    conditions,
    dialogChooser,
    setDialogChooser,
    parentScrollCallback
}: {
    slot: AttchValuesT
    setConditions: Function
    setAttachment: Function
    showSaveButton: Function
    conditions: conditionT[]
    dialogChooser: DialogChooserT
    setDialogChooser: Function
    parentScrollCallback : (shouldScroll:boolean) => void
}) => {
    const { intents, entities, PageState, workspaceName, assistantID, jumptoRoutes } = useContext(DialogueContext)
    const context = useContext(DialogueContext)
    const { data } = PageState as ATTACH_RESPONSE

    const [mode, setMode] = useState<'view' | 'edit'>('edit')

    const [prompt, setPrompt] = useState<string>(slot.prompt.value)

    const [variable, setVariable] = useState<string>(slot.variable)

    const [errorMessage, setErrorMessage] = useState<string>(slot.error_message ? slot.error_message.value : '')

    const [keycode, setKeycode] = useState<number>(13)

    const [metacode, setMetacode] = useState<boolean>(false)

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [categoryChooser, setCategoryChooser] = useState<boolean>(false)

    const [categoryValue, setCategoryValue] = useState<string>(
        slot.category.charAt(0).toUpperCase() + slot.category.slice(1)
    )

    const [deleteModal, setDeleteModal] = useState({ show: false, index: 0 })

    const [jumpOrExitDropdown, setJumpOrExitDropdown] = useState<null | number>(null)

    const [rootDialogues, setRootDialogues] = useState<DialogueT[]>(jumptoRoutes ? jumptoRoutes : [])

    const onChange = (index: number, condition: conditionT) =>
        setConditions((element: conditionT[]) =>
            element.map((e: conditionT, i: number) => (i == index ? condition : e))
        )

    useEffect(() => {
        setAttachment({
            category: categoryValue,
            prompt: { type: ExpressionTypeE.TEXT, value: prompt },
            variable: variable,
            error_message: { type: ExpressionTypeE.TEXT, value: errorMessage }
        } as AttchValuesT)
    }, [categoryValue, variable, prompt, errorMessage])

    useEffect(() => {
        const { data } = PageState as ATTACH_RESPONSE
        if (data.id === '') {
            setConditions([])
        } else {
            setConditions(
                ramda.flatten(
                    data.file_exit_conditions.map(cond => reverseCond(cond.condition, context, context.dialogueNode))
                ) as any
            )
        }

        getDialogueNodes(data)
    }, [])

    function getDialogueNodes(data: AttachmentT) {
        getDialogues(workspaceName, assistantID)
            .then((res: DialogueT[]) => {
                // const WelcomeNode = res
                //     .filter((e: DialogueT) => (e.conditions ? e.conditions.type === 'WelcomeExpression' : false))
                //     .pop()

                // const FallbackNode = res
                //     .filter((e: DialogueT) => (e.conditions ? e.conditions.type === 'FallBackExpression' : false))
                //     .pop()

                // const jumptoRoutes = sortBySibling(res.filter(e => e.parent === null))

                if (data.id !== '') {

                    setDialogChooser(
                        data.file_exit_conditions.map(d => {
                            const next_step = d.next_step as any
                            if (next_step.jump_to) {
                                const matchDialog: DialogueT = (jumptoRoutes as DialogueT[]).filter(dia => dia.uuid == next_step.jump_to.to || dia.id == next_step.jump_to.to)[0]
                                return {
                                    show: true,
                                    title: matchDialog ? matchDialog.title : "",
                                    dialogId: matchDialog ? matchDialog.id : "",
                                    showDropDown: false,
                                    jumpOrExit: 'jump',
                                    respondWith: d.response.type == 'text' ? d.response.value : '',
                                    existing:true
                                }
                            }

                            return {
                                show: true,
                                title: '',
                                dialogId: '',
                                showDropDown: false,
                                jumpOrExit: 'exit',
                                respondWith: d.response.type == 'text' ? d.response.value : '',
                                existing:true
                            }
                        })
                    )
                }
            })
            .catch(err => {
                // setError({
                //     error: true,
                //     info: `Server error!<br />Please try again after sometime.`
                // })
                // setLoader(false)
            })
    }

    useEffect(() => {
        showSaveButton(showAddMoreIf())
    }, [showAddMoreIf()])

    function showAddMoreIf() {
        return (
            dialogChooser.length == 0 ||
            (dialogChooser.length > 0 &&
                dialogChooser.filter(
                    d =>
                        (d.jumpOrExit != 'jump' || (d.jumpOrExit == 'jump' && d.dialogId.length > 0)) &&
                        d.respondWith.length > 0
                ).length > 0 &&
                conditions.filter(data => {
                    if (data.type == conditionTYPES.INTENT) {
                        return data.name == ''
                    } else if (data.type == conditionTYPES.ENTITY) {
                        if (data.operator == operatorsT.SELECT) {
                            return true
                        }
                        if (data.name != '') {
                            if (data.operator == operatorsT.IS || data.operator == operatorsT.IS_NOT) {
                                return data.value == ''
                            }
                        } else {
                            return true
                        }
                    } else if (data.type == conditionTYPES.SELECT) {
                        return true
                    } else if (data.type == conditionTYPES.CONTEXT) {
                        if (data.operator == operatorsT.SELECT) {
                            return true
                        }
                        if (data.LHS.type === 'context' && data.LHS.variable != '') {
                            if (data.LHS.variable != '') {
                                if (
                                    data.operator == operatorsT.IS_TRUE ||
                                    data.operator == operatorsT.IS_FALSE ||
                                    data.operator == operatorsT.IS_PRESENT ||
                                    data.operator == operatorsT.IS_NOT_PRESENT
                                ) {
                                    return false
                                } else if (typeof data.RHS !== 'boolean') {
                                    return data.RHS.blocks[0].text === ''
                                }
                            } else {
                                return true
                            }
                        } else {
                            return true
                        }
                    }
                    return false
                }).length == 0)
        )
    }

    function showDeleteModal() {
        return deleteModal.show ? (
            <Modal showPopupLoader={false}>
                <React.Fragment>
                    {/* <h2>Delete {PageState.value.value}?</h2> */}
                    <p>Are you sure you want to delete selected value?</p>{' '}
                </React.Fragment>

                <ButtonContainer style = {{paddingBottom: 0}}>
                    <Button primary type="button" onClick={() => setDeleteModal({ show: false, index: 0 })}>
                        <label>No</label>
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            setDialogChooser(dialogChooser.filter((d, i) => i != deleteModal.index))
                            setConditions(conditions.filter((c, i) => i != deleteModal.index))
                            setDeleteModal({ show: false, index: 0 })
                        }}
                    >
                        <label>Yes</label>
                    </Button>
                </ButtonContainer>
            </Modal>
        ) : null
    }

    return (
        // <div className={mode === 'view' ? 'slots_left_bot' : 'slots_right_bot plus_addmore_edit'}>
        <div className="slots_right_bot plus_addmore_edit attachment_upload_wrapper">
            <div className="automation_condition_form get_user_details_form">
                <InputWrapper>
                    <InputContainer>
                        <Textarea value={prompt} onChange={(e: any) => setPrompt(e.target.value)} />
                        <InputContent>Prompt user with message (to upload attachment)</InputContent>
                    </InputContainer>
                </InputWrapper>

                <div
                    className={`select_work_form select_work_form_top_0`}
                    style={{ marginBottom: 20, marginTop: 10, position: 'relative' }}
                >
                    <div
                        className="input_attach_wrapper"
                        onClick={() => setCategoryChooser(true)}
                        onChange={() => {}}
                        // className={`${mode == 'view' ? 'disable_edit' : ''}`}
                    >
                        <FocusContent>
                            Attachment type
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={Formats.filter((type, key) => type.type == categoryValue)[0].desc}
                                distance={5}
                            >
                                <QaIconWithCircle />
                            </Tooltip>
                        </FocusContent>
                        {Formats.filter((type, key) => type.type == categoryValue)[0].value}
                    </div>

                    {categoryChooser && (
                        <DropdownUlWrapper>
                            <Downshift isOpen={true} onOuterClick={() => setCategoryChooser(false)}>
                                {() => (
                                    <div className="automation_scroly">
                                        <DropdownUl>
                                            {Formats.map((type, key) => (
                                                <DropdownLi
                                                    key={key}
                                                    onClick={() => {
                                                        setCategoryChooser(false)
                                                        setCategoryValue(type.type)
                                                    }}
                                                >
                                                    <p>{type.value}</p>
                                                </DropdownLi>
                                            ))}
                                        </DropdownUl>{' '}
                                    </div>
                                )}
                            </Downshift>
                        </DropdownUlWrapper>
                    )}
                    <div className="arrow_up_form" onClick={() => setCategoryChooser(true)}>
                        <DropdownWithCircle />
                    </div>
                </div>
                <InputWrapper>
                    <InputContainer style={{ marginBottom: 20 }}>
                        <Input
                            type="text"
                            value={errorMessage}
                            // onKeyDown={(e: any) => {
                            //     setMetacode(e.metaKey || e.ctrlKey ? true : false)
                            //     setKeycode(e.keyCode)
                            // }}
                            onChange={(e: any) => {
                                if (keycode !== 32 && metacode === false) {
                                    setErrorMessage(e.target.value.replace(/[^\w\s]/gi, ''))
                                }
                            }}
                        />
                        <InputContent>Error message when attachment size exceeded</InputContent>
                    </InputContainer>
                </InputWrapper>
                <InputWrapper>
                    <InputContainer style={{ marginBottom: 0 }}>
                        <Input
                            type="text"
                            value={variable}
                            onKeyDown={(e: any) => {
                                setMetacode(e.metaKey || e.ctrlKey ? true : false)
                                setKeycode(e.keyCode)
                            }}
                            onChange={(e: any) => {
                                if (keycode !== 32 && metacode === false) {
                                    setVariable(e.target.value.replace(/[^\w\s]/gi, ''))
                                }
                            }}
                        />
                        <InputContent>Save attachment as</InputContent>
                    </InputContainer>
                </InputWrapper>
                {/* Advanced Options Start */}
                <div className="advanced_options_wrapper get_user__details_wrapper upload_attachment">
                    <div className="add_connection_automation" style={{ display: 'inline-flex' }}>
                        <SmallButton
                            primary
                            onClick={() => {
                                parentScrollCallback(true)
                                if (dialogChooser.length === 0 && mode != 'view') {
                                    setDialogChooser([
                                        ...dialogChooser,
                                        {
                                            show: true,
                                            title: '',
                                            dialogId: '',
                                            showDropDown: false,
                                            jumpOrExit: 'jump',
                                            respondWith: ''
                                        }
                                    ])

                                    setConditions([
                                        ...conditions,
                                        {
                                            type: conditionTYPES.SELECT
                                        }
                                    ])
                                }
                            }}
                        >
                            <span>{dialogChooser.length !== 0 ? <MinusIcon /> : <AddIcon />}</span>
                            <label>Set advanced conditions ({dialogChooser.length})</label>
                        </SmallButton>
                        <span style={{ marginLeft: '5px', marginTop: 4 }}>
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={
                                    'Setting advanced condition helps the bot handle specific text, value or input in user response, to the bot question.'
                                }
                                distance={5}
                            >
                                <span className="info_icon">
                                    <InfoIcon />
                                </span>
                            </Tooltip>
                        </span>
                    </div>

                    {/* {showAdvancedOptions && ( */}
                    <>
                        <div className="assistant_recognizes">
                            {/* close recognise dialog */}
                            {dialogChooser.map((dialog, index) => {
                                const updateState = (value: {
                                    show: boolean
                                    title: string
                                    dialogId: string
                                    showDropDown: boolean
                                    jumpOrExit: string
                                    respondWith: string
                                    existing:boolean
                                }) => {
                                    setDialogChooser(
                                        dialogChooser.map((d, j) => {
                                            if (j == index) {
                                                return value
                                            }
                                            return d
                                        })
                                    )
                                }
                                if (!dialog.show) {
                                    return (
                                        <div className="assistant_recognizes_header assistant_recognizes_hide">
                                            <Header className="justify-content-start">
                                                Set condition{' '}
                                                <span>({index + 1 < 10 ? '0' + (index + 1) : index + 1})</span>
                                            </Header>
                                            {
                                                <div className="icons_right_close">
                                                    <span
                                                        onClick={() => setDeleteModal({ show: true, index: index })}
                                                        className={mode == 'view' ? 'disable_edit' : ''}
                                                    >
                                                        <CrossIcon />
                                                    </span>
                                                    <span onClick={() => updateState({ ...dialog, show: true })}>
                                                        <DownArrowCircle />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    )
                                }

                                return (
                                    <>
                                        {/* close recognise dialog */}
                                        <div className="assistant_recognizes_header">
                                            <Header className="justify-content-start">
                                                Set condition{' '}
                                                <span>({index + 1 < 10 ? '0' + (index + 1) : index + 1})</span>
                                            </Header>
                                            {
                                                <div className="icons_right_close">
                                                    <span
                                                        onClick={() => setDeleteModal({ show: true, index: index })}
                                                        className={mode == 'view' ? 'disable_edit' : ''}
                                                    >
                                                        {/* <MinusIcon /> */}
                                                        <CrossIcon />
                                                    </span>
                                                    <span onClick={() => updateState({ ...dialog, show: false })}>
                                                        <DownArrowCircle />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                        <div className={mode == 'view' ? 'disable_edit' : ''}>
                                            {conditions[index].type != conditionTYPES.COMBINATOR ? (
                                                <ConditionalStatements
                                                    condition={conditions[index]}
                                                    conditions={conditions.filter((c, i) => i == index)}
                                                    key={index}
                                                    onChange={(condition: any) => onChange(index, condition)}
                                                    remove={() => {}}
                                                    hideTititle={true}
                                                    callbackWhenChooserisOpen={(() =>   parentScrollCallback && parentScrollCallback(true))}
                                                    isWelcomeNode={false}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="textarea_user_details">
                                            <InputContainer style={{marginBottom: 20}}>
                                                <Textarea
                                                    className={`${mode == 'view' ? 'disable_edit' : ''}`}
                                                    value={dialog.respondWith}
                                                    onChange={(e: any) =>
                                                        updateState({ ...dialog, respondWith: e.target.value })
                                                    }
                                                />
                                                <InputContent>Respond with</InputContent>
                                            </InputContainer>
                                        </div>
                                        {/* radio button */}
                                        <div className="radio_slot_btn">
                                            <div className="dropdown_select_dialog">
                                                <div className="select_work_form">
                                                    <InputContainer style={{
                                                                marginBottom: 20
                                                            }}>
                                                        <Input
                                                            type="text"
                                                            value={
                                                                dialog.jumpOrExit == 'jump'
                                                                    ? 'Jump to specific dialog or faq'
                                                                    : 'Exit the conversation'
                                                            }
                                                            onClick={() => { parentScrollCallback(true);setJumpOrExitDropdown(index)}}
                                                        />
                                                        <InputContent>Then</InputContent>
                                                        {jumpOrExitDropdown == index && (
                                                            <DropdownUlWrapper className="call_automation_dropdown">
                                                                <Downshift
                                                                    isOpen={true}
                                                                    onOuterClick={() => setJumpOrExitDropdown(null)}
                                                                >
                                                                    {() => (
                                                                        <div className="automation_scroly">
                                                                            <DropdownUl>
                                                                                <DropdownLi
                                                                                    onClick={() => {
                                                                                        updateState({
                                                                                            ...dialog,
                                                                                            jumpOrExit: 'jump'
                                                                                        })
                                                                                        setJumpOrExitDropdown(null)
                                                                                    }}
                                                                                >
                                                                                    Jump to specific dialog or faq
                                                                                </DropdownLi>
                                                                                <DropdownLi
                                                                                    onClick={() => {
                                                                                        updateState({
                                                                                            ...dialog,
                                                                                            jumpOrExit: 'exit',
                                                                                            title: 'Select dialog',
                                                                                            dialogId: ''
                                                                                        })

                                                                                        setJumpOrExitDropdown(null)
                                                                                    }}
                                                                                >
                                                                                    Exit the conversation
                                                                                </DropdownLi>
                                                                            </DropdownUl>{' '}
                                                                        </div>
                                                                    )}
                                                                </Downshift>
                                                            </DropdownUlWrapper>
                                                        )}
                                                        <CancelButton className="d-flex">
                                                            <DropdownWithCircle />
                                                        </CancelButton>
                                                    </InputContainer>
                                                </div>
                                            </div>
                                        </div>
                                        {dialog.jumpOrExit == 'jump' && (
                                            <>
                                                <div className="dropdown_select_dialog">
                                                    <div className="select_work_form">
                                                        <InputContainer className="mb-0">
                                                            <Input
                                                                type="text"
                                                                value={dialog.title}
                                                                onClick={() =>{
                                                                    parentScrollCallback(true);
                                                                    updateState({
                                                                        ...dialog,
                                                                        showDropDown: true
                                                                    })}
                                                                }
                                                                className={mode == 'view' ? 'disable_edit' : ''}
                                                            />
                                                            <InputContent>Select dialog or faq</InputContent>
                                                            {dialog.showDropDown && (
                                                                <DropdownUlWrapper>
                                                                    <Downshift
                                                                        isOpen={true}
                                                                        onOuterClick={() =>
                                                                            updateState({
                                                                                ...dialog,
                                                                                showDropDown: false
                                                                            })
                                                                        }
                                                                    >
                                                                        {() => (
                                                                            <div className="automation_scroly">
                                                                                <DropdownUl>
                                                                                    {(sortAnArrayByName(rootDialogues,"title") as DialogueT[]).map((dg, i) => (
                                                                                        <DropdownLi
                                                                                            key={i}
                                                                                            onClick={() => {
                                                                                                updateState({
                                                                                                    ...dialog,
                                                                                                    showDropDown: false,
                                                                                                    title: dg.title,
                                                                                                    dialogId: dg.id
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            {dg.title}
                                                                                        </DropdownLi>
                                                                                    ))}
                                                                                </DropdownUl>{' '}
                                                                            </div>
                                                                        )}
                                                                    </Downshift>
                                                                </DropdownUlWrapper>
                                                            )}
                                                            <CancelButton className="d-flex">
                                                                <DropdownWithCircle />
                                                            </CancelButton>
                                                        </InputContainer>
                                                        {/* <p>Select a dialog from the dropdown list.</p> */}
                                                        {data.id !='' && !(jumptoRoutes as DialogueT[]).filter(dia => dia.uuid == dialog.dialogId || dia.id == dialog.dialogId)[0] && dialog.existing && <div className="error_input_show dialog_node svg-15px">
                                                            <WarningAlertIcon />
                                                            <span className="error_menu_hvr">Input data is missing.</span>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                    </>
                    {/* // )} */}
                    {/* addmore field */}

                    <div className="add_connection_automation add_recognise">
                        <div className="show_options">
                            {dialogChooser.length !== 0 ? (
                                <SmallButton
                                    primary
                                    onClick={() => {
                                        if (mode != 'view') {
                                            parentScrollCallback(true);
                                            setDialogChooser([
                                                ...dialogChooser,
                                                {
                                                    show: true,
                                                    title: '',
                                                    dialogId: '',
                                                    showDropDown: false,
                                                    jumpOrExit: 'jump',
                                                    respondWith: '',
                                                    existing: false
                                                }
                                            ])

                                            setConditions([
                                                ...conditions,
                                                {
                                                    type: conditionTYPES.SELECT
                                                }
                                            ])
                                        }
                                    }}
                                    className={!showAddMoreIf() ? 'editor_btn_disabled' : ''}
                                >
                                    <span>
                                        <AddIcon />
                                    </span>
                                    <label>Add more</label>
                                </SmallButton>
                            ) : null}
                        </div>
                    </div>
                </div>
                {/* Advanced Options End */}
            </div>

            {showDeleteModal()}
        </div>
    )
}

const SlotsContainer = () => {
    const {
        workspaceName,
        assistantID,
        setPageState,
        PageState,
        fetchCallback,
        dialogueName,
        parentIfnode,
        queryClient
    } = useContext(DialogueContext)

    const { data } = PageState as ATTACH_RESPONSE

    const [attachment, setAttachment] = useState<AttchValuesT>({
        category: '',
        prompt: { type: ExpressionTypeE.TEXT, value: '' },
        variable: '',
        error_message: { type: ExpressionTypeE.TEXT, value: '' }
    })

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(false)

    const [showSaveButton, setShowSaveButton] = useState<boolean>(false)

    const [conditions, setConditions] = useState<conditionT[]>([])

    const [dialogChooser, setDialogChooser] = useState<
        {
            show: boolean
            title: string
            dialogId: string
            showDropDown: boolean
            jumpOrExit: string
            respondWith: string
            existing:boolean
        }[]
    >([])

    const [digression, setDigression] = useState<boolean>(data.allow_digression)

    const [scrollableDivRef, scrollTo] = useScrollHook()

    const save = async () => {
        setLoader(true)
        try {
            if (data.id === '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        prompt: attachment.prompt,
                        variable: attachment.variable,
                        category: attachment.category.toLowerCase(),
                        type: 'file_upload',
                        allow_digression: digression,
                        error_message: attachment.error_message,
                        file_exit_conditions: conditions
                            .map((cond, i) => {
                                if (
                                    cond.type != conditionTYPES.SELECT &&
                                    cond.type != conditionTYPES.COMBINATOR &&
                                    dialogChooser.length > 0
                                ) {
                                    return {
                                        condition: generateCond([cond]),
                                        response: {
                                            type: 'text',
                                            value: dialogChooser[i].respondWith
                                        },
                                        next_step:
                                            dialogChooser[i].jumpOrExit == 'jump'
                                                ? {
                                                      jump_to: {
                                                          to: dialogChooser[i].dialogId,
                                                          selector: 'body'
                                                      }
                                                  }
                                                : {
                                                      exit: null
                                                  }
                                    } as SlotExitConditions
                                }

                                return null
                            })
                            .filter(Boolean) as SlotExitConditions[]
                    } as AttachmentT,
                    dialogueName
                )
                await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
                await queryClient.invalidateQueries(['assistants'])
                await queryClient.invalidateQueries(['dialogs', assistantID])

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::UPLOAD_ATTACHMENT", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        prompt: attachment.prompt,
                        variable: attachment.variable,
                        category: attachment.category.toLowerCase(),
                        error_message: attachment.error_message,
                        type: 'file_upload',
                        allow_digression: digression,
                        file_exit_conditions: conditions
                            .map((cond, i) => {
                                if (
                                    cond.type != conditionTYPES.SELECT &&
                                    cond.type != conditionTYPES.COMBINATOR &&
                                    dialogChooser.length > 0
                                ) {
                                    return {
                                        condition: generateCond([cond]),
                                        response: {
                                            type: 'text',
                                            value: dialogChooser[i].respondWith
                                        },
                                        next_step:
                                            dialogChooser[i].jumpOrExit == 'jump'
                                                ? {
                                                      jump_to: {
                                                          to: dialogChooser[i].dialogId,
                                                          selector: 'body'
                                                      }
                                                  }
                                                : {
                                                      exit: null
                                                  }
                                    } as SlotExitConditions
                                }

                                return null
                            })
                            .filter(Boolean) as SlotExitConditions[]
                    } as AttachmentT,
                    dialogueName
                )
                await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])  
                await queryClient.invalidateQueries(['assistants'])
                await queryClient.invalidateQueries(['dialogs', assistantID])

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Get Details Node Updated'
                })
            }
            fetchCallback(() => setLoader(false))
        } catch (error) {
            setLoader(false)
            setError({ error: true, info: 'Something went wrong. Please try again!' })
        }
    }
    const parentRootNode = parentIfnode as IfNodeT

    return (
        <Wrapper className="upload_attachment_popup">
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Upload attachment
                            <span className='upload_info_que_icon'>
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={<FileUploadTips />}
                                    distance={5}
                                >
                                    <InfoIcon />
                                </Tooltip>
                                <a href="https://help.workativ.com/knowledgebase/upload-attachment/" target="_blank">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content="View help document"
                                        distance={5}
                                    >
                                        <QaIconWithCircle />
                                    </Tooltip>
                                </a>
                            </span>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'UPLOAD_ATTACHMENT'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Make your chatbot collect attachment from user.</Paragraph>

                    <ErrorBoundary
                        error={error}
                        render={(err: any, info: any) => {
                            return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                        }}
                    >
                        {loader ? (
                            <div className="popup_loader">
                                <Loader.PopupLoader show={loader} />
                            </div>
                        ) : (
                            <React.Fragment>
                                <PopupScrollHeight ref={scrollableDivRef}>
                                    <EmptyDiv>
                                        {parentRootNode.allow_digression && (
                                            <SwitchWrapper>
                                                <HeaderWithSwitch>
                                                    <Header>
                                                        Allow conversation switching{' '}
                                                        <Tooltip
                                                            className="target customTip upload_popup_info"
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content="By enabling this option, chatbot can switch conversation at this point, based on user input."
                                                            distance={5}
                                                        >
                                                            <InfoIcon />
                                                        </Tooltip>
                                                        {/* <a
                                                            href="https://help.workativ.com/knowledgebase/building-a-dialog/"
                                                            target="_blank"
                                                            rel="noopener"
                                                            title="Building a dialog"
                                                        >
                                                            <Tooltip
                                                                className="target customTip tooltips_color upload_popup_qus"
                                                                zIndex={10000}
                                                                arrowSize={8}
                                                                tagName="span"
                                                                content="View help document"
                                                                distance={5}
                                                            >
                                                                <QaIconWithCircle />
                                                            </Tooltip>
                                                        </a> */}
                                                        <div className="popup_show_hide upload_popup">
                                                            <div className="show_hide">
                                                                <div className="switch_box box_1">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="switch_1"
                                                                        checked={digression}
                                                                        onChange={() => setDigression(!digression)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Header>
                                                </HeaderWithSwitch>
                                            </SwitchWrapper>
                                        )}
                                        <div className="">
                                            <Slot
                                                slot={data}
                                                setAttachment={(data: AttchValuesT) => {
                                                    setAttachment(data)
                                                }}
                                                showSaveButton={(showSaveButton: boolean) => {
                                                    setShowSaveButton(showSaveButton)
                                                }}
                                                setConditions={setConditions}
                                                conditions={conditions}
                                                dialogChooser={dialogChooser}
                                                setDialogChooser={setDialogChooser}
                                                parentScrollCallback={((shouldScroll) => scrollTo('bottom', shouldScroll) )}
                                            />
                                            {/* <div
                                className={alreadyOpen === false ? 'plus_addmore' : 'plus_addmore editor_btn_disabled'}
                            >
                                <div
                                    className="plus_add_more full_width"
                                    onClick={() => (alreadyOpen === false ? addMore() : null)}
                                >
                                    <h4>
                                        <div className="parent_node_no">
                                            <span className="parent_svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.24 16.24">
                                                    <title>Add More</title>
                                                    <g id="Layer_2" data-name="Layer 2">
                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                            <path
                                                                className="cls-1"
                                                                d="M0,8.12a.93.93,0,0,1,.93-.93H7.19V.93a.93.93,0,0,1,1.86,0V7.19h6.26a.93.93,0,0,1,0,1.86H9.05v6.26a.93.93,0,0,1-1.86,0V9.05H.93A.93.93,0,0,1,0,8.12Z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        Add More
                                    </h4>
                                </div>
                            </div> */}
                                        </div>
                                    </EmptyDiv>
                                    <ButtonContainer className="footer_button_fixed">
                                        <div className="popup_footer_button_container">
                                        <Button
                                            primary
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => setPageState(VIEW)}
                                        >
                                            <label>Cancel</label>
                                        </Button>

                                        <Button
                                            className={
                                                attachment.category.length == 0 ||
                                                attachment.prompt.value.length == 0 ||
                                                attachment.variable.length == 0 ||
                                                attachment.error_message.value.length == 0 ||
                                                !showSaveButton
                                                    ? 'editor_btn_disabled'
                                                    : ''
                                            }
                                            disabled={
                                                attachment.category.length == 0 ||
                                                attachment.prompt.value.length == 0 ||
                                                attachment.variable.length == 0 ||
                                                attachment.error_message.value.length == 0 ||
                                                !showSaveButton
                                            }
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => save()}
                                        >
                                            <label>Save</label>
                                        </Button>
                                        </div>
                                    </ButtonContainer>
                                </PopupScrollHeight>
                            </React.Fragment>
                        )}
                    </ErrorBoundary>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export default SlotsContainer
