import * as R from 'ramda'

// Dialog-editor

export const findDuplicateInSamerray = R.pipe(
    R.reduce(
        ({ results, foundOnce }, item) =>
            R.contains(item, foundOnce)
                ? { results: R.append(item, results), foundOnce }
                : { results, foundOnce: R.append(item, foundOnce) },
        { results: [], foundOnce: [] } as any
    ),
    R.prop('results')
)

export const returnValidConfidence = (num:number) =>{
    const processed = Math.round(num * 100)
    return processed + "%"
}