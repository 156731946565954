export type WorkspacesT = {
    id: number
    name: string
    ownerId: number
    createdAt: string
    updatedAt: string
    workspaceURL: string
}

export const defaultWorkspace: WorkspacesT = {
    id: 0,
    name: '',
    ownerId: 0,
    createdAt: '',
    updatedAt: '',
    workspaceURL: ''
}

export type stateT = {
    workspaces: WorkspacesT[]
    defaultWs: string
    viewWorkspaces: boolean
}

export const defaultState: stateT = {
    workspaces: [],
    defaultWs: '',
    viewWorkspaces: false
}
