import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AddIcon,
  AppTriggerFilterIcon,
  BotDropdown,
  CloseIcon,
  CloseIconWithCircle,
  DialogIcon,
  DropdownWithCircle,
  EyeOpenIcon,
  LAPlusIcon,
  LATagIcon,
  NewThumbsDownIcon,
  NewThumbsUpIcon,
  SlackIconCC,
  TeamsIcon,
  TickIcon,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import {
  CountNumber,
  DropdownWrapper,
  Input,
  InputContent,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import { TableWrapper } from '@/common/styled/Entity.Dumb';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  TdContent,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import Downshift from 'downshift';
import {
  ChatDataT,
  ChatHistoryT,
  dataConversion,
  getBaseAPI,
  getValues,
  mockchat,
  MockHistory,
  useAgentList,
  useBots,
  useChatHistory,
  useLogAgentList,
  useSessionEvent,
  useSingleStatus,
  useTeamHistory,
} from './LivaAgentApi';
import {
  BotsLA,
  eventType,
  initialSessionData,
  Sessions,
  teamsType,
} from './LiveagentMockFile';
import _ from 'lodash';
import {
  DropdownUl,
  DropdownUlForLogs,
  Dropdwon,
} from '@/DialogEditor/TrainingModule/TrainingModule';
import { DropdwonLogSearch, DropdwonWithDebounce } from './dropDown';
import { ReceiveEvents, SessionEvents } from './LiveAgentTypes';
import {
  convertFromToDate,
  capitalizeFirstLetter,
  convertToMDText,
  formatAMPM,
  formatDateInCard,
} from '@/common/utils/utils';
import AssistantMd from '@skitter/assistant_md';
import { getJson } from '@/common/utils/api-utils';
import { platformIcon } from './inbox';
import { DisplayOldEvent, DisplayTeamEvent } from './ChatUser';
import { TabContainer, TabHeader } from '@/common/styled/Settings.Dumb';
import {
  BasiceDropdown,
  DateFilterT,
} from 'libs/feature/assistant/src/Dialogue/BotDetails';
import BasicDateRangePicker from '@/Assistant/Dialogue/CustomDatePicker';
import moment from 'moment';
import { HeaderForLiveAgent } from '.';

export const TabRow = styled.div`
    width: 100%;
    float: left;
    position:relative;
    display: flex;

    :after{
        content: "";
        position: absolute;
        border-bottom: 1px solid #e0e0e0;
        width: 21%;
        height: 1px;
        top: 33px;
        left: 2px;
        z-index: 0;  
        
        @media (min-width: 1920px) {
          width: 39%;
        }
        @media (min-width: 2160px) {
            width: 34.5%;
        }
        @media (min-width: 2560px) {
            width: 28.5%;
        }
        @media (min-width: 3480px) {
            width: 20.5%;
        }
        @media (max-width: 1440px) {
            width: 25%;
        }
        @media (max-width: 1280px) {
            width: 28%;
        }
    }
`

const DropdownContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
type Customdate = {
  from_date: null | string;
  to_date: null | string;
};
const initialDates = {
  from_date: null,
  to_date: null,
};
export function LiveAgentLogs(props: any) {
  const workspace = props.match.params.workspacename;
  const [customDates, setCustomDates] = useState<Customdate>(initialDates);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const changecurrentPage = () => setCurrentPage(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const ChngaeTotalCount = (value: number) => setTotalCount(value);
  const [dateOptions, setOptions] = useState('last30days');
  const changeDateFilter = (filter: DateFilterT) => {
    if (filter != 'customrange') {
      console.log(filter, 'customDates');
    }
  };
  const [showState, setShowState] = useState({ customDatePicker: 'hide' });

  const [filterDropDown, setFilterDropDown] = useState<boolean>(false);
  const closeFilterpopup = () => {
    setFilterDropDown(false);
    setDropDownAgentList(['']);
    setDropDownUserList(['']);
    setDropDownIDList(['']);
    setDropDownTagList(['']);
    setDropDownLocationList(['']);
    setFilterpage(1);
  };
  const [filterPage, setFilterpage] = useState<number>(1);
  const [sessionPopup, setSessionPopup] = useState<boolean>(false);
  const [sessionID, setSessionID] = useState<string>('');
  const { singleSessionData, isSessionFetched } = useSingleStatus(
    workspace,
    sessionID
  );

  type LogEventsT = eventType | teamsType;
  const [eventData, setEventData] = useState<LogEventsT[]>([]);
  console.log(currentPage, 'LiveAgentLogs');
  const { singleEventData } = useSessionEvent(workspace, sessionID);
  // console.log(sessionID,eventData,singleEventData, 'singleEventData');
  const { bots, isBotsFetched } = useBots(workspace);
  const [sessionData, setSessionData] = useState<Sessions>(initialSessionData);
  // type filterValue = ''|'Location'|'Tag' |'Chat Source'
  const [filterby, setFilterBy] = useState<string>('');

  const getDateNumber = (date: string) => {
    console.log(date, 'LiveAdate');
    switch (date) {
      case 'today':
        return 'today';
      case 'yesterday':
        return 'yesterday';
      case 'last30days':
        return 30;
      case 'last14days':
        return 14;
      case 'last7days':
        return 7;
      default:
        return 'today2';
    }
  };
  const [conversationID, setConversationID] = useState<string>('');
  const [botID, setBotID] = useState<string>('');
  const { teamHistoryData } = useTeamHistory(workspace, botID, conversationID);

  useEffect(() => {
    if (singleSessionData) {
      setSessionData(singleSessionData);
      // const eventConvert = singleEventData?.map((data) => getValues(data));
      const id: string = singleSessionData.meta.user_conversation;
      const splitID = id?.split('/');
      const convID = splitID[splitID.length - 1];
      const bot_ID = singleSessionData.chat_source.id;
      setBotID(bot_ID);
      setConversationID(convID);
    }
  }, [sessionID, isSessionFetched]);

  useEffect(() => {
    if (singleEventData && singleEventData.length>0 ) {
      console.log(
        'DOING ::: all data',
        teamHistoryData,
        workspace,
        botID,
        conversationID,
        singleEventData
      );
      const dataConvert: teamsType[] = teamHistoryData
        ? dataConversion(teamHistoryData)
        : [];
      setEventData([...dataConvert, ...singleEventData]);
    }
  }, [teamHistoryData]);
  interface StateType {
    bot_id: string;
    chat_channel: string;
    feedback: string;
    user_email: string;
    agent_email: string;
    tag: string;
    chat_id: string;
    location: string;
  }
  const [state, setState] = useState<StateType>({
    bot_id: '',
    chat_channel: '',
    feedback: '',
    user_email: '',
    agent_email: '',
    tag: '',
    chat_id: '',
    location: '',
  });
  const [selectedState, setSelectedState] = useState<StateType>({});

  const getName = (value: string) => {
    switch (value) {
      case 'bot_id':
        return 'Chat Source';
      case 'feedback':
        return 'Feedback';
      case 'chat_channel':
        return 'Chat Channel';
      case 'user_email':
        return 'User name';
      case 'agent_email':
        return 'Agent name';
      case 'tag':
        return 'Tag';
      case 'location':
        return 'Location';
      case 'chat_id':
        return 'Chat ID';
      default:
        return 'default';
    }
  };

  const getValue = (name: string, value: string) => {
    if (name === 'bot_id') {
      return botName(value);
    } else {
      return value;
    }
  };
  const [chatHistoryData, setChatHistoryData] = useState<ChatDataT[]>([]);
  type selectedFilterT = {
    displatText: string;
    value: string;
  };
  const [selectedFilter, setSelectedFiler] = useState<selectedFilterT[] | null>(
    null
  );
  console.log(state, state['chat_id'], 'adfadstate');
  const filterDelete = (value: string) => {
    //   const stateName = state[value]
    // console.log(state,state[value], 'adfadstate');
    setState((prevState) => ({
      ...prevState,
      [value]: '',
    }));
    delete selectedState[value];
  };
  useEffect(() => {
    if (dateOptions === 'customrange' && customDates?.from_date !== null) {
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
    }
  }, [dateOptions,customDates]);
  useEffect(() => {
    const findStateValue = (obj:StateType) => {
      const keys = [];
      for (let key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          obj[key] !== '' &&
          obj[key] !== 'All Value'
        ) {
          keys.push(key);
        }
      }
      return keys;
    };

    const value = findStateValue(state);
    const selectedVal = findStateValue(selectedState);
    const mappedValue =
      value.length > 0 ? value.map((val) => `&${val}=${state[val]}`) : [''];
    const arrayOfSelected =
      selectedVal.length > 0
        ? selectedVal.map((val) => ({
            displatText: `${getName(val)} is ${getValue(val, selectedState[val])}`,
            value: val,
          }))
        : null;
    setSelectedFiler(arrayOfSelected);
    const mappedString = mappedValue.join('');
    console.log(mappedString, mappedValue.join(''), dateOptions, 'calcvalue');
    if (
      dateOptions &&
      (dateOptions === 'customrange' ? customDates?.from_date !== null : true)
    ) {
      if (mockchat) {
        setChatHistoryData(MockHistory.data);
      } else {
        console.log('apihit');
        getJson(
          `${getBaseAPI()}/la-logs/${workspace}/sessions/${
            dateOptions === 'customrange' && customDates?.from_date !== null
              ? 'custom_date?to_date=' +
                customDates?.to_date +
                '&from_date=' +
                customDates?.from_date +
                '&'
              : getDateNumber(dateOptions) + '?'
          }sort_by_start_time=-1&page_no=${
            value.length > 0 ? 1 : currentPage
          }&no_of_records=50${mappedString}`
        )
          .then((res) => {
            setChatHistoryData(res.data);
            ChngaeTotalCount(res.totalCount);
          })
          .catch((err) => {
            console.log(err)
          });
      }
    }
  }, [state, dateOptions, customDates, currentPage]);

  const checkdata = (event: any) => {
    const keys = Object.keys(event);
    if (keys.includes('data')) {
      return true;
    } else {
      return false;
    }
  };
  // const { chatHistoryData } = useChatHistory(workspace, state);
  const [focus, setFocus] = useState({ focus: false, value: '' });
  const [userfocus, setUserFocus] = useState({ focus: false, value: '' });
  const [agentfocus, setAgentFocus] = useState({ focus: false, value: '' });
  const [idFocus, setIdFocus] = useState({ focus: false, value: 0 });
  const [tagFocus, setTagFocus] = useState({ focus: false, value: '' });
  const [locationFocus, setLocationFocus] = useState({
    focus: false,
    value: '',
  });

  const [dropDownAgentList, setDropDownAgentList] = useState<string[]>(['']);
  const [totalAgentCount, setTotalAgentCount] = useState<number>(0);
  const [dropDownUserList, setDropDownUserList] = useState<string[]>(['']);
  const [totalUserCount, setTotalUserCount] = useState<number>(0);
  const [dropdownIDList, setDropDownIDList] = useState<string[]>(['']);
  const [totalIdcount, setTotalIdCount] = useState<number>(0);
  const [dropdownTagList, setDropDownTagList] = useState<string[]>(['']);
  const [totalTagcount, setTotalTagCount] = useState<number>(0);
  const [dropdownLocationList, setDropDownLocationList] = useState<string[]>([
    '',
  ]);
  console.log(dropdownIDList, 'dropDownAgentList');

  const [totalLocationcount, setTotalLocationCount] = useState<number>(0);

  const [agentName, setAgentName] = useState<string>('');

  const { agentLogList } = useLogAgentList(workspace, agentName);
  const { agentList } = useAgentList(workspace);
  console.log(dropdownLocationList, totalLocationcount, 'valuecheck');
  const findAgentName = (id: string) => {
    return agentList
      ? agentList.filter((ag) => ag.auth_user_id.toString() == id)[0]
          ?.first_name
      : 'AgentName';
  };
  const useDebounce = <T,>(value: T, timeout = 1000) => {
    const [state, setState] = useState<T>(value);

    useEffect(() => {
      const handler = setTimeout(() => setState(value), timeout);

      return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
  };
  const debouncedOnChange = useDebounce(userfocus.value);
  const debouncedAgentOnChange = useDebounce(agentfocus.value);
  const debounceChatId = useDebounce(idFocus.value);
  const debounceTag = useDebounce(tagFocus.value);
  const debounceLocation = useDebounce(locationFocus.value);
  console.log(
    debouncedAgentOnChange,
    agentfocus.value,
    'debouncedOnChange search'
  );
  useEffect(() => {
    userEmailSearch('');
    agentEmailSearch('');
    chatIDSearch(0);
    tagSearch('');
    locationSearch('');
  }, [dateOptions, customDates, filterDropDown]);
  useEffect(() => {
    debouncedOnChange.length
      ? userEmailSearch(debouncedOnChange)
      : userEmailSearch('');
    debouncedAgentOnChange.length
      ? agentEmailSearch(debouncedAgentOnChange)
      : agentEmailSearch('');
    debounceChatId > 0 ? chatIDSearch(debounceChatId) : chatIDSearch(0);
    debounceTag.length ? tagSearch(debounceTag) : tagSearch('');
    debounceLocation.length
      ? locationSearch(debounceLocation)
      : locationSearch('');
  }, [
    debouncedOnChange,
    debouncedAgentOnChange,
    debounceChatId,
    filterPage,
    debounceLocation,
    debounceTag,
  ]);

  const userEmailSearch = (userName: string) => {
    console.log('apicalled');
    if (mockchat) {
      setChatHistoryData(MockHistory.data);
      setShowLoader('');
    } else {
      getJson(
        `${getBaseAPI()}/la-logs/${workspace}/users?user_email=${
          userName ? userName : ''
        }&${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }&${
          debouncedOnChange.length ? '' : `page_no=${filterPage}`
        }&no_of_records=50`
      ).then((res) => {
        setTotalUserCount(res.total_count);
        filterPage > 1
          ? setDropDownUserList([
              ...dropDownUserList,
              ...res.user_email.map((s: any) => {
                return { label: s, value: s };
              }),
            ])
          : setDropDownUserList([
              { label: 'All Users', value: 'All Value' },
              ...res.user_email.map((s: any) => {
                return { label: s, value: s };
              }),
            ]);
        setShowLoader('');
      });
    }
  };

  const agentEmailSearch = (userName: string) => {
    console.log('apiagentcalled');
    if (mockchat) {
      setChatHistoryData(MockHistory.data);
      setShowLoader('');
    } else {
      getJson(
        `${getBaseAPI()}/la-logs/${workspace}/agents?${
          userName ? 'agent_email=' + userName + '&' : ''
        }${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }&${
          debouncedOnChange.length ? '' : `page_no=${filterPage}`
        }&no_of_records=50`
      ).then((res) => {
        console.log('dropDownAgentList', res);
        setTotalAgentCount(res.total_count);
        filterPage > 1
          ? setDropDownAgentList([
              ...dropDownAgentList,
              ...res.agent_email.map((s: any) => {
                return { label: s, value: s };
              }),
            ])
          : setDropDownAgentList([
              { label: 'All Agents', value: 'All Value' },
              ...res.agent_email.map((s: any) => {
                return { label: s, value: s };
              }),
            ]);
        setShowLoader('');
      });
    }
  };

  const chatIDSearch = (id: number) => {
    console.log('dropdownProps');
    if (mockchat) {
      setChatHistoryData(MockHistory.data);
      setShowLoader('');
    } else {
      getJson(
        `${getBaseAPI()}/la-logs/${workspace}/sessions?search_by=chat_id&chat_id=${
          id ? id : ''
        }&${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }&${
          debouncedOnChange.length ? '' : `page_no=${filterPage}`
        }&no_of_records=50`
      ).then((res) => {
        console.log(res, 'afadfsadfsa');
        setTotalIdCount(res.total_count);
        filterPage > 1
          ? setDropDownIDList([
              ...dropdownIDList,
              ...res.chat_id.map((s: any) => {
                return { label: s, value: s };
              }),
            ])
          : setDropDownIDList([
              { label: 'All ChatIDs', value: 'All Value' },
              ...res.chat_id.map((s: any) => {
                return { label: s, value: s };
              }),
            ]);
        setShowLoader('');
      });
    }
  };

  const convertDateFormat = (inputDate: string) => {
    var parts = inputDate.split('-');
    if (parts.length === 3) {
      var mm = parts[0];
      var dd = parts[1];
      var yyyy = parts[2];
      // Rearrange the parts to yyyy-mm-dd format
      return yyyy + '-' + mm + '-' + dd;
    } else {
      // Handle invalid input format
      return 'Invalid date format';
    }
  };

  const tagSearch = (id: string) => {
    console.log('tagapicalled');
    if (mockchat) {
      setChatHistoryData(MockHistory.data);
      setShowLoader('');
    } else {
      getJson(
        `${getBaseAPI()}/liveagent/${workspace}/v1/search_tags?${
          id ? '&tag_name=' + id : ''
        }&${
          dateOptions === 'customrange' &&
          customDates &&
          customDates.from_date !== null &&
          customDates.to_date !== null
            ? 'day_span=custom&to_date=' +
              convertDateFormat(customDates.to_date.toString()) +
              '&from_date=' +
              convertDateFormat(customDates.from_date.toString())
            : 'day_span=' + getDateNumber(dateOptions)
        }`
      ).then((res) => {
        setTotalTagCount(res.total_count);
        res.length > 0
          ? setDropDownTagList([
              { label: 'All Tags', value: 'All Value' },
              ...res.map((s: any) => {
                return { label: s, value: s };
              }),
            ])
          : setDropDownTagList([{ label: 'All Tags', value: 'All Value' },
              ...res.map((s: any) => {
                return { label: s, value: s };
              }),]);
        setShowLoader('');
      });
    }
  };
  const locationSearch = (id: string) => {
    console.log('locationapicalled');
    if (mockchat) {
      setChatHistoryData(MockHistory.data);
      setShowLoader('');
    } else {
      getJson(
        `${getBaseAPI()}/la-logs/${workspace}/sessions?search_by=location&location=${
          id ? id : ''
        }&${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }&${
          debouncedOnChange.length ? '' : `page_no=${filterPage}`
        }&no_of_records=50`
        // &
        // ${
        //   debouncedOnChange.length ? '' : 'page_no=' + currentPage
        // }&no_of_records=10`
      ).then((res) => {
        setTotalLocationCount(res.total_count);
        filterPage > 1
          ? setDropDownLocationList([
              ...dropdownLocationList,
              ...res.location.map((s: any) => {
                return { label: s, value: s };
              }),
            ])
          : setDropDownLocationList([
              { label: 'All Location', value: 'All Value' },
              ...res.location.map((s: any) => {
                return { label: s, value: s };
              }),
            ]);
        setShowLoader('');
      });
    }
  };
  const [showLoader, setShowLoader] = useState<string>('');
  console.log('showloader', showLoader);

  const botName = (id: string) => {
    const bData = bots as BotsLA[];
    return bData.filter((b) => b.id === id)[0]?.name;
  };
  const [searchWord, setSearchWord] = useState<string>('');
  console.log(searchWord,"searchWord")
  const chatList = [
    { id: 'All Value', name: 'All chat channels' },
    { id: 'slack', name: 'Slack' },
    { id: 'teams', name: 'Teams' },
    { id: 'widget', name: 'Widget' },
    { id: 'tryme', name: 'Tryme' },
  ];
  const feedBackList = [
    { id: 'All Value', name: 'All' },
    { id: 'Satisfied', name: 'Satisfied' },
    { id: 'Not Satisfied', name: 'Not Satisfied' },
  ];
  console.log(chatHistoryData, 'singleSessionDatalog');
  return (
    <>
    <HeaderForLiveAgent {...props} />
      {/* <div className="liveAgent_title_section">
        <h2 className="liveAgent_title">Live Chat Inbox <span>Beta</span></h2>
      </div> */}
      <TabContainer className="liveAgent-menu comman">
        <TabRow className="liveAgent-menu-row">
          <TabHeader
            onClick={() =>
              props.history.push(
                `/workspace/${workspace}/live-agent/Inbox/all/conversations`
              )
            }
          >
            Inbox
            <span
            // onClick={() => {
            //   setBotdropdown(true);
            // }}
            // className={tab === 'Bot' ? 'activesvg' : ''}
            >
              <BotDropdown />
            </span>
            {/* {botdropdown ? (
              <Downshift
                isOpen={botdropdown}
                onOuterClick={() => setBotdropdown(false)}
              >
                {() => (
                  <div className="flow_sort_by dialog_dp">
                    <ul className="flow_sort_dropdown">
                      {botList.map((data) => (
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            botSelect(data);
                            setBotdropdown(false);
                          }}
                          className={
                            data.name === selectedBot.name
                              ? 'liveAt_submenu_active'
                              : ''
                          }
                        >
                          {data.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Downshift>
            ) : null} */}
          </TabHeader>
          <TabHeader
            // className="active"
            onClick={() => {
              props.history.push(
                `/workspace/${workspace}/live-agent/dashboard`
              );
            }}
            // onClick={() => {
            //   setTab('DashBoard');
            // }}
          >
            Dashboard
          </TabHeader>
          <TabHeader
            className="active"
            // className={tab === 'Chat History' ? 'active' : ''}
          >
            Chat History
          </TabHeader>
          <div className="tab_container_right">
            <BasiceDropdown
              dateOptions={dateOptions}
              changeDateFilter={changeDateFilter}
              setOptions={setOptions}
              showCustomDatePicker={() => {
                setShowState({ customDatePicker: 'show' });
              }}
            />
            {showState.customDatePicker == 'show' && (
              <div className="tab_dropdown_date dialog_logs">
                <div className="tab_calender">
                  <BasicDateRangePicker
                    setDate={(date: Date[]) => {
                      const customDates = {
                        from_date: convertFromToDate(date[0].toString()) as any,
                        to_date: convertFromToDate(date[1].toString()) as any,
                      };
                      setCustomDates(customDates);
                      // changeToCustomDateFilter(customDates)
                      setShowState({
                        customDatePicker: 'hide',
                      });
                    }}
                    show={showState.customDatePicker == 'show'}
                    minDate={
                      new Date(
                        moment().add(1, 'day').subtract(1, 'year').toString()
                      )
                    }
                    customDates={customDates}
                    closeCalendar={() => {
                      setShowState({
                        customDatePicker: 'hide',
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {totalCount && totalCount > 0 ? (
                <p>
                  {currentPage === 1 ? 1 : (currentPage - 1) * 50 + 1} -{' '}
                  {totalCount > currentPage * 50
                    ? currentPage * 50
                    : totalCount}{' '}
                  of {totalCount}
                  <span
                    style={{ width: '16px', height: '16px' }}
                    className={
                      currentPage !== 1
                        ? 'prev_icon'
                        : 'prev_icon editor_btn_disabled'
                    }
                    onClick={() =>
                      setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0)
                    }
                  >
                    <DropdownWithCircle />
                  </span>
                  <span
                    style={{ width: '16px', height: '16px' }}
                    className={
                      totalCount > currentPage * 50
                        ? 'next_icon'
                        : 'next_icon editor_btn_disabled'
                    }
                    onClick={() => {
                      // changingGroupByRef.current = null
                      setCurrentPage(currentPage + 1);
                    }}
                  >
                    <DropdownWithCircle />
                  </span>
                </p>
            ) : null}
          </div>
        </TabRow>
      </TabContainer>
      <div className="entity_cards liveAgent_logs">
      <div className='liveAgent_filter_box'>
        {selectedFilter &&
          selectedFilter.length > 0 &&
          selectedFilter.map((selected:selectedFilterT, index:number) => (
            <label key={index} className='flex'>
              <p>{selected.displatText}</p>{' '}
              <span
                className="liveAt_filter_delete"
                onClick={() => filterDelete(selected.value)}
              >
                <CloseIcon />
              </span>
            </label>
          ))}
        </div>
        <div className="col-md-12">
          <div className="row">
            <TableWrapper style={{ marginTop: 0 }}>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th size={1.2}>
                        <div className="th_label">Chat ID</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Chat ID');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Chat ID' ? (
                          <DropdwonWithDebounce
                            header={'ChatID'}
                            content={'All Email'}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            list={dropdownIDList}
                            value={state.chat_id}
                            showNoDataFound={dropdownIDList?.length == 0}
                            onClick={(data: any) => {
                              setState({ ...state, chat_id: data });
                              setSelectedState({
                                ...selectedState,
                                chat_id: data,
                              });
                              //  setSearchWord(data)
                              setIdFocus({ focus: false, value: 0 });
                              //  data == "all" && props.callEmailListAPI()
                            }}
                            onChange={(
                              value:string
                            ) => {
                              setIdFocus({
                                ...focus,
                                value: Number(value),
                              });
                              setShowLoader('search');
                            }}
                            toatlCount={totalIdcount}
                            inputOnClick={() => {
                              if (focus.focus == false) {
                                setIdFocus({ focus: true, value: 0 });
                                // filterByEmail == 'all' &&  props.callEmailListAPI()
                              }
                            }}
                            onBlur={() => {
                              //  if(focus.value.length == 0) {
                              setIdFocus({ focus: false, value: 0 });
                              //  }
                            }}
                            showLoader={showLoader}
                            fetchData={() => {
                              console.log('fetching next');
                              setFilterpage(filterPage + 1);
                              chatIDSearch(idFocus.value);
                              setShowLoader('scroll');
                            }}
                            loader={() => {
                              setShowLoader('search');
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={1.2}>
                        <div className="th_label">Location</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Location');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Location' ? (
                          <DropdwonWithDebounce
                            header={'Location'}
                            content={'All Email'}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            list={dropdownLocationList}
                            value={state.location}
                            showNoDataFound={dropdownLocationList?.length == 0}
                            onClick={(data: any) => {
                              setState({ ...state, location: data });
                              setSelectedState({
                                ...selectedState,
                                location: data,
                              });
                              //  setSearchWord(data)
                              setLocationFocus({ focus: false, value: '' });
                              //  data == "all" && props.callEmailListAPI()
                            }}
                            onChange={(
                              value:string
                            ) => {
                              setLocationFocus({
                                ...focus,
                                value:value,
                              });
                              setShowLoader('search');
                            }}
                            toatlCount={totalLocationcount}
                            inputOnClick={() => {
                              if (focus.focus == false) {
                                setLocationFocus({ focus: true, value: '' });
                                // filterByEmail == 'all' &&  props.callEmailListAPI()
                              }
                            }}
                            onBlur={() => {
                              //  if(focus.value.length == 0) {
                              setIdFocus({ focus: false, value: 0 });
                              //  }
                            }}
                            showLoader={showLoader}
                            fetchData={() => {
                              console.log('fetching next');
                              setFilterpage(filterPage + 1);
                              locationSearch(locationFocus.value);
                              setShowLoader('scroll');
                            }}
                            loader={() => {
                              setShowLoader('search');
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={1.6}>
                        <div className="th_label">Tag</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Tag');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Tag' ? (
                          <DropdwonWithDebounce
                            header={'Filter by'}
                            content={'All Email'}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            list={dropdownTagList}
                            value={state.tag}
                            showNoDataFound={dropdownTagList?.length == 0}
                            onClick={(data: any) => {
                              setState({ ...state, tag: data });
                              setSelectedState({ ...selectedState, tag: data });
                              //  setSearchWord(data)
                              setTagFocus({ focus: false, value: '' });
                              //  data == "all" && props.callEmailListAPI()
                            }}
                            toatlCount={totalTagcount}
                            onChange={(
                              value:string
                            ) => {
                              setTagFocus({ ...focus, value: value });
                              setShowLoader('search');
                            }}
                            inputOnClick={() => {
                              if (focus.focus == false) {
                                setTagFocus({ focus: true, value: '' });
                                // filterByEmail == 'all' &&  props.callEmailListAPI()
                              }
                            }}
                            onBlur={() => {
                              //  if(focus.value.length == 0) {
                              setTagFocus({ focus: false, value: '' });
                              //  }
                            }}
                            showLoader={showLoader}
                            fetchData={() => {
                              console.log('fetching next');
                              setFilterpage(filterPage + 1);
                              tagSearch(tagFocus.value);
                              setShowLoader('scroll');
                            }}
                            loader={() => {
                              setShowLoader('');
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={2}>
                        <div className="th_label">Agent name</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Agent Name');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Agent Name' ? (
                          <DropdwonWithDebounce
                            header={'Filter by'}
                            content={'All Email'}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            list={dropDownAgentList}
                            value={state.agent_email}
                            showNoDataFound={dropDownAgentList?.length == 0}
                            onClick={(data: any) => {
                              setState({ ...state, agent_email: data });
                              setSelectedState({ ...selectedState, agent_email: data });
                              //  setSearchWord(data)
                              setAgentFocus({ focus: false, value: '' });
                              //  data == "all" && props.callEmailListAPI()
                            }}
                            toatlCount={totalAgentCount}
                            onChange={(
                              value:string
                            ) => {
                              console.log(
                                value,
                                'debouncedOnChange search'
                              );
                              setAgentFocus({
                                ...focus,
                                value: value,
                              });
                              setShowLoader('search');
                            }}
                            inputOnClick={() => {
                              if (focus.focus == false) {
                                setAgentFocus({ focus: true, value: '' });
                                // filterByEmail == 'all' &&  props.callEmailListAPI()
                              }
                            }}
                            onBlur={() => {
                              //  if(focus.value.length == 0) {
                              setAgentFocus({ focus: false, value: '' });
                              //  }
                            }}
                            showLoader={showLoader}
                            fetchData={() => {
                              console.log('fetching next');
                              setFilterpage(filterPage + 1);
                              agentEmailSearch(agentfocus.value);
                              setShowLoader('scroll');
                            }}
                            loader={() => {
                              setShowLoader('');
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={2}>
                        <div className="th_label">User name</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('User Name');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'User Name' ? (
                          <DropdwonWithDebounce
                            header={'Filter by'}
                            content={'All Email'}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            list={dropDownUserList}
                            value={state.user_email}
                            showNoDataFound={dropDownUserList?.length == 0}
                            onClick={(data: any) => {
                              setState({ ...state, user_email: data });
                              setSelectedState({ ...selectedState, user_email: data });
                              //  setSearchWord(data)
                              setUserFocus({ focus: false, value: '' });
                              //  data == "all" && props.callEmailListAPI()
                            }}
                            onChange={(
                              value:string
                            ) => {
                              console.log(value, 'debouncedOnChange');
                              setUserFocus({ ...focus, value: value });
                              setShowLoader('search');
                            }}
                            toatlCount={totalUserCount}
                            inputOnClick={() => {
                              if (focus.focus == false) {
                                setUserFocus({ focus: true, value: '' });
                                // filterByEmail == 'all' &&  userEmailSearch("")
                              }
                            }}
                            onBlur={() => {
                              if (userfocus.value.length == 0) {
                                setUserFocus({ focus: false, value: '' });
                              }
                            }}
                            showLoader={showLoader}
                            fetchData={() => {
                              console.log('fetching next');
                              setFilterpage(filterPage + 1);
                              userEmailSearch(userfocus.value);
                              setShowLoader('scroll');
                            }}
                            loader={() => {
                              setShowLoader('');
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={1.5}>
                        <div className="th_label">Chat Source</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Chat Source');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Chat Source' ? (
                          <DropdwonLogSearch
                            header={filterby}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            settingSearch={(e: {
                              target: { value: React.SetStateAction<string> };
                            }) => {
                              setSearchWord(e.target.value);
                            }}
                            setSearchWord={setSearchWord}
                            // content={'All Sessions'}
                            placeHolder="Select Bot"
                            list={
                              searchWord == '' && bots
                                ? [
                                    { id: 'All Value', name: 'All Chat Source' },
                                    ...bots,
                                  ]
                                : bots && [
                                    { id: 'All Value', name: 'All Chat Source' },
                                    ...bots?.filter((b) =>
                                      b.name
                                        .toLowerCase()
                                        .includes(searchWord.toLowerCase())
                                    ),
                                  ]
                            }
                            value={state.bot_id !== '' ? state.bot_id : ''}
                            onClick={(data: any) => {
                              if (data === 'All') {
                                setState({ ...state, bot_id: '' });
                                setSelectedState({
                                  ...selectedState,
                                  bot_id: 'All Value',
                                });
                              } else {
                                setState({ ...state, bot_id: data });
                                setSelectedState({
                                  ...selectedState,
                                  bot_id: data,
                                });
                              }
                              // setCurrentPage(1)
                            }}
                            className={'filter_by_dorpDown_box1'}
                          />
                        ) : null}
                      </Th>
                      <Th size={1.5}>
                        <div className="th_label">Chat Channel</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Chat Channel');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Chat Channel' ? (
                          <DropdwonLogSearch
                            header={filterby}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            settingSearch={(e: {
                              target: { value: React.SetStateAction<string> };
                            }) => {
                              setSearchWord(e.target.value);
                            }}
                            setSearchWord={setSearchWord}
                            placeHolder=""
                            // content={'All Sessions'}
                            list={
                              searchWord == ''
                                ? chatList
                                : chatList.filter((b) =>
                                    b.name
                                      .toLowerCase()
                                      .includes(searchWord.toLowerCase())
                                  )
                            }
                            value={
                              state.chat_channel !== ''
                                ? state.chat_channel
                                : ''
                            }
                            onClick={(data: any) => {
                              if (data === 'All') {
                                setState({ ...state, chat_channel: '' });
                                setSelectedState({
                                  ...selectedState,
                                  chat_channel: 'All Value',
                                });
                              } else {
                                setState({ ...state, chat_channel: data });
                                setSelectedState({
                                  ...selectedState,
                                  chat_channel: data,
                                })
                            }}}
                            className={'filter_by_dorpDown_box2'}
                          />
                        ) : null}
                      </Th>
                      <Th size={1}>
                        <div className="th_label">Feedback</div>
                        <span
                          className="liveAt_logs_filter"
                          onClick={() => {
                            setFilterDropDown(true);
                            setFilterBy('Feedback');
                          }}
                        >
                          <AppTriggerFilterIcon />
                        </span>
                        {filterDropDown && filterby == 'Feedback' ? (
                          <DropdwonLogSearch
                            header={filterby}
                            filterDropDown={filterDropDown}
                            closeFilterpopup={closeFilterpopup}
                            settingSearch={(e: {
                              target: { value: React.SetStateAction<string> };
                            }) => {
                              setSearchWord(e.target.value);
                            }}
                            setSearchWord={setSearchWord}
                            // content={'All Sessions'}
                            list={
                              searchWord == ''
                                ? feedBackList
                                : feedBackList.filter((b) =>
                                    b.name
                                      .toLowerCase()
                                      .includes(searchWord.toLowerCase())
                                  )
                            }
                            value={selectedState.feedback !== '' ? selectedState.feedback : ''}
                            onClick={(data: any) => {
                              if (data === 'All') {
                                setState({ ...state, feedback: '' });
                                setSelectedState({
                                  ...selectedState,
                                  feedback: 'All Value',
                                });
                              } else {
                                setState({ ...state, feedback: data });
                                setSelectedState({
                                  ...selectedState,
                                  feedback: data,
                                })
                                // setCurrentPage(1)
                              }
                            }}
                            className={'filter_by_dorpDown_box2'}
                          />
                        ) : null}
                      </Th>
                    </Tr>
                  </Thead>
                  {chatHistoryData && chatHistoryData.length > 0 ? (
                    <Tbody style={{minHeight: 'calc(100vh - 550px)'}}>
                      {chatHistoryData &&
                        chatHistoryData.length > 0 &&
                        chatHistoryData.map((data, index) => (
                          <Tr key={index} 
                            onClick={() => {
                              setSessionID(data.session_id);
                              setSessionPopup(true);
                            }}
                          >
                            <Td size="1.2">
                              <div
                                className="td_header chat_id"
                              >
                                #
                                {parseInt(data.chat_id) < 10
                                  ? '00' + data.chat_id
                                  : parseInt(data.chat_id) < 100
                                  ? '0' + data.chat_id
                                  : data.chat_id}
                              </div>
                            </Td>
                            <Td size="1.2">
                              <div className="td_header">
                                {data.location ? data.location : <span className='liveAt_empty'>N/A</span>}
                              </div>
                            </Td>
                            <Td size="1.6">
                              <div
                                className={
                                  data.tags.length > 0
                                    ? 'td_header liveAt_tags'
                                    : ''
                                }
                              >
                                {data.tags && data.tags.length > 1 ? (
                                  <>
                                    <span className='liveAt_tags_box'>
                                      <div><LATagIcon /> </div>                                      
                                      <div>{data.tags[0].tag}</div>
                                    </span>
                                    <span className="td_header liveAt_tags numbers">
                                      <LAPlusIcon /> {data.tags.length - 1}
                                    </span>
                                  </>
                                ) : data.tags.length > 0 ? (
                                  <span className='liveAt_tags_box'>
                                    {' '}
                                    <div><LATagIcon /> </div>                                      
                                    <div>{data.tags[0].tag}</div>
                                  </span>
                                ) : (
                                  <span className='liveAt_empty'>-</span>
                                )}                               
                              </div>
                            </Td>
                            <Td size="2">
                              {/* <div className='liveAgent_chat'>
                                            <div><SlackIconCC /></div>
                                            <div className='td_header'>IT Support Bot</div>
                                        </div> */}
                              <div className="td_header">
                                {data.agent_email}
                              </div>
                            </Td>
                            <Td size="2">
                              {/* <div className='liveAgent_chat'>
                                            <div><SlackIconCC /></div>
                                            <div className='td_header'>IT Support Bot</div>
                                        </div> */}
                              <div className="td_header">{data.user_email}</div>
                            </Td>
                            <Td size="1.5">
                              <div className="liveAgent_chat">
                                <div style={{width: '20px', height: "20px"}}>
                                  <DialogIcon />
                                </div>
                                <div className="td_header">
                                {botName(data.bot_id)}
                              </div>
                              </div>
                            </Td>
                            <Td size="1.5">
                              <div className="liveAgent_chat">
                                <div>{platformIcon(data.platform)}</div>
                                <div className="td_header">{capitalizeFirstLetter(data.platform)}</div>
                              </div>
                            </Td>
                            <Td size="1" className="liveAt_feedback">
                              <div className="liveAgent_chat feedback">
                                <div>
                                  {data.feedback === null ? (
                                    '-'
                                  ) : data.feedback === 'Satisfied' ? (
                                    <NewThumbsUpIcon />
                                  ) : (
                                    <NewThumbsDownIcon />
                                  )}
                                </div>
                              </div>
                            </Td>
                            <Td></Td>
                          </Tr>
                        ))}
                    </Tbody>
                  ) : (
                    <div className="liveAgent_inbox_conversation_trash">
                      <p>No Chat Information to display</p>
                    </div>
                  )}
                </Table>
              </TableContainer>
            </TableWrapper>
          </div>
        </div>

        {sessionPopup && !_.isEqual(sessionData, initialSessionData) && (
          <div className="la_logs_popup">
            <div className="la_logs_header">
              <div className="la_logs_header_box1">
                <div>
                  <UserIconAnalyticsMenu />
                </div>
                <div>
                  <span>User</span>
                  <h5>{sessionData.user.first_name}</h5>
                  <p>{sessionData.user.email}</p>
                  <p>
                    {sessionData.meta.location !== null &&
                      sessionData.meta.location}
                  </p>
                </div>
              </div>
              <div className="la_logs_header_box2">
                <h6>
                  Chat ID:{' '}
                  {sessionData.chat_id < 10
                    ? '00' + sessionData.chat_id
                    : sessionData.chat_id < 100
                    ? '0' + sessionData.chat_id
                    : sessionData.chat_id}
                </h6>
              </div>
              <div className="la_logs_header_box3">
                <div>
                  <UserIconAnalyticsMenu />
                </div>
                <div>
                  <span>Agent</span>
                  <h5>{sessionData.assigned_agent?.first_name + " "+ sessionData.assigned_agent?.last_name }</h5>
                  <p>{sessionData.assigned_agent?.email}</p>
                  {/* <p>USA</p> */}
                </div>
              </div>
              <div
                className="la_logs_close_btn"
                onClick={() => {
                  setSessionPopup(false);
                }}
              >
                <CloseIconWithCircle />
              </div>
            </div>
            <div className="la_logs_body">
              {console.log(eventData, sessionData, 'eventData')}
              {eventData &&
                singleSessionData &&
                eventData.length > 0 &&
                eventData
                  // .filter((mh) => mh.data.session_id === singleSessionData.id)
                  .map(
                    (mess: LogEventsT, index: number) =>
                      !Object.keys(mess).includes('event_type') ? (
                        // checkTeamConvo(mess)
                        <DisplayTeamEvent
                          mess={mess as teamsType}
                          botName={botName}
                          userName={singleSessionData.user.first_name}
                          sessionData={singleSessionData}
                          key={index}
                        />
                      ) : (
                        <DisplayOldEvent
                          mess={mess as eventType}
                          singleSessionData={singleSessionData}
                          botName={botName}
                          agentName={findAgentName}
                          key={index}
                        />
                      )
                    //     checkdata(mess) ? (
                    //     // mess.event_type === ReceiveEvents.Connect ||
                    //     // mess.event_type === ReceiveEvents.Disconnect ? (
                    //     //   <div className="liveAt_new_message_box">
                    //     //     <div>
                    //     //       <span className="liveAt_new_message_user">{`${mess.data.name}`}</span>
                    //     //       {` joined the queue from`}
                    //     //       <span className="liveAt_new_message_platform">
                    //     //         {' '}
                    //     //         <TeamsIcon />{' '}
                    //     //       </span>{' '}
                    //     //       {`${mess.data.platform} at `}
                    //     //       <i className="liveAt_new_message_time">
                    //     //         {`${formatAMPM(
                    //     //           mess.data.message_time.toString()
                    //     //         )} | ${formatDateInCard(
                    //     //           mess.data.message_time.toString()
                    //     //         )}`}
                    //     //       </i>
                    //     //     </div>
                    //     //     <p>
                    //     //       {`Chatting from:`}{' '}
                    //     //       <span>{`${botName(mess.data.bot_id)}`}</span>{' '}
                    //     //     </p>
                    //     //   </div>
                    //     // ) :
                    //     // mess.event_type === ReceiveEvents.AgentMessage ||
                    //     // mess.event_type === ReceiveEvents.UserMessage ||
                    //     // mess.event_type === ReceiveEvents.Comment ||
                    //     // mess.event_type === ReceiveEvents.Mention ?
                    //     <div
                    //       className={
                    //         mess.event_type === ReceiveEvents.UserMessage
                    //           ? 'liveAgent_message_container left_message'
                    //           : 'liveAgent_message_container right_message'
                    //       }
                    //     >
                    //       <span>
                    //         <UserIconAnalyticsMenu />
                    //       </span>
                    //       <div className="liveAgent_user_message">
                    //         <h5>
                    //           {findAgentName(mess.data.agent_id)}
                    //           <span>
                    //             {formatAMPM(mess.data.message_time.toString())}
                    //           </span>
                    //           {/* <span>
                    //               <CheckIcon />
                    //             </span> */}
                    //         </h5>
                    //         <p>
                    //           <AssistantMd
                    //             mdText={convertToMDText(mess.data.message)}
                    //           />
                    //         </p>
                    //       </div>
                    //     </div>
                    //   ):mess.event_type === ReceiveEvents.AgentMessage ||
                    //   mess.event_type === ReceiveEvents.UserMessage ? (
                    //   <div
                    //     className={
                    //       mess.event_type === ReceiveEvents.UserMessage
                    //         ? 'liveAgent_message_container'
                    //         : 'liveAgent_message_container right_message'
                    //     }
                    //   >
                    //     <span>
                    //       <UserIconAnalyticsMenu />
                    //     </span>
                    //     <div className="liveAgent_user_message">
                    //       <h5>
                    //         {mess.event_type === ReceiveEvents.UserMessage
                    //           ? singleSessionData?.user.user_name
                    //           : findAgentName(mess.agent_id)}
                    //         <span>
                    //           {formatAMPM(mess.created_at)}
                    //         </span>
                    //         {/* <span>
                    //             <CheckIcon />
                    //           </span> */}
                    //       </h5>
                    //       <p>
                    //         <AssistantMd mdText={convertToMDText(mess.message)} />
                    //       </p>
                    //     </div>
                    //   </div>
                    // ) : mess.event_type === ReceiveEvents.Comment ||
                    //   mess.event_type === ReceiveEvents.Mention ? (
                    //   <div
                    //     className="liveAgent_message_container right_message comment"
                    //     // style={{'background': '#E8FFFB 0% 0% no-repeat padding-box',
                    //     //   'boxShadow': '0px 3px 6px #00000029',
                    //     //   'borderRadius': '10px'}}
                    //   >
                    //     <span>
                    //       <UserIconAnalyticsMenu />
                    //     </span>
                    //     <div className="liveAgent_user_message">
                    //       <h5>
                    //         {findAgentName(mess.agent_id)}
                    //         <span>
                    //           {formatAMPM(mess.created_at)}
                    //         </span>
                    //         {/* <span>
                    //             <CheckIcon />
                    //           </span> */}
                    //       </h5>
                    //       <p style={{ fontSize: '10px', display: 'flex' }}>
                    //             <div className='liveAgent_user_message_eyeicon'>
                    //               <EyeOpenIcon />
                    //             </div>
                    //         Only visible to your team
                    //       </p>
                    //       <p>
                    //         <AssistantMd mdText={convertToMDText(mess.message)} />
                    //       </p>
                    //     </div>
                    //   </div>
                    // ):mess.event_type === ReceiveEvents.Connect ||
                    // mess.event_type === ReceiveEvents.Disconnect ? (
                    //   <div className="liveAt_new_message_box">
                    //     <div>
                    //       <span className="liveAt_new_message_user">{`${mess.user_firstname}`}</span>
                    //       {` joined the queue from`}
                    //       <span className="liveAt_new_message_platform">
                    //         {' '}
                    //         <TeamsIcon />{' '}
                    //       </span>{' '}
                    //       {`${mess.platform} at `}
                    //       <i className="liveAt_new_message_time">
                    //         {`${formatAMPM(
                    //           mess.created_at
                    //         )} | ${formatDateInCard(
                    //           mess.created_at
                    //         )}`}
                    //       </i>
                    //     </div>
                    //     <p>
                    //       {`Chatting from:`}{' '}
                    //       {<span>IT Support Bot</span>}
                    //       {/* <span>{`${botName(mess.data.bot_id)}`}</span>{' '} */}
                    //     </p>
                    //   </div>
                    // )
                    //    : null
                  )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
