import { AddIcon, DropdownWithCircle, InfoIcon } from '@/common/Icons/Icons';
import { TabContainer, TabHeader, TabRow } from '@/common/styled/Settings.Dumb';
import { Button } from '@/common/styled/Workflow.Dumb';
import { SwitchWrapper } from '@/Flows/Card';
import Tooltip from 'react-tooltip-lite';
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
  } from '@/common/styled/Workflow.Analytics.Dumb';
import React from 'react';

export function KnowledgeSettings() {
  return (
    <section className="entity_cards settings pb-4">
      <div className="container-fluid page__center">
        <div className="row">
          <div className="dialog_settings">
            <TabContainer>
              <TabRow>
                <TabHeader
                // active={currentTab == 'assistant'}
                // onClick={() => setTab('assistant')}
                >
                  Genral
                </TabHeader>
                <TabHeader
                // active={currentTab == 'ai_settings'}
                // onClick={() => setTab('ai_settings')}
                >
                  Connect Open AI
                </TabHeader>
              </TabRow>
            </TabContainer>

        
              <div className="dialog_settings_body knowledge_settings hide">
                <div className="dialog_settings_body_bg">
                  <div className="dialog_settings_body_pad">
                    <h5>
                      Restrict sensitive data (PII)
                      <SwitchWrapper>
                        <input
                          type="checkbox"
                          className="switch_1 automation_home_switch_without_hover settings_page"
                          checked
                        />
                      </SwitchWrapper>
                    </h5>
                    <p>Restrict sensitive data (PII)</p>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Name
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Email Address
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Phone Number
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            SSN Number
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Credit Card Number
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Device_Type
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Vehicles
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Tickets
                        </div>
                    </div>
                    <div className='knowledge_settings_checkbox'>
                        <div className="switch_box box_1">
                            <input
                                type="checkbox"
                                // className={`switch_1`}                               
                            />
                            Laptop
                        </div>
                    </div>
                    
                    <Button style={{display: 'flex', alignItems: 'center'}} primary>
                        <span><AddIcon/></span>
                        <label>Create New</label>
                    </Button>

                  </div>
                </div>
                <div className="dialog_settings_body_pad knowledge_settings_box">
                    <h5>
                    Block code/script uploads on chat
                      <SwitchWrapper>
                        <input
                          type="checkbox"
                          className="switch_1 automation_home_switch_without_hover settings_page"
                          checked
                        />
                      </SwitchWrapper>
                    </h5>
                    <p>This will block uploading code/scripting by the end user in chat.</p>
                    
                  </div>
                  <div className="dialog_settings_body_pad knowledge_settings_box">
                    <h5>
                        Response to show
                            <Tooltip
                                className="target customTip settings_info"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content=";OLNCV;DSOKNC;ODSNC"
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                    </h5>
                    <InputContainer style={{ width: '40%'}}>
                        <Input
                            type="text"
                            vbaluse="1"
                        />
                        <InputContent>
                        Number of responses to display to a user query
                        </InputContent>
                        <CancelButton
                            className="d-flex"
                            style={{ top: 15 }}
                        >
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                    
                  </div>
              </div>

              <div className="dialog_settings_body knowledge_settings">
                <div className="dialog_settings_body_bg">
                  <div className="dialog_settings_body_pad">
                    <h5>
                        Connect Open AI
                            <Tooltip
                                className="target customTip settings_info"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content=";OLNCV;DSOKNC;ODSNC"
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                    </h5>
                    <InputContainer style={{ width: '100%'}}>
                        <Input
                            type="text"
                            vbaluse="1"
                        />
                        <InputContent>
                            Secret key
                        </InputContent>
                        <CancelButton
                            className="d-flex"
                            style={{ top: 15 }}
                        >
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}
