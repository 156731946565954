import React, { useContext, useEffect, useState } from 'react'

import { DropdownWithCircle } from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import { DropDown } from '../../../Common/Effective'
import { EntityT, defaultEntity, EntityValueT } from '../../../Entity/kind'
//CONTEXT IMPORT
import { DialogueContext } from '../../Context'
import { validateNode } from '../../DialogueMap/Descendant'
import { ActionT } from '../../kind'
import { operatorsT } from './kind'
import { sortAnArrayByName } from '@/common/utils/common.utils'

const EntityCompoenent = ({ condition, onChange, conditionEdit }: any) => {
    const [entityChooser, setEntitChooser] = useState<boolean>(false)
    const [conditionChooser, setConditionChooser] = useState<boolean>(false)
    const [valueChooser, setValueChooser] = useState<boolean>(false)
    const [currentEntity, setCurrentEntity] = useState<EntityT>(defaultEntity)

    const [search, setSearch] = useState({
        searching: false,
        key: '',
        inFocus: false,
        dropdown:"values"
    })


    const { entities } = useContext(DialogueContext)

    useEffect(() => {
        if (condition.name != '' && entities.length > 0) {
            const entity = entities.filter(e => e.entity == condition.name.trim()).pop()
            if (entity) {
                setCurrentEntity(entity)
            } else {
                onChange({
                    ...condition,
                    name: '',
                    value: ''
                })
            }
        }
    }, [condition])

    const context = useContext(DialogueContext)

    const showError =
        (context.PageState.action == ActionT.SLOT_RESPONSE ||
            context.PageState.action == ActionT.ATTACH_RESPONSE ||
            (context.PageState.action == ActionT.IF_DIALOGUE &&
                context.PageState.data.conditions &&
                condition.operator != 'SELECT' &&
                (context.PageState.data.conditions.entity == condition.value ||
                    (condition.value.length == 0 && condition.name.length == 0)))) &&
        context.PageState.data.id.length > 0

    return (
        <React.Fragment>
            <div className="automation_condition_form reg_condition_form">
                <div className="select_work_form">
                    <InputContainer className="mb-0" style={{ marginTop: 20 }}>
                        <Input
                            type="text"
                            value={search.dropdown == "entity"  && search.searching  ? search.key : condition.name}
                            // onChange={() => {}}
                            onClick={(e: any) => setEntitChooser(true)}
                            onFocus={() => {
                                setSearch({
                                    inFocus: true,
                                    searching: true,
                                    key: condition.name
                                            ? condition.name
                                            : '',
                                    dropdown: "entity"
                                })
                            }}
                            onBlur={() => {
                                setSearch({
                                    ...search,
                                    inFocus: false,
                                    dropdown:"entity"
                                })
                            }}
                            onChange={(e: any) => {
                                console.log(e.target.value)
                                setSearch({ ...search, key: e.target.value })
                            }}
                        />
                        {showError && (validateNode(context, condition) || validateNode(context, conditionEdit)) && (
                            <div className="error_input_show">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">Entity is not available.</span>
                            </div>
                        )}
                        <InputContent>Select Entity</InputContent>
                        <CancelButton onClick={(e: any) => setEntitChooser(true)}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>

                    {entityChooser ? (
                        <DropdownUlWrapper>
                            <DropDown isOpen={entityChooser} onOuterClick={() => {
                                    !search.inFocus&&setSearch({
                                        ...search,
                                        inFocus:false,
                                        searching: false,
                                        key: "",
                                        dropdown:"entity"
                                    })
                                    setEntitChooser(false)
                                }}>
                                <div className="automation_scroly" style={{ top: '0px' }}>
                                    <DropdownUl>
                                        {(search.searching && search.dropdown == "entity" ? sortAnArrayByName(entities,"entity").filter(
                                                (ent:EntityT) => ent.entity.toLowerCase().indexOf(search.key.toLowerCase()) > -1
                                            ) : sortAnArrayByName(entities,"entity")).map((entity:EntityT, index:number) => (
                                            <DropdownLi
                                                key={index}
                                                onClick={(e: any) => {
                                                    setCurrentEntity(entity)
                                                    setEntitChooser(false)
                                                    onChange({
                                                        ...condition,
                                                        name: entity.entity,
                                                        operator: operatorsT.SELECT,
                                                        value: ''
                                                    })
                                                    setSearch({
                                                        ...search,
                                                        inFocus:false,
                                                        searching: false,
                                                        key: "",
                                                        dropdown:"entity"
                                                    })
                                                }}
                                            >
                                                {entity.entity}
                                            </DropdownLi>
                                        ))}
                                    </DropdownUl>
                                </div>
                            </DropDown>
                        </DropdownUlWrapper>
                    ) : null}
                </div>
            </div>
            {condition.name != '' ? (
                <React.Fragment>
                    {/* condition area */}
                    <div className="automation_condition_form">
                        <div className="select_work_form">
                            <InputWrapper marginTop style={{marginBottom: 0}}>
                                <InputContainer>
                                    <Input
                                        type="text"
                                        value={
                                            condition.operator == operatorsT.IS
                                                ? 'value is'
                                                : condition.operator == operatorsT.IS_NOT
                                                ? 'value is not'
                                                : condition.operator == operatorsT.IS_PRESENT
                                                ? 'is recognized'
                                                : condition.operator == operatorsT.IS_NOT_PRESENT
                                                ? 'is not recognized'
                                                : ''
                                        }
                                        onChange={() => {}}
                                        onClick={(e: any) => setConditionChooser(true)}
                                    />
                                    <InputContent>Select Logic</InputContent>
                                    <CancelButton onClick={(e: any) => setConditionChooser(true)}>
                                        <DropdownWithCircle />
                                    </CancelButton>
                                </InputContainer>
                            </InputWrapper>
                            {conditionChooser ? (
                                <DropdownUlWrapper>
                                    <DropDown isOpen={conditionChooser} onOuterClick={() => setConditionChooser(false)}>
                                        <div className="automation_scroly" style={{ top: '0px' }}>
                                            <DropdownUl>
                                                <DropdownLi
                                                    onClick={(e: any) => {
                                                        setConditionChooser(false)
                                                        onChange({
                                                            ...condition,
                                                            operator: operatorsT.IS_PRESENT,
                                                            value: ''
                                                        })
                                                    }}
                                                >
                                                    {'is recognized'}
                                                </DropdownLi>
                                                <DropdownLi
                                                    onClick={(e: any) => {
                                                        setConditionChooser(false)
                                                        onChange({
                                                            ...condition,
                                                            operator: operatorsT.IS_NOT_PRESENT,
                                                            value: ''
                                                        })
                                                    }}
                                                >
                                                    {'is not recognized'}
                                                </DropdownLi>
                                                <DropdownLi
                                                    onClick={(e: any) => {
                                                        setConditionChooser(false)
                                                        onChange({
                                                            ...condition,
                                                            operator: operatorsT.IS,
                                                            value: ''
                                                        })
                                                    }}
                                                >
                                                    {'value is'}
                                                </DropdownLi>
                                                <DropdownLi
                                                    onClick={(e: any) => {
                                                        setConditionChooser(false)
                                                        onChange({
                                                            ...condition,
                                                            operator: operatorsT.IS_NOT,
                                                            value: ''
                                                        })
                                                    }}
                                                >
                                                    {'value is not'}
                                                </DropdownLi>{' '}
                                            </DropdownUl>
                                        </div>
                                    </DropDown>
                                </DropdownUlWrapper>
                            ) : null}
                        </div>
                    </div>
                    {/* value area */}
                    {condition.operator != operatorsT.SELECT &&
                    condition.operator != operatorsT.IS_NOT_PRESENT &&
                    condition.operator != operatorsT.IS_PRESENT ? (
                        <div className="automation_condition_form">
                            <div className="select_work_form entity_select_value">
                                <InputWrapper style={{ marginTop: 20 }}>
                                    <InputContainer>
                                        <Input
                                            type="text"
                                            value={search.searching && search.dropdown == "values" ? search.key :condition.value}
                                            onFocus={() => {
                                                setSearch({
                                                    inFocus: true,
                                                    searching: true,
                                                    key: condition.value
                                                            ? condition.value
                                                            : '',
                                                    dropdown : "values"
                                                })
                                            }}
                                            onBlur={() => {
                                                setSearch({
                                                    ...search,
                                                    inFocus: false,
                                                    dropdown: "values"
                                                })
                                            }}
                                            onChange={(e: any) => {
                                                console.log(e.target.value)
                                                setSearch({ ...search, key: e.target.value })
                                            }}
                                            onClick={(e: any) => setValueChooser(true)}
                                        />
                                        <InputContent>Select Value</InputContent>
                                        <CancelButton onClick={(e: any) => setValueChooser(true)}>
                                            <DropdownWithCircle />
                                        </CancelButton>
                                    </InputContainer>
                                </InputWrapper>
                                {valueChooser ? (
                                    <DropdownUlWrapper>
                                        <DropDown isOpen={valueChooser} onOuterClick={() => {
                                            !search.inFocus && setSearch({
                                                ...search,
                                                inFocus:false,
                                                searching: false,
                                                key: "",
                                                dropdown:"values"
                                            })
                                            setValueChooser(false)}}>
                                            <div className="automation_scroly">
                                                <DropdownUl>
                                                    {(search.searching && search.dropdown == "values" ? sortAnArrayByName(currentEntity.values,"value").filter(
                                                       (ent:EntityValueT) => ent.value.toLowerCase().indexOf(search.key.toLowerCase()) > -1
                                                     ) :  sortAnArrayByName(currentEntity.values,"value")).map((value: EntityValueT, index: number) => (
                                                        <DropdownLi
                                                            key={index}
                                                            onClick={(e: any) => {
                                                                setValueChooser(false)
                                                                onChange({
                                                                    ...condition,
                                                                    value: value.value
                                                                })
                                                                setSearch({
                                                                    ...search,
                                                                    inFocus:false,
                                                                    searching: false,
                                                                    key: "",
                                                                    dropdown:"values"
                                                                })
                                                            }}
                                                        >
                                                            {value.value}
                                                        </DropdownLi>
                                                    ))}
                                                </DropdownUl>
                                            </div>
                                        </DropDown>
                                    </DropdownUlWrapper>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

export default EntityCompoenent
