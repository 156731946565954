import * as R from 'ramda'
/*eslint-disable*/
import React, { useEffect } from 'react'
/*eslint-disable*/
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'

import { useJourney, useUpdateJourney } from '@/Journey/JourneyContext'
import { createUserJourney } from '@/Journey/JourneyMachine'

import { getJson, userJourneyWithWorkspace } from '@/common/utils/api-utils'
import { Loader } from '../components/Loader'
import { getProductId } from '../utils/utils'
import {AuthType, useMFAValidator} from '../utils/auth-token-api-utils'

function initializeAnalytics() {
    const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

    const toDebug = R.isNil(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG)
        ? false
        : JSON.parse(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG)

    if (analyticsId) {
        ReactGA.initialize(analyticsId, {
            debug: toDebug,
            titleCase: true
        })
    }
}

const DIALOGUE_BOT_PAGE = 'Assistant Homepage | Workativ Assistant'

const DIALOGUE_HOME_PAGE = 'Dialog Homepage | Workativ Assistant'
const DIALOGUE_DETAIL_PAGE = 'Dialog Canvaspage | Workativ Assistant'

const INTENT_HOME_PAGE = 'Intent Homepage | Workativ Assistant'
const INTENT_DETAIL_PAGE = 'Intent Detailpage | Workativ Assistant'

const ENTITY_HOME_PAGE = 'Entity Homepage | Workativ Assistant'
const ENTITY_DETAIL_PAGE = 'Entity Detailpage | Workativ Assistant'

const DIALOGUE_ANALYTICS = 'Chatbot Analytics | Workativ Assistant'
const DIALOGUE_LOGS = 'Chatbot Logs | Workativ Assistant'
const DIALOGUE_SETTINGS = 'Dialog Settings | Workativ Assistant'

const TRINNING_MODULE = 'Train Assistant Homepage | Workativ Assistant'

const INTEGRATION_HOME_PAGE = 'Chat Channel Homepage | Workativ Assistant'
const SLACK_INTEGRATION = 'Slack Integration page | Workativ Assistant'
const TEAMS_INTEGRATION = 'Teams Integration page | Workativ Assistant'

const SUPPORT_PAGE = 'Support Page | Workativ Assistant'

const PROFILE_PAGE = 'Profile Page | Workativ Assistant'

const USERS_PAGE = 'Manage Users Page | Workativ Assistant'

const MARKETPLACE_MY_UPLOADS = 'Marketplace My Uploads | Workativ Assistant'
const MARKETPLACE_SELECT_APPLICATION = 'Marketplace Applications Listing | Workativ Assistant'
const MARKETPLACE_LIST_AUTOMATIONS = 'Marketplace Automations Listing | Workativ Assistant'
const MARKETPLACE_AUTOMATION_DETAIL = 'Marketplace Automation canvas page | Workativ Assistant'

const AUTOMATION_HOME_PAGE = 'Workflows Homepage | Workativ Assistant'
const AUTOMATION_DRAFT = 'Workflow Drafts page | Workativ Assistant'
const AUTOMATION_TRIGGER = 'Trigger Workflows page | Workativ Assistant'
const AUTOMATION_NO_TRIGGER = 'No-Trigger Workflows page | Workativ Assistant'
const AUTOMATION_ACTIVE = 'Active Workflows page | Workativ Assistant'
const AUTOMATION_IN_ACTIVE = 'Inactive Workflows page | Workativ Assistant'

const AUTOMATION_ANALYTICS = 'Workflow Analytics | Workativ Assistant'
const AUTOMATION_LOGS = 'Workflow Logs | Workativ Assistant'

const useDocumentTitleUpdator = (props: { pathname: string; path: string }) => {
    const setAppTitle = () => {
        const path = props.path
        const pathname = props.pathname

        if (path === '/workspace/:workspacename/dialog/assistant') {
            document.title = DIALOGUE_BOT_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/homepage') {
            document.title = DIALOGUE_HOME_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node') {
            document.title = DIALOGUE_DETAIL_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/intent') {
            document.title = INTENT_HOME_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/intent/:intent') {
            document.title = INTENT_DETAIL_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/entity') {
            document.title = ENTITY_HOME_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/entity/:entity') {
            document.title = ENTITY_DETAIL_PAGE
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/analytics') {
            document.title = DIALOGUE_ANALYTICS
        } else if (path === '/workspace/:workspacename/dialog/:assistantname/logs') {
            document.title = DIALOGUE_LOGS
        } else if (path === '/workspace/:workspacename/dialog/settings') {
            document.title = DIALOGUE_SETTINGS
        } else if (path === '/workspace/:workspacename/train-assistant/home') {
            document.title = TRINNING_MODULE
        } else if (path === '/workspace/:workspacename/integration/chat') {
            document.title = INTEGRATION_HOME_PAGE
        } else if (pathname.includes('assistant/chat-channels/slack')) {
            document.title = SLACK_INTEGRATION
        } else if (pathname.includes('assistant/chat-channels/teams')) {
            document.title = TEAMS_INTEGRATION
        } else if (path === '/workspace/:workspacename/support') {
            document.title = SUPPORT_PAGE
        } else if (path === '/workspace/:workspacename/profile') {
            document.title = PROFILE_PAGE
        } else if (path === '/workspace/:workspacename/users') {
            document.title = USERS_PAGE
        } else if (path === '/workspace/:workspacename/marketplace/myuploads') {
            document.title = MARKETPLACE_MY_UPLOADS
        } else if (pathname.includes('marketplace/automation/select-application')) {
            document.title = MARKETPLACE_SELECT_APPLICATION
        } else if (pathname.includes('marketplace/automation/list-automations')) {
            document.title = MARKETPLACE_LIST_AUTOMATIONS
        } else if (path === '/workspace/:workspacename/marketplace/automation/detail/:id') {
            document.title = MARKETPLACE_AUTOMATION_DETAIL
        } else 
        // if (pathname.includes('automation/view/draft')) {
        //     document.title = AUTOMATION_HOME_PAGE
        // } else 
        if (pathname.includes('automation/view/draft')) {
            document.title = AUTOMATION_HOME_PAGE
        } else if (pathname.includes('automation/view/trigger')) {
            document.title = AUTOMATION_TRIGGER
        } else if (pathname.includes('automation/view/no-trigger')) {
            document.title = AUTOMATION_NO_TRIGGER
        } else if (pathname.includes('automation/view/active')) {
            document.title = AUTOMATION_ACTIVE
        } else if (pathname.includes('automation/view/inactive')) {
            document.title = AUTOMATION_IN_ACTIVE
        } else if (path === '/workspace/:workspacename/automation/analytics') {
            document.title = AUTOMATION_ANALYTICS
        } else if (path === '/workspace/:workspacename/automation/logs') {
            document.title = AUTOMATION_LOGS
        }
    }

    useEffect(setAppTitle, [props.pathname])
}

const useGoogleAnalytics = ({ pathname }: { pathname: string }) => {
    useEffect(() => {
        const page = pathname
        ReactGA.set({ page })
        ReactGA.pageview(page)
    }, [pathname])
}

type IM_ET = ReturnType<typeof createUserJourney>
export const TrackedAndProtected = (props: any) => {
    useGoogleAnalytics(props.location.pathname)

    useDocumentTitleUpdator({ path: props.path, pathname: props.location.pathname })

    return <>
             {props.children}
           </>
}

export const TrackedAndProtectedWithWorkspace = (props: any) => {
    const product = getProductId()
    const workspace = props.match.params.workspacename
    // const router = props.history
    // console.log('Inside TrackedAndProtected1 ::: props', props)
    const x1 = useUpdateJourney()
    useGoogleAnalytics(props.location.pathname)

    useDocumentTitleUpdator({ path: props.path, pathname: props.location.pathname })
    const rootJourneyContext = useJourney()
    // console.log(rootJourneyContext.type,'rootJourneyContext')
    const isMFARedirectionNeeded = useMFAValidator(workspace)
    // console.log(isMFARedirectionNeeded.value,'redirection')
    if(isMFARedirectionNeeded.value === AuthType.AuthNeeded) {
        window.location.href = `/workspace/${workspace}/users/auth`
    }
    React.useEffect(() => {
        if (rootJourneyContext.type !== 'success') {
            getJson(userJourneyWithWorkspace(workspace, '/journey'))
                .then(saved => {
                    return saved.output.journeyJson
                })
                .then(saved => {
                    console.log('saved', saved)
                    const S1 = createUserJourney(workspace)
                    if (saved) {
                        S1.start({
                            value: saved.state,
                            context: {
                                systemContext: {
                                    workspace: workspace
                                },
                                executionContext: saved.context
                            }
                        })
                    } else {
                        S1.start({
                            value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
                            context: {
                                systemContext: {
                                    workspace: workspace
                                },
                                executionContext: {
                                    pendingActions: []
                                }
                            }
                        })
                    }

                    const y = {
                        type: 'success' as 'success',
                        value: S1,
                        id: JSON.stringify(S1.state()).length
                    }
                    x1 && x1(y)
                    // props.setJourneyState(y)
                })
                .catch((err: any) => {
                    console.log('saved :: error', err)
                    // x.response.data.tag === JOURNEY_NOT_FOUND

                    if ((err.response && err.response.status == 401) || (err.response && err.response.status == 403)) {
                        console.log('Unauthorised || Forbidden access')
                        location.replace(window.location.origin + '/authentication/u')
                    }

                    if (err.response && err.response.data && err.response.data.tag === 'JOURNEY_NOT_FOUND') {
                        const S1 = createUserJourney(workspace)
                        S1.start({
                            value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
                            context: {
                                systemContext: {
                                    workspace: workspace
                                },
                                executionContext: {
                                    pendingActions: []
                                }
                            }
                        })
                        const y = {
                            type: 'success' as 'success',
                            value: S1,
                            id: JSON.stringify(S1).length
                        }
                        x1 && x1(y)
                    }
                })
            // const x = sleeper<LoadedStateWithRerender<IM_ET, any>>(2000)(() => {
            //     const saved = window.localStorage.getItem(workspace)
            //     const S1 = createUserJourney(workspace)
            //     if (saved) {
            //         S1.start({
            //             value: JSON.parse(saved).state,
            //             context: {
            //                 systemContext: {
            //                     workspace: workspace
            //                 },
            //                 executionContext: JSON.parse(saved).context
            //             }
            //         })
            //     } else {
            //         S1.start({
            //             value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
            //             context: {
            //                 systemContext: {
            //                     workspace: workspace
            //                 },
            //                 executionContext: {}
            //             }
            //         })
            //     }

            //     return {
            //         type: 'success',
            //         value: S1,
            //         id: Math.random()
            //     }
            // })

            // x.then(val => (x1 && val.type === 'success' ? x1(val) : null))
        }
    }, [])
    
    // console.log('rootJourneyContext', rootJourneyContext, x1)

    if (rootJourneyContext.type === 'success') {
        if (
            rootJourneyContext.value.state().value.type == 'MAIN' &&
            rootJourneyContext.value.state().value['JOURNEY'] === 'JOURNEY::NOT_STARTED' &&
            product !== "AUTOMATE"
        ) {
            if(window.location.pathname !== `/workspace/${workspace}/get-started/marketplace` && window.location.pathname !== `/workspace/${workspace}/get-started`){
                window.location.href = `/workspace/${workspace}/get-started`
            }else{
                return props.render(props)
            }
        } else {
            return props.render(props)
        }
        return props.render(props)
    }

    return <Loader.PageLoader show={true} />
}

export const Tracked = (props: any) => {
    console.log(props,"props")
    useGoogleAnalytics(props.location.pathname)

    useDocumentTitleUpdator({ path: props.path, pathname: props.location.pathname })

    return props.children
}

initializeAnalytics()

export default function() {
    return ReactGA
}
