import produce from 'immer'
import * as R from 'ramda'
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { SerachInput, SerachInputWrapper } from '@/DialogEditor/TrainingModule/TrainingModule'
import { VideoLink } from '@/Videos/Videos'
//ICONS IMPORT
import {
    BotDropdown,
    BotsIcon,
    DialogCardIcon,
    DialogIcon,
    FolderIcon,
    HelpIconMenu,
    InfoIcon,
    MoreIconJump,
    QaIconWithCircle,
    RightArrowWithCircle,
    SearchIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    Button as JumpButton,
    DropdownLi,
    DropdownLiSpan,
    DropdownUl,
    DropdownWrapper,
    Input,
    InputContent
} from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    EmptyDiv,
    Header,
    HeaderLeft,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper as WrapperJummpto
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'
import { DialogueContext } from '../DialogueCrew/Context'
import { ErrorState, ErrorT, JUMP_DIALOGUE, VIEW } from '../DialogueCrew/kind'
import { DefaultDialogue, DialogueT, IfNodeT, isJumpTo, PasteToSelectorT } from './kind'
import { copyIfNode, getDialogueRoots, getDialogues } from '../Mechanisms/action'
import { sortBySibling } from '../DialogueCrew/action'
import { DropDown } from '../Common/Effective'
import Downshift from 'downshift'
import { AssistantT } from '../Construction/kind'

const SearchWrapper = styled.div`
    width: 100%;
    float: left;
    padding-left: 10px;
    margin-bottom: 10px;

    ${SerachInputWrapper} {
        width: 100%;
        float: left;

        ${SerachInput} {
            width: 100%;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #b9b9b9;
            border-radius: 19px;
            box-shadow: none;

            :hover {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #009d5f;
            }
        }
        span {
            top: 10px;
        }
    }
`

const Count = styled.label`
    width: 16px;
    height: 16px;
    margin: 0px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 50%;
    color: #333;
    font-size: 8px;
    font-family: 'Gordita-MEdium';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -6px;
    right: -2px;
    line-height: normal;
    z-index: 9;
`

const PasteDialogsPopup = (props: {
    workspaceName: string
    assistantID: string
    dialog: DialogueT
    assistants: AssistantT[]
    closePopup:Function,
    refresh?:Function
}) => {
    let SEARCHELEMENT: any

    const { PageState, setPageState, fetchCallback, dialogueName,assistant, queryClient } = useContext(DialogueContext)
    const { workspaceName, assistantID, assistants,refresh } = props
    console.log(props, useContext(DialogueContext))

    const { data } = PageState as JUMP_DIALOGUE

    const [searchKey, setSearchKey] = useState<string>('')

    const [selector, setSelector] = useState<PasteToSelectorT>(PasteToSelectorT.above)

    const [selected, setSelected] = useState<DialogueT | null>(null)

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [dialogs, setDialogs] = useState<(DialogueT & { showChildren: boolean })[]>([])

    const [rootDialogues, setRootDialogues] = useState<(DialogueT & { showChildren: boolean })[]>([])

    const [WelcomeNode, setWelcomeNode] = useState<DialogueT>(DefaultDialogue)

    const [FallbackNode, setFallbackNode] = useState<DialogueT>(DefaultDialogue)

    const [selectedBot, setSelectedBot] = useState(assistantID)

    const [selectedChildIndex, setSelectedChildIndex] = useState<null|number>(null)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setLoader(true)

        callAPI(workspaceName, selectedBot)
            .then(([allDialogs, rootDialogs]: [DialogueT[], DialogueT[]]) => {

                setDialogs(
                    allDialogs.map(e => {
                        return { ...e, showChildren: false as boolean }
                    })
                )

                setRootDialogues(
                    sortBySibling(rootDialogs)
                        .filter((e: DialogueT) => e.type !== 'faq_node')
                        .map(e => {
                            return { ...e, showChildren: false as boolean }
                        })
                )
                setLoader(false)
            })
            .catch(err => {
                setError({
                    error: true,
                    info: `Server error!<br />Please try again after sometime.`
                })
                setLoader(false)
            })
    }, [selectedBot])

    function callAPI(workspaceName: string, assistantID: string) {
        return Promise.all([getDialogues(workspaceName, assistantID), getDialogueRoots(workspaceName, assistantID)])
    }

    const handleSave = async () => {
        try {
            if (selected) {
                setLoader(true)
                const isDefaultNode =
                    selected.previous_sibling != null && selected.conditions && selected.conditions.type ==
                        'FallBackExpression'
                const req:any =
                    (PasteToSelectorT.above == selector || isDefaultNode) &&  ( selectedChildIndex!=null||selected.previous_sibling != null)
                        ? {
                              node_id: props.dialog.uuid,
                              previous_sibling_id:selectedChildIndex!=null&& selectedChildIndex==0?null: selected.previous_sibling,
                              parent: selected.parent
                          }
                        : {
                              node_id: props.dialog.uuid,
                              previous_sibling_id: selected.uuid,
                              parent: selected.parent
                          }


                await copyIfNode(workspaceName, assistantID, req,`paste/${selectedBot}/copy_if_node`)
                queryClient.invalidateQueries(['dialogRoots', assistantID])
                queryClient.invalidateQueries(['dialogDescendants', selected.parent])
                queryClient.invalidateQueries(['dialogs', assistantID])
                queryClient.invalidateQueries(['assistants'])

                // lazyGA().event({
                //     category: 'Dialog Editor',
                //     action: 'Jump to Node Created'
                // })

           if(refresh){
                if(assistantID == selectedBot){
                refresh(() => {
                    setLoader(false)
                    props.closePopup()
                    setSelectedChildIndex(null)
                })
                }else{
                    setLoader(false)
                    props.closePopup()
                }
            }else{
                fetchCallback(() => {
                    setLoader(false)
                    props.closePopup()
                })
            }
        }
        } catch (err) {
            console.log(err,"err")
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoader(false)
        }
    }

    const DownshiftModel = (dialog: DialogueT) => (
        <div className="dropdown_jumpto">
            <div className="dropdown_move">
                {dialog.uuid === assistant.welcome_node ? (
                    <span
                        className={selector == PasteToSelectorT.below ? '_selected_input' : ''}
                        onClick={() => setSelector(PasteToSelectorT.below)}
                    >
                        Paste below this
                        <label>
                            <input
                                type="radio"
                                className="option-input radio"
                                name="example"
                                checked={
                                selector == 'below'
                                }
                                onChange={() =>
                                    setSelector(PasteToSelectorT.below)
                                }
                            />
                        </label>
                    </span>
                ) :  dialog.conditions && dialog.conditions.type == 'FallBackExpression' ? (
                    <span
                        className={
                            selector == PasteToSelectorT.above ? '_selected_input last-child_jump' : 'last-child_jump'
                        }
                        onClick={() => setSelector(PasteToSelectorT.above)}
                    >
                        Paste above this
                        <label>
                            <input
                                type="radio"
                                className="option-input radio"
                                name="example"
                                checked={
                                selector == 'above'
                                }
                                onChange={() =>
                                    setSelector(PasteToSelectorT.above)
                                }
                            />
                        </label>
                    </span>
                ) : (
                    <>
                        <span
                            className={
                                selector == PasteToSelectorT.above
                                    ? '_selected_input last-child_jump'
                                    : 'last-child_jump'
                            }
                            onClick={() => setSelector(PasteToSelectorT.above)}
                        >
                            Paste above this
                            <label>
                                <input
                                    type="radio"
                                    className="option-input radio"
                                    name="example"
                                    checked={
                                    selector == 'above'
                                    }
                                    onChange={() =>
                                        setSelector(PasteToSelectorT.above)
                                    }
                                />
                            </label>
                        </span>
                        <span
                            className={selector == PasteToSelectorT.below ? '_selected_input' : ''}
                            onClick={() => setSelector(PasteToSelectorT.below)}
                        >
                            Paste below this
                            <label>
                                <input
                                    type="radio"
                                    className="option-input radio"
                                    name="example"
                                    checked={
                                    selector == 'below'
                                    }
                                    onChange={() =>
                                        setSelector(PasteToSelectorT.below)
                                    }
                                />
                            </label>
                        </span>
                    </>
                )}
            </div>
        </div>
    )

    const hasChildren = <
        T extends DialogueT & {
            showChildren: boolean
        }
    >(
        list: T[],
        nodeToCheck: T
    ) => {
        return list.filter(e => e.parent == nodeToCheck.uuid).length > 0
    }

    const Representation = (
        dialog: DialogueT & {
            showChildren: boolean
        }
    ) => (
        <React.Fragment>
            {dialogs.filter(
                (
                    e: DialogueT & {
                        showChildren: boolean
                    }
                ) => e.parent == dialog.uuid
            ).length > 0 ? (
                <ul>
                    {sortBySibling(
                        dialogs.filter(
                            (
                                e: DialogueT & {
                                    showChildren: boolean
                                }
                            ) => e.parent == dialog.uuid
                        )
                    ).map((node, index: number) => (
                        <li key={index.toString()}>
                            <input type="checkbx" checked onChange={() => {}} />
                            <label className="tree_label1">
                                <div className="jump_to">
                                    <div
                                        className={
                                            selected && selected.uuid == node.uuid
                                                ? 'jump_to_list_selected'
                                                : 'jump_to_list'
                                        }
                                        onClick={() => {
                                            const dialogsUp = produce(dialogs, draftDialog => {
                                                draftDialog.forEach(dialogmap => {
                                                    console.log(
                                                        'dialogsUp',
                                                        dialogmap.uuid === node.uuid,
                                                        dialogmap.uuid,
                                                        dialog.uuid
                                                    )
                                                    if (dialogmap.uuid === node.uuid) {
                                                        dialogmap.showChildren = !dialogmap.showChildren
                                                        console.log(
                                                            'dialogmap.showChildren = !dialogmap.showChildren',
                                                            dialogmap.showChildren
                                                        )
                                                    }
                                                })
                                                return draftDialog
                                            })
                                            console.log(dialogsUp, 'dialogsUp')
                                            setDialogs(dialogsUp as any)
                                        }}
                                    >
                                        <h5>{node.title}</h5>
                                        {hasChildren(dialogs, node) ? (
                                            <span
                                                className={`indicate_children ${node.showChildren ? 'close' : 'open'}`}
                                            >
                                                <RightArrowWithCircle />
                                            </span>
                                        ) : null}

                                        <div
                                            className="jump_selected_dropdown"
                                            onClick={(e: any) => {
                                                if (selected && selected.id === node.id) {
                                                    setSelected(null)
                                                } else {
                                                    setSelected(node)
                                                }
                                                console.log(node.conditions,"dial")

                                                setSelectedChildIndex(index)
                                                if(node.uuid === assistant.welcome_node){
                                                    setSelector(PasteToSelectorT.below)
                                                }else{
                                                    setSelector(PasteToSelectorT.above)
                                                }
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <span>
                                                <MoreIconJump />
                                            </span>
                                        </div>
                                    </div>
                                    {selected && selected.uuid == node.uuid ? (
                                        <DropDown
                                            isOpen={selected ? selected.uuid == node.uuid : false}
                                            onOuterClick={() => {}}
                                        >
                                            {DownshiftModel(selected)}
                                        </DropDown>
                                    ) : null}
                                </div>
                            </label>
                            {node.showChildren && Representation(node)}
                        </li>
                    ))}
                </ul>
            ) : null}
        </React.Fragment>
    )

    console.log(rootDialogues, 'rootDialogues')

    return (
        <WrapperJummpto>
            <PopupWrapper className="dialog_paste_popup">
                <PopupContainer>
                    <ErrorBoundary
                        error={error}
                        render={(err: any, info: any) => {
                            return err && <Error.Delete onClick={() => props.closePopup()} info={info} />
                        }}
                    >
                        <Header>
                            <HeaderLeft minWidth={300}>
                                Paste to
                                <Tooltip
                                    className="target customTip jumpto"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content={"Select the dialog from the list below to paste the copied dialog/child dialog."}
                                    distance={5}
                                >
                                    <InfoIcon />
                                </Tooltip>
                                <a href="https://help.workativ.com/knowledgebase/create-an-assistant/" target="_blank">
                                    <Tooltip
                                        className="target customTip"
                                        zIndex={10000}
                                        arrowSize={8}
                                        tagName="span"
                                        content="View help document "
                                        distance={5}
                                    >
                                        <QaIconWithCircle />
                                    </Tooltip>
                                </a>
                            </HeaderLeft>
                            <HeaderRight>
                                <VideoLink id={'JUMP_TO_A_DIALOG'} text={'How to create'} />
                            </HeaderRight>
                        </Header>
                        <Paragraph>Paste dialog before, after, or within specific dialog.</Paragraph>
                        {loader ? (
                            <div className="popup_loader">
                                <Loader.PopupLoader show={loader} />
                            </div>
                        ) : (
                            <React.Fragment>
                                <PopupScrollHeight>
                                    <EmptyDiv>
                                        <div className="jumpto_wrapper jumpto_search">
                                            <DropdownWrapper className="dialog_paste_dropdown">
                                                <Input
                                                    activeDropdown={open}
                                                    onClick={() => setOpen(!open)}
                                                    type="text"
                                                    value={assistants.filter(a => a.id == selectedBot)[0].name}
                                                />
                                                <span className="copy_dropdown_boticon">
                                                    <DialogIcon />
                                                    <Count>{assistants.length}</Count>
                                                </span>
                                                <InputContent className="dialog_paste_dropdown_inputContent">Select Bot</InputContent>
                                                <span className="copy_dropdown_icon" onClick={() => setOpen(!open)}>
                                                    <BotDropdown />
                                                    
                                                </span>
                                                {open && (
                                                    <Downshift isOpen={true} onOuterClick={() => setOpen(false)}>
                                                        {() => (
                                                            <div>
                                                                <DropdownUl>
                                                                    {assistants.map(a => (
                                                                        <DropdownLi
                                                                            onClick={() => {
                                                                                setOpen(false)
                                                                                setSelectedBot(a.id)
                                                                                // props.changeDateFilter('today' as DateFilterT)
                                                                            }}
                                                                        >
                                                                            <DropdownLiSpan>{a.name}</DropdownLiSpan>{' '}
                                                                        </DropdownLi>
                                                                    ))}
                                                                </DropdownUl>
                                                            </div>
                                                        )}
                                                    </Downshift>
                                                )}
                                            </DropdownWrapper>
                                            <SearchWrapper>
                                                <SerachInputWrapper>
                                                    <SerachInput
                                                        type="search"
                                                        className="form-control jumpto_search"
                                                        placeholder="Search "
                                                        value={searchKey}
                                                        ref={(element: any) => {
                                                            SEARCHELEMENT = element
                                                        }}
                                                        onChange={(e: any) => setSearchKey(e.target.value)}
                                                    />
                                                    <span>
                                                        <SearchIcon />
                                                    </span>
                                                </SerachInputWrapper>
                                            </SearchWrapper>
                                            <div className="copy_function_popup_list">
                                                {rootDialogues.length > 0 ? (
                                                    <ul className="tree1 zontal_lines1">
                                                        {rootDialogues
                                                            .filter(e =>
                                                                e.title
                                                                    ? e.title
                                                                          .toLowerCase()
                                                                          .includes(searchKey.toLowerCase())
                                                                    : false
                                                            )
                                                            .map(
                                                                (
                                                                    dialog: DialogueT & {
                                                                        showChildren: boolean
                                                                    },
                                                                    index: number
                                                                ) => (
                                                                    <li
                                                                        className="first_add_node"
                                                                        key={index.toString()}
                                                                    >
                                                                        <label className="tree_label1">
                                                                            <div className="jump_to">
                                                                                <div
                                                                                    className={
                                                                                        selected &&
                                                                                        selected.uuid == dialog.uuid
                                                                                            ? 'jump_to_list_selected'
                                                                                            : 'jump_to_list'
                                                                                    }
                                                                                    onClick={() => {
                                                                                        const dialogsUp = produce(
                                                                                            rootDialogues,
                                                                                            draftDialog => {
                                                                                                draftDialog.forEach(
                                                                                                    dialogmap => {
                                                                                                        console.log(
                                                                                                            'dialogsUp',
                                                                                                            dialogmap.uuid ===
                                                                                                                dialog.uuid,
                                                                                                            dialogmap.uuid,
                                                                                                            dialog.uuid
                                                                                                        )
                                                                                                        if (
                                                                                                            dialogmap.uuid ===
                                                                                                            dialog.uuid
                                                                                                        ) {
                                                                                                            dialogmap.showChildren = !dialogmap.showChildren
                                                                                                            console.log(
                                                                                                                'dialogmap.showChildren = !dialogmap.showChildren',
                                                                                                                dialogmap.showChildren
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                )
                                                                                                return draftDialog
                                                                                            }
                                                                                        )
                                                                                        console.log(
                                                                                            dialogsUp,
                                                                                            'dialogsUp'
                                                                                        )
                                                                                        setRootDialogues(
                                                                                            dialogsUp as any
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {(() => {
                                                                                        if (
                                                                                            dialog.conditions ===
                                                                                                null ||
                                                                                            (dialog.uuid !==
                                                                                                assistant.welcome_node &&
                                                                                                dialog.conditions
                                                                                                    .type !==
                                                                                                    'FallBackExpression')
                                                                                        ) {
                                                                                            return (
                                                                                                <div className="parent_node_no">
                                                                                                    <span>
                                                                                                        {(() => {
                                                                                                            console.log(
                                                                                                                dialog.title,
                                                                                                                index
                                                                                                            )
                                                                                                            if (
                                                                                                                index >
                                                                                                                9
                                                                                                            ) {
                                                                                                                return index
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    '0' +
                                                                                                                    index.toString()
                                                                                                                )
                                                                                                            }
                                                                                                        })()}
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        return null
                                                                                    })()}
                                                                                    {dialog.type === 'folder' ? 
                                                                                        <span className='paste_popup_icon'>
                                                                                            <FolderIcon />                                                                                            
                                                                                        </span>
                                                                                        :
                                                                                        <span className='paste_popup_icon'>
                                                                                            <DialogCardIcon />                                                                                            
                                                                                        </span>
                                                                                    }
                                                                                    <h4>{dialog.title}</h4>
                                                                                    {hasChildren(dialogs, dialog) ? (
                                                                                        <span
                                                                                            className={`indicate_children ${
                                                                                                dialog.showChildren
                                                                                                    ? 'close'
                                                                                                    : 'open'
                                                                                            }`}
                                                                                        >
                                                                                            <RightArrowWithCircle />
                                                                                        </span>
                                                                                    ) : null}
                                                                                    <div
                                                                                        className="jump_selected_dropdown"
                                                                                        onClick={(e: any) => {
                                                                                            if (
                                                                                                selected &&
                                                                                                selected.id ===
                                                                                                    dialog.id
                                                                                            ) {
                                                                                                setSelected(null)
                                                                                            } else {
                                                                                                setSelected(dialog)
                                                                                            }
                                                                                            if(dialog.uuid === assistant.welcome_node){
                                                                                                setSelector(PasteToSelectorT.below)
                                                                                            }else {
                                                                                                setSelector(PasteToSelectorT.above)
                                                                                            }
                                                                                            setSelectedChildIndex(null)
                                                                                            // setSelected(dialog)
                                                                                            e.preventDefault()
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                    >
                                                                                        <span>
                                                                                            <MoreIconJump />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                {selected &&
                                                                                selected.uuid == dialog.uuid ? (
                                                                                    <DropDown
                                                                                        isOpen={
                                                                                            selected
                                                                                                ? selected.uuid ==
                                                                                                  dialog.uuid
                                                                                                : false
                                                                                        }
                                                                                        onOuterClick={() => {}}
                                                                                    >
                                                                                        {DownshiftModel(selected)}
                                                                                    </DropDown>
                                                                                ) : null}
                                                                            </div>
                                                                        </label>
                                                                        {dialog.showChildren && Representation(dialog)}
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                ) : null}
                                                {/* {FallbackNode && (
                                                <div
                                                    className="jump_to_start"
                                                    onClick={() => setSelected(FallbackNode)}
                                                >
                                                    <div
                                                        className={
                                                            selected
                                                                ? selected.id == FallbackNode.id
                                                                    ? 'jump_to_list_selected fallback first_add_node'
                                                                    : 'jump_to_list fallback first_add_node'
                                                                : 'jump_to_list fallback first_add_node'
                                                        }
                                                    >
                                                        <h4>Fallback</h4>

                                                        {selected && selected.id == FallbackNode.id ? (
                                                            <DropDown
                                                                isOpen={
                                                                    selected ? selected.id == FallbackNode.id : false
                                                                }
                                                                onOuterClick={() => {}}
                                                            >
                                                                {DownshiftModel()}
                                                            </DropDown>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )} */}
                                                <div className="margin-bottom-75" />
                                            </div>
                                        </div>
                                    </EmptyDiv>
                                    <ButtonContainer className="footer_button_fixed">
                                        <div className="popup_footer_button_container">
                                        <JumpButton
                                            primary
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => props.closePopup()}
                                        >
                                            <label>Cancel</label>
                                        </JumpButton>
                                        <JumpButton
                                            className={selected == null ? 'editor_btn_disabled' : ''}
                                            type="button"
                                            data-dismiss="modal"
                                            onClick={() => handleSave()}
                                        >
                                            <label>Paste</label>
                                        </JumpButton>
                                        </div>
                                    </ButtonContainer>
                                </PopupScrollHeight>
                            </React.Fragment>
                        )}
                    </ErrorBoundary>
                </PopupContainer>
            </PopupWrapper>
        </WrapperJummpto>
    )
}

export default PasteDialogsPopup
