import styled from 'styled-components/macro'

import { ButtonWrapper } from './Dialogue.Dumb'
import { InputContainer, Table, Tbody, Td, Th, Thead, Tr } from './Workflow.Analytics.Dumb'

export const TableWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 26px;
    .option-input.radio {
        top: 6px;
        position: relative;
        margin-right: 0px;
    }
    .home_edit {
        width: 16px;
        height: 16px;
        :first-child {
            margin-right: 10px !important;
        }
    }
    ${Table} {
        margin-top: 0px;
        ${Td} {
            padding: 0px;
            min-height: 48px;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: #000;
        }
    }
    ${Thead} {
        ${Tr} {
            padding-left: 32px;
            padding-right: 20px;

            ${Th} {
                :last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 252px);
        height: auto;
        ${Tr} {
            height: 48px;
            padding-left: 32px;
            padding-right: 20px;
            .entity_span {
                :first-child {
                    margin-right: 3px;
                    color: #000;
                    font-size: 11px;
                }
                :last-child {
                    margin-left: 3px;
                    color: #000;
                    font-size: 11px;
                }
            }
            :hover {
                ${Td} {
                    :last-child {
                        display: flex;
                    }
                }
            }

            ${Td} {
                padding: 0px;
                min-height: 48px;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                color: #000;
                cursor: inherit;
                span {
                    margin-right: 0px;
                }

                :nth-child(1) {
                    display: flex;
                    padding-right: 20px;
                    cursor: inherit;
                    font-family: 'Gordita-Medium';
                    position: relative;
                    label {
                        float: left;
                        display: flex;
                        position: absolute;
                        top: 18px;
                        width: 16px;
                        height: 16px;
                        margin-bottom: 0px;
                        left: -22px;
                    }
                }

                :nth-child(2) {
                    display: flex;
                    padding-right: 20px;
                }
                :last-child {
                    justify-content: flex-end;
                    display: none;
                    svg {
                        width: 16px;
                        height: 16px;
                        color: #666;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`
export const AddExamplesWrapper = styled.div`
    width: 100%;
    float: left;
    ${ButtonWrapper} {
        margin-top: 0px;
    }
    ${InputContainer} {
        margin-bottom: 20px;
    }
    .entity_add_value {
        ${InputContainer} {
            margin-bottom: 12px !important;
        }
    }
`
export const BackButton = styled.button`
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #cecece' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    color: ${props => (props.primary ? '#333' : '#fff')};
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    min-width: 64px;
    height: 24px;
    transition: 0.346s ease;
    padding: 0px 10px;
    cursor: pointer;

    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
    :hover {
        border: 1px solid #ddfeef;
        background: #ddfeef;
        color: #333;
        fill: #333;
        outline: 0;
        cursor: pointer;
        span svg {
            fill: ${props => (props.primary ? '#333' : '#333')};
            color: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#FFE5D3' : '#FFE5D3')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 3px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        svg {
            width: 10px;
            height: 10px;
            fill: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
            transform: rotate(180deg);
        }
    }
`

export const CircleButton = styled.div`
    background: ${props => (props.primary ? '#fff' : '#E0F6FF')};
    border-radius: 50%;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #E0F6FF')};
    outline: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
    float: left;
    justify-content: center;
    margin-left: 10px;

    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
    }
    :hover {
        border: 1px solid #fff3eb;
        background: ${props => (props.primary ? '#FFE5D3' : '#FFE5D3')};
        outline: 0;
        transition: 0.5s ease-in;
        cursor: pointer;
        span svg {
            color: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#FFE5D3' : '#FFE5D3')};
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')};
        span svg {
            color: ${props => (props.primary ? '#333' : '#fff')};
            color: ${props => (props.primary ? '#333' : '#fff')};
        }
    }
    :focus {
        background: #ddfeef;
        color: #333;
        border: 1px solid #009d5f;
    }
    span {
        width: 8px;
        height: 8px;
        float: left;
        margin-right: ${props => (props.margin ? '0px' : '0px')};
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        svg {
            width: 8px;
            height: 8px;
            // fill: ${props => (props.primary ? '#355D8B' : '#355D8B')};
            // color: ${props => (props.primary ? '#355D8B' : '#355D8B')};
            fill: rgb(153, 153, 153);
            color: rgb(153, 153, 153)
        }
    }
`
export const IconButton = styled.div`
    width: 18px;
    height: 18px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    bottom: 2px;
    svg {
        color: #999;
        fill: #999;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
    :hover {
        svg {
            color: #333;
            fill: #333;
        }
    }
`
