import { EditorState, Modifier, RawDraftContentBlock, convertFromRaw, convertToRaw } from 'draft-js'
import produce from 'immer'
import * as R from 'ramda'

import { getOutputValueRecursive, getValuesBetweenBraces } from '@/Flows/_utils'

import { convertObjectToArray } from '@/common/utils/utils'
import { BlocksT, DraftJST, EntityRangesT, VariableT, decorator } from './InputandVariable'
import { FileDefaultObject } from './NoTriggerPanel'
import {
    ActionInputsListT,
    CollectionOutputListTypeT,
    ConfigIdT,
    ConfigKinds,
    ContextPopupStateT,
    DefaultInputKeysT,
    InputFieldT,
    InputKey,
    NodeT,
    ObjectOutputListTypeT,
    OutputListTypeT,
    PathIdType,
    ResponseOutputT,
    TreeT,
    ValueTypeT
} from './types'

const ramda = require('ramda')

export const validInputKeys = (inputKeys: InputKey[]) => {
    return inputKeys
        .map(key => ({
            ...key,
            title: key.title.replace(/\&nbsp;/g, '').trim()
        }))
        .filter(({ title }) => title != '')
}

export const getFirstMatched: <T>(filterArray: T[], predicate: (value: T) => boolean) => T | undefined = (
    filterArray,
    predicate
) => filterArray.filter(predicate)[0]

export function reduceInputs(inputs: ValueTypeT[]): string {
    const reducedString = inputs.reduce((acc, curr) => {
        switch (curr.type) {
            case 'string':
                return acc + (curr.value ? curr.value : '')
            case 'context':
                return acc + '${' + curr.id + '}'
            default:
                return acc
        }
    }, '')

    return reducedString
}

export const sortAnArrayAlphabetically = (array: any[], key: string) => {
    const sortByFirstItem = ramda.sortBy(ramda.prop(key))
    return sortByFirstItem(array)
}

export function convertActionObjectToArray(object: object, appId: boolean) {
    if (!object) return []
    if (appId) {
        return Object.keys(object).map(function(key) {
            return {
                name: key,
                appId: object[key].length > 0 ? object[key][0].appId : '',
                value: object[key]
            }
        })
    } else {
        return Object.keys(object).map(function(key) {
            return { name: key, value: object[key] }
        })
    }
}

export function grouping(array: any, key: string) {
    if (!array || array.length === 0) {
        return []
    }

    const filteredValue = ramda.groupBy((x: any) => x[key])(array)

    return convertActionObjectToArray(filteredValue, true)
}

export function updateValueUsingLens(path: string, toObject: any, updatingValue: any) {
    const pathL = ramda.compose(ramda.tail, ramda.split('/'))(path)

    const pathLens = ramda.lensPath(pathL)

    const data = ramda.view(pathLens, toObject) as {
        [key: string]: DefaultInputKeysT & {
            config: ConfigKinds
        }
    }

    const value = ramda.set(pathLens, { ...data, ...updatingValue }, toObject) as InputFieldT
    // console.log(value, toObject, 'toObject', pathL)
    return value
}

export const checkForCollectionMapper = (input: InputFieldT, findingObject: string): false | InputFieldT => {
    // console.log(input, 'checkForCollectionMapper')
    if (input.config.id && input.config.id == findingObject) {
        return input
    } else if (input.config.kind == 'multiple') {
        const itemsArray = Object.values(
            input.config.items as {
                [key: string]: DefaultInputKeysT & {
                    config: ConfigKinds & ConfigIdT
                }
            }
        )
        const anyCollectionMapper = itemsArray
            .map(x => checkForCollectionMapper(x as InputFieldT, findingObject))
            .filter(x => x != false)
            .filter(Boolean)
        return anyCollectionMapper[0] as InputFieldT
    } else {
        return false
    }
}

export const checkForParticularValueinInput = (inputs: InputFieldT[], findingObject: string): false | InputFieldT => {
    const op = inputs
        .map(input => checkForCollectionMapper(input, findingObject))
        .filter(x => x != false)
        .filter(Boolean)
    // console.log(op, 'opps')

    return op.length > 0 ? op[0] : false
}

export function reduceArrayToString(inputs: any[], key: string): string {
    const reducedString = inputs.reduce((acc, curr) => {
        if (curr.type == 'context') {
            return acc + (curr[key] ? `<b>${curr.examples}</b>` : '')
        }

        return acc + (curr[key] ? curr[key] : '')
    }, '')

    return reducedString
}

type ContextRange = {
    offset: number
    length: number
    key: number
}

export const convertEditorStateDatatoVariables = (text: string, contextRangeList: ContextRange[]) => {
    const textLength = text.length

    type Range = {
        start: number
        end: number
    }

    type Text = Range & {
        type: 'string'
    }

    type ContextVariable = Range & {
        type: 'context'
        key: number
    }
    type Input = Text | ContextVariable
    type TextBoxContent = Input[]
    const noText = (text: string) => text.length == 0
    const onlyText = <T>(rangeList: T[], textLength: number) => rangeList.length === 0 && textLength > 0

    if (noText(text)) {
        return []
    }

    if (onlyText(contextRangeList, textLength)) {
        return [{ start: 0, end: textLength + 1, type: 'string' }] as Text[]
    }

    const makeContextvariableFromRange = (range: ContextRange): ContextVariable => ({
        type: 'context',
        start: range.offset,
        end: range.offset + range.length - 1,
        key: range.key
    })
    const makeTextFromRange = (range: ContextRange, start: number): Text => ({
        type: 'string',
        start: start,
        end: range.offset
    })

    let textAndContextRanges = contextRangeList.reduce((accumlatedContent: TextBoxContent, current: ContextRange) => {
        const hasOnlyContextvariable = (firstContext: ContextRange) => firstContext.offset === 0

        if (accumlatedContent.length === 0) {
            const generatedContextBlock: ContextVariable = makeContextvariableFromRange(current)
            if (hasOnlyContextvariable(current)) {
                return [generatedContextBlock]
            } else {
                const generatedTextBlock: Text = makeTextFromRange(current, 0)
                return [generatedTextBlock, generatedContextBlock]
            }
        } else {
            const { end } = accumlatedContent[accumlatedContent.length - 1]
            const generatedContextBlock: ContextVariable = makeContextvariableFromRange(current)
            if (end === current.offset - 1) {
                // has only context variable
                return [...accumlatedContent, generatedContextBlock]
            } else {
                const generatedTextBlock: Text = makeTextFromRange(current, end + 1)
                return [...accumlatedContent, generatedTextBlock, generatedContextBlock]
            }
        }
    }, [])

    const lastContextVariable = R.last(textAndContextRanges)

    // Concating Contains text at end after all context vaiables
    if (lastContextVariable && lastContextVariable.end != textLength) {
        return [
            ...textAndContextRanges,
            { start: lastContextVariable.end + 1, end: textLength + 1, type: 'string' } as Text
        ]
    }
    //console.log('text COntext range:', textAndContextRanges)

    return textAndContextRanges
}

export function reduceMultipleInputs(inputs: InputFieldT[]): InputFieldT {
    const x = inputs.map(y => {
        const { inputHasValue, ...rest } = y
        if (y.config.kind == 'hidden' || configKinds.some(value => value === y.config.kind)) {
            return { ...rest }
        } else if (y.config.kind !== 'multiple') {
            const convertedBlocks =
                typeof rest.value == 'string'
                    ? rest.value
                    : convertToRaw(rest.value.getCurrentContent())
                          .blocks.map(b => {
                              const st = rest.value as EditorState

                              return convertToServerFormat2(convertToRaw(st.getCurrentContent()).entityMap, b)
                          })

                          .reduce((acc, ele, i) => (i == 0 ? (acc += ele) : (acc += '\n' + ele)), '')

            return {
                ...rest,
                // value: convertToServerFormat(rest.value as any)
                value: convertedBlocks
            }
        } else {
            const items = Object.values(y.config.items) as InputFieldT[]

            return {
                ...rest,
                config: {
                    ...y.config,
                    items: reduceMultipleInputs(items)
                }
            }
        }
    }) as any

    return x.reduce((acc: any, curr: any, i: any) => {
        acc[curr.key] = curr
        return acc
    }, {})
}

export const configKinds = ['date', 'enum', 'lookup-enum', 'switch', "multiselect-lookup-enum", "multiselect-enum"]

export function setEmptyContextValuesToMultipleInputs(inputs: InputFieldT[]): InputFieldT {
    const x = inputs.map(y => {
        if (y.config.kind !== 'multiple') {
            if (configKinds.some(value => value === y.config.kind)) {
                return y
            } else
                return {
                    ...y,
                    value: emptyEditorState
                }
        } else {
            const items = Object.values(y.config.items) as InputFieldT[]

            return {
                ...y,
                config: {
                    ...y.config,
                    items: setEmptyContextValuesToMultipleInputs(items)
                }
            }
        }
    }) as any
    return x.reduce((acc: any, curr: any, i: any) => {
        acc[curr.key] = curr
        return acc
    }, {})
}

export function setContextValuesToMultipleInputs(
    inputs: InputFieldT[],
    combiningResponseWithTriggerOutput: ActionInputsListT[],
    triggerNodeOutput: NodeT,
    input: InputFieldT,
    exptraProps: {
        inputs: InputFieldT[]
        contextPopupValues: ContextPopupStateT
        setContextPopupValues: any
    },
    responseData: InputFieldT[]
): InputFieldT {
    const x = inputs.map(y => {
        if (y.config.kind !== 'multiple') {
            const { value } = y
            if (y.config.kind == 'hidden' || configKinds.some(value => value === y.config.kind)) {
                const obj = responseData.find(x => x.key == y.key)
                // console.log(obj, responseData, 'objectss', { ...obj, value })
                return { ...obj, value }
            } else if (value) {
                // console.log(y.path)
                const obj = responseData.find(x => x.key == y.key)

                return {
                    ...obj,
                    value: converToEditorFormat(
                        value as string,
                        combiningResponseWithTriggerOutput,
                        triggerNodeOutput,
                        y,
                        exptraProps
                    ) as EditorState,
                    inputHasValue: true
                }
            }
            return { ...y, value: emptyEditorState as any }
        } else {
            const items = Object.values(y.config.items) as InputFieldT[]

            return {
                ...y,
                config: {
                    ...y.config,
                    items: setContextValuesToMultipleInputs(
                        items,
                        combiningResponseWithTriggerOutput,
                        triggerNodeOutput,
                        y,
                        { ...exptraProps, inputs: items },
                        responseData
                    )
                }
            }
        }
    }) as any
    return x.reduce((acc: any, curr: any, i: any) => {
        acc[curr.key] = curr
        return acc
    }, {})
}

export function getInputsInNestedLevel(inputs: InputFieldT[]): InputFieldT[] {
    const x = inputs.map(y => {
        if (y.config.kind !== 'multiple') {
            return y
        } else {
            const items = Object.values(y.config.items) as InputFieldT[]
            return getInputsInNestedLevel(items)
        }
    }) as any

    return ramda.flatten(x)
}

// export const emptyEditorState = EditorState.createWithContent(
//     convertFromRaw({
//         blocks: [
//             {
//                 key: Math.random()
//                     .toString(36)
//                     .substring(2, 12),
//                 text: '',
//                 type: 'unstyled',
//                 depth: 0,
//                 inlineStyleRanges: [],
//                 entityRanges: [],
//                 data: {}
//             }
//         ],
//         entityMap: {}
//     }),
//     decorator
// )

export const emptyEditorState = EditorState.createEmpty(decorator)

export const InsertContext = (value: string, information: VariableT, editorState: EditorState) => {
    // const currentContent = editorState.getCurrentContent()
    // const selection = editorState.getSelection()
    // const entityKey = Entity.create('VARIABLE', 'IMMUTABLE', { ...information })
    // const textWithEntity = Modifier.insertText(currentContent, selection, value, undefined, entityKey)
    // return EditorState.push(editorState, textWithEntity, 'insert-characters')

    const selectionState = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('VARIABLE', 'IMMUTABLE', { ...information })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const modifiedContent = Modifier.insertText(contentState, selectionState, value, undefined, entityKey)
    return EditorState.push(editorState, modifiedContent, editorState.getLastChangeType())
}

export function convertToServerFormat2(entityMap: any, blocks: RawDraftContentBlock) {
    const entityValues = Object.values(entityMap)

    // const blocks = rawData.blocks[0];

    if (entityValues.length == 0) {
        return blocks.text
    }

    type MutateString = {
        replacingString: string

        addedReplacementLength: number
    }

    const fullyReplacedString = blocks.entityRanges.reduce<MutateString>(
        (acc: MutateString, currentRange, index) => {
            const original = acc.replacingString

            const start = currentRange.offset + acc.addedReplacementLength

            const length = currentRange.length

            const toReplace = '${' + `${entityMap[currentRange.key].data.id}` + '}'

            const bfre = original.slice(0, start)

            const after = original.slice(start + length)

            const afterReplace = `${bfre}${toReplace}${after}`

            return {
                replacingString: afterReplace,

                addedReplacementLength: acc.addedReplacementLength + (afterReplace.length - original.length)
            }
        },

        { replacingString: blocks.text, addedReplacementLength: 0 }
    )

    // console.log('output', fullyReplacedString)

    return fullyReplacedString.replacingString
}

///Here
export function convertToServerFormat(value: any) {
    if (!value || typeof value == 'string') {
        return value
    }
    const rawData = convertToRaw(value.getCurrentContent())

    const entityValues = Object.values(rawData.entityMap)
    const blocks = rawData.blocks[0]

    if (entityValues.length == 0) {
        return blocks.text
    }

    type MutateString = {
        replacingString: string
        addedReplacementLength: number
    }
    const fullyReplacedString = blocks.entityRanges.reduce<MutateString>(
        (acc: MutateString, currentRange, index) => {
            const original = acc.replacingString
            const start = currentRange.offset + acc.addedReplacementLength
            const length = currentRange.length
            const toReplace = '${' + `${rawData.entityMap[index].data.id}` + '}'
            const bfre = original.slice(0, start)
            const after = original.slice(start + length)
            const afterReplace = `${bfre}${toReplace}${after}`

            return {
                replacingString: afterReplace,
                addedReplacementLength: acc.addedReplacementLength + (afterReplace.length - original.length)
            }
        },
        { replacingString: blocks.text, addedReplacementLength: 0 }
    )
    // console.log('output', fullyReplacedString)
    return fullyReplacedString.replacingString
}

export function convertToServerFormat3(value: any) {
    if (!value || typeof value == 'string') {
        return value
    }
    const rawData = value as DraftJST

    const entityValues = Object.values(rawData.entityMap)
    const blocks = rawData.blocks[0]

    if (entityValues.length == 0) {
        return blocks.text
    }

    type MutateString = {
        replacingString: string
        addedReplacementLength: number
    }

    const removeIndex: any = []

    const fullyReplacedString = blocks.entityRanges.reduce<MutateString>(
        (acc: MutateString, currentRange, index) => {
            const original = acc.replacingString
            const start = currentRange.offset + acc.addedReplacementLength
            const length = currentRange.length
            rawData.entityMap[index].data && removeIndex.push(currentRange.key)
            const toReplace = '${' + `${(rawData.entityMap[index].data as any).variable}` + '}'
            const bfre = original.slice(0, start)
            const after = original.slice(start + length)
            const afterReplace = `${bfre}${toReplace}${after}`

            return {
                replacingString: afterReplace,
                addedReplacementLength: acc.addedReplacementLength + (afterReplace.length - original.length)
            }
        },
        { replacingString: blocks.text, addedReplacementLength: 0 }
    )
    return { text: fullyReplacedString.replacingString, removeIndex: removeIndex }
}

export function converToEditorFormat(
    value: string,
    combiningResponseWithTriggerOutput: ActionInputsListT[],
    triggerNodeOutput: NodeT,
    input: InputFieldT,
    exptraProps: {
        inputs: InputFieldT[]
        contextPopupValues: ContextPopupStateT
        setContextPopupValues: any
    }
) {
    return EditorState.createWithContent(
        convertFromRaw(
            converterToEditor(
                convertInputToExpression(
                    value,
                    combiningResponseWithTriggerOutput,
                    triggerNodeOutput,
                    input,
                    exptraProps
                )
            )
        ),
        decorator
    )
}
// HERE 2
function getContextValue(fullContextPath: string) {
    const splitAtFirstInstance = (separator: string) => (value: string) => {
        const [first, ...rest] = value.split(separator)
        const restValue = rest.join(separator)
        return [first, restValue]
    }
    const handleCollection = (path: string[]) => {
        const [before, after] = R.splitWhen(R.equals('__COLLECTION__PLACEHOLDER__'))(path)
        let paths = []
        if (after.length > 1) {
            paths = R.tail(after)
        } else {
            paths = before
        }
        return paths
    }

    const toSplitWith = (value: string) => {
        const [first] = splitAtFirstInstance('.input.')(value)
        if (first.indexOf('.') === -1) {
            return '.input.'
        } else {
            return '.output.'
        }
    }

    const separatorToSplitWith = toSplitWith(fullContextPath)

    const getContextPath = (val: string) =>
        R.compose(R.split('.'), x => x[1], splitAtFirstInstance(separatorToSplitWith))(val)

    return R.compose(handleCollection, getContextPath)(fullContextPath)
}

export function convertInputToExpression(
    value: string,
    combiningResponseWithTriggerOutput: ActionInputsListT[],
    triggerNodeOutput: NodeT,
    input: InputFieldT,
    exptraProps: {
        inputs: InputFieldT[]
        contextPopupValues: ContextPopupStateT
        setContextPopupValues: any
    }
) {
    var result = value.split(/(\${.*?})/)

    const { inputs, contextPopupValues, setContextPopupValues } = exptraProps

    const flatArray = (result
        .filter(x => x !== '')
        .map(valueWithContextPath => {
            if (valueWithContextPath.match(/(\${.*?})/)) {
                const [f, s, ...word] = [...valueWithContextPath]
                const path1 = word.join('')
                const contextValue = getContextValue(path1.substring(0, path1.length - 1))

                const collectionInput = inputs.filter(input => input.config.kind == 'collection-picker-single')

                const filteredValue = (
                    config: ActionInputsListT[],
                    collectionInput: InputFieldT[],
                    contextValue: string[]
                ) => {
                    const filt = config
                        .map(resp => {
                            const nodeId = path1.includes('.output.')
                                ? path1.split('.output.')[0]
                                : path1.split('.input.')[0]
                            if (resp.output.app && resp.output.app.pathId.nodeId == nodeId) {
                                if (resp.output.forEachCollection) {
                                    const op = resp.output.output as ObjectOutputListTypeT
                                    const value = getOutputValueRecursive(
                                        op,
                                        contextValue,
                                        Object.values(resp.output.output.properties)[0] as CollectionOutputListTypeT
                                    )
                                    if (value) return { value: value, app: resp.output.app }
                                } else {
                                    const op = resp.output.output as ObjectOutputListTypeT
                                    // to get collection output value
                                    let collectionOutput: any = false
                                    if (collectionInput.length > 0) {
                                        const collectionValue = collectionInput[0].value
                                        collectionOutput =
                                            collectionValue &&
                                            (typeof collectionValue == 'string'
                                                ? collectionValue.length > 0
                                                : convertToRaw(collectionValue.getCurrentContent()).blocks[0].text
                                                      .length > 0)
                                                ? getOutputValueRecursive(
                                                      op,
                                                      getValuesBetweenBraces(
                                                          typeof collectionValue == 'string'
                                                              ? collectionValue
                                                              : convertToRaw(collectionValue.getCurrentContent())
                                                                    .blocks[0].text
                                                      )[0]
                                                          .split('.output.')[1]
                                                          .split('.')
                                                  )
                                                : ''
                                    }

                                    const outputHasFile =
                                        resp.output.output.properties &&
                                        convertObjectToArray(resp.output.output.properties).filter(
                                            o => o.value.type == 'file'
                                        ).length > 0

                                    const newConfig = outputHasFile
                                        ? {
                                              ...op,
                                              properties: {
                                                  ...op.properties,
                                                  [`${contextValue[0]}`]: {
                                                      ...op.properties[`${contextValue[0]}`],
                                                      properties: FileDefaultObject(contextValue[0])
                                                  }
                                              }
                                          }
                                        : op

                                    const value = getOutputValueRecursive(
                                        newConfig,
                                        contextValue,
                                        collectionOutput as CollectionOutputListTypeT
                                    )
                                    if (
                                        value &&
                                        resp.output.app &&
                                        (path1.includes('.input.')
                                            ? path1.split('.input.')[0]
                                            : path1.split('.output.')[0]) == resp.output.app.pathId.nodeId
                                    ) {
                                        return { value: value, app: resp.output.app }
                                    }
                                }
                            }
                            return null
                        })
                        .filter(Boolean)
                    return filt[0]
                }

                const finalValue = filteredValue(combiningResponseWithTriggerOutput, collectionInput, contextValue)

                if (finalValue) {
                    const app = finalValue.app as ResponseOutputT & PathIdType
                    const tooltipPaths = finalValue.value.$id
                        .split('/')
                        .filter(x => x.length !== 0 && x !== 'properties')

                    if (input.config.kind == 'collection-picker-single') {
                        const collectionFinalValue = finalValue.value as CollectionOutputListTypeT
                        setContextPopupValues(
                            produce(contextPopupValues, draft => {
                                draft.collectionPicker = {
                                    ...draft.collectionPicker,
                                    app: app,
                                    selectedCollection: {
                                        value: collectionFinalValue,
                                        name: finalValue.value.title
                                    }
                                }
                            })
                        )
                    }

                    const examples = finalValue.value as any

                    if (valueWithContextPath.includes('__COLLECTION__PLACEHOLDER__')) {
                        const collection = getInputsInNestedLevel(inputs).filter(
                            inp => inp.config.kind == 'collection-picker-single'
                        )[0]

                        if (collection) {
                            const collectionValue = collection.value
                            if (
                                collectionValue &&
                                (typeof collectionValue == 'string'
                                    ? collectionValue.length > 0
                                    : convertToRaw(collectionValue.getCurrentContent()).blocks[0].text.length > 0)
                            ) {
                                const [first, second, ...restWithCollectionPath] = [...(collectionValue as string)]
                                const path1 = restWithCollectionPath.join('')
                                const contextValue2 = getContextValue(path1.substring(0, path1.length - 1))
                                const collectionVariable = R.last(contextValue2)

                                const [dollarSign, openFlowerBrace, ...restWithContextPath] = [...valueWithContextPath]

                                const collectionInputValue = collection.value as string

                                const collectionContextValue = getContextValue(
                                    collectionInputValue.substring(0, collectionInputValue.length - 1)
                                )

                                const filteredCollection = filteredValue(
                                    combiningResponseWithTriggerOutput,
                                    collectionInput,
                                    collectionContextValue
                                ) as {
                                    value: OutputListTypeT | CollectionOutputListTypeT
                                    app: ResponseOutputT & PathIdType
                                }
                                const collectionFinalValue = filteredCollection.value

                                return {
                                    type: ExpressionTypeE.PATH,
                                    path: contextValue
                                        .filter((v, i) => i + 1 != contextValue.length)
                                        .concat(finalValue.value.title),
                                    app,
                                    examples: examples.examples && examples.examples[0].toString(),
                                    id: restWithContextPath.join('').split('}')[0],
                                    tooltipForVariable: [app.appName, app.action, collectionFinalValue.title]
                                        .concat(tooltipPaths.filter((v, i) => i + 1 != tooltipPaths.length))
                                        .concat(finalValue.value.title)
                                } as PathT
                            }
                        }
                    }

                    const value = {
                        type: ExpressionTypeE.PATH,
                        path: contextValue
                            .filter((v, i) => i + 1 != contextValue.length)
                            .concat(finalValue.value.title),
                        app,
                        id: path1.split('}')[0],
                        examples: examples.examples && examples.examples[0].toString(),
                        tooltipForVariable:
                            app.type != 'trigger' || triggerNodeOutput.kind !== 'NoTrigger'
                                ? app.pathId.approvalId
                                    ? ['Approval', 'Output']
                                    : [app.appName, app.action]
                                          .concat(tooltipPaths.filter((v, i) => i + 1 != tooltipPaths.length))
                                          .concat(finalValue.value.title)
                                : ['Triggered by Chatbot', 'Output', finalValue.value.title]
                    } as PathT
                    // console.log('inside value 2', value)
                    return value
                }

                return {
                    type: ExpressionTypeE.PATH,
                    path: contextValue,
                    id: '',
                    app: { icon: '' },
                    tooltipForVariable: []
                } as PathT
            }

            return {
                type: ExpressionTypeE.TEXT,
                value: valueWithContextPath
            } as TextT
        }) as unknown) as ExpressionT[]

    const x = flatArray.reduce((a: ExpressionT, c: ExpressionT) => {
        return {
            type: ExpressionTypeE.JOIN,
            lhs: a,
            rhs: c
        } as JoinT
    })

    return x as JoinT
}

export const converterToEditor = (expression: ExpressionT): DraftJST => {
    const genRange = (block: BlocksT, exp: string): EntityRangesT => ({
        offset: block.text.length - exp.length,
        length: exp.length,
        key: block.entityRanges.length + 1
    })

    const genDraft = R.curry(
        (draft: DraftJST, exp: ExpressionT): DraftJST =>
            R.cond([
                [
                    R.compose(R.equals('text'), R.prop('type')),
                    R.compose<TextT, string, string, DraftJST>(
                        val => R.assocPath(['blocks', 0, 'text'], val, draft),
                        R.concat(draft.blocks[0].text),
                        R.prop('value')
                    )
                ],
                [
                    R.compose(R.equals('path'), R.prop('type')),
                    ex =>
                        R.compose<PathT, string[], string, string, DraftJST, DraftJST, DraftJST>(
                            dr => {
                                const entRange = dr.blocks[0].entityRanges

                                const key = R.toString(entRange[entRange.length - 1].key) as string
                                // console.log('ex id', dr, ex.id, expression)
                                const obj = {
                                    type: 'VARIABLE',
                                    mutability: 'IMMUTABLE',
                                    data: {
                                        value: R.last(ex.path),
                                        visible: R.last(ex.path),
                                        path: ex.tooltipForVariable,
                                        icon: ex.app.icon,
                                        dataType: 'string',
                                        id: ex.id,
                                        examples: ex.examples ? ex.examples : ''
                                    }
                                }
                                return R.assocPath(['entityMap', key], obj, dr)
                            },
                            dr =>
                                R.assocPath(
                                    ['blocks', 0, 'entityRanges'],
                                    R.concat(dr.blocks[0].entityRanges, [
                                        genRange(
                                            dr.blocks[0],
                                            R.last(ex.app.type != 'trigger' ? ex.path : ex.tooltipForVariable) as string
                                        )
                                    ]),
                                    dr
                                ),
                            val => R.assocPath(['blocks', 0, 'text'], val, draft),
                            str => R.concat(draft.blocks[0].text)(str),
                            R.last,
                            ex.app.type != 'trigger' ? R.prop('path') : R.prop('tooltipForVariable')
                        )(ex)
                ],
                [R.T, exp => genDraft(genDraft(draft, R.prop('lhs', exp)), R.prop('rhs', exp))]
            ])(exp)
    )

    return genDraft(
        {
            blocks: [
                {
                    key: Math.random()
                        .toString(36)
                        .substring(2, 12),
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {}
                } as BlocksT
            ],
            entityMap: {}
        },
        expression
    )
}

export type ExpressionT = TextT | PathT | JoinT

export enum ExpressionTypeE {
    TEXT = 'text',
    PATH = 'path',
    JOIN = 'join'
}

interface TextT {
    type: ExpressionTypeE.TEXT
    value: string
}
interface PathT {
    type: ExpressionTypeE.PATH
    path: string[]
    app: any
    tooltipForVariable: string[]
}

interface JoinT {
    type: ExpressionTypeE.JOIN
    lhs: ExpressionT
    rhs: ExpressionT
}

export function convertAsaSingleString(blocks: RawDraftContentBlock[]) {
    return blocks.reduce((acc, ele) => {
        return (acc += ele.text)
    }, '')
}

export function reduceNodes(stepTwoTree: TreeT<NodeT>) {
    if (!stepTwoTree) return []
    const reduceTree = (fa: TreeT<NodeT>, b: any, f: any) => {
        let r1: any = null

        if (fa.value.kind === 'ForEach') {
            r1 = f(b, fa.value)
            const len = fa.value.subflow ? fa.value.subflow.children.length : 0
            for (let i = 0; i < len; i++) {
                if (fa.value.subflow) {
                    r1 = reduceTree(fa.value.subflow.children[i], r1, f)
                }
            }
        } else {
            r1 = f(b, fa.value)
            const len = fa.children.length
            for (let i = 0; i < len; i++) {
                r1 = reduceTree(fa.children[i], r1, f)
            }
        }
        return r1
    }

    function mapTreeWithPath(fa: TreeT<NodeT>, f: any, index: any): any {
        return {
            value: f(fa.value, index),
            children: fa.children.map((t, i) => mapTreeWithPath(t, f, `${index}.${i + 1}`))
        }
    }

    const nodePath = mapTreeWithPath(
        stepTwoTree,
        (node: NodeT, index: number) => {
            return node
        },
        '2'
    )

    const stepTwoDescription = reduceTree(nodePath, [], (accum: [], curr: any) => {
        // console.log("curr", curr)
        return [...accum, curr]
    })

    // console.log("stepTwoDescription", stepTwoDescription)

    return stepTwoDescription
}
