import * as React from 'react'
import { ResponseNodeE } from './kind'
import { Signal, signal } from '@preact/signals-react'

const initialValue = signal(null)

export const CopyPasteContext = React.createContext<{copiedData:Signal<null|ResponseNodeE>,setCopiedData: (copiedData: ResponseNodeE|null) => void}>({
    copiedData:initialValue,
    setCopiedData: (copiedData: ResponseNodeE|null) => {},
})
