import React, { useEffect, useState } from 'react';
import {
  EmptyDiv,
  Header,
  HeaderLeft,
  InputContainer,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';
import { Input, InputContent } from '@/common/styled/Workflow.Analytics.Dumb';
import { Button, DropdownLiSpan } from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  ButtonContainer,
  CancelButton,
  DropdownLi,
  DropdownUl,
} from '@/common/styled/Workflow.Dumb';
import styled from 'styled-components/macro';
import {
  AddIcon,
  CloseIcon,
  CloseIconWithCircle,
  DropdownWithCircle,
  InfoIcon,
  MinusIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import {
  initialState,
  KnowledgeBase,
  mockGoogleConn,
  Resp,
  sharepointCred,
  sharepointCredData,
  SharepointPopupT,
  StateT,
  UpdateResponse,
} from './MockFiles';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { getJson, knowledgeApiUrl } from '@/common/utils/api-utils';
import * as R from 'ramda';
import { Loader } from '@/common/components/Loader';
import {
  useAuthSaveCred,
  useAuthUpdateCred,
  useSaveConnection,
  useUpdateConnection,
  useUpdateCred,
} from './KnowledgeApis';
import Downshift from 'downshift';
import Tooltip from 'react-tooltip-lite';
import _ from 'lodash';
// import { useGetKb } from './KnowledgeApis';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;

  .sharepoint_dropdown{
    ${DropdownLi}{
      :last-child{
        padding-top: 2px; 
      }
    }
  }
`;
const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

export default function GoogleDrivePopup({
  pageState,
  PageStateUpdate,
  selectedID,
  workspace,
  Connection,
  exCatArray,
}: SharepointPopupT) {
  const [state, setState] = useState<StateT>(initialState);
  const [status, setStatus] = useState<string>('');
  const [popUpState, setPopupState] = useState<{
    selectedConnection: UpdateResponse[];
    clonedConnection: UpdateResponse[];
  }>({
    selectedConnection: [],
    clonedConnection: [],
  });
  const[comment,setComment]=useState<boolean>(false)
  const[drive,setDrive]=useState<boolean>(false)
  const[sharedDrive,setSharedDrive]=useState<boolean>(false)
  const[popComment,setPopComment]=useState<boolean>(false)
  const[popDrive,setPopDrive]=useState<boolean>(false)
  const[popSharedDrive,setPopSharedDrive]=useState<boolean>(false)

  console.log(state.connectionID,pageState,"connectionID");
  const [showAdditionalProp, setShowAdditionalProp] = useState<boolean>(false);
  const initialFolderValue = {
    name: '',
    value: '',
    description: '',
    valueDesc: '',
  };
  const [folderType, setFolderType] = useState<{
    name: string;
    value: string;
    description: string;
    valueDesc: string;
  }>(initialFolderValue);
  
  const initialAdditioanlProp =  {
      inclusionTargetAudienceGroup: [],
      inclusionMimeTypes: [],
      exclusionMimeTypes: [],
      inclusionSharedDrives: [],
      exclusionSharedDrives: [],
      inclusionUserAccounts: [],
      exclusionUserAccounts: [],
      inclusionFileNamePatterns: [],
      exclusionFileNamePatterns: [],
      inclusionFilePathFilter: [],
      exclusionFilePathFilter: [],
      inclusionFileTypePatterns: [],
      exclusionFileTypePatterns: []
}

type addedPropT ={
      inclusionTargetAudienceGroup: string[],
      inclusionMimeTypes: string[],
      exclusionMimeTypes: string[],
      inclusionSharedDrives: string[],
      exclusionSharedDrives: string[],
      inclusionUserAccounts: string[],
      exclusionUserAccounts: string[],
      inclusionFileNamePatterns: string[],
      exclusionFileNamePatterns: string[],
      inclusionFilePathFilter: string[],
      exclusionFilePathFilter: string[],
      inclusionFileTypePatterns: string[],
      exclusionFileTypePatterns: string[]
}
  console.log(pageState,"pageState");
  useEffect(() => {
    console.log(pageState,selectedID,"pageState");
    if (selectedID !== '' && pageState === 'edit') {
    // if (pageState === 'add') {// change to make add button work as edit
      getConnection();
    }
  }, [pageState]);

  
  const [folderDropDown, setFolderDropDown] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(-1);
  interface additionalObjT {
    name: string;
    bName: string;
    value: string;
    description: string;
    doesInclude?:string
  }
  const initialObj = [{ name: '', bName: '', value: '', description: '' }];
  const [additionalObj, setAdditionalObj] =
    useState<additionalObjT[]>(initialObj);
  const [clonedInfo, setClonedInfo] = useState<additionalObjT[]>(initialObj);

  console.log(additionalObj, 'setAdditionalObj');
  const folderValue = [
    {
      name: 'Target Audience Group',
      value: 'TargetAudienceGroup',
      description: 'Target audiences are groups of people—such as departments or teams, that you can recommend for users to share your data with.',
      valueDesc: "Provide your target audience group name",
    },
    {
      name: 'Mimie Types',
      value: 'MimeTypes',
      description: 'Add Mime types to include or exclude in Google Drive account',
      valueDesc: 'Add Mime types to include or exclude in Google Drive account',
      doesInclude:""
    },
    {
      name: 'Shared Drives',
      value: 'SharedDrives',
      description: 'Add the shared drive you want to include or exclude in your index',
      valueDesc: 'Add the shared drive you want to include or exclude in your index',
      doesInclude:""
    },
    {
      name: 'User Accounts',
      value: 'UserAccounts',
      description: 'Add user accounts to include or exclude in Google Drive account',
      valueDesc: 'Add user accounts to include or exclude in Google Drive account',
      doesInclude:""
    },
    {
      name: 'File Path (Folder)',
      value: 'FilePathFilter',
      description: 'Provide the folder name you want to include or exclude',
      valueDesc: 'You can give a folder inside the folder path separated by /',
      doesInclude:""
    },
    {
      name: 'File Name',
      value: 'FileNamePatterns',
      description: 'Provide name of the file you want to include or exclude.',
      valueDesc: 'Provide name of the file.',
      doesInclude:""
    },
    {
      name: 'File Type',
      value: 'FileTypePatterns',
      description: 'Provide file type you want to include or exclude.',
      valueDesc:
        'You can add one file type at a time. To add multiple file types, add additional filters.',
      doesInclude:""
    },
  ];
  
  console.log(additionalObj.map(ad=> ad.name),folderValue,"folderValue");

  const checkifInclude=(bName:string)=>{
    console.log(bName,"checkifInclude")
    const check = folderValue.filter(fo=>fo.value === bName)[0]
    console.log(check,"checkifInclude")
    return Object.keys(check).includes("doesInclude")
  }
  const setValue = (index: number, folderInfo: any, value: string) => {
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.bName,
      value: value,
      description: folderInfo.description,
      doesInclude:folderInfo.doesInclude
    };
    setAdditionalObj(list);
  };

  const setName = (index: number, folderInfo: any) => {
    console.log(additionalObj,index,folderInfo, 'setAdditionalObj');
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.value,
      description: folderInfo.valueDesc,
      value: '',
      doesInclude: Object.keys(folderInfo).includes("doesInclude") ? "avaialble" :"notAvailable"
    };
    console.log(additionalObj, 'setAdditionalObj');
    setAdditionalObj(list);
  };
const reverseAdditionalObj = (inputObj: { [key: string]: any }) => {
  const result: any[] = [];

  Object.keys(inputObj).forEach((key) => {
    const values = inputObj[key];
    
    // Determine bName based on the key
    let bName = key;
    let doesInclude: string | null = null;
    console.log(bName,"checkBName")
  
    if (key.includes("include")) {
      bName = `${key.replace("include", "")}`;
      doesInclude = "inclusion";
    } else if (key.includes("exclude")) {
      bName = `${key.replace("exclude", "")}`;
      doesInclude = "exclusion";
    } else if(key.includes("inclusion")) {
      bName = `${key.replace("inclusion", "")}`;
      doesInclude = "inclusion";
    } else if (key.includes("exclusion")) {
      bName = `${key.replace("exclusion", "")}`;
      doesInclude = "exclusion";
    }
    // Handle non-empty values
    if (Array.isArray(values) && values.length > 0) {
      values.forEach((value: any) => {
        console.log(bName,"checkBName")
        result.push({
          name: folderValue.find(fo=> fo.value === bName)?.name,
          value: value,
          bName: bName,
          description: folderValue.find(fo=> fo.value === bName)?.description, 
          doesInclude: doesInclude,
        });
      });
    }
  });

  return result;
};

// Expected output
console.log(popUpState.selectedConnection,"testingOutput");
// const output = reverseAdditionalObj(popUpState.selectedConnection[0].additionalProperties);
// console.log(output,"testingOutput");

  const setInclude = (index: number,folderInfo: any, include: string) => {
    console.log('working', 'setAdditionalObj');
    const list = [...additionalObj];
    const toAdd = include === "yes" ? "inclusion" :"exclusion"
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.bName,
      description: folderInfo.description,
      value: '',
      doesInclude:toAdd
    };
    setAdditionalObj(list);
  };

  const removeSubset = (index: number) => {
    const rows = [...additionalObj];
    rows.splice(index, 1);
    rows.length === 0 ? setAdditionalObj(initialObj) : setAdditionalObj(rows);
  };

  const addMoreObj = () => {
    scroollUp();
    setAdditionalObj([...additionalObj, ...initialObj]);
  };
  const auth = ['Basic', 'With OAuth'];

  const checkingName: KnowledgeBase[] | [] =
    exCatArray && exCatArray.length > 0
      ? exCatArray.filter(
          (arr) => arr.name.toLowerCase() === state.kBName.toLowerCase()
        )
      : [];

  const getConnection = () => {
    // setPopupState({//for mock edit data
    //         ...popUpState,
    //         selectedConnection: JSON.parse(JSON.stringify([mockGoogleConn.output])),
    //         clonedConnection: JSON.parse(JSON.stringify([mockGoogleConn.output])),
    //       });
    //       setComment(mockGoogleConn.output.repository.isCrawlComment)
    //       setDrive(mockGoogleConn.output.repository.isCrawlMyDriveAndSharedWithMe)
    //       setSharedDrive(mockGoogleConn.output.repository.isCrawlSharedDrives)
    getJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${selectedID}`)).then(
      (data) => {
        if (data.type === 'success') {
          const output = [data.output];
          setPopupState({
            ...popUpState,
            selectedConnection: JSON.parse(JSON.stringify(output)),
            clonedConnection: JSON.parse(JSON.stringify(output)),
          });
          setPopComment(data.output.repository.isCrawlComment)
          setPopDrive(data.output.repository.isCrawlMyDriveAndSharedWithMe)
          setPopSharedDrive(data.output.repository.isCrawlSharedDrives)
          setComment(data.output.repository.isCrawlComment)
          setDrive(data.output.repository.isCrawlMyDriveAndSharedWithMe)
          setSharedDrive(data.output.repository.isCrawlSharedDrives)
        }
      }
    );
  };
  

  useEffect(() => {
    if (popUpState.selectedConnection.length > 0) {
      const addit = popUpState.selectedConnection[0].additionalProperties;
      const newObj = reverseAdditionalObj(addit)
      setClonedInfo(newObj.length > 0 ? newObj : initialObj);
      setAdditionalObj(newObj.length > 0 ? newObj : initialObj);
      setShowAdditionalProp(newObj.length > 0 ? true : false);
      console.log(addit, 'newObj');
    }
  }, [popUpState]);

  const handleConnectionEdit = (
    value: any,
    id: string,
    property: string,
    obj?: string
  ) => {
    const index = popUpState.selectedConnection.findIndex((el) => el.id === id);
    const data = [...popUpState.selectedConnection];
    if (obj === 'credential') {
      data[index].credential[property] = value;
    } else if (obj === 'repository') {
      data[index].repository[property] = value;
    } else {
      data[index][property] = value;
    }
    setPopupState({ ...popUpState, selectedConnection: data });
  };
  const [updateCred, setUpdateCred] = useState<boolean>(false);

  const handleRepositoryChange = (
    index: number,
    event: { target: { value: string } },
    id: string,
    prop: string
  ) => {
    const { value } = event.target;
    const rep = popUpState.selectedConnection[0].repository;
    const list = [...rep.siteUrls];
    list[index] = value;
    handleConnectionEdit(list, id, prop, 'repository');
  };
  const removeSiteUrl = (index: number, id: string) => {
    const rows = [...popUpState.selectedConnection[0].repository.siteUrls];
    rows.splice(index, 1);
    rows.length === 0
      ? handleConnectionEdit([''], id, 'siteUrls', 'repository')
      : handleConnectionEdit(rows, id, 'siteUrls', 'repository');
  };
  const addSiteUrl = (id: string) => {
    const rep = popUpState.selectedConnection[0].repository;
    const list = [...rep.siteUrls, ''];
    handleConnectionEdit(list, id, 'siteUrls', 'repository');
  };
  const accountUpdate = () => {
    const selectedaccount = popUpState.selectedConnection[0].credential;
    const clonedaccount = popUpState.clonedConnection[0].credential;
    return (
      JSON.stringify(selectedaccount) === JSON.stringify(clonedaccount) &&
      state.updatePassWord === ''
    );
  };

  const { saveAuthCredMutate } = useAuthSaveCred(
    (response: Resp) => {
      const receiveMessage = (event: any) => {
        if (event.origin === 'http://localhost:3000') return;
        // window.removeEventListener('message', receiveMessage, false);
        if (
          event.data.from === 'knowledgebase' &&
          event.data.status === 'success'
        ) {
          setStatus('');
          window.removeEventListener('message', receiveMessage, false);
          childWindow.close();
        } else {
          setStatus('Authentication Error');
        }
        // window.removeEventListener('message', receiveMessage, false);
        // childWindow.close();
      };

      const childWindow: any = window.open(
        response.data.output.redirectURI,
        'authwind',
        'width=550,height=500,0,status=0,'
      );

      window.addEventListener('message', receiveMessage, false);
      setStatus('');
      console.log(response,"checkResponse")
      setState({//commmand this to give random value in local
        ...state,
        connectionID: response.data.output.id,
        redirectURI: response.data.output.redirectURI,
        showConnectError: false,
        connectLoader: false,
      });
      setClonedCredData(JSON.parse(JSON.stringify(credData)));
    },
    () => {
      // setState({//uncommmand this to give random value in local
      //   ...state,
      //   connectionID: "020348007238412",
      //   redirectURI: "y2y42u3y41uio23",
      //   showConnectError: false,
      //   connectLoader: false,
      // });
      setStatus('apiConnectError');
      setState({//commmand this to give random value in local
        ...state,
        connectLoader: false,
      });
    }
  );

  const { updateAuthCredMutate } = useAuthUpdateCred(
    (response: Resp) => {
      const receiveMessage = (event: any) => {
        if (event.origin === 'http://localhost:3000') return;
        // window.removeEventListener('message', receiveMessage, false);
        if (
          event.data.from === 'knowledgebase' &&
          event.data.status === 'success'
        ) {
          // setStatus('');
          window.removeEventListener('message', receiveMessage, false);
          getConnection();
          childWindow.close();
        } else {
          // setStatus('Authentication Error');
          setTimeout(() => {
            window.removeEventListener('message', receiveMessage, false);
            getConnection();
          }, 5000);
        }
        // window.removeEventListener('message', receiveMessage, false);
        // childWindow.close();
      };

      const childWindow: any = window.open(
        response.data.output.redirectURI,
        'authwind',
        'width=550,height=500,0,status=0,'
      );

      window.addEventListener('message', receiveMessage, false);
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const { updateCredMutate } = useUpdateCred(
    () => {
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  //saving credential data to get connectionID //create credential
  const onCredSave = () => {
    const credBody = {
      connector: 'GOOGLEDRIVEV2',
      authType: 'OAuth2',
      input: {
        clientId: credData.clientId,
        // tenantId: credData.tenantId,
        clientSecret: credData.clientSecret,
        refreshToken: '',
        authType: 'OAuth2',
        result: false,
      },
    };
    saveAuthCredMutate({ workspace, credBody });
  };

  // const checkingValidUrl =
  //   popUpState.selectedConnection[0]?.repository.siteUrls.map((url) =>
  //     IsValidURL(url)
  //   );

  // updating credential data
  const onCredUpdateSave = (id: string) => {

    const newData = popUpState.selectedConnection[0].credential;
    const updateCredBody = {
      connector: 'GOOGLEDRIVEV2',
      authType: 'OAuth2',
      input: {
        clientId: newData.clientId,
        // tenantId: newData.tenantId,
        clientSecret: newData.clientSecret,
        refreshToken: '',
        authType: 'OAuth2',
        result: false,
      },

    };
    const updatedCloned = popUpState.clonedConnection.map((yes) =>
      Object.assign(yes, {
        credential: newData,
      })
    );
    setState({
      ...state,
      connectLoader: true,
    });
    updateAuthCredMutate({ workspace, id, updateCredBody });
    setPopupState({
      ...popUpState,
      clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    });
  };

  //siteURL management

  const onCancel = () => {
    PageStateUpdate();
    setAdditionalObj(initialObj)
    setShowAdditionalProp(false);
    setUpdateCred(false);
    setState(initialState);
    setStatus('');
    setPopupState({
      selectedConnection: [],
      clonedConnection: [],
    });
  };
  //cred
  const [credData, setCredData] = useState<sharepointCred>(sharepointCredData);
  const [clonedCredData, setClonedCredData] =
    useState<sharepointCred>(sharepointCredData);

  const needUpdate = () => {
    const omittedAccount = R.omit(
      ['credential'],
      popUpState.selectedConnection[0]
    );
    const omittedClonedAccount = R.omit(
      ['credential'],
      popUpState.clonedConnection[0]
    );

    const eRadditionalObj = additionalObj.filter((add) => add.value !== '');
    const eRclonedInfo = clonedInfo.filter((add) => add.value !== '');

    console.log(
      JSON.stringify(omittedAccount)=== JSON.stringify(omittedClonedAccount),
      _.isEqual(eRclonedInfo, eRadditionalObj),
      popComment === comment,
      'chcksfsd'
    );

    return (
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount) &&
      _.isEqual(eRclonedInfo, eRadditionalObj) && popComment === comment && popDrive === drive && popSharedDrive === sharedDrive
    );
  };

  // const checkingDuplicate = (arr: string[]) => {
  //   return arr.length === new Set(arr).size;
  // };
  const { saveConmutate } = useSaveConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const { updateConmutate } = useUpdateConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );

  const saveConnection = () => {
    const addedProperties:addedPropT = uniqueadditionalObj(additionalObj)
    console.log(addedProperties,"addedProperties");
    const connectionData = {
      name: state.kBName,
      category: 'GOOGLE_DRIVE',
      template: {
        type: 'GOOGLEDRIVEV2',
        syncMode: 'FORCED_FULL_CRAWL',
        authType: 'OAuth2',
        credentialDomainRefId: state.connectionID,
        repository: {
            isCrawlComment: comment,
            isCrawlMyDriveAndSharedWithMe:drive,
            isCrawlSharedDrives: sharedDrive
        },
        additionalProperties: {
            isCrawlAcl: true,
            maxFileSizeInMegaBytes: "50",
            includeTargetAudienceGroup:addedProperties.inclusionTargetAudienceGroup,
            includeUserAccounts:addedProperties.inclusionUserAccounts,
            excludeUserAccounts:addedProperties.exclusionUserAccounts,
            includeSharedDrives: addedProperties.inclusionSharedDrives,
            excludeSharedDrives: addedProperties.exclusionSharedDrives,
            includeMimeTypes: addedProperties.inclusionMimeTypes,
            excludeMimeTypes: addedProperties.exclusionMimeTypes,
            inclusionFilePathFilter:addedProperties.inclusionFilePathFilter,
            exclusionFilePathFilter: addedProperties.exclusionFilePathFilter,
            inclusionFileTypePatterns: addedProperties.inclusionFileTypePatterns,
            exclusionFileTypePatterns:addedProperties.exclusionFileTypePatterns,
            inclusionFileNamePatterns: addedProperties.inclusionFileNamePatterns,
            exclusionFileNamePatterns: addedProperties.exclusionFileNamePatterns
        },
      },
    };
    if (
      checkingName.length > 0
    ) {
      setStatus('error');
    } else {
      setStatus('loader');
      setState({
        ...state,
        disableSave: true,
      });

      saveConmutate({ workspace, connectionData });
    }
  };

  const checkingUpdateName = (id: string) => {
    const array =
      exCatArray.length > 0 ? exCatArray.filter((a) => a.id !== id) : [];
    return array.length > 0
      ? array.filter(
          (arr) =>
            arr.name.toLowerCase() ===
            popUpState.selectedConnection[0]?.name.toLowerCase()
        )
      : [];
  };

  const uniqueadditionalObj = (additionalObj: additionalObjT[]) => {
    const uniqueObject: { [key: string]: any[] } = {};

    const keys = Object.keys(initialAdditioanlProp);

    additionalObj.forEach((obj) => {
      let updatedBName = obj.bName;

      if (obj.doesInclude === "inclusion"){
        updatedBName = `inclusion${obj.bName}`;  
      } else if (obj.doesInclude === "exclusion")
        updatedBName = `exclusion${obj.bName}`;
      if (!uniqueObject[updatedBName]) {
        uniqueObject[updatedBName] = [];
      }

      if (obj.value !== "") {
      if (!uniqueObject[updatedBName]) {
        uniqueObject[updatedBName] = [];
      }
      uniqueObject[updatedBName].push(obj.value);
    }
    });

    keys.forEach((key) => {
      if (!uniqueObject.hasOwnProperty(key)) {
        uniqueObject[key] = [];
      }
    });

    console.log(uniqueObject, 'uniqueObject');
    return uniqueObject;
  };

  const updateConnection = (id: string) => {
    const data = popUpState.selectedConnection[0];

    const addedProperties:addedPropT = uniqueadditionalObj(additionalObj)

      const connectionData = {
        name: data.name,
        category: 'GOOGLE_DRIVE',
        template: {
          type: 'GOOGLEDRIVEV2',
          authType: 'OAuth2',
          syncMode: 'FORCED_FULL_CRAWL',
          credentialDomainRefId: data.credentialId,
          repository: {
            isCrawlComment: comment,
            isCrawlMyDriveAndSharedWithMe:drive,
            isCrawlSharedDrives: sharedDrive
          },
          additionalProperties: {
            isCrawlAcl: true,
            maxFileSizeInMegaBytes: "50",
            includeTargetAudienceGroup:addedProperties.inclusionTargetAudienceGroup,
            includeUserAccounts:addedProperties.inclusionUserAccounts,
            excludeUserAccounts:addedProperties.exclusionUserAccounts,
            includeSharedDrives: addedProperties.inclusionSharedDrives,
            excludeSharedDrives: addedProperties.exclusionSharedDrives,
            includeMimeTypes: addedProperties.inclusionMimeTypes,
            excludeMimeTypes: addedProperties.exclusionMimeTypes,
            inclusionFilePathFilter:addedProperties.inclusionFilePathFilter,
            exclusionFilePathFilter: addedProperties.exclusionFilePathFilter,
            inclusionFileTypePatterns: addedProperties.inclusionFileTypePatterns,
            exclusionFileTypePatterns:addedProperties.exclusionFileTypePatterns,
            inclusionFileNamePatterns: addedProperties.inclusionFileNamePatterns,
            exclusionFileNamePatterns: addedProperties.exclusionFileNamePatterns
          }
        },
      };
      if (
        checkingUpdateName(id).length > 0
      ) {
        setStatus('error');
        // setState({
        //   ...state,
        //   // error:true,
        //   loader: false,
        //   showError: true,
        // });
      } else {
        setStatus('loader');
        setState({
          ...state,
          disableSave: true,
        });
        updateConmutate({ workspace, connectionData, id });
      }
    
  };
  const messageend = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scroollUp();
  }, [folderType, showAdditionalProp]);

  const scroollUp = () => {
    setImmediate(() =>
      messageend.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    );
  };
  return (
    <>
      {pageState === 'add' && (
        <Wrapper>
          <PopupWrapper>
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  Google Drive Configuration 
                </HeaderLeft>
                <HeaderRight></HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Create a new connection.</Paragraph>
              <PopupScrollHeight>
                <EmptyDiv>
                  <BgWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e: { target: { value: string } }) =>
                          setState({
                            ...state,
                            kBName: e.target.value,
                          })
                        }
                      />
                      <InputContent>Connection name</InputContent>
                    </InputContainer>
                    {checkingName.length > 0 && status === 'error' && (
                      <p className="valid_url_alert">
                        Connection Name has to be unique
                      </p>
                      // </div>
                    )}
                  </BgWrapper>
                    <BgWrapper>
                        <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Connect Account{' '}
                        </Header>
                        </div>
                        <OptionalInput>
                        <InputContainer>
                            <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                                setCredData({
                                ...credData,
                                clientId: e.target.value,
                                });
                            }}
                            />
                            <InputContent>Client ID</InputContent>
                        </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                        <InputContainer>
                            <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                                setCredData({
                                ...credData,
                                clientSecret: e.target.value,
                                });
                            }}
                            />
                            <InputContent>Client Secret</InputContent>
                        </InputContainer>
                        </OptionalInput>
                        {/* <OptionalInput>
                        <InputContainer>
                            <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                                setCredData({
                                ...credData,
                                tenantId: e.target.value,
                                });
                            }}
                            />
                            <InputContent>Tenant ID</InputContent>
                        </InputContainer>
                        </OptionalInput> */}
                        <ButtonContainer
                        className="knowledge_ms_btn"
                        style={{ padding: '10px 0' }}
                        >
                        <Button
                            type="button"
                            style={{ marginRight: 20 }}
                            className={
                            state.connectionID === ''
                                ? credData.clientId !== '' &&
                                credData.clientSecret !== ''
                                // credData.tenantId !== ''
                                ? ''
                                : 'editor_btn_disabled'
                                : JSON.stringify(clonedCredData) !==
                                    JSON.stringify(credData) &&
                                credData.clientId !== '' &&
                                credData.clientSecret !== '' 
                                // credData.tenantId !== ''
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => onCredSave()}
                        >
                            <label>Save</label>
                        </Button>
                        {state.connectLoader && (
                            <span className="connect_account_loading automate_connect_account_loading">
                            <img src={LoadingSvgImage} alt="" />
                            </span>
                        )}
                        {status === 'apiConnectError' && (
                            <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                Something went wrong
                                </span>
                            </div>
                            </span>
                        )}
                        {status === 'Authentication Error' && (
                            <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                Authentication Error
                                </span>
                            </div>
                            </span>
                        )}
                        <Button
                            primary
                            type="button"
                            onClick={() => {
                            onCancel();
                            }}
                        >
                            <label> Cancel</label>
                        </Button>
                        </ButtonContainer>
                    </BgWrapper>
                </EmptyDiv>
                {state.connectionID !== '' && (
                  <>
                  <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Sync Contents{' '}
                          </Header>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={drive}
                            onChange={() => setDrive(!drive)}
                          />
                          <p>My drive and shared with me</p>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={sharedDrive}
                            onChange={() => setSharedDrive(!sharedDrive)}
                          />
                          <p>Shared drives</p>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={comment}
                            onChange={() => setComment(!comment)}
                          />
                          <p>Comments</p>
                        </div>
                      </BgWrapper>
                  <SmallButton
                    primary
                    onClick={() => {
                      scroollUp();
                      setShowAdditionalProp(!showAdditionalProp);
                    }}
                    style={{ marginBottom: '10px', width: '205px' }}
                  >
                    {showAdditionalProp ? (
                      <span>
                        <MinusIcon />
                      </span>
                    ) : (
                      <span>
                        <AddIcon />
                      </span>
                    )}
                    <label>Select for additional configuration</label>
                  </SmallButton>
                  </>
                )}
                {state.connectionID !== '' && showAdditionalProp && (
                  <>
                    {additionalObj &&
                      additionalObj.map((add: additionalObjT , index: number) => (
                        <BgWrapper key={index}>
                        {console.log(add,"additionalObj")}
                          <InputContainer style={{ marginBottom: '10px' }}>
                            <Input
                              type="text"
                              value={add.name}
                              // onChange={(e: any) => {}}
                              onClick={() => {
                                setFolderDropDown(true);
                                setNumber(index);
                              }}
                              style={{ paddingLeft: 10 }}
                            />
                            <InputContent>Additional Information</InputContent>
                            <CancelButton
                              onClick={() => setFolderDropDown(true)}
                              style={{ display: 'block', top: '14px' }}
                            >
                              <DropdownWithCircle />
                            </CancelButton>
                            {folderDropDown && number === index && (
                              <Downshift
                                isOpen={folderDropDown}
                                onOuterClick={() => setFolderDropDown(false)}
                              >
                                {() => (
                                  <div>
                                    <DropdownUl style={{ marginTop: '48px' }}>
                                      {folderValue.map((a,folIndex) => (
                                        <DropdownLi
                                          onClick={() => {
                                            setFolderDropDown(false);
                                            setFolderType(a);
                                            setName(index, a);
                                          }}
                                          key={folIndex}
                                        >
                                          <DropdownLiSpan>
                                            {a.name}
                                          </DropdownLiSpan>{' '}
                                          <Tooltip
                                            className="kai_sharepoint target customTip"
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={a.description}
                                            distance={5}
                                          >
                                            <InfoIcon />
                                          </Tooltip>
                                        </DropdownLi>
                                      ))}
                                    </DropdownUl>
                                  </div>
                                )}
                              </Downshift>
                            )}
                          </InputContainer>
                          {add.name !== "" && checkifInclude(add.bName) && (
                            <div className="col-md-12 entity_radio" style={{paddingBottom:"9px"}}>
                              <div className="radio">
                                  <label>
                                      <input
                                          type="radio"
                                          className="radio-warning"
                                          onChange={() => {
                                              setInclude(index,add,"yes")
                                          }}
                                          checked={add.doesInclude === 'inclusion'}
                                      />
                                  </label>
                                  <span>include</span>
                              </div>
                              <div className="radio">
                                  <label>
                                      <input
                                          type="radio"
                                          onChange={() => {
                                              setInclude(index,add,"no")
                                          }}
                                          checked={add.doesInclude === 'exclusion'}
                                      />
                                  </label>
                                  <span>exclude</span>
                              </div>
                          </div>
                          )}
                          {add.name !== '' && (add.doesInclude && add.doesInclude !== "avaialble" && add.doesInclude !== "notAvaialble") && (
                            <>
                              <div className="knowledge_ai_folder_box flex">
                                <InputContainer
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Input
                                    type="text"
                                    value={add.value}
                                    onChange={(e: {
                                      target: { value: string };
                                    }) =>
                                      setValue(
                                        index,
                                        add,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <InputContent>Value</InputContent>
                                  <span
                                    onClick={() => {
                                      removeSubset(index);
                                    }}
                                  >
                                    <CloseIconWithCircle/>
                                  </span>
                                </InputContainer>
                              </div>
                              <Paragraph>{add.description}</Paragraph>
                            </>
                          )}
                           
                        </BgWrapper>
                      ))}
                    {additionalObj[additionalObj.length - 1].value !== '' && (
                      <SmallButton
                        primary
                        style={{ marginBottom: 30 }}
                        onClick={() => addMoreObj()}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        <label>Add More</label>
                      </SmallButton>
                    )}
                    <div id="scrollIn" ref={messageend}/>
                  </>
                )}
                <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                  <div className="popup_footer_button_container">
                    <Button
                      type="button"
                      className={
                        state.kBName !== '' &&
                        state.connectionID !== ''
                        // inputUrl.length > 0 &&
                        // inputUrl[0].length > 0 &&
                        // (status !== 'error' ||
                        //   !inputURLValid.includes(false)) &&
                        // !state.disableSave
                          ? ''
                          : 'editor_btn_disabled'
                      }
                      onClick={() => {
                        saveConnection();
                      }}
                    >
                      <label> Save</label>
                    </Button>
                    {status === 'loader' && (
                      <span className="connect_account_loading automate_connect_account_loading">
                        <img src={LoadingSvgImage} alt="" />
                      </span>
                    )}
                    {/* {status === 'error' && (
                      <span
                        className={
                          checkingDuplicate(inputUrl) &&
                          !inputURLValid.includes(false) &&
                          checkingName.length === 0
                            ? 'connect_account_failed hide'
                            : 'connect_account_failed'
                        }
                      >
                        <div className="error_input_show error_input_show_add teams_page_alert">
                          <WarningAlertIcon />
                          <span className="error_menu_hvr">
                            Something went wrong
                          </span>
                        </div>
                      </span>
                    )} */}
                    {status === 'apiError' && (
                      <span className="connect_account_failed">
                        <div className="error_input_show error_input_show_add teams_page_alert">
                          <WarningAlertIcon />
                          <span className="error_menu_hvr">
                            Something went wrong
                          </span>
                        </div>
                      </span>
                    )}
                    {/* {!checkingDuplicate(inputUrl) && status === 'error' && (
                      <span className="connect_account_failed">
                        <div className="error_input_show error_input_show_add teams_page_alert">
                          <WarningAlertIcon />
                          <span className="error_menu_hvr">
                            All Urls must be unique
                          </span>
                        </div>
                      </span>
                    )} */}
                    <Button
                      primary
                      type="button"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      <label> Cancel</label>
                    </Button>
                  </div>
                </ButtonContainer>
              </PopupScrollHeight>
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}

      {pageState === 'edit' && (
        <Wrapper>
          <PopupWrapper className="edit_btn">
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  Google Drive Configuration
                </HeaderLeft>
                <HeaderRight>
                  {/* <VideoLink id={'ADD_AN_ADAPTIVE_CARD'} text={'How to'} /> */}
                </HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Update the connection.</Paragraph>
              {popUpState.selectedConnection.length === 0 ? (
                <PopupScrollHeight>
                  <div className="popup_loader">
                    <Loader.PopupLoader show={true} />
                  </div>
                </PopupScrollHeight>
              ) : (
                popUpState.selectedConnection.map((data,index) => (
                  <PopupScrollHeight key={index}>
                    <EmptyDiv>
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            name="connection_name"
                            value={data.name}
                            onChange={(e: { target: { value: string } }) => {
                              handleConnectionEdit(
                                e.target.value,
                                data.id,
                                'name'
                              );
                            }}
                          />
                          <InputContent>Connection name</InputContent>

                          {status === 'error' &&
                            checkingUpdateName(data.id).length > 0 && (
                              <p className="valid_url_alert">
                                Connection Name has to be unique
                              </p>
                              // </div>
                            )}
                        </InputContainer>
                      </BgWrapper>

                        <BgWrapper>
                          <div className="opations_label sharepoint_edit">
                            <Header style={{ fontSize: 14, marginBottom: 10 }}>
                              Connect Account{' '}
                            </Header>
                          </div>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.clientId}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'clientId',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Client ID</InputContent>
                            </InputContainer>
                          </OptionalInput>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.clientSecret}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'clientSecret',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Client Secret</InputContent>
                            </InputContainer>
                          </OptionalInput>
                          {/* <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.tenantId}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'tenantId',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Tenant ID</InputContent>
                            </InputContainer>
                          </OptionalInput> */}

                          <ButtonContainer
                            style={{
                              gap: 20,
                              padding: '0 0 10px 0',
                              marginTop: '-10px',
                            }}
                            className="slack_buton_center"
                          >
                            <Button
                              type="button"
                              className={
                                accountUpdate()
                                  ? 'hidden'
                                  : data.credential.clientId !== '' &&
                                    data.credential.clientSecret !== ''
                                    // data.credential.tenantId !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() => {
                                // setUpdateCred(false);
                                setState({
                                  ...state,
                                  updatePassWord: '',
                                });
                                onCancel();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              style={{ marginRight: 20 }}
                              className={
                                accountUpdate()
                                  ? 'hidden'
                                  : data.credential.clientId !== '' &&
                                    data.credential.clientSecret !== ''
                                    // data.credential.tenantId !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() =>
                                onCredUpdateSave(data.credentialId)
                              }
                            >
                              <label> Update </label>
                            </Button>
                            {state.connectLoader && (
                              <span className="connect_account_loading">
                                <img src={LoadingSvgImage} alt="" />
                              </span>
                            )}
                            {status === 'apiConnectError' && (
                              <span className="connect_account_failed">
                                <div className="error_input_show error_input_show_add teams_page_alert">
                                  <WarningAlertIcon />
                                  <span className="error_menu_hvr">
                                    Something went wrong
                                  </span>
                                </div>
                              </span>
                            )}
                            {/* {!data.credential.result && (
                              <span className="connect_account_failed">
                                <div className="error_input_show error_input_show_add teams_page_alert">
                                  <WarningAlertIcon />
                                  <span className="error_menu_hvr">
                                    Authentication Error
                                  </span>
                                </div>
                              </span>
                            )} */}
                          </ButtonContainer>
                        </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Sync Contents{' '}
                          </Header>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={drive}
                            onChange={() => setDrive(!drive)}
                          />
                          <p>My drive and shared with me</p>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={sharedDrive}
                            onChange={() => setSharedDrive(!sharedDrive)}
                          />
                          <p>Shared drives</p>
                        </div>
                        <div className="google_box">
                          <input
                            style={{marginRight:"8px"}}
                            type="checkbox"
                            className={'switch_1'}
                            checked={comment}
                            onChange={() => setComment(!comment)}
                          />
                          <p>Comments</p>
                        </div>
                      </BgWrapper>
                      <SmallButton
                        primary
                        onClick={() => {
                          scroollUp();
                          setShowAdditionalProp(!showAdditionalProp);
                        }}
                        style={{ marginBottom: '10px', width: '205px' }}
                      >
                        {showAdditionalProp ? (
                          <span>
                            <MinusIcon />
                          </span>
                        ) : (
                          <span>
                            <AddIcon />
                          </span>
                        )}
                        <label>Select for additional configuration</label>
                      </SmallButton>
                      {showAdditionalProp && (
                        <>
                          {additionalObj &&
                            additionalObj.map((add: any, index: number) => (
                              <BgWrapper key={index}>
                                <InputContainer
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Input
                                    type="text"
                                    value={add.name}
                                    // onChange={(e: any) => {}}
                                    onClick={() => {
                                      setFolderDropDown(true);
                                      setNumber(index);
                                    }}
                                    style={{ paddingLeft: 10 }}
                                  />
                                  <InputContent>
                                    Additional Information
                                  </InputContent>
                                  <CancelButton
                                    onClick={() => setFolderDropDown(true)}
                                    style={{ display: 'block', top: '14px' }}
                                  >
                                    <DropdownWithCircle />
                                  </CancelButton>
                                  {folderDropDown && number === index && (
                                    <Downshift
                                      isOpen={folderDropDown}
                                      onOuterClick={() =>
                                        setFolderDropDown(false)
                                      }
                                    >
                                      {() => (
                                        <div>
                                          <DropdownUl
                                            style={{ marginTop: '48px' }}
                                          >
                                            {folderValue.map((a,folIndex) => (
                                              <DropdownLi
                                                onClick={() => {
                                                  setFolderDropDown(false);
                                                  setFolderType(a);
                                                  setName(index, a);
                                                }}
                                                key={folIndex}
                                              >
                                                <DropdownLiSpan>
                                                  {a.name}
                                                </DropdownLiSpan>{' '}
                                                <Tooltip
                                                  className="kai_sharepoint target customTip"
                                                  zIndex={10000}
                                                  arrowSize={8}
                                                  tagName="span"
                                                  content={a.description}
                                                  distance={5}
                                                >
                                                  <InfoIcon />
                                                </Tooltip>
                                              </DropdownLi>
                                            ))}
                                          </DropdownUl>
                                        </div>
                                      )}
                                    </Downshift>
                                  )}
                                </InputContainer>
                                {add.name !== "" && checkifInclude(add.bName) && (
                                  <div className="col-md-12 entity_radio" style={{paddingBottom:"9px"}}>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                className="radio-warning"
                                                onChange={() => {
                                                    setInclude(index,add,"yes")
                                                }}
                                                checked={add.doesInclude === 'inclusion'}
                                            />
                                        </label>
                                        <span>include</span>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                onChange={() => {
                                                    setInclude(index,add,"no")
                                                }}
                                                checked={add.doesInclude === 'exclusion'}
                                            />
                                        </label>
                                        <span>exclude</span>
                                    </div>
                                </div>
                                )}
                                {add.name !== '' && (add.doesInclude && add.doesInclude !== "avaialble" && add.doesInclude !== "notAvaialble") && (
                                  <>
                                    <div className="knowledge_ai_folder_box flex">
                                      <InputContainer
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <Input
                                          type="text"
                                          value={add.value}
                                          onChange={(e: {
                                            target: { value: string };
                                          }) =>
                                            setValue(
                                              index,
                                              add,
                                              e.target.value
                                            )
                                          }
                                          style={{ transition: '.2s' }}
                                        />
                                        <InputContent>Value</InputContent>
                                        <span
                                          onClick={() => {
                                            removeSubset(index);
                                          }}
                                        >
                                          <CloseIconWithCircle />
                                        </span>
                                      </InputContainer>
                                    </div>
                                    <Paragraph>{add.description}</Paragraph>
                                  </>
                                )}
                              </BgWrapper>
                            ))}
                          {additionalObj[additionalObj.length - 1].value !==
                            '' && (
                            <SmallButton
                              primary
                              style={{ marginBottom: 30 }}
                              onClick={() => addMoreObj()}
                            >
                              <span>
                                <AddIcon />
                              </span>
                              <label>Add More</label>
                            </SmallButton>
                          )}
                          <div id="scrollIn" ref={messageend} />
                        </>
                      )}
                          <ButtonContainer
                            style={{ padding: 0 }}
                            className="footer_button_fixed"
                          >
                            <div className="popup_footer_button_container slack_buton_center">
                              <Button
                                type="button"
                                className={
                                  needUpdate()
                                    ? 'hidden'
                                    : data.name !== '' &&
                                      status !== 'error'
                                      // (status !== 'error' ||
                                      //   !checkingValidUrl.includes(false))
                                    ? ''
                                    : 'editor_btn_disabled'
                                }
                                onClick={() => {
                                  updateConnection(data.id);
                                }}
                              >
                                <label> Save</label>
                              </Button>
                              {status === 'loader' && (
                                <span className="connect_account_loading">
                                  <img src={LoadingSvgImage} alt="" />
                                </span>
                              )}

                              {/* {status === 'error' && (
                                <span
                                  className={
                                    checkingDuplicate(data.repository.siteUrls) &&
                                    !checkingValidUrl.includes(false) &&
                                    checkingUpdateName(data.id).length === 0
                                      ? 'connect_account_failed hide'
                                      : 'connect_account_failed'
                                  }
                                >
                                  <div className="error_input_show error_input_show_add teams_page_alert">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">
                                      Something went wrong
                                    </span>
                                  </div>
                                </span>
                              )} */}
                              {status === 'apiError' && (
                                <span className="connect_account_failed">
                                  <div className="error_input_show error_input_show_add teams_page_alert">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">
                                      Something went wrong
                                    </span>
                                  </div>
                                </span>
                              )}
                              {/* {!checkingDuplicate(data.repository.siteUrls) &&
                                status === 'error' && (
                                  <span className="connect_account_failed">
                                    <div className="error_input_show error_input_show_add teams_page_alert">
                                      <WarningAlertIcon />
                                      <span className="error_menu_hvr">
                                        All Urls must be unique
                                      </span>
                                    </div>
                                  </span>
                                )} */}
                            </div>
                          </ButtonContainer>
                    </EmptyDiv>
                  </PopupScrollHeight>
                ))
              )}
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
    </>
  );
}
