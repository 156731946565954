//TYPE IMPORTS
import { DialogueT } from '../Dialogue/kind'

export const sortBySibling = <T extends DialogueT>(source: T[]): T[] =>
    source.reduce((accumulator: T[], currentValue: T, index: number, arr: T[]): T[] => {
        if (accumulator.length) {
            const previous_sibling = accumulator.pop()
            if (previous_sibling) {
                const next_sibling = arr.filter((val: T) => previous_sibling.uuid == val.previous_sibling).pop()
                return next_sibling
                    ? accumulator.concat(previous_sibling, next_sibling)
                    : accumulator.concat(previous_sibling)
            }
            return accumulator
        } else {
            const root = arr.filter(value => !value.previous_sibling || value.previous_sibling === null).pop()
            return root ? accumulator.concat(root) : []
        }
    }, [])
