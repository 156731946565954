import { useQuery } from 'react-query'

type LazyPromise<T = any> = () => Promise<T>
type CachedQuery<T, E = any> =
    | {
          status: 'fetching'
          fetcher: LazyPromise<T>
      }
    | {
          status: 'fetched'
          data: T
          asyncData: Promise<T>
          lazyData: LazyPromise<T>
      }
    | {
          status: 'error'
          error: E
          asyncError: Promise<E>
          lazyError: LazyPromise<E>
      }
const makeAsync = <T = any>(value: T) => Promise.resolve(value)

export const useGenericCached = <T = any, E = any>(
    key: string,
    fetcher: LazyPromise<T>,
    options?: any,
    customOptions?: {
        everyTime: boolean
    }
): CachedQuery<T, E> => {
    const queriedData = useQuery<T, any>(key, fetcher, {
        refetchOnMount: false,
        staleTime: Infinity,
        ...options
    })

    const toCheck = customOptions && customOptions.everyTime ? queriedData.isFetching : queriedData.isLoading

    if (toCheck) {
        return {
            status: 'fetching',
            fetcher: fetcher
        }
    } else if (queriedData.isSuccess) {
        return {
            status: 'fetched',
            asyncData: makeAsync(queriedData.data),
            lazyData: () => makeAsync(queriedData.data),
            data: queriedData.data
        }
    } else if (queriedData.isError) {
        return {
            status: 'error',
            error: queriedData.error.response.data,
            asyncError: makeAsync(queriedData.error.response.data),
            lazyError: () => makeAsync(queriedData.error.response.data)
        }
    } else {
        return {
            status: 'fetching',
            fetcher: fetcher
        }
    }
}

export const useGenericCachedRun = <T = any>(key: string, fetcher: LazyPromise<T>) => {
    const cachedData = useGenericCached(key, fetcher)

    const toRun =
        cachedData.status === 'fetching'
            ? cachedData.fetcher
            : cachedData.status === 'fetched'
            ? cachedData.lazyData
            : cachedData.error

    return toRun
}
