import { BotDetailsT } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney';

import {
  agentHandoffIntegraionList,
  slackIntegrationUrl,
  teamsIntegrationGetListUrl,
  wrapperGetJson,
  wrapperPostJson,
} from '@/common/utils/api-utils';
import {
  MOCK_SLACK,
  slackResponse,
} from '../../../../feature/integrations/src/MockFile';
export interface AssistantT {
    id: string
    name: string
    lang_type: string
    created: string
    updated: string
    deployed: string | null
    published_at: string | null
    welcome_node:string
    avg_time_spend_by_user:number
    avg_time_spend_by_agent:number
    avg_cost_to_resolve_an_issue:number
}

export const DumpAssistant: AssistantT = {
    id: '',
    name: '',
    lang_type: '',
    created: '',
    updated: '',
    deployed: '',
    published_at: '',
    welcome_node:"",
    avg_cost_to_resolve_an_issue:0,
    avg_time_spend_by_agent:0,
    avg_time_spend_by_user:0
}

export enum ActionT {
  View,
  Loading,
  Unauthorized,
  NoDataFound,
}

export type ViewT = {
  action: ActionT.View;
  Assistants: AssistantT[];
  summary: BotDetailsT[];
};

export type LoadingT = {
  action: ActionT.Loading;
};

export type UnauthorizedT = {
  action: ActionT.Unauthorized;
};

export type NoDataFoundT = {
  action: ActionT.NoDataFound;
  showAddPopup: boolean;
};

export type PageStateT = ViewT | LoadingT | UnauthorizedT | NoDataFoundT;

export function fetchWorkspaces(workspace: string, botId: string) {
  let state = { slackWorkspaces: [], teamsWorkspaces: [] };

  // const workspaces = {
  //     slackworkspaces: await getJson(slackIntegrationUrl(`/clients/${workspace}`)).catch(err => err),
  //     teamsWorkspaces: await getJson(teamsIntegrationGetListUrl(workspace, '/credentialList/')).catch(err => err)
  // }
  return Promise.all([
    // MOCK_SLACK ? Promise.resolve(slackResponse) : '',
    wrapperGetJson(slackIntegrationUrl(`/clients/${workspace}`)),
    wrapperGetJson(teamsIntegrationGetListUrl(workspace, '/credentialList/')),
    wrapperPostJson(
      agentHandoffIntegraionList(workspace, botId, '/agent/getAgent/'),
      { bot_id: botId }
    ),
  ]);
}
