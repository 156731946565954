import React from 'react';
import { createPortal } from 'react-dom';

export function AssistantMainHeader({
  children,
}: {
  children: React.ReactElement;
}) {
  const ele = document.getElementById('assistant-main-header')
  return  ele ? createPortal(
    <div className="intent_home flow_group_home">
      <div className="intent_dialog_right">{children}</div>
    </div>,
    ele
  ) : null
}

export function AssistantSubHeader({
  children,
}: {
  children: React.ReactElement;
}) {
  const ele = document.getElementById('assistant-sub-header')

  return ele ? createPortal(
    <div className="sub_menu_icon_list">{children}</div>,
    ele
  ) : null
}

export function AssistantHomeSubHeader({
  children,
}: {
  children: React.ReactElement;
}) {
  return createPortal(
    <div className="sub_menu_icon_list">{children}</div>,
    document.getElementById('assistant-home-sub-header') as HTMLElement
  );
}