import React, { useImperativeHandle, useRef } from 'react'
import Draggable from 'react-draggable'
import classes from './PIP.module.css'

const PIP = React.forwardRef<HTMLElement, React.PropsWithChildren<any>>((props, ref) => {
    // const innerRef = useRef<HTMLDivElement>(null);

    // useImperativeHandle(ref, () => innerRef.current);
    console.log('ref', ref)
    return (
        <div>
            <Draggable handle="div" nodeRef={ref as any}>
                <div ref={ref as any} className={classes.pipWindow}>
                    {props.children}
                </div>
            </Draggable>
        </div>
    )
})

export default PIP
