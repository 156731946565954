/*eslint-disable*/
import React from 'react'

import { fetchDialogs, watsonApiURL } from '@/DialogEditor/Canvas/Actions/AsyncOperations'
import { AnalyticsSuccessResponse } from '@/Flows/analytics/types'
import { getJson } from '@/common/utils/axios-utils'

import {
    AutomationApi,
    automationAPIURLWithWorkspace,
    postJson,
    teamsIntegrationGetListUrl,
    wrapperGetJson
} from '@/common/utils/api-utils'
import { DashboardBannerContainer } from './Banner'
import { DashboardHeader } from './Header'
import { CardsChatDivide } from './Logs'
import { DashboardCardsWidth } from './Records'
import { WORKATIV_META } from './Types'

export function Dashboard(props: any) {
    const workspace = props.match.params.workspacename

    const [detailedData, setDetailedData] = React.useState<WORKATIV_META[]>([])

    const [automationLogs, setAutomationLogs] = React.useState(AnalyticsSuccessResponse)

    const [loading, setLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        Promise.all([
            fetchDialogs(workspace),
            getJson(watsonApiURL(workspace, '/intents')),
            getJson(watsonApiURL(workspace, '/entities')),
            wrapperGetJson(automationAPIURLWithWorkspace(workspace, AutomationApi.GetAll)).then(res => res.data),
            // postJson(slackIntegrationUrl(workspace, '/listworkspaces'))({
            //     cognidesk_workspace_name: workspace
            // }).then(res => res.data),
            Promise.resolve([]),
            getJson(teamsIntegrationGetListUrl(workspace, '/credentialList/'))
        ]).then(([dialogs, { intents }, { entities }, automations, SlackConnections, TeamsConnections]) => {
            setDetailedData([
                {
                    header: 'Total Dialogs',
                    count: dialogs.length,
                    subHeaders: [
                        {
                            subHeader: 'Intent',
                            count: intents.length
                        },
                        {
                            subHeader: 'Entity',
                            count: entities.length
                        }
                    ]
                },
                {
                    header: 'Total Automations',
                    count: automations.output.automations.length,
                    subHeaders: [
                        {
                            subHeader: 'Trigger',
                            count: automations.output.automations.filter((flow: any) => flow.type == 'trigger').length
                        },
                        {
                            subHeader: 'NoTrigger',
                            count: automations.output.automations.filter((flow: any) => flow.type == 'no_trigger')
                                .length
                        }
                    ]
                },
                {
                    header: 'Integrations',
                    count: SlackConnections.length + TeamsConnections.length,
                    subHeaders: [
                        {
                            subHeader: 'Slack',
                            count: SlackConnections.length
                        },
                        {
                            subHeader: 'Teams',
                            count: TeamsConnections.length
                        }
                    ]
                }
            ])
        })

        postJson(automationAPIURLWithWorkspace(workspace, '/automation/analytics/chart'))({
            timeInterval: 'last 30 days'
        }).then(res => {
            setAutomationLogs(res.data.output)
        })
    }, [])
    return (
        <React.Fragment>
            {loading ? (
                <h1>Loading</h1>
            ) : (
                <div className="dashboard_page">
                    <DashboardHeader />
                    <DashboardCardsWidth detailedData={detailedData} />
                    <DashboardBannerContainer />
                    <CardsChatDivide automationLogs={automationLogs} />
                </div>
            )}
        </React.Fragment>
    )
}
