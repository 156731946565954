/*eslint-disable*/
import * as React from 'react'
const Parser = require('html-react-parser')

type MoveT = {
    Card: React.ComponentType<any>
    Popup: React.ComponentType<{ render: RenderCallback }>
    Header: React.FunctionComponent<HeaderP>
    Wrapper: React.FunctionComponent
    CardWrapper: React.FunctionComponent
    Search: React.FunctionComponent<SearchP>
    CancelB: React.FunctionComponent<ButtonP>
    MoveB: React.FunctionComponent<MoveButtonP>
    ButtonWrapper: React.FunctionComponent
}

type HeaderP = {
    item: string
    moveTo: string
}

const Header: React.FunctionComponent<HeaderP> = ({ item, moveTo }) => {
    return (
        <React.Fragment>
            <h2>Move {item}</h2>
            <p>Select {moveTo} from the below list</p>
        </React.Fragment>
    )
}

const Wrapper: React.FunctionComponent = ({ children }) => {
    return <div className="right-panel select_flow">{children}</div>
}

type SearchP = {
    text: string
    onChange: (text: string) => void
}

const Search: React.FunctionComponent<SearchP> = ({ text, onChange }) => {
    return (
        <span className="form-input">
            <input
                type="text"
                className="form-control"
                placeholder="Search Group"
                value={text}
                onChange={e => onChange(e.target.value)}
            />
        </span>
    )
}

type ButtonP = {
    onClick: () => void
}

const CancelB: React.FunctionComponent<ButtonP> = ({ onClick }) => {
    return (
        <button className="btn btn-primary" type="button" onClick={onClick}>
            Cancel
        </button>
    )
}

type MoveButtonP = {
    onClick: () => void
    disabled?: boolean
}

const MoveB: React.FunctionComponent<MoveButtonP> = ({ onClick, disabled }) => {
    return (
        <button className="btn btn-success" type="button" onClick={onClick} disabled={disabled && true}>
            Move
        </button>
    )
}

const ButtonWrapper: React.FunctionComponent<any> = ({ children }) => {
    return <div className="right-panel_btn">{children}</div>
}

const CardWrapper: React.FunctionComponent<any> = ({ children }) => {
    return <div className="right_panel_ht">{children}</div>
}

type MoveCardS = {
    show: boolean
}

const isFirst = (index: number) => index === 0

export class MoveCard extends React.Component<any, MoveCardS> {
    state: MoveCardS = {
        show: false
    }

    handleDrag = () => {
        this.setState(({ show }) => ({
            show: !show
        }))
    }

    handleSelected = () => {
        this.props.select(this.props.id)
    }

    showSelection = () => this.props.selectMode || this.state.show

    render() {
        let { label, index } = this.props
        const cardClass = isFirst(index) ? 'select_flow_head select_flow_start' : 'select_flow_head'

        return (
            <div className={cardClass} onMouseEnter={this.handleDrag} onMouseLeave={this.handleDrag} key={index}>
                <h6>{Parser(label)}</h6>
                <SelectBox
                    show={this.showSelection()}
                    isSelected={this.props.isSelected}
                    handleSelected={this.handleSelected}
                />
            </div>
        )
    }
}

type SelectBoxP = {
    show: boolean
    isSelected: boolean
    handleSelected: () => void
}

const SelectBox: React.FunctionComponent<SelectBoxP> = ({ show, isSelected, handleSelected }) => (
    <label>
        <input
            type="radio"
            name="example"
            className="option-input radio"
            checked={isSelected}
            onClick={handleSelected}
            style={{ visibility: show ? 'visible' : 'hidden' }}
        />
    </label>
)

type MoveS = {
    searchText: string
    selectedGroup: string
}

type RenderCallback = (text: string, onChange: any, group: string, selectGroups: any) => JSX.Element

export class MovePopup extends React.Component<{ render: RenderCallback }, MoveS> {
    state: MoveS = {
        searchText: '',
        selectedGroup: ''
    }
    onSearchTextChanged = (text: string) => {
        this.setState({ searchText: text })
    }

    selectGroups = (id: string) => {
        this.setState({ selectedGroup: id })
    }

    render() {
        return this.props.render(
            this.state.searchText,
            this.onSearchTextChanged,
            this.state.selectedGroup,
            this.selectGroups
        )
    }
}

export const Move: MoveT = {
    Card: MoveCard,
    Header,
    Search,
    CancelB,
    MoveB,
    ButtonWrapper,
    Wrapper,
    CardWrapper,
    Popup: MovePopup
}
