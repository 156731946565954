import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react'

import { StatusContext, getSettings, statusT } from '@/Assistant/Mechanisms/action'
import { useJourney } from '@/Journey/JourneyContext'
import { VideoLinkSimple } from '@/Videos/Videos'
import {
    AddIcon,
    AttachmentIcon,
    CheckColorIcon,
    CloseColorIcon,
    CloseIcon,
    DeleteIcon,
    DialogIcon,
    EditIcon,
    HrLeftArrow,
    HrRightArrow,
    MinusIcon,
    NewTrymeIcon,
    RestartIcon,
    SetValuesIcon,
    TryMeLogo,
    TrymeCloseIcon,
    TrymeIcon,
    UserIconAnalyticsMenu,
    WidgetSendIcon
} from '@/common/Icons/Icons'
import botTyping from '@/common/images/bot_typing.gif'
import SmallLoader from '@/common/images/loading.svg'
import { Header } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { Input, InputContainer, InputContent, InputWrapper } from '@/common/styled/Workflow.Analytics.Dumb'
import { postJson } from '@/common/utils/axios-utils'

import { MOCK_TRYIT, generateUniqueId, urlify, convertToMDText, convertToSingleStringforTryme } from '@/common/utils/utils'
import { getJson, logEvent, loginApiURL } from '@/common/utils/api-utils'
import { withAuth as executeWithAuthToken } from '@/common/utils/auth-token-api-utils'
import { DialogApi, IntentApi, socketApiURLWithoutWorkspace, watsonApiURL } from '../../Actions/AsyncOperations'
import { disconnectSocket, initiateSocket, subscribeToChatWithErrorHandling, subscribeToTypingEnd } from './socket'
import AssistantMd from '@skitter/assistant_md'
import { useQueryClient } from 'react-query'
import { returnValidConfidence } from '@/DialogEditor/utils'
import styled from 'styled-components'
import logo_img from '@/common/images/logo.png';
const autosize = require("autosize");

const style = {
    maxHeight: "86px",
    minHeight: "38px",
    resize: "none",
    padding: "9px",
    boxSizing: "border-box",
    fontSize: "13px",
    width: "100%",
    border:"none",
    overflow: "hidden",
    overflowWrap: "break-word",
    textAlign: "start",
    height: "38px",
    fontFamily:"Gordita-Regular"
  };

const LogoSize = styled.div`
  width: 48px;
  height: 48px;
  float: left;
  background: #fff;
  border-radius: 50px;
  padding: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 2160px) {
    width: 64px;
    height: 64px;
  } 
  @media (min-width: 2560px) {
    width: 72px;
    height: 72px;
  }  
  @media (min-width: 3840px) {
    width: 90px;
    height: 76px;
  }   

  @media (max-width: 1920px) {
    width: 54px;
    height: 54px;
  }  

  @media (max-width: 1680px) {
    width: 48px;
    height: 48px;
  }  

  @media (max-width: 1500px) {
    width: 56px;
    height: 46px;
    padding: 0px;
  }

  @media (max-width: 1280px) {
    width: 42px;
    height: 36px;
  }  

  img {
    width: 46px;
    height: 46px;
    // border: 1px solid #CECECE;
    border-radius: 50%;

    @media (min-width: 2160px) {
        width: 58px;
        height: 58px;
    } 
    @media (min-width: 2560px) {
        width: 68px;
        height: 68px;
    }  
    @media (min-width: 3840px) {
        width: 68px;
        height: 68px;
    }   

    @media (max-width: 1920px) {
        width: 52px;
        height: 52px;
    }
    @media (max-width: 1680px) {
        width: 46px;
        height: 46px;
    }
    @media (max-width: 1440px) {
        width: 42px;
        height: 42px;
    }
    @media (max-width: 1280px) {
        width: 28px;
        height: 28px;
    }
  }

  svg {
    width: 48px;
    height: 48px;
  }
`;

const R = require('ramda')
const MessageIO = require('messege_io_client')
const pbUtil = require('pb-util')
let Parser = require('html-react-parser')

export let messengerObject: any

let socketURL = MOCK_TRYIT ? 'https://dev-assistant.workativ.ai' : socketApiURLWithoutWorkspace('')
let authToken = MOCK_TRYIT
    ? () =>
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzYsImVtYWlsIjoidmlub2QubUB3b3JrYXRpdi5jb20iLCJ3c3MiOlt7ImlkIjoxNzUsIm5hbWUiOiJ2aW5vZGRldiIsIm93bmVySWQiOjM2LCJpc19tZmFfYWN0aXZlIjpmYWxzZX1dLCJhY2wiOnsidmlub2RkZXYiOnsiQ0hBVF9CT1RfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJUUkFJTklOR19NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIkJPVF9NQVJLRVRfUExBQ0UvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIkVOR0lORS9QVUJMSUNfQk9UIjoiUkVBRC5XUklURS5ERUxFVEUiLCJXT1JLRkxPV19NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIldPUktGTE9XX01BUktFVF9QTEFDRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiVVNFUlNfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJXRUxDT01FX1BBR0UiOiJSRUFEIiwiQklMTElOR19QQUdFIjoiUkVBRC5XUklURS5ERUxFVEUiLCJDSEFUX0NIQU5ORUxfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJTSEFSRURfSU5CT1hfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUifX0sImlhdCI6MTcxODc3NDQ3NCwiZXhwIjoxNzE4Nzc1Mzc0fQ.sC62X_-bJxtr8zIzE7hpO4jaM7h7PwFgu_hWmOGeZQI'
    : () => sessionStorage.getItem('access_token')
// console.log('socketURL', socketURL)
export let createMessenger = () => {
    // console.log('socketURL', socketURL)
    if (!messengerObject) {
        messengerObject = initiateSocket({
            url: socketURL,
            path: DialogApi.Message
            // transports: ['polling'],
            // withCredentials: true,
        })
        // messengerObject = initiateSocket(socketApiURLWithoutWorkspace(DialogApi.Message))
    } else {
        return messengerObject
    }
}

export let reinitializeSocket = () => {
    // console.log('reinitializeSocket called')

    messengerObject && disconnectSocket()
    messengerObject = undefined
    return createMessenger()
    // messengerObject = initiateSocket(socketApiURLWithoutWorkspace(DialogApi.Message))
}

export let clearSocket = () => {
    // console.log('clearSocket called')

    messengerObject && disconnectSocket()
    messengerObject = undefined
    // return createMessenger()
    // messengerObject = initiateSocket(socketApiURLWithoutWorkspace(DialogApi.Message))
}

enum VIEW {
    VIEW_MODE = 'VIEW',
    EDIT_MODE = 'EDIT'
}

enum MODE {
    CHAT_VIEW = 'CHAT',
    CONTEXT_VIEW = 'CONTEXT'
}

type TryItP = {
    workspacename: string
    close: Function
    intents: any[]
    assistantid: string
    deploybot?: Function
    deployed?: boolean
    deploymentError?: boolean
}

type UserTrainingP = {
    message: any
    isTrainingOpen: boolean
    setIsTrainingOpen: Function
    trainingIndex: number | null
    setTrainingIndex: Function
    intents: any[]
    changeMappedIntent: Function
    removeMappedIntent: Function
    scrollBase: Function
}

function UserTraining(props: UserTrainingP) {
    const {
        message,
        isTrainingOpen,
        setIsTrainingOpen,
        trainingIndex,
        setTrainingIndex,
        intents,
        changeMappedIntent,
        removeMappedIntent
    } = props

    return (
        <>
            <div className="tryit_dropdown_user">
                <div className="tryit_password_parent">
                    <div className="user_data_input">
                        <div className="tryit_password tryit_password_p">
                            <div className="tryit_user_msg">
                                <p>{message.text}</p>
                                {setIsTrainingOpen && trainingIndex === message.index && (
                                    <div className='tryme_box'>
                                        <h5>Confidence Score:</h5>
                                        <TrainIntent
                                            message={message}
                                            intents={intents}
                                            changeMappedIntent={changeMappedIntent}
                                            removeMappedIntent={removeMappedIntent}
                                        />
                                        <TrainEntity message={message} />
                                        <TrainFAQ message={message} />
                                    </div>
                                )}
                                <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                                    <path d="M-.1 8c0-4.4 3.6-8 8-8h-8v8z" fill="currentColor" />
                                </svg>
                            </div>
                            {isTrainingOpen && trainingIndex === message.index ? (
                                <div className="hide_details_user">
                                    <div className="tryit_content_show_one">
                                        <div>
                                            <h6
                                                onClick={() => {
                                                    setIsTrainingOpen(false)
                                                    setTrainingIndex(null)
                                                }}
                                            >
                                                <span>
                                                    <MinusIcon />
                                                </span>
                                                Hide Details
                                            </h6>
                                        </div>
                                    </div>
                                    {/* {identifications} */}
                                </div>
                            ) : (
                                <div className="show_details_user">
                                    <div className="tryit_content_show_one">
                                        <div>
                                            <h6
                                                onClick={() => {
                                                    setIsTrainingOpen(true)
                                                    setTrainingIndex(message.index)
                                                }}
                                            >
                                                <span>
                                                    <AddIcon />
                                                </span>
                                                Show Details
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="width_17_user">
                            {/* <span className="entity_name_user_span"> */}
                                <UserIconAnalyticsMenu />
                            {/* </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </>

        // <div className="tryit_content_show_one">
        //     <div>
        //         {isTrainingOpen && trainingIndex === message.index ? (
        //             <h6
        //                 onClick={() => {
        //                     setIsTrainingOpen(false)
        //                     setTrainingIndex(null)
        //                 }}
        //             >
        //                 <span>
        //                     <MinusIcon />
        //                 </span>
        //                 Hide Details
        //             </h6>
        //         ) : (
        //             <h6
        //                 onClick={() => {
        //                     setIsTrainingOpen(true)
        //                     setTrainingIndex(message.index)
        //                 }}
        //             >
        //                 <span>
        //                     <AddIcon />
        //                 </span>
        //                 Show More Details
        //             </h6>
        //         )}
        //     </div>
        //     {setIsTrainingOpen && trainingIndex === message.index && (
        //         <div>
        //             <TrainIntent
        //                 message={message}
        //                 intents={intents}
        //                 changeMappedIntent={changeMappedIntent}
        //                 removeMappedIntent={removeMappedIntent}
        //             />
        //             <TrainEntity message={message} />
        //         </div>
        //     )}
        // </div>
    )
}

type TrainIntentP = {
    message: any
    intents: any
    changeMappedIntent: Function
    removeMappedIntent: Function
}

function TrainIntent(props: TrainIntentP) {
    const { message, intents, changeMappedIntent, removeMappedIntent } = props

    const [searchKey, setSearchKey] = useState<string>('')

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const calback = () => {
        // console.log('call back called')
    }

    // console.log('TrainIntent', message)

    return message.intents ? (
        <div className="tryit_content_hide_intent">
            <div className="tryit_content_hide_heading">
                <span className="show_details_header">Intent :</span>
            </div>
            <div className="tryit_entity_container">
                <div className="tryit_content_hide_int_entity">
                    {message.intents.length ? (
                        message.intents.map((intent: any, index: number) => (
                            <h6 key={index}>
                                <span>{intent.intent}:</span> <span>{returnValidConfidence(intent.confidence)}</span>
                                {/* <span
                                onClick={() => {
                                    setIsOpen(true)
                                }}
                            >
                                <EditIcon />
                                {isOpen ? (
                                    <IntentDownShift
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        searchKey={searchKey}
                                        setSearchKey={setSearchKey}
                                        intents={intents}
                                        currentIntent={intent}
                                        message={message}
                                        changeMappedIntent={changeMappedIntent}
                                    />
                                ) : null}
                            </span>
                            <span
                                onClick={() =>
                                    removeMappedIntent(
                                        intents.filter((e: any) => e.intent===intent.intent).pop(),
                                        message,
                                        calback
                                    )
                                }
                            >
                                <DeleteIcon />
                            </span> */}
                            </h6>
                        ))
                    ) : (
                        <div className="tryit_content_hide_int_entity">
                            <h6>
                                irrelevent
                                {/* <span
                                onClick={() => {
                                    setIsOpen(true)
                                }}
                            >
                                <EditIcon />
                                {isOpen ? (
                                    <IntentDownShift
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        searchKey={searchKey}
                                        setSearchKey={setSearchKey}
                                        intents={intents}
                                        currentIntent={null}
                                        message={message}
                                        changeMappedIntent={changeMappedIntent}
                                    />
                                ) : null}
                            </span> */}
                            </h6>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null
}

type IntentDownShiftP = {
    isOpen: boolean
    setIsOpen: Function
    searchKey: string
    setSearchKey: Function
    intents: any
    currentIntent: any
    message: any
    changeMappedIntent: Function
}

function IntentDownShift({
    isOpen,
    setIsOpen,
    searchKey,
    setSearchKey,
    intents,
    currentIntent,
    message,
    changeMappedIntent
}: IntentDownShiftP) {
    const callback = () => {
        setIsOpen(false)
    }
    return (
        <Downshift
            isOpen={isOpen}
            onOuterClick={() => {
                setIsOpen(false)
            }}
        >
            {() => (
                <div className="dropdown_right_entity">
                    <label
                        className="form-input"
                        onClick={() => {
                            setIsOpen(false)
                        }}
                    >
                        <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            value={searchKey}
                            onChange={(word: any) => {
                                const list = word.currentTarget.value

                                setSearchKey(list)
                            }}
                        />
                    </label>
                    <div className="dialog_scroll_list">
                        <ul>
                            {intents.map((intent: any, index: number) => {
                                const isVisible = intent.intent.includes(searchKey)
                                return (
                                    isVisible && (
                                        <li
                                            key={index}
                                            className={
                                                currentIntent !== null
                                                    ? currentIntent.intent === intent.intent
                                                        ? 'irrelevant_color'
                                                        : ''
                                                    : ''
                                            }
                                            onClick={() => changeMappedIntent(intent, message, callback)}
                                        >
                                            <span>{intent.intent}</span>

                                            <input
                                                type="radio"
                                                checked={
                                                    currentIntent !== null
                                                        ? intent.intent === currentIntent.intent
                                                        : false
                                                }
                                                className="option-input radio"
                                                name="example"
                                            />
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </Downshift>
    )
}

type TrainEntityP = {
    message: any
}

function TrainEntity({ message }: TrainEntityP) {
    return message.entities ? (
        <div className="tryit_content_hide_entity tryit_entity_section">
            <div className="tryit_content_hide_heading">
                <span className="show_details_header">Entity :</span>
            </div>
            <div className="tryit_entity_container">
                {message.entities.length ? (
                    message.entities.map((entity: any, index: number) => (
                        <div className="tryit_content_hide_int_entity tryit_entity" key={index}>
                            <h6><span>{entity.entity}:</span> <span>{returnValidConfidence(entity.confidence)}</span></h6>
                        </div>
                    ))
                ) : (
                    <div className="tryit_content_hide_int_entity">
                        <h6>irrelevent</h6>
                    </div>
                )}
            </div>
        </div>
    ) : null
}

function TrainFAQ({ message }: TrainEntityP) {
    return message.faq ? (
        <div className="tryit_content_hide_entity tryit_faq_section">
            <div className="tryit_content_hide_heading">
                <span className="show_details_header">FAQ :</span>
            </div>
            <div className="tryit_entity_container">
                {message.faq.length ? (
                    message.faq.map((faq: any, index: number) => (
                        <div className="tryit_content_hide_int_entity tryit_faq" key={index}>
                            <h6><span>{faq.name}:</span> {returnValidConfidence(faq.confidence)}</h6>
                        </div>
                    ))
                ) : (
                    <div className="tryit_content_hide_int_entity">
                        <h6>irrelevent</h6>
                    </div>
                )}
            </div>
        </div>
    ) : null
}

type OptionResP = {
    message: any
    sendMessage: Function
    forCarousal?: boolean
}

function OptionRes({ message, sendMessage }: OptionResP) {
    return (
        <React.Fragment>
            {message.option.title !== '' ? (
                <div className="tryit_understand_parent">
                    <div className='try_me_icon'>
                        <TrymeIcon />
                    </div>
                    <p className="tryit_understand tryme_option_title">
                        <AssistantMd mdText={convertToMDText(message.option.title)} />
                    </p>
                </div>
            ) : null}
            <div className="tryit_option">
                {message.option.options.map((option: any, index: number) => (
                    <button
                        key={index}
                        className={
                            option.label.length < 57
                                ? 'btn btn-primary tryme_option_button'
                                : 'btn btn-primary tryme_option_button'
                        }
                        onClick={() => {
                            sendMessage(convertToSingleStringforTryme(option.value,""))
                        }}
                    >
                        <span>
                            <AssistantMd mdText={convertToMDText(option.label)} />
                        </span>
                    </button>
                ))}
            </div>
        </React.Fragment>
    )
}



function AdaptiveCard({ message, sendMessage, forCarousal }: OptionResP) {
    const currentValue = message.adaptive_card.fields.blocks.listValue.values

    const title = currentValue.filter(
        (block: any) =>
            block.structValue.fields.text &&
            block.structValue.fields.text.structValue.fields.style.stringValue === 'bold'
    )[0]
    const description = currentValue.filter(
        (block: any) =>
            block.structValue.fields.text &&
            block.structValue.fields.text.structValue.fields.style.stringValue === 'normal'
    )[0]
    const image = currentValue.filter((block: any) => block.structValue.fields.accessory)[0]
    const fields = currentValue.filter((block: any) => block.structValue.fields.accessory)[0]

    const elements = currentValue.filter((block: any) => block.structValue.fields.elements)[0]

    function generateChildren() {
        return (
            <div className="position-realtive tryit_wrapper_card single">
                <div className="tryit_cards">
                    <div className="tryit_cards_top">
                        <div className="tryit_cards_top_left">
                            {image &&
                                image.structValue.fields.accessory &&
                                image.structValue.fields.accessory.kind !== 'nullValue' && (
                                    <img
                                        src={
                                            image.structValue.fields.accessory.structValue.fields.image_url.stringValue
                                        }
                                        alt={
                                            image.structValue.fields.accessory.structValue.fields.type.stringValue
                                                ? image.structValue.fields.accessory.structValue.fields.type.stringValue
                                                : 'image'
                                        }
                                    />
                                )}
                        </div>
                        <div className="tryit_cards_top_right">
                            {title && (
                                <h4 className="word_break">
                                    <AssistantMd mdText={convertToMDText(pbUtil.value.decode(title).text.text)} />
                                </h4>
                            )}
                            {description && (
                                <p>
                                    <AssistantMd mdText={convertToMDText(pbUtil.value.decode(description).text.text)} />
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="tryit_cards_wrapper">
                        <div className="tryit_cards_left">
                            <div className="tryit_ticket_wrapper tryit_content_wrapper">
                                {fields && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {pbUtil.value.decode(fields).fields.map((field: any, key: number) => (
                                            <div className="tryit_ticket_details" key={key}>
                                                <h6>
                                                    <AssistantMd mdText={convertToMDText(field.title)} />
                                                </h6>
                                                <p>:</p>
                                                <p>
                                                    <AssistantMd mdText={convertToMDText(field.value)} />
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {elements && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {pbUtil.value.decode(elements).elements.map((field: any, key: number) => {
                                            return field.type === 'button' ? (
                                                <div className="tryit_ticket_details">
                                                    <SmallButton
                                                        style={{ marginTop: 6, minWidth: 'max-content', maxWidth: 'max-content'}}
                                                        primary
                                                        key={key}
                                                        onClick={() => window.open(convertToSingleStringforTryme(convertToMDText(field.url),""))}
                                                    >
                                                        <label >
                                                            {/* {field.structValue.fields.text.stringValue} */}
                                                            <AssistantMd mdText={convertToMDText(field.text)} />
                                                        </label>
                                                    </SmallButton>
                                                </div>
                                            ) : null
                                        })}
                                    </div>
                                )}
                                {/* <div className="tryit_ticket_details">
                                <h6>Ticket Details:</h6>
                                <p> I’m locked out of my account. Please unlock my account.</p>
                                <SmallButton style={{ marginTop: 6 }} primary>
                                    <label>View Ticket</label>
                                </SmallButton>
                            </div>
                            <div className="tryit_ticket_details">
                                <h6>Status: </h6>
                                <p>Account Locked</p>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <span className="adaptive_tail_icon">
                    {' '}
                    <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                        <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                    </svg>
                </span>
            </div>
        )
    }

    return forCarousal ? (
        generateChildren()
    ) : (
        <>
            <div className="tryit_understand_parent tryit_card_wrapper">
                <div className='try_me_icon'>
                    <TrymeIcon />
                </div>
                {generateChildren()}
            </div>
        </>
    )
}

function AdaptiveCardForCarousal({ message, sendMessage }: OptionResP) {
    const currentValue = message.adaptive_card.fields.blocks.listValue.values

    const title = currentValue.filter(
        (block: any) =>
            block.structValue.fields.text &&
            block.structValue.fields.text.structValue.fields.style.stringValue === 'bold'
    )[0]
    const description = currentValue.filter(
        (block: any) =>
            block.structValue.fields.text &&
            block.structValue.fields.text.structValue.fields.style.stringValue === 'normal'
    )[0]
    const image = currentValue.filter((block: any) => block.structValue.fields.accessory)[0]
    const fields = currentValue.filter((block: any) => block.structValue.fields.accessory)[0]

    const elements = currentValue.filter((block: any) => block.structValue.fields.elements)[0]

    return (
        <>
            <div className="position-realtive tryit_wrapper_card">
                <div className="tryit_cards">
                    <div className="tryit_cards_top">
                        <div className="tryit_cards_top_left">
                            {image &&
                                image.structValue.fields.accessory &&
                                image.structValue.fields.accessory.kind !== 'nullValue' && (
                                    <img
                                        src={
                                            image.structValue.fields.accessory.structValue.fields.image_url.stringValue
                                        }
                                        alt={
                                            image.structValue.fields.accessory.structValue.fields.type.stringValue
                                                ? image.structValue.fields.accessory.structValue.fields.type.stringValue
                                                : 'image'
                                        }
                                    />
                                )}
                        </div>
                        <div className="tryit_cards_top_right">
                            {title && (
                                <h4 className="word_break">
                                    {title.structValue.fields.text.structValue.fields.text.stringValue}
                                </h4>
                            )}

                            {description && (
                                <p>{description.structValue.fields.text.structValue.fields.text.stringValue}</p>
                            )}
                            {/* <div className="time_stamp">10:12 am</div> */}
                        </div>
                    </div>
                    <div className="tryit_cards_wrapper">
                        <div className="tryit_cards_left">
                            <div className="tryit_ticket_wrapper tryit_content_wrapper">
                                {fields && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {fields.structValue.fields.fields.listValue.values.map(
                                            (field: any, key: number) => (
                                                <div className="tryit_ticket_details" key={key}>
                                                    <h6>{field.structValue.fields.title.stringValue}</h6>
                                                    {field.structValue.fields.value.stringValue.includes('https') ||
                                                    field.structValue.fields.value.stringValue.includes('www.') ? (
                                                        // <a href={field.structValue.fields.value.stringValue}>
                                                        //     {Parser(field.structValue.fields.value.stringValue)}
                                                        // </a>
                                                        <p>
                                                            {Parser(urlify(field.structValue.fields.value.stringValue))}
                                                        </p>
                                                    ) : (
                                                        <p>{field.structValue.fields.value.stringValue}</p>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                                {elements && (
                                    <div className="tryit_ticket_details_wrapper">
                                        {elements.structValue.fields.elements.listValue.values.map(
                                            (field: any, key: number) =>
                                                field.structValue.fields.type.stringValue === 'button' ? (
                                                    <div className="tryit_ticket_details">
                                                        <SmallButton
                                                            style={{ marginTop: 6 }}
                                                            primary
                                                            key={key}
                                                            onClick={() =>
                                                                window.open(field.structValue.fields.url.stringValue)
                                                            }
                                                        >
                                                            <label>{field.structValue.fields.text.stringValue}</label>
                                                        </SmallButton>
                                                    </div>
                                                ) : null
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <span className="adaptive_tail_icon">
                    {' '}
                    <svg x="0px" y="0px" viewBox="0 0 7.9 7.9" xmlSpace="preserve">
                        <path d="M7.9 8c0-4.4-3.6-8-8-8h8v8z" fill="currentColor" />
                    </svg>
                </span>
            </div>
        </>
    )
}

type TextBoxP = {
    announcement: string
    setAnnouncement: Function
    sendMessage: Function
    assistantid: string
    workspacename: string
    setFileValue: Function
    statusValue: statusT
    botIsTyping: boolean
    sessionEnded: boolean
    past: any
}

function TextBox({
    announcement,
    setAnnouncement,
    sendMessage,
    assistantid,
    workspacename,
    setFileValue,
    statusValue,
    sessionEnded,
    botIsTyping,
    past
}: TextBoxP) {
    const inpRef = useRef<null | HTMLInputElement>(null)

    const onFileChange = (event: any) => {
        onFileUpload(event)
    }

    const onFileUpload = (event: any) => {
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file, file.name)
        formData.append(
            'category',
            file.type.split('/')[0] === 'image' ? 'image' : file.type.split('/')[0] === 'video' ? 'video' : 'file'
        )
        postJson(socketApiURLWithoutWorkspace(`/tryme/${assistantid}/${workspacename}/file_upload`), formData)
            .then(res => setFileValue('', past, res.data))
            .catch(err => console.log(err))
    }

    if (statusValue === 'training' || sessionEnded) {
        inpRef.current && inpRef.current.blur()
    }

    if (statusValue === 'trained') {
        inpRef.current && inpRef.current.focus()
    }

    const textareaRef = useRef(null);
    useEffect(() => { 
        if(textareaRef.current){
        textareaRef.current.focus();
        autosize.default(textareaRef.current);
        }    
    }, [textareaRef.current]);

    return (
        <>
            {botIsTyping && (
                <p className="tryit_bot_typing">
                    Try Me is typing
                    <span>
                        <img src={botTyping} />{' '}
                    </span>
                </p>
            )}
            <div
                className={
                    statusValue === 'training' || sessionEnded ? 'btn-deactivaed tryit_something' : 'tryit_something'
                }
            >
                <div className="widget_input_message_section">
                    <div className="tryit_something_input">
                        <textarea
                            // type="text"
                            placeholder="Write a message"
                            value={announcement}
                            onChange={(e) => setAnnouncement(e.target.value)}
                            onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                announcement.replace(/\s/g, '').length !== 0
                                ? sendMessage(announcement)
                                : null
                                if(textareaRef.current) {
                                textareaRef.current.style.height = "30px"
                                textareaRef.current.style.overflow = "hidden"
                                }
                            }
                            }}    
                            style={style}
                            ref={textareaRef}
                            rows={1}
                            id="typemsg"
                            wrap="hard"
                        >
                        </textarea>
                    </div>
                    <span className="selectFileButton">
                        <input id="___SelectFileButton___" type="file" onChange={onFileChange} hidden ref={inpRef} />
                        <span
                            onClick={() => {
                                if (inpRef && inpRef.current) {
                                    inpRef.current.click()
                                }
                            }}
                        >
                            <AttachmentIcon />
                        </span>
                        <span
                            onClick={e => (announcement.trim() !== '' ? sendMessage(announcement) : null)}
                            className="_send_icon"
                        >
                            {announcement ? (
                                <WidgetSendIcon />
                            ) : (
                                <span className="btn-deactivaed">
                                    <WidgetSendIcon />
                                </span>
                            )}
                        </span>
                    </span>                        
                    </div>
                </div>               
                    {/* <input
                        type="text"
                        name="Enter Text"
                        placeholder="Write a message"
                        value={announcement}
                        onKeyDown={e =>
                            e.keyCode === 13 && announcement.replace(/\s/g, '').length !== 0
                                ? sendMessage(announcement)
                                : null
                        }
                        onChange={e => setAnnouncement(e.target.value)}
                    /> */}   
        </>
    )
}

type ContextP = {
    key?: number
    index: number
    Context: any
    modifyContext: Function
    removeContext: Function
    setValues: Function
}

function Context(props: ContextP) {
    const [view, setView] = useState<VIEW>(VIEW.VIEW_MODE)

    const [variable, setVariable] = useState<string>(props.Context.variable)

    const [value, setValue] = useState<string>(props.Context.value)
    const [key, setKey] = useState(false)

    useEffect(() => {
        if (props.Context.variable === '' && props.Context.value === '') {
            setView(VIEW.EDIT_MODE)
        }
    }, [])

    useEffect(() => {
        setVariable(props.Context.variable)
        setValue(props.Context.value)
    }, [props.Context])

    useEffect(() => {
        if (key) {
            props.modifyContext(props.Context, props.Context.variable, variable, value)
            typeof props.index === 'number'
                ? props.setValues((data: any) => [
                      ...data.slice(0, props.index),
                      variable,
                      ...data.slice(props.index + 1)
                  ])
                : props.setValues((data: any) => [...data, variable])
            setKey(false)
            setView(VIEW.VIEW_MODE)
        }
    }, [key])

    return (
        <React.Fragment>
            <div className={view === VIEW.EDIT_MODE ? 'plus_addmore_edit' : 'plus_addmore'}>
                <div className="plus_add_more tryit_addmore">
                    <Header>
                        {props.index < 9 ? '' + (props.index + 1).toString() : (props.index + 1).toString()}. Set value
                    </Header>

                    <div className="right_pop_icons">
                        {view === VIEW.EDIT_MODE ? (
                            <span onClick={() => setKey(true)}>
                                <CheckColorIcon />
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    // console.log('Key::', props.index)
                                    setView(VIEW.EDIT_MODE)
                                }}
                                className="try_me_edit_icon"
                            >
                                <EditIcon />
                            </span>
                        )}
                        {view === VIEW.EDIT_MODE ? (
                            <span
                                onClick={() => {
                                    setView(VIEW.VIEW_MODE)
                                    setVariable(props.Context.variable)
                                    setValue(props.Context.value)
                                }}
                            >
                                <CloseColorIcon />
                            </span>
                        ) : (
                            
                            <span onClick={() => props.removeContext(props.Context.variable, props.index)} className="try_me_close_icon">
                            <DeleteIcon />
                        </span>
                        )}
                    </div>
                </div>

                <InputWrapper>
                    <InputContainer style={{marginBottom: 10}}>
                        <Input type="text" value={variable} onChange={(e: any) => setVariable(e.target.value)} />
                        <InputContent>Varaible name</InputContent>
                    </InputContainer>
                    <InputContainer>
                        <Input type="text" value={value} onChange={(e: any) => setValue(e.target.value)} />
                        <InputContent>Set Value</InputContent>
                    </InputContainer>
                </InputWrapper>

                {/* <div className="tryit-content">
                    <div className="if_bot_wdt">
                        <div className="if_bot">
                            <div className="if_dropdown">
                                <h6>Varaible name</h6>
                            </div>
                        </div>
                        <div className="if_right_dropdown dropdown_entity">
                            <div className="if_dropdown if_tryit_right">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    value={variable}
                                    onChange={e => setVariable(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="tryit-content">
                    <div className="if_bot_wdt">
                        <div className="if_bot">
                            <div className="if_dropdown">
                                <h6>Set Value</h6>
                            </div>
                        </div>
                        <div className="if_right_dropdown dropdown_entity">
                            <div className="if_dropdown if_tryit_right">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Value"
                                    value={value}
                                    onChange={e => setValue(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    )
}

type TrainContextP = {
    values: any
    setValues: Function
    context: any
    setContext: Function
}

function TrainContext(props: TrainContextP) {
    const { context, setContext, values, setValues } = props

    const [newContext, setNewContext] = useState<Boolean>(false)

    const modifyContext = (context: any, oldKey: string, newKey: string, value: string) => {
        if (oldKey === '') {
            setContext((data: any) => R.assoc(newKey, value, data))
            setNewContext(false)
        } else {
            setContext((context: any) =>
                Object.keys(context).reduce((object, key) => {
                    if (key === oldKey) {
                        return R.assoc(newKey, value, object)
                    }
                    return R.assoc(key, context[key], object)
                }, {})
            )
        }
    }

    const removeContext = (context: any, prop: any) => {
        setValues((values: string[]) => values.filter((value: string) => value !== prop))
        setContext(
            Object.keys(context).reduce((object, key) => {
                if (key !== prop) {
                    object[key] = context[key]
                }
                return object
            }, {})
        )
    }
    return (
        <div className="tryit_message module_tryit_message">
            {R.concat(
                Object.keys(values.reduce((a: any, b: any) => ({ ...a, [b]: context[b] ? context[b] : '' }), {})),
                Object.entries(context)
                    .map(
                        (c: any) =>
                            (c[0].includes('SYS_') == false && typeof c[1] == 'string' && values.indexOf(c[0]) == -1
                                ? c[0]
                                : null) as any
                    )
                    .filter(Boolean)
            )
                // .filter(data => values.some(value => value === data))
                // .filter((e: any) => R.is(String, context[e]))
                // .filter((e: any) => e !== 'conversation_id')
                // .filter((e: any) => e !== 'SYS_platform')
                // .filter((e: any) => e !== 'SYS_COGNIDESK_WORKSPACE')
                .map((e: any, index: number) => (
                    <Context
                        key={index}
                        index={index}
                        Context={{ variable: e, value: context[e] }}
                        modifyContext={modifyContext}
                        removeContext={(key: string) => removeContext(context, key)}
                        setValues={setValues}
                    />
                ))}
            {newContext ? (
                <Context
                    index={
                        R.concat(
                            Object.keys(
                                values.reduce((a: any, b: any) => ({ ...a, [b]: context[b] ? context[b] : '' }), {})
                            ),
                            Object.entries(context)
                                .map(
                                    (c: any) =>
                                        (c[0].includes('SYS_') == false &&
                                        typeof c[1] == 'string' &&
                                        values.indexOf(c[0]) == -1
                                            ? c[0]
                                            : null) as any
                                )
                                .filter(Boolean)
                        ).length
                        // Object.keys(context)
                        //     .filter((e: any) => R.is(String, context[e]))
                        //     .filter((e: any) => e !== 'conversation_id')
                        //     .filter((e: any) => e !== 'SYS_platform')
                        //     .filter((e: any) => e !== 'SYS_COGNIDESK_WORKSPACE').length
                    }
                    Context={{ variable: '', value: '' }}
                    modifyContext={modifyContext}
                    removeContext={(key: string) => setNewContext(false)}
                    setValues={setValues}
                />
            ) : null}

            <SmallButton style={{ marginLeft: 20 }} primary onClick={() => setNewContext(true)}>
                <span>
                    <AddIcon />
                </span>
                <label>Add More</label>
            </SmallButton>
        </div>
    )
}

// const pastReducer = (state : any[] , action : {type : string , data : any}){

// }

export function TryItWithButton(
    props: TryItP & {
        // showButton: boolean
        setTryme: Function
        // tryme: boolean
        tryMeStatus: boolean
        deployStatus: { error: boolean; success: boolean; deploying: boolean }
        hideAll: boolean
        isNull?: boolean
        isIntentEnityEmpty?: boolean
        dialogueName?:string
    }
) {

    const queryClient = useQueryClient();

    const { deployed, deploymentError, deploybot, isNull, isIntentEnityEmpty, dialogueName } = props
    const hasDisabled = false
    let climaxPart: any

    const journeyData = useJourney()

    const [defaultContext, setDefaultContext] = useState<any>({
        SYS_COGNIDESK_WORKSPACE: props.workspacename,
        SYS_BOT_ID: props.assistantid,
        SYS_platform: 'try_it',
        metadata: { deployment: 'try_it' },
        SYS_FirstName: '',
        SYS_LastName: '',
        SYS_UserEmail: '',
        SYS_UPLOADED_FILE: null,
        SYS_SETTINGS: null
    })

    const [context, setContext] = useState<any>({
        SYS_COGNIDESK_WORKSPACE: props.workspacename,
        SYS_BOT_ID: props.assistantid,
        SYS_platform: 'try_it',
        metadata: { deployment: 'try_it' },
        SYS_FirstName: '',
        SYS_LastName: '',
        SYS_UserEmail: '',
        SYS_UPLOADED_FILE: null,
        SYS_SETTINGS: null
    })
    // const [tryMeRestart, setTryMeRestart] = useState(false)
    const statusValue = useContext(StatusContext)

    const [pageError, setPageError] = useState<boolean>(false)

    const [socketError, setSocketError] = useState<boolean>(false)

    const [isTrainingOpen, setIsTrainingOpen] = useState<boolean>(false)

    const [trainingIndex, setTrainingIndex] = useState<number | null>(null)

    const [past, setPast] = useState<any[]>([])

    const [announcement, setAnnouncement] = useState<string>('')

    const [view, setView] = useState<MODE>(MODE.CHAT_VIEW)

    const [endMessage, setEndMessage] = useState<string>('')

    const [endTime, setEndTime] = useState<number>(60000 * 5)

    const [lastMessageTime, setLastMessageTime] = useState<number>(0)

    const [typing, setTyping] = useState<boolean>(false)

    const [fetching, setFetching] = useState<boolean>(true)
    const fetchedRef = useRef<'fetched' | 'not-fetched'>('not-fetched')

    const [debouncedValue, setDebouncedValue] = useState(Math.random())

    const [conversation_id, setConversationId] = useState('')

    const conversationIdRef = useRef<string>('')

    const [subscribeToSocket, setSubscribeToSocket] = useState<'subscribed' | 'not-subscribed'>('not-subscribed')
    const subscribeToSocketRef = useRef<'subscribed' | 'not-subscribed'>('not-subscribed')

    const initialValues = [
        'SYS_FirstName',
        'SYS_LastName',
        'SYS_UserEmail',
        'SYS_platform',
        'SYS_Anything_Else',
        'SYS_User_Latest_Response',
        "SYS_previous_dialog_title",
        "SYS_current_dialog_title",
        'SYS_user_conversation'
    ]

    const [values, setValues] = useState(initialValues)

    const [sessionEnded, setSessionEnded] = useState<boolean>(false)

    // console.log('TryItWithButton props::: ', props, statusValue)

    // console.log('statusValue context ::: ', statusValue)
    // console.log('DeployBot ::: ', props.deployStatus, deployed)

    // const messageReferneceId = useRef<null | string>(null)

    // const getNewConversionId = () => {
    //     const messageId = generateUniqueId()

    //     messageReferneceId.current = messageId
    // }

    const [system, setSystem] = useState({
        stack: [],
        conversation_id: conversation_id,
        user_email: defaultContext.SYS_UserEmail,
        workspace: props.workspacename,
        platform: defaultContext.SYS_platform
    })
    useEffect(() => {
        // console.log('props ::: tryme', props.tryMeStatus, props.assistantid)
        if (props.assistantid !== '' && props.tryMeStatus == true) {
            // console.log('Calling deploy if not deployed:: 1')
            deploybot && deploybot()
        }
    }, [props.tryMeStatus])
    const fetch = () => {
        // console.log('calling fetch ::: 1')
        setPageError(false)
        setTyping(true)

        // getSettings(props.workspacename, props.assistantid)
        queryClient.fetchQuery(
            ['settings', props.assistantid],
            () => getSettings(props.workspacename, props.assistantid)
          )
            .then((data: any) => {
                const userData = MOCK_TRYIT
                    ? Promise.resolve({
                          phone_number: null,
                          id: 39,
                          first_name: 'Manjula',
                          last_name: 'M',
                          email: 'manjula.m@skitter.in',
                          org: null,
                          role: null,
                          country: 'IN'
                      })
                    : getJson(loginApiURL('/user_info'))
                userData
                    .then(res => {
                        // console.log('settings', data, res)
                        setContext({
                            ...context,
                            SYS_FirstName: res.first_name,
                            SYS_LastName: res.last_name,
                            SYS_UserEmail: res.email
                        })
                        setDefaultContext({
                            ...defaultContext,
                            SYS_FirstName: res.first_name,
                            SYS_LastName: res.last_name,
                            SYS_UserEmail: res.email
                        })

                        const { end_conversation_message, end_conversation_time } = data
                        setEndMessage(end_conversation_message)
                        setEndTime(60000 * parseInt(end_conversation_time))
                        setFetching(false)
                        fetchedRef.current = 'fetched'
                    })
                    .catch(err => {
                        // console.log("TRYME ::: can't able to fetch user info :(")
                        setTyping(false)
                        setPageError(true)
                    })
            })
            .catch(err => {
                console.log("TRYME ::: can't able to fetch settings :(")
                setTyping(false)
                setPageError(true)
            })
    }

    // Working
    useEffect(() => {
        // console.log('TryItWithButton props ::: ::: ', statusValue)
        if (statusValue === 'trained' && fetchedRef.current === 'not-fetched') {
            fetch()
        }
        return () => {
            // console.log('disconnectiod :::: ')
            if (subscribeToSocket === 'subscribed') {
                messengerObject && disconnectSocket()
                messengerObject = undefined
                setSubscribeToSocket('not-subscribed')
                subscribeToSocketRef.current = 'not-subscribed'
            }
        }
    }, [deployed, statusValue])

    useEffect(() => {
        // console.log(
        //     'TryItWithButton props::: 1',
        //     // deployed,
        //     // fetching,
        //     props.tryMeStatus,
        //     deployed,
        //     // statusValue,
        //     props,
        //     fetching
        // )
        if (deployed == true && fetching === false && statusValue === 'trained' && props.tryMeStatus == true) {
            // console.log('sending message')
            sendMessage('')
        }
    }, [fetching, props.tryMeStatus, statusValue])

    // Working
    useEffect(() => {
        scrollBase()
    }, [debouncedValue])

    // useEffect(() => {
    //     console.log('useEffect ::: past', past)
    // }, [past])

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(Math.random())
            }, 200)
            return () => {
                clearTimeout(handler)
            }
        },
        [past, isTrainingOpen] // Only re-call effect if value or delay changes
    )

    // Working
    const scrollBase = () => {
        climaxPart ? climaxPart.scrollIntoView({ behavior: 'smooth' }) : null
    }

    // Working
    const clearConversation = () => R.cond([[R.equals(typing), clearConversationMessageAndSendEmptyMessage]])(false)

    // Working
    const clearConversationMessageAndSendEmptyMessage = async () => {
        if (fetching === false) {
            // createMessenger()
            unsubscribeAndReinitialize()
            setSocketError(false)
            setTyping(true)
            setAnnouncement('')
            setPast([])
            setContext(defaultContext)
            setTrainingIndex(null)
            sendMessageToWatson('', [])
            subscribeToTypingEnd((result: any) => {
                setTyping(false)
            })
        }
    }

    const clearConversationMessageWithoutSend = () => {
        // createMessenger()

        setTyping(true)
        setAnnouncement('')

        setPast([])
        setContext(defaultContext)

        subscribeToTypingEnd((result: any) => {
            setTyping(false)
        })
    }
    // Working
    const sendMessage = (proof: string) => {
        // console.log('send message called')
        if (proof === '') {
            // console.log('messages :: 1', past)
            sendMessageToWatson('', past)
        } else if (Date.now() - lastMessageTime > endTime) {
            // console.log('messages :: 2', past)
            const index = past.length + 1
            setPast([
                ...past,
                { responseType: 'user', text: proof, index },
                {
                    output: 'text',
                    text: { text: endMessage },
                    index: index + 1,
                    conversationId: conversationIdRef.current
                }
            ])
            setContext(defaultContext)
            setAnnouncement('')
            setLastMessageTime(Date.now())
            setSessionEnded(true)
        } else {
            // console.log('messages :: 3', past)
            past[past.length - 2] && past[past.length - 2].output === 'option' && past[past.length - 1].text === proof
                ? null
                : sendMessageToWatson(proof, past)
        }
    }

    const unsubscribeAndReinitialize = () => {
        setSubscribeToSocket('not-subscribed')
        subscribeToSocketRef.current = 'not-subscribed'

        clearSocket()
    }

    const sendMessageToWatson = async (proof: string, past: any[], file?: any) => {
        createMessenger()
        let proofContext = proof == '' && !file ? R.clone(defaultContext) : R.clone(context)
        let index = 0
        let pastResponse = past

        setPast(() => {
            index = past.length + 1
            if (proof.length) {
                pastResponse = R.concat(past, [{ responseType: 'user', text: proof, index: past.length + 1 }])
                return pastResponse
            } else return past
        })
        setTyping(true)
        setAnnouncement('')

        // console.log('proofContext', proofContext)
        setLastMessageTime(Date.now())
        await executeWithAuthToken(async () => {
            setSessionEnded(false)
            let eventToEmit = proof === '' && file === undefined ? 'restart' : 'message'
            // console.log('messengerObject ::: ', messengerObject, subscribeToSocket)

            const messageToSend = {
                input: proof,
                output: [],
                context:
                    eventToEmit === 'message' || file != undefined
                        ? { ...proofContext, SYS_UPLOADED_FILE: file, SYS_MESSAGE_ID: generateUniqueId() }
                        : { ...proofContext, SYS_MESSAGE_ID: generateUniqueId() },
                bot_id: 'testing_' + props.assistantid,
                system:
                    eventToEmit === 'restart'
                        ? {
                              ...system,
                              stack: [],
                              conversation_id: conversation_id,
                              user_email: defaultContext.SYS_UserEmail,
                              workspace: props.workspacename,
                              platform: defaultContext.SYS_platform
                          }
                        : { ...system, user_email: proofContext.SYS_UserEmail }
            }

            // console.log('messageToSend :: 1915', messageToSend, proofContext, eventToEmit)

            messengerObject.emit(eventToEmit, {
                message: messageToSend,
                authToken: authToken()
            })
        })

        const errorCallback = () => {
            setSocketError(true)
        }

        let consecutiveOutput: any = []
        let messageIdForOutput = ''
        if (subscribeToSocketRef.current == 'not-subscribed') {
            // console.log('sendMessage ::: called subscription')
            await subscribeToChatWithErrorHandling(
                (result: any) => {
                    let pastResponse = past
                    // console.log(result, 'past in 1934 ::: subscribeToChat', result)
                    setConversationId(result.system.conversation_id)
                    conversationIdRef.current =
                        result.system.conversation_id === '' ? conversationIdRef.current : result.system.conversation_id
                    setSystem(result.system)
                    // if (result.context.SYS_MESSAGE_ID === messageIdForOutput) {
                    //     consecutiveOutput = consecutiveOutput.concat(result.output)
                    //     return
                    // } else {
                    //     messageIdForOutput = result.context.SYS_MESSAGE_ID
                    //     consecutiveOutput = [].concat(result.output)
                    // }
                    consecutiveOutput = [].concat(result.output)
                    const hasFile = result.context.SYS_UPLOADED_FILE

                    result = hasFile ? { ...result, context: { ...result.context, SYS_UPLOADED_FILE: null } } : result

                    // console.log('result-1 ::', result)

                    // console.log('TRYME:: consecutiveOutput', consecutiveOutput)
                    // console.log('TYRME:: pastResponse', pastResponse)

                    if (result.context.SYS_FLOW_EXECUTION_INFO) {
                        const info = result.context.SYS_FLOW_EXECUTION_INFO

                        setPast(pastR =>
                            R.concat(
                                pastR.map((e: any, i: number) =>
                                    index === 0
                                        ? e
                                        : i + 1 === index
                                        ? {
                                              ...e,
                                              entities: result.entities ? result.entities : [],
                                              intents: result.intents ? result.intents : [],
                                              faq: result.faq ? result.faq : []
                                          }
                                        : e
                                ),
                                [
                                    {
                                        responseType: 'flow',
                                        automationDialogName: info.automationName,
                                        automationName: info.automationNode.name,
                                        success: result.context[info.output_variable].output.executionContext.output
                                            .success
                                            ? 'Success'
                                            : 'Failure'
                                    },
                                    ...consecutiveOutput.map((e: any, i: number) => ({
                                        ...e,
                                        index: pastR.length + i + 2
                                    }))
                                ]
                            )
                        )
                        setContext((data: any) => ({ ...R.dissoc('SYS_FLOW_EXECUTION_INFO', result.context) }))
                    } else {
                        // const allResponses = R.concat()
                        // console.log('index', index)
                        setPast(pastR => {
                            return R.concat(
                                pastR
                                    // .filter(message => {
                                    //     return message.conversationId === conversation_id
                                    // })
                                    .map((e: any, i: number, pastMessages: any[]) =>
                                        index === 0
                                            ? {
                                                  ...e
                                              }
                                            : i + 1 === pastMessages.length
                                            ? {
                                                  ...e,
                                                  entities: result.entities ? result.entities : [],
                                                  intents: result.intents ? result.intents : [],
                                                  faq: result.faq ? result.faq : []
                                              }
                                            : {
                                                  ...e
                                              }
                                    ),
                                consecutiveOutput.map((e: any, i: number) => ({
                                    ...e,
                                    index: pastR.length + i + 1,
                                    messageId: result.context.SYS_MESSAGE_ID,
                                    conversationId: result.system.conversation_id
                                }))
                            )
                        })

                        setContext((data: any) => ({ ...result.context }))
                    }
                    // setTrainingIndex(index)
                    // setIsTrainingOpen(true)
                },
                errorCallback,
                () => unsubscribeAndReinitialize()
            )
            setSubscribeToSocket('subscribed')
            subscribeToSocketRef.current = 'subscribed'
        }
        subscribeToTypingEnd((result: any) => {
            setTyping(false)
        })
        consecutiveOutput = []
        return null
    }

    const removeMappedIntent = async (intent: any, message: any, callback: Function) => {
        const obj = {
            ...intent,
            examples: intent.examples.filter((e: any) => e.text !== message.text)
        }
        await postJson(watsonApiURL(props.workspacename, IntentApi.UpdateIntent + intent.intent), obj).then(res => {
            setPast(past => past.map(e => (e.index === message.index ? { ...e, intents: [] } : e)))
            callback()
        })
    }

    const changeMappedIntent = async (intent: any, message: any, callback: Function) => {
        const obj = {
            ...intent,
            examples: [
                ...intent.examples.filter(
                    (e: any) => e.text.toLowerCase().trim() !== message.text.toLowerCase().trim()
                ),
                { text: message.text }
            ]
        }

        if (message.intents.length && props.intents.filter(e => e.intent === message.intents[0].intent).length) {
            const currentIntent = props.intents.filter(e => e.intent === message.intents[0].intent).pop()
            await postJson(watsonApiURL(props.workspacename, IntentApi.UpdateIntent + message.intents[0].intent), {
                ...currentIntent,
                examples: [
                    ...currentIntent.examples.filter(
                        (e: any) => e.text.toLowerCase().trim() !== message.text.toLowerCase().trim()
                    )
                ]
            })
        }
        await postJson(watsonApiURL(props.workspacename, IntentApi.UpdateIntent + intent.intent), obj)
            .then(e => e.data)
            .then(res => {
                setPast((past: any[]) =>
                    past.map(e => (e.index === message.index ? { ...e, intents: [{ intent: res.intent }] } : e))
                )
                callback()
            })
    }

    const fetchRawHtml = (text: string): string => {
        var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        var content = text.replace(exp_match, "<a href='$1' target='_blank'>$1</a>")
        var new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim

        var new_content = content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>')

        var html_res = new_content.replace('\n', '<br />')

        return html_res
    }

    if (props.hideAll) {
        return null
    }

    if (!props.tryMeStatus) {
        return (
            <section className="tryme_tooltip">
                <div
                    className={`tryme_popup ${
                        // journeyData.type === 'success' &&
                        // journeyData.value.state().actions.filter(x => x.name === 'tryme-popup').length > 0 &&
                        // !hasDisabled
                        //     ? `_active`
                        //     : 
                            `_inactive`
                    }`}
                >
                    <button
                        className={
                            hasDisabled
                                ? 'btn btn-primary editor_btn_disabled'
                                : `btn btn-primary ${
                                      journeyData.type === 'success' &&
                                      journeyData.value.state().actions.filter(x => x.name === 'tryme-popup').length > 0
                                          ? 'journey_tryme_highlight'
                                          : ''
                                  }`
                        }
                        onClick={() => {
                            clearConversationMessageWithoutSend()
                            props.setTryme(true)
                            
                            logEvent(props.workspacename, {
                                event:"EVENT::DIALOG::USE_TRY_ME", event_meta:{
                                name: dialogueName?dialogueName:"",
                                description: ""
                            }})

                            // journeyData.type === 'success' &&
                            //     journeyData.value.send({
                            //         type: 'EVENT::DIALOG::TRYME_CLICKED',
                            //         tag: 'DIALOG_GENERIC_EVENT',
                            //         journeyType: 'Dialog',
                            //         botId: props.assistantid
                            //     })
                        }}
                    >
                        <span>Try Me</span>
                    </button>
                </div>
                {journeyData.type === 'success' &&
                    journeyData.value.state().actions.filter(x => x.name === 'tryme-popup').length > 0 &&
                    !hasDisabled && (
                        <div className="tooltip_wrapper">
                            <div className="tooltip_wrapper_bg">
                                <p>Test your bot with Try Me</p>
                            </div>
                        </div>
                    )}
            </section>
        )
    }

    const addOne = (x: any) => x + 1
    const minus = (x: any, y: any) => x - y
    const minusOne = (x: any) => x - 1
    const add = (x: any, y: any) => x + y

    const ReceivedCarousalOutput = ({ message, sendMessage }: OptionResP) => {
        let [activeSlide, setActiveSlide] = useState({ index: 0, transformWidth: 0 })
        let currentSlideEls: any = useRef([])

        console.log(message, 'message')
        const adaptive_cards = message.carousal.carousal_adaptive_card
        // const slideRight = (getNextSlideIndex, getNextSlideTransform) => {
        //   if (currentSlideEls.current.length - 1 === activeSlide.index) {
        //     return;
        //   }
        //   let nextIndex = activeSlide.index + 1;
        //   let nextEl = currentSlideEls.current[nextIndex];
        //   let width = nextEl.offsetWidth;
        //   setActiveSlide({
        //     index: nextIndex,
        //     transformWidth: activeSlide.transformWidth - width,
        //   });
        // };
        const slide = (getNextSlideIndex: any, getNextSlideTransform: any) => () => {
            let nextIndex = getNextSlideIndex(activeSlide.index)
            let currentEls = currentSlideEls.current
            if (nextIndex >= currentEls.length || nextIndex < 0) {
                return
            }
            let nextEl = currentSlideEls.current[nextIndex] as any
            let width = nextEl.offsetWidth
            setActiveSlide({
                index: nextIndex,
                transformWidth: getNextSlideTransform(activeSlide.transformWidth, width)
            })
        }
        let slideRight = slide(addOne, minus)
        let slideLeft = slide(minusOne, add)
        return (
            <div className="tryit_understand_parent tryit_card_wrapper slider">
                <div className='try_me_icon'>
                    <TrymeIcon />
                </div>
                <div className='tryit_wrapper_card_box'>
                    <div className='tryit_cards'>
                        <div className="carousal_wrapper">
                            <div
                                className="arrow left_arrow"
                                onClick={slideLeft}    
                            >
                                <img
                                    alt={"left_arrow"}
                                    className="left_arrow"
                                    src="https://workativ-asssistant-widget-images.s3.amazonaws.com/adaptive_card_arrow.svg"
                                    style={activeSlide.index == 0 ? { visibility: 'hidden' } : {}}
                                />
                            </div>
                            <div className='carousal_box_section'>
                                <div className="carousal_container">
                                    <div
                                        className="carousal_slide_container"
                                        style={{
                                            transform: `translate3d(${activeSlide.transformWidth}px, 0px, 0px)`
                                        }}
                                    >
                                        {adaptive_cards.map((adaptive_card: any, i: number) => (
                                            <div
                                                className="slide flex justify-between flex-col"
                                                ref={el => (currentSlideEls.current[i] = el)}
                                            >
                                                <AdaptiveCard
                                                    message={{ adaptive_card }}
                                                    sendMessage={sendMessage}
                                                    key={i}
                                                    forCarousal
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={adaptive_cards.length == 1 ?`arrow right_arrow editor_btn_disabled`:`arrow right_arrow`}
                                onClick={slideRight}
                            >
                                 <img
                                    alt={"right_arrow"}
                                    src="https://workativ-asssistant-widget-images.s3.amazonaws.com/adaptive_card_arrow.svg"
                                    style={
                                        activeSlide.index == currentSlideEls.current.length - 1
                                            ? { visibility: 'hidden' }
                                            : {}
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="tryit_popup-over-overlay" />
            <div className="tryit_popup">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="tryit_panel">
                            {/* <div
                                className="tryit_close"
                                onClick={() => {
                                    setView(MODE.CHAT_VIEW)
                                    setValues(initialValues)
                                    setPast([])
                                    props.close()
                                    unsubscribeAndReinitialize()
                                }}
                            >
                                <TrymeCloseIcon />
                            </div> */}
                            {/* <div className="tryit_howto">
                                <VideoLinkSimple id={'USE_TRY_ME'} />
                            </div> */}
                            <div className="tryit_header" 
                                style={{
                                    backgroundImage: `url(https://workativ-asssistant-widget-images.s3.amazonaws.com/widget_header_bg.svg)`,
                                    backgroundColor:  "#618eea",
                                  }}
                            >
                                <LogoSize>
                                    {/* <img
                                        alt={"logo"}
                                        src={"https://workativ-asssistant-widget-images.s3.amazonaws.com/chat_logo.png"}
                                    /> */}
                                    <img src={logo_img} />
                                </LogoSize> 
                                <div className='tryit_header_title'>
                                    <h4>Try Me</h4>
                                    <p>Test your bot conversations in real time.{' '}</p>
                                    </div>
                                <div className="tryit_btn">
                                    <span
                                        onClick={
                                            view === MODE.CONTEXT_VIEW
                                                ? () => setView(MODE.CHAT_VIEW)
                                                : () => clearConversation()
                                        }
                                    >
                                        {view === MODE.CONTEXT_VIEW ? <DialogIcon /> : <RestartIcon />}
                                        <div className="side_menu_hvr">
                                            {view === MODE.CONTEXT_VIEW ? 'Chat' : 'Restart'}{' '}
                                        </div>
                                    </span>

                                    <span onClick={() => setView(MODE.CONTEXT_VIEW)}>
                                        <SetValuesIcon />
                                        <div className="side_menu_hvr">Set Values</div>
                                        <div className="set_count">
                                            <span>
                                                {
                                                    R.concat(
                                                        Object.keys(values),
                                                        Object.entries(context)
                                                            .map(
                                                                (c: any) =>
                                                                    (c[0].includes('SYS_') == false &&
                                                                    typeof c[1] == 'string'
                                                                        ? c[0]
                                                                        : null) as any
                                                            )
                                                            .filter(Boolean)
                                                    ).length
                                                }
                                                {/* {
                                                    Object.keys(context)
                                                        .filter((e: any) => R.is(String, context[e]))
                                                        .filter((e: any) => e !== 'conversation_id')
                                                        .filter((e: any) => e !== 'SYS_platform')
                                                        .filter((e: any) => e !== 'SYS_COGNIDESK_WORKSPACE').length
                                                } */}
                                            </span>
                                        </div>
                                    </span>
                                    <span
                                        onClick={() => {
                                            setView(MODE.CHAT_VIEW)
                                            setValues(initialValues)
                                            setPast([])
                                            props.close()
                                            unsubscribeAndReinitialize()
                                        }}
                                    >
                                        <TrymeCloseIcon />
                                    </span>
                                </div>
                            </div>
                            {/* {console.log('statusValue ,', statusValue)}
                            {console.log('pageError ,', pageError)} */}
                            <div className="tryme_error">
                                {(!deployed && deploymentError) || statusValue === 'failed' ? (
                                    <div className="tryme_error_retry">
                                        <p>
                                            Something went wrong. <br /> Please try again.
                                        </p>
                                        <button onClick={() => deploybot && deploybot()}>Retry</button>
                                    </div>
                                ) : pageError ? (
                                    <div className="tryme_error_retry">
                                        <p>
                                            Something went wrong. <br /> Please try again.
                                        </p>
                                        <button onClick={() => fetch()}>Retry</button>
                                    </div>
                                ) : socketError ? (
                                    <div className="tryme_error_retry">
                                        <p>
                                            Something went wrong. <br /> Please try again.
                                        </p>
                                        <button onClick={() => clearConversation()}>Retry</button>
                                    </div>
                                ) : (
                                    <>
                                        {view === MODE.CONTEXT_VIEW ? (
                                            <TrainContext
                                                values={values}
                                                setValues={setValues}
                                                context={R.clone(context)}
                                                setContext={setContext}
                                            />
                                        ) : (
                                            <div className="tryit_message module_tryit_message">
                                                {/* {past.length === 0 && typing && (
                                                    <div className="tryit_understand_parent">
                                                        <span>
                                                            <TrymeIcon />
                                                        </span>
                                                        <p className="tryit_understand_typing">
                                                            <div className="snippet" data-title=".dot-flashing">
                                                                <div className="stage">
                                                                    <div className="dot-flashing"></div>
                                                                </div>
                                                            </div>
                                                        </p>
                                                    </div>
                                                )} */}
                                                {props.deployStatus.deploying || statusValue === 'bot_not_deployed' || statusValue === 'checking_for_update' ? (
                                                    <div
                                                        className={
                                                            typing
                                                                ? 'bot_training_wrapper typing_bot_training_wrapper'
                                                                : 'bot_training_wrapper'
                                                        }
                                                    >
                                                        <div>
                                                            <p className="margin-bottom-9">
                                                                Please wait, the bot is checking for the latest changes
                                                            </p>{' '}
                                                            <p className="connect_account_loading">
                                                                <img src={SmallLoader} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : statusValue === 'training' && props.deployStatus.success ? (
                                                    <div
                                                        className={
                                                            typing
                                                                ? 'bot_training_wrapper typing_bot_training_wrapper'
                                                                : 'bot_training_wrapper'
                                                        }
                                                    >
                                                        <p>Bot training in progress, please wait...</p>{' '}
                                                        <span className="connect_account_loading">
                                                            <img src={SmallLoader} />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    past
                                                        .filter(message => {
                                                            return (
                                                                message.conversationId === conversationIdRef.current ||
                                                                message.conversationId === '' ||
                                                                message.responseType === 'user'
                                                            )
                                                        })
                                                        .map((message: any, index: number) => {
                                                            // {
                                                            //     console.log('messages', message)
                                                            // }
                                                            if (message.output === 'text') {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className="tryit_understand_parent ">
                                                                            <div className='try_me_icon'>
                                                                                <TrymeIcon />
                                                                            </div>
                                                                            <div className="tryit_bot_msg">
                                                                                {/* <pre
                                                                                    className="tryit_understand"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: fetchRawHtml(
                                                                                            message.text.text
                                                                                        )
                                                                                    }}
                                                                                ></pre> */}
                                                                                <div className="tryit_understand">
                                                                                <AssistantMd
                                                                                    mdText={convertToMDText(message.text.text)}
                                                                                />
                                                                                </div>
                                                                                {/* <svg
                                                                                    x="0px"
                                                                                    y="0px"
                                                                                    viewBox="0 0 7.9 7.9"
                                                                                    xmlSpace="preserve"
                                                                                >
                                                                                    <path
                                                                                        d="M7.9 8c0-4.4-3.6-8-8-8h8v8z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg> */}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            } else if (message.responseType === 'flow') {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className="tryit_understand_parent">
                                                                            <span>
                                                                                <TrymeIcon />
                                                                            </span>
                                                                            <p className="tryit_understand">
                                                                                Execute automation
                                                                            </p>
                                                                        </div>
                                                                        <div className="tryme_autoamtion">
                                                                            <div className="tryme_autoamtion_header">
                                                                                <h5>Automation Executed</h5>
                                                                            </div>
                                                                            <div className="tryme_autoamtion_content">
                                                                                <div className="tryme_autoamtion_content_span">
                                                                                    <span className="tryme_autoamtion_left">
                                                                                        Selected Automation
                                                                                    </span>
                                                                                    <span className="tryme_autoamtion_right">
                                                                                        <span className="tryme_autoamtion_center">
                                                                                            :
                                                                                        </span>
                                                                                        {message.automationName}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="tryme_autoamtion_content_span">
                                                                                    <span className="tryme_autoamtion_left">
                                                                                        Automation Name
                                                                                    </span>
                                                                                    <span className="tryme_autoamtion_right">
                                                                                        <span className="tryme_autoamtion_center">
                                                                                            :
                                                                                        </span>
                                                                                        {message.automationDialogName}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="tryme_autoamtion_content_span">
                                                                                    <span className="tryme_autoamtion_left">
                                                                                        Execution Status
                                                                                    </span>
                                                                                    <span className="tryme_autoamtion_right">
                                                                                        <span className="tryme_autoamtion_center">
                                                                                            :
                                                                                        </span>
                                                                                        {message.success}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            } else if (message.responseType === 'user') {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <UserTraining
                                                                            message={message}
                                                                            isTrainingOpen={isTrainingOpen}
                                                                            setIsTrainingOpen={setIsTrainingOpen}
                                                                            trainingIndex={trainingIndex}
                                                                            setTrainingIndex={setTrainingIndex}
                                                                            intents={props.intents}
                                                                            changeMappedIntent={changeMappedIntent}
                                                                            scrollBase={scrollBase}
                                                                            removeMappedIntent={removeMappedIntent}
                                                                        />

                                                                        {typing && message === history.length && (
                                                                            <div className="tryit_understand_parent">
                                                                                <span>
                                                                                    <TrymeIcon />
                                                                                </span>
                                                                                <p className="tryit_understand_typing">
                                                                                    <div
                                                                                        className="snippet"
                                                                                        data-title=".dot-flashing"
                                                                                    >
                                                                                        <div className="stage">
                                                                                            <div className="dot-flashing"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                )
                                                            } else if (message.output === 'option') {
                                                                return (
                                                                    <OptionRes
                                                                        message={message}
                                                                        sendMessage={sendMessage}
                                                                        key={index}
                                                                    />
                                                                )
                                                            } else if (message.output === 'adaptive_card') {
                                                                return (
                                                                    <AdaptiveCard
                                                                        message={message}
                                                                        sendMessage={sendMessage}
                                                                        key={index}
                                                                    />
                                                                )
                                                            } else if (message.output == 'carousal') {
                                                                return (
                                                                    <ReceivedCarousalOutput
                                                                        sendMessage={sendMessage}
                                                                        key={index}
                                                                        message={message}
                                                                    />
                                                                )
                                                            }
                                                            return null
                                                        })
                                                )}

                                                <TextBox
                                                    announcement={announcement}
                                                    setAnnouncement={setAnnouncement}
                                                    sendMessage={sendMessage}
                                                    workspacename={props.workspacename}
                                                    assistantid={props.assistantid}
                                                    setFileValue={sendMessageToWatson}
                                                    statusValue={statusValue}
                                                    sessionEnded={sessionEnded}
                                                    botIsTyping={statusValue !== 'training' && statusValue !== 'checking_for_update' && !props.deployStatus.deploying && typing}
                                                    past={past}
                                                />
                                                <div
                                                    style={{
                                                        float: 'left',
                                                        clear: 'both',
                                                        marginBottom: 15,
                                                        width: '100%'
                                                    }}
                                                    ref={el => {
                                                        climaxPart = el
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export const TryItLegacy = TryItWithButton
