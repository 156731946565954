/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'

import { DownArrowCircle, FlowCanvasIcons } from '@/common/Icons/Icons'
import {
    ConnectionLineIcon,
    ForEachNodeIcon,
    GreenTickIcon,
    LeftPanelStepSuccessIcon,
    TickIconEmptyCircle
} from '@/common/Icons/Workflow.Icons'

import automationImg from '@/common/images/automation.png'
import { LeftSidePanelContext, NonCanvasPanelContext, SidePanelContext } from './MarketPlaceFlowCanvas'
import { useDropDown } from './Tree'
import { LeftPanelStateT, NodeMode, NodeT, Nodes, TreeT } from './types'

export function CanvasLeftPanel(props: any) {
    const { rightPanelState, editTreeData, treeData } = useContext(SidePanelContext)

    const { setLeftPanelState, leftPanelState } = useContext(LeftSidePanelContext)

    useEffect(() => {
        setLeftPanelState({
            ...leftPanelState,
            step1: {
                done: treeData.firstStepDone
            },
            step2: {
                done: treeData.secondStepDone
            },
            parentNode: rightPanelState.parentNode,
            mode: rightPanelState.mode,
            currentNode: rightPanelState.currentNode
        })
    }, [rightPanelState.mode, rightPanelState.currentNode, rightPanelState.parentNode, treeData])

    const currentTree = leftPanelState.mode.mode === NodeMode.View ? treeData : editTreeData

    const secondStepNodes =
        leftPanelState.mode.mode === NodeMode.View ? currentTree.tree.children[0] : currentTree.tree.children[0]

    return (
        <Wrapper>
            {/* <Steps leftPanelState={leftPanelState} step3Node={EndNode.value} /> */}

            <React.Fragment>
                <div className="tree_lines_popup" style={{ borderTop: '0.5px solid #d9e2eb' }}>
                    <ul className="tree_left tree_left_lines">
                        <TriggerOrNoTriggerStep node={currentTree.tree.value} step1done={leftPanelState.step1.done} />
                        {leftPanelState.step1.done ? (
                            secondStepNodes && secondStepNodes.children.length > 0 ? (
                                <BuildSecondStepNodes
                                    secondStepNodes={secondStepNodes}
                                    leftPanelState={leftPanelState}
                                />
                            ) : (
                                firstAndStepInfoImage(
                                    'Complete workflow by adding actions, conditions or selecting other functions.'
                                )
                            )
                        ) : null}
                    </ul>
                </div>
            </React.Fragment>
        </Wrapper>
    )
}

function BuildSecondStepNodes(props: { secondStepNodes: TreeT<NodeT>; leftPanelState: LeftPanelStateT }) {
    const { secondStepNodes, leftPanelState } = props

    return (
        <React.Fragment>
            {secondStepNodes.children.length === 1 ? (
                secondStepNodes.children.map((node: TreeT<NodeT>, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <TreeNodes
                                node={node.value}
                                leftPanelState={leftPanelState}
                                index={i + 2}
                                className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                                showNumbering={true}
                            />
                            {node.children.length > 0 ? (
                                node.children.length === 1 ? (
                                    <GenerateChildrenNode
                                        key={i}
                                        children={node.children}
                                        leftPanelState={leftPanelState}
                                        index={i + 2}
                                        showNumbering={true}
                                    />
                                ) : (
                                    <ParallelPathlist
                                        children={node.children}
                                        leftPanelState={leftPanelState}
                                        showNumbering={true}
                                    />
                                )
                            ) : null}
                        </React.Fragment>
                    )
                })
            ) : (
                <ParallelPathlist
                    children={secondStepNodes.children}
                    leftPanelState={leftPanelState}
                    showNumbering={true}
                />
            )}
        </React.Fragment>
    )
}

const Wrapper = (props: { children: React.ReactNode }) => {
    return (
        <div className="sidepanel_menu_right">
            <div className="sidepanel_menu_right_menu">
                <div className="sidepanel_menu_right_menu_step">
                    <div className="automation_left_panel">
                        <div className="automation_left_panel_tree">
                            <div className="left_popup_automation_tree download_marketplace">{props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GenerateChildrenNode = (props: {
    children: TreeT<NodeT>[]
    leftPanelState: LeftPanelStateT
    index: number
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
}) => {
    const { children, leftPanelState, index, setCurrentParallelPathActive, showNumbering } = props

    return (
        <React.Fragment>
            {children.map((node: TreeT<NodeT>, i: number) => (
                <React.Fragment key={i}>
                    <TreeNodes
                        className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                        node={node.value}
                        leftPanelState={leftPanelState}
                        index={index + (i + 1)}
                        setCurrentParallelPathActive={setCurrentParallelPathActive}
                        showNumbering={showNumbering}
                    />
                    {node.children.length > 0 ? (
                        node.children.length === 1 ? (
                            <GenerateChildrenNode
                                key={i}
                                children={node.children}
                                leftPanelState={leftPanelState}
                                index={index + (i + 1)}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={showNumbering}
                            />
                        ) : (
                            <ParallelPathlist
                                children={node.children}
                                leftPanelState={leftPanelState}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={showNumbering}
                            />
                        )
                    ) : null}
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}

const Steps = (props: { leftPanelState: LeftPanelStateT; step3Node: NodeT }) => {
    const { leftPanelState, step3Node } = props

    const currentNode = leftPanelState.currentNode

    const currentlyStep1Active = currentNode.nodeType === Nodes.Trigger

    const currentlyStep2Active = currentNode.nodeType === Nodes.Action || currentNode.nodeType === Nodes.Condition

    const currentlyStep3Active = currentNode.nodeType === Nodes.End

    const step3NotSaved =
        !step3Node.meta_data ||
        (step3Node.meta_data &&
            step3Node.meta_data.successEmail.length === 0 &&
            step3Node.meta_data.failureEmail.length === 0)

    return (
        <div className="sidepanel_step_automations">
            <StepHeader
                name={'1'}
                done={currentlyStep1Active || !leftPanelState.step1.done}
                showSuccesIcon={leftPanelState.step1.done}
            />
            <StepHeader
                name={'2'}
                done={
                    (currentlyStep2Active && leftPanelState.mode.mode !== NodeMode.View) ||
                    (leftPanelState.step1.done &&
                        !leftPanelState.step2.done &&
                        !currentlyStep1Active &&
                        !currentlyStep3Active)
                }
                showSuccesIcon={leftPanelState.step2.done}
            />

            <StepHeader
                name={'3'}
                showSuccesIcon={!step3NotSaved}
                done={
                    ((leftPanelState.step1.done && leftPanelState.step2.done && currentlyStep3Active) ||
                        (leftPanelState.step2.done && step3NotSaved)) &&
                    (leftPanelState.mode.mode === NodeMode.View || (!currentlyStep2Active && !currentlyStep1Active))
                }
            />
        </div>
    )
}

function StepHeader(props: { done: boolean; name: string; showSuccesIcon: boolean }) {
    const { done, name, showSuccesIcon } = props
    return (
        <div
            className={
                done
                    ? 'sidepanel_step_automations_show sidepanel_step_automations_show_selected'
                    : 'sidepanel_step_automations_show'
            }
        >
            <div className="sidepanel_show_step">
                Step
                {showSuccesIcon && (
                    <span>
                        <LeftPanelStepSuccessIcon />
                    </span>
                )}
            </div>

            <div className="sidepanel_show_step">{name}</div>
        </div>
    )
}

function TriggerOrNoTriggerStep(props: { node: NodeT; step1done: boolean }) {
    const { node, step1done } = props

    const { leftPanelState } = useContext(LeftSidePanelContext)

    const currentNode = leftPanelState.currentNode

    if (node.kind === 'NoTrigger' || node.kind === 'AppTrigger' || node.kind === 'EventTrigger') {
        if (
            (node.kind === 'NoTrigger' && currentNode.nodeType !== Nodes.Trigger) ||
            (currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger')
        ) {
            let inputs = []

            let addedInputs = false

            if (currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger') {
                inputs = currentNode.trigger.triggerMeta.inputKeys
                addedInputs = inputs.filter(x => x.title.length > 0).length > 0
            } else {
                addedInputs = node.output && node.output.properties
            }

            const showInSelectedState =
                currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger'
                    ? 'selected_parent_node'
                    : ''

            return (
                <React.Fragment>
                    <li className="notrigger_start_left">
                        <label className="tree_label">
                            <div className={`step_border_left ${showInSelectedState}`}>
                                <span className="step_border_left_number">1 </span>
                                <span className="step_border_left_line">
                                    <ConnectionLineIcon />
                                </span>
                                <div className="step_border_left_h6_first">
                                    <h6>Triggered by Chatbot</h6>
                                </div>
                                <Step title={'Provide Input'} done={addedInputs} />
                                <Step
                                    title={showInSelectedState.length > 0 ? 'Save' : 'Saved'}
                                    done={showInSelectedState.length === 0}
                                />
                            </div>
                        </label>
                    </li>
                    {currentNode.nodeType === Nodes.Trigger &&
                        !step1done &&
                        firstAndStepInfoImage('Start building your Workflow Automation')}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <TreeNodes
                        className={''}
                        node={{ ...node, kind: 'AppTrigger' }}
                        leftPanelState={leftPanelState}
                        index={1}
                        setCurrentParallelPathActive={() => {}}
                        showNumbering={true}
                    />
                    {currentNode.nodeType === Nodes.Trigger &&
                        !step1done &&
                        firstAndStepInfoImage('Start building your Workflow Automation')}
                </React.Fragment>
            )
        }
    }

    return null
}

function firstAndStepInfoImage(text: string) {
    return (
        <div className="sidepanel_step_automations_content">
            <p>{text}</p>
            <img src={automationImg} alt="automations" title="automations" />
        </div>
    )
}

function TreeNodes(props: {
    node: NodeT
    leftPanelState: LeftPanelStateT
    index: number
    className: string
    showNumbering: boolean
    setCurrentParallelPathActive?: () => void
}) {
    const { node, leftPanelState, index, className, setCurrentParallelPathActive, showNumbering } = props

    // to handle single parallel path empty node while removing it from after empty parallel paths added
    if (node.kind == 'ParallelPath' && node.name.length == 0) return null

    const [expandedView, makevisible, makehidden] = useDropDown(true)

    const { parentAutomation } = useContext(NonCanvasPanelContext)

    const nodesWithoutConnection: string[] = parentAutomation.nodesWithoutConnection

    const ifCurrentNodeisEditNode =
        leftPanelState.mode.mode === NodeMode.Edit && node.id === leftPanelState.mode.nodeEditInput.id

    const showInSelectedState = ''

    useEffect(() => {
        showInSelectedState && setCurrentParallelPathActive && setCurrentParallelPathActive()
    }, [leftPanelState.mode])

    useEffect(() => {
        // to open parallel path as active in left panel
        if (node.kind === 'ParallelPath' && node.name.length > 0) {
            showInSelectedState && setCurrentParallelPathActive && setCurrentParallelPathActive()
        }
    }, [])

    const step = node.kind !== 'AppTrigger' && node.kind !== 'NoTrigger' && node.kind !== 'EventTrigger' ? 2 : index

    return (
        <li
            className={
                className
                    ? className + ` tree_node tree_node_parent ${node.kind == 'ForEach' ? 'foreach_loop_panel' : ''}`
                    : `tree_node tree_node_parent ${node.kind == 'ForEach' ? 'foreach_loop_panel' : ''}`
            }
        >
            <label className="tree_label">
                <div
                    className={`step_border_left ${showInSelectedState} ${
                        node.kind == 'ForEach' ? 'foreach_loop_left' : ''
                    }`}
                >
                    {showNumbering && (
                        <>
                            <span className="step_border_left_number">{step}</span>
                            <span className="step_border_left_line">
                                <ConnectionLineIcon />
                            </span>
                        </>
                    )}
                    <div
                        className={`step_border_left_h6 ${node.kind == 'ForEach' ? 'foreach_loop_h6' : ''}`}
                        onClick={() => (expandedView ? makehidden() : makevisible())}
                    >
                        <h6>
                            {node.kind === 'AppTrigger' || node.kind === 'EventTrigger'
                                ? 'Started with Trigger'
                                : node.kind == 'ErrorHandlingCondition'
                                ? 'Condition'
                                : node.kind == 'ForEach'
                                ? `${node.kind} - ${node.name}`
                                : node.kind}
                        </h6>

                        {node.kind != 'ForEach' &&
                            (expandedView ? (
                                <span className="show_arrow_as_up">
                                    <DownArrowCircle />
                                </span>
                            ) : (
                                <DownArrowCircle />
                            ))}
                    </div>
                    {node.kind == 'ForEach' ? buildForEachSubflow(node.subflow) : buildNodeStepsDetail()}
                </div>
            </label>
        </li>
    )

    function buildForEachSubflow(subflow?: { children: TreeT<NodeT>[] }) {
        return subflow && subflow.children.length > 0 ? (
            <div className="foreach_loop_container">
                <ul className="tree_left tree_left_lines foreach_loop_tree">
                    {subflow.children.length > 0 ? (
                        subflow.children.length === 1 ? (
                            <GenerateChildrenNode
                                children={subflow.children}
                                leftPanelState={leftPanelState}
                                index={index}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={false}
                            />
                        ) : (
                            <ParallelPathlist
                                children={subflow.children}
                                leftPanelState={leftPanelState}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={false}
                            />
                        )
                    ) : null}
                </ul>
            </div>
        ) : null
    }

    function buildNodeStepsDetail() {
        switch (node.kind) {
            case 'AppTrigger':
            case 'EventTrigger':
                return (
                    expandedView && (
                        <div className="step_border_left_p">
                            <Step
                                title={node.meta_data && node.meta_data.app && node.meta_data.app.appName}
                                done
                                icon={node.icon}
                            />
                            <Step title={node.name} done />
                            <Step
                                title={`Connected to ${node.meta_data &&
                                    node.meta_data.app &&
                                    node.meta_data.app.appName}`}
                                done={true}
                            />
                            <Step title={'Input details'} done />
                            <Step title={'Saved'} done />
                        </div>
                    )
                )

            case 'Action':
                return (
                    expandedView && (
                        <div className="step_border_left_p">
                            <Step title={node.meta_data.app && node.meta_data.app.appName} done icon={node.icon} />
                            <Step title={node.name} done />
                            <Step
                                title={`Connected to ${node.meta_data.app && node.meta_data.app.appName}`}
                                done={true}
                            />
                            <Step title={'Input details'} done={true} />
                            <Step title={'Saved'} done={true} />
                        </div>
                    )
                )

            case 'Condition':
            case 'ErrorHandlingCondition': {
                return (
                    expandedView && (
                        <div className="step_border_left_p">
                            <Step title={node.name} done />
                            <Step title={'Setup condition'} done={true} />
                            <Step title={'Saved'} done={true} />
                        </div>
                    )
                )
            }

            case 'Function':
                return (
                    expandedView && (
                        <div className="step_border_left_p">
                            <Step title={'Select function'} done icon={node.icon} />
                            <Step title={'Select operation'} done />
                            <Step title={'Input details'} done={true} />
                            <Step title={'Saved'} done={true} />
                        </div>
                    )
                )
            default:
                return null
        }
    }
}

function Step(props: { title: string; done: boolean; icon?: string }) {
    const { title, done, icon } = props
    return (
        <div className="step_border_left_lists">
            <h6>
                {icon && (
                    <span className="img_left_lists">
                        {' '}
                        <img src={icon} alt="A" />
                    </span>
                )}
                <span className="step_border_left_lists_title">{title}</span>
            </h6>
            <span>{done ? <GreenTickIcon /> : <TickIconEmptyCircle />}</span>
        </div>
    )
}

function ParallelPathlist(props: {
    children: TreeT<NodeT>[]
    leftPanelState: LeftPanelStateT
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
}) {
    const { children, leftPanelState, setCurrentParallelPathActive, showNumbering } = props

    const [currentElementToShowChild, setCurrentChild] = useState(0)

    useEffect(() => {
        const hasOnlyOneParallelPaths = children
            .map((child, i) => ({ ...child, index: i }))
            .filter(child => child.value.kind == 'ParallelPath')

        if (
            hasOnlyOneParallelPaths.length == 1 &&
            hasOnlyOneParallelPaths[0].value.name.length == 0 &&
            hasOnlyOneParallelPaths[0].index == currentElementToShowChild
        ) {
            setCurrentChild(
                currentElementToShowChild == 0 ? currentElementToShowChild + 1 : currentElementToShowChild - 1
            )
        }
    }, [children])

    return ParallelPathlistWrapper(
        children.map((node, i) => {
            return (
                <ParallelPathDetailList
                    leftPanelState={leftPanelState}
                    node={node}
                    index={i}
                    key={i}
                    currentElementToShowChild={currentElementToShowChild}
                    setCurrentChild={index => setCurrentChild(index)}
                    setCurrentParallelPathActive={setCurrentParallelPathActive}
                    showNumbering={showNumbering}
                />
            )
        }),
        children,
        currentElementToShowChild
    )
}

const ParallelPathlistWrapper = (
    Elements: React.ReactNode[],
    children: TreeT<NodeT>[],
    currentElementToShowChild: number
) => {
    const hasOnlyParallelPaths = children
        .filter(child => child.value.kind == 'ParallelPath')
        .filter(child => child.value.name.length === 0)

    const hasOnlyOneParallelPaths = children
        .map((child, i) => ({ ...child, index: i }))
        .filter(child => child.value.kind == 'ParallelPath')

    return (
        <li
            className={`left_panel_parallel_path tree_node ${
                hasOnlyParallelPaths.length > 1
                    ? 'empty_parallel_paths'
                    : hasOnlyOneParallelPaths.length == 1 && hasOnlyOneParallelPaths[0].value.name.length == 0
                    ? 'margin_bottom_for_end_node'
                    : ''
            }`}
        >
            <div className="tf-tree">
                <ul className="tf-tree-ul">
                    <li className="tf-tree-ul-li">
                        <ul className={`tf-tree-ul-li-ul parallel_path_has_${children.length}childs`}>{Elements}</ul>
                    </li>
                </ul>
            </div>
        </li>
    )
}

function ParallelPathDetailList(props: {
    node: TreeT<NodeT>
    leftPanelState: LeftPanelStateT
    index: number
    currentElementToShowChild: number
    setCurrentChild: (index: number) => void
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
}) {
    const {
        node,
        leftPanelState,
        index,
        currentElementToShowChild,
        setCurrentChild,
        setCurrentParallelPathActive,
        showNumbering
    } = props

    // const style: any =
    //     index > 0
    //         ? {
    //               display: currentElementToShowChild === index ? 'block' : 'none'
    //           }
    //         : {
    //               display: currentElementToShowChild === index ? 'block' : 'none'
    //           }

    const style: any = {
        display: currentElementToShowChild === index ? 'block' : 'none'
    }

    return (
        <React.Fragment>
            <li className="tf-tree-li" id={`parallel_path_parent${index}`}>
                <span
                    className={`tf-nc ${
                        index == currentElementToShowChild &&
                        (node.value.kind != 'ParallelPath' || node.value.name.length > 0)
                            ? 'tf-nc-click'
                            : ''
                    }`}
                    onClick={() => {
                        if (node.value.kind !== 'ParallelPath' && leftPanelState.mode.mode == NodeMode.View) {
                            setCurrentChild(index)
                            setCurrentParallelPathActive && setCurrentParallelPathActive()
                        }
                    }}
                >
                    {node.value.kind === 'ParallelPath' ? (
                        <span className="action_plus_icon">
                            <FlowCanvasIcons.ArrowPlusIcon />
                        </span>
                    ) : (
                        <img src={node.value.kind == 'ForEach' ? ForEachNodeIcon : node.value.icon} />
                    )}
                </span>
                {((index == currentElementToShowChild && node.value.kind !== 'ParallelPath') ||
                    (index == currentElementToShowChild &&
                        node.value.kind == 'ParallelPath' &&
                        node.value.name.length > 0)) && <span className="tf-tree-li-span" />}
            </li>
            {(node.value.kind !== 'ParallelPath' || node.value.name.length > 0) && (
                <div className="parallel_path_top" style={style}>
                    <ul className="data_child_ul">
                        <li className="data_child_li">
                            <div className="tree_lines_popup_after_tree">
                                <ul className="tree_left tree_left_lines_child">
                                    <TreeNodes
                                        className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                                        node={node.value}
                                        leftPanelState={leftPanelState}
                                        index={1}
                                        setCurrentParallelPathActive={() => {
                                            setCurrentParallelPathActive && setCurrentParallelPathActive()
                                            setCurrentChild(index)
                                        }}
                                        showNumbering={showNumbering}
                                    />
                                    {node.children.length > 0 ? (
                                        node.children.length === 1 ? (
                                            <GenerateChildrenNode
                                                children={node.children}
                                                leftPanelState={leftPanelState}
                                                index={1}
                                                setCurrentParallelPathActive={() => {
                                                    setCurrentParallelPathActive && setCurrentParallelPathActive()
                                                    setCurrentChild(index)
                                                }}
                                                showNumbering={showNumbering}
                                            />
                                        ) : (
                                            <ParallelPathlist
                                                children={node.children}
                                                leftPanelState={leftPanelState}
                                                setCurrentParallelPathActive={() => {
                                                    setCurrentParallelPathActive && setCurrentParallelPathActive()
                                                    setCurrentChild(index)
                                                }}
                                                showNumbering={showNumbering}
                                            />
                                        )
                                    ) : null}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    )
}
