/*eslint-disable*/
import React from 'react'

import { DialogIcon } from '@/common/Icons/Icons'

import { WORKATIV_META } from './Types'

export function DashboardCardsWidth({ detailedData }: { detailedData: WORKATIV_META[] }) {
    return (
        <div className="dashboard_card_home">
            {detailedData.map((data: WORKATIV_META, index: number) => (
                <DashboardCards key={index} header={data.header} count={data.count} subHeader={data.subHeaders} />
            ))}
        </div>
    )
}

function DashboardCards(props: any) {
    let sentence = 'I got,. commas, here,'
    sentence = sentence.replace(/(.+),$/, '$1')

    return (
        <div className="dashboard_card">
            <div className="dashboard_card_left">
                <h4>{props.header}</h4>
                <h6>{props.count}</h6>
                <div className="dashboard_card_left_img">
                    <span>
                        <DialogIcon />
                    </span>
                </div>
            </div>
            <div className="dashboard_card_right">
                <p>
                    {props.subHeader.map((item: any, i: any) => (
                        <React.Fragment>
                            {item.subHeader} <span>({item.count})</span>,
                        </React.Fragment>
                    ))}
                </p>
            </div>
        </div>
    )
}
