/*eslint-disable*/
import { Modal } from '@/common/components/Modal';
import {
  ApptriggerIconNew,
  AppTriggersIcon,
  DownarrowWithCircle,
} from '@/common/Icons/Icons';
import { NoTriggerIcon } from '@/common/Icons/Workflow.Icons';
import {
  InputContainer,
  InputWrapper,
  Input as InputBox,
  InputContent,
  ButtonContainer,
  Button,
} from '@/common/styled/Workflow.Dumb';
import Downshift from 'downshift';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ButtonWrapper } from '../AutomationMarketplaceDownload';
import { CancelButtonInput } from './ActionPanel';

import { SidePanelContext } from './Canvas';
import { CanvasRightPanel } from './CanvasRightPanel';
import { AppTriggerInitialState, NodeEdit, NodeT, NodeView, Tree } from './types';
import { reduceNodes } from './utils';

export const ChatbotTriggeredPanel = (props: any) => {
  const {
    rightPanelState,
    setRightPanelState,
    setAutomationMetaType,
    editTreeData,
    setEditTreeData,
    treeData,
    active_automation,
    hasLinkedDialogs,
    saveTree,
    automationMeta
  } = useContext(SidePanelContext);

  const [showWarningModal, setShowWarningModal] = useState({
    show: false,
  });

  function saveNode() {
    setRightPanelState({
      ...rightPanelState,
      show: false,
      mode: NodeView,
      parentNode: { value: Tree, children: [] },
    });
    saveTree(rightPanelState.currentNode);
  }

  const renderWarningModal = () => {
    if (showWarningModal.show) {
      return (
        <Modal showPopupLoader={false}>
          <React.Fragment>
            <h2>Do you want to continue?</h2>
            <p>Changing the type will affect your workflow model.</p>
          </React.Fragment>
          <ButtonWrapper style={{ marginTop: 5 }}>
            <Button
              className="btn btn-primary variable_update"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setRightPanelState({
                  ...rightPanelState,
                  show: true,
                  currentTrigger: 'application',
                  currentNode: AppTriggerInitialState,
                  mode: {
                    ...NodeEdit,
                    nodeEditInput: treeData.tree.value,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              className="btn btn-success variable_update"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setShowWarningModal({ show: false });
              }}
              // style={{background: '#fff', border: '1px solid rgb(185, 185, 185)', color: 'rgb(51, 51, 51)'}}
            >
              No
            </Button>
          </ButtonWrapper>
        </Modal>
      );
    } else return null;
  };
  const hasNotAtionNode = reduceNodes(treeData.tree.children[0].children[0]).filter(
    (node: NodeT) => node.kind == 'Action'
).length == 0

  return (
    <React.Fragment>
      {renderWarningModal()}
      <InputWrapper>
        <div className="select_application">
          <div className="select_work_form">
            {/* <h4>{'Select a starting point'}</h4> */}
            <InputContainer className="automation_connection">
              <CanvasRightPanel.AppsDropDown
                inputs={{
                  inputs: rightPanelState.sendNotificationAccess=== 'yes'&& hasNotAtionNode? [
                    {
                      name: 'Application Trigger',
                      value: 'application',
                      icon: ApptriggerIconNew,
                    },
                    {
                      name: 'Triggered by Chatbot',
                      value: 'no_trigger',
                      icon: NoTriggerIcon,
                    },
                  ]:[
                    {
                      name: 'Triggered by Chatbot',
                      value: 'no_trigger',
                      icon: NoTriggerIcon,
                    },
                  ],
                  key: 'name',
                  idKey: 'value',
                  showIcon: true,
                }}
                addDropDown
                updateSelection={(
                  selectedOperatorValue: 'application' | 'no_trigger',
                  id: string
                ) => {
                  if (id == 'application' && automationMeta.type != null) {
                    setShowWarningModal({ show: true });
                  } else if (id == 'application') {
                    const newTree = editTreeData.tree;

                    setRightPanelState({
                      ...rightPanelState,
                      show: true,
                      currentTrigger: 'application',
                      currentNode: AppTriggerInitialState,
                      mode: {
                        ...NodeEdit,
                        nodeEditInput: {
                          ...Tree,
                          id: newTree.value.id,
                          name: 'Triggered by Application',
                          kind: 'AppTrigger',
                          output: { properties: {} },
                          icon: AppTriggersIcon,
                        },
                      },
                    });
                    // setAutomationMetaType('trigger');
                    // this should happen after previous statement in the next tick that's why put timeout
                    setTimeout(() =>
                      setEditTreeData({
                        ...editTreeData,
                        tree: {
                          ...newTree,
                          value: {
                            ...Tree,
                            id: newTree.value.id,
                            name: 'Triggered by Application',
                            kind: 'AppTrigger',
                            output: { properties: {} },
                            icon: AppTriggersIcon,
                          },
                        },
                      })
                    );
                  }
                }}
                render={(makeDropDownVisible: () => void) => (
                  <React.Fragment>
                    <InputBox
                      type="text"
                      value={'Triggered by Chatbot'}
                      onClick={makeDropDownVisible}
                      onChange={() => {}}
                      style={{
                        paddingLeft: 35,
                        color: 'transparent',
                        textShadow: '0 0 0 #000',
                      }}
                      className={
                        !hasLinkedDialogs ? '' : 'editor_btn_disabled'
                      }
                    />
                    <InputContent>Select trigger type</InputContent>

                    <CancelButtonInput rotate onClick={makeDropDownVisible}>
                      <DownarrowWithCircle />
                    </CancelButtonInput>

                    <div className="input_img_left">
                      <img src={NoTriggerIcon} />
                    </div>
                  </React.Fragment>
                )}
              />
            </InputContainer>
            <p></p>
          </div>
        </div>
      </InputWrapper>
      <ButtonContainer className="footer_button_fixed">
        <Button
          className="automation_condition_button_btn cancel_btn"
          // onClick={() => {
          //   setRightPanelState({
          //     ...rightPanelState,
          //     show: false,
          //     mode: NodeView,
          //     parentNode: { value: Tree, children: [] },
          //     currentTrigger: 'application',
          //     // to set first step done in left side panel while cancel
          //     currentNode: AppTriggerInitialState,
          //   })

          //   setTimeout(()=> setEditTreeData(treeData))
          // }}
          onClick={props.cancelAction}
        >
          Cancel
        </Button>
        {!active_automation && (
          <Button
            className={'automation_condition_button_btn'}
            onClick={() => {
              saveNode();
            }}
          >
            Save
          </Button>
        )}
      </ButtonContainer>
    </React.Fragment>
  );
};
