import React from 'react'

import { combinatorConditionT, conditionTYPES, combinatorValueT } from './kind'

const CombinatorCompoenent = ({ condition, onChange }: { condition: combinatorConditionT; onChange: Function }) => (
    <div className="condition_trigger">
        <div
            className="radio"
            onClick={e =>
                onChange({
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.AND
                })
            }
        >
            <label>
                <input
                    type="radio"
                    className="radio-warning"
                    checked={condition.value == combinatorValueT.AND}
                    onChange={() => {}}
                />
            </label>
            <span>And</span>
        </div>
        <div
            className="radio"
            onClick={e =>
                onChange({
                    type: conditionTYPES.COMBINATOR,
                    value: combinatorValueT.OR
                })
            }
        >
            <label>
                <input type="radio" checked={condition.value == combinatorValueT.OR} onChange={() => {}} />
            </label>
            <span>Or</span>
        </div>
    </div>
)

export default CombinatorCompoenent
