import * as parseDomain from 'parse-domain'
import * as R from 'ramda'

import emailBlockList from '@/common/utils/emailBlockList'

const getDomain = R.compose(
    R.ifElse(R.startsWith('.'), R.slice(1, Infinity), R.identity),
    R.converge(R.unapply(R.join('.')), [R.prop('subdomain'), R.prop('domain'), R.prop('tld')]),
    parseDomain as any
)
const domainIsExistedInBlackList = (domain: any) => {
    return R.findIndex(R.equals(domain), emailBlockList) !== -1
}
export const isBlockedDomain: any = R.compose(domainIsExistedInBlackList, getDomain)
