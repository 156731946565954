import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from './user.types';
import * as API from './managerUsers.api';
import { CurrentUserInfo, getCurrentUserInfo } from '@/common/utils/utils';
import { useState } from 'react';
import { ConfirmationOnActionP } from './manageUsers.types';

type WsName = string;

type DisableUserMutationInfo = { ws: WsName; user: number };

const useManageUsersQuries = (ws: WsName) => {
  const {
    data: users,
    isLoading: usersAreLoading,
    isError: usersFetchIsErr,
    error: usersFetchErr,
  } = useQuery<User[], AxiosError>([`users`, ws], () => API.fetchUsers(ws));
  const {
    data: currentUser,
    isLoading: currentUserInfoLoading,
    isError: currUserInfoIsErr,
    error: currUserInfoErr,
  } = useQuery<CurrentUserInfo, AxiosError>(`currentUser`, () =>
    getCurrentUserInfo()
  );
  const {
    data: noOfUsersAllowed,
    isLoading: noOfUsersAllowedLoading,
    isError: isFetchNoOfUsersAllowedErr,
    error: fetchNoOfUsersAllowedErr,
  } = useQuery<number, AxiosError>(['noOfUsersAllowed', ws], () =>
    API.getAllowedNumberOfAdminsApi(ws)
  );

  const loading =
    usersAreLoading || currentUserInfoLoading || noOfUsersAllowedLoading;

  const error = usersFetchErr || currUserInfoErr || fetchNoOfUsersAllowedErr;

  const isError =
    usersFetchIsErr || currUserInfoIsErr || isFetchNoOfUsersAllowedErr;

  return {
    noOfUsersAllowed,
    users,
    currentUser,
    queryInfo: {
      loading,
      error,
      isError,
    },
  };
};

const usePopup = (initialState: boolean) => {
  const [show, invitePopUp] = useState<boolean>(initialState);
  const close = () => invitePopUp(false);
  const open = () => invitePopUp(true);

  return { show, open, close };
};

const useConfirmationPopUp = <ActionData>() => {
  const [confirmation, confirmPopUp] =
    useState<ConfirmationOnActionP<ActionData> | null>(null);
  const open = confirmPopUp;
  const close = () => confirmPopUp(null);
  const showErr = (
    err: string,
    data: ActionData,
    retry: (data: ActionData) => void,
    canceled: () => void
  ) =>
    confirmPopUp({
      ...confirmation,
      isErr: true,
      isLoading: false,
      data,
      err,
      doAction: retry,
      canceled,
    });

  const showLoading = (data: ActionData) =>
    confirmPopUp({ isLoading: true, isErr: false, data });

  return {
    confirmationContent: confirmation,
    open,
    close,
    showErr,
    showLoading,
  };
};

const enableUserConfirmation = (
  user: UserID,
  close: () => void,
  surelyEnable: (userId: number) => void
) => ({
  data: user,
  canceled: close,
  doAction: surelyEnable,
  title: 'Provide User Access',
  message: 'Are you sure to provide the workspace access to the user?',
  actionBtn: 'Yes',
  cancelBtn: 'No',
  primary: 'actionBtn' as const,
});

const disableUserConfirmation = (
  user: UserID,
  close: () => void,
  surelyDisable: (userId: number) => void
) => ({
  data: user,
  canceled: close,
  doAction: surelyDisable,
  title: 'Remove User Access',
  message: 'Are you sure to remove the workspace access to the user?',
  actionBtn: 'Yes',
  cancelBtn: 'No',
  primary: 'actionBtn' as const,
});

const removeUserConfirmation = (
  user: UserID,
  close: () => void,
  surelyRemove: (userId: number) => void
) => ({
  data: user,
  canceled: close,
  doAction: surelyRemove,
  title: 'Remove User',
  message: 'Are you sure to remove the user from workspace?',
  actionBtn: 'Yes',
  cancelBtn: 'No',
  primary: 'actionBtn' as const,
});

type RolesPopupT = { user: User; ws: WsName };

const useEditRolesPopup = (ws: WsName) => {
  const [rolesPopup, setRolesPopup] = useState<RolesPopupT | null>(null);
  const editRole = (user: User) => setRolesPopup({ user, ws });
  const close = () => setRolesPopup(null);
  return { rolesPopup, editRole, close };
};

const useManageUsersPageState = <ConfActionData>(ws: WsName) => {
  const invitePopUp = usePopup(false);
  const confirmationPopup = useConfirmationPopUp<ConfActionData>();
  const editRolesPopup = useEditRolesPopup(ws);
  return { invitePopUp, confirmationPopup, editRolesPopup };
};

type UserID = number;

export const useMangeUsers = (ws: WsName) => {
  const queryClient = useQueryClient();
  const queriesInfo = useManageUsersQuries(ws);
  const states = useManageUsersPageState<UserID>(ws);
  const newUserAdded = () => {
    states.invitePopUp.close();
    queryClient.invalidateQueries(['users', ws]); //expecting to fetch users
  };
  const disableUserMutation = useMutation<
    unknown,
    AxiosError,
    DisableUserMutationInfo
  >(
    ({ ws, user }) => {
      return API.disableUser(ws, user);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['users', ws]); //expecting to fetch users
        states.confirmationPopup.close();
      },
      onError() {
        states.confirmationPopup.confirmationContent &&
          states.confirmationPopup.showErr(
            'Server Error! Please try again',
            states.confirmationPopup.confirmationContent.data,
            surelyDisableUser,
            states.confirmationPopup.close
          );
      },
    }
  );
  const enableUserMutation = useMutation<
    unknown,
    AxiosError,
    DisableUserMutationInfo
  >(
    ({ ws, user }) => {
      return API.enableUser(ws, user);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['users', ws]); //expecting to fetch users
        states.confirmationPopup.close();
      },
      onError() {
        states.confirmationPopup.confirmationContent &&
          states.confirmationPopup.showErr(
            'Server Error! Please try again',
            states.confirmationPopup.confirmationContent.data,
            surelyEnableUser,
            states.confirmationPopup.close
          );
      },
    }
  );
  const removeUserMutation = useMutation<
    unknown,
    AxiosError,
    DisableUserMutationInfo
  >(
    ({ ws, user }) => {
      return API.removeUser(ws, user);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['users', ws]); //expecting to fetch users
        states.confirmationPopup.close();
      },
      onError() {
        states.confirmationPopup.confirmationContent &&
          states.confirmationPopup.showErr(
            'Server Error! Please try again',
            states.confirmationPopup.confirmationContent.data,
            surelyRemoveUser,
            states.confirmationPopup.close
          );
      },
    }
  );
  const surelyDisableUser = (user: number) => {
    disableUserMutation.mutate({ ws, user });
    states.confirmationPopup.showLoading(user);
  };
  const surelyEnableUser = (user: number) => {
    enableUserMutation.mutate({ ws, user });
    states.confirmationPopup.showLoading(user);
  };
  const surelyRemoveUser = (user: number) => {
    removeUserMutation.mutate({ ws, user });
    states.confirmationPopup.showLoading(user);
  };
  const disableUser = (user: User) =>
    states.confirmationPopup.open(
      disableUserConfirmation(
        user.id,
        states.confirmationPopup.close,
        surelyDisableUser
      )
    );

  const enableUser = (user: User) =>
    states.confirmationPopup.open(
      enableUserConfirmation(
        user.id,
        states.confirmationPopup.close,
        surelyEnableUser
      )
    );

  const removeUser = (user: User) =>
    states.confirmationPopup.open(
      removeUserConfirmation(
        user.id,
        states.confirmationPopup.close,
        surelyRemoveUser
      )
    );

  const editRole = (user: User) => {
    states.editRolesPopup.editRole(user);
  };

  const invalidateWsUsers = () => {
    queryClient.invalidateQueries(['users', ws]);
  };

  return {
    ...queriesInfo,
    ...states,
    editRole,
    newUserAdded,
    disableUser,
    enableUser,
    removeUser,
    invalidateWsUsers,
  };
};

export default useMangeUsers;
