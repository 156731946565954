import { DraftJST, ExpressionT } from '../../../Editor/Action'
import { VariableT } from '../../Context'

export enum conditionTYPES {
    SELECT = 'Select Type',
    INTENT = 'Intent',
    ENTITY = 'Entity',
    CONTEXT = 'Variable',
    COMBINATOR = 'COMBINATOR',
    FAQ = 'User Queries',
    NOCONDITION=' No condition required'
}

export enum combinatorValueT {
    AND = 'and',
    OR = 'or'
}

export enum operatorsT {
    IS = 'IS',
    IS_NOT = 'IS_NOT',

    IS_PRESENT = 'IS_PRESENT',

    IS_NOT_PRESENT = 'IS_NOT_PRESENT',

    EQUALS_TEXT = 'equals',
    DOES_NOT_EQUALS_TEXT = 'DOES_NOT_EQUALS_TEXT',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    IS_TRUE = 'IS_TRUE',
    IS_FALSE = 'IS_FALSE',
    EQUALS_NUMBER = 'EQUALS_NUMBER',
    DOES_NOT_EQUALS_NUMBER = 'DOES_NOT_EQUALS_NUMBER',
    CONTAINS = "CONTAINS",
    DOES_NOT_CONTAINS = "DOES_NOT_CONTAINS",
    SELECT = 'SELECT'
}

export interface combinatorConditionT {
    type: conditionTYPES.COMBINATOR
    value: combinatorValueT
}

export interface faqConditionT {
    type: conditionTYPES.FAQ
    queries: string[]
}

export interface selectConditionT {
    type: conditionTYPES.SELECT
}

export interface NoConditionT {
    type: conditionTYPES.NOCONDITION
}
interface entityConditionT {
    type: conditionTYPES.ENTITY
    name: string
    operator: operatorsT
    value: string
}

export interface intentConditionT {
    type: conditionTYPES.INTENT
    name: string
    operator: operatorsT
}

export interface variableConditionT {
    type: conditionTYPES.CONTEXT
    operator: operatorsT
    LHS: VariableT
    RHS: DraftJST | boolean
}

export type conditionT =
    | selectConditionT
    | intentConditionT
    | entityConditionT
    | combinatorConditionT
    | variableConditionT
    | faqConditionT
    | NoConditionT

type IntentExpression = {
    type: 'IntentExpression'
    value: string
}

type EntityExpression = {
    type: 'EntityExpression'
    entity: string
    negation?: boolean
}

type EntityValueExpression = {
    type: 'EntityValueExpression'
    entity: string
    value: string
    negation: boolean
}

interface TextT {
    type: 'text'
    value: string
}
interface PathT {
    type: 'path'
    path: string[]
}
interface EntityVariableInContextT {
    type: 'entity'
    entity_name: string
}

interface TextMatchExpression {
    type: 'TextMatchExpression'
    prompts: string[]
}

interface JoinT {
    type: 'join'
    lhs: RecogniseServerT
    rhs: RecogniseServerT
}

interface ComparisionExpressionT {
    type: 'ComparisionExpression'
    lhs: PathT
    rhs: ExpressionT | boolean
    op: 'equals'
}

export type RecogniseServerT =
    | IntentExpression
    | EntityExpression
    | EntityValueExpression
    | TextT
    | PathT
    | JoinT
    | ComparisionExpressionT
    | EntityVariableInContextT
    | TextMatchExpression
