import { RetryErrorIcon } from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { GreenTickIcon, WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { type } from 'ramda';
import React from 'react';
export interface Root {
  type: string;
  output: Output[];
  httpStatus: number;
}
export interface Resp {
  data: RootResp;
}
export interface RootResp {
  type: string;
  output: Output;
  httpStatus: number;
}
export interface Output {
  isActive: boolean;
  id: string;
  name: string;
  type: string;
  group: string;
  knowledgeBase: KnowledgeBase[];
  redirectURI: string;
}

export interface KnowledgeBase {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  syncHistory: SyncHistory[] | [];
  createdAt: string;
  updatedAt: string;
}
export interface SyncHistory {
  id: string;
  executionId: string;
  status: string;
  knowledgeBaseId: string;
  errorMessage?: string;
  errorCode: any;
  startTime?: string;
  endTime: string | null;
  createdAt: string;
  updatedAt: string;
  metrics: Metrics | null;
}

export interface Metrics {
  DocumentsAdded: string;
  DocumentsFailed: string;
  DocumentsDeleted: string;
  DocumentsScanned: string;
  DocumentsModified: string;
}

export interface Repository {
  domain: string;
  siteUrls: string[];
}
export interface WebsiteRepository {
  domain: string;
  seedUrlConnections:{
  seedUrl: string
  }[]
}

export interface ConfluenceRep {
  hostUrl: string;
  spaceKeys: string[];
}

export interface VivantioRep {
  domain: string;
  published: boolean;
  queueId: number;
  workspaceId:number;
  folders?:{
    name:string;
    value:string
  }[],
  category?:{
    name:string;
    value:string
  }
}

export enum Mode {
  View = 'view',
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  Train = 'train',
  show='show'
}
export enum ConnectionType {
  //url :Connection list
  view = 'view',
  sharepoint = 'sharepoint',
  confluence = 'confluence',
  vivantio = 'vivantio',
  freshservice = 'freshservice',
  amazons3 = 'amazons3',
  sharepointcustom="sharepointcustom",
  googleDrive="googleDrive"
}
export type SharepointPopupT = {
  pageState: Mode;
  PageStateUpdate: () => void;
  workspace: string;
  Connection: string;
  exCatArray: KnowledgeBase[];
  selectedID: string;
};
export interface StateT {
  kBName: string;
  connectionID: string;
  newConnectionID: string;
  domainName: string;
  disableSave: boolean;
  showConnectError: boolean;
  connectLoader: boolean;
  eyeIcon: boolean;
  updatePassWord: string;
  queueID: number;
  redirectURI: string;
  workspaceId:number;
  category:{
    name:string,
    value:string
  };
  folder:{
    name:string,
    value:string
  }[]
}
export const initialState = {
  kBName: '',
  connectionID: '',
  newConnectionID: '',
  domainName: '',
  disableSave: false,
  showConnectError: false,
  connectLoader: false,
  eyeIcon: false,
  updatePassWord: '',
  queueID: 0,
  redirectURI: '',
  workspaceId:0,
  category:{
    name:"",
    value:""
  },
  folder:[]
};
export interface EmailValidT {
  valid: boolean;
  message: any;
}
export interface CredSharePointT {
  userName: string;
  password: string;
}
export interface CredConfluenceT {
  username: string;
  password: string;
}
export interface S3CredT {
  accessKeyId: string;
  secretAccessKey: string;
}
export interface additionalPropertiesT {
  pageTitleFilterRegEx: string[];
  inclusionFilePath: string[];
  inclusionFileTypePatterns: string[];
  inclusionFileNamePatterns: string[];
}
export interface UpdateResponse {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  credentialId: string;
  repository: Repository;
  credential: sharepointCred;
  createdAt: string;
  updatedAt: string;
  additionalProperties: additionalPropertiesT;
}
type SharePointSite = {
    siteName: string;
    additionalProperties: {
        pageTitleFilterRegEx: string[];
        inclusionFilePath: string[];
        inclusionFileTypePatterns: string[];
        inclusionFileNamePatterns: string[];
    };
};

type CustomCredential = {
    authType: string; // e.g., "OAuth2_RefreshToken"
    result: boolean;
    refreshToken: string;
    tenantId: string;
    clientId: string;
    clientSecret: string;
};

type SharePointCustomInput = {
    type: string; // e.g., "SHAREPOINT_CUSTOM"
    sites: SharePointSite[];
};
export interface CustomResponse {
  id: string;
    name: string;
    status: string;
    type: string;
    workspaceId: string;
    knowledgeBaseCategoryId: string;
    credentialId: string;
    input: SharePointCustomInput;
    credential: CustomCredential;
    createdAt: string;
    updatedAt: string; 
}
export interface UpdatewebsiteResponse {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  credentialId: string;
  repository: WebsiteRepository;
  credential: sharepointCred;
  createdAt: string;
  updatedAt: string;
  additionalProperties: additionalPropertiesT;
}
export interface UpdatewebsiteResponse {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  credentialId: string;
  repository: WebsiteRepository;
  credential: sharepointCred;
  createdAt: string;
  updatedAt: string;
  additionalProperties:additionalPropertiesT
}
export interface ConfResponse {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  credentialId: string;
  repository: ConfluenceRep;
  credential: ConfluenceCred;
  createdAt: string;
  updatedAt: string;
  input: VivantioRep;
}
export type S3Input= {
  type: string;
  bucketNames: string[];
  filePaths: string[];
  fileTypes: string[];
  regularExpressionPatterns: string[];
};
export interface S3Response {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  credentialId: string;
  input:S3Input;
  credential: {
    accessKeyId: string;
  };
  createdAt: string;
  updatedAt: string;
}
export interface SyncOutput {
  type: string;
  output: SyncHistoryOutput;
  httpStatus: number;
}

// export interface mutatewithID{

// }
export interface SyncHistoryOutput {
  id: string;
  name: string;
  status: string;
  type: string;
  credentialId: string;
  syncHistory: SyncHistory[];
  createdAt: string;
  updatedAt: string;
}

export interface Credential {
  userName: string;
  password: string;
}
export interface ConfluenceCred {
  username: string;
  password: string;
  domain?: string;
}

export interface sharepointCred {
  clientId?: string;
  tenantId?: string;
  clientSecret?: string;
  userName?: string;
  password?: string;
  result?: boolean;
}
export const sharepointCredData = {
  clientId: '',
  tenantId: '',
  clientSecret: '',
  userName: '',
  password: '',
};

export const initialCredData = {
  userName: '',
  password: '',
};

export const confluenceCredData = {
  username: '',
  password: '',
};
export const S3CredData = {
  accessKeyId: '',
  secretAccessKey: '',
};

export const IsValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
// export const statusButton = (status: string) => {
//   switch (status) {
//     case 'SYNCING':
//       return 'Training';
//     case 'SYNCING_INDEXING':
//       return 'Training';
//     case 'FAILED':
//       return 'Retry';
//     case 'DELETION_INITIATED':
//       return 'Deleting';
//     case 'INCOMPLETE':
//       return 'Retry';
//     default:
//       return 'Train';
//   }
// };
// export const statusIcon = (status: string) => {
//   switch (status) {
//     case 'FAILED':
//       return <WarningAlertIcon />;
//     case 'SUCCEEDED':
//       return <GreenTickIcon />;
//     case 'SYNCING':
//       return <img src={LoadingSvgImage} />;
//     case 'SYNCING_INDEXING':
//       return <img src={LoadingSvgImage} />;
//     case 'INCOMPLETE':
//       return <RetryErrorIcon />;
//     default:
//       return <img src={LoadingSvgImage} />;
//   }
// };

// export const statusCheck = (status: string) => {
//   switch (status) {
//     case 'FAILED':
//       return 'Failed';
//     case 'SUCCEEDED':
//       return 'Trained';
//     case 'SYNCING':
//       return 'Training';
//     case 'SYNCING_INDEXING':
//       return 'Training';
//     case 'INCOMPLETE':
//       return 'Incomplete';
//     default:
//       return 'Trained';
//   }
// };

export const statusButton = (status: string) => {
  switch (status) {
    case 'SYNCING':
      return 'Training';
    case 'SYNCING_INDEXING':
      return 'Training';
    case 'FAILED':
      return 'Retry';
    case 'DELETION_INITIATED':
      return 'Deleting';
    case 'INCOMPLETE':
      return 'Retry';
    default:
      return 'Train';
  }
};
export const statusIcon = (status: string) => {
  switch (status) {
    case 'FAILED':
      return <WarningAlertIcon />;
    case 'SUCCEEDED':
      return <GreenTickIcon />;
    case 'SYNCING':
      return <img src={LoadingSvgImage} />;
    case 'SYNCING_INDEXING':
      return <img src={LoadingSvgImage} />;
    case 'INCOMPLETE':
      return <RetryErrorIcon />;
    default:
      return <img src={LoadingSvgImage} />;
  }
};

export const statusCheck = (status: string) => {
  switch (status) {
    case 'FAILED':
      return 'Failed';
    case 'SUCCEEDED':
      return 'Trained';
    case 'SYNCING':
      return 'Training';
    case 'SYNCING_INDEXING':
      return 'Training';
    case 'INCOMPLETE':
      return 'Incomplete';
    default:
      return 'Trained';
  }
};
export const knowledgeCategory: Root ={
    "type": "success",
    "output": [
        {
            "isActive": true,
            "id": "09f9de68-fd45-48b2-8d67-38746a0fc82e",
            "name": "Website",
            "type": "WEBCRAWLER",
            "group": "WEBSITE",
            "knowledgeBase": [
                {
                    "id": "bb1abfbf-5489-4698-9258-7c960e6ed790",
                    "name": "sabari-test-6-nov",
                    "status": "CREATED",
                    "type": "WEBCRAWLER",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "09f9de68-fd45-48b2-8d67-38746a0fc82e",
                    "syncHistory": [],
                    "createdAt": "2024-05-08T04:54:41.885Z",
                    "updatedAt": "2024-05-08T04:54:53.443Z"
                },
                {
                    "id": "c3e701ba-b429-49b4-acc2-d791a62fcbe5",
                    "name": "sabari-test-6-nov",
                    "status": "CREATED",
                    "type": "WEBCRAWLER",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "09f9de68-fd45-48b2-8d67-38746a0fc82e",
                    "syncHistory": [],
                    "createdAt": "2024-05-08T04:48:12.776Z",
                    "updatedAt": "2024-05-08T04:48:24.382Z"
                }
            ]
        },
        {
            "isActive": true,
            "id": "5af773c3-34e5-4911-9974-0f0d3d8693c1",
            "name": "Vivantio",
            "type": "VIVANTIO",
            "group": "EXTERNAL",
            "knowledgeBase": []
        },
        {
            "isActive": true,
            "id": "6cb28b02-30ba-47f8-9523-dbd416aa2e92",
            "name": "Custom",
            "type": "CUSTOM",
            "group": "EXTERNAL",
            "knowledgeBase": []
        },
        {
            "isActive": true,
            "id": "74e86975-f21e-428b-91ff-6b665a36e1b7",
            "name": "Microsoft Sharepoint",
            "type": "SHAREPOINT_ONLINE",
            "group": "EXTERNAL",
            "knowledgeBase": [
                {
                    "id": "505c0dee-d419-42b9-a791-e8ffe65d5702",
                    "name": "Test-1",
                    "status": "CREATED",
                    "type": "SHAREPOINT_ONLINE",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "74e86975-f21e-428b-91ff-6b665a36e1b7",
                    "syncHistory": [],
                    "createdAt": "2024-05-08T04:59:26.524Z",
                    "updatedAt": "2024-05-08T04:59:37.327Z"
                }
            ]
        },
        {
            "isActive": true,
            "id": "75578aef-df4b-4779-be1d-63a79ec53c54",
            "name": "Confluence",
            "type": "CONFLUENCE_ONLINE",
            "group": "EXTERNAL",
            "knowledgeBase": []
        },
        {
            "isActive": true,
            "id": "8005a726-b932-4673-a261-505c49ed8507",
            "name": "Freshservice",
            "type": "FRESHSERVICE",
            "group": "EXTERNAL",
            "knowledgeBase": []
        },
        {
            "isActive": true,
            "id": "8005a726-b932-4673-a261-505c49ed8507",
            "name": "googleDrive",
            "type": "GOOGLE_DRIVE",
            "group": "EXTERNAL",
            "knowledgeBase": []
        },
        {
            "isActive": true,
            "id": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
            "name": "S3",
            "type": "S3",
            "group": "EXTERNAL",
            "knowledgeBase": [
                {
                    "id": "337f5c63-540d-4cbb-b064-8b11cd641f96",
                    "name": "Test-S3",
                    "status": "CREATION_INITIATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [],
                    "createdAt": "2024-05-10T04:43:30.115Z",
                    "updatedAt": "2024-05-10T04:43:30.115Z"
                },
                {
                    "id": "3ea4fb39-f85c-4bfd-9752-8d7b99a2498b",
                    "name": "Test-S3",
                    "status": "CREATION_INITIATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [],
                    "createdAt": "2024-05-11T11:48:40.552Z",
                    "updatedAt": "2024-05-11T11:48:40.552Z"
                },
                {
                    "id": "54d46dc8-9747-4820-8d17-000ede7b7f9f",
                    "name": "Test-S3",
                    "status": "CREATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [],
                    "createdAt": "2024-05-10T04:55:04.284Z",
                    "updatedAt": "2024-05-10T04:55:14.875Z"
                },
                {
                    "id": "6880f067-a3fc-4219-af17-477a5e48118f",
                    "name": "Test-S3",
                    "status": "CREATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [
                        {
                            "id": "4daba221-dad3-4e52-815e-8111c759b502",
                            "executionId": "64def8ac-a73b-4068-a57b-365e5ea7800d",
                            "status": "SUCCEEDED",
                            "knowledgeBaseId": "6880f067-a3fc-4219-af17-477a5e48118f",
                            "errorMessage": "Unexpected message handler failure: {\"error\":{},\"type\":\"Domain\",\"_tag\":\"AWSKendraDataSourceDocumenrError\"}",
                            "errorCode": "Error",
                            "startTime": "2024-05-12T03:11:06.395Z",
                            "endTime": "2024-05-12T03:15:34.252Z",
                            "createdAt": "2024-05-12T03:11:06.868Z",
                            "updatedAt": "2024-05-12T03:16:07.404Z",
                            "metrics": {
                                "DocumentsAdded": "25",
                                "DocumentsFailed": "0",
                                "DocumentsDeleted": "0",
                                "DocumentsModified": "0"
                            }
                        }
                    ],
                    "createdAt": "2024-05-12T03:09:20.570Z",
                    "updatedAt": "2024-05-12T03:09:48.130Z"
                },
                {
                    "id": "68a8fda1-b767-447d-bad3-ea708a5217b7",
                    "name": "Test-S3",
                    "status": "CREATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [
                        {
                            "id": "5aee9a91-01d3-41d7-a87b-0a1d4e5ff9de",
                            "executionId": "3c0e9425-2bc1-4c12-b2d5-ebc70b0841e6",
                            "status": "SYNCING",
                            "knowledgeBaseId": "68a8fda1-b767-447d-bad3-ea708a5217b7",
                            "errorMessage": null,
                            "errorCode": null,
                            "startTime": null,
                            "endTime": null,
                            "createdAt": "2024-05-11T11:46:55.538Z",
                            "updatedAt": "2024-05-11T11:46:55.538Z",
                            "metrics": null
                        },
                        {
                            "id": "08ef1278-ad26-4234-b78e-e929997ed2e4",
                            "executionId": "4be1531f-6c69-462e-a7e8-118ad6dba0e8",
                            "status": "FAILED",
                            "knowledgeBaseId": "68a8fda1-b767-447d-bad3-ea708a5217b7",
                            "errorMessage": "Unexpected message handler failure: unable to access this AWS S3",
                            "errorCode": "ExternalConnectorAPIError",
                            "startTime": "2024-05-11T10:24:14.801Z",
                            "endTime": null,
                            "createdAt": "2024-05-11T10:24:15.232Z",
                            "updatedAt": "2024-05-11T10:43:36.928Z",
                            "metrics": {
                                "DocumentsAdded": "0",
                                "DocumentsFailed": "0",
                                "DocumentsDeleted": "0",
                                "DocumentsModified": "0"
                            }
                        }
                    ],
                    "createdAt": "2024-05-11T10:12:56.815Z",
                    "updatedAt": "2024-05-11T10:13:07.610Z"
                },
                {
                    "id": "7d064372-f323-4674-821b-0101e410b981",
                    "name": "Test-S3",
                    "status": "CREATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [
                        {
                            "id": "447799c4-0eb5-42d0-b6b0-c01e794dee8f",
                            "executionId": "375f6fb1-a3ff-4ae6-95b2-373b4991997c",
                            "status": "SUCCEEDED",
                            "knowledgeBaseId": "7d064372-f323-4674-821b-0101e410b981",
                            "errorMessage": "Unexpected message handler failure: {\"error\":{},\"type\":\"Domain\",\"_tag\":\"AWSKendraDataSourceDocumenrError\"}",
                            "errorCode": "Error",
                            "startTime": "2024-05-11T14:52:52.508Z",
                            "endTime": "2024-05-11T15:14:07.986Z",
                            "createdAt": "2024-05-11T14:52:52.914Z",
                            "updatedAt": "2024-05-11T15:14:10.709Z",
                            "metrics": {
                                "DocumentsAdded": "11",
                                "DocumentsFailed": "0",
                                "DocumentsDeleted": "1",
                                "DocumentsModified": "13"
                            }
                        },
                        {
                            "id": "2f43ddd9-64c6-4dd2-913e-14ce511f8276",
                            "executionId": "9833b418-c73f-45f2-a19a-ef640ce1e117",
                            "status": "INCOMPLETE",
                            "knowledgeBaseId": "7d064372-f323-4674-821b-0101e410b981",
                            "errorMessage": "Unexpected message handler failure: {\"error\":{},\"type\":\"Domain\",\"_tag\":\"AWSKendraDataSourceDocumenrError\"}",
                            "errorCode": "Error",
                            "startTime": "2024-05-11T14:33:08.905Z",
                            "endTime": "2024-05-11T14:39:44.023Z",
                            "createdAt": "2024-05-11T14:33:09.253Z",
                            "updatedAt": "2024-05-11T14:40:11.301Z",
                            "metrics": {
                                "DocumentsAdded": "0",
                                "DocumentsFailed": "11",
                                "DocumentsDeleted": "0",
                                "DocumentsModified": "14"
                            }
                        }
                    ],
                    "createdAt": "2024-05-11T11:50:34.713Z",
                    "updatedAt": "2024-05-11T11:50:45.173Z"
                },
                {
                    "id": "dadb4dcd-4a08-4a7a-add4-5cab88c4f97f",
                    "name": "Test-S3",
                    "status": "CREATION_INITIATED",
                    "type": "S3",
                    "workspaceId": "9076ff5f-6ea3-4f0b-8869-6068f4629b12",
                    "knowledgeBaseCategoryId": "cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa",
                    "syncHistory": [],
                    "createdAt": "2024-05-10T04:37:00.355Z",
                    "updatedAt": "2024-05-10T04:37:00.355Z"
                }
            ]
        }
    ],
    "httpStatus": 200
}

// export const updateResponse = [
//   {
//     id: '1a3382b7-2571-4964-8b95-d8f5809d1aa5',
//     name: 'test-enrichment',
//     status: 'CREATED',
//     type: 'SHAREPOINT_ONLINE',
//     workspaceId: '31706143-2f1a-4618-a982-58013d4db449',
//     knowledgeBaseCategoryId: '068dc706-5532-4b01-9284-8208f2706e07',
//     credentialId: '13563f08-6717-4ce7-9459-12b76706cf51',
//     createdAt: '2023-07-11T08:21:17.911Z',
//     updatedAt: '2023-07-11T08:22:09.373Z',
//     repository: {
//       domain: 'workativ',
//       siteUrls: [
//         'https://workativ.sharepoint.com/sites/Hellos',
//         'https://workativ.sharepoint.com/sites/TestTeamSite',
//       ],
//     },
//     credential: {
//       userName: 'arunadevi.r@workativ.com',
//       password: 'dhsdfjsgkdlfjg',
//     },
//   },
// ];
export const mockSharepointCustom={
    "type": "success",
    "output": {
        "id": "86b10c17-4eb3-4eaf-92da-67f7686fc3f8",
        "name": "Test",
        "status": "CREATED",
        "type": "SHAREPOINT_CUSTOM",
        "workspaceId": "d37f7188-3652-47f9-97cc-eb964c23bab7",
        "knowledgeBaseCategoryId": "6a9272e9-4701-42b7-ae8f-fdef5f7e4e7f",
        "credentialId": "463da619-eaae-4fa6-b8e5-de1a385c7024",
        "input": {
            "type": "SHAREPOINT_CUSTOM",
            "sites": [
                {
                    "siteName": "Communication site",
                    "additionalProperties": {
                        "pageTitleFilterRegEx": [
                            "Benefits.aspx"
                        ],
                        "inclusionFilePath": [
                            "example/"
                        ],
                        "inclusionFileTypePatterns": [],
                        "inclusionFileNamePatterns": [
                            "docx"
                        ]
                    }
                },
                {
                    "siteName": "Help-Workativ",
                    "additionalProperties": {
                        "pageTitleFilterRegEx": [],
                        "inclusionFilePath": [],
                        "inclusionFileTypePatterns": [],
                        "inclusionFileNamePatterns": []
                    }
                }
            ]
        },
        "credential": {
            "authType": "OAuth2_RefreshToken",
            "result": true,
            "refreshToken": "0.AT0AdgCP_r-NhEafc7OJwzshWFAwuNhwEHhCgZ2s6qEj5AY9AA8.AgABAwEAAADW6jl31mB3T7ugrWTT8pFeAwDs_wUA9P-G-7vaeeUxQX8ntN6pnh0t4hUf5MeIGBo5v8BhowUJAg2sXEOLOO5H6Jpp1Ls8OfYacGu_mFWQUytnDewZZ5pdR-rHTlMmxFXRdMgOXW4lnW04l1CZy5YLzf93wkDJCfTR0lDhXrLbOWQ15zglClGKkoIo4ibkE90_8Jyl1tyvMmlrcxWdGaWD0hqN8WqItqSpwvoISN59zKz9Nr7zkR-qY5f3a6PjBUf5tvZEMN1JPBwxyscu62Gx2r7LOh0EL2a42Y2QO2QRxBIdHuQjFQBRKFTvBlPOanA2gA4XUp-v3Br3nRq18QZBb0oxTs_OSTQ1DU3gljkN1E2b11dP1AccfQui9zkQPEImg8YSFlP1zU7VeqCL3QLGG5AjtBNlNIlNv67LS33MPX9kW_-CC3mj8cwIMYoXKPAXhSqvKksoJ-OIfOt1FbulTxY03eLdge6T2sNnVu9WCQRiteXamPCRTRmDF6eFdfmxHbxpL-80BH-PK2yf_XRwVwdDQFbp0r8Q-846tnCzfI1S2UqIhNc8rwWHSVfewoc8W1yvBMIfCKCgNd5O7ExGeqPOMpsiijPETWiufhDS94dUDbx4IYwmRLixaf-MR43ySgDr1ArKKfL8aDp9k8cuEz2d-nl2nOKkiENuAOApBqn2W3fRWqpFX4MRtT0VHrtf5cMD1ubBybVwkc3WtrRuTeqq4fFXDuRM3BqH92sCRbzmDc-h3FwXtuVuqN5aZsDiosCqJn4D8iuYYQ",
            "tenantId": "fe8f0076-8dbf-4684-9f73-b389c33b2158",
            "clientId": "d8b83050-1070-4278-819d-aceaa123e406",
            "clientSecret": "nOm8Q~RgoUOgiFNb4exPRfDnSXoPSlAe4mNXzaPP"
        },
        "createdAt": "2024-10-21T13:20:06.482Z",
        "updatedAt": "2024-10-21T13:22:21.186Z"
    },
    "httpStatus": 200
}
export const mockGoogleConn ={
    "type": "success",
    "output": {
        "id": "b441828c-8204-4ff0-afdd-5ae5281339e3",
        "name": "Test-Drive",
        "status": "CREATED",
        "type": "GOOGLE_DRIVE",
        "workspaceId": "2dd88f85-116b-48ef-b395-23958b414ef9",
        "knowledgeBaseCategoryId": "999893d5-aaca-4dba-817d-942496599561",
        "credentialId": "779953b2-3d9b-4aec-b92e-37d8c32a1864",
        "repository": {
            "isCrawlComment": true,
            "isCrawlMyDriveAndSharedWithMe": true,
            "isCrawlSharedDrives": true
        },
        "additionalProperties": {
            "isCrawlAcl": true,
            "maxFileSizeInMegaBytes": "20",
            "includeTargetAudienceGroup": [],
            "includeUserAccounts": [],
            "excludeUserAccounts": [],
            "includeSharedDrives": [],
            "excludeSharedDrives": ["sadfjsdjfsd","slkjflaskhflk"],
            "includeMimeTypes": [],
            "excludeMimeTypes": ["alkdjfklafda","skjhalhdf"],
            "inclusionFilePathFilter": ["skjfaskfdasd","saljflasjfdlsad"],
            "exclusionFilePathFilter": [
                "Legal/"
            ],
            "inclusionFileTypePatterns": [],
            "exclusionFileTypePatterns": [],
            "inclusionFileNamePatterns": [
                "Ayehu NG Trial Installation Pre-Requisites"
            ],
            "exclusionFileNamePatterns": []
        },
        "credential": {
            "result": false,
            "clientId": "722678106622-istimmte26mv4e8vojlakr53hk3b3qh4.apps.googleusercontent.com",
            "clientSecret": "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"
        },
        "createdAt": "2024-11-12T11:40:34.091Z",
        "updatedAt": "2024-11-12T11:41:08.016Z"
    },
    "httpStatus": 200
}