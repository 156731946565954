/*eslint-disable*/
import * as R from 'ramda';

import { findActionNode } from '@/Assistant/utils';
import logo from '@/common/images/logo.png';
import userIcon from '@/common/images/user_white.svg';

import { DialogueContextT } from '../DialogueCrew/Context';
import { findPathViaRes } from '../DialogueCrew/Variable';
import { ActionInputsListT, InputFieldT, NodeT } from '@/Flows/canvas/types';
import { ContextPopupStateT } from '@/Flows/canvas/ForEachPopup';
import { convertInputToExpression } from '@/Flows/canvas/utils';

//Server expression Types
export type ExpressionT = TextT | PathT | JoinT | StyleT | LINKT | NewlineT;
// export type ExpressionT = TextT | PathT | JoinT | StyleT | ORDEREDLISTT | UNORDEREDLISTT

export enum ExpressionTypeE {
  TEXT = 'text',
  PATH = 'path',
  JOIN = 'join',
  ENTITY = 'entity',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  LINK = 'hyper_link',
  NEWLINE = "newline",
  MENTION="mention"
  // UNORDEREDLIST = 'unorder_list',
  // ORDEREDLIST = 'order_list'
}

// export interface ORDEREDLISTT {
//     type: ExpressionTypeE.ORDEREDLIST
//     value: ExpressionT[]
// }

// export interface UNORDEREDLISTT {
//     type: ExpressionTypeE.UNORDEREDLIST
//     value: ExpressionT[]
// }

export interface LINKT {
  type: ExpressionTypeE.LINK;
  link: TextT;
  value: TextT;
}

export interface NewlineT {
  type: ExpressionTypeE.NEWLINE;
  value: string;
}

export interface TextT {
  type: ExpressionTypeE.TEXT;
  value: string;
}

export type StyleT = BOLDT | ITALICT | UNDERLINET;

export interface BOLDT {
  type: ExpressionTypeE.BOLD;
  value: TextT | StyleT;
}
export interface ITALICT {
  type: ExpressionTypeE.ITALIC;
  value: TextT | StyleT;
}
export interface UNDERLINET {
  type: ExpressionTypeE.UNDERLINE;
  value: TextT | StyleT;
}

interface PathT {
  type: ExpressionTypeE.PATH;
  path: string[];
}
interface EntityExpressionT {
  type: ExpressionTypeE.PATH;
  entity_name: string;
}

interface JoinT {
  type: ExpressionTypeE.JOIN;
  lhs: ExpressionT;
  rhs: ExpressionT;
}

//DraftJS expression Types
export interface DraftJST {
  blocks: blocks[];
  entityMap: EntityMapT;
}

interface EntityValueDataT {
  value: string;
  visible: string;
  path: string[];
  icon: string;
  dataType: 'string';
}

interface EntityValueT {
  type: 'VARIABLE';
  mutability: 'IMMUTABLE';
  data: EntityValueDataT;
}

interface DialogEntityValueDataT {
  dummypath: string;
  name: string[];
  icon: string;
  dataType: 'string';
}

interface DialogEntityValueT {
  type: 'ENTITY-VARIABLE';
  mutability: 'IMMUTABLE';
  data: DialogEntityValueDataT;
}

interface LinkValueT {
  type: 'LINK';
  mutability: 'MUTABLE';
  data: {
    // href: string
    // rel: string
    // target: string
    // title: string
    linkText: string;
    target: string;
    url: string;
  };
}


interface EmojiValueT {
  type: 'emoji';
  mutability: 'IMMUTABLE';
  data: {
    emojiUnicode: string;
  };
}
interface MentionDateValueT{
  mention:{
    name:string
  }
}

interface MentionValueT {
  type: 'mention';
  mutability: 'SEGMENTED';
  data: MentionDateValueT
}

// TODO : Reasearch on all possible types from DraftJs
interface EntityMapT {
  [id: string]: EntityValueT | DialogEntityValueT | LinkValueT | EmojiValueT |MentionValueT;
}

export interface blocks {
  key: string;
  text: string;
  type: 'unstyled';
  depth: 0;
  inlineStyleRanges: [];
  entityRanges: Array<entityRangesT>;
}

interface entityRangesT {
  offset: number;
  length: number;
  key: number | null;
}

type StyleEntity = {
  offset: number;
  length: number;
  style: 'BOLD' | 'ITALIC' | 'UNDERLINE';
};

const isStyleExpressionType = (val: string) =>
  val === ExpressionTypeE.BOLD ||
  val === ExpressionTypeE.ITALIC ||
  val === ExpressionTypeE.UNDERLINE;

const isStyleExpression = R.compose<ExpressionT, string, boolean>(
  isStyleExpressionType,
  R.prop('type')
);

const isEntityExpressiosn = R.compose<ExpressionT, string, boolean>(
  R.equals('entity'),
  R.prop('type')
);

const isTextExpression = R.compose<ExpressionT, string, boolean>(
  (type:string)=>type=='text'||type=='newline',
  R.prop('type')
);

const isLinkExpression = R.compose<ExpressionT, string, boolean>(
  R.equals('hyper_link'),
  R.prop('type')
);

const isPathExpression = R.compose<ExpressionT, string, boolean>(
  R.equals('path'),
  R.prop('type')
);

export const isPathWorkflowExpression = R.compose<any, string, boolean>(
  R.equals('workflow_variable'),
  R.prop('type')
);

export const workflowAndDialogconverterToEditor = (
  expression: ExpressionT,
  combiningResponseWithTriggerOutput: ActionInputsListT[],
    triggerNodeOutput: NodeT,
    input: InputFieldT,
    exptraProps: {
        inputs: InputFieldT[]
        contextPopupValues: ContextPopupStateT
        setContextPopupValues: any
    }
): DraftJST => {
  // console.log("Called ::: workflowAndDialogconverterToEditor", expression,combiningResponseWithTriggerOutput,exptraProps)
  const generateEntityRange = (
    block: blocks,
    exp: string,
    totalEmojis: number,
    key:number
  ): entityRangesT => { 
    // console.log("generateEntityRange", block , exp , totalEmojis)
    return ({
    offset: block.text.length - exp.length - totalEmojis,
    // offset: block.text.length,
    length: exp.length,
    key: key,
  });
}
  const Emojiregex = /\p{Extended_Pictographic}/u;

  const draftJsFromExpression = R.curry((draft: DraftJST, exp: ExpressionT): DraftJST => {
    return R.cond<any, DraftJST>([
      [
        isStyleExpression,
        R.compose<StyleT, DraftJST>((val) => {
          // R.compose<StyleT | ORDEREDLISTT | UNORDEREDLISTT, DraftJST>(val => {
          // console.log("EXPRESSION :: STYLE", val)
          // const lastRange = R.last(draft.blocks[draft.blocks.length-1].entityRanges);
          const index = draft.blocks.length-1

          const totalEmojisProcessed = draft.blocks[index].entityRanges.filter(
            (e) => draft.entityMap[`${e.key}`].type === 'emoji'
          ).length;
          const generateStyles = (val: StyleT) => {
            function getResult(obj: StyleT | TextT, result: any): any {
              if (!obj) return;

              const { value, ...rest } = obj;

              if (typeof value == 'string') {
                result.push({ value });
                return result;
              } else {
                result.push({ ...rest });
                return getResult(value, result);
              }
            }

            const values: any[] = getResult(val, []);

            const textValue: string = R.last(values).value;

            const reducedValue: StyleEntity[] = values
              .slice(0, values.length - 1)
              .reduce((acc: StyleEntity[], currValue) => {
                return [
                  ...acc,
                  {
                    offset: draft.blocks[index].text.length - totalEmojisProcessed,
                    length: textValue.length,
                    style:
                      currValue.type === 'bold'
                        ? 'BOLD'
                        : currValue.type === 'italic'
                        ? 'ITALIC'
                        : 'UNDERLINE',
                  },
                ];
              }, []);
            return { styles: reducedValue, text: textValue };
          };

          const styleRanges = generateStyles(val);

          // console.log("DOING ::: styleRanges",styleRanges)

          const textDraft = R.assocPath(
            ['blocks', index, 'text'],
            R.concat(draft.blocks[index].text, styleRanges.text),
            draft
          );

          const toReturn = R.assocPath(
            ['blocks', index, 'inlineStyleRanges'],
            R.concat(draft.blocks[index].inlineStyleRanges, styleRanges.styles),
            textDraft
          ) as DraftJST;
          // console.log("EXPRESSION :: toReturns :: styles",toReturn,textDraft)

          return toReturn;
          // }
        }),
      ],
      [
        isTextExpression,
        R.compose<TextT, string, string, DraftJST>(
          (val: string) => {
            const index = draft.blocks.length-1
            const textExpression = (exp as TextT).value;
            // console.log("EXPRESSION :: TEXT", textExpression)
            if (Emojiregex.test(textExpression)) {
              // console.log("EXPRESSION :: emoji", textExpression)
              const entityRangesInBlock = draft.blocks[index].entityRanges;

              const lastRange = R.last(entityRangesInBlock);
              const totalEmojisProcessed =
                entityRangesInBlock.filter(
                  (e) => draft.entityMap[`${e.key}`].type === 'emoji'
                ).length + 1;

              const entityRanges =
                lastRange !== undefined
                  ? [
                      ...entityRangesInBlock,
                      {
                        offset: val.length - 1 + totalEmojisProcessed * -1,
                        length: 1,
                        key: lastRange.key != null ? lastRange.key + 1 : 1,
                      },
                    ]
                  : [
                      {
                        offset: val.length - 1 + totalEmojisProcessed * -1,
                        length: 1,
                        key: 0,
                      },
                    ];
              const updatedEntityDraft: DraftJST = {
                blocks: draft.blocks.map((d,i) => i==index?({
                  ...d,
                  entityRanges,
                }):d),
                entityMap: {
                  ...draft.entityMap,
                  [`${
                    lastRange
                      ? lastRange.key != null
                        ? lastRange.key + 1
                        : 1
                      : 0
                  }`]: {
                    type: 'emoji',
                    data: { emojiUnicode: textExpression } as any,
                    mutability: 'IMMUTABLE',
                  },
                },
              };

              const toReturn = R.assocPath(
                ['blocks', index, 'text'],
                val,
                updatedEntityDraft
              ) as DraftJST;
              // console.log("EXPRESSION :: toReturn emoji" ,toREturn )
              return toReturn;
            }

            // No Emoji Text
            const toReturn = R.assocPath(
              ['blocks', index, 'text'],
              val,
              draft
            ) as DraftJST;
            return toReturn;
          },
          (val)=>{
            if(exp.type==='newline'){
              draft.blocks.push({
                key: Math.random().toString(36).substring(2, 12),
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              } as blocks)
              return ""
            }else{
              return R.concat(draft.blocks[draft.blocks.length-1].text,val)
            }
          },
          R.prop('value')
        ),
      ],
      [
        isLinkExpression,
        (linkExpression: LINKT) =>
          R.compose<LINKT, TextT, string, DraftJST, DraftJST, DraftJST>(
            (draftTemp) => {
              const index = draft.blocks.length-1

              const entityRangesInBlock = draftTemp.blocks[index].entityRanges;
              const entityKey = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );
              const linkEntity = {
                type: 'LINK',
                mutability: 'MUTABLE',
                data: {
                  url: linkExpression.link.value,
                  target: '_blank',
                  linkText: linkExpression.value.value,
                },
              };
              return R.assocPath(
                ['entityMap', entityKey],
                linkEntity,
                draftTemp
              );
            },
            (draftTemp) => {
              const index = draft.blocks.length-1

              const entityRangesInBlock = draftTemp.blocks[index].entityRanges;
              const totalEmojisProcessed = entityRangesInBlock.filter(
                (e) => draftTemp.entityMap[`${e.key}`].type === 'emoji'
              ).length;

              const entityInBlock = draftTemp.blocks.reduce((acc,d)=>[...acc,...d.entityRanges],[] as entityRangesT[]);

              return R.assocPath(
                ['blocks', index, 'entityRanges'],
                R.concat(entityRangesInBlock, [
                  generateEntityRange(
                    draftTemp.blocks[index],
                    linkExpression.value.value as string,
                    totalEmojisProcessed,
                    entityInBlock.length
                  ),
                ]),
                draftTemp
              );
            },
            (val) => R.assocPath(['blocks', draft.blocks.length-1, 'text'], val, draft),
            (str) => R.concat(draft.blocks[draft.blocks.length-1].text)(str.value),
            R.prop('value')
          )(linkExpression),
      ],
      [
        isPathWorkflowExpression,
        (ex: PathT) =>{

              const dr = draft
              const workflowVariable:any = convertInputToExpression(ex.path as any,combiningResponseWithTriggerOutput,triggerNodeOutput,input,exptraProps as any)
              // console.log("isPathWorkflowExpression",ex,workflowVariable)

              const concatText = R.concat(draft.blocks[draft.blocks.length-1].text)(R.last(workflowVariable.path) as string)
              const draftWithTextAdded =  R.assocPath(['blocks', draft.blocks.length-1, 'text'], concatText, draft)

              const totalEmojisProcessed =
               draftWithTextAdded.blocks[draft.blocks.length-1].entityRanges.filter(
                  (e) => draftWithTextAdded.entityMap[`${e.key}`].type === 'emoji'
                ).length;
                const entityInBlock = dr.blocks.reduce((acc,d)=>[...acc,...d.entityRanges],[] as entityRangesT[]);

              const draftWithEntityRange = R.assocPath(
                ['blocks', draft.blocks.length-1, 'entityRanges'],
                R.concat(draftWithTextAdded.blocks[draft.blocks.length-1].entityRanges, [
                  generateEntityRange(
                    draftWithTextAdded.blocks[draft.blocks.length-1],
                    R.last(workflowVariable.path) as string,
                    totalEmojisProcessed,
                    entityInBlock.length
                  ),
                ]),
                draftWithTextAdded
              );

              const obj = {
                type: 'VARIABLE',
                mutability: 'IMMUTABLE',
                data: {
                  dataType: 'string',
                  path: workflowVariable.tooltipForVariable,
                  icon: workflowVariable.app.icon,
                  variable: R.last(workflowVariable.path),
                  id: ex.path,
                  examples:workflowVariable.examples,
                  value:R.last(workflowVariable.path)
                },
              }; 

              const entityRangesInBlock = draftWithEntityRange.blocks[draft.blocks.length-1].entityRanges;

              const entityKey = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );

              const toReturn = R.assocPath(['entityMap', entityKey], obj, draftWithEntityRange);
              // console.log("to return ",toReturn, draftWithTextAdded , draftWithEntityRange)
                            
              // console.log("isPathWorkflowExpression entityRange",concatText,draft.blocks[0].text , R.last(workflowVariable.path),textAdded)
              return toReturn
            },
      ],
      [
        isPathExpression,
        (ex: PathT) =>
          R.compose<
            PathT,
            string[],
            string,
            string,
            DraftJST,
            DraftJST,
            DraftJST
          >(
            (dr) => {

              const entityRangesInBlock = dr.blocks[dr.blocks.length-1].entityRanges;

              const entityKey = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );

              const path = ["User Information",...ex.path];
          
              const obj = {
                  type: 'VARIABLE',
                  mutability: 'IMMUTABLE',
                  data: {
                    value: R.last(ex.path),
                    visible: R.last(ex.path),
                    path: path,
                    id: ex.path,
                    icon: "",
                    dataType: 'string',
                  },
                };  

              return R.assocPath(['entityMap', entityKey], obj, dr);
            },
            (dr) => {
              console.log(dr,"draft")

              const totalEmojisProcessed =
                dr.blocks[dr.blocks.length-1].entityRanges.filter(
                  (e) => dr.entityMap[`${e.key}`].type === 'emoji'
                ).length;

              const entityInBlock = dr.blocks.reduce((acc,d)=>[...acc,...d.entityRanges],[] as entityRangesT[]);

              return R.assocPath(
                ['blocks', dr.blocks.length-1, 'entityRanges'],
                R.concat(dr.blocks[dr.blocks.length-1].entityRanges, [
                  generateEntityRange(
                    dr.blocks[dr.blocks.length-1],
                    R.last(ex.path) as string,
                    totalEmojisProcessed,
                    entityInBlock.length
                  ),
                ]),
                dr
              );
            },
            (val) => R.assocPath(['blocks', draft.blocks.length-1, 'text'], val, draft),
            (str) => R.concat(draft.blocks[draft.blocks.length-1].text)(str),
            R.last,
            R.prop('path')
          )(ex),
      ],
      [
        R.T,
        (exp: JoinT) =>
          draftJsFromExpression(draftJsFromExpression(draft, R.prop('lhs', exp)), R.prop('rhs', exp)),
      ],
    ])(exp);
  });

  const draftSturctureGenerated = draftJsFromExpression(
    {
      blocks: [
        {
          key: Math.random().toString(36).substring(2, 12),
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        } as blocks,
      ],
      entityMap: {},
    },
    expression
  );

  // console.log("DOING :: generatoed Expression" , draftSturctureGenerated)

  return draftSturctureGenerated;
};

export const converterToEditor = (
  expression: ExpressionT,
  dialogContext?: DialogueContextT,
  assistantID?: string
): DraftJST => {
  const nodeVariables =
    dialogContext && assistantID && dialogContext.dialogueContext[assistantID];
  const assistantName = (dialogContext &&
    dialogContext.assistant.name) as string;

  const generateEntityRange = (
    block: blocks,
    exp: string,
    totalEmojis: number
  ): entityRangesT => ({
    offset: block.text.length - exp.length - totalEmojis,
    length: exp.length,
    key: block.entityRanges.length + 1,
  });
  const Emojiregex = /\p{Extended_Pictographic}/u;

  const draftJsFromExpression = R.curry((draft: DraftJST, exp: ExpressionT): DraftJST => {
    return R.cond<any, DraftJST>([
      [
        isStyleExpression,
        R.compose<StyleT, DraftJST>((val) => {
          // R.compose<StyleT | ORDEREDLISTT | UNORDEREDLISTT, DraftJST>(val => {
          // console.log("EXPRESSION :: STYLE", val)
          // const lastRange = R.last(draft.blocks[0].entityRanges);
          const totalEmojisProcessed = draft.blocks[0].entityRanges.filter(
            (e) => draft.entityMap[`${e.key}`].type === 'emoji'
          ).length;
          const generateStyles = (val: StyleT) => {
            function getResult(obj: StyleT | TextT, result: any): any {
              if (!obj) return;

              const { value, ...rest } = obj;

              if (typeof value == 'string') {
                result.push({ value });
                return result;
              } else {
                result.push({ ...rest });
                return getResult(value, result);
              }
            }

            const values: any[] = getResult(val, []);

            const textValue: string = R.last(values).value;

            const reducedValue: StyleEntity[] = values
              .slice(0, values.length - 1)
              .reduce((acc: StyleEntity[], currValue) => {
                return [
                  ...acc,
                  {
                    offset: draft.blocks[0].text.length - totalEmojisProcessed,
                    length: textValue.length,
                    style:
                      currValue.type === 'bold'
                        ? 'BOLD'
                        : currValue.type === 'italic'
                        ? 'ITALIC'
                        : 'UNDERLINE',
                  },
                ];
              }, []);
            return { styles: reducedValue, text: textValue };
          };

          const styleRanges = generateStyles(val);

          // console.log("DOING ::: styleRanges",styleRanges)

          const textDraft = R.assocPath(
            ['blocks', 0, 'text'],
            R.concat(draft.blocks[0].text, styleRanges.text),
            draft
          );

          const toReturn = R.assocPath(
            ['blocks', 0, 'inlineStyleRanges'],
            R.concat(draft.blocks[0].inlineStyleRanges, styleRanges.styles),
            textDraft
          ) as DraftJST;
          // console.log("EXPRESSION :: toReturns :: styles",toReturn,textDraft)

          return toReturn;
          // }
        }),
      ],
      [
        isTextExpression,
        R.compose<TextT, string, string, DraftJST>(
          (val: string) => {
            const textExpression = (exp as TextT).value;
            // console.log("EXPRESSION :: TEXT", textExpression)
            if (Emojiregex.test(textExpression)) {
              // console.log("EXPRESSION :: emoji", textExpression)
              const entityRangesInBlock = draft.blocks[0].entityRanges;

              const lastRange = R.last(entityRangesInBlock);
              const totalEmojisProcessed =
                entityRangesInBlock.filter(
                  (e) => draft.entityMap[`${e.key}`].type === 'emoji'
                ).length + 1;

              const entityRanges =
                lastRange !== undefined
                  ? [
                      ...entityRangesInBlock,
                      {
                        offset: val.length - 1 + totalEmojisProcessed * -1,
                        length: 1,
                        key: lastRange.key != null ? lastRange.key + 1 : 1,
                      },
                    ]
                  : [
                      {
                        offset: val.length - 1 + totalEmojisProcessed * -1,
                        length: 1,
                        key: 0,
                      },
                    ];
              const updatedEntityDraft: DraftJST = {
                blocks: draft.blocks.map((d) => ({
                  ...d,
                  entityRanges,
                })),
                entityMap: {
                  ...draft.entityMap,
                  [`${
                    lastRange
                      ? lastRange.key != null
                        ? lastRange.key + 1
                        : 1
                      : 0
                  }`]: {
                    type: 'emoji',
                    data: { emojiUnicode: textExpression },
                    mutability: 'IMMUTABLE',
                  },
                },
              };

              const toReturn = R.assocPath(
                ['blocks', 0, 'text'],
                val,
                updatedEntityDraft
              ) as DraftJST;
              // console.log("EXPRESSION :: toReturn emoji" ,toREturn )
              return toReturn;
            }

            // No Emoji Text
            const toReturn = R.assocPath(
              ['blocks', 0, 'text'],
              val,
              draft
            ) as DraftJST;
            // console.log("EXPRESSION :: toReturn text" ,toReturn )
            return toReturn;
          },
          R.concat(draft.blocks[0].text),
          R.prop('value')
        ),
      ],
      [
        isEntityExpressiosn,
        (expression: EntityExpressionT) =>
          R.compose<
            EntityExpressionT,
            string,
            string,
            DraftJST,
            DraftJST,
            DraftJST
          >(
            (draftTemp) => {
              const entityRangesInBlock = draftTemp.blocks[0].entityRanges;
              const key = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );
              const variableEntity = {
                type: 'ENTITY-VARIABLE',
                mutability: 'IMMUTABLE',
                data: {
                  name: expression.entity_name,
                  dummypath: [assistantName, 'Entity', expression.entity_name],
                  icon: userIcon,
                  dataType: 'string',
                },
              };
              return R.assocPath(['entityMap', key], variableEntity, draftTemp);
            },
            (draftTemp) => {
              const entityRangesInBlock = draftTemp.blocks[0].entityRanges;
              const totalEmojisProcessed = entityRangesInBlock.filter(
                (e) => draftTemp.entityMap[`${e.key}`].type === 'emoji'
              ).length;
              return R.assocPath(
                ['blocks', 0, 'entityRanges'],
                R.concat(entityRangesInBlock, [
                  generateEntityRange(
                    draftTemp.blocks[0],
                    expression.entity_name,
                    totalEmojisProcessed
                  ),
                ]),
                draftTemp
              );
            },
            (val) => R.assocPath(['blocks', 0, 'text'], val, draft),
            (str) => R.concat(draft.blocks[0].text)(str),
            R.prop('entity_name')
          )(expression),
      ],
      [
        isLinkExpression,
        (linkExpression: LINKT) =>
          R.compose<LINKT, TextT, string, DraftJST, DraftJST, DraftJST>(
            (draftTemp) => {
              const entityRangesInBlock = draftTemp.blocks[0].entityRanges;
              const entityKey = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );
              const linkEntity = {
                type: 'LINK',
                mutability: 'MUTABLE',
                data: {
                  url: linkExpression.link.value,
                  target: '_blank',
                  linkText: linkExpression.value.value,
                },
              };
              return R.assocPath(
                ['entityMap', entityKey],
                linkEntity,
                draftTemp
              );
            },
            (draftTemp) => {
              const entityRangesInBlock = draftTemp.blocks[0].entityRanges;
              const totalEmojisProcessed = entityRangesInBlock.filter(
                (e) => draftTemp.entityMap[`${e.key}`].type === 'emoji'
              ).length;
              return R.assocPath(
                ['blocks', 0, 'entityRanges'],
                R.concat(entityRangesInBlock, [
                  generateEntityRange(
                    draftTemp.blocks[0],
                    linkExpression.value.value as string,
                    totalEmojisProcessed
                  ),
                ]),
                draftTemp
              );
            },
            (val) => R.assocPath(['blocks', 0, 'text'], val, draft),
            (str) => R.concat(draft.blocks[0].text)(str.value),
            R.prop('value')
          )(linkExpression),
      ],
      [
        isPathExpression,
        (ex: PathT) =>
          R.compose<
            PathT,
            string[],
            string,
            string,
            DraftJST,
            DraftJST,
            DraftJST
          >(
            (dr) => {
              const entityRangesInBlock = dr.blocks[0].entityRanges;

              const entityKey = R.toString(
                entityRangesInBlock[entityRangesInBlock.length - 1].key
              );

              const hasVariable =
                nodeVariables &&
                (R.flatten<any>(nodeVariables).find(
                  (v) => v.variable === R.last(ex.path)
                ));
              const path =
                hasVariable && dialogContext
                  ? [
                      assistantName,
                      ...findPathViaRes(
                        dialogContext.workspaceDescendents,
                        hasVariable.node.id,
                        []
                      ),
                      hasVariable.node.type === 'ask_a_question'
                        ? 'Ask a question'
                        : hasVariable.node.type === 'file_upload'
                        ? 'Upload Attachment'
                        : hasVariable.node.type === 'context_variable'
                        ? 'Custom Variables'
                        : 'Verify user details',
                      hasVariable.node.type === 'context_variable'
                        ? hasVariable.variable
                        : hasVariable.node.variable,
                    ]
                  : ex.path.indexOf('Assistant') === -1
                  ? [assistantName, ...ex.path]
                  : ex.path;
              // const path =
              //     hasVariable && dialogContext
              //         ? [
              //               'Assistant',
              //               ...findPathViaRes(
              //                   dialogContext.workspaceDescendents,
              //                   hasVariable.node.id,
              //                   []
              //               ),
              //               hasVariable.node.type === 'ask_a_question'
              //                   ? 'Ask a question'
              //                   : hasVariable.node.type == 'file_upload'
              //                   ? 'Upload Attachment'
              //                   : hasVariable.node.type == 'context_variable'
              //                   ? 'Custom Variables'
              //                   : 'Verify user details',
              //               hasVariable.node.variable
              //           ]
              //         : ex.path.indexOf('Assistant') == -1
              //         ? ['Assistant', ...ex.path]
              //         : ex.path

              let dummypath = path;
              let obj, draft;
              if (dialogContext && ex.path.includes('errorContext')) {
                const automationContext =
                  dialogContext.workspaceDescendents.reduce(
                    (acc: any, va: any) => {
                      const result =
                        va.type === 'if_node'
                          ? va.responses.filter(
                              (r: any) => r.output_variable === ex.path[0]
                            )[0]
                          : null;

                      if (result) {
                        return result;
                      }
                      return acc;
                    },
                    null
                  );
                const auto =
                  automationContext &&
                  ((dialogContext.automationContext as any)[
                    (automationContext as any).parent
                  ]
                    ? (
                        Object.values(
                          (dialogContext.automationContext as any)[
                            (automationContext as any).parent
                          ]
                        ) as any
                      )[0]
                    : Object.values(dialogContext.automationContext).reduce(
                        (acc, v: any) => {
                          const auto = v[(automationContext as any).id];
                          if (auto) {
                            return auto;
                          }
                          return acc;
                        },
                        null
                      ));
                const action_id =
                  auto &&
                  findActionNode(
                    ex.path.slice(-2, -1)[0],
                    auto.custom_json.children[0].children[0]
                  );
                const errors =
                  action_id &&
                  dialogContext.actionMixture[
                    action_id.value.meta_data.action_id
                  ].errors[`${R.last(ex.path)}`];

                dummypath = errors
                  ? ex.path.map((p: any, i: number) =>
                      i + 1 == ex.path.length ? errors.message : p
                    )
                  : path;

                obj = {
                  type: 'VARIABLE',
                  mutability: 'IMMUTABLE',
                  data: {
                    value: errors ? errors.message : R.last(ex.path),
                    visible: errors ? errors.message : R.last(ex.path),
                    path: ex.path,
                    dummypath:
                      automationContext && errors
                        ? [
                            dialogContext.assistant.name,
                            ...findPathViaRes(
                              dialogContext.workspaceDescendents,
                              automationContext.id,
                              []
                            ),
                            automationContext.automation_name,
                          ].concat(errors.message)
                        : dummypath,
                    icon: logo,
                    dataType: 'string',
                  },
                };

                draft = errors
                  ? {
                      ...dr,
                      blocks: dr.blocks.map((b) => {
                        const ranges = b.entityRanges.reduce((acc, val) => {
                          const isExist =
                            val.offset == b.text.indexOf(errors.code);
                          return isExist
                            ? ([
                                ...acc,
                                { ...val, length: errors.message.length },
                              ] as entityRangesT[])
                            : [...acc, val];
                        }, [] as entityRangesT[]);

                        return {
                          ...b,
                          text: b.text.replace(errors.code, errors.message),
                          entityRanges: ranges,
                        };
                      }),
                    }
                  : dr;
              } else if (
                dialogContext &&
                ex.path.includes('executionContext')
              ) {
                const automationContext =
                  dialogContext.workspaceDescendents.reduce(
                    (acc: any, va: any) => {
                      const result =
                        va.type === 'if_node'
                          ? va.responses.filter(
                              (r: any) => r.output_variable == ex.path[0]
                            )[0]
                          : null;

                      if (result) {
                        return result;
                      }
                      return acc;
                    },
                    null
                  );

                obj = {
                  type: 'VARIABLE',
                  mutability: 'IMMUTABLE',
                  data: {
                    value: R.last(ex.path),
                    visible: R.last(ex.path),
                    path: ex.path,
                    dummypath: automationContext
                      ? [
                          dialogContext.assistant.name,
                          ...findPathViaRes(
                            dialogContext.workspaceDescendents,
                            automationContext.id,
                            []
                          ),
                          automationContext.automation_name,
                        ].concat(R.last(ex.path))
                      : dummypath,
                    icon: logo,
                    dataType: 'string',
                  },
                };
                draft = dr;
              } else {
                obj = {
                  type: 'VARIABLE',
                  mutability: 'IMMUTABLE',
                  data: {
                    value: R.last(ex.path),
                    visible: R.last(ex.path),
                    path: ex.path,
                    dummypath: dummypath,
                    icon: logo,
                    dataType: 'string',
                  },
                };
                draft = dr;
              }

              return R.assocPath(['entityMap', entityKey], obj, draft);
            },
            (dr) => {
              const totalEmojisProcessed =
                dr.blocks[0].entityRanges.filter(
                  (e) => dr.entityMap[`${e.key}`].type === 'emoji'
                ).length;
              return R.assocPath(
                ['blocks', 0, 'entityRanges'],
                R.concat(dr.blocks[0].entityRanges, [
                  generateEntityRange(
                    dr.blocks[0],
                    R.last(ex.path) as string,
                    totalEmojisProcessed
                  ),
                ]),
                dr
              );
            },
            (val) => R.assocPath(['blocks', 0, 'text'], val, draft),
            (str) => R.concat(draft.blocks[0].text)(str),
            R.last,
            R.prop('path')
          )(ex),
      ],
      [
        R.T,
        (exp: JoinT) =>
          draftJsFromExpression(draftJsFromExpression(draft, R.prop('lhs', exp)), R.prop('rhs', exp)),
      ],
    ])(exp);
  });

  const draftSturctureGenerated = draftJsFromExpression(
    {
      blocks: [
        {
          key: Math.random().toString(36).substring(2, 12),
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        } as blocks,
      ],
      entityMap: {},
    },
    expression
  );

  // console.log("DOING :: generatoed Expression" , expression)

  return draftSturctureGenerated;
};

const isobject = function (x: unknown) {
  return Object.prototype.toString.call(x) === '[object Object]';
};

export const getkeys = function (obj: object, prefix?: string) {
  const keys = Object.keys(obj);

  prefix = prefix ? prefix + '.' : '';

  return keys.reduce(function (result: string[], key) {
    if (isobject(obj[key])) {
      result = result.concat(getkeys(obj[key], prefix + key));
    } else {
      result.push(prefix + key);
    }

    return result;
  }, []);
};

export function updateNewLineValueUsingLens(
  p: string[],
  toObject: any,
  updatingValue: any,
  updatingList?: boolean
) {
  const paths = p.reduce(function (a: string, b: string) {
    return a.split('.').length > b.split('.').length &&
      !a.split('.').includes('lhs')
      ? a
      : b;
  }) as string;
  const newPaths = paths.split('.').reduce((acc: string[], val) => {
    if (val === 'rhs') {
      return [...acc, val];
    }
    return acc;
  }, []);

  //   const pathLens = R.lensPath(R.dropLast(1, paths.split('.')))
  const pathLens = R.lensPath(newPaths);

  let value;
  if (
    toObject.type === 'bold' ||
    toObject.type === 'italic' ||
    toObject.type === 'underline'
  ) {
    value = {
      lhs: toObject,
      type: 'join',
      rhs: {
        lhs: { type: updatingList?'newline':"text", value: '\n' },
        rhs: updatingValue,
        type: 'join',
      },
    };
  } else {
    const data = R.view(pathLens, toObject) as any;
    
    value = R.set(
      R.lensPath(newPaths),
      {
            lhs: data,
            type: 'join',
            rhs: {
              lhs: { type: updatingList?'newline':"text", value: '\n' },
              rhs: updatingValue,
              type: 'join',
            },
          },

      toObject
    ) as any;
  }
  return value;
}

export function convertToOne(draft: DraftJST,forWorkflow?:boolean) {
  return draft.blocks.reduce(
    (acc, val, currentIndex) => {
      if (currentIndex == 0) {
        return {
          res: {
            ...converterToServer2({
              blocks: [draft.blocks[currentIndex]],

              entityMap: draft.entityMap,
            },forWorkflow),
          },
        };
      }

      const keys = getkeys(acc.res as any);

      const newObj = {
        res: updateNewLineValueUsingLens(keys, acc.res, {
          ...converterToServer2({
            blocks: [draft.blocks[currentIndex]],

            entityMap: draft.entityMap,
          },forWorkflow),
        }, forWorkflow),
      };

      return newObj;
    },

    { res: {} }
  );
}

const sortWithOffset = R.sortBy(R.prop('offset'));

const findeLastRHS = (obj: any): any => {
  // console.log(obj, 'obj');
  if (obj.type !== ExpressionTypeE.JOIN) {
    return obj.rhs ? obj.rhs : obj;
  }
  return findeLastRHS(obj.rhs);
};

// const updateLastRHS = (obj: any, updateValue: any): any => {
//     console.log('DOING ::: updateLastRHS', obj, updateValue)
//     if (obj.type !== ExpressionTypeE.JOIN) {
//         const cloned = R.clone(obj)

//         obj = {
//             type: ExpressionTypeE.JOIN,
//             lhs: cloned,
//             rhs: updateValue
//         }
//         console.log('DOING ::: updateLastRHS return', obj)
//         return
//     }
//     return updateLastRHS(obj.rhs, updateValue)
// }

const updateLastRHS = (
  obj: any,
  updateValue: any,
  updatingSame?: string
): any => {
  const lastRHS = findeLastRHS(obj);
  const cloned = R.clone(lastRHS);

  if (updatingSame) {
    lastRHS.type = updatingSame;
    lastRHS.value = cloned;
  } else {
    lastRHS.type = ExpressionTypeE.JOIN;
    lastRHS.lhs = cloned;
    lastRHS.rhs = updateValue;
    delete lastRHS.value;
  }
  return obj;
};

export const converterToServer = (meta: DraftJST,forWorkflow?:boolean): ExpressionT => {
  // return meta.blocks.length > 1
  //     ? (convertToOne(meta).res as ExpressionT)
  //     : meta.blocks[0].type != 'unstyled'
  //     ? {
  //           type:
  //               meta.blocks[0].type == 'unordered-list-item'
  //                   ? ExpressionTypeE.UNORDEREDLIST
  //                   : ExpressionTypeE.ORDEREDLIST,
  //           value: [converterToServer2(meta)]
  //       }
  //     : converterToServer2(meta)

  return meta.blocks.length > 1
    ? (convertToOne(meta,forWorkflow).res as ExpressionT)
    : converterToServer2(meta,forWorkflow);
};

export const converterToServer2 = (meta: DraftJST,forWorkflow?:boolean): ExpressionT => {
  const lenmapper = (
    entity: entityRangesT[],
    sentence: string
  ): entityRangesT[] => {
    const totalEmojisProcessed = Object.values(meta.entityMap).filter(
      (x) => x.type === 'emoji'
    ).length;

    console.log("lenmapper :::" ,sentence, totalEmojisProcessed,)

    // const sentenceLengthWithEmojis = sentence.length + totalEmojisProcessed

    const toReturn = entity.reduce(
      (
        accum: entityRangesT[],
        value: entityRangesT,
        index: number,
        arr: entityRangesT[]
      ): entityRangesT[] => {
        console.log('DOING :: lenmapper 0',value, index, arr.length, arr);
        if (index === 0 && arr.length === 1) {
          // single context handled
          if (value.offset === 0 && sentence.length === value.length) {
            // only one context variable
            const toReturn = [value];
            console.log('DOING :: lenmapper 1.1', toReturn);
            return toReturn;
          } else if (value.offset === 0 && sentence.length > value.length) {
            // one context variable after some text
            const isEmoji = meta.entityMap[`${value.key}`].type === 'emoji';
            const toReturn = [
              isEmoji ? { ...value, length: value.length + 1 } : value,
              {
                offset: isEmoji ? value.length + 1 : value.length,
                length:
                  sentence.length - (isEmoji ? value.length + 1 : value.length),
                key: null,
              },
            ];
            console.log('DOING :: lenmapper 1.2', toReturn);
            return toReturn;
          } else if (
            value.offset !== 0 &&
            sentence.length === value.offset + value.length
          ) {
            // one context variable before some text
            const toReturn = [
              { offset: 0, length: value.offset, key: null },
              value,
            ];
            console.log('DOING :: lenmapper 1.3', toReturn);
            return toReturn;
          } else {
            // one context variable with dual side some text
            const isEmoji = meta.entityMap[`${value.key}`].type === 'emoji';
            const valueToSet = isEmoji
              ? { ...value, length: value.length + 1 }
              : value;

            const toReturn = [
              { offset: 0, length: value.offset, key: null },
              valueToSet,
              {
                offset: valueToSet.offset + valueToSet.length,
                length: sentence.length - (value.offset + valueToSet.length),
                key: null,
              },
            ];
            console.log('DOING :: lenmapper 1.4', toReturn);
            return toReturn;
          }
        } else if (index === arr.length - 1) {
          // last context variable handled
          const lastEntityItemFromOriginal = arr[index - 1];
          const isEmojiEntity = meta.entityMap[`${value.key}`].type === 'emoji';
          const lastFromModifiedEntity = R.last(accum);

          if (lastFromModifiedEntity === undefined) {
            throw new Error('Impossible scenario');
          }

          const textBeforeEntity = {
            offset:
              lastFromModifiedEntity.offset + lastFromModifiedEntity.length,
            length:
              value.offset -
              (lastEntityItemFromOriginal.offset +
                lastEntityItemFromOriginal.length),
            key: null,
          };

          const entityObject = isEmojiEntity
            ? {
                ...value,
                length: value.length + 1,
                offset: textBeforeEntity.offset + textBeforeEntity.length,
              }
            : {
                ...value,
                offset: textBeforeEntity.offset + textBeforeEntity.length,
              };

          if (entityObject.offset + entityObject.length === sentence.length) {
            const toReturn = filterEmptyEntities(
              accum.concat([textBeforeEntity, entityObject])
            );
            console.log('DOING :: lenmapper 2.1', toReturn);
            return toReturn;
          } else {
            const textAfterEntity = {
              offset: entityObject.offset + entityObject.length,
              length:
                sentence.length - (entityObject.offset + entityObject.length),
              key: null,
            };

            const toReturn = filterEmptyEntities(
              accum.concat([textBeforeEntity, entityObject, textAfterEntity])
            );
            // console.log(
            //   'DOING :: lenmapper 2.2',

            //   lastFromModifiedEntity,
            //   lastEntityItemFromOriginal,
            //   lastEntityItemFromOriginal.offset +
            //     lastEntityItemFromOriginal.length,
            //   value,
            //   textBeforeEntity,
            //   entityObject,
            //   textAfterEntity,
            //   toReturn
            // );

            return toReturn;
          }
        } else if (index === 0) {
          const isEmoji = meta.entityMap[`${value.key}`].type === 'emoji';

          const emojiEntity = isEmoji
            ? { ...value, length: value.length + 1 }
            : value;
          // first context variable handled
          if (value.offset === 0) {
            // return accum.concat([emojiEntity]);
            return [emojiEntity];
          } else {
            const textBeforeEntity = {
              offset: 0,
              length: value.offset,
              key: null,
            };

            // const toReturn =  accum.concat([
            //   textBeforeEntity,
            //   emojiEntity
            // ]);

            const toReturn = [textBeforeEntity, emojiEntity];

            return toReturn;
          }
        } else {
          // other stuffs

          const previousItemFromOriginalEntityList = arr[index - 1];

          const isEmojiEntity = meta.entityMap[`${value.key}`].type === 'emoji';

          const lastItemFromModifiedEntityList = R.last(accum);

          if (lastItemFromModifiedEntityList === undefined) {
            throw new Error('Impossible scenario');
          }

          const textBeforeEntity = {
            offset:
              lastItemFromModifiedEntityList.offset +
              lastItemFromModifiedEntityList.length,
            length:
              value.offset -
              (previousItemFromOriginalEntityList.offset +
                previousItemFromOriginalEntityList.length),
            key: null,
          };

          const actualEntity = isEmojiEntity
            ? {
                ...value,
                length: value.length + 1,
                offset: textBeforeEntity.offset + textBeforeEntity.length,
              }
            : {
                ...value,
                offset: textBeforeEntity.offset + textBeforeEntity.length,
              };
          const toReturn = accum.concat([textBeforeEntity, actualEntity]);

          return toReturn;
        }
      },
      []
    );

    return toReturn;
  };

  const getString = R.curry(
    (value: string, start: number, length: number): string =>
      R.slice(start, start + length, value)
  );

  const expressionConverter = (
    entity: entityRangesT[],
    sentence: string,
    entityMap: EntityMapT
  ): any[] => {
    // any poda kudathu ...! ExpressionT[] varanum yen error adikkuthu :(

    // console.log("DOING ::: expressionConverter :: 1", entity,sentence,entityMap)

    const emojiIndexList = (
      entity.filter((e) => e.key != null) as {
        offset: number;
        length: number;
        key: number;
      }[]
    ).reduce((accum: number[], val) => {
      const valueIsEmoji = entityMap[val.key].type === 'emoji';

      return valueIsEmoji ? [...accum, val.offset] : accum;
    }, []);

    // const updatedEmojiIndexList = emojiIndexList.map((v, i) => v + i);

    const newStyles = inlineStyleRanges.map((s) => {
      // const noOfEmojisBeforStyleOffset = emojiIndexList.filter(
      //   (e) => s.offset > e
      // );

      const newStartingIndex = emojiIndexList.reduce((a, c) => {
        if (c < a) {
          return a + 1;
        }
        return a;
      }, s.offset);

      const currentEntityRange = Array.from(
        Array(s.length),
        (_, i) => i + newStartingIndex
      );
      const noOfEmojisInCurrentRange = emojiIndexList.filter((e) =>
        currentEntityRange.includes(e)
      );

      const toReturn = {
        ...s,
        offset: newStartingIndex,
        length: s.length + noOfEmojisInCurrentRange.length,
      };

      // console.log(
      //   ':: newStyles ::',
      //   emojiIndexList,
      //   currentEntityRange,
      //   noOfEmojisInCurrentRange,
      //   noOfEmojisBeforStyleOffset,
      //   s,
      //   toReturn
      // );

      return toReturn;
    });

    //  console.log("DOING :: expressionConverter :: 2", newStyles,entity)

    const toReturn = entity
      // .filter(e => e.key != null)
      .map((value) => {
        if (value.key !== null) {
          const key = value.key;
          const entityMapData = entityMap[key];
          console.log(entityMapData,'singleSessionData1')

          if (entityMapData && entityMapData.type === 'LINK') {
            return {
              type: ExpressionTypeE.LINK,
              link: {
                type: ExpressionTypeE.TEXT,
                value: entityMapData.data.url,
              },
              value: {
                type: ExpressionTypeE.TEXT,
                value: entityMapData.data.linkText,
              },
            };
          }

          if (entityMapData && entityMapData.type === 'VARIABLE') {
            return {
              type: ExpressionTypeE.PATH,
              path: entityMapData.data.path,
            };
          } else if (
            entityMapData &&
            entityMapData.type === 'ENTITY-VARIABLE'
          ) {
            return {
              type: ExpressionTypeE.ENTITY,
              entity_name: entityMapData.data.name,
            };
          } else if (entityMapData && entityMapData.type === 'emoji') {
            // console.log(sentence, entity, key, entityMapData)
            return {
              type: ExpressionTypeE.TEXT,
              value: entityMapData.data.emojiUnicode,
            };
          } else if(entityMapData && entityMapData.type === 'mention'){
            console.log(sentence, entity, key, entityMapData,'singleSessionData1')
            return {
              type: ExpressionTypeE.MENTION,
              value: entityMapData.data.mention.name,
            };
          }
          return {
            type: ExpressionTypeE.PATH,
            path: undefined,
          };
        } else {
          if (inlineStyleRanges.length === 0) {
            return {
              type: ExpressionTypeE.TEXT,
              value: getString(sentence, value.offset, value.length),
            };
          } else {
            // to handle empty string or style range

            // const textToProcess = getString(sentence, value.offset, value.length)

            // const stylesWithModifiedOffset = sortWithOffset(
            //     newStyles.splice(
            //         0,
            //         newStyles.filter(r => r.offset + r.length <= value.offset + value.length).length
            //     )
            // ).map((r, _i, arr) => {
            //     const modifiedOffset = { ...r, offset: r.offset - value.offset }
            //     return modifiedOffset
            // })

            // const stylesWithEmptyRange = sortWithOffset(
            //     generateCompleteStyleRange(stylesWithModifiedOffset, value.length)
            // )
            // console.log(
            //     'DOING :: ENTITY',
            //     textToProcess,
            //     inlineStyleRanges,
            //     newStyles,
            //     newStyles.filter(r => r.offset + r.length <= value.offset + value.length).length,
            //     newStyles.splice(
            //         0,
            //         newStyles.filter(r => r.offset + r.length <= value.offset + value.length).length
            //     ),
            //     stylesWithModifiedOffset,
            //     stylesWithEmptyRange,
            //     value
            // )
            // return reducedText(textToProcess, stylesWithEmptyRange)

            const textToProcess = getString(
              sentence,
              value.offset,
              value.length
            );
            const textRange = R.range(
              value.offset,
              value.offset + value.length
            );
            const stylesToProcess = newStyles.filter((styleBlock) => {
              const styleRange = R.range(
                styleBlock.offset,
                styleBlock.offset + styleBlock.length
              );

              const commonRange = R.intersection(styleRange, textRange);
              // console.log(
              //   'style range',
              //   styleRange,
              //   styleBlock,
              //   textToProcess,
              //   value,
              //   commonRange,
              //   commonRange.length > 0
              // );
              return commonRange.length > 0;
              // return textRange.includes(styleBlock.offset);
              // return styleBlock.offset >= value.offset &&
              // value.offset + value.length <= styleBlock.offset + styleBlock.length && styleBlock.offset < value.offset + value.length
            });

            const stylesWithModifiedOffset = sortWithOffset(
              stylesToProcess
            ).map((r, _i, arr) => {
              const styleRange = R.range(r.offset, r.offset + r.length);

              const commonRange = R.intersection(styleRange, textRange);
              const modifiedOffset = {
                ...r,
                offset: commonRange[0] - value.offset,
                length: commonRange.length,
              };
              // console.log('DOING:: TEST', stylesToProcess,r, value,modifiedOffset);
              return modifiedOffset;
            });

            const textLengthToProcess = Math.abs(value.length);

            const completeStyleRange = generateCompleteStyleRange(
              stylesWithModifiedOffset,
              textLengthToProcess
            );

            const stylesWithEmptyRange = sortWithOffset(completeStyleRange);

            const toReturn = reduceTextBlock(
              textToProcess,
              stylesWithEmptyRange
            );

            // console.log(
            //   'DOING ::: expressions debug',
            //   value,
            //   textToProcess,
            //   textRange,
            //   newStyles,
            //   stylesToProcess,
            //   completeStyleRange,
            //   stylesWithEmptyRange,
            //   toReturn
            // );

            return toReturn;

            // return newStyles.some(r => r.offset == value.offset)
            //     ? reducedText(textToProcess, stylesWithEmptyRange)
            // : {
            //       type: ExpressionTypeE.TEXT,
            //       value: getString(sentence, value.offset, value.length)
            //   }
          }
        }
      });

    // console.log("DOING :: expressionConverter :: 3", toReturn)
    return toReturn;
  };

  const workflowExpressionConverter = (
    entity: entityRangesT[],
    sentence: string,
    entityMap: EntityMapT
  ): any[] => {
    // any poda kudathu ...! ExpressionT[] varanum yen error adikkuthu :(

    // console.log("DOING ::: expressionConverter :: 1", entity,sentence,entityMap)

    const emojiIndexList = (
      entity.filter((e) => e.key != null) as {
        offset: number;
        length: number;
        key: number;
      }[]
    ).reduce((accum: number[], val) => {
      const valueIsEmoji = entityMap[val.key].type === 'emoji';

      return valueIsEmoji ? [...accum, val.offset] : accum;
    }, []);

    // const updatedEmojiIndexList = emojiIndexList.map((v, i) => v + i);

    const newStyles = inlineStyleRanges.map((s) => {

      const newStartingIndex = emojiIndexList.reduce((a, c) => {
        if (c < a) {
          return a + 1;
        }
        return a;
      }, s.offset);

      const currentEntityRange = Array.from(
        Array(s.length),
        (_, i) => i + newStartingIndex
      );
      const noOfEmojisInCurrentRange = emojiIndexList.filter((e) =>
        currentEntityRange.includes(e)
      );

      const toReturn = {
        ...s,
        offset: newStartingIndex,
        length: s.length + noOfEmojisInCurrentRange.length,
      };

      return toReturn;
    });

    const toReturn = entity
      // .filter(e => e.key != null)
      .map((value) => {
        if (value.key !== null) {
          const key = value.key;
          const entityMapData = entityMap[key];
          console.log(entityMapData,'lenmapper')
          if (entityMapData && entityMapData.type === 'LINK') {
            return {
              type: ExpressionTypeE.LINK,
              link: {
                type: ExpressionTypeE.TEXT,
                value: entityMapData.data.url,
              },
              value: {
                type: ExpressionTypeE.TEXT,
                value: entityMapData.data.linkText,
              },
            };
          }

          if (entityMapData && entityMapData.type === 'VARIABLE') {
            console.log(entityMapData)
            const eMap = entityMapData.data as any
            const isSysytemVariable = Array.isArray(eMap.id)
            return isSysytemVariable?{
              type: ExpressionTypeE.PATH,
              path: eMap.id,
            }:{
              type: "workflow_variable",
              path: eMap.id.includes("${") ?eMap.id:"${" + eMap.id + "}",
            };
          }else if (entityMapData && entityMapData.type === 'emoji') {
            // console.log(sentence, entity, key, entityMapData)
            return {
              type: ExpressionTypeE.TEXT,
              value: entityMapData.data.emojiUnicode,
            };
          }
          return {
            type: ExpressionTypeE.PATH,
            path: undefined,
          };
        } else {
          if (inlineStyleRanges.length === 0) {
            return {
              type: ExpressionTypeE.TEXT,
              value: getString(sentence, value.offset, value.length),
            };
          } else {
            const textToProcess = getString(
              sentence,
              value.offset,
              value.length
            );
            const textRange = R.range(
              value.offset,
              value.offset + value.length
            );
            const stylesToProcess = newStyles.filter((styleBlock) => {
              const styleRange = R.range(
                styleBlock.offset,
                styleBlock.offset + styleBlock.length
              );

              const commonRange = R.intersection(styleRange, textRange);
             
              return commonRange.length > 0;
            });

            const stylesWithModifiedOffset = sortWithOffset(
              stylesToProcess
            ).map((r, _i, arr) => {
              const styleRange = R.range(r.offset, r.offset + r.length);

              const commonRange = R.intersection(styleRange, textRange);
              const modifiedOffset = {
                ...r,
                offset: commonRange[0] - value.offset,
                length: commonRange.length,
              };
              // console.log('DOING:: TEST', stylesToProcess,r, value,modifiedOffset);
              return modifiedOffset;
            });

            const textLengthToProcess = Math.abs(value.length);

            const completeStyleRange = generateCompleteStyleRange(
              stylesWithModifiedOffset,
              textLengthToProcess
            );

            const stylesWithEmptyRange = sortWithOffset(completeStyleRange);

            const toReturn = reduceTextBlock(
              textToProcess,
              stylesWithEmptyRange
            );
            return toReturn;

          }
        }
      });

    // console.log("DOING :: expressionConverter :: 3", toReturn)
    return toReturn;
  };

  const generateCond = (cond: ExpressionT[]): ExpressionT => {
    const lastRhs = R.last(cond) as ExpressionT;

    const findeRecursiveValue = (obj: any, updateValue: any): any => {
      if (obj.type != ExpressionTypeE.JOIN) {
        return { type: ExpressionTypeE.JOIN, lhs: obj, rhs: updateValue };
      }
      return { ...obj, rhs: findeRecursiveValue(obj.rhs, updateValue) };
    };

    const remaining = R.compose<ExpressionT[], ExpressionT[], ExpressionT[]>(
      R.reverse,
      R.init
    )(cond);
    const reducer = R.reduce(
      (accum: ExpressionT, value: ExpressionT): ExpressionT => {
        return value.type != ExpressionTypeE.JOIN
          ? {
              type: ExpressionTypeE.JOIN,
              lhs: {
                ...value,
              },
              rhs: {
                ...accum,
              },
            }
          : findeRecursiveValue(value, accum);
      },
      lastRhs
    );
    return reducer(remaining);
  };

  const text: string = R.path(['blocks', 0, 'text'], meta) as string;

  const inlineStyleRanges = R.path(
    ['blocks', 0, 'inlineStyleRanges'],
    meta
  ) as {
    offset: number;
    length: number;
    style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'NONE';
  }[];

  const entity: entityRangesT[] = R.path(
    ['blocks', 0, 'entityRanges'],
    meta
  ) as entityRangesT[];

  if (entity.length === 0) {
    if (inlineStyleRanges.length == 0) {
      return {
        type: ExpressionTypeE.TEXT,
        value: text,
      };
    } else {
      const sortedStyles = sortWithOffset(inlineStyleRanges);

      // return reducedText(text, sortWithOffset(generateCompleteStyleRange(inlineStyleRanges, text.length)))

      return reduceTextBlock(
        text,
        sortWithOffset(
          generateCompleteStyleRange(inlineStyleRanges, text.length)
        )
      );
    }
  }

  const entityMap: EntityMapT = R.path(['entityMap'], meta) as EntityMapT;

  // console.log('meta:::', meta)

  // console.log('text:::', text)

  // console.log('entity:::', entity)

  // console.log('DOING :: lenmapper entityMap:::', entityMap,meta)

  const entityRanges: entityRangesT[] = filterEmptyEntities(
    lenmapper(entity, text)
  );

  // console.log('DOING :: lenmapper entityRanges:::', entityRanges)

  const expressions: ExpressionT[] = forWorkflow? workflowExpressionConverter(
    entityRanges,
    text,
    entityMap
  ): expressionConverter(
    entityRanges,
    text,
    entityMap
  );

  // console.log('expressions:::', expressions)

  return generateCond(expressions);
};

type StyleWithOffset = {
  offset: number;
  length: number;
  style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'NONE';
};

const generateCompleteStyleRange = (
  sortedStyleArray: StyleWithOffset[],
  totalLength: number
): StyleWithOffset[] => {
  if (totalLength === 0) {
    return [{ length: 0, offset: 0, style: 'NONE' }];
  }
  // const totalLength = text.length
  const allIndexes = Array.from(Array(totalLength), (_, i) => i);

  const allMatched = sortedStyleArray.reduce((accum: number[], current) => {
    const matchedIndex: number[] = Array.from(
      Array(current.length),
      (_, i) => i + current.offset
    );
    return accum.concat(matchedIndex);
  }, []);

  const missedOutElements = allIndexes.filter((x) => !allMatched.includes(x));
  const grouped = R.groupWith((a, b) => a + 1 === b, missedOutElements).map(
    (x) => {
      return {
        offset: x[0],
        length: x.length,
        style: 'NONE',
      } as StyleWithOffset;
    }
  );

  return [...sortedStyleArray, ...grouped];
};

const reduceTextBlock = (
  text: string,
  style_range: {
    offset: number;
    length: number;
    style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'NONE';
  }[]
) => {
  // const text = "H31H2";
  // const style_range = [
  // { length: 1, offset: 0, style: "BOLD" },
  // { length: 3, offset: 2, style: "BOLD" },
  // { length: 2, offset: 2, style: "ITALIC" }
  // ];
  const expanded_tokens = style_range.map((x) => ({
    styleType: x.style,
    tokenRange: R.range(x.offset, x.offset + x.length),
  }));
  const grouped = R.groupBy(R.prop('styleType'), expanded_tokens);
  const paired = R.toPairs(grouped);
  const x = R.map(([k, v]) => {
    const merged = v.map((x) => x.tokenRange).reduce((a, c) => a.concat(c), []);
    return { [k]: R.uniq(merged) };
  }, paired);
  const finalRanges = x.reduce((a, c) => {
    return { ...a, ...c };
  }, {});
  const bold = finalRanges['BOLD'] ? finalRanges['BOLD'] : [];
  const italic = finalRanges['ITALIC'] ? finalRanges['ITALIC'] : [];
  const underLine = finalRanges['UNDERLINE'] ? finalRanges['UNDERLINE'] : [];
  //const noStyle = finalRanges['NONE'] ? finalRanges['NONE'] : [];
  const tokens = R.range(0, text.length).map((x) => {
    const result = [];
    if (bold.includes(x)) {
      result.push('BOLD');
    }
    if (italic.includes(x)) {
      result.push('ITALIC');
    }
    if (underLine.includes(x)) {
      result.push('UNDERLINE');
    }
    if (result.length === 0) {
      result.push('NONE');
    }
    return result;
  });
  const zipedtext = R.zip([...text], tokens);
  //console.log("zipedtext",zipedtext)
  const z = R.reverse(zipedtext).reduce(
    (acc: any, curr: any) => {
      const text = curr[0];
      const styles = curr[1];
      const [first, ...rest] = acc;
      const accTokens = first.tokens;
      const accStyle = first.styles;
      // console.log("curr",curr,accStyle,accTokens)
      if (R.equals(accStyle, styles)) {
        return [{ tokens: `${text}${accTokens}`, styles: styles }, ...rest];
      } else {
        return [{ tokens: `${text}`, styles: styles }, ...acc];
      }
    },
    [{ tokens: '', styles: [] }]
  );
  const rev = R.reverse(R.init(z));
  const y = rev.reduce((acc, curr, i) => {
    const tokens = curr.tokens;
    const styles = curr.styles;

    const merged = styles.reduce(
      (a: any, c: any) => {
        const key = c.toLowerCase();
        if (key === 'none') {
          return {
            type: 'text',
            value: tokens,
          };
        }
        return {
          type: key,
          value: a,
        };
      },
      {
        type: 'text',
        value: tokens,
      }
    );
    if (i === 0) {
      return merged;
    }
    return { type: 'join', lhs: merged, rhs: acc };
  }, {});
  // console.log('DOING ::: block', text, style_range, y);
  return y;
};

const filterEmptyEntities = (entities: entityRangesT[]) => {
  const toreturn = entities.filter((x) => x.length !== 0);
  // console.log("lenmapper entities ::", toreturn)
  return toreturn;
};

const reducedText = (
  text: string,
  inlineStyleRanges: {
    offset: number;
    length: number;
    style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'NONE';
  }[]
) => {
  const firstOffset = inlineStyleRanges[0].offset;
  const rangeHasSameoffset =
    inlineStyleRanges.length == 3 &&
    inlineStyleRanges.every((r, i) => firstOffset == r.offset);

  const reducedValue = rangeHasSameoffset
    ? {
        type: 'bold',
        value: {
          type: 'italic',
          value: {
            type: 'underline',
            value: {
              type: ExpressionTypeE.TEXT,
              value: text,
            },
          },
        },
      }
    : (inlineStyleRanges.reduce((acc: any, val, i, arr) => {
        const alreadyHasValue = acc.type;
        const lastValue = i + 1 === inlineStyleRanges.length;
        const prevValue = arr[i - 1];

        if (val.style === 'NONE') {
          if (alreadyHasValue) {
            if (acc.type === ExpressionTypeE.JOIN) {
              const b4 = R.clone(acc);
              updateLastRHS(acc, {
                type: ExpressionTypeE.TEXT,
                value: text.slice(val.offset, val.length + val.offset),
              });

              return acc;
            }

            return {
              type: ExpressionTypeE.JOIN,
              lhs: acc,
              rhs: {
                type: ExpressionTypeE.TEXT,
                value: text.slice(val.offset, val.length + val.offset),
              },
            };
          }

          return {
            type: ExpressionTypeE.TEXT,
            value: text.slice(val.offset, val.length + val.offset),
          };
        } else if (val.style === 'BOLD') {
          if (alreadyHasValue) {
            if (prevValue && prevValue.offset === val.offset) {
              updateLastRHS(
                acc,
                {
                  //   type: ExpressionTypeE.BOLD,
                  //   value: text.slice(val.offset, val.length + val.offset)
                },
                ExpressionTypeE.BOLD
              );
              return acc;
            }

            if (acc.type === ExpressionTypeE.JOIN) {
              updateLastRHS(acc, {
                type: ExpressionTypeE.BOLD,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              });
              return acc;
            }

            return {
              type: ExpressionTypeE.JOIN,
              lhs: acc,
              rhs: {
                type: ExpressionTypeE.BOLD,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              },
            };
          }

          return {
            type: ExpressionTypeE.BOLD,
            value: {
              type: ExpressionTypeE.TEXT,
              value: text.slice(val.offset, val.offset + val.length),
            },
          };
        } else if (val.style === 'ITALIC') {
          if (alreadyHasValue) {
            if (prevValue && prevValue.offset === val.offset) {
              updateLastRHS(
                acc,
                {
                  //   type: ExpressionTypeE.ITALIC,
                  //   value: text.slice(val.offset, val.length + val.offset)
                },
                ExpressionTypeE.ITALIC
              );
              return acc;
            }

            if (acc.type === ExpressionTypeE.JOIN) {
              updateLastRHS(acc, {
                type: ExpressionTypeE.ITALIC,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              });
              return acc;
            }

            return {
              type: ExpressionTypeE.JOIN,
              lhs: acc,
              rhs: {
                type: ExpressionTypeE.ITALIC,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              },
            };
          }

          return {
            type: ExpressionTypeE.ITALIC,
            value: {
              type: ExpressionTypeE.TEXT,
              value: text.slice(val.offset, val.offset + val.length),
            },
          };
        } else if (val.style === 'UNDERLINE') {
          if (alreadyHasValue) {
            if (prevValue && prevValue.offset === val.offset) {
              updateLastRHS(
                acc,
                {
                  //   type: ExpressionTypeE.UNDERLINE,
                  //   value: text.slice(val.offset, val.length + val.offset)
                },
                ExpressionTypeE.UNDERLINE
              );
              return acc;
            }

            if (acc.type === ExpressionTypeE.JOIN) {
              updateLastRHS(acc, {
                type: ExpressionTypeE.UNDERLINE,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              });
              return acc;
            }

            return {
              type: ExpressionTypeE.JOIN,
              lhs: acc,
              rhs: {
                type: ExpressionTypeE.UNDERLINE,
                value: {
                  type: ExpressionTypeE.TEXT,
                  value: text.slice(val.offset, val.length + val.offset),
                },
              },
            };
          }

          return {
            type: ExpressionTypeE.UNDERLINE,
            value: {
              type: ExpressionTypeE.TEXT,
              value: text.slice(val.offset, val.offset + val.length),
            },
          };
        }

        //   else if (val.style == 'BOLD') {
        //       if (prevValue && prevValue.offset != val.offset) {
        //           return lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: {
        //                             type: 'bold',
        //                             value: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset, val.length + val.offset)
        //                             }
        //                         },
        //                         rhs: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.length + val.offset, text.length)
        //                         }
        //                     }
        //                 }
        //               : {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: 'bold',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     }
        //                 }
        //       } else {
        //           return acc.type == ExpressionTypeE.JOIN
        //               ? lastValue && text.slice(0, val.offset + val.length).length != text.length
        //                   ? {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: { ...acc.lhs },
        //                         rhs: {
        //                             type: ExpressionTypeE.JOIN,
        //                             lhs: { type: 'bold', value: acc.rhs },
        //                             rhs: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset + val.length, text.length)
        //                             }
        //                         }
        //                     }
        //                   : { ...acc, rhs: { type: 'bold', value: acc.rhs } }
        //               : alreadyHasValue
        //               ? {
        //                     type: 'bold',
        //                     value: acc
        //                 }
        //               : lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: {
        //                         type: 'bold',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     },
        //                     rhs: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.length + val.offset, text.length)
        //                     }
        //                 }
        //               : {
        //                     type: 'bold',
        //                     value: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.offset, val.length + val.offset)
        //                     }
        //                 }
        //       }
        //   } else if (val.style == 'ITALIC') {
        //       if (prevValue && prevValue.offset != val.offset) {
        //           return lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: {
        //                             type: 'italic',
        //                             value: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset, val.length + val.offset)
        //                             }
        //                         },
        //                         rhs: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.length + val.offset, text.length)
        //                         }
        //                     }
        //                 }
        //               : {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: 'italic',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     }
        //                 }
        //       } else {
        //           return acc.type == ExpressionTypeE.JOIN
        //               ? lastValue && text.slice(0, val.offset + val.length).length != text.length
        //                   ? {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: { ...acc.lhs },
        //                         rhs: {
        //                             type: ExpressionTypeE.JOIN,
        //                             lhs: { type: 'italic', value: acc.rhs },
        //                             rhs: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset + val.length, text.length)
        //                             }
        //                         }
        //                     }
        //                   : { ...acc, rhs: { type: 'italic', value: acc.rhs } }
        //               : alreadyHasValue
        //               ? {
        //                     type: 'italic',
        //                     value: acc
        //                 }
        //               : lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: {
        //                         type: 'italic',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     },
        //                     rhs: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.length + val.offset, text.length)
        //                     }
        //                 }
        //               : {
        //                     type: 'italic',
        //                     value: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.offset, val.length + val.offset)
        //                     }
        //                 }
        //       }
        //   } else {
        //       if (prevValue && prevValue.offset != val.offset) {
        //           return lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: {
        //                             type: 'underline',
        //                             value: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset, val.length + val.offset)
        //                             }
        //                         },
        //                         rhs: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.length + val.offset, text.length)
        //                         }
        //                     }
        //                 }
        //               : {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: acc,
        //                     rhs: {
        //                         type: 'underline',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     }
        //                 }
        //       } else {
        //           return acc.type == ExpressionTypeE.JOIN
        //               ? lastValue && text.slice(0, val.offset + val.length).length != text.length
        //                   ? {
        //                         type: ExpressionTypeE.JOIN,
        //                         lhs: { ...acc.lhs },
        //                         rhs: {
        //                             type: ExpressionTypeE.JOIN,
        //                             lhs: { type: 'underline', value: acc.rhs },
        //                             rhs: {
        //                                 type: ExpressionTypeE.TEXT,
        //                                 value: text.slice(val.offset + val.length, text.length)
        //                             }
        //                         }
        //                     }
        //                   : { ...acc, rhs: { type: 'underline', value: acc.rhs } }
        //               : alreadyHasValue
        //               ? {
        //                     type: 'underline',
        //                     value: acc
        //                 }
        //               : lastValue && text.slice(0, val.offset + val.length).length != text.length
        //               ? {
        //                     type: ExpressionTypeE.JOIN,
        //                     lhs: {
        //                         type: 'underline',
        //                         value: {
        //                             type: ExpressionTypeE.TEXT,
        //                             value: text.slice(val.offset, val.length + val.offset)
        //                         }
        //                     },
        //                     rhs: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.length + val.offset, text.length)
        //                     }
        //                 }
        //               : {
        //                     type: 'underline',
        //                     value: {
        //                         type: ExpressionTypeE.TEXT,
        //                         value: text.slice(val.offset, val.length + val.offset)
        //                     }
        //                 }
        //       }
        //   }
      }, {}) as any);
  // console.log(reducedValue, 'reducedValue')
  return reducedValue;
};

// export function convertToOne(draft: DraftJST) {
//     return draft.blocks.reduce(
//         (acc, val, currentIndex, arr) => {
//             if (currentIndex == 0) {
//                 return {
//                     res:
//                         val.type == 'unstyled'
//                             ? {
//                                   ...converterToServer2({
//                                       blocks: [draft.blocks[currentIndex]],

//                                       entityMap: draft.entityMap
//                                   })
//                               }
//                             : {
//                                   type: val.type == 'unordered-list-item' ? 'unorder_list' : 'order_list',
//                                   value: [
//                                       {
//                                           ...converterToServer2({
//                                               blocks: [draft.blocks[currentIndex]],

//                                               entityMap: draft.entityMap
//                                           })
//                                       }
//                                   ]
//                               }
//                 }
//             }

//             var keys = getkeys(acc.res as any)

//             const newObj = {
//                 res: updateNewLineValueUsingLens(
//                     keys,
//                     acc.res,
//                     draft.blocks[currentIndex].type != 'unstyled'
//                         ? !R.isEmpty(acc.res) && arr[currentIndex - 1].type == draft.blocks[currentIndex].type
//                             ? {
//                                   ...findeLastRHS(acc.res),
//                                   value: [
//                                       ...findeLastRHS(acc.res).value,
//                                       {
//                                           ...converterToServer2({
//                                               blocks: [draft.blocks[currentIndex]],

//                                               entityMap: draft.entityMap
//                                           })
//                                       }
//                                   ]
//                               }
//                             : {
//                                   type:
//                                       (draft.blocks[currentIndex].type as any) == 'unordered-list-item'
//                                           ? 'unorder_list'
//                                           : 'order_list',
//                                   value: [
//                                       {
//                                           ...converterToServer2({
//                                               blocks: [draft.blocks[currentIndex]],

//                                               entityMap: draft.entityMap
//                                           })
//                                       }
//                                   ]
//                               }
//                         : {
//                               ...converterToServer2({
//                                   blocks: [draft.blocks[currentIndex]],

//                                   entityMap: draft.entityMap
//                               })
//                           },
//                     !R.isEmpty(acc.res) &&
//                         draft.blocks[currentIndex].type != 'unstyled' &&
//                         arr[currentIndex - 1].type == draft.blocks[currentIndex].type
//                 )
//             }

//             return newObj
//         },

//         { res: {} }
//     )
// }

// 247 // if (val.type == ExpressionTypeE.ORDEREDLIST || val.type == ExpressionTypeE.UNORDEREDLIST) {
//     const reduceExpression = val.value.reduce(
//         (accum, expres, i) => {
//             const converted = converterToEditor(expres, dialogContext, assistantID)

//             const block = {
//                 key: Math.random()
//                     .toString(36)
//                     .substring(2, 12),
//                 text: converted.blocks[0].text,
//                 type: val.type == 'unorder_list' ? 'unordered-list-item' : 'ordered-list-item',
//                 depth: 0,
//                 inlineStyleRanges: converted.blocks[0].inlineStyleRanges,
//                 entityRanges: converted.blocks[0].entityRanges,
//                 data: {}
//             } as any

//             return {
//                 blocks: R.concat(accum.blocks, [block]),
//                 entityMap: { ...accum.entityMap, ...converted.entityMap }
//             }
//         },
//         { blocks: [], entityMap: {} } as any
//     )

//     return reduceExpression
// } else {
