/*eslint-disable*/
import { RawDraftContentBlock, convertToRaw } from 'draft-js'
import React, { useContext, useEffect, useState } from 'react'

import { DownArrowCircle, FlowCanvasIcons } from '@/common/Icons/Icons'
import {
    ConnectionLineIcon,
    ForEachNodeIcon,
    GreenTickIcon,
    LeftPanelStepSuccessIcon,
    TickIconEmptyCircle,
    WarningAlertIcon
} from '@/common/Icons/Workflow.Icons'

import automationImg from '@/common/images/automation.png'
import { seperateOptionalFields } from './ActionPanel'
import { LeftSidePanelContext, NonCanvasPanelContext, SidePanelContext } from './Canvas'
import { useDropDown } from './Tree'
import {
    ActionsResponseT,
    ApprovalResponseT,
    ConditionResponseT,
    EndResponseT,
    FunctionResponseT,
    InputFieldT,
    LeftPanelStateT,
    NewConnectionT,
    NodeMode,
    NodeT,
    Nodes,
    TreeT,
    checkInputHasValue,
    WebHookResponseT,
    TimerResponseT,
    NotificationResponseT
} from './types'
import { configKinds, convertAsaSingleString, getInputsInNestedLevel } from './utils'
import { getProductId } from '@/common/utils/utils'

export function CanvasLeftPanel(props: any) {
    const { rightPanelState, editTreeData, treeData } = useContext(SidePanelContext)

    const { setLeftPanelState, leftPanelState } = useContext(LeftSidePanelContext)

    useEffect(() => {
        setLeftPanelState({
            ...leftPanelState,
            step1: {
                done: treeData.firstStepDone
            },
            step2: {
                done: treeData.secondStepDone
            },
            parentNode: rightPanelState.parentNode,
            mode: rightPanelState.mode,
            currentNode: rightPanelState.currentNode
        })
    }, [rightPanelState.mode, rightPanelState.currentNode, rightPanelState.parentNode, treeData])

    const currentTree = leftPanelState.mode.mode === NodeMode.View ? treeData : editTreeData

    const secondStepNodes =
        leftPanelState.mode.mode === NodeMode.View ? currentTree.tree.children[0] : currentTree.tree.children[0]

    const EndNode =
        leftPanelState.mode.mode === NodeMode.View ? currentTree.tree.children[1] : currentTree.tree.children[1]

    return (
        <Wrapper
            secondStepDone={
                secondStepNodes &&
                secondStepNodes.children.length == 1 &&
                secondStepNodes.children.filter((c: TreeT<NodeT>) => c.value.kind != 'ParallelPath').length > 0
            }
        >
            {/* <Steps leftPanelState={leftPanelState} step3Node={EndNode.value} /> */}

            <React.Fragment>
                <div className="tree_lines_popup" style={{ borderTop: '0.5px solid #d9e2eb' }}>
                    <ul className="tree_left tree_left_lines">
                        <TriggerOrNoTriggerStep node={currentTree.tree.value} step1done={leftPanelState.step1.done} />
                        {leftPanelState.step1.done ? (
                            secondStepNodes && secondStepNodes.children.length > 0 ? (
                                <BuildSecondStepNodes
                                    secondStepNodes={secondStepNodes}
                                    leftPanelState={leftPanelState}
                                />
                            ) : (
                                firstAndStepInfoImage(
                                    'Complete workflow by adding actions, conditions or selecting other functions.'
                                )
                            )
                        ) : null}
                        {currentTree.secondStepDone && <EndStepNode node={EndNode.value} />}
                    </ul>
                </div>
            </React.Fragment>
        </Wrapper>
    )
}

function BuildSecondStepNodes(props: { secondStepNodes: TreeT<NodeT>; leftPanelState: LeftPanelStateT }) {
    const { secondStepNodes, leftPanelState } = props

    return (
        <React.Fragment>
            {secondStepNodes.children.length === 1 ? (
                secondStepNodes.children.map((node: TreeT<NodeT>, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <TreeNodes
                                node={node.value}
                                leftPanelState={leftPanelState}
                                index={i + 2}
                                className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                                showNumbering={true}
                                hasOneChildren={false}
                            />
                            {node.children.length > 0 ? (
                                node.children.length === 1 ? (
                                    <GenerateChildrenNode
                                        key={i}
                                        children={node.children}
                                        leftPanelState={leftPanelState}
                                        index={i + 2}
                                        showNumbering={true}
                                        hasOneChildren={false}
                                        fromForEach={false}
                                    />
                                ) : (
                                    <ParallelPathlist
                                        children={node.children}
                                        leftPanelState={leftPanelState}
                                        showNumbering={true}
                                        fromForEach={false}
                                        parentFromParallelPath={secondStepNodes.children.length > 1}
                                    />
                                )
                            ) : null}
                        </React.Fragment>
                    )
                })
            ) : (
                <ParallelPathlist
                    children={secondStepNodes.children}
                    leftPanelState={leftPanelState}
                    showNumbering={true}
                    fromForEach={false}
                    // parentFromParallelPath={secondStepNodes.children.length > 1}
                />
            )}
        </React.Fragment>
    )
}

const Wrapper = (props: { children: React.ReactNode; secondStepDone: boolean }) => {
    return (
        <div className="sidepanel_menu_right">
            {/* <div className="sidepanel_menu_right_head">
                <h4>Workflow Builder</h4>
            </div> */}
            <div className="sidepanel_menu_right_menu">
                <div className="sidepanel_menu_right_menu_step">
                    <div className="automation_left_panel">
                        <div className="automation_left_panel_tree">
                            <div
                                className={`left_popup_automation_tree ${
                                    props.secondStepDone ? '' : 'empty_second_step'
                                }`}
                            >
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GenerateChildrenNode = (props: {
    children: TreeT<NodeT>[]
    leftPanelState: LeftPanelStateT
    index: number
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
    hasOneChildren: boolean
    fromForEach: boolean
}) => {
    const {
        children,
        leftPanelState,
        index,
        setCurrentParallelPathActive,
        showNumbering,
        hasOneChildren,
        fromForEach
    } = props

    return (
        <React.Fragment>
            {children.map((node: TreeT<NodeT>, i: number) => (
                <React.Fragment key={i}>
                    <TreeNodes
                        className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                        node={node.value}
                        leftPanelState={leftPanelState}
                        index={index + (i + 1)}
                        setCurrentParallelPathActive={setCurrentParallelPathActive}
                        showNumbering={showNumbering}
                        hasOneChildren={hasOneChildren}
                    />
                    {node.children.length > 0 ? (
                        node.children.length === 1 ? (
                            <GenerateChildrenNode
                                key={i}
                                children={node.children}
                                leftPanelState={leftPanelState}
                                index={index + (i + 1)}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={showNumbering}
                                hasOneChildren={fromForEach ? true : false}
                                fromForEach={fromForEach}
                            />
                        ) : (
                            <ParallelPathlist
                                children={node.children}
                                leftPanelState={leftPanelState}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={showNumbering}
                                fromForEach={fromForEach}
                                parentFromParallelPath={children.length > 1}
                            />
                        )
                    ) : null}
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}

const Steps = (props: { leftPanelState: LeftPanelStateT; step3Node: NodeT }) => {
    const { leftPanelState, step3Node } = props

    const currentNode = leftPanelState.currentNode

    const currentlyStep1Active = currentNode.nodeType === Nodes.Trigger

    const currentlyStep2Active = currentNode.nodeType === Nodes.Action || currentNode.nodeType === Nodes.Condition

    const currentlyStep3Active = currentNode.nodeType === Nodes.End

    const step3NotSaved =
        !step3Node.meta_data ||
        (step3Node.meta_data &&
            step3Node.meta_data.successEmail.length === 0 &&
            step3Node.meta_data.failureEmail.length === 0)

    return (
        <div className="sidepanel_step_automations">
            <StepHeader
                name={'1'}
                done={currentlyStep1Active || !leftPanelState.step1.done}
                showSuccesIcon={leftPanelState.step1.done}
            />
            <StepHeader
                name={'2'}
                done={
                    (currentlyStep2Active && leftPanelState.mode.mode !== NodeMode.View) ||
                    (leftPanelState.step1.done &&
                        !leftPanelState.step2.done &&
                        !currentlyStep1Active &&
                        !currentlyStep3Active)
                }
                showSuccesIcon={leftPanelState.step2.done}
            />

            <StepHeader
                name={'3'}
                showSuccesIcon={!step3NotSaved}
                done={
                    ((leftPanelState.step1.done && leftPanelState.step2.done && currentlyStep3Active) ||
                        (leftPanelState.step2.done && step3NotSaved)) &&
                    (leftPanelState.mode.mode === NodeMode.View || (!currentlyStep2Active && !currentlyStep1Active))
                }
            />
        </div>
    )
}

function StepHeader(props: { done: boolean; name: string; showSuccesIcon: boolean }) {
    const { done, name, showSuccesIcon } = props
    return (
        <div
            className={
                done
                    ? 'sidepanel_step_automations_show sidepanel_step_automations_show_selected'
                    : 'sidepanel_step_automations_show'
            }
        >
            <div className="sidepanel_show_step">
                Step
                {showSuccesIcon && (
                    <span>
                        <LeftPanelStepSuccessIcon />
                    </span>
                )}
            </div>

            <div className="sidepanel_show_step">{name}</div>
        </div>
    )
}

function TriggerOrNoTriggerStep(props: { node: NodeT; step1done: boolean }) {
    const { node, step1done } = props

    const { leftPanelState } = useContext(LeftSidePanelContext)

    const currentNode = leftPanelState.currentNode

    if(node.kind === "WebhookTrigger"){
        const showInSelectedState =
                    (currentNode.nodeType === Nodes.Webhook &&
                        currentNode.trigger.triggerMeta.triggerType === "Webhook")
                        ? 'selected_parent_node'
                        : ''
                        const currentNod = currentNode as WebHookResponseT;
                        
                        const name =
                          currentNode.nodeType === Nodes.Webhook &&
                          currentNode.trigger.triggerMeta.triggerType === "Webhook"
                            ? currentNod.name.length > 0
                            : node.name.length > 0;

                                return (
                                    <React.Fragment>
                                      <li className="notrigger_start_left">
                                        <label className="tree_label">
                                          <div className={`step_border_left ${showInSelectedState}`}>
                                            <span className="step_border_left_number">1 </span>
                                            <span className="step_border_left_line">
                                              <ConnectionLineIcon />
                                            </span>
                                            <div className="step_border_left_h6_first">
                                              <h6>Started with Webhook</h6>
                                            </div>
                                            <Step title={"Provide Name"} done={name} />
                          
                                            {/* <Step title={"Provide Input"} done={addedInputs} /> */}
                                            <Step
                                              title={showInSelectedState.length > 0 ? "Save" : "Saved"}
                                              done={showInSelectedState.length === 0}
                                            />
                                          </div>
                                        </label>
                                      </li>
                                      {currentNode.nodeType === Nodes.Webhook &&
                                        !step1done &&
                                        firstAndStepInfoImage(
                                          "Start with Trigger or No Trigger to build automation"
                                        )}
                                    </React.Fragment>
                                  );
    }else if (node.kind === 'NoTrigger' || node.kind === 'AppTrigger' || node.kind === 'EventTrigger') {
        if (
            (node.kind === 'NoTrigger' && currentNode.nodeType !== Nodes.Trigger) ||
            (currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger')
        ) {
            let inputs = []

            let addedInputs = false

            if (currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger') {
                inputs = currentNode.trigger.triggerMeta.inputKeys
                addedInputs = inputs.filter(x => x.title.length > 0).length > 0
            } else {
                addedInputs = node.output && node.output.properties
            }

            const showInSelectedState =
                currentNode.nodeType === Nodes.Trigger && currentNode.trigger.triggerMeta.triggerType === 'NoTrigger'
                    ? 'selected_parent_node'
                    : ''

            return (
                <React.Fragment>
                    {/* <li className="notrigger_start_left">
                        <label className="tree_label">
                            <div className={`step_border_left ${showInSelectedState}`}>
                                <span className="step_border_left_number" />
                                <span className="step_border_left_line">
                                    <ConnectionLineIcon />
                                </span>
                                <div className="step_border_left_h6_first">
                                    <h6>Chatbot Triggered</h6>
                                </div>
                                <Step title={'Provide Input'} done={addedInputs} />
                                <Step
                                    title={showInSelectedState.length > 0 ? 'Save' : 'Saved'}
                                    done={showInSelectedState.length === 0}
                                />
                            </div>
                        </label>
                    </li> */}
                    {currentNode.nodeType === Nodes.Trigger &&
                        !step1done &&
                        firstAndStepInfoImage('Start building your Workflow Automation')}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <TreeNodes
                        className={''}
                        node={{ ...node, kind: 'AppTrigger' }}
                        leftPanelState={leftPanelState}
                        index={1}
                        setCurrentParallelPathActive={() => {}}
                        showNumbering={true}
                        hasOneChildren={false}
                    />
                    {currentNode.nodeType === Nodes.Trigger &&
                        !step1done &&
                        firstAndStepInfoImage('Start building your Workflow Automation')}
                </React.Fragment>
            )
        }
    }

    return null
}

function firstAndStepInfoImage(text: string) {
    return (
        <div className="sidepanel_step_automations_content"> 

            {  getProductId() === "ASSISTANT" ? <p>{text}</p> : <p></p> }
            {  getProductId() === "ASSISTANT" ? <img src={automationImg} alt="automations" title="automations" /> : <img />}

        </div>
    )
}

function TreeNodes(props: {
    node: NodeT
    leftPanelState: LeftPanelStateT
    index: number
    className: string
    showNumbering: boolean
    setCurrentParallelPathActive?: () => void
    hasOneChildren: boolean
    hasparallelChildren?: boolean
}) {
    const {
        node,
        leftPanelState,
        index,
        className,
        setCurrentParallelPathActive,
        showNumbering,
        hasOneChildren,
        hasparallelChildren
    } = props

    // to handle single parallel path empty node while removing it from after empty parallel paths added
    if (node.kind == 'ParallelPath' && node.name.length == 0) return null

    const [expandedView, makevisible, makehidden] = useDropDown(true)

    const { parentAutomation } = useContext(NonCanvasPanelContext)

    const nodesWithoutConnection: string[] = parentAutomation.nodesWithoutConnection

    const ifCurrentNodeisEditNode =
        leftPanelState.mode.mode === NodeMode.Edit && node.id === leftPanelState.mode.nodeEditInput.id

    const showInSelectedState =
        node.kind === 'EmptyAction' ||
        node.kind === 'EmptyCondition' ||
        node.kind === 'EmptyFunction' ||
        node.kind === 'ParallelPath' ||
        node.kind === 'EmptyApproval' ||
        node.kind === 'EmptyTimer' ||
        ifCurrentNodeisEditNode
            ? 'selected_parent_node'
            : ''

    useEffect(() => {
        showInSelectedState && setCurrentParallelPathActive && setCurrentParallelPathActive()
    }, [leftPanelState.mode])

    useEffect(() => {
        // to open parallel path as active in left panel
        if (node.kind === 'ParallelPath' && node.name.length > 0) {
            showInSelectedState && setCurrentParallelPathActive && setCurrentParallelPathActive()
        }
    }, [])

    const step = node.kind !== 'AppTrigger' && node.kind !== 'NoTrigger' && node.kind !== 'EventTrigger' ? 2 : index

    return (
        <li
            className={
                className
                    ? className + ` tree_node tree_node_parent ${node.kind == 'ForEach' ? 'foreach_loop_panel' : ''}`
                    : `tree_node tree_node_parent ${node.kind == 'ForEach' ? 'foreach_loop_panel' : ''}`
            }
        >
            <label className="tree_label">
                <div
                    className={`step_border_left ${showInSelectedState} ${
                        node.kind == 'ForEach' ? 'foreach_loop_left' : ''
                    } ${hasOneChildren || showInSelectedState ? 'inside_for_node' : ''} ${
                        hasparallelChildren ? 'left_6' : ''
                    }`}
                >
                    {showNumbering && (
                        <>
                            <span className="step_border_left_number" />
                            {/* <span className="step_border_left_number">{step}</span> */}
                            <span className="step_after_left_border"></span>
                            <span className="step_border_left_line">
                                <ConnectionLineIcon />
                            </span>
                        </>
                    )}
                    <div
                        className={`step_border_left_h6 ${node.kind == 'ForEach' ? 'foreach_loop_h6' : ''}`}
                        onClick={() => (expandedView ? makehidden() : makevisible())}
                    >
                        <h6>
                            {node.kind === 'EmptyAction' ||
                            node.kind === 'EmptyCondition' ||
                            node.kind === 'EmptyFunction' ||
                            node.kind === 'EmptyApproval' ||
                            node.kind === 'EmptyTimer' ||
                            node.kind === 'ParallelPath' ||
                            node.kind === 'EmptyNotification'
                                ? node.name
                                : node.kind === 'AppTrigger' || node.kind === 'EventTrigger'
                                ? 'Start with Trigger'
                                : node.kind == 'ErrorHandlingCondition'
                                ? 'Condition'
                                : node.kind == 'ForEach'
                                ? `${node.kind} - ${node.name}`
                                : node.kind}
                        </h6>
                        {(((props.node.kind == 'AppTrigger' || props.node.kind == 'EventTrigger') && node.meta_data)||(props.node.kind == 'Action')) &&
                         (!props.node.meta_data ||
                            !props.node.meta_data.authentication_id ||
                            props.node.meta_data.authentication_id.length == 0 ||
                            nodesWithoutConnection.indexOf(props.node.id) != -1) ? (
                            <span className="no_input_value">
                                <div className="error_input_show">
                                    <WarningAlertIcon />
                                    <span className="error_menu_hvr">Connection details are missing.</span>
                                </div>
                            </span>
                        ) : (
                            !checkInputHasValue(node) &&
                            node.kind !== 'EmptyAction' &&
                            node.kind !== 'EmptyCondition' &&
                            node.kind !== 'EmptyFunction' &&
                            node.kind !== 'EmptyForEach' &&
                            node.kind !== 'AppTrigger' &&
                            node.kind !== 'EventTrigger' &&
                            node.kind !== 'ParallelPath' &&
                            node.kind !== 'EmptyApproval' &&
                            node.kind !== 'EmptyTimer' && 
                            node.kind !== 'EmptyNotification' && (
                                <span className="no_input_value">
                                    <div className="error_input_show">
                                        <WarningAlertIcon />
                                        <span className="error_menu_hvr">Input fields are missing.</span>
                                    </div>
                                </span>
                            )
                        )}
                        {node.kind != 'ForEach' &&
                            (expandedView ? (
                                <span className="show_arrow_as_up">
                                    <DownArrowCircle />
                                </span>
                            ) : (
                                <DownArrowCircle />
                            ))}
                    </div>
                    {node.kind == 'ForEach' ? buildForEachSubflow(node.subflow) : buildNodeStepsDetail()}
                </div>
            </label>
        </li>
    )

    function buildForEachSubflow(subflow?: { children: TreeT<NodeT>[] }) {
        return subflow && subflow.children.length > 0 ? (
            <div className="foreach_loop_container">
                <ul className="tree_left tree_left_lines foreach_loop_tree">
                    {subflow.children.length > 0 ? (
                        subflow.children.length === 1 ? (
                            <GenerateChildrenNode
                                children={subflow.children}
                                leftPanelState={leftPanelState}
                                index={index}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={false}
                                hasOneChildren={true}
                                fromForEach={true}
                            />
                        ) : (
                            <ParallelPathlist
                                children={subflow.children}
                                leftPanelState={leftPanelState}
                                setCurrentParallelPathActive={setCurrentParallelPathActive}
                                showNumbering={false}
                                fromForEach={true}
                                parentFromParallelPath={subflow.children.length > 1}
                            />
                        )
                    ) : null}
                </ul>
            </div>
        ) : null
    }

    function buildNodeStepsDetail() {
        switch (node.kind) {
            case 'AppTrigger':
            case 'EventTrigger':
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step
                                    title={!node.meta_data? "Select Application" : node.meta_data.app && node.meta_data.app.appName}
                                    done={node.meta_data}
                                    icon={node.icon}
                                />
                                <Step title={!node.meta_data? "Select Action" : node.name} done={node.meta_data} />
                                <Step
                                    title={`${!node.meta_data?  "Choose a Connection" : "Connected to"} ${node.meta_data &&
                                        node.meta_data.app ?
                                        node.meta_data.app.appName:""}`}
                                    done={
                                        node.meta_data &&
                                        node.meta_data.authentication_id &&
                                        node.meta_data.authentication_id.length > 0 &&
                                        nodesWithoutConnection.indexOf(props.node.id) == -1
                                    }
                                />
                                <Step title={'Input details'} done={node.meta_data} />
                                <Step title={'Saved'} done={node.meta_data} />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditAction(node, leftPanelState, expandedView)
                }
            case 'Timer':
                console.log(node,!ifCurrentNodeisEditNode)
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={"Timer type"} done icon={node.icon} />
                                <Step title={"Timer inputs"} done />
                                <Step title={'Saved'} done/>
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditTimer(node, leftPanelState, expandedView)
                }
            case 'Action':
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={node.meta_data.app && node.meta_data.app.appName} done icon={node.icon} />
                                <Step title={node.name} done />
                                <Step
                                    title={`Connected to ${node.meta_data.app && node.meta_data.app.appName}`}
                                    done={
                                        node.meta_data &&
                                        node.meta_data.authentication_id &&
                                        node.meta_data.authentication_id.length > 0 &&
                                        nodesWithoutConnection.indexOf(props.node.id) == -1
                                    }
                                />
                                <Step title={'Input details'} done={checkInputHasValue(node)} />
                                <Step
                                    title={checkInputHasValue(node) ? 'Saved' : 'Save'}
                                    done={checkInputHasValue(node)}
                                />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditAction(node, leftPanelState, expandedView)
                }

            case 'Condition':
            case 'ErrorHandlingCondition': {
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={node.name} done />
                                <Step title={'Setup condition'} done={checkInputHasValue(node)} />
                                <Step
                                    title={checkInputHasValue(node) ? 'Saved' : 'Save'}
                                    done={checkInputHasValue(node)}
                                />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditCondition(node, leftPanelState, expandedView)
                }
            }

            case 'Notification': {
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={'Notification type'} done />
                                <Step
                                    title={'Select bot'}
                                    done={(node as any).bot_id}
                                />
                                <Step
                                    title={'Chat channel'}
                                    done={(node as any).chat_channel_type}
                                />
                                <Step
                                    title={'Notification message'}
                                    done={checkNodeInputHasValue(node.input as InputFieldT[],"inputs")}
                                />
                                <Step
                                    title={
                                        checkNodeInputHasValue(node.input as InputFieldT[], 'inputs') ? 'Saved' : 'Save'
                                    }
                                    done={checkNodeInputHasValue(node.input as InputFieldT[], 'inputs')}
                                />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditNotification(node, leftPanelState, expandedView)
                }
            }

            case 'Approval': {
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={'Approval type'} done />
                                <Step
                                    title={'Approval inputs'}
                                    done={checkNodeInputHasValue(node.input as InputFieldT[], 'inputs')}
                                />
                                <Step
                                    title={'Remainder and expiry'}
                                    done={checkNodeInputHasValue(node.input as InputFieldT[], 'expiry')}
                                />
                                <Step
                                    title={
                                        checkNodeInputHasValue(node.input as InputFieldT[], 'all') ? 'Saved' : 'Save'
                                    }
                                    done={checkNodeInputHasValue(node.input as InputFieldT[], 'all')}
                                />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditApproval(node, leftPanelState, expandedView)
                }
            }

            case 'Function':
                if (!ifCurrentNodeisEditNode) {
                    return (
                        expandedView && (
                            <div className="step_border_left_p">
                                <Step title={'Select function'} done icon={node.icon} />
                                <Step title={'Select operation'} done />
                                <Step title={'Input details'} done={checkInputHasValue(node)} />
                                <Step
                                    title={checkInputHasValue(node) ? 'Saved' : 'Save'}
                                    done={checkInputHasValue(node)}
                                />
                            </div>
                        )
                    )
                } else {
                    return showNewOrEditFunction(node, leftPanelState, expandedView)
                }

            case 'ParallelPath':
                const currentNode = leftPanelState.currentNode

                if (currentNode.nodeType === Nodes.Action && node.name.length > 0) {
                    return showNewOrEditAction(node, leftPanelState, expandedView)
                } else if (currentNode.nodeType === Nodes.Condition && node.name.length > 0) {
                    return showNewOrEditCondition(node, leftPanelState, expandedView)
                } else if (currentNode.nodeType === Nodes.Function && node.name.length > 0) {
                    return showNewOrEditFunction(node, leftPanelState, expandedView)
                }

                return null

            case 'EmptyTimer':
                return showNewOrEditTimer(node, leftPanelState, expandedView)    

            case 'EmptyAction':
                return showNewOrEditAction(node, leftPanelState, expandedView)

            case 'EmptyCondition':
                return showNewOrEditCondition(node, leftPanelState, expandedView)

            case 'EmptyFunction':
                return showNewOrEditFunction(node, leftPanelState, expandedView)
            case 'EmptyApproval':
                return showNewOrEditApproval(node, leftPanelState, expandedView)
            case 'EmptyNotification':
                return showNewOrEditNotification(node,leftPanelState,expandedView)

            default:
                return null
        }
    }
}

function showNewOrEditTimer(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as TimerResponseT
    console.log("coming")
    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step title={"Timer type"} done icon={node.icon} />
                <Step title={"Timer inputs"} done={ (currentNode.timerType=== 'wait_time' && currentNode.waitFor.length>0) || (currentNode.timerType=== 'condition_met' &&currentNode.currentDay>0)} />
                <Step title={'Save'} done={false} />
            </div>
        )
    )
}

function showNewOrEditAction(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as ActionsResponseT
    const selectedApplication = currentNode.selectedApp.id.length > 0
    const selectedAction = currentNode.selectedAction.id.length > 0

    const connection = currentNode.selectedConnection as NewConnectionT

    const selectedConnection = !connection.connectionEditing && connection.valid === true

    const AllrequiredFieldsHasValue =
        currentNode.actionInputsList.output.input.length > 0
            ? seperateOptionalFields(currentNode.actionInputsList.output.input, true).every(hasValue)
            : currentNode.selectedConnection.valid && currentNode.actionInputsList.output.input.length == 0
            ? true
            : false

    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step
                    title={!selectedApplication ? 'Select Application' : currentNode.selectedApp.name}
                    done={selectedApplication}
                    icon={currentNode.selectedApp.icon}
                />
                <Step
                    title={!selectedAction ? 'Select Action' : currentNode.selectedAction.name}
                    done={selectedAction}
                />
                <Step
                    title={!selectedConnection ? 'Choose a Connection' : `Connected to ${currentNode.selectedApp.name}`}
                    done={selectedConnection}
                />
                <Step title={'Input Fields'} done={AllrequiredFieldsHasValue} />
                <Step title={'Save'} done={false} />
            </div>
        )
    )
}

function showNewOrEditCondition(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as ConditionResponseT
    const conditionName = currentNode.conditionName.length > 0

    const setupCondition = currentNode.rules.filter(x => {
        return (
            x.left.length > 0 &&
            ((x.valType === 'boolean' && x.operator !== 'Select') ||
                convertAsaSingleString(
                    convertToRaw((x.right as any).getCurrentContent()).blocks as RawDraftContentBlock[]
                ).replace(/\s/g, '').length ||
                x.rightHasValue)
        )
    })

    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step
                    title={conditionName ? currentNode.conditionName : 'Name for this condition'}
                    done={conditionName}
                />
                <Step
                    title={'Setup condition'}
                    done={setupCondition.length > 0 && setupCondition.length === currentNode.rules.length}
                />
                <Step title={'Save'} done={node.kind == 'ErrorHandlingCondition' ? true : false} />
            </div>
        )
    )
}

function showNewOrEditApproval(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as ApprovalResponseT

    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step title={'Approval type'} done />
                <Step title={'Approval inputs'} done={checkNodeInputHasValue(currentNode.input, 'inputs')} />
                <Step title={'Remainder and expiry'} done={checkNodeInputHasValue(currentNode.input, 'expiry')} />
                <Step title={'Save'} done={false} />
            </div>
        )
    )
}

function showNewOrEditNotification(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as NotificationResponseT
    var IsValidURL = (url: string) =>
        url.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );

    function hasValue(currentValue: any) {
        return currentValue.value !== undefined &&
            convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text
                .length > 0 &&
            convertToRaw(
                currentValue.value.getCurrentContent()
            ).blocks[0].text.replace(/\s/g, '').length
            ? true
            : false;
    }
    
    function isInputsAreNotDone(){
        let messageHasNotvalue = false;
        let optionsHasNotValue = false;
        let adaptiveFieldHasNotValue = false;
        let adaptiveButtonHasNotValue = false;
        let adaptiveBaseInputs = false;
        if (currentNode.message_type?.id == 'message') {
        messageHasNotvalue =
            !currentNode.actionInputsList.output.input.every(hasValue);
        } else if (currentNode.message_type?.id == 'option') {
        messageHasNotvalue =
            !currentNode.actionInputsList.output.input.every(hasValue);
        optionsHasNotValue =
            currentNode.options.filter((op) => {
            return (
                (op.key.length == 0 && !hasValue(op)) ||
                (op.key.length > 0 && hasValue(op))
            );
            }).length === 0;
        } else if (currentNode.message_type?.id == 'adaptive_card') {
        adaptiveFieldHasNotValue =
            currentNode.adaptivecard.fields.filter((op) => {
            return (
                (op.key.length == 0 && !hasValue(op)) ||
                (op.key.length > 0 && hasValue(op))
            );
            }).length === 0;
        adaptiveButtonHasNotValue =
            currentNode.adaptivecard.buttons.filter((op) => {
            return (
                (op.key.length == 0 && !hasValue(op)) ||
                (op.key.length > 0 && hasValue(op))
            );
            }).length === 0;
        adaptiveBaseInputs =
            currentNode.adaptivecard.title.length === 0 ||
            !hasValue({ value: currentNode.adaptivecard.description }) ||
            (currentNode.adaptivecard.url &&
            IsValidURL(currentNode.adaptivecard.url) === null)?true:false;
        }

        return (
            messageHasNotvalue ||
            optionsHasNotValue ||
            adaptiveFieldHasNotValue ||
            adaptiveButtonHasNotValue ||
            adaptiveBaseInputs
          );
    }

    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step title={'Notification type'} done={currentNode.notification_type!=null} />
                <Step title={'Select bot'} done={currentNode.selectedBot!=null} />
                <Step title={'Chat Channel'} done={currentNode.selectedChatChannel!=null} />
                <Step title={'Notification message'} done={currentNode.selectedChatChannel!=null && !isInputsAreNotDone()} />
                <Step title={'Save'} done={false} />
            </div>
        )
    )
}

function showNewOrEditFunction(node: NodeT, leftPanelState: LeftPanelStateT, expandedView: boolean) {
    const currentNode = leftPanelState.currentNode as FunctionResponseT
    const functionSelected = currentNode.selectedFunction.id.length > 0
    const operationSelected = currentNode.selectedFunction.function_id.length > 0
    const AllrequiredFieldsHasValue =
        currentNode.functionInputsList.output.input.length > 0
            ? seperateOptionalFields(getInputsInNestedLevel(currentNode.functionInputsList.output.input), true).every(
                  hasValue
              )
            : currentNode.functionInputsList.output.input.length == 0 &&
              functionSelected &&
              currentNode.functionInputsList.output.name.length > 0 &&
              true

    return (
        expandedView && (
            <div className="step_border_left_p">
                <Step title={'Select function'} done={functionSelected} />
                <Step title={'Select operation'} done={operationSelected} />
                <Step title={'Input details'} done={AllrequiredFieldsHasValue} />
                <Step title={'Save'} done={false} />
            </div>
        )
    )
}

function hasValue(currentValue: any) {
    // to check whether if any context has been selected for this input then enable save
    return currentValue.config.kind == 'dictionary'
        ? (
              currentValue.config.fields &&
              currentValue.config.fields.filter(
                  (f: any) =>
                      (f.key.length == 0 &&
                          convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks).length == 0) ||
                      (f.key.length > 0 &&
                          convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks).length > 0 &&
                          convertAsaSingleString(convertToRaw(f.value.getCurrentContent()).blocks).replace(/\s/g, '')
                              .length)
              )
          ).length == currentValue.config.fields.length
        : currentValue.inputHasValue || (currentValue && currentValue.value && currentValue.value.length > 0)
}

function Step(props: { title: string; done: boolean; icon?: string }) {
    const { title, done, icon } = props
    return (
        <div className="step_border_left_lists">
            <h6>
                {icon && (
                    <span className="img_left_lists">
                        {' '}
                        <img src={icon} alt="A" />
                    </span>
                )}
                <span className="step_border_left_lists_title">{title}</span>
            </h6>
            <span>{done ? <GreenTickIcon /> : <TickIconEmptyCircle />}</span>
        </div>
    )
}

function ParallelPathlist(props: {
    children: TreeT<NodeT>[]
    leftPanelState: LeftPanelStateT
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
    fromForEach: boolean
    hasparallelChildren?: boolean
    parentFromParallelPath?: boolean
}) {
    const {
        children,
        leftPanelState,
        setCurrentParallelPathActive,
        showNumbering,
        fromForEach,
        hasparallelChildren,
        parentFromParallelPath
    } = props

    const [currentElementToShowChild, setCurrentChild] = useState(0)

    useEffect(() => {
        const hasOnlyOneParallelPaths = children
            .map((child, i) => ({ ...child, index: i }))
            .filter(child => child.value.kind == 'ParallelPath')

        if (
            hasOnlyOneParallelPaths.length == 1 &&
            hasOnlyOneParallelPaths[0].value.name.length == 0 &&
            hasOnlyOneParallelPaths[0].index == currentElementToShowChild
        ) {
            setCurrentChild(
                currentElementToShowChild == 0 ? currentElementToShowChild + 1 : currentElementToShowChild - 1
            )
        }
    }, [children])

    return ParallelPathlistWrapper(
        children.map((node, i) => {
            return (
                <ParallelPathDetailList
                    leftPanelState={leftPanelState}
                    node={node}
                    index={i}
                    key={i}
                    currentElementToShowChild={currentElementToShowChild}
                    setCurrentChild={index => setCurrentChild(index)}
                    setCurrentParallelPathActive={setCurrentParallelPathActive}
                    showNumbering={showNumbering}
                    fromForEach={fromForEach}
                    hasparallelChildren={hasparallelChildren}
                />
            )
        }),
        children,
        currentElementToShowChild,
        parentFromParallelPath
    )
}

const ParallelPathlistWrapper = (
    Elements: React.ReactNode[],
    children: TreeT<NodeT>[],
    currentElementToShowChild: number,
    parentFromParallelPath?: boolean
) => {
    const hasOnlyParallelPaths = children
        .filter(child => child.value.kind == 'ParallelPath')
        .filter(child => child.value.name.length === 0)

    const hasOnlyOneParallelPaths = children
        .map((child, i) => ({ ...child, index: i }))
        .filter(child => child.value.kind == 'ParallelPath')

    return (
        <li
            className={`left_panel_parallel_path tree_node ${
                hasOnlyParallelPaths.length > 1
                    ? 'empty_parallel_paths'
                    : hasOnlyOneParallelPaths.length == 1 && hasOnlyOneParallelPaths[0].value.name.length == 0
                    ? 'margin_bottom_for_end_node'
                    : ''
            }`}
        >
            <div
                className={`tf-tree ${
                    parentFromParallelPath && hasOnlyParallelPaths.length > 1
                        ? 'parallel_path_from_parent_parallel'
                        : hasOnlyParallelPaths.length > 1
                        ? 'parallel_path_from_parent_single'
                        : ''
                }`}
            >
                <ul className="tf-tree-ul">
                    <li className="tf-tree-ul-li">
                        <ul
                            className={`tf-tree-ul-li-ul parallel_path_has_${children.length}childs`}
                            style={
                                hasOnlyParallelPaths.length > 1
                                    ? {
                                          paddingBottom: 15,
                                          left:
                                              parentFromParallelPath && hasOnlyParallelPaths.length > 1
                                                  ? 15
                                                  : hasOnlyParallelPaths.length > 1
                                                  ? -80
                                                  : -90
                                      }
                                    : {}
                            }
                        >
                            {Elements}
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    )
}

function ParallelPathDetailList(props: {
    node: TreeT<NodeT>
    leftPanelState: LeftPanelStateT
    index: number
    currentElementToShowChild: number
    setCurrentChild: (index: number) => void
    setCurrentParallelPathActive?: () => void
    showNumbering: boolean
    fromForEach: boolean
    hasparallelChildren?: boolean
}) {
    const {
        node,
        leftPanelState,
        index,
        currentElementToShowChild,
        setCurrentChild,
        setCurrentParallelPathActive,
        showNumbering,
        fromForEach,
        hasparallelChildren
    } = props

    // const style: any =
    //     index > 0
    //         ? {
    //               display: currentElementToShowChild === index ? 'block' : 'none'
    //           }
    //         : {
    //               display: currentElementToShowChild === index ? 'block' : 'none'
    //           }

    const style: any = {
        display: currentElementToShowChild === index ? 'block' : 'none'
    }

    const swictch = (name: string) => {
        switch (name) {
            case 'New Action':
                return true
            case 'New Condition':
                return true
            case 'New Function':
                return true
            case 'New Aproval':
                return true
            case 'New ForEach':
                return true
            default:
                return false
        }
    }

    return (
        <React.Fragment>
            <li className="tf-tree-li" id={`parallel_path_parent${index}`}>
                <span
                    className={`tf-nc ${
                        index == currentElementToShowChild &&
                        (node.value.kind != 'ParallelPath' || node.value.name.length > 0)
                            ? 'tf-nc-click'
                            : ''
                    }`}
                    onClick={() => {
                        if (node.value.kind !== 'ParallelPath' && leftPanelState.mode.mode == NodeMode.View) {
                            setCurrentChild(index)
                            setCurrentParallelPathActive && setCurrentParallelPathActive()
                        }
                    }}
                >
                    {node.value.kind === 'ParallelPath' ? (
                        <span className="action_plus_icon">
                            <FlowCanvasIcons.ArrowPlusIcon />
                        </span>
                    ) : (
                        <img src={node.value.kind == 'ForEach' ? ForEachNodeIcon : node.value.icon} />
                    )}
                </span>
                {((index == currentElementToShowChild && node.value.kind !== 'ParallelPath') ||
                    (index == currentElementToShowChild &&
                        node.value.kind == 'ParallelPath' &&
                        node.value.name.length > 0)) && <span className="tf-tree-li-span" />}
            </li>
            {(node.value.kind !== 'ParallelPath' || node.value.name.length > 0) && (
                <div className="parallel_path_top" style={style}>
                    <ul className="data_child_ul">
                        <li className="data_child_li">
                            <div className="tree_lines_popup_after_tree">
                                <ul
                                    className={`tree_left tree_left_lines_child ${
                                        node.children.filter(
                                            child => child.value.kind == 'ParallelPath' || swictch(node.value.name)
                                        ).length > 1
                                            ? 'has_two_child'
                                            : node.children.filter(
                                                  child =>
                                                      child.value.kind == 'ParallelPath' || swictch(node.value.name)
                                              ).length == 1
                                            ? 'has_one_child'
                                            : ''
                                    }`}
                                    style={
                                        leftPanelState.parentNode.children.length > 1 &&
                                        node.children.length == 0 &&
                                        swictch(node.value.name)
                                            ? { left: -5 }
                                            : {}
                                    }
                                >
                                    <TreeNodes
                                        className={node.children.length === 1 ? '' : 'parallel_path-parent'}
                                        node={node.value}
                                        leftPanelState={leftPanelState}
                                        index={1}
                                        setCurrentParallelPathActive={() => {
                                            setCurrentParallelPathActive && setCurrentParallelPathActive()
                                            setCurrentChild(index)
                                        }}
                                        showNumbering={showNumbering}
                                        hasOneChildren={fromForEach}
                                        hasparallelChildren={hasparallelChildren}
                                    />
                                    {node.children.length > 0 ? (
                                        node.children.length === 1 ? (
                                            <GenerateChildrenNode
                                                children={node.children}
                                                leftPanelState={leftPanelState}
                                                index={1}
                                                setCurrentParallelPathActive={() => {
                                                    setCurrentParallelPathActive && setCurrentParallelPathActive()
                                                    setCurrentChild(index)
                                                }}
                                                showNumbering={showNumbering}
                                                hasOneChildren={fromForEach ? true : false}
                                                fromForEach={fromForEach}
                                            />
                                        ) : (
                                            <ParallelPathlist
                                                children={node.children}
                                                leftPanelState={leftPanelState}
                                                setCurrentParallelPathActive={() => {
                                                    setCurrentParallelPathActive && setCurrentParallelPathActive()
                                                    setCurrentChild(index)
                                                }}
                                                showNumbering={showNumbering}
                                                fromForEach={fromForEach}
                                                hasparallelChildren={fromForEach && true}
                                                parentFromParallelPath={node.children.length > 1}
                                            />
                                        )
                                    ) : null}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    )
}

function EndStepNode(props: { node: NodeT }) {
    const { leftPanelState } = useContext(LeftSidePanelContext)
    const currentNode = leftPanelState.currentNode as EndResponseT
    const { node } = props
    const [expandedView, makevisible, makehidden] = useDropDown(true)

    const ifCurrentNodeisEditNode =
        leftPanelState.mode.mode === NodeMode.Edit && node.id === leftPanelState.mode.nodeEditInput.id

    const showInSelectedState = ifCurrentNodeisEditNode ? 'selected_parent_node' : ''

    const nodeNotSaved =
        ifCurrentNodeisEditNode ||
        !node.meta_data ||
        (node.meta_data && node.meta_data.successEmail.length === 0 && node.meta_data.failureEmail.length === 0)

    return (
        <li className={'tree_node tree_node_parent send_exection_node'}>
            <label className="tree_label">
                <div className={`step_border_left ${showInSelectedState}`}>
                    <span className="step_border_left_number" />
                    {/* <span className="step_border_left_number">{3}</span> */}
                    <span className="step_border_left_line">
                        <ConnectionLineIcon />
                    </span>
                    <div className="step_border_left_h6" onClick={() => (expandedView ? makehidden() : makevisible())}>
                        <h6>Send Execution Report</h6>
                        {expandedView ? (
                            <span className="show_arrow_as_up">
                                <DownArrowCircle />
                            </span>
                        ) : (
                            <DownArrowCircle />
                        )}
                    </div>
                    {expandedView && (
                        <div className="step_border_left_p">
                            <Step
                                title={'Success report'}
                                done={
                                    ifCurrentNodeisEditNode
                                        ? currentNode.reportMeta.successEmail.valid
                                        : node.meta_data && node.meta_data.successEmail.length > 0
                                }
                            />
                            <Step
                                title={'Failure report'}
                                done={
                                    ifCurrentNodeisEditNode
                                        ? currentNode.reportMeta.failureEmail.valid
                                        : node.meta_data && node.meta_data.failureEmail.length > 0
                                }
                            />
                            <Step title={nodeNotSaved ? 'Save' : 'Saved'} done={!nodeNotSaved && true} />
                        </div>
                    )}
                </div>
            </label>
        </li>
    )
}

export function checkNodeInputHasValue(inputs: InputFieldT[], type: 'all' | 'inputs' | 'expiry') {
    function hasValue(currentValue: any) {
        // to check whether if any context has been selected for this input then enable save
        if (configKinds.some(value => value === currentValue.config.kind)) {
            return currentValue.value.length > 0 || typeof currentValue.value == 'number'
        }
        return typeof currentValue.value == 'string'
            ? currentValue.value.length > 0
            : typeof currentValue.value === 'object'? Object.keys(currentValue.value).length>0 :currentValue.value !== undefined &&
              convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.length > 0 &&
              convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length
            ? true
            : false
    }

    function hasNotValue(currentValue: any) {
        // console.log('currentValue', currentValue
        return typeof currentValue.value == 'string'
            ? currentValue.value.length == 0
            :  typeof currentValue.value === 'object'? Object.keys(currentValue.value).length == 0 : (currentValue.value !== undefined &&
                  convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.length == 0) ||
              (currentValue.value !== undefined &&
                  convertToRaw(currentValue.value.getCurrentContent()).blocks[0].text.replace(/\s/g, '').length === 0)
            ? true
            : false
    }

    const inputsValues = inputs.slice(0, 7),
        enumDropdown = inputs.slice(7, 10) as any

    const AllrequiredFieldsHasValue = !seperateOptionalFields(inputsValues as any, true).every(hasValue)
    const AllEnumFieldsHasValue = !enumDropdown.every(hasValue)
    const AllOptionalFieldsHasNotValue = seperateOptionalFields(inputsValues as any, false)
        .map(hasNotValue)
        .filter(s => s)

    switch (type) {
        case 'all':
            return !AllrequiredFieldsHasValue && !AllEnumFieldsHasValue && AllOptionalFieldsHasNotValue.length != 1
        case 'expiry':
            return !AllEnumFieldsHasValue
        default:
            return !AllrequiredFieldsHasValue && AllOptionalFieldsHasNotValue.length != 1
    }
}
