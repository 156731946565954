import React, { useEffect, useState } from 'react';
import {
  NotificationEvent,
  SessionEvents,
  UserConnectEvent,
} from './LiveAgentTypes';
import { Modal } from '@/common/components/Modal';
import { Paragraph } from '@/common/styled/Dialogue.Dumb';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  AddTagT,
  AgentT,
  BotsLA,
  initialNotificationEvent,
  notificationEvent,
  Sessions,
} from './LiveagentMockFile';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CloseBtnWithCircle, CloseIconWithCircle } from '@/common/Icons/Icons';
import { getCurrentUserInfo } from '@/common/utils/utils';
import { useQuery } from 'react-query';
import { getBaseAPI, useUpdateAgent } from './LivaAgentApi';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { AgentDetailsT, useAllAgents } from '@/ManageUsers/v2/manageUsersApi';
import { UserApi } from '@/DialogEditor/Canvas/Components/GetStarted';
import Axios from 'axios';
export const ButtonWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: baseline;
  margin-top: 0px;
  gap: 10px; ${Button} {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bebebe;
    border-radius: 10px;
    label {
      color: #333;
    }
    :hover {
      background: #d3e0fd 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #3a72f0;
    }
  }
`;
function LiveAgentNotification(props: {
  msg: any[];
  bots: BotsLA[];
  workspace: string;
  tabBot: string;
  deleteNotification: (event: NotificationEvent) => void;
  transferAgent: (
    newAgentID: number,
    agentId: number,
    sessionId: string,
    team_id:string| undefined
  ) => void;
  user: UserApi;
}) {
  const [msgArray, setMsgArray] = useState<NotificationEvent[]>([]);
  const [userDetails, setUserDetails] = useState<AgentDetailsT>();
  const { AllAgentsData, agentsFetching } = useAllAgents(props.workspace);

  useEffect(() => {
    setMsgArray(props.msg);
  console.log(props.msg, msgArray,userDetails, 'askjdfhjkas');

    if (AllAgentsData) {
  console.log(props.msg, msgArray,userDetails, 'askjdfhjkas');

      const currentUserDetail = AllAgentsData.filter(
        (ag) => ag.auth_user_id === props.user.id
      )[0];
      setUserDetails(currentUserDetail);
    }
  }, [props.msg, AllAgentsData]);

  const navigate = useNavigate();
  const [event, setEvent] = useState<NotificationEvent | null>(null);
  const botName = (id: string) => {
    return props.bots.filter((b) => b.id === id)[0]?.name;
  };
  console.log(props.msg, msgArray,userDetails,event, 'askjdfhjkas');
  // const successEvent = () => {
  //   console.log(event,"isEvent")
  //   if(event){
  //     console.log(event,"isEvent")
  //   navigate(
  //     `/workspace/${props.workspace}/live-agent/${props.tabBot}/open-sessions/${event.data.chat_id}`
  //   );
  //   props.deleteNotification(event);
  //   }
  //   setError(false);
  // }
  const [error, setError] = useState<boolean>(false);
  // const { updateAgent } = useUpdateAgent(()=>
  //   // successEvent(),
  //   {
  //     console.log(event,"isEvent")
  //     if(event){
  //       console.log(event,"isEvent")
  //     navigate(
  //       `/workspace/${props.workspace}/live-agent/${props.tabBot}/open-sessions/${event.data.chat_id}`
  //     );
  //     props.deleteNotification(event);
  //     }
  //     setError(false);
  //   },
  //   () => {
  //     // setAgentDropdown(false);
  //     setError(true);
  //   }
  // );
  // const {
  //   data: currentUser,
  //   isLoading: currentUserInfoLoading,
  //   isError: currUserInfoIsErr,
  //   error: currUserInfoErr,
  // } = useQuery(`currentUser`, () => getCurrentUserInfo());
 const eventSetting =(msg:NotificationEvent)=>{
  setEvent(()=>{
    return msg
  });
 }
  const transferApi = (
    agentData: UserApi,
    sessionData: NotificationEvent
  ) => {
    console.log(agentData, sessionData, event,'ashdkfjahsdkj');
    if (sessionData.data.session_id !== agentData.id.toString()) {
      const tagValue = {
        data:{
          agent_id: userDetails ? userDetails.auth_user_id.toString() :"",
          team_id: sessionData.data.accessible_teams[0],
          status: 'InProgress',
        },
        workspace: props.workspace,
        sessionID: sessionData.data.session_id,
      };
      const agentId = 0
      props.transferAgent(agentData.id, agentId, sessionData.data.session_id, sessionData.data.accessible_teams[0]);
      Axios.patch(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${tagValue.sessionID}`,tagValue.data)
        .then((res)=>{
          navigate(
            `/workspace/${props.workspace}/live-agent/${props.tabBot}/open-sessions/${sessionData.data.chat_id}`
          );
          props.deleteNotification(sessionData);
          setError(false);
        }).catch((err)=>{
          setError(true);
        }

        )
      // updateAgent(tagValue);
    }
  };
  return (
    <div className="liveAgent_notification">
      {msgArray.length > 0
        ? msgArray.map((msg, index) => (
            <div className="liveAgent_notification_content">
              <h6>Incoming Chat</h6>
              <span
                className="liveAt__notifi_close_icon"
                onClick={() => props.deleteNotification(msg)}
              >
                <CloseIconWithCircle />
              </span>
              <span>
                <b>{`${msg.data.name}`}</b> waiting in queue from{' '}
                <b>{`${botName(msg.data.bot_id)}.`}</b>
              </span>{' '}
              <ButtonWrapper style={{gap: '8px'}}>
                <Button
                  className="notifi_btn"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    transferApi(props.user, msg);
                    eventSetting(msg)
                  }}
                >
                  <label>Connect</label>
                </Button>
                <Button
                  className="notifi_btn"
                  type="button"
                  onClick={() =>
                    navigate(
                      `/workspace/${props.workspace}/live-agent/${props.tabBot}/all/${msg.data.chat_id}`
                    )
                  }
                >
                  <label>View Chat</label>
                </Button>
                {/* <Button
            primary
            type="button"
            // onClick={() => {
            //   setClosePopup(false);
            //   setPageState({
            //     action: ActionT.VIEW,
            //   });
            //   setError(false);
            // }}
          >
            <label> Skip to next agent</label>
          </Button> */}
                {error && (
                  <span className="connect_account_failed">
                    <div className="error_input_show error_input_show_add teams_page_alert">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        Something went wrong
                      </span>
                    </div>
                  </span>
                )}
              </ButtonWrapper>
            </div>
          ))
        : null}
    </div>
  );
}

export default LiveAgentNotification;
