import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  DifferntTriggers,
  NavLinkT,
  WorkflowBaseRoutes,
  getSideBarLinkTree,
  subMenu,
} from '@/SidePanel/SideBarTypes';

const rawLink = getSideBarLinkTree();

export const dialogNavlink: NavLinkT = {
  displayText: 'Assistant',
  icon: () => <></>,
  to: 'assistant/homepage',
  active: true,
  submenu: [
    {
      displayText: 'Conversations',
      icon: () => <></>,
      to: `/conversations`,
      active: true,
    },
    {
      displayText: 'Intent',
      icon: () => <></>,
      to: `/intent`,
      active: true,
    },
    {
      displayText: 'Entity',
      icon: () => <></>,
      to: `/entity`,
      active: true,
    },
    {
      displayText: 'Chat History',
      icon: () => <></>,
      to: `/chat-history`,
      active: true,
    },
    {
      displayText: 'Train Chatbot',
      icon: () => <></>,
      to: `/train-assistant`,
      active: true,
    },
    {
      displayText: 'Dashboard',
      icon: () => <></>,
      to: `/dashboard`,
      active: true,
    },
    {
      displayText: 'Agent Handover',
      icon: () => <></>,
      to: `/agent-handover`,
      active: true,
    },
    {
      displayText: 'Settings',
      icon: () => <></>,
      to: `/settings`,
      active: true,
    },
  ],
};

export const automationNavlink: NavLinkT = {
  displayText: 'Automation',
  icon: () => <></>,
  to: 'automation/view/active',
  active: true,
  submenu: [
    ...WorkflowBaseRoutes,
    ...DifferntTriggers,
  ],
};


const links = [...rawLink.slice(0, 1), dialogNavlink, automationNavlink, ...rawLink.slice(1)];

export const useSubTabHook = ({
  url,
  match,
}: {
  url: string;
  match: {
    params: { assistantname: string; workspacename: string, view?:string };
    path: string;
    url: string;
  };
}) => {
  const { workspacename, assistantname } = match.params;
  const [currentSubTab, setCurrentSubTab] = useState<string>('');

  useEffect(() => {
    const { defaultDisplayText, defaultSublink } = (() => {
      const splittedPath = match.path.split('/');
      if (splittedPath.length > 0 && splittedPath[0] !== '*') {
        const defaultRoute = links.filter(
          (x) =>
            x.displayText.toLowerCase() ===
            (!splittedPath[3].includes('-')
              ? splittedPath[3].toLowerCase()
              : splittedPath[3].replace('-', ' ').toLowerCase())
        );

        if (defaultRoute.length > 0) {
          const subMenu = defaultRoute[0].submenu.filter((menu) => {
            return match.params.view ? match.params.view == menu.to.toLowerCase() : match.url.toLowerCase().includes(menu.to.toLowerCase());
          });
          // console.log(subMenu, 'subMenu', defaultRoute[0].submenu, match.url);
          return {
            defaultDisplayText: defaultRoute[0].displayText,
            defaultSublink:
              subMenu.length > 0 ? subMenu[0].displayText.replace(/ /g, "-").toLowerCase() : '',
          };
        } else {
          return {
            defaultDisplayText: 'Dialog',
            defaultSublink: '',
          };
        }
      }
      return {
        defaultDisplayText: 'Dialog',
        defaultSublink: '',
      };
    })();
    setCurrentSubTab(defaultSublink);
  }, [match]);

  return [currentSubTab];
};
