/*eslint-disable*/
import * as React from 'react'
/*eslint-disable*/
import * as ReactDOM from 'react-dom'

import { ModalPopupWrapper } from '../styled/Dialogue.Dumb'
import { getProductId } from '@/common/utils/utils'
import { Loader } from './Loader'

type ModalT = {
    showPopupLoader?: boolean
    error?: any
    close?: any
    className?: string
}

const usePortal = (rootElementId: string) => {
    const portalContainerRef = React.useRef<HTMLDivElement | null>(null)

    React.useEffect(() => {
        const rootElement = document.querySelector(`#${rootElementId}`)
        if (rootElement && portalContainerRef.current) {
            rootElement.appendChild(portalContainerRef.current)
        }

        return () => {
            if (portalContainerRef.current) {
                portalContainerRef.current.remove()
            }
        }
    }, [])

    function getRootElem() {
        if (!portalContainerRef.current) {
            portalContainerRef.current = document.createElement('div')
        }
        return portalContainerRef.current
    }
    return getRootElem()
}

const ModalPortal: React.FunctionComponent = ({ children }) => {
    const portalContainer = usePortal('modal-root')
    if (!portalContainer) {
        return null
    }
    return ReactDOM.createPortal(children, portalContainer)
}

export const Modal: React.FunctionComponent<ModalT> = props => {
    const popupRef = React.useRef<HTMLDivElement | null>(null)
    const { error } = props.error ? props.error : { error: false }
    const { showPopupLoader, className } = props

    return (
        <ModalPortal>
            <ModalPopupWrapper>
                <div className= {getProductId() === "ASSISTANT" ? `flow_popup1 ${className ? className : ''}` : `flow_popup1 automate ${className ? className : ''}`}>
                    <div data-ml-modal="">
                        <a className="modal-overlay" style={{ backgroundColor: 'transparent' }} onClick={props.close} />
                        {!error && !showPopupLoader ? (
                            <div className="modal-dialog" ref={popupRef}>
                                <div className="modal-content center">{props.children}</div>
                            </div>
                        ) : (
                            <div className="modal-dialog" ref={popupRef}>
                                <div
                                    className="modal-content center modal_error_content"
                                    style={{
                                        height: popupRef.current
                                            ? popupRef.current.offsetHeight
                                            : props.children
                                            ? 'auto'
                                            : 150
                                    }}
                                >
                                    {!showPopupLoader ? props.children : <Loader.PopupLoader show={showPopupLoader} />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ModalPopupWrapper>
        </ModalPortal>
    )
}

export const AlternateModal: React.FunctionComponent<ModalT> = props => {
    const popupRef = React.useRef<HTMLDivElement | null>(null)
    const { error } = props.error ? props.error : { error: false }
    const { showPopupLoader } = props

    return (
        <ModalPortal>
            <ModalPopupWrapper>
                <div className="flow_popup_3">
                    <div data-ml-modal="">
                        <a className="modal-overlay" style={{ backgroundColor: 'transparent' }} onClick={props.close} />
                        {!error && !showPopupLoader ? (
                            <div className="modal-dialog" ref={popupRef}>
                                <div className="modal-content center">{props.children}</div>
                            </div>
                        ) : (
                            <div className="modal-dialog" ref={popupRef}>
                                <div
                                    className="modal-content center modal_error_content"
                                    style={{
                                        height: popupRef.current
                                            ? popupRef.current.offsetHeight
                                            : props.children
                                            ? 'auto'
                                            : 150
                                    }}
                                >
                                    {!showPopupLoader ? props.children : <Loader.PopupLoader show={showPopupLoader} />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ModalPopupWrapper>
        </ModalPortal>
    )
}
export const StrippedModal: React.FunctionComponent<ModalT> = props => {
    const popupRef = React.useRef<HTMLDivElement | null>(null)
    const { error } = props.error ? props.error : { error: false }
    const { showPopupLoader } = props

    return (
        <ModalPortal>
            <ModalPopupWrapper
                onClick={(e: any) => {
                    e.stopPropagation()
                }}
            >
                <div className="flow_popup1">
                    <div data-ml-modal="">
                        <a className="modal-overlay" style={{ backgroundColor: 'transparent' }} onClick={props.close} />
                        {!error && !showPopupLoader ? (
                            <div className="stripped-modal-dialog" ref={popupRef}>
                                <div className="stripped-modal-content modal-content  center">{props.children}</div>
                            </div>
                        ) : (
                            <div className="stripped-modal-dialog" ref={popupRef}>
                                <div
                                    className="stripped-modal-content modal-content center modal_error_content"
                                    style={{
                                        height: popupRef.current
                                            ? popupRef.current.offsetHeight
                                            : props.children
                                            ? 'auto'
                                            : 150
                                    }}
                                >
                                    {!showPopupLoader ? props.children : <Loader.PopupLoader show={showPopupLoader} />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ModalPopupWrapper>
        </ModalPortal>
    )
}
export const UpgradeModal: React.FunctionComponent<ModalT> = props => {
    const popupRef = React.useRef<HTMLDivElement | null>(null)
    const { error } = props.error ? props.error : { error: false }
    const { showPopupLoader } = props

    return (
        <ModalPortal>
            <div className="flow_popup1 upgrade_modal_popup">
                <div data-ml-modal="">
                    <a className="modal-overlay" style={{ backgroundColor: 'transparent' }} onClick={props.close} />
                    {!error && !showPopupLoader ? (
                        <div className="modal-dialog" ref={popupRef}>
                            <div className="modal-content center">{props.children}</div>
                        </div>
                    ) : (
                        <div className="modal-dialog" ref={popupRef}>
                            <div
                                className="modal-content center modal_error_content"
                                style={{
                                    height: popupRef.current
                                        ? popupRef.current.offsetHeight
                                        : props.children
                                        ? 'auto'
                                        : 150
                                }}
                            >
                                {!showPopupLoader ? props.children : <Loader.PopupLoader show={showPopupLoader} />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ModalPortal>
    )
}
export const ModalSnippet: React.FunctionComponent<ModalT> = props => {
    const popupRef = React.useRef<HTMLDivElement | null>(null)
    const { error } = props.error ? props.error : { error: false }
    const { showPopupLoader } = props

    return (
        <ModalPortal>
            <div className="flow_popup1 modal_snippet">
                <div data-ml-modal="">
                    <a className="modal-overlay" style={{ backgroundColor: 'transparent' }} onClick={props.close} />
                    {!error && !showPopupLoader ? (
                        <div className="modal-dialog" ref={popupRef}>
                            <div className="modal-content center">{props.children}</div>
                        </div>
                    ) : (
                        <div className="modal-dialog" ref={popupRef}>
                            <div
                                className="modal-content center modal_error_content"
                                style={{
                                    height: popupRef.current
                                        ? popupRef.current.offsetHeight
                                        : props.children
                                        ? 'auto'
                                        : 150
                                }}
                            >
                                {!showPopupLoader ? props.children : <Loader.PopupLoader show={showPopupLoader} />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ModalPortal>
    )
}
