/*eslint-disable*/
import React, { Suspense, useRef } from 'react'
import { QueryClient } from 'react-query'

import { JourneyState } from '@/Journey/Journey'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import Validate, { ErrorContext } from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { Loader } from '@/common/components/Loader'
import { AlternateModal, Modal } from '@/common/components/Modal'
import lazyGA from '@/common/google-analytics/tracking'
import { Button, PopupButton } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonContainer } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper, ButtonWrapperAlternate, Paragraph } from '@/common/styled/Dialogue.Dumb'
import {
    ErrorMessage,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Textarea
} from '@/common/styled/Workflow.Analytics.Dumb'
import {
    flowValidations,
    inputTextAreaValidations,
    inputTextAreaValidationsForWorkflow,
    newLine
} from '@/common/utils/_validation-utils'

import { AutomationApi, automationAPIURLWithWorkspace, getJson, logEvent, postJson, putJson } from '@/common/utils/api-utils'
import {
    ActivateAutomation,
    AutomationMeta,
    DeActivateAutomation,
    DeleteConnection,
    DeleteConnectionT,
    Deploy,
    DeployAcknowledgement,
    DeployT,
    EditAutomationMeta,
    EditAutomationMetaT,
    NonCanvasActionModes,
    NonCanvasActions,
    None,
    ReactSetStateObject,
    SaveAs,
    SaveAsT
} from './types'
import { FlowIcon } from '@/common/Icons/Icons'
import { getDuplicateName } from '../utils'
import { FlowResponseT } from '../types'

let Parser = require('html-react-parser')

export function automationEditMetaAction(
    automationId: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    [automationMeta, setAutomationMeta]: ReactSetStateObject<AutomationMeta>,
    workspace: string,
    queryClient: QueryClient
) {
    const setEditAutomationMeta = () => {
        setNonCanvasAction({
            ...EditAutomationMeta,
            name: automationMeta.name,
            description: automationMeta.description
        })
    }
    const onNameChange = (name: string) => {
        const nonCanvasActionCurrent = nonCanvasAction as EditAutomationMetaT
        nonCanvasActionCurrent.error.error
            ? setNonCanvasAction({
                  ...nonCanvasActionCurrent,
                  name,
                  error: {
                      error: false,
                      info: ''
                  }
              })
            : setNonCanvasAction({
                  ...nonCanvasActionCurrent,
                  name
              })
    }
    const onDescriptionChange = (description: string) => {
        const nonCanvasActionCurrent = nonCanvasAction as EditAutomationMetaT

        setNonCanvasAction({
            ...nonCanvasActionCurrent,
            description
        })
    }
    async function updateAutomationMeta() {
        const nonCanvasActionCurrent = nonCanvasAction as EditAutomationMetaT
        try {
            const nonCanvasActionCurrent = nonCanvasAction as EditAutomationMetaT
            await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Update))()({
                name: nonCanvasActionCurrent.name,
                description: nonCanvasActionCurrent.description,
                id: automationId
            })
            lazyGA().event({
                category: 'Automation',
                action: 'Update Automation'
            })
            setAutomationMeta({
                ...automationMeta,
                name: nonCanvasActionCurrent.name,
                description: nonCanvasActionCurrent.description
            })
            setNonCanvasAction(None)
            queryClient.invalidateQueries('workflows')
            queryClient.invalidateQueries(`workflow::${automationId}`)
        } catch (error) {
            let err = error as any
            setNonCanvasAction({
                ...nonCanvasActionCurrent,
                error: { error: true, info: err.response ? err.response.data.error : err.toString() }
            })
        }
    }
    const renderMetaEdit = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.EditAutomationMeta) {
            return (
                <Modal>
                    <Validate defaultValue={{ flowName: true, flowDescription: true }}>
                        <div className="alert_popup_message">
                            <h2>Edit Workflow</h2>
                            <Paragraph margin>Enter name and description</Paragraph>
                            <InputBox
                                validationList={flowValidations}
                                value={nonCanvasAction.name}
                                name="flowName"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                            <InputWrapper>
                                                <InputContainer>
                                                    <Input
                                                        type="text"
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowName', event.target.value)
                                                            onNameChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <InputContent>Automation Name</InputContent>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <InputBox
                                validationList={inputTextAreaValidationsForWorkflow}
                                value={nonCanvasAction.description}
                                name="flowDescription"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                            <InputWrapper>
                                                <InputContainer>
                                                    <Textarea
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowDescription', event.target.value)
                                                            onDescriptionChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <InputContent>Automation Description</InputContent>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <p className="error_msg">
                                {nonCanvasAction.error.error ? nonCanvasAction.error.info : newLine}
                            </p>
                            <ButtonWrapper>
                                <Button
                                    primary
                                    onClick={() => {
                                        setNonCanvasAction(None)
                                    }}
                                >
                                    <label> Cancel</label>
                                </Button>
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <Button
                                                disabled={
                                                    !valid.formValid.formValid ||
                                                    (nonCanvasAction.error.error ? true : false)
                                                }
                                                onClick={() => {
                                                    updateAutomationMeta()
                                                }}
                                                className={
                                                    !valid.formValid.formValid ||
                                                    (nonCanvasAction.error.error ? true : false)
                                                        ? 'editor_btn_disabled'
                                                        : ''
                                                }
                                            >
                                                <label>Save</label>
                                            </Button>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </ButtonWrapper>
                        </div>
                    </Validate>
                </Modal>
            )
        }
        return null
    }
    return [setEditAutomationMeta, renderMetaEdit]
}
export function automationDeployAction(
    automationId: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    [automationMeta, setAutomationMeta]: ReactSetStateObject<AutomationMeta>,
    workspace: string,
    queryClient: QueryClient
    // journey: JourneyState
): [() => void, (type: string) => any] {
    const setToDeployAutomationMode = () => {
        setNonCanvasAction(Deploy)
    }
    const deployAutomation = async (type: string) => {
        const deployAction = nonCanvasAction as DeployT
        try {
            await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Deploy))()({
                deployed_status: 'deployed',
                type: type,
                id: automationId
            })
            lazyGA().event({
                category: 'Automation',
                action: 'Deploy Automation'
            })
            setNonCanvasAction(DeployAcknowledgement)
            setAutomationMeta({
                ...automationMeta,
                deployed_status: 'deployed'
            })
            queryClient.invalidateQueries('workflows')
            queryClient.invalidateQueries(`workflow::${automationId}`)
        } catch (error) {
            let err = error as any
            setNonCanvasAction({
                ...deployAction,
                error: {
                    error: true,
                    info: err.response.data.error //err.toString()
                }
            })
        }
    }
    const renderDeployAutomation = (type: string) => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Deploy) {
            return (
                <Modal>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Deploy Workflow </h2>
                        <Paragraph>Are you sure to deploy this workflow? </Paragraph>
                        <ButtonWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    deployAutomation(type)
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else return null
    }
    return [setToDeployAutomationMode, renderDeployAutomation]
}

export function automationActivateAction(
    automationId: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    [automationMeta, setAutomationMeta]: ReactSetStateObject<AutomationMeta>,
    workspace: string,
    journey: JourneyState,
    queryClient: QueryClient
): [() => void, (type: string) => any] {
    const setToActivateAutomationMode = () => {
        setNonCanvasAction(ActivateAutomation)
    }
    const activateAutomation = async (type: string) => {
        const deployAction = nonCanvasAction as DeployT

        try {
            await Promise.resolve(1)
            await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Deploy))()({
                deployed_status: 'deployed',
                type: type,
                id: automationId
            })

            await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Deploy))()({
                id: automationId,
                active_status: true,
                type: type
            })
            // await Promise.reject('Error')
            lazyGA().event({
                category: 'Automation',
                action: 'Deploy Automation'
            })
            logEvent(workspace, {
                event:"EVENT::WORKFLOW::ACTIVATE",event_meta:{
               name: automationMeta.name,
               description: automationMeta.description
            }})
            setNonCanvasAction(DeployAcknowledgement)
            // setAutomationMeta({
            //     ...automationMeta,
            //     deployed_status: 'deployed'
            // })
            setAutomationMeta({
                ...automationMeta,
                deployed_status: 'deployed',
                active_status: true
            })
            setNonCanvasAction(None)
            queryClient.invalidateQueries('workflows')
        } catch (error) {
            let err = error as any
            const errorResponse = err.response
            if (
                errorResponse &&
                errorResponse.data &&
                errorResponse.data.type == 'error' &&
                errorResponse.data.tag &&
                errorResponse.data.tag == 'RESOURCE_CONSUMPTION_LIMIT_EXCEEDED'
            ) {
                setNonCanvasAction({
                    ...deployAction,
                    error: {
                        error: true,
                        info: 'Maximum limit reached, deactivate an active automation to make this workflow active' //err.toString()
                    }
                })
            } else {
                setNonCanvasAction({
                    ...deployAction,
                    error: {
                        error: true,
                        info:
                            err && err.response && err.response.data && err.response.data.error
                                ? err.response.data.error
                                : 'Something went wrong' //err.toString()
                    }
                })
            }
        }
    }
    const renderDeployAutomation = (type: string) => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Activate) {
            return (
                <Modal>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Activate Workflow </h2>
                        <Paragraph>Are you sure to activate this workflow? </Paragraph>
                        <ButtonWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    activateAutomation(type)
                                    if (journey.type === 'success') {
                                        journey.value.send({
                                            type: 'EVENT::WORKFLOW::ACTIVATED',
                                            tag: 'WORKFLOW_GENERIC_EVENT',
                                            journeyType: 'Workflow'
                                        })
                                    }
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else return null
    }
    return [setToActivateAutomationMode, renderDeployAutomation]
}

export function automationDeActivateAction(
    automationId: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    [automationMeta, setAutomationMeta]: ReactSetStateObject<AutomationMeta>,
    workspace: string,
    journey: JourneyState,
    queryClient: QueryClient
): [() => void, (type: string) => any] {
    const setToDeActivateAutomationMode = () => {
        setNonCanvasAction(DeActivateAutomation)
    }
    //     active_status: true
    // id: "75b60dfd-bc08-484d-bf5e-15a6dfc53933"
    // type: "no_trigger"
    // deployed_status: "deployed"
    // id: "76cc018f-1bc1-4bd9-ab9c-d4844d64f7dd"
    // type: "no_trigger"
    const deactivateAutomation = async (type: string) => {
        const deployAction = nonCanvasAction as DeployT

        try {
            // await Promise.resolve(1)

            await putJson(automationAPIURLWithWorkspace(workspace, AutomationApi.Deploy))()({
                id: automationId,
                active_status: false,
                type: type
            })
            // await Promise.reject('Error')
            lazyGA().event({
                category: 'Automation',
                action: 'Deploy Automation'
            })
            setNonCanvasAction(DeployAcknowledgement)
            setAutomationMeta({
                ...automationMeta,
                deployed_status: 'deployed',
                active_status: false
            })
            setNonCanvasAction(None)
            queryClient.invalidateQueries('workflows')
        } catch (error) {
            let err = error as any
            const errorResponse = err.response

            setNonCanvasAction({
                ...deployAction,
                error: {
                    error: true,
                    info:
                        err && err.response && err.response.data && err.response.data.error
                            ? err.response.data.error
                            : 'Something went wrong' //err.toString()
                }
            })
        }
    }
    const renderDeactivateAutomation = (type: string, linkedWithDialog?: boolean, props?: any) => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Deactivate) {
            if (linkedWithDialog) {
                const { dialog, automationName, workspacename, history } = props

                return (
                    <AlternateModal>
                        <div className="dialog-link-modal">
                            <div className="center_automation_popup">
                                {/* <h4 className="center_popup_automation_h4">
                                    Are you sure to deactivate {automationName}?
                                </h4> */}
                                {/* <Paragraph>
                                    This automation is linked with these dialogs, You must delete {automationName} in
                                    Dialog Designer first.
                                </Paragraph> */}
                                <h4 className="center_popup_automation_h4">
                                    Deactivate {automationName}?
                                </h4>
                                <Paragraph>
                                This worflow is being used with the following dialogs below.
                                Please delete {automationName} in chatbot builder first.
                                </Paragraph>
                                
                                <ButtonWrapperAlternate>
                                    <PopupButton
                                        primary
                                        small
                                        center
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                    >
                                        <label>Cancel</label>
                                    </PopupButton>
                                    {dialog.map((e: any, i: number) => (
                                        <PopupButton
                                            key={i}
                                            onClick={() => {
                                                // console.log('e', e)
                                                //workspace/nirup/dialog/Test/detail/bb7dcc84-60a6-475b-b48d-6998a790ee9f
                                                history.push(
                                                    `/workspace/${workspacename}/assistant/${e.botName}/dialog/${e.id}`
                                                )
                                            }}
                                            className="w-100"
                                        >
                                            {/* <span>
                                                {' '}
                                                <ArrowIcon />{' '}
                                            </span> */}
                                            <label>{e.title}</label>
                                        </PopupButton>
                                    ))}
                                </ButtonWrapperAlternate>
                            </div>
                        </div>
                    </AlternateModal>
                )
            }
            return (
                <Modal>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <h2>Deactivate Workflow </h2>
                        <Paragraph>Are you sure to deactivate this workflow? </Paragraph>
                        <ButtonWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    deactivateAutomation(type)
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else return null
    }
    return [setToDeActivateAutomationMode, renderDeactivateAutomation]
}

export function automationCopyAction(
    automationId: string,
    redirectToAutomation: (id: string) => void,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    [automationMeta, setAutomationMeta]: ReactSetStateObject<AutomationMeta>,
    workspace: string,
    queryClient: QueryClient
) {

    const queryData = queryClient.getQueryData(['workflows']) as {data:{output:{automations:FlowResponseT[]}}}
    const setToSaveAsAutomationMode = () => {
        const name = getDuplicateName(automationMeta.name,queryData.data.output.automations)

        setNonCanvasAction({ ...SaveAs, name:name, description: automationMeta.description })
    }
    const copyAutomation = async () => {
        const saveAsAction = nonCanvasAction as SaveAsT
        try {
            lazyGA().event({
                category: 'Automation',
                action: 'Copy Automation'
            })
            const response = await postJson(automationAPIURLWithWorkspace(workspace, AutomationApi.SaveAs))({
                description: automationMeta.description,
                name: saveAsAction.name,
                id: automationId
            })
            
            const createdId = response.data.output

            logEvent(workspace, {
                event:"EVENT::WORKFLOW::DUPLICATE",event_meta:{
                name:saveAsAction.name,
                description:automationMeta.description
            }}).finally(()=>{
                setNonCanvasAction(None)
                queryClient.invalidateQueries('workflows')
                queryClient.invalidateQueries(`workflow::${automationId}`)    
                redirectToAutomation(createdId)
            })
        } catch (error) {
            let err = error as any
            setNonCanvasAction({
                ...saveAsAction,
                error: {
                    error: true,
                    info: err.response ? err.response.data.error : err.toString()
                }
            })
        }
    }
    const onNameChange = (name: string) => {
        const nonCanvasActionCurrent = nonCanvasAction as SaveAsT

        // setNonCanvasAction({
        //     ...nonCanvasActionCurrent,
        //     name
        // })

        nonCanvasActionCurrent.error.error
            ? setNonCanvasAction({
                  ...nonCanvasActionCurrent,
                  name,
                  error: {
                      error: false,
                      info: ''
                  }
              })
            : setNonCanvasAction({
                  ...nonCanvasActionCurrent,
                  name
              })
    }
    const onDescriptionChange = (description: string) => {
        const nonCanvasActionCurrent = nonCanvasAction as EditAutomationMetaT

        setNonCanvasAction({
            ...nonCanvasActionCurrent,
            description
        })
    }
    const renderSaveAsAutomation = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.SaveAs) {
            return (
                <Modal>
                    <Validate defaultValue={{ flowName: true, flowDescription: true }}>
                        <div className="alert_popup_message">
                            <h2>Save Workflow as</h2>
                            <Paragraph>Workflow will be saved under Drafts</Paragraph>
                            <InputBox
                                validationList={flowValidations}
                                value={nonCanvasAction.name}
                                name="flowName"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <InputWrapper style={{marginBottom: 0}}>
                                                <InputContainer>
                                                    <Input
                                                        type="text"
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowName', event.target.value)
                                                            onNameChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                    <InputContent>Automation Name</InputContent>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <InputBox
                                validationList={inputTextAreaValidations}
                                value={nonCanvasAction.description}
                                name="flowDescription"
                                render={(
                                    value: string,
                                    valid: boolean,
                                    errorMsg: string,
                                    startValidation: () => void,
                                    handleInput: Function
                                ) => {
                                    return (
                                        <React.Fragment>
                                            <InputWrapper>
                                                <InputContainer>
                                                    <Textarea
                                                        primary={!valid ? `primary` : ''}
                                                        onChange={(event: any) => {
                                                            startValidation()
                                                            handleInput('flowDescription', event.target.value)
                                                            onDescriptionChange(event.target.value)
                                                        }}
                                                        value={value}
                                                    />
                                                    <InputContent>Automation Description</InputContent>
                                                    <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage>
                                                </InputContainer>
                                            </InputWrapper>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <p className="error_msg">{nonCanvasAction.error.error ? nonCanvasAction.error.info : ''}</p>
                            <ButtonWrapper>
                                <Button
                                    primary
                                    onClick={() => {
                                        setNonCanvasAction(None)
                                    }}
                                >
                                    <label>Cancel</label>
                                </Button>
                                <ErrorContext.Consumer>
                                    {valid => {
                                        return (
                                            <Button
                                                disabled={
                                                    !valid.formValid.formValid ||
                                                    (nonCanvasAction.error.error ? true : false)
                                                }
                                                onClick={() => {
                                                    copyAutomation()
                                                }}
                                            >
                                                <label>Save As</label>
                                            </Button>
                                        )
                                    }}
                                </ErrorContext.Consumer>
                            </ButtonWrapper>
                        </div>
                    </Validate>
                </Modal>
            )
        } else return null
    }
    return [setToSaveAsAutomationMode, renderSaveAsAutomation]
}

export function acknowledgeDeploy([nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>) {
    const renderAcknowledgeModal = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Acknowledge) {
            return (
                <Modal>
                    <div className="error_style">
                        <Paragraph>{Parser(nonCanvasAction.message)}</Paragraph>
                        <Button className="btn btn-error center" onClick={() => setNonCanvasAction(None)}>
                            <label>Okay</label>
                        </Button>
                    </div>
                </Modal>
            )
        }
        return null
    }
    return [renderAcknowledgeModal]
}
type callBackT = (connction: string) => void

export function connectionDelete(
    workspace: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>
): any {
    const callBackRef = useRef<null | callBackT>(null)
    const resourceRef = useRef<any>(null)

    const setDeleteConnection = (connectionId: string, type: string, callback: callBackT, resource: any) => {
        callBackRef.current = callback
        resourceRef.current = resource
        setNonCanvasAction({
            ...DeleteConnection,
            id: connectionId,
            type: type
        })
    }

    async function deleteConnection() {
        const nonCanvasActionCurrent = nonCanvasAction as DeleteConnectionT
        try {
            const nonCanvasActionCurrent = nonCanvasAction as DeleteConnectionT
            await postJson(automationAPIURLWithWorkspace(workspace, '/connection/delete'))({
                type: nonCanvasActionCurrent.type,
                id: nonCanvasActionCurrent.id
            })
            callBackRef.current ? callBackRef.current(nonCanvasActionCurrent.id) : null
            setNonCanvasAction(None)
        } catch (error) {
            let err = error as any
            setNonCanvasAction({ ...nonCanvasActionCurrent, error: { error: true, info: err.response.data.error } })
            // setNonCanvasAction(None)
        }
    }
    const renderDeleteConnection = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.DeleteConnection) {
            return (
                <Modal>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={info}
                                    />
                                )
                            )
                        }}
                    >
                        <Suspense
                            fallback={<Loader.PopupLoader show={true} className="remove_connection_popuploader" />}
                        >
                            <AutomationsList
                                workspace={workspace}
                                connectionId={''}
                                setNonCanvasAction={setNonCanvasAction}
                                deleteConnection={deleteConnection}
                                resource={resourceRef.current}
                            />
                        </Suspense>
                    </ErrorBoundary>
                </Modal>
            )
        }
        return null
    }
    return [setDeleteConnection, deleteConnection, renderDeleteConnection]
}

function AutomationsList(props: {
    workspace: string
    connectionId: String
    setNonCanvasAction: any
    deleteConnection: any
    resource: any
}) {
    const automations = props.resource.read()

    return !automations.response ? (
        <>
            <div className="alert_popup_message">
                <h2>Remove Account?</h2>
                <Paragraph>
                    Account will be removed from all the automations. Update the account to make them work seamlessly.
                </Paragraph>
                <ul style={{ listStyleType: 'none' }}>
                    {automations.output.map((auto: { id: string; name: string }) => (
                        <li key={auto.id} className="automations_with_connections">
                            <div className='automations_with_connections_img'>
                                <FlowIcon />
                            </div>
                            <div className='automations_with_connections_content'>
                                {auto.name}
                            </div>
                            
                        </li>
                    ))}
                </ul>
                <ButtonContainer className="p-0">
                    <Button primary onClick={() => props.setNonCanvasAction(None)}>
                        <label>Cancel</label>
                    </Button>
                    <Button onClick={() => props.deleteConnection()}>
                        <label>Ok</label>
                    </Button>
                </ButtonContainer>
            </div>
        </>
    ) : (
        <div className="alert_popup_message">
            {automations.response.data && automations.response.data.error ? (
                <p style={{ marginBottom: 15, fontSize: 16 }}>{automations.response.data.error}</p>
            ) : (
                <p style={{ marginBottom: 15, fontSize: 16 }}>
                    Something went wrong!
                    <br />
                    Please try after some time.
                </p>
            )}
            <Button onClick={() => props.setNonCanvasAction(None)}>
                <label>Ok</label>
            </Button>
        </div>
    )
}

export function fetchAutomations(workspace: string, connectionId: string) {
    const automations = getJson(
        automationAPIURLWithWorkspace(workspace, '/connection/' + connectionId + '/automations')
    ).catch(err => err)

    return automations
}

export function wrapPromise<T>(promise: Promise<T>) {
    let status = 'pending'
    let result: any
    let suspender = promise.then(
        (r: any) => {
            status = 'success'
            result = r
        },
        (e: any) => {
            status = 'error'
            result = e
        }
    )
    return {
        read() {
            if (status === 'pending') {
                throw suspender
            } else if (status === 'error') {
                throw result
            } else if (status === 'success') {
                return result
            }
        }
    }
}
