import * as React from 'react'

import { JourneyState, WorkspaceS } from './Journey'

export const RootJourneyContext = React.createContext<JourneyState>({
    type: 'yet-to-load'
})
export const RootWorkspaceContext = React.createContext<WorkspaceS>({
    type: 'yet-to-load'
})
export const UpdateJourneyProvider = React.createContext<React.Dispatch<React.SetStateAction<JourneyState>> | null>(
    null
)

export const useJourney = () => React.useContext(RootJourneyContext)

export const useUpdateJourney = () => React.useContext(UpdateJourneyProvider)

export const useWorkspace = () => React.useContext(RootWorkspaceContext)

export const RootJourneyContextConsumer = RootJourneyContext.Consumer
