import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { Loader } from '@/common/components/Loader';
import { usePermissionSignalWithErrorHandling, PERMISSION_STATUS } from '@/common/utils/auth-token-api-utils';
import { useSignal } from '@preact/signals-react';
import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { stripeGetApi } from '@/common/utils/api-utils';

export function UserPermissionCheck(props: any) {
    const workspaceName = props.match.params.workspacename;
    const moduleName = props._moduleName;
    const permissions =  props._permission;
    const { Component,...rest } = props
    const moduleName$= useSignal<string | null>(null)

    const queryClient = useQueryClient();

    const assignModule = async () => {
      if(moduleName.type === "template-generated") {
        const template = moduleName.template

        const moduleNameGenerated = await moduleName.action(props,template,queryClient)
        // ERROR scenario js to be handled.
        //
        moduleName$.value = moduleNameGenerated

      } else {
        moduleName$.value = moduleName
      }
    }
    const _multiStepCached = useQuery(
      'users::la-access',
      () => stripeGetApi(`${workspaceName}/info/LA_ACCESS`),
      {
        onSuccess: (response) => {
          //console.log(response,'response')
        },
        staleTime: 0,
        refetchOnMount: true,
      }
    );
    const liveAgentAccess = 
      _multiStepCached.isSuccess && _multiStepCached.data.output === 'yes';

    console.log(liveAgentAccess,'liveAgentAccess')
    
    const hasPermission = usePermissionSignalWithErrorHandling(workspaceName, moduleName$, permissions);

    useEffect(() => {

      assignModule()

    },[props.location])

    // useEffect(() => {

    //   assignModule()

    // },[])
  
    if (hasPermission.value === PERMISSION_STATUS.LOADING) {
      return <Loader.PageLoader show={true}/>;
    }
  
    if ((moduleName === "LIVEAGENT_MODULE" && liveAgentAccess) || (hasPermission.value === PERMISSION_STATUS.HAS_PERMISSION)){
      return <Component {...rest}/>;
    }
    return <WarningCompont />;
  }

  export function WarningCompont(){
    return(
      <div className='d-flex warning_box_container'>
        <div className='d-flex warning_box'>
          <div>
            <WarningAlertIcon />
          </div>
          <div>
            <p>You don’t have access to this page. Contact your workspace admin</p>
          </div>
        </div>
      </div>
    )
  }