import * as R from 'ramda';
import { flip, propEq } from 'ramda';
import React, { useEffect, useReducer, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  ErrorBoundary,
  Error as ErrorPage,
} from '@/common/components/ErrorBoundary';
import { Loader } from '@/common/components/Loader';
import { postJson } from '@/common/utils/axios-utils';

import cogniLogo from '@/common/images/cogni_logo.png';
import LoginImage from '@/common/images/login_img.png';
import { WorkspacesT } from '@/SidePanel/types';
import { getJson, getProductId, loginApiURL } from '@/common/utils/api-utils';
import { mfaScreening } from '../src/MFAscreening';
import { AuthType } from '@/common/utils/auth-token-api-utils';
export type stateT = {
  workspaces: WorkspacesT[];
  defaultWs: null | number;
  hasOnlyOneWorkspace: boolean;
  loading: boolean;
  error: null | string;
};

export const defaultState: stateT = {
  workspaces: [],
  defaultWs: null,
  hasOnlyOneWorkspace: false,
  loading: true,
  error: null,
};

export function WorkSpaceRedirection(props: any) {
  const [state, setState] = useState(defaultState);
  const router = props.history;
  const MOCK_workspaces = [
    {
      createdAt: '2020-02-24T08:38:14.000Z',
      id: 175,
      name: 'vinoddev',
      ownerId: 36,
      updatedAt: '2020-02-24T08:38:14.000Z',
      workspaceURL: 'https://dev-assistant.workativ.ai/workspace/vinoddev',
    },
    {
      createdAt: '2020-02-28T08:57:01.000Z',
      id: 177,
      name: 'manjulaworkspace',
      ownerId: 39,
      updatedAt: '2023-05-18T05:31:05.000Z',
      workspaceURL:
        'https://dev-assistant.workativ.ai/workspace/manjulaworkspace',
    },
    {
      createdAt: '2020-05-04T12:31:36.000Z',
      id: 189,
      name: 'skitter',
      ownerId: 32,
      updatedAt: '2020-05-04T12:31:36.000Z',
      workspaceURL: 'https://dev-assistant.workativ.ai/workspace/skitter',
    },
  ];
  const callWorkspacesApi = async () => {
    let updatedState = { ...state };
    // Promise.resolve(MOCK_workspaces)
      await getJson(loginApiURL('/accessible_workspaces'))
      .then((res) => {
        // console.log(res, 'workspace');
        updatedState = {
          ...updatedState,
          workspaces: res,
          hasOnlyOneWorkspace: res.length == 1,
          loading: false,
          defaultWs: null,
        };
        getJson(loginApiURL('/get_default_workspace')).then(e => {
            setState({
                ...updatedState,
                defaultWs: e.default_workspaceId === null ? null : e.default_workspaceId
            })
        })
        setState({ ...updatedState });
      })
      .catch((error) => {
        setState({
          ...updatedState,
          error: error.response ? error.response.data.error : error.toString(),
        });
      });
  };
  // console.log(state, 'redirect');
  useEffect(() => {
    callWorkspacesApi();
  }, []);

  const retry = () => {
    let updatedState = { ...state };

    setState({
      ...updatedState,
      loading: true,
      error: null,
    });

    callWorkspacesApi();
  };
  const callingDefault =()=>{
    const defaultWorkspace = state.workspaces.filter((w) => w.id == state.defaultWs)[0].name
    if (mfaScreening(defaultWorkspace) === AuthType.AuthNeeded){
      return `/workspace/${defaultWorkspace}/users/auth`
    } else{
      return `/workspace/${defaultWorkspace}`
    }
  }

  return state.loading ? (
    <Loader.PageLoader show={true} />
  ) : state.error ? (
    <ErrorBoundary
      error={{ error: true, info: state.error }}
      render={(err: any, info: any) => {
        return (
          err && (
            <ErrorPage.PageLoadingError
              onClick={retry}
              info={info}
              btnName={'Retry'}
            />
          )
        );
      }}
    />
  ) : state.hasOnlyOneWorkspace || state.defaultWs ? (
    <Navigate
      to={{
        pathname: state.defaultWs
          ? callingDefault()
          : mfaScreening(state.workspaces[0].name) === AuthType.AuthNeeded
          ? `/workspace/${state.workspaces[0].name}/users/auth`
          : `/workspace/${state.workspaces[0].name}`,
        search: props.location.search,
      }}
      state={{ from: props.location }}
    />
    ) : (
    <WorkSpaceListing
      workspaces={state.workspaces}
      router={router}
      noAccessError={state.workspaces.length == 0}
    />
  );
}

const updateDefaultWS = (workspace: WorkspacesT, router: any) => {
  postJson(loginApiURL(`/update_def_ws/${workspace.id}`), {})
    .then((e) => {
      (mfaScreening(workspace.name) === AuthType.AuthNeeded)
        ? router.push(`/workspace/${workspace.name}/users/auth`)
        : router.push(`/workspace/${workspace.name}`);
    })
    .catch((e) => {
      // console.log(e);
    });
};

const WorkspaceItem = (props: {
  workspace: WorkspacesT;
  willbeDefault: boolean;
  router: any;
}) => {
  const { willbeDefault, workspace, router } = props;
  return (
    <span
      onClick={
        () =>
          willbeDefault
            ? updateDefaultWS(workspace, router)
            : (mfaScreening(workspace.name) === AuthType.AuthNeeded
            ? router.push(`/workspace/${workspace.name}/users/auth`)
            : router.push(`/workspace/${workspace.name}/welcome`))
        // router.push(`/workspace/${workspace.name}`)
      }
    >
      <div className="accounts_users">
        <div className="accounts_users_name">
          <div className="accounts_users_name_img">
            <div className="accounts_users_name_img_circle">
              <span className="accounts_users_first_name">
                {R.toUpper(workspace.name[0])}
              </span>
            </div>
            <h5>{workspace.name}</h5>
            <div className="arrow_clik_user">
              <div className="accounts_users_name_img_circle_bg arrow_clik_user_active">
                <span>
                  <svg x="0px" y="0px" viewBox="0 0 49.636 49.636">
                    <polygon points="23.001,27.245 0,23.39 49.636,0.61 25.636,49.026 "></polygon>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

type StateT = {
  loading: boolean;
  workspaces: WorkspacesT[];
  rememberMyselection: boolean;
};

const initialState: StateT = {
  loading: true,
  workspaces: [],
  rememberMyselection: false,
};

const wListReducer = (state: StateT, action: any) => {
  const actionIs = flip(propEq('type'))(action);
  if (actionIs('loaded')) {
    return { ...state, workspaces: action.workspaces, loading: false };
  } else if (actionIs('toggleRememberMyselection')) {
    return { ...state, rememberMyselection: !state.rememberMyselection };
  } else {
    throw new Error('Invalid action');
  }
};

export const WorkSpaceListing = (props: {
  workspaces: WorkspacesT[];
  router: any;
  noAccessError: boolean;
}) => {
  const [state, dispatch] = useReducer(wListReducer, initialState);
  // console.log(state, 'WorkSpaceListing');

  useEffect(() => {
    dispatch({ type: 'loaded', workspaces: props.workspaces });
  }, []);

  return (
    <span className="login assistant">
      {getProductId() === 'ASSISTANT' ? (
        <div className="login_bg_height assistant">
          <div className="login_title">
            <h3>
              Welcome back to <span>Workativ Assistant</span>
            </h3>
            <p>Sign in to continue to your account</p>
          </div>
          <div className="login_bg_width">
            {/* <div className="col-md-6 col-lg-6 login_bg_width_left">
                            <img className="centerImage" src={cogniLogo} alt="Assistant" title="Assistant" />
                            <h2>
                                Welcome back to <span>Workativ Assistant</span>
                            </h2>
                            <p>Signin to continue to your account</p>
                            <div className="left_bottom_img">
                                <img src={LoginImage} alt="Login_img" title="Login_img" />
                            </div>
                        </div> */}

            <div className="login_bg_width_left_right workspace_width_right">
              <h3>
                <span>Workspace</span>
              </h3>
              <div className="login_bg_height_width_auth">
                <div className="login_bg_height_auth">
                  <div className="login_bg_width_auth">
                    <div className="accounts_users_auth">
                      {state.workspaces.length > 0 ? (
                        state.workspaces.map((w: WorkspacesT, i: number) => (
                          <WorkspaceItem
                            workspace={w}
                            key={i}
                            willbeDefault={state.rememberMyselection}
                            router={props.router}
                          />
                        ))
                      ) : (
                        <div className="accounts_users">
                          <div className="accounts_users_name">
                            <div className="accounts_users_name_img accounts_users_change">
                              <div className="accounts_users_name_img_circle_bg ">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 17.11 20"
                                  >
                                    <title>entity</title>
                                    <g id="Layer_2" data-name="Layer 2">
                                      <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M8.55,8.81A4.41,4.41,0,1,0,4.15,4.4,4.42,4.42,0,0,0,8.55,8.81Z" />
                                        <path d="M8.55,9.78A8.57,8.57,0,0,0,0,18.33,1.67,1.67,0,0,0,1.67,20H15.44a1.67,1.67,0,0,0,1.67-1.67A8.57,8.57,0,0,0,8.55,9.78Z" />
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                              <h5>14 days free trial workspace</h5>
                              <h6 className="accounts_create_name_h6">
                                Create
                              </h6>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {state.workspaces.length > 1 && (
                    <div className="checkbox login_checkbox">
                      <label>
                        <input
                          type="checkbox"
                          checked={state.rememberMyselection}
                          onChange={() =>
                            dispatch({
                              type: 'toggleRememberMyselection',
                            })
                          }
                        />
                        <p>Remember my Selection</p>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {props.noAccessError && (
            <section className="container login_acc_message">
              <p>
                You are no longer a member of the workspace "Freshworks" choose
                new workspace or create your own workspace.
              </p>
              <span>
                <svg x="0px" y="0px" viewBox="0 0 405.2 399.1">
                  <g>
                    <path
                      d="M337.6,65.2C301.7,29.2,254,9.5,203.2,9.5c-50.8,0-98.5,19.8-134.4,55.7c-35.9,35.9-55.7,83.6-55.7,134.4
        c0,50.8,19.8,98.5,55.7,134.4c35.9,35.9,83.6,55.7,134.4,55.7c50.8,0,98.5-19.8,134.4-55.7c35.9-35.9,55.7-83.6,55.7-134.4
        C393.3,148.8,373.5,101.1,337.6,65.2L337.6,65.2z M322.3,318.7c-31.8,31.8-74.2,49.4-119.1,49.4c-45,0-87.3-17.5-119.1-49.4
        c-65.7-65.7-65.7-172.6,0-238.2c31.8-31.8,74.2-49.4,119.1-49.4c45,0,87.3,17.5,119.1,49.4C388,146.1,388,253,322.3,318.7
        L322.3,318.7z M322.3,318.7"
                    />
                    <path
                      d="M287,115.8c-4.2-4.2-11-4.2-15.3,0l-68.5,68.5l-68.5-68.5c-4.2-4.2-11-4.2-15.3,0c-4.2,4.2-4.2,11,0,15.3l68.5,68.5
        L119.5,268c-4.2,4.2-4.2,11,0,15.3c2.1,2.1,4.9,3.2,7.6,3.2c2.7,0,5.5-1,7.6-3.2l68.5-68.5l68.5,68.5c2.1,2.1,4.9,3.2,7.6,3.2
        c2.8,0,5.5-1,7.6-3.2c4.2-4.2,4.2-11,0-15.3l-68.3-68.5l68.5-68.5C291.2,126.8,291.2,120,287,115.8L287,115.8z M287,115.8"
                    />
                  </g>
                </svg>
              </span>
            </section>
          )}
        </div>
      ) : (
        <div className="login_bg_height">
          <div className="login_bg_width">
            <div className="row">
              <div className="col-md-6 col-lg-6 automate_workspace login_bg_width_left">
                <img
                  className="centerImage"
                  src={cogniLogo}
                  alt="Automate"
                  title="Automate"
                />
                <h2>
                  Welcome back to <span>Workativ Automate</span>
                </h2>
                <p>Signin to continue to your account</p>
                <div className="left_bottom_img">
                  <img
                    src={LoginImage}
                    alt="Automate Login_img"
                    title="Automate Login_img"
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-6 login_bg_width_left_right workspace_width_right">
                <h3>
                  <span>Workspace</span>
                </h3>
                <div className="login_bg_height_width_auth">
                  <div className="login_bg_height_auth">
                    <div className="login_bg_width_auth">
                      <div className="accounts_users_auth">
                        {state.workspaces.length > 0 ? (
                          state.workspaces.map((w: WorkspacesT, i: number) => (
                            <WorkspaceItem
                              workspace={w}
                              key={i}
                              willbeDefault={state.rememberMyselection}
                              router={props.router}
                            />
                          ))
                        ) : (
                          <div className="accounts_users">
                            <div className="accounts_users_name">
                              <div className="accounts_users_name_img accounts_users_change">
                                <div className="accounts_users_name_img_circle_bg ">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 17.11 20"
                                    >
                                      <title>entity</title>
                                      <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <path d="M8.55,8.81A4.41,4.41,0,1,0,4.15,4.4,4.42,4.42,0,0,0,8.55,8.81Z" />
                                          <path d="M8.55,9.78A8.57,8.57,0,0,0,0,18.33,1.67,1.67,0,0,0,1.67,20H15.44a1.67,1.67,0,0,0,1.67-1.67A8.57,8.57,0,0,0,8.55,9.78Z" />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                                <h5>14 days free trial workspace</h5>
                                <h6 className="accounts_create_name_h6">
                                  Create
                                </h6>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {state.workspaces.length > 1 && (
                      <div className="checkbox login_checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={state.rememberMyselection}
                            onChange={() =>
                              dispatch({
                                type: 'toggleRememberMyselection',
                              })
                            }
                          />
                          Remember my Selection
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.noAccessError && (
            <section className="container login_acc_message">
              <p>
                You are no longer a member of the workspace "Freshworks" choose
                new workspace or create your own workspace.
              </p>
              <span>
                <svg x="0px" y="0px" viewBox="0 0 405.2 399.1">
                  <g>
                    <path
                      d="M337.6,65.2C301.7,29.2,254,9.5,203.2,9.5c-50.8,0-98.5,19.8-134.4,55.7c-35.9,35.9-55.7,83.6-55.7,134.4
                                            c0,50.8,19.8,98.5,55.7,134.4c35.9,35.9,83.6,55.7,134.4,55.7c50.8,0,98.5-19.8,134.4-55.7c35.9-35.9,55.7-83.6,55.7-134.4
                                            C393.3,148.8,373.5,101.1,337.6,65.2L337.6,65.2z M322.3,318.7c-31.8,31.8-74.2,49.4-119.1,49.4c-45,0-87.3-17.5-119.1-49.4
                                            c-65.7-65.7-65.7-172.6,0-238.2c31.8-31.8,74.2-49.4,119.1-49.4c45,0,87.3,17.5,119.1,49.4C388,146.1,388,253,322.3,318.7
                                            L322.3,318.7z M322.3,318.7"
                    />
                    <path
                      d="M287,115.8c-4.2-4.2-11-4.2-15.3,0l-68.5,68.5l-68.5-68.5c-4.2-4.2-11-4.2-15.3,0c-4.2,4.2-4.2,11,0,15.3l68.5,68.5
                                            L119.5,268c-4.2,4.2-4.2,11,0,15.3c2.1,2.1,4.9,3.2,7.6,3.2c2.7,0,5.5-1,7.6-3.2l68.5-68.5l68.5,68.5c2.1,2.1,4.9,3.2,7.6,3.2
                                            c2.8,0,5.5-1,7.6-3.2c4.2-4.2,4.2-11,0-15.3l-68.3-68.5l68.5-68.5C291.2,126.8,291.2,120,287,115.8L287,115.8z M287,115.8"
                    />
                  </g>
                </svg>
              </span>
            </section>
          )}
        </div>
      )}
    </span>
  );
};
