/*eslint-disable*/
import React from 'react'

import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'

import { MarketPlaceApi, automationAPIURLWithWorkspace, postJson, logEvent } from '@/common/utils/api-utils'
import { AcknowledgeT, NonCanvasActionModes, NonCanvasActions, None, ReactSetStateObject } from '../types'

let Parser = require('html-react-parser')

export function automationDownloadAction(
    _automationId: string,
    [nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>,
    workspace: string
) {
    const setToDeployAutomationMode = () => {
        setNonCanvasAction({
            mode: NonCanvasActionModes.DownloadFromMarket,
            id: _automationId,
            error: { error: false, info: '' }
        })
    }
    const downloadAutomation = async () => {
        try {
            const response = await postJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.AddToWorkSpace))({
                automationId: _automationId
            })

            const DownloadAcknowledgement: AcknowledgeT = {
                mode: NonCanvasActionModes.Acknowledge,
                message: `<b>Workflow downloaded as "${response.data.output.name}" in drafts</b>`
            }

            logEvent(workspace, {
                event:"EVENT::WORKFLOW::DOWNLOAD_FROM_MARKETPLACE", event_meta:{
               name: response.data.output.name,
               description: ""
            }})

            setNonCanvasAction(DownloadAcknowledgement)
        } catch (error) {
            let err = error as any
            const DownloadAcknowledgement: AcknowledgeT = {
                mode: NonCanvasActionModes.Acknowledge,
                message: err.response.data.error
            }
            setNonCanvasAction(DownloadAcknowledgement)
        }
    }
    const renderDeployAutomation = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.DownloadFromMarket) {
            return (
                <Modal>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, _info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={'Error in downloading automation. Please try again later'}
                                    />
                                )
                            )
                        }}
                    >
                        <h2> Download Automation</h2>
                        <Paragraph>
                            {' '}
                            Automation will be downloaded to your automation drafts. Do you want to download?{' '}
                        </Paragraph>
                        <ButtonWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    downloadAutomation()
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </ErrorBoundary>
                </Modal>
            )
        } else return null
    }
    return [setToDeployAutomationMode, renderDeployAutomation]
}
export function acknowledge([nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<NonCanvasActions>) {
    const renderAcknowledgeModal = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Acknowledge) {
            return (
                <Modal>
                    <div className="error_style">
                        <p>{Parser(nonCanvasAction.message)}</p>
                        <button className="btn btn-error center" onClick={() => setNonCanvasAction(None)}>
                            Okay
                        </button>
                    </div>
                </Modal>
            )
        }
        return null
    }
    return [renderAcknowledgeModal]
}
