import React, { useEffect, useState } from 'react';
import {
  CancelButton,
  EmptyDiv,
  Header,
  HeaderLeft,
  InputContainer,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';
import { Input, InputContent } from '@/common/styled/Workflow.Analytics.Dumb';
import { Button, DropdownLiSpan } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonContainer } from '@/common/styled/Workflow.Dumb';
import styled from 'styled-components/macro';
import {
  AddIcon,
  CloseColorIcon,
  CloseIcon,
  CloseIconWithCircle,
  DropdownWithCircle,
  EditIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  InfoIcon,
  MinusIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import {
  S3Response,
  CredConfluenceT,
  EmailValidT,
  initialState,
  IsValidURL,
  KnowledgeBase,
  Resp,
  S3CredData,
  S3CredT,
  SharepointPopupT,
  StateT,
} from './MockFiles';
import { isValidEmail } from 'libs/feature/manage-users/src/v2/ManageUsers';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { getJson, knowledgeApiUrl } from '@/common/utils/api-utils';
import * as R from 'ramda';
import { useQueryClient } from 'react-query';
import { Loader } from '@/common/components/Loader';
import {
  useSaveConnection,
  useSaveConnectionVivantio,
  useSaveCred,
  useUpdateConnection,
  useUpdateCred,
} from './KnowledgeApis';
import Tooltip from 'react-tooltip-lite';
import Downshift from 'downshift';
import {
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
} from '@/common/styled/Workflow.Dumb';
import _ from 'lodash';
const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;
`;
const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;
export default function S3Popup({
  pageState,
  PageStateUpdate,
  selectedID,
  workspace,
  Connection,
  exCatArray,
}: SharepointPopupT) {
  const [state, setState] = useState<StateT>(initialState);
  console.log(state, Connection, 'stateViviantio');
  const [status, setStatus] = useState<string>('');
  const [published, setPublished] = useState<boolean>(false);
  const [showAdditionalProp, setShowAdditionalProp] = useState<boolean>(false);
  useEffect(() => {
    if (selectedID !== '' && pageState === 'edit') {
      getJson(
        knowledgeApiUrl(workspace, `knowledge-base/custom/${selectedID}`)
      ).then((data) => {
        if (data.type === 'success') {
          const output = [data.output];
          setPopupState({
            ...popUpState,
            selectedConnection: JSON.parse(JSON.stringify(output)),
            clonedConnection: JSON.parse(JSON.stringify(output)),
          });
          setPublished(output[0].input.published)
        }
      });
    }
    // if (selectedID !== '' && pageState === 'edit') {
    // const output = [
    //   {
    //     id: '8e4af033-ce8e-4a79-8a23-bdb295027579',
    //     name: 'Test-S3',
    //     status: 'CREATED',
    //     type: 'S3',
    //     workspaceId: '9076ff5f-6ea3-4f0b-8869-6068f4629b12',
    //     knowledgeBaseCategoryId: 'cca0ef9c-f69d-4a4d-9b53-2ffd0584aafa',
    //     credentialId: '55ec5d8d-b34c-49f5-b6c2-63a265cb8aa6',
    //     input: {
    //       type: 'S3',
    //       bucketNames: ['kendra-s3-testing'],
    //       filePaths: ['test', 'testing'],
    //       fileTypes: ['asdfsdf'],
    //       regularExpressionPatterns: ['asdfasf'],
    //     },
    //     credential: {
    //       accessKeyId: 'AKIA6MSNY67ITTQPUHOE',
    //     },
    //     createdAt: '2024-05-11T16:14:07.450Z',
    //     updatedAt: '2024-05-12T02:11:17.767Z',
    //   },
    // ];
    // setPopupState({
    //   ...popUpState,
    //   selectedConnection: JSON.parse(JSON.stringify(output)),
    //   clonedConnection: JSON.parse(JSON.stringify(output)),
  }, [selectedID, pageState]);
  const [bucketNames, setBucketNames] = useState<string[]>(['']);
  const checkingName: KnowledgeBase[] | [] =
    exCatArray && exCatArray.length > 0
      ? exCatArray.filter(
          (arr) => arr.name.toLowerCase() === state.kBName.toLowerCase()
        )
      : [];

  const [popUpState, setPopupState] = useState<{
    selectedConnection: S3Response[];
    clonedConnection: S3Response[];
  }>({
    selectedConnection: [],
    clonedConnection: [],
  });
  console.log(popUpState, 'popUpState');
  const handleConnectionEdit = (
    value: any,
    id: string,
    property: string,
    obj?: string
  ) => {
    const index = popUpState.selectedConnection.findIndex((el) => el.id === id);
    const data = [...popUpState.selectedConnection];
    if (obj === 'credential') {
      data[index].credential[property] = value;
    } else if (obj === 'input') {
      data[index].input[property] = value;
    } else {
      data[index][property] = value;
    }
    setPopupState({ ...popUpState, selectedConnection: data });
  };
  const [updateCred, setUpdateCred] = useState<boolean>(false);

  const addSiteUrl = (id: string) => {
    const rep = popUpState.selectedConnection[0].input;
    const list = [...rep.bucketNames, ''];
    handleConnectionEdit(list, id, 'bucketNames', 'input');
  };
  const accountUpdate = () => {
    const selectedaccount =
      popUpState.selectedConnection[0].credential.accessKeyId;
    const clonedaccount = popUpState.clonedConnection[0].credential.accessKeyId;
    return (
      JSON.stringify(selectedaccount) === JSON.stringify(clonedaccount) &&
      state.updatePassWord === ''
    );
  };

  const addInputUrl = () => {
    setBucketNames([...bucketNames, '']);
  };

  const removeinputUrl = (index: number) => {
    const rows = [...bucketNames];
    rows.splice(index, 1);
    rows.length === 0 ? setBucketNames(['']) : setBucketNames(rows);
  };
  const changeUrl = (index: number, event: { target: { value: any } }) => {
    const { value } = event.target;
    const list = [...bucketNames];
    list[index] = value;
    setBucketNames(list);
  };
  //saving credential data to get connectionID //create credential

  const { saveCredMutate } = useSaveCred(
    (response: Resp) => {
      setStatus('');
      setState({
        ...state,
        connectionID: response.data.output.id,
        showConnectError: false,
      });
      setClonedCredData(JSON.parse(JSON.stringify(credData)));
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const onCredSave = () => {
    const credBody = {
      connector: 'S3',
      input: {
        accessKeyId: credData.accessKeyId,
        secretAccessKey: credData.secretAccessKey,
      },
    };
    // if (!emailValidation.valid) {
    //   setStatus('');
    //   setState((prevState) => {
    //     return {
    //       ...state,
    //       showConnectError: true,
    //     };
    //   });
    // } else {
    setState({
      ...state,
      connectLoader: true,
    });
    saveCredMutate({ workspace, credBody });
  };

  //   const checkingValidUrl = popUpState.selectedConnection[0]?.input.hostUrl
  //     ? IsValidURL(popUpState.selectedConnection[0]?.input.hostUrl)
  //     : true;
  // updating credential data
  const { updateCredMutate } = useUpdateCred(
    () => {
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const onCredUpdateSave = (id: string) => {
    const newData = {
      accessKeyId: popUpState.selectedConnection[0].credential.accessKeyId,
      secretAccessKey: state.updatePassWord,
    };

    const updateCredBody = {
      connector: 'S3',
      //   authType: 'basic',
      input: {
        accessKeyId: newData.accessKeyId,
        secretAccessKey: newData.secretAccessKey,
      },
    };
    const updatedCloned = popUpState.clonedConnection.map((yes) =>
      Object.assign(yes, {
        credential: newData,
      })
    );
    setState({
      ...state,
      connectLoader: true,
    });
    updateCredMutate({ workspace, id, updateCredBody });
    setPopupState({
      ...popUpState,
      clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    });
  };

  //siteURL management

  // const [spaceKey, setSpaceKey] = useState<string[]>(['']);

  const inputURLValid = bucketNames.map((url) => IsValidURL(url));
  const onCancel = () => {
    PageStateUpdate();
    setBucketNames(['']);
    setStatus('');
    setUpdateCred(false);
    setAdditionalObj(initialObj)
    setShowAdditionalProp(false)
    setState(initialState);
    setPopupState({
      selectedConnection: [],
      clonedConnection: [],
    });
    // setEmailValidation({
    //   valid: true,
    //   message: null,
    // });
    setCredData(S3CredData);
  };

  //cred
  const [credData, setCredData] = useState<S3CredT>(S3CredData);
  const [clonedCredData, setClonedCredData] = useState<S3CredT>(S3CredData);

  // const [emailValidation, setEmailValidation] = useState<EmailValidT>({
  //   valid: true,
  //   message: null,
  // });
  //   const queryClient = useQueryClient();
  useEffect(() => {
    if (popUpState.selectedConnection.length > 0) {
      const addit = popUpState.selectedConnection[0].input;

      const filePath =
        addit.filePaths.length > 0
          ? addit.filePaths.map((value) => ({
              name: 'File Path',
              value: value,
              bName: 'filePaths',
              description:
                'You can give a folder inside the folder path separated by /',
            }))
          : [];

      const fileType =
        addit.fileTypes.length > 0
          ? addit.fileTypes.map((value) => ({
              name: 'File Type',
              value: value,
              bName: 'fileTypes',
              description:
                'You can add one file type at a time. To add multiple file type. Add, another Filter.',
            }))
          : [];
      const path =
        addit.regularExpressionPatterns.length > 0
          ? addit.regularExpressionPatterns.map((value) => ({
              name: 'Regular Expression Patterns',
              value: value,
              bName: 'regularExpressionPatterns',
              description:
                'Provide Regular Expression Pattern name. Example Regexp: "Name: .*"',
            }))
          : [];
      // newObj.push({name:'',value:''})
      const newObj = filePath.concat(fileType, path);
      setClonedInfo(newObj.length > 0 ? newObj : initialObj);
      setAdditionalObj(newObj.length > 0 ? newObj : initialObj);
      setShowAdditionalProp(newObj.length > 0 ? true : false);
      console.log(fileType, addit, 'newObj');
    }
  }, [popUpState]);
  const needUpdate = () => {
    const omittedAccount = R.omit(
      ['credential'],
      popUpState.selectedConnection[0]
    );
    const omittedClonedAccount = R.omit(
      ['credential'],
      popUpState.clonedConnection[0]
    );

    const eRadditionalObj = additionalObj.filter((add) => add.value !== '');
    const eRclonedInfo = clonedInfo.filter((add) => add.value !== '');

    console.log(
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount),
      _.isEqual(eRclonedInfo, eRadditionalObj),
      'chcksfsd'
    );

    return (
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount) &&
      _.isEqual(eRclonedInfo, eRadditionalObj)
    );
    // return (
    //   JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount)
    // );
  };
  const uniqueadditionalObj = (additionalObj: additionalObjT[]) => {
    const uniqueObject = {};

    const keys = Object.keys(initialAdditioanlProp);

    additionalObj.forEach((obj) => {
      if (!uniqueObject[obj.bName]) {
        uniqueObject[obj.bName] = [];
      }
      uniqueObject[obj.bName].push(obj.value);
    });

    keys.forEach((key) => {
      if (!uniqueObject.hasOwnProperty(key)) {
        uniqueObject[key] = [];
      }
    });
    console.log(uniqueObject, 'uniqueObject');
    return uniqueObject;
  };
  const checkingDuplicate = (arr: string[]) => {
    return arr.length === new Set(arr).size;
  };

  const { saveConVivantioMutate } = useSaveConnectionVivantio(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const saveConnection = () => {
    const addedProperties = uniqueadditionalObj(additionalObj);
    const connectionData = {
      name: state.kBName,
      category: 'S3',
      credentialDomainRefId: state.connectionID,
      input: {
        type: 'S3',
        // syncMode: 'FORCED_FULL_CRAWL',
        bucketNames: bucketNames,
        ...addedProperties,
      },
    };
    console.log(connectionData, 'connectionData');
    if (
      // IsValidURL(state.domainName) === false ||
      checkingName.length > 0 ||
      !checkingDuplicate(bucketNames)
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   // error:true,
      //   loader: false,
      //   showError: true,
      // });
    } else {
      setStatus('loader');
      setState({
        ...state,
        disableSave: true,
      });
      saveConVivantioMutate({ workspace, connectionData });

      //   postJson(knowledgeApiUrl(workspace, `knowledge-base/v2`))(connectionData)
      //     .then((data) => {
      //       if (data.data.type === 'success') {
      //         queryClient.invalidateQueries('kbcategory');
      //         setpageState(Mode.View);
      //         setState(initialState);
      //         setSpaceKey(['']);
      //         onCancel();
      //       }
      //     })
      //     .catch((err) => {
      //       setStatus('apiError');
      //     });
      //   //   connectionSave(connectionData);
      //   // //   onCancel()
      //   // setPopupState(({
      //   //     selectedConnection: [],
      //   //     clonedConnection: [],
      //   //   }))
    }
  };

  const checkingUpdateName = (id: string) => {
    const array =
      exCatArray.length > 0 ? exCatArray.filter((a) => a.id !== id) : [];
    return array.length > 0
      ? array.filter(
          (arr) =>
            arr.name.toLowerCase() ===
            popUpState.selectedConnection[0]?.name.toLowerCase()
        )
      : [];
  };

  const { updateConmutate } = useUpdateConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const updateConnection = (id: string) => {
    const data = popUpState.selectedConnection[0];
    const addedProperties = uniqueadditionalObj(additionalObj);
    // const filteredSiteUrls = data.input.spaceKeys.filter((f) => f !== '');
    const connectionData = {
      name: data.name,
      category: 'S3',
      credentialDomainRefId: data.credentialId,
      input: {
        type: 'S3',
        // syncMode: 'FORCED_FULL_CRAWL',
        bucketNames: data.input.bucketNames,
        ...addedProperties,
      },
    };
    if (
      // IsValidURL(data.input.domain) === false ||
      !checkingDuplicate(bucketNames) ||
      checkingUpdateName(id).length > 0
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   showError: true,
      // });
    } else {
      setStatus('');
      setState({
        ...state,
        disableSave: true,
      });
      console.log(Connection, 'jagfkjgadfa');
      updateConmutate({ workspace, connectionData, id, Connection });

      // putJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${id}`))()(
      //   connectionData
      // )
      //   .then((data) => {
      //     if (data.data.type === 'success') {
      //       queryClient.invalidateQueries('kbcategory');
      //       onCancel();
      //     }
      //   })
      //   .catch((err) => {
      //     setStatus('apiError');
      //     // setState({
      //     //   ...state,
      //     //   loader: false,
      //     //   error: true,
      //     // });
      //   });
    }
  };

  ////additionalprop
  const initialFolderValue = {
    name: '',
    value: '',
    description: '',
    valueDesc: '',
  };
  const [folderType, setFolderType] = useState<{
    name: string;
    value: string;
    description: string;
    valueDesc: string;
  }>(initialFolderValue);

  const initialAdditioanlProp = {
    filePaths: [],
    fileTypes: [],
    regularExpressionPatterns: [],
  };

  const [folderDropDown, setFolderDropDown] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(-1);
  interface additionalObjT {
    name: string;
    bName: string;
    value: string;
    description: string;
  }
  const initialObj = [{ name: '', bName: '', value: '', description: '' }];
  const [additionalObj, setAdditionalObj] =
    useState<additionalObjT[]>(initialObj);
  const [clonedInfo, setClonedInfo] = useState<additionalObjT[]>(initialObj);

  console.log(additionalObj, 'setAdditionalObj');
  const FolderDropValue = [
    {
      name: 'File Path (Folder)',
      value: 'filePaths',
      description: 'Provide the folder name',
      valueDesc: 'You can give a folder inside the folder path separated by /',
    },
    {
      name: 'File Type',
      value: 'fileTypes',
      description: 'Provide file type.',
      valueDesc:
        'You can add one file type at a time. To add multiple file type. Add, another Filter.',
    },
    {
      name: 'Regular Expression Patterns',
      value: 'regularExpressionPatterns',
      description: 'Provide Regular Expression Pattern name',
      valueDesc: 'Provide Regular Expression Pattern name. Example Regexp: "Name: .*"',
    },
  ];
  const setValue = (index: number, folderInfo: any, value: string) => {
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.value,
      value: value,
      description: folderInfo.valueDesc,
    };
    setAdditionalObj(list);
  };

  const setName = (index: number, folderInfo: any) => {
    console.log('working', 'setAdditionalObj');
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.value,
      description: folderInfo.valueDesc,
      value: '',
    };
    setAdditionalObj(list);
  };
  const removeSiteUrl = (index: number, id: string) => {
    const rows = [...popUpState.selectedConnection[0].input.bucketNames];
    rows.splice(index, 1);
    rows.length === 0
      ? handleConnectionEdit([''], id, 'bucketNames', 'input')
      : handleConnectionEdit(rows, id, 'bucketNames', 'input');
  };
  const handleRepositoryChange = (
    index: number,
    event: { target: { value: any } },
    id: string,
    prop: string
  ) => {
    console.log(index, event.target.value, id, prop, 'handleRepositoryChange');
    const { value } = event.target;
    const rep = popUpState.selectedConnection[0].input;
    const list = [...rep.bucketNames];
    list[index] = value;
    handleConnectionEdit(list, id, prop, 'input');
  };
  const removeSubset = (index: number) => {
    const rows = [...additionalObj];
    rows.splice(index, 1);
    rows.length === 0 ? setAdditionalObj(initialObj) : setAdditionalObj(rows);
  };
  useEffect(() => {
    scroollUp();
  }, [folderType, showAdditionalProp]);

  const scroollUp = () => {
    const element = document.getElementById('scrollIn');
    element?.scrollIntoView({ behavior: 'smooth' });
  };
  const addMoreObj = () => {
    scroollUp();
    setAdditionalObj([...additionalObj, ...initialObj]);
  };
  return (
    <>
      {pageState === 'add' && (
        <Wrapper>
          <PopupWrapper>
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>S3 Configuration</HeaderLeft>
                <HeaderRight></HeaderRight>
              </Header>
              <Paragraph>Create a new connection.</Paragraph>
              <PopupScrollHeight>
                <EmptyDiv>
                  <BgWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e: { target: { value: any } }) =>
                          setState({
                            ...state,
                            kBName: e.target.value,
                          })
                        }
                      />
                      <InputContent>Connection name</InputContent>
                    </InputContainer>
                    {/* {console.log(checkingName, state, 'checking')} */}
                    {checkingName.length > 0 && status === 'error' && (
                      <p className="valid_url_alert">
                        Connection Name has to be unique
                      </p>
                      // </div>
                    )}
                  </BgWrapper>
                  <BgWrapper>
                    <div className="opations_label">
                      <Header style={{ fontSize: 14, marginBottom: 10 }}>
                        Connect Account{' '}
                      </Header>
                    </div>
                    <OptionalInput>
                      <InputContainer>
                        <Input
                          type="text"
                          onChange={(e: { target: { value: string } }) => {
                            setCredData({
                              ...credData,
                              accessKeyId: e.target.value,
                            });
                          }}
                        />
                        <InputContent>Accesskey Id</InputContent>
                        {/* {!emailValidation.valid && state.showConnectError && (
                          <p className="error_msg_invalid">
                            {emailValidation.message}
                          </p>
                        )} */}
                      </InputContainer>
                    </OptionalInput>
                    <OptionalInput>
                      <InputContainer style={{ marginBottom: 0 }}>
                        <Input
                          type={!state.eyeIcon ? 'password' : 'text'}
                          onChange={(e: { target: { value: any } }) =>
                            setCredData({
                              ...credData,
                              secretAccessKey: e.target.value,
                            })
                          }
                        />
                        <InputContent>Secret accesskey</InputContent>
                      </InputContainer>
                      <CancelButton
                        className="d-flex"
                        onClick={() =>
                          setState({
                            ...state,
                            eyeIcon: !state.eyeIcon,
                          })
                        }
                      >
                        {state.eyeIcon ? <EyeOpenIcon /> : <EyeCloseIcon />}
                      </CancelButton>
                    </OptionalInput>
                    <ButtonContainer
                      className="knowledge_ms_btn"
                      style={{ padding: '10px 0' }}
                    >
                      <Button
                        type="button"
                        style={{ marginRight: 20 }}
                        className={
                          state.connectionID === ''
                            ? credData.accessKeyId !== '' &&
                              credData.secretAccessKey !== ''
                              ? ''
                              : 'editor_btn_disabled'
                            : JSON.stringify(clonedCredData) !==
                                JSON.stringify(credData) &&
                              credData.accessKeyId !== '' &&
                              credData.secretAccessKey !== ''
                            ? ''
                            : 'editor_btn_disabled'
                        }
                        onClick={() => onCredSave()}
                      >
                        <label>Save</label>
                      </Button>
                      {state.connectLoader && (
                        <span className="connect_account_loading automate_connect_account_loading">
                          <img src={LoadingSvgImage} />
                        </span>
                      )}
                      {status === 'apiConnectError' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      <Button
                        primary
                        type="button"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        <label> Cancel</label>
                      </Button>
                    </ButtonContainer>
                  </BgWrapper>
                  {state.connectionID !== '' && (
                    <BgWrapper>
                      <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                          Bucket names{' '}
                        </Header>
                      </div>
                      <OptionalInput>
                        {bucketNames &&
                          bucketNames.map((data, index) => (
                            <>
                              <InputContainer
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Input
                                  type="text"
                                  onChange={(evnt: {
                                    target: { value: string };
                                  }) => changeUrl(index, evnt)}
                                  value={data}
                                  name="url"
                                />
                                <InputContent>
                                  Bucket Name {index + 1}
                                </InputContent>
                                <span
                                  className="kbclose_Icon"
                                  onClick={() => removeinputUrl(index)}
                                >
                                  <CloseIcon />
                                </span>
                                {/* </div> */}
                              </InputContainer>
                              {/* {!IsValidURL(data) && status === 'error' && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )} */}
                            </>
                          ))}
                      </OptionalInput>
                      {bucketNames.length < 99 && 
                      <SmallButton
                        primary
                        style={{ marginBottom: 20 }}
                        onClick={addInputUrl}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        <label>Add More</label>
                      </SmallButton>
}
                    </BgWrapper>
                  )}
                  {state.connectionID !== '' && (
                    <SmallButton
                      primary
                      onClick={() => {
                        scroollUp();
                        setShowAdditionalProp(!showAdditionalProp);
                      }}
                      style={{ marginBottom: '10px', width: '153px' }}
                    >
                      {showAdditionalProp ? (
                        <span>
                          <MinusIcon />
                        </span>
                      ) : (
                        <span>
                          <AddIcon />
                        </span>
                      )}
                      <label>Select subset of the site</label>
                    </SmallButton>
                  )}
                  {state.connectionID !== '' && showAdditionalProp && (
                    <>
                      {additionalObj &&
                        additionalObj.map((add: any, index: number) => (
                          <BgWrapper>
                            <InputContainer style={{ marginBottom: '10px' }}>
                              <Input
                                type="text"
                                value={add.name}
                                // onChange={(e: any) => {}}
                                onClick={() => {
                                  setFolderDropDown(true);
                                  setNumber(index);
                                }}
                                style={{ paddingLeft: 10 }}
                              />
                              <InputContent>
                                Additional Information
                              </InputContent>
                              <CancelButton
                                onClick={() => setFolderDropDown(true)}
                                style={{ display: 'block', top: '14px' }}
                              >
                                <DropdownWithCircle />
                              </CancelButton>
                              {folderDropDown && number === index && (
                                <Downshift
                                  isOpen={folderDropDown}
                                  onOuterClick={() => setFolderDropDown(false)}
                                >
                                  {() => (
                                    <div>
                                      <DropdownUl style={{ marginTop: '48px' }}>
                                        {FolderDropValue.map((a) => (
                                          <DropdownLi
                                            onClick={() => {
                                              setFolderDropDown(false);
                                              setFolderType(a);
                                              setName(index, a);
                                            }}
                                          >
                                            <DropdownLiSpan>
                                              {a.name}
                                            </DropdownLiSpan>{' '}
                                            <Tooltip
                                              className="kai_sharepoint target customTip"
                                              zIndex={10000}
                                              arrowSize={8}
                                              tagName="span"
                                              content={a.description}
                                              distance={5}
                                            >
                                              <InfoIcon />
                                            </Tooltip>
                                          </DropdownLi>
                                        ))}
                                      </DropdownUl>
                                    </div>
                                  )}
                                </Downshift>
                              )}
                            </InputContainer>
                            {add.name !== '' && (
                              <>
                                <div className="knowledge_ai_folder_box flex">
                                  <InputContainer
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <Input
                                      type="text"
                                      value={add.value}
                                      onChange={(e: {
                                        target: { value: string };
                                      }) =>
                                        setValue(
                                          index,
                                          folderType,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputContent>Value</InputContent>
                                    <span
                                      onClick={() => {
                                        removeSubset(index);
                                      }}
                                    >
                                      <CloseIconWithCircle />
                                    </span>
                                  </InputContainer>
                                </div>
                                <Paragraph>{add.description}</Paragraph>
                              </>
                            )}
                          </BgWrapper>
                        ))}
                      {additionalObj[additionalObj.length - 1].value !== '' && (
                        <SmallButton
                          primary
                          style={{ marginBottom: 30 }}
                          onClick={() => addMoreObj()}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                      )}
                      <div id="scrollIn" />
                    </>
                  )}
                </EmptyDiv>
                {state.connectionID !== '' && (
                  <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                    <div className="popup_footer_button_container">
                      <Button
                        type="button"
                        className={
                          state.kBName !== '' && state.connectionID !== ''
                            ? // !state.disableSave
                              //   state.domainName !== '' &&
                              // state.tenantId !== '' &&
                              //   spaceKey.length > 0 &&
                              //   spaceKey[0].length > 0 &&
                              //   !state.showError &&
                              //     // !inputURLValid.includes(false)) &&
                              //   !state.disableSave
                              ''
                            : 'editor_btn_disabled'
                        }
                        onClick={() => {
                          saveConnection();
                        }}
                      >
                        <label> Save</label>
                      </Button>
                      {status === 'loader' && (
                        <span className="connect_account_loading automate_connect_account_loading">
                          <img src={LoadingSvgImage} />
                        </span>
                      )}
                      {status === 'error' && (
                        <span
                          className={
                            !checkingDuplicate(bucketNames) &&
                            !inputURLValid.includes(false) &&
                            checkingName.length === 0
                              ? 'connect_account_failed hide'
                              : 'connect_account_failed'
                          }
                        >
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      {status === 'apiError' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      {!checkingDuplicate(bucketNames) && status === 'error' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              All bucketNames must be unique
                            </span>
                          </div>
                        </span>
                      )}
                      <Button
                        primary
                        type="button"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        <label> Cancel</label>
                      </Button>
                    </div>
                  </ButtonContainer>
                )}
              </PopupScrollHeight>
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
      {pageState === 'edit' && (
        <Wrapper>
          <PopupWrapper className="edit_btn">
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>S3 Configuration</HeaderLeft>
                <HeaderRight>
                  {/* <VideoLink id={'ADD_AN_ADAPTIVE_CARD'} text={'How to'} /> */}
                </HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Update the connection.</Paragraph>
              {popUpState.selectedConnection.length === 0 ? (
                <PopupScrollHeight>
                  <div className="popup_loader">
                    <Loader.PopupLoader show={true} />
                  </div>
                </PopupScrollHeight>
              ) : (
                popUpState.selectedConnection.map((data) => (
                  <PopupScrollHeight>
                    <EmptyDiv>
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            name="connection_name"
                            value={data.name}
                            onChange={(e: { target: { value: any } }) => {
                              handleConnectionEdit(
                                e.target.value,
                                data.id,
                                'name'
                              );
                            }}
                          />
                          <InputContent>Connection name</InputContent>
                          {/* {console.log(
                          checkingUpdateName(data.id),
                          state,
                          'checking'
                        )} */}
                          {status === 'error' &&
                            checkingUpdateName(data.id).length > 0 && (
                              <p className="valid_url_alert">
                                Connection Name has to be unique
                              </p>
                              // </div>
                            )}
                        </InputContainer>
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label sharepoint_edit">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Connect Account{' '}
                          </Header>
                          {updateCred ? (
                            <div
                              className="sharepoint_icon"
                              onClick={() => {
                                setUpdateCred(false);
                                setState({
                                  ...state,
                                  updatePassWord: '',
                                });
                              }}
                            >
                              <CloseColorIcon />
                            </div>
                          ) : (
                            <div
                              className="sharepoint_icon"
                              onClick={() => setUpdateCred(true)}
                            >
                              <EditIcon />
                            </div>
                          )}
                        </div>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type="text"
                              value={data.credential?.accessKeyId}
                              disabled={!updateCred}
                              className={
                                updateCred ? '' : 'editor_btn_disabled'
                              }
                              onChange={(e: { target: { value: string } }) => {
                                handleConnectionEdit(
                                  e.target.value,
                                  data.id,
                                  'accessKeyId',
                                  'credential'
                                );
                              }}
                            />
                            <InputContent>Accesskey Id</InputContent>
                          </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type={!state.eyeIcon ? 'password' : 'text'}
                              disabled={!updateCred}
                              className={
                                updateCred ? '' : 'editor_btn_disabled'
                              }
                              value={state.updatePassWord}
                              onChange={(e: { target: { value: any } }) =>
                                setState({
                                  ...state,
                                  updatePassWord: e.target.value,
                                })
                              }
                            />
                            <InputContent>Secret accesskey</InputContent>
                          </InputContainer>
                          <CancelButton
                            className={
                              updateCred ? 'd-flex' : 'editor_btn_disabled'
                            }
                            onClick={() =>
                              setState({
                                ...state,
                                eyeIcon: !state.eyeIcon,
                              })
                            }
                          >
                            {state.eyeIcon ? <EyeOpenIcon /> : <EyeCloseIcon />}
                          </CancelButton>
                        </OptionalInput>
                        <ButtonContainer
                          style={{
                            gap: 20,
                            padding: '0 0 10px 0',
                            marginTop: '-10px',
                          }}
                          className="slack_buton_center"
                        >
                          <Button
                            type="button"
                            className={
                              accountUpdate() || !updateCred
                                ? 'hidden'
                                : //  emailValidation.valid &&
                                data.credential.accessKeyId !== '' &&
                                  state.updatePassWord !== ''
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => {
                              setUpdateCred(false);
                              setState({
                                ...state,
                                updatePassWord: '',
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            style={{ marginRight: 20 }}
                            className={
                              accountUpdate() || !updateCred
                                ? 'hidden'
                                : // emailValidation.valid &&
                                data.credential.accessKeyId !== '' &&
                                  state.updatePassWord !== ''
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => onCredUpdateSave(data.credentialId)}
                          >
                            <label> Update </label>
                          </Button>
                          {state.connectLoader && (
                            <span className="connect_account_loading">
                              <img src={LoadingSvgImage} />
                            </span>
                          )}
                          {status === 'apiConnectError' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                        </ButtonContainer>
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Bucket names{''}
                          </Header>
                        </div>
                        {/* <OptionalInput>
                          <InputContainer>
                            <Input
                              type="text"
                              value={data.input?.bucketNames}
                              onChange={(e: { target: { value: any } }) =>
                                handleConnectionEdit(
                                  e.target.value,
                                  data.id,
                                  'bucketNames',
                                  'input'
                                )
                              }
                            />
                            <InputContent>
                              Bucket names
                              <Tooltip
                                className="target customTip _description"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="Provide Domain. You can find it admin > Integration & API > Downloads. 
                                Copy Platform Url"
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </InputContent>
                            {/* {!IsValidURL(data.input?.domain) &&
                              status === 'error' && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )} */}
                        {/*</InputContainer>
                        </OptionalInput> */}
                        <OptionalInput>
                          {data.input?.bucketNames &&
                            data.input?.bucketNames.map((rep, index) => (
                              <div className="knowledge_domain_url_box bucket">
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="text"
                                    onChange={(evnt: {
                                      target: { value: any };
                                    }) =>
                                      handleRepositoryChange(
                                        index,
                                        evnt,
                                        data.id,
                                        'bucketNames'
                                      )
                                    }
                                    value={rep}
                                    name="bucketNames"
                                  />

                                  <InputContent>
                                    Bucket name {index + 1}
                                    {/* <Tooltip
                                      className="target customTip _description"
                                      zIndex={10000}
                                      arrowSize={6}
                                      tagName="span"
                                      content="test spare key"
                                      distance={5}
                                    >
                                      <InfoIcon />
                                    </Tooltip> */}
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() =>
                                      removeSiteUrl(index, data.id)
                                    }
                                  >
                                    <CloseIcon />
                                  </span>
                                </InputContainer>
                                {/* {!IsValidURL(rep) && state.showError && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )}*/}
                              </div>
                            ))}
                        </OptionalInput>
                        {data.input?.bucketNames.length <99 &&
                        <SmallButton
                          primary
                          style={{ marginBottom: 30 }}
                          onClick={() => addSiteUrl(data.id)}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                        }       
                      </BgWrapper>
                      <SmallButton
                        primary
                        onClick={() => {
                          scroollUp();
                          setShowAdditionalProp(!showAdditionalProp);
                        }}
                        style={{ marginBottom: '10px' }}
                      >
                        {showAdditionalProp ? (
                          <span>
                            <MinusIcon />
                          </span>
                        ) : (
                          <span>
                            <AddIcon />
                          </span>
                        )}
                        <label>Select subset of the site</label>
                      </SmallButton>
                      {showAdditionalProp && (
                        <>
                          {additionalObj &&
                            additionalObj.map((add: any, index: number) => (
                              <BgWrapper>
                                <InputContainer
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Input
                                    type="text"
                                    value={add.name}
                                    // onChange={(e: any) => {}}
                                    onClick={() => {
                                      setFolderDropDown(true);
                                      setNumber(index);
                                    }}
                                    style={{ paddingLeft: 10 }}
                                  />
                                  <InputContent>
                                    Additional Information
                                  </InputContent>
                                  <CancelButton
                                    onClick={() => setFolderDropDown(true)}
                                    style={{ display: 'block', top: '14px' }}
                                  >
                                    <DropdownWithCircle />
                                  </CancelButton>
                                  {folderDropDown && number === index && (
                                    <Downshift
                                      isOpen={folderDropDown}
                                      onOuterClick={() =>
                                        setFolderDropDown(false)
                                      }
                                    >
                                      {() => (
                                        <div>
                                          <DropdownUl
                                            style={{ marginTop: '48px' }}
                                          >
                                            {FolderDropValue.map((a) => (
                                              <DropdownLi
                                                onClick={() => {
                                                  setFolderDropDown(false);
                                                  setFolderType(a);
                                                  setName(index, a);
                                                }}
                                              >
                                                <DropdownLiSpan>
                                                  {a.name}
                                                </DropdownLiSpan>{' '}
                                                <Tooltip
                                                  className="kai_sharepoint target customTip"
                                                  zIndex={10000}
                                                  arrowSize={8}
                                                  tagName="span"
                                                  content={a.description}
                                                  distance={5}
                                                >
                                                  <InfoIcon />
                                                </Tooltip>
                                              </DropdownLi>
                                            ))}
                                          </DropdownUl>
                                        </div>
                                      )}
                                    </Downshift>
                                  )}
                                </InputContainer>

                                {add.name !== '' && (
                                  <>
                                    <div className="knowledge_ai_folder_box flex">
                                      <InputContainer
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <Input
                                          type="text"
                                          value={add.value}
                                          onChange={(e: {
                                            target: { value: string };
                                          }) =>
                                            setValue(
                                              index,
                                              folderType,
                                              e.target.value
                                            )
                                          }
                                          style={{ transition: '.2s' }}
                                        />
                                        <InputContent>Value</InputContent>
                                        <span
                                          onClick={() => {
                                            removeSubset(index);
                                          }}
                                        >
                                          <CloseIconWithCircle />
                                        </span>
                                      </InputContainer>
                                    </div>
                                    <Paragraph>{add.description}</Paragraph>
                                  </>
                                )}
                              </BgWrapper>
                            ))}
                          {additionalObj[additionalObj.length - 1].value !==
                            '' && (
                            <SmallButton
                              primary
                              style={{ marginBottom: 30 }}
                              onClick={() => addMoreObj()}
                            >
                              <span>
                                <AddIcon />
                              </span>
                              <label>Add More</label>
                            </SmallButton>
                          )}
                          <div id="scrollIn" />
                        </>
                      )}
                    </EmptyDiv>
                    {console.log(needUpdate(), 'asdkjhfkjhasjd')}
                    <ButtonContainer className="footer_button_fixed">
                      <div className="popup_footer_button_container slack_buton_center">
                        <Button
                          type="button"
                          className={
                            needUpdate()
                              ? 'hidden'
                              : data.name !== ''
                              ? // data.input.hostUrl !== ''
                                // data.input.spaceKeys.length > 0
                                // !state.showError
                                // !checkingValidUrl
                                ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => {
                            updateConnection(data.id);
                          }}
                        >
                          <label> Update</label>
                        </Button>
                        {status === 'loader' && (
                          <span className="connect_account_loading">
                            <img src={LoadingSvgImage} />
                          </span>
                        )}
                        {status === 'error' && (
                          <span
                            className={
                              checkingDuplicate(data.input.bucketNames) &&
                              // IsValidURL(data.input.domain) &&
                              checkingUpdateName(data.id).length === 0
                                ? 'connect_account_failed hide'
                                : 'connect_account_failed'
                            }
                          >
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {status === 'apiError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {/* {!checkingDuplicate(data.input.spaceKeys) &&
                          status === 'error' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Space Keys must be unique
                                </span>
                              </div>
                            </span>
                          )} */}
                      </div>
                    </ButtonContainer>
                  </PopupScrollHeight>
                ))
              )}
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
    </>
  );
}
