import knowledgeHomeImg from '@/common/images/knowledge_AI.svg';
import knowledgeWebsiteImg from '@/common/images/Website_KB.svg';
import knowledgeExternalImg from '@/common/images/External_KB.svg';
import knowledgeWorkativImg from '@/common/images/Workativ_KB.svg';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import React from 'react';

export function KnowledgeAiHome(props: any) {
  return (
    <section className="knowledge_home_container">
      <div className="knowledge_Home_Box">
        <div>
          <img src={knowledgeHomeImg} alt="Knowledge Home img" />
        </div>
        <div>
          <p>
            Knowledge AI leverages the power of Large Language Model (LLM) and
            Generative AI to respond to user queries.
          </p>
          <h6>
            Get started by connecting our Generative AI with your knowledge
            source.
          </h6>
        </div>
      </div>
      <div className="knowledge_home_column">
        <div className="knowledge_home_column_box">
          <div>
            <img src={knowledgeWebsiteImg} alt="Knowledge Website img" />
          </div>
          <div>
            <h5>Website KB</h5>
            <p>Connect with your Website</p>
            <Button
              className="btn btn-primary"
              onClick={() => {
                props.props.history.push(
                  `/workspace/${props.props.match.params.workspacename}/knowledge/website-kb`
                );
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
        <div className="knowledge_home_column_box">
          <div>
            <img src={knowledgeExternalImg} alt="Knowledge External img" />
          </div>
          <div>
            <h5>External KB</h5>
            <p>Connect with your Knowledge Source</p>
            <p>(E.g SharePoint, ServiceNow, etc)</p>
            <Button
              className="btn btn-primary"
              onClick={() => {
                props.props.history.push(
                  `/workspace/${props.props.match.params.workspacename}/knowledge/external-kb`
                );
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
        <div className="knowledge_home_column_box">
          <div>
            <img src={knowledgeWorkativImg} alt="Knowledge Workativ img" />
          </div>
          <div>
            <h5>Workativ KB</h5>
            <p>
              Upload and connect with Workativ<br/> Knowledge Base
            </p>
            <Button
            className="editor_btn_disabled"
            >
              Coming Soon
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
