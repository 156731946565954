import { AttachmentT } from '@/Assistant/Dialogue/kind';
import { eventType, teamsType } from './LiveagentMockFile';
import { Markdown } from './MarkDown';

/*
1. CloseConversation ( Http api only, just broadcast message will be thrown via websocket )
2. Transfer ( Http api only, just broadcast message will be thrown via websocket )
3. AgentOffline ( Http api only, just broadcast message will be thrown via websocket )
4. AgentOnline ( Http api only, just broadcast message will be thrown via websocket )
*/
export enum ReceiveEvents {
  AgentMessage = 'agent_message', // User to Agent Message
  UserMessage = 'user_message', // Agent to User Message Done
  Connect = 'connect',
  Comment = 'agent_comment',
  Mention = 'agent_mention',
  Queue = 'queued',
  Disconnect = 'Disconnect',
  QueueMessage = 'queued_message',
  // CloseConversationProcessed = 'CloseConversationProcessed', // no need.. once close conversation eevnt send from front end.. backend need to close the connection and  change the conversation status to Closed
  // TransferProcessed = 'TransferProcessed', // this info need to be shown in UI???
  // AgentOfflineProcessed = 'AgentOfflineProcessed',
  // AgentOnlineProcessed = 'AgentOnlineProcessed',
  FeedbackView = 'agent_feedback', // Button view of feedback from liveagent backend
  FeedbackProcessed = 'user_feedback', // We are not going to take feedback for Now
  // CommentProcessed="CommentProcessed",
  // MentionProcessed = 'MentionProcessed',
  AgentAttachment = 'agent_attachment',
  AgentTransfer = 'agent_transfer',
  AgentReassigned = 'agent_reassigned',
  Refresh ="refresh"
}

export type NormalMd = {
  md_type: 'md_normal';
  value: string;
};

export type JoinMd = {
  md_type: 'md_join';
  lhs: MarkdownMdObject;
  rhs: MarkdownMdObject;
};

export type LinkMd = {
  md_type: 'md_hyperlink';
  link: string;
  value: MarkdownMdObject;
};

export type SimpleMd = {
  md_type: 'md_bold' | 'md_italic' | 'md_underline';
  value: MarkdownMdObject;
};

export type NewlineMd = {
  md_type: 'md_newline';
};

export type OrderedMd = {
  md_type: 'md_ordred_list';
  list: [MarkdownMdObject];
};

export type UnOrderedMd = {
  md_type: 'md_unordred_list';
  list: [MarkdownMdObject];
};

export type MarkdownMdObject =
  | SimpleMd
  | NormalMd
  | JoinMd
  | LinkMd
  | NewlineMd
  | OrderedMd
  | UnOrderedMd;

export type NormalText = {
  type: 'normal_text';
  value: MarkdownTextObject;
};

export type JoinText = {
  md_type: 'join';
  lhs: MarkdownTextObject;
  rhs: MarkdownTextObject;
};

export type LinkText = {
  md_type: 'hyperlink';
  link: string;
  value: MarkdownTextObject;
};

export type SimpleText = {
  type: 'bold' | 'italic' | 'underline';
  value: MarkdownTextObject;
};

export type NewlineText = {
  type: 'md_newline';
};

export type OrderedText = {
  type: 'ordred_list';
  list: [MarkdownTextObject];
};

export type UnOrderedText = {
  type: 'unordred_list';
  list: [MarkdownTextObject];
};

export type MarkdownTextObject =
  | SimpleText
  | NormalText
  | JoinText
  | LinkText
  | NewlineText
  | OrderedText
  | UnOrderedText;

// For message | Connect | Queue | Disconnect | TransferAgent
export type ReceiveMessage = {
  id: string;
  agent_id: string;
  session_id: string;
  message: MarkdownMdObject;
  attachments: AttachmentT; //will change
  message_time: Date;
  delivery_status: boolean; // Default false
  deleted: boolean; // Default false
};

export type FeedbackType = 'Satisfied' | 'NotSatisfied';

export type MessageProcessed = {
  event_type: 'agent_message';
  data: {
    id: string;
    agent_id: string;
    session_id: string;
    message: MarkdownMdObject;
    attachments: AttachmentT; //will change
    message_time: Date;
    delivery_status: boolean; // Default false
    deleted: boolean; // Default false
  };
};
export type Event = {
  id: string;
  time: string;
  workspace: string;
};
export type UserConnectEvent = Event & {
  event_type: ReceiveEvents.Connect;
  data: {
    id: string;
    session_id: string;
    message: MarkdownMdObject;
    bot_id: string;
    team_id: string | null;
    agent_id: string;
    platform: string;
    workspace: string;
    name: string;
    email: string;
    attachments: object;
    message_time: Date;
    delivery_status: number;
    deleted: boolean;
    // type:ReceiveEvents.Connect,
    // data:{
    // user:{
    //     first_name:string
    // },
    // platform:string,
    // start_time:Date,
    // escalated_by:string,
  };
};
export type DisconnectEvent = Event & {
  event_type: ReceiveEvents.Disconnect;
  data: {
    id: string;
    session_id: string;
    message: MarkdownMdObject;
    bot_id: string;
    team_id: string | null;
    agent_id: string;
    platform: string;
    workspace: string;
    name: string;
    email: string;
    attachments: object;
    message_time: Date;
    delivery_status: number;
    deleted: boolean;
    // user:{
    //     first_name:string
    // },
    // platform:string,
    // start_time:string,
    // escalated_by:string,
  };
};

export type QueueMessageEvent = Event & {
  event_type: ReceiveEvents.QueueMessage;
  id: string;
  time: string;
  workspace: string;
  data: {
    id: string;
    session_id: string;
    message:MarkdownMdObject;
    message_time: Date;
    delivery_status: number;
    deleted: number;
  };
};
export type QueuedEvent = Event & {
  event_type: ReceiveEvents.Queue;
  data: {
    id: string;
    session_id: string;
    message: MarkdownMdObject;
    bot_id: string;
    team_id: string | null;
    agent_id: string | null;
    platform: string;
    workspace: string;
    name: string;
    email: string;
    attachments: object;
    message_time: Date;
    delivery_status: number;
    deleted: boolean;
    // user:{
    //     first_name:string
    // },
    // platform:string,
    // start_time:string,
    // escalated_by:string,
  };
};
export type CommentEvent = Event & {
  event_type: ReceiveEvents.Comment | ReceiveEvents.Mention;
  workspace: string;
  data: {
    id: string;
    message: MarkdownMdObject; // Yet to write
    agent_id: string; // Who mentioned
    mention?: string[]; // Ids of the mentioned agent
    delivery_status: number;
    message_time: Date;
  };
};

// export type ReceiveEvent = ReceiveMessage | MessageProcessed | ConnectEvent |DisconnectEvent  |CommentProcessed
// {
//     type: ReceiveEvents
//     data: ReceiveMessage | MessageProcessed | ConnectEvent
// }
export type UserMessageEvent = Event & {
  event_type: 'user_message';
  data: ReceiveMessage;
};

export type NotificationEvent = Event & {
  event_type: 'queue_notification';
  data: NotificationDataT;
};
type NotificationDataT = {
  id: string;
  session_id: string;
  message: MarkdownMdObject;
  bot_id: string;
  platform: string;
  workspace: string;
  name: string;
  email: string;
  attachments: [];
  message_time: string;
  delivery_status: number;
  deleted: number;
  chat_id: number;
  accessible_teams:string[]
};

export type AgentMessageEvent = Event & {
  event_type: 'agent_message';
  data: SendMessage;
};

export type AgentAttachment = Event & {
  event_type: 'agent_attachment';
  data: SendMessage;
};
export type TransferT = {
  id: string;
  session_id: string;
  current_agent_id: number;
  transferred_agent_id: number | undefined;
  team_id: string;
  // transferred_agent_uuid: string;
  message_time: Date;
  delivery_status: number;
  deleted: number;
};

export type AgentTransferT = Event & {
  event_type: 'agent_transfer';
  data: TransferT;
};
export type AgentReassigned = Event & {
  event_type: 'agent_reassigned';
  data: SendMessage;
};

export type SessionEvents =
  | UserMessageEvent
  | AgentMessageEvent
  | UserConnectEvent
  | DisconnectEvent
  | QueuedEvent
  | CommentEvent
  | FeedbackReq
  | CloseConvoReq
  | eventType
  | teamsType
  | AgentAttachment
  | AgentReassigned
  | QueueMessageEvent;
export type WebSocketEvents =
  | UserMessageEvent
  | AgentMessageEvent
  | UserConnectEvent
  | DisconnectEvent
  | QueuedEvent
  | CommentEvent
  | FeedbackReq
  | CloseConvoReq
  | userFeedback
  | AgentAttachment
  | AgentReassigned
  | QueueMessageEvent;
/* 
From Frontend Events
 1. message
 2. Comment | Mention
 3. Feedback | FeedbackWithExit
*/

export enum SendEvents {
  AgentMessage = 'agent_message', // Agent to User Message
  Comment = 'agent_comment',
  Mention = 'agent_mention',
  Feedback = 'Feedback',
  FeedbackWithExit = 'FeedbackWithExit',
  CloseConversation = 'CloseConversation',
  AgentAttachment = 'agent_attachment',
  AgentTransfer = 'agent_transfer',
}
export interface Feedback {
  output: FeedOutput[];
}

export interface FeedOutput {
  option: FeedOption;
  output: string;
}

export interface FeedOption {
  options: FeedOption2[];
  title: string;
}

export interface FeedOption2 {
  label: string;
  value: string;
}

// For Feedback | FeedbackWithExit
export type FeedbackReq = Event & {
  event_type: ReceiveEvents.FeedbackView;
  data: {
    message: Feedback;
    session_id: string;
    agent_id: string;
    message_time: Date;
    delivery_status: number; // Default false
    deleted: number; // Default false
  };
};
export type FeedbackSend = Event & {
  event_type: ReceiveEvents.FeedbackView;
  data: {
    session_id: string;
    agent_id: string;
    message_time: Date;
    delivery_status: number; // Default false
    deleted: number; // Default false
  };
};

export type userFeedback = Event & {
  event_type: ReceiveEvents.FeedbackProcessed;
  data: {
    id: string;
    session_id: string;
    agent_id: string;
    message: MarkdownMdObject;
    message_time: Date;
    delivery_status: number; // Default false
    deleted: number; // Default false
  };
};
export type CloseConvoReq = Event & {
  event_type: SendEvents.CloseConversation;
  data: {
    session_id: string;
    agent_id: string;
    message_time: Date;
    delivery_status: number; // Default false
    deleted: number; // Default false
  };
};

// For Message |
export type SendMessage = {
  id: string;
  session_id: string;
  agent_id: string;
  message: Markdown;
  attachments: string[]; // will change
  message_time: Date;
  delivery_status: number; // Default false
  deleted: number; // Default false
};
export enum RefreshEvents {
  userQueued = 'user_queued',
  AgentConnect ="agent_connected",
  ClosedSession="session_closed",
  Comment = 'agent_commented',
  AgentStatus = 'agent_status_updated',
  Mention = 'agent_mentioned',
}

export type RefreshEvent = Event &{
    event_type: ReceiveEvents.Refresh,
    data: {
        id: string,
        session_id:string,
        workspace: string,
        event_category: {
            category: RefreshEvents,
            agent_list?:number[]
        }
    }
}

// // For Comment | Feedback
// export type Comment = {
//     id:string,
//     message: MarkdownMdObject, // Yet to write
//     agent_id: string, // Who mentioned
//     mention: string[], // Ids of the mentioned agent
//     delivery_status: boolean,
//     message_time: Date
// }

// export type SendEvent = {
//     event: SendEvents | Record<string, never>
//     data: SendMessage | Comment | Feedback |CloseConvo | Record<string, never>
// }

// Hi @Manjula @Aruna

// join {
//     lhs :{
//         md_type:"md_normal",
//         value: "hi"
//     }
//     rhs :{
//         md_type: "md_mention",
//         value: "@manjula"
//     }
// }
