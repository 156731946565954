/*eslint-disable*/
import * as R from 'ramda'
import React, { useContext, useEffect, useState } from 'react'

import { DownArrowCircle } from '@/common/Icons/Icons'
import { Error } from '@/common/components/ErrorBoundary'
import Validate from '@/common/components/FormValidate'
import InputBox from '@/common/components/InputBox'
import { Loader } from '@/common/components/Loader'
import { PopupWrapper } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { flowValidations, newLine } from '@/common/utils/_validation-utils'

import { MarketPlaceApi, automationAPIURLWithWorkspace, postJson, wrapperGetJson } from '@/common/utils/api-utils'
import { NonCanvasPanelContext, SidePanelContext } from './Canvas'
import { CanvasRightPanel as CRP, CanvasRightPanel } from './CanvasRightPanel'
import {
    ActionInitialState,
    AutomationMeta,
    NodeT,
    NodeView,
    None,
    Tree,
    TreeT,
    UploadToMarketPlaceAcknowledgement,
    mapTreeWithPath,
    reduceTree
} from './types'
import { Input, InputContent, ActionPanelPopup, Wrapper as ActionWrapper, Button, ButtonContainer } from '@/common/styled/Workflow.Dumb'
import { getProductId } from '@/common/utils/utils'

type UploadPanelActions =
    | { kind: 'View' }
    | { kind: 'Create New' }
    | { kind: 'Replace'; selected: null | string; selectedName: null | string }
    | { kind: 'Loading' }
    | { kind: 'Error'; message: string }

export const MarketPlacePanel = (_props: any) => {
    const { rightPanelState, setRightPanelState, treeData, setEditTreeData } = useContext(SidePanelContext)
    const {
        actionState: [, setNonCanvasAction],
        parentAutomation,
        workspaceName,
        parentId
    } = useContext(NonCanvasPanelContext)
    const howItWorks = constructHowItWorks(treeData.tree, parentAutomation)

    const [uploadStep, setUploadStep] = useState<UploadPanelActions>({ kind: 'Loading' })
    const [myUploads, setMyupload] = useState<any>(null)

    useEffect(() => {
        loadMyUploads(workspaceName).then(data => {
            setMyupload(data)
            setUploadStep({ kind: 'View' })
        })
    }, [])

    const [uploadData, setUploadData] = useState<AutomationMeta>(parentAutomation)

    const uploadToMarketPlace = async () => {
        try {
            let resp: any = await postJson(
                automationAPIURLWithWorkspace(workspaceName, MarketPlaceApi.UploadToMarketPlace)
            )({
                parentId,
                name: uploadData.name,
                description: uploadData.description
            })
            if (resp.data && resp.data.type == 'success') {
                setNonCanvasAction(UploadToMarketPlaceAcknowledgement)
                loadMyUploads(workspaceName).then(data => {
                    setMyupload(data)
                })
            } else {
                setUploadStep({
                    kind: 'Error',
                    message:
                        resp.data.error || resp.error
                            ? resp.error
                                ? resp.error
                                : resp.data.error
                            : 'Something went wrong!<br /> Please try again afer sometime.'
                })
            }
        } catch (error) {
            let err = error as any
            setUploadStep({
                kind: 'Error',
                message: err.response
                    ? err.response.data.error
                    : 'Something went wrong!<br /> Please try again afer sometime.'
            })
        }
    }
    const resetRightPanel = () => {
        setRightPanelState({
            ...rightPanelState,
            currentNode: ActionInitialState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] }
        })
        setEditTreeData(treeData)
    }
    const onNameChange = (name: string) => {
        setUploadData({
            ...uploadData,
            name
        })
    }
    const replaceInMarketPlace = async () => {
        try {
            if (uploadStep.kind === 'Replace' && uploadStep.selected != null) {
                let resp: any = await postJson(
                    automationAPIURLWithWorkspace(workspaceName, MarketPlaceApi.UploadToMarketPlace)
                )({
                    parentId,
                    name: uploadData.name,
                    description: uploadData.description,
                    replaceWith: uploadStep.selected
                })
                if (resp.data && resp.data.type == 'success') {
                    setNonCanvasAction(UploadToMarketPlaceAcknowledgement)
                    loadMyUploads(workspaceName).then(data => {
                        setMyupload(data)
                    })
                } else {
                    setUploadStep({
                        kind: 'Error',
                        message:
                            resp.data.error || resp.error
                                ? resp.error
                                    ? resp.error
                                    : resp.data.error
                                : 'Something went wrong!<br /> Please try again afer sometime.'
                    })
                }
            }
        } catch (error) {
            let err = error as any
            setUploadStep({
                kind: 'Error',
                message: err.response
                    ? err.response.data.error
                    : 'Something went wrong!<br /> Please try again afer sometime.'
            })
        }
    }
    if (uploadStep.kind == 'Loading') {
        return (
            <LWrapper>
                <Loader.PopupLoader show={true} />
            </LWrapper>
        )
    }
    if (uploadStep.kind === 'Error') {
        return (
            <LWrapper>
                <div className="flow_canvas_popup_error">
                    <Error.AddorEdit
                        info={uploadStep.message}
                        onClick={() => {
                            setNonCanvasAction(None)
                        }}
                        buttonName={'Okay'}
                    />
                </div>
            </LWrapper>
        )
    }

    return (
        <ActionWrapper>
            <ActionPanelPopup>
            <PopupWrapper>
                <CRP.StepHeader
                    title={'Upload to Marketplace'}
                    description={'Upload this Workflow to marketplace'}
                    infocontent="Upload the app workflow to marketplace."
                    hasMinWidth={true}
                />
                {uploadStep.kind == 'View' && (
                    <React.Fragment>
                        <div className="error_handle_autoamtion_bg step_three_error margin-top-60 edit_action_height">
                            <Validate defaultValue={{ flowName: true, flowDescription: true }}>
                                <InputBox
                                    validationList={flowValidations}
                                    value={uploadData.name}
                                    name="flowName"
                                    render={(
                                        value: string,
                                        valid: boolean,
                                        errorMsg: string,
                                        startValidation: () => void,
                                        handleInput: Function
                                    ) => {
                                        return (
                                            <React.Fragment>
                                                <div className="automation_global_form">
                                                    <div className="select_work_form">
                                                        {/* <h4>Name</h4> */}
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Automation Name"
                                                            onChange={event => {
                                                                startValidation()
                                                                handleInput('flowName', event.target.value)
                                                                onNameChange(event.target.value)
                                                            }}
                                                            value={value}
                                                        />
                                                        <InputContent>
                                                            Name
                                                                {/* <Tooltip
                                                                    className="target customTip _description"
                                                                    zIndex={10000}
                                                                    tagName="span"
                                                                    content="Select the Event that triggers this Automation"
                                                                    distance={15}
                                                                    forceDirection={true}
                                                                    direction="up-start"
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip> */}
                                                            </InputContent>
                                                        <p className="error_msg">{!valid ? errorMsg : newLine}</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            </Validate>
                        </div>
                        {/* <div className="marketplace_upload_btn"> */}
                        <ButtonContainer>
                            <div className='footer_button_fixed marketplace'>
                                <Button
                                    // className="button_marketplace"
                                    onClick={() => {
                                        setUploadStep({ kind: 'Replace', selected: null, selectedName: null })
                                    }}
                                >
                                    Replace
                                </Button>
                                <Button
                                    // className="button_marketplace"
                                    onClick={() => {
                                        setUploadStep({ kind: 'Create New' })
                                    }}
                                >
                                    Add New
                                </Button>
                                <Button
                                    // className="button_marketplace"
                                    onClick={() => {
                                        setNonCanvasAction(None)
                                        resetRightPanel()
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </ButtonContainer>
                        {/* </div> */}
                    </React.Fragment>
                )}
                {uploadStep.kind === 'Create New' && (
                    <React.Fragment>
                        <div className="error_handle_autoamtion_bg step_three_error margin-top-60 edit_action_height">
                            <div className="automation_nodes">
                                <div className="automation_nodes_p">
                                    {getProductId() === 'ASSISTANT' &&
                                        <> 
                                            <h4 style={{ fontWeight: 'bold' }}>How it works</h4>
                                            {howItWorks.map(item => (
                                                <p key={item.index}>
                                                    <span style={{ fontWeight: 'bold', color: '#000' }}>[{item.index}]</span>{' '}
                                                    {item.description}
                                                </p>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="marketplace_upload_btn">
                            <button
                                className="button_marketplace"
                                onClick={() => {
                                    uploadToMarketPlace()
                                    resetRightPanel()
                                }}
                            >
                                Add New
                            </button>
                            <button
                                className="button_marketplace"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                    resetRightPanel()
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </React.Fragment>
                )}

                {uploadStep.kind === 'Replace' && (
                    <React.Fragment>
                        <div className="error_handle_autoamtion_bg step_three_error margin-top-60 edit_action_height">
                            <div className="automation_global_form">
                                <div className="select_work_form">
                                    <CanvasRightPanel.AppsDropDown
                                        inputs={{
                                            inputs: myUploads,
                                            key: 'name',
                                            idKey: 'id'
                                        }}
                                        addDropDown
                                        updateSelection={(_: string, id: string) => {
                                            setUploadStep({ kind: 'Replace', selected: id, selectedName: _ })
                                        }}
                                        render={(makeDropDownVisible: () => void) => (
                                            <React.Fragment>
                                                <h4>Select Automation</h4>
                                                <input
                                                    type="text"
                                                    className="without_caret_input"
                                                    value={
                                                        uploadStep.selectedName != null
                                                            ? uploadStep.selectedName
                                                            : 'Select'
                                                    }
                                                    onClick={() => {
                                                        makeDropDownVisible()
                                                    }}
                                                    onChange={() => {}}
                                                />
                                                <div className="arrow_up_form">
                                                    <DownArrowCircle />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="marketplace_upload_btn">
                            <button
                                className="button_marketplace"
                                onClick={() => {
                                    replaceInMarketPlace()
                                    resetRightPanel()
                                }}
                            >
                                Replace
                            </button>
                            <button
                                className="button_marketplace"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                    resetRightPanel()
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </PopupWrapper>
            </ActionPanelPopup>
        </ActionWrapper>
    )
}
export const constructHowItWorks = (tree: TreeT<NodeT>, meta: AutomationMeta) => {
    const stepOneDescription = {
        index: '1',
        description:
            meta.type === 'no_trigger' ? `User request to ${meta.name.toLowerCase()} from bot` : tree.value.description
    }
    const stepThreeDescription = {
        index: '3',
        description: 'Send execution report to admin'
    }

    const stepTwoTree = tree.children[0].children[0]
    const description = mapTreeWithPath(
        stepTwoTree,
        (node, index) => {
            switch (node.kind) {
                case 'Function':
                    return { index, description: node.name, kind: node.kind }
                default:
                    return { index, ...R.pick(['kind', 'description'], node) }
            }
        },
        '2'
    )

    const stepTwoDescription = reduceTree(
        description,
        [],
        (accum: Array<{ index: string; description: string }>, curr) => {
            return [...accum, { index: curr.index ? curr.index : 'none', description: curr.description }]
        }
    )
    return [stepOneDescription, ...stepTwoDescription, stepThreeDescription]
}
export const loadMyUploads = async (wName: string) => {
    return wrapperGetJson(automationAPIURLWithWorkspace(wName, MarketPlaceApi.GetMyUploads)).then(
        (response: any) => response.data.output.myUploads
    )
}

const Wrapper = (props: any) => (
    <div className="automation_canvas_right">
        <div className="automation_canvas_right_popup automation_canvas_right_popup_notrigger">
            <div className="automation_canvas_right_popup_form">
                <div className="automation_rightside_form">{props.children}</div>
            </div>
        </div>
    </div>
)
const LWrapper = (props: any) => (
    <div className="automation_canvas_right">
        <div className="automation_canvas_right_popup">
            <div className="automation_canvas_right_popup_form">
                <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                    {props.children}
                </div>
            </div>
        </div>
    </div>
)
