/*eslint-disable*/

import Downshift from 'downshift'
import * as R from 'ramda'
import * as ramda from 'ramda'
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Tooltip as ChartTip,
    Label,
    ResponsiveContainer,
    XAxis,
    YAxis
} from 'recharts'

import { DateSelectInput } from '@/Flows/analytics/dateDropDown'
import { VideoButtonB } from '@/Videos/Videos'
import {
    BotDropdown,
    DialogCardIcon,
    DialogIcon,
    DownloadFromMarketplace,
    FcrIcon,
    GroupPersonIcon,
    InfoIcon,
    ProfileIcon,
    QueriesIcon,
    SlackIcon,
    SlackIconCC,
    SubPanelIcons,
    TeamsIcon,
    UserIconAnalyticsMenu,
    WidgetCopyIcon,
    WidgetIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { HeaderChild, Header as Headers } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import thumbs_down from '@/common/images/bot_page/thumbs_down.svg'
import thumbs_up from '@/common/images/bot_page/thumbs_up.svg'
import {
    BgLine,
    BotCard,
    BotCardWrapper,
    BotChannelsWrapper,
    Button,
    Card,
    CardContainer,
    CardHeader,
    ChannelContainer,
    ChartFeedbackWrapper,
    ChartWrapper,
    ChatHeaderWrapper,
    ChatWrapperLeft,
    ChatWrapperRight,
    Content,
    Count,
    CountNumber,
    CountWrapper,
    DropdownLi,
    DropdownLiSpan,
    DropdownUl,
    DropdownWrapper,
    FeedbackWrapper,
    Header,
    HeaderContainer,
    HeaderTabWrapper,
    HeaderTop,
    HeaderWrapper,
    HoverContent,
    Input,
    InputContent,
    InputWrapper,
    NodataContent,
    PieContent,
    ProgressBar,
    ProgressContent,
    ProgressLi,
    ProgressWrapper,
    Row,
    SettingsWrapper,
    Span,
    StatusWrapper,
    TabCardContainer,
    Wrapper
} from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ResponsivePie } from '@nivo/pie'

import { convertFromToDate, formatAMPM, formatDateInCard, MOCK_BOT_SUMMARY } from '@/common/utils/utils'
import { getJson, logEvent, watsonLogsApiURL } from '@/common/utils/api-utils'
import { AssistantT } from '../Construction/kind'
import { getAssistants, updateAssistant } from '../Mechanisms/action'
import { Paragraph } from '@/common/styled/Workflow.Dumb'
import { ProfileImg } from '@/common/styled/Subscription.Xtra.Dumb'
import { MicrosoftIcon } from '@/Flows/Icons'
import { useFetchAssistant, useFetchSummary, useUpdateSummary } from './BotSummaryAPI'
import { BotSessionTab, percentageCalculate } from './TabUIElements'
import { QueriesTab } from './QueriesTab'
import { BotDetailsTab } from './BotDetailsTab'
import { UserMetricsTab } from './UserMetrics'
import { ROITab } from './ROITab'
import { UnAnsweredTab } from './UnAnswered'
import { AssistantSubHeader } from '../BotHome/PortalElement'
import BasicDateRangePicker from './CustomDatePicker'

var approx = require('approximate-number')

type BotDetailsT = {
    user_count: number
    conversation_count: number
    satisfied_count: number
    not_satisfied_count: number
    day_wise_status_count: {
        date: string
        resolved: number
        abandoned: number
        escalated?: undefined
    }[]
    top_5_resolved_count: {
        dialog: string
        dialog_id: string
        status_count: number
    }[]
    top_5_escalated_count: {
        dialog: string
        dialog_id: string
        status_count: number
    }[]
    top_5_abandoned_count: {
        dialog: string
        dialog_id: string
        status_count: number
    }[]
}

export type OverAllSummaryT = {
    total_unique_users: number,
    total_chatbot_session: number,
    total_user_queries: number,
    total_auto_resolved_queries: number,
    avg_session_per_user: number,
    avg_query_per_session: number,
    avg_query_per_user: number,
    avg_query_resolved_per_user: number
}

type chatT = {
    active_users: {
        bot: string
        agent: string
        queued: string
    }
    active_integrations: number
}

export const BotDetail = (props: any) => {
    // const S = useContext(JourneyContext)
    // console.log('context', S)
    // const [pageLoading, setPageLoading] = useState<boolean>(true)
    const [dateOptions, setOptions] = useState('last30days')
    const [customDates, setCustomDates] = useState({ from_date: null, to_date: null })

    // const [overAllSummary, setBotDetails] = useState({})

    const [chatIntegrations, setChatIntegrations] = useState<{ teams: chatT; slack: chatT; widget: chatT }>({
        teams: {
            active_users: {
                bot: '0',
                agent: '0',
                queued: '0'
            },
            active_integrations: 0
        },
        slack: {
            active_users: {
                bot: '0',
                agent: '0',
                queued: '0'
            },
            active_integrations: 0
        },
        widget: {
            active_users: {
                bot: '0',
                agent: '0',
                queued: '0'
            },
            active_integrations: 0
        }
    })

    const [agentIntegrtions, setAgentIntegrations] = useState({
        active_agent_integrations: 0
    })

    const [bots, setBots] = useState<any>([])
    // console.log(bots,"datadata")
    const { botsOutput,botsAPIStatus } =  useFetchAssistant(props.match.params.workspacename)
    const assistantName = props.match.params.assistantname
    const botId = botsOutput? botsOutput.filter((a)=>a.name == assistantName)[0].id : ""
    const { overAllSummaryStatus,summaryRetry,dataUpdatedAt, isRefetching, overAllSummary } = useFetchSummary(props.match.params.workspacename, botId ,"30",
        {
            enabled: !!botsOutput,
            // onSuccess:(response:any)=>{
            //     console.log(response,"response")
            //     setBotDetails(response)
            // }
        }
    )

    const {mutate,status} = useUpdateSummary()

    const pageLoading = botsAPIStatus=='loading' || overAllSummaryStatus == 'loading'

    const [pageError, setPageError] = useState({
        error: false,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    })

    function retry(){
        summaryRetry()
    }

    // function callAPI() {
    //     setPageLoading(true)
    //     setPageError({ ...pageError, error: false })
    //     const assistant = MOCK_BOT_SUMMARY
    //         ? Promise.resolve<any[]>([
    //               {
    //                   id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
    //                   name: 'ManjulaBot',
    //                   created: '2021-06-22T10:31:38',
    //                   updated: '2021-08-02T06:23:00',
    //                   deployed: '2021-08-02T06:23:00',
    //                   lang_type: 'english',
    //                   published_at: null,
    //                   avg_cost_to_resolve_an_issue: 15.5,
    //                   avg_time_spend_by_agent: 8,
    //                   fallback_node: '165db996-d060-4298-8ebe-a7c3d0c20a73',
    //                   welcome_node: 'c099172f-e651-4ae9-8858-2c99b0091a18'
    //               }
    //           ])
    //         : getAssistants(props.match.params.workspacename)

    //     assistant
    //         .then(data => {
    //             const bot = data.filter((b: any) => b.name == props.match.params.assistantname)[0]
    //             const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    //             const chatChannelAndAnalyticsApi = MOCK_BOT_SUMMARY
    //                 ? Promise.all([
    //                       { active_users: { bot: '0', agent: '0', queued: '0' }, active_integrations: 0 },
    //                       { active_users: { bot: '0', agent: '0', queued: '0' }, active_integrations: 0 },
    //                       { active_users: { bot: '0', agent: '0', queued: '0' }, active_integrations: 0 },
    //                       { active_agent_integrations: 0 },
    //                       getJson(
    //                           watsonLogsApiURL(
    //                               `/${props.match.params.workspacename}/analytics/bot_summary_report/${bot.id}/30?timezone=Asia/Kolkatta`
    //                           )
    //                       )
    //                   ])
    //                 : Promise.all([
    //                       getJson(
    //                           `${process.env.REACT_APP_TEAMS_INTEGRATION_URL}/${props.match.params.workspacename}/${bot.id}/active_users`
    //                       ),
    //                       getJson(
    //                           `${process.env.REACT_APP_NEW_SLACK_INTEGRATION_URL}/${props.match.params.workspacename}/${bot.id}/active_users`
    //                       ),
    //                       getJson(
    //                           `${process.env.REACT_APP_WIDGET_API}/${props.match.params.workspacename}/${bot.id}/widget/getActiveUsers`
    //                       ),
    //                       getJson(
    //                           `${process.env.REACT_APP_AGENT_HANDOFF}/${props.match.params.workspacename}/${bot.id}/agent/activeIntegrations`
    //                       ),
    //                       getJson(
    //                           watsonLogsApiURL(
    //                               `/${props.match.params.workspacename}/analytics/bot_summary_report/${bot.id}/30?timezone=${timezone}`
    //                           )
    //                       )
    //                   ])
    //             chatChannelAndAnalyticsApi.then(response => {
    //                 setBots(data)
    //                 setChatIntegrations({ teams: response[0], slack: response[1], widget: response[2] })
    //                 setAgentIntegrations(response[3])
    //                 setBotDetails(response[4] as any)
    //                 setPageLoading(false)
    //             })
    //         })
    //         .catch(err => {
    //             console.log('err', err)
    //             setPageError({
    //                 ...pageError,
    //                 error: true
    //             })
    //             setPageLoading(false)
    //         })
    // }

    // useEffect(() => {
        // callAPI()
        // logEvent(props.match.params.workspacename, {
        //     event:"EVENT::BOT_SUMMARY::VIEW", event_meta:{}
        // })
    // }, [])

    const bot = botsOutput && botsOutput.filter((b: any) => b.name == props.match.params.assistantname)[0]

    // function updateCostDetails(type: 'cost' | 'hr', value: string) {
    //     updateAssistant(
    //         props.match.params.workspacename,
    //         bot.id,
    //         type == 'cost'
    //             ? { avg_cost_to_resolve_an_issue: parseFloat(value) }
    //             : { avg_time_spend: parseFloat(value) },
    //         bot.name
    //     )
    //         .then((res: AssistantT) => {
    //             setBots(
    //                 bots.map((b: any) =>
    //                     b.id == bot.id
    //                         ? {
    //                               ...b,
    //                               [`${
    //                                   type == 'cost' ? 'avg_cost_to_resolve_an_issue' : 'avg_time_spend_by_agent'
    //                               }`]: value
    //                           }
    //                         : b
    //                 )
    //             )
    //         })
    //         .catch(_ => {
    //             // failure()
    //         })
    // }

    function returnDate(filter: DateFilterT) {
        switch (filter) {
            case 'last14days':
                return 14
            case 'last30days':
                return 30
            case 'last7days':
                return 7
            default:
                return filter
        }
    }

    const changeDateFilter = (filter: DateFilterT) => {
        if(filter != "customrange"){
            mutate({botId,workspace:props.match.params.workspacename,date:returnDate(filter)})
        }
    }

    const changeToCustomDateFilter = (date:{from_date:Date,to_date:Date}) =>{
        mutate({ botId,workspace:props.match.params.workspacename,date:date })
    }

    const { history, match } = props as any

    return (
        <ErrorBoundary
            error={pageError}
            render={(err: any, info: any) => {
                return err && <Error.PageLoadingError onClick={retry} info={info} btnName="Retry" />
            }}
        >
            {/* <Headers>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title" style={{ marginBottom: 0 }}>
                            Bot Summary
                        </div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    <Button>
                        <span className="header_button">
                            {' '}
                            <DownloadFromMarketplace />
                        </span>
                        <label>Download</label>
                    </Button>
                    <VideoButtonB id={'READ_BOT_SUMMARY_PAGE'} />
                </HeaderChild>
            </Headers> */}
            <AssistantSubHeader>
                <>
                    <VideoButtonB id={'READ_BOT_SUMMARY_PAGE'} showOnlyIcon/>
                </>
            </AssistantSubHeader>
            <Wrapper style={{position: 'relative', bottom: 8}}>
                {pageLoading ? (
                    <Loader.PageLoader show={true} />
                ) : (
                    <>
                        <BotHeader
                            botDetails={overAllSummary as OverAllSummaryT}
                            {...props}
                            bot={bot}
                            // updateCostDetails={updateCostDetails}
                            updateCostDetails={()=>{}}
                            changeDateFilter={changeDateFilter}
                            dateOptions={dateOptions}
                            setOptions={setOptions}
                            dataUpdatedAt={dataUpdatedAt}
                            summaryRetry={summaryRetry}
                            loading={isRefetching || status=='loading'}
                            customDates={customDates}
                            setCustomDates={setCustomDates}
                            changeToCustomDateFilter={changeToCustomDateFilter}
                        />
                        <RealtimeStatus chatIntegrations={chatIntegrations} agentIntegrtions={agentIntegrtions} />
                        <TabStatus chatIntegrations={chatIntegrations} agentIntegrtions={agentIntegrtions} botId={botId} workspace={props.match.params.workspacename} overAllSummary={overAllSummary as any} selectedBot={bot as AssistantT} match={match} history={history} dateOptions={dateOptions} customDates={customDates}/>
                        {/* <ChartWithoutWrapper dateOptions={dateOptions} botDetails={botDetails as BotDetailsT} /> */}
                        {/* <ChartWithWrapper dateOptions={dateOptions} bot={bot} botDetails={botDetails as BotDetailsT} /> */}
                        {/* <BotDetails {...props} botDetails={botDetails as BotDetailsT} /> */}
                    </>
                )}
            </Wrapper>
        </ErrorBoundary>
    )
}

export type DateFilterT = 'last7days' | 'yesterday' | 'today' | 'last14days' | 'last30days' | "customrange"
export const items: DateSelectInput[] = [
    {
        date: 'last30days',
        label: 'Last 30 days'
    },
    {
        date: 'last14days',
        label: 'Last 14 days'
    },
    {
        date: 'last7days',
        label: 'Last 7 days'
    },
    {
        date: 'yesterday',
        label: 'Yesterday'
    },
    {
        date: 'today',
        label: 'Today'
    },
    {
        date: "customrange",
        label: "Custom Range"
    }
]

export function BasiceDropdown(props:{dateOptions:string,setOptions:(date:string)=>void,changeDateFilter:(date:DateFilterT)=>void,showCustomDatePicker:()=>void}){
    const {dateOptions, setOptions, showCustomDatePicker} = props
    const [open, setOpen] = useState(false)

    function dates() {
        return items.filter(it => it.date == dateOptions)[0].label
    }

    return(
        <DropdownWrapper style={{width: 200}}>
                        <Input activeDropdown={open} onClick={() => setOpen(!open)} type="text" value={dates()} />
                        <InputContent>Show by</InputContent>
                        <span onClick={() => setOpen(!open)}>
                            <BotDropdown />
                        </span>
                        {open && (
                            <Downshift isOpen={true} onOuterClick={() => setOpen(false)}>
                                {() => (
                                    <div>
                                        <DropdownUl>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('last30days')
                                                    props.changeDateFilter('last30days' as DateFilterT)
                                                }}
                                            >
                                                <DropdownLiSpan> Last 30 days</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('last14days')
                                                    props.changeDateFilter('last14days' as DateFilterT)
                                                }}
                                            >
                                                <DropdownLiSpan> Last 14 days</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('last7days')
                                                    props.changeDateFilter('last7days' as DateFilterT)
                                                }}
                                            >
                                                <DropdownLiSpan> Last 7 days</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('yesterday')
                                                    props.changeDateFilter('yesterday' as DateFilterT)
                                                }}
                                            >
                                                <DropdownLiSpan>Yesterday</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('today')
                                                    props.changeDateFilter('today' as DateFilterT)
                                                }}
                                            >
                                                <DropdownLiSpan>Today</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                            <DropdownLi
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOptions('customrange')
                                                    props.changeDateFilter('customrange' as DateFilterT)
                                                    showCustomDatePicker()
                                                }}
                                            >
                                                <DropdownLiSpan>Custom Range</DropdownLiSpan>{' '}
                                            </DropdownLi>
                                        </DropdownUl>
                                    </div>
                                )}
                            </Downshift>
                        )}
                    </DropdownWrapper>
    )
}

const BotHeader = (props: any) => {
    const { dateOptions, setOptions, setCustomDates, changeToCustomDateFilter } = props

    const [state,setState] = useState({ customDatePicker : "hide" })

    return (
        <HeaderWrapper>
            {props.loading && 
                <div className='first_section_loader'>
                    <Loader.PopupLoader show={true}/>
                </div>
            }
            <HeaderContainer>
                <HeaderTop>
                    <span>
                        <DialogIcon />
                    </span>
                    <div className="header_top_right">
                        {props.match.params.assistantname}
                        <Content>Detailed analytics on your bot performance.</Content>
                    </div>
                </HeaderTop>
                <div className='assistant_bot_header_right'>
                    <div className="header_right_content">
                        <p>This report was generated on&nbsp;
                            {formatDateInCard(new Date(props.dataUpdatedAt).toString(),true)} at {' '}
                            {formatAMPM(new Date(props.dataUpdatedAt).toString(),true)} (UTC) - {' '}
                            <span onClick={()=>props.summaryRetry()}>Refresh</span></p>
                    </div>
                    <div className='header_right_content summary_drop_down_li'>
                        <BasiceDropdown dateOptions={dateOptions} changeDateFilter={props.changeDateFilter} setOptions={setOptions} showCustomDatePicker={()=>{
                            setState({customDatePicker:"show"})
                        }}/>
                        <div className="tab_dropdown_date">
                            {state.customDatePicker == "show" && (
                                <div className='tab_calender'>
                                    <BasicDateRangePicker
                                        setDate={(date: Date[]) => {
                                            const customDates = {
                                                from_date: convertFromToDate(date[0].toString()) as any,
                                                to_date: convertFromToDate(date[1].toString()) as any,
                                            }
                                            setCustomDates(customDates)
                                            changeToCustomDateFilter(customDates)
                                            setState({
                                                customDatePicker: "hide"
                                            });
                                        }}
                                        show={state.customDatePicker == "show"}
                                        customDates={props.customDates}
                                        closeCalendar={()=>{
                                        setState({
                                            ...state,
                                            customDatePicker:"hide"
                                        });
                                        }}
                                    />
                                </div>
                            )}  
                        </div>   
                    </div>                    
                </div>
                
            </HeaderContainer>

            {/* card */}
            <CardWrapper {...props} />
            {/* card */}
        </HeaderWrapper>
    )
}

const CardWrapper = (props: { botDetails: OverAllSummaryT }) => {
    const { botDetails } = props

    const [costDropdown, setCostDropdown] = useState(false)
    const [hrsDropdown, setHrsDropdown] = useState(false)

    const p = props as any
    const bot = p.bot

    const [updateDetail, setUpdateDetail] = useState({
        avg_time_spend_by_agent: 0,
        avg_cost_to_resolve_an_issue: 0
    })

    useEffect(() => {
        setUpdateDetail({
            avg_time_spend_by_agent: bot ? bot.avg_time_spend_by_agent : 0,
            avg_cost_to_resolve_an_issue: bot ? bot.avg_cost_to_resolve_an_issue : 0
        })
        costDropdown && setCostDropdown(false)
        hrsDropdown && setHrsDropdown(false)
    }, [bot])

    function retnFn(i: number) {
        switch (i) {
            case 0:
                return botDetails.total_unique_users
            case 1:
                return botDetails.total_chatbot_session
            case 2:
                return botDetails.total_user_queries
            default:
                return botDetails.total_auto_resolved_queries
        }
    }

    return (
        <CardContainer>
            {CardDetails.map((data, i) => (
                <Card>
                    <CardHeader>
                        {data.header}{' '}
                        {/* <Tooltip
                            className="target_bot"
                            tipContentHover
                            zIndex={5000}
                            arrowSize={5}
                            tagName="span"
                            content={data.hoverContent}
                        >
                            <span>{data.image}</span>
                        </Tooltip> */}
                    </CardHeader>
                    {data.subheader && <CardHeader>
                        <p>({data.subheaderPercentage&&<span>{percentageCalculate((botDetails.total_auto_resolved_queries/botDetails.total_user_queries)*100)}%</span>}{data.subheader})</p>
                    </CardHeader>}
                    <CountNumber>
                        <CountWrapper>
                            {retnFn(i)}
                            {/* <span>{data.hours && data.hours}</span> */}
                        </CountWrapper>
                        <CountWrapper className="count_wrapper_icon">
                            <div>
                                {data.iconImage}
                            </div>
                        </CountWrapper>
                        {data.header.toLowerCase() == 'Agent Hours Saved'.toLowerCase() && (
                            <SettingsWrapper>
                                {data.settingsIcon && (
                                    <div className="settings" onClick={() => setHrsDropdown(true)}>
                                        {data.settingsIcon}
                                        {hrsDropdown && (
                                            <HoverContent>
                                                <Content>
                                                    Time spent by your support agent to resolve or address an issue,
                                                    ticket, or service request.
                                                </Content>
                                                <Downshift isOpen={true} onOuterClick={() => setHrsDropdown(false)}>
                                                    {() => (
                                                        <span>
                                                            <InputWrapper>
                                                                <Input
                                                                    type="number"
                                                                    value={updateDetail.avg_time_spend_by_agent}
                                                                    onChange={(event: any) => {
                                                                        setUpdateDetail({
                                                                            ...updateDetail,
                                                                            avg_time_spend_by_agent: event.target.value
                                                                        })
                                                                    }}
                                                                    style={{ width: 42, height: 26 }}
                                                                />
                                                                <span style={{ marginLeft: 4, marginRight: 4 }}>
                                                                    hrs
                                                                </span>
                                                                <SmallButton style={{ minWidth: 54}}
                                                                    onClick={() => {
                                                                        p.updateCostDetails(
                                                                            'hr',
                                                                            updateDetail.avg_time_spend_by_agent
                                                                        )
                                                                    }}
                                                                >
                                                                    <label>Apply</label>
                                                                </SmallButton>
                                                            </InputWrapper>
                                                        </span>
                                                    )}
                                                </Downshift>
                                            </HoverContent>
                                        )}
                                    </div>
                                )}
                            </SettingsWrapper>
                        )}
                        {data.header.toLowerCase() == 'Cost Savings'.toLowerCase() && (
                            <SettingsWrapper>
                                {data.settingsIcon && (
                                    <div className="settings" onClick={() => setCostDropdown(true)}>
                                        {data.settingsIcon}
                                        {costDropdown && (
                                            <HoverContent>
                                                <Content>
                                                    Average cost to resolve an issue, ticket or service request.
                                                </Content>
                                                <Downshift isOpen={true} onOuterClick={() => setCostDropdown(false)}>
                                                    {() => (
                                                        <span>
                                                            <InputWrapper>
                                                                <span
                                                                    style={{
                                                                        marginLeft: '0px',
                                                                        marginRight: '5px',
                                                                    }}
                                                                >
                                                                    $
                                                                </span>{' '}
                                                                <Input
                                                                    type="number"
                                                                    value={updateDetail.avg_cost_to_resolve_an_issue}
                                                                    onChange={(event: any) => {
                                                                        setUpdateDetail({
                                                                            ...updateDetail,
                                                                            avg_cost_to_resolve_an_issue:
                                                                                event.target.value
                                                                        })
                                                                    }}
                                                                />
                                                                <SmallButton
                                                                    style={{ minWidth: 54, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        p.updateCostDetails(
                                                                            'cost',
                                                                            updateDetail.avg_cost_to_resolve_an_issue
                                                                        )
                                                                    }}
                                                                >
                                                                    <label>Apply</label>
                                                                </SmallButton>
                                                            </InputWrapper>
                                                        </span>
                                                    )}
                                                </Downshift>
                                            </HoverContent>
                                        )}
                                    </div>
                                )}
                            </SettingsWrapper>
                        )}
                    </CountNumber>
                </Card>
            ))}
        </CardContainer>
    )
}

const CardDetails = [
    {
        header: 'Total Unique Users',
        count: '248',
        image: <InfoIcon />,
        iconImage: <GroupPersonIcon />
        //hoverContent: 'Total number of unique users who interacted with the bot in a specific time period.'
    },
    {
        header: 'Total Chatbot Sessions',
        count: '900',
        image: <InfoIcon />,
        iconImage: <DialogCardIcon />
        //hoverContent: 'Total number of bot sessions between bot and user in a specific time period.'
    },
    {
        header: 'Total User Queries',
        subheader: 'queries from chatbot sessions',
        count: '698',
        image: <InfoIcon />,
        iconImage: <QueriesIcon />
        // hoverContent: 'Total number of bot sessions auto resolved by the bot in a specific time period.'
    },
    {
        header: 'First Contact Resolution (FCR)',
        subheaderPercentage: '80% ',
        subheader: ' of total user queries were auto-resolved',
        count: '5863',
        image: <InfoIcon />,
        iconImage: <FcrIcon />,
        // hours: 'hrs',
        // hoverContent:
        //     'Total number of agent hours saved by the bot by auto resolving user issues and requests in a specific time period.',
          settingsIcon: <SubPanelIcons.settings />
    }
    // {
    //     header: 'Cost Savings',
    //     count: '$10819',
    //     image: <InfoIcon />,
    //     hoverContent:
    //         'Total cost saved to your company by the bot by auto resolving user issues and requests in a specific time period.',
    //     settingsIcon: <SubPanelIcons.settings />
    // }
]


const RealtimeStatus = (props: {
    chatIntegrations: { teams: chatT; slack: chatT; widget: chatT }
    agentIntegrtions: {
        active_agent_integrations: number
    }
}) => {
    const { chatIntegrations, agentIntegrtions } = props
    function retnFn(i: number) {
        const teamsUsers = chatIntegrations.teams.active_users
        const slackUsers = chatIntegrations.slack.active_users
        const widgetUsers = chatIntegrations.widget.active_users

        switch (i) {
            case 0:
                const team = parseInt(teamsUsers.agent) + parseInt(teamsUsers.bot) + parseInt(teamsUsers.queued)
                const slack = parseInt(slackUsers.agent) + parseInt(slackUsers.bot) + parseInt(slackUsers.queued)
                const widget = parseInt(widgetUsers.agent) + parseInt(widgetUsers.bot) + parseInt(widgetUsers.queued)

                return team + slack + widget
            case 1:
                return parseInt(teamsUsers.agent) + parseInt(slackUsers.agent) + parseInt(widgetUsers.agent)
            case 2:
                return (
                    chatIntegrations.teams.active_integrations +
                    chatIntegrations.slack.active_integrations +
                    chatIntegrations.widget.active_integrations
                )
            case 3:
                return agentIntegrtions.active_agent_integrations
            default:
                return 0
        }
    }
    return (
        <StatusWrapper>
            {/* <HeaderWrapper>
                <Header>Realtime Status</Header>
                <HeaderContainer>
                    <CardContainer>
                        {StatusDetails.map((data, i) => (
                            <Card>
                                <CardHeader>
                                    {data.header}{' '}
                                    <Tooltip
                                        className="target_bot"
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        tagName="span"
                                        content={data.hoverContent}
                                    >
                                        <span>{data.image}</span>
                                    </Tooltip>
                                </CardHeader>
                                <CountNumber>
                                    <CountWrapper>{retnFn(i)}</CountWrapper>
                                    {data.toDisplayChatbotIcons != undefined && (
                                        <ChatbotIcons
                                            chatIntegrations={chatIntegrations}
                                            agentIntegrtions={agentIntegrtions}
                                            showOnlyAgentChannels={i == 3}
                                        />
                                    )}
                                </CountNumber>
                            </Card>
                        ))}
                    </CardContainer>
                </HeaderContainer>
            </HeaderWrapper> */}
        </StatusWrapper>
    )   
    
}

const TabStatus = (props: {
    chatIntegrations: { teams: chatT; slack: chatT; widget: chatT }
    agentIntegrtions: {
        active_agent_integrations: number
    },
    overAllSummary: OverAllSummaryT
    botId:string
    workspace:string
    selectedBot:AssistantT
    match:any
    history:any
    dateOptions:string
    customDates:{
        from_date: null | string
        to_date: null | string
    }
}) => {
    const { overAllSummary , selectedBot,match,history, dateOptions, customDates} = props

    const [currentTab,setCurrentTab] = useState<"bot_session"|"user_queries"|"agent_handover"|"bot_details"|"user_metrics"|"roi"|"un_answered">("bot_session")

    const [tabLoading,setTabLoading] = useState(false)

    function renderTabUI(){
        switch(currentTab){
            case "bot_session":
                return <BotSessionTab botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} setTabLoading={setTabLoading} overAllDate={dateOptions} customDates={customDates}/>
            case "bot_details":
                return <BotDetailsTab botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} history={history} match={match} setTabLoading={setTabLoading}/>
            case 'user_metrics':
                return <UserMetricsTab botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} setTabLoading={setTabLoading} overAllDate={dateOptions} customDates={customDates}/>
            case 'roi':
                return <ROITab botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} setTabLoading={setTabLoading} overAllDate={dateOptions} customDates={customDates}/>
            case 'un_answered':
                return <UnAnsweredTab botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} setTabLoading={setTabLoading} history={history} match={match} overAllDate={dateOptions} customDates={customDates}/>
            default:
                return <QueriesTab currenPagetTab={currentTab} botId={props.botId.length>0 ? props.botId : undefined } workspace={props.workspace} overAllSummary={overAllSummary} selectedBot={selectedBot} history={history} match={match} setTabLoading={setTabLoading} overAllDate={dateOptions} customDates={customDates}/>
        }
    }

    return (
        <StatusWrapper>
            {tabLoading &&  
                <div className='second_section_loader'>
                    <Loader.PopupLoader show={true}/>
                </div>
            } 
            <HeaderTabWrapper>
                <div className="tab_menu dashboard_menu">
                    <a  className={currentTab==='bot_session'? "active":""} onClick={()=>setCurrentTab("bot_session")}>Bot Sessions </a>
                    <a  className={currentTab==='user_queries'? "active":""} onClick={()=>setCurrentTab("user_queries")}>User Queries</a>
                    <a  className={currentTab==='agent_handover'? "active":""} onClick={()=>setCurrentTab("agent_handover")}>Agent Handover</a>
                    <a  className={currentTab==='bot_details'? "active":""} onClick={()=>setCurrentTab("bot_details")}>Live Bot Status</a>
                    <a  className={currentTab==='user_metrics'? "active":""} onClick={()=>setCurrentTab("user_metrics")}>User Metrics</a>
                    <a  className={currentTab==='roi'? "active":""} onClick={()=>setCurrentTab("roi")}>ROI</a>
                    {/* <a  className={currentTab==='un_answered'? "active":""} onClick={()=>setCurrentTab("un_answered")}>Unanswered Queries</a> */}
                    {/* <a  className={currentTab==='knowledge_ai'? "active":""} onClick={()=>setCurrentTab("knowledge_ai")}>Knowledge AI</a> */}
                </div>
                
                {/* Bot Sessions */}
                {renderTabUI()}
                 {/* User Queries */}
                 <div className="bot_sessions_container" style={{display: 'none'}}>
                    <div className="bot_sessions_heaader">
                        <div><h5>Closer look at user queries during bot sessions</h5></div>
                        <div>This report was generated on 12/09/22 at 8:09:15 pm - <span>Refresh</span></div>
                    </div>
                    <HeaderContainer>
                        <TabCardContainer className="tab_user_queris_one">
                            {StatusDetails1.map((data, i) => (
                                <Card>
                                    <CardHeader>
                                        {data.header}{' '}
                                    </CardHeader>
                                    <CardHeader>
                                         <span>{data.hours && data.hours}</span>
                                    </CardHeader>
                                    <CountNumber>
                                        <CountWrapper>{data.count}</CountWrapper>
                                    </CountNumber>
                                </Card>
                            ))}
                        </TabCardContainer>
                    </HeaderContainer>
                    <HeaderContainer>
                        <TabCardContainer className="tab_user_queris_two">
                            {StatusDetails2.map((data, i) => (
                                <Card style={{height: '140px'}}>
                                    <CardHeader>
                                        <p style={{height: 'auto'}}>(<span>{data.headerPercentage}</span> {data.header}{' '})</p>
                                    </CardHeader>
                                    <CardHeader>
                                        <p>{data.subheader}{' '}</p>
                                    </CardHeader>
                                    <CountNumber>
                                        <CountWrapper>{data.count}</CountWrapper>
                                    </CountNumber>
                                </Card>
                            ))}
                        </TabCardContainer>
                    </HeaderContainer>

                    <ChartFeedbackWrapper>
                        <ChartWrapper chartsBg>
                            <ChatWrapperLeft chartsPadding>
                                <ChatHeaderWrapper style={{height: 80}}>
                                    <Header>
                                        <h4>User Queries</h4>
                                        <p>How user queries were handled</p>
                                    </Header>
                                    <Header>
                                        <DropdownWrapper style={{margin: 0}}>
                                        <Input/>
                                        <InputContent>Show by</InputContent>
                                        <span>
                                            <BotDropdown />
                                        </span>
                                            {/* <Downshift isOpen={true}>
                                                {() => (
                                                    <div>
                                                        <DropdownUl>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 30 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 14 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 7 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan>Yesterday</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan>Today</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                        
                                                        </DropdownUl>
                                                    </div>
                                                )}
                                            </Downshift> */}
                                        </DropdownWrapper>
                                    </Header>

                                </ChatHeaderWrapper>
                                <div className="bar_charts_wrapper">
                                    <BarCharts botDetails={{
                                        user_count: 0,
                                        conversation_count: 0,
                                        satisfied_count: 0,
                                        not_satisfied_count: 0,
                                        day_wise_status_count: [],
                                        top_5_resolved_count: [],
                                        top_5_escalated_count: [],
                                        top_5_abandoned_count: []
                                    }} dateOptions={''} />
                                </div>
                            </ChatWrapperLeft>
                            <ChatWrapperRight chartsPadding>
                                <ChatHeaderWrapper style={{height: 60}}>
                                    <Header>Total User Queries</Header>
                                </ChatHeaderWrapper>
                                <BotSessionsPieChat botDetails={{
                                    user_count: 0,
                                    conversation_count: 0,
                                    satisfied_count: 0,
                                    not_satisfied_count: 0,
                                    day_wise_status_count: [],
                                    top_5_resolved_count: [],
                                    top_5_escalated_count: [],
                                    top_5_abandoned_count: []
                                }}  />
                                <div className="pie_content_container">
                                    <PieContent>
                                        <div>
                                            <span className="_dot_1"></span>Auto Resolved
                                        </div>
                                        <div>
                                            <span className="_dot_2"></span>Agent Escalated
                                        </div>
                                    </PieContent>
                                    <PieContent className="pie_content_section_two">
                                        <div>
                                            <span className="_dot_3"></span>Abandoned
                                        </div>
                                        <div>
                                            <span className="_dot_4"></span>Unresolved
                                        </div>
                                    </PieContent>
                                </div>
                            </ChatWrapperRight>
                        </ChartWrapper>
                    </ChartFeedbackWrapper>

                   <div className="tab_section_wrapper">
                       <div className="tab_section_header">
                            <div>
                                <h5>User Queries</h5>
                                <p>Compare how user queries were handled over a specific period</p>
                            </div>
                            <div className="tab_dropdown_date">
                                <DropdownWrapper>
                                    <Input/>
                                    <InputContent>Show by</InputContent>
                                    <span>
                                        <BotDropdown />
                                    </span>
                                        {/* <Downshift isOpen={true}>
                                            {() => (
                                                <div>
                                                    <DropdownUl>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 30 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 14 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 7 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan>Yesterday</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan>Today</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                    
                                                    </DropdownUl>
                                                </div>
                                            )}
                                        </Downshift> */}
                                </DropdownWrapper>
                            </div>
                       </div>
                       <div className="tab_section_graph">
                           <p>Compare how user queries were handled over a specific period</p>
                       </div>
                   </div>    
                   <div className="tab_section_wrapper">
                       <div className="tab_section_header sub_header">
                            <div>
                                <h5>How most frequent user queries were handled</h5>
                                {/* <p></p> */}
                            </div>
                       </div>
                       <div className="tab_menu tab_section user_queries">
                            <a  className="active">Auto-resolved using Dialogs </a>
                            <a  className="">Auto-resolved using FAQs</a>
                            <a  className="">Escalated to agent</a>
                            <a  className="">Abandoned by users</a>
                            <a  className="">Unresolved</a>
                        </div> 
                        <div className="tab_user_queris_table_section">
                            <div className="tab_user_queris_table_container">
                                <div className="tab_user_queris_table">
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Unlock Account</a>
                                        <p>12</p>
                                        <span className="bar bar_1"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Password Reset   </a>
                                        <p>09</p>
                                        <span className="bar bar_2"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Create Service Request</a>
                                        <p>08</p>
                                        <span className="bar bar_3"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Apply Leave</a>
                                        <p>07</p>
                                        <span className="bar bar_4"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Add User to Group</a>
                                        <p>07</p>
                                        <span className="bar bar_5"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Get User Details</a>
                                        <p>08</p>
                                        <span className="bar bar_6"></span>
                                    </div>
                                    
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Unlock Account</a>
                                        <p>12</p>
                                        <span className="bar bar_1"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Password Reset   </a>
                                        <p>09</p>
                                        <span className="bar bar_2"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Create Service Request</a>
                                        <p>08</p>
                                        <span className="bar bar_3"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Apply Leave</a>
                                        <p>07</p>
                                        <span className="bar bar_4"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Apply Leave</a>
                                        <p>07</p>
                                        <span className="bar bar_5"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Apply Leave</a>
                                        <p>08</p>
                                        <span className="bar bar_6"></span>
                                    </div>
                                </div> 
                            </div>        
                            <div className="tab_user_queris_piechart">
                                    <p></p>
                            </div>         
                        </div>
                   </div> 
                           
                </div> 
                 
                 {/* Agent Handover */}                       
                <div className="bot_sessions_container" style={{display: 'none'}}>
                    <div className="bot_sessions_heaader">
                        <div><h5>Closer look at queries that were escalated to agents (agent handover)</h5></div>
                        <div>This report was generated on 12/09/22 at 8:09:15 pm - <span>Refresh</span></div>
                    </div>
                    <HeaderContainer>
                        <TabCardContainer className="tab_user_queris_one">
                            {StatusDetailsAgent.map((data, i) => (
                                <Card style={{height: '160px'}}>
                                    <CardHeader>
                                        {data.header}{' '}
                                    </CardHeader>
                                    <CardHeader>
                                        <p>{data.subheader}{' '}</p>
                                    </CardHeader>
                                    <CountNumber>
                                        <CountWrapper>{data.count}</CountWrapper>
                                    </CountNumber>
                                </Card>
                            ))}
                        </TabCardContainer>
                    </HeaderContainer>

                    <ChartFeedbackWrapper>
                        <ChartWrapper chartsBg>
                            <ChatWrapperLeft chartsPadding>
                                <ChatHeaderWrapper style={{height: 80}}>
                                    <Header>
                                        <h4>Agent Handover Sessions</h4>
                                        <p>Bot resolved user queries vs. queries escalated to agents</p>
                                    </Header>
                                    <Header>
                                        <DropdownWrapper style={{margin: '5px 0px 0 10px'}}>
                                        <Input/>
                                        <InputContent>Show by</InputContent>
                                        <span>
                                            <BotDropdown />
                                        </span>
                                            {/* <Downshift isOpen={true}>
                                                {() => (
                                                    <div>
                                                        <DropdownUl>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 30 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 14 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan> Last 7 days</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan>Yesterday</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                            <DropdownLi>
                                                                <DropdownLiSpan>Today</DropdownLiSpan>{' '}
                                                            </DropdownLi>
                                                        
                                                        </DropdownUl>
                                                    </div>
                                                )}
                                            </Downshift> */}
                                        </DropdownWrapper>
                                    </Header>

                                </ChatHeaderWrapper>
                                <div className="bar_charts_wrapper">
                                    <BarCharts botDetails={{
                                        user_count: 0,
                                        conversation_count: 0,
                                        satisfied_count: 0,
                                        not_satisfied_count: 0,
                                        day_wise_status_count: [],
                                        top_5_resolved_count: [],
                                        top_5_escalated_count: [],
                                        top_5_abandoned_count: []
                                    }} dateOptions={''} />
                                </div>
                            </ChatWrapperLeft>
                            <ChatWrapperRight chartsPadding>
                                <ChatHeaderWrapper className="sub_header">
                                    <Header>Feedback on agents
                                    <Tooltip
                                        className="target_bot"
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        tagName="span"
                                        content="User feedback on live agent handover"
                                    >
                                        <span className="info_icon">
                                            <InfoIcon />
                                        </span>
                                    </Tooltip>

                                    </Header>
                                </ChatHeaderWrapper>
                                <BotSessionsPieChat botDetails={{
                                    user_count: 0,
                                    conversation_count: 0,
                                    satisfied_count: 0,
                                    not_satisfied_count: 0,
                                    day_wise_status_count: [],
                                    top_5_resolved_count: [],
                                    top_5_escalated_count: [],
                                    top_5_abandoned_count: []
                                }}  />
                                
                            </ChatWrapperRight>
                        </ChartWrapper>
                    </ChartFeedbackWrapper>

                   <div className="tab_section_wrapper">
                       <div className="tab_section_header">
                            <div>
                                <h5>Agent Handover Sessions</h5>
                                <p>Compare agent handover sessions over a specific period</p>
                            </div>
                            <div className="tab_dropdown_date">
                                <DropdownWrapper>
                                    <Input/>
                                    <InputContent>Show by</InputContent>
                                    <span>
                                        <BotDropdown />
                                    </span>
                                        {/* <Downshift isOpen={true}>
                                            {() => (
                                                <div>
                                                    <DropdownUl>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 30 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 14 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan> Last 7 days</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan>Yesterday</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                        <DropdownLi>
                                                            <DropdownLiSpan>Today</DropdownLiSpan>{' '}
                                                        </DropdownLi>
                                                    
                                                    </DropdownUl>
                                                </div>
                                            )}
                                        </Downshift> */}
                                </DropdownWrapper>
                            </div>
                       </div>
                       <div className="tab_section_graph">
                           <p>Compare how user queries were handled over a specific period</p>
                       </div>
                   </div>    

                   <ChartFeedbackWrapper>
                        <ChartWrapper chartsBg>
                            <ChatWrapperLeft chartsPadding>
                                <ChatHeaderWrapper style={{height: 80}}>
                                    <Header>
                                        <h4>Agent Handover Triggers</h4>
                                        <p>Queries that triggered agent handover</p>
                                    </Header>
                                </ChatHeaderWrapper>
                                <div className="bar_charts_wrapper">
                                    <BarCharts botDetails={{
                                        user_count: 0,
                                        conversation_count: 0,
                                        satisfied_count: 0,
                                        not_satisfied_count: 0,
                                        day_wise_status_count: [],
                                        top_5_resolved_count: [],
                                        top_5_escalated_count: [],
                                        top_5_abandoned_count: []
                                    }} dateOptions={''} />
                                </div>
                            </ChatWrapperLeft>
                            <ChatWrapperRight chartsPadding>
                                
                                <BotSessionsPieChat botDetails={{
                                    user_count: 0,
                                    conversation_count: 0,
                                    satisfied_count: 0,
                                    not_satisfied_count: 0,
                                    day_wise_status_count: [],
                                    top_5_resolved_count: [],
                                    top_5_escalated_count: [],
                                    top_5_abandoned_count: []
                                }}  />
                                
                            </ChatWrapperRight>
                        </ChartWrapper>
                    </ChartFeedbackWrapper>
                   
                           
                </div> 


                {/* Unanswered Queries */}
                <div className="bot_sessions_container" style={{display: 'none'}}>
                    <div className="bot_sessions_heaader">
                        <div><h5>Queries that were unanswered by the bot (as no dialogs or FAQs were trained to respond)</h5></div>
                        {/* <div>This report was generated on 12/09/22 at 8:09:15 pm - <span>Refresh</span></div> */}
                    </div>
                    <HeaderContainer>
                        <TabCardContainer className="tab_user_queris_unanswered">
                            {StatusDetailsUnanswered.map((data, i) => (
                                <Card style={{height: '120px'}}>
                                    <CardHeader>
                                        {data.header}
                                    </CardHeader>
                                    <CardHeader>
                                        <p>{data.subheader}{' '}</p>
                                    </CardHeader>
                                    <CountNumber>
                                        <CountWrapper>{data.count}</CountWrapper>
                                    </CountNumber>
                                </Card>
                            ))}
                        </TabCardContainer>
                    </HeaderContainer>

                   <div className="tab_section_wrapper">
                       <div className="tab_section_header">
                            <div>
                                <h5>Unanswered Queries</h5>
                                <p>User queries unanswered by the bot over a specific period</p>
                            </div>
                            <div className="tab_dropdown_date">
                                <DropdownWrapper>
                                    <Input/>
                                    <InputContent>Show by</InputContent>
                                    <span>
                                        <BotDropdown />
                                    </span>
                                </DropdownWrapper>
                            </div>
                       </div>
                       <div className="tab_section_graph">
                           <p>Compare how user queries were handled over a specific period</p>
                       </div>
                   </div>  

                    <div className="tab_section_wrapper">
                       <div className="tab_section_header">
                            <div>
                                <h5>Unanswered Queries</h5>
                                <p>Compare unanswered queries over a specific period.</p>
                            </div>
                            <div className="tab_dropdown_date">
                                <DropdownWrapper>
                                    <Input/>
                                    <InputContent>Show comparision</InputContent>
                                    <span>
                                        <BotDropdown />
                                    </span>
                                </DropdownWrapper>
                            </div>
                       </div>
                       <div className="tab_section_graph">
                           <p>Compare how user queries were handled over a specific period</p>
                       </div>
                   </div>    
                   <div className="tab_section_wrapper unanswered">
                       <div className="tab_section_header sub_header">
                            <div>
                                <h5>Most frequent user queries that were unanswered by the bot (as no dialogs or FAQs were trained to respond)</h5>
                            </div>
                       </div>
                        <div className="tab_user_queris_table_section">
                            <div className="tab_user_queris_table_container">
                                <div className="tab_user_queris_table">
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Access to former employees files</a>
                                        <p>12</p>
                                        <span className="bar bar_1"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Windows 11 crashes  </a>
                                        <p>09</p>
                                        <span className="bar bar_2"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>I nee access to the official GoTo terminology e-mail address</a>
                                        <p>08</p>
                                        <span className="bar bar_3"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Your account is temporarily locked</a>
                                        <p>07</p>
                                        <span className="bar bar_4"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a href='#'>Hi, My Outlook isn’t launching on the laptop. Could you…</a>
                                        <p>07</p>
                                        <span className="bar bar_5"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>PC displays incorrect time</a>
                                        <p>08</p>
                                        <span className="bar bar_6"></span>
                                    </div>
                                    
                                    <div className="tab_user_queris_table_content">
                                        <a>Laptop displays incorrect time</a>
                                        <p>12</p>
                                        <span className="bar bar_1"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>Password Reset   </a>
                                        <p>09</p>
                                        <span className="bar bar_2"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>Create Service Request</a>
                                        <p>08</p>
                                        <span className="bar bar_3"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>Apply Leave</a>
                                        <p>07</p>
                                        <span className="bar bar_4"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>Apply Leave</a>
                                        <p>07</p>
                                        <span className="bar bar_5"></span>
                                    </div>
                                    <div className="tab_user_queris_table_content">
                                        <a>Apply Leave</a>
                                        <p>08</p>
                                        <span className="bar bar_6"></span>
                                    </div>
                                </div>  
                            </div>    

                            <div className="tab_user_queris_piechart">
                                <h5>Top 10 Unanswered Queries</h5>
                                    <p></p>
                            </div>         
                        </div>
                   </div>
                </div> 

            </HeaderTabWrapper>
        </StatusWrapper>
    )   
    
}

const ChatbotIcons = (props: {
    chatIntegrations: { teams: chatT; slack: chatT; widget: chatT }
    agentIntegrtions: {
        active_agent_integrations: number
    }
    showOnlyAgentChannels: boolean
}) => {
    const { chatIntegrations, agentIntegrtions, showOnlyAgentChannels } = props

    const teamsUsers = chatIntegrations.teams
    const slackUsers = chatIntegrations.slack
    const widgetUsers = chatIntegrations.widget

    return (
        <BotChannelsWrapper>
            {showOnlyAgentChannels ? (
                <ChannelContainer>
                    <span>
                        <TeamsIcon />
                        <Count>{agentIntegrtions.active_agent_integrations}</Count>
                    </span>
                </ChannelContainer>
            ) : (
                <ChannelContainer>
                    <span>
                        <SlackIconCC />
                        <Count>{slackUsers.active_integrations}</Count>
                    </span>
                    <span>
                        <TeamsIcon />
                        <Count>{teamsUsers.active_integrations}</Count>
                    </span>
                    <span>
                        <WidgetIcon />
                        <Count>{widgetUsers.active_integrations}</Count>
                    </span>
                </ChannelContainer>
            )}
        </BotChannelsWrapper>
    )
}

const StatusDetails = [
    {
        header: 'Avg. sessions per user',
        count: '10',
        image: <InfoIcon />,
        hoverContent: 'Average number of bot sessions by a user'
    },
    {
        header: 'Avg. session duration',
        count: '00:04:20',
        hours: '(hh:mm:ss)'
        // image: <InfoIcon />,
        // hoverContent: 'Total number of active user sessions with live agents.'
    },
    {
        header: 'sessions were rated positive out of total sessions that received a feedback',
        count: '00:04:20'
        // image: <InfoIcon />,
        // hoverContent: 'Total number of active bot channels available for users.'
    }
    // {
    //     header: 'Active Agent Channels',
    //     count: '1',
    //     image: <InfoIcon />,
    //     hoverContent: 'Total number of active agent channels for live agent handover.',
    //     toDisplayChatbotIcons: true
    // }
]

const StatusDetailsUsers = [
    {
        header: 'Avg. sessions per user',
        count: '10',
        image: <InfoIcon />,
        hoverContent: 'Average number of bot sessions by a user'
    },
    {
        header: 'Avg. session duration',
        count: '00:04:20',
        hours: '(hh:mm:ss)'
        // image: <InfoIcon />,
        // hoverContent: 'Total number of active user sessions with live agents.'
    },
    {
        header: 'sessions were rated positive out of total sessions that received a feedback',
        count: '00:04:20'
        // image: <InfoIcon />,
        // hoverContent: 'Total number of active bot channels available for users.'
    }
]

const StatusDetails1 = [
    {
        header: 'Avg. queries per session',
        count: '2'
    },
    {
        header: 'Avg. queries per user',
        count: '5'
    },
    {
        header: 'Avg. queries resolved per user',
        count: '20'
    },
    {
        header: 'Avg. queries resolved per user',
        count: '00:01:00',
        hours: '(hh:mm:ss)'
    }
]

const StatusDetailsAgent = [
    {
        header: 'Total user queries',
        count: '5000'
    },
    {
        header: '(10% of total queries)',
        subheader: 'queries escalated to agent',
        count: '500'
    },
    {
        header: 'Avg. agent session duration',
        subheader: '(hh:mm:ss)',
        count: '00:04:20'
    },
    {
        subheader: 'sessions were rated positive out of 400 sessions that received feedback',
        count: '320'
    }
]


const StatusDetails2 = [
    {
        headerPercentage: '57%',
        header: 'of total queries',
        subheader: 'queries auto-resolved using Dialogs',
        count: '2850'
    },
    {
        headerPercentage: '23%',
        header: 'of total queries',
        subheader: 'queries auto-resolved using FAQs',
        count: '1150'
    },
    {
        headerPercentage: '10%',
        header: 'of total queries',
        subheader: 'queries escalated to agent',
        count: '500'
    },
    {
        headerPercentage: '7%',
        header: 'of total queries',
        subheader: 'queries abandoned by users',
        count: '350'
    },
    {
        headerPercentage: '3%',
        header: 'of total queries',
        subheader: 'queries abandoned by users',
        count: '150'
    }
]

const StatusDetailsUnanswered = [
    {
        header: 'Total unanswered queries',
        subheader: '(queries were unanswered)',
        count: '150'
    },
    {
        header: 'Avg. unanswered queries per day',
        subheader: '',
        count: '3'
    }
]


const StatusDetails3 = [
    {
        headerPercentage: '57%',
        header: 'of total queries',
        subheader: 'queries auto-resolved using Dialogs',
        count: '2850'
    },
    {
        headerPercentage: '23%',
        header: 'of total queries',
        subheader: 'queries auto-resolved using FAQs',
        count: '1150'
    },
    {
        headerPercentage: '10%',
        header: 'of total queries',
        subheader: 'queries escalated to agent',
        count: '500'
    },
    {
        headerPercentage: '7%',
        header: 'of total queries',
        subheader: 'queries abandoned by users',
        count: '350'
    },
    {
        headerPercentage: '3%',
        header: 'of total queries',
        subheader: 'queries abandoned by users',
        count: '150'
    }
]

const BarchartTooltip = (props: any) => {
    const { series, seriesIndex, dataPointIndex, w } = props

    return `<div class="custom-tooltip">
                <p class="tooltip_date">
                    Bot Sessions: 
                    <span>
                        ${series.reduce(
                            (acc: any, val: any) => (acc += val[dataPointIndex] ? val[dataPointIndex] : 0),
                            0
                        )}
                    </span>
                </p>
                <p>
                   Auto Resolved: <span>${series[2][dataPointIndex] ? series[2][dataPointIndex] : 0}</span>
                </p>
                <p>
                    Agent Escalated: <span>${series[0][dataPointIndex] ? series[0][dataPointIndex] : 0}</span>
                </p>
                <p>
                    Abandoned: <span>${series[1][dataPointIndex] ? series[1][dataPointIndex] : 0}</span>
                </p>
            </div>`
}

function BarCharts(props: { botDetails: BotDetailsT; dateOptions: string }) {
    const BarchartTooltip = (props: any) => {
        // const { series, seriesIndex, dataPointIndex, w } = props
        // console.log("pLoad",props)

        const { active, payload, label } = props
        if (active && payload && payload.length) {
            const pLoad = payload[0].payload
            return (
                <div className="custom-tooltip">
                    <p className="tooltip_date">
                        Bot Sessions:
                        <span>{pLoad.resolved + pLoad.escalated + pLoad.abandoned}</span>
                    </p>
                    <p>
                        Auto Resolved: <span>{pLoad.resolved ? pLoad.resolved : 0}</span>
                    </p>
                    <p>
                        Agent Escalated: <span>{pLoad.escalated ? pLoad.escalated : 0}</span>
                    </p>
                    <p>
                        Abandoned: <span>{pLoad.abandoned ? pLoad.abandoned : 0}</span>
                    </p>
                </div>
            )
        }
        return null
    }
    type ChartData = {
        name: string
        resolved: number
        abandoned: number
        escalated: number
    }

    const { botDetails, dateOptions } = props

    function grouping() {
        const filteredValue = ramda.groupBy(
            (x: { date: string; resolved: number; abandoned: number; escalated?: undefined }) => {
                const split = x.date.split('/')
                return new Date(`${split[1]}/${split[0]}/${split[2]}`).toLocaleDateString()
            }
        )(botDetails.day_wise_status_count)
        return filteredValue
    }

    function filterDate() {
        return botDetails.day_wise_status_count.map(chart => {
            const date =
                dateOptions != 'today' && dateOptions != 'yesterday'
                    ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
                    : chart.date
            return {
                name: date,
                resolved: chart.resolved,
                abandoned: chart.abandoned ? chart.abandoned : 0,
                escalated:
                    chart.escalated === undefined || chart.escalated === null
                        ? 0
                        : ((chart.escalated as unknown) as number)
            }
        })
    }

    const datas: ChartData[] = filterDate()

    const modifiedData = datas

    const maxValue = modifiedData
        .map((x: ChartData) => x.resolved + x.escalated + x.abandoned)
        .reduce((prev, curr) => {
            return Math.max(prev, curr)
        }, 0)

    const length = `${maxValue}`.length - 1

    const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length))

    const yAxisTicks =
        unit === 5
            ? [5, 10, 15, 20, 25, 30]
            : R.unfold<number, number>(it => (it > maxValue + 2 * unit ? false : [it, it + unit]))(unit)
    return (
        // <div className="analytics_charts_left_img">
        <div style={{ width: '100%' }} className={datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'}>
            {datas.length > 0 ? (
                <>
                    <div className="bot-summary-all-sessions">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={830}
                                height={320}
                                data={modifiedData}
                                margin={{
                                    top: 15,
                                    right: 25,
                                    left: -10,
                                    bottom: 5
                                }}
                                barCategoryGap={'35%'}
                                barGap={'10%'}
                            >
                                <CartesianGrid />
                                <XAxis
                                    angle={45}
                                    dy={12}
                                    dx={12}
                                    dataKey="name"
                                    interval={0}
                                    label={{
                                        value: dateOptions == 'today' || dateOptions == 'yesterday' ? 'Hours' : 'Date',
                                        position: 'insideBottom',
                                        className: 'xAxisLabel',
                                        offset: -20
                                    }}
                                    scale="point"
                                    // padding={{ left: 5, right: 5 }}
                                    axisLine={false}
                                />
                                <YAxis
                                    allowDecimals={false}
                                    label={{
                                        value: 'Sessions',
                                        angle: -90,
                                        position: 'insideLeft',
                                        offset: 16,
                                        style: { textAnchor: 'middle' }
                                    }}
                                    axisLine={false}
                                    tickLine={{ stroke: '#F2F2F2' }}
                                    tickFormatter={(x: number) => {
                                        return approx(x)
                                    }}
                                    ticks={[0, ...yAxisTicks]}
                                    tickMargin={5}
                                />
                                <ChartTip isAnimationActive={false} cursor={false} content={<BarchartTooltip />} />
                                <Bar dataKey="escalated" stackId="a" fill="#EB6453" barSize={10} />

                                <Bar dataKey="abandoned" stackId="a" fill="#FFAB57" barSize={10} />
                                <Bar dataKey="resolved" stackId="a" fill="#7AE3DC" barSize={10} />
                                {/* ['#EB6453', '#FFAB57', '#7AE3DC'] */}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </>
            ) : (
                <NodataContent>No data available</NodataContent>
            )}
        </div>
        // </div>
    )
}

const ChartWithoutWrapper = (props: { botDetails: BotDetailsT; dateOptions: string }) => {
    const { botDetails, dateOptions } = props
    // reference
    function filterDate() {
        return botDetails.day_wise_status_count.map(chart => {
            return {
                name: dateOptions == 'today' || dateOptions == 'yesterday' ? chart.date : chart.date,
                resolved: chart.resolved ? chart.resolved : 0,
                abandoned: chart.abandoned ? chart.abandoned : 0,
                escalated: chart.escalated ? chart.escalated : 0
            }
        })
    }

    const datas: any = filterDate()
    // reference

    return (
        <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
                <ChatWrapperLeft chartsPadding>
                    <Header>Bot Sessions: {botDetails.conversation_count}</Header>
                    <div className={datas.length > 0 ? 'bar_charts_wrapper' : 'bar_no_charts_wrapper'}>
                        <BarCharts botDetails={botDetails} dateOptions={dateOptions} />
                    </div>
                </ChatWrapperLeft>
                <ChatWrapperRight chartsPadding>
                    <Header>Total Bot Sessions: {botDetails.conversation_count}</Header>
                    <BotSessionsPieChat botDetails={props.botDetails} />
                    <PieContent>
                        <div>
                            <span className="_dot_1"></span>Auto Resolved
                        </div>
                        <div>
                            <span className="_dot_3"></span>Agent Escalated
                        </div>
                        <div>
                            <span className="_dot_2"></span>Abandoned
                        </div>
                    </PieContent>
                </ChatWrapperRight>
            </ChartWrapper>
        </ChartFeedbackWrapper>
    )
}

export const CustomPieTooltip = (props: any) => {
    if (props) {
        return (
            <div className={'tooltiptext'}>
                {props.label}: {props.value}
            </div>
        )
    }
    return null
}

export function PieCharts(props: {
    data: {
        value: number
        id: string
        label: string
    }[]
    colors: string[]
}) {
    const resolved = props.data.filter(a => a.id == 'resolved')[0]
    const satisfied = props.data.filter(a => a.id == 'successRate')[0]

    return (
        <div className="analytics_charts_left_img pie-chart">
            <div className="pie_chart_img">
                <ResponsivePie
                    sortByValue={true}
                    data={props.data}
                    startAngle={-180}
                    innerRadius={0.85}
                    borderWidth={0}
                    enableSlicesLabels={false}
                    animate={true}
                    // tooltip={CustomPieTooltip}
                    colors={props.colors}
                    enableRadialLabels={false}
                    theme={{
                        tooltip: {
                            container: {
                                display: 'none'
                            }
                        }
                    }}
                />
                {resolved && (
                    <span className="pie_chart_center">
                        <p style={{ color: '#7AE3DC' }}>{resolved.value ? resolved.value : 0}%</p>
                        <p style={{ color: '#7AE3DC' }}>Auto Resolved</p>
                    </span>
                )}
                {satisfied && (
                    <span className="pie_chart_center">
                        <p style={{ color: '#A1E89B' }}>{satisfied.value ? satisfied.value : 0}%</p>
                        <p style={{ color: '#A1E89B' }}>Satisfied</p>
                    </span>
                )}
            </div>
        </div>
    )
}

function BotSessionsPieChat(props: { botDetails: BotDetailsT }) {
    const { botDetails } = props

    const total = botDetails.day_wise_status_count.reduce(
        (acc, val) =>
            (acc +=
                (val.abandoned ? (val.abandoned as any) : 0) +
                (val.resolved ? (val.resolved as any) : 0) +
                (val.escalated ? (val.escalated as any) : 0)),
        0
    )

    const abandoned =
        (botDetails.day_wise_status_count.reduce((acc, val) => (acc += val.abandoned ? (val.abandoned as any) : 0), 0) /
            total) *
        100
    const resolved =
        (botDetails.day_wise_status_count.reduce((acc, val) => (acc += val.resolved ? (val.resolved as any) : 0), 0) /
            total) *
        100
    const escalated =
        (botDetails.day_wise_status_count.reduce((acc, val) => (acc += val.escalated ? (val.escalated as any) : 0), 0) /
            total) *
        100

    const data = [
        { value: !resolved ? 0 : Math.round(resolved), id: 'resolved', label: 'Resolved' },
        { value: !escalated ? 0 : Math.round(escalated), id: 'escalated', label: 'Escalated' },
        { value: !abandoned ? 0 : Math.round(abandoned), id: 'abandoned', label: 'Abandoned' }
    ]
    return (
        // <div className="pie_chat">
        total == 0 ? (
            <div className="pie_chart_img">
                <NodataContent>No data available</NodataContent>
            </div>
        ) : (
            <PieCharts data={data} colors={['#7AE3DC', '#EB6453', '#FFAB57']} />
        )
        // </div>
    )
}

function FeedbackPieChat(props: { botDetails: BotDetailsT }) {
    const { botDetails } = props

    const successRate =
        (botDetails.satisfied_count / (botDetails.satisfied_count + botDetails.not_satisfied_count)) * 100
    const failRate =
        (botDetails.not_satisfied_count / (botDetails.satisfied_count + botDetails.not_satisfied_count)) * 100
    const data = [
        { value: Math.round(successRate), id: 'successRate', label: 'Satisfied' },
        { value: Math.round(failRate), id: 'failRate', label: 'Not Satisfied' }
    ]
    return (
        // <div className="pie_chat">
        botDetails.satisfied_count || botDetails.not_satisfied_count ? (
            <PieCharts data={data} colors={['#A1E89B', '#FF8484']} />
        ) : (
            <div className="pie_chart_img">
                <NodataContent>No data available</NodataContent>
            </div>
        )
        // </div>
    )
}

// dummy commit for COG1-7106

const ChartWithWrapper = (props: { botDetails: BotDetailsT; bot: any; dateOptions: string }) => {
    const { botDetails, bot, dateOptions } = props

    const AreaTooltip = (props: any) => {
        const { active, payload, label } = props
        // console.log(payload, label, 'payload::AreaTooltip')
        if (active && payload && payload.length && payload[0].payload.resolved) {
            return (
                <div className="custom-tooltip _resolved">
                    <p className="tooltip_date">
                        Auto Resolved:
                        <span>{payload[0].payload.resolved ? payload[0].payload.resolved : 0}</span>
                    </p>
                    <p>
                        Cost Savings:{' '}
                        <span>
                            $
                            {payload[0].payload.resolved
                                ? (payload[0].payload.resolved * (bot ? bot.avg_cost_to_resolve_an_issue : 0)).toFixed(
                                      2
                                  )
                                : 0}
                        </span>
                    </p>
                    <p>
                        Hours Saved:{' '}
                        <span>
                            {payload[0].payload.resolved
                                ? (payload[0].payload.resolved * (bot ? bot.avg_time_spend_by_agent : 0)).toFixed(2)
                                : 0}{' '}
                            hrs
                        </span>
                    </p>
                </div>
            )
        }
        return null
    }

    const AreaChartTooltip = (props: any) => {
        const { series, seriesIndex, dataPointIndex, w } = props

        return `<div class="custom-tooltip _resolved">
                    <p class="tooltip_date">
                        Auto Resolved: 
                        <span>
                           ${
                               series && series[seriesIndex] && series[seriesIndex][dataPointIndex]
                                   ? series[seriesIndex][dataPointIndex]
                                   : 0
                           }
                        </span>
                    </p>
                    <p>
                        
                    
                    
                    
                    Cost Savings: <span>$ ${
                        series[seriesIndex][dataPointIndex]
                            ? series[seriesIndex][dataPointIndex] * (bot ? bot.avg_cost_to_resolve_an_issue : 0)
                            : 0
                    }</span>
                    </p>
                    <p>
                        Hours Saved: <span>${
                            series[seriesIndex][dataPointIndex]
                                ? series[seriesIndex][dataPointIndex] * (bot ? bot.avg_time_spend_by_agent : 0)
                                : 0
                        } hrs</span>
                    </p>
                </div>`
    }

    const datas = botDetails.day_wise_status_count.reduce(
        (acc: any, chart) => {
            const date =
                dateOptions != 'today' && dateOptions != 'yesterday'
                    ? chart.date.split('/')[0] +
                      '/' +
                      (parseInt(chart.date.split('/')[1]) < 10
                          ? '0' + parseInt(chart.date.split('/')[1])
                          : parseInt(chart.date.split('/')[1]))
                    : chart.date
            return {
                previousvalue: chart.resolved,
                array: [
                    ...acc.array,
                    {
                        name: date,
                        resolved: chart.resolved + acc.previousvalue
                    }
                ]
            }
        },
        { previousvalue: 0, array: [] }
    ).array as { name: string; resolved: number }[]

    const modifiedData = datas

    const maxValue = modifiedData
        .map(x => x.resolved)
        .reduce((prev, curr) => {
            return Math.max(prev, curr)
        }, 0)

    const length = `${maxValue}`.length - 1

    const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length))

    const yAxisTicks =
        unit === 5
            ? [5, 10, 15, 20, 25, 30]
            : R.unfold<number, number>(it => (it > maxValue + 2 * unit ? false : [it, it + unit]))(unit)

    return (
        <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
                <ChatWrapperLeft chartsPadding>
                    <Row>
                        <Header>
                            Bot Auto Resolved:{' '}
                            {botDetails.day_wise_status_count.reduce(
                                (acc, val) => (acc += val.resolved ? val.resolved : 0),
                                0
                            )}
                        </Header>
                    </Row>
                    <div
                        className={
                            botDetails.day_wise_status_count.reduce((acc, val) => (acc += val.resolved), 0) > 0
                                ? 'bot-auto-resolved'
                                : 'bot-auto-resolved display_inline'
                        }
                    >
                        {botDetails.day_wise_status_count.reduce((acc, val) => (acc += val.resolved), 0) > 0 ? (
                            <>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                        width={830}
                                        height={320}
                                        data={datas}
                                        margin={{
                                            top: 15,
                                            right: 40,
                                            left: -10,
                                            bottom: 5
                                        }}
                                        barCategoryGap={'35%'}
                                        barGap={'10%'}
                                    >
                                        <defs>
                                            <linearGradient id="colour-resolved" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#BD60C7" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#BD60C7" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid />
                                        <XAxis
                                            angle={45}
                                            dy={5}
                                            dx={12}
                                            dataKey="name"
                                            interval={0}
                                            tickLine={true}
                                            axisLine={false}
                                            tickMargin={10}
                                        >
                                            <Label
                                                value={
                                                    dateOptions == 'today' || dateOptions == 'yesterday'
                                                        ? 'Hours'
                                                        : 'Date'
                                                }
                                                position={'bottom'}
                                                className={'xAxisLabel'}
                                                offset={20}
                                            />
                                        </XAxis>
                                        <YAxis
                                            allowDecimals={false}
                                            label={{
                                                value: 'Sessions',
                                                angle: -90,
                                                position: 'insideLeft',
                                                offset: 20,
                                                style: { textAnchor: 'middle' }
                                            }}
                                            axisLine={false}
                                            tickLine={{ stroke: '#F2F2F2' }}
                                            tickFormatter={(x: number) => {
                                                return approx(x)
                                            }}
                                            ticks={[0, ...yAxisTicks]}
                                        />
                                        <ChartTip isAnimationActive={false} cursor={false} content={<AreaTooltip />} />
                                        {/* <Legend /> */}
                                        <Area
                                            type="monotone"
                                            dataKey="resolved"
                                            stroke="#BD60C7"
                                            fillOpacity={1}
                                            fill="url(#colour-resolved)"
                                            strokeWidth={3}
                                            activeDot={{ stroke:"#BD60C7", fill:"white"}}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </>
                        ) : (
                            <NodataContent>No data available</NodataContent>
                        )}
                    </div>
                </ChatWrapperLeft>
                <ChatWrapperRight chartsPadding>
                    <Header>User Feedback (from Bot sessions)</Header>
                    <FeedbackPieChat botDetails={props.botDetails} />
                    <FeedbackWrapper>
                        <label>
                            <img src={thumbs_up} alt="thumbs_up" />
                            <span>Satisfied</span>
                        </label>
                        <label>
                            <img src={thumbs_down} alt="thumbs_down" />
                            <span>Not Satisfied</span>
                        </label>
                    </FeedbackWrapper>
                </ChatWrapperRight>
            </ChartWrapper>
        </ChartFeedbackWrapper>
    )
}

function CustomAxis(props: any) {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={0} textAnchor="end" fill="#494949" className="customized-axis-tick-text">
                {payload.value} hrs
            </text>
        </g>
    )
}

export const CustomizedTooltip = (props: any) => {
    if (props && props.payload[0]) {
        return (
            <div className="custom_tooltip">
                <p>
                    {' '}
                    Auto Resolved:{' '}
                    <span style={{ color: '#000' }}>
                        {props.botDetails.top_5_resolved_count.reduce(
                            (acc: any, val: any) => (acc += val.status_count),
                            0
                        )}
                    </span>
                </p>
                <p>
                    {' '}
                    Cost Savings: <span style={{ color: '#5A9BEA' }}>{props.payload[0].payload.dollar}</span>
                </p>
                <p>
                    {' '}
                    Hours Saved: <span style={{ color: '#BD60C7' }}>{props.payload[0].payload.name}</span>{' '}
                </p>
            </div>
        )
    }
    return null
}

const BotDetails = (props: { botDetails: BotDetailsT }) => {
    const { botDetails } = props

    const { history, match } = props as any

    const sum = botDetails.top_5_resolved_count.reduce((acc, c) => (acc += c.status_count), 0)
    const sum2 = botDetails.top_5_escalated_count.reduce((acc, c) => (acc += c.status_count), 0)
    const sum3 = botDetails.top_5_abandoned_count.reduce((acc, c) => (acc += c.status_count), 0)

    function gotoDialog(dialog_id: string) {
        history.push(
            `/workspace/${match.params.workspacename}/assistant/${match.params.assistantname}/detail/${dialog_id}`
        )
    }
    return (
        <BotCardWrapper>
            <BotCard>
                <CardHeader>
                    <Header className="header_space">
                        Bot Auto Resolved
                        <Tooltip
                            className="target_bot"
                            tipContentHover
                            zIndex={5000}
                            arrowSize={5}
                            tagName="span"
                            content="Top auto-resolved sessions by bot."
                        >
                            <span className="info_icon">
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    </Header>
                    <p>By Dialogs</p>
                </CardHeader>
                <ProgressWrapper className="ul">
                    {botDetails.top_5_resolved_count.length > 0 ? (
                        botDetails.top_5_resolved_count.map((a, i) => (
                            <ProgressLi key={i}>
                                <ProgressContent
                                    onClick={() => {
                                        gotoDialog(a.dialog_id)
                                    }}
                                >
                                    {a.dialog}
                                </ProgressContent>
                                <ProgressBar>
                                    <Span>{a.status_count}</Span>
                                    <BgLine>
                                        <progress
                                            id="file"
                                            style={{ width: '100%' }}
                                            max="100"
                                            value={(a.status_count / sum) * 100}
                                            className="border-0"
                                        ></progress>
                                    </BgLine>
                                </ProgressBar>
                            </ProgressLi>
                        ))
                    ) : botDetails.top_5_resolved_count.length > 0 ? (
                        <NodataContent style={{ position: 'relative', paddingTop: 0 }}>No data available</NodataContent>
                    ) : (
                        <NodataContent>No data available</NodataContent>
                    )}
                </ProgressWrapper>
                {/* <label>
                    <Tooltip
                        className="target_bot"
                        tipContentHover
                        zIndex={5000}
                        arrowSize={5}
                        tagName="span"
                        content="Top auto resolved dialogs by the chatbot."
                    >
                        <span className="info_icon">
                            <InfoIcon />
                        </span>
                    </Tooltip>
                </label> */}
            </BotCard>
            <BotCard>
                <CardHeader>
                    <Header className="header_space">
                        Agent Escalated
                        <Tooltip
                            className="target_bot"
                            tipContentHover
                            zIndex={5000}
                            arrowSize={5}
                            tagName="span"
                            content="Top agent escalated sessions by bot."
                        >
                            <span className="info_icon">
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    </Header>
                    <p>By Dialogs</p>
                </CardHeader>
                <ProgressWrapper className="ul">
                    {botDetails.top_5_escalated_count.length > 0 ? (
                        botDetails.top_5_escalated_count.map((a, i) => (
                            <ProgressLi key={i}>
                                <ProgressContent
                                    onClick={() => {
                                        gotoDialog(a.dialog_id)
                                    }}
                                >
                                    {a.dialog}
                                </ProgressContent>
                                <ProgressBar>
                                    <Span>{a.status_count}</Span>
                                    <BgLine className="progress_2">
                                        <progress
                                            id="file"
                                            style={{ width: '100%' }}
                                            max="100"
                                            value={(a.status_count / sum2) * 100}
                                            className="border-0"
                                        ></progress>
                                    </BgLine>
                                </ProgressBar>
                            </ProgressLi>
                        ))
                    ) : botDetails.top_5_escalated_count.length > 0 ? (
                        <NodataContent style={{ position: 'relative', paddingTop: 0 }}>No data available</NodataContent>
                    ) : (
                        <NodataContent>No data available</NodataContent>
                    )}
                </ProgressWrapper>
                {/* <label>
                    <Tooltip
                        className="target_bot"
                        tipContentHover
                        zIndex={5000}
                        arrowSize={5}
                        tagName="span"
                        content="Top agent escalated dialogs by bot."
                    >
                        <span className="info_icon">
                            <InfoIcon />
                        </span>
                    </Tooltip>
                </label> */}
            </BotCard>
            <BotCard>
                <CardHeader>
                    <Header className="header_space">
                        Abandoned
                        <Tooltip
                            className="target_bot"
                            tipContentHover
                            zIndex={5000}
                            arrowSize={5}
                            tagName="span"
                            content=" Top abandoned sessions by the end-users after initiating conversation with the bot."
                        >
                            <span className="info_icon">
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    </Header>
                    <p>By Dialogs</p>
                </CardHeader>
                <ProgressWrapper className="ul">
                    {botDetails.top_5_abandoned_count.length > 0 ? (
                        botDetails.top_5_abandoned_count.map((a, i) => (
                            <ProgressLi key={i}>
                                <ProgressContent
                                    className="abandoned_link_content"
                                    onClick={() => {
                                        gotoDialog(a.dialog_id)
                                    }}
                                >
                                    {a.dialog}
                                </ProgressContent>
                                <ProgressBar>
                                    <Span>{a.status_count}</Span>
                                    <BgLine className="progress_3">
                                        <progress
                                            id="file"
                                            style={{ width: '100%' }}
                                            max="100"
                                            value={(a.status_count / sum3) * 100}
                                            className="border-0"
                                        ></progress>
                                    </BgLine>
                                </ProgressBar>
                            </ProgressLi>
                        ))
                    ) : botDetails.top_5_abandoned_count.length > 0 ? (
                        <NodataContent style={{ position: 'relative', paddingTop: 0 }}>No data available</NodataContent>
                    ) : (
                        <NodataContent>No data available</NodataContent>
                    )}
                </ProgressWrapper>
                {/* <label>
                    <Tooltip
                        className="target_bot"
                        tipContentHover
                        zIndex={5000}
                        arrowSize={5}
                        tagName="span"
                        content="Top abandoned dialogs by the end-users after initiating the conversation with the bot."
                    >
                        <span className="info_icon">
                            <InfoIcon />
                        </span>
                    </Tooltip>
                </label> */}
            </BotCard>
        </BotCardWrapper>
    )
}
