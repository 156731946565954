import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  ChartFeedbackWrapper,
  ChartWrapper,
  ChatWrapperLeft,
  CountNumber,
  CountWrapper,
  DropdownLi,
  DropdownLiSpan,
  DropdownUl,
  DropdownWrapper,
  HeaderContainer,
  Input,
  InputContent,
  NodataContent,
  TabCardContainer,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  BotDropdown,
  TriangleIcon,
} from '@/common/Icons/Icons';
import { BasiceDropdown, OverAllSummaryT } from './BotDetails';
import { AssistantT } from '../Construction/kind';
import {
  useCustomLoading,
  useFetchAreaBotSummary,
  useFetchBarchartBotSummary,
  useFetchTop10StatusValues,
  useFetchUnAnsweredSummary,
  useUpdateAreaBotSummary,
  useUpdateBarChartSummary,
} from './BotSummaryAPI';
import {
  convertFromToDate,
  formatAMPM,
  formatAreaChartDate,
  formatDateInCard,
} from '@/common/utils/utils';
import { areachartDates, barchartDates, returnDate } from './TabUIElements';
import Downshift from 'downshift';
import BasicDateRangePicker from './CustomDatePicker';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTip,
  AreaChart,
  Label,
  Area,
} from 'recharts';
import * as R from 'ramda';
import { Top10ValuesCharts } from './QueriesTab';
var approx = require('approximate-number');

const UserMetricsDetails = [
  {
    header: 'Total unanswered queries',
    hours:"(queries were unanswered)"
  },
  {
    header: 'Avg. unanswered queries per day',
  },
];

type UnansweredQueryReportT = {
    total_unanswered_queries: {count:number,_id:string};
    avg_unanswered_query_per_day: null| number
};

type DayQueyCount = {
     date: string;
     num_of_queries: number;
};

export function UnAnsweredTab(props: {
  botId: string | undefined;
  workspace: string;
  overAllSummary: OverAllSummaryT;
  selectedBot: AssistantT;
  setTabLoading:(loading:boolean)=>void
  history: any;
  match: any;
  overAllDate: string
  customDates: {
    from_date: null | string
    to_date: null | string
 }
}) {
  const { overAllSummary, setTabLoading,history, match, overAllDate, customDates } = props;

  // const [dateOptions, setOptions] = useState('last30days')
  const dateOptions = overAllDate

  const {
    unAnsweredSummary,
    unAnsweredSummaryRetry,
    unAnsweredSummaryStatus,
    dataUpdatedAt,
    isRefetching,
    isFetching
  } = useFetchUnAnsweredSummary(
    props.workspace,
    props.botId ? props.botId : '',
    dateOptions != 'customrange'?returnDate(dateOptions):customDates,
    {
      enabled:
        !!props.botId &&
        (dateOptions != 'customrange' ||
          (customDates.from_date && customDates.to_date?true:false)),
      onSuccess:(data:any)=>{
        setTabLoading(false)
      }
    }
  );

  const loading = useCustomLoading({ isRefetching,isFetching,setTabLoading })

  const barchartBotSummary = unAnsweredSummary && unAnsweredSummary.day_wise_unanswered_query

  const { areaSummary } = useFetchAreaBotSummary(
    props.workspace,
    props.botId ? props.botId : '',
    returnDate('last2months'),
    {
      enabled: !!props.botId,
    },
    'areaunansweredsummary'
  );

  console.log(areaSummary,"areaSummary")

  const top10ValuesForAgent = unAnsweredSummary && unAnsweredSummary.top_10_unanswered_query


  // const { botSummaryMutate, botSummaryMutateStatus } = useUpdateBotSummary();


  const { areaChartMutate } = useUpdateAreaBotSummary('areaunansweredsummary');


  const [state, setState] = useState({
    showBarChartDropDown: false,
    selectedBarchartDate: 'last30days',
    showAreaChartDropDown: false,
    selectedAreachartDate: 'last2months',
    customDatePickerShow: false,
    customDates: { from_date: null, to_date: null },
  });


  function areachartDate() {
    return areachartDates.filter(
      (it) => it.date === state.selectedAreachartDate
    )[0].label;
  }


  useEffect(() => {
    areaSummary &&
      areaChartMutate({
        botId: props.botId,
        workspace: props.workspace,
        date: returnDate(state.selectedAreachartDate),
      });
  }, [state.selectedAreachartDate]);

  function retnFn(i: number) {
    if (!unAnsweredSummary) {
      return 0;
    }

    const s = unAnsweredSummary as UnansweredQueryReportT;

    switch (i) {
      case 0:
        return s?s.total_unanswered_queries:0;
      default:
        return !s.avg_unanswered_query_per_day?0:s.avg_unanswered_query_per_day
    }
  }

  console.log(areaSummary, barchartBotSummary, '123');

  return (
    
    <div className="bot_sessions_container">
      <div className="bot_sessions_heaader">
        <div>
          <h5>Queries that were unanswered by the bot (as no dialogs or FAQs were trained to respond)</h5>
        </div>
      </div>
      {/* <div className="bot_sessions_heaader">
        <div>
          <h5>Queries that were unanswered by the bot (as no dialogs or FAQs were trained to respond)</h5>
        </div>
        <div className="d-flex align-items-center bot_sessions_heaader_color">
          <div>
            This report was generated on{' '}<br/>
            {formatDateInCard((unAnsweredSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} at{' '}
            {formatAMPM((unAnsweredSummary?new Date(dataUpdatedAt):new Date()).toString(), true)} -{' '}
            <span onClick={() =>{
              setTabLoading(true)
              unAnsweredSummaryRetry()
            }}>Refresh</span>
          </div>
          <BasiceDropdown dateOptions={dateOptions} changeDateFilter={()=>{
              setTabLoading(true)
            }} setOptions={setOptions}/>
          </div>
      </div> */}
      <HeaderContainer>
        <TabCardContainer>
          {UserMetricsDetails.map((data, i) => (
            <Card style={{ minHeight: 130 }}>
              <CardHeader>
                {data.header}{' '}
              </CardHeader>
              <CardHeader>
                  <span>{data.hours}</span>
                </CardHeader>
              <CountNumber>
                <CountWrapper>{retnFn(i)}</CountWrapper>
              </CountNumber>
            </Card>
          ))}
        </TabCardContainer>
      </HeaderContainer>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>{"Most frequent user queries that were unanswered by the bot (as no dialogs or FAQs were trained to respond)"}</h5>
          </div>
        </div>
        <Top10ValuesCharts top10ValuesForAgent={top10ValuesForAgent} history={history} match={match} from="unanswered"/>
        </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Unanswered Queries</h5>
            <p>User queries unanswered by the bot over a specific period</p>
          </div>
          <div className="tab_dropdown_date">
            {state.customDatePickerShow && (
              <div className='tab_calender'>
              <BasicDateRangePicker
                setDate={(date: Date[]) => {
                  setState({
                    ...state,
                    customDates: {
                      from_date: convertFromToDate(date[0].toString()) as any,
                      to_date: convertFromToDate(date[1].toString()) as any,
                    },
                    customDatePickerShow:false
                  });
                }}
                show={state.customDatePickerShow}
                customDates={state.customDates}
                closeCalendar={()=>{
                  setState({
                    ...state,
                    customDatePickerShow:false
                  });
                }}
              />
              </div>
            )}
            {/* <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
                onChange={() => {}}
                value={barchartDate("selectedBarchartDate")}
              />
              <InputContent>Show by</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showBarChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showBarChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showBarChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {barchartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedBarchartDate: date.date,
                                showBarChartDropDown: false,
                                customDatePickerShow: date.date === 'customrange' ? !state.customDatePickerShow : state.customDatePickerShow

                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper> */}
          </div>
        </div>
        <div className="tab_section_graph">
          <ChartFeedbackWrapper>
            <ChartWrapper chartsBg>
              <ChatWrapperLeft chartsPadding>
                <div className="bar_charts_wrapper">
                  <BarCharts
                    day_wise_session_query_count={
                      barchartBotSummary ? barchartBotSummary : []
                    }
                    dateOptions={dateOptions}
                  />
                </div>
              </ChatWrapperLeft>
            </ChartWrapper>
          </ChartFeedbackWrapper>
        </div>
      </div>

      <div className="tab_section_wrapper">
        <div className="tab_section_header">
          <div>
            <h5>Unanswered Queries</h5>
            <p>Compare unanswered queries over a specific period</p>
          </div>
          <div className="tab_dropdown_date">
            <DropdownWrapper>
              <Input
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
                onChange={() => {}}
                value={areachartDate()}
              />
              <InputContent>Show comparision</InputContent>
              <span
                onClick={() =>
                  setState({ ...state, showAreaChartDropDown: true })
                }
              >
                <BotDropdown />
              </span>
              {state.showAreaChartDropDown && (
                <Downshift
                  isOpen={true}
                  onOuterClick={() =>
                    setState({ ...state, showAreaChartDropDown: false })
                  }
                >
                  {() => (
                    <div>
                      <DropdownUl>
                        {areachartDates.map((date, i) => (
                          <DropdownLi
                            key={i}
                            onClick={() => {
                              setState({
                                ...state,
                                selectedAreachartDate: date.date,
                                showAreaChartDropDown: false,
                              });
                            }}
                          >
                            <DropdownLiSpan>{date.label}</DropdownLiSpan>{' '}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </div>
                  )}
                </Downshift>
              )}
            </DropdownWrapper>
          </div>
        </div>
        <div className="tab_section_graph">
          <AreaChartWithWrapper
            areaDetails={areaSummary ? areaSummary : []}
            dateOptions={state.selectedAreachartDate}
          />
        </div>        
      </div>
        
    </div>
  );
}

function BarCharts(props: {
  day_wise_session_query_count: DayQueyCount[];
  dateOptions: string;
}) {
  console.log(props.day_wise_session_query_count);
  const BarchartTooltip = (props: any) => {
    // const { series, seriesIndex, dataPointIndex, w } = props
    // console.log("pLoad",props)

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const pLoad = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p>
          Unanswered queries: <span style={{color:"#FFE626"}}>{pLoad.num_of_queries ? pLoad.num_of_queries : 0}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  type ChartData = {
    name: string;
    num_of_queries: number;
  };

  const { day_wise_session_query_count, dateOptions } = props;

  function filterDate() {
    return day_wise_session_query_count.map((chart) => {
      const date =
        dateOptions != 'today' && dateOptions != 'yesterday'
          ? chart.date.split('/')[0] + '/' + parseInt(chart.date.split('/')[1])
          : chart.date;
      return {
        name: date,
        num_of_queries: chart.num_of_queries,
      };
    });
  }
  // const mock_uniqueUsers =[
  //   {
  //     name: '10/04/2023',
  //     users: 15,
  //   },
  //   {
  //     name: '12/04/2023',
  //     users: 25,
  //   },
  // ]
  const datas: ChartData[] = filterDate();

  const modifiedData = [
    {
      name: '',
      num_of_queries: 0,
    },
    ...datas,
  ];

  const mxValue = modifiedData
    .map((x: ChartData) => x.num_of_queries)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));
  console.log(datas, modifiedData, 'datas', maxValue, unit);

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);
  const [position, setPosition] = useState({
    data: { x: 0, y: 0,height:0 },
    show: false,
  });
  return (
    // <div className="analytics_charts_left_img">
    <div
      style={{ width: '100%' }}
      className={
        datas.length > 0 ? 'bot_session_chart' : 'no_data_bot_session_chart'
      }
    >
      {datas.length > 0 ? (
        <div className="bot-summary-all-sessions">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={830}
              height={320}
              data={modifiedData}
              margin={{
                top: 15,
                right: 25,
                left: -10,
                bottom: 5,
              }}
              barCategoryGap={'35%'}
              barGap={'10%'}
            >
              <defs>
              <linearGradient id="sessionsclr" x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                <stop offset='0%' stopColor='#FFE626' />
                <stop offset='14%' stopColor='#FFE626' />
                <stop offset='100%' stopColor='#B9AF68'/>
              </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis
                angle={45}
                dy={8}
                dx={12}
                dataKey="name"
                interval={0}
                scale="point"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                allowDecimals={false}
                label={{
                  value: 'Unanswered Queries',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 16,
                  style: { textAnchor: 'middle' },
                }}
                axisLine={false}
                tickLine={{ stroke: '#F2F2F2' }}
                tickFormatter={(x: number) => {
                  return approx(x);
                }}
                ticks={[0, ...yAxisTicks]}
                tickMargin={5}
              />
              <ChartTip
                isAnimationActive={false}
                cursor={false}
                position ={(position.show? {x:position.data.x - 60, y:position.data.y - 35}: undefined)}
                content={
                  position.show ? (
                    <BarchartTooltip />
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )
                }
              />
              <Bar
                dataKey="num_of_queries"
                stackId="a"
                fill="url(#sessionsclr)"
                // "#6AB5FF"
                barSize={10}
                radius={[10, 10, 0, 0]}
                isAnimationActive={false}
                onMouseOver={(data) => {
                  setPosition({ data: data, show: true });
                }}
                onMouseLeave={(data) => {
                  setPosition({ data : { x : 0 , y : 0,height:0 },show: false });
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NodataContent>No data available</NodataContent>
      )}
    </div>
    // </div>
  );
}

const AreaChartWithWrapper = (props: {
  areaDetails: DayQueyCount[];
  dateOptions: string;
}) => {
  const { areaDetails, dateOptions } = props;

  const AreaTooltip = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip _resolved">
          <p className="tooltip_date">
          Unanswered queries:
            <span style={{color:"#333333"}}>
              {payload[0].payload.newUser ? payload[0].payload.newUser : 0}{' '}
              {payload[0].payload.index > 1 && payload[0].payload.newUserPercentage>0 && (
                <p
                  className="custom_tooltip_persentage_color"
                  style={{
                    color: payload[0].payload.newUserIncresed
                      ? '#70D397'
                      : '#D13737',
                  }}
                >
                  <span
                    className="custom_tooltip_persentage_img"
                    style={{
                      transform: payload[0].payload.newUserIncresed
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                      fill: payload[0].payload.newUserIncresed
                        ? '#70D397'
                        : '#D13737',
                    }}
                  >
                    <TriangleIcon />
                  </span>
                  {Math.round(payload[0].payload.newUserPercentage) + '%'}
                </p>
              )}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const datas = areaDetails.reduce(
    (accum, chart, i) => {
      const date = formatAreaChartDate(chart.date);
      const calculate = (first: number, second: number) =>
        first < second
          ? Math.abs((second - first) / second)
          : Math.abs((first - second) / first);
      const newUserPercentage =
        i === 0 ? 100 : calculate(accum[i].newUser, chart.num_of_queries) * 100;

      return [
        ...accum,
        {
          name: date,
          newUser: chart.num_of_queries,
          newUserPercentage: isNaN(newUserPercentage) ? 0 : newUserPercentage,
          index: i + 1,
          newUserIncresed: accum[i].newUser < chart.num_of_queries,
        },
      ] as any;
    },
    [
      {
        name: '',
        newUser: 0,
        newUserPercentage: 0,
        index: 0,
        newUserIncresed: false,
      },
    ] as any
  ) as { name: string; newUser: number; }[];
  const modifiedData = datas;

  const mxValue = modifiedData
    .map((x) => x.newUser)
    .reduce((prev, curr) => {
      return Math.max(prev, curr);
    }, 0);

  const maxValue = mxValue ? mxValue : 0;

  const length = `${maxValue}`.length - 1;

  const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

  const yAxisTicks =
    unit === 5
      ? [5, 10, 15, 20, 25, 30]
      : R.unfold<number, number>((it) =>
          it > maxValue + 2 * unit ? false : [it, it + unit]
        )(unit);

  return (
    <ChartFeedbackWrapper>
      <ChartWrapper chartsBg style={{ marginTop: 0 }}>
        <ChatWrapperLeft chartsPadding>
          {/* <Row>
              <Header>
                Bot Auto Resolved:{' '}
                {areaDetails.reduce(
                  (acc, val) =>
                    (acc += val.num_of_queries ? val.num_of_queries : 0),
                  0
                )}
              </Header>
            </Row> */}
          <div
            className={
              areaDetails.reduce((acc, val) => (acc += val.num_of_queries), 0) > 0
                ? 'bot-auto-resolved'
                : 'bot-auto-resolved display_inline'
            }
          >
            {areaDetails.reduce((acc, val) => (acc += val.num_of_queries), 0) > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={830}
                  height={320}
                  data={datas}
                  margin={{
                    top: 15,
                    right: 40,
                    left: -10,
                    bottom: 5,
                  }}
                  barCategoryGap={'35%'}
                  barGap={'10%'}
                >
                  <defs>
                    <linearGradient
                      id="colour-resolved-1"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#E5DDA3" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#FFFBDB" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid />
                  <XAxis
                    // angle={45}
                    dy={2}
                    dx={3}
                    dataKey="name"
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                  ></XAxis>
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: 'Unanswered Queries',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      style: { textAnchor: 'middle' },
                    }}
                    axisLine={false}
                    tickLine={{ stroke: '#F2F2F2' }}
                    tickFormatter={(x: number) => {
                      return approx(x);
                    }}
                    ticks={[0, ...yAxisTicks]}
                  />
                  <ChartTip
                    isAnimationActive={false}
                    cursor={false}
                    content={<AreaTooltip />}
                  />
                  <Area
                    type="linear"
                    dataKey="newUser"
                    stroke="#BAB066"
                    fillOpacity={1}
                    fill="url(#colour-resolved-1)"
                    strokeWidth={3}
                    activeDot={{stroke:"#BAB066",fill:"white"}}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NodataContent>
                No data available
              </NodataContent>
            )}
          </div>
        </ChatWrapperLeft>
      </ChartWrapper>
    </ChartFeedbackWrapper>
  );
};

// const ChartWithWrapper = (props: {
//   botDetails: UserQuerT[];
//   dateOptions: string;
// }) => {
//   const { botDetails, dateOptions } = props;

//   const AreaTooltip = (props: any) => {
//     const { active, payload, label } = props;
//     if (active && payload && payload.length && payload[0].payload.total_query) {
//       return (
//         <div className="custom-tooltip _resolved">
//           <p>
//             Total users:{' '}
//             <span style={{color:"#6FA7DD"}}>
//               {payload[0].payload.total_users
//                 ? payload[0].payload.total_users
//                 : 0}
//             </span>
//           </p>
//           <p>
//             User Queries:{' '}
//             <span style={{color:"#63B7B1"}}>
//               {payload[0].payload.total_query
//                 ? payload[0].payload.total_query
//                 : 0}{' '}
//             </span>
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const datas = botDetails.reduce(
//     (acc: any, chart) => {
//       const date =
//         dateOptions != 'today' && dateOptions != 'yesterday'
//           ? chart.date.split('/')[0] +
//             '/' +
//             (parseInt(chart.date.split('/')[1]) < 10
//               ? '0' + parseInt(chart.date.split('/')[1])
//               : parseInt(chart.date.split('/')[1]))
//           : chart.date;
//       return {
//         previousQueryvalue: acc.previousQueryvalue + chart.total_query,
//         previousUservalue: acc.previousUservalue + chart.total_users,
//         array: [
//           ...acc.array,
//           {
//             name: date,
//             total_query: chart.total_query,
//             total_users: chart.total_users,
//           },
//         ],
//       };
//     },
//     { previousQueryvalue: 0, previousUservalue: 0, array: [] }
//   ).array as { name: string; total_query: number; total_users: number }[];

//   const modifiedData = datas;

//   const mxValue = modifiedData
//     .map((x) => x.total_query)
//     .reduce((prev, curr) => {
//       return Math.max(prev, curr);
//     }, 0);
//   const maxValue = mxValue ? mxValue : 0;

//   const length = `${maxValue}`.length - 1;

//   const unit = maxValue <= 25 ? 5 : parseInt(1 + '0'.repeat(length));

//   const yAxisTicks =
//     unit === 5
//       ? [5, 10, 15, 20, 25, 30]
//       : R.unfold<number, number>((it) =>
//           it > maxValue + 2 * unit ? false : [it, it + unit]
//         )(unit);
//   console.log(datas, botDetails, 'datas');
//   return (
//     <ChartFeedbackWrapper>
//       <ChartWrapper chartsBg>
//         <ChatWrapperLeft chartsPadding>
//           <div
//             className={
//               botDetails.reduce((acc, val) => (acc += val.total_query), 0) > 0
//                 ? 'bot-auto-resolved'
//                 : 'bot-auto-resolved display_inline'
//             }
//           >
//             {botDetails.reduce((acc, val) => (acc += val.total_query), 0) >
//             0 ? (
//               <ResponsiveContainer width="100%" height="100%">
//                 <AreaChart
//                   width={830}
//                   height={320}
//                   data={datas}
//                   margin={{
//                     top: 15,
//                     right: 40,
//                     left: -10,
//                     bottom: 5,
//                   }}
//                   barCategoryGap={'35%'}
//                   barGap={'10%'}
//                 >
//                   <defs>
//                     <linearGradient
//                       id="colour-resolved-2"
//                       x1="0"
//                       y1="0"
//                       x2="0"
//                       y2="1"
//                     >
//                       <stop offset="5%" stopColor="#D8FFD5" stopOpacity={0.6} />
//                       <stop offset="95%" stopColor="#A1E29B" stopOpacity={1} />
//                     </linearGradient>
//                   </defs>
//                   <CartesianGrid />
//                   <XAxis
//                     angle={45}
//                     dy={5}
//                     dx={12}
//                     dataKey="name"
//                     interval={0}
//                     tickLine={true}
//                     axisLine={false}
//                     tickMargin={10}
//                   >
//                     <Label
//                       value={
//                         dateOptions == 'today' || dateOptions == 'yesterday'
//                           ? 'Hours'
//                           : 'Date'
//                       }
//                       position={'bottom'}
//                       className={'xAxisLabel'}
//                       offset={20}
//                     />
//                   </XAxis>
//                   <YAxis
//                     allowDecimals={false}
//                     label={{
//                       value: 'Unique Users',
//                       angle: -90,
//                       position: 'insideLeft',
//                       offset: 20,
//                       style: { textAnchor: 'middle' },
//                     }}
//                     axisLine={false}
//                     tickLine={{ stroke: '#F2F2F2' }}
//                     tickFormatter={(x: number) => {
//                       return approx(x);
//                     }}
//                     ticks={[0, ...yAxisTicks]}
//                   />
//                   <ChartTip
//                     isAnimationActive={false}
//                     cursor={false}
//                     content={<AreaTooltip />}
//                   />
//                   {/* <Legend /> */}
//                   <Area
//                     type="monotone"
//                     dataKey="total_query"
//                     stroke="#63B7B1"
//                     fillOpacity={1}
//                     fill="url(#colour-resolved-2)"
//                     strokeWidth={3}
//                     activeDot={{stroke:"#63B7B1",fill:"white"}}
//                   />
//                 </AreaChart>
//               </ResponsiveContainer>
//             ) : (
//               <NodataContent>
//                 No data available
//               </NodataContent>
//             )}
//           </div>
//         </ChatWrapperLeft>
//       </ChartWrapper>
//     </ChartFeedbackWrapper>
//   );
// };
