import Downshift, { useCombobox } from 'downshift'
import produce from 'immer'
import * as R from 'ramda'
/*eslint-disable*/
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { useJourney } from '@/Journey/JourneyContext'
import { filterFun, loadMarketPlaceAutomations } from '@/MarketPlace/AutomationMarketPlace'
import {
    Action,
    Application,
    AutomationGroups2,
    MarketPlaceApplication,
    MarketPlaceAutomation,
    MarketPlaceAutomationRaw,
    SelectApplication
} from '@/MarketPlace/types'
import {
    allAreSame,
    getAllSubsets,
    padWithZero,
    uniqueMarketPlaceApps,
    uniqueMarketPlaceAutomations
} from '@/MarketPlace/utils'
import { VideoLinkSimple } from '@/Videos/Videos'
import {
    AddIcon,
    ArrowIcon,
    CloseIcon,
    DownloadFromMarketplace,
    DropdownWithCircle,
    FlowIcon,
    InfoIcon,
    QaIconWithCircle,
    SearchIcon,
    TimerIcon,
    WebhooksIcon,
    AppTriggerCardIconNew
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal as Modals } from '@/common/components/Modal'
import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper as YesOrnoWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    Input,
    InputContainer,
    InputContent
} from '@/common/styled/Workflow.Analytics.Dumb'

import { formatAMPM, formatDateInCard, reloadWithoutParams } from '@/common/utils/utils'
import { MarketPlaceApi, automationAPIURLWithWorkspace, postJson, logEvent, getProductId } from '@/common/utils/api-utils'
import { MarketFlowCanvasWrapper } from './canvas/marketplace/MarketFlowCanvasWrapper'
import { Loading, None } from './canvas/marketplace/types'
import { AcknowledgeT, NonCanvasActionModes } from './canvas/types'
import { AppCount, ApplicationHeader, Avatar, AvatarImg, groupAutomationsIntoSingle, MaxHeight, CardWrapper as GroupWrapper } from '@/MarketPlace/CardsApplications'
import { SmallButton } from './analytics/logs'

let Parser = require('html-react-parser')

export const SvgWidth = styled.svg`
    width: 12px;
    height: 12px;
    fill: #193A5D;
    border-radius: 50%;
    border: 0.5px solid #193A5D;
    padding: 3px;
    margin-right: 5px;
    margin-left: 1px;
`

const Wrapper = styled.section`
    position: fixed;
    top: 64px;
    bottom: 0px;
    left: 0;
    right: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
    display: grid;
    overflow: hidden;
`
export const AutomationMarketplaceDownload = (props: any) => {
    return (
        <div>
            <Wrapper>
                <Modalbg />
                <Modal {...props} />
            </Wrapper>
        </div>
    )
}

const ModalbgColor = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0px 0px 0px 0px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: auto;
`

export const Modalbg = () => {
    return <ModalbgColor />
}
// PopupRight css Start

export const Paragraph = styled.p`
    text-align: left;
    color: #355d8b;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    margin-bottom: 10px;
    width: 100%;
    float: left;
    line-height: 20px;
    cursor: ${props => (props.cursor ? 'pointer' : 'inherit')};
    padding: 0px 20px;

    svg {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        position: relative;
        top: 2px;
    }
`
export const HeaderLeft = styled.div`
    width: max-content;
    float: left;

    .automate_multiselect_title{
        color: #355d8b;
        font-size: 12px;
        font-family: 'Gordita-Regular';
    }
`
const HeaderRight = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;
    ${Paragraph} {
        color: #007bff;
        margin-bottom: 0px;
    }
`
export const Header = styled.h4`
    text-align: left;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    margin-bottom: 8px;
    width: 100%;
    float: left;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;

    span {
        margin-left: 6px;
        svg {
            width: 12px;
            height: 12px;
            fill: #999;
            color: #999;
            margin-left: 0px;
            cursor: pointer;
        }
    }
`

export const HeaderText = styled.h4`
    text-align: center;
    color: #000;
    font-size: 14px;
    font-family: 'Gordita-Medium';
    margin-bottom: 8px;
    width: 100%;
    padding: 10px 20px;
    line-height: normal;
    }
`

export const DropdownIcon = styled.div`
    position: relative;
    }
`

const DropdownImage = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
    float: left;
    position: absolute;
    margin-top: 12px;
    left: 8px;
    display: flex;

    img {
        width: 20px;
        height: 20px;
    }
    svg {
        width: 20px;
        height: 20px;
        fill: #999999;
    }
`

const ButtonHoverContent = styled.div`
    width: 100%;
    float: left;
    padding-bottom: 3px;
    border-bottom: 1px solid #bababa;
    display: flex;
    flex-direction: column;
    :last-child {
        border-bottom: none;
    }
    h5 {
        font-family: 'Gordita-Medium';
        font-size: 14px;
        color: #000;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 1px;

        span {
            color: #f07f31;
        }
    }
    p {
        font-family: 'Gordita-Regular';
        font-size: 12px;
        color: #000;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 7px;
        }
    }
`
const TabsWrapper = styled.div`
width:100%:
float:left;
border-bottom:1px solid #BABABA;
    h5 {
        font-family: 'Gordita-Regular';
        font-size: 12px;
        color: #000;
        text-align: left;
        margin-bottom: 8px;
        margin-top: 11px;
        display: flex;
        align-items: center;
        label {
            position: relative;
            top: 1px;
            margin: 0px;
            margin-left: 6px;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
        }

        span {
            margin-left: 3px;

            svg {
                width: 10px;
                height: 8px;
                fill: #707070;
                color: #707070;
                margin: 0px;
                transform: rotate(0deg);
            }
        }
        div {
            width: 18px;
            float: left;
            height: 18px;
            background: #d5ffee 0% 0% no-repeat padding-box;
            border-radius: 24px;
            margin-bottom: 0px;
            font-family: 'Gordita-Medium';
            font-size: 7px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;

            label {
                position: relative;
                top: 2px;
                margin: 0px;
                line-height: initial;
            }
        }
    }
    .tabs_dropdown {
        width: 100%;
        float: left;
        overflo-x: hidden;
        padding-left: 24px;
        h6 {
            font-family: 'Gordita-Medium';
            font-size: 10px;
            color: #000;
            text-align: left;
            margin-bottom: 6px;

            span {
                color: #f07f31;
            }
        }
        p {
            font-size: 10px;
            color: #000000;
            margin-bottom: 7px;
            :last-child{
                margin-bottom: 10px;
            }
            label {
                margin: 0;
                position: relative;
                top: 1px;
            }
            div {
                width: 11px;
                float: left;
                height: 11px;
                background: #c7e3ff 0% 0% no-repeat padding-box;
                border-radius: 24px;
                margin-bottom: 0px;
                font-family: 'Gordita-Medium';
                font-size: 6px;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 3px;

                label {
                    position: relative;
                    top: 1px;
                    margin: 0px;
                }
            }
        }
    }
    ${({ isActive }) =>
        isActive &&
        `h5 { 
                span {

                    svg{
                        transform: rotate(90deg);
                    }
                }
            }
`}
`
const ButtonRow = styled.div`
    width: 100%;
    float: left;
    ${ButtonHoverContent} {
        h5 {
            margin-top: 12px;
            margin-bottom: 0px;
        }
        ${TabsWrapper} {
            h5 {
                margin-bottom: 10px;
            }
        }
    }
    :first-child {
        margin-top: 0px;
        ${ButtonHoverContent} {
            h5 {
                margin-top: 12px;
                margin-bottom: 0px;
            }
            ${TabsWrapper} {
                h5 {
                    margin-bottom: 10px;
                }
            }
        }
    }
`
const ButtonHover = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 19px;
    position: absolute;
    top: 32px;
    width: 200px;
    max-height: 360px;
    overflow-y: scroll;
    right: 0px;
    padding: 0px 12px 12px;
    z-index: 1111;
    padding-top: 8px;
`
const ButtonLeft = styled.button`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 19px;
    width: max-content;
    position: relative;
    padding: 0px 12px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gordita-Medium';
    font-size: 12px;
    color: #000;
    cursor: pointer;
    transition: 0.5s ease;
    line-height: normal;
    margin-right: 20px;
    cursor: pointer;

    .buton_top {
        display: flex;
        align-items: center;
    }

    svg {
        width: 16px;
        height: 16px;
        fill: #000;
        margin-right: 5px;
    }
    label {
        margin: 0;
        position: relative;
        top: 2px;
        cursor: pointer;
    }
    :hover {
        border: 0.5px solid #ddfeef;
        background: #ddfeef;
        color: #333;
        fill: #333;
        outline: 0;
        svg {
            fill: #333;
        }
    }
    span {
        width: 12px;
        height: 12px;

        svg {
            width: 12px;
            height: 12px;
            margin-right: 0px;
            margin-left: 3px;
        }
    }
    ${({ isActive }) =>
        isActive &&
        `
        background: #FFE5D3 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F07F31;
        color: #000;
        outline: 0;
        svg {
            fill: #333;
        }
        span {
            svg {
             transform:rotate(-90deg);
            }
        }
        
    `}
`
export const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #fff;
    padding-top: 8px;
    border-radius: 0 0 20px 20px;
    ${Button} {
        margin: 0px;
    }
    .btn.btn-success.variable_update{
        background: #fff; 
        border: 1px solid rgb(185, 185, 185);
        color: rgb(51, 51, 51);
        :hover{
            background: #ddfeef;
            color: #333;
            border: 1px solid #ddfeef;
        }
    }

`

export const SerachInputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;

    span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 10px;
        left: 12px;
        svg {
            width: 18px;
            height: 18px;
            color: #999;
            fill: #999;
        }
    }
`
export const SerachInput = styled.input`
    width: 100%;
    height: 38px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    padding: 14px 12px 11px 36px;
    font-size: 12px;
    font-family: Gordita-Regular;
    color: #000;
    :placeholder {
        color: #999;
    }
    :hover {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #009d5f;
    }
`
export const HeaderCount = styled.h4`
    text-align: left;
    color: #000;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    margin-bottom: 12px;
    width: 100%;
    float: left;
    line-height: 18px;
    span {
        color: #e57700;
    }
`
export const BoxWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;

    ${InputContainer} {
        margin-bottom: 10px;
    }

    .loader_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 550px;
        flex-direction: column;
    }
`
// card back wrapper

export const BackWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    background: #f4f4f4;
    padding: 0px 20px;
`
export const BackWrapperLeft = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;

    ${Header} {
        padding: 0px;
        font-size: 14px;
        line-height: 14px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: 1px;
    }
    ${Paragraph} {
        padding: 0px;
        font-size: 10px;
        line-height: 10px;
        margin-left: 10px;
        margin-bottom: 0px;
    }
`
export const BackWrapperRight = styled.div`
    width: auto;
    float: left;
    ${Button} {
        display: flex;
        align-items: center;
    }
`

export const BgBox = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4;
    height: calc(100vh - 227px);
    position: relative;
    .shadow_bottom {
        background: transparent linear-gradient(180deg, #f4f4f400 0%, #dedede 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        bottom: -14px;
        height: 48px;
        width: 100%;
        left: 0px;
    }
    .bot_market_place_loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100vh - 227px);
    }
    ${DropdownUlWrapper} {
        margin-bottom: 0px;
        ${InputContainer} {
            margin-bottom: 10px;
        }
    }
`
export const PopUpRight = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    background: #ffffff 0% 0% no-repeat padding-box;
    text-align: center;
    display: flex;
    padding: 20px 0px;
    place-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: ${props => (props.primary ? '20px' : '0px 20px 20px 0px')};
`

// PopupRight css End

// Automation left css Start
const HeaderContentRight = styled.div`
    width: 40%;
    float: left;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row-reverse;
`
const HeaderContentLeft = styled.div`
    width: 60%;
    float: left;
`
const HeaderContent = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    ${Paragraph} {
        margin-bottom: 0px;
    }
`
const CanvasMarketpalce = styled.div`
    display: flex;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    float: left;
    height: calc(100vh - 175px);
    padding: 0px;
    overflow: hidden;
    position: relative;

    @media (max-width: 1536px) {
        width: calc(100% - -168px);
        height: calc(100vh - 175px);
        transform: scale(0.84);
        transform-origin: top left;
        -webkit-transform-origin: left top;
        -moz-transform-origin: left top;

        div#preloader {
            width: 128px;
            height: 128px;
            top: 30px;
        }
    }

    .dialog_editor.dialog_detail .zontal_lines {
        padding-top: 0px;
        padding-left: 24px;
    }
    .dialog__header {
        display: none;
    }
    .tree.zontal_lines .first_add_node {
        margin-bottom: 40px;
    }
    .flex_side_menu_dialog.dialog_model_overlay {
        display: none;
    }
    .dialog_canvas_startup_page .flex_side_menu {
        display: none !important;
    }
    .dialog_entity_cards .entity_cards {
        padding: 0px;
        position: relative;
        left: -20px;
        height: 100%;
        padding: 0px;
        display: flex;
        align-items: flex-start;
    }
    .tryme_popup {
        display: none;
    }
    .flow_buttons {
        display: none;
    }
    .send_message_details.send_message_hover {
        padding-right: 0px;
    }
    .dialog_tree_next {
        pointer-events: none;
    }
    .error_input_show.parent {
        display: none;
    }
    .error_input_show.dialog_node.child_svg {
        display: none;
    }
`
const AutomationBg = styled.div`
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    float: left;
    height: calc(100vh - 175px);
    padding: 20px 10px 20px 30px;
    overflow: hidden;

    @media (max-width: 1700px) {
        height: calc(100vh - 200px);
    }

    @media (max-width: 1536px) {
        padding: 20px 20px 20px 25px;
    }

    .automation_canvas .cogni_cards {
        display: none;
    }
    .entity_cards .col-md-12 {
        padding: 0px;
    }
    .automation_canvas_left {
        height: 100%;
    }
    .flow_structure {
        padding-top: 0px;
    }
    .automation_entity_cards {
        height: calc(100vh - 300px);
        overflow: scroll;
    }
    .flow_popup2 {
        display: none;
    }
    div#preloader {
        width: 128px;
        height: 128px;
        top: -10px;
        position: relative;
    }
`
export const AutomationWrapper = styled.div`
    background: ${props => (props.visiblity ? '#ffffff 0% 0% no-repeat padding-box' : 'none')};
    width: 100%;
    float: left;
    height: 100%;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    visiblity: ${props => (props.visiblity ? 'visible' : 'none')};
`
export const PopUpLeft = styled.div`
    width: 100%;
    float: left;
    visibility: ${props => (props.hideLeft ? 'hidden' : 'visible')};
`
const Modal = (props: any) => {
    const [showModalAutomation, setShowmodalAutomation] = useState({ id: '' })
    const [state, , , retry] = useFetchFromApi(() => loadMarketPlaceAutomations(props.match.params.workspacename))

    return (
        <>
            <ModalRow>
                <ModalAutomation {...props} id={showModalAutomation.id} visiblity={showModalAutomation.id.length > 0} />
                <ModalPopup
                    {...props}
                    state={state}
                    visiblity={showModalAutomation.id.length > 0}
                    setSelectedWorkflow={(id: string) => setShowmodalAutomation({ id })}
                    selectedWorkflow={showModalAutomation.id}
                />
            </ModalRow>
        </>
    )
}

const ModalPopup = (props: any) => {


    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [search, setSearch] = useState('')
    const [multiSelectApps,setMultiSelectApps] = useState<SelectApplication[]>([])

    const allAutomationList = props.state.type ==="loading" || props.state.type ==="error" ? [] : props.state.data.apps.output
    const allCategories = props.state.type ==="loading" || props.state.type ==="error" ? [] : props.state.data.categories.output

    const itemsSelectionLimit = 5
    const initializeSelectApp = (item: MarketPlaceApplication) => ({ ...item, isSelected: false } as SelectApplication)

    const rawData = allAutomationList as Record<string, MarketPlaceAutomationRaw[]>

    const uniqueApps = uniqueMarketPlaceApps(rawData)
    const automationList = uniqueMarketPlaceAutomations(rawData)
    const [category, setCategory] = useState(filterFun(R.map(initializeSelectApp, uniqueApps), allCategories))
    const [apps, setApps] = useState<SelectApplication[]>([])
    const [selected, setSelected] = useState({
        selectedCategory: '',
        selectedApp: '',
        selectedAppIcon: ''
    })

    const selectedItems = R.uniq((multiSelectApps.length >0 ? multiSelectApps : apps.filter(app => app.isSelected)))

    const allPossibleAppCombinations = getAllSubsets(selectedItems.map(app => app.id))

    const filteredAutomations = allPossibleAppCombinations
        .filter(a => a.length == 1 || a.length == selectedItems.length)
        .map(appItem => {
            if (appItem.length == 0) return []
            const filterOnlyMatchingAppSet = R.converge(allAreSame, [
                R.compose((x: any) => x.length, R.intersection(appItem)),
                R.always(appItem.length),
                R.length
            ])

            const filterAutomations = R.compose<
                MarketPlaceAutomation,
                Array<Application & Action>,
                string[],
                string[],
                boolean
            >(filterOnlyMatchingAppSet, R.uniq, R.map(R.prop('id')), R.prop('apps'))

            return R.filter(filterAutomations)(automationList)
        }) as any
    const sortLengthByApps = R.sort(function(a: any, b: any) {
        return b.apps.length - a.apps.length
    })

    const automations = R.compose(R.map(sortLengthByApps))(
        filteredAutomations.map((auto: any) => auto.length > 0 && auto).filter(Boolean)
    )

    const workflow = R.flatten(automations).filter(
        a => search.length == 0 || (a.description && a.description.toLowerCase().includes(search.toLowerCase()))
    )
    const sortByDate = (a: any, b: any) => (a.modified_date < b.modified_date ? -1 : 1)
    const workflows = R.sort(sortByDate,workflow)
    
    const [inputValue, setInputValue] = useState('')
    const [changeCategory, setchangeCategory] = useState({ id: '', name: 'All' })
    const onChangeCategory = (value: { name: string; id: string }) => setchangeCategory(value)
    const [isFirstPopup, setIsFirstPopup] = useState(true)
    const [searchApp, setSearchApp] = useState('')

    if (props.state.type == 'loading') {
        return (
            <PopUpRight primary>
                <Header>
                    <HeaderLeft minWidth={300}>
                        App Workflow Marketplace
                        {/* <a target="_blank" href="https://help.workativ.com/knowledgebase/download-workflow/">
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="View help document"
                                distance={5}
                            >
                                <QaIconWithCircle />
                            </Tooltip>
                        </a> */}
                    </HeaderLeft>
                    <HeaderRight>
                        <Paragraph className="header_right_popup" cursor>
                            <VideoLinkSimple id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} text={'How to'} />
                        </Paragraph>
                    </HeaderRight>
                </Header>

                <Paragraph>Download from over 600+ pre-built app workflows for your chatbot.</Paragraph>
                <BgBox>
                    <BoxWrapper>
                        <div className="loader_box">
                            <Loader.PopupLoader show={true} />
                        </div>
                        <>
                            <div className="shadow_bottom"></div>
                        </>
                    </BoxWrapper>
                </BgBox>
            </PopUpRight>
        )
    } else if (props.state.type == 'error') {
        return (
            <PopUpRight primary>
                <Header>
                    <HeaderLeft minWidth={300}>
                        App Workflow Marketplace
                        {/* <a target="_blank" href="https://help.workativ.com/knowledgebase/download-workflow/">
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="View help document"
                                distance={5}
                            >
                                <QaIconWithCircle />
                            </Tooltip>
                        </a> */}
                    </HeaderLeft>
                    <HeaderRight>
                        <Paragraph className="header_right_popup" cursor>
                            <VideoLinkSimple id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} text={'How to'} />
                        </Paragraph>
                    </HeaderRight>
                </Header>

                <Paragraph>Download from over 600+ pre-built app workflows for your chatbot.</Paragraph>
                <BgBox>
                    <BoxWrapper>
                        <div className="loader_box">
                            <p>Something went wrong! Please try again after some time.</p>
                            <form>
                                <Button primary type="button" onClick={() => props.closePopup()}>
                                    <label>Okay</label>
                                </Button>
                            </form>
                        </div>
                        <>
                            <div className="shadow_bottom"></div>
                        </>
                    </BoxWrapper>
                </BgBox>
            </PopUpRight>
        )
    }

    return (
        <>
            <PopUpRight primary={props.visiblity ? null : 'primary'}>
            { getProductId() === "ASSISTANT" ? 
                (<Header>
                    <HeaderLeft minWidth={300}>
                        App Workflow Marketplace
                        {/* <a target="_blank" href="https://help.workativ.com/knowledgebase/download-workflow/">
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="View help document"
                                distance={5}
                            >
                                <QaIconWithCircle />
                            </Tooltip>
                        </a> */}
                    </HeaderLeft>
                    <HeaderRight className="app_workflow_right">
                        <Paragraph className="header_right_popup" cursor>
                            <VideoLinkSimple id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} text={'How to'} />
                        </Paragraph>
                    </HeaderRight>
                </Header>)
                 : 
                 (<Header>
                    <HeaderLeft minWidth={300}>
                        App Marketplace
                        {/* <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content=""
                                distance={5}
                            >
                                <InfoIcon />
                        </Tooltip> */}
                        {/* <a target="_blank" href="https://help.workativ.com/knowledgebase/marketplace-2/">
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="View help document"
                                distance={5}
                            >
                                <QaIconWithCircle />
                            </Tooltip>
                        </a> */}
                        
                    </HeaderLeft>
                    <HeaderRight className="app_workflow_right">
                        <Paragraph className="header_right_popup" cursor>
                            <VideoLinkSimple id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} text={'How to'} />
                        </Paragraph>
                    </HeaderRight>
                </Header>)
                 }

                <Paragraph>Download app workflows, triggers, webhooks</Paragraph>
                {isFirstPopup && (
                    <FirstPopup
                        open={open}
                        setOpen={setOpen}
                        selected={selected}
                        setSelected={setSelected}
                        category={category}
                        setApps={setApps}
                        appList={uniqueApps}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        changeCategory={changeCategory}
                        onChangeCategory={onChangeCategory}
                        isFirstPopup={isFirstPopup}
                        setIsFirstPopup={setIsFirstPopup}
                        searchApp={searchApp}
                        setSearchApp={setSearchApp}
                        multiSelectApps={multiSelectApps}
                        setMultiSelectApps={setMultiSelectApps}
                    />
                )}
                {!isFirstPopup && (
                    <BgBox>
                        <BoxWrapper>
                        {multiSelectApps.length > 0 &&
                            <div className='automate_select_app_section_header'>
                                <SmallButton
                                className='automate_app_header_back_btn'
                                onClick={() => {
                                        props.setSelectedWorkflow({id: ''})
                                        setIsFirstPopup(true)
                                    }}>
                                        <span>
                                            <ArrowIcon />
                                        </span>{' '}
                                    <label>Back</label>
                                </SmallButton>
                                {multiSelectApps.map((app:any)=>(
                                    <div className='automate_select_app_card'>
                                        <div className='automate_select_app_image'>
                                            <img src={app.image} />
                                        </div>
                                        <div className='automate_select_app_title'>
                                            {app.name}
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                        }
                            <DropdownWrapper>
                                <InputContainer>
                                    <Input
                                        onClick={() => setOpen(!open)}
                                        padding={'0px 32px 0px 12px'}
                                        type="text"
                                        value={selected.selectedCategory}
                                        placeholder={'Select Category'}
                                    />
                                    <InputContent>Select</InputContent>
                                    <CancelButton onClick={() => setOpen(!open)}>
                                        <DropdownWithCircle />
                                    </CancelButton>
                                    {open && (
                                        <Downshift
                                            isOpen={true}
                                            onOuterClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            {() => (
                                                <div>
                                                    <DropdownUl className="download_marketpalce_category_list">
                                                        <DropdownLi
                                                            onClick={() => {
                                                                setSelected({
                                                                    ...selected,
                                                                    selectedCategory: 'All',
                                                                    selectedAppIcon: '',
                                                                    selectedApp: ''
                                                                })
                                                                setApps([])
                                                                setOpen(false)
                                                                setIsFirstPopup(true)
                                                            }}
                                                        >
                                                            All
                                                        </DropdownLi>
                                                        {category.map((c: any, i: number) => (
                                                            <DropdownLi
                                                                key={i}
                                                                onClick={() => {
                                                                    setSelected({
                                                                        ...selected,
                                                                        selectedCategory: c.name,
                                                                        selectedAppIcon: '',
                                                                        selectedApp: ''
                                                                    })
                                                                    setApps(c.value)
                                                                    setOpen(false)
                                                                }}
                                                            >
                                                                {c.name}
                                                            </DropdownLi>
                                                        ))}
                                                    </DropdownUl>
                                                </div>
                                            )}
                                        </Downshift>
                                    )}
                                </InputContainer>
                            </DropdownWrapper>
                            {selected.selectedCategory != '' && multiSelectApps.length === 0 && (
                                <DropdownWrapper>
                                    <InputContainer>
                                        {selected.selectedAppIcon != '' && (
                                            <DropdownImage>
                                                <img src={selected.selectedAppIcon} />
                                            </DropdownImage>
                                        )}
                                        <Input
                                            onClick={() => setOpen1(!open1)}
                                            style={{ padding: '0px 12px 0px 32px' }}
                                            padding={
                                                selected.selectedAppIcon == ''
                                                    ? '0px 12px 0px 10px'
                                                    : '0px 12px 0px 32px'
                                            }
                                            type="text"
                                            placeholder={'Select application'}
                                            value={selected.selectedApp}
                                        />
                                        {/* <DropdownIcon>
                                            <span onClick={() => setOpen1(!open1)}>
                                                <DownarrowWithCircle />
                                            </span>
                                        </DropdownIcon> */}
                                        <CancelButton onClick={() => setOpen1(!open1)}>
                                            <DropdownWithCircle />
                                        </CancelButton>
                                        {open1 && (
                                            <Downshift
                                                isOpen={true}
                                                onOuterClick={() => {
                                                    setOpen1(false)
                                                }}
                                            >
                                                {() => (
                                                    <div>
                                                        <DropdownUl className="download_marketpalce_application_list">
                                                            {apps.map(a => (
                                                                <DropdownLi
                                                                    onClick={() => {
                                                                        setSelected({
                                                                            ...selected,
                                                                            selectedApp: a.name,
                                                                            selectedAppIcon: a.image
                                                                        })
                                                                        setOpen1(false)
                                                                        const nextState = produce(apps, apps => {
                                                                            apps.forEach(ap => {
                                                                                if (ap.name == a.name) {
                                                                                    ap.isSelected = true
                                                                                } else {
                                                                                    ap.isSelected = false
                                                                                }
                                                                            })
                                                                        })

                                                                        setApps(nextState)
                                                                    }}
                                                                    style={{padding: '2px 10px 0 10px'}}
                                                                >
                                                                    <img className="marketplace_dd_img" src={a.image} />
                                                                    {a.name}
                                                                </DropdownLi>
                                                            ))}
                                                        </DropdownUl>
                                                    </div>
                                                )}
                                            </Downshift>
                                        )}
                                    </InputContainer>
                                </DropdownWrapper>
                            )}
                            {(selected.selectedApp != '' || multiSelectApps.length > 0) && (
                                <DropdownWrapper>
                                    <InputContainer>
                                        <DropdownImage>
                                            <SearchIcon />
                                        </DropdownImage>
                                        <Input
                                            style={{ padding: '0px 12px 0px 32px' }}
                                            type="text"
                                            placeholder="Search Workflows"
                                            onChange={(event: any) => {
                                                setSearch(event.target.value)
                                            }}
                                        />
                                    </InputContainer>
                                </DropdownWrapper>
                            )}
                            {
                                multiSelectApps.length > 0 ? 
                                <div className='automate_multiselect_apps'>
                                    <MultiSelectedWorkflows
                                        setSelectedWorkflow={props.setSelectedWorkflow} 
                                        search={search}
                                        listItems={automations}
                                        selectedApps={multiSelectApps}
                                    /> 
                                </div>
                                : 
                                    <CardWrapper
                                        workflows={workflows}
                                        setSelectedWorkflow={props.setSelectedWorkflow}
                                        search={search}
                                    />
                            }
                            <>
                                <div className="shadow_bottom"></div>
                            </>
                        </BoxWrapper>
                    </BgBox>
                )}
                <ButtonWrapper className="automate_download_marketplace">
                    {multiSelectApps.length > 0 && isFirstPopup &&
                        <Button style={{marginRight: 20}} onClick={() => {
                            setSelected({
                                selectedCategory: "All",
                                selectedApp: "",
                                selectedAppIcon: ""
                            })
                            setIsFirstPopup(false)
                            setSearchApp("")
                        }}>
                            View Workflows
                        </Button>
                    }
                    <Button primary onClick={() => props.closePopup()}>
                        Cancel
                    </Button>
                </ButtonWrapper>
            </PopUpRight>
        </>
    )
}

type GroupHeaderP = {
    automationGroup: AutomationGroups2
}

function joinChildren(
    children: any[],
    render: (item: any, index: any) => (props: any) => React.ReactNode,
    renderSeparator: (key: any) => (props: any) => React.ReactNode
) {
    const data = children.reduce((result: React.ReactNode[], child, index) => {
        if (index < children.length - 1) {
            return result.concat([render(child, index), renderSeparator(index + '-separator')])
        }

        return result.concat(render(child, index))
    }, []) as React.SFC[]
    return (
        <React.Fragment>
            {data.map((C, i) => (
                <C key={i} />
            ))}
        </React.Fragment>
    )
}

const renderSingleHead = (item: any, index: any) => (_: any) => (
        <span className="apps_wrap" key={index}>
            <Avatar>
                <AvatarImg src={item.image} />
            </Avatar>
        </span>
)
const renderAddIcon = (key: any) => (_: any) => (
    <SvgWidth key={key}>
        <AddIcon />
    </SvgWidth>
)

const renderSingleTriggerHead = (item: any, count: number) => (
    <React.Fragment>
        <span className="apps_wrap">
            <span>Triggers & Actions</span>
            <AppCount>({padWithZero(count)}) </AppCount>
            <Avatar>
                <AvatarImg src={item.image} />
            </Avatar>
        </span>
    </React.Fragment>
)

const GroupHeader = (props: GroupHeaderP) => {
    const { automationGroup } = props
    const info = 'Workflows'
    const uniqApps = R.uniq(automationGroup.apps.map(x => ({ image: x.image, name: x.name })))
    const automations = automationGroup.automations

    return (
        <ApplicationHeader>
            <span className="mar_apps">
                {uniqApps.length > 1 ? 
                    <>
                        <span className="apps_autoamations">{info} </span>
                        <AppCount>({padWithZero(automations.length)}) </AppCount>
                        {joinChildren(uniqApps, renderSingleHead, renderAddIcon)}
                    </>
                    : 
                    <>
                        {renderSingleTriggerHead(automationGroup.apps[0],automations.length)}
                    </>
                }
            </span>
        </ApplicationHeader>
    )
}

const MultiSelectedWorkflows = (props:any) => {
    const automationGroups =
        props.listItems.length > 0 ? groupAutomationsIntoSingle(props.listItems, props.selectedApps) : []
        return( 
        <>
        {automationGroups.length === 0 && (
                <p className="no_match_apps">
                    {' '}
                    {automationGroups.length === 0 ? 'No automations to match selection' : null}
                </p>
            )}
            {automationGroups.map((group: AutomationGroups2, index: number) => (
                <>
                    <GroupWrapper style={{marginBottom: 20}} key={index}>
                        {group.automations && <GroupHeader automationGroup={group} />}
                            <CardWrapper
                                fromGroup  
                                workflows={group.automations.filter((auto)=>auto.description.toLowerCase().includes(props.search.toLowerCase()))}
                                setSelectedWorkflow={props.setSelectedWorkflow}
                                search={props.search}
                            />
                    </GroupWrapper>
                </>
            ))}
        </>
        )
}

const CardWrapper = (props: any) => {
    return (
        <CardContainer style={props.fromGroup ? { height: 'auto', paddingBottom: 10 } : {}}>
            {props.workflows.length > 0 ? (
                props.workflows.map((c: any, i: number) => (
                    <Card
                        onClick={() => {
                            props.setSelectedWorkflow(c.id)
                        }}
                        key={i}
                        className={props.selectedWorkflow === c.id ? "workflow_card_active" : ""}
                    >
                        <HeaderWithImage>
                            <div className='automate_market_workflow_card'>
                                <ImageCircle>
                                    {getProductId() === 'ASSISTANT' ? (
                                    <span>
                                        {<FlowIcon />}
                                    </span>
                                ) : (
                                    <span className='bg_tree_flow'>
                                        {c.type==='webhook_trigger'? <WebhooksIcon/> : c.type==='event_trigger'?<AppTriggerCardIconNew/> : <TimerIcon />}
                                    </span>
                                )}   
                                </ImageCircle>
                                {/* {getProductId() === 'ASSISTANT' ? (
                                    <p>
                                        No Trigger
                                    </p>
                                ) : (
                                    <p>
                                        {c.type==='webhook_trigger'?"Webhook":"App Trigger"}
                                    </p>
                                )}    */}
                            </div>
                            <Header className="automate_market_workflow_card_content">{c.description}</Header>
                        </HeaderWithImage>
                        <CardBottom>
                            <DateTime>
                                <SVGicons>
                                    <TimerIcon />
                                </SVGicons>
                                <CardDate>{formatDateInCard(c.modified_date.toString())} </CardDate>{' '}
                                <CardTime>{formatAMPM(c.modified_date.toString())}</CardTime>
                            </DateTime>
                            <Downloads>
                                <span>
                                    <DownloadFromMarketplace />
                                </span>{' '}
                                <Count>{padWithZero(c.count)}</Count>
                            </Downloads>
                        </CardBottom>
                    </Card>
                ))
            ) : (
                <div className="no_data_available">
                    <p>{props.search.length > 0 ? 'No such workflow found' : 'No workflows found'}</p>
                </div>
            )}
        </CardContainer>
    )
}
const FirstPopup = ({
    open,
    setOpen,
    selected,
    setSelected,
    category,
    appList,
    setInputValue,
    inputValue,
    onChangeCategory,
    changeCategory,
    isFirstPopup,
    setIsFirstPopup,
    searchApp,
    setSearchApp,
    setApps,
    multiSelectApps,
    setMultiSelectApps
}: any) => {
    const app = changeCategory.value ? changeCategory.value : appList
    const [apps, setAppsDetails] = useState(app)

    useEffect(() => {
        setAppsDetails(app.filter((data: any) => data.name.toLowerCase().indexOf(searchApp.toLowerCase()) != -1))
    }, [searchApp])
    useEffect(() => {
        if (changeCategory.value) {
            setAppsDetails(changeCategory.value)
        } else {
            setAppsDetails(appList)
        }
    }, [changeCategory])

    console.log(inputValue, onChangeCategory, 'category')
    return (
       <div>
            {getProductId() === "ASSISTANT" ? (
                <BgBox>
                    <BoxWrapper>
                    <ComboboxCategory
                        categories={category}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        value={changeCategory}
                        onChange={onChangeCategory}
                        setSelected={setSelected}
                    />
                    <SerachInputWrapper>
                        <SerachInput
                            type="search"
                            value={searchApp}
                            placeholder="Search apps"
                            onChange={(e: SyntheticEvent) => {
                                const { value } = e.target as HTMLInputElement
                                setSearchApp(value)
                            }}
                        />
                        <span>
                            <SearchIcon />
                        </span>
                    </SerachInputWrapper>
                    
                    <AppWrapper
                        isFirstPopup={isFirstPopup}
                        setIsFirstPopup={setIsFirstPopup}
                        apps={apps}
                        changeCategory={changeCategory}
                        setSelected={setSelected}
                        selected={selected}
                        setApps={setApps}
                        searchApp={searchApp}
                    />
                    <>
                        <div className="shadow_bottom"></div>
                    </>
                    </BoxWrapper>
                </BgBox>
             ) : (
                <BgBox style={{background: 'transparent'}}>
                    <BoxWrapper style={{padding: '10px 20px 0 20px'}}>
                        <ComboboxCategory
                            categories={category}
                            setInputValue={setInputValue}
                            inputValue={inputValue}
                            value={changeCategory}
                            onChange={onChangeCategory}
                            setSelected={setSelected}
                        />
                        <SerachInputWrapper>
                            <SerachInput
                                type="search"
                                value={searchApp}
                                placeholder="Search apps"
                                onChange={(e: SyntheticEvent) => {
                                    const { value } = e.target as HTMLInputElement
                                    setSearchApp(value)
                                }}
                            />
                            <span>
                                <SearchIcon />
                            </span>
                        </SerachInputWrapper>
                            <Header style={{marginTop: 15, paddingLeft: 5}}>
                                <HeaderLeft minWidth={300} style={{fontSize: 16}}>
                                    Select applications
                                    <span className='automate_multiselect_title'>
                                    (Maximum 3)
                                    </span>
                                </HeaderLeft>
                            </Header>
                            <div className='automate_select_app_section'>
                                {multiSelectApps.map((app:any)=>(
                                    <div className='automate_select_app_card'>
                                        <div className='automate_select_app_image'>
                                            <img src={app.image} />
                                        </div>
                                        <div className='automate_select_app_title'>
                                            {app.name}
                                        </div>
                                        <div className='automate_select_app_close' onClick={()=>setMultiSelectApps(multiSelectApps.filter((a:any)=>a.id!==app.id))}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                    </BoxWrapper>  
                        <AppWrapper
                            isFirstPopup={isFirstPopup}
                            setIsFirstPopup={setIsFirstPopup}
                            apps={apps}
                            changeCategory={changeCategory}
                            setSelected={setSelected}
                            selected={selected}
                            multiSelectApps={multiSelectApps}
                            setMultiSelectApps={(c:any)=>setMultiSelectApps(c)}
                            setApps={setApps}
                            searchApp={searchApp}
                        />
                        <>
                            <div className="shadow_bottom"></div>
                        </>
                </BgBox>         
             )}
       </div>
    )
}

const AppWrapper = (props: any) => {
    const { selected, setSelected, changeCategory, isFirstPopup, setIsFirstPopup, setApps, searchApp, setMultiSelectApps,multiSelectApps } = props
    return (
        <div>
            {getProductId() === "ASSISTANT" ? ( 
                <CardContainer style={{ height: 'calc(100vh - 370px)'}}>
                    {props.apps.length > 0 ? (
                        props.apps.map((c: any, i: number) => (
                            <Card
                                onClick={() => {
                                    setSelected({
                                        selectedCategory: changeCategory.name,
                                        selectedApp: c.name,
                                        selectedAppIcon: c.image
                                    })
                                    setIsFirstPopup(false)

                                    const nextState = produce(props.apps as any, (apps: any) => {
                                        apps.forEach((ap: any) => {
                                            if (ap.name == c.name) {
                                                ap.isSelected = true
                                            } else {
                                                ap.isSelected = false
                                            }
                                        })
                                    })

                                    setApps(nextState)
                                    setMultiSelectApps([])
                                }}
                                key={i}
                            >
                                <HeaderBox>
                                    <HeaderImageCircle>
                                        <img src={c.image} />
                                    </HeaderImageCircle>

                                    <HeaderText>{c.name}</HeaderText>
                                </HeaderBox>
                                {/* {
                                    (multiSelectApps.length<3 || multiSelectApps.includes(c)) && <label className='card_multiselect_option_app'>
                                        <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={multiSelectApps.findIndex((a:any)=>a.id===c.id) != -1}
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                multiSelectApps.findIndex((a:any)=>a.id===c.id) == -1 ? setMultiSelectApps([...multiSelectApps,c]) : setMultiSelectApps(multiSelectApps.filter((a:any)=>a.id!==c.id))
                                            }}
                                            onChange={() => {}}
                                        />
                                    </label>
                                } */}
                            </Card>
                        ))
                    ) : (
                        <div className="no_data_available">
                            <p>{searchApp.length > 0 ? 'No such application found' : 'No applications found'}</p>
                        </div>
                    )}
                </CardContainer>
        ) : ( 
            <div className='automate_card_section'>
                <CardContainer>
                    {props.apps.length > 0 ? (
                        props.apps.map((c: any, i: number) => (
                            <Card
                                onClick={() => {
                                    setSelected({
                                        selectedCategory: changeCategory.name,
                                        selectedApp: c.name,
                                        selectedAppIcon: c.image
                                    })
                                    setIsFirstPopup(false)

                                    const nextState = produce(props.apps as any, (apps: any) => {
                                        apps.forEach((ap: any) => {
                                            if (ap.name == c.name) {
                                                ap.isSelected = true
                                            } else {
                                                ap.isSelected = false
                                            }
                                        })
                                    })

                                    setApps(nextState)
                                    setMultiSelectApps([])
                                }}
                                key={i}
                            >
                                <HeaderBox>
                                    <HeaderImageCircle>
                                        <img src={c.image} />
                                    </HeaderImageCircle>

                                    <HeaderText>{c.name}</HeaderText>
                                </HeaderBox>
                                {
                                    (multiSelectApps.length<3 || multiSelectApps.includes(c)) && <label className='card_multiselect_option_app'>
                                        <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={multiSelectApps.findIndex((a:any)=>a.id===c.id) != -1}
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                multiSelectApps.findIndex((a:any)=>a.id===c.id) == -1 ? setMultiSelectApps([...multiSelectApps,c]) : setMultiSelectApps(multiSelectApps.filter((a:any)=>a.id!==c.id))
                                            }}
                                            onChange={() => {}}
                                        />
                                    </label>
                                }
                            </Card>
                        ))
                    ) : (
                        <div className="no_data_available">
                            <p>{searchApp.length > 0 ? 'No such application found' : 'No applications found'}</p>
                        </div>
                    )}
                </CardContainer>
            </div>
        )}
        </div>
    )
}

export function ComboboxCategory({ inputValue, setInputValue, categories, value, onChange, setSelected }: any) {
    categories = [{ name: 'All', id: '' }, ...categories]
    const displayItem = (item: any | null) => (item ? item.name : '')
    const searchBox: any = useRef(null)
    const [inputItems, setInputItems] = useState(categories)
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps
    } = useCombobox({
        items: inputItems,
        selectedItem: value,
        itemToString: displayItem,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem)
            setSelected({ selectedCategory: selectedItem.name, selectedApp: '', selectedAppIcon: '' })
        },
        inputValue,
        onInputValueChange: ({ inputValue }: any) => {
            setInputItems(
                categories.filter(({ name: item }: { name: string }) =>
                    item.toLowerCase().startsWith(inputValue.toLowerCase())
                )
            )
            setInputValue(inputValue)
        }
    })
    useEffect(() => {
        if (isOpen && searchBox.current) {
            searchBox.current.focus()
            setInputValue('')
            setInputItems(categories)
        }
    }, [isOpen])
    return (
        <DropdownWrapper>
            <InputContainer>
                <div {...getComboboxProps()}>
                    {!isOpen ? (
                        <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
                    ) : (
                        <Input
                            {...getInputProps({
                                ref: searchBox
                            })}
                        />
                    )}
                    <InputContent>Select app category</InputContent>
                    <CancelButton>
                        <DropdownWithCircle />
                    </CancelButton>
                </div>
                <DropdownUlWrapper>
                    <DropdownUl {...getMenuProps()}>
                        {isOpen ? (
                            inputItems.length !== 0 ? (
                                inputItems.map((item: any, index: number) => {
                                    return (
                                        <DropdownLi key={`${item.name}${index}`} {...getItemProps({ item, index })}>
                                            {item.name}
                                        </DropdownLi>
                                    )
                                })
                            ) : (
                                <DropdownLi>No match found</DropdownLi>
                            )
                        ) : null}
                    </DropdownUl>
                </DropdownUlWrapper>
            </InputContainer>
        </DropdownWrapper>
    )
}
// Automation left css End

const ModalAutomation = (props: any) => {
    if (props.hidden) {
        return (
            <PopUpLeft hideLeft>
                <AutomationWrapper />
            </PopUpLeft>
        )
    }
    const [nonCanvasAction, setNonCanvasAction] = useState<any>(Loading)
    const [headerDetails, setHeaderDetails] = useState<any>({ title: '', description: '' })

    const journeyData = useJourney()

    const downloadAutomation = async () => {
        try {
            const response = await postJson(
                automationAPIURLWithWorkspace(props.match.params.workspacename, MarketPlaceApi.AddToWorkSpace)
            )({
                automationId: props.id
            })
            const DownloadAcknowledgement: AcknowledgeT = {
                mode: NonCanvasActionModes.Acknowledge,
                message: `<b>Automation downloaded as<br/> "${response.data.output.name}" in drafts</b>`
            }
            if (journeyData.type === 'success') {
                journeyData.value.send({
                    type: 'EVENT::WORKFLOW::DOWNLOADED_FROM_MARKETPLACE',
                    tag: 'GENERIC',
                    journeyType: 'Workflow',
                    refId: Math.random().toString()
                })
            }

            logEvent(props.match.params.workspacename, {
                event:"EVENT::WORKFLOW::DOWNLOAD_FROM_MARKETPLACE",event_meta:{
               name: headerDetails.title,
               description: headerDetails.description
            }})
            
            setNonCanvasAction(DownloadAcknowledgement)
        } catch (err) {
            let error = err as any
            const DownloadAcknowledgement: AcknowledgeT = {
                mode: NonCanvasActionModes.Acknowledge,
                message: error.response.data.error
            }
            setNonCanvasAction(DownloadAcknowledgement)
        }
    }

    const renderAcknowledgeModal = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.Acknowledge) {
            return (
                <Modals>
                    <div className="error_style" style={{ height: 'auto' }}>
                        <p style={{ textAlign: 'center' }}>{Parser(nonCanvasAction.message)}</p>
                        <Button
                            primary
                            onClick={() => {
                                setNonCanvasAction(None)
                                props.closePopup()
                                props.history.push(
                                    `/workspace/${props.match.params.workspacename}/automation/view/draft`
                                )
                                reloadWithoutParams()
                                // window.location.reload()
                            }}
                            style={{marginTop: 15 }}
                        >
                            <label>Okay</label>
                        </Button>
                    </div>
                </Modals>
            )
        }
        return null
    }

    const renderDeployAutomation = () => {
        if (nonCanvasAction.mode === NonCanvasActionModes.DownloadFromMarket) {
            return (
                <Modals>
                    <ErrorBoundary
                        error={nonCanvasAction.error}
                        render={(err: any, _info: any) => {
                            return (
                                err && (
                                    <Error.Delete
                                        onClick={() => {
                                            setNonCanvasAction(None)
                                        }}
                                        info={'Error in downloading automation. Please try again later'}
                                    />
                                )
                            )
                        }}
                    >
                        <h2> Download Automation</h2>
                        <Paragraph style={{ paddingBottom: 20 }}>
                            {' '}
                            Automation will be downloaded to your automation drafts. Do you want to download?{' '}
                        </Paragraph>
                        <YesOrnoWrapper>
                            <Button
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setNonCanvasAction(None)
                                }}
                            >
                                <label>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    downloadAutomation()
                                }}
                            >
                                <label>Yes</label>
                            </Button>
                        </YesOrnoWrapper>
                    </ErrorBoundary>
                </Modals>
            )
        } else return null
    }

    return (
        <PopUpLeft>
            <AutomationWrapper visiblity={props.visiblity}>
                {props.visiblity && (
                    <>
                        <HeaderContent>
                            <HeaderContentLeft>
                                <Header>{headerDetails.title}</Header>
                                <Paragraph>{headerDetails.description}</Paragraph>
                            </HeaderContentLeft>
                            <HeaderContentRight>
                                <Button
                                    className="m-0"
                                    onClick={() => {
                                        setNonCanvasAction({
                                            mode: NonCanvasActionModes.DownloadFromMarket,
                                            id: props.id,
                                            error: { error: false, info: '' }
                                        })
                                    }}
                                >
                                    <span className="header_button">
                                        <DownloadFromMarketplace />
                                    </span>
                                    <label>Download</label>
                                </Button>
                            </HeaderContentRight>
                        </HeaderContent>
                        <AutomationBg>
                            <CanvasMarketpalce>
                                <MarketFlowCanvasWrapper
                                    setHeaderDetails={setHeaderDetails}
                                    showLeftPanel
                                    match={{
                                        params: { id: props.id, workspacename: props.match.params.workspacename }
                                    }}
                                />
                            </CanvasMarketpalce>
                        </AutomationBg>
                    </>
                )}
            </AutomationWrapper>
            {renderDeployAutomation()}
            {renderAcknowledgeModal()}
        </PopUpLeft>
    )
}

export const ModalRow = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 480px;
    position: absolute;
    box-shadow: 0px 10px 20px #00000029;
    padding: 0px 20px 20px;
`
export const EmptyDiv = styled.div`
    width: 87%;
    float: left;
`
export const HeaderWithImage = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    border-bottom: 0.5px solid #ccc;
    font-size: 18px;
    padding: 0px 10px 10px 10px;
    word-break: break-word;
    padding-top: 10px;
    min-height: 108px;
`

export const HeaderBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
`

export const ImageCircle = styled.span`
    width: 30px;
    height: 30px;
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7e3ff;
    border-radius: 50%;
    position: relative;

    svg {
        width: 12px;
        height: 12px;
        fill: #367af2;
    }
`

export const HeaderImageCircle = styled.span`
    width: 30%;
    height: auto;
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7e3ff;
    border-radius: 50%;
    position: relative;

    img{
        max-width: 100%;
    }

    svg {
        width: 12px;
        height: 12px;
        fill: #367af2;
    }
`

export const CardBottom = styled.p`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 10px;
    height: 36px;
`

export const SVGicons = styled.span`
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-left: 0px;
    display: flex;
    float: left;
`
export const DateTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;

    ${SVGicons} {
        position: relative;
        top: 1px;
        svg {
            fill: #ff7900;
            width: 10px;
            height: 10px;
        }
    }
`

export const CardTime = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    margin-left: 0px;
    align-items: baseline;
`
export const CardDate = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    align-items: baseline;
`
export const Downloads = styled.span`
    font-family: 'Gordita-Regular';
    color: #666666;
    font-size: 9px;
    display: flex;
    align-items: baseline;

    span {
        margin-right: 2px;
        svg {
            width: 10px;
            height: 10px;
            fill: #e57700;
            position: relative;
            top: 1px;
        }
    }
`

export const Count = styled.span`
    font-family: 'Gordita-Regular';
    color: #666;
    font-size: 9px;
    margin-left: 2px;
`
export const Card = styled.div`
    height: 144px;
    float: left;
    margin-right: 0px;
    border: 0.5px solid #fff;
    position: relative;
    justify-content: left;
    display: flex;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 6px;
    
    :hover {
        background: #ddfeef;
        color: #333;
        cursor: pointer;
        border: 0.5px solid #ddfeef;
    }

    ${({ isActive }) =>
        isActive &&
        `
    background: #ddfeef;
    color: #333;
    cursor: pointer;
    border: 0.5px solid #ddfeef;
    ${CardBottom} {
        color: #333;
    }
    ${DateTime} {
        color: #333;
    
    }
    ${CardDate} {
        color: #333;
    }
    ${CardTime} {
        color: #333;
    }
    ${Downloads} {
        color: #333;
    }
    ${ImageCircle} {
        background: #fff;
        svg {
            fill: #333;
        }
    `}

    &:hover ${HeaderWithImage} {
        color: #333;
    }
    &:hover ${CardBottom} {
        color: #333;
    }
    &:hover ${DateTime} {
        color: #333;
     
    }
    &:hover ${CardDate} {
        color: #333;
    }
    &:hover ${CardTime} {
        color: #333;
    }
    &:hover ${Downloads} {
        color: #333;
    }
    // &:hover ${Count} {
    //     color: #333;
    // }

    &:hover ${ImageCircle} {
        background: #fff;
        svg {
            fill: #333;
        }
    }
`
export const CardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 10px;
    overflow-y: scroll;
    height: calc(100vh - 430px);
    margin-top: 10px;
    place-content: baseline;
    padding-bottom: 30px;

    .no_data_available {
        width: calc(100% - -220px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 390px);

        img {
            width: 48px;
            height: 48px;
            animation-name: spin;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @-ms-keyframes spin {
            from {
                -ms-transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
            }
        }
        @-moz-keyframes spin {
            from {
                -moz-transform: rotate(0deg);
            }
            to {
                -moz-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            from {
                -webkit-transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        p {
            font-family: 'Gordita-Regular';
            font-size: 14px;
            color: #000;
            margin-bottom: 0px;
        }
    }

    ${Header} {
        color: #000;
        font-family: 'Gordita-Regular';
        font-size: 10px;
        margin-left: 4px;
        margin-top: 6px;
        width: 100%;
        padding: 0px;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${Paragraph} {
        color: #000;
        font-family: 'Gordita-Regular';
        font-size: 10px;
        margin-left: 7px;
        width: 100%;
        padding: 0px;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        margin-bottom: 0px;
        margin-top: 0px;
    }
`
