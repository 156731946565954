import React from 'react';

export const useScrollHook = () => {
    const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

    const scrollTo = (where: 'top' | 'bottom', condition?: boolean) => {
        if (scrollableDivRef.current) {
            setTimeout(() => {
                if (condition !== false) {
                    if (where === 'bottom') {
                        scrollableDivRef.current?.scrollTo({
                            top: scrollableDivRef.current?.scrollHeight,
                            behavior: 'smooth',
                        });
                    } else {
                        scrollableDivRef.current?.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }
                }
            }, 0);
        }
    };
    return [scrollableDivRef, scrollTo] as const;
};
