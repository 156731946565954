import Downshift from 'downshift'
/*eslint-disable*/
import React from 'react'

import { Button as PopupButton } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonContainer } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

export const DropDown = ({
    isOpen,
    onOuterClick,
    children
}: {
    isOpen: boolean
    onOuterClick: Function
    children: React.ReactChild
}) =>
    isOpen ? (
        <Downshift isOpen={isOpen} onOuterClick={() => onOuterClick()}>
            {() => children}
        </Downshift>
    ) : null

export const Button = ({ disable, onSave, onCancel, className }: { onSave: Function; onCancel: Function; disable: boolean; className?: string }) => (
    <ButtonContainer className={`right-panel_btn ${className ? className :''}`}>
        <div className="popup_footer_button_container">
            <PopupButton primary type="button" data-dismiss="modal" onClick={() => onCancel()}>
                <label>Cancel</label>
            </PopupButton>

            <PopupButton
                className={disable ? 'editor_btn_disabled' : ''}
                type="button"
                data-dismiss="modal"
                onClick={() => onSave()}
            >
                <label>Save</label>
            </PopupButton>
        </div>
    </ButtonContainer>
)
