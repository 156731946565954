import * as ramda from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useQueryClient } from 'react-query'

import { SideBarContext } from '@/SidePanel/SideBarContext'
import {
    CloseIconWithCircle,
    MaxiArrowDownIcon,
    MaxiArrowUpIcon,
    MaxiIcon,
    MiniIcon,
    SearchIcon,
    SubPanelIcons,
    VideoIcon
} from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { useGenericCached } from '@/common/hooks/useCachedQuery'
import { usePreviousWithCondition } from '@/common/hooks/usePrevious'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { Button, Paragraph } from '@/common/styled/Workflow.Dumb'
import { getJson, helpVideoUrl } from '@/common/utils/api-utils'

import ImageWithPlaceHolder from './ImagePlaceHolder'
import ResizablePIP from './resizable-pip/resizablePIP'

export const VideosLayout = (props: any) => {
    const [videosList, setVideoList] = useState<Videos[]>([])

    const [filter, setFilter] = useState('all')
    const [search, setSearch] = useState('')

    const categories = videosList.length ? ramda.groupBy((x: Videos) => x.categoryName)(videosList) : {}
    const allVideos = Object.entries(categories)
        .map((all, i) => (filter == 'all' ? all[1] : all[0] == filter ? all[1] : []))
        .filter(x => x.length !== 0)

    // const allVideosFlattened = ramda.flatten<Videos[]>(allVideos)

    const allVideosNotUniq = ramda.flatten<Videos>(Object.values(allVideos))

    const uniqVideos = ramda.uniqBy(x => x.videoName.toLowerCase(), videosList)
    const allLength = uniqVideos.length

    // console.log(categories, allVideos, allVideosNotUniq, 'categories')
    const videoContext = useContext(VideoContext)
    const sidebarContext = useContext(SideBarContext)
    const propsState = props.state

    // console.log('categories', categories)

    useEffect(() => {
        if (props.state.type == 'success') {
            setVideoList(props.state.data)
        }
    }, [propsState])

    if (propsState.type == 'loading') return <Loader.PageLoader show={true} />

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="market_place_wrapper viedo_page_side_menu">
                    {/* sideapanel */}
                    {sidebarContext.isSubPanelOpen == false && (
                        <div className="flex_side_menu video_side_menu">
                            <Wrapper>
                                <div className="sidepanel_menu_right_menu">
                                    {/* <div className="app_category_wrapper"> */}
                                    <div className="side_menu_ul">
                                        <div
                                            className={`side_menu_li ${filter == 'all' ? 'side_menu_li_active' : ''}`}
                                            onClick={() => setFilter('all')}
                                        >
                                            <SubPanelIcons.HomePage />
                                            <p>
                                                All Videos <span className="apps_counter">({allLength})</span>
                                            </p>
                                        </div>

                                        {Object.entries(categories).map((key, i) => (
                                            <div
                                                className={`side_menu_li ${
                                                    filter == key[0] ? 'side_menu_li_active' : ''
                                                }`}
                                                key={i}
                                                onClick={() => setFilter(key[0])}
                                            >
                                                <p>
                                                    {key[0]} <span className="apps_counter">({key[1].length})</span>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    {/* </div> */}
                                </div>
                            </Wrapper>
                        </div>
                    )}
                    <div className="marketplace_container">
                        <Header>
                            <HeaderChild type="first">{null}</HeaderChild>
                            <HeaderChild type="second">
                                <span className="search_icon_svg">
                                    <input
                                        type="search"
                                        placeholder="Search"
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                    <SearchIcon />
                                </span>
                            </HeaderChild>
                        </Header>
                        <ErrorBoundary
                            unauthorized={
                                propsState.type == 'error' &&
                                propsState.type.error &&
                                propsState.type.error.response &&
                                propsState.type.error.response.status == 403
                            }
                            error={
                                propsState.type != 'error'
                                    ? { error: null, info: null }
                                    : {
                                          error: true,
                                          info: 'Server error!<br />Please try again after sometime.'
                                      }
                            }
                            render={(err: any, info: any) => {
                                return (
                                    err && (
                                        <Error.PageLoadingError
                                            onClick={() => {
                                                props.retry()
                                            }}
                                            info={info}
                                            btnName={'Retry'}
                                        />
                                    )
                                )
                            }}
                        >
                            <div className="video-border overflow-height">
                                <div className="container-fluid">
                                    <div className="row video-pannel">
                                        {/* <div className="col-md-4">
                                        <a className="open-popup" href="#">
                                            <div className="video-box">
                                                <img className="" src={Thumbnail} alt="img" />
                                                <div className="play-icon">
                                                    <img className="" src={PlayIcon} alt="playicon" />
                                                </div>
                                            </div>
                                            <div className="video-title-box">
                                                <h5>Some Title</h5>
                                            </div>
                                            <div className="popup_body">
                                                <div className="popup_back"></div>
                                                <div className="popup_contain">
                                                    <div className="popup_close">x</div>
                                                    Pop Up Body 1
                                                </div>
                                            </div>
                                        </a>
                                    </div> */}
                                        {ramda
                                            .uniqBy(x => x.videoName.toLowerCase(), allVideosNotUniq)
                                            .filter(v => v.videoName.toLowerCase().indexOf(search.toLowerCase()) != -1)
                                            .map((v, i) => (
                                                <div
                                                    className="col-lg-3 col-md-4 col-sm-6 col-12 video-box-container"
                                                    key={i}
                                                >
                                                    <a>
                                                        <div
                                                            className="video-box"
                                                            onClick={() => {
                                                                videoContext.setVideoURL({
                                                                    id: `${v.externalId}`,
                                                                    title: v.videoName
                                                                })
                                                            }}
                                                        >
                                                            <ImageWithPlaceHolder
                                                                // className=""
                                                                src={`https://vumbnail.com/${v.externalId}.jpg`}
                                                                // alt="img"
                                                            />
                                                            {/* <div className="video-box-background">
                                                                <div className="play-icon">
                                                                    <img className="" src={PlayIcon} alt="playicon" />
                                                                </div>
                                                            </div> */}
                                                            <div className="video_time_box">
                                                                <p>{v.duration}</p>
                                                            </div>
                                                        </div>
                                                        <div className="video-title-box">
                                                            <h5>
                                                                <span>{v.videoName}</span>
                                                            </h5>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </ErrorBoundary>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

const Wrapper = (props: { children: React.ReactNode }) => {
    return (
        <div className="sidepanel_menu_right">
            <div className="sidepanel_menu_right_head">
                <h4>Assistant Videos</h4>
            </div>
            <div className="sidepanel_menu_right_menu video_page">
                <div className="sidepanel_menu_right_menu_step">
                    <div className="automation_left_panel">
                        <div className="automation_left_panel_tree">
                            {/* <div className="left_popup_automation_tree"> */}
                            {props.children}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const useVideoData = (internalId: string) => {
    const videoContext = React.useContext(VideoContext)

    // console.log('internalId', videoContext)
    const videoToPlace = videoContext.videosList.find(x => x.internalId === internalId)

    const showVideo = () => {
        if (videoToPlace) {
            videoContext.setVideoURL({ id: videoToPlace.externalId, title: videoToPlace.videoName })
        }
    }

    return [videoToPlace, showVideo] as [Videos | undefined, () => void]
}

export const VideoButton = (props: { id: string; text?: string ,showOnlyIcon?:boolean}) => {
    const [videoToPlace, showVideo] = useVideoData(props.id)

    return videoToPlace ? (
        <>
        {props.showOnlyIcon?(
          <div onClick={showVideo}>
            <VideoIcon />
          </div>
        ):
        (<SmallButton
            // disabled={disableState ? 'disable' : null}
            primary
            onClick={showVideo}
            className={`journey_publish _active`}
            style={{ minWidth: 80, marginLeft: 14 }}
        >
            <label>
                <VideoIcon />
                <p>{props.text ? props.text : 'How to'}</p>
            </label>
        </SmallButton>
        )}
        </>
    ) : null
}

export const VideoButtonB = (props: { id: string; text?: string, showOnlyIcon?:boolean }) => {
    const [videoToPlace, showVideo] = useVideoData(props.id)

    return videoToPlace ? (
        <>
        {props.showOnlyIcon?(
          <div onClick={showVideo}>
            <VideoIcon />
          </div>
        ):
        (
            <Button
            // disabled={disableState ? 'disable' : null}
            primary
            onClick={showVideo}
            className={`journey_publish _active`}
            style={{ minWidth: 80, marginLeft: 14 }}
            margin={'sides'}
            >
                <label>
                    <VideoIcon />
                    <p>{props.text ? props.text : 'How to'}</p>
                </label>
            </Button>
        )}
        </>
    ) : null
}

export const VideoLink = (props: { id: string; text?: string }) => {
    const [videoToPlace, showVideo] = useVideoData(props.id)

    return videoToPlace ? (
        <label className={'video_link'} onClick={showVideo}>
            <VideoIcon />
            {props.text ? props.text : 'How to'}
        </label>
    ) : null
}

export const VideoLinkSimple = (props: { id: string; text?: string }) => {
    const [videoToPlace, showVideo] = useVideoData(props.id)

    return videoToPlace ? (
        <Paragraph cursor onClick={showVideo}>
            <VideoIcon />
            {props.text ? props.text : 'How to'}
        </Paragraph>
    ) : null
}

enum PlayerSize {
    small = 'small',
    large = 'large',
    minimized = 'minimized'
}
export interface Videos {
    categoryId: number
    categoryName: string
    videoId: number
    videoName: string
    internalId: string
    externalId: string
    thumbnail: string
    duration: string
}

export function useVideos(props: any) {
    const singletonQueryClient = useQueryClient()

    function invalidateVideoCache() {
        singletonQueryClient.invalidateQueries('common::videos')
    }
    const [videoURL, setVideoURL] = React.useState({ url: '', title: '' })
    const [playerSize, setPlayerSize] = React.useState<PlayerSize>(PlayerSize.small)
    const lastPlayerSize = usePreviousWithCondition<PlayerSize>(
        playerSize,
        (size: PlayerSize) => size !== PlayerSize.minimized
    )
    const [videoLoading, setVideoLoading] = React.useState(true)
    const [videosList, setVideosList] = React.useState<Videos[]>([])

    const fetchVideosList = useGenericCached(
        'common::videos',
        () => {
            const s = props.state as any
            if (s.match) {
                return getJson(helpVideoUrl(s.match.params.workspacename, `/get-all-video-category`))
            } else {
                return Promise.resolve([])
            }
        },
        {
            enabled: (props.state as any).match != undefined
        }
    )
    React.useEffect(() => {
        if (fetchVideosList.status === 'fetched') {
            setVideosList(fetchVideosList.data.output)
        } else if (fetchVideosList.status === 'error') {
            setVideosList(fetchVideosList.error)
        }
        // refetchVideos()
    }, [fetchVideosList.status])

    const setVideoUrlId = ({ id, title }: { id: string; title: string }) => {
        const data = {
            url: `https://vimeo.com/${id}`,
            title: title
        }
        setVideoURL(data)
    }

    return {
        videoURL,
        setVideoURL,
        lastPlayerSize,
        videoLoading,
        setVideoLoading,
        videosList,
        setVideoUrlId,
        playerSize,
        setPlayerSize,
        refetchVideos: invalidateVideoCache
    }
}

export type VideoPlayerComponentP = {
    videoURL: { url: string; title: string }
    playerSize: PlayerSize
    setPlayerSize: React.Dispatch<React.SetStateAction<PlayerSize>>
    videoLoading: boolean
    setVideoLoading: React.Dispatch<React.SetStateAction<boolean>>
    lastPlayerSize: PlayerSize
    setVideoURL: React.Dispatch<
        React.SetStateAction<{
            url: string
            title: string
        }>
    >
}

export function VideoPlayerComponent({
    videoURL,
    playerSize,
    setPlayerSize,
    videoLoading,
    setVideoLoading,
    lastPlayerSize,
    setVideoURL
}: VideoPlayerComponentP) {
    const nodeRef = React.useRef<HTMLElement>(null)
    const getWrapperClassName = () => {
        // console.log('playersize', playerSize)
        switch (playerSize) {
            case PlayerSize.minimized:
                return 'minimized-player'
            case PlayerSize.small:
                return 'small-player'
            case PlayerSize.large:
                return 'maximize-player'
        }
    }

    const getPlayerSizeInPixels = () => {
        switch (playerSize) {
            case PlayerSize.small:
                return { width: 550, height: 330 }
            case PlayerSize.large:
                return { width: 1000, height: 600 }
            case PlayerSize.minimized:
                return { width: 350, height: 27 }
        }
    }

    React.useEffect(() => {
        // console.log('noderef', nodeRef)
        if (nodeRef.current) {
            nodeRef.current.style.transform = 'none'
        }

        // if (playerSize == PlayerSize.minimized) {
        //     setVideoLoading(true)
        // }
    }, [playerSize])

    // console.log('nodeRef', nodeRef)

    return videoURL.url.length > 0 ? (
        <div className={`help_video_box ${getWrapperClassName()}`}>
            <ResizablePIP
                {...getPlayerSizeInPixels()}
                minConstraints={[100, 100]}
                maxConstraints={[1200, 600]}
                ref={nodeRef}
            >
                <div className="help_video_title_container">
                    <div
                        className={`help_video_title_box ${
                            playerSize === PlayerSize.minimized
                                ? 'video-minimised-header'
                                : 'video-non-minimised-header'
                        }`}
                    >
                        <div className="help_video_title_box_img">
                            <VideoIcon />
                        </div>
                        <div className="help_video_title_box_text">
                            <p>{videoURL.title}</p>
                        </div>
                    </div>
                    <div
                        className={`help_video_title_button  ${
                            playerSize === PlayerSize.minimized
                                ? 'video-minimised-header-icons'
                                : 'video-non-minimised-header-icons'
                        }`}
                    >
                        {playerSize === PlayerSize.minimized ? (
                            <div
                                onClick={() => {
                                    setPlayerSize(lastPlayerSize)
                                }}
                                className="help_video_title_icon"
                            >
                                <MaxiArrowDownIcon />
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    setPlayerSize(PlayerSize.minimized)
                                }}
                                className="help_video_title_icon"
                            >
                                <MaxiArrowUpIcon />
                            </div>
                        )}
                        {playerSize === PlayerSize.large ||
                        (playerSize === PlayerSize.minimized && lastPlayerSize === PlayerSize.large) ? (
                            <div className="help_video_title_icon" onClick={() => setPlayerSize(PlayerSize.small)}>
                                <MiniIcon />
                            </div>
                        ) : null}
                        {playerSize === PlayerSize.small ||
                        (playerSize === PlayerSize.minimized && lastPlayerSize === PlayerSize.small) ? (
                            <div className="help_video_title_icon" onClick={() => setPlayerSize(PlayerSize.large)}>
                                <MaxiIcon />
                            </div>
                        ) : null}
                        <div
                            className="help_video_button2"
                            onClick={() => {
                                setVideoURL({ url: '', title: '' })
                                setVideoLoading(true)
                                setPlayerSize(PlayerSize.small)
                            }}
                        >
                            <CloseIconWithCircle />
                        </div>
                    </div>
                </div>
                {videoLoading && playerSize !== PlayerSize.minimized ? <Loader.PopupLoader show={true} /> : null}

                {/* {playerSize !== PlayerSize.minimized ? ( */}
                <ReactPlayer
                    url={videoURL.url}
                    className="react-player"
                    onReady={() => setVideoLoading(false)}
                    playing={playerSize !== PlayerSize.minimized}
                    style={{ display: playerSize !== PlayerSize.minimized ? 'block' : 'none' }}
                    width="100%"
                    height="100%"
                    controls={true}
                    config={{
                        vimeo: {
                            playerOptions: {
                                url: videoURL.url,
                                autoplay: true,
                                pip: false,
                                responsive: false
                            }
                        }
                    }}
                />
                {/* ) : null} */}
            </ResizablePIP>
        </div>
    ) : null
}

export const defaultVideoData = {
    videoURL: { url: '', title: '' },
    setVideoURL: (value: { id: string; title: string }) => {},
    videosList: [] as Videos[],
    refetchVideos: () => {}
}

export const VideoContext = React.createContext(defaultVideoData)

export const useVideoUrl = (internalId: string) => {
    const videoContext = React.useContext(VideoContext)

    // console.log('internalId', videoContext)
    const videoToPlace = videoContext.videosList.find(x => x.internalId === internalId)

    const showVideo = () => {
        if (videoToPlace) {
            videoContext.setVideoURL({ id: videoToPlace.externalId, title: videoToPlace.videoName })
        }
    }

    return [videoToPlace, showVideo] as [Videos | undefined, () => void]
}
