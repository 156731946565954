import { ToolBox } from '@/common/components/Card';
import { Modal } from '@/common/components/Modal';
import {
  AddIcon,
  ArrowIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  FolderColorIcon,
  InfoColorIcon,
  PdfFileIcon,
  SearchIcon,
  UplaodIcon,
  VideoIcon,
  WebsiteKBSideIcon,
  WordFileIcon,
} from '@/common/Icons/Icons';
import {
  EmptyDiv,
  Header,
  HeaderLeft,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import knowledgeEmptyImg from '@/common/images/no_kb.svg';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { TabelWrapper } from '@/common/styled/Dialogue.Dumb';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import {
  ButtonContainer,
  HeaderRight,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
} from '@/common/styled/Workflow.Dumb';
import React, { useEffect, useState } from 'react';
import { useDeleteConnection, useKBCategory, useSaveConnection, useUpdateWebsite, useWebsiteTrainMode } from './KnowledgeApis';
import { IsValidURL, KnowledgeBase, Mode, Output, UpdatewebsiteResponse } from './MockFiles';
import { Header as PageHeader, HeaderChild } from '@/common/components/Header';
import styled from 'styled-components';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import ImgLoader from '@/common/images/loading.svg';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import { getJson, knowledgeApiUrl, stripeGetApi } from '@/common/utils/api-utils';
import { Loader } from '@/common/components/Loader';
import { repeat } from 'ramda';
import { useQuery } from 'react-query';
import { KnowledgeAIUpgrade } from './KnowledgeUpgrade';
import { AssistantSubHeader } from '../BotHome/PortalElement';
const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;
`;

const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;
export function KnowledgeWebsiteAi(props: any) {
  const workspace: string = props.match.params.workspacename;

  const [searchWord, setSearchWord] = useState<string>('');
  const { kbCategorydata } = useKBCategory(workspace);

  const website: Output[] | [] =
    kbCategorydata && kbCategorydata.length > 0
      ? kbCategorydata.filter((kb) => kb.group === 'WEBSITE')
      : [];
  const websiteArray: KnowledgeBase[] | [] = website.length>0 ? website[0].knowledgeBase: [];
  const renderNodes =
    websiteArray && searchWord !== ''
      ? websiteArray.filter((micro) =>
          micro.name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : websiteArray;
  console.log(renderNodes, 'renderNodes');
  const [name, setName] = useState<string>('');
  const [inputUrl, setInputUrl] = useState<string[]>(['']);
  const [status, setStatus] = useState<string>('');
  const [pageState, setpageState] = useState<Mode>(Mode.View);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const inputURLValid = inputUrl.map((url) => IsValidURL(url));
  
  const checkUrlValid = (urlArray:string[])=>{
    return urlArray.map((url) => IsValidURL(url))
  }
  const [selectedID, setSelectedID] = useState<string>('');
  const [trainMode, setTrainMode] = useState<string>('');
  const [updateConnectionError,setUpdateConnectionError] =useState<boolean>(false)
  console.log(updateConnectionError,"updateConnectionError")
  const [popUpState, setPopupState] = useState<{
    selectedConnection: UpdatewebsiteResponse[];
    clonedConnection: UpdatewebsiteResponse[];
  }>({
    selectedConnection: [],
    clonedConnection: [],
  });
  const getConnection = () => {
    getJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${selectedID}`)).then(
      (data) => {
        if (data.type === 'success') {
          const output = [data.output];
          setPopupState({
            ...popUpState,
            selectedConnection: JSON.parse(JSON.stringify(output)),
            clonedConnection: JSON.parse(JSON.stringify(output)),
          });
        }
      }
    ).catch((err)=>setUpdateConnectionError(true));
  };

  useEffect(() => {
    if (selectedID !== '' && pageState === 'edit') {
      getConnection();
    }
  }, [pageState]);
  const gettingURLArray = (array:any)=>{
    const newArray = array.map(obj=> obj.seedUrl)
    return newArray
  }
  const checkingName: KnowledgeBase[] | [] =
    websiteArray && websiteArray.length > 0
      ? websiteArray.filter(
          (arr) => arr.name.toLowerCase() === name.toLowerCase()
        )
      : [];

  const onCancel = () => {
    setName('');
    setInputUrl(['']);
    setStatus('');
    setpageState(Mode.View);
    setUpdateConnectionError(false)
    setPopupState({
      selectedConnection: [],
      clonedConnection: [],
    })
  };
  const { saveConmutate } = useSaveConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    },
    ()=>{
      setStatus("loader")
    }
  );
  const {updateWebsiteapi}= useUpdateWebsite(() => {
    setpageState(Mode.View);
  },
  () => {
    setError(true);
  })
  const { deleteConnMutate } = useDeleteConnection(
    () => {
      setpageState(Mode.View);
    },
    () => {
      setError(true);
    }
  );
  const handleConnectionEdit = (
    value: any,
    id: string,
    property: string,
    obj?: string
  ) => {
    const index = popUpState.selectedConnection.findIndex((el) => el.id === id);
    const data = [...popUpState.selectedConnection];
    if (obj === 'credential') {
      data[index].credential[property] = value;
    } else if (obj === 'repository') {
      data[index].repository[property] = value;
    } else {
      data[index][property] = value;
    }
    setPopupState({ ...popUpState, selectedConnection: data });
  };
  const handleRepositoryChange = (
    index: number,
    event: { target: { value: string } },
    id: string,
    prop: string
  ) => {
    const { value } = event.target;
    const rep = popUpState.selectedConnection[0].repository;
    const list = gettingURLArray(popUpState.selectedConnection[0].repository.seedUrlConnections)
    list[index] = value;
    const newObj = converttoObj(list)
    handleConnectionEdit(newObj, id, 'seedUrlConnections', 'repository')
    // handleConnectionEdit(list, id, prop, 'repository');
  };
  const [error, setError] = useState<boolean>(false);
  const removeSiteUrl = (index: number, id: string) => {
    const rows = gettingURLArray(popUpState.selectedConnection[0].repository.seedUrlConnections);
    rows.splice(index, 1);
    const newObj = converttoObj(rows)
    rows.length === 0
      ? handleConnectionEdit(newObj, id, 'seedUrlConnections', 'repository')
      : handleConnectionEdit(newObj, id, 'seedUrlConnections', 'repository');
  };
  const { webSitetrainMutate } = useWebsiteTrainMode(
    () => {
      setpageState(Mode.View);
    },
    () => {
      setError(true);
    }
  );
  const TrainingMode = () => {
    webSitetrainMutate({ workspace, selectedID });
  };
  const statusCheck = (status: string) => {
    switch (status) {
      case 'CREATED':
        return 'Train';
      case 'DELETION_INITIATED':
        return 'Deleting';
      case 'SYNCING':
        return 'Training';
      case 'SYNCING_INDEXING':
        return 'Training';
      case 'FAILED':
        return 'Retry';
      case 'INCOMPLETE':
        return 'Retry';
      case 'CREATION_INITIATED':
        return 'Creating';
      default:
        return 'Train';
    }
  };
  const converttoObj = (array:string[])=>{
    return array.map(url=>{
        return ({
            seedUrl:url
        })
    })
  }
  console.log(status,"chelcknalkcas")
  const connectingWebsite = () => {
    const urlConnection =converttoObj(inputUrl)
    console.log(urlConnection,"urlConnection")
    const connectionData = {
      name: name,
      category: 'WEBCRAWLER',
      template: {
        type: 'WEBCRAWLERV2',
        syncMode: 'FORCED_FULL_CRAWL',
        credentialDomainRefId: '0099935a-e075-4001-b80d-26c9b9a0adcd',
        repository: {
          seedUrlConnections: urlConnection
        },
      },
    };
    if (inputURLValid.includes(false) ||
      checkingName.length > 0 ||
      !checkingDuplicate(inputUrl)) {
      setStatus('Error');
    } else {
      saveConmutate({ workspace, connectionData });
    }
  };

  const updateWebsite = () => {
    const data = popUpState.selectedConnection[0];
    const id= data.id
    const filteredSiteUrls = gettingURLArray(data.repository.seedUrlConnections).filter((f) => f !== '');
    const newObj =converttoObj(filteredSiteUrls)
    const connectionData = {
      name: popUpState.selectedConnection[0].name,
      category: 'WEBCRAWLER',
      template: {
        type: 'WEBCRAWLERV2',
        syncMode: 'FORCED_FULL_CRAWL',
        credentialDomainRefId: '0099935a-e075-4001-b80d-26c9b9a0adcd',
        repository: {
          seedUrlConnections: newObj,
        },
      },
    };
    if (checkUrlValid(gettingURLArray(data.repository.seedUrlConnections)).includes(false) ||
      checkingName.length > 0 ||
      !checkingDuplicate(gettingURLArray(data.repository.seedUrlConnections))) {
      setStatus('Error');
      console.log("errorinupdate")
    } else {
      updateWebsiteapi({ workspace, connectionData,id});
    }
  };
  const addInputUrl = () => {
    setInputUrl([...inputUrl, '']);
  };
  const changeUrl = (index: number, event: { target: { value: string } }) => {
    const { value } = event.target;
    const list = [...inputUrl];
    list[index] = value;
    setInputUrl(list);
  };
  const addSiteUrl = (id: string) => {
    const rep =gettingURLArray(popUpState.selectedConnection[0].repository.seedUrlConnections);
    const list = [...rep, ''];
    const newObj = converttoObj(list)
    handleConnectionEdit(newObj, id, 'seedUrlConnections', 'repository');
  };
  const removeinputUrl = (index: number) => {
    const rows = [...inputUrl];
    rows.splice(index, 1);
    rows.length === 0 ? setInputUrl(['']) : setInputUrl(rows);
  };

  const checkingDuplicate = (arr: string[]) => {
    return arr.length === new Set(arr).size;
  };
  const checkingPlanAccess = useQuery(
    'users::knowledge-access',
    () => stripeGetApi(`${workspace}/info/KB_ACCESS`),
    {
      onSuccess: (response) => {
        //console.log(response,'response')
      },
      staleTime: 0,
      refetchOnMount: true,
    }
  );
  const planAllowed = 
  checkingPlanAccess.isSuccess && checkingPlanAccess.data.output == 'yes';
  return (
    <>
    <AssistantSubHeader>
               {/* {websiteArray && websiteArray.length < 5 && ( */}
                <Button
                  style={{ marginLeft: 10 }}
                  primary
                  onClick={() => {
                    setpageState(Mode.Add);
                  }}
                  className="website_link_btn"
                >
                  <span className="add_teams_header">
                    <AddIcon />
                  </span>
                  <label style={{ top: '1.5px' }}>Add Connection</label>
                </Button>
              {/* )} */} 
            </AssistantSubHeader>
      {/* <PageHeader>
        {[
          <>
            <HeaderChild type="first">
              <span
                className="buton_back inner_pages"
                onClick={() => window.history.back()}
              >
                <ArrowIcon />
                <span>Back</span>
              </span>
              <div className="indent_left">
                <div className="indent_title">Website KB</div>
                <p className="content_header">
                  Connections (
                  {websiteArray && websiteArray.length > 0
                    ? websiteArray.length
                    : 0}
                  )
                </p>
              </div>
                <Button
                  style={{ marginLeft: 10 }}
                  primary
                  onClick={() => {
                    setpageState(Mode.Add);
                  }}
                >
                  <span className="add_teams_header">
                    <AddIcon />
                  </span>
                  <label style={{ top: '1.5px' }}>Add Connection</label>
                </Button>
            </HeaderChild>
            <HeaderChild type="second">
              {!inputFocus ? (
                <span className="search_icon_svg">
                  <input
                    type="search"
                    placeholder="Search"
                    value=""
                    onFocus={() => setInputFocus(true)}
                  />
                  <SearchIcon />
                </span>
              ) : (
                <span className="search_click">
                  <input
                    className="search_click"
                    type="search"
                    placeholder="Search"
                    value={searchWord}
                    autoFocus={true}
                    onChange={(e) => setSearchWord(e.target.value)}
                  />
                  <span
                    onClick={() => {
                      setSearchWord('');
                      setInputFocus(false);
                    }}
                  >
                    <CloseIcon />
                  </span>
                </span>
              )}
            </HeaderChild>
          </>,
        ]}
      </PageHeader> */}
      {!planAllowed ? (
          <KnowledgeAIUpgrade {...props}/>
        ) : pageState === 'view' && websiteArray && websiteArray.length === 0 ? (
          <section className="knowledge_container website_link">
            <div className="knowledge_content">
              <div>
                <img src={knowledgeEmptyImg} alt="Knowledge Ai img" />
              </div>
              <div>
                <p>Generate answers from Website.</p>
                <p>Empty as wind. Connect with your website.</p>
              </div>

              
              {/* <InputContainer style={{ marginBottom: 10 }}>
                <Input
                  primary
                  type="text"
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
                <InputContent>Website URL</InputContent>
                {!IsValidURL(url) && status === 'error' && (
                  <p className="valid_url_alert">Provide a Valid URL</p>
                )}
              </InputContainer> */}

              <Button
                className="btn btn-primary"
                onClick={() => {
                  setpageState(Mode.Add);
                }}
              >
                Add Connection
              </Button>
            </div>
            <div className="knowledge_video">
              <span>
                <VideoIcon />
              </span>
            </div>
          </section>
        ) : (
            <>
          <section className="entity_cards externalKB externalKB_home weblink">
            <div className="col-md-12">
              <div className="row card_grid_view">
                {websiteArray &&
                  renderNodes.map((data, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                      <div
                        className={
                          data.status === 'DELETION_INITIATED'
                            ? 'col-lg-3 col-md-3 col-sm-3 dialog_cards_layer deleting_box'
                            : 'col-lg-3 col-md-3 col-sm-3 dialog_cards_layer'
                        }
                      >
                        <div className="externalKB_menu_box">
                          {data.status !== 'DELETION_INITIATED' && (
                            <SmallButton
                              className={
                                data.status === 'DELETION_INITIATED' ||
                                data.syncHistory[0]?.status === 'SYNCING' ||
                                data.syncHistory[0]?.status ===
                                  'SYNCING_INDEXING' ||
                                data.status === 'CREATION_INITIATED'
                                  ? 'training_btn'
                                  : data.syncHistory[0]?.status === 'FAILED' ||
                                    data.syncHistory[0]?.status === 'INCOMPLETE'
                                  ? 'retry_btn'
                                  : 'train_btn'
                              }
                              primary
                              style={{ minWidth: 90 }}
                              disable={
                                data.status === 'DELETION_INITIATED' ||
                                data.status === 'CREATION_INITIATED' ||
                                data.syncHistory[0]?.status === 'SYNCING' ||
                                data.syncHistory[0]?.status ===
                                  'SYNCING_INDEXING'
                              }
                              onClick={() => {
                                if (
                                  (data.status !== 'DELETION_INITIATED' &&
                                    data.status !== 'CREATION_INITIATED' &&
                                    data.syncHistory[0]?.status !==
                                      'SYNCING') ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING_INDEXING'
                                ) {
                                  setpageState(Mode.Train);
                                  setSelectedID(data.id);
                                }
                              }}
                            >
                              <label>
                                {data.status === 'DELETION_INITIATED' ||
                                data.status === 'CREATION_INITIATED'
                                  ? statusCheck(data.status)
                                  : data.syncHistory.length === 0
                                  ? statusCheck(data.status)
                                  : statusCheck(data.syncHistory[0].status)}
                                {(data.status === 'DELETION_INITIATED' ||
                                  data.status === 'CREATION_INITIATED' ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING_INDEXING' ||
                                  data.syncHistory[0]?.status ===
                                    'SYNCING') && (
                                  <div className="publish_button_dialog btn_loader">
                                    <img src={ImgLoader} />
                                  </div>
                                )}
                                {data.status !== 'DELETION_INITIATED' &&
                                  data.status !== 'CREATION_INITIATED' &&
                                  data.syncHistory.length > 0 &&
                                  statusCheck(data.syncHistory[0].status) ===
                                    'Retry' && (
                                    <div className="retry_btn_icon">
                                      {/* <Tooltip
                                          className="target customTip"
                                          zIndex={10000}
                                          arrowSize={8}
                                          tagName="span"
                                          content={data.errorMessage}
                                          distance={5}
                                      > */}
                                      <WarningAlertIcon />

                                      {/* </Tooltip> */}
                                    </div>
                                  )}
                              </label>
                            </SmallButton>
                          )}
                          {data.status !== 'DELETION_INITIATED' &&
                            data.status !== 'CREATION_INITIATED' &&
                            data.syncHistory[0]?.status !== 'SYNCING' &&
                            data.syncHistory[0]?.status !==
                              'SYNCING_INDEXING' && (
                              <div className="externalKB_menu_tools">
                                <div
                                  onClick={() => {
                                    setpageState(Mode.Edit);
                                    setSelectedID(data.id);
                                  }}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  onClick={() => {
                                    setSelectedID(data.id);
                                    setpageState(Mode.Delete);
                                  }}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            )}
                        </div>
                        <div
                          className="externalKB_title_box sharepoint_box website_link"
                          onClick={() => {
                            if (
                              data.status !== 'DELETION_INITIATED' &&
                              data.status !== 'CREATION_INITIATED' &&
                              data.syncHistory[0]?.status !== 'SYNCING' &&
                              data.syncHistory[0]?.status !== 'SYNCING_INDEXING'
                            ) {
                              setpageState(Mode.Edit);
                              setSelectedID(data.id);
                            }
                          }}
                        >
                          <div className="externalKB_title">
                            <div className="externalKB_title_logo">
                              {<WebsiteKBSideIcon />}
                              {/* {data.type === 'SHAREPOINT_ONLINE' ?  <SharePointIcon /> : <span> <ConfluenceIcon /></span> } */}
                              {/* <SharePointIcon /> */}
                            </div>
                            <div className="externalKB_title_head">
                              <h4>{data.name}</h4>
                              <p>
                                <span>Language:</span>{' '}
                                <span>English (United States)</span>
                              </p>
                            </div>
                          </div>
                          <div className="externalKB_title_count_section flex">
                           {/* <h4>Website URL</h4>
                           <p></p> */}
                          </div>

                          <div className="externalKB_status_box flex">
                            <div className="externalKB_status_title flex">
                              <h5>Last Updated:</h5>
                              <div className="error_icon_wrapper_left">
                                <span className="entity_span">
                                  {data.updatedAt
                                    ? formatDateInCard(data.updatedAt)
                                    : '--'}
                                </span>
                                <span className="entity_span">|</span>
                                <span className="entity_span">
                                  {data.updatedAt
                                    ? formatAMPM(data.updatedAt)
                                    : '--'}
                                </span>
                              </div>
                            </div>
                            <div className="externalKB_status_title flex">
                              <h5>Last Trained:</h5>
                              <p>
                                <span className="entity_span">
                                  {data.syncHistory &&
                                  data.syncHistory.length === 0
                                    ? '--'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1
                                      ? formatDateInCard(
                                          data.syncHistory[1].endTime
                                        )
                                      : '--'
                                    : formatDateInCard(
                                        data.syncHistory[0].endTime
                                      )}
                                </span>
                                <span className="entity_span">|</span>
                                <span
                                  className="entity_span"
                                  style={{ color: '#355D8B' }}
                                >
                                  {data.syncHistory &&
                                  data.syncHistory.length === 0
                                    ? '--'
                                    : data.syncHistory[0].status ===
                                        'SYNCING' ||
                                      data.syncHistory[0].status ===
                                        'SYNCING_INDEXING'
                                    ? data.syncHistory.length > 1
                                      ? formatAMPM(data.syncHistory[1].endTime)
                                      : '--'
                                    : formatAMPM(data.syncHistory[0].endTime)}
                                </span>
                              </p>
                            </div>
                            <div className="externalKB_status_title flex">
                              <h5>Trained History:</h5>
                              <p>
                                <u
                                  className={
                                    data.syncHistory.length === 0
                                      ? 'editor_btn_disabled'
                                      : ''
                                  }
                                  onClick={() =>
                                    props.history.push(
                                      `/workspace/${workspace}/assistant/knowledge/website-kb/${data.id}/syncsummary`
                                    )
                                  }
                                >
                                  View History
                                </u>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {data.status === 'DELETION_INITIATED' && (
                        <div className="deleting_btn">
                          <SmallButton>
                            <label>
                              Deleting{' '}
                              <span>(It will take more than 10 mins)</span>
                              <div className="publish_button_dialog btn_loader">
                                <img src={ImgLoader} />
                              </div>
                            </label>
                          </SmallButton>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </section>
          {pageState === 'add' && (
            <Wrapper>
              <PopupWrapper>
                <PopupContainer>
                  <Header>
                    <HeaderLeft minWidth={300}>Website KB Configuration</HeaderLeft>
                    <HeaderRight></HeaderRight>
                    <span
                      onClick={() => {
                        onCancel();
                      }}
                      className="slack_close_btn weblink_close_btn"
                    >
                      <CloseIcon />
                    </span>
                  </Header>
                  <Paragraph>Create a new connection.</Paragraph>
                  <PopupScrollHeight>
                    <EmptyDiv>
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            onChange={(e: {
                              target: { value: React.SetStateAction<string> };
                            }) => {
                              setName(e.target.value);
                            }}
                          />
                          <InputContent>Connection name</InputContent>
                        </InputContainer>
                        {checkingName.length > 0 && status === 'error' && (
                          <p className="valid_url_alert">
                            Connection Name has to be unique
                          </p>
                          // </div>
                        )}
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Url{' '}
                          </Header>
                        </div>
                        <OptionalInput>
                          {inputUrl &&
                            inputUrl.map((data, index) => (
                              <>
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="url"
                                    onChange={(evnt: {
                                      target: { value: string };
                                    }) => changeUrl(index, evnt)}
                                    value={data}
                                    name="url"
                                  />
                                  <InputContent>
                                    Website URL {index + 1}
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() => removeinputUrl(index)}
                                  >
                                    <CloseIcon />
                                  </span>
                                  {/* </div> */}
                                </InputContainer>
                                {!IsValidURL(data) && status === 'error' && (
                                  <p className="valid_url_alert">
                                    Provide a Valid URL
                                  </p>
                                )}
                              </>
                            ))}
                        </OptionalInput>
                        <SmallButton
                          primary
                          style={{ marginBottom: 20 }}
                          onClick={addInputUrl}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                      </BgWrapper>
                      <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                        <div className="popup_footer_button_container">
                          <Button
                            type="button"
                            className={
                              name !== '' &&
                              inputUrl.length > 0 &&
                              inputUrl[0].length > 0 &&
                              status !== 'error'
                              && !inputURLValid.includes(false)
                                ? // !state.disableSave
                                  ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => {
                              connectingWebsite();
                            }}
                          >
                            <label> Save </label>
                          </Button>
                          {status === 'loader' && (
                            <span className="connect_account_loading automate_connect_account_loading">
                              <img src={LoadingSvgImage} alt="" />
                            </span>
                          )}
                          {/* {status === 'Error' && (
                            <span
                              className={
                                checkingDuplicate(inputUrl) &&
                                !inputURLValid.includes(false) &&
                                checkingName.length === 0
                                  ? 'connect_account_failed hide'
                                  : 'connect_account_failed'
                              }
                            >
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )} */}
                          {status === 'apiError' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                          {status === 'Error' && !checkingDuplicate(inputUrl) &&  (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Urls must be unique
                                </span>
                              </div>
                            </span>
                          )}
                          <Button
                            primary
                            type="button"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            <label> Cancel</label>
                          </Button>
                        </div>
                      </ButtonContainer>
                    </EmptyDiv>
                    <div id="scrollIn"></div>
                  </PopupScrollHeight>
                </PopupContainer>
              </PopupWrapper>
            </Wrapper>
          )}
          {pageState === "edit" && (
            <Wrapper>
              <PopupWrapper className="edit_btn website">
                <PopupContainer>
                  <Header>
                    <HeaderLeft minWidth={300}>Website KB Configuration</HeaderLeft>
                    <HeaderRight></HeaderRight>
                    <span
                      onClick={() => {
                        onCancel();
                      }}
                      className="slack_close_btn weblink_close_btn"
                    >
                      <CloseIcon />
                    </span>
                  </Header>
                  <Paragraph>Update the connection.</Paragraph>
                  {updateConnectionError ? 
                  (<div>
                    Error in getting the KnowledgeBase value
                  </div>) :
                  (popUpState.selectedConnection.length === 0 ? (
                    <PopupScrollHeight>
                      <div className="popup_loader">
                        <Loader.PopupLoader show={true} />
                      </div>
                    </PopupScrollHeight>
                  ) : (
                    popUpState.selectedConnection.map((data) => (
                  <PopupScrollHeight>
                    <EmptyDiv>
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            value = {data.name}
                            onChange={(e: {
                              target: { value: React.SetStateAction<string> };
                            }) => {
                                handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'name'
                                )
                            }}
                          />
                          <InputContent>Connection name</InputContent>
                        </InputContainer>
                        {checkingName.length > 0 && status === 'error' && (
                          <p className="valid_url_alert">
                            Connection Name has to be unique
                          </p>
                          // </div>
                        )}
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Url{' '}
                          </Header>
                        </div>
                        <OptionalInput>
                          {console.log(gettingURLArray(data.repository?.seedUrlConnections),data.repository?.seedUrlConnections && data.repository?.seedUrlConnections[0].seedUrl ,"aslkjhflkasd")}
                          {data.repository?.seedUrlConnections && 
                          // data.repository?.seedUrlConnections[0].seedUrl &&
                            gettingURLArray(data.repository?.seedUrlConnections).map((rep:string, index:number) => (
                                <div className="knowledge_domain_url_box">
                                  {console.log(rep,"aslkjhflkasd")}
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="url"
                                    onChange={(evnt: {
                                      target: { value: string };
                                    }) =>
                                      handleRepositoryChange(
                                        index,
                                        evnt,
                                        data.id,
                                        'siteUrls'
                                      )
                                    }
                                    value={rep}
                                    name="url"
                                  />
    
                                  <InputContent>
                                    Website URL {index + 1}
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() =>
                                      removeSiteUrl(index, data.id)
                                    }
                                  >
                                    <CloseIcon />
                                  </span>
                                </InputContainer>
                                {!IsValidURL(rep) && status === 'error' && (
                                  <p className="valid_url_alert">
                                    Provide a Valid URL
                                  </p>
                                )}
                              </div>
                            ))}
                        </OptionalInput>
                        <SmallButton
                          primary
                          style={{ marginBottom: 20 }}
                          onClick={()=>addSiteUrl(data.id)}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                      </BgWrapper>
                      <ButtonContainer className="footer_button_fixed knowledge_ms_btn website_kb">
                        <div style={{gap: '0 20px'}} className="popup_footer_button_container">
                          <Button
                            type="button"
                            className={
                              data.name !== '' &&
                              data.repository.seedUrlConnections.length > 0 &&
                              data.repository.seedUrlConnections[0].seedUrl !== "" &&
                              status !== 'error' && !checkUrlValid(gettingURLArray(data.repository.seedUrlConnections)).includes(false)
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => {
                                updateWebsite();
                            }}
                          >
                            <label> Update </label>
                          </Button>
                          {status === 'loader' && (
                            <span className="connect_account_loading automate_connect_account_loading">
                              <img src={LoadingSvgImage} alt="" />
                            </span>
                          )}
                          {/* {status === 'error' && (
                            <span
                              className={
                                checkingDuplicate(inputUrl) &&
                                !inputURLValid.includes(false) &&
                                checkingName.length === 0
                                  ? 'connect_account_failed hide'
                                  : 'connect_account_failed'
                              }
                            >
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )} */}
                          {status === 'apiError' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                          {status === "Error" && !checkingDuplicate(gettingURLArray(popUpState.selectedConnection[0].repository.seedUrlConnections))&& (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Urls must be unique
                                </span>
                              </div>
                            </span>
                          )}
                          <Button
                            primary
                            type="button"
                            onClick={() => {
                              onCancel();
                            }}
                            style={{margin: 0}}
                          >
                            <label> Cancel</label>
                          </Button>
                        </div>
                      </ButtonContainer>
                    </EmptyDiv>
                    <div id="scrollIn"></div>
                  </PopupScrollHeight>
                    ))
                  ))}
                </PopupContainer>
              </PopupWrapper>
            </Wrapper>
          )}
          {pageState === 'train' &&
                  <Modal className="knowledge_container_popup externalKB">
                    <section className="knowledge_container_section externalKB_popup">
                      <h4>Train Mode</h4>
                      <p>
                        Choose how you want to update your index when your data
                        source content changes.
                      </p>
                      <div className="externalKB_option">
                        <input
                          type="radio"
                          name="trainmode"
                          className="option-input radio"
                          onClick={() => setTrainMode('FORCED_FULL_CRAWL')}
                        />
                        <div>
                          <h6>Full train</h6>
                          <p>
                            Train and index all contents in all entities, regardless
                            of the previous trained status.
                          </p>
                        </div>
                      </div>
                      <ButtonContainer
                        style={{
                          padding: 0,
                          justifyContent: 'flex-start',
                          marginTop: 5,
                        }}
                      >
                        <div className="popup_footer_button_container">
                          <Button
                            primary
                            type="button"
                            data-dismiss="modal"
                            onClick={() => {
                              onCancel();
                              setpageState(Mode.View);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={
                              trainMode !== '' ? '' : 'editor_btn_disabled'
                            }
                            type="button"
                            data-dismiss="modal"
                            onClick={() => TrainingMode()}
                          >
                            Train
                          </Button>
                          {error && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                        </div>
                      </ButtonContainer>
                    </section>
                  </Modal>
                }
                </>
        )}
        {pageState === 'delete' && (
            <Modal className="knowledge_container_popup externalKB delete_popup">
              <div className="info_icon">
                <InfoColorIcon />
              </div>
              <h2>Remove Connection?</h2>
              <p>Connection will be removed along with the trained data and can't be undone.</p>{' '}
              <ButtonContainer
                className="knowledge_ms_btn"
                style={{ padding: 0, marginTop: 5 }}
              >
                <div className="popup_footer_button_container">
                  <Button
                    primary
                    type="button"
                    data-dismiss="modal"
                    onClick={() => {
                      setpageState(Mode.View);
                      onCancel()
                      // setState(initialState);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={() => deleteConnMutate({ workspace, selectedID })}
                  >
                    Yes
                  </Button>
                  {error && (
                    <span className="connect_account_failed">
                      <div className="error_input_show error_input_show_add teams_page_alert">
                        <WarningAlertIcon />
                        <span className="error_menu_hvr">
                          Something went wrong
                        </span>
                      </div>
                    </span>
                  )}
                </div>
              </ButtonContainer>
            </Modal>
          )}
      
      <Modal className="knowledge_container_popup hide">
        <section className="knowledge_container_section">
          <h4>Upload Knowledge Article</h4>
          <div className="knowledge_upload">
            <span>
              <UplaodIcon />
            </span>
            <h4>Drag and drop your PDF/Word file to upload</h4>
            <p>or</p>
            <span>
              <input type="file" className="default-file-input" />
              <span className="knowledge_upload_text">Browse</span>
            </span>
          </div>
          <ButtonContainer style={{ padding: 0 }}>
            <div className="popup_footer_button_container">
              <Button
                // className={false ? 'ditor_btn_disabled' : ''}
                type="button"
                data-dismiss="modal"
              >
                Upload
              </Button>
              <Button primary type="button" data-dismiss="modal">
                Cancel
              </Button>
            </div>
          </ButtonContainer>
        </section>
      </Modal>

      <section className="entity_cards knowledgeAi_table hide">
        <h3>IT Support 1</h3>
        <div className="col-md-12">
          <div className="row">
            <TabelWrapper>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th size="2.5">Name</Th>
                      <Th size="3">Description</Th>
                      <Th size="2">Modified</Th>
                      <Th size="2.5">Modified by</Th>
                      <Th size="1">File size </Th>
                      <Th size="1">Option </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td size="2.5" className={'list_flow_hr'}>
                        <div className="knowledge_table_name">
                          <div>
                            <WordFileIcon />
                          </div>
                          <div>IT Support Document</div>
                        </div>

                        <label>
                          <input type="radio" className="option-input radio" />
                        </label>
                      </Td>
                      <Td size="3" className="list_flow_p">
                        <p className="listite_centre">
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                        </p>
                      </Td>
                      <Td size="2">28 Aug 2020</Td>
                      <Td size="2.5" className="list_flow_span">
                        <span className="entity_span">
                          katherine.s@acmecorp.com
                        </span>
                      </Td>
                      <Td size="1" className="list_flow_span">
                        394 KB
                      </Td>
                      <Td size="1">
                        <ToolBox></ToolBox>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td size="2.5" className={'list_flow_hr'}>
                        <div className="knowledge_table_name">
                          <div>
                            <FolderColorIcon />
                          </div>
                          <div>IT Support Document</div>
                        </div>

                        <label>
                          <input type="radio" className="option-input radio" />
                        </label>
                      </Td>
                      <Td size="3" className="list_flow_p">
                        <p className="listite_centre">
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                        </p>
                      </Td>

                      <Td size="2">28 Aug 2020</Td>
                      <Td size="2.5" className="list_flow_span">
                        <span className="entity_span">
                          katherine.s@acmecorp.com
                        </span>
                      </Td>
                      <Td size="1" className="list_flow_span">
                        394 KB
                      </Td>
                      <Td size="1">
                        <ToolBox></ToolBox>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td size="2.5" className={'list_flow_hr'}>
                        <div className="knowledge_table_name">
                          <div>
                            <PdfFileIcon />
                          </div>
                          <div>IT Support Document</div>
                        </div>

                        <label>
                          <input type="radio" className="option-input radio" />
                        </label>
                      </Td>
                      <Td size="3" className="list_flow_p">
                        <p className="listite_centre">
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                          Description comes here Description comes here
                        </p>
                      </Td>
                      <Td size="2">28 Aug 2020</Td>
                      <Td size="2.5" className="list_flow_span">
                        <span className="entity_span">
                          katherine.s@acmecorp.com
                        </span>
                      </Td>
                      <Td size="1" className="list_flow_span">
                        394 KB
                      </Td>
                      <Td size="1">
                        <ToolBox></ToolBox>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabelWrapper>
          </div>
        </div>
      </section>
    </>
  );
}
