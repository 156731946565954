import { AuthType, getParsedToken } from '@/common/utils/auth-token-api-utils';


export function mfaScreening(workspace: string) {
  const workspaceName = workspace;
  // const expiredToken =
  // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzksImVtYWlsIjoibWFuanVsYS5tQHNraXR0ZXIuaW4iLCJ3c3MiOlt7ImlkIjoxNzUsIm5hbWUiOiJ2aW5vZGRldiIsIm93bmVySWQiOjM2fSx7ImlkIjoxNzcsIm5hbWUiOiJtYW5qdWxhd29ya3NwYWNlIiwib3duZXJJZCI6Mzl9LHsiaWQiOjE4OSwibmFtZSI6InNraXR0ZXIiLCJvd25lcklkIjozMn1dLCJhY2wiOnsidmlub2RkZXYiOnsiQ0hBVF9CT1RfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJUUkFJTklOR19NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIkJPVF9NQVJLRVRfUExBQ0UvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIkVOR0lORS9QVUJMSUNfQk9UIjoiUkVBRC5XUklURS5ERUxFVEUiLCJXT1JLRkxPV19NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIldPUktGTE9XX01BUktFVF9QTEFDRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiVVNFUlNfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUifSwibWFuanVsYXdvcmtzcGFjZSI6eyJDSEFUX0JPVF9NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIlRSQUlOSU5HX01PRFVMRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiQk9UX01BUktFVF9QTEFDRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiRU5HSU5FL1BVQkxJQ19CT1QiOiJSRUFELldSSVRFLkRFTEVURSIsIldPUktGTE9XX01PRFVMRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiV09SS0ZMT1dfTUFSS0VUX1BMQUNFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJVU0VSU19NT0RVTEUvKioiOiJSRUFELldSSVRFLkRFTEVURSJ9LCJza2l0dGVyIjp7IkNIQVRfQk9UX01PRFVMRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIiwiVFJBSU5JTkdfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJCT1RfTUFSS0VUX1BMQUNFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJFTkdJTkUvUFVCTElDX0JPVCI6IlJFQUQuV1JJVEUuREVMRVRFIiwiV09SS0ZMT1dfTU9EVUxFLyoqIjoiUkVBRC5XUklURS5ERUxFVEUiLCJXT1JLRkxPV19NQVJLRVRfUExBQ0UvKioiOiJSRUFELldSSVRFLkRFTEVURSIsIlVTRVJTX01PRFVMRS8qKiI6IlJFQUQuV1JJVEUuREVMRVRFIn19LCJpYXQiOjE2ODQ0MDA0MDIsImV4cCI6MTY4NDQwMTMwMn0.HJDHfTVtsF8jY_xYa8j475bPWPSKjGIEW4Ry-VT4Qoc'
  // // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzcsImVtYWlsIjoic2hyaS5naGF5YXRocmlAc2tpdHRlci5pbiIsIndzcyI6W3siaWQiOjE3NiwibmFtZSI6Im5pcnVwIiwib3duZXJJZCI6Mzd9LHsiaWQiOjE4OSwibmFtZSI6InNraXR0ZXIiLCJvd25lcklkIjozMn1dLCJpYXQiOjE1OTcxNDg3OTksImV4cCI6MTU5NzE0OTY5OX0.qAb-y9x5NduqCKmoUiGBsnhueJbEzuS63RnW-SWX86U'

  // const decoded = jwt_decode(expiredToken)
  // console.log(decoded,workspacename,'decodedtoken')

  const decodedToken = getParsedToken();
  // TODO :: Handle error if no token. But the case is alomost impossible
  if (decodedToken === null) {
    return AuthType.NoToken;
  }

  const workSpaceinList = decodedToken['wss'].filter(
    (a) => a.name === workspaceName
  );
  const mfaNeeded = workSpaceinList[0].is_mfa_active;
  const checkAuthenticated = (decodedToken: Record<string, any>) => {
    if (
      Object.keys(decodedToken).includes('mfa') &&
      decodedToken['mfa'] === true
    ) {
      return true;
    } else {
      return false;
    }
  };
  const mfaAuthenticated = checkAuthenticated(decodedToken);
  // console.log(mfaAuthenticated, mfaNeeded, 'workSpaceList');
  if (mfaNeeded === true) {
    if (mfaAuthenticated === false) {
      return AuthType.AuthNeeded;
    } else {
      return AuthType.NoAuthNeeded;
    }
  } else {
    return AuthType.NoAuthNeeded;
  }
}
