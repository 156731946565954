import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import { DropdownWithCircle, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
//ICONS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import {
    ButtonContainer,
    CancelButton,
    EmptyDiv,
    Header,
    HeaderLeft,
    InputWrapper,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper as WrapperExit
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { IfNodeT } from '../../Dialogue/kind'
import { DraftJST, ExpressionTypeE, converterToEditor, converterToServer } from '../../Editor/Action'
//Rich Editor Imports
import Editor from '../../Editor/Editor'
//ACTION IMPORT
import { updateIfDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext } from '../Context'
//TYPE IMPORTS
import { EXIT_DIALOGUE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'

export const HeaderWithSwitch = styled.div`
    width: 100%;
    float: left;
    margin-top: 10px;
    h4 {
        font-size: 14px !important;
        justify-content: space-between;
    }
    .popup_show_hide {
        text-align: left;
        height: 20px;
        display: flex;
        position: relative;
        top: 0px;
        align-items: center;
        left: 6.5px;
        // margin-bottom: 10px;

        span {
            top: 1.5px;
        }
    }
    p span {
        color: #007bff;
        cursor: pointer;
    }
`
const ExitTips = () => (
    <>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/exit/"
            >
                Exit conversation
            </a>{' '}
            makes the bot display a message and exit from user conversation.
        </p>
        <p>
            You can also make the bot get user feedback by turning on <b>‘Get user feedback upon exit’</b> option. And
            you can configure feedback options from the bot settings page from the chatbot menu.
        </p>
    </>
)

function Exit() {
    const context = useContext(DialogueContext)
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName, queryClient, parentIfnode } = context

    const { data } = PageState as EXIT_DIALOGUE

    const [loader, setLoader] = useState<boolean>(false)

    const [error, setError] = useState<any>({ error: null, info: null })
    const next_step: any = data.next_step

    const next_step_value = next_step.exit_with_feedback ? next_step.exit_with_feedback : next_step.exit
    const statusInitialValue = typeof next_step_value == 'object' ? (next_step_value as any).update_query_status_as : "resolved"
    const [status , setStatus] = useState(statusInitialValue)
    const [mark_ticket_created , setTicketCreated] = useState(typeof next_step_value == 'object' && (next_step_value as any).mark_ticket_created)
    const [collapseStatusSection,setCollapseStatusSection] = useState(true)

    // console.log('PageState Exit', PageState, data)
    const [draft, setDraft] = useState<DraftJST>(
        (() => {
            const next_step: any = data.next_step
            if (typeof next_step !== 'string') {
                if (next_step.exit_with_feedback !== undefined) {
                    return converterToEditor(next_step.exit_with_feedback.message, context, context.dialogueNode)
                } else if (next_step.exit !== undefined) {
                    return converterToEditor(next_step.exit.message, context, context.dialogueNode)
                }
            }
            return converterToEditor({
                type: ExpressionTypeE.TEXT,
                value: ''
            })
        })()
    )

    const [needFeedBack, setNeedFeedBack] = useState<boolean>(
        (() => {
            const next_step: any = data.next_step
            if (typeof next_step !== 'string') {
                if (next_step.exit_with_feedback !== undefined) {
                    return true
                } else if (next_step.exit !== undefined) {
                    return false
                }
            }
            return true
        })()
    )

    const save = async () => {
        try {
            setLoader(true)
            const req = {
                ...data,
                next_step: {
                    [needFeedBack ? 'exit_with_feedback' : 'exit']: {
                        message: converterToServer(draft),
                        mark_ticket_created: mark_ticket_created,
                        update_query_status_as: status
                    }
                }
            } as any
            await updateIfDialogue(workspaceName, assistantID, data.uuid, req as IfNodeT, dialogueName)
            queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as IfNodeT).uuid])
            queryClient.invalidateQueries(['dialogRoots', assistantID])
            queryClient.invalidateQueries(['dialogs', assistantID])
            await queryClient.invalidateQueries(['assistants'])

            logEvent(workspaceName, {
                event: needFeedBack? "EVENT::DIALOG::EXIT_CONVERSATION_WITH_FEEDBACK" : "EVENT::DIALOG::EXIT_CONVERSATION_WITHOUT_FEEDBACK", event_meta:{
                name: dialogueName,
                description: ""
            }})

            fetchCallback(() => {
                setLoader(false)
            })
        } catch (err) {
            setError({
                error: {
                    error: true,
                    info: `Server error!<br />Please try again after sometime.`
                },
                showPopupLoader: false
            })
        }
    }
    return (
        <WrapperExit>
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Exit conversation
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<ExitTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/exit" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'EXIT_FROM_A_CONVERSATION'} text={'How to'} />
                        </HeaderRight>
                    </Header>
                    <Paragraph>Make your chatbot exit from the conversation.</Paragraph>
                    <PopupScrollHeight>
                        <ErrorBoundary
                            error={error}
                            render={(err: any, info: any) => {
                                return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                            }}
                        >
                            <div className="exit_popup">
                                {/* <h5 className="popup_header">
                                    Exit{' '}
                                    <span className="help_doc_span">
                                        <a target="_blank" href="https://help.workativ.com/knowledgebase/exit/">
                                            <HelpIconMenu />
                                        </a>
                                        <span className="side_menu_hvr">Help</span>
                                    </span>
                                </h5>
                                <p className="ask_ques_editor_p">
                                    Make the chatbot exit from session with a message for end user
                                </p> */}

                                {loader ? (
                                    <div className="popup_loader">
                                        <Loader.PopupLoader show={loader} />
                                    </div>
                                ) : (
                                    <EmptyDiv>
                                        <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <h4 className='title'> Dialog resolution status
                                                                <CancelButton className="d-flex slots_dropDown" onClick={()=>setCollapseStatusSection(!collapseStatusSection)}>
                                                                    <DropdownWithCircle />
                                                                </CancelButton>
                                                            </h4>
                                                        {collapseStatusSection &&
                                                            <>
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='resolved'}
                                                                            onChange={() => {
                                                                                setStatus("resolved")
                                                                            }}
                                                                            name="status_jump_or_exit"

                                                                        />
                                                                    </div>
                                                                </div>
                                                                Mark dialog as resolved                                                            
                                                            </Paragraph>
                                                            <Paragraph className="recognize_dialog_fontsize row">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='unresolved'}
                                                                            onChange={() => {
                                                                                setStatus("unresolved")
                                                                            }}
                                                                            name="status_jump_or_exit"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Mark dialog as unresolved                                                           
                                                            </Paragraph>
                                                            <Paragraph className="recognize_dialog_fontsize row single">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="radio"
                                                                            // className={`switch_1`}
                                                                            checked={status=='ignore'}
                                                                            onChange={() => {
                                                                                setStatus("ignore")
                                                                            }}
                                                                            name="status_jump_or_exit"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Do not mark dialog resolution status                                                         
                                                            </Paragraph>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>

                                           <EmptyDiv>
                                                <div className="row">
                                                    <div className="get_users_wrapper">
                                                        <div className="slots_right_bot">
                                                            <Paragraph className="recognize_dialog_fontsize row single">
                                                            <div className="show_hide">
                                                                    <div className="switch_box box_1">
                                                                        <input
                                                                            type="checkbox"
                                                                            // className={`switch_1`}
                                                                            checked={mark_ticket_created}
                                                                            onChange={() => {
                                                                                setTicketCreated(!mark_ticket_created)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                Ticket raised for this dialog
                                                                <Tooltip
                                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                                    zIndex={10000}
                                                                    arrowSize={4}
                                                                    tagName="span"
                                                                    content="Enable it to mark this user query as raised a ticket if the chatbot is not able to solve the user query."
                                                                    distance={5}
                                                                >
                                                                    <InfoIcon />
                                                                </Tooltip>                                                               
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyDiv>
                                        <React.Fragment>
                                            <InputWrapper>
                                                <DraftInputWrapper className="draft_focus_content">
                                                    <Editor
                                                        focusContent={'Exit message'}
                                                        DraftMap={draft}
                                                        setDraftMap={setDraft}
                                                        hideToolbar
                                                        focus={
                                                            convertAsaSingleString(
                                                                draft.blocks as RawDraftContentBlock[]
                                                            ).replace(/\s/g, '').length
                                                                ? 'focus_input'
                                                                : ''
                                                        }
                                                        stripPastedStyles
                                                    />
                                                </DraftInputWrapper>
                                                {/* <Paragraph pMargin>Type your exit message </Paragraph> */}
                                                <HeaderWithSwitch>
                                                    <Header>
                                                        Get user feedback upon exit{' '}
                                                        <div className="popup_show_hide">
                                                            <div className="show_hide">
                                                                <div className="switch_box box_1">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="switch_1 widget_switch"
                                                                        checked={needFeedBack}
                                                                        onChange={() => setNeedFeedBack(!needFeedBack)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Header>
                                                    <Paragraph style={{marginBottom: '4px'}}>
                                                        Note: You can edit feedback messages in this bot&nbsp;
                                                        <a href={`/workspace/${workspaceName}/assistant/${context.assistant.name}/settings`}>
                                                        settings
                                                        </a>
                                                        &nbsp;page.
                                                    </Paragraph>
                                                </HeaderWithSwitch>
                                            </InputWrapper>
                                        </React.Fragment>
                                    </EmptyDiv>
                                )}
                            </div>
                        </ErrorBoundary>
                        {!loader && (
                            <ButtonContainer className="footer_button_fixed">
                                <div className="popup_footer_button_container">
                                    <Button primary type="button" data-dismiss="modal" onClick={() => setPageState(VIEW)}>
                                        Cancel
                                    </Button>

                                    <Button
                                        className={false ? 'ditor_btn_disabled' : ''}
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => save()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </ButtonContainer>
                        )}
                    </PopupScrollHeight>
                </PopupContainer>
            </PopupWrapper>
        </WrapperExit>
    )
}

export default Exit
