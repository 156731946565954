/*eslint-disable*/
import React, { useContext, useState, createContext, useEffect } from 'react';
import { CanvasRightPanel, CanvasRightPanel as CRP } from './CanvasRightPanel';
import {
  ActionPanelPopup,
  Wrapper as ActionWrapper,
  Button,
  ButtonContainer,
  Input,
  InputContainer,
  InputContent,
  InputWrapper,
  PopupContainer,
  PopupWrapper,
} from '@/common/styled/Workflow.Dumb';
import { CancelButtonInput } from './ActionPanel';
import { DropdownWithCircle, InfoIcon } from '@/common/Icons/Icons';
import { VideoLink } from '@/Videos/Videos';
import styled from 'styled-components/macro';
import { Textarea } from '@/common/styled/Workflow.Analytics.Dumb';
import { SidePanelContext } from './Canvas';
import {
  NodeAddT,
  NodeEditT,
  NodeMode,
  NodeModes,
  NodeT,
  NodeView,
  RightPanelStateContext,
  TimerInitialState,
  TimerResponseT,
  Tree,
  TreeT,
  updateNode,
} from './types';
import { Loader } from '@/common/components/Loader';
import { Error } from '@/common/components/ErrorBoundary';
import Tooltip from 'react-tooltip-lite';
import { getProductId } from '@/common/utils/api-utils';

const InputGrid = styled.div`
  width: 100%;
  float: left;
`;

const timerTypeInuput = [
  { name: 'Wait for a specified period of time', value: 'wait_time' },
  { name: 'Wait until specific conditions are met', value: 'condition_met' },
];

const timerLimit = [
  { name: 'minutes', value: 'minute' },
  { name: 'hour(s)', value: 'hour' },
  { name: 'day(s)', value: 'day' },
  // { name: 'week(s)', value: 'weeks' },
];

const timeType = [
  { name: 'Current time', value: 'current_time' },
  { name: 'Date & Time', value: 'date_time' },
];

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const timeZone = ['CET(UTC + 1)', 'IST(UTC +5:30)', 'GMT(UTC + 0)'];

export type InputT =
  | {
      type: 'schedule-by-date';
      time: string;
      timezone: string;
      day: {
        type: 'without-day';
      };
    }
  | {
      type: 'schedule-by-date';
      time: string;
      timezone: string;
      day: {
        type: 'with-day';
        on: number;
        period: 'month' | 'week' | 'year';
      };
    }
  | {
      type: 'schedule-by-delay';
      delay: {
        by: number;
        period: 'day' | 'hour' | 'minute';
      };
      date: {
        type: 'without-date';
      };
    }
  | {
      type: 'schedule-by-delay';
      delay: {
        by: number;
        period: 'day' | 'hour' | 'minute';
      };
      date: {
        type: 'with-date';
        on: string;
        timezone: string;
      };
    };

export type SchedularPanelStateT = {
  show: boolean;
  parentNode: TreeT<NodeT>;
  mode: NodeModes;
  currentNode: TimerResponseT;
};

export type SchedularPanelStateContextT = {
  schedularPanelState: SchedularPanelStateT;
  setSchedularPanelState: (object: any) => void;
};

export const SchedularPanelStateContext =
  createContext<SchedularPanelStateContextT>({
    schedularPanelState: {
      show: false,
      mode: NodeAddT,
      parentNode: { value: Tree, children: [] },
      currentNode: TimerInitialState,
    },
    setSchedularPanelState: (object: any) => undefined,
  });

export const SchedularPanel = (props: any) => {
  const { rightPanelState } = useContext(SidePanelContext);

  const [schedularPanelState, setSchedularPanelState] = useState({
    ...rightPanelState,
    currentNode: rightPanelState.currentNode as TimerResponseT,
  });

  return (
    <SchedularPanelStateContext.Provider
      value={{
        schedularPanelState,
        setSchedularPanelState,
      }}
    >
      <SchedularActualComponent {...props} />
    </SchedularPanelStateContext.Provider>
  );
};

function SchedularActualComponent(props: any) {
  const { hasLinkedDialogs, automationMeta, active_automation } = useContext(SidePanelContext);
  const { schedularPanelState, setSchedularPanelState } = useContext(
    SchedularPanelStateContext
  );
  const { rightPanelState, setRightPanelState, saveTree } =
    useContext(SidePanelContext);
  const SidePanelContextValue = useContext(SidePanelContext);

  useEffect(() => {
    const currentMode = rightPanelState.mode;
    console.log(currentMode, NodeMode, rightPanelState);
    if (currentMode.mode === NodeMode.Add) {
      setSchedularPanelState({
        ...rightPanelState,
        currentNode: rightPanelState.currentNode as TimerResponseT,
      });
    } else if (currentMode.mode === NodeMode.Edit) {
      const mode = currentMode as NodeEditT;
      const input = mode.nodeEditInput.input as unknown as InputT;

      setSchedularPanelState({
        ...rightPanelState,
        currentNode: {
          ...rightPanelState.currentNode,
          timerType:
            input.type === 'schedule-by-delay' ? 'wait_time' : 'condition_met',
          timerValue:
            input.type === 'schedule-by-delay' ? input.delay.period : 'day',
          waitFor: input.type === 'schedule-by-delay' ? input.delay.by : '',
          conditionTimeType:
            input.type === 'schedule-by-date' && input.day.type === 'with-day'
              ? 'date_time'
              : 'current_time',
          hour: input.type === 'schedule-by-date' ? input.time : 0,
          timezone:
            input.type === 'schedule-by-date'
              ? input.timezone
              : 'IST(UTC +5:30)',
          currentDay:
            input.type === 'schedule-by-date' && input.day.type === 'with-day'
              ? input.day.on
              : 1,
        } as TimerResponseT,
      });
    }
  }, [rightPanelState.mode]);

  useEffect(() => {
    // to see the details in left side panel while adding or editing
    setRightPanelState({
      ...rightPanelState,
      currentNode: schedularPanelState.currentNode,
    });
  }, [schedularPanelState.currentNode]);

  function cancelCurrentPopup(props: {
    schedularPanelState: SchedularPanelStateT;
    SidePanelContext: RightPanelStateContext;
  }) {
    const { schedularPanelState, SidePanelContext } = props;

    const {
      rightPanelState,
      setRightPanelState,
      treeData,
      setEditTreeData,
      editTreeData,
    } = SidePanelContext;

    const tree = schedularPanelState.parentNode.children.filter(
      (x) => x.value.kind === 'ParallelPath'
    );

    const cancellingNode =
      schedularPanelState.currentNode.parallelPathNode.currentParallelPathNode;

    const currentNode = schedularPanelState.currentNode;

    if (tree.length < 2) {
      setRightPanelState({
        ...rightPanelState,
        show: false,
        mode: NodeView,
        parentNode: { value: Tree, children: [] },
        currentNode: {
          ...currentNode,
          parallelPathNode: {
            ...currentNode.parallelPathNode,
            currentParallelPathNode: null,
          },
        },
      });

      const newTree = cancellingNode
        ? updateNode({ ...cancellingNode, name: '' }, treeData.tree, undefined)
        : treeData.tree;

      setEditTreeData({ ...treeData, tree: newTree });
    } else {
      const node = cancellingNode as NodeT;
      // for while adding new parallel path
      setRightPanelState({
        ...rightPanelState,
        show: false,
        currentNode: {
          ...currentNode,
          parallelPathNode: {
            ...currentNode.parallelPathNode,
            currentParallelPathNode: null,
          },
        },
      });

      const newTree = cancellingNode
        ? updateNode(
            { ...cancellingNode, name: '' },
            editTreeData.tree,
            undefined
          )
        : editTreeData.tree;
      setEditTreeData({ ...editTreeData, tree: newTree });
    }
  }

  function onSave() {
    saveTree(state);
  }

  const state = schedularPanelState.currentNode;

  if (rightPanelState.mode.mode === NodeMode.Loading) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          {[
            <div className="popup_inside_loader">
              <Loader.PopupLoader show={true} />
            </div>,
          ]}
        </Wrapper>
      </div>
    );
  } else if (rightPanelState.mode.mode === NodeMode.Error) {
    return (
      <div className="automation_canvas_right">
        <Wrapper>
          {[
            <div className="flow_canvas_popup_error">
              <Error.AddorEdit
                info={
                  'Something went wrong!<br/>Please try again after sometime.'
                }
                onClick={() => {
                  cancelCurrentPopup({
                    schedularPanelState,
                    SidePanelContext: SidePanelContextValue,
                  });
                }}
              />
            </div>,
          ]}
        </Wrapper>
      </div>
    );
  }

  

  const isAssistant = getProductId() === 'ASSISTANT'

  return (
    //option 1

    <Wrapper>
      <CanvasRightPanel.StepHeader
        title={
          rightPanelState.mode.mode === NodeMode.Edit
            ? 'Edit Timer'
            : 'Add Timer'
        }
        description={'Set timer'}
        infocontent={'Set the timer to execute the workflow when the timer conditions are met.'}
        // helpcontent="To help documention of action"
        // helplink="https://help.workativ.com/knowledgebase/add-action/"
      >
        {<VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to'} />}
      </CanvasRightPanel.StepHeader>

      <TimerTypeDropDown
        schedularPanelState={schedularPanelState}
        setSchedularPanelState={setSchedularPanelState}
        hasLinkedDialogs={(isAssistant ? automationMeta.type =='no_trigger' ?false : active_automation: active_automation)}
      />

      {state.timerType == 'wait_time' ? (
        <InputGrid>
          <div className="shuedular_input ask_ques_editor_input">
            <InputWrapper
              style={{ padding: '0px 0px 20px 20px', width: '270px' }}
            >
              <InputContainer 
                className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
              >
                <Input
                  type="text"
                  value={state.waitFor}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex

                    if (e.target.value === '' || re.test(e.target.value)) {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, waitFor: e.target.value },
                      });
                    }
                  }}
                />
                <InputContent>Wait for 
                  <Tooltip
                    className="target customTip _description timer"
                    zIndex={10000}
                    tagName="span"
                    content="Add a number to wait for"
                    distance={15}
                    forceDirection={true}
                    direction="up-start"
                    >
                      <InfoIcon />
                  </Tooltip>
                </InputContent>
                
              </InputContainer>
            </InputWrapper>
            <InputWrapper
              className="autoamtion_connection scheduler_popup" 
              style={{
                padding: '0px 20px 20px 10px',
                marginBottom: 'auto',
                width: '200px',
              }}
            >
              <div className="select_application">
                <div className="">
                  <CanvasRightPanel.AppsDropDown
                    inputs={{
                      inputs: timerLimit,
                      key: 'name',
                      idKey: 'value',
                      showIcon: false,
                    }}
                    addDropDown
                    updateSelection={(
                      selecterTimerType: string,
                      timerValue: string
                    ) => {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, timerValue: timerValue },
                      });
                    }}
                    render={(makeDropDownVisible: () => void) => (
                      <InputContainer style={{ marginBottom: 0 }}>
                        <Input
                          type="text"
                          className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                          value={
                            timerLimit.filter(
                              (t) => t.value == state.timerValue
                            )[0].name
                          }
                          onClick={() => makeDropDownVisible()}
                          onChange={(e: any) => {}}
                          style={{ padding: '14px 7px 10px 12px' }}
                        />
                        {/* <InputContent>day(s)</InputContent> */}
                        <CancelButtonInput
                          onClick={() => makeDropDownVisible()}
                        >
                          <DropdownWithCircle />
                        </CancelButtonInput>
                      </InputContainer>
                    )}
                  />
                </div>
              </div>
            </InputWrapper>
            {/* </InputWrapper>
          <InputWrapper
            style={{
              padding: '0px 20px 20px 20px',
              marginBottom: 'auto',
              width: '170px',
            }}
          >
            <InputContainer>
              <Input type="text" />
              <InputContent>day(s) </InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </InputWrapper> */}
          </div>
        </InputGrid>
      ) : (
        <>
          <InputWrapper
            className="autoamtion_connection scheduler_popup_cur_time"
            style={{ marginBottom: 0, padding: '0 20px' }}
          >
            <div className="select_application">
              <div className="">
                <CanvasRightPanel.AppsDropDown
                  inputs={{
                    inputs: timeType,
                    key: 'name',
                    idKey: 'value',
                    showIcon: false,
                  }}
                  addDropDown
                  updateSelection={(
                    selected: string,
                    conditionTimeType: string
                  ) => {
                    setSchedularPanelState({
                      ...schedularPanelState,
                      currentNode: { ...state, conditionTimeType },
                    });
                  }}
                  render={(makeDropDownVisible: () => void) => (
                    <InputContainer style={{ marginBottom: 20 }}>
                      <Input
                        type="text"
                        className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                        value={
                          timeType.filter(
                            (t) => t.value === state.conditionTimeType
                          )[0].name
                        }
                        onClick={() => makeDropDownVisible()}
                        onChange={(e: any) => {}}
                      />
                      <InputContent>Set condition</InputContent>
                      <CancelButtonInput onClick={() => makeDropDownVisible()}>
                        <DropdownWithCircle />
                      </CancelButtonInput>
                    </InputContainer>
                  )}
                />
              </div>
            </div>
          </InputWrapper>
          {state.conditionTimeType == 'date_time' && (
            <InputWrapper
              className="autoamtion_connection scheduler_popup_cur_time"
              style={{ marginBottom: 0, padding: '0 20px' }}
            >
              <div className="select_application">
                <div className="">
                  <CanvasRightPanel.AppsDropDown
                    inputs={{
                      inputs: days.map((d) => ({ name: d, value: d })),
                      key: 'name',
                      idKey: 'value',
                      showIcon: false,
                    }}
                    addDropDown
                    updateSelection={(selected: string, currentDay: number) => {
                      setSchedularPanelState({
                        ...schedularPanelState,
                        currentNode: { ...state, currentDay },
                      });
                    }}
                    render={(makeDropDownVisible: () => void) => (
                      <InputContainer style={{ marginBottom: 20 }}>
                        <Input
                          type="text"
                          className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                          value={state.currentDay}
                          onClick={() => makeDropDownVisible()}
                          onChange={(e: any) => {}}
                        />
                        <InputContent>Current day of month is</InputContent>
                        <CancelButtonInput
                          onClick={() => makeDropDownVisible()}
                        >
                          <DropdownWithCircle />
                        </CancelButtonInput>
                      </InputContainer>
                    )}
                  />
                </div>
              </div>
            </InputWrapper>
          )}
          <InputGrid>
            <div className="shuedular_input ask_ques_editor_input">
              <InputWrapper
                style={{ padding: '0px 5px 20px 20px', width: '250px' }}
              >
                <div className="select_application">
                  <div className="">
                    <CanvasRightPanel.AppsDropDown
                      inputs={{
                        inputs: hours.map((v) => ({
                          name: `${v}h (${v === 0 ? 12 : v < 13 ? v : v % 12}${
                            v < 12 ? 'AM' : 'PM'
                          })`,
                          value: v,
                        })),
                        key: 'name',
                        idKey: 'value',
                        showIcon: false,
                      }}
                      addDropDown
                      updateSelection={(selected: string, hour: number) => {
                        setSchedularPanelState({
                          ...schedularPanelState,
                          currentNode: { ...state, hour },
                        });
                      }}
                      render={(makeDropDownVisible: () => void) => (
                        <InputContainer style={{ marginBottom: 0 }}>
                          <Input
                            type="text"
                            className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                            value={`${state.hour}h (${
                              state.hour === 0
                                ? 12
                                : state.hour < 13
                                ? state.hour
                                : state.hour % 12
                            }${state.hour < 12 ? 'AM' : 'PM'})`}
                            onClick={() => makeDropDownVisible()}
                            onChange={() => {}}
                          />
                          <InputContent>Time is</InputContent>
                          <CancelButtonInput
                            onClick={() => makeDropDownVisible()}
                          >
                            <DropdownWithCircle />
                          </CancelButtonInput>
                        </InputContainer>
                      )}
                    />
                  </div>
                </div>
              </InputWrapper>
              {/* <InputWrapper
                style={{
                  padding: '0px 20px 20px 5px',
                  marginBottom: 'auto',
                  width: '170px',
                }}
              >
                <InputContainer>
                  <Input type="text" />
                  <InputContent>Timezone </InputContent>
                  <CancelButtonInput>
                    <DropdownWithCircle />
                  </CancelButtonInput>
                </InputContainer>
              </InputWrapper> */}
              <InputWrapper
                style={{
                  padding: '0px 20px 20px 5px',
                  marginBottom: 'auto',
                  width: '230px',
                }}
              >
                <div className="select_application">
                  <div className="">
                    <CanvasRightPanel.AppsDropDown
                      inputs={{
                        inputs: timeZone.map((v) => ({
                          name: v,
                          value: v,
                        })),
                        key: 'name',
                        idKey: 'value',
                        showIcon: false,
                      }}
                      addDropDown
                      updateSelection={(selected: string, timezone: string) => {
                        setSchedularPanelState({
                          ...schedularPanelState,
                          currentNode: { ...state, timezone },
                        });
                      }}
                      render={(makeDropDownVisible: () => void) => (
                        <InputContainer style={{ marginBottom: 0 }}>
                          <Input
                            type="text"
                            className={(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)?'':"editor_btn_disabled"}
                            value={state.timezone}
                            onClick={() => makeDropDownVisible()}
                            onChange={(e: any) => {}}
                          />
                          <InputContent>Timezone</InputContent>
                          <CancelButtonInput
                            onClick={() => makeDropDownVisible()}
                          >
                            <DropdownWithCircle />
                          </CancelButtonInput>
                        </InputContainer>
                      )}
                    />
                  </div>
                </div>
              </InputWrapper>
            </div>
          </InputGrid>
        </>
      )}
      {/* 
      <InputWrapper className="autoamtion_connection">
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Notification type</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select bot</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select chat channel</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>
        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Send notification to</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>

        <div className="select_application">
          <div className="">
            <InputContainer style={{ marginBottom: 20 }}>
              <Input type="text" />
              <InputContent>Select notification type</InputContent>
              <CancelButtonInput>
                <DropdownWithCircle />
              </CancelButtonInput>
            </InputContainer>
          </div>
        </div>

        <InputContainer style={{ marginBottom: 20 }}>
          <Input type="text" />
          <InputContent>Notification Title</InputContent>
        </InputContainer>
        <InputContainer>
          <Textarea value={prompt} />
          <InputContent>Message to user</InputContent>
        </InputContainer>
      </InputWrapper> */}

      <ButtonContainer className="footer_button_fixed">
        <div className="popup_footer_button_container">
          <Button
            primary
            className="automation_condition_button_btn"
            onClick={() =>
              cancelCurrentPopup({
                schedularPanelState,
                SidePanelContext: SidePanelContextValue,
              })
            }
          >
            <label>Cancel</label>
          </Button>
          {(isAssistant ? automationMeta.type =='no_trigger' ?true : !active_automation: !active_automation)&&<Button
            className={
              (state.timerType === 'wait_time' && state.waitFor.length > 0) ||
              state.timerType === 'condition_met'
                ? 'automation_condition_button_btn'
                : 'automation_condition_button_btn editor_btn_disabled'
            }
            onClick={() => {
              onSave();
            }}
          >
            <label>Save</label>
          </Button>}
        </div>
      </ButtonContainer>
    </Wrapper>

    //option 2

    // <Wrapper>
    //   <CanvasRightPanel.StepHeader
    //     title={'Start your workflow'}
    //     description={'Set timer'}
    //     // infocontent={<ActionTip />}
    //     helpcontent="To help documention of action"
    //     helplink="https://help.workativ.com/knowledgebase/add-action/"
    //   >
    //     {<VideoLink id={'CREATE_AN_ACTION_IN_APP_WORKFLOW'} text={'How to'} />}
    //   </CanvasRightPanel.StepHeader>

    //   <InputWrapper
    //     className="autoamtion_connection"
    //     style={{ marginBottom: 0, padding: '0 20px' }}
    //   >
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Set condition</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //   </InputWrapper>

    //   <InputWrapper
    //     className="autoamtion_connection"
    //     style={{ marginBottom: 0, padding: '0 20px' }}
    //   >
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Current day of month is</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //   </InputWrapper>

    //   <InputGrid>
    //     <div className="shuedular_input ask_ques_editor_input">
    //       <InputWrapper
    //         style={{ padding: '0px 5px 20px 20px', width: '290px' }}
    //       >
    //         <InputContainer>
    //           <Input type="text" />
    //           <InputContent>Time is </InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </InputWrapper>
    //       <InputWrapper
    //         style={{
    //           padding: '0px 20px 20px 5px',
    //           marginBottom: 'auto',
    //           width: '170px',
    //         }}
    //       >
    //         <InputContainer>
    //           <Input type="text" />
    //           <InputContent>Timezone </InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </InputWrapper>
    //     </div>
    //   </InputGrid>

    //   <InputWrapper className="autoamtion_connection">
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Notification type</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select bot</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select chat channel</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select channel in MS Teams</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Send notification to</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>
    //     <div className="select_application">
    //       <div className="">
    //         <InputContainer style={{ marginBottom: 20 }}>
    //           <Input type="text" />
    //           <InputContent>Select notification type</InputContent>
    //           <CancelButtonInput>
    //             <DropdownWithCircle />
    //           </CancelButtonInput>
    //         </InputContainer>
    //       </div>
    //     </div>

    //     <InputContainer style={{ marginBottom: 20 }}>
    //       <Input type="text" />
    //       <InputContent>Notification Title</InputContent>
    //     </InputContainer>
    //     <InputContainer>
    //       <Textarea value={prompt} />
    //       <InputContent>Message to user</InputContent>
    //     </InputContainer>
    //   </InputWrapper>

    //   <ButtonContainer className="footer_button_fixed">
    //     <div className="popup_footer_button_container">
    //       <Button primary className="automation_condition_button_btn">
    //         <label>Cancel</label>
    //       </Button>
    //       <Button className="automation_condition_button_btn editor_btn_disabled">
    //         <label>Save</label>
    //       </Button>
    //     </div>
    //   </ButtonContainer>
    // </Wrapper>
  );
}

const Wrapper = (props: { children: React.ReactChild[] }) => (
  <ActionWrapper>
    <ActionPanelPopup>
      <PopupWrapper>
        <PopupContainer>
          <div className="automation_canvas_right_popup">
            <div className="automation_canvas_right_popup_form">
              <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                {props.children}
              </div>
            </div>
          </div>
        </PopupContainer>
      </PopupWrapper>
    </ActionPanelPopup>
  </ActionWrapper>
);

function TimerTypeDropDown(props: {
  schedularPanelState: SchedularPanelStateT;
  setSchedularPanelState: (state: SchedularPanelStateT) => void;
  hasLinkedDialogs: boolean;
}) {
  const { setSchedularPanelState, schedularPanelState, hasLinkedDialogs } =
    props;
  const state = schedularPanelState.currentNode;
  return (
    <InputWrapper className="autoamtion_connection scheduler_popup" style={{ marginBottom: 0 }}>
      <div className="select_application">
        <div className="">
          <CanvasRightPanel.AppsDropDown
            inputs={{
              inputs: timerTypeInuput,
              key: 'name',
              idKey: 'value',
              showIcon: false,
            }}
            addDropDown
            updateSelection={(selecterTimerType: string, timerType: string) => {
              setSchedularPanelState({
                ...schedularPanelState,
                currentNode: { ...state, timerType },
              });
            }}
            render={(makeDropDownVisible: () => void) => (
              <InputContainer style={{ marginBottom: 0 }}>
                <Input
                  type="text"
                  className={`${hasLinkedDialogs ? 'disabled_with_blue' : ''}`}
                  value={
                    timerTypeInuput.filter((t) => t.value == state.timerType)[0]
                      .name
                  }
                  onClick={() => makeDropDownVisible()}
                  onChange={(e: any) => {}}
                  style={{color: 'transparent', textShadow: '0 0 0 #000'}}
                />
                <InputContent>Timer type</InputContent>
                <CancelButtonInput onClick={() => makeDropDownVisible()}>
                  <DropdownWithCircle />
                </CancelButtonInput>
              </InputContainer>
            )}
          />
        </div>
      </div>
    </InputWrapper>
  );
}
