import React from 'react'

function getArtificialDependency() {
    return {}
}

export function useConditionalEffect(callback: any, dependencies: any, isEqual: any) {
    const lastDependenciesValue = React.useRef(undefined)
    const artificialDep = React.useRef(getArtificialDependency())
    const shouldRunEffect = !isEqual(dependencies, lastDependenciesValue.current)

    artificialDep.current = shouldRunEffect ? getArtificialDependency() : artificialDep.current

    React.useEffect(() => {
        // console.log('run effect', artificialDep.current, shouldRunEffect)
        if (shouldRunEffect) {
            callback()
        }
    }, [artificialDep.current, shouldRunEffect])
    lastDependenciesValue.current = dependencies
}
