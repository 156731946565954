import { useSelect } from 'downshift'
import React from 'react'
import styled from 'styled-components/macro'

import { DropdownWithCircle, QaIconWithCircle, SupportIcon, VideoIcon } from '@/common/Icons/Icons'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    ButtonWithImage,
    DownloadBotHelp,
    DownloadContent,
    DropdownContainer,
    OrSticky
} from '@/common/styled/GetStarted.Dumb'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper
} from '@/common/styled/Workflow.Analytics.Dumb'
import emptyImg from '@/common/images/empty_img.png'


export const BoxWrapperBg = styled.div`
    width: 1200px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin-top: 20px;

    .box_wrapper_top {
        width: 100%;
        display: flex;
        flex-direction: column;

        h2 {
            margin-bottom: 10px;
            line-height: 24px;
        }
        p {
            line-height: 14px;
            color: #355d8b;
            font-family: 'Gordita-Regular';
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
`
export const BotGridsContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 40% 20% 1fr;
    grid-gap: 40px;
    align-items: center;
    ${OrSticky}{
        margin-left: 80px;
    }
`

export const BotWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 40px;
    background: #fff;
    border-radius: 10px;

    .box_wrapper_top p {
        color: #355d8b;
        font-family: 'Gordita-Regular';
        font-size: 14px;
        margin-bottom: 12px;
        width: 365px;
        line-height: 20px;
    }
    ${DropdownWrapper} {
        width: 390px;
    }

    .get_start_img{
        width: 500px;
        position: relative;
        right: 50px;
        margin-bottom: 20px;
        img{
            max-width: 100%;
            height: auto;
        }
        }
    }
`

export const WrapperBgFixed = styled.section`
    width: 100%;
    float: left;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`
const BotContainer = styled.section`
    width: 100%;
    float: left;
    padding: 20px;
`

export default function BotCreation({
    modelError,
    setModelError,
    NoErrorV,
    loading,
    setLoading,
    keycode,
    setKeycode,
    setMetacode,
    metacode,
    name,
    setName,
    selectedLanguage,
    setSelectedLanguage,
    ErrorState,
    onCancel,
    editId,
    setEditId,
    assistantAction,
    setOpenPopup
}: any) {
    const [validName, setValidName] = React.useState(false)
    const CreateAssistant = () => {
        console.log('selectec langeage', selectedLanguage)
        assistantAction(name, selectedLanguage, (error: any) => {
            const errorMessage =
                error.response &&
                error.response.data &&
                error.response.data.code === 'RESOURCE_CONSUMPTION_LIMIT_EXCEEDED'
                    ? 'Maximum allowed bots limit reached, upgrade now to add more'
                    : 'something went wrong'
            setLoading(false)
            setModelError({
                type: ErrorState.Valid,
                error: true,
                info: errorMessage
            })
        })
    }
    const format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/
    const checkSpecialChar = (str: string) => format.test(str)
    const onChangeName = (event: any) => {
        setName(event.target.value)
        setValidName(checkSpecialChar(event.target.value))
    }
    let isEmptyField = !validName && name !== '' && name.length < 30 && selectedLanguage.name !== ''
    return (
        <BotContainer>
                        <WrapperBgFixed>
                            <BoxWrapperBg>
                                <BotWrapper>
                                    {/* <div className="box_wrapper_top">
                                        <p>Create a new bot or download from marketplace</p>
                                    </div> */}
                                    
                                    <BotGridsContainer>
                                        <DropdownContainer>
                                            <DropdownWrapper>
                                                <div className='get_start_img'>
                                                    <img src={emptyImg} text-align="middle" alt="" title="" />
                                                </div>
                                                <SelectLanguage 
                                                value={selectedLanguage} onChange={setSelectedLanguage} 
                                                />
                                                {validName ? (
                                                    <small style={{ float: 'right', color: 'red' }}>
                                                        Special character will not be accepted
                                                    </small>
                                                ) : null}
                                                <InputWrapper>
                                                    <InputContainer>
                                                        <Input
                                                            type="text"
                                                            onChange={(event: any) => onChangeName(event)}
                                                            value={name}
                                                        />
                                                        <InputContent>Bot name</InputContent>
                                                    </InputContainer>
                                                </InputWrapper>
                                            </DropdownWrapper>
                                            <ButtonWrapper style={{justifyContent: 'flex-start'}}>
                                                {/* <Button primary type="button" 
                                                onClick={() => onCancel()}
                                                >
                                                    <label>Cancel</label>
                                                </Button> */}
                                                <Button
                                                    margin={'0px'}
                                                    className={!isEmptyField ? 'editor_btn_disabled' : null}
                                                    disabled={name === '' ? true : false}
                                                    onClick={() => CreateAssistant()}
                                                >
                                                    {' '}
                                                    Create your Bot
                                                </Button>
                                            </ButtonWrapper>
                                        </DropdownContainer>
                                        <OrSticky>
                                            <span>OR</span>
                                        </OrSticky>
                                        <ButtonWrapper
                                            direction
                                        >
                                            <ButtonWithImage onClick={(e:any) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                onCancel()
                                                setOpenPopup(true)
                                            }}
                                            >
                                                <span>
                                                    <SupportIcon />
                                                </span>
                                                <label>Download from Marketplace</label>
                                            </ButtonWithImage>
                                            <DownloadContent>
                                                {' '}
                                                <DownloadBotHelp
                                                    href="https://help.workativ.com/knowledgebase/download-bot"
                                                    target="_blank"
                                                >
                                                    <span className="width_18">
                                                        <VideoIcon />
                                                    </span>
                                                    <label>How to download Bot from marketplace</label>
                                                </DownloadBotHelp>
                                            </DownloadContent>
                                        </ButtonWrapper>
                                    </BotGridsContainer>
                                </BotWrapper>
                            </BoxWrapperBg>
                        </WrapperBgFixed>
                    </BotContainer>
    )
}
export function SelectLanguage({ value, onChange }: { value: string; onChange: (e: any) => void }) {
    const items = ['english', 'spanish', 'greek', 'portuguese']
    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect<string>(
        {
            items,
            selectedItem: value,
            onSelectedItemChange: ({ selectedItem }) => {
                console.log('onSelectedItemChange', selectedItem)
                onChange(selectedItem)
            }
        }
    )
    const displayString = (item: string | null | undefined) =>
        item === 'english'
            ? 'English (United States)'
            : item === 'spanish'
            ? 'Spanish'
            : item === 'greek'
            ? 'Greek'
            : item === 'portuguese'
            ? 'Portuguese'
            : ''
    return (
        <InputContainer>
            <Input type="text" value={displayString(selectedItem)} {...getToggleButtonProps()} />
            <InputContent {...getLabelProps()}>Select language</InputContent>
            <CancelButton {...getToggleButtonProps()}>
                <DropdownWithCircle />
            </CancelButton>
            <DropdownUlWrapper className="mb-0">
                <DropdownUl {...getMenuProps()}>
                    {isOpen &&
                        items.map((item, index) => (
                            <DropdownLi key={item} {...getItemProps({ item, index })}>
                                {displayString(item)}
                            </DropdownLi>
                        ))}
                </DropdownUl>
            </DropdownUlWrapper>
        </InputContainer>
    )
}
