/*eslint-disable*/
import React from 'react'
export type FlowT = {
    id: string
    name: string
    description: string
    disabled: boolean
    modified_date: string
    type: string
}

export type SortedResponse = {
    id: string
    modified_date: string
    sortBy: number
    type: string
}

export type SortCardT = {
    id: string
    modified_date: string
    sortBy: number
    type: CardTypes
}

export type FlowRequestT = {
    name: string
    description: string
    group?: string
}

export type FlowValidT = {
    name: string
    description: string
    nodes: any[]
}

export type FlowResponseT = {
    id: string
    name: string
    description: string
    active_status: boolean
    groupId: string
    modified_date: string
    deployed_status: 'saved' | 'deployed'
    type: 'trigger' | 'no_trigger' | 'event_trigger' | 'webhook_trigger' | 'user_trigger'
    linkedDialogs?: any[]
}

export type GroupResponseT = {
    id: string
    name: string
    description: string
    modified_date: string
}

export type GroupT = {
    id: string
    name: string
    description: string
    modified_date: string
}

export type GroupRequestT = {
    name: string
    description: string
    flowids: Array<string>
}

export type FlowGroupT = {
    id: string
    groupId: number
}

export type FlowsResponseT = {
    automations: FlowResponseT[]
    groups: GroupT[]
    sortedList?: SortedResponse[]
    automationListWithoutConnection: string[]
    automationListWithApproval: { id: string }[]
}

export type CardCountT = {
    count: number
}

export enum Mode {
    View = 'View',
    Add = 'Add',
    Edit = 'Edit',
    Delete = 'Delete',
    Search = 'Search',
    Select = 'Selection',
    SaveAs = 'SaveAs',
    GroupView = 'GroupView',
    Loading = 'Loading',
    Error = 'Error',
    MoveToGroup = 'MoveToGroup',
    Activate = 'Activate',
    DeactivateLinkedDialog = 'DeactivateLinkedDialog',
    EditAutomationMeta = 'EditAutomationMeta',
    DeleteLinkedAutomation = 'DeleteLinkedAutomation',
    NoDataFound = 'NoDataFound',
    NoWorkflowsCreatedInitalRediraction = 'NoWorkflowsCreatedInitalRediraction'
}

export enum CardTypes {
    Flow = 'Workflow',
    Group = 'Group'
}

export enum FlowFilterE {
    Draft = 'draft',
    Home = 'home',
    Trigger = 'trigger',
    NoTrigger = 'no-trigger',
    Active = 'active',
    InActive = 'inactive',
    EventTrigger = 'event-trigger',
    AppTrigger = 'app-trigger',
    WebhookTrigger = 'webhook-trigger',
    UserTrigger = 'user-trigger',
    Approval = 'approvals',
    ChatbotTrigger = 'chatbot-trigger'
}

export type FlowFilterT = FlowFilterE

export enum ActionAfterSelection {
    Delete = 'Delete',
    Group = 'Group',
    UnGroup = 'UnGroup',
    Move = 'Move'
}

export type GroupSelectedT = {
    action: ActionAfterSelection.Group
    groupName: string
    groupInfo: string
}

export type DeleteSelectedT = {
    action: ActionAfterSelection.Delete
}

export type UngroupSelectedT = {
    action: ActionAfterSelection.UnGroup
}

export type MoveSelectedT = {
    action: ActionAfterSelection.Move
    toMove?: string
}

export type ViewT = {
    mode: Mode.View
}

export type DeactivateLinkedDialogT = {
    mode: Mode.DeactivateLinkedDialog
    dialog: any[]
    card: CardDisplayT
    flowId: string
}

export type SaveAsT = {
    mode: Mode.SaveAs
    id: string
    name: string
    description: string
    error: {
        error: boolean
        info: string
    }
}

export type ErrorT = {
    mode: Mode.Error
}

export type LoadingT = {
    mode: Mode.Loading
}

export type NoDataFoundT = {
    mode: Mode.NoDataFound
    showAddPopup: boolean
    name: string
    description: string
    error?: {
        error: boolean
        info: string
    }
}

export type GroupViewT = {
    mode: Mode.GroupView
    groupId: string
}

export type AddT = {
    mode: Mode.Add
    name: string
    description: string
    error: {
        error: boolean
        info: string
    }
}

export type SearchT = {
    mode: Mode.Search
    query: string
}

export type EditAutomationMetaT = {
    mode: Mode.EditAutomationMeta
    error: {
        error: boolean
        info: string
    }
}

export const EditAutomationMeta: EditAutomationMetaT = {
    mode: Mode.EditAutomationMeta,
    error: {
        error: false,
        info: ''
    }
}

export type CardDisplayT = {
    id: string
    name: string
    description: string
    modified_date: string
    cardType: CardTypes
    displayLabel: string
    active_status: boolean
    deployed_status: 'saved' | 'deployed'
    type: 'trigger' | 'no_trigger' | 'event_trigger' | 'webhook_trigger' | 'user_trigger'
    linkedDialogs?: any[]
}

type ErrorState = {
    error: string
}
export type DeleteSingleCardT =
    | {
          mode: Mode.Delete
          card: CardDisplayT
          deleteAction: 'Delete' | 'Ungroup'
      }
    | {
          mode: Mode.Delete
          deleteAction: 'Error'
          error: string
      }

export type SelectT = {
    mode: Mode.Select
    cards: CardDisplayT[]
    showAddGroupModel: boolean
    groupName: string
    groupInfo: string
    action?: DeleteSelectedT | GroupSelectedT | UngroupSelectedT | MoveSelectedT
    error?: {
        error: boolean
        info: string
    }
}

export type EditT = {
    mode: Mode.Edit
    cardType: CardTypes
    name: string
    description: string
    id: string
    error: {
        error: boolean
        info: string
    }
}

export type MoveT = {
    mode: Mode.MoveToGroup
    groupsList: GroupResponseT[]
    error: boolean
    info: string
    selectedGroupToMove: GroupResponseT[]
    selectedFlows: CardDisplayT[]
}

export const MoveToGroup: MoveT = {
    mode: Mode.MoveToGroup,
    groupsList: [],
    error: false,
    info: '',
    selectedGroupToMove: [],
    selectedFlows: []
}

export type AciveOrDeactiveT = {
    mode: Mode.Activate
    groupsList: GroupResponseT[]
    error: boolean
    info: string
    selectedCard: any
    flowCountError: boolean
}

export type DeleteLinkedAutomationT = {
    mode: Mode.DeleteLinkedAutomation
    dialog: any[]
    card: CardDisplayT
    flowId: string
}

export const AciveOrDeactive: AciveOrDeactiveT = {
    mode: Mode.Activate,
    groupsList: [],
    error: false,
    info: '',
    selectedCard: {},
    flowCountError: false
}

export type Modes =
    | ViewT
    | SelectT
    | AddT
    | EditT
    | SaveAsT
    | DeleteSingleCardT
    | SearchT
    | LoadingT
    | ErrorT
    | MoveT
    | AciveOrDeactiveT
    | DeleteLinkedAutomationT
    | DeactivateLinkedDialogT
    | NoDataFoundT
    | NoWorkflowsCreatedInitalRediractionT

export enum GroupMode {
    All,
    Single
}

export type AllGroupsT = { groupMode: GroupMode.All }
export type SingleGroupT = {
    groupMode: GroupMode.Single
    groupId: string
    name: string
}

export const AllGroups: AllGroupsT = { groupMode: GroupMode.All }

export type GroupModeT = AllGroupsT | SingleGroupT

export const View: ViewT = { mode: Mode.View }

export type NoWorkflowsCreatedInitalRediractionT = {
    mode: Mode.NoWorkflowsCreatedInitalRediraction
    name: string
    error: {
        error: boolean
        info: string
    }
}

//export const DeactivateLinkedDialog: DeactivateLinkedDialogT = { mode: Mode.DeactivateLinkedDialog }

export const SaveAs: SaveAsT = {
    mode: Mode.SaveAs,
    id: '',
    name: '',
    description: '',
    error: {
        error: false,
        info: ''
    }
}

export const Loading: LoadingT = {
    mode: Mode.Loading
}
export const NoWorkflowsCreatedInitalRediraction: NoWorkflowsCreatedInitalRediractionT = {
    mode: Mode.NoWorkflowsCreatedInitalRediraction,
    name: '',
    error: {
        error: false,
        info: ''
    }
}

export const ErrorPage: ErrorT = {
    mode: Mode.Error
}

export const NoDataFound: NoDataFoundT = {
    mode: Mode.NoDataFound,
    showAddPopup: false,
    name: '',
    description: ''
}

export const DeleteCards: DeleteSelectedT = {
    action: ActionAfterSelection.Delete
}

export const UnGroupCards: UngroupSelectedT = {
    action: ActionAfterSelection.UnGroup
}

export const MoveCards: MoveSelectedT = {
    action: ActionAfterSelection.Move
}

export const Add: AddT = {
    mode: Mode.Add,
    name: '',
    description: '',
    error: {
        error: false,
        info: ''
    }
}

export const SEARCH_KEY: string = ''

export const FLOW: string = 'FLOW'

export const GROUP: string = 'GROUP'

export enum SortType {
    Date = 'date',
    Type = 'type',
    Name = 'name'
}
export type SortT = SortType.Date | SortType.Type | SortType.Name

export enum ViewTypeT {
    GridView,
    ListView
}

export type Views = GridT | ListT

export type GridT = {
    View: ViewTypeT.GridView
}

export type ListT = {
    View: ViewTypeT.ListView
}

export const GridView: GridT = { View: ViewTypeT.GridView }

export const ListView: ListT = { View: ViewTypeT.ListView }

// run page types

export type NodeResponseT = {
    executionId: string
    flowId: string
    insertedTime: string
    level: string
    logType: string
    message: string
    nodeId: string
    nodeName: string
    schema: any
}

export type Node = {
    node: string
    outputs: NodeResponseT[]
}

export type NormalOrDevNode = {
    node: string
    value: Node[]
    nodeName: string
}

export type Config = {
    properties: string[]
    type: string
}

export type NodeConfig = {
    config: Config[]
    id: string
    name: string
}
