import React, { useState } from 'react';
import Downshift from 'downshift';
import { AddIcon, DeleteIcon, DropdownWithCircle, EditIcon, InfoIcon, LAPlusIcon, ProfileIcon, SlackIconCC, TeamsIcon, UserIconAnalyticsMenu, WidgetIcon } from '@/common/Icons/Icons';
import { Button, InputWrapper } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonContainer, CancelButton, DropdownLi, DropdownUl, DropdownUlWrapper, InputContainer, InputContent } from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { Input, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@/common/styled/Workflow.Analytics.Dumb';
import styled from 'styled-components';
import { Tooltip } from 'rsuite';
import { Modal } from '@/common/components/Modal'
import { PlusIcon } from '@/common/Icons/Workflow.Icons';
// import { ToolBox } from '@/common/components/Card';

const TabelWrapper = styled.div`
    width: 100%;
    float: left;
    .liveAt_settings_agent_table{
        margin: 30px 0;
        ${Tbody}{
            ${Tr} {
                ${Td} {
                    font-size: 12px;
                    font-family: 'Gordita-Regular';
                }
            }
        }
    }
    .liveAt_settings_agent_table.teamsTable{
        ${Td}:nth-child(2) {
            padding-left: 40px;
        }
    }
    
    .liveAt_settings_agent_table{
        ${Td}{
            position: relative;
        }
    }
    ${Table} {
        margin-top: 0px;
    }
    ${Thead}{
        ${Th}{
            :last-child{
                justify-content: flex-end;
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 380px);
        height: auto;
        @media (max-width: 1500px) {
            max-height: calc(100vh - 340px);
        }
        ${Tr} {
            height: 54px;
            ${Td} {
                padding: 0px;
                height: 54px;
                font-size: 16px;
                font-family: 'Gordita-Regular';
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                :first-child {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                }

                .td_header {
                    font-size: 12px;
                    color: #000;
                    line-height: 12px;
                    margin-bottom: 6px;
                }
                .td_date {
                    font-size: 12px;
                    color: #000;
                }

                .td_cc_img {
                    width: 24px;
                    height: 24px;
                    margin-right: 6px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                div {
                    a {
                        color: #3B78F7;
                        font-size: 16px;
                        font-family: 'Gordita-Medium';
                        text-decoration: underline;
                    }
                }

                .td_agentMail{
                    display: flex;
                    align-items: center;
                    svg{
                        width: 32px;
                        height: 32px;
                        margin-right: 6px;
                    }
                    p{
                        font-size: 12px;
                        font-family: 'Gordita-Regular';
                    }
                }
            }
        }
    }
`

export function LiveAgentSettings(){
    const [currentTab,setCurrentTab] = useState<"chat_assignment"|"agents"|"business_hours"|"chat_channels"|"slas"|"canned_responses">("chat_assignment")
    
    function renderTabUI(){
        switch(currentTab){
            case "chat_assignment":
                return <Chat_Assignment />
            // case "agents":
            //     return <LiveChat_Agents /> 
            case 'business_hours':
                    return <Business_Hours />
            case 'chat_channels':
                return <Chat_Channels />
            case 'slas':
                return <Slas />
            case 'canned_responses':
                return <Canned_reponses />
        }
    }
    return (

        <section className='liveAt_settings_contaniner'>
            <div className="tab_menu">
                <a className={currentTab==='chat_assignment'? "active":""} onClick={()=>setCurrentTab("chat_assignment")}>Chat Assignment </a>
                {/* <a className={currentTab==='agents'? "active":""} onClick={()=>setCurrentTab("agents")}>Agents</a> */}
                <a className={currentTab==='business_hours'? "active":""} onClick={()=>setCurrentTab("business_hours")}>Business hours</a>
                <a className={currentTab==='chat_channels'? "active":""} onClick={()=>setCurrentTab("chat_channels")}>Chat Channels</a>
                <a className={currentTab==='slas'? "active":""} onClick={()=>setCurrentTab("slas")}>SLAs</a>
                <a className={currentTab==='canned_responses'? "active":""} onClick={()=>setCurrentTab("canned_responses")}>Canned Responses</a>
            </div>
            {renderTabUI()}
        </section>
    )}
    export enum ChatRouting {
        Random,
        Maxutilization,
        Averageutilization,
        Ringtoall,
      }        
    export function Chat_Assignment(){
        const [routing, setRouting] = useState<ChatRouting>(ChatRouting.Random)

        const routingDesc =()=>{
            switch(routing){
                case ChatRouting.Random:
                    return "New chats will be assigned randomly to one of the agents available for chat. This strategy helps you to assign in long term about the same amount of chats to each of your agents."
                case ChatRouting.Maxutilization:
                    return "New chats will be assigned to the agent with the highest number of running chats to keep their utilization on maximum. Once the agent has no free chat slots, a new chat will be routed to the next available agent. This strategy helps you maximize loads for chatting agents and lets other agents work on offline tickets."
                case ChatRouting.Averageutilization:
                    return "New chats will be assigned to the agent with the lowest number of running chats and the longest time since last chat to keep the same utilization of all available agents. This strategy helps you to spread the chats between all available agents."
                case ChatRouting.Ringtoall:
                    return "New chat will ring to all agents available for chat until one of them will pick it up."
            }
        }
        return(
            <section className='liveAt_settings_contaniner'>    
                <div className='liveAt_settings_live_chat'>
                    <h5>Chat routing</h5>
                    <div className='liveAt_settings_live_radio'>
                        <div>
                            <input type='radio' 
                            onClick={()=>setRouting(ChatRouting.Random)} 
                            checked={routing === ChatRouting.Random}
                            />
                            <span>Random Assignment</span>
                        </div>
                        <div>
                            <input type='radio' 
                            onClick={()=>setRouting(ChatRouting.Maxutilization)}
                            checked={routing === ChatRouting.Maxutilization}
                            />
                            <span>Max utilization</span>
                        </div>
                        <div>
                            <input type='radio' 
                            onClick={()=>setRouting(ChatRouting.Averageutilization)}
                            checked={routing === ChatRouting.Averageutilization}
                            />
                            <span>Average utilization</span>
                        </div>
                        <div>
                            <input type='radio' 
                            onClick={()=>setRouting(ChatRouting.Ringtoall)} 
                            checked={routing === ChatRouting.Ringtoall}
                            />
                            <span>Ring to all</span>
                        </div>
                        <p>
                            {routingDesc()}
                        </p>
                    </div>
                    {console.log(routing,'routing')}
                    {routing === ChatRouting.Maxutilization || routing === ChatRouting.Averageutilization ? (
                    <>
                      <h5>Agent priorities</h5>
                      <div>
                          <Input style={{padding: '0 0 0 15px', marginBottom: 10}} 
                      />
                      </div>
                      <p>
                      Incoming chat will be assigned to an online agent with free chat slots and with the highest priority (lowest number). Assignment of chat will follow selected chat routing strategy for agents with the same priority.
                      </p>
                    </>) : (" ")
                    }        
                    <h5>Routing time</h5>
                    <div>
                        <Input style={{padding: '0 0 0 15px', marginBottom: 10}} 
                        value='30'
                    />
                    </div>
                    
                    <p>Number of seconds after which chat will be rerouted to another agent.</p>
                    
                    <h5>Inactivity time</h5>
                    <div>
                        <Input style={{padding: '0 0 0 15px', marginBottom: 10}} 
                        value='30'
                    />
                    </div>
                    <p>Number of minutes after which chat will be recognised as inactive and slot will be released for
                    next waiting chat in the queue. Inactive chats remain open till agent end them manually?</p>
                    
                    <h5>Breathing time</h5>
                    <div>
                        <Input style={{padding: '0 0 0 15px', marginBottom: 10}} 
                        value='30'
                    />
                    </div>
                    <p>Timeout in seconds (0 - 600). Temporary sets agent to “Breathing Out” status after finishing chat.</p>
                    
                    <h5>Show customer typing text</h5>
                        <div className=''>
                            <div>
                                <p>Turn on</p>
                            </div>
                            <div className="show_hide">
                                <div className="switch_box box_1"></div>
                            </div>
                        </div>
                    <p>Enable agent to see what customer is typing before the message is sent.</p>

                    <h5>State after chat ends</h5>
                    <InputContainer style={{marginBottom: 10}}>
                        <Input style={{padding: '0 0 0 15px', marginBottom: 10}} 
                            type="text"
                        />
                        <CancelButton className="d-flex">
                            <DropdownWithCircle />
                        </CancelButton>
                        <DropdownUlWrapper>
                            {/* <Downshift>
                                    <div className="automation_scroly">
                                        <DropdownUl>
                                            <DropdownLi>
                                                Answered
                                            </DropdownLi>
                                            <DropdownLi>
                                                Unanswered
                                            </DropdownLi>
                                        </DropdownUl>
                                    </div>
                            </Downshift> */}
                        </DropdownUlWrapper>
                    </InputContainer>
                    <p>After chat ends, it will be automatically switched to selected state.</p>
                    <div>
                        <Button>Save</Button>
                    </div>
                </div>
            </section>
        )
    }

       
export function LiveChat_Agents(){
    const PageTab = {
        Agent : "agents",
        Teams : "teams",
        View : "view",
        AgentPopup : "agentPopup",
        AgentEditPopup : "agenteditPopup",
        TeamPopup:"teamPopup",
        TeamEditPopup:"teamEditPopup"
        } 
    // const[agentPopup, setAgentPopup]= useState<boolean>(false)
    // const[teamPopup, setTeamPopup]= useState<boolean>(false)
    // const[teamEditPopup, setTeamEditPopup]= useState<boolean>(false)
    const[pageStatus,setPagestatus]= useState<string>(PageTab.Agent)
    console.log(pageStatus,'pageStatus')
       
    return(
        <div className='liveAt_settings_agent'>
            <div className='liveAt_settings_agent_title'>
                <h4>Agents</h4>
                <p>List of all the users and their roles.</p>
            </div>
            <div className='liveAt_settings_agent_tab flex'>
                <div className="tab_menu">
                    <a className={pageStatus === PageTab.Agent || pageStatus === PageTab.AgentPopup || pageStatus === PageTab.AgentEditPopup? "active":""} onClick={()=>setPagestatus(PageTab.Agent)}>Agents</a>
                    <a className={pageStatus===PageTab.Teams || pageStatus===PageTab.TeamPopup || pageStatus===PageTab.TeamEditPopup? "active":""} onClick={()=>setPagestatus(PageTab.Teams)}>Teams</a>
                </div>
                <div className="btn_width_wrapper">

                    {pageStatus === PageTab.Agent ? (
                        <Button
                            primary
                            className="btn btn-primary"  
                            onClick = {()=> setPagestatus(PageTab.AgentPopup)}                     
                        >
                            <span className="header_button">
                                <AddIcon />
                            </span>
                            <label>Create agent</label>
                        </Button> ) : (
                        <Button
                            primaryÏ
                            className="btn btn-primary"
                            onClick={()=> setPagestatus(PageTab.TeamPopup)}    
                        >
                            <span className="header_button">
                                <AddIcon />
                            </span>
                            <label>Create team</label>
                        </Button>
                        )}
                </div>
            </div>
            {pageStatus === PageTab.Agent &&
            // <Agent PageTab={PageTab} pageStatus= {pageStatus}setPagestatus={setPagestatus} />
            <div className='liveAt_settings_agent_agentTab' >
                <TabelWrapper >
                    <TableContainer className='liveAt_settings_agent_table'>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th size="2.5">Agent email</Th>
                                    <Th size="2">First name</Th>
                                    <Th size="2">Last Name</Th>
                                    <Th size="1.5">Teams</Th>
                                    <Th size="1.5">Access</Th>
                                    <Th size="1.5">Last active</Th>
                                    <Th size="1">Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr
                                    onClick = {()=> setPagestatus(PageTab.AgentEditPopup)}>
                                    <Td size="2.5">
                                        <div className='td_agentMail'>
                                                <UserIconAnalyticsMenu />
                                            <p>roger.b@acmecorp.com</p>
                                        </div>
                                    </Td>
                                    <Td size="2">Roger</Td>
                                    <Td size="2">Ballmer</Td>
                                    <Td size="1.5">IT Support</Td>
                                    <Td size="1.5">(05)</Td>
                                    <Td size="1.5">
                                        <div className='td_date'>
                                            16 Mar 2023 | 10:36 AM
                                        </div>
                                    </Td>
                                    <Td size="1">
                                        {/* <ToolBox> */}
                                            <span
                                             onClick={(e) => {
                                                e.stopPropagation();
                                              }}>
                                                <EditIcon />
                                            </span>
                                            <span>
                                                <DeleteIcon />
                                            </span>
                                        {/* </ToolBox> */}
                                    </Td>
                                </Tr>
                                {/* <Tr>
                                    <Td size="2.5">
                                        <div className='td_agentMail'>
                                                <UserIconAnalyticsMenu />
                                            <p>lorraine.s@acmecorp.com</p>
                                        </div>
                                    </Td>
                                    <Td size="2">Lorraine</Td>
                                    <Td size="2">Steve</Td>
                                    <Td size="1.5">HR Support</Td>
                                    <Td size="1.5">
                                        <p className="td_agent_access_count">
                                            (05)
                                        </p>
                                    </Td>
                                    <Td size="1.5">
                                        <div className='td_date'>
                                            16 Mar 2023 | 10:36 AM
                                        </div>
                                    </Td>
                                    <Td size="1">
                                    </Td>
                                </Tr> */}
                            </Tbody>
                        </Table>
                        </TableContainer>
                    
                </TabelWrapper>
                    
                </div>
                }                 
                {pageStatus === PageTab.AgentPopup && 
                    <Modal className='create_agent_popup_container'>
                        <div className='create_agent_popup_title'>
                            <h4>Create Agent</h4>                           
                        </div>
                        
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>First name</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Last name</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Email ID</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Team</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Agent role</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <ButtonContainer>
                            <Button primary
                            onClick = {()=>setPagestatus(PageTab.Agent)}>Cancel</Button>
                            <Button>Create agent</Button>
                        </ButtonContainer>
                    </Modal>
                }
                {pageStatus === PageTab.AgentEditPopup && 
                    <Modal className='create_agent_popup_container'>
                        <div className='create_agent_popup_title'>
                            <h4>Create Agent</h4>                           
                        </div>
                        
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>First name</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Last name</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Email ID</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Team</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <div className='form_w100'>
                            <div className='from_input_section'>
                                <div className='form-group '>
                                    <InputContainer>
                                        <Input type="text" />
                                        <InputContent>Agent role</InputContent>
                                    </InputContainer>
                                </div>       
                            </div>
                        </div>
                        <ButtonContainer>
                            <Button primary
                            onClick = {()=>setPagestatus(PageTab.Agent)}>Cancel</Button>
                            <Button>Create agent</Button>
                        </ButtonContainer>
                    </Modal>
                }
                
                        {/* <div className='td_agent_access'>
                        <div>
                            <UserIconAnalyticsMenu />
                            <p>Workplace Bot</p>
                        </div>
                        <div>
                            <UserIconAnalyticsMenu />
                            <p>Live Chat</p>
                        </div>
                        <div>
                            <UserIconAnalyticsMenu />
                            <p>HR Chatbot</p>
                        </div>
                        <div>
                            <UserIconAnalyticsMenu />
                            <p>IT Support Bot</p>
                        </div>
                    </div>
                    </TableContainer>
                    
                </TabelWrapper> */}
                    
            {/* // </div> */}
            {/* // } */}

            {pageStatus === PageTab.Teams &&
            <div className='liveAt_settings_agent_teamsTab'>
                <TabelWrapper >
                    <TableContainer className='liveAt_settings_agent_table teamsTable'>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th size="6">Team name</Th>
                                    <Th size="5">Team members</Th>
                                    <Th size="1">Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td size="6">
                                        IT Support
                                    </Td>
                                    <Td size="5">(03)</Td>
                                    <Td size="1">
                                        {/* <ToolBox>
                                            <span>
                                                <EditIcon />
                                            </span>
                                            <span>
                                                <DeleteIcon />
                                            </span>
                                        </ToolBox> */}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td size="6">
                                        HR Support
                                    </Td>
                                    <Td size="5">(02)</Td>
                                    <Td size="1">
                                    </Td>
                                </Tr>
                                
                            </Tbody>
                        </Table>
                    </TableContainer>
                </TabelWrapper>
            </div>
            }
            {pageStatus === PageTab.TeamPopup && 
                <Modal className='create_team_popup_container'>
                    <div className='create_team_popup_title'>
                        <h4>Create Team</h4>                           
                    </div>
                    <InputContainer className="mb-3">
                        <Input
                            type="text"
                        />
                        <InputContent>Team name</InputContent>
                    </InputContainer>
                    <InputContainer className="mb-3">
                        <Input
                            type="text"
                        />
                        <InputContent>Team members</InputContent>
                        <CancelButton>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                        <ButtonContainer styel={{paddingBottom: 0}}>
                            <Button 
                            primary
                            onClick={()=> setPagestatus(PageTab.Teams)}    

                            >Cancel</Button>
                            <Button>Create team</Button>
                        </ButtonContainer>
                </Modal>
                }
                {pageStatus === PageTab.TeamEditPopup && 
                <Modal className='create_team_popup_container'>
                    <div className='create_team_popup_title'>
                        <h4>Edit Team</h4>                           
                    </div>
                    <InputContainer className="mb-3">
                        <Input
                            type="text"
                        />
                        <InputContent>Team name</InputContent>
                    </InputContainer>
                    <InputContainer className="mb-3">
                        <Input
                            type="text"
                        />
                        <InputContent>Team members</InputContent>
                        <CancelButton>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                        <ButtonContainer styel={{paddingBottom: 0}}>
                            <Button 
                            primary
                            onClick={()=> setPagestatus(PageTab.Teams)}    

                            >Cancel</Button>
                            <Button>Edit team</Button>
                        </ButtonContainer>
                </Modal>
                }

        </div>
    )
}

export function Business_Hours(){
    return(
        <div className="profile_form-page liveAt_settings">
            <h4>Select Time Zone</h4>
            <InputContainer style={{width: '60%', marginBottom: 10}}>
                <Input style={{padding: '0 0 0 15px', height: '32px', marginBottom: 10}} 
                    type="text"
                />
                <CancelButton className="d-flex" style={{top: 8}}>
                    <DropdownWithCircle />
                </CancelButton>
                <DropdownUlWrapper>
                </DropdownUlWrapper>
            </InputContainer>
            <h4>Enter information about your business hours</h4>
            <div className='liveat_hours_day_box flex'>
                <div className='flex'>
                    <p>Monday</p>
                    <div className='liveat_hours_day flex'>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <span><DeleteIcon /></span>
                        <span><PlusIcon /></span>
                    </div>
                    <p></p>
                    <div className='liveat_hours_day flex'>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <span><DeleteIcon /></span>
                        <span><PlusIcon /></span>
                    </div>                    
                </div>   
                <div className='flex'>
                    <p>Tuesday</p>
                    <div className='liveat_hours_day flex'>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <InputContainer style={{marginBottom: 0, width: '140px'}}>
                            <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            />
                            <CancelButton className="d-flex">
                                <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                            </DropdownUlWrapper>
                        </InputContainer>
                        <span><DeleteIcon /></span>
                        <span><PlusIcon /></span>
                    </div>
                </div>  
                <div className='flex'>
                    <p>Saturday</p>
                    <div className='liveat_hours_day flex'>
                        <InputContainer className="liveat_hours_day_off">
                            {/* <Input style={{padding: '0 0 0 15px'}} 
                                type="text"
                            /> */}
                            <span>Day off</span>
                        </InputContainer>              
                        
                        <span><PlusIcon /></span>
                    </div>
                </div>               
                
            </div>
            <ButtonContainer className="p-0 justify-content-start" style={{ marginTop: 0 }}>                            
                <Button  primary>
                    <label>Cancel</label>
                </Button>
                <Button>
                    <label>Save</label>
                </Button>
            </ButtonContainer>                   
        </div>
    )
}

export function Chat_Channels(){
    return(
        <section className="entity_cards intr_chat_cards">
            <div className="col-md-12">
                <div className="chat_channel_row">
                    <div className='col-3 intr_chat_cards_bg'>
                        <div className="chat_channel_container">
                            <div className="chat_channel_img team_integation">
                                <SlackIconCC />
                            </div>
                            <div className="intr_chat_cards_cont">
                                <h2>Slack</h2>
                            </div>
                            <Button>
                            <span><LAPlusIcon /></span>
                            <label>Add Connection</label>
                        </Button>
                        </div>
                    </div>
                    <div className='col-3 intr_chat_cards_bg'>
                        <div className="chat_channel_container">
                            <div className="chat_channel_img team_integation">
                                <WidgetIcon />
                            </div>
                            <div className="intr_chat_cards_cont">
                                <h2>Chat Widget</h2>
                            </div>
                            <Button>
                            <span><LAPlusIcon /></span>
                            <label>Add Connection</label>
                        </Button>
                        </div>
                    </div>
                    <div className='col-3 intr_chat_cards_bg'>
                        <div className="chat_channel_container">
                            <div className="chat_channel_img team_integation">
                                <TeamsIcon />
                            </div>
                            <div className="intr_chat_cards_cont">
                                <h2>Microsoft Teams</h2>
                            </div>
                            <Button>
                            <span><LAPlusIcon /></span>
                            <label>Add Connection</label>
                        </Button>
                        </div>
                    </div>
                    <div className='col-3 intr_chat_cards_bg'>
                        <div className="chat_channel_container">
                            <div className="chat_channel_img team_integation">
                                <SlackIconCC />
                            </div>
                            <div className="intr_chat_cards_cont">
                                <h2>Whatsapp</h2>
                            </div>
                            <Button>
                            <span><LAPlusIcon /></span>
                            <label>Add Connection</label>
                        </Button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export function Slas(){
    return(
        <div className='liveAt_settings_canned'>
            <div className='liveAt_settings_can_title_container flex'>
                <div className='liveAt_settings_can_title'>
                    <h5>Set SLA limit</h5>
                    <h5>Time to first response</h5>
                    <input type="number" />
                    <p>Time to first response in seconds (0 - 600).</p>
                    <h5>Time to close</h5>
                    <input type="number" />
                    <p>Time to close in seconds (0 - 600).</p>
                </div>                    
            </div>                
        </div>
    )
}

export function Canned_reponses(){
    return(
        <div className='liveAt_settings_canned'>
                <div className='liveAt_settings_can_title_container flex'>
                    {/* <div className='liveAt_settings_can_title'>
                        <h4>Canned Responses</h4>
                        <p>List of all the canned responses and you can create, edit and delete.</p>
                    </div> */}
                    <div className="btn_width_wrapper">
                        <Button
                            primary
                            className="btn btn-primary"                          
                        >
                            <span className="header_button">
                                <AddIcon />
                            </span>
                            <label>Create New</label>
                        </Button>
                    </div>
                </div>
                <div>
                    <TabelWrapper>
                        <TableContainer>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th size="1.5">S.No</Th>
                                        <Th size="7">Name</Th>
                                        <Th size="2.5">Created date</Th>
                                        <Th size="1">Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td size="1.5">01</Td>
                                        <Td size="7">Howdy! Thanks for messaging us! How can I help you?</Td>
                                        <Td size="2.5">
                                            <div className='td_date'>
                                                16 Mar 2023 | 10:36 AM
                                            </div>
                                        </Td>
                                        <Td size="1">
                                            {/* <ToolBox>
                                                <span>
                                                    <EditIcon />
                                                </span>
                                                <span>
                                                    <DeleteIcon />
                                                </span>
                                            </ToolBox> */}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td size="1.5">02</Td>
                                        <Td size="7">Could you please send me your ticket id?</Td>
                                        <Td size="2.5">
                                            <div className='td_date'>
                                                16 Mar 2023 | 10:36 AM
                                            </div>
                                        </Td>
                                        <Td size="1">
                                            
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td size="1.5">03</Td>
                                        <Td size="7">
                                            <div className='flex'>
                                                Please check this help article ” 
                                                <div>
                                                    <a>Printer Issue</a>
                                                </div> ”
                                            </div>
                                        </Td>
                                        <Td size="2.5">
                                            <div className='td_date'>
                                                16 Mar 2023 | 10:36 AM
                                            </div>
                                        </Td>
                                        <Td size="1">
                                            
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </TabelWrapper>
                </div>
            </div>
    )
}

//<div className='liveAt_settings_agent'  style={{display: 'none'}}>
                // <div className='liveAt_settings_agent_title'>
                //     <h4>Agents</h4>
                //     <p>List of all the users and their roles.</p>
                // </div>
                // <div className='liveAt_settings_agent_tab flex'>
                //     <div className="tab_menu">
                //         <a className="active">Agents</a>
                //         <a className="">Teams</a>
                        
                //     </div>
                //     <div className="btn_width_wrapper">
                //         <Button
                //             primary
                //             className="btn btn-primary"  
                //             // style={{display: 'none'}}                        
                //         >
                //             <span className="header_button">
                //                 <AddIcon />
                //             </span>
                //             <label>Create agent</label>
                //         </Button>
                //         <Button
                //             primary
                //             className="btn btn-primary"    
                //             style={{display: 'none'}}                        
                //         >
                //             <span className="header_button">
                //                 <AddIcon />
                //             </span>
                //             <label>Create team</label>
                //         </Button>
                //     </div>
                // </div>
                // <div className='liveAt_settings_agent_agentTab' >
                //     <TabelWrapper >
                //         <TableContainer className='liveAt_settings_agent_table'>
                //             <Table>
                //                 <Thead>
                //                     <Tr>
                //                         <Th size="2.5">Agent email</Th>
                //                         <Th size="2">First name</Th>
                //                         <Th size="2">Last Name</Th>
                //                         <Th size="1.5">Teams</Th>
                //                         <Th size="1.5">Access</Th>
                //                         <Th size="1.5">Last active</Th>
                //                         <Th size="1">Actions</Th>
                //                     </Tr>
                //                 </Thead>
                //                 <Tbody>
                //                     <Tr>
                //                         <Td size="2.5">
                //                             <div className='td_agentMail'>
                //                                     <UserIconAnalyticsMenu />
                //                                 <p>roger.b@acmecorp.com</p>
                //                             </div>
                //                         </Td>
                //                         <Td size="2">Roger</Td>
                //                         <Td size="2">Ballmer</Td>
                //                         <Td size="1.5">IT Support</Td>
                //                         <Td size="1.5">(05)</Td>
                //                         <Td size="1.5">
                //                             <div className='td_date'>
                //                                 16 Mar 2023 | 10:36 AM
                //                             </div>
                //                         </Td>
                //                         <Td size="1">
                                            {/* <ToolBox>
                                                <span>
                                                    <EditIcon />
                                                </span>
                                                <span>
                                                    <DeleteIcon />
                                                </span>
                                            </ToolBox> */}
                                        {/* </Td>
                                    </Tr>
                                    <Tr>
                                        <Td size="2.5">
                                            <div className='td_agentMail'>
                                                    <UserIconAnalyticsMenu />
                                                <p>lorraine.s@acmecorp.com</p>
                                            </div>
                                        </Td>
                                        <Td size="2">Lorraine</Td>
                                        <Td size="2">Steve</Td>
                                        <Td size="1.5">HR Support</Td>
                                        <Td size="1.5">
                                            <p className="td_agent_access_count">
                                                (05)
                                            </p>
                                        </Td>
                                        <Td size="1.5">
                                            <div className='td_date'>
                                                16 Mar 2023 | 10:36 AM
                                            </div>
                                        </Td>
                                        <Td size="1">
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                            <div className='td_agent_access'>
                            <div>
                                <UserIconAnalyticsMenu />
                                <p>Workplace Bot</p>
                            </div>
                            <div>
                                <UserIconAnalyticsMenu />
                                <p>Live Chat</p>
                            </div>
                            <div>
                                <UserIconAnalyticsMenu />
                                <p>HR Chatbot</p>
                            </div>
                            <div>
                                <UserIconAnalyticsMenu />
                                <p>IT Support Bot</p>
                            </div>
                        </div>
                        </TableContainer>
                        
                    </TabelWrapper>
                        
                </div>
                <div className='liveAt_settings_agent_teamsTab' style={{display: 'none'}}>
                    <TabelWrapper >
                        <TableContainer className='liveAt_settings_agent_table teamsTable'>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th size="6">Team name</Th>
                                        <Th size="5">Team members</Th>
                                        <Th size="1">Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td size="6">
                                            IT Support
                                        </Td>
                                        <Td size="5">(03)</Td>
                                        <Td size="1">
                                            {/* <ToolBox>
                                                <span>
                                                    <EditIcon />
                                                </span>
                                                <span>
                                                    <DeleteIcon />
                                                </span>
                                            </ToolBox> */}
        //                                 </Td>
        //                             </Tr>
        //                             <Tr>
        //                                 <Td size="6">
        //                                     HR Support
        //                                 </Td>
        //                                 <Td size="5">(02)</Td>
        //                                 <Td size="1">
        //                                 </Td>
        //                             </Tr>
                                    
        //                         </Tbody>
        //                     </Table>
        //                 </TableContainer>
        //             </TabelWrapper>
        //         </div>

        //     </div>

        //     <div className='liveAt_settings_canned' style={{display: 'none'}}>
        //         <div className='liveAt_settings_can_title_container flex'>
        //             <div className='liveAt_settings_can_title'>
        //                 <h4>Canned Responses</h4>
        //                 <p>List of all the canned responses and you can create, edit and delete.</p>
        //             </div>
        //             <div className="btn_width_wrapper">
        //                 <Button
        //                     primary
        //                     className="btn btn-primary"                          
        //                 >
        //                     <span className="header_button">
        //                         <AddIcon />
        //                     </span>
        //                     <label>Create New</label>
        //                 </Button>
        //             </div>
        //         </div>
        //         <div>
        //             <TabelWrapper>
        //                 <TableContainer>
        //                     <Table>
        //                         <Thead>
        //                             <Tr>
        //                                 <Th size="8.5">Name</Th>
        //                                 <Th size="2.5">Created date</Th>
        //                                 <Th size="1">Actions</Th>
        //                             </Tr>
        //                         </Thead>
        //                         <Tbody>
        //                             <Tr>
        //                                 <Td size="8.5">Howdy! Thanks for messaging us! How can I help you?</Td>
        //                                 <Td size="2.5">
        //                                     <div className='td_date'>
        //                                         16 Mar 2023 | 10:36 AM
        //                                     </div>
        //                                 </Td>
        //                                 <Td size="1">
        //                                     {/* <ToolBox>
        //                                         <span>
        //                                             <EditIcon />
        //                                         </span>
        //                                         <span>
        //                                             <DeleteIcon />
        //                                         </span>
        //                                     </ToolBox> */}
        //                                 </Td>
        //                             </Tr>
        //                             <Tr>
        //                                 <Td size="8.5">Could you please send me your ticket id?</Td>
        //                                 <Td size="2.5">
        //                                     <div className='td_date'>
        //                                         16 Mar 2023 | 10:36 AM
        //                                     </div>
        //                                 </Td>
        //                                 <Td size="1">
                                            
        //                                 </Td>
        //                             </Tr>
        //                             <Tr>
        //                                 <Td size="8.5">
        //                                     <div className='flex'>
        //                                         Please check this help article ” 
        //                                         <div>
        //                                             <a>Printer Issue</a>
        //                                         </div> ”
        //                                     </div>
        //                                 </Td>
        //                                 <Td size="2.5">
        //                                     <div className='td_date'>
        //                                         16 Mar 2023 | 10:36 AM
        //                                     </div>
        //                                 </Td>
        //                                 <Td size="1">
                                            
        //                                 </Td>
        //                             </Tr>
        //                         </Tbody>
        //                     </Table>
        //                 </TableContainer>
        //             </TabelWrapper>
        //         </div>
        //     </div>
        // </section>
        {/* <Modal className='create_agent_popup_container'>
            <div className='create_agent_popup_title'>
                <h4>Create Agent</h4>                           
            </div>
                
                <div className='form_w100'>
                    <div className='from_input_section'>
                        <div className='form-group '>
                            <InputContainer>
                                <Input type="text" />
                                <InputContent>First name</InputContent>
                            </InputContainer>
                        </div>       
                    </div>
                    <div className='from_input_section'>
                        <div className='form-group '>
                            <InputContainer>
                                <Input type="text" />
                                <InputContent>Last name</InputContent>
                            </InputContainer>
                        </div>       
                    </div>
                </div>
                <div className='form_w100'>
                    <div className='from_input_section'>
                        <div className='form-group '>
                            <InputContainer>
                                <Input type="text" />
                                <InputContent>Email ID</InputContent>
                            </InputContainer>
                        </div>       
                    </div>
                    <div className='from_input_section'>
                        <div className='form-group '>
                            <InputContainer>
                                <Input type="text" />
                                <InputContent>Team</InputContent>
                            </InputContainer>
                        </div>       
                    </div>
                </div>
                <div className='form_w100'>
                    <div className='from_input_section'>
                        <div className='form-group '>
                            <InputContainer>
                                <Input type="text" />
                                <InputContent>Agent role</InputContent>
                            </InputContainer>
                        </div>       
                    </div>
                </div>
                <ButtonContainer>
                    <Button primary>Cancel</Button>
                    <Button>Create agent</Button>
                </ButtonContainer>
        </Modal> */}
        {/* <Modal className='create_team_popup_container'>
            <div className='create_team_popup_title'>
                <h4>Create Team</h4>                           
            </div>
            <InputContainer className="mb-3">
                <Input
                    type="text"
                />
                <InputContent>Team name</InputContent>
            </InputContainer>
            <InputContainer className="mb-3">
                <Input
                    type="text"
                />
                <InputContent>Team members</InputContent>
                <CancelButton>
                    <DropdownWithCircle />
                </CancelButton>
            </InputContainer>
                <ButtonContainer styel={{paddingBottom: 0}}>
                    <Button primary>Cancel</Button>
                    <Button>Create team</Button>
                </ButtonContainer>
        </Modal> */}
//         </>
//     )
// }</section>