/*eslint-disable*/
import * as React from 'react'

import { ErrorWrapper } from '@/common/components/ErrorBoundary'

import _404Img from '@/common/images/404error.png'

export const NotFoundPage: React.SFC = () => {
    return (
        <ErrorWrapper>
            <img src={_404Img} text-align="middle" alt="nodata_found" title="nodata_found" />
            <p className="first_parah_error_text">Error 404</p>
            <p>Page not found</p>
            <div className="nodata_found_button">
                <button type="submit" className="btn btn-primary" onClick={() => window.history.back()}>
                    Go back
                </button>
            </div>
        </ErrorWrapper>
    )
}
