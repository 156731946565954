/*eslint-disable*/
import React, { useEffect } from 'react';
import {
  TabContainer,
  TabHeader,
  TabRow,
  TabLeft,
  TabRight,
} from '@/Flows/analytics/logs';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { dialogNavlink, useSubTabHook } from './SubTabHook';

export function SingleAssistant(props: any) {
  const { match } = props;
  const { assistantname, workspacename } = match.params;

  const [currentSubTab] = useSubTabHook(props);

  const navigate = useNavigate()

  useEffect(()=>{
    if(location.pathname == `/workspace/${workspacename}/assistant/${assistantname}`){
      navigate(`/workspace/${workspacename}/assistant/${assistantname}/conversations`,{ replace:true })
    }
  },[])

  return (
    <>
      <TabContainer className="sub_menu_section">
        <TabRow className="sub_menu">
          <TabLeft>
            {dialogNavlink.submenu.map((sub,index) => {
              const sublink = sub.displayText.replace(/ /g, "-").toLowerCase()
              return (
                <TabHeader key={index} active={currentSubTab == sublink}>
                  <Link
                    className={currentSubTab == sublink ? 'active' : ''}
                    to={`/workspace/${workspacename}/assistant/${assistantname}${sub.to}`}
                  >
                    {sub.displayText}
                  </Link>
                </TabHeader>
              );
            })}
          </TabLeft>
          <TabRight>
            <div id="assistant-sub-header"></div>
          </TabRight>
        </TabRow>
      </TabContainer>
      <Outlet />
    </>
  );
}
