import { Stripe, loadStripe } from '@stripe/stripe-js'

let stripePromise: Promise<Stripe | null>
export const getStripe = () => {
    if (!stripePromise) {
        const key = process.env.REACT_APP_STRIPE_KEY
        stripePromise = loadStripe(key ? key : '')
    }
    return stripePromise
}

export const stripeUTCDate = (stripeUnixDate: number) => {
    return new Date(stripeUnixDate * 1000)
}
export const billingEmail = 'sales@wokativ.com'
export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}
