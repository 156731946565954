/*eslint-disable*/
import produce from 'immer'
import React, { useContext, useEffect, useState } from 'react'

import { getOutputValueRecursive, getValuesBetweenBraces } from '@/Flows/_utils'
import {
    AttachmentIcon,
    DeleteIcon,
    DownarrowWithCircle,
    EditIcon,
    FlowCanvasContextIcons,
    RightArrowForAutomationErrors,
    TickIcon
} from '@/common/Icons/Icons'
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage'
import {
    ApprovalIcon,
    ForEachNodeIcon,
    FunctionNodeIcon,
    NoTriggerVariable,
    PasswordEyeClose,
    PasswordEyeOpen,
    PlusIcon,
    WarningAlertIcon
} from '@/common/Icons/Workflow.Icons'
import { Modal } from '@/common/components/Modal'
import lazyGA from '@/common/google-analytics/tracking'
import alphabet_img from '@/common/images/alphabet.svg'
import cal_img from '@/common/images/cal.svg'
import number_img from '@/common/images/num.svg'
import boolean_img from '@/common/images/true_false.svg'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'

import { convertObjectToArray } from '@/common/utils/utils'
import { AutomationApi, automationAPIURLWithWorkspace, getJson, putJson } from '@/common/utils/api-utils'
import { SidePanelContext } from './Canvas'
import { FileDefaultObject, InputKeyFormControl, makeDefaultInputKey } from './NoTriggerPanel'
import {
    ActionInputsListT,
    AuthType,
    CollectionOutputListTypeT,
    ConfigPropertiesTypeT,
    ContextPopupStateT,
    InputFieldT,
    InputKey,
    NodeMode,
    NodeT,
    ObjectOutputListTypeT,
    OutpuTypeT,
    OutputListTypeT,
    PathIdType,
    PopupError,
    ResponseOutputT,
    TreeT,
    removeChildrenContextVariables
} from './types'

const Parser = require('html-react-parser')
const isHTML = require('is-html')

export function reducePath(inputs: string[]): string {
    const reducedString = inputs.reduce((acc, curr) => {
        if (curr.length === 0 || curr == 'properties') return acc
        return acc + '.' + curr
    }, '')

    return reducedString
}

const systemVariables = [ 
             {
              "$id": "SYS_FirstName",
              "type": "string",
            },
           {
              "$id": "SYS_LastName",
              "type": "string",
            },
            {
                "$id": "SYS_UserEmail",
                "type": "string",
            } 
  ]

const ApprovalOutput = {
    id: '',
    __esModule: true,
    name: 'Approval',
    input: [],
    type: 'approval',
    output: {
        properties: {
            requester_name: {
                examples: ['John Doe'],
                type: 'string',
                title: 'Requested by name',
                $id: '/properties/requester_name'
            },
            requester_email: {
                examples: ['johndoe@example.com'],
                type: 'string',
                title: 'Requested by email',
                $id: '/properties/requester_email'
            },

            requested_time: {
                examples: ['2021-10-07T03:37:47.593Z'],
                type: 'date',
                title: 'Time of request',
                $id: '/properties/requested_time'
            },
            approved: {
                examples: ['true'],
                type: 'boolean',
                title: 'Approved',
                $id: '/properties/approved'
            },
            rejected: {
                examples: ['false'],
                type: 'boolean',
                title: 'Rejected',
                $id: '/properties/rejected'
            },
            expired: {
                examples: ['false'],
                type: 'boolean',
                title: 'Expired',
                $id: '/properties/expired'
            },
            approval_expiry: {
                examples: ['1day 30min'],
                type: 'date',
                title: 'Expiry Date',
                $id: '/properties/approval_expiry'
            },
            comments: {
                examples: ['Request approved'],
                type: 'string',
                title: 'Approver Comments',
                $id: '/properties/comments'
            },
            approval_time: {
                examples: ['2021-10-07T03:38:53.495Z'],
                type: 'date',
                title: 'Approval Time',
                $id: '/properties/approval_time'
            },
            assigned_to: {
                examples: ['Aaron'],
                type: 'string',
                title: 'Assigned to',
                $id: '/properties/assigned_to'
            }
        },
        type: 'object',
        title: 'Approval'
    }
}

export const configApiCall = async function getConfigDetails(
    actionAppsList: { output: ResponseOutputT[] },
    parentNode: TreeT<NodeT>,
    isEditMode: boolean,
    workspaceName: string,
    addingFlowInsideForEach: boolean,
    forContextVariablePopup?: boolean
) {
    if (!parentNode || !parentNode.value.path || parentNode.value.path.length === 0) return []

    const path =
        isEditMode && parentNode.value.kind !== 'Condition' && parentNode.value.kind !== 'Notification'
            ? !addingFlowInsideForEach && parentNode.value.kind == 'ForEach'
                ? parentNode.value.path
                      .filter((x, i) => i + 1 != parentNode.value.path.length)
                      .filter((x, i) => i + 1 != parentNode.value.path.length - 1)
                : parentNode.value.path
                      .filter((x, i) => {
                          if (
                              x.forEachId &&
                              parentNode.value.meta_data.subflow &&
                              parentNode.value.meta_data.subflow.hasSubFlow &&
                              x.nodeId != parentNode.value.meta_data.subflow.forEachNodeId
                          ) {
                              return null
                          } else if (i + 1 == parentNode.value.path.length) {
                              return null
                          }
                          return x
                      })
                      .filter(Boolean)
            : //   filter is only for for-each
              parentNode.value.path
                  .filter((x, i) => {
                      if (!addingFlowInsideForEach && x.forEachId) {
                          return null
                      } else if (
                          !addingFlowInsideForEach &&
                          parentNode.value.kind == 'ForEach' &&
                          i + 1 == parentNode.value.path.length
                      ) {
                          return null
                      } else if (
                          x.forEachId &&
                          parentNode.value.meta_data.subflow &&
                          parentNode.value.meta_data.subflow.hasSubFlow &&
                          x.nodeId != parentNode.value.meta_data.subflow.forEachNodeId
                      ) {
                          return null
                      }
                      return x
                  })
                  .filter(Boolean)

    let updatedPath: {
        actionId: string
        nodeId: string
        approvalId?: string
        forEachId?: string | undefined
        forEachName?: string | undefined
        forEachNodeId?: string | undefined
        forEachSubflow?:
            | {
                  actionId: string
                  nodeId: string
                  forEachId?: string | undefined
                  forEachName?: string | undefined
                  forEachNodeId?: string | undefined
              }[]
            | undefined
    }[] = []

    if (forContextVariablePopup) {
        const forEachFiltered = path
            .filter(p => p.forEachId)
            .map(p => {
                const insideForEachChild = path.filter(t => t.forEachNodeId && t.forEachNodeId == p.nodeId)
                if (insideForEachChild.length > 0) {
                    return { ...p, forEachSubflow: insideForEachChild }
                }
                return p
            })

        updatedPath = path
            .map(p => {
                const updatingForEach = forEachFiltered.filter(t => t.nodeId == p.nodeId)
                if (updatingForEach.length > 0) {
                    return updatingForEach[0]
                }
                return p
            })
            // .filter(p => !p.forEachNodeId)

    } else {
        updatedPath = path
    }

    const apiCalls = updatedPath.map(async actionId => {
        if (actionId.approvalId) {
            return {
                type: 'success',
                output: { ...ApprovalOutput, id: actionId.approvalId }
            }
        }
        if (actionId.forEachId) {
            if (actionId.forEachSubflow && actionId.forEachSubflow.length > 0) {
                const resolved = await Promise.all(
                    actionId.forEachSubflow.map(a =>
                        getJson(automationAPIURLWithWorkspace(workspaceName, `/actions/${a.actionId}/config`))
                    )
                )
                return {
                    ...actionId,
                    forEachSubflow: resolved
                }
            }
            return actionId
        }
        return getJson(automationAPIURLWithWorkspace(workspaceName, `/actions/${actionId.actionId}/config`))
    })

    return Promise.all(apiCalls)
        .then((resposne: ActionInputsListT[]) => {
            const filteredValue = (config: ActionInputsListT[], collection: string, app: any) => {
                const filt = config
                    .map(resp => {
                        if (resp.output) {
                            const op = resp.output.output as ObjectOutputListTypeT

                            const collectionOutput = getOutputValueRecursive(
                                op,
                                getValuesBetweenBraces(collection)[0]
                                    .split('.output.')[1]
                                    .split('.')
                            )

                            if (collectionOutput) return { value: collectionOutput, app: app }
                        }
                        return null
                    })
                    .filter(Boolean)
                return filt[0]
            }

            const updatedResponse =
                resposne.length > 0
                    ? resposne
                          .map((output: any, index: number) => {
                              if (output.forEachId) {
                                  const collectionNode = path.filter(
                                      node => node.nodeId == output.forEachId.split('.output.')[0]
                                  )

                                  if (collectionNode.length > 0) {
                                      const app = {
                                          ...actionAppsList.output.filter(
                                              (x: ResponseOutputT, i: number) => x.id === collectionNode[0].actionId
                                          )[0],
                                          pathId: { ...output }
                                      }

                                      const finalValue = filteredValue(resposne.slice(0, index), output.forEachId, app)

                                      if (finalValue) {
                                          const path = finalValue.value.$id.split('/')
                                          return {
                                              type: 'success',
                                              output: {
                                                  output: {
                                                      name: finalValue.value.title,
                                                      type: 'object',
                                                      properties: {
                                                          [path[path.length - 1]]: {
                                                              ...finalValue.value,
                                                              showForEachCollection: true
                                                          }
                                                      }
                                                  },
                                                  forEachSubflow:
                                                      output.forEachSubflow &&
                                                      output.forEachSubflow.map((op: any, ind: number) => {
                                                          const forEach = updatedPath.filter(
                                                              f => f.forEachId == output.forEachId
                                                          )[0]

                                                          const app = {
                                                              ...actionAppsList.output.filter(
                                                                  (x: ResponseOutputT, i: number) =>
                                                                      x.id === op.output.id
                                                              )[0],
                                                              pathId:
                                                                  forEach &&
                                                                  forEach.forEachSubflow &&
                                                                  forEach.forEachSubflow[ind]
                                                          }

                                                          return {
                                                              ...op,
                                                              output: {
                                                                  ...op.output,
                                                                  app:
                                                                      app.type == 'function'
                                                                          ? { ...app, icon: FunctionNodeIcon }
                                                                          : app
                                                              }
                                                          }
                                                      }),
                                                  forEachCollection: true,
                                                  app: { ...app, icon: ForEachNodeIcon }
                                              }
                                          }
                                      }
                                  }
                                  return null
                              }
                              const app = {
                                  ...(actionAppsList &&
                                      actionAppsList.output.filter(
                                          (x: ResponseOutputT, i: number) =>
                                              x.id === parentNode.value.path[index].actionId
                                      )[0]),
                                  pathId: parentNode.value.path[index]
                              }

                              return {
                                  ...output,
                                  output: {
                                      ...output.output,
                                      app:
                                          app.type == 'function'
                                              ? { ...app, icon: FunctionNodeIcon }
                                              : output.output.type == 'approval'
                                              ? { ...app, type: 'approval', icon: ApprovalIcon }
                                              : app
                                  }
                              }
                          })
                          .filter(Boolean)
                    : []
            // for removing outside node outputs from for-each subflow nodes
            return addingFlowInsideForEach
                ? updatedResponse
                      .filter((op: any, i) => {
                          if (
                              ((op.output.app.pathId.forEachId && op.output.app.pathId.nodeId) ||
                                  (op.output.app.pathId.forEachNodeId && op.output.app.pathId.forEachNodeId)) ==
                              (parentNode.value.kind == 'ForEach'
                                  ? parentNode.value.id
                                  : parentNode.value.meta_data.subflow.forEachNodeId)
                          ) {
                              return op
                          }
                          return null
                      })
                      .filter(Boolean)
                : updatedResponse
        })
        .catch(err => {
            throw err
        })
}

export function ContextPopup(props: any) {
    const { updateInputValuesWithContext } = props

    const [outPutsResponse, setOutPutsResponse] = useState<ActionInputsListT[]>([])

    const workspaceName: string = props.match.params.workspacename

    useEffect(() => {
        const triggerNodeOutput = props.triggerNode as NodeT
        if (props.tree.value.kind !== 'AppTrigger' && props.tree.value.kind !== 'EventTrigger') {
            const output: ActionInputsListT[] = [
                {
                    type: 'success',
                    output: {
                        name: triggerNodeOutput.name,
                        input: [],
                        app: {
                            appName: triggerNodeOutput.name,
                            action: triggerNodeOutput.kind,
                            pathId: {
                                nodeId: triggerNodeOutput.id,
                                actionId: ''
                            },
                            type: 'trigger',
                            appId: '',
                            createdDate: '',
                            file: '',
                            authentication_type: AuthType.None,
                            id: '',
                            scope: '',
                            icon: NoTriggerVariable,
                            latest: false,
                            version: ''
                        },
                        output: triggerNodeOutput.output
                    }
                }
            ]

            props.parentNode.value.path.length > 0
                ? configApiCall(
                      props.actionAppsList,
                      props.parentNode,
                      props.mode.mode === NodeMode.Edit,
                      workspaceName,
                      props.addingFlowInsideForEach,
                      true
                  ).then((response: ActionInputsListT[]) => {
                      setOutPutsResponse(props.addingFlowInsideForEach ? response : output.concat(response))
                  })
                : setOutPutsResponse(output)
        } else {
            const firstStepNode = props.tree.value
            const parentNode = {
                ...props.parentNode,
                value: {
                    ...props.parentNode.value,
                    path: props.parentNode.value.path
                        ? [
                              {
                                  actionId: firstStepNode.meta_data.action_id,
                                  nodeId: firstStepNode.id
                              }
                          ].concat(props.parentNode.value.path)
                        : [
                              {
                                  actionId: firstStepNode.meta_data.action_id,
                                  nodeId: firstStepNode.id
                              }
                          ]
                }
            }
            configApiCall(
                props.actionAppsList,
                parentNode,
                props.mode.mode === NodeMode.Edit,
                workspaceName,
                props.addingFlowInsideForEach,
                true
            ).then((response: ActionInputsListT[]) => {
                setOutPutsResponse(response)
            })
        }
    }, [])

    function updateValue(input: any) {
        updateInputValuesWithContext(input)
    }

    function outterClick(e: any) {
        // let div = document.getElementsByClassName('context_popup')
        // const modal = document.getElementsByClassName('modal-content center')
        // const ele1 = document.getElementById('editor-input')
        // console.log(ele1, e.target)
        // ;((div && !div[0].contains(e.target) && !e.target.className.includes('variable_update') && modal.length == 0) ||
        //     (modal.length && !modal[0].contains(e.target)) ||
        //     !ele1 ||
        //     ele1.contains(e.target) == false) &&
        //     props.closeContextPopup()

        let div = document.getElementsByClassName('context_popup')
        const modal = document.getElementsByClassName('modal-content center')
        ;((div && !div[0].contains(e.target) && !e.target.className.includes('variable_update') && !e.target.className.includes("message_toolbar_input") && modal.length == 0) ||
            (modal.length && !modal[0].contains(e.target))) &&
            props.closeContextPopup()
    }

    return (
        <div className="flow_popup1 overlay_message_toolbar">
            <div data-ml-modal="">
                <div className="modal-overlay context_popup_overlay" onClick={outterClick}>
                    <section className="context_popup">
                        <div className="context_popup_width">
                            <div className="context_popup_head">
                                <h3>Variable Picker</h3>
                                <p>Select input variables to use in your workflow input field</p>
                            </div>
                            <div style={{ marginTop: '46px' }} />
                            {props.showDialogVariables&&
                                <DialogVariables
                                    updateValue={updateValue}
                                    {...props}
                                />
                            }
                            {outPutsResponse.map((output, i) => {
                                if (output.type === 'success') {
                                    const op = output as any

                                    return (
                                        <ExpandedOutputView
                                            updateValue={updateValue}
                                            {...props}
                                            treeData={output}
                                            key={i}
                                            isTriggerOrNoTrigger={i == 0 && true}
                                            isWebhook={op.output.app &&(op.output.app.action == "WebhookTrigger"||op.output.app.action == "NoTrigger")}
                                        />
                                    )
                                }
                                return null
                            })}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

function ExpandedOutputView(props: {
    updateValue: (input: any) => void
    treeData: ActionInputsListT
    isTriggerOrNoTrigger: boolean
    isWebhook?: boolean
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
}) {
    const [isOpen, toggleOpen] = useState(false)

    return (
        <div
            className={
                props.isTriggerOrNoTrigger
                    ? !isOpen
                        ? 'context_popup_accordian step1_background'
                        : 'context_popup_accordian_open node_output_first_child step1_background'
                    : !isOpen
                    ? 'context_popup_accordian_open context_popup_accordian_close'
                    : 'context_popup_accordian_open context_popup_accordian_open_margin_zero'
            }
        >
            <div className="context_join_output variabel_picker_title">
                <NodeOutputDetailHeader
                    isOpen={isOpen}
                    toggleOpen={() => toggleOpen(!isOpen)}
                    app={props.treeData.output.app && props.treeData.output.app}
                />
                {/* search */}
                {isOpen && props.isWebhook ? (
                    <NodeOutputWebhookDetailBody {...props} treeData={props.treeData.output} />
                ) : (
                    isOpen && <NodeOutputDetailBody {...props} treeData={props.treeData.output} />
                )}
            </div>
        </div>
    )
}

function DialogVariables(props: {
    updateValue: (input: any) => void
}) {
    const [isOpen, toggleOpen] = useState(false)

    return (
        <div
            className={
                !isOpen
                    ? 'context_popup_accordian_open context_popup_accordian_close user_information'
                    : 'context_popup_accordian_open context_popup_accordian_open_margin_zero user_information'
            }
        >
            <div className="context_join_output">
                <h4 onClick={()=>{
                    toggleOpen(!isOpen)
                }}>
                    <span className="context_path">
                        <span className="context_path_content">User Information</span>
                    </span>
                    <span>
                        <div className={isOpen ? 'arrow_up_form' : 'arrow_up_form closed_state_arrow'}>
                            <DownarrowWithCircle />
                        </div>
                    </span>
                </h4>
                {isOpen && systemVariables.map((output, i) => {
                    return (
                        <div className="context_popup_buton_content">
                            <div className="context_popup_buton_content_span">
                                <div
                                    className={'button_auto'}
                                    onClick={() => {
                                            props.updateValue({
                                                path: [output["$id"]],
                                                value: output["$id"],
                                                valuePath: ["User Information" ,output["$id"]],
                                                type: output.type,
                                                icon: ""
                                            })
                                    }}
                                >
                                    {output.$id}
                                </div>
                            </div>
                        </div>
                    )
                    }) 
                }
            </div>
        </div>
    )
}

function NodeOutputDetailHeader(props: {
    isOpen: boolean
    toggleOpen: () => void
    app: (ResponseOutputT & PathIdType) | undefined
}) {
    const { app } = props

    const forEachOutput = app && app.pathId.forEachName

    return app ? (
        <h4 onClick={props.toggleOpen}>
            <img src={app.icon} alt="" />
            {app.action != 'NoTrigger' && <span className="context_path">{app.appName}</span>}
            {forEachOutput ? (
                <>
                    <span className="context_path">Function</span>
                    <span className="context_path">
                        <FlowCanvasContextIcons.NextIcon />
                        <span className="context_path_content">For Each - {forEachOutput}</span>
                    </span>
                </>
            ) : app.pathId && app.pathId.approvalId ? (
                <span className="context_path">Approval</span>
            ) : (
                <>
                    <span className="context_path">
                        {app.action != 'NoTrigger' && <FlowCanvasContextIcons.NextIcon />}
                        <span className="context_path_content">
                            {app.action == 'NoTrigger' ? 'Triggered by Chatbot' : app.action}
                        </span>
                    </span>
                </>
            )}
            <span className="context_path">
                <FlowCanvasContextIcons.NextIcon />
                <span className="context_path_content">Output</span>
            </span>
            <span>
                <div className={props.isOpen ? 'arrow_up_form' : 'arrow_up_form closed_state_arrow'}>
                    <DownarrowWithCircle />
                </div>
            </span>
        </h4>
    ) : null
}

function NodeOutputDetailBody(props: {
    updateValue: (input: any) => void
    treeData: {
        name: string
        input: InputFieldT[]
        app?: ResponseOutputT & PathIdType
        output: {
            type: ConfigPropertiesTypeT
            title: string
            properties: {
                [key: string]: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
            }
        }
    }
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
    addingFlowInsideForEach?: boolean
}) {
    const treeData = props.treeData

    const { updateValue, showOnlyCollection } = props

    const [searchOutput, setSearchValue] = useState('')

    function showFilteredValueWithSearch(
        array: {
            name: string
            value: ObjectOutputListTypeT | OutputListTypeT
        }[],
        search: string
    ) {
        if (array.length === 0) return []
        return search.length > 0
            ? array.filter(x => x.value.title.toLowerCase().indexOf(search.toLowerCase()) > -1)
            : array
    }
    const collectionOutput = treeData.output as any

    const prop = props as any

    return (
        <React.Fragment>
            <div className="context_popup_search">
                <input type="search" placeholder='Search Variable' value={searchOutput} onChange={e => setSearchValue(e.target.value)} />
            </div>
            {showFilteredValueWithSearch(
                convertObjectToArray(treeData.output ? treeData.output.properties : {}),
                searchOutput
            ).map((output, i) => {
                const op = treeData as any
                return (
                    <React.Fragment key={i}>
                        <div className="context_popup_buton_content">
                            {generateTree(
                                output as {
                                    name: string
                                    value: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
                                },
                                updateValue,
                                showOnlyCollection,
                                props.treeData.app,
                                collectionOutput.showForEachCollection,
                                props.addingFlowInsideForEach,
                                prop.isFilePicker
                            )}
                        </div>

                        {op.forEachSubflow &&
                            op.forEachSubflow.map((outputs: any, i: number) => {
                                if (outputs.type === 'success') {
                                    return (
                                        <div className={`${op.forEachSubflow ? 'context_foreach_wrapper' : ''}`}>
                                            <ExpandedOutputView
                                                {...props}
                                                treeData={outputs}
                                                key={i}
                                                isTriggerOrNoTrigger={false}
                                            />
                                        </div>
                                    )
                                }
                                return null
                            })}
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

function NodeOutputWebhookDetailBody(props: {
    updateValue: (input: any) => void
    treeData: {
        name: string
        input: InputFieldT[]
        app?: ResponseOutputT & PathIdType
        output: {
            type: ConfigPropertiesTypeT
            title: string
            properties: {
                [key: string]: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
            }
        }
    }
    showOnlyCollection?: {
        showOnlyCollection: boolean
        contextPopupValues: ContextPopupStateT
    }
    addingFlowInsideForEach?: boolean
}) {
    const p = props as any

    const tree = useContext(SidePanelContext).treeData

    const [treeData, setNewTreeData] = useState(tree.tree.value)

    const { setRightPanelState, rightPanelState, setEditTreeData, setTreeData, editTreeData, hasLinkedDialogs } = useContext(
        SidePanelContext
    )

    const { updateValue, showOnlyCollection } = props

    const [searchOutput, setSearchValue] = useState('')

    const [editId, setEditId] = useState('')
    const [loading, setLoading] = useState(false)

    const [inputKeyList, setInputKeys] = useState<InputKey[]>([])

    const [modified, setModified] = useState<boolean>(false)

    const [showWarningModal, setShowWarningModal] = useState({
        show: false,
        id: ''
    })

    const addNewInputKey = () => {
        const newKey = makeDefaultInputKey()
        setInputKeys([...inputKeyList, newKey])
    }

    const enableAddBtn = () => setAddBtnDisabled(false)
    const disableAddBtn = () => setAddBtnDisabled(true)

    const hasDuplicate = () =>
        inputKeyList.length > 0 &&
        reverseConversion(treeData.output.properties).filter(
            p => p.title == inputKeyList[0].title && p.type.toLowerCase() == inputKeyList[0].type.toLowerCase()
        ).length > 0
    const [isAddBtnDisabled, setAddBtnDisabled] = useState<boolean>(false)

    const [showAddButton, setShowAddButton] = useState<boolean>(treeData.kind == 'NoTrigger' || treeData.kind=='WebhookTrigger')

    const [maskingId, setMaskingId] = useState('')

    async function saveNewVariable() {
        setLoading(true)

        console.log('new variable save')
        const transFormToProperties = inputKeyList
            .filter((key: any) => key.title !== '')
            .map((key: any) => ({
                [key.uniqueKey]: {
                    $id: `/properties/${key.uniqueKey}`,
                    type: key.type.toLowerCase(),
                    title: key.title,
                    optional: key.optional,
                    masked: false
                }
            }))
            .reduce((acc: any, curr: any) => {
                return { ...acc, ...curr }
            }, tree.tree.value.output.properties)

        const output = {
            type: 'object',
            title:  'output schema',
            properties: transFormToProperties
        }

        const updateTree = {
            ...tree.tree,
            value: { ...tree.tree.value, output }
        }

        callAPI(updateTree, output, 'add')
    }

    async function updateVariable(key: string) {
        setLoading(true)

        const properties = produce(tree.tree.value.output.properties, (draft: any) => {
            draft[`${key}`] = {
                ...inputKeyList[0],
                type: inputKeyList[0].type.toLowerCase(),
                title: inputKeyList[0].title
            }
        })

        const output = {
            type: 'object',
            title: 'output schema',
            properties: properties
        }

        const updateTree = {
            ...tree.tree,
            value: { ...tree.tree.value, output }
        }

        p.updateVariableFromInput(
            {
                ...inputKeyList[0],
                type: inputKeyList[0].type.toLowerCase(),
                title: inputKeyList[0].title
            },
            tree.tree.value.output.properties[key],
            (type: string) =>
                callAPI(updateTree, output, type, tree.tree.value.output.properties[key], {
                    ...inputKeyList[0],
                    type: inputKeyList[0].type.toLowerCase(),
                    title: inputKeyList[0].title
                })
        )
    }

    async function updateVariableMasking(key: string, val: InputKey) {
        setLoading(true)
        setMaskingId(key)

        console.log('tree.tree.value.output.properties', tree.tree.value.output.properties, key, val)
        const properties = produce(tree.tree.value.output.properties, (draft: any) => {
            console.log('draft', key)

            draft[`${key}`] = {
                ...val,
                masked: val.masked != null || val.masked != undefined ? !val.masked : false
            }
        })

        const output = {
            type: 'object',
            title: 'output schema',
            properties: properties
        }

        const updateTree = {
            ...tree.tree,
            value: { ...tree.tree.value, output }
        }

        p.updateVariableFromInput(
            {
                ...val,
                masked: val.masked != null || val.masked != undefined ? !val.masked : false
            },
            tree.tree.value.output.properties[key],
            (type: string) => {
                callAPI(updateTree, output, 'edit', tree.tree.value.output.properties[key], {
                    ...val,
                    masked: val.masked != null || val.masked != undefined ? !val.masked : false
                })
            }
        )
    }

    async function removeVariable(key: string) {
        setLoading(true)

        const properties = tree.tree.value.output.properties
        const { [`${key}`]: omit, ...rest } = properties

        const output = {
            type: 'object',
            title: 'output schema',
            properties: rest
        }
        const updateTree = {
            ...tree.tree,
            value: { ...tree.tree.value, output }
        }
        p.removeVariableFromInput(omit, (type: string) => callAPI(updateTree, output, type, omit))
    }

    function reverseConversion(properties: {
        [key: string]: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
    }) {
        return convertObjectToArray(properties).map((val: any) => {
            return {
                title: val.value.title,
                uniqueKey: val.name,
                type: val.value.type
            }
        })
    }

    async function callAPI(
        updateTree: any,
        output: any,
        from: 'add' | 'edit' | 'delete' | string,
        old?: any,
        newValue?: any
    ) {
        console.log('calling', from)
        const prop = props as any

        const { id, workspacename } = prop.match.params
        let updatedEditTree = {
            ...editTreeData.tree,
            value: { ...editTreeData.tree.value, output }
        }

        if (from == 'delete') {
            const inputkeys = reverseConversion(output.properties)
            updatedEditTree = removeChildrenContextVariables(treeData.id, updatedEditTree, inputkeys, {
                errorHandling: false,
                action_id: ''
            })
            updateTree = removeChildrenContextVariables(treeData.id, updateTree, inputkeys, {
                errorHandling: false,
                action_id: ''
            })
        }

        await putJson(automationAPIURLWithWorkspace(workspacename, AutomationApi.Update))()({
            custom_json: updateTree,
            id: id
        })
            .then(res => {
                setEditTreeData({
                    ...editTreeData,
                    tree: updatedEditTree
                })
                setTreeData({
                    ...tree,
                    tree: updateTree
                })
                setNewTreeData({ ...treeData, output: output as any })
                setInputKeys([])
                lazyGA().event({
                    category: 'Automation',
                    action: 'Update Automation Steps'
                })
                enableAddBtn()
                setShowWarningModal({ show: false, id: '' })
                setEditId('')
                setModified(false)
                // if (rightPanelState.currentNode.nodeType != Nodes.Approval) {
                //     from == 'delete' && p.removeVariableFromInput(old)
                //     from == 'edit' && p.updateVariableFromInput(newValue, old)
                // }
                setLoading(false)
                setMaskingId('')
            })
            .catch((err: any) => {
                setLoading(false)

                setRightPanelState({
                    ...rightPanelState,
                    mode: {
                        ...PopupError,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong!<br /> Please try again after sometime.'
                    }
                })
            })
    }

    const removeKey = () => {
        setInputKeys([])
        setEditId('')
        setModified(false)
        enableAddBtn()
    }

    const updateKey = (selectedKey: InputKey) => {
        const updatedKeys = inputKeyList.map(key => {
            if (key.uniqueKey === selectedKey.uniqueKey) {
                return { ...selectedKey }
            }
            return key
        })
        editId.length > 0 && setModified(true)
        setInputKeys(updatedKeys)
    }

    const AddInputBtn: React.FunctionComponent<any> = ({
        onClick,
        disable
    }: {
        onClick: () => void
        disable: boolean
    }) => {
        const buttonWrapperClass = disable ? 'notrigger_add_buton_span_disabled' : 'notrigger_add_buton_span'
        return (
            <div className={buttonWrapperClass}>
                <button onClick={onClick} disabled={disable} className='workflow_variable_add_input'>
                    <span>
                        <PlusIcon />
                    </span>
                    Add Input
                </button>
            </div>
        )
    }

    const renderWarningModal = () => {
        if (showWarningModal.show) {
            return (
                <Modal
                    showPopupLoader={loading}
                    error={
                        rightPanelState.mode.mode == NodeMode.Error
                            ? {
                                  error: true,
                                  info: rightPanelState.mode.error
                              }
                            : { error: null, info: null }
                    }
                >
                    <React.Fragment>
                        <h2>Do you want to {editId.length > 0 ? 'save' : 'delete'}?</h2>
                        <p>{'Modifying input fields might affect workflow'}</p>
                    </React.Fragment>
                    <ButtonWrapper margin="margintop">
                        <Button
                            primary
                            type="button"
                            data-dismiss="modal"
                            onClick={() => {
                                editId.length > 0 && removeKey()
                                setShowWarningModal({ show: false, id: '' })
                            }}
                        >
                            <label>No</label>
                        </Button>
                        <Button
                            type="button"
                            data-dismiss="modal"
                            onClick={() => {
                                editId.length > 0 ? updateVariable(editId) : removeVariable(showWarningModal.id)
                            }}
                        >
                            <label>Yes</label>
                        </Button>
                    </ButtonWrapper>
                </Modal>
            )
        } else return null
    }

    function showFilteredValueWithSearch(
        array: {
            name: string
            value: ObjectOutputListTypeT | OutputListTypeT
        }[],
        search: string
    ) {
        if (array.length === 0) return []
        return search.length > 0
            ? array.filter(x => x.value.title.toLowerCase().indexOf(search.toLowerCase()) > -1)
            : array
    }
    const collectionOutput = treeData.output as any

    return (
        <React.Fragment>
            <div className="context_popup_search">
                <input type="search" placeholder='Search Variable' value={searchOutput} onChange={e => setSearchValue(e.target.value)} />
            </div>
            {!showOnlyCollection || !showOnlyCollection.showOnlyCollection ? (
                <>
                    {showFilteredValueWithSearch(
                        convertObjectToArray(treeData.output ? treeData.output.properties : {}),
                        searchOutput
                    ).map((output, i) => {
                        return !output.value.type ? null: (
                            <React.Fragment key={i}>
                                {editId.length == 0 || editId != output.name ? (
                                    <div className="context_popup_buton_content context_popup_buton_content_webhook">
                                        {generateTree(
                                            output as {
                                                name: string
                                                value:
                                                    | OutputListTypeT
                                                    | ObjectOutputListTypeT
                                                    | CollectionOutputListTypeT
                                            },
                                            updateValue,
                                            showOnlyCollection,
                                            props.treeData.app,
                                            collectionOutput.showForEachCollection,
                                            props.addingFlowInsideForEach
                                        )}
                                        <span className="notrigger_span_svg hide_on_parent_hover margin-left-neg-20">
                                            {(output?.value as any)?.masked ? (
                                                <PasswordEyeClose />
                                            ) : (
                                                <span style={{ width: '20px' }}></span>
                                            )}
                                        </span>
                                        {(editId.length == 0 && loading && showWarningModal.id == output.name) ||
                                        (loading && maskingId === output.name) ? (
                                            <span className="connect_account_loading">
                                                <img src={LoadingSvgImage} title={'loading'} />
                                            </span>
                                        ) : !hasLinkedDialogs && (
                                            <>
                                                <span
                                                    className="notrigger_span_svg edit_span_svg"
                                                    onClick={() => {
                                                        setInputKeys([{ ...(output.value as any) }])
                                                        setEditId(output.name)
                                                    }}
                                                >
                                                    <EditIcon />
                                                </span>
                                                <span
                                                    className="notrigger_span_svg "
                                                    onClick={() => {
                                                        setShowWarningModal({ show: true, id: output.name })
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </span>
                                                <span
                                                    className="notrigger_span_svg"
                                                    onClick={() => {
                                                        updateVariableMasking(output.name, output.value as any)
                                                    }}
                                                >
                                                    {(output?.value as any)?.masked ? (
                                                        <PasswordEyeOpen />
                                                    ) : (
                                                        <PasswordEyeClose />
                                                    )}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className="context_popup_buton_content context_popup_buton_content_webhook">
                                        {inputKeyList.map(inputKey => {
                                            return (
                                                <>
                                                    <InputKeyFormControl
                                                        key={inputKey.uniqueKey}
                                                        inputKey={inputKey}
                                                        removeInputKey={removeKey}
                                                        updateInputKey={updateKey}
                                                        disableAddBtn={disableAddBtn}
                                                        children={
                                                            modified &&
                                                            (hasDuplicate() ? (
                                                                <span className="connect_account_failed">
                                                                    <div className="error_input_show">
                                                                        <WarningAlertIcon />
                                                                        <span className="error_menu_hvr">
                                                                            Variable already exists
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            ) : !loading ? (
                                                                <span
                                                                    className={
                                                                        inputKey.title.length
                                                                            ? 'connect_account_success tick_icon'
                                                                            : 'connect_account_success tick_icon editor_btn_disabled'
                                                                    }
                                                                    onClick={() => {
                                                                        setShowWarningModal({ show: true, id: editId })
                                                                    }}
                                                                >
                                                                    <TickIcon />
                                                                </span>
                                                            ) : (
                                                                <span className="connect_account_loading">
                                                                    <img src={LoadingSvgImage} alt="loading" />
                                                                </span>
                                                            ))
                                                        }
                                                    />
                                                </>
                                            )
                                        })}
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    })}
                    <>
                        <div className="context_popup_buton_content context_popup_buton_content_webhook">
                            {editId.length == 0 &&
                                inputKeyList.map(inputKey => {
                                    return (
                                        <>
                                            <InputKeyFormControl
                                                key={inputKey.uniqueKey}
                                                inputKey={inputKey}
                                                removeInputKey={removeKey}
                                                updateInputKey={updateKey}
                                                disableAddBtn={disableAddBtn}
                                                edit={true}
                                                children={
                                                    hasDuplicate() ? (
                                                        <span className="connect_account_failed">
                                                            <div className="error_input_show variable_alert">
                                                                <WarningAlertIcon />
                                                                <span className="error_menu_hvr">
                                                                    Variable already exists
                                                                </span>
                                                            </div>
                                                        </span>
                                                    ) : !loading ? (
                                                        <span
                                                            onClick={saveNewVariable}
                                                            className={
                                                                inputKey.title.length
                                                                    ? 'connect_account_success tick_icon'
                                                                    : 'connect_account_success tick_icon editor_btn_disabled'
                                                            }
                                                        >
                                                            <TickIcon />
                                                        </span>
                                                    ) : (
                                                        <span className="connect_account_loading">
                                                            <img src={LoadingSvgImage} />
                                                        </span>
                                                    )
                                                }
                                            />
                                        </>
                                    )
                                })}
                        </div>
                        {showAddButton && inputKeyList.length == 0 && !hasLinkedDialogs && (
                            <div className="notrigger_add_buton">
                                <AddInputBtn onClick={addNewInputKey} disable={isAddBtnDisabled} />
                            </div>
                        )}
                    </>
                    {renderWarningModal()}
                </>
            ) : null}
        </React.Fragment>
    )
}
function generateTree(
    output: {
        name: string
        value: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
    },
    updateValue: (input: any) => void,
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT },
    app?: ResponseOutputT & PathIdType,
    showForEachCollection?: boolean,
    addingFlowInsideForEach?: boolean,
    isFilePicker?: boolean
) {
    // console.log("generateTree ::: output", output)
    if (
        output.value.type !== 'object' &&
        output.value.type !== 'collection' &&
        output.value.type !== 'file' &&
        (!showOnlyCollection || !showOnlyCollection.showOnlyCollection)
    ) {
        return (
            <OutputKeyDetailedView
                output={
                    output as {
                        name: string
                        value: OutputListTypeT
                    }
                }
                showOnlyCollection={showOnlyCollection}
                app={app}
                onClick={updateValue}
                isFilePicker={isFilePicker as any}
            />
        )
    } else if (output.value.type === 'file' || output.value.type === 'object' || output.value.type === 'collection') {
        return (
            <ContextOutputTree
                output={
                    output.value.type === 'file'
                        ? ({
                              ...output,
                              value: { ...output.value, properties: FileDefaultObject(output.name).properties }
                          } as any)
                        : (output as {
                              name: string
                              value: ObjectOutputListTypeT | CollectionOutputListTypeT
                          })
                }
                showOnlyCollection={showOnlyCollection}
                app={app}
                onClick={updateValue}
                level={output.value.type === 'collection' ? 1 : undefined}
                addingFlowInsideForEach={addingFlowInsideForEach}
                isFilePicker={isFilePicker}
            />
        )
    }

    return null
}

export function ContextOutputTree(props: {
    output: { name: string; value: ObjectOutputListTypeT | CollectionOutputListTypeT }
    onClick: (input: any) => void
    app?: (ResponseOutputT & PathIdType) | undefined
    usingForLogsPage?: boolean
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
    usingForInputs?: boolean
    level?: number
    addingFlowInsideForEach?: boolean
    choosingFileVariable?: boolean
    hideObjectHead?: boolean
    isFilePicker?: boolean
    forDialog?: boolean
}) {
    const { output, app, usingForLogsPage, showOnlyCollection, onClick, usingForInputs, level, forDialog } = props
    const [showObjectValues,setShowObjectValues] = useState({})
    const [showObjectValues1,setShowObjectValues1] = useState(false)

    const [showObjectValues2,setShowObjectValues2] = useState(false)

    const objectHasSingleValue =
        output.value.type == 'object' && convertObjectToArray(output.value.properties).length == 1
    const op = output as any
    return (
        <div className="context_popup_buton_content_span">
            {/* Tree structure start*/}
            <ContextTreeWrapper>
                <ul
                    className={
                        usingForLogsPage ? 'tree1 treeview_automatiom_ul for_logs' : 'tree1 treeview_automatiom_ul'
                    }
                >
                    <div className="treeview_automatiom_ul_hide">
                        {output.value.type == 'collection' ? (
                            <>
                                <div className="collection_picker collection_picker_wrapeer">
                                    <ul
                                        className={
                                            usingForLogsPage
                                                ? 'collection_picker_workflow_collection'
                                                : 'collection_condition_variable_picker'
                                        }
                                    >
                                        <PopulateTree
                                            nodeOutput={output}
                                            app={app}
                                            usingForLogsPage={usingForLogsPage}
                                            showOnlyCollection={showOnlyCollection}
                                            onClick={onClick}
                                            isTopMostParent={false}
                                            usingForInputs={usingForInputs}
                                            parent={output.value.type}
                                            addingFlowInsideForEach={props.addingFlowInsideForEach}
                                            forDialog={forDialog}
                                        />

                                        {usingForLogsPage
                                            ? output.value.collection_response &&
                                              output.value.collection_response.map(
                                                  (objectOutput: ObjectOutputListTypeT, i) => {
                                                      return (
                                                          <>
                                                              <div className="tree_collection_header">
                                                                  <TreeStartingHeader
                                                                      name={output.value.title + ' ' + (i + 1)}
                                                                      showObjectValues={showObjectValues[`${"show"+i}`]}
                                                                      setShowObjectValues={()=>setShowObjectValues({[`${"show"+i}`]:!showObjectValues[`${"show"+i}`]})}
                                                                  />
                                                                  {showObjectValues[`${"show"+i}`] && <div className="automation_logs_collection">
                                                                      {convertObjectToArray(
                                                                          objectOutput.properties
                                                                      ).map((nodeOutput, j) =>
                                                                          nodeOutput.value.type != 'collection' ? (
                                                                              <BuildContextTreeParent
                                                                                  key={j}
                                                                                  isTopMostParent={j == 0}
                                                                                  nodeOutput={nodeOutput}
                                                                                  onClick={onClick}
                                                                                  app={app}
                                                                                  parent={output.value.type}
                                                                                  usingForLogsPage={usingForLogsPage}
                                                                                  showOnlyCollection={
                                                                                      showOnlyCollection
                                                                                  }
                                                                                  objectHasOnlySingleValue={
                                                                                      i == 0 && objectHasSingleValue
                                                                                  }
                                                                                  level={
                                                                                      level && level == 1 ? 2 : level
                                                                                  }
                                                                                  addingFlowInsideForEach={
                                                                                      props.addingFlowInsideForEach
                                                                                  }
                                                                              />
                                                                          ) : (
                                                                              <>
                                                                                  <li className="collection_picker treeview_automatiom_li collection_tree_li">
                                                                                      <PopulateTree
                                                                                          nodeOutput={nodeOutput}
                                                                                          app={app}
                                                                                          usingForLogsPage={
                                                                                              usingForLogsPage
                                                                                          }
                                                                                          showOnlyCollection={
                                                                                              showOnlyCollection
                                                                                          }
                                                                                          onClick={onClick}
                                                                                          isTopMostParent={false}
                                                                                          usingForInputs={
                                                                                              usingForInputs
                                                                                          }
                                                                                          parent={nodeOutput.value.type}
                                                                                          level={
                                                                                              level && level == 1
                                                                                                  ? 2
                                                                                                  : level
                                                                                          }
                                                                                          addingFlowInsideForEach={
                                                                                              props.addingFlowInsideForEach
                                                                                          }
                                                                                      />

                                                                                      {usingForLogsPage
                                                                                          ? nodeOutput.value
                                                                                                .collection_response &&
                                                                                            nodeOutput.value.collection_response.map(
                                                                                                (
                                                                                                    objectOutput: ObjectOutputListTypeT,
                                                                                                    i: number
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="tree_collection_header">
                                                                                                                <TreeStartingHeader
                                                                                                                    name={
                                                                                                                        nodeOutput
                                                                                                                            .value
                                                                                                                            .title +
                                                                                                                        ' ' +
                                                                                                                        (i +
                                                                                                                            1)
                                                                                                                    }
                                                                                                                    showObjectValues={showObjectValues1}
                                                                                                                    setShowObjectValues={setShowObjectValues1}
                                                                                                                />
                                                                                                                {showObjectValues1 && <div className="automation_logs_collection">
                                                                                                                    {convertObjectToArray(
                                                                                                                        objectOutput.properties
                                                                                                                    ).map(
                                                                                                                        (
                                                                                                                            nodeOutput,
                                                                                                                            j
                                                                                                                        ) => (
                                                                                                                            <BuildContextTreeParent
                                                                                                                                level={
                                                                                                                                    level &&
                                                                                                                                    level ==
                                                                                                                                        1
                                                                                                                                        ? 3
                                                                                                                                        : level
                                                                                                                                }
                                                                                                                                key={
                                                                                                                                    j
                                                                                                                                }
                                                                                                                                isTopMostParent={
                                                                                                                                    j ==
                                                                                                                                    0
                                                                                                                                }
                                                                                                                                nodeOutput={
                                                                                                                                    nodeOutput
                                                                                                                                }
                                                                                                                                onClick={
                                                                                                                                    onClick
                                                                                                                                }
                                                                                                                                app={
                                                                                                                                    app
                                                                                                                                }
                                                                                                                                parent={
                                                                                                                                    nodeOutput
                                                                                                                                        .value
                                                                                                                                        .type
                                                                                                                                }
                                                                                                                                usingForLogsPage={
                                                                                                                                    usingForLogsPage
                                                                                                                                }
                                                                                                                                showOnlyCollection={
                                                                                                                                    showOnlyCollection
                                                                                                                                }
                                                                                                                                objectHasOnlySingleValue={
                                                                                                                                    i ==
                                                                                                                                        0 &&
                                                                                                                                    objectHasSingleValue
                                                                                                                                }
                                                                                                                                showForEachCollection={() => {
                                                                                                                                    const value = nodeOutput.value as CollectionOutputListTypeT
                                                                                                                                    return value.showForEachCollection as boolean
                                                                                                                                }}
                                                                                                                                addingFlowInsideForEach={
                                                                                                                                    props.addingFlowInsideForEach
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </div>}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                          : convertObjectToArray(
                                                                                                nodeOutput.value.item
                                                                                                    .properties
                                                                                            ).map(
                                                                                                (
                                                                                                    nodeConvertedOutput,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <BuildContextTreeParent
                                                                                                        key={i}
                                                                                                        isTopMostParent={
                                                                                                            false
                                                                                                        }
                                                                                                        nodeOutput={
                                                                                                            nodeConvertedOutput
                                                                                                        }
                                                                                                        onClick={
                                                                                                            props.onClick
                                                                                                        }
                                                                                                        app={app}
                                                                                                        parent={
                                                                                                            nodeOutput
                                                                                                                .value
                                                                                                                .type
                                                                                                        }
                                                                                                        usingForLogsPage={
                                                                                                            usingForLogsPage
                                                                                                        }
                                                                                                        showOnlyCollection={
                                                                                                            showOnlyCollection
                                                                                                        }
                                                                                                        usingForInputs={
                                                                                                            usingForInputs
                                                                                                        }
                                                                                                        objectHasOnlySingleValue={
                                                                                                            i == 0 &&
                                                                                                            objectHasSingleValue
                                                                                                        }
                                                                                                        showForEachCollection={() => {
                                                                                                            const value = nodeOutput.value as CollectionOutputListTypeT
                                                                                                            return value.showForEachCollection as boolean
                                                                                                        }}
                                                                                                        addingFlowInsideForEach={
                                                                                                            props.addingFlowInsideForEach
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}
                                                                                  </li>
                                                                              </>
                                                                          )
                                                                      )}
                                                                  </div>}
                                                              </div>
                                                                                                    
                                                          </>
                                                      )
                                                  }
                                              )
                                            : convertObjectToArray(output.value.item.properties).map((nodeOutput, i) =>
                                                  nodeOutput.value.type != 'collection' ? (
                                                      <BuildContextTreeParent
                                                          key={i}
                                                          isTopMostParent={false}
                                                          nodeOutput={nodeOutput}
                                                          onClick={props.onClick}
                                                          app={app}
                                                          parent={output.value.type}
                                                          usingForLogsPage={usingForLogsPage}
                                                          showOnlyCollection={showOnlyCollection}
                                                          usingForInputs={usingForInputs}
                                                          objectHasOnlySingleValue={i == 0 && objectHasSingleValue}
                                                          showForEachCollection={() => {
                                                              const value = output.value as CollectionOutputListTypeT
                                                              return value.showForEachCollection as boolean
                                                          }}
                                                          addingFlowInsideForEach={props.addingFlowInsideForEach}
                                                          isFilePicker={props.isFilePicker as any}
                                                          forDialog={forDialog}
                                                      />
                                                  ) : (
                                                      <>
                                                          <li className="collection_picker treeview_automatiom_li collection_tree_li">
                                                              <PopulateTree
                                                                  nodeOutput={nodeOutput}
                                                                  app={app}
                                                                  usingForLogsPage={usingForLogsPage}
                                                                  showOnlyCollection={showOnlyCollection}
                                                                  isTopMostParent={false}
                                                                  usingForInputs={usingForInputs}
                                                                  parent={nodeOutput.value.type}
                                                                  level={
                                                                      (level && level == 1) ||
                                                                      output.value.type == 'collection'
                                                                          ? 2
                                                                          : level
                                                                  }
                                                                  onClick={onClick}
                                                                  addingFlowInsideForEach={
                                                                      props.addingFlowInsideForEach
                                                                  }
                                                                  isFilePicker={props.isFilePicker as any}
                                                                  forDialog={forDialog}
                                                              />

                                                              {convertObjectToArray(
                                                                  nodeOutput.value.item.properties
                                                              ).map((nodeConvertedOutput, i) =>
                                                                  nodeConvertedOutput.value.type != 'collection' ? (
                                                                      <BuildContextTreeParent
                                                                          key={i}
                                                                          level={
                                                                              (level && level == 1) ||
                                                                              output.value.type == 'collection'
                                                                                  ? 3
                                                                                  : level
                                                                          }
                                                                          isTopMostParent={false}
                                                                          nodeOutput={nodeConvertedOutput}
                                                                          onClick={props.onClick}
                                                                          app={app}
                                                                          parent={nodeOutput.value.type}
                                                                          usingForLogsPage={usingForLogsPage}
                                                                          showOnlyCollection={showOnlyCollection}
                                                                          usingForInputs={usingForInputs}
                                                                          objectHasOnlySingleValue={
                                                                              i == 0 && objectHasSingleValue
                                                                          }
                                                                          showForEachCollection={() => {
                                                                              const value = nodeOutput.value as CollectionOutputListTypeT
                                                                              return value.showForEachCollection as boolean
                                                                          }}
                                                                          addingFlowInsideForEach={
                                                                              props.addingFlowInsideForEach
                                                                          }
                                                                          isFilePicker={props.isFilePicker as any}
                                                                          forDialog={forDialog}
                                                                      />
                                                                  ) : null
                                                              )}
                                                          </li>
                                                      </>
                                                  )
                                              )}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={op.value.type == 'file' ? 'li_first_child' : ''}>
                                    {!props.hideObjectHead &&
                                        (op.value.type == 'file' ? (
                                            <BuildContextTreeParent
                                                isTopMostParent={false}
                                                nodeOutput={op}
                                                onClick={props.onClick}
                                                app={app}
                                                parent={output.value.type}
                                                usingForLogsPage={usingForLogsPage}
                                                showOnlyCollection={showOnlyCollection}
                                                objectHasOnlySingleValue={false}
                                                addingFlowInsideForEach={props.addingFlowInsideForEach}
                                                choosingFileVariable={props.choosingFileVariable}
                                                isFilePicker={props.isFilePicker as any}
                                            />
                                        ) : (
                                            <TreeStartingHeader name={output.value.title} 
                                            showObjectValues={showObjectValues2}
                                            setShowObjectValues={setShowObjectValues2}
                                             />
                                        ))}
                                </div>
                                {showObjectValues2&&<div className={op.value.type == 'file' ? 'li_child' : ''}>
                                    {convertObjectToArray(output.value.properties).map((nodeOutput, i) =>
                                        nodeOutput.value.type != 'collection' ? (
                                            <BuildContextTreeParent
                                                key={i}
                                                isTopMostParent={props.isFilePicker ? false : i == 0}
                                                nodeOutput={nodeOutput}
                                                onClick={props.onClick}
                                                app={app}
                                                usingForInputs={props.usingForInputs}
                                                parent={output.value.type}
                                                usingForLogsPage={usingForLogsPage}
                                                showOnlyCollection={showOnlyCollection}
                                                objectHasOnlySingleValue={i == 0 && objectHasSingleValue}
                                                addingFlowInsideForEach={props.addingFlowInsideForEach}
                                                isFilePicker={props.isFilePicker as any}
                                            />
                                        ) : (
                                            <ul className="automation_logs_content_wrapper">
                                                <div className="automation_logs_tree">
                                                    <div className="context_popup_buton_content" key={i}>
                                                        {usingForLogsPage ? (
                                                            <ContextOutputTree
                                                                output={nodeOutput}
                                                                usingForLogsPage={usingForLogsPage}
                                                                onClick={() => {}}
                                                            />
                                                        ) : (
                                                            <ContextOutputTree
                                                                output={
                                                                    nodeOutput as {
                                                                        name: string
                                                                        value:
                                                                            | ObjectOutputListTypeT
                                                                            | CollectionOutputListTypeT
                                                                    }
                                                                }
                                                                showOnlyCollection={showOnlyCollection}
                                                                app={app}
                                                                onClick={props.onClick}
                                                                level={
                                                                    nodeOutput.value.type === 'collection'
                                                                        ? 1
                                                                        : undefined
                                                                }
                                                                addingFlowInsideForEach={props.addingFlowInsideForEach}
                                                                isFilePicker={false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </ul>
                                        )
                                    )}
                                </div>}
                            </>
                        )}
                    </div>
                </ul>
                {/* <div className="margin-bottom-75" /> */}
            </ContextTreeWrapper>

            {/* Tree structure End*/}
        </div>
    )
}

function TreeStartingHeader(props: { name: string,showObjectValues:boolean,setShowObjectValues:Function }) {

    return (
        <li className="treeview_automatiom_li_parent">
            <label>
                <div className="popup_tree_head">
                {/* <span className="tree_down_arrow_object" onClick={()=> setShowObjectValues(!showObjectValues) }>
                <RightArrowForAutomationErrors />
              </span> */}
                    <h4>
                        <span className={`popup_tree_head_left_svg ${props.showObjectValues?"arrow_opened":""}`}
                        onClick={(e)=> {
                                e.preventDefault()
                                e.stopPropagation()                            
                                props.setShowObjectValues(!props.showObjectValues)}
                            }>
                            <RightArrowForAutomationErrors />
                            {props.name}
                        </span>
                        {/* {props.name} */}
                        {/* <span className="popup_tree_head_right_svg">
                            <FlowCanvasContextIcons.DownFatArrow />
                        </span> */}
                    </h4>
                </div>
            </label>
        </li>
    )
}

function ContextTreeWrapper(props: { children: React.ReactChild[] | React.ReactChild }) {
    return (
        <div className="popup_tree">
            <div className="tree_structure_body">
                <div className=" treeview_automatiom">
                    <div className="right_panel_ht">{props.children}</div>
                </div>
            </div>
        </div>
    )
}

export function OutputKeyDetailedView(props: {
    output: { name: string; value: OutputListTypeT }
    onClick: (input: any) => void
    app?: (ResponseOutputT & PathIdType) | undefined
    usingForLogsPage?: boolean
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
    isFilePicker?: boolean
}) {
    const { output, onClick, app, usingForLogsPage } = props

    const { treeData } = useContext(SidePanelContext)

    const triggerNodeOutput = treeData && treeData.tree && treeData.tree.value

    return (
        <div className="context_popup_buton_content_span">
            {/* <span className="content_capital">{output.value.type}</span> */}
            <span className="content_capital">
                {!usingForLogsPage && <img src={getImage(output.value.type)} alt="" />}
            </span>
            <div
                className={props.isFilePicker ? 'button_auto editor_btn_disabled' : 'button_auto'}
                onClick={() => {
                    if (app) {
                        onClick({
                            path: app && `${app.pathId.nodeId}.output${reducePath(props.output.value.$id.split('/'))}`,
                            value: output.value.title.replace(/\&nbsp;/g, ' '),
                            valuePath: app
                                ? app.type != 'trigger' || triggerNodeOutput.kind !== 'NoTrigger'
                                    ? app.pathId.approvalId
                                        ? ['Approval', 'Output', output.value.title]
                                        : [app.appName, app.action, output.value.title]
                                    : ['Triggered by Chatbot', 'Output', output.value.title.replace(/\&nbsp;/g, ' ')]
                                : [],
                            type: output.value.type,
                            icon: app.icon
                        })
                    }
                }}
            >
                {output.value.title && output.value.title.replace(/\&nbsp;/g, ' ')}
            </div>
            {!usingForLogsPage ? (
                <p>{output.value.examples && output.value.examples[0].toString()}</p>
            ) : (
                <span className="context_popup_tree_value">
                    :{' '}
                    {output.value.value != undefined
                        ? isHTML(output.value.value.toString())
                            ? Parser(output.value.value.toString())
                            : output.value.value.toString()
                        : '-'}
                </span>
            )}
        </div>
    )
}

function getImage(type: OutpuTypeT) {
    if (type === 'string') {
        return alphabet_img
    } else if (type === 'boolean') {
        return boolean_img
    } else if (type === 'date') {
        return cal_img
    }

    return number_img
}

function BuildContextTreeParent(props: {
    isTopMostParent: boolean
    nodeOutput: { name: string; value: OutputListTypeT | ObjectOutputListTypeT }
    onClick: (input: any) => void
    parent: string
    app?: (ResponseOutputT & PathIdType) | undefined
    usingForLogsPage?: boolean
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
    usingForInputs?: boolean
    objectHasOnlySingleValue?: boolean
    showForEachCollection?: () => boolean
    level?: number
    addingFlowInsideForEach?: boolean
    choosingFileVariable?: boolean
    isFilePicker?: boolean
    forDialog?: boolean
}) {
    return <PopulateTree {...props} />
}

const PopulateTree = (props: {
    isTopMostParent: boolean
    nodeOutput: { name: string; value: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT }
    onClick: (input: any) => void
    parent: string
    app?: (ResponseOutputT & PathIdType) | undefined
    usingForLogsPage?: boolean
    showOnlyCollection?: { showOnlyCollection: boolean; contextPopupValues: ContextPopupStateT }
    usingForInputs?: boolean
    objectHasOnlySingleValue?: boolean
    showForEachCollection?: () => boolean
    level?: number
    addingFlowInsideForEach?: boolean
    choosingFileVariable?: boolean
    isFilePicker?: boolean
    forDialog?: boolean
}) => {

    const [showObjectValues,setShowObjectValues] = useState(false)

    const {
        onClick,
        app,
        usingForLogsPage,
        showOnlyCollection,
        usingForInputs,
        parent,
        objectHasOnlySingleValue,
        showForEachCollection,
        level,
        addingFlowInsideForEach,
        forDialog
    } = props

    const nodeOutput = usingForLogsPage
        ? (props.nodeOutput as any)
        : (props.nodeOutput as {
              name: string
              value: OutputListTypeT | ObjectOutputListTypeT | CollectionOutputListTypeT
          })

    const { treeData } = useContext(SidePanelContext)

    const triggerNodeOutput = treeData && treeData.tree && treeData.tree.value

    const objectHasSingleValue =
        nodeOutput.value.type == 'object' && convertObjectToArray(nodeOutput.value.properties).length == 1

    const generateClass = () => {
        return props.isTopMostParent || (nodeOutput.value.type == 'collection' && !usingForInputs)
            ? `treeview_automatiom_li logs_tree_parent ${
                  objectHasOnlySingleValue ? '' : 'treeview_automatiom_li_first'
              } ${nodeOutput.value.type == 'collection' ? 'collection_parent' : ''}`
            : (showOnlyCollection && showOnlyCollection.showOnlyCollection && !usingForInputs) ||
              (nodeOutput.value.type == 'collection' && usingForInputs)
            ? 'treeview_automatiom_li collection_picker_parent pointer_events_none'
            : 'treeview_automatiom_li'
    }

    const getCollectionParentClass = () => {
        if (showOnlyCollection) {
            // checkForJoinInsideForEach --> is to disable ForEach output for Join Function added inside ForEach)

            const checkForJoinInsideForEach =
                showOnlyCollection.showOnlyCollection &&
                nodeOutput.value.type === 'collection' &&
                showOnlyCollection.contextPopupValues.collectionPicker.isCollectionPicker &&
                ((showForEachCollection && showForEachCollection()) || nodeOutput.value.showForEachCollection)

            if ((showOnlyCollection.showOnlyCollection && nodeOutput.value.type !== 'collection') || parent == 'file') {
                return 'button_auto editor_btn_disabled'
            } else if (
                app &&
                app.pathId.nodeId == showOnlyCollection.contextPopupValues.collectionPicker.app.pathId.nodeId &&
                nodeOutput.value.$id ==
                    showOnlyCollection.contextPopupValues.collectionPicker.selectedCollection.value.$id &&
                showOnlyCollection.showOnlyCollection
            ) {
                return 'button_auto selected'
            } else if (checkForJoinInsideForEach) {
                return 'button_auto editor_btn_disabled'
            } else if (parent == 'collection' && level && level > 1 && !usingForLogsPage) {
                return 'button_auto editor_btn_disabled'
            } else {
                return `button_auto ${
                    nodeOutput.value.type == 'object' ||
                    (nodeOutput.value.type !== 'collection' && nodeOutput.value.showForEachCollection)
                        ? 'editor_btn_disabled'
                        : (nodeOutput.value.type != 'collection' || !showOnlyCollection.showOnlyCollection) &&
                          parent == 'collection' &&
                          !usingForInputs
                        ? 'editor_btn_disabled'
                        : ''
                }`
            }
        } else {
            return `button_auto ${returnClass()}`
        }

        function returnClass() {
            if (nodeOutput.value.type == 'collection' || nodeOutput.value.type == 'object') {
                return 'editor_btn_disabled'
            } else if (nodeOutput.value.type != 'file' && props.isFilePicker) {
                return 'editor_btn_disabled'
            } else if (
                parent == 'collection' &&
                ((level && level > 1) || !addingFlowInsideForEach) &&
                !usingForLogsPage
            ) {
                return 'editor_btn_disabled'
            } else if (nodeOutput.value.type == 'file' && !props.choosingFileVariable && !props.isFilePicker) {
                return 'editor_btn_disabled'
            } else {
                return ''
            }
        }
    }

    return (
        <li className={generateClass()}>
            {nodeOutput.value.type == 'file' && !usingForInputs && (
                <span className="attachment_icon">
                    <AttachmentIcon />
                </span>
            )}
             {nodeOutput.value.type === 'object' &&
              <span className={`tree_down_arrow_object ${showObjectValues?"arrow_opened":""}`} onClick={(e)=> {
                  e.preventDefault()
                  e.stopPropagation()
              
                setShowObjectValues(!showObjectValues) }}>
                <RightArrowForAutomationErrors />
              </span>
              }
            <div className="treeview_automatiom_label_container">
                <label
                    className={
                        !usingForLogsPage
                            ? 'treeview_automatiom_label'
                            : 'treeview_automatiom_label pointer_events_none'
                    }
                >
                    <div
                        className={
                            (props.isTopMostParent
                                ? usingForLogsPage || !showOnlyCollection || !showOnlyCollection.showOnlyCollection
                                    ? `button_auto ${objectHasOnlySingleValue ? '' : 'button_auto_first'}`
                                    : 'button_auto button_auto_first editor_btn_disabled'
                                : getCollectionParentClass()) +
                            ` ${
                                nodeOutput.value.type == 'collection' &&
                                level &&
                                level > 1 &&
                                (props.addingFlowInsideForEach == undefined ||
                                    (props.addingFlowInsideForEach != undefined &&
                                        props.addingFlowInsideForEach == false))
                                    ? 'editor_btn_disabled'
                                    : ''
                            }`
                        }
                        onClick={() => {
                            if (
                                app &&
                                ((nodeOutput.value.type !== 'object' &&
                                    nodeOutput.value.type !== 'collection' &&
                                    (!showOnlyCollection ||
                                        (usingForInputs
                                            ? showOnlyCollection.contextPopupValues.show &&
                                              !showOnlyCollection.showOnlyCollection
                                            : !showOnlyCollection.showOnlyCollection))) ||
                                    (nodeOutput.value.type == 'collection' &&
                                        showOnlyCollection &&
                                        showOnlyCollection.showOnlyCollection &&
                                        !usingForInputs))
                            ) {
                                const path = nodeOutput.value.$id
                                    .split('/')
                                    .filter((x: any) => x.length !== 0 && x !== 'properties') as string[]

                                onClick({
                                    path: app
                                        ? !usingForInputs
                                            ? `${app.pathId.nodeId}.${
                                                  showForEachCollection && showForEachCollection() ? 'input' : 'output'
                                              }${reducePath(nodeOutput.value.$id.split('/'))}`
                                            : `${app.pathId.nodeId}.${
                                                  showForEachCollection && showForEachCollection() ? 'input' : 'output'
                                              }.__COLLECTION__PLACEHOLDER__${reducePath(
                                                  nodeOutput.value.$id.split('/')
                                              )}`
                                        : '',
                                    value: nodeOutput.value.title,
                                    valuePath: app
                                        ? usingForInputs && showOnlyCollection
                                            ? [
                                                  app.appName,
                                                  app.action,
                                                  showOnlyCollection.contextPopupValues.collectionPicker
                                                      .selectedCollection.name
                                              ]
                                                  .concat(path.filter((v, i) => i + 1 != path.length))
                                                  .concat(nodeOutput.value.title)
                                            : app.type != 'trigger' || triggerNodeOutput.kind !== 'NoTrigger'
                                            ? app.pathId.approvalId
                                                ? ['Approval', 'Output']
                                                : [app.appName, app.action]
                                                      .concat(path.filter((v, i) => i + 1 != path.length))
                                                      .concat(nodeOutput.value.title)
                                            : ['Triggered by Chatbot', 'Output', nodeOutput.value.title]
                                        : [],
                                    type: nodeOutput.value.type,
                                    icon: app.icon,
                                    selectedColletion: nodeOutput.value.type == 'collection' ? nodeOutput : {},
                                    appForCollection: app,
                                    examples: nodeOutput.value.examples ? nodeOutput.value.examples[0].toString() : '',
                                    hidden_item_type:
                                        nodeOutput.value.type == 'collection' ? nodeOutput.value.item.type : ''
                                })
                            }
                            if (forDialog) {
                                onClick(nodeOutput)
                            }
                        }}
                    >
                        {nodeOutput.value.type == 'collection' && usingForInputs && (
                            <span>
                                <FlowCanvasContextIcons.CollectionPickerIcon />
                            </span>
                        )}
                        {nodeOutput.value.type == 'collection' && !usingForInputs && (
                            <FlowCanvasContextIcons.CollectionPickerIcon />
                        )}
                        {nodeOutput.value.title}
                    </div>
                    {nodeOutput.value.type != 'object' && nodeOutput.value.type != 'file' && (
                        <span
                            className={
                                props.isTopMostParent && !objectHasOnlySingleValue ? 'letter_num_parent' : 'letter_num'
                            }
                        >
                            {!usingForLogsPage && nodeOutput.value.type != 'collection' && (
                                <img src={getImage(nodeOutput.value.type)} alt="" />
                            )}
                        </span>
                    )}
                </label>

                {usingForLogsPage ? (
                    nodeOutput.value.type != 'collection' && (
                        <span className="context_popup_tree_value">
                            : {nodeOutput.value.value != undefined ? nodeOutput.value.value.toString() : '-'}
                        </span>
                    )
                ) : (usingForInputs || showOnlyCollection || (parent == 'file' && nodeOutput.value.type != 'file')) &&
                  nodeOutput.value.type !== 'collection' &&
                  nodeOutput.value.type != 'object' ? (
                    <span className="collection_child_examples">
                        - {nodeOutput.value.examples && nodeOutput.value.examples[0].toString()}
                    </span>
                ) : null}
            </div>

            { showObjectValues&& nodeOutput.value.type === 'object' &&
                (parent == 'object' || parent == 'collection' ? (
                    <div className={parent == 'object' ? 'object_inside_object' : 'object_inside_collection'}>
                        <ul>
                          
                            {convertObjectToArray(nodeOutput.value.properties).map((childNodeOutput, i) => (
                                <BuildContextTreeParent
                                    key={i}
                                    isTopMostParent={false}
                                    nodeOutput={childNodeOutput}
                                    onClick={onClick}
                                    app={app}
                                    parent={parent}
                                    usingForLogsPage={usingForLogsPage}
                                    showOnlyCollection={showOnlyCollection}
                                    usingForInputs={usingForInputs}
                                    objectHasOnlySingleValue={i == 0 && objectHasSingleValue}
                                    showForEachCollection={showForEachCollection}
                                    level={level}
                                    forDialog={forDialog}
                                />
                            ))}
                        </ul>
                    </div>
                ) : (
                    <>
                        <ul>
                            {convertObjectToArray(nodeOutput.value.properties).map((childNodeOutput, i) => (
                                <BuildContextTreeParent
                                    key={i}
                                    isTopMostParent={false}
                                    nodeOutput={childNodeOutput}
                                    onClick={onClick}
                                    app={app}
                                    parent={nodeOutput.value.type}
                                    usingForLogsPage={usingForLogsPage}
                                    showOnlyCollection={showOnlyCollection}
                                    usingForInputs={usingForInputs}
                                    objectHasOnlySingleValue={i == 0 && objectHasSingleValue}
                                    level={level}
                                    forDialog={forDialog}
                                />
                            ))}
                        </ul>
                    </>
                ))}
        </li>
    )
}
