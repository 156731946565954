import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonContainer,
  Header as MoveHeader,
  HeaderLeft,
  HeaderRight,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper as WrapperMove,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import Tooltip from 'react-tooltip-lite';
import {
  AddIcon,
  FolderIcon,
  InfoIcon,
  MoreIconJump,
  QaIconWithCircle,
  SearchIcon,
} from '@/common/Icons/Icons';
import { VideoLink } from '@/Videos/Videos';
import { Paragraph } from '@/common/styled/Dialogue.Dumb';
import { useScrollHook } from '@/common/hooks/useScrollHook';
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb';
import styled from 'styled-components';
import { Input, InputContent } from '@/common/styled/Workflow.Analytics.Dumb';
import Summary from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/Summary';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  combinatorValueT,
  conditionT,
  conditionTYPES,
  operatorsT,
} from '../DialogueCrew/Nodes/RecogniseDialog/kind';
import {
  DialogueT,
  FolderT,
  IfNodeT,
  LOADING,
  PageStateT,
  DefaultDialogue,
  EXISTING_FOLDERT,
} from './kind';
import {
  generateCond,
  reverseCond,
} from '../DialogueCrew/Nodes/RecogniseDialog/action';
import {
  createFolder,
  getDialogueRoots,
  moveIfDialogue,
  updateIfDialogue,
} from '../Mechanisms/action';
import CombinatorCompoenent from '../DialogueCrew/Nodes/RecogniseDialog/CombinatorCompoenent';
import ConditionalStatements from '../DialogueCrew/Nodes/RecogniseDialog/ConditionalStatements';
import { groupT } from './Dialogue';
import { DialogueContext } from '../DialogueCrew/Context';
import { logEvent } from '@/common/utils/api-utils';
import {
  SerachInput,
  SerachInputWrapper,
} from '@/common/styled/DialogLogs.Dumb';
import { DropDown } from '../Common/Effective';
import { AssistantT } from '../Construction/kind';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { useQueryClient } from 'react-query';

export const InputContainer = styled.div`
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 20px;
`;

const SearchWrapper = styled.div`
  width: 100%;
  float: left;
  padding-left: 10px;
  margin-bottom: 10px;

  ${SerachInputWrapper} {
    width: 100%;
    float: left;

    ${SerachInput} {
      width: 100%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #b9b9b9;
      border-radius: 19px;
      box-shadow: none;

      :hover {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #009d5f;
      }
    }
    span {
      top: 10px;
    }
  }
`;

interface FolderPopupT {
  dialogs: DialogueT[];
  PageState: PageStateT;
  setPageState: React.Dispatch<React.SetStateAction<PageStateT>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  workspacename: string;
  assistant: AssistantT;
  diaGroup: groupT;
  setDiaGroup: React.Dispatch<React.SetStateAction<groupT>>;
  closePopup: Function;
  folderData: DialogueT;
  retry: Function;
  rootNodes: DialogueT[] | [];
}

export const FolderPopup = (props: FolderPopupT) => {
  const queryClient = useQueryClient()
  const {
    dialogs,
    PageState,
    setPageState,
    setLoading,
    workspacename,
    assistant,
    diaGroup,
    setDiaGroup,
    closePopup,
    folderData,
    retry,
    rootNodes,
  } = props;
  // const [diaGroup, setDiaGroup] = useState<string>("")
  const [conditions, setConditions] = useState<conditionT[]>([]);
  const [updateConditions, setUpdateConditions] = useState<conditionT[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');

  const context = useContext(DialogueContext);
  const [folders, setFolders] = useState<DialogueT[]>([]);

  useEffect(() => {
    if (diaGroup === groupT.New_Group) {
      setConditions([
        {
          type: conditionTYPES.SELECT,
        },
      ]);
    }

    if (diaGroup === groupT.Edit_Group) {
      if (folderData.conditions === null) {
        setUpdateConditions([
          {
            type: conditionTYPES.NOCONDITION,
          },
        ]);
      } 
        else {
        const isWelcomeNode = folderData.uuid == assistant.welcome_node;
        const reversedCondition = reverseCond(
          folderData.conditions,
          context,
          context.dialogueNode
        );
        setUpdateConditions(
          isWelcomeNode
            ? reversedCondition.slice(2, reversedCondition.length)
            : reversedCondition
        );
      }
    }

    if (diaGroup === groupT.Existing_Group) {
      queryClient
          .fetchQuery(['dialogRoots', assistant.id], () =>
            getDialogueRoots(workspacename, assistant.id)
          ).then((data) => {

        const foldersOnly = data.filter((fil:DialogueT) => fil.type === 'folder');

        setFolders(foldersOnly);
      });
    }
  }, []);

  const [newGroup, setNewGroup] = useState<{
    name: string;
    description: string;
  }>({ name: '', description: '' });
  const [updatedvalues, setUpdatedVlaues] = useState<{
    name: string;
    description: string;
  }>({
    name: folderData ? folderData.title : '',
    description: folderData ? folderData.description : '',
  });

  const onChange = (index: number, condition: conditionT) =>
    diaGroup === groupT.New_Group
      ? setConditions((element) =>
          element.map((e: conditionT, i: number) =>
            i == index ? condition : e
          )
        )
      : setUpdateConditions((element) =>
          element.map((e: conditionT, i: number) =>
            i == index ? condition : e
          )
        );

  const remove = (index: number) =>
    setConditions((condition) =>
      condition.filter((e: any, i: number) => {
        const conditionIndex = index == 0 ? index + 1 : index - 1;
        if (i != index && i != conditionIndex) return e;
        return false;
      })
    );

  const removeUpdate = (index: number) =>
    setUpdateConditions((condition) =>
      condition.filter((e: any, i: number) => {
        const conditionIndex = index == 0 ? index + 1 : index - 1;
        if (i != index && i != conditionIndex) return e;
        return false;
      })
    );
  const data = dialogs.length > 0 ? dialogs[0] : DefaultDialogue;

  const addMore = () =>
    setConditions((condition) =>
      condition.concat([
        {
          type: conditionTYPES.COMBINATOR,
          value: combinatorValueT.AND,
        },
        {
          type: conditionTYPES.SELECT,
        },
      ])
    );
  const addMoreUpdate = () =>
    setUpdateConditions((condition) =>
      condition.concat([
        {
          type: conditionTYPES.COMBINATOR,
          value: combinatorValueT.AND,
        },
        {
          type: conditionTYPES.SELECT,
        },
      ])
    );

  const [scrollableDivRef, scrollTo] = useScrollHook();

  const [selected, setSelected] = useState<DialogueT | null>(null);


  // const CheckingFolder = (arr: strisng[]) => {
  //   const res = dialogs.filter((item) => arr.includes(item.uuid));
  //   const ifFolder = res.filter((item) => item.type === 'folder');
  //   console.log(res, 'result');
  //   if (ifFolder.length > 0) {
  //     return false;
  //   } else return true;
  // };

  const onSave = () => {
    const checkName = rootNodes.filter((dialog) => dialog.type === 'folder').filter((dia)=>
      dia.title.toLowerCase() === (newGroup.name.toLowerCase())
    );
    if (checkName.length > 0) {
      setNameError(true);
    } else {
      setLoading(true);
      const data = dialogs[0];
      const nodeToGroup = PageState.data;
      const isWelcomeDialog = false;
      const cond = generateCond(conditions, isWelcomeDialog);
      const newConditions = cond;
      const checkCon = conditions[0].type === conditionTYPES.NOCONDITION || conditions[0].type === conditionTYPES.SELECT ;
      if (checkCon === true) {
        createFolder(workspacename, assistant.id, {
          if_node_uuid_to_group: nodeToGroup,
          group_if_node: {
            type: 'folder',
            title: newGroup.name,
            description: newGroup.description,
            conditions: null,
            previous_sibling: data.previous_sibling,
          },
        })
          .then(() => {
            queryClient.invalidateQueries(['dialogRoots', assistant.id])
      
            logEvent(workspacename, {
              event: 'EVENT::DIALOG::CREATE_CONDITION',
              event_meta: {
                name: newGroup.name,
                description: newGroup.description,
              },
            });
            retry();
            closePopup();
          })
          .catch(() => {
            setShowError(true);
          });
      } else {
        createFolder(workspacename, assistant.id, {
          if_node_uuid_to_group: nodeToGroup,
          group_if_node: {
            type: 'folder',
            title: newGroup.name,
            description: newGroup.description,
            conditions: newConditions,
            previous_sibling: data.previous_sibling,
          },
        })
          .then(() => {
            queryClient.invalidateQueries(['dialogRoots', assistant.id])
      
            logEvent(workspacename, {
              event: 'EVENT::DIALOG::CREATE_CONDITION',
              event_meta: {
                name: newGroup.name,
                description: newGroup.description,
              },
            });
            retry();
            closePopup();
          })
          .catch(() => {
            setShowError(true);
          });
      }
    }
  };
  const onUpdate = () => {
    const checkName = rootNodes.filter((dialog) => dialog.title !== folderData.title && dialog.type === 'folder').filter((dia)=>
    dia.title.toLowerCase() === updatedvalues.name.toLowerCase()
    );
  if (checkName.length > 0) {
    setNameError(true);
  } else {
    const isWelcomeDialog = false;
    const cond = generateCond(updateConditions, isWelcomeDialog);
    const updateCond = cond;
    updateIfDialogue(
      workspacename,
      assistant.id,
      folderData.uuid,
      {
        ...folderData,
        title: updatedvalues.name,
        description: updatedvalues.description,
        conditions: updateCond,
      } as IfNodeT,
      folderData.title
    )
      .then(() => {
        data.conditions == null &&
          logEvent(workspacename, {
            event: 'EVENT::DIALOG::CREATE_CONDITION',
            event_meta: {
              name: folderData.title,
              description: folderData.description,
            },
          });
          queryClient.invalidateQueries(['dialogRoots', assistant.id])

        retry();
        // fetchCallback(() => {
        //     setLoading(false)
        // })
      })
      .catch(() => {
        setShowError(true);
      });}
  };
  const [selector, setSelector] = useState<DialogueT | null>(null);

  const DownshiftModel = (dialog: DialogueT) => (
    <div className="dropdown_jumpto">
      <div className="dropdown_move">
        <span
          className={'_selected_input'}
          //     selector == PasteToSelectorT.above
          //         ? '_selected_input last-child_jump'
          //         : 'last-child_jump'
          // }
          onClick={() => setSelector(dialog)}
        >
          Add to this group
          <label>
            <input
              type="radio"
              className="option-input radio"
              name="example"
              checked={selector && selector.id === dialog.id}
              // checked={
              //     selector ==
              //     'above'
              // }
              // onChange={() =>
              //     setSelector(
              //         'above'
              //     )
              // }
            />
          </label>
        </span>
      </div>
    </div>
  );

  const moveFolder = async () => {
    const movingDia = (PageState as EXISTING_FOLDERT).value;
    setPageState(LOADING);
    const folderID = selector?.id;
    const data = {
      parent: folderID,
      previous_sibling: null,
    };
    try {
      for(let i in movingDia){
        await moveIfDialogue(workspacename, assistant.id, movingDia[i], data);
      }
        queryClient.invalidateQueries(['dialogDescendants', folderID])
        queryClient.invalidateQueries(['dialogRoots', assistant.id])
        closePopup();
        retry();
    } catch (err) {
      setShowError(true);
    }
  };
  return (
    <WrapperMove style={{ display: '' }}>
      {diaGroup === groupT.New_Group && (
        <PopupWrapper>
          <PopupContainer>
            <MoveHeader>
              <HeaderLeft minWidth={300}>
                Create group
                {/* <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={'test'}
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip> */}
                <a href="#" target="_blank" rel="noopener" title="Create Group">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="View help document"
                    distance={5}
                  >
                    <QaIconWithCircle />
                  </Tooltip>
                </a>
              </HeaderLeft>
              <HeaderRight style={{ width: '20%' }}>
                {/* <VideoLink id={'SEND_MESSAGE'} text={'How to'} /> */}
              </HeaderRight>
            </MoveHeader>
            <Paragraph className="text-left">
              Set conditions to execute this group.
            </Paragraph>
            <PopupScrollHeight ref={scrollableDivRef}>
              <div className="recognise_popup_right create_group_popup">
                <div className="simple_question">
                  <DraftInputWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setNewGroup({
                            ...newGroup,
                            name: e.target.value,
                          })
                        }
                      />
                      <InputContent>Group name </InputContent>
                    </InputContainer>
                    {nameError && (
                      <div className="group_alert_icon">
                        <span className="alerticon">
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Group name has to be unique'}
                            distance={5}
                          >
                            <WarningAlertIcon />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                    <InputContainer style={{ marginBottom: 10 }}>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setNewGroup({
                            ...newGroup,
                            description: e.target.value,
                          })
                        }
                      />
                      <InputContent>Group description (Optional) </InputContent>
                    </InputContainer>
                  </DraftInputWrapper>
                </div>
                <div className="">
                  {conditions && conditions.length > 0
                    ? conditions.map((element, index) =>
                        element.type == conditionTYPES.COMBINATOR ? (
                          <CombinatorCompoenent
                            key={index}
                            condition={element}
                            onChange={(condition: any) => {
                              // console.log("DOING :::")
                              //   scrollTo('bottom', index === conditions.length - 1)
                              onChange(index, condition);
                            }}
                          />
                        ) : (
                          <ConditionalStatements
                            condition={element}
                            conditions={conditions}
                            key={index}
                            index={index > 0 ? index / 2 : index}
                            onChange={(condition: any) => {
                              // console.log("DOING :::",isMounted())
                              // scrollTo('bottom', index === conditions.length - 1)
                              onChange(index, condition);
                            }}
                            remove={() => remove(index)}
                            conditionEdit={
                              data.conditions
                                ? reverseCond(data.conditions)[index]
                                : null
                            }
                            callbackWhenChooserisOpen={() =>
                              scrollTo(
                                'bottom',
                                index === conditions.length - 1
                              )
                            }
                            groupDialog={true}
                          />
                        )
                      )
                    : null}
                  {/* <div className="qeustions_wrapper">
                                                          <DraftInputWrapper>
                                                              <InputContainer>
                                                                  <Input
                                                                      type="text"
                                                                  />
                                                                  <InputContent>
                                                                  If chatbot recognizes{' '}
                                                                  </InputContent>
                                                                  <CancelButton className="d-flex">
                                                                      <DropdownWithCircle />
                                                                  </CancelButton>
                                                              </InputContainer>    
                                                              <div className='intent_section_box' style={{display: 'none'}}>
                                                                  <InputContainer>
                                                                      <Input
                                                                          type="text"
                                                                      />
                                                                      <InputContent>
                                                                      Intent name{' '}
                                                                      </InputContent>
                                                                      <CancelButton className="d-flex">
                                                                          <DropdownWithCircle />
                                                                      </CancelButton>
                                                                  </InputContainer> 
                                                                  <InputContainer style={{marinBottom: 0}}>
                                                                      <Input
                                                                          type="text"
                                                                      />
                                                                      <InputContent>
                                                                      Condition{' '}
                                                                      </InputContent>
                                                                  </InputContainer>        
                                                              </div>                                
                                                          </DraftInputWrapper>
                                                      </div> */}
                </div>
                {conditions.length > 0 &&
                  conditions[0].type !== conditionTYPES.NOCONDITION && (
                    <SmallButton
                      primary
                      onClick={() => {
                        addMore();
                        scrollTo('bottom');
                      }}
                      // style={{marginBottom: 15}}
                    >
                      <span>
                        <AddIcon />
                      </span>
                      <label>Add more conditions</label>
                    </SmallButton>
                  )}
                {conditions.length > 0 &&
                conditions[0].type !== conditionTYPES.NOCONDITION &&
                conditions.filter((cond) => cond.type == conditionTYPES.SELECT)
                  .length == 0 ? (
                  <Summary conditions={conditions} />
                ) : null}
                <ButtonContainer className="footer_button_fixed">
                  <div className="popup_footer_button_container">
                    <Button
                      primary
                      type="button"
                      data-dismiss="modal"
                      onClick={() => {
                        setShowError(false);
                        closePopup();
                      }}
                    >
                      <label>Cancel</label>
                    </Button>
                    <Button
                      className={
                        newGroup.name === '' ||
                        conditions.filter((data) => {
                          // if (data.type == conditionTYPES.SELECT) {
                          //   return true;
                          // }
                          if (data.type == conditionTYPES.INTENT) {
                            return data.name == '';
                          } else if (data.type == conditionTYPES.ENTITY) {
                            if (data.operator == operatorsT.SELECT) {
                              return true;
                            }
                            if (data.name != '') {
                              if (
                                data.operator == operatorsT.IS ||
                                data.operator == operatorsT.IS_NOT
                              ) {
                                return data.value == '';
                              }
                            } else {
                              return true;
                            }
                          } else if (data.type == conditionTYPES.CONTEXT) {
                            if (data.operator == operatorsT.SELECT) {
                              return true;
                            }
                            if (
                              data.LHS.type === 'context' &&
                              data.LHS.variable != ''
                            ) {
                              if (
                                data.operator == operatorsT.IS_TRUE ||
                                data.operator == operatorsT.IS_FALSE ||
                                data.operator == operatorsT.IS_PRESENT ||
                                data.operator == operatorsT.IS_NOT_PRESENT
                              ) {
                                return false;
                              } else if (typeof data.RHS !== 'boolean') {
                                return data.RHS.blocks[0].text === '';
                              }
                            } else {
                              return true;
                            }
                          }
                          return false;
                        }).length > 0
                          ? 'editor_btn_disabled'
                          : ''
                      }
                      type="button"
                      data-dismiss="modal"
                      onClick={() => onSave()}
                    >
                      <label>Save</label>
                    </Button>
                      {showError && (
                      <div className="group_alert_icon">
                        <span className="alerticon">
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Something went wrong'}
                            distance={5}
                          >
                            <WarningAlertIcon />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </ButtonContainer>
              </div>
            </PopupScrollHeight>
          </PopupContainer>
        </PopupWrapper>
      )}
      {diaGroup === groupT.Edit_Group && (
        <PopupWrapper>
          <PopupContainer>
            <MoveHeader>
              <HeaderLeft minWidth={300}>
                Edit group
                {/* <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={'test'}
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip> */}
                <a href="#" target="_blank" rel="noopener" title="Create Group">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="View help document"
                    distance={5}
                  >
                    <QaIconWithCircle />
                  </Tooltip>
                </a>
              </HeaderLeft>
              <HeaderRight style={{ width: '20%' }}>
                {/* <VideoLink id={'SEND_MESSAGE'} text={'How to'} /> */}
              </HeaderRight>
            </MoveHeader>
            <Paragraph className="text-left">
              Edit conditions to execute this group.
            </Paragraph>
            <PopupScrollHeight ref={scrollableDivRef}>
              <div className="recognise_popup_right edit_group_popup">
                <div className="simple_question">
                  <DraftInputWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        value={updatedvalues.name}
                        onChange={(e) => {
                          setUpdatedVlaues({
                            ...updatedvalues,
                            name: e.target.value,
                          });
                        }}
                      />
                      <InputContent>Group name </InputContent>
                    </InputContainer>
                    {nameError && (
                      <div className="group_alert_icon">
                        <span className="alerticon">
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Group name has to be unique'}
                            distance={5}
                          >
                            <WarningAlertIcon />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                    <InputContainer style={{ marginBottom: 10 }}>
                      <Input
                        type="text"
                        value={updatedvalues.description}
                        onChange={(e) =>
                          setUpdatedVlaues({
                            ...updatedvalues,
                            description: e.target.value,
                          })
                        }
                      />
                      <InputContent>Group description (Optional) </InputContent>
                    </InputContainer>
                  </DraftInputWrapper>
                </div>
                <div className="">
                  {updateConditions && updateConditions.length > 0
                    ? updateConditions.map(
                        (element: conditionT, index: number) =>
                          element.type == conditionTYPES.COMBINATOR ? (
                            <CombinatorCompoenent
                              key={index}
                              condition={element}
                              onChange={(condition: any) => {
                                onChange(index, condition);
                              }}
                            />
                          ) : (
                            <ConditionalStatements
                              condition={element}
                              conditions={updateConditions}
                              key={index}
                              index={index > 0 ? index / 2 : index}
                              onChange={(condition: any) => {
                                // console.log('DOING :::', condition);
                                onChange(index, condition);
                              }}
                              remove={() => removeUpdate(index)}
                              conditionEdit={
                                data.conditions
                                  ? reverseCond(data.conditions)[index]
                                  : null
                              }
                              callbackWhenChooserisOpen={() =>
                                scrollTo(
                                  'bottom',
                                  index === conditions.length - 1
                                )
                              }
                              groupDialog={true}
                            />
                          )
                      )
                    : null}
                </div>
                {updateConditions.length > 0 &&
                  updateConditions[0].type !== conditionTYPES.NOCONDITION && (
                    <SmallButton
                      primary
                      onClick={() => {
                        addMoreUpdate();
                        scrollTo('bottom');
                      }}
                    >
                      <span>
                        <AddIcon />
                      </span>
                      <label>Add more conditions</label>
                    </SmallButton>
                  )}
                {updateConditions.length > 0 &&
                updateConditions[0].type !== conditionTYPES.NOCONDITION &&
                updateConditions.filter(
                  (cond) => cond.type == conditionTYPES.SELECT
                ).length == 0 ? (
                  <Summary conditions={updateConditions} />
                ) : null}
                <ButtonContainer className="footer_button_fixed">
                  <div className="popup_footer_button_container">
                    <Button
                      primary
                      type="button"
                      data-dismiss="modal"
                      onClick={() => {
                        setShowError(false);
                        closePopup();
                      }}
                    >
                      <label>Cancel</label>
                    </Button>
                    <Button
                      className={
                        updatedvalues.name === '' ||
                        updateConditions.filter((data) => {
                          if (data.type == conditionTYPES.SELECT) {
                            return true;
                          }
                          if (data.type == conditionTYPES.INTENT) {
                            return data.name == '';
                          } else if (data.type == conditionTYPES.ENTITY) {
                            if (data.operator == operatorsT.SELECT) {
                              return true;
                            }
                            if (data.name != '') {
                              if (
                                data.operator == operatorsT.IS ||
                                data.operator == operatorsT.IS_NOT
                              ) {
                                return data.value == '';
                              }
                            } else {
                              return true;
                            }
                          } else if (data.type == conditionTYPES.CONTEXT) {
                            if (data.operator == operatorsT.SELECT) {
                              return true;
                            }
                            if (
                              data.LHS.type === 'context' &&
                              data.LHS.variable != ''
                            ) {
                              if (
                                data.operator == operatorsT.IS_TRUE ||
                                data.operator == operatorsT.IS_FALSE ||
                                data.operator == operatorsT.IS_PRESENT ||
                                data.operator == operatorsT.IS_NOT_PRESENT
                              ) {
                                return false;
                              } else if (typeof data.RHS !== 'boolean') {
                                return data.RHS.blocks[0].text === '';
                              }
                            } else {
                              return true;
                            }
                          }
                          return false;
                        }).length > 0
                          ? 'editor_btn_disabled'
                          : ''
                      }
                      type="button"
                      data-dismiss="modal"
                      onClick={() => onUpdate()}
                    >
                      <label>Update</label>
                    </Button>
                    {showError && (
                      <div className="group_alert_icon">
                        <span className="alerticon">
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Something went wrong'}
                            distance={5}
                          >
                            <WarningAlertIcon />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </ButtonContainer>
              </div>
            </PopupScrollHeight>
          </PopupContainer>
        </PopupWrapper>
      )}
      {diaGroup === groupT.Existing_Group && (
        <PopupWrapper>
          <PopupContainer>
            <MoveHeader>
              <HeaderLeft minWidth={300}>
                Add to group
                {/* <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={'test'}
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip> */}
                <a href="#" target="_blank" rel="noopener" title="Create Group">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content="View help document"
                    distance={5}
                  >
                    <QaIconWithCircle />
                  </Tooltip>
                </a>
              </HeaderLeft>
              <HeaderRight style={{ width: '20%' }}>
                {/* <VideoLink id={'SEND_MESSAGE'} text={'How to'} /> */}
              </HeaderRight>
            </MoveHeader>
            <Paragraph className="text-left">
              Click any group and select an option to add your Dialogs & FAQs
            </Paragraph>
            {/* <PopupScrollHeight> */}

            <div className="jumpto_wrapper jumpto_search">
              <SearchWrapper>
                <SerachInputWrapper>
                  <SerachInput
                    type="search"
                    className="form-control jumpto_search"
                    placeholder="Search "
                    value={searchKey}
                    onChange={(e: any) => setSearchKey(e.target.value)}
                  />
                  <span>
                    <SearchIcon />
                  </span>
                </SerachInputWrapper>
              </SearchWrapper>
              <div className="folder_popup">
                {folders.length > 0 ? (
                  <ul className="tree1 zontal_lines1">
                    {folders
                      .filter((e) =>
                        e.title
                          ? e.title
                              .toLowerCase()
                              .includes(searchKey.toLowerCase())
                          : false
                      )
                      .map((data, index) => (
                        <li className="first_add_node" key={index.toString()}>
                          <label className="tree_label1">
                            <div className="jump_to">
                              <div
                                className={
                                  selected && selected.uuid == data.uuid
                                    ? 'jump_to_list_selected'
                                    : 'jump_to_list'
                                }
                              >
                                <div className="jump_folder_icon">
                                  <FolderIcon />
                                </div>
                                <h4>{data.title}</h4>
                                <div
                                  className="jump_selected_dropdown"
                                  onClick={(e: any) => {
                                    setSelected(data);
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <span>
                                    <MoreIconJump />
                                  </span>
                                </div>
                              </div>
                              {selected && selected.uuid == data.uuid ? (
                                <DropDown
                                  isOpen={
                                    selected
                                      ? selected.uuid == data.uuid
                                      : false
                                  }
                                  onOuterClick={() => {}}
                                >
                                  {DownshiftModel(selected)}
                                </DropDown>
                              ) : null}
                            </div>
                          </label>
                        </li>
                      ))}
                  </ul>
                ) : null}
                <div className="margin-bottom-75" />
              </div>
            </div>

            <ButtonContainer className="footer_button_fixed">
              <div className="popup_footer_button_container add_group_popup">
                <Button
                  primary
                  type="button"
                  data-dismiss="modal"
                  onClick={() => {
                    setShowError(false);
                    closePopup();
                  }}
                >
                  <label>Cancel</label>
                </Button>
                <Button
                  className={selector === null? 'editor_btn_disabled' : ''}
                  type="button"
                  data-dismiss="modal"
                  onClick={() => moveFolder()}
                >
                  <label>Add</label>
                </Button>
                {showError && (
                      <div className="group_alert_icon">
                        <span className="alerticon">
                          <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'Something went wrong'}
                            distance={5}
                          >
                            <WarningAlertIcon />
                          </Tooltip>
                        </span>
                      </div>
                    )}
              </div>
            </ButtonContainer>
          </PopupContainer>
        </PopupWrapper>
      )}
    </WrapperMove>
  );
};
