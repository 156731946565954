import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components/macro';

import { VideoLink } from '@/Videos/Videos';
import {
  AgentSummaryIconNew,
  DropdownWithCircle,
  InfoIcon,
  QaIconWithCircle,
  TeamsIcon,
} from '@/common/Icons/Icons';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader';
import lazyGA from '@/common/google-analytics/tracking';
import {
  EmptyDiv,
  Header,
  HeaderLeft,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  Wrapper as WrapperAgent,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';
import {
  CancelButton,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
  DropdownWrapper,
  Input,
  InputContainer,
  InputContent,
} from '@/common/styled/Workflow.Analytics.Dumb';

import {
  agenHandoffUrl,
  getJson,
  logEvent,
  loginApiURL,
  postJson,
  stripeGetApi,
} from '@/common/utils/api-utils';
import { AgentHandoffWorkspace } from '../../AgentHandoff/types';
import { Button, DropDown } from '../../Common/Effective';
import { AgentHandoffT } from '../../Dialogue/kind';
import {
  createResponseDialogue,
  updateResponseDialogue,
} from '../../Mechanisms/action';
import { DialogueContext } from '../Context';
import { AGENT_HANDOFF_RESPONSE, ErrorState, ErrorT, VIEW } from '../kind';
import { DraftInputWrapper, FocusContent } from '@/common/styled/DraftJs.Dumb';
import Editor from '@/Assistant/Editor/Editor';
import {
  converterToEditor,
  converterToServer,
  DraftJST,
} from '@/Assistant/Editor/Action';
import { convertAsaSingleString } from '@/Flows/canvas/utils';
import { RawDraftContentBlock } from 'draft-js';
import { useQuery } from 'react-query';
import { User } from '@/ManageUsers/v2/user.types';
import { MOCK_TRYIT, MOCK_USER_API } from '@/common/utils/utils';
import { useAgentList } from '@/LiveAgent/LivaAgentApi';
import { TeamDetailsT, useAllTeams } from '@/ManageUsers/v2/manageUsersApi';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 20px;

  .draft_focus_content {
    .dialog_context_input_edit_true ${FocusContent} {
      top: 18px;
    }

    .dialog_context_input_edit_true.editor_btn_disabled ${FocusContent} {
      top: 8px;
    }

    .input_selected_dialog ${FocusContent} {
      top: 8px;
    }
    .focus_input ${FocusContent} {
      top: 8px;
    }
  }

  ${DropdownUlWrapper} {
    margin-bottom: 0px;
  }
  ${InputContainer} {
    margin-bottom: 0px;
  }
  .application_dropdown_content {
    font-size: 12px;
    color: #000;
    margin-left: 6px;
    position: relative;
    top: 1px;
    line-height: 14px;
  }
  .input_img_left {
    position: absolute;
    margin-top: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
    float: left;
    margin-left: 12px;
    top: 23px;
  }
  ${DropdownWrapper} {
    position: relative;
    ${Input} {
      padding-left: 36px;
    }
  }
`;

const AgentHandoverTips = () => (
  <>
    <p>
      Use{' '}
      <a
        target="_blank"
        className="sidepanel_href_color"
        href="https://help.workativ.com/index.php/knowledgebase/agent-handover/"
      >
        Agent Handover
      </a>{' '}
      when you want the bot to handover the chat session to live agents.
    </p>
    <p>
      Select your agent chat channel from the list. You can configure the agent
      handover settings in the ‘Agent Handover’ tab in the bot menu.
    </p>
  </>
);
const AutomationWrapper = (props: any) => (
  <div>
    <Header>
      <HeaderLeft minWidth={300}>
        {console.log(props, 'AutomationWrapper')}
        Agent handover
        <Tooltip
          className="target customTip"
          zIndex={10000}
          arrowSize={8}
          tagName="span"
          content={<AgentHandoverTips />}
          distance={5}
        >
          <InfoIcon />
        </Tooltip>
        <a
          href="https://help.workativ.com/knowledgebase/agent-handover"
          target="_blank"
        >
          <Tooltip
            className="target customTip"
            zIndex={10000}
            arrowSize={8}
            tagName="span"
            content="View help document"
            distance={5}
          >
            <QaIconWithCircle />
          </Tooltip>
        </a>
      </HeaderLeft>

      <HeaderRight>
        <VideoLink id={'ADD_AGENT_HANDOVER'} text={'How to'} />
      </HeaderRight>
    </Header>
    <Paragraph>Handover chat to live agent.</Paragraph>
    {props.children}
  </div>
);

export function AgentHandoffResponse(props: any) {
  console.log(props, 'AgentHandoffResponse');
  const workspace: string = props.match.params.workspacename;
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<ErrorT>(ErrorState);
  const dropDownList = {
    changeChanel: 'changeChanel',
    agentDropdown: 'agentDropdown',
    liveAgentDropdown: 'liveAgentDropdown',
    liveAgentListDropdown: 'liveAgentListDropdown',
    Closed: 'closed',
  };
  const [dropDown, setDropDown] = useState<string>(dropDownList.Closed);
  const [liveAgentSelect, setLiveAgentSelect] = useState<string>('');
  // const [liveAgent, setLiveAgent] = useState<string>('');
  // const [allAgents, setallAgents] = useState<User[]>();
  const [teamList, setTeamList] = useState<string[]>([]);
  // const [channel, setChannel] = useState<{ channel: string; value: string,icon: React.FunctionComponent }[]>([]) // flow change drop down open or close
  const teamSelection = (id: string) => {
    const checkDuplicate = teamList.includes(id);
    if (checkDuplicate) {
      const removeDupli = teamList.filter((team) => team !== id);
      setTeamList(removeDupli);
    } else {
      setTeamList([...teamList, id]);
    }
  };
  console.log(
    props.liveAgentAccess,
    liveAgentSelect,
    teamList,
    'liveAgentAccessteam'
  );
  const channel = !props.liveAgentAccess
    ? [{ channel: 'Teams', value: 'teams', icon: TeamsIcon }]
    : [
        {
          channel: 'Workativ Live Agent',
          value: 'liveagent',
          icon: AgentSummaryIconNew,
        },
        { channel: 'Teams', value: 'teams', icon: TeamsIcon },
      ];
  // const {
  //   agentList,
  //   sessionRetry,
  //   dataUpdatedAt,
  //   isRefetching,
  //   isAgentFetching,
  // } = useAgentList(workspace);
  const { AllTeamsData, TeamFetching } = useAllTeams(workspace);
  console.log(AllTeamsData, 'sdAllTeamsData');
  const [agent, setSelectedAgent] = useState<
    AgentHandoffWorkspace | Record<string, never>
  >({});
  // const [agents, setAgents] = useState<AgentHandoffWorkspace[]>([])
  const [selectedChannel, setSelectedCHannel] = useState<any>({});
  // console.log(selectedChannel, channel, agentList, 'selectedChannel');
  const [agentMessage, setAgentMessage] = useState<DraftJST>({
    blocks: [],
    entityMap: {},
  });
  // const [agents, setAgents] = useState<AgentHandoffWorkspace[]>([]);
  const getTeamName = (team: string) => {
    console.log(team, 'sdAllTeamsData');
    return AllTeamsData?.filter((all) => all.id === team)[0]?.name;
  };
  const context = useContext(DialogueContext);
  const {
    workspaceName,
    assistantID,
    PageState,
    setPageState,
    fetchCallback,
    dialogueName,
    agents,
    queryClient,
    parentIfnode,
  } = context;
  console.log(agents, PageState, channel, selectedChannel, 'agenst');
  useEffect(() => {
    const { data } = PageState as AGENT_HANDOFF_RESPONSE;
    setLoading(true);
    console.log(data, 'agenthandoffData');
    if (props.liveAgentAccess) {
      // postJson(agenHandoffUrl(workspaceName, `/${assistantID}/agent/getAgent/`))({ bot_id: assistantID })
      //     .then(resp => resp.data)
      //     .then(agents => {
      if (
        agents.length == 0
        //  ||
        // agent.teams_deploy == null ||
        // agent.teams_deploy.is_active == false
      ) {
        if (data.id.length > 0) {
          //setting Agent handover bot if data has agent_id
          const selectedAgent = agents.filter(
            (a: AgentHandoffWorkspace) => a.id == data.agent_id
          )[0];
          setSelectedAgent(selectedAgent ? selectedAgent : {});
        }
        // setAgents(agents)
        //setting msg if any
        data.id.length > 0 &&
          data.agent_message != null &&
          setAgentMessage(
            converterToEditor(data.agent_message, context, context.dialogueNode)
          );

        if (data.platform !== '' && AllTeamsData) {
          setSelectedCHannel(
            channel.filter((p) => p.value === data.platform)[0]
          );
          setLiveAgentSelect(data.platform === 'teams' ? 'Agent' : 'Team');
          // setLiveAgent(
          //   data.platform === 'teams'
          //     ? ''
          //     : AllTeamsData.length > 0
          //     ? data.meta.teams.value.length> 0 &&
          //       teamListReverse(data.meta.teams.value).map((team: string) =>
          //         getTeamName(team)
          //       )
          //     : ''
          // );
          setTeamList(
            data.platform === 'teams'
              ? []
              : data.meta.teams.value.length > 0
              ? teamListReverse(data.meta.teams.value)
              : []
          );
        } else {
          setSelectedCHannel(channel[1]);
        }

        // setError({
        //   error: true,
        //   info: 'Agent handover not configured yet, <br/> configure agent in agent handover page.',
        // });
        setLoading(false);
      } else {
        if (data.id.length > 0) {
          const selectedAgent = agents.filter(
            (a: AgentHandoffWorkspace) => a.id == data.agent_id
          )[0];
          setSelectedAgent(selectedAgent ? selectedAgent : {});
        }
        // setAgents(agents)
        data.id.length > 0 &&
          data.agent_message != null &&
          setAgentMessage(
            converterToEditor(data.agent_message, context, context.dialogueNode)
          );
        if (data.platform !== '' && AllTeamsData) {
          setSelectedCHannel(
            channel.filter((p) => p.value === data.platform)[0]
          );
          setLiveAgentSelect(data.platform === 'teams' ? 'Agent' : 'Team');
          // setLiveAgent(
          //   data.platform === 'teams'
          //     ? ''
          //     : AllTeamsData.length > 0
          //     ? AllTeamsData &&
          //     data.meta.teams.value.length> 0 &&
          //       teamListReverse(data.meta.teams.value).map((team: string) =>
          //         getTeamName(team)
          //       )
          //       // data.meta.teams.map((team: string) => getTeamName(team))
          //     : // agentList && agentList.length > 0
          //       // ? agentList &&
          //       //   agentList.filter((m) => m.id === data.meta.agent_id.value)[0]
          //       //     .display_name
          //       ''
          // );
          setTeamList(
            data.platform === 'teams'
              ? []
              : data.meta.teams.value.length > 0
              ? teamListReverse(data.meta.teams.value)
              : []
          );
        } else {
          setSelectedCHannel(channel[0]);
        }

        setLoading(false);
      }
    } else {
      if (data.platform === 'teams') {
        if (data.id.length > 0) {
          const selectedAgent = agents.filter(
            (a: AgentHandoffWorkspace) => a.id == data.agent_id
          )[0];
          setSelectedAgent(selectedAgent ? selectedAgent : {});
        }
        setSelectedCHannel(channel.filter((p) => p.value === data.platform)[0]);
        setTeamList(
          data.meta.teams.value.length > 0
            ? teamListReverse(data.meta.teams.value)
            : []
        );
        setLoading(false);
        setAgentMessage(
          converterToEditor(data.agent_message, context, context.dialogueNode)
        );
      } else {
        setSelectedCHannel(channel[0]);
        setLoading(false);
      }
      console.log('check');
    }
  }, [AllTeamsData]);
  const teamListConvert = (teamList: any[]) => {
    const result = teamList.join(",")
      return result
  };
  type teamListT = { type: string; value: string };
  const teamListReverse = (teamList: string) => {
    return teamList.split(",")
  };
  async function handleSave() {
    setLoading(true);
    if (selectedChannel.value === 'teams') {
      try {
        const { data } = PageState as AGENT_HANDOFF_RESPONSE;
        let node: any = null;
        const ag = agent as AgentHandoffWorkspace;

        if (data.id == '') {
          node = await createResponseDialogue(
            workspaceName,
            assistantID,
            data.parent,
            {
              ...data,
              platform: selectedChannel.value,
              agent_id: ag.id,
              agent_message: converterToServer(agentMessage),
            } as AgentHandoffT,
            dialogueName
          );

          await queryClient.invalidateQueries([
            'dialogDescendants',
            (parentIfnode as any).uuid,
          ]);
          await queryClient.invalidateQueries(['assistants']);

          logEvent(workspaceName, {
            event: 'EVENT::DIALOG::ADD_AGENT_HANDOVER',
            event_meta: {
              name: dialogueName,
              description: '',
            },
          });
          lazyGA().event({
            category: 'Dialog Editor',
            action: 'Automation Node Created',
          });
        } else {
          node = await updateResponseDialogue(
            workspaceName,
            assistantID,
            data.parent,
            data.id,
            {
              ...data,
              platform: selectedChannel.value,
              agent_id: ag.id,
              agent_message: converterToServer(agentMessage),
            } as AgentHandoffT,
            dialogueName
          );

          await queryClient.invalidateQueries([
            'dialogDescendants',
            (parentIfnode as any).uuid,
          ]);
          await queryClient.invalidateQueries(['assistants']);

          lazyGA().event({
            category: 'Dialog Editor',
            action: 'Automation Node Updated',
          });
        }

        fetchCallback(() => setLoading(false));
      } catch (error) {
        setLoading(false);
        setError({
          error: true,
          info: 'Something went wrong. Please try again!',
        });
      }
    } else {
      try {
        const { data } = PageState as AGENT_HANDOFF_RESPONSE;
        let node: any = null;
        const ag = agent as AgentHandoffWorkspace;
        const agentMeta = {
          teams: { type: 'text', value: teamListConvert(teamList) },
          bot_id: {
            type: 'text',
            value: assistantID,
          },
        };
        if (data.id == '') {
          node = await createResponseDialogue(
            workspaceName,
            assistantID,
            data.parent,
            {
              ...data,
              platform: selectedChannel.value,
              agent_id: 'workativ_liveagent',
              agent_message: converterToServer(agentMessage),
              meta: agentMeta,
            } as AgentHandoffT,
            dialogueName,
            [],
            "agentHandoff",
            teamList
          );
          await queryClient.invalidateQueries([
            'dialogDescendants',
            (parentIfnode as any).uuid,
          ]);
          await queryClient.invalidateQueries(['assistants']);
          logEvent(workspaceName, {
            event: 'EVENT::DIALOG::ADD_AGENT_HANDOVER',
            event_meta: {
              name: dialogueName,
              description: '',
            },
          });
          lazyGA().event({
            category: 'Dialog Editor',
            action: 'Automation Node Created',
          });
        } else {
          node = await updateResponseDialogue(
            workspaceName,
            assistantID,
            data.parent,
            data.id,
            {
              ...data,
              platform: selectedChannel.value,
              agent_id: 'workativ_liveagent',
              agent_message: converterToServer(agentMessage),
              meta: agentMeta,
            } as AgentHandoffT,
            dialogueName,
            "agentHandoff",
            teamList
          );
          await queryClient.invalidateQueries([
            'dialogDescendants',
            (parentIfnode as any).uuid,
          ]);
          await queryClient.invalidateQueries(['assistants']);
          lazyGA().event({
            category: 'Dialog Editor',
            action: 'Automation Node Updated',
          });
        }

        fetchCallback(() => setLoading(false));
      } catch (error) {
        setLoading(false);
        setError({
          error: true,
          info: 'Something went wrong. Please try again!',
        });
      }
    }
  }

  return (
    <WrapperAgent>
      <PopupWrapper>
        <PopupContainer>
          <AutomationWrapper>
            <PopupScrollHeight>
              {loading ? (
                <div className="popup_loader">
                  <Loader.PopupLoader show={loading} />
                </div>
              ) : (
                <ErrorBoundary
                  error={error}
                  render={(err: any, info: any) => {
                    return (
                      err && (
                        <Error.Delete
                          className={'agent_handoff_error'}
                          onClick={() => setPageState(VIEW)}
                          info={info}
                        />
                      )
                    );
                  }}
                >
                  <React.Fragment>
                    <EmptyDiv>
                      <BgWrapper>
                        <DropdownWrapper>
                          <InputContainer>
                            <Input
                              type="text"
                              value={
                                selectedChannel && selectedChannel.value
                                  ? selectedChannel.channel
                                  : 'Select'
                              }
                              // onChange={(e: any) => {}}
                              onClick={() =>
                                setDropDown(dropDownList.changeChanel)
                              }
                              style={
                                selectedChannel && selectedChannel.value
                                  ? {}
                                  : { paddingLeft: 10 }
                              }
                            />
                            <InputContent>Select agent app</InputContent>
                            <CancelButton
                              onClick={() =>
                                setDropDown(dropDownList.changeChanel)
                              }
                            >
                              <DropdownWithCircle />
                            </CancelButton>
                            <DropdownUlWrapper>
                              <DropDown
                                isOpen={dropDown === dropDownList.changeChanel}
                                onOuterClick={() =>
                                  setDropDown(dropDownList.Closed)
                                }
                              >
                                <div
                                  className="automation_scroly"
                                  role="combobox"
                                  aria-expanded="true"
                                  aria-haspopup="listbox"
                                  aria-owns="downshift-5-menu"
                                  aria-labelledby="downshift-5-label"
                                >
                                  <DropdownUl>
                                    {channel.map((channel, index: number) => (
                                      <DropdownLi
                                        key={index}
                                        onClick={(event: any) => {
                                          setSelectedCHannel(channel);
                                          setDropDown(dropDownList.Closed);
                                          // setLiveAgent('');
                                          setSelectedAgent({});
                                          setLiveAgentSelect('');
                                        }}
                                      >
                                        <div className="dropdown_app_icon">
                                          <channel.icon />
                                        </div>
                                        <div className="application_dropdown_content">
                                          {channel.channel}
                                        </div>
                                      </DropdownLi>
                                    ))}
                                  </DropdownUl>
                                </div>
                              </DropDown>
                            </DropdownUlWrapper>
                          </InputContainer>
                          {selectedChannel && selectedChannel.value && (
                            <div className="input_img_left">
                              <selectedChannel.icon />
                            </div>
                          )}
                        </DropdownWrapper>
                      </BgWrapper>
                      {selectedChannel && selectedChannel.value === 'teams' && (
                        <BgWrapper
                          style={{
                            padding:
                              Object.keys(agent).length > 0
                                ? '0 20px 20px 20px'
                                : '0 20px 20px 20px',
                          }}
                        >
                          <DropdownWrapper>
                            <InputContainer>
                              <Input
                                type="text"
                                value={
                                  Object.keys(agent).length
                                    ? (agent as AgentHandoffWorkspace).name
                                    : 'Select'
                                }
                                // onChange={(e: any) => {}}
                                onClick={() =>
                                  setDropDown(dropDownList.agentDropdown)
                                }
                                // style={
                                //     Object.keys(agent).length && (agent as AgentHandoffWorkspace).id
                                //         ? {}
                                //         : { paddingLeft: 10 }
                                // }
                                style={{ paddingLeft: 10 }}
                              />
                              <InputContent>
                                Select your agent handover bot
                              </InputContent>
                              <CancelButton
                                onClick={() =>
                                  setDropDown(dropDownList.agentDropdown)
                                }
                              >
                                <DropdownWithCircle />
                              </CancelButton>
                              <DropdownUlWrapper>
                                <DropDown
                                  isOpen={
                                    dropDown === dropDownList.agentDropdown
                                  }
                                  onOuterClick={() =>
                                    setDropDown(dropDownList.Closed)
                                  }
                                >
                                  <div
                                    className="automation_scroly"
                                    role="combobox"
                                    aria-expanded="true"
                                    aria-haspopup="listbox"
                                    aria-owns="downshift-5-menu"
                                    aria-labelledby="downshift-5-label"
                                  >
                                    <DropdownUl>
                                      {agents.filter(
                                        (a) =>
                                          a.teams_deploy &&
                                          a.teams_deploy.is_active
                                      ).length > 0 ? (
                                        agents
                                          .filter(
                                            (a) =>
                                              a.teams_deploy &&
                                              a.teams_deploy.is_active
                                          )
                                          .map((agent, index: number) => (
                                            <DropdownLi
                                              key={index}
                                              onClick={(event: any) => {
                                                setSelectedAgent(agent);
                                                setDropDown(
                                                  dropDownList.Closed
                                                );
                                              }}
                                            >
                                              <div className="application_dropdown_content">
                                                {agent.name}
                                              </div>
                                            </DropdownLi>
                                          ))
                                      ) : (
                                        <DropdownLi
                                          style={{ justifyContent: 'center' }}
                                        >
                                          <div
                                            className="application_dropdown_content"
                                            style={{ textAlign: 'center' }}
                                          >
                                            No active Agent bot
                                          </div>
                                        </DropdownLi>
                                      )}
                                    </DropdownUl>
                                  </div>
                                </DropDown>
                              </DropdownUlWrapper>
                            </InputContainer>
                          </DropdownWrapper>
                        </BgWrapper>
                      )}
                      {selectedChannel &&
                        selectedChannel.value === 'liveagent' &&
                        props.liveAgentAccess && (
                          <BgWrapper
                            style={{
                              padding:
                                Object.keys(agent).length > 0
                                  ? '0 20px 20px 20px'
                                  : '0 20px 20px 20px',
                            }}
                          >
                            <DropdownWrapper>
                              <InputContainer>
                                <Input
                                  type="text"
                                  value={
                                    liveAgentSelect !== ''
                                      ? liveAgentSelect
                                      : 'Select'
                                  }
                                  // onChange={(e: any) => {}}
                                  onClick={() =>
                                    setDropDown(dropDownList.agentDropdown)
                                  }
                                  // style={
                                  //     Object.keys(agent).length && (agent as AgentHandoffWorkspace).id
                                  //         ? {}
                                  //         : { paddingLeft: 10 }
                                  // }
                                  style={{ paddingLeft: 10 }}
                                />
                                <InputContent>
                                  Select your agent/team
                                </InputContent>
                                <CancelButton
                                  onClick={() =>
                                    setDropDown(dropDownList.agentDropdown)
                                  }
                                >
                                  <DropdownWithCircle />
                                </CancelButton>
                                <DropdownUlWrapper>
                                  <DropDown
                                    isOpen={
                                      dropDown === dropDownList.agentDropdown
                                    }
                                    onOuterClick={() =>
                                      setDropDown(dropDownList.Closed)
                                    }
                                  >
                                    <div
                                      className="automation_scroly"
                                      role="combobox"
                                      aria-expanded="true"
                                      aria-haspopup="listbox"
                                      aria-owns="downshift-5-menu"
                                      aria-labelledby="downshift-5-label"
                                    >
                                      <DropdownUl>
                                        {/* {liveAgentTeam.map((agent, index: number) => (  */}
                                        <DropdownLi
                                          // key={index}
                                          onClick={(event: any) => {
                                            setAgentMessage({
                                              blocks: [],
                                              entityMap: {},
                                            });
                                            setLiveAgentSelect('Team');
                                            setDropDown(dropDownList.Closed);
                                          }}
                                        >
                                          <div className="application_dropdown_content">
                                            {'Team'}
                                          </div>
                                        </DropdownLi>
                                        {/* )) */}
                                        {/* // : */}
                                        {/* //                                  <DropdownLi style={{ justifyContent : "center" }}>
                                        //                                     <div className="application_dropdown_content" style={{ textAlign: "center" }}>
                                        //                                       No active Agent bot
                                        //                                     </div>
                                        //                                   </DropdownLi> */}
                                        {/* }  */}
                                      </DropdownUl>
                                    </div>
                                  </DropDown>
                                </DropdownUlWrapper>
                              </InputContainer>
                            </DropdownWrapper>
                          </BgWrapper>
                        )}

                      {console.log(teamList, 'checkingdropdown')}
                      {selectedChannel.value === 'liveagent' &&
                        liveAgentSelect &&
                        liveAgentSelect === 'Team' && (
                          <BgWrapper style={{ padding: '0 20px 20px 20px' }}>
                            <DropdownWrapper>
                              <InputContainer>
                                <Input
                                  type="text"
                                  value={teamList.map((team) =>
                                    getTeamName(team)
                                  )}
                                  onClick={() =>
                                    setDropDown(
                                      dropDownList.liveAgentListDropdown
                                    )
                                  }
                                  style={{ paddingLeft: 10 }}
                                />
                                <InputContent>Select your Team</InputContent>
                                <CancelButton
                                  onClick={() =>
                                    setDropDown(
                                      dropDownList.liveAgentListDropdown
                                    )
                                  }
                                >
                                  <DropdownWithCircle />
                                </CancelButton>
                                <DropdownUlWrapper>
                                  <DropDown
                                    isOpen={
                                      dropDown ===
                                      dropDownList.liveAgentListDropdown
                                    }
                                    onOuterClick={() =>
                                      setDropDown(dropDownList.Closed)
                                    }
                                  >
                                    <div
                                      className="automation_scroly"
                                      role="combobox"
                                      aria-expanded="true"
                                      aria-haspopup="listbox"
                                      aria-owns="downshift-5-menu"
                                      aria-labelledby="downshift-5-label"
                                    >
                                      <DropdownUl>
                                        {AllTeamsData &&
                                        AllTeamsData.length > 0 ? (
                                          AllTeamsData.map(
                                            (team:TeamDetailsT, index: number) => (
                                              <DropdownLi
                                                key={index}
                                                onClick={() => {
                                                  teamSelection(team.id);
                                                }}
                                              >
                                                <div className="dropdown_list flex">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      className="option-input radio"
                                                      checked={teamList.includes(
                                                        team.id
                                                      )}
                                                    />
                                                  </label>
                                                  <p>{team.name}</p>
                                                </div>
                                              </DropdownLi>
                                            )
                                          )
                                        ) : (
                                          <DropdownLi
                                            style={{ justifyContent: 'center' }}
                                          >
                                            <div
                                              className="application_dropdown_content"
                                              style={{ textAlign: 'center' }}
                                            >
                                              No team to display
                                            </div>
                                          </DropdownLi>
                                        )}
                                      </DropdownUl>
                                    </div>
                                  </DropDown>
                                </DropdownUlWrapper>
                              </InputContainer>
                            </DropdownWrapper>
                          </BgWrapper>
                        )}
                      {console.log(
                        agent,
                        Object.keys(agent).length > 0,
                        'asjdfkjas'
                      )}
                      {(Object.keys(agent).length > 0 ||
                        liveAgentSelect === 'Team') && (
                        <BgWrapper style={{ padding: '0 20px 20px 20px' }}>
                          <DraftInputWrapper>
                            <Editor
                              DraftMap={agentMessage}
                              setDraftMap={(value: DraftJST) => {
                                setAgentMessage(value);
                              }}
                              focusContent={`Message to live agent`}
                              focus={
                                convertAsaSingleString(
                                  agentMessage.blocks as RawDraftContentBlock[]
                                ).replace(/\s/g, '').length
                                  ? 'focus_input'
                                  : ''
                              }
                            />
                          </DraftInputWrapper>
                        </BgWrapper>
                      )}
                    </EmptyDiv>
                    <Button
                      className="footer_button_fixed"
                      disable={
                        selectedChannel.value === 'teams'
                          ? Object.keys(agent).length === 0 ||
                            Object.keys(selectedChannel).length === 0
                          : teamList.length === 0
                      }
                      onCancel={() => setPageState(VIEW)}
                      onSave={() => handleSave()}
                    />
                  </React.Fragment>
                </ErrorBoundary>
              )}
            </PopupScrollHeight>
          </AutomationWrapper>
        </PopupContainer>
      </PopupWrapper>
    </WrapperAgent>
  );
}
