import { RawDraftContentBlock } from 'draft-js'
/*eslint-disable*/
import React, { useContext, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components/macro'

import { convertAsaSingleString } from '@/Flows/canvas/utils'
import { VideoLink } from '@/Videos/Videos'
import { AddIcon, CloseIcon, InfoIcon, QaIconWithCircle } from '@/common/Icons/Icons'
//ICONS IMPORT
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader'
import lazyGA from '@/common/google-analytics/tracking'
import {
    EmptyDiv,
    Header,
    HeaderLeft,
    Input,
    InputContainer,
    InputContent,
    Paragraph,
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    SmallButton,
    Wrapper
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb'
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb'

import { Button } from '../../Common/Effective'
// TYPE IMPORTS
import { OptionT, OptionsValueT, TextValueT } from '../../Dialogue/kind'
//Rich Editor Imports
import { DraftJST, converterToEditor, converterToServer } from '../../Editor/Action'
import Editor from '../../Editor/Editor'
import { createResponseDialogue, updateResponseDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORTS
import { DialogueContext, VariableContext, VariableContextT } from '../Context'
import { ErrorState, ErrorT, OPTIONS_RESPONSE, VIEW } from '../kind'
import { logEvent } from '@/common/utils/api-utils'

const OptionsMessageTips = () => (
    <>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/options/"
            >
                <b>Menu option</b>
            </a>{' '}
            when you want the bot to display clickable menu with options, to the user.
        </p>
        <p>
            When the user selects an option from the menu, the value of that option gets passed to the chatbot (to
            process next step based on user selection).{' '}
        </p>
        <p>
            For example,
            <br />
            <b>Chatbot:</b> Do you want to continue with the bot?
            <br /> Option: <b>“Yes”</b> Value: <b>Welcome</b>
            <br />
            Option: <b>“No”</b> Value: <b>Exit</b>
        </p>
        <p>
            Then <b>Yes</b> and <b>No</b> dialogs are created in the canvas, once you click on the <b>Save</b> button.
        </p>
        <p>
            You can then add <b>intents</b> that matches the option's value as a condition to execute respective
            dialogs.
        </p>
    </>
)
const InputGrid = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 179px 179px 1fr;
    grid-gap: 10px;

    .target.customTip {
        width: 12px;
        pointer-events: all;
        height: 12px;
        cursor: pointer;
        svg {
            width: 9px;
            pointer-events: all;
            height: 9px;
        }
    }
    .parent_node_no {
        width: 18px;
        height: 18px;
        background: #fff;
        margin: 0;
        position: relative;
        top: 16px;
        left: 2px;
        border-radius: 50%;
        span {
            width: 18px;
            height: 18px;
            border: 1px solid #999;
            border-radius: 50%;
            display: flex;
            align-itmes: center;
            justify-content: center;
            svg {
                width: 8px;
                height: 8px;
                color: #999;
                fill: #999;
            }
        }
        :hover {
            background: #ddfeef;
            cursor: pointer;
            span {
                border: 1px solid #009d5f;
            }
            svg {
                color: #333;
                fill: #333;
            }
        }
    }

    .menu_option_value_box{
        .target.customTip{
            padding-left: 5px;
        }
    }
`
const BgWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px 20px 0px;
    background: #f4f4f4;

    .plus_addmore {
        margin-top: -8px;
        margin-bottom: 20px;
    }
`

const OptionResponse = () => {
    const context = useContext(DialogueContext)
    const { workspaceName, assistantID, setPageState, PageState, fetchCallback, dialogueName, queryClient, parentIfnode } = context

    const variableContext: VariableContextT = React.useContext(VariableContext)

    const { data } = PageState as OPTIONS_RESPONSE

    const [error, setError] = useState<ErrorT>(ErrorState)

    const [loading, setLoading] = useState<boolean>(false)

    const [needChildNode, setNeedChildNode] = useState<boolean>(data.id == '')

    const [title, setTitle] = useState<DraftJST>(converterToEditor(data.prompt_message, context, context.dialogueNode))

    const [options, setOptions] = useState<OptionsValueT[]>(data.options.map((op)=>({...op,value:converterToEditor(op.value,context, context.dialogueNode)})))

    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    const lableHandleChange = (value: TextValueT, index: number) =>
        setOptions(options =>
            options.map((option, count) =>
                count == index
                    ? {
                          ...option,
                          label: value
                      }
                    : option
            )
        )

    const valueHandleChange = (value: DraftJST, index: number) =>
        setOptions(options =>
            options.map((option, count) =>
                count == index
                    ? {
                          ...option,
                          value: value
                      }
                    : option
            )
        )

    const countSet = (count: number) => (count < 10 ? '0' + count.toString() : count)

    const handleSave = async () => {
        try {
            //show Loading
            setLoading(true)

            if (data.id == '') {
                await createResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    {
                        ...data,
                        prompt_message: converterToServer(title),
                        options: options.map((op)=>({...op, value:converterToServer(op.value)}))
                    } as OptionT,
                    dialogueName,
                    needChildNode
                        ? options.map(op => ({
                              title: op.label.value,
                              conditions: null
                          }))
                        : []
                )
                // if (needChildNode) {
                //     let previous_sibling = null
                //     for (let i = 0; i < options.length; i++) {
                //         previous_sibling = await createIfDialogue(
                //             workspaceName,
                //             assistantID,
                //             {
                //                 type: 'if_node',
                //                 description: '',
                //                 title: options[i].label.value,
                //                 conditions: null,
                //                 responses: [],
                //                 parent: data.parent,
                //                 next_step: 'wait_for_user_input',
                //                 previous_sibling: previous_sibling ? previous_sibling.id : null,
                //                 jump_to: null
                //             },
                //             dialogueName
                //         )
                //     }
                // }
                await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
                await queryClient.invalidateQueries(['assistants'])

                logEvent(workspaceName, {
                    event:"EVENT::DIALOG::ADD_MENU_OPTION", event_meta:{
                    name: dialogueName,
                    description: ""
                }})

                lazyGA().event({
                    category: 'Dialog Editor',
                    action: 'Options Node Created'
                })
            } else {
                await updateResponseDialogue(
                    workspaceName,
                    assistantID,
                    data.parent,
                    data.id,
                    {
                        ...data,
                        prompt_message: converterToServer(title),
                        options: options.map((op)=>({...op, value:converterToServer(op.value)}))
                    } as OptionT,
                    dialogueName
                )

            await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
            await queryClient.invalidateQueries(['assistants'])

            }

            fetchCallback(() => setLoading(false))
        } catch (err) {
            setError({
                error: true,
                info: `Server error!<br />Please try again after sometime.`
            })
            setLoading(false)
        }
    }

    return (
        <Wrapper className="menu_option_popup">
            <PopupWrapper>
                <PopupContainer>
                    <Header>
                        <HeaderLeft minWidth={300}>
                            Menu option
                            <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={<OptionsMessageTips />}
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            <a href="https://help.workativ.com/knowledgebase/options" target="_blank">
                                <Tooltip
                                    className="target customTip"
                                    zIndex={10000}
                                    arrowSize={8}
                                    tagName="span"
                                    content="View help document"
                                    distance={5}
                                >
                                    <QaIconWithCircle />
                                </Tooltip>
                            </a>
                        </HeaderLeft>
                        <HeaderRight>
                            <VideoLink id={'ADD_MENU_OPTION'} text={'How to'} />
                        </HeaderRight>
                        {/* ADD_MENU_OPTION */}
                    </Header>
                    <Paragraph>Create clickable menu for the user.</Paragraph>
                    <div className="menu_option_response">
                        <PopupScrollHeight>
                            <ErrorBoundary
                                error={error}
                                render={(err: any, info: any) => {
                                    return err && <Error.Delete onClick={() => setPageState(VIEW)} info={info} />
                                }}
                            >
                                {loading ? (
                                    <div className="popup_loader">
                                        <Loader.PopupLoader show={loading} />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <EmptyDiv>
                                            {/* <div className="slots_right_bot">
                                            <Paragraph className="recognize_dialog_fontsize row">
                                                Allow dialog switching at this point
                                                <Tooltip
                                                    className="target customTip verify_user_tooltips tooltips_color"
                                                    zIndex={10000}
                                                    arrowSize={4}
                                                    tagName="span"
                                                    content={''}
                                                    distance={5}
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                                <Tooltip
                                                    className="target customTip tooltips_color"
                                                    zIndex={10000}
                                                    arrowSize={8}
                                                    tagName="span"
                                                    content="View help document"
                                                    distance={5}
                                                >
                                                    <QaIconWithCircle />
                                                </Tooltip>
                                                <div className="show_hide">
                                                    <div className="switch_box box_1">
                                                        <input type="checkbox" className={`switch_1`} defaultChecked />
                                                    </div>
                                                </div>
                                            </Paragraph>
                                        </div> */}

                                            <div className="slots_right_bot">
                                                <DraftInputWrapper>
                                                    <InputContainer className="menu_option">
                                                        <Editor
                                                            focusContent={'Prompt user with a question'}
                                                            DraftMap={title}
                                                            setDraftMap={setTitle}
                                                            focus={
                                                                convertAsaSingleString(
                                                                    title.blocks as RawDraftContentBlock[]
                                                                ).replace(/\s/g, '').length
                                                                    ? 'focus_input'
                                                                    : ''
                                                            }
                                                        />
                                                    </InputContainer>
                                                </DraftInputWrapper>

                                                {options.map((option, index) => {
                                                    return (
                                                        <div className="opations_label1" key={index}>
                                                            <InputGrid>
                                                                <InputContainer>
                                                                    <Input
                                                                        type="text"
                                                                        value={option.label.value}
                                                                        onChange={(event: any) => {
                                                                            if (option.label.value.length < 80) {
                                                                                lableHandleChange(
                                                                                    {
                                                                                        type: 'text',
                                                                                        value: event.target.value
                                                                                    },
                                                                                    index
                                                                                )
                                                                            } else if (
                                                                                option.label.value > event.target.value
                                                                            ) {
                                                                                lableHandleChange(
                                                                                    {
                                                                                        type: 'text',
                                                                                        value: event.target.value
                                                                                    },
                                                                                    index
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                    {/* <span>{countSet(index + 1)}</span> */}

                                                                    <InputContent>Option</InputContent>
                                                                </InputContainer>

                                                                <InputContainer>
                                                                    {/* <Editor
                                                                        type="text"
                                                                        value={option.value.value}
                                                                        onChange={(event: any) =>
                                                                            valueHandleChange(
                                                                                {
                                                                                    type: 'text',
                                                                                    value: event.target.value
                                                                                },
                                                                                index
                                                                            )
                                                                        }
                                                                    /> */}
                                                                    <DraftInputWrapper className="menu_option_value_box">
                                                                        <Editor
                                                                            focusContent={'Value'}
                                                                            focusTooltip
                                                                            updated={isUpdated}
                                                                            focusTooltipContent={
                                                                                'Option value acts as user’s selection (to process next step)'
                                                                            }
                                                                            DraftMap={option.value}
                                                                            setDraftMap={(value:DraftJST)=>{
                                                                                valueHandleChange(value, index)
                                                                            }}
                                                                            hideToolbar
                                                                            focus={
                                                                                convertAsaSingleString(
                                                                                    option.value.blocks as RawDraftContentBlock[]
                                                                                ).replace(/\s/g, '').length
                                                                                    ? 'focus_input menu_popup'
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    </DraftInputWrapper>
                                                                    {/* <InputContent>
                                                                        Value{' '}
                                                                        <Tooltip
                                                                            className="target customTip"
                                                                            zIndex={10000}
                                                                            arrowSize={8}
                                                                            tagName="span"
                                                                            content={
                                                                                'Option value acts as user’s selection (to process next step)'
                                                                            }
                                                                            distance={5}
                                                                        >
                                                                            <InfoIcon />
                                                                        </Tooltip>
                                                                    </InputContent> */}
                                                                </InputContainer>

                                                                {options.length > 1 ? (
                                                                    <div
                                                                        className="parent_node_no"
                                                                        onClick={() =>{
                                                                            setOptions(options =>
                                                                                options.filter(
                                                                                    (option, count) => count != index
                                                                                )
                                                                            )
                                                                            setIsUpdated(true)
                                                                            setTimeout(()=>{
                                                                                setIsUpdated(false)
                                                                            })
                                                                        }}
                                                                    >
                                                                        <span className="parent_svg">
                                                                            <CloseIcon />
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </InputGrid>
                                                        </div>
                                                    )
                                                })}

                                                {options.filter(
                                                    data => !convertAsaSingleString(
                                                        data.value.blocks as RawDraftContentBlock[]
                                                    ).replace(/\s/g, '').length || data.label.value == ''
                                                ).length == 0 && options.length < 20 ? (
                                                    <div className="plus_addmore">
                                                        <div
                                                            className="plus_add_more"
                                                            onClick={() =>
                                                                setOptions(options =>
                                                                    options.concat({
                                                                        label: { type: 'text', value: '' },
                                                                        value: { entityMap:{},blocks:[] }
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <SmallButton primary>
                                                                <span>
                                                                    <AddIcon />
                                                                </span>
                                                                <label>Add More</label>
                                                            </SmallButton>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </EmptyDiv>

                                        {/* {data.id == '' ? (
                                        <HeaderWithSwitch style={{ margin: '0px 0px 20px' }}>
                                            <Header>
                                                Create Sub-dialogs for each option{' '}
                                                <div className="popup_show_hide">
                                                    <div className="show_hide">
                                                        <div className="switch_box box_1">
                                                            <input
                                                                type="checkbox"
                                                                className="switch_1"
                                                                checked={needChildNode}
                                                                onChange={() =>
                                                                    setNeedChildNode(needChildNode => !needChildNode)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Header>
                                        </HeaderWithSwitch>
                                                            ) : null} */}
                                        <Button
                                            className="footer_button_fixed"
                                            disable={
                                                options.filter(
                                                    data => convertAsaSingleString(
                                                        data.value.blocks as RawDraftContentBlock[]
                                                    ).replace(/\s/g, '').length && data.label.value !== ''
                                                ).length != options.length|| variableContext.isUrlOpen
                                                // || title == ''
                                            }
                                            onCancel={() => setPageState(VIEW)}
                                            onSave={handleSave}
                                        />
                                    </React.Fragment>
                                )}
                            </ErrorBoundary>
                        </PopupScrollHeight>
                    </div>
                </PopupContainer>
            </PopupWrapper>
        </Wrapper>
    )
}

export default OptionResponse
