/*eslint-disable*/
import * as React from 'react'

import { DeleteIcon, EditIcon, UnGroupIcon } from '@/common/Icons/Icons'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { SelectBox } from './SelectBox'
import { CardDisplayT, CardTypes, Mode, Modes } from './types'

let Parser = require('html-react-parser')

type CardP = {
    card: CardDisplayT
    select: (card: CardDisplayT) => void
    ungroup: (card: CardDisplayT) => void
    delete: (card: CardDisplayT) => void
    click: () => void
    edit: any
    mode: Modes
}

type CardS = {
    show: boolean
}
export default class GroupCard extends React.Component<CardP, CardS> {
    state: CardS = {
        show: false
    }

    isSelected = () => {
        const { mode, card } = this.props
        return (
            mode.mode == Mode.Select &&
            mode.cards.filter(e => e.id == card.id && e.cardType == CardTypes.Group).length != 0
        )
    }

    handleDrag = () => {
        this.setState(({ show }) => ({
            show: !show
        }))
    }

    handleSelected = () => {
        this.props.select(this.props.card)
    }

    showSelection = () => this.props.mode.mode == Mode.Select || this.state.show

    renderToolBox = () => {
        return !(this.props.mode.mode == Mode.Select) ? (
            <ToolBox>
                <span
                    className="home_edit"
                    onClick={event => {
                        event.stopPropagation()
                        this.props.delete(this.props.card)
                    }}
                >
                    <DeleteIcon />
                </span>
                <span
                    className="home_edit"
                    onClick={event => {
                        event.stopPropagation()
                        this.props.edit(this.props.card)
                    }}
                >
                    <EditIcon />
                </span>
                <span
                    className="home_edit"
                    onClick={event => {
                        event.stopPropagation()
                        this.props.ungroup(this.props.card)
                    }}
                >
                    <UnGroupIcon />
                </span>
            </ToolBox>
        ) : null
    }

    render() {
        let { displayLabel, description: info, modified_date: modifiedDate } = this.props.card
        return (
            <div className="col-3 dialog_grids">
                <div
                    className={
                        this.props.mode.mode == Mode.Select
                            ? 'entity_cards_layer'
                            : 'entity_cards_layer show_create_card'
                    }
                    onMouseEnter={this.handleDrag}
                    onMouseLeave={this.handleDrag}
                    onClick={this.props.click}
                >
                    <div className="col-lg-2 col-md-2 col-sm-6 col-12 entity_flow_layer">
                        <SelectBox
                            show={this.showSelection()}
                            isSelected={this.isSelected()}
                            handleSelected={this.handleSelected}
                        />
                        {this.renderToolBox()}
                        {Parser(displayLabel)}
                        <span className="entity_span">{formatDateInCard(modifiedDate)}</span>
                        <span className="entity_span">{formatAMPM(modifiedDate)}</span>
                        <p>{info}</p>
                    </div>
                    <div className="flow_label_grp">
                        <SelectBox
                            show={this.showSelection()}
                            isSelected={this.isSelected()}
                            handleSelected={this.handleSelected}
                        />
                        {this.renderToolBox()}
                        {Parser(displayLabel)}
                        <span className="entity_span">{formatDateInCard(modifiedDate)}</span>
                        <span className="entity_span">{formatAMPM(modifiedDate)}</span>
                        <p>{info}</p>
                    </div>
                </div>
            </div>
        )
    }
}
const ToolBox: React.FunctionComponent = ({ children }) => {
    return <div className="entity_hvr">{children}</div>
}
