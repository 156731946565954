import produce from 'immer'
import * as R from 'ramda'
/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { QueryObserverIdleResult, QueryObserverLoadingResult, QueryObserverSuccessResult, useQuery } from 'react-query'

import { VideoButtonB } from '@/Videos/Videos'
import { DownArrowCircle, SearchIcon } from '@/common/Icons/Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'

import { convertObjectToArray } from '@/common/utils/utils'
import {
    MarketPlaceApi,
    automationAPIURL,
    automationAPIURLWithWorkspace,
    postJson,
    wrapperGetJson,
    wrapperPostJson
} from '@/common/utils/api-utils'
import { MarketPlaceItems } from './Applications'
import { CardsApplications } from './CardsApplications'
import { CarosoulSlider } from './Carosoul'
import { ReactSetStateObject } from './ModalActions'
import { SelectedItems } from './SelectedItems'
import { MarketPlaceHeader, MarketPlaceHeaderTyped } from './common'
import {
    Action,
    ActionTypes,
    Actions,
    Application,
    DeployAcknowledgement,
    MarketPlaceApplication,
    MarketPlaceAutomation,
    MarketPlaceAutomationRaw,
    None,
    SelectApplication
} from './types'
import { SortBy, allAreSame, getAllSubsets, uniqueMarketPlaceApps, uniqueMarketPlaceAutomations } from './utils'
import { DropdownLi, DropdownUlWrapper, Input, InputContainer } from '@/common/styled/Workflow.Analytics.Dumb'
import styled from 'styled-components'
import { Dropdwon } from '@/DialogEditor/TrainingModule/TrainingModule'

let Parser = require('html-react-parser')

//import { y as requestMock } from './requestMock'

const DropdownContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 66% 34%;
    height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 12px;

    ${DropdownUlWrapper} {
        margin-bottom: 0px;
        ${DropdownLi} {
            justify-content: space-between;
            .option-input.radio {
                top: 2px;
                position: relative;
                margin-right: 0px;
            }
        }
    }
    ${InputContainer} {
        margin-bottom: 0px;
        ${Input} {
            padding-right: 30px;
        }
    }

    .tab_container_left {
        display: grid;
        grid-template-columns: 240px 240px 240px;
        grid-gap: 20px;
    }
    .tab_container_right {
        display: grid;
        grid-template-columns: 180px auto;
        grid-gap: 20px;
        justify-content: flex-end;
        .prev_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
                fill: #555;
                transform: rotate(90deg);
            }
        }
        .next_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                fill: #555;
                cursor: pointer;
                transform: rotate(-90deg);
            }
        }
        p {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
        }
    }
`

type OptionsP<T> = Array<{
    text: string
    value: T
}>

type DropDownP<T> = {
    selectedValue: T
    options: OptionsP<T>
    change: (t: T) => void
}
const SortDropdown = (props: DropDownP<SortBy>) => {
    const [showDropDown, setShow] = useState<boolean>(false)
    const selectedText = props.options.filter(x => x.value === props.selectedValue)[0].text
    return (
        <div className="marketplace_dropdowns">
            <div className="date_dropdown_label" onClick={() => setShow(true)}>
                {selectedText}
                <div className="dropdown_down_arrowslot">
                    <DownArrowCircle />
                </div>
                {showDropDown && (
                    <div
                        className="dropdown_right_entity"
                        role="combobox"
                        aria-expanded="true"
                        aria-haspopup="listbox"
                        aria-owns="downshift-17-menu"
                        aria-labelledby="downshift-17-label"
                    >
                        <ul className="ana_chart_dropdown_right_entity">
                            {props.options.map(x => (
                                <li
                                    onClick={(e: any) => {
                                        props.change(x.value)
                                        setShow(false)
                                        e.stopPropagation()
                                    }}
                                >
                                    <p>{x.text} </p>
                                    <span>
                                        <input
                                            type="radio"
                                            className="option-input radio"
                                            name="example"
                                            checked={props.selectedValue === x.value}
                                        />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

type Steps = 'AppSelection' | 'AutomationListing'
type stepUrl = 'list-automations' | 'select-application'

const routeStepMapping: Record<stepUrl, Steps> = {
    'list-automations': 'AutomationListing',
    'select-application': 'AppSelection'
}

const figureOutRouteKey = (pathName: string): Steps => {
    const routeKey = pathName.split('/').reverse()[0] as stepUrl
    const step = routeStepMapping[routeKey]
    return step
}
const figureOutRouteValue = (pathName: Steps): string => {
    const values = Object.entries(routeStepMapping)
    return values.filter(([, value]) => value === pathName)[0][0]
}

export const loadMarketPlaceAutomations = async (wName: string) =>
    wrapperGetJson(automationAPIURLWithWorkspace(wName, MarketPlaceApi.AllMarketPlaceAutomation)).then(
        (response: any) => {
            return wrapperGetJson(automationAPIURL(MarketPlaceApi.AllCategories)).then((resp: any) => ({
                apps: response.data,
                categories: resp.data
            }))
        }
    )

export function MarketPlaceLoadStateHandler(props: any) {
    const workspace = props.match.params.workspacename
    const [state, , , retry] = useFetchFromApi(() => loadMarketPlaceAutomations(props.match.params.workspacename))

    const LoadingError = {
        error: true,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    }
    const NoMarketPlaceData = {
        show: state.type === 'success' && R.isEmpty(state.data),
        noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
        onClick: () => {},
        btnName: 'Move One'
    }

    if (state.type === 'loading') {
        return <Loader.PageLoader show={true} />
    }

    return (
        <ErrorBoundary
            noDataFound={NoMarketPlaceData}
            error={state.type === 'error' ? LoadingError : { error: false, info: null }}
            render={(err: any, info: any) => {
                return err && <Error.PageLoadingError onClick={retry} info={info} btnName="Retry" />
            }}
        >
            {state.type === 'success' && (
                <StepSwitcher {...props}>
                    {(step: any, _workspace: string, redirectToMarketplaceDetail: (id: string) => void) => (
                        <AutomationMarketPlace
                            allAutomationList={state.data.apps.output}
                            allCategories={state.data.categories.output}
                            step={step}
                            workspaceName={workspace}
                            redirectToMarketplaceDetail={redirectToMarketplaceDetail}
                        />
                    )}
                </StepSwitcher>
            )}
        </ErrorBoundary>
    )
}

export function StepSwitcher(props: any) {
    const workspaceName = props.match.params.workspacename
    const [step, setStep] = useState<Steps>('AppSelection')

    useEffect(() => {
        const routeValue = figureOutRouteValue(step)
        props.history.push(`/workspace/${workspaceName}/marketplace/automation/${routeValue}`)
    }, [step])

    useEffect(() => {
        const step = figureOutRouteKey(props.history.location.pathname)
        setStep(step)
    }, [props.history.location])

    const redirectToMarketplaceDetail = (id: string) => {
        props.history.push(`/workspace/${workspaceName}/marketplace/workflow/detail/${id}`)
    }

    return props.children([step, setStep], workspaceName, redirectToMarketplaceDetail)
}
const sortByNameCaseInsensitive = R.compose<any, any, any>(R.toLower, R.prop<string, string>('name'))
const sortByNameDate = R.prop<string, string>('lastAutomationModifiedDate') as any
const sortByNameCategory = R.compose(R.toLower, R.prop('name') as any)

type Search = {
    initialSearchFocus: boolean
    searchText: string
}

function convertObjectToArrayTyped<T>(object: { [key: string]: T }) {
    if (!object) return []

    return Object.keys(object).map(function(key) {
        return { name: key, value: object[key] }
    })
}
export function filterFun(validApps: SelectApplication[], catApps: { [key: string]: SelectApplication[] }) {
    console.log('filterFun', validApps, catApps)
    const categoryApps = convertObjectToArrayTyped(catApps)
    const filtered = categoryApps
        .map(cat => {
            const filteredApps = cat.value
                .filter((app: any) => validApps.filter(a => a.name == app.appName).length > 0)
                .map((app: any) => ({
                    id: app.appId,
                    image: app.icon,
                    name: app.appName,
                    isSelected: false,
                    lastAutomationModifiedDate: new Date()
                }))
            return filteredApps.length > 0
                ? {
                      ...cat,
                      value: filteredApps
                  }
                : null
        })
        .filter(x => x !== null)

    return filtered
}

export function AutomationMarketPlace(props: any) {
    const itemsSelectionLimit = 5
    const initializeSelectApp = (item: MarketPlaceApplication) => ({ ...item, isSelected: false } as SelectApplication)
    const workspaceName = props.workspaceName

    const rawData = props.allAutomationList as Record<string, MarketPlaceAutomationRaw[]>
    const uniqueApps = uniqueMarketPlaceApps(rawData)
    const automationList = uniqueMarketPlaceAutomations(rawData)
    const [category, setCategory] = useState(filterFun(R.map(initializeSelectApp, uniqueApps), props.allCategories))

    const sortByAppName: any = R.compose(R.toLower, R.prop<string, string>('appName'))

    const [sortBy, changeSort] = useState<SortBy>('Alphabetical')
    const [search, setSearch] = useState<Search>({ initialSearchFocus: false, searchText: '' })
    const [searchApp, setSearchApp] = useState<Search>({ initialSearchFocus: false, searchText: '' })

    const [action, setAction] = useState<ActionTypes>(None)
    const [apps, setApps] = useState<SelectApplication[]>(R.map(initializeSelectApp, uniqueApps))
    const [filteredAutomationList, setAutomationList] = useState<MarketPlaceAutomation[][]>([])
    const [step, setStep] = props.step as [Steps, React.Dispatch<React.SetStateAction<Steps>>]
    const [renderAcknowledgeModal] = acknowledgeDeploy([action, setAction])
    const [appForSideMenu, setAppforSideMenu] = useState<SelectApplication[]>(R.map(initializeSelectApp, uniqueApps))
    const [currentCategories, setCurrentCategories] = useState<string[]>([])
    const [showMore, setShowMore] = useState({ appShowMore: false, catShowMore: false })
    const [otherWorkFlows, setOtherWorkFlows] = useState<MarketPlaceAutomation[][]>([])
    const selectedItems = R.uniq(
        apps.filter(app => app.isSelected).concat(appForSideMenu.filter(app => app.isSelected))
    )
    // Scroll sticky added

    const [header, setHeader] = useState('marketplace_search_sticky')

    const listenScrollEvent = (e: any) => {
        if (window.scrollY < 40) {
            return setHeader('marketplace_search_sticky')
        } else if (window.scrollY > 40) {
            return setHeader('marketplace_search_sticky marketplace_search_sticky_fixed')
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)

        return () => window.removeEventListener('scroll', listenScrollEvent)
    }, [])

    // Scroll sticky added

    const filteredItems =
        search.searchText == ''
            ? apps
            : apps.filter(app => app.name.toLowerCase().includes(search.searchText.toLowerCase()))

    const headerInfo =
        step === 'AutomationListing'
            ? `You have selected ${selectedItems.length} applications`
            : `you can select maximum ${itemsSelectionLimit} applications`

    useEffect(() => {
        const sortedApps: SelectApplication[] =
            sortBy === 'Alphabetical' ? R.sortBy(sortByNameCaseInsensitive, apps) : R.sortBy(sortByNameDate, apps)
        setApps(sortedApps)
    }, [sortBy])

    useEffect(() => {
        if (step === 'AutomationListing') {
            if (selectedItems.length <= 0) {
                setStep('AppSelection')
            } else {
                const allPossibleAppCombinations = getAllSubsets(selectedItems.map(app => app.id))
                const filteredAutomations = allPossibleAppCombinations
                    .filter(a => a.length == 1 || a.length == selectedItems.length)
                    .map(appItem => {
                        if (appItem.length == 0) return { matchingAutomationForSingleAppSet: [], otherWorkFlows: [] }
                        const filterOnlyMatchingAppSet = R.converge(allAreSame, [
                            R.compose((x: any) => x.length, R.intersection(appItem)),
                            R.always(appItem.length),
                            R.length
                        ])

                        const filterAutomations = R.compose<
                            MarketPlaceAutomation,
                            Array<Application & Action>,
                            string[],
                            string[],
                            boolean
                        >(filterOnlyMatchingAppSet, R.uniq, R.map(R.prop('id')), R.prop('apps'))

                        const matchingAutomationForSingleAppSet: MarketPlaceAutomation[] = R.filter(filterAutomations)(
                            automationList
                        )

                        return {
                            matchingAutomationForSingleAppSet: matchingAutomationForSingleAppSet,
                            otherWorkFlows: []
                        }
                    }) as any
                setOtherWorkFlows(filteredAutomations.map((auto: any) => auto.otherWorkFlows))

                const sortByLength = R.sort(function(a: any, b: any) {
                    return b.length - a.length
                })

                const sortLengthByApps = R.sort(function(a: any, b: any) {
                    return b.apps.length - a.apps.length
                })

                const newAutomationList = R.compose(
                    R.map(sortLengthByApps)
                    // sortByLength
                )(
                    filteredAutomations
                        .map(
                            (auto: any) =>
                                auto.matchingAutomationForSingleAppSet.length > 0 &&
                                auto.matchingAutomationForSingleAppSet
                        )
                        .filter(Boolean)
                )

                setAutomationList(newAutomationList)
            }
        } else {
            // setAppforSideMenu(R.map(initializeSelectApp, uniqueApps))
        }
    }, [step, apps])

    const changeAppSelection = (action: 'Toggle' | 'Deselect') => (item: any) => {
        const typedItem = item as SelectApplication
        const nextState = produce(
            currentCategories.length > 0 ? R.uniq(apps.concat(appForSideMenu)) : appForSideMenu,
            apps => {
                apps.forEach(a => {
                    if (a.name == typedItem.name) {
                        const toSet = action == 'Toggle' ? !typedItem.isSelected : false
                        a.isSelected = toSet
                    }
                })
            }
        )

        const appsForSideMenu = produce(appForSideMenu, apps => {
            apps.forEach(a => {
                if (a.name == typedItem.name) {
                    const toSet = action == 'Toggle' ? !typedItem.isSelected : false
                    a.isSelected = toSet
                }
            })
        })
        setApps(nextState)
        setAppforSideMenu(appsForSideMenu)
        setSearch({ ...search, searchText: '' })
        setStep('AutomationListing')
    }
    const sort = (s: SortBy) => changeSort(s)

    const options: OptionsP<SortBy> = [
        {
            text: 'Show by alphabetical',
            value: 'Alphabetical'
        },
        {
            text: 'Show by latest',
            value: 'Time'
        }
    ]
    const resetInitialState = () => {
        const appsWithSelectionCleared = produce(apps, apps => {
            apps.forEach(a => {
                a.isSelected = false
            })
        })

        const sideMenuAppsWithSelectionCleared = produce(appForSideMenu, apps => {
            apps.forEach(a => {
                a.isSelected = false
            })
        })

        setAppforSideMenu(sideMenuAppsWithSelectionCleared)

        setApps(appsWithSelectionCleared)

        setSearch({ initialSearchFocus: false, searchText: '' })
    }
    const addToWorkSpace = async (automation: MarketPlaceAutomation) => {
        await postJson(automationAPIURLWithWorkspace(workspaceName, MarketPlaceApi.AddToWorkSpace))({
            automationId: automation.id
        })
        setAction(DeployAcknowledgement)
    }
    const [checked, setChecked] = React.useState(true)

    const Wrapper = (props: { children: React.ReactNode }) => {
        return (
            <div className="sidepanel_menu_right">
                <div className="sidepanel_menu_right_head">
                    <h4>Marketplace</h4>
                </div>
                <div className="sidepanel_menu_right_menu">
                    <div className="sidepanel_menu_right_menu_step">
                        <div className="automation_left_panel">
                            <div className="automation_left_panel_tree">
                                <div className="left_popup_automation_tree">{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <React.Fragment>
            {renderAcknowledgeModal()}
            <div className="market_place_wrapper marketplace_page_side_menu">
                {/* sideapanel */}
                <div className="flex_side_menu marketplace_app_workflow_line">
                    <Wrapper>
                        <div className="sidepanel_menu_right_menu">
                            {step == 'AutomationListing' ? (
                                <SidebarSearch
                                    appForSideMenu={appForSideMenu}
                                    showMore={showMore}
                                    changeAppSelection={changeAppSelection}
                                    toShow={step == 'AutomationListing'}
                                    setShowMore={setShowMore}
                                />
                            ) : null}
                            {/*  */}
                            {step != 'AutomationListing' ? (
                                <div className="app_category_wrapper">
                                    <h5>App Category</h5>
                                    <div className="side_menu_ul">
                                        <div
                                            className="side_menu_li"
                                            onClick={() => {
                                                if (step == 'AppSelection') {
                                                    setCurrentCategories([])
                                                    setApps(R.map(initializeSelectApp, uniqueApps))
                                                    setStep('AppSelection')
                                                }
                                            }}
                                        >
                                            <p>
                                                All Integrations{' '}
                                                <span className="apps_counter">
                                                    ({R.map(initializeSelectApp, uniqueApps).length})
                                                </span>
                                            </p>
                                        </div>
                                        {category
                                            .slice(
                                                0,
                                                showMore.catShowMore
                                                    ? R.sortBy(sortByNameCategory, convertObjectToArray(category))
                                                          .length
                                                    : 15
                                            )
                                            .map((obj: any, i) => (
                                                <div
                                                    className="side_menu_li"
                                                    onClick={() => {
                                                        if (step == 'AppSelection' && !R.equals(apps, obj.value)) {
                                                            setApps(obj.value)
                                                            setStep('AppSelection')
                                                        }
                                                    }}
                                                >
                                                    <p>
                                                        {obj.name}{' '}
                                                        <span className="apps_counter">({obj.value.length})</span>
                                                    </p>
                                                </div>
                                            ))}

                                        {!showMore.catShowMore && (
                                            <div
                                                className="side_menu_li show_more_link"
                                                onClick={() => setShowMore({ ...showMore, catShowMore: true })}
                                            >
                                                {' '}
                                                <span>Show More</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Wrapper>
                </div>
                <div className="marketplace_container">
                    <MarketPlaceHeader
                        title={step === 'AutomationListing' ? 'Workflows' : 'App Workflow Marketplace'}
                        content={
                            step === 'AutomationListing'
                                ? ''
                                : 'Download from over 600+ pre-built app workflows for your chatbot.'
                        }
                        showBackButton={step === 'AutomationListing'}
                        clearSelection={resetInitialState}
                    >
                        {step === 'AppSelection' ? (
                            <React.Fragment>
                                <HeaderChild type="second">
                                    <SortDropdown selectedValue={sortBy} change={sort} options={options} />
                                    <VideoButtonB id={'DOWNLAOD_PRE_BUILT_APP_WORKFLOW'} />
                                </HeaderChild>
                            </React.Fragment>
                        ) : null}
                    </MarketPlaceHeader>
                    <>
                        <div className={step == 'AppSelection' ? 'marketplace_wrapper_' : 'marketplace_wrapper test1'}>
                            <SelectedItems.Wrapper>
                                {step === 'AppSelection' && <CarosoulSlider />}
                                <div className={header}>
                                    {step == 'AppSelection' && (
                                        <SelectedItems.Header
                                            heading="Select an Application"
                                            info={''}
                                            emphasizeInfo={selectedItems.length > itemsSelectionLimit}
                                        />
                                    )}
                                    <div className="search_marketpalce">
                                        <input
                                            className="search_click"
                                            type="search"
                                            placeholder={step == 'AppSelection' ? 'Search apps' : 'Search Workflows'}
                                            value={search.searchText}
                                            onChange={e => setSearch({ ...search, searchText: e.target.value })}
                                        />
                                        <span className="search_icon">
                                            {/* <i className="fa fa-search livesearch"></i> */}
                                            <SearchIcon />
                                        </span>
                                    </div>
                                </div>
                                {/* <SelectedItems.Main>
                                    <SelectedItems
                                        allowDeselection={step === 'AppSelection'}
                                        items={selectedItems}
                                        deselectItem={changeAppSelection('Deselect')}
                                    />
                                    {step == 'AppSelection' ? (
                                        <NextStepButton
                                            disabled={
                                                selectedItems.length == 0 || selectedItems.length > itemsSelectionLimit
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => setStep('AutomationListing')}
                                        />
                                    ) : null}
                                </SelectedItems.Main> */}
                            </SelectedItems.Wrapper>

                            {step == 'AppSelection' ? (
                                <MarketPlaceItems
                                    items={filteredItems}
                                    selectionLimit={5}
                                    toggleItemSelection={changeAppSelection('Toggle')}
                                />
                            ) : (
                                <CardsApplications
                                    otherWorkFlows={otherWorkFlows}
                                    listItems={filteredAutomationList}
                                    selectedApps={selectedItems}
                                    addToWorkSpace={addToWorkSpace}
                                    searchText={search.searchText}
                                    redirectToMarketplaceDetail={props.redirectToMarketplaceDetail}
                                />
                            )}
                        </div>
                    </>
                </div>
            </div>
        </React.Fragment>
    )
}

export function SidebarSearch({
    appForSideMenu,
    showMore,
    toShow,
    changeAppSelection,
    setShowMore
}: {
    appForSideMenu: SelectApplication[]
    toShow: boolean
    showMore: {
        appShowMore: boolean
        catShowMore: boolean
    }
    setShowMore: Function
    changeAppSelection: Function
}) {
    const [searchApp, setSearchApp] = useState<Search>({ initialSearchFocus: false, searchText: '' })

    if (!toShow) {
        return null
    }
    return (
        <div className="checkbox_wrapper">
            <h5>Selected Applications</h5>
            <div className="search_marketpalce">
                <input
                    key="search-apps"
                    className="search_click"
                    type="search"
                    placeholder={'Search apps'}
                    value={searchApp.searchText}
                    onChange={e => setSearchApp({ ...searchApp, searchText: e.target.value })}
                />
                <span className="search_icon">
                    {/* <i className="fa fa-search livesearch"></i> */}
                    <SearchIcon />
                </span>
            </div>
            <ul>
                {appForSideMenu
                    .slice(0, showMore.appShowMore ? appForSideMenu.length : 15)
                    .filter(
                        a =>
                            searchApp.searchText.length == 0 ||
                            (searchApp.searchText.length > 0 &&
                                a.name.toLowerCase().indexOf(searchApp.searchText.toLowerCase()) != -1)
                    )
                    .map((app, i) => (
                        <li key={i}>
                            {' '}
                            <label className="container-checkbox">
                                {app.name}
                                <input
                                    type="checkbox"
                                    checked={app.isSelected}
                                    onClick={() => changeAppSelection('Toggle')(app)}
                                />
                                <span className="checkmark" />
                            </label>
                        </li>
                    ))}
                {!showMore.appShowMore && (
                    <li className="show_more_link" onClick={() => setShowMore({ ...showMore, appShowMore: true })}>
                        <span>Show More</span>
                    </li>
                )}
            </ul>
        </div>
    )
}

export function acknowledgeDeploy([nonCanvasAction, setNonCanvasAction]: ReactSetStateObject<ActionTypes>) {
    const renderAcknowledgeModal = () => {
        if (nonCanvasAction.mode === Actions.Acknowledge) {
            return (
                <Modal>
                    <div className="error_style">
                        <p>{Parser(nonCanvasAction.message)}</p>
                        <button className="btn btn-error center" onClick={() => setNonCanvasAction(None)}>
                            Okay
                        </button>
                    </div>
                </Modal>
            )
        }
        return null
    }
    return [renderAcknowledgeModal]
}

export function StepSwitcherV2(props: any) {
    const workspaceName = props.match.params.workspacename
    const [step, setStep] = useState<Steps>('AppSelection')

    // useEffect(() => {
    //     const routeValue = figureOutRouteValue(step)
    //     props.history.push(`/workspace/${workspaceName}/marketplace/automation/${routeValue}`)
    // }, [step])

    useEffect(() => {
        const step = figureOutRouteKey(props.history.location.pathname)
        setStep(step)
    }, [props.history.location])

    const _redirectToMarketplaceDetail = (id: string) => {
        props.history.push(`/workspace/${workspaceName}/marketplace/workflow/detail/${id}`)
    }

    const _redirectToMarketplaceAppSelection = (id: string) => {
        props.history.push(`/workspace/${workspaceName}/marketplace/automation/select-application`)
    }

    const _redirectToMarketplaceWorkflows = (id: string) => {
        const location = {
            pathname: `/workspace/${workspaceName}/marketplace/automation/list-workflows`,
            search: `?apps=${[id].join(',')}`
        }
        props.history.push(location)
    }

    return props.children(
        [step, setStep],
        workspaceName,
        _redirectToMarketplaceDetail,
        _redirectToMarketplaceWorkflows,
        _redirectToMarketplaceAppSelection
    )
}

type CategoryApps = {
    appId: string
    appName: string
    categoryId: string
    icon: string
    scope: string
}

export function filterFunTyped(validApps: SelectApplication[], catApps: { [key: string]: CategoryApps[] }) {
    const categoryApps = convertObjectToArrayTyped(catApps)
    const filtered = categoryApps
        .map(cat => {
            const filteredApps = cat.value
                .filter((app: CategoryApps) => validApps.filter(a => a.name == app.appName).length > 0)
                .map(
                    (app: CategoryApps) =>
                        ({
                            id: app.appId,
                            image: app.icon,
                            name: app.appName,
                            isSelected: false,
                            lastAutomationModifiedDate: new Date()
                        } as SelectApplication)
                )
            return filteredApps.length > 0
                ? {
                      ...cat,
                      value: filteredApps
                  }
                : null
        })
        .filter(x => x !== null)

    return (filtered as unknown) as {
        value: SelectApplication[]
        name: string
    }[]
}

export function categoryAppsDataTransform(catApps: { [key: string]: CategoryApps[] }) {
    const categoryApps = convertObjectToArrayTyped(catApps)
    const filtered = categoryApps
        .map(cat => {
            const filteredApps = cat.value.map(
                (app: CategoryApps) =>
                    ({
                        id: app.appId,
                        image: app.icon,
                        name: app.appName,
                        isSelected: false,
                        lastAutomationModifiedDate: new Date()
                    } as SelectApplication)
            )

            return {
                ...cat,
                value: filteredApps
            }
        })
        .filter(x => x !== null)

    return (filtered as unknown) as {
        value: SelectApplication[]
        name: string
    }[]
}

const uniqCategoryAppsTransform = (x: SelectApplication[], sortBy: SortBy = 'Alphabetical') => {
    const equalApps = (a: SelectApplication, b: SelectApplication) => {
        return a.id === b.id
    }
    const fn = (x: SelectApplication) => {
        return x
    }

    const sortByNameCaseInsensitive = R.compose(R.toLower, R.prop<string, string>('name'))
    const sortByNameDate = R.prop<string, string>('lastAutomationModifiedDate')

    const flatteed = R.compose<any, any, any, any>(R.map(fn), x => [].concat.apply([], x), R.values)(x)
    const uniqueApps = R.uniqWith(equalApps, flatteed)
    return sortBy === 'Alphabetical'
        ? (R.sortBy(sortByNameCaseInsensitive, uniqueApps as any) as any)
        : (R.sortBy(sortByNameDate, uniqueApps as any) as any)
}
export function MarketPlaceAppsLoadStateHandlerV2(props: any) {
    const workspace = props.match.params.workspacename
    // const [state, , , retry] = useFetchFromApi(() => loadMarketPlaceAutomations(props.match.params.workspacename))

    // const _multiStepCached = useQuery(
    //     'workflow-marketplace::workflows',
    //     () => wrapperGetJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.AllMarketPlaceAutomation)),
    //     {
    //         staleTime: Infinity,
    //         refetchOnMount: false
    //     }
    // )

    const _marketplaceApps = useQuery(
        'workflow-marketplace::apps',
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.FilteredMarketplaceCategory)),
        {
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const LoadingError = {
        error: true,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    }

    if (_marketplaceApps.isLoading) {
        return <Loader.PageLoader show={true} />
    }

    if (_marketplaceApps.isError) {
        return (
            <ErrorBoundary
                error={LoadingError}
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={() => {}} info={info} btnName="Retry" />
                }}
            ></ErrorBoundary>
        )
    }

    if (_marketplaceApps.isSuccess) {
        const NoMarketPlaceData = {
            show: R.isEmpty(_marketplaceApps.data.data.output),
            noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
            onClick: () => {},
            btnName: 'Move One'
        }
        return (
            <ErrorBoundary
                noDataFound={NoMarketPlaceData}
                error={{ error: false, info: null }}
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={() => {}} info={info} btnName="Retry" />
                }}
            >
                <StepSwitcherV2 {...props}>
                    {(
                        step: any,
                        _workspace: string,
                        redirectToMarketplaceDetail: (id: string) => void,
                        redirectToMarketplaceWorkflows: (id: string) => void,
                        redirectToMarketplaceAppSelection: () => void
                    ) => (
                        <AutomationMarketPlaceApps
                            allAutomationList={{}}
                            allCategories={_marketplaceApps.data.data.output}
                            step={step}
                            workspaceName={workspace}
                            redirectToMarketplaceDetail={redirectToMarketplaceDetail}
                            redirectToMarketplaceWorkflows={redirectToMarketplaceWorkflows}
                            props={props}
                        />
                    )}
                </StepSwitcherV2>
            </ErrorBoundary>
        )
    }

    return null
}

export function AutomationMarketPlaceApps(props: any) {
    // const initializeSelectApp = (item: MarketPlaceApplication) => ({ ...item, isSelected: false } as SelectApplication)

    const DEFAULT_CATEGORIES_TO_SHOW = 15

    // const rawData = props.allAutomationList as Record<string, MarketPlaceAutomationRaw[]>
    // const uniqueApps = uniqueMarketPlaceApps(rawData)

    const catogoryToAppsData = categoryAppsDataTransform(props.allCategories)

    const uniqCategoryApps = uniqCategoryAppsTransform(
        R.flatten<SelectApplication>(catogoryToAppsData.map(x => x.value))
    ) as SelectApplication[]
    const [searchWorkflows, setSearchWorkflows] = useState<Search>({ initialSearchFocus: false, searchText: '' })

    const [category] = useState(catogoryToAppsData)
    const [sortBy, changeSort] = useState<SortBy>('Alphabetical')
    const [search, setSearch] = useState<Search>({ initialSearchFocus: false, searchText: '' })
    const [apps, setApps] = useState<SelectApplication[]>(uniqCategoryApps)
    const [showMoreCategoriesLinkVisiblity, setShowMoreCategoriesLinkVisiblity] = useState<'show' | 'hide'>('show')
    const [headerClass, setHeaderClass] = useState('marketplace_search_sticky') // Scroll sticky added

    const [state, setState] = useState<{
        selectedCategory: { value: SelectApplication[]; name: string };
        selectedApp: { value: string; name: string };
        showWorflows: 'show' | 'hide';
        worklowAppId : string
      }>({
        selectedCategory: { name: 'All', value: uniqCategoryApps },
        selectedApp: { name: 'All', value: 'all' },
        showWorflows: 'hide',
        worklowAppId : ""
      });
    // const [searchApp, setSearchApp] = useState<Search>({ initialSearchFocus: false, searchText: '' })
    // const itemsSelectionLimit = 5
    // const workspaceName = props.workspaceName
    // const automationList = uniqueMarketPlaceAutomations(rawData)
    // const sortByAppName: any = R.compose(R.toLower, R.prop<string, string>('appName'))
    // const [action, setAction] = useState<ActionTypes>(None)
    // const [filteredAutomationList, setAutomationList] = useState<MarketPlaceAutomation[][]>([])
    // const [step, setStep] = props.step as [Steps, React.Dispatch<React.SetStateAction<Steps>>]
    // const [renderAcknowledgeModal] = acknowledgeDeploy([action, setAction])

    // const [appForSideMenu, setAppforSideMenu] = useState<SelectApplication[]>(R.map(initializeSelectApp, uniqueApps))
    // const [currentCategories, setCurrentCategories] = useState<string[]>([])

    // const [otherWorkFlows, setOtherWorkFlows] = useState<MarketPlaceAutomation[][]>([])
    // const selectedItems = R.uniq(
    //     apps.filter(app => app.isSelected).concat(appForSideMenu.filter(app => app.isSelected))
    // )

    const listenScrollEvent = (e: any) => {
        if (window.scrollY < 40) {
            return setHeaderClass('marketplace_search_sticky')
        } else if (window.scrollY > 40) {
            return setHeaderClass('marketplace_search_sticky marketplace_search_sticky_fixed')
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)

        return () => window.removeEventListener('scroll', listenScrollEvent)
    }, [])

    // Scroll sticky added

    const filteredItems =
        search.searchText == ''
            ? apps
            : apps.filter(app => app.name.toLowerCase().includes(search.searchText.toLowerCase()))

    // const headerInfo =
    //     step === 'AutomationListing'
    //         ? `You have selected ${selectedItems.length} applications`
    //         : `you can select maximum ${itemsSelectionLimit} applications`

    useEffect(() => {
        const sortedApps: SelectApplication[] =
            sortBy === 'Alphabetical' ? R.sortBy(sortByNameCaseInsensitive, apps) : R.sortBy(sortByNameDate, apps)
        setApps(sortedApps)
    }, [sortBy])

    // useEffect(() => {
    //     if (step === 'AutomationListing') {
    //         if (selectedItems.length <= 0) {
    //             setStep('AppSelection')
    //         } else {
    //             const allPossibleAppCombinations = getAllSubsets(selectedItems.map(app => app.id))
    //             const filteredAutomations = allPossibleAppCombinations
    //                 .filter(a => a.length == 1 || a.length == selectedItems.length)
    //                 .map(appItem => {
    //                     if (appItem.length == 0) return { matchingAutomationForSingleAppSet: [], otherWorkFlows: [] }
    //                     const filterOnlyMatchingAppSet = R.converge(allAreSame, [
    //                         R.compose((x: any) => x.length, R.intersection(appItem)),
    //                         R.always(appItem.length),
    //                         R.length
    //                     ])

    //                     const filterAutomations = R.compose<
    //                         MarketPlaceAutomation,
    //                         Array<Application & Action>,
    //                         string[],
    //                         string[],
    //                         boolean
    //                     >(filterOnlyMatchingAppSet, R.uniq, R.map(R.prop('id')), R.prop('apps'))

    //                     const matchingAutomationForSingleAppSet: MarketPlaceAutomation[] = R.filter(filterAutomations)(
    //                         automationList
    //                     )

    //                     return {
    //                         matchingAutomationForSingleAppSet: matchingAutomationForSingleAppSet,
    //                         otherWorkFlows: []
    //                     }
    //                 }) as any
    //             setOtherWorkFlows(filteredAutomations.map((auto: any) => auto.otherWorkFlows))

    //             const sortByLength = R.sort(function(a: any, b: any) {
    //                 return b.length - a.length
    //             })

    //             const sortLengthByApps = R.sort(function(a: any, b: any) {
    //                 return b.apps.length - a.apps.length
    //             })

    //             const newAutomationList = R.compose(
    //                 R.map(sortLengthByApps)
    //                 // sortByLength
    //             )(
    //                 filteredAutomations
    //                     .map(
    //                         (auto: any) =>
    //                             auto.matchingAutomationForSingleAppSet.length > 0 &&
    //                             auto.matchingAutomationForSingleAppSet
    //                     )
    //                     .filter(Boolean)
    //             )

    //             setAutomationList(newAutomationList)
    //         }
    //     } else {
    //         // setAppforSideMenu(R.map(initializeSelectApp, uniqueApps))
    //     }
    // }, [step, apps])

    // const changeAppSelection = (action: 'Toggle' | 'Deselect') => (item: any) => {
    //     const typedItem = item as SelectApplication
    //     const nextState = produce(
    //         currentCategories.length > 0 ? R.uniq(apps.concat(appForSideMenu)) : appForSideMenu,
    //         apps => {
    //             apps.forEach(a => {
    //                 if (a.name == typedItem.name) {
    //                     const toSet = action == 'Toggle' ? !typedItem.isSelected : false
    //                     a.isSelected = toSet
    //                 }
    //             })
    //         }
    //     )

    //     const appsForSideMenu = produce(appForSideMenu, apps => {
    //         apps.forEach(a => {
    //             if (a.name == typedItem.name) {
    //                 const toSet = action == 'Toggle' ? !typedItem.isSelected : false
    //                 a.isSelected = toSet
    //             }
    //         })
    //     })
    //     setApps(nextState)
    //     setAppforSideMenu(appsForSideMenu)
    //     setSearch({ ...search, searchText: '' })
    //     setStep('AutomationListing')
    // }

    const selectApp = (action: 'Toggle' | 'Deselect') => (item: any) => {
        setState({...state, showWorflows:"show", worklowAppId:item.id})
        // const typedItem = item as SelectApplication
        // props.redirectToMarketplaceWorkflows(typedItem.id)
        // const nextState = produce(
        //     currentCategories.length > 0 ? R.uniq(apps.concat(appForSideMenu)) : appForSideMenu,
        //     apps => {
        //         apps.forEach(a => {
        //             if (a.name == typedItem.name) {
        //                 const toSet = action == 'Toggle' ? !typedItem.isSelected : false
        //                 a.isSelected = toSet
        //             }
        //         })
        //     }
        // )

        // const appsForSideMenu = produce(appForSideMenu, apps => {
        //     apps.forEach(a => {
        //         if (a.name == typedItem.name) {
        //             const toSet = action == 'Toggle' ? !typedItem.isSelected : false
        //             a.isSelected = toSet
        //         }
        //     })
        // })
        // setApps(nextState)
        // setAppforSideMenu(appsForSideMenu)
        // setSearch({ ...search, searchText: '' })
        // setStep('AutomationListing')
    }

    const sort = (s: SortBy) => changeSort(s)

    const options: OptionsP<SortBy> = [
        {
            text: 'Show by alphabetical',
            value: 'Alphabetical'
        },
        {
            text: 'Show by latest',
            value: 'Time'
        }
    ]
    // const resetInitialState = () => {
    //     const appsWithSelectionCleared = produce(apps, apps => {
    //         apps.forEach(a => {
    //             a.isSelected = false
    //         })
    //     })

    //     const sideMenuAppsWithSelectionCleared = produce(appForSideMenu, apps => {
    //         apps.forEach(a => {
    //             a.isSelected = false
    //         })
    //     })

    //     setAppforSideMenu(sideMenuAppsWithSelectionCleared)

    //     setApps(appsWithSelectionCleared)

    //     setSearch({ initialSearchFocus: false, searchText: '' })
    // }
    // const addToWorkSpace = async (automation: MarketPlaceAutomation) => {
    //     await postJson(automationAPIURLWithWorkspace(workspaceName, MarketPlaceApi.AddToWorkSpace))({
    //         automationId: automation.id
    //     })
    //     setAction(DeployAcknowledgement)
    // }
    // const [checked, setChecked] = React.useState(true)

    const Wrapper = (props: { children: React.ReactNode }) => {
        return (
            <div className="sidepanel_menu_right">
                <div className="sidepanel_menu_right_head">
                    <h4>Marketplace</h4>
                </div>
                <div className="sidepanel_menu_right_menu">
                    <div className="sidepanel_menu_right_menu_step">
                        <div className="automation_left_panel">
                            <div className="automation_left_panel_tree">
                                <div className="left_popup_automation_tree">{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <React.Fragment>
            {/* {renderAcknowledgeModal()} */}
            <div className="market_place_wrapper marketplace_page_side_menu">
                <div className="marketplace_container">
                <DropdownContainer>
                    <div className="tab_container_left">
                        <Dropdwon
                            header={'Select Category'}
                            content={'All Sessions'}
                            list={[
                                { label: 'All', value: uniqCategoryApps },
                                ...category.map((s) => ({ label: s.name, value: s.value }))
                            ]}
                            forAutomationMarketplace
                            value={`${state.selectedCategory.name} (${state.selectedCategory.value.length})`}
                            onClick={(data: any) => {
                                setState({...state,selectedCategory:{name:data.label,value:data.value}, worklowAppId:"",showWorflows:"hide", selectedApp:{name:"All",value:"all"}})
                                if (!R.equals(apps, data.value)) {
                                    setApps(data.value)
                                }
                            }}
                        />
                        {/* {state.selectedCategory.name != "All" && */}
                            {console.log(
                                state.selectedApp,"state.selectedApp"
                            )}
                            <Dropdwon
                                header={'Select Application'}
                                content={'All Sessions'}
                                list={[
                                    { label: 'All', value: "all" },
                                    ...apps.map((s) => ({ label: s.name, value: s.id }))
                                ]}
                                forAutomationMarketplace
                                value={state.selectedApp.name}
                                onClick={(data: any) => {
                                    setState({...state,selectedApp : { value:data.value,name:data.label }, worklowAppId:"",showWorflows:"hide"})
                                }}
                            />
                        {/* } */}
                        {state.showWorflows == "show" && <div className="search_marketpalce">
                            <input
                                className="search_click"
                                type="search"
                                placeholder={'Search workflow'}
                                value={searchWorkflows.searchText}
                                // onChange={e => setSearch({ ...search, searchText: e.target.value })}
                                onChange={e =>
                                    setSearchWorkflows({
                                        ...searchWorkflows,
                                        searchText: e.target.value
                                    })
                                }
                            />
                            <span className="search_icon">
                                {/* <i className="fa fa-search livesearch"></i> */}
                                <SearchIcon />
                            </span>
                        </div>}
                    </div>
                </DropdownContainer>
                    <>
                        <div className="marketplace_wrapper">
                            {state.showWorflows == "hide" ?
                                <MarketPlaceItems
                                    items={filteredItems}
                                    selectionLimit={5}
                                    toggleItemSelection={selectApp('Toggle')}
                                    selectedApp={state.selectedApp}
                                />
                                :
                                <MarketPlaceWorkflowsLoadStateHandlerV2 {...{...props.props,location:{...props.props.location,search:`?apps=${state.worklowAppId}`}, searchWorkflows, setSearchWorkflows, goBack:()=>setState({...state, showWorflows:"hide"})}}/>
                            }
                        </div>
                    </>
                </div>
            </div>
        </React.Fragment>
    )
}

// WORKFLOW LISTING

export function MarketPlaceWorkflowsLoadStateHandlerV2(props: any) {
    console.log(props,"props")
    const workspace = props.match.params.workspacename
    // const [state, , , retry] = useFetchFromApi(() => loadMarketPlaceAutomations(props.match.params.workspacename))

    const searchParams = props.location.search

    const [paramState, setParamState] = useState(props.location.search)

    const memoizedAppValue = React.useMemo(() => {
        const allParams = new URLSearchParams(paramState)
        const appParams = allParams.get('apps')

        if (appParams !== null) {
            return R.uniq(appParams.split(','))
        } else {
            return []
        }
    }, [paramState])

    const updateAppsQueryParam = (appId: string) => {
        const allParams = new URLSearchParams(searchParams)
        const appParams = allParams.get('apps')
        console.log('appParams :: 1', appParams)
        let newAppList = []
        if (appParams === null) {
            newAppList = [appId]
        } else {
            const currentParams = appParams.split(',').filter(Boolean)

            if (currentParams.includes(appId)) {
                newAppList = currentParams.filter(x => x !== appId)
            } else {
                newAppList = currentParams.concat(appId)
            }
        }

        setParamState(`?apps=${newAppList.join(',')}`)
        const location = {
            pathname: props.location.pathname,
            search: `?apps=${newAppList.join(',')}`,
            key: Math.random(),
            state: { test: Math.random() }
        }
        props.history.push(location)
    }

    const _marketPlaceAutomations = useQuery<any, any>(
        ['workflow-marketplace::workflows', ...memoizedAppValue],
        () =>
            wrapperPostJson(
                automationAPIURLWithWorkspace(workspace, `${MarketPlaceApi.FilteredMarketplaceAutomation}`),
                {
                    appList: memoizedAppValue
                }
            ),
        {
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const _marketplaceApps = useQuery(
        'workflow-marketplace::apps',
        () => wrapperGetJson(automationAPIURLWithWorkspace(workspace, MarketPlaceApi.FilteredMarketplaceCategory)),
        {
            staleTime: Infinity,
            refetchOnMount: false
        }
    )

    const LoadingError = {
        error: true,
        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
    }

    if (_marketplaceApps.isLoading) {
        return <Loader.PageLoader show={true} />
    }

    if (_marketPlaceAutomations.isError || _marketplaceApps.isError) {
        return (
            <ErrorBoundary
                error={LoadingError}
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={() => {}} info={info} btnName="Retry" />
                }}
            ></ErrorBoundary>
        )
    }

    if (_marketplaceApps.isSuccess) {
        const NoMarketPlaceData = {
            show: R.isEmpty(_marketplaceApps.data.data.output),
            noDatainfo: `<p>Zero, zip, zilch, nada. Create an Automation now!</p>`,
            onClick: () => {},
            btnName: 'Move One'
        }
        return (
            <ErrorBoundary
                noDataFound={NoMarketPlaceData}
                error={{ error: false, info: null }}
                render={(err: any, info: any) => {
                    return err && <Error.PageLoadingError onClick={() => {}} info={info} btnName="Retry" />
                }}
            >
                <StepSwitcherV2 {...props}>
                    {(
                        step: any,
                        _workspace: string,
                        redirectToMarketplaceDetail: (id: string) => void,
                        redirectToMarketplaceWorkflows: (id: string) => void,
                        redirectToMarketplaceAppSelection: () => void
                    ) => (
                        <AutomationMarketPlaceWorkflows
                            allAutomationList={
                                _marketPlaceAutomations.isSuccess ? _marketPlaceAutomations.data.data.output : {}
                            }
                            allCategories={_marketplaceApps.data.data.output}
                            selectedApps={memoizedAppValue}
                            workspaceName={workspace}
                            redirectToMarketplaceDetail={redirectToMarketplaceDetail}
                            redirectToMarketplaceWorkflows={redirectToMarketplaceWorkflows}
                            redirectToMarketplaceAppSelection={redirectToMarketplaceAppSelection}
                            updateAppsQueryParam={updateAppsQueryParam}
                            workflowLoadingStatus={_marketPlaceAutomations}
                            {...props}
                        />
                    )}
                </StepSwitcherV2>
            </ErrorBoundary>
        )
    }

    return null
}

type AutomationMarketPlaceWorkflowsPropsT = {
    allAutomationList: Record<string, MarketPlaceAutomationRaw[]>
    allCategories: any
    selectedApps: string[]
    workspaceName: string
    redirectToMarketplaceDetail: (id: string) => void
    redirectToMarketplaceWorkflows: (id: string) => void
    redirectToMarketplaceAppSelection: () => void
    updateAppsQueryParam: (id: string) => void
    workflowLoadingStatus:
        | QueryObserverIdleResult<any, any>
        | QueryObserverLoadingResult<any, any>
        | QueryObserverSuccessResult<any, any>
    goBack:()=>void
}

export function AutomationMarketPlaceWorkflows(props: AutomationMarketPlaceWorkflowsPropsT) {
    const initializeSelectApp = (item: MarketPlaceApplication) => {
        if (props.selectedApps.includes(item.id)) {
            return { ...item, isSelected: true } as SelectApplication
        }
        return { ...item, isSelected: false } as SelectApplication
    }

    const { searchWorkflows, setSearchWorkflows, goBack } = props as any

    const rawData = props.allAutomationList as Record<string, MarketPlaceAutomationRaw[]>
    // const uniqueApps = props.workflowLoadingStatus.isSuccess ? uniqueMarketPlaceApps(rawData) : []
    const automationList = props.workflowLoadingStatus.isSuccess ? uniqueMarketPlaceAutomations(rawData) : []

    const catogoryToAppsData = categoryAppsDataTransform(props.allCategories)

    const uniqCategoryApps = uniqCategoryAppsTransform(
        R.flatten<SelectApplication>(catogoryToAppsData.map(x => x.value))
    ) as SelectApplication[]

    console.log('rawData', rawData)

    const [apps, setApps] = useState<SelectApplication[]>(R.map(initializeSelectApp, uniqCategoryApps))

    const [appForSideMenu, setAppforSideMenu] = useState<SelectApplication[]>(
        R.map(initializeSelectApp, uniqCategoryApps)
    )

    const [filteredAutomationList, setAutomationList] = useState<MarketPlaceAutomation[][]>([])
    const [showMoreAppsLinkVisiblity, setShowMoreAppsLinkVisiblity] = useState<'show' | 'hide'>('show')
    const [otherWorkFlows, setOtherWorkFlows] = useState<MarketPlaceAutomation[][]>([])
    const selectedItems = R.uniq(
        apps.filter(app => app.isSelected).concat(appForSideMenu.filter(app => app.isSelected))
    )

    // const workspaceName = props.workspaceName
    // const [action, setAction] = useState<ActionTypes>(None)
    // const [renderAcknowledgeModal] = acknowledgeDeploy([action, setAction])
    // const [currentCategories, setCurrentCategories] = useState<string[]>([])
    // const [category, setCategory] = useState(filterFun(R.map(initializeSelectApp, uniqueApps), props.allCategories))
    // const sortByAppName: any = R.compose(R.toLower, R.prop<string, string>('appName'))
    // const [sortBy, changeSort] = useState<SortBy>('Alphabetical')
    // const [searchApp, setSearchApp] = useState<Search>({ initialSearchFocus: false, searchText: '' })
    // const [step, setStep] = props.step as [Steps, React.Dispatch<React.SetStateAction<Steps>>]
    // Scroll sticky added

    const [header, setHeader] = useState('marketplace_search_sticky')

    const listenScrollEvent = (e: any) => {
        if (window.scrollY < 40) {
            return setHeader('marketplace_search_sticky')
        } else if (window.scrollY > 40) {
            return setHeader('marketplace_search_sticky marketplace_search_sticky_fixed')
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)

        return () => window.removeEventListener('scroll', listenScrollEvent)
    }, [])

    // useEffect(() => {
    //     const uniqueApps = props.workflowLoadingStatus.isSuccess ? uniqueMarketPlaceApps(rawData) : []
    //     const automationList = props.workflowLoadingStatus.isSuccess ? uniqueMarketPlaceAutomations(rawData) : []

    //     // setApps(R.map(initializeSelectApp, uniqueApps))
    //     // setAppforSideMenu(R.map(initializeSelectApp, uniqueApps))
    // }, [props.allAutomationList])

    // Scroll sticky added

    // const filteredItems =
    //     searchWorkflows.searchText == ''
    //         ? apps
    //         : apps.filter(app => app.name.toLowerCase().includes(searchWorkflows.searchText.toLowerCase()))

    useEffect(() => {
        console.log('seletecd Items ::: ', selectedItems, automationList)
        const allPossibleAppCombinations = getAllSubsets(selectedItems.map(app => app.id))
        const filteredAutomations = allPossibleAppCombinations
            .filter(a => a.length == 1 || a.length == selectedItems.length)
            .map(appItem => {
                if (appItem.length == 0) return { matchingAutomationForSingleAppSet: [], otherWorkFlows: [] }
                const filterOnlyMatchingAppSet = R.converge(allAreSame, [
                    R.compose((x: any) => x.length, R.intersection(appItem)),
                    R.always(appItem.length),
                    R.length
                ])

                const filterAutomations = R.compose<
                    MarketPlaceAutomation,
                    Array<Application & Action>,
                    string[],
                    string[],
                    boolean
                >(filterOnlyMatchingAppSet, R.uniq, R.map(R.prop('id')), R.prop('apps'))

                const matchingAutomationForSingleAppSet: MarketPlaceAutomation[] = R.filter(filterAutomations)(
                    automationList
                )

                return {
                    matchingAutomationForSingleAppSet: matchingAutomationForSingleAppSet,
                    otherWorkFlows: []
                }
            }) as any
        setOtherWorkFlows(filteredAutomations.map((auto: any) => auto.otherWorkFlows))

        // const sortByLength = R.sort(function(a: any, b: any) {
        //     return b.length - a.length
        // })

        const sortLengthByApps = R.sort(function(a: any, b: any) {
            return b.apps.length - a.apps.length
        })

        const newAutomationList = R.compose(
            R.map(sortLengthByApps)
            // sortByLength
        )(
            filteredAutomations
                .map(
                    (auto: any) =>
                        auto.matchingAutomationForSingleAppSet.length > 0 && auto.matchingAutomationForSingleAppSet
                )
                .filter(Boolean)
        )

        console.log('newAutomationList', newAutomationList)
        setAutomationList(newAutomationList)
    }, [apps, props.allAutomationList])

    const changeAppSelection = (action: 'Toggle' | 'Deselect') => (item: any) => {
        const typedItem = item as SelectApplication

        const nextState = produce(R.uniq(apps.concat(appForSideMenu)), apps => {
            apps.forEach(a => {
                if (a.name == typedItem.name) {
                    const toSet = action == 'Toggle' ? !typedItem.isSelected : false
                    a.isSelected = toSet
                }
            })
        })

        const appsForSideMenu = produce(appForSideMenu, apps => {
            apps.forEach(a => {
                if (a.name == typedItem.name) {
                    const toSet = action == 'Toggle' ? !typedItem.isSelected : false
                    a.isSelected = toSet
                }
            })
        })

        const selectedAppCount = appsForSideMenu.filter(x => x.isSelected).length
        if (selectedAppCount > 0) {
            setApps(nextState)
            setAppforSideMenu(appsForSideMenu)
            setSearchWorkflows({ ...searchWorkflows, searchText: '' })

            props.updateAppsQueryParam(typedItem.id)
        } else {
            props.redirectToMarketplaceAppSelection()
        }
    }

    const resetInitialState = () => {
        const appsWithSelectionCleared = produce(apps, apps => {
            apps.forEach(a => {
                a.isSelected = false
            })
        })

        const sideMenuAppsWithSelectionCleared = produce(appForSideMenu, apps => {
            apps.forEach(a => {
                a.isSelected = false
            })
        })

        setAppforSideMenu(sideMenuAppsWithSelectionCleared)

        setApps(appsWithSelectionCleared)

        setSearchWorkflows({ initialSearchFocus: false, searchText: '' })
    }

    const Wrapper = (props: { children: React.ReactNode }) => {
        return (
            <div className="sidepanel_menu_right">
                <div className="sidepanel_menu_right_head">
                    <h4>Marketplace</h4>
                </div>
                <div className="sidepanel_menu_right_menu">
                    <div className="sidepanel_menu_right_menu_step">
                        <div className="automation_left_panel">
                            <div className="automation_left_panel_tree">
                                <div className="left_popup_automation_tree">{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {/* {renderAcknowledgeModal()} */}
            <div className="market_place_wrapper marketplace_page_side_menu">
                <div className="marketplace_container">
                    <MarketPlaceHeaderTyped
                        title={'Workflows'}
                        content={''}
                        backButton={{
                            show: 'yes',
                            action: () => {
                                goBack()
                                resetInitialState()
                            }
                        }}
                    ></MarketPlaceHeaderTyped>

                    {props.workflowLoadingStatus.isSuccess ? (
                        <>
                            <div className={'marketplace_wrapper'}>
                                {/* <SelectedItems.Wrapper>
                                    <div className={header}>
                                        <div className="search_marketpalce">
                                            <input
                                                className="search_click"
                                                type="search"
                                                placeholder={'Search Workflows'}
                                                value={searchWorkflows.searchText}
                                                onChange={e =>
                                                    setSearchWorkflows({
                                                        ...searchWorkflows,
                                                        searchText: e.target.value
                                                    })
                                                }
                                            />
                                            <span className="search_icon">
                                                <i className="fa fa-search livesearch"></i>
                                            </span>
                                        </div>
                                    </div>
                                </SelectedItems.Wrapper> */}

                                <CardsApplications
                                    otherWorkFlows={otherWorkFlows}
                                    listItems={filteredAutomationList}
                                    selectedApps={selectedItems}
                                    addToWorkSpace={() => {}}
                                    searchText={searchWorkflows.searchText}
                                    redirectToMarketplaceDetail={props.redirectToMarketplaceDetail}
                                />
                            </div>
                        </>
                    ) : (
                        <Loader.PageLoader show={true} />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export function SidebarSearchV2({
    appForSideMenu,
    showMoreAppsLinkVisiblity,
    changeAppSelection,
    setShowMore
}: {
    appForSideMenu: SelectApplication[]
    showMoreAppsLinkVisiblity: 'show' | 'hide'
    setShowMore: React.Dispatch<React.SetStateAction<'show' | 'hide'>>
    changeAppSelection: Function
}) {
    const DEFAULT_COUNT_OF_APPS_LISTED = 15
    const [searchApp, setSearchApp] = useState<Search>({ initialSearchFocus: false, searchText: '' })

    return (
        <div className="checkbox_wrapper">
            <h5>Selected Applications</h5>
            <div className="search_marketpalce">
                <input
                    key="search-apps"
                    className="search_click"
                    type="search"
                    placeholder={'Search apps'}
                    value={searchApp.searchText}
                    onChange={e => setSearchApp({ ...searchApp, searchText: e.target.value })}
                />
                <span className="search_icon">
                    {/* <i className="fa fa-search livesearch"></i> */}
                    <SearchIcon />
                </span>
            </div>
            <ul>
                {appForSideMenu
                    .slice(
                        0,
                        showMoreAppsLinkVisiblity === 'hide' ? appForSideMenu.length : DEFAULT_COUNT_OF_APPS_LISTED
                    )
                    .filter(
                        a =>
                            searchApp.searchText.length == 0 ||
                            (searchApp.searchText.length > 0 &&
                                a.name.toLowerCase().indexOf(searchApp.searchText.toLowerCase()) != -1)
                    )
                    .map((app, i) => (
                        <li key={i}>
                            {' '}
                            <label className="container-checkbox">
                                {app.name}
                                <input
                                    type="checkbox"
                                    checked={app.isSelected}
                                    onClick={() => changeAppSelection('Toggle')(app)}
                                />
                                <span className="checkmark" />
                            </label>
                        </li>
                    ))}
                {showMoreAppsLinkVisiblity === 'show' && appForSideMenu.length > DEFAULT_COUNT_OF_APPS_LISTED ? (
                    <li className="show_more_link" onClick={() => setShowMore('hide')}>
                        <span>Show More</span>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
