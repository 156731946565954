import classNames from 'classnames'
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import Joyride, { CallBackProps, Placement } from 'react-joyride'

import { defaultProfileData } from '@/Profile/types'
import {
    DialogIcon,
    DownArrowCircle,
    EntityIcon,
    FlowIcon,
    HelpIcon,
    IntegrationIcon,
    IntentIcon,
    LogoutIcon,
    ManageUserIcon,
    MarketPlaceIcon,
    NotificationIcon,
    ProfileIcon,
    RightArrowWithOutCircle,
    SubPanelIcons,
    SupportIcon,
    TrainingModuleIcon,
    UserIconAnalyticsMenu,
    WorkspacesIcon
} from '@/common/Icons/Icons'
import logo_img from '@/common/images/logo.png'
import { defaultState } from '@/common/types/WorkspacesT'

import { SideBarT } from './SideBarTypes'

const Parser = require('html-react-parser')
const R = require('ramda')

type Panels = 'Profile' | 'Notification' | 'None'

export type Linktext = 'Dialog' | 'Automation' | 'Omni-channel' | 'Training' | 'ProfilePaths' | 'Marketplace'

export type LinkRoute =
    | 'dialog/home'
    | 'automation/view/home'
    | 'integration/chat'
    | 'train-assistant/home'
    | 'marketplace/automation/select-application'

export type SubLinkRoute =
    | 'dialog/homepage'
    | 'dialog/it-assistant'
    | 'dialog/home'
    | 'dialog/analytics'
    | 'dialog/logs'
    | 'dialog/intent'
    | 'dialog/entity'
    | 'dialog/settings'
    | 'automation/view/home'
    | 'automation/view/active'
    | 'automation/view/inactive'
    | 'automation/view/draft'
    | 'automation/view/trigger'
    | 'automation/view/no-trigger'
    | 'automation/onprem-agent'
    | 'automation/analytics'
    | 'automation/logs'
    | 'integration/chat'
    | 'integration/application'
    | 'train-assistant/home'
    | 'marketplace/automation/select-application'
    | 'marketplace/automation/list-automations'
    | 'marketplace/myuploads'

export type SubLinktext =
    | 'Homepage'
    | 'Home'
    | 'Intent'
    | 'Entity'
    | 'Analytics'
    | 'Logs'
    | 'Settings'
    | 'Draft'
    | 'Active'
    | 'Inactive'
    | 'Trigger'
    | 'No Trigger'
    | 'Chat Channel'
    | 'Application'
    | 'Automations'
    | 'MyUploads'
    | 'Dialogs'
    | 'IT_Chatbot'
    | 'On-Prem Connect'
    | 'Workflow Builder'
    | 'Training'

export type subMenu = {
    displayText: SubLinktext
    to: SubLinkRoute
    icon: React.FunctionComponent
    content: { title: string; body: string }
}

export type NavLinkT = {
    displayText: Linktext
    to: LinkRoute
    icon: React.FunctionComponent
    submenu: subMenu[]
    content: { title: string; body: string }
}

export const dialog: NavLinkT = {
    displayText: 'Dialog',
    icon: DialogIcon,
    to: 'dialog/home',
    content: {
        title: 'Chatbot Builder',
        body: 'Create, manage, and analyse your chatbot. Explore workativ’s no-code chatbot building canvas. '
    },
    submenu: [
        {
            displayText: 'Homepage',
            icon: SubPanelIcons.HomePage,
            to: 'dialog/homepage',
            content: {
                title: 'Homepage',
                body:
                    'Create and manage multiple chatbots for various needs. Use several no-code tools that are easy to use. E.g., create IT Chatbot to help automate your employee IT support requests like password reset, account unlock etc. '
            }
        },
        {
            displayText: 'IT_Chatbot',
            icon: DialogIcon,
            to: 'dialog/it-assistant',
            content: {
                title: 'IT_Chatbot',
                body:
                    'Your new chatbot. Use menu options to view, create dialogs, assess analytics and logs, as well as change settings.'
            }
        },
        {
            displayText: 'Dialogs',
            icon: SubPanelIcons.BotIcon,
            to: 'dialog/home',
            content: {
                title: 'Dialogs',
                body:
                    'Create or view dialogs on the Dialogs page. Default dialogs (Welcome & Fallback) are auto-created when you create your first dialog. <br/> Welcome dialog translates to the greeting that appears to a user when they initiate a session with the chatbot. <br/> Fallback dialog translates to the message that a user would see when the chatbot does not recognize text input during a session.'
            }
        },
        {
            displayText: 'Intent',
            icon: IntentIcon,
            to: 'dialog/intent',
            content: {
                title: 'Intent',
                body:
                    'Intents are goals, utterances, queries, or purposes that the user types into the bot. Create Intents and map them with dialogs to help the bot recognize user intention.'
            }
        },
        {
            displayText: 'Entity',
            icon: EntityIcon,
            to: 'dialog/entity',
            content: {
                title: 'Entity',
                body:
                    'Create entities or use existing system entities for the bot to extract details from user inputs from a session such as a date, time, currency, phone number, email, etc.'
            }
        },
        {
            displayText: 'Analytics',
            icon: SubPanelIcons.Analytics,
            to: 'dialog/analytics',
            content: {
                title: 'Analytics',
                body:
                    'Use Analytics to explore the various reasons for which users engage the bot. It includes the number of sessions in a day and the interaction messages between the users and the bot.'
            }
        },
        {
            displayText: 'Logs',
            icon: SubPanelIcons.logs,
            to: 'dialog/logs',
            content: {
                title: 'Logs',
                body:
                    'View logs to see a detailed conversation between the bot and the end-user, and how your bot helped users to resolve their issues.'
            }
        },
        {
            displayText: 'Settings',
            icon: SubPanelIcons.settings,
            to: 'dialog/settings',
            content: {
                title: 'Settings',
                body:
                    'Configure bot using settings: for example, you can configure onboarding or off-boarding from chat channels or for feedback messages when users exit from a session.'
            }
        }
    ]
}

const flow: NavLinkT = {
    displayText: 'Automation',
    icon: FlowIcon,
    to: 'automation/view/home',
    content: {
        title: 'Workflow Builder',
        body:
            'Create workflows without writing a single line of code. Use pre-configured actions by selecting the respective application from the drop-down menu.'
    },
    submenu: [
        {
            displayText: 'Homepage',
            icon: SubPanelIcons.HomePage,
            to: 'automation/view/home',
            content: {
                title: 'Homepage',
                body:
                    'Create new or manage workflows from home page. <br/> You can filter your automations easily by:  <br/>Draft  <br/>Active  <br/>Inactive  <br/>'
            }
        },
        {
            displayText: 'Draft',
            icon: SubPanelIcons.Draft,
            to: 'automation/view/draft',
            content: {
                title: 'Draft',
                body:
                    'Create or manage workflows under ‘Drafts’ and make changes until you are ready to test and deploy.'
            }
        },
        // {
        //     displayText: 'Trigger',
        //     icon: SubPanelIcons.Trigger,
        //     to: 'automation/view/trigger',
        //     content: {
        //         title: 'Trigger',
        //         body: 'All workflows with ‘Trigger’ will be displayed here. Choose the time interval for the trigger.'
        //     }
        // },
        // {
        //     displayText: 'No Trigger',
        //     icon: SubPanelIcons.NoTrigger,
        //     to: 'automation/view/no-trigger',
        //     content: {
        //         title: 'No Trigger',
        //         body:
        //             'All workflows with ‘No Trigger’ (called from dialogs) will be displayed here. Make the automation active or inactive to view them inside the dialogs.'
        //     }
        // },
        {
            displayText: 'Active',
            icon: SubPanelIcons.Active,
            to: 'automation/view/active',
            content: {
                title: 'Active',
                body: 'Manage all your active workflows here.'
            }
        },
        {
            displayText: 'Inactive',
            icon: SubPanelIcons.Inactive,
            to: 'automation/view/inactive',
            content: {
                title: 'InActive',
                body: 'Manage all your in-active workflows here. '
            }
        },
        {
            displayText: 'Analytics',
            icon: SubPanelIcons.Analytics,
            to: 'automation/analytics',
            content: {
                title: 'Analytics',
                body: 'View Analytics to see how your workflows are performing.'
            }
        },
        {
            displayText: 'Logs',
            icon: SubPanelIcons.logs,
            to: 'automation/logs',
            content: {
                title: 'Logs',
                body: 'Analyse your workflows using Logs '
            }
        },
        {
            displayText: 'On-Prem Connect',
            icon: SubPanelIcons.OnPremICon,
            to: 'automation/onprem-agent',
            content: {
                title: 'On-Prem Connect',
                body: 'Establish a secure connectivity between your workativ and your on-premise apps.'
            }
        }
    ]
}

const integration: NavLinkT = {
    displayText: 'Omni-channel',
    icon: IntegrationIcon,
    to: 'integration/chat',
    content: {
        title: 'Omni-channel',
        body: 'Deploy your chatbot to your workplace chat channel such as Slack, Teams and/or Widget.'
    },
    submenu: [
        {
            displayText: 'Chat Channel',
            icon: SubPanelIcons.All,
            to: 'integration/chat',
            content: {
                title: 'Chat Channel',
                body: 'Deploy your chatbot to your workplace chat channel such as Slack, Teams and/or Widget.'
            }
        }
    ]
}

const trainingModule: NavLinkT = {
    displayText: 'Training',
    icon: TrainingModuleIcon,
    to: 'train-assistant/home',
    content: {
        title: 'Training',
        body:
            'Train your chatbot here. You can view all the user and chatbot interactions in one place and how they map with intents and entities.'
    },
    submenu: [
        {
            displayText: 'Training',
            icon: SubPanelIcons.All,
            to: 'train-assistant/home',
            content: {
                title: 'Training',
                body:
                    'Make the best use of ‘Training’ to see user responses, queries to the bot, and  chatbo interactions.'
            }
        }
    ]
}

const marketPlace: NavLinkT = {
    displayText: 'Marketplace',
    icon: MarketPlaceIcon,
    to: 'marketplace/automation/select-application',
    content: {
        title: 'Marketplace',
        body: 'Make use of ‘Marketplace’ to download pre-build data for your chatbot.'
    },
    submenu: [
        {
            displayText: 'Automations',
            icon: FlowIcon,
            to: 'marketplace/automation/select-application',
            content: {
                title: 'Workflow Automations',
                body:
                    'Pre-built workflows can automate your process from the chatbot.<br/><br/>1.Select application<br/>2.View pre-built workflows<br/>3.Pick a workflow and see how it works <br/>4.Download to your workspace '
            }
        }
    ]
}

const BottomTabs = [
    {
        content: {
            title: 'Support',
            body: 'Create support tickets for defects, feedback, queries, or to request a new feature.'
        },
        styles: {
            spotlight: {
                borderRadius: 50,
                boxShadow: '0px 0px 10px #fff',
                border: '3px solid #fff'
            }
        },
        spotlightPadding: -10,
        placement: 'right' as Placement,
        target: `#support_tab`,
        disableBeacon: true
    },
    {
        content: {
            title: 'Help',
            body:
                'View documentation and videos on how to create dialogs, workflows, and deploying the chatbot to chat channels.'
        },
        styles: {
            spotlight: {
                borderRadius: 50,
                boxShadow: '0px 0px 10px #fff',
                border: '3px solid #fff'
            }
        },
        spotlightPadding: -10,
        placement: 'right' as Placement,
        target: `#help_tab`,
        disableBeacon: true
    },
    {
        content: {
            title: 'Notifications',
            body: 'Be informed about changes in your workspace. You will receive notifications for all admin actions.'
        },
        styles: {
            spotlight: {
                borderRadius: 50,
                boxShadow: '0px 0px 10px #fff',
                border: '3px solid #fff'
            }
        },
        spotlightPadding: -10,
        placement: 'right' as Placement,
        target: `#notification_tab`,
        disableBeacon: true
    },
    {
        content: {
            title: 'Notification panel',
            body:
                'You can see the changes done to your workspace on which module what action and who did the changes with timestamp.'
        },
        styles: {
            spotlight: {
                boxShadow: '0px 0px 2px #fff',
                border: '3px solid #fff'
            }
        },
        spotlightPadding: 8,
        placement: 'right' as Placement,
        target: `#notif_list`,
        disableBeacon: true
    },
    {
        content: {
            title: 'User Details',
            body: 'View user information, invite your colleague to work on the workspace, change password, etc.'
        },
        styles: {
            spotlight: {
                borderRadius: 50,
                boxShadow: '0px 0px 10px #fff',
                border: '3px solid #fff',
                marginTop: 0
            }
        },
        spotlightPadding: -10,
        placement: 'right' as Placement,
        target: '#profile_icon',
        disableBeacon: true
    },
    {
        content: {
            title: 'User Info',
            body: 'Logged in user information will be displayed here as an Email ID.'
        },
        styles: {
            spotlight: {
                borderRadius: 8,
                boxShadow: '0px 0px 10px #fff',
                border: '1px solid #fff',
                width: '220px !important',
                height: '40px !important'
            }
        },
        spotlightPadding: -2,
        placement: 'right' as Placement,
        target: '#user_email_id',
        disableBeacon: true
    },
    {
        content: {
            title: 'Profile',
            body: 'Add phone number, change the country, or reset password under profile.'
        },
        styles: {
            spotlight: {
                borderRadius: 8,
                boxShadow: '0px 0px 10px #fff',
                border: '1px solid #fff',
                width: '220px !important',
                height: '40px !important'
            }
        },
        spotlightPadding: -2,
        placement: 'right' as Placement,
        target: '#profile_page',
        disableBeacon: true
    },
    {
        content: {
            title: 'Manage Users',
            body: 'Invite new users or grant/revoke access to users.'
        },
        styles: {
            spotlight: {
                borderRadius: 8,
                boxShadow: '0px 0px 10px #fff',
                border: '1px solid #fff',
                width: '220px !important',
                height: '40px !important'
            }
        },
        spotlightPadding: -2,
        placement: 'right' as Placement,
        target: '#manage_users',
        disableBeacon: true
    },
    {
        content: {
            title: 'Workspaces',
            body:
                'All workspace(s) that your email id is associated with will be displayed here. Select a workspace to access.'
        },
        styles: {
            spotlight: {
                borderRadius: 8,
                boxShadow: '0px 0px 10px #fff',
                border: '1px solid #fff',
                width: '220px !important',
                height: '40px !important'
            }
        },
        spotlightPadding: -2,
        placement: 'right' as Placement,
        target: '#workspaces',
        disableBeacon: true
    },
    {
        content: {
            title: 'Logout',
            body: 'Logout from Workativ chatbot here.'
        },
        styles: {
            spotlight: {
                borderRadius: 8,
                boxShadow: '0px 0px 10px #fff',
                border: '1px solid #fff',
                width: '220px !important',
                height: '40px !important'
            }
        },
        spotlightPadding: -2,
        placement: 'right' as Placement,
        target: '#log_out',
        disableBeacon: true
    }
]

export const links: NavLinkT[] = [dialog, flow, integration, trainingModule, marketPlace]

export function TourSideBars(props: any) {
    const [selectedLinks, setSelectedLink] = useState<SideBarT>({
        selectedLink: 'Dialog',
        selectedSublink: 'home',
        showSubpanel: true,
        previousLink: false
    })

    const [profileData, setProfileData] = useState(defaultProfileData)

    const [panel, setPanel] = useState<Panels>('None')

    const [state, setState] = useState(defaultState)

    let profileReference: any = useRef()
    let notificationRef: any = useRef()

    let emailLiRef: any = useRef()

    useEffect(() => {
        if (panel !== 'None') {
            setSelectedLink({ ...selectedLinks, showSubpanel: false })
        }
    }, [panel])

    const activeClass = (linkText: Linktext) => {
        const classList = linkText !== selectedLinks.selectedLink ? ['item'] : ['item', 'selected']
        return classNames(classList)
    }

    const handleChange = (linkText: Linktext) => {
        setSelectedLink({
            ...selectedLinks,
            selectedLink: linkText,
            showSubpanel: true,
            previousLink: selectedLinks.selectedLink as any
        })

        //to close workspace or notification popup
        if (panel !== 'None') {
            setPanel('None')
        }
    }

    function createTab(link: NavLinkT, i: number) {
        return (
            <div
                key={i}
                className={activeClass(link.displayText)}
                id={`side_panel_tab_${link.to.toLowerCase().replace(/\//g, '_')}`}
                onClick={() => {
                    if (link.displayText === selectedLinks.selectedLink && selectedLinks.showSubpanel) return
                    handleChange(link.displayText)
                }}
            >
                <div className="img">
                    <link.icon />
                </div>
                <span className="side_menu_hvr">{link.displayText}</span>
            </div>
        )
    }

    function bottomBar() {
        return (
            <div className="item-group bottom_bar_sidemenu">
                <div
                    className={`item help_tab ${selectedLinks.selectedLink == 'Support' ? 'selected' : ''}`}
                    id="support_tab"
                >
                    <div className="img">
                        <SupportIcon />
                    </div>
                    <span className="side_menu_hvr">Support</span>
                </div>

                <a>
                    <div
                        className={`item help_tab ${selectedLinks.selectedLink == 'Help' ? 'selected' : ''}`}
                        id="help_tab"
                    >
                        <div className="img">
                            <HelpIcon />
                        </div>
                        <span className="side_menu_hvr">Help</span>
                    </div>
                </a>
                <div
                    className={`item ${selectedLinks.selectedLink == 'Notification' ? 'selected' : ''}`}
                    ref={notificationRef}
                    id="notification_tab"
                >
                    <div className="img">
                        <NotificationIcon />
                        {selectedLinks.selectedLink != 'Notification' && (
                            <div className="count_notification">
                                <span className="count_not_number">{0}</span>
                            </div>
                        )}
                    </div>
                    <span className="side_menu_hvr">Notifications</span>
                </div>
                <div
                    className={selectedLinks.selectedLink === 'ProfilePaths' ? 'item profile_selected' : 'item'}
                    ref={profileReference}
                    id="profile_icon"
                >
                    <div className="img ">
                        <div className="img_width">
                            {!profileData.image ? (
                                <span>
                                    <UserIconAnalyticsMenu />
                                </span>
                            ) : (
                                <img src={profileData.image} alt="user" />
                            )}
                        </div>
                    </div>
                    <span className="side_menu_hvr">Profile</span>
                </div>
            </div>
        )
    }

    const showSelected = (subLink: subMenu) => {
        return selectedLinks.selectedSublink === subLink.to
    }

    const filterArrary = (links: NavLinkT[], selectedLink: string) => {
        const currentRoute = links.filter(x => {
            return x.displayText === selectedLinks.selectedLink
        })

        return currentRoute
    }

    const filteredSubpanelArray = filterArrary(links, selectedLinks.selectedLink)[0]

    const [start, setStartTutorial] = useState(true)

    const [currentStep, setCurrentStep] = useState<any>({ action: 'update' })

    useEffect(() => {
        setStartTutorial(true)
    }, [])

    useEffect(() => {
        const e = currentStep as CallBackProps

        const selectedSublink = currentStep.step && currentStep.step.target.replace(/#sub_panel_tab_/g, '')

        if (e.action == 'update' && selectedSublink && selectedSublink.includes('dialog')) {
            setSelectedLink({
                ...selectedLinks,
                selectedLink: 'Dialog',
                selectedSublink: !selectedSublink.includes('side_panel_tab') ? selectedSublink.replace(/_/g, '/') : ''
            })
        }

        if (e.action == 'update' && selectedSublink && selectedSublink.includes('automation')) {
            setSelectedLink({
                ...selectedLinks,
                selectedLink: 'Automation',
                selectedSublink: !selectedSublink.includes('side_panel_tab') ? selectedSublink.replace(/_/g, '/') : ''
            })
        }

        if (e.action == 'update' && selectedSublink && selectedSublink.includes('integration')) {
            setSelectedLink({
                ...selectedLinks,
                selectedLink: 'Integration',
                selectedSublink: !selectedSublink.includes('side_panel_tab') ? selectedSublink.replace(/_/g, '/') : ''
            })
        }

        if (e.action == 'update' && selectedSublink && selectedSublink.includes('train-assistant')) {
            setSelectedLink({
                ...selectedLinks,
                selectedLink: 'Train Assistant',
                selectedSublink: !selectedSublink.includes('side_panel_tab') ? selectedSublink.replace(/_/g, '/') : ''
            })
        }

        if (e.action == 'update' && selectedSublink && selectedSublink.includes('marketplace')) {
            setSelectedLink({
                ...selectedLinks,
                selectedLink: 'Marketplace',
                selectedSublink: !selectedSublink.includes('side_panel_tab') ? selectedSublink.replace(/_/g, '/') : ''
            })
        }

        // setTimeout(() => {
        //     if (e.action == 'update' && selectedSublink && selectedSublink.includes('dialog')) {
        //         setSelectedLink({
        //             ...selectedLinks,
        //             selectedLink: 'Dialog',
        //             selectedSublink: !selectedSublink.includes('side_panel_tab')
        //                 ? selectedSublink.replace(/_/g, '/')
        //                 : ''
        //         })
        //     }

        //     if (e.action == 'update' && selectedSublink && selectedSublink.includes('automation')) {
        //         setSelectedLink({
        //             ...selectedLinks,
        //             selectedLink: 'Automation',
        //             selectedSublink: !selectedSublink.includes('side_panel_tab')
        //                 ? selectedSublink.replace(/_/g, '/')
        //                 : ''
        //         })
        //     }

        //     if (e.action == 'update' && selectedSublink && selectedSublink.includes('integration')) {
        //         setSelectedLink({
        //             ...selectedLinks,
        //             selectedLink: 'Integration',
        //             selectedSublink: !selectedSublink.includes('side_panel_tab')
        //                 ? selectedSublink.replace(/_/g, '/')
        //                 : ''
        //         })
        //     }

        //     if (e.action == 'update' && selectedSublink && selectedSublink.includes('train-assistant')) {
        //         setSelectedLink({
        //             ...selectedLinks,
        //             selectedLink: 'Train Assistant',
        //             selectedSublink: !selectedSublink.includes('side_panel_tab')
        //                 ? selectedSublink.replace(/_/g, '/')
        //                 : ''
        //         })
        //     }
        // }, 100)

        if (e.action == 'update' && e.index == 23) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'Support' })
        }

        if (e.action == 'update' && e.index == 24) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'Help' })
        }

        if (e.action == 'update' && e.index == 25) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'Notification' })
        }

        if (e.action == 'update' && e.index == 26) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'Notification' })
        }

        if (e.action == 'update' && e.index > 26) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'ProfilePaths' })
        }

        if (e.action == 'reset' && e.index == 0) {
            setSelectedLink({ ...selectedLinks, selectedLink: 'Dialog', showSubpanel: false })
            props.closeTour()
        }
    }, [currentStep.action])

    const steps = R.flatten(
        links.map((link: NavLinkT, i: number) => {
            return [
                {
                    content: (
                        <React.Fragment>
                            <div className="tour_headers">
                                <h2>{link.content.title}</h2>
                                <h5>{Parser(link.content.body)}</h5>
                            </div>
                        </React.Fragment>
                    ),

                    styles: {
                        spotlight: {
                            borderRadius: 50,
                            boxShadow: '0px 0px 10px #fff',
                            border: '3px solid #fff'
                        }
                    },
                    spotlightPadding: -10,
                    placement: 'right' as Placement,
                    target: `#side_panel_tab_${link.to.toLowerCase().replace(/\//g, '_')}`,
                    disableBeacon: true
                },
                link.submenu.map((subLink: subMenu, index: number) => {
                    return {
                        content: (
                            <React.Fragment>
                                <div className="tour_headers">
                                    <h2>{subLink.content.title}</h2>
                                    <h5>{Parser(subLink.content.body)}</h5>
                                </div>
                            </React.Fragment>
                        ),
                        styles: {
                            spotlight: {
                                borderRadius: 8,
                                boxShadow: '0px 0px 10px #fff',
                                border: '1px solid #fff',
                                width: '220px !important',
                                height: '40px !important'
                            }
                        },
                        spotlightPadding: -8,
                        placement: 'right' as Placement,
                        target: `#sub_panel_tab_${subLink.to.toLowerCase().replace(/\//g, '_')}`,
                        disableBeacon: true
                    }
                })
            ]
        })
    ).concat(
        BottomTabs.map((tabs, i) => {
            const { content, ...rest } = tabs
            return {
                content: (
                    <React.Fragment>
                        <div className="tour_headers">
                            <h2>{content.title}</h2>
                            <h5>{Parser(content.body)}</h5>
                        </div>
                    </React.Fragment>
                ),
                ...rest
            }
        })
    )

    return (
        <React.Fragment>
            <Joyride
                continuous
                scrollToFirstStep
                showProgress
                showSkipButton
                run={start}
                steps={steps}
                disableOverlayClose
                spotlightClicks={false}
                hideBackButton
                locale={{ last: 'Done', skip: 'End tour' }}
                styles={{
                    buttonClose: {
                        display: 'none'
                    },
                    buttonNext: {
                        backgroundColor: '#2468F6',
                        color: '#fff',
                        borderRadius: '18px',
                        width: 'auto',
                        fontFamily: 'Gordita-Regular',
                        padding: '7px 10px 6px',
                        height: '24px',
                        fontSize: 12
                    },
                    buttonSkip: {
                        backgroundColor: '#fff',
                        color: '#000',
                        borderRadius: '18px',
                        width: 'auto',
                        fontFamily: 'Gordita-Regular',
                        padding: '7px 10px 6px',
                        height: '24px',
                        fontSize: 12,
                        border: '1px solid #b9b9b9'
                    },
                    options: {
                        zIndex: 10000
                    }
                }}
                callback={(e: CallBackProps) => {
                    var url = new URL(window.location as any)
                    var showtour = url.searchParams.get('showtour')
                    if (e.action === 'reset' && showtour) {
                        props.history.replace(props.location.pathname)
                    }
                    setCurrentStep(e)
                }}
                disableCloseOnEsc
            />
            <div className="flex_menu">
                <div className="sidepanel_menu_left">
                    <div className="sidepanel_menu_left_logo">
                        <img src={logo_img} />
                    </div>
                    <nav>
                        <div className="sidepeanel_settings">
                            <div className="item-group">{links.map((link, index) => createTab(link, index))}</div>
                            {bottomBar()}
                        </div>
                    </nav>
                </div>
                <ProfilePopup selectedLinks={selectedLinks} />
                {selectedLinks.selectedLink == 'Notification' && <NotificationPopup selectedLinks={selectedLinks} />}
            </div>
            {links &&
                selectedLinks.selectedLink !== 'Support' &&
                selectedLinks.selectedLink !== 'Help' &&
                selectedLinks.selectedLink !== 'Notification' &&
                selectedLinks.selectedLink !== 'ProfilePaths' && (
                    <div className="flex_side_menu">
                        <div className="sidepanel_menu_right">
                            <div className="sidepanel_menu_right_head">
                                <h4>
                                    {selectedLinks.selectedLink.length === 0
                                        ? ''
                                        : selectedLinks.selectedLink === 'Dialog'
                                        ? 'Chatbot Builder'
                                        : selectedLinks.selectedLink === 'Automation'
                                        ? 'Workflow Builder'
                                        : selectedLinks.selectedLink}
                                </h4>
                                <span
                                    className="sub_panel_toggle_btn"
                                    onClick={() => {
                                        setSelectedLink({
                                            ...selectedLinks,
                                            showSubpanel: !selectedLinks.showSubpanel
                                        })
                                    }}
                                >
                                    <RightArrowWithOutCircle />
                                </span>
                            </div>
                            <div className="sidepanel_menu_right_menu">
                                {filteredSubpanelArray &&
                                    filteredSubpanelArray.submenu.map((subLink: subMenu, index: number) => {
                                        return (
                                            <div
                                                id={`sub_panel_tab_${subLink.to.toLowerCase().replace(/\//g, '_')}`}
                                                className={
                                                    showSelected(subLink)
                                                        ? 'side_menu_li side_menu_li_active'
                                                        : 'side_menu_li'
                                                }
                                                key={index}
                                                onClick={() => {
                                                    setSelectedLink({
                                                        ...selectedLinks,
                                                        selectedSublink: subLink.displayText
                                                    })
                                                }}
                                            >
                                                <subLink.icon />
                                                <p>{subLink.displayText}</p>
                                                {subLink.to == 'dialog/it-assistant' && (
                                                    <div className="svg_workspace_width">
                                                        <DownArrowCircle />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                )}
        </React.Fragment>
    )
}

const ProfilePopup = (props: { selectedLinks: any }) => {
    const { selectedLinks } = props
    return (
        <section
            className="notifications_panel"
            style={{ visibility: selectedLinks.selectedLink == 'ProfilePaths' ? 'visible' : 'hidden' }}
        >
            <div>
                <div className="profile_page_side" style={{ left: 75 }}>
                    <div className="profile_page_menu">
                        <ul>
                            <li className="pro_user_name" id="user_email_id">
                                <span>john.smith@workativ.com</span>
                            </li>
                            <li className={'pro_user_svg pro_user_name_select'} id="profile_page">
                                <ProfileIcon />
                                <span>Profile</span>
                            </li>
                            <li className={'pro_user_svg pro_user_name_select'} id="manage_users">
                                <ManageUserIcon />
                                <span>Manage Users</span>
                            </li>
                            <React.Fragment>
                                <li className={'pro_user_svg pro_user_name_select'} id="workspaces">
                                    <WorkspacesIcon />
                                    <span>Workspaces</span>
                                    <div className="svg_workspace_width">
                                        <DownArrowCircle />
                                    </div>
                                </li>
                                <div className="workspace_nav">
                                    <ul />
                                </div>
                            </React.Fragment>
                            <li className="pro_user_svg" id="log_out">
                                <LogoutIcon />
                                <span>Logout</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

const NotificationPopup = (props: { selectedLinks: any }) => {
    const { selectedLinks } = props
    return (
        <section className="notifications_panel">
            <div className="_head_notifications_panel">
                <div className="notifications_panel_body">
                    <h4 className="notification_border-after">
                        <span className="notification_svg_span">
                            <RightArrowWithOutCircle />
                        </span>
                        Notifications
                    </h4>
                    <div
                        className="notifications_panel_scrol"
                        id="scrollableDiv"
                        style={{ height: '800px', overflow: 'auto' }}
                    >
                        <div>
                            <div className="infinite-scroll-component " style={{ height: 'auto', overflow: 'auto' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <h6 className="h6_notifis_left">New</h6>
                                    <h6 className="h6_notifis_right">Mark all as read</h6>
                                </div>
                                <div className="older_notifications ">
                                    <div>
                                        <h6 className="h6_notifis_left">Older</h6>
                                        <div className="notifications_panel_user " id="notif_list">
                                            <div className="notifications_panel_body_right">
                                                <h5>Automation</h5>
                                                <span> 2 minutes ago </span>
                                                <p title="">
                                                    New Automation <b>Unlock Account</b> created by <b>John Smith</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Dialog</h5>
                                                <span> 15 minutes ago </span>
                                                <p title="">
                                                    Dialog <b>Greeting</b> updated by <b>John Smith</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Dialog</h5>
                                                <span> 15 minutes ago </span>
                                                <p title="">
                                                    Dialog <b>Password Reset</b> updated by <b>Robert S</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Dialog</h5>
                                                <span> 14 hours ago </span>
                                                <p title="">
                                                    Dialog <b>Create Ticket</b> updated by <b>Robert S</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Intent</h5>
                                                <span> 16 hours ago </span>
                                                <p title="">
                                                    Intent <b>Support Ticket</b> updated by <b>Rodriguez</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Intent</h5>
                                                <span> 1 days ago </span>
                                                <p title="">
                                                    New Intent <b>Unlock</b> created by <b>Maria Martinez</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Automation</h5>
                                                <span> 1 days ago </span>
                                                <p title="">
                                                    Automation <b>Password Reset</b> updated by <b>Maria Martinez</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Entity</h5>
                                                <span> 1 days ago </span>
                                                <p title="">
                                                    Entity <b>Email</b> updated by <b>Maria Martinez</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="notifications_panel_user notifications_panel_user_read">
                                            <div className="notifications_panel_body_right">
                                                <h5>Entity</h5>
                                                <span> 1 days ago </span>
                                                <p title="">
                                                    Entity <b>Phone number</b> Created by <b>Maria Martinez</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
