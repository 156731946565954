import { TabLeft } from '@/Flows/analytics/logs'
import styled from 'styled-components'

export const TabHeader = styled.h4`
    font-family: 'Gordita-Medium';
    font-size: 16px;
    width: auto;
    float: left;
    color: #555555;
    margin-bottom: 6px;
    line-height: 26px;
    position: relative;
    cursor: pointer;

    ${({ active }) =>
        active &&
        `
    transition: 0.3255s ease;
    color: #466AF3;
    
    :before{
        content: "";
        position: absolute;
        border-bottom: 3px solid #466AF3;
        width: 100%;
        height: 3px;
        bottom: -7px;
    }
  
    color: #466AF3;
    `}
`
export const TabRow = styled.div`
    width: 100%;
    float: left;
    position:relative;
    display: flex;
    gap: 30px

    :after{
        content: "";
        position: absolute;
        border-bottom: 1px solid #e0e0e0;
        width: 67%;
        height: 1px;
        top: 33px;
        left: 4px;
        z-index: 0;

        @media (min-width: 1920px) {
            width: 31%;
        }
        @media (min-width: 2160px) {
            width: 27.5%;
        }
        @media (min-width: 2560px) {
            width: 23%;
        }
        @media (min-width: 3480px) {
            width: 16%;
        }
        @media (max-width: 1440px) {
            width: 80%;
        }
        @media (max-width: 1280px) {
            width: 91%;
        }
    }
`
export const TabContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 70px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 20px;
    margin-top: 4px;
    align-items: baseline;

    ${TabRow}{
        width: 50%;
    }

    .settings_menu{
        :after{
            content: "";
            position: absolute;
            border-bottom: 1px solid #e0e0e0;
            width: 60%;
            height: 1px;
            top: 33px;
            left: 4px;
            z-index: 0;
            
            @media (min-width: 1920px) {
                width: 52%;
            }
            @media (min-width: 2160px) {
                width: 46%;
            }
            @media (min-width: 2560px) {
                width: 38%;
            }

            @media (min-width: 3460px) {
                width: 33.5%;
            }
            @media (max-width: 1440px) {
                width: 71%;
            }
            @media (max-width: 1300px) {
                width: 81%;
            }
        }
    }

    .dashboard_submenu{
        :after{
            width: 20%;

            @media (max-width: 1440px) {
                width: 23.5%;
            }
            @media (max-width: 1300px) {
                width: 26%;
            }
        }
    }
`
