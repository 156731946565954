import React from 'react'

export const usePrevious = <T,>(value: T) => {
    const ref = React.useRef<T>(value)
    React.useEffect(() => {
        ref.current = value
    })
    return ref.current
}
export const usePreviousWithCondition = <T,>(value: T, condition: (value: T) => any) => {
    const ref = React.useRef<T>(value)
    React.useEffect(() => {
        if (condition(value)) {
            ref.current = value
        }
    })
    return ref.current
}
