/*eslint-disable*/
import React from 'react'

import dashbord_img from '@/common/images/dashboard_user.png'

export function DashboardHeader(props: any) {
    const user: any = {
        name: 'Arunachalamoorthy sethuraja',
        email: 'arunachalamoorthy.s@skitter.in',
        loginData: 'Last login was 23 hours ago.'
    }

    const workspaceSummary = [
        { header: 'Dialog Created', count: 6 },
        { header: 'Automations Created', count: 5 },
        { header: 'Integrations', count: 10 }
    ]

    return (
        <div className="dashboard_header">
            <DashboardHeaderUser user={user} />
            <div className="dashboard_header_right">
                <DashboardUserCompany />
                {workspaceSummary.map((d, i) => (
                    <DashboardUserCreated count={d.count} header={d.header} />
                ))}
            </div>
        </div>
    )
}

function DashboardHeaderUser({ children, user }: any) {
    // const C = Content
    return (
        <div className="dashboard_header_left">
            <UserImage />
            <Wrapper>
                <Name name={user.name} />
                <Email email={user.email} />
                <LoginData loginData={user.loginData} />
            </Wrapper>
        </div>
    )
}

const UserImage = () => (
    <div className="dashboard_header_img">
        <div className="dashboard_header_img_left">
            <img src={dashbord_img} alt="dashboard_user" title="dashboard_user" />
        </div>
    </div>
)

const Wrapper = ({ children }: any) => <div className="dashboard_header_content">{children}</div>

const Name = (props: any) => <h4>{props.name}</h4>

const Email = ({ email }: any) => <h5>{email}</h5>

const LoginData = ({ loginData }: any) => <p>{loginData}</p>

function DashboardUserCompany(props: any) {
    return (
        <div className="user_company_details">
            <h4>Workspace Name</h4>
            <h6>Company</h6>
        </div>
    )
}

function DashboardUserCreated(props: any) {
    return (
        <div className="user_company_details">
            <h4>{props.header}</h4>
            <div className="user_create_numbers">
                <span className="user_create_numbers_span">{props.count}</span>
            </div>
        </div>
    )
}
