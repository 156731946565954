import knowledgeHomeImg from '@/common/images/knowledge_AI.svg'
import { Button } from "@/common/styled/Dialog.BotDetails.Dumb";
import React from "react";

export function KnowledgeAIUpgrade(props:any){
    return(
        <section className="knowledge_home_container upgrade">
            <div className="knowledge_Home_Box">
                <div>
                    <img src={knowledgeHomeImg} alt="Knowledge Home img" />
                </div>
                <div>
                    <h6>Please upgrade to Enterprise plan to use this feature!</h6>
                </div>
                <div style={{marginTop: 10}}>
                    <Button className="btn btn-primary" style={{fontSize: 14, padding: '5px 16px'}}
                    onClick={() => {
                        props.history.push(
                          `/workspace/${props.match.params.workspacename}/billing/plans?from=billing`
                        );
                        }}>
                        Upgrade Now
                    </Button>

                </div>
            </div>
            
        </section>
    )
}