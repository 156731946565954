import React, { useEffect, useState } from 'react';
import {
  CancelButton,
  EmptyDiv,
  Header,
  HeaderLeft,
  InputContainer,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';
import { Input, InputContent } from '@/common/styled/Workflow.Analytics.Dumb';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { ButtonContainer } from '@/common/styled/Workflow.Dumb';
import styled from 'styled-components/macro';
import {
  AddIcon,
  CloseColorIcon,
  CloseIcon,
  DownArrowCircle,
  EditIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  InfoIcon,
  MinusIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import {
  confluenceCredData,
  ConfResponse,
  CredConfluenceT,
  EmailValidT,
  initialState,
  IsValidURL,
  KnowledgeBase,
  Resp,
  SharepointPopupT,
  StateT,
} from './MockFiles';
import { isValidEmail } from 'libs/feature/manage-users/src/v2/ManageUsers';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { getJson, knowledgeApiUrl, postJson } from '@/common/utils/api-utils';
import * as R from 'ramda';
import { useQueryClient } from 'react-query';
import { Loader } from '@/common/components/Loader';
import {
  usegetCategories,
  useSaveConnection,
  useSaveConnectionVivantio,
  useSaveCred,
  useUpdateConnection,
  useUpdateCred,
} from './KnowledgeApis';
import Tooltip from 'react-tooltip-lite';
import Downshift from 'downshift';
import Axios from 'axios';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;
`;
const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;
export default function FreshServicePopup({
  pageState,
  PageStateUpdate,
  selectedID,
  workspace,
  Connection,
  exCatArray,
}: SharepointPopupT) {
  const [state, setState] = useState<StateT>(initialState);
  const [categoryDropdown, setCategoryDropdown] = useState<boolean>(false);
  const [folderDropdown, setFolderDropdown] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  console.log(status, state, Connection, 'stateViviantio');

  const [published, setPublished] = useState<boolean>(false);
  const [showAdditionalProp, setShowAdditionalProp] = useState<boolean>(false);

  const [popUpState, setPopupState] = useState<{
    selectedConnection: ConfResponse[];
    clonedConnection: ConfResponse[];
  }>({
    selectedConnection: [],
    clonedConnection: [],
  });
  console.log(showAdditionalProp, 'showAdditionalProp');
  useEffect(() => {
    if (selectedID !== '' && pageState === 'edit') {
      getJson(
        knowledgeApiUrl(workspace, `knowledge-base/custom/${selectedID}`)
      ).then((data) => {
        if (data.type === 'success') {
          const output = [data.output];
          if (data.output.input.folders?.length > 0) {
            setShowAdditionalProp(true);
          }
          setPopupState({
            ...popUpState,
            selectedConnection: JSON.parse(JSON.stringify(output)),
            clonedConnection: JSON.parse(JSON.stringify(output)),
          });
          setPublished(output[0].input.published);
        }
      });
    }
  }, [selectedID, pageState]);

  useEffect(() => {
    updateCategories();
  }, [popUpState.clonedConnection]);

  const removefolderInfo = () => {
    // getCategories();
    setState({
      ...state,
      folder: [],
      category: {
        name: '',
        value: '',
      },
    });
  };
  const updatefolderInfo = () => {
    // updateCategories();
    handleConnectionEdit(
      {
        name: '',
        value: '',
      },
      popUpState.selectedConnection[0].id,
      'category',
      'input'
    );
    handleConnectionEdit(
      [],
      popUpState.selectedConnection[0].id,
      'folders',
      'input'
    );
  };
  interface FsTypes {
    name: string;
    value: string;
  }
  const [categories, setCategories] = useState<FsTypes[]>(); //check type
  const [folder, setFolders] = useState<FsTypes[]>(); //check type
  const [loader, setLoader] = useState<boolean>(false);
  console.log(categories, 'categories');
  // const { allCategories } = usegetCategories(workspace, state.connectionID);
  const getCategories = () => {
    setLoader(true);
    postJson(
      knowledgeApiUrl(
        workspace,
        `knowledge-base/${state.connectionID}/custom/filter`
      )
    )({
      id: state.connectionID,
      type: 'FRESHSERVICE',
      input: {
        type: 'FRESHSERVICE',
        action: 'GET_CATEGORIES',
        workspaceId: state.workspaceId,
      },
    })
      .then((res) => {
        setLoader(false);
        console.log(res, 'postresponse');
        setCategories(res.data.output);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const updateCategories = () => {
    setLoader(true);
    const connectionID = popUpState.selectedConnection[0]?.credentialId;
    const workspaceId = popUpState.selectedConnection[0]?.input.workspaceId;
    postJson(
      knowledgeApiUrl(workspace, `knowledge-base/${connectionID}/custom/filter`)
    )({
      id: connectionID,
      type: 'FRESHSERVICE',
      input: {
        type: 'FRESHSERVICE',
        action: 'GET_CATEGORIES',
        workspaceId: workspaceId,
      },
    })
      .then((res) => {
        setLoader(false);
        setCategories(res.data.output);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getFolder = () => {
    setLoader(true);
    postJson(
      knowledgeApiUrl(
        workspace,
        `knowledge-base/${state.connectionID}/custom/filter`
      )
    )({
      id: state.connectionID,
      type: 'FRESHSERVICE',
      input: {
        type: 'FRESHSERVICE',
        action: 'GET_FOLDERS',
        workspaceId: state.workspaceId,
        categoryId: state.category,
      },
    })
      .then((res) => {
        setLoader(false);
        setFolders(res.data.output);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const updateFolder = () => {
    setLoader(true);
    const connectionID = popUpState.selectedConnection[0]?.credentialId;
    const workspaceId = popUpState.selectedConnection[0]?.input.workspaceId;
    const category = popUpState.selectedConnection[0]?.input.category;
    postJson(
      knowledgeApiUrl(workspace, `knowledge-base/${connectionID}/custom/filter`)
    )({
      id: connectionID,
      type: 'FRESHSERVICE',
      input: {
        type: 'FRESHSERVICE',
        action: 'GET_FOLDERS',
        workspaceId: workspaceId,
        categoryId: category,
      },
    })
      .then((res) => {
        setLoader(false);
        setFolders(res.data.output);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const settingCategory = (data: FsTypes) => {
    setState({
      ...state,
      category: data,
      folder: [],
    });
    setCategoryDropdown(false);
    getFolder();
  };
  const settingFolder = (folder: FsTypes) => {
    //check type
    // console.log(teamID,'adfadfasdfas')
    const list = state.folder;
    const checkAvailable = list.filter((li) => li.value === folder.value);
    if (checkAvailable.length > 0) {
      const removedList = list.filter((li) => li.value !== folder.value);
      setState({
        ...state,
        folder: removedList,
      });
    } else {
      list.push(folder);
      setState({
        ...state,
        folder: list,
      });
    }
  };

  const updatingFolder = (folder: FsTypes) => {
    const list = popUpState.selectedConnection[0]?.input.folders
      ? popUpState.selectedConnection[0]?.input.folders
      : [];
    const checkAvailable = list
      ? list.filter((li) => li.value === folder.value)
      : [];
    if (checkAvailable.length > 0) {
      const removedList = list.filter((li) => li.value !== folder.value);
      handleConnectionEdit(
        removedList,
        popUpState.selectedConnection[0]?.id,
        'folders',
        'input'
      );
    } else {
      list.push(folder);
      handleConnectionEdit(
        list,
        popUpState.selectedConnection[0]?.id,
        'folders',
        'input'
      );
    }
  };
  const checkingName: KnowledgeBase[] | [] =
    exCatArray && exCatArray.length > 0
      ? exCatArray.filter(
          (arr) => arr.name.toLowerCase() === state.kBName.toLowerCase()
        )
      : [];

  console.log(popUpState, 'popUpState');
  const handleConnectionEdit = (
    value: any,
    id: string,
    property: string,
    obj?: string
  ) => {
    const index = popUpState.selectedConnection.findIndex((el) => el.id === id);
    const data = [...popUpState.selectedConnection];
    if (obj === 'credential') {
      data[index].credential[property] = value;
    } else if (obj === 'input') {
      data[index].input[property] = value;
    } else {
      data[index][property] = value;
    }
    setPopupState({ ...popUpState, selectedConnection: data });
  };
  const [updateCred, setUpdateCred] = useState<boolean>(false);

  //   const handleRepositoryChange = (
  //     index: number,
  //     event: { target: { value: any } },
  //     id: string,
  //     prop: string
  //   ) => {
  //     const { value } = event.target;
  //     const rep = popUpState.selectedConnection[0].input;
  //     const list = [...rep.spaceKeys];
  //     list[index] = value;
  //     handleConnectionEdit(list, id, prop, 'input');
  //   };
  //   const removeSiteUrl = (index: number, id: string) => {
  //     const rows = [...popUpState.selectedConnection[0].input.spaceKeys];
  //     rows.splice(index, 1);
  //     rows.length === 0
  //       ? handleConnectionEdit([''], id, 'spaceKeys', 'input')
  //       : handleConnectionEdit(rows, id, 'spaceKeys', 'input');
  //   };
  //   const addSiteUrl = (id: string) => {
  //     const rep = popUpState.selectedConnection[0].input;
  //     const list = [...rep.spaceKeys, ''];
  //     handleConnectionEdit(list, id, 'spaceKeys', 'input');
  //   };
  const accountUpdate = () => {
    const selectedaccount =
      popUpState.selectedConnection[0].credential.username;
    const clonedaccount = popUpState.clonedConnection[0]?.credential.username;
    return (
      JSON.stringify(selectedaccount) === JSON.stringify(clonedaccount) &&
      state.updatePassWord === ''
    );
  };

  const addInputUrl = () => {
    setSpaceKey([...spaceKey, '']);
  };

  const removeinputUrl = (index: number) => {
    const rows = [...spaceKey];
    rows.splice(index, 1);
    rows.length === 0 ? setSpaceKey(['']) : setSpaceKey(rows);
  };
  const changeUrl = (index: number, event: { target: { value: any } }) => {
    const { value } = event.target;
    const list = [...spaceKey];
    list[index] = value;
    setSpaceKey(list);
  };
  //saving credential data to get connectionID //create credential

  const { saveCredMutate } = useSaveCred(
    (response: Resp) => {
      setStatus('');
      setState({
        ...state,
        connectionID: response.data.output.id,
        showConnectError: false,
      });
      setClonedCredData(JSON.parse(JSON.stringify(credData)));
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const onCredSave = () => {
    const credBody = {
      connector: 'FRESHSERVICE',
      //   authType: 'basic',
      input: {
        domain: credData.username,
        apikey: credData.password,
      },
    };
    if (!IsValidURL(credData.username)) {
      setStatus('');
      setState({
        ...state,
        showConnectError: true,
      });
    } else {
      setState({
        ...state,
        connectLoader: true,
      });
      saveCredMutate({ workspace, credBody });
    }
  };

  //   const checkingValidUrl = popUpState.selectedConnection[0]?.input.hostUrl
  //     ? IsValidURL(popUpState.selectedConnection[0]?.input.hostUrl)
  //     : true;
  // updating credential data
  const { updateCredMutate } = useUpdateCred(
    () => {
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const onCredUpdateSave = (id: string) => {
    const newData = {
      domain: popUpState.selectedConnection[0].credential.domain,
      apikey: state.updatePassWord,
    };

    const updateCredBody = {
      connector: 'FRESHSERVICE',
      //   authType: 'basic',
      input: {
        domain: newData.domain,
        apikey: newData.apikey,
      },
    };
    const updatedCloned = popUpState.clonedConnection.map((yes) =>
      Object.assign(yes, {
        credential: newData,
      })
    );
    setState({
      ...state,
      connectLoader: true,
    });
    updateCredMutate({ workspace, id, updateCredBody });
    setPopupState({
      ...popUpState,
      clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    });
    // putJson(knowledgeApiUrl(workspace, `knowledge-base/v2/credential/${id}`))()(
    //   updateConfluence
    // )
    //   .then((data) => {
    //     if (data.data.type === 'success') {
    //       setUpdateCred(false);
    //       setState({
    //         ...state,
    //         updatePassWord: '',
    //         connectLoader: false,
    //       });
    //       setPopupState({
    //         ...popUpState,
    //         clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     setStatus('apiConnectError');

    //     // setState({
    //     //   ...state,
    //     //   error: true,
    //     // });
    //   });
  };

  //siteURL management

  const [spaceKey, setSpaceKey] = useState<string[]>(['']);

  const inputURLValid = spaceKey.map((url) => IsValidURL(url));
  const onCancel = () => {
    PageStateUpdate();
    setSpaceKey(['']);
    setStatus('');
    setUpdateCred(false);
    setState(initialState);
    setPopupState({
      selectedConnection: [],
      clonedConnection: [],
    });
    setEmailValidation({
      valid: true,
      message: null,
    });
    setCredData(confluenceCredData);
    setShowAdditionalProp(false);
  };

  //cred
  const [credData, setCredData] = useState<CredConfluenceT>(confluenceCredData);
  const [clonedCredData, setClonedCredData] =
    useState<CredConfluenceT>(confluenceCredData);

  const [emailValidation, setEmailValidation] = useState<EmailValidT>({
    valid: true,
    message: null,
  });
  //   const queryClient = useQueryClient();

  const needUpdate = () => {
    const omittedAccount = R.omit(
      ['credential'],
      popUpState.selectedConnection[0]
    );
    const omittedClonedAccount = R.omit(
      ['credential'],
      popUpState.clonedConnection[0]
    );
    return (
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount)
    );
  };

  //   const checkingDuplicate = (arr: string[]) => {
  //     return arr.length === new Set(arr).size;
  //   };

  const { saveConVivantioMutate } = useSaveConnectionVivantio(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const saveConnection = () => {
    const connectionData = {
      name: state.kBName,
      category: 'FRESHSERVICE',
      credentialDomainRefId: state.connectionID,
      input: {
        type: 'FRESHSERVICE',
        workspaceId: state.workspaceId,
        published: published,
        category: state.category,
        folders: state.folder,
      },
    };
    console.log(connectionData, 'connectionData');
    if (
      checkingName.length > 0
      //   !checkingDuplicate(spaceKey)
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   // error:true,
      //   loader: false,
      //   showError: true,
      // });
    } else {
      setStatus('loader');
      setState({
        ...state,
        disableSave: true,
      });
      saveConVivantioMutate({ workspace, connectionData });

      //   postJson(knowledgeApiUrl(workspace, `knowledge-base/v2`))(connectionData)
      //     .then((data) => {
      //       if (data.data.type === 'success') {
      //         queryClient.invalidateQueries('kbcategory');
      //         setpageState(Mode.View);
      //         setState(initialState);
      //         setSpaceKey(['']);
      //         onCancel();
      //       }
      //     })
      //     .catch((err) => {
      //       setStatus('apiError');
      //     });
      //   //   connectionSave(connectionData);
      //   // //   onCancel()
      //   // setPopupState(({
      //   //     selectedConnection: [],
      //   //     clonedConnection: [],
      //   //   }))
    }
  };

  const checkingUpdateName = (id: string) => {
    const array =
      exCatArray.length > 0 ? exCatArray.filter((a) => a.id !== id) : [];
    return array.length > 0
      ? array.filter(
          (arr) =>
            arr.name.toLowerCase() ===
            popUpState.selectedConnection[0]?.name.toLowerCase()
        )
      : [];
  };

  const { updateConmutate } = useUpdateConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const updateConnection = (id: string) => {
    const data = popUpState.selectedConnection[0];
    // const filteredSiteUrls = data.input.spaceKeys.filter((f) => f !== '');
    const connectionData = {
      name: data.name,
      category: 'FRESHSERVICE',
      credentialDomainRefId: data.credentialId,
      input: {
        type: 'FRESHSERVICE',
        // syncMode: 'FORCED_FULL_CRAWL',
        //   domain: data.input.domain,
        workspaceId: data.input.workspaceId,
        published: data.input.published,
        category: data.input.category,
        folders: data.input.folders,
      },
    };
    if (
      //   IsValidURL(data.input.domain) === false ||
      //   !checkingDuplicate(filteredSiteUrls) ||
      checkingUpdateName(id).length > 0
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   showError: true,
      // });
    } else {
      setStatus('');
      setState({
        ...state,
        disableSave: true,
      });
      console.log(Connection, 'jagfkjgadfa');
      updateConmutate({ workspace, connectionData, id, Connection });

      // putJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${id}`))()(
      //   connectionData
      // )
      //   .then((data) => {
      //     if (data.data.type === 'success') {
      //       queryClient.invalidateQueries('kbcategory');
      //       onCancel();
      //     }
      //   })
      //   .catch((err) => {
      //     setStatus('apiError');
      //     // setState({
      //     //   ...state,
      //     //   loader: false,
      //     //   error: true,
      //     // });
      //   });
    }
  };
  return (
    <>
      {pageState === 'add' && (
        <Wrapper>
          <PopupWrapper>
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  FreshService Configuration
                </HeaderLeft>
                <HeaderRight></HeaderRight>
              </Header>
              <Paragraph>Create a new connection.</Paragraph>
              <PopupScrollHeight>
                <EmptyDiv className="slack_inter_box">
                  <BgWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e: { target: { value: any } }) =>
                          setState({
                            ...state,
                            kBName: e.target.value,
                          })
                        }
                      />
                      <InputContent>Connection name</InputContent>
                    </InputContainer>
                    {/* {console.log(checkingName, state, 'checking')} */}
                    {checkingName.length > 0 && status === 'error' && (
                      <p className="valid_url_alert">
                        Connection Name has to be unique
                      </p>
                      // </div>
                    )}
                  </BgWrapper>
                  <BgWrapper>
                    <div className="opations_label">
                      <Header style={{ fontSize: 14, marginBottom: 10 }}>
                        Connect Account{' '}
                      </Header>
                    </div>
                    <OptionalInput>
                      <InputContainer>
                        <Input
                          type="url"
                          onChange={(e: { target: { value: string } }) => {
                            setCredData({
                              ...credData,
                              username: e.target.value,
                            });
                          }}
                        />
                        <InputContent>Domain</InputContent>
                        {!IsValidURL(credData.username) &&
                          state.showConnectError && (
                            <p className="valid_url_alert">
                              Provide a Valid URL
                            </p>
                          )}
                      </InputContainer>
                    </OptionalInput>
                    <OptionalInput>
                      <InputContainer style={{ marginBottom: 0 }}>
                        <Input
                          type="text"
                          //   type={!state.eyeIcon ? 'password' : 'text'}
                          onChange={(e: { target: { value: any } }) =>
                            setCredData({
                              ...credData,
                              password: e.target.value,
                            })
                          }
                        />
                        <InputContent>Api Key</InputContent>
                      </InputContainer>
                      {/* <CancelButton
                        className="d-flex"
                        onClick={() =>
                          setState({
                            ...state,
                            eyeIcon: !state.eyeIcon,
                          })
                        }
                      >
                        {state.eyeIcon ? <EyeOpenIcon /> : <EyeCloseIcon />}
                      </CancelButton> */}
                    </OptionalInput>
                    <ButtonContainer
                      className="knowledge_ms_btn"
                      style={{ padding: '10px 0' }}
                    >
                      <Button
                        type="button"
                        style={{ marginRight: 20 }}
                        className={
                          state.connectionID === ''
                            ? credData.username !== '' &&
                              credData.password !== ''
                              ? ''
                              : 'editor_btn_disabled'
                            : JSON.stringify(clonedCredData) !==
                                JSON.stringify(credData) &&
                              credData.username !== '' &&
                              credData.password !== ''
                            ? ''
                            : 'editor_btn_disabled'
                        }
                        onClick={() => onCredSave()}
                      >
                        <label>Save</label>
                      </Button>
                      {state.connectLoader && (
                        <span className="connect_account_loading automate_connect_account_loading">
                          <img src={LoadingSvgImage} />
                        </span>
                      )}
                      {status === 'apiConnectError' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      <Button
                        primary
                        type="button"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        <label> Cancel</label>
                      </Button>
                    </ButtonContainer>
                  </BgWrapper>
                  {state.connectionID !== '' && (
                    <BgWrapper>
                      <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                          WorkSpace{' '}
                        </Header>
                      </div>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="number"
                            onChange={(e: { target: { value: any } }) => {
                              removefolderInfo();
                              setState({
                                ...state,
                                workspaceId: parseInt(e.target.value),
                              });
                            }}
                          />
                          <InputContent>
                            Workspace Id
                            <Tooltip
                              className="target customTip _description"
                              zIndex={10000}
                              arrowSize={8}
                              tagName="span"
                              content="Provide Workspace ID. You can find it on your admin page. Provide the number after ws/ from the url. Example:https://xxxxx.freshservice.com/ws/3/admin/home"
                              distance={5}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputContent>
                        </InputContainer>
                      </OptionalInput>
                      <OptionalInput>
                        <InputContainer
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0 5px',
                          }}
                        >
                          <input
                            type="checkbox"
                            // className={`switch_1`}
                            checked={published}
                            onChange={() => {
                              setPublished(!published);
                            }}
                          />
                          <Paragraph
                            style={{
                              lineHeight: 'normal',
                              marginBottom: 0,
                              color: '#000',
                              marginTop: '3px',
                            }}
                          >
                            Published
                          </Paragraph>
                        </InputContainer>
                      </OptionalInput>
                    </BgWrapper>
                  )}
                
                {state.workspaceId > 0 && (
                  <SmallButton
                    primary
                    onClick={() => {
                      // scroollUp();
                      getCategories();
                      setShowAdditionalProp(!showAdditionalProp);
                    }}
                    style={{ marginBottom: '10px', width: '153px' }}
                  >
                    {showAdditionalProp ? (
                      <span>
                        <MinusIcon />
                      </span>
                    ) : (
                      <span>
                        <AddIcon />
                      </span>
                    )}
                    <label>Select subset of the site</label>
                  </SmallButton>
                )}
                {state.connectionID !== '' && showAdditionalProp && (
                  <BgWrapper className="freshservice">
                    <>
                      <div className="slack_buton_box_12">
                        <InputContainer className="dialog_name_alert_content">
                          <div>
                            <Input
                              type="text"
                              name="appId"
                              value={state.category.name}
                              onClick={() => {
                                getCategories();
                                setCategoryDropdown(true);
                              }}
                            />
                            <div className="arrow_up_form">
                              <DownArrowCircle />
                            </div>
                          </div>
                          <InputContent
                            style={{
                              top: 10,
                              fontSize: 10,
                            }}
                          >
                            Select category
                          </InputContent>
                        </InputContainer>
                      
                      {categoryDropdown && (
                        <Downshift
                          isOpen={true}
                          onOuterClick={() => {
                            setCategoryDropdown(false);
                          }}
                        >
                          {() => (
                            <div className="slack_buton_4 integration_dropdown">
                              <ul>
                                {loader ? (
                                  <span className="connect_account_loading">
                                    <img src={LoadingSvgImage} />
                                  </span>
                                ) : categories && categories.length > 0 ? (
                                  categories.map(
                                    (data: FsTypes, index: number) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          settingCategory(data);
                                        }}
                                      >
                                        {data.name}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <li>No Categories available</li>
                                )}
                              </ul>
                            </div>
                          )}
                        </Downshift>
                      )}
                      </div>
                      {state.category.name !== '' && (
                        // <BgWrapper className="freshservice">
                          <>
                            <div className="slack_buton_box_12">
                              <InputContainer className="dialog_name_alert_content">
                                <div>
                                  <Input
                                    type="text"
                                    name="appId"
                                    value={state.folder.map((fol) => fol.name)}
                                    onClick={() => {
                                      getFolder();
                                      setFolderDropdown(true);
                                    }}
                                  />
                                  <div className="arrow_up_form">
                                    <DownArrowCircle />
                                  </div>
                                </div>
                                <InputContent
                                  style={{
                                    top: 10,
                                    fontSize: 10,
                                  }}
                                >
                                  Select folders
                                </InputContent>
                              </InputContainer>
                              {folderDropdown && (
                                <Downshift
                                  isOpen={true}
                                  onOuterClick={() => {
                                    setFolderDropdown(false);
                                  }}
                                >
                                  {() => (
                                    <div className="slack_buton_4 integration_dropdown">
                                      <ul>
                                        {loader ? (
                                          <span className="connect_account_loading">
                                            <img src={LoadingSvgImage} />
                                          </span>
                                        ) : folder && folder.length > 0 ? (
                                          folder.map((data, index) => (
                                            <li
                                              key={index}
                                              onClick={() => {
                                                settingFolder(data);
                                                // setFolderDropdown(false);
                                              }}
                                            >
                                              <div className="dropdown_list">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    checked={state.folder
                                                      ?.map((dat) => dat.value)
                                                      .includes(data.value)}
                                                  />
                                                </label>
                                              </div>
                                              {data.name}
                                            </li>
                                          ))
                                        ) : (
                                          <li>No folders available</li>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </Downshift>
                              )}
                            </div>
                          </>
                        // </BgWrapper>
                      )}
                    </>
                  </BgWrapper>
                )}
                {state.connectionID !== '' && (
                  <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                    <div className="popup_footer_button_container">
                      <Button
                        type="button"
                        className={
                          state.kBName !== '' && state.connectionID !== ''
                            ? // !state.disableSave
                              //   state.domainName !== '' &&
                              // state.tenantId !== '' &&
                              //   spaceKey.length > 0 &&
                              //   spaceKey[0].length > 0 &&
                              //   !state.showError &&
                              //     // !inputURLValid.includes(false)) &&
                              //   !state.disableSave
                              ''
                            : 'editor_btn_disabled'
                        }
                        onClick={() => {
                          saveConnection();
                        }}
                      >
                        <label> Save</label>
                      </Button>
                      {status === 'loader' && (
                        <span className="connect_account_loading automate_connect_account_loading">
                          <img src={LoadingSvgImage} />
                        </span>
                      )}
                      {status === 'error' && (
                        <span
                          className={
                            // checkingDuplicate(spaceKey) &&
                            !inputURLValid.includes(false) &&
                            checkingName.length === 0
                              ? 'connect_account_failed hide'
                              : 'connect_account_failed'
                          }
                        >
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      {status === 'apiError' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              Something went wrong
                            </span>
                          </div>
                        </span>
                      )}
                      {/* {!checkingDuplicate(spaceKey) && status === 'error' && (
                        <span className="connect_account_failed">
                          <div className="error_input_show error_input_show_add teams_page_alert">
                            <WarningAlertIcon />
                            <span className="error_menu_hvr">
                              All SpaceKeys must be unique
                            </span>
                          </div>
                        </span>
                      )} */}
                      <Button
                        primary
                        type="button"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        <label> Cancel</label>
                      </Button>
                    </div>
                  </ButtonContainer>
                )}
                </EmptyDiv>
              </PopupScrollHeight>
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
      {pageState === 'edit' && (
        <Wrapper>
          <PopupWrapper className="edit_btn">
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  FreshService Configuration
                </HeaderLeft>
                <HeaderRight>
                  {/* <VideoLink id={'ADD_AN_ADAPTIVE_CARD'} text={'How to'} /> */}
                </HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Update the connection.</Paragraph>
              {popUpState.selectedConnection.length === 0 ? (
                <PopupScrollHeight>
                  <div className="popup_loader">
                    <Loader.PopupLoader show={true} />
                  </div>
                </PopupScrollHeight>
              ) : (
                popUpState.selectedConnection.map((data) => (
                  <PopupScrollHeight>
                    <EmptyDiv className="slack_inter_box">
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            name="connection_name"
                            value={data.name}
                            onChange={(e: { target: { value: any } }) => {
                              handleConnectionEdit(
                                e.target.value,
                                data.id,
                                'name'
                              );
                            }}
                          />
                          <InputContent>Connection name</InputContent>
                          {/* {console.log(
                          checkingUpdateName(data.id),
                          state,
                          'checking'
                        )} */}
                          {status === 'error' &&
                            checkingUpdateName(data.id).length > 0 && (
                              <p className="valid_url_alert">
                                Connection Name has to be unique
                              </p>
                              // </div>
                            )}
                        </InputContainer>
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label sharepoint_edit">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Connect Account{' '}
                          </Header>
                          {updateCred ? (
                            <div
                              className="sharepoint_icon"
                              onClick={() => {
                                setUpdateCred(false);
                                setState({
                                  ...state,
                                  updatePassWord: '',
                                });
                              }}
                            >
                              <CloseColorIcon />
                            </div>
                          ) : (
                            <div
                              className="sharepoint_icon"
                              onClick={() => setUpdateCred(true)}
                            >
                              <EditIcon />
                            </div>
                          )}
                        </div>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type="url"
                              value={data.credential?.domain}
                              disabled={!updateCred}
                              className={
                                updateCred ? '' : 'editor_btn_disabled'
                              }
                              onChange={(e: { target: { value: string } }) => {
                                // const validEmail = isValidEmail(e.target.value);
                                // setEmailValidation({
                                //   valid: validEmail,
                                //   message: validEmail ? '' : 'Email is invalid',
                                // });
                                handleConnectionEdit(
                                  e.target.value,
                                  data.id,
                                  'domain',
                                  'credential'
                                );
                              }}
                            />
                            <InputContent>Connected Domain</InputContent>
                            {!emailValidation.valid && (
                              <p className="error_msg">
                                {emailValidation.message}
                              </p>
                            )}
                          </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type={!state.eyeIcon ? 'password' : 'text'}
                              disabled={!updateCred}
                              className={
                                updateCred ? '' : 'editor_btn_disabled'
                              }
                              value={state.updatePassWord}
                              onChange={(e: { target: { value: any } }) =>
                                setState({
                                  ...state,
                                  updatePassWord: e.target.value,
                                })
                              }
                            />
                            <InputContent>Api Key</InputContent>
                          </InputContainer>
                          {/* <CancelButton
                            className={
                              updateCred ? 'd-flex' : 'editor_btn_disabled'
                            }
                            onClick={() =>
                              setState({
                                ...state,
                                eyeIcon: !state.eyeIcon,
                              })
                            }
                          >
                            {state.eyeIcon ? <EyeOpenIcon /> : <EyeCloseIcon />}
                          </CancelButton> */}
                        </OptionalInput>
                        <ButtonContainer
                          style={{
                            gap: 20,
                            padding: '0 0 10px 0',
                            marginTop: '-10px',
                          }}
                          className="slack_buton_center"
                        >
                          <Button
                            type="button"
                            className={
                              accountUpdate() || !updateCred
                                ? 'hidden'
                                : emailValidation.valid &&
                                  data.credential.username !== '' &&
                                  state.updatePassWord !== ''
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => {
                              setUpdateCred(false);
                              setState({
                                ...state,
                                updatePassWord: '',
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            style={{ marginRight: 20 }}
                            className={
                              accountUpdate() || !updateCred
                                ? 'hidden'
                                : emailValidation.valid &&
                                  data.credential.username !== '' &&
                                  state.updatePassWord !== ''
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => onCredUpdateSave(data.credentialId)}
                          >
                            <label> Update </label>
                          </Button>
                          {state.connectLoader && (
                            <span className="connect_account_loading">
                              <img src={LoadingSvgImage} />
                            </span>
                          )}
                          {status === 'apiConnectError' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                        </ButtonContainer>
                      </BgWrapper>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            WorkSpace{''}
                          </Header>
                        </div>
                        {/* <OptionalInput>
                          <InputContainer>
                            <Input
                              type="url"
                              value={data.input?.domain}
                              onChange={(e: { target: { value: any } }) =>
                                handleConnectionEdit(
                                  e.target.value,
                                  data.id,
                                  'domain',
                                  'input'
                                )
                              }
                            />
                            <InputContent>
                            Domain
                            <Tooltip
                                className="target customTip _description"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="test domain URL"
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                            </InputContent>
                            {!IsValidURL(data.input?.domain) &&
                              status === 'error' && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )}
                          </InputContainer>
                        </OptionalInput> */}
                        {/* <OptionalInput>
                          {data.input?.spaceKeys &&
                            data.input?.spaceKeys.map((rep, index) => (
                              <div className="knowledge_domain_url_box">
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="text"
                                    onChange={(evnt: {
                                      target: { value: any };
                                    }) =>
                                      handleRepositoryChange(
                                        index,
                                        evnt,
                                        data.id,
                                        'spaceKeys'
                                      )
                                    }
                                    value={rep}
                                    name="spaceKey"
                                  />

                                  <InputContent>
                                    Space Key {index + 1}
                                    <Tooltip
                                      className="target customTip _description"
                                      zIndex={10000}
                                      arrowSize={8}
                                      tagName="span"
                                      content="test spare key"
                                      distance={5}
                                  >
                                      <InfoIcon />
                                  </Tooltip>
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() =>
                                      removeSiteUrl(index, data.id)
                                    }
                                  >
                                    <CloseIcon />
                                  </span>
                                </InputContainer>
                                {/* {!IsValidURL(rep) && state.showError && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )} */}
                        {/* </div>
                            ))}
                        </OptionalInput> */}
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type="number"
                              value={
                                data.input?.workspaceId !== 0
                                  ? data.input?.workspaceId
                                  : null
                              }
                              onChange={(e: { target: { value: any } }) => {
                                updatefolderInfo();
                                handleConnectionEdit(
                                  parseInt(e.target.value),
                                  data.id,
                                  'workspaceId',
                                  'input'
                                );
                              }}
                            />
                            <InputContent>
                              Workspace ID
                              <Tooltip
                                className="target customTip _description"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="Provide Workspace ID. You can find it on your admin page. Provide the number after ws/ from the url. Example:https://xxxxx.freshservice.com/ws/3/admin/home"
                                distance={5}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </InputContent>
                            {/* {console.log(
                            IsValidURL(state.domainName),
                            status === 'error',
                            'kadsfjkadfa'
                          )}
                          {!IsValidURL(state.domainName) &&
                            status === 'error' && (
                              <p className="valid_url_alert">
                                Provide a Valid URL
                              </p>
                            )} */}
                          </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                          <InputContainer
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0 5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              // className={`switch_1`}
                              checked={published}
                              onChange={() => {
                                handleConnectionEdit(
                                  !published,
                                  data.id,
                                  'published',
                                  'input'
                                );
                                setPublished(!published);
                              }}
                            />
                            <Paragraph
                              style={{
                                lineHeight: 'normal',
                                marginBottom: 0,
                                color: '#000',
                                marginTop: '3px',
                              }}
                            >
                              Published
                              {/* <Tooltip
                                className="target customTip _description"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="published"
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip> */}
                            </Paragraph>
                          </InputContainer>
                        </OptionalInput>
                        {/* <SmallButton
                          primary
                          style={{ marginBottom: 30 }}
                          onClick={() => addSiteUrl(data.id)}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton> */}
                      </BgWrapper>
                      {data.input.workspaceId > 0 && (
                        <SmallButton
                          primary
                          onClick={() => {
                            // scroollUp();
                            updateCategories();
                            setShowAdditionalProp(!showAdditionalProp);
                          }}
                          style={{ marginBottom: '10px', width: '153px' }}
                        >
                          {showAdditionalProp ? (
                            <span>
                              <MinusIcon />
                            </span>
                          ) : (
                            <span>
                              <AddIcon />
                            </span>
                          )}
                          <label>Select subset of the site</label>
                        </SmallButton>
                      )}
                      {showAdditionalProp && (
                        <BgWrapper className="freshservice">
                          <>
                            <div className="slack_buton_box_12">
                              <InputContainer className="dialog_name_alert_content">
                                <div>
                                  <Input
                                    type="text"
                                    name="appId"
                                    value={data.input.category?.name}
                                    onClick={() => {
                                      updateCategories();
                                      setCategoryDropdown(true);
                                    }}
                                  />
                                  <div className="arrow_up_form">
                                    <DownArrowCircle />
                                  </div>
                                </div>
                                <InputContent
                                  style={{
                                    top: 10,
                                    fontSize: 10,
                                  }}
                                >
                                  Select category
                                </InputContent>
                              </InputContainer>
                              {categoryDropdown && (
                                <Downshift
                                  isOpen={true}
                                  onOuterClick={() => {
                                    setCategoryDropdown(false);
                                  }}
                                >
                                  {() => (
                                    <div className="slack_buton_4 integration_dropdown">
                                      <ul>
                                        {loader ? (
                                          <span className="connect_account_loading">
                                            <img src={LoadingSvgImage} />
                                          </span>
                                        ) : categories &&
                                          categories.length > 0 ? (
                                          categories.map(
                                            (
                                              cate: {
                                                name: string;
                                                value: string;
                                              },
                                              index: number
                                            ) => (
                                              <li
                                                key={index}
                                                onClick={() => {
                                                  handleConnectionEdit(
                                                    cate,
                                                    data.id,
                                                    'category',
                                                    'input'
                                                  );
                                                  handleConnectionEdit(
                                                    [],
                                                    data.id,
                                                    'folders',
                                                    'input'
                                                  );
                                                  setCategoryDropdown(false);
                                                  updateFolder();
                                                }}
                                              >
                                                {cate.name}
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <li>No Categories available</li>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </Downshift>
                              )}
                            </div>
                            {data.input.category &&
                              data.input.category.name !== '' && (
                                <div className="slack_buton_box_12">
                                  <InputContainer className="dialog_name_alert_content">
                                    <div>
                                      <Input
                                        type="text"
                                        name="appId"
                                        value={data.input.folders?.map(
                                          (fol) => fol.name
                                        )}
                                        onClick={() => {
                                          updateFolder();
                                          setFolderDropdown(true);
                                        }}
                                      />
                                      <div className="arrow_up_form">
                                        <DownArrowCircle />
                                      </div>
                                    </div>
                                    <InputContent
                                      style={{
                                        top: 10,
                                        fontSize: 10,
                                      }}
                                    >
                                      Select folder
                                    </InputContent>
                                  </InputContainer>
                                  {folderDropdown && (
                                    <Downshift
                                      isOpen={true}
                                      onOuterClick={() => {
                                        setFolderDropdown(false);
                                      }}
                                    >
                                      {() => (
                                        <div className="slack_buton_4 integration_dropdown">
                                          <ul>
                                            {loader ? (
                                              <span className="connect_account_loading">
                                                <img src={LoadingSvgImage} />
                                              </span>
                                            ) : folder && folder.length > 0 ? (
                                              folder.map(
                                                (
                                                  fold: {
                                                    name: string;
                                                    value: string;
                                                  },
                                                  index: number
                                                ) => (
                                                  <li
                                                    key={index}
                                                    onClick={() => {
                                                      updatingFolder(fold);
                                                      // setFolderDropdown(false);
                                                    }}
                                                  >
                                                    <div className="dropdown_list">
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          className="option-input radio"
                                                          checked={data.input.folders
                                                            ?.map(
                                                              (dat) => dat.value
                                                            )
                                                            .includes(
                                                              fold.value
                                                            )}
                                                        />
                                                      </label>
                                                      <span>{fold.name}</span>
                                                    </div>
                                                  </li>
                                                )
                                              )
                                            ) : (
                                              <li>No folders available</li>
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </Downshift>
                                  )}
                                </div>
                              )}
                          </>
                        </BgWrapper>
                      )}
                    </EmptyDiv>
                    <ButtonContainer className="footer_button_fixed">
                      <div className="popup_footer_button_container slack_buton_center">
                        <Button
                          type="button"
                          className={
                            needUpdate()
                              ? 'hidden'
                              : data.name !== ''
                              ? // data.input.hostUrl !== ''
                                // data.input.spaceKeys.length > 0
                                // !state.showError
                                // !checkingValidUrl
                                ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => {
                            updateConnection(data.id);
                          }}
                        >
                          <label> Save</label>
                        </Button>
                        {status === 'loader' && (
                          <span className="connect_account_loading">
                            <img src={LoadingSvgImage} />
                          </span>
                        )}
                        {status === 'error' && (
                          <span
                            className={
                              //   checkingDuplicate(data.input.spaceKeys) &&
                              IsValidURL(data.input.domain) &&
                              checkingUpdateName(data.id).length === 0
                                ? 'connect_account_failed hide'
                                : 'connect_account_failed'
                            }
                          >
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {status === 'apiError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {/* {!checkingDuplicate(data.input.spaceKeys) &&
                          status === 'error' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Space Keys must be unique
                                </span>
                              </div>
                            </span>
                          )} */}
                      </div>
                    </ButtonContainer>
                  </PopupScrollHeight>
                ))
              )}
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
    </>
  );
}
