import { BroadcastChannel, createLeaderElection } from 'broadcast-channel'

type Logout = {
    logout: boolean
}
const channel: BroadcastChannel<Logout> = new BroadcastChannel('logout')

export const addHandlerForLogout = (fn: (logout: Logout) => void) => {
    channel.onmessage = fn
}

export const postMessage = (logout: Logout) => {
    channel.postMessage(logout)
}

type AccessToken = {
    token: string
    action: 'updated'
}

const accessTokenChannel = new BroadcastChannel<AccessToken>('access_token')

export const accessTokenElector = createLeaderElection(accessTokenChannel)

export async function initializeAccessTokenElection() {
    await accessTokenElector.awaitLeadership()
}

export async function applyOnce() {
    await accessTokenElector.applyOnce()
}

export const addHandlerForAccessToken = (fn: (logout: AccessToken) => void) => {
    // console.log('added handler', fn)
    accessTokenChannel.onmessage = fn
}

export const postMessageForAccessToken = (accessToken: AccessToken) => {
    accessTokenChannel.postMessage(accessToken)
}

export const loadSessionStorage = function() {
    //source: http://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
    // console.log('executing session storage initializer')

    if (!sessionStorage.length) {
        // Ask other tabs for session storage
        localStorage.setItem('getSessionStorage', Date.now().toString())
    }

    window.addEventListener('storage', function(event: any) {
        //console.log('storage event', event);

        if (event.key == 'getSessionStorage') {
            // console.log('get session storage')
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
            localStorage.removeItem('sessionStorage')
        } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
            // console.log('store session storage')
            let data: any = JSON.parse(event.newValue)

            for (let key in data) {
                sessionStorage.setItem(key, data[key])
            }

            //showSessionStorage()
        }
    })

    window.onbeforeunload = function() {
        sessionStorage.clear()
    }
}
