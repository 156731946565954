/*eslint-disable*/
import React, { Component } from 'react'
import moment from 'moment'
import InputMoment from './input-moment'

export class Calendar extends Component<
    { choosenDate: (date: string) => void; closePopup: () => void; value: string | undefined },
    any
> {
    state = {
        m: this.props.value ? moment(new Date(this.props.value)) : moment()
    }

    handleChange = (m: any) => {
        this.setState({ m })
    }

    handleSave = () => {
        this.props.choosenDate(this.state.m.toString())
        this.props.closePopup()
    }

    render() {
        return (
            <InputMoment
                prevMonthIcon="fa fa-angle-left"
                nextMonthIcon="fa fa-angle-right"
                moment={this.state.m}
                onChange={this.handleChange}
                minStep={1}
                onSave={this.handleSave}
            />
        )
    }
}
