/*eslint-disable*/
import * as React from 'react'
import { useErrorContext } from './FormValidate'

type validation = {
    validationFn: (value: string) => void
    Error: string
}

type InputPropsT = {
    validationList?: validation[]
    value: string
    render: Function
    name?: string
}

//export class InputBox extends React.Component<InputPropsT, any> {
//state = {
//beginValidation: true,
//valid: true,
//errorMsg: true,
//}

//startValidation = () => {
//this.setState({ beginValidation: false })
//}

//handleInput = (setContext: (object: any) => undefined, valid: object) => (
//name: string,
//value: string,
//) => {
//const errors = this.props.validationList.filter(validation =>
//validation.validationFn(value),
//)

//if (this.state.beginValidation) {
//setContext(valid)
//this.setState({ valid: true })
//}

//if (errors.length > 0) {
//const errorMsg = errors[0].Error
//valid[name] = false
//setContext(valid)
//this.setState({ valid: false, errorMsg: errorMsg })
//} else {
//valid[name] = true
//setContext(valid)
//this.setState({ valid: true })
//}
//}

//render() {
//let { value } = this.props

//return (
//<ErrorsContext.Consumer>
//{({ formValid, setValid }) => (
//<React.Fragment>
//{this.props.render(
//value,
//this.state.valid,
//this.state.errorMsg,
//this.startValidation,
//this.handleInput(setValid, formValid),
//)}
//</React.Fragment>
//)}
//</ErrorsContext.Consumer>
//)
//}
//}

const InputBoxH: React.FunctionComponent<InputPropsT> = (props: InputPropsT) => {
    const [beginValidation, setBeginValidation] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState<null | string>(null)
    const { formValid, setValid } = useErrorContext()

    const startValidation = () => setBeginValidation(true)

    const handleInput = (setFormValidContext: (object: any) => undefined, fieldsValid: object) => (
        name: string,
        value: string
    ) => {
        const errors = props.validationList && props.validationList.filter(validation => validation.validationFn(value))

        if (beginValidation) {
            setFormValidContext(fieldsValid)
            setErrorMsg(null)
        }

        if (errors && errors.length > 0) {
            const errorMsg = errors[0].Error
            fieldsValid[name] = false
            setFormValidContext(fieldsValid)
            setErrorMsg(errorMsg)
        } else {
            fieldsValid[name] = true
            setFormValidContext(fieldsValid)
            setErrorMsg(null)
        }
    }

    let { value } = props

    return props.render(value, errorMsg === null, errorMsg, startValidation, handleInput(setValid, formValid))
}

export default InputBoxH
