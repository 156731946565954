/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProfileTabs } from '@/SidePanel/Sidebar';
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header';
import { Loader } from '@/common/components/Loader';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFetchAssistant } from '@/Assistant/Dialogue/BotSummaryAPI';
import styled from 'styled-components';
import { DialogIcon, KnowledgeSideIcon } from '@/common/Icons/Icons';
import {
  DownloadBotHelp,
  DownloadContent,
  ImageSpan,
} from '@/common/styled/GetStarted.Dumb';
import { Button, CardContainer } from '@/common/styled/Dialog.BotDetails.Dumb';
import { VideoButtonB } from '@/Videos/Videos';
import perfectIcon from '@/common/images/perfect.svg';
import { useJourney } from '@/Journey/JourneyContext';

export function HeaderForCanvasPage(props: any) {
  const params = useParams();

  return (
    <>
      <Header>
        <HeaderChild type="first">
          <div className="indent_left">
            <div className="indent_title">Workativ Assistant</div>
          </div>
        </HeaderChild>
        <HeaderChild type="second">
          <>
            <ProfileTabs {...props} />
          </>
          {/* <VideoButtonB id={'READ_CHATBOT_LOGS'} /> */}
        </HeaderChild>
      </Header>
    </>
  );
}

const WrapperBg = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: ${(props) =>
    props.topPadding === 'small' ? '30px 20px 50px' : '40px 20px 60px'};
  width: ${(props) => (props.bgWidth ? '840px' : '100%')};
  float: left;
`;

const IconWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  span {
    width: 64px;
    height: 64px;
    background: #e0f6ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 28px;
      height: 28px;
      fill: #355d8b;
    }
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  // padding-bottom: 40px;
  ${Button} {
    margin: 0px;
    max-width: max-content;
    min-width: 116px;
    padding: 5.5px 18px;
    font-size: 14px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
`;
const Card = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #b9b9b9;
  justify-content: space-between;

  :last-child {
    border: none;
  }
  h4 {
    font-size: 12px;
    line-height: 12px;
    color: #111111;
    font-family: 'Gordita-Medium';
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
  }
  p {
    font-size: 12px;
    line-height: 19px;
    color: #355d8b;
    font-family: 'Gordita-Regular';
    margin-bottom: 20px;
    text-align: center;
  }

  .getstarted_demo_btn{
    a{
      color: #fff;
    }

    :hover{
      background: rgb(221, 254, 239);
      color: rgb(51, 51, 51);
      border: 1px solid rgb(0, 157, 95);

     a{
      color: #000;
     }
    }
    :active{
      border: 1px solid #2468f6;
      background: #2468f6;
      color: #000;
      a{
        color: #000;
       }
     }

    a{
    :hover{
      text-decoration: none;
      color: #000;
    }
  }
  }
`;
const CardsRow = styled.section`
  width: 100%;
  float: left;
  margin-top: 20px;
  display: grid;
  padding: 0px 10px;
  grid-template-columns: ${(props) =>
    props.gridWidth
      ? 'repeat(auto-fill, minmax(44%, 1fr));'
      : 'repeat(auto-fill, minmax(44%, 1fr));'};
`;

const HeaderH1 = styled.h1`
  width: 100%;
  float: left;
  font-size: 24px;
  line-height: 24px;
  color: #3da27e;
  font-family: 'Gordita-Medium';
  margin-bottom: ${(props) => (props.margin ? '12px' : '5px')};
  text-align: center;

  span {
    width: 64px;
    height: 64px;

    img {
      width: 64px;
      height: 64px;
    }
  }
`;
const HeaderH2 = styled.h2`
  width: 100%;
  float: left;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  font-family: 'Gordita-Medium';
  margin-bottom: 12px;
  text-align: center;
  span {
    background: #ddfeef;
    line-height: 40px;
    height: 40px;
    padding: 8px 5px;
  }
`;
const HeaderH4 = styled.h4`
  width: 100%;
  float: left;
  line-height: 18px;
  font-size: 18px;
  color: #000000;
  font-family: 'Gordita-Regular';
  text-align: center;
`;
const HeaderRow = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    width: 100%;
    float: left;
    line-height: 12px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #355d8b;
    margin-bottom: 0px;
    text-align: center;
  }
`;

const Wrapper = styled.section`
  // display: flex;
  // justify-content: center;
  // align-items: baseline;
  // width: 100%;
  // float: left;
  // height: 100%;

  scroll-behavior: smooth;
  position: fixed;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 25%;
  width: 740px;
  z-index: 9999;
`;

const ThreeHeaderContainer = (props: { botName: string }) => {
  return (
    <>
      <HeaderRow>
        <HeaderH1>
          <span style={{ marginTop: '5px', marginRight: '-8px;' }}>
            Perfect
          </span>{' '}
          <span style={{ marginLeft: '3px' }}>
            <img
              src={perfectIcon}
              style={{ paddingBottom: '13px', paddingRight: '13px' }}
              alt="dialog"
            />
          </span>
        </HeaderH1>
        <HeaderH2>
          Build your <span>{props.botName}</span> now!
        </HeaderH2>
        <HeaderH4>What would you like to build today ?</HeaderH4>
        {/* <p>You can start building your bot by creating FAQs, conversations, or app workflows.</p> */}
      </HeaderRow>
    </>
  );
};

export function NewGetStarted(props: any) {
  const navigate = useNavigate();
  const { match } = props;
  const { workspacename } = match.params;

  const journeyData = useJourney();
  if (journeyData.type === 'loading') {
    return <Loader.PageLoader show={true} />;
  }
  if (
    journeyData.type === 'success' &&
    journeyData.value.state().value.type === 'MAIN' &&
    journeyData.value.state().value['JOURNEY'] !== 'JOURNEY::NOT_STARTED'
  ) {
    return <Navigate to={`/workspace/${workspacename}/welcome`} replace/>
  }

  return (
    <>
      <HeaderForCanvasPage {...props} />
      <div className="getstart_page_box"></div>
      
      <div className="header_loader_height_change getstart_page">
        {
          <Wrapper>
            <WrapperBg topPadding="small">
              <ThreeHeaderContainer botName={'Chatbot'} />
              <CardsRow>
                <Card>
                  <ContentWrapper>
                    <IconWrapper>
                      <span>
                        <KnowledgeSideIcon />
                      </span>
                    </IconWrapper>
                    <h4>Knowledge AI Bot</h4>
                    <p>
                      Connect and build your own custom Chat GPT for your
                      knowledge base, website etc
                    </p>
                  </ContentWrapper>
                  <ButtonWrapper>
                    <Button className="getstarted_demo_btn">
                      <a
                        href="https://workativ.com/conversational-ai-platform/demo"
                        target="_blank"
                      >
                        Book Demo for Trial
                      </a>
                    </Button>
                    {/* <VideoButtonB id={'START_WITH_FAQ'} /> */}
                  </ButtonWrapper>
                  {/* <DownloadContent top="small">
                              <DownloadBotHelp
                                  href="https://help.workativ.com/knowledgebase/start-with-faq/"
                                  target="_blank"
                                  style={{ margin: 'auto' }}
                              >
                                  <ImageSpan imagePadding="small">
                                      <QaIconWithCircle />
                                  </ImageSpan>
                                  <span className="dialog_start_with">How to start with FAQ</span>
                              </DownloadBotHelp>
                          </DownloadContent> */}
                </Card>
                <Card>
                  <ContentWrapper>
                    <IconWrapper>
                      <span>
                        <DialogIcon />
                      </span>
                    </IconWrapper>
                    <h4>Conversational Bot</h4>
                    <p>
                      Build your own customized bot with simple or complex
                      conversations flows. Integrate bot with apps and chat
                      channels.
                    </p>
                  </ContentWrapper>
                  <ButtonWrapper>
                    <Button onClick={() => navigate('marketplace')}>
                      Get Started
                    </Button>
                    <VideoButtonB id={'START_WITH_CONVERSATION'} />
                  </ButtonWrapper>
                  {/* <DownloadContent top="small">
                              <DownloadBotHelp
                                  href="https://help.workativ.com/knowledgebase/start-with-conversational/"
                                  target="_blank"
                                  style={{ margin: 'auto' }}
                              >
                                  <ImageSpan imagePadding="small">
                                      <QaIconWithCircle />
                                  </ImageSpan>
                                  <span className="dialog_start_with">How to start with conversations</span>
                              </DownloadBotHelp>
                          </DownloadContent> */}
                </Card>
                {/* <Card>
                          <ContentWrapper>
                              <IconWrapper>
                                  <span>
                                      <FlowIcon />
                                  </span>
                              </IconWrapper>
                              <h4>App Workflows</h4>
                              <p>
                                  Start budling app workflows for your bot. Think of app workflows as automations that
                                  connect your bot with your apps to perform actions or tasks so you can automate process
                                  and end-user requests directly from your bot.
                              </p>
                          </ContentWrapper>
                          <ButtonWrapper>
                              <Button onClick={props.redirectToWorkflow}>Get Started</Button>
                              <VideoButtonB id={'START_WITH_APP_WORKFLOW'} />
                          </ButtonWrapper>
                          <DownloadContent top="small">
                              <DownloadBotHelp
                                  href="https://help.workativ.com/knowledgebase/start-with-app-workflows/"
                                  target="_blank"
                                  style={{ margin: 'auto' }}
                              >
                                  <ImageSpan imagePadding="small">
                                      <QaIconWithCircle />
                                  </ImageSpan>
                                  How to start with app workflows
                              </DownloadBotHelp>
                          </DownloadContent>
                      </Card> */}
              </CardsRow>
            </WrapperBg>
          </Wrapper>
        }
      </div>
    </>
  );
}
