import {
  getJson,
  loginApiURL,
  postJson,
  putJson,
  subscriptionApiURL,
} from '@/common/utils/api-utils';
import { deleteReq, patchReq } from '@/common/utils/axios-fp.utils';
import { fold } from 'fp-ts/lib/Either';
import URLTemplate from 'url-template';
import { Role, User } from './user.types';

enum APIList {
  numberOfAllowedAdmins = '/{ws}/resource/admin/limit',
  workspaceUsers = '/access/v2/{ws}/users',
  inviteUsers = '/access/v2/invite/{ws}',
  revoke = '/access/revoke/{ws}',
  enable = '/access/enable/{ws}',
  remove = '/access/v2/remove/{ws}',
  roles = '/access/v2/{ws}/roles',
  updateRoles = '/access/v2/roles/{ws}/{user}',
}

export type BasicUserInfo = {
  first_name?: string;
  last_name?: string;
  email: string;
  roles: Pick<Role, 'id'>[] | [];
};

const getUsersURL = URLTemplate.parse(APIList.workspaceUsers);
const inviteUserURL = URLTemplate.parse(APIList.inviteUsers);
const getRolesURL = URLTemplate.parse(APIList.roles);
const revokeURL = URLTemplate.parse(APIList.revoke);
const enableUserURL = URLTemplate.parse(APIList.enable);
const removeUserURL = URLTemplate.parse(APIList.remove);
const updateRolesURL = URLTemplate.parse(APIList.updateRoles);
const numberOfAllowedAdminsURL = URLTemplate.parse(
  APIList.numberOfAllowedAdmins
);

export const fetchUsers = (ws: string) => {
  // return Promise.resolve([
    // {
    //     "id": 59,
    //     "first_name": "Sangeetha",
    //     "last_name": "Udayakumar",
    //     "email": "sangeetha.n@workativ.com",
    //     "verified": true,
    //     "has_password": true,
    //     "Roles": [
    //         {
    //             "id": 1,
    //             "name": "super_admin",
    //             "display_name": "Admin",
    //             "workspace": null,
    //             "meta": {},
    //             "createdAt": "2023-03-15T18:17:28.000Z",
    //             "updatedAt": "2023-03-15T18:17:28.000Z"
    //         }
    //     ],
    //     "AccessibleWorkspaces": [
    //         {
    //             "name": "dhana",
    //             "ownerId": 339,
    //             "WorkspaceAccess": {
    //                 "valid": true
    //             }
    //         }
    //     ],
    //     "CountryCode": {
    //         "name": "India",
    //         "ISOcode": "IN"
    //     },
    //     "MobileNumberVsUser": null
    // },
//     {
//         "id": 235,
//         "first_name": "Arunadevi",
//         "last_name": "Rathinakumar",
//         "email": "arunadevi.r@workativ.com",
//         "verified": true,
//         "has_password": true,
//         "Roles": [
//             {
//                 "id": 1,
//                 "name": "super_admin",
//                 "display_name": "Admin",
//                 "workspace": null,
//                 "meta": {},
//                 "createdAt": "2023-03-15T18:17:28.000Z",
//                 "updatedAt": "2023-03-15T18:17:28.000Z"
//             }
//         ],
//         "AccessibleWorkspaces": [
//             {
//                 "name": "dhana",
//                 "ownerId": 339,
//                 "WorkspaceAccess": {
//                     "valid": true
//                 }
//             }
//         ],
//         "CountryCode": null,
//         "MobileNumberVsUser": {
//             "id": 135,
//             "mobileNo": "7550242010",
//             "verified": false,
//             "valid": true,
//             "createdAt": "2023-03-16T08:34:55.000Z",
//             "updatedAt": "2023-03-16T08:34:55.000Z",
//             "code": "1",
//             "userId": 235
//         }
//     },
//     {
//         "id": 339,
//         "first_name": "Dhanasekaran",
//         "last_name": "T",
//         "email": "dhanasekaran.t@workativ.com",
//         "verified": true,
//         "has_password": true,
//         "Roles": [
//             {
//                 "id": 1,
//                 "name": "super_admin",
//                 "display_name": "Admin",
//                 "workspace": null,
//                 "meta": {},
//                 "createdAt": "2023-03-15T18:17:28.000Z",
//                 "updatedAt": "2023-03-15T18:17:28.000Z"
//             }
//         ],
//         "AccessibleWorkspaces": [
//             {
//                 "name": "dhana",
//                 "ownerId": 339,
//                 "WorkspaceAccess": {
//                     "valid": true
//                 }
//             }
//         ],
//         "CountryCode": null,
//         "MobileNumberVsUser": {
//             "id": 191,
//             "mobileNo": "9876543210",
//             "verified": false,
//             "valid": true,
//             "createdAt": "2023-09-13T05:24:14.000Z",
//             "updatedAt": "2023-09-13T05:24:14.000Z",
//             "code": "1",
//             "userId": 339
//         }
//     }
// ]);
  
  return getJson<User[]>(
    loginApiURL(
      getUsersURL.expand({
        ws,
      })
    )
  );
};

export type RolesInputT = Pick<Role, 'id'>[];

export const updateRoles = (ws: string, userId: number, roles: RolesInputT) => {
  return putJson(
    loginApiURL(
      updateRolesURL.expand({
        ws,
        user: userId,
      })
    )
  )()(roles);
};

export const inviteUser = (ws: string, user: BasicUserInfo) => {
  return postJson(
    loginApiURL(
      inviteUserURL.expand({
        ws,
      })
    )
  )(user);
};

const MOCK_Roles = [
  {
      "id": 171,
      "name": "chatbot_admin_aishika.n_7981",
      "display_name": "Chatbot Admin",
      "workspace": 374,
      "meta": {
          "bot_list": [
              "f1cf3d70-a17e-49f9-bcc2-a762d08f65a1"
          ],
          "botname_list": [
              "IT Support Bot"
          ],
          "conatins_chatbotAdmin": true,
          "contains_chatbotContributor": false
      },
      "createdAt": "2024-02-05T13:46:53.000Z",
      "updatedAt": "2024-02-05T13:46:53.000Z"
  },
  {
      "id": 172,
      "name": "chatbot_admin_arunadevi.r_5905",
      "display_name": "Chatbot Admin",
      "workspace": 374,
      "meta": {
          "bot_list": [
              "f1cf3d70-a17e-49f9-bcc2-a762d08f65a1"
          ],
          "botname_list": [
              "IT Support Bot"
          ],
          "conatins_chatbotAdmin": true,
          "contains_chatbotContributor": false
      },
      "createdAt": "2024-02-05T13:52:25.000Z",
      "updatedAt": "2024-02-05T13:52:25.000Z"
  },
  {
      "id": 1,
      "name": "super_admin",
      "display_name": "Admin",
      "workspace": null,
      "meta": {},
      "createdAt": "2023-03-15T18:17:28.000Z",
      "updatedAt": "2023-03-15T18:17:28.000Z"
  }
]
export const fetchRoles = (ws: string) => {
  return getJson<Role[]>(loginApiURL(getRolesURL.expand({ ws })));
  // return Promise.resolve(MOCK_Roles);
};

export const disableUser = (ws: string, userId: number) =>
  deleteReq<{ id: number }>(loginApiURL(revokeURL.expand({ ws })))({
    id: userId,
  })().then((deleteRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(deleteRes);
  });

export const enableUser = (ws: string, userId: number) =>
  patchReq<{ id: number }>(loginApiURL(enableUserURL.expand({ ws })))({
    id: userId,
  })().then((enableRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(enableRes);
  });

export const removeUser = (ws: string, userId: number) =>
  deleteReq<{ id: number }>(loginApiURL(removeUserURL.expand({ ws })))({
    id: userId,
  })().then((removeRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(removeRes);
  });

export const getAllowedNumberOfAdminsApi: (
  ws: string
) => Promise<number> = async (ws: string) => {
  console.log('ws ::: ', ws);
  const {
    output: { resourceLimit },
  } = await getJson(
    subscriptionApiURL(numberOfAllowedAdminsURL.expand({ ws }))
  );
  console.log('resourceLimit ::: ', resourceLimit);
  return resourceLimit;
};
