/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'

import {
    AdaptiveIcon,
    AttachmentIcon,
    BulpIcon,
    DialogIcon,
    ExitIcon,
    FlowIcon,
    JumpIcon,
    OptionIcon,
    QaIcon,
    ResponseIcon,
    SlotsIcon,
    SubPanelIcons,
    VariableIcon
} from '@/common/Icons/Icons'

import dialogpng from '@/common/images/dialog.png'
import { DialogueContext } from '../Context'
import { ActionT } from '../kind'

enum VIEWS {
    DIALOG,
    SENDMESSAGE,
    QUESTION,
    OPTIONS,
    JUMP,
    SLOT,
    FLOW,
    EXIT,
    ADAPTIVE,
    AGENT,
    CUSTOM_VARIABLE,
    FILE_UPLOAD
}

const SidePanel = ({ dialog }: any) => {
    const [butonHover, setButonHover] = useState('')

    const { PageState } = useContext(DialogueContext)

    const [view, setView] = useState<VIEWS>(VIEWS.DIALOG)

    useEffect(() => {
        if (PageState.action == ActionT.TEXT_RESPONSE) {
            setView(VIEWS.SENDMESSAGE)
        } else if (PageState.action == ActionT.OPTIONS_RESPONSE) {
            setView(VIEWS.OPTIONS)
        } else if (PageState.action == ActionT.QUESTION_RESPONSE) {
            setView(VIEWS.QUESTION)
        } else if (PageState.action == ActionT.JUMP_DIALOGUE) {
            setView(VIEWS.JUMP)
        } else if (PageState.action == ActionT.ADAPTIVE_RESPONSE) {
            setView(VIEWS.ADAPTIVE)
        } else if (PageState.action == ActionT.SLOT_RESPONSE) {
            setView(VIEWS.SLOT)
        } else if (PageState.action == ActionT.FLOW_DIALOGUE) {
            setView(VIEWS.FLOW)
        } else if (PageState.action == ActionT.AGENT_HANDOFF_RESPONSE) {
            setView(VIEWS.AGENT)
        } else if (PageState.action == ActionT.VARIABLE_RESPONSE) {
            setView(VIEWS.CUSTOM_VARIABLE)
        } else if (PageState.action == ActionT.ATTACH_RESPONSE) {
            setView(VIEWS.FILE_UPLOAD)
        } else if (PageState.action == ActionT.EXIT_DIALOGUE) {
            setView(VIEWS.EXIT)
        } else {
            if (view != VIEWS.DIALOG) {
                setView(VIEWS.DIALOG)
            }
        }
    }, [PageState])

    const settings = {
        infinite: false,
        speed: 300,
        slidesToShow: 7,
        slidesToScroll: 1
    }

    return (
        <div className="flex_side_menu" style={{ display: 'none' }}>
            <div className="sidepanel_menu_right">
                <div className="sidepanel_menu_right_menu">
                    <div className="sidepanel_menu_right_menu_step">
                        <div className="sidepanel_step_automations_content dialog_canvas_editor_content">
                            <div className="sidepanel_menu_right_head">
                                <h4>Chatbot Builder</h4>
                            </div>

                            {dialog.conditions != '' ? (
                                <></>
                            ) : (
                                <>
                                    <p className="dialog_p_head">
                                        Use this space to compose a dialog for the chatbot to chat with a user.
                                    </p>
                                </>
                            )}
                            <img src={dialogpng} alt="dialog" title="dialog" />

                            <div className="sidepanel_step_canvas">
                                <h6 className="sidepanel_step_canvas_h6">
                                    <span>
                                        <BulpIcon />
                                    </span>
                                    Quick Tips
                                </h6>
                                <div className="sidepanel_step_canvas_svg">
                                    <span
                                        style={{ visibility: butonHover == 'DIALOG' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Recognize Dialog
                                    </span>

                                    <span
                                        style={{ visibility: butonHover == 'SENDMESSAGE' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Send a Message
                                    </span>

                                    <span
                                        style={{ visibility: butonHover == 'QUESTION' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Ask a question
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'OPTIONS' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Options
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'JUMP' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Jump
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'SLOT' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Verify user details
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'FLOW' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Call an automation
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'AGENT' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Agent Handover
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'ADAPTIVE' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Adaptive Cards
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'CUSTOM_VARIABLE' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Custom Variable
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'FILE_UPLOAD' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        File Upload
                                    </span>
                                    <span
                                        style={{ visibility: butonHover == 'EXIT' ? 'visible' : 'hidden' }}
                                        className="side_menu_hvr"
                                    >
                                        Exit
                                    </span>
                                    <Slider {...settings}>
                                        <div
                                            className="box"
                                            onMouseEnter={() => setButonHover('DIALOG')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.DIALOG)}
                                                className={
                                                    view == VIEWS.DIALOG
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <DialogIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('SENDMESSAGE')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.SENDMESSAGE)}
                                                className={
                                                    view == VIEWS.SENDMESSAGE
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <ResponseIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('QUESTION')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.QUESTION)}
                                                className={
                                                    view == VIEWS.QUESTION
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <QaIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('OPTIONS')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.OPTIONS)}
                                                className={
                                                    view == VIEWS.OPTIONS
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <OptionIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('JUMP')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.JUMP)}
                                                className={
                                                    view == VIEWS.JUMP
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <JumpIcon />
                                            </span>
                                        </div>

                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('SLOT')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.SLOT)}
                                                className={
                                                    view == VIEWS.SLOT
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <SlotsIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('FLOW')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.FLOW)}
                                                className={
                                                    view == VIEWS.FLOW
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <FlowIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('AGENT')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.AGENT)}
                                                className={
                                                    view == VIEWS.AGENT
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <SubPanelIcons.AgentHandoff />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('ADAPTIVE')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.ADAPTIVE)}
                                                className={
                                                    view == VIEWS.ADAPTIVE
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <AdaptiveIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('CUSTOM_VARIABLE')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.CUSTOM_VARIABLE)}
                                                className={
                                                    view == VIEWS.CUSTOM_VARIABLE
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <VariableIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('FILE_UPLOAD')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.FILE_UPLOAD)}
                                                className={
                                                    view == VIEWS.FILE_UPLOAD
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <AttachmentIcon />
                                            </span>
                                        </div>
                                        <div
                                            className="box"
                                            onMouseOver={() => setButonHover('EXIT')}
                                            onMouseLeave={() => setButonHover('')}
                                        >
                                            <span
                                                onClick={() => setView(VIEWS.EXIT)}
                                                className={
                                                    view == VIEWS.EXIT
                                                        ? 'sidepanel_step_canvas_svg_span sidepanel_step_canvas_svg_span_selected'
                                                        : 'sidepanel_step_canvas_svg_span'
                                                }
                                            >
                                                <ExitIcon />
                                            </span>
                                        </div>
                                    </Slider>
                                </div>
                                {view == VIEWS.SENDMESSAGE && <SendMessageTips />}
                                {view == VIEWS.OPTIONS && <OptionsMessageTips />}
                                {view == VIEWS.QUESTION && <QuestionTips />}
                                {view == VIEWS.JUMP && <JumpTips />}
                                {view == VIEWS.SLOT && <SlotTips />}
                                {view == VIEWS.FLOW && <FlowTips />}
                                {view == VIEWS.EXIT && <ExitTips />}
                                {view == VIEWS.AGENT && <AgentHandoverTips />}
                                {view == VIEWS.ADAPTIVE && <AdaptiveTips />}
                                {view == VIEWS.CUSTOM_VARIABLE && <CustomVaraibleTips />}
                                {view == VIEWS.FILE_UPLOAD && <FileUploadTips />}
                                {view == VIEWS.DIALOG && <DilogTips />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SendMessageTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Send a Message</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/send-message/"
            >
                Send a Message
            </a>{' '}
            makes the chatbot respond to a user's input or query with a text message.
        </p>
        <p>
            You can specify multiple messages on the Send a Message pop up. The chatbot will then display each message
            one at a time for similar user input. This is to break the monotony.
        </p>
        <p>
            You can make the chatbot to either display the messages <b>sequentially</b> or <b>randomly</b>.
        </p>
    </div>
)
const OptionsMessageTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Options</h4>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/options/"
            >
                <b>Options</b>
            </a>{' '}
            when you want the chatbot to display clickable options to the user.
        </p>
        <p>When a user selects an option, the value for that option gets passed to the chatbot. </p>
        <p>
            Based on the condition that this <b>value</b> satisfies, the corresponding dialog gets triggered.
        </p>
        <p>
            For example,
            <br />
            <b>Chatbot:</b> Do you want to continue with the assistance?
            <br /> Option: <b>“Yes”</b> Value: <b>Welcome</b>
            <br />
            Option: <b>“No”</b> Value: <b>Exit</b>
        </p>
        <p>
            The <b>Yes</b> and <b>No</b> dialogs are created once you click on the <b>Save</b> button.
        </p>
        <p>
            You can then add the <b>intent</b> that matches the option's value as a condition to the respective dialog.
        </p>
    </div>
)
const QuestionTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Ask a Question</h4>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/ask-a-question/"
            >
                Ask a Question
            </a>{' '}
            makes the chatbot prompt the user with a question and store the user's response in a 
            <b>context variable</b>.{' '}
        </p>
        <p>
            You can select between a <b>simple</b> question and an <b>options-based</b> question.
        </p>
        <p>
            For example,
            <br />
            <b>Chatbot:</b> Please provide your mobile number.
            <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The chatbot will then store, <b>"My mobile number is 1234567890"</b> in a context variable.
        </p>{' '}
        <p>
            <b>Tip:</b> Use <b>Verify user details </b>
            function if you want to validate user answer before saving.
        </p>
    </div>
)
const JumpTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Jump</h4>
        <p>
            Use{' '}
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/jump/"
            >
                Jump
            </a>{' '}
            when you want the chatbot to jump to a specific dialog. It has the following options:
        </p>
        <p>
            <b>Wait for user input—</b>Jumps to a dialog and waits for the user to input again. Based on that input, the
            specified dialog or any one of its successors get executed by the chatbot.{' '}
        </p>
        <p>
            <b>If bot recognizes—</b>Jumps to a dialog and executes it only if it recognizes that dialog from the user's
            last input. If it doesn't, then it checks successive dialogs' conditions against the user's input.{' '}
        </p>
        <p>
            <b>Execute Dialog—</b>Jumps to a dialog and executes its conversation flow. This doesn't depend on the
            user's input.
        </p>
    </div>
)
const AdaptiveTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Adaptive Cards</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/knowledgebase/adaptive-cards/"
            >
                Adaptive Cards
            </a>{' '}
            makes chatbot to display details or information to users using beautiful cards. You can choose what data or
            information needs to be shown to the user within these cards and you can also have buttons in place for a
            URL link as an example
        </p>
    </div>
)
const SlotTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Verify User Details</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/get-details/"
            >
                Verify user details
            </a>{' '}
            makes the chatbot extract and store only the required data from a user's input.{' '}
        </p>
        <p>
            Use an appropriate <b>entity</b> to extract the required data after validation.
        </p>
        <p>
            For example, <br />
            <b>Chatbot:</b> Please provide your mobile number. <br />
            <b>User:</b> My mobile number is 1234567890.
        </p>
        <p>
            The chatbot will store only <b>1234567890</b> after validating it. <br />
            If there's no match, the chatbot will continue to prompt the user for valid input.
        </p>
    </div>
)
const FlowTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Call an Automation</h4>
        <p>
            Use{' '}
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/call-an-automation/"
            >
                Call an Automation
            </a>{' '}
            function when you want the chatbot to call and execute a workflow automation within a dialog.
        </p>
        <p>
            You'll have to <b>map the context variables</b> as <b>input values</b> on the Automation pop up.
        </p>
        <p> This enables the Workflow Builder to get the user's input to execute the workflow. </p>
        <p>
            The chatbot will create <b>Success</b> and <b>Failure</b> dialogs automatically automatically after adding
            an automation.
        </p>
        <p>You can then create a conversation flow for those dialogs.</p>
    </div>
)
const AgentHandoverTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Agent Handover </h4>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/agent-handover/"
            >
                Agent Handover
            </a>{' '}
            helps the chatbot handover the chat session from chatbot to live agents.
        </p>
        <p>Select your agent chat channel from the list to handover the chat session to live agent.</p>
        <p>You can configure the agent handover settings in the ‘Agent Handover’ tab under the Bot Menu. </p>
    </div>
)
const FileUploadTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>File Upload: </h4>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/file-upload/"
            >
                File Upload
            </a>{' '}
            helps admin to send files like images, pdf, video to chatbot which can then send as data to execute a
            workflow. URL will be generated and stored in the context variable of the file.
        </p>
        <p>
            <b>Note:</b> Chatbot can receive only one file at a time.
        </p>
    </div>
)

const CustomVaraibleTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Custom Variable </h4>
        <p>
            <a
                target="_blank"
                className="sidepanel_href_color"
                href="https://help.workativ.com/index.php/knowledgebase/create-custom-variables/"
            >
                Custom Variable
            </a>{' '}
            helps admin create a custom variable.
        </p>
        <p>
            <b>For Example:</b> If the admin wants to send "login type" as 'computer' to the bot in the conversation.
            You can add <b>"Login_Type"</b> as variable name and value as <b>"Computer"</b>.
        </p>
        <p>
            <b>Note:</b> You can add custom variable value as simple text or combination of entity and context variable.{' '}
        </p>
    </div>
)
const ExitTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Exit</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/exit/"
            >
                Exit
            </a>{' '}
            makes the chatbot to display a message and exit from a conversation with a user.
        </p>
        <p>
            You can also make the chatbot get the user's feedback with <b>Get feedback from the user option </b> on the{' '}
            <b>Exit</b> pop-up.
        </p>
        <p>You can configure the feedback options on the chatbot's settings page.</p>
    </div>
)
const DilogTips = () => (
    <div className="sidepanel_canvas_svg_content">
        <h4>Recognize Dialog</h4>
        <p>
            <a
                className="sidepanel_href_color"
                target="_blank"
                href="https://help.workativ.com/index.php/knowledgebase/recognize-dialog/"
            >
                Recognize Dialog
            </a>{' '}
            helps the chatbot recognize user utterance or intent to start a conversation with a user.
        </p>
        <p>
            {' '}
            Let's say you have 10 dialogs. You can specify conditions on how each dialog is executed using the Recognize
            Dialog pop up. Based on the user's intent, the chatbot can then initiate an appropriate dialog that can
            solve the user’s query.
        </p>
        <p> The chatbot can then check and choose the dialog that the user's input satisfies.</p>
    </div>
)

export default SidePanel
