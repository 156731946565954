/*eslint-disable*/
import React from 'react'
export const LinkedDialogIcon: React.FunctionComponent = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 48">
        <g>
            <g>
                <g>
                    <path
                        d="M28.7,19.3c-0.5-0.5-1.1-1-1.7-1.3c-1.6-1-3.5-1.6-5.4-1.6c-2.7,0-5.2,1-7.1,2.9l-9.1,9.1c-1.9,1.9-2.9,4.4-2.9,7.1
				c0,5.5,4.5,10,10,10c2.6,0,5.2-1,7.1-2.9l7.5-7.5c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.7-0.7-0.7h-0.3c-1.6,0-3.1-0.3-4.6-0.9
				c-0.3-0.1-0.6,0-0.8,0.2l-5.4,5.4c-1.7,1.7-4.4,1.7-6.1,0c-1.7-1.7-1.7-4.4,0-6.1l9.1-9.1c1.7-1.7,4.4-1.7,6.1,0
				c1.1,1.1,2.9,1.1,4,0c0.5-0.5,0.8-1.1,0.8-1.8C29.6,20.7,29.3,19.9,28.7,19.3z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M42.5,5.5c-3.9-3.9-10.2-3.9-14.1,0L20.9,13c-0.2,0.2-0.3,0.5-0.1,0.8c0.1,0.3,0.4,0.4,0.7,0.4h0.3
				c1.6,0,3.1,0.3,4.6,0.9c0.3,0.1,0.6,0,0.8-0.2l5.4-5.4c1.7-1.7,4.4-1.7,6.1,0c1.7,1.7,1.7,4.4,0,6.1l-6.7,6.7l-0.1,0.1l-2.3,2.3
				c-1.7,1.7-4.4,1.7-6.1,0c-1.1-1.1-2.9-1.1-4,0c-0.5,0.5-0.8,1.1-0.8,1.8c-0.1,0.8,0.2,1.6,0.8,2.2c0.8,0.8,1.8,1.5,2.9,2
				c0.1,0.1,0.3,0.1,0.4,0.2s0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.5,0.1l0.4,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.4,0.1,0.7,0.1,1.1,0.1h0.5
				h0l0.4,0c0.2,0,0.3,0,0.5,0h0.2l0.5-0.1l0.2,0l0.4-0.1h0.1c1.8-0.4,3.4-1.3,4.6-2.6l9.1-9.1C46.4,15.7,46.4,9.4,42.5,5.5z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export const AppleIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <path d="M24.4 16.9c0 4.5 4.1 6 4.1 6 0 .1-.6 2.1-2.1 4.2-1.3 1.8-2.6 3.6-4.7 3.6s-2.7-1.2-5.1-1.2c-2.3 0-3.1 1.1-5 1.2-2 .1-3.5-2-4.8-3.8C4.2 23.2 2.2 16.5 4.9 12c1.3-2.3 3.7-3.7 6.3-3.7 2 0 3.8 1.3 5.1 1.3 1.2 0 3.5-1.6 5.9-1.4 1 0 3.8.4 5.6 2.9-.3.3-3.5 2.1-3.4 5.8m-3.9-11c1.1-1.3 1.8-3 1.6-4.7-1.5.1-3.4 1-4.5 2.2-1 1.1-1.9 2.9-1.6 4.6 1.7.1 3.4-.8 4.5-2.1" />
    </svg>
)

export const LinuxIcon = () => (
    <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <style>{'.st01{fill:#fff}.st11{fill:#e69629}'}</style>
        <path
            className="st01"
            d="M16.6 7.4v-.1c0-.7.4-1.2 1-1.2s1 .5 1 1.2c0 .2 0 .3-.1.5.2-.3.3-.6.3-.9 0-.8-.5-1.5-1.2-1.5s-1.2.7-1.2 1.5c0 0 0 .2.2.5-.1-.1 0-.1 0 0zM13.6 6h.1c.4 0 .8.4.9 1v.2h.1v-.3c0-.8-.4-1.4-.9-1.4-.4 0-.8.5-.9 1.1.3-.3.5-.5.7-.6zM21.9 21.6c.1-.5.3-1.7.1-3.1-.3-1.8-2.8-7.5-3.3-8.2-.3 0-.9.4-1.5.8s-1.3.8-1.8.9h-.3c-.7 0-1.4-.6-1.8-1-.1.9-.5 1.9-1 2.5-.3.4-.6 1.2-.9 2-.3.7-.5 1.5-.8 2-.6 1-1 2.9-.5 4 .2.5 1 1.2 1.8 1.7.9.7 1.6 1.3 1.7 1.9.1.7-.3 1.1-.6 1.3l.9 1.1c.1 0 .5.1 1.1.1.6 0 1.4-.1 2.1-.2 1.9-.3 3.2-1.8 3.5-2.2 0-.4 0-2.4.1-3 0-.2.2-.6 1-.6 0-.1.1 0 .2 0z"
        />
        <g>
            <path
                className="st11"
                d="M13.3 9.5c0 .1.4.5.9.8.1 0 .1.1.2.1.3.2.4.3.6.2.3 0 1.4-.7 1.9-1l.1.1c.2-.1.3-.2.4-.2.2-.1.2-.1.3-.2 0-.1-.2-.2-.6-.4-.1 0-.3-.1-.5-.2-.5-.2-1.1-.4-1.3-.5-.2 0-.6.3-1.1.7-.3.2-.7.5-.9.6zM9.5 24.3c-.2-.3-.3-.6-.4-.7-.3-.5-.9-1.1-1.2-1.1-.2.1-.3.3-.4.6-.2.3-.4.7-.7 1-.3.2-.7.2-1 .3-.3.1-.9.2-.9.3v.6c.1.3.1.6.1 1 0 .3-.1.5-.2.7-.1.3-.2.7-.1.8 0 .2.7.3 1.3.4.7.1 1.4.2 1.9.4l.1.1c.8.3 2.3 1 3.1 1h.2c.1-.1.7-.5.8-1 .1-.6-.4-1.4-.8-1.7-.4-.5-1.2-1.8-1.8-2.7zM26.7 25.8c-.7-.4-1.1-1.3-1-2v-.3c-.1.2-.3.4-.5.6-.3.3-1.2.7-1.9.7-.3 0-.5 0-.8-.1-.7-.3-.8-1.3-.8-1.7V24.7c0 .8-.2 2.2-.4 3.2s.2 1.7.7 1.8h.1c.4 0 1.2-.5 1.8-1.1.5-.5 1.5-1 2.4-1.5.5-.3 1-.5 1.2-.7 0-.2-.6-.5-.8-.6z"
            />
        </g>
        <path d="M28.2 25.2c-.9-.4-1.3-.8-1.2-1.6 0-.8-.4-1.5-.7-1.7.1-.5.5-2.3 0-3.9-.6-1.7-2.3-4.3-4.2-6.8-.7-1-.8-2.2-.8-3.5 0-1.2-.1-2.7-.8-4.2-.8-1.7-2.3-2.7-4.2-2.7-1.1 0-2.3.4-3.2 1-1.8 1.3-1.6 4-1.4 5.8 0 .2 0 .5.1.7.1 1.8 0 2.7-.1 3-.1.2-.5.7-.9 1.3-.4.6-.9 1.2-1.3 1.8-.5.7-.9 1.9-1.3 2.9-.3.8-.5 1.5-.8 2-.5.9-.4 1.7-.3 2-.2.1-.4.4-.7.9-.3.6-.8.9-2 1.1-.4.1-.8.4-1 .7-.3.5-.1 1.1 0 1.5.2.6.1 1-.2 1.7-.1.2-.1.3-.2.5 0 .3 0 .6.2.8.4.7 1.7.9 3 1 .8.1 1.6.4 2.4.7.8.3 1.6.6 2.4.7h.3c1.1 0 1.7-.8 1.8-1.1.4-.1 1.8-.4 3.3-.4 1.5 0 2.9.2 3.3.3.1.2.5.8 1 1.1.3.2.7.2 1.1.2.4 0 1.3-.1 1.9-.8.7-.7 2.3-1.6 3.5-2.2.3-.1.5-.3.7-.4.7-.4 1-.9 1-1.5 0-.3-.3-.7-.7-.9zm-11-20c.7 0 1.2.7 1.2 1.5 0 .3-.1.7-.3.9 0-.1.1-.3.1-.5 0-.7-.4-1.2-1-1.2s-1 .6-1 1.2v.1h-.1c-.1-.1-.1-.3-.1-.5 0-.8.6-1.5 1.2-1.5zm-2 3c.2 0 .9.3 1.3.5.2.1.4.2.5.2.4.1.6.3.6.4 0 0 0 .1-.3.2-.1.1-.3.1-.4.2l-.1.1c-.5.3-1.6 1-1.9 1-.2 0-.3-.1-.6-.2-.1 0-.1-.1-.2-.1-.5-.3-.8-.7-.9-.8.2-.1.6-.4.8-.6.5-.6.9-.9 1.2-.9zm-1.7-2.7c.5 0 .9.7.9 1.4v.3h-.1V7c-.1-.6-.5-1-.9-1h-.1c-.3 0-.5.2-.6.5.1-.6.4-1 .8-1zM12 28.6c-.1.5-.7.9-.8 1H11c-.8 0-2.3-.6-3.1-1l-.1-.1c-.4-.2-1.2-.3-1.9-.4-.6-.1-1.3-.2-1.5-.3-.1-.1 0-.5.1-.8.1-.2.2-.5.2-.7.1-.4 0-.8-.1-1 0-.2-.1-.4 0-.6 0-.1.7-.3.9-.3.4-.1.8-.2 1-.3.4-.2.5-.6.7-1 .1-.3.2-.5.4-.6.3 0 .9.6 1.2 1.1.1.1.2.4.4.7.6.9 1.3 2.2 1.7 2.6.7.4 1.3 1.1 1.1 1.7zm8.3-6.4c-.1.6-.1 2.6-.1 3-.3.3-1.6 1.9-3.5 2.2-.8.1-1.5.2-2.1.2-.6 0-.9 0-1.1-.1l-.9-1.1c.4-.2.7-.6.6-1.3-.1-.6-.8-1.2-1.7-1.9-.7-.6-1.5-1.2-1.8-1.7-.5-1.1-.1-3 .5-4 .3-.5.6-1.3.8-2 .3-.8.5-1.6.9-2 .5-.6 1-1.6 1-2.5.5.4 1.2 1 1.8 1h.3c.5-.1 1.1-.5 1.8-.9.6-.3 1.2-.7 1.5-.8.4.6 3 6.3 3.3 8.2.2 1.4 0 2.6-.1 3.1h-.3c-.7-.1-.9.3-.9.6zm7.2 4.2c-.2.1-.7.4-1.2.7-.9.5-1.9 1-2.4 1.5-.7.7-1.4 1.1-1.8 1.1H22c-.5-.2-.9-.8-.7-1.8.2-1 .5-2.4.4-3.2v-.6c0-.4-.1-.9 0-1.1 0 .5.1 1.4.8 1.7.2.1.5.1.8.1.7 0 1.6-.4 1.9-.7.2-.2.4-.4.5-.6v.3c0 .7.3 1.6.9 1.9h.1c.2.2.8.5.8.7z" />
    </svg>
)

export const MicrosoftIcon = () => (
    <svg x="0px" y="0px" fill="#0a89e8" viewBox="0 0 32 32" xmlSpace="preserve">
        <path d="M30.6 15.2c-5.3 0-10.6.1-15.9.1V3.6c5.3-.8 10.6-1.5 15.9-2.3v13.9zM1.4 5.5v9.9c4 0 8-.1 12 0V3.9c-4.1.5-8.1 1-12 1.6zm0 11.1v10c4 .5 8 1.1 12 1.6V16.6c-4.1.1-8 0-12 0zm13.4 0v11.8c5.3.7 10.6 1.5 15.8 2.2V17c-5.3 0-10.6-.4-15.8-.4z" />
    </svg>
)

export const TokenIcon = () => (
    <svg x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <path d="M30.2 27.1H1.8c-.3 0-.6-.3-.6-.6V12.9c0-.3.3-.6.6-.6h28.4c.3 0 .6.3.6.6v13.6c0 .3-.3.6-.6.6zM2.4 25.9h27.1V13.5H2.4v12.4z" />
        <path d="M22.8 13.5c-.2 0-.3-.1-.5-.2L16 6.4l-6.3 6.9c-.2.3-.6.3-.9 0-.3-.2-.3-.6 0-.9L15.6 5c.2-.3.7-.3.9 0l6.8 7.4c.2.3.2.6 0 .9-.2.2-.4.2-.5.2zM7.4 22.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c-.1 1.2-1 2.2-2.2 2.2zm0-3.1c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM13.2 22.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zm0-3.1c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM19.1 22.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c-.1 1.2-1 2.2-2.2 2.2zm0-3.1c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9z" />
        <g>
            <path d="M24.9 22.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zm0-3.1c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.3-.9-.9-.9z" />
        </g>
    </svg>
)

export const RefreshIcon = () => (
    <svg x="0px" y="0px" fill="#0a89e8" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <g>
                <g>
                    <g>
                        <path
                            d="M4.9,15.8c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.2-0.6-0.5l-2.8-4.8C1,10,1.1,9.4,1.6,9.1C2.1,8.8,2.7,9,3,9.5l2.3,3.9
					l3.6-2.4c0.5-0.3,1.1-0.2,1.4,0.3c0.3,0.5,0.2,1.1-0.3,1.4l-4.5,3C5.3,15.7,5.1,15.8,4.9,15.8z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        d="M5.1,15.2c0,0-0.1,0-0.1,0c-0.5-0.1-0.9-0.6-0.9-1.1C4.9,8,9.8,3.5,16,3.5c5.5,0,10.3,3.5,11.9,8.6
				c0.2,0.5-0.1,1.1-0.7,1.2c-0.5,0.2-1.1-0.1-1.2-0.7c-1.3-4.2-5.4-7.2-10-7.2c-5.2,0-9.2,3.6-9.8,8.8C6.1,14.8,5.6,15.2,5.1,15.2z
				"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            d="M29.9,23c-0.3,0-0.7-0.2-0.9-0.5l-2.3-3.9l-3.6,2.4c-0.5,0.3-1.1,0.2-1.4-0.3c-0.3-0.5-0.2-1.1,0.3-1.4l4.5-3
					c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0.1,0.5,0.2,0.6,0.5l2.8,4.8c0.3,0.5,0.1,1.1-0.4,1.4C30.2,23,30.1,23,29.9,23z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        d="M16,28.5c-5.5,0-10.3-3.5-11.9-8.6c-0.2-0.5,0.1-1.1,0.7-1.2c0.5-0.2,1.1,0.1,1.2,0.7c1.3,4.2,5.4,7.2,10,7.2
				c5.2,0,9.2-3.6,9.8-8.8c0.1-0.5,0.6-0.9,1.1-0.9c0.5,0.1,0.9,0.6,0.9,1.1C27.1,24,22.2,28.5,16,28.5z"
                    />
                </g>
            </g>
        </g>{' '}
    </svg>
)
