import {
  BotDropdown,
  CloseIcon,
  DialogIcon,
  DownArrowCircle,
  SearchIcon,
  UpDownIcon,
} from '@/common/Icons/Icons';
import {
  CountNumber,
  CountWrapper,
  HeaderWrapper,
  Input,
  Wrapper,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import React, { useState } from 'react';
import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header';

import { TableWrapper } from '@/common/styled/Entity.Dumb';
import styled from 'styled-components';
import { BasiceDropdown, DateFilterT } from '@/Assistant/Dialogue/BotDetails';
import BasicDateRangePicker from '@/Assistant/Dialogue/CustomDatePicker';
import { convertFromToDate } from '@/common/utils/utils';
import moment from 'moment';
import { CountryListwithISO, Customdate } from './LiveagentMockFile';
import {
  AgentResponseT,
  getAgentResponse,
  getChatAssignment,
  getChatStatus,
  getLiveAgentRes,
  getliveChatAgents,
  getLiveChatStatus,
  useStatusByloc,
  LiveAgentResponseT,
  StatusbyLocT,
  useAgentList,
} from './LivaAgentApi';
import MapChart, {
  chatAssignmentT,
  ChatStausT,
  DashboardPieChart,
} from './dropDown';
import { TabContainer, TabHeader } from '@/common/styled/Settings.Dumb';
import { Outlet, useParams } from 'react-router-dom';
import { ProfileTabs } from '@/SidePanel/Sidebar';

export const TabRow = styled.div`
  width: 100%;
  float: left;
  position: relative;
  display: flex;

  :after {
    content: '';
    position: absolute;
    border-bottom: 1px solid #e0e0e0;
    width: 21%;
    height: 1px;
    top: 33px;
    left: 2px;
    z-index: 0;

    @media (min-width: 1920px) {
      width: 39%;
    }
    @media (min-width: 2160px) {
      width: 34.5%;
    }
    @media (min-width: 2560px) {
      width: 28.5%;
    }
    @media (min-width: 3480px) {
      width: 20.5%;
    }
    @media (max-width: 1440px) {
      width: 25%;
    }
    @media (max-width: 1280px) {
      width: 60%;
    }
  }
`;

export const Table = styled.table`
  width: 99%;
  float: left;
  left: 6px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const HeaderTabWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 0;
`;

export const CardContainer = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 20px;
  margin-top: 10px;

  ${CountWrapper} {
    width: auto;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000;
    div {
      background: #538fd1;
      width: 32px;
      height: 32px;
      svg {
        fill: #fff;
        width: 18px;
        height: 18px;
      }
    }
  }
  .liveAt_dashboard_card {
    justify-content: flex-start;
    gap: 15px 0;
    .flex {
      justify-content: space-between;
      align-items: center;
      p:first-child {
        color: #355d8b;
        font-size: 12px;
        font-family: 'Gordita-Regular';
      }
      p:last-child {
        color: #333;
        font-size: 12px;
        font-family: 'Gordita-Medium';
      }
    }
  }
`;

export const PieContent = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    white-space: pre;
    display: flex;
    gap: 14px 0;

    div {
        width: auto;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000;
        position: relative;
        margin-left: 20px;
        @media (max-width: 1280px) {
            margin-left: 14px;
        }

        // :nth-child(1){
        //     margin-bottom: 20px;
        //     display: flex;

            .target.customTip{
                position: absolute !important;
                right: 14px;
                cursor: pointer;
                top: 1px;
                @media (min-width: 1920px) {
                    right: 48px;
                }
                @media (max-width: 1440px) {
                    right: -22px;
                }
                @media (max-width: 1370px) {
                    right: -12px;
                }
                
                svg{
                    color: #999999;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        span {
            width: 16px;
            height: 16px;
            float: left;
            background: #fff;
            position: relative;
            border-radius: 50%;
            margin-right: 6px;
        }
        ._dot_1 {
            background: #F2A355;
        }
        ._dot_2 {
            background: #76AEF6;
        }
        ._dot_3 {
            background: #7AE3DC;
        }
        ._dot_4 {
            background: #FF7462;
        }
        ._dot_4.chat_status{
          background: #7AE3DC;
        }
        ._dot_5 {
          background: #76AEF6;
        }
        ._dot_6 {
        background: #7AE3DC;
        }
        ._dot_7 {
      background: #F2A355;
        }
        ._dot_8 {
    background: #FF7462;
}
    }
`;

export const Card = styled.div`
  width: 100%;
  float: left;
  background: #f5f5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 15px 20px;
  position: relative;
  height: 240px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  ${CountNumber} {
    justify-content: flex-start;
    gap: 10px;
  }
`;
export enum PageTab {
  ByChat,
  ByAgent,
  LiveChat,
}
export function HeaderForLiveAgent(props: any) {
  const params = useParams();

  return (
    <>
      <Header>
        <HeaderChild type="first">
          <div className="indent_left">
            <div className="liveAgent_title">
              Live Chat Inbox <span>Beta</span>{' '}
            </div>
          </div>
        </HeaderChild>
        <HeaderChild type="second">
          <>
            <ProfileTabs {...props} />
          </>
          {/* <VideoButtonB id={'READ_CHATBOT_LOGS'} /> */}
        </HeaderChild>
      </Header>
      {params['dialogue_node'] && <Outlet />}
    </>
  );
}
export function LiveAgentSummary(props: any) {
  const workspace = props.match.params.workspacename;
  const initialDates = {
    from_date: null,
    to_date: null,
  };
  const [pageStatus, setPagestatus] = useState<PageTab>(PageTab.ByChat);
  const [dateOptions, setOptions] = useState('last30days');
  const [customDates, setCustomDates] = useState<Customdate>(initialDates);
  const [dateOptionsCon, setOptionsCon] = useState('last30days');
  const [customDatesCon, setCustomDatesCon] =
    useState<Customdate>(initialDates);
  const changeDateFilter = (filter: DateFilterT) => {
    if (filter != 'customrange') {
      console.log(filter, 'customDates');
    }
  };

  const noValueChatAssignment: chatAssignmentT = {
    AssignedCount: 0,
    UnassignedCount: 0,
    AssignedNotReplied: 0,
  };

  const noValueChatStatus: ChatStausT = {
    Resolved: 0,
    Open: 0,
    Queue: 0,
    Closed: 0,
    TotalCount: 0,
  };
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const [searchWord, setSearchWord] = useState<string>('');
  const [showState, setState] = useState({ customDatePicker: 'hide' });
  const [showCountryState, setCountryState] = useState({
    customDatePicker: 'hide',
  });

  const { chatStatus } = getChatStatus(workspace, dateOptions, customDates);
  const { chatAssignment } = getChatAssignment(
    workspace,
    dateOptions,
    customDates
  );
  const { agentResponse } = getAgentResponse(
    workspace,
    dateOptions,
    customDates
  );
  const [sortBy, setSortBy] = useState<string>('');
  const [sorting, setSorting] = useState<string>('');

  const { liveAgentResponse } = getLiveAgentRes(workspace);
  const agentWithSort = liveAgentResponse
    ? sortBy === ''
      ? liveAgentResponse
      : sorting === 'asc'
      ? liveAgentResponse.sort((a, b) => a[sortBy] - b[sortBy])
      : liveAgentResponse.sort((a, b) => b[sortBy] - a[sortBy])
    : [];
  const agentDetails = agentWithSort
    ? searchWord == ''
      ? agentWithSort
      : agentWithSort.filter((res) =>
          res.agent.toLowerCase().includes(searchWord.toLowerCase())
        )
    : [];

  const [statusDropdown, setStatusDropdown] = useState<boolean>(false);

  const statusList = ['All', 'Online', 'Offline'];

  const { liveChatAgents } = getliveChatAgents(workspace);
  const { agentList } = useAgentList(workspace);
  const { liveChatStatus } = getLiveChatStatus(workspace);
  const agentValue = agentResponse
    ? searchWord == ''
      ? agentResponse
      : agentResponse.filter((res) =>
          res.agent.toLowerCase().includes(searchWord.toLowerCase())
        )
    : [];
  const { statusByloc } = useStatusByloc(
    workspace,
    dateOptionsCon,
    customDatesCon
  );

  const getISOName = (name: string) => {
    const split = name.split(',');
    const findCountry = split[split.length - 1];
    console.log(findCountry, 'findCountry');
    const foundCountry = CountryListwithISO.find(
    (list) => list.name === findCountry || list.code === findCountry || list.otherName === findCountry
  );
  return foundCountry?.code;
    };
  const getCountryName = (name: string) => {
    const split = name !== null ? name.split(','): "N/A"
    const coutName = split.length >1 ? split[split.length - 1] : name
    const foundCountry = CountryListwithISO.find(
    (list) => list.name === coutName || list.code === coutName || list.otherName === coutName
  );
  return foundCountry ? foundCountry.name : name;
  };
  const removeNull =
    statusByloc &&
    statusByloc.length > 0 &&
    statusByloc.filter((loc) => loc.Location !== null);

  const locationStatus:StatusbyLocT[] = statusByloc && statusByloc.length > 0 
  ? statusByloc.map((loc) => ({
      ...loc,
      Location: getCountryName(loc.Location)
    }))
  : [];

  const locationArr =
    statusByloc && removeNull && removeNull.length > 0
      ? removeNull.map(({ Location, TotalCount }) => {
          return { country: getISOName(Location), value: TotalCount };
        })
      : [];
  const accumulateValuesByCountry = (locationArr: any) => {
    return Object.values(
      locationArr.reduce((acc, curr) => {
        // If exists, add the value of curr.value to the existing value
        acc[curr.country] = (acc[curr.country] || 0) + curr.value;
        return acc;
      }, {})
    ).map((value, index) => ({
      country: Object.keys(
        locationArr.reduce((acc, curr) => {
          acc[curr.country] = (acc[curr.country] || 0) + curr.value;
          return acc;
        }, {})
      )[index],
      value,
    }));
  };
  const combinedData =(data:StatusbyLocT[]):StatusbyLocT[]=>{

  return data.reduce((acc:StatusbyLocT[], curr:StatusbyLocT) => {
    // Check if the location already exists in the accumulator
    const existing = acc.find(item => item.Location === curr.Location);
    
    if (existing) {
        // Sum the values for the matching location
        existing.Resolved += curr.Resolved;
        existing.Open += curr.Open;
        existing.Queue += curr.Queue;
        existing.Closed += curr.Closed;
        existing.TotalCount += curr.TotalCount;
    } else {
        // If the location doesn't exist, add the current object
        acc.push({ ...curr });
    }
    
    return acc;
}, []);
  }

const locationStatusChart=locationStatus? combinedData(locationStatus) :[]
  console.log(locationStatusChart,"locationStatusChart")

  const result =
    locationArr.length > 0 ? accumulateValuesByCountry(locationArr) : [];

  const arr1Map = new Map(result.map(item => [item.country, item.value]));

const locationAdd = CountryListwithISO.map(item => ({
    country: item.code,
    value: arr1Map.get(item.code) || 0
}));
 
  const Locationwithsort = locationStatusChart && locationStatusChart.length>0
    ? sortBy === ''
      ? locationStatusChart
      : sortBy !== 'Location'
      ? sorting === 'asc'
        ? locationStatusChart.sort((a, b) => a[sortBy] - b[sortBy])
        : locationStatusChart.sort((a, b) => b[sortBy] - a[sortBy])
      : sorting === 'asc'
      ? locationStatusChart.sort((a, b) => a.Location.localeCompare(b.Location))
      : locationStatusChart.sort((a, b) => b.Location.localeCompare(a.Location))
    : [];
  console.log(agentResponse, 'chatStatus');
  return (
    <>
      <HeaderForLiveAgent {...props} />
      {/* <div className="liveAgent_title_section">
        <h2 className="liveAgent_title">Live Chat Inbox <span>Beta</span></h2>
      </div> */}
      <TabContainer className="liveAgent-menu comman">
        <TabRow className="liveAgent-menu-row">
          <TabHeader
            onClick={() =>
              props.history.push(
                `/workspace/${workspace}/live-agent/Inbox/all/conversations`
              )
            }
          >
            Inbox
            <span
            // onClick={() => {
            //   setBotdropdown(true);
            // }}
            // className={tab === 'Bot' ? 'activesvg' : ''}
            >
              <BotDropdown />
            </span>
            {/* {botdropdown ? (
              <Downshift
                isOpen={botdropdown}
                onOuterClick={() => setBotdropdown(false)}
              >
                {() => (
                  <div className="flow_sort_by dialog_dp">
                    <ul className="flow_sort_dropdown">
                      {botList.map((data) => (
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            botSelect(data);
                            setBotdropdown(false);
                          }}
                          className={
                            data.name === selectedBot.name
                              ? 'liveAt_submenu_active'
                              : ''
                          }
                        >
                          {data.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Downshift>
            ) : null} */}
          </TabHeader>
          <TabHeader
            className="active"
            // onClick={() => {
            //   setTab('DashBoard');
            // }}
          >
            Dashboard
          </TabHeader>
          <TabHeader
            // className={tab === 'Chat History' ? 'active' : ''}
            onClick={() => {
              props.history.push(
                `/workspace/${workspace}/live-agent/chat-history`
              );
            }}
          >
            Chat History
          </TabHeader>
        </TabRow>
      </TabContainer>
      <TabContainer className="liveAgent-subMenu dashoboard">
        <TabRow className="liveAgent-subMenu-row dashboard_submenu">
          <TabHeader
            className={pageStatus === PageTab.ByChat ? 'active' : ''}
            onClick={() => {
              setOptions('last30days');
              setPagestatus(PageTab.ByChat);
            }}
          >
            By Chat
          </TabHeader>
          <TabHeader
            className={pageStatus === PageTab.ByAgent ? 'active' : ''}
            onClick={() => {
              setOptions('last30days');
              setPagestatus(PageTab.ByAgent);
            }}
          >
            By Agent
          </TabHeader>
          <TabHeader
            className={pageStatus === PageTab.LiveChat ? 'active' : ''}
            onClick={() => {
              setOptions('last30days');
              setPagestatus(PageTab.LiveChat);
            }}
          >
            Ongoing Chats
          </TabHeader>
        </TabRow>
        {(pageStatus === PageTab.ByChat || pageStatus === PageTab.ByAgent) && (
          <div className="tab_container_right">
            <BasiceDropdown
              dateOptions={dateOptions}
              changeDateFilter={changeDateFilter}
              setOptions={setOptions}
              showCustomDatePicker={() => {
                setState({ customDatePicker: 'show' });
              }}
            />
            {showState.customDatePicker == 'show' && (
              <div className="tab_dropdown_date dialog_logs">
                <div className="tab_calender">
                  <BasicDateRangePicker
                    setDate={(date: Date[]) => {
                      const customDates = {
                        from_date: convertFromToDate(date[0].toString()) as any,
                        to_date: convertFromToDate(date[1].toString()) as any,
                      };
                      setCustomDates(customDates);
                      // changeToCustomDateFilter(customDates)
                      setState({
                        customDatePicker: 'hide',
                      });
                    }}
                    show={showState.customDatePicker == 'show'}
                    minDate={
                      new Date(
                        moment().add(1, 'day').subtract(1, 'year').toString()
                      )
                    }
                    customDates={customDates}
                    closeCalendar={() => {
                      setState({
                        customDatePicker: 'hide',
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </TabContainer>

      <Wrapper style={{ padding: 0, height: 'calc(100vh - 240px)' }}>
        <HeaderWrapper
          style={{
            margin: '0px 20px 20px 20px',
            width: '97.3%',
            padding: '10px 20px 20px',
          }}
        >
          {pageStatus === PageTab.ByChat && (
            <>
              <CardContainer className="dashboard_page">
                <Card className="liveAt_dashboard_card">
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 32 32"
                        >
                          <g>
                            <g>
                              <path
                                d="M10.6,29c-0.1,0-0.3,0-0.4-0.1c-0.3-0.1-0.5-0.5-0.5-0.8v-4.6c-5.1-0.7-9-5-9-10.3C0.7,7.6,5.3,3,11.1,3H21
                            c5.7,0,10.3,4.6,10.3,10.4S26.7,23.6,21,23.6h-3.8l-5.9,5.2C11,29,10.8,29,10.6,29z M11.5,23v3.2l4.7-4.1c0.2-0.1,0.4-0.2,0.6-0.2
                            H21c4.7,0,8.5-3.8,8.5-8.6S25.7,4.8,21,4.8h-9.9c-4.7,0-8.6,3.8-8.6,8.6c0,4.5,3.5,8.3,8.1,8.5c0.9,0.1,1,0.9,1,0.9
                            C11.5,22.8,11.5,22.9,11.5,23z"
                              />
                            </g>
                            <circle cx="9.8" cy="13.3" r="2.2" />
                            <circle cx="16.5" cy="13.3" r="2.2" />
                            <circle cx="23.1" cy="13.3" r="2.2" />
                          </g>
                        </svg>
                      </div>
                    </CountWrapper>
                    <CountWrapper>Chat status</CountWrapper>
                  </CountNumber>
                  <div
                    className={
                      chatStatus
                        ? 'pie_chart_userQueries '
                        : 'pie_chart_userQueries no_data'
                    }
                  >
                    <DashboardPieChart
                      querySummary={chatStatus ? chatStatus : noValueChatStatus}
                      keys={['Resolved', 'Open', 'Queue', 'Closed']}
                    />
                    <div className="pie_content_container">
                      <PieContent>
                        <div>
                          <span className="_dot_1"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Waiting in queue</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Queue
                                  ? chatStatus.Queue
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="_dot_2"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Open</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Open
                                  ? chatStatus.Open
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <span className="_dot_3"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Resolved</p>
                            </div>
                            <div>
                              <p>{chatStatus && chatStatus.Resolved}</p>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <span className="_dot_4 chat_status"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Closed</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Closed
                                  ? chatStatus.Closed
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </PieContent>
                    </div>
                  </div>
                </Card>
                <Card className="liveAt_dashboard_card">
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 32 32"
                        >
                          <g>
                            <g>
                              <path
                                d="M10.6,29c-0.1,0-0.3,0-0.4-0.1c-0.3-0.1-0.5-0.5-0.5-0.8v-4.6c-5.1-0.7-9-5-9-10.3C0.7,7.6,5.3,3,11.1,3H21
                            c5.7,0,10.3,4.6,10.3,10.4S26.7,23.6,21,23.6h-3.8l-5.9,5.2C11,29,10.8,29,10.6,29z M11.5,23v3.2l4.7-4.1c0.2-0.1,0.4-0.2,0.6-0.2
                            H21c4.7,0,8.5-3.8,8.5-8.6S25.7,4.8,21,4.8h-9.9c-4.7,0-8.6,3.8-8.6,8.6c0,4.5,3.5,8.3,8.1,8.5c0.9,0.1,1,0.9,1,0.9
                            C11.5,22.8,11.5,22.9,11.5,23z"
                              />
                            </g>
                            <circle cx="9.8" cy="13.3" r="2.2" />
                            <circle cx="16.5" cy="13.3" r="2.2" />
                            <circle cx="23.1" cy="13.3" r="2.2" />
                          </g>
                        </svg>
                      </div>
                    </CountWrapper>
                    <CountWrapper>Chat assignment status</CountWrapper>
                    <CountWrapper
                      style={{ position: 'absolute', right: '20px' }}
                    >
                      <p>Total</p>
                      <span className="chat_count">
                        {chatAssignment && chatAssignment.AssignedCount + chatAssignment.AssignedNotReplied +chatAssignment.UnassignedCount}
                      </span>
                    </CountWrapper>
                  </CountNumber>
                  <div
                    className={
                      chatStatus
                        ? 'pie_chart_userQueries'
                        : 'pie_chart_userQueries no_data'
                    }
                  >
                    <DashboardPieChart
                      querySummary={
                        chatAssignment ? chatAssignment : noValueChatAssignment
                      }
                      keys={[
                        'AssignedCount',
                        'UnassignedCount',
                        'AssignedNotReplied',
                      ]}
                    />
                    <div className="pie_content_container">
                      <PieContent>
                        <div>
                          <span className="_dot_5"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Assigned chats</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment && chatAssignment.AssignedCount
                                  ? chatAssignment.AssignedCount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="_dot_6"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Assigned and not replied</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment &&
                                chatAssignment.AssignedNotReplied
                                  ? chatAssignment.AssignedNotReplied
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <span className="_dot_7"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Overdue</p>
                            </div>
                            <div>
                              <p>0</p>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <span className="_dot_8"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Unassigned</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment &&
                                chatAssignment.UnassignedCount
                                  ? chatAssignment.UnassignedCount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </PieContent>
                    </div>
                  </div>
                </Card>
                {/* <Card className="liveAt_dashboard_card">
                <CountNumber>
                  <CountWrapper className="count_wrapper_icon">
                    <div>
                      <DialogIcon />
                    </div>
                  </CountWrapper>
                  <CountWrapper>Chat SLA</CountWrapper>
                </CountNumber>
                <div className="flex">
                  <p>SLA compliant chats</p>
                  <p>246</p>
                </div>
              </Card> */}
              </CardContainer>

              <div className="tab_section_wrapper">
                <div className="tab_section_header">
                  <div>
                    <h5>Chat from</h5>
                    <p>Total chats by country</p>
                  </div>
                  <div className="tab_container_right">
                    <BasiceDropdown
                      dateOptions={dateOptionsCon}
                      changeDateFilter={changeDateFilter}
                      setOptions={setOptionsCon}
                      showCustomDatePicker={() => {
                        setCountryState({ customDatePicker: 'show' });
                      }}
                    />
                    {showCountryState.customDatePicker == 'show' && (
                      <div className="tab_dropdown_date dialog_logs">
                        <div className="tab_calender">
                          <BasicDateRangePicker
                            setDate={(date: Date[]) => {
                              const customDates = {
                                from_date: convertFromToDate(
                                  date[0].toString()
                                ) as any,
                                to_date: convertFromToDate(
                                  date[1].toString()
                                ) as any,
                              };
                              setCustomDatesCon(customDates);
                              setState({
                                customDatePicker: 'hide',
                              });
                            }}
                            show={showCountryState.customDatePicker == 'show'}
                            minDate={
                              new Date(
                                moment()
                                  .add(1, 'day')
                                  .subtract(1, 'year')
                                  .toString()
                              )
                            }
                            customDates={customDatesCon}
                            closeCalendar={() => {
                              setState({
                                customDatePicker: 'hide',
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tab_section_graph" style={{ margin: 0 }}>
                  <MapChart data={locationAdd} />
                </div>
              </div>
              <TableWrapper className="dashboard_page" style={{ marginTop: 5 }}>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th size={3.5}>
                          {/* <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('Location');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('Location');
                            }}
                          >
                            <UpDownIcon />
                          </span> */}
                          <div className="th_label">Chat from</div>
                        </Th>
                        <Th size={2}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('TotalCount');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('TotalCount');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Chats</div>
                        </Th>
                        <Th size={2}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('Queue');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('Queue');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Waiting on queue</div>
                        </Th>
                        <Th size={2} style={{ paddingLeft: 18 }}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('Open');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('Open');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Open</div>
                        </Th>
                        {/* <Th size={1.5}>
                          <span
                            className="liveAt_logs_filter"
                            onClick={() => setSortBy('Resolved')}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Resolved</div>
                        </Th> */}
                        <Th size={2}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('Closed');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('Closed');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Closed Chats</div>
                        </Th>
                        <Th size={0.5}></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Locationwithsort.length > 0 ? (
                        Locationwithsort.map((status: StatusbyLocT) => (
                          <Tr>
                            <Td size="3.5">
                              <div className="td_header">
                                {status.Location === null
                                  ? 'N/A'
                                  : status.Location}
                              </div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">
                                {status.TotalCount}
                              </div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">{status.Queue}</div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">{status.Open}</div>
                            </Td>
                            {/* <Td size="1.5">
                              <div className="td_header">{status.Resolved}</div>
                            </Td> */}
                            <Td size="2">
                              <div className="td_header">{status.Closed}</div>
                            </Td>
                            <Td size="0.5"></Td>
                          </Tr>
                        ))
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            </>
          )}
          {pageStatus === PageTab.ByAgent && (
            <div>
              <CardContainer className="dashboard_page">
                <Card className="liveAt_dashboard_card">
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 32 32"
                        >
                          <g>
                            <g>
                              <path
                                d="M10.6,29c-0.1,0-0.3,0-0.4-0.1c-0.3-0.1-0.5-0.5-0.5-0.8v-4.6c-5.1-0.7-9-5-9-10.3C0.7,7.6,5.3,3,11.1,3H21
                            c5.7,0,10.3,4.6,10.3,10.4S26.7,23.6,21,23.6h-3.8l-5.9,5.2C11,29,10.8,29,10.6,29z M11.5,23v3.2l4.7-4.1c0.2-0.1,0.4-0.2,0.6-0.2
                            H21c4.7,0,8.5-3.8,8.5-8.6S25.7,4.8,21,4.8h-9.9c-4.7,0-8.6,3.8-8.6,8.6c0,4.5,3.5,8.3,8.1,8.5c0.9,0.1,1,0.9,1,0.9
                            C11.5,22.8,11.5,22.9,11.5,23z"
                              />
                            </g>
                            <circle cx="9.8" cy="13.3" r="2.2" />
                            <circle cx="16.5" cy="13.3" r="2.2" />
                            <circle cx="23.1" cy="13.3" r="2.2" />
                          </g>
                        </svg>
                      </div>
                    </CountWrapper>
                    <CountWrapper>Chat status</CountWrapper>
                  </CountNumber>
                  <div
                    className={
                      chatStatus
                        ? 'pie_chart_userQueries'
                        : 'pie_chart_userQueries no_data'
                    }
                  >
                    <DashboardPieChart
                      querySummary={chatStatus ? chatStatus : noValueChatStatus}
                      keys={['Resolved', 'Open', 'Queue', 'Closed']}
                    />
                    <div className="pie_content_container">
                      <PieContent>
                        <div>
                          <span className="_dot_1"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Waiting in queue</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Queue
                                  ? chatStatus.Queue
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="_dot_2"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Open</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Open
                                  ? chatStatus.Open
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <span className="_dot_3"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Resolved</p>
                            </div>
                            <div>
                              <p>{chatStatus && chatStatus.Resolved}</p>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <span className="_dot_4 chat_status"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Closed</p>
                            </div>
                            <div>
                              <p>
                                {chatStatus && chatStatus.Closed
                                  ? chatStatus.Closed
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </PieContent>
                    </div>
                  </div>
                </Card>
                <Card className="liveAt_dashboard_card">
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 32 32"
                        >
                          <g>
                            <g>
                              <path
                                d="M10.6,29c-0.1,0-0.3,0-0.4-0.1c-0.3-0.1-0.5-0.5-0.5-0.8v-4.6c-5.1-0.7-9-5-9-10.3C0.7,7.6,5.3,3,11.1,3H21
                            c5.7,0,10.3,4.6,10.3,10.4S26.7,23.6,21,23.6h-3.8l-5.9,5.2C11,29,10.8,29,10.6,29z M11.5,23v3.2l4.7-4.1c0.2-0.1,0.4-0.2,0.6-0.2
                            H21c4.7,0,8.5-3.8,8.5-8.6S25.7,4.8,21,4.8h-9.9c-4.7,0-8.6,3.8-8.6,8.6c0,4.5,3.5,8.3,8.1,8.5c0.9,0.1,1,0.9,1,0.9
                            C11.5,22.8,11.5,22.9,11.5,23z"
                              />
                            </g>
                            <circle cx="9.8" cy="13.3" r="2.2" />
                            <circle cx="16.5" cy="13.3" r="2.2" />
                            <circle cx="23.1" cy="13.3" r="2.2" />
                          </g>
                        </svg>
                      </div>
                    </CountWrapper>
                    <CountWrapper>Chat assignment status</CountWrapper>
                    <CountWrapper
                      style={{ position: 'absolute', right: '20px' }}
                    >
                      <p>Total</p>
                      <span className="chat_count">
                        {chatAssignment && chatAssignment.AssignedCount + chatAssignment.AssignedNotReplied +chatAssignment.UnassignedCount}
                      </span>
                    </CountWrapper>
                  </CountNumber>
                  <div
                    className={
                      chatStatus
                        ? 'pie_chart_userQueries'
                        : 'pie_chart_userQueries no_data'
                    }
                  >
                    <DashboardPieChart
                      querySummary={
                        chatAssignment ? chatAssignment : noValueChatAssignment
                      }
                      keys={[
                        'AssignedCount',
                        'UnassignedCount',
                        'AssignedNotReplied',
                      ]}
                    />
                    <div className="pie_content_container">
                      <PieContent>
                        <div>
                          <span className="_dot_5"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Assigned chats</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment && chatAssignment.AssignedCount
                                  ? chatAssignment.AssignedCount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="_dot_6"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Assigned and not replied</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment &&
                                chatAssignment.AssignedNotReplied
                                  ? chatAssignment.AssignedNotReplied
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <span className="_dot_7"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Overdue</p>
                            </div>
                            <div>
                              <p>0</p>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <span className="_dot_8"></span>
                          <div className="pie_content_box">
                            <div>
                              <p>Unassigned</p>
                            </div>
                            <div>
                              <p>
                                {chatAssignment &&
                                chatAssignment.UnassignedCount
                                  ? chatAssignment.UnassignedCount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </PieContent>
                    </div>
                  </div>
                </Card>
                {/* <Card>
                <CountNumber>
                  <CountWrapper className="count_wrapper_icon">
                    <div>
                      <DialogIcon />
                    </div>
                  </CountWrapper>
                  <CountWrapper>Chat SLA</CountWrapper>
                </CountNumber>
              </Card> */}
              </CardContainer>

              <div className="dashboard_search_box">
                {!inputFocus ? (
                  <span className="search_icon_svg">
                    <input
                      type="search"
                      placeholder="Search agent"
                      value=""
                      onFocus={() => setInputFocus(true)}
                    />
                    <SearchIcon />
                  </span>
                ) : (
                  <span className="search_click">
                    <input
                      className="search_click"
                      type="search"
                      placeholder="Search"
                      value={searchWord}
                      autoFocus={true}
                      onChange={(e) => setSearchWord(e.target.value)}
                    />
                    <span
                      onClick={() => {
                        setSearchWord('');
                        setInputFocus(false);
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </span>
                )}
              </div>

              <TableWrapper className="dashboard_page" style={{ marginTop: 0 }}>
                <TableContainer>
                  <Table style={{ marginTop: 5 }}>
                    <Thead>
                      <Tr>
                        <Th size={4}>
                          <div className="th_label">Agent email</div>
                        </Th>
                        <Th size={2}>
                          <div className="th_label">First response time</div>
                        </Th>
                        <Th size={2}>
                          <div className="th_label">Resolution time</div>
                        </Th>
                        {/* <Th size={1.5}>
                          <div className="th_label">Avg. CSAT</div>
                        </Th> */}
                        <Th size={2}>
                          <div className="th_label">Resolved chats</div>
                        </Th>
                        <Th size={2}>
                          <div className="th_label">Chat assigned</div>
                        </Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {agentValue && agentValue.length > 0 ? (
                        agentValue.map((agent: AgentResponseT) => (
                          <Tr>
                            <Td size="4">
                              <div className="td_header">{agent.agent}</div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">
                                {agent.avgFirstResponse}
                              </div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">
                                {agent.avgResolution}
                              </div>
                            </Td>
                            {/* <Td size="1.5">
                              <div className="td_header">
                                {agent.satisfiedPercentage}
                              </div> 
                            </Td>*/}
                            <Td size="2">
                              <div className="td_header">
                                {agent.closedCount}
                              </div>
                            </Td>
                            <Td size="2">
                              <div className="td_header">
                                {agent.assignedCount}
                              </div>
                            </Td>
                            <Td></Td>
                          </Tr>
                        ))
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            </div>
          )}
          {pageStatus === PageTab.LiveChat && (
            <div>
              <CardContainer>
                <Card style={{ height: '180px' }}>
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <DialogIcon />
                      </div>
                    </CountWrapper>
                    <CountWrapper>Agents</CountWrapper>
                  </CountNumber>
                  <div className="liveAT_ongoing_card">
                    <div className="live_chat_status flex">
                      <p>Total</p>
                      <p>
                        {agentList &&
                        agentList.length > 0 ?
                        agentList.length
                          : 0}
                      </p>
                    </div>
                    <div className="live_chat_status flex">
                      <p>Online</p>
                      <p>
                        {agentList &&
                        agentList.length > 0 ?
                        agentList.filter(ag=>ag.agent_status === "Online").length
                          : 0}
                      </p>
                    </div>
                    <div className="live_chat_status flex">
                      <p>Chatting with users</p>
                      <p>
                        {liveChatAgents &&
                        Object.keys(liveChatAgents).length > 0 &&
                        liveChatAgents.ChattingWithUser
                          ? liveChatAgents.ChattingWithUser
                          : 0}
                      </p>
                    </div>
                  </div>
                </Card>
                <Card style={{ height: '180px' }}>
                  <CountNumber>
                    <CountWrapper className="count_wrapper_icon">
                      <div>
                        <DialogIcon />
                      </div>
                    </CountWrapper>
                    <CountWrapper>Chat status</CountWrapper>
                  </CountNumber>
                  <div className="liveAT_ongoing_card">
                    <div className="live_chat_status flex">
                      <p>Waiting in queue</p>
                      <p>
                        {liveChatStatus &&
                        Object.keys(liveChatStatus).length > 0 &&
                        liveChatStatus.WaitingInQueue
                          ? liveChatStatus.WaitingInQueue
                          : 0}
                      </p>
                    </div>
                    <div className="live_chat_status flex">
                      <p>Open chats</p>
                      <p>
                        {liveChatStatus &&
                        Object.keys(liveChatStatus).length > 0 &&
                        liveChatStatus.Open
                          ? liveChatStatus.Open
                          : 0}
                      </p>
                    </div>
                    <div className="live_chat_status flex">
                      <p>Awaiting agent response</p>
                      <p>
                        {liveChatStatus &&
                        Object.keys(liveChatStatus).length > 0 &&
                        liveChatStatus.AwaitingAgentResponse
                          ? liveChatStatus.AwaitingAgentResponse
                          : 0}
                      </p>
                    </div>
                  </div>
                </Card>
              </CardContainer>
              <div className="dashboard_search_box test">
                {!inputFocus ? (
                  <span className="search_icon_svg">
                    <input
                      type="search"
                      placeholder="Search agent"
                      value=""
                      onFocus={() => setInputFocus(true)}
                    />
                    <SearchIcon />
                  </span>
                ) : (
                  <span className="search_click">
                    <input
                      className="search_click"
                      type="search"
                      placeholder="Search"
                      value={searchWord}
                      autoFocus={true}
                      onChange={(e) => setSearchWord(e.target.value)}
                    />
                    <span
                      onClick={() => {
                        setSearchWord('');
                        setInputFocus(false);
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </span>
                )}
              </div>
              <TableWrapper className="dashboard_page" style={{ marginTop: 0 }}>
                <TableContainer>
                  <Table style={{ marginTop: 5 }}>
                    <Thead>
                      <Tr>
                        <Th size={4.5}>
                          {/* <span className="liveAt_logs_filter">
                            <UpDownIcon />
                          </span> */}
                          <div className="th_label">Agent email</div>
                        </Th>
                        {/* <Th size={1.5}>
                          {/* <span className="liveAt_logs_filter">
                            <UpDownIcon />
                          </span> */}
                        {/*<div className="th_label">Status</div>
                        </Th> */}
                        <Th size={2.5}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('openChats');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('openChats');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Open chats</div>
                        </Th>
                        <Th size={2.5}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('avgFirstResponse');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('avgFirstResponse');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">First response time</div>
                        </Th>
                        <Th size={2.5}>
                          <span
                            className={
                              sorting === '' || sorting === 'des'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('asc');
                              setSortBy('avgResolution');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <span
                            className={
                              sorting === 'asc'
                                ? 'liveAt_logs_filter'
                                : 'liveAt_logs_filter hide'
                            }
                            onClick={() => {
                              setSorting('des');
                              setSortBy('avgResolution');
                            }}
                          >
                            <UpDownIcon />
                          </span>
                          <div className="th_label">Avg. Resolution time</div>
                        </Th>
                        {/* <Th size={3}>
                          {/* <span className="liveAt_logs_filter">
                            <UpDownIcon />
                          </span> */}
                        {/* <div className="th_label">Last login</div>
                        </Th> */}
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {agentDetails && agentDetails.length > 0 ? (
                        agentDetails.map(
                          (resp: LiveAgentResponseT, index: number) => (
                            <Tr>
                              <Td size="4.5">
                                <div className="td_header">{resp.agent}</div>
                              </Td>
                              {/* <Td size="1.5">
                                <div className="td_header">online</div>
                              </Td> */}
                              <Td size="2.5">
                                <div className="td_header">
                                  {resp.openChats}
                                </div>
                              </Td>
                              <Td size="2.5">
                                <div className="td_header">
                                  {resp.avgFirstResponse}
                                </div>
                              </Td>
                              <Td size="2.5">
                                <div className="td_header">
                                  {resp.avgResolution}
                                </div>
                              </Td>
                              {/* <Td size="3">
                                <div className="td_header">2days Ago</div>
                              </Td> */}
                              <Td></Td>
                            </Tr>
                          )
                        )
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            </div>
          )}
        </HeaderWrapper>
      </Wrapper>
    </>
  );
}
