import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useState } from 'react'

import { DownArrowCircle } from '@/common/Icons/Icons'

import { HowItWorks } from './HowItWorks'
// import { TriggerPanel } from './TriggerPanel'
// import { ActionPanel } from './ActionPanel'
import { SidePanelContext } from './MarketPlaceFlowCanvas'
import { useDropDown } from './Tree'
import { Action, CanvasRightPanelT, Nodes, RightPanelStateT } from './types'

// import { ConditionPanel } from './ConditionPopup'
// import { EndPanel } from './EndPanel'

export const MarketPlaceRightPanel: CanvasRightPanelT = {
    Component: null,
    ToggleArrow: null,
    AppsDropDown: null,
    StepHeader: null,
    Step: Action
}

MarketPlaceRightPanel.Component = (props: any) => {
    const { rightPanelState, setRightPanelState, treeData } = useContext(SidePanelContext)

    return (
        <React.Fragment>
            {rightPanelState.currentNode.nodeType === Nodes.HowItWorks && rightPanelState.show && (
                <HowItWorks
                    {...props}
                    treeData={treeData}
                    rightPanelState={rightPanelState}
                    setRightPanelState={setRightPanelState}
                />
            )}

            {/* {rightPanelState.currentNode.nodeType === Nodes.Trigger && rightPanelState.show && (
                <TriggerPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Action && rightPanelState.show && (
                <ActionPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.Condition && rightPanelState.show && (
                <ConditionPanel {...props} />
            )}
            {rightPanelState.currentNode.nodeType === Nodes.End && rightPanelState.show && <EndPanel {...props} />} */}
        </React.Fragment>
    )
}

MarketPlaceRightPanel.ToggleArrow = (props: {
    showDropDown: RightPanelStateT
    showOrHidePanel: (state: RightPanelStateT) => void
}) => {
    const { showOrHidePanel } = props

    return (
        <div
            className={!props.showDropDown.show ? 'closed_menu_arrow_build_close' : 'arrow_build_close'}
            onClick={() =>
                showOrHidePanel({
                    ...props.showDropDown,
                    show: !props.showDropDown.show
                })
            }
        >
            <DownArrowCircle />
        </div>
    )
}

MarketPlaceRightPanel.StepHeader = (props: { title: string; description: string }) => (
    <div className="automation_form_header">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
    </div>
)

MarketPlaceRightPanel.AppsDropDown = (props: {
    inputs: { inputs: any[]; key: string; idKey: string; showIcon: boolean }
    addDropDown: boolean
    render: (makeDropDownVisible: any) => void
    updateSelection: (selected: string, id: string) => void
    showVersion?: boolean
    closeCallBack: () => void
}) => {
    const { addDropDown, inputs, updateSelection, showVersion, closeCallBack } = props

    const [showDropDown, makeDropDownVisible, makeDropDownHidden] = useDropDown()
    console.log('inpyts', inputs)

    function onClose() {
        makeDropDownHidden()
        closeCallBack && closeCallBack()
    }

    return (
        <React.Fragment>
            {props.render(makeDropDownVisible)}
            {addDropDown && showDropDown ? (
                <Downshift isOpen={showDropDown} onOuterClick={() => onClose()}>
                    {() => (
                        <div className="automation_scroly">
                            <ul>
                                {inputs.inputs && inputs.inputs.length > 0 ? (
                                    inputs.inputs.map((ele, i: number) => (
                                        <React.Fragment key={i}>
                                            <li
                                                onClick={() => {
                                                    onClose()
                                                    updateSelection(ele[inputs.key], ele[inputs.idKey])
                                                }}
                                            >
                                                {ele.value && ele.value.length > 0 && ele.value[0].icon ? (
                                                    <div className="dropdown_app_icon">
                                                        <img src={ele.value[0].icon} />
                                                    </div>
                                                ) : (
                                                    inputs.showIcon && (
                                                        <div
                                                            className="dropdown_app_icon"
                                                            style={{ visibility: 'hidden' }}
                                                        />
                                                    )
                                                )}
                                                <div className="application_dropdown_content">{ele[inputs.key]}</div>
                                                {showVersion && (
                                                    <span className="application_dropdown_version">
                                                        Version :{' '}
                                                        {parseInt(ele.version.split('v')[1]) < 10
                                                            ? '0' + ele.version.split('v')[1]
                                                            : ele.version.split('v')[1]}
                                                    </span>
                                                )}
                                            </li>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <li className="no_data_found_dropdown_li">No data available</li>
                                )}
                            </ul>
                        </div>
                    )}
                </Downshift>
            ) : null}
        </React.Fragment>
    )
}
