import { DialogCardIcon, ExternalKBHomeIcon, WebsiteKBSideIcon } from '@/common/Icons/Icons';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { stripeGetApi } from '@/common/utils/api-utils';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { KnowledgeExternalKB } from './ExternalKB';
import { useKBCategory } from './KnowledgeApis';
import { KnowledgeAIUpgrade } from './KnowledgeUpgrade';
import { KnowledgeBase, Output } from './MockFiles';
import { Header, HeaderChild } from '@/common/components/Header';
import { KnowledgeAiHome } from './KnowledgeHome';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';

export function KnowledgeHome(props: any) {
  const workspace = props.match.params.workspacename;
  const { kbCategorydata } = useKBCategory(workspace);

  const [external, setExternal] = useState<Output[]>([]);
  const [internal, setInternal] = useState<Output[]>([]);
  const [website, setWebsite] = useState<Output[]>([]);

  useEffect(() => {
    if (kbCategorydata && kbCategorydata.length > 0) {
      setExternal(kbCategorydata.filter((kb) => kb.group === 'EXTERNAL'));
      setInternal(kbCategorydata.filter((kb) => kb.group === 'Internal'));
      setWebsite(kbCategorydata.filter((kb) => kb.group === 'WEBSITE'));
    }
  }, [kbCategorydata]);
  console.log(kbCategorydata,external,website,"checlskhfalk")
  const checkingPlanAccess = useQuery(
    'users::knowledge-access',
    () => stripeGetApi(`${workspace}/info/KB_ACCESS`),
    {
      onSuccess: (response) => {
        //console.log(response,'response')
      },
      staleTime: 0,
      refetchOnMount: true,
    }
  );
  const planAllowed =
    checkingPlanAccess.isSuccess && checkingPlanAccess.data.output == 'yes';
  return (
    <>
      <Header>
        {[
          <HeaderChild type="first">
            <div className="indent_left">
              <div className="indent_title">Home</div>
              {/* <p className="content_header">
                  Deploy your chatbot on your chat channels.{' '}
                </p> */}
              {/* <div className="">Select chat channel to integrate assistant</div> */}
            </div>
            {/* <section className="intr_homepage">
                                <div className="mt-3">
                                    <h6>Chat Channel</h6>
                                    <p>Select chat channel to integrate chatbot</p>
                                </div>
                            </section> */}
          </HeaderChild>,
        ]}
      </Header>
      <section className="entity_cards knowledgeHome">
        {!planAllowed ? (
          <KnowledgeAIUpgrade {...props} />
        ) : (
          <div className="col-md-12">
            {kbCategorydata && kbCategorydata.length > 0 && (website[0]?.knowledgeBase.length > 0 || external[0]?.knowledgeBase.length > 0 ||internal[0]?.knowledgeBase.length > 0) ? (
              <div className="row card_grid_view">
                {website && website[0]?.knowledgeBase.length > 0 && (
                  <div className="col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer"
                  onClick={() =>
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/knowledge/website-kb`
                    )
                  }>
                    <div className="dialog_icon">
                      <span>
                        <WebsiteKBSideIcon />
                      </span>
                    </div>
                    <div className="search_word_highligter">
                      <div className="d_card_wrapper ">
                        <div className="d_card_right">
                          <div className="search-card-title d-inline searching_dialog_title">
                            <h3 style={{ paddingTop: 0 }}>Website KB</h3>
                          </div>
                          <p>
                            Items{' '}
                            <span>(
                              {website[0]?.knowledgeBase.length == 0
                                ? '0'
                                : website[0]?.knowledgeBase.length < 10
                                ? '0' + website[0]?.knowledgeBase.length
                                : website[0]?.knowledgeBase.length}
                              )</span>
                          </p>
                        </div>
                      </div>
                      <div className="error_icon_wrapper">
                        <div className="error_icon_wrapper_left">
                        <span className="entity_span">
                            {website.length > 0 &&
                            website[0]?.knowledgeBase.length > 0
                              ? formatDateInCard(
                                website[0]?.knowledgeBase[0].updatedAt
                                )
                              : '--'}
                          </span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">
                            {website.length > 0 &&
                            website[0]?.knowledgeBase.length > 0
                              ? formatAMPM(
                                website[0]?.knowledgeBase[0].updatedAt
                                )
                              : '--'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {external && external[0]?.knowledgeBase.length > 0 && (
                  <div
                    className="col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer"
                    onClick={() =>
                      props.history.push(
                        `/workspace/${props.match.params.workspacename}/knowledge/external-kb`
                      )
                    }
                  >
                    <div className="dialog_icon">
                      <span>
                        <ExternalKBHomeIcon />
                      </span>
                    </div>
                    <div className="search_word_highligter">
                      <div className="d_card_wrapper ">
                        <div className="d_card_right">
                          <div className="search-card-title d-inline searching_dialog_title">
                            <h3 style={{ paddingTop: 0 }}>External KB</h3>
                          </div>
                          <p>
                            Items{' '}
                            <span>
                              (
                              {external[0]?.knowledgeBase.length == 0
                                ? '0'
                                : external[0]?.knowledgeBase.length < 10
                                ? '0' + external[0]?.knowledgeBase.length
                                : external[0]?.knowledgeBase.length}
                              )
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="error_icon_wrapper">
                        <div className="error_icon_wrapper_left">
                          <span className="entity_span">
                            {external.length > 0 &&
                            external[0]?.knowledgeBase.length > 0
                              ? formatDateInCard(
                                  external[0]?.knowledgeBase[0].updatedAt
                                )
                              : '--'}
                          </span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">
                            {external.length > 0 &&
                            external[0]?.knowledgeBase.length > 0
                              ? formatAMPM(
                                  external[0]?.knowledgeBase[0].updatedAt
                                )
                              : '--'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {internal && internal[0]?.knowledgeBase.length > 0 && (
                  <div className="col-lg-2 col-md-2 col-sm-6 col-12  dialog_cards_layer">
                    <div className="dialog_icon">
                      <span>
                        <DialogCardIcon />
                      </span>
                    </div>
                    <div className="search_word_highligter">
                      <div className="d_card_wrapper ">
                        <div className="d_card_right">
                          <div className="search-card-title d-inline searching_dialog_title">
                            <h3 style={{ paddingTop: 0 }}>Workativ KB</h3>
                          </div>
                          <p>
                            Items <span>(03)</span>
                          </p>
                        </div>
                      </div>
                      <div className="error_icon_wrapper">
                        <div className="error_icon_wrapper_left">
                          <span className="entity_span">28 Aug 2020</span>
                          <span className="entity_span">|</span>
                          <span className="entity_span">5:44PM</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <KnowledgeAiHome props={props} />
            )}
          </div>
        )}
      </section>
    </>
  );
}
