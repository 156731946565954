import { ToolBox } from "@/common/components/Card";
import { TabelWrapper } from "@/common/styled/Dialogue.Dumb";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@/common/styled/Workflow.Analytics.Dumb";
import React from "react";

export function KnowledgeEntityAi(){
    return(
        <>
            <section className="entity_cards knowledgeAi_table">
                <div className="col-md-12">
                    <div className="row">
                        <TabelWrapper>
                            <TableContainer>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th size="3">Entity name</Th>
                                            <Th size="3">Description</Th>
                                            <Th size="3">No. of values</Th>
                                            <Th size="2">Date</Th>
                                            <Th size="1">Option</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td
                                                size="3"
                                                className={
                                                    'list_flow_hr'
                                                }
                                            >
                                                Workativ-mobilenumber
                                            <label>
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                    />
                                            </label>               
                                            </Td>
                                            <Td
                                                size="3"
                                                className="list_flow_p"
                                            >
                                                <p className="listite_centre">
                                                Entity description goes here
                                                </p>
                                            </Td>
                                            <Td size="3">
                                                Values <span style={{color: "#F07F31", marginLeft: 5}}>(02)</span>
                                            </Td>
                                            <Td
                                                size="2"
                                                className="list_flow_span"
                                            >
                                                28 Aug 2020 | 5:44PM
                                            </Td>
                                            
                                            <Td size="1">
                                                <ToolBox>
                                                    
                                                </ToolBox>
                                            </Td>
                                                
                                        </Tr>
                                        <Tr>
                                            <Td
                                                size="3"
                                                className={
                                                    'list_flow_hr'
                                                }
                                            >
                                                Workativ-mobilenumber
                                            <label>
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                    />
                                            </label>               
                                            </Td>
                                            <Td
                                                size="3"
                                                className="list_flow_p"
                                            >
                                                <p className="listite_centre">
                                                Entity description goes here
                                                </p>
                                            </Td>
                                            <Td size="3">
                                                Values <span style={{color: "#F07F31", marginLeft: 5}}>(02)</span>
                                            </Td>
                                            <Td
                                                size="2"
                                                className="list_flow_span"
                                            >
                                                28 Aug 2020 | 5:44PM
                                            </Td>
                                            
                                            <Td size="1">
                                                <ToolBox>
                                                    
                                                </ToolBox>
                                            </Td>
                                                
                                        </Tr>
                                        <Tr>
                                            <Td
                                                size="3"
                                                className={
                                                    'list_flow_hr'
                                                }
                                            >
                                                Workativ-mobilenumber
                                            <label>
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                    />
                                            </label>               
                                            </Td>
                                            <Td
                                                size="3"
                                                className="list_flow_p"
                                            >
                                                <p className="listite_centre">
                                                Entity description goes here
                                                </p>
                                            </Td>
                                            <Td size="3">
                                                Values <span style={{color: "#F07F31", marginLeft: 5}}>(02)</span>
                                            </Td>
                                            <Td
                                                size="2"
                                                className="list_flow_span"
                                            >
                                                28 Aug 2020 | 5:44PM
                                            </Td>
                                            
                                            <Td size="1">
                                                <ToolBox>
                                                    
                                                </ToolBox>
                                            </Td>
                                                
                                        </Tr>
                                        
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </TabelWrapper>
                    </div>
                </div>
            </section>
        </>

    )
}