/*eslint-disable*/
import Downshift from 'downshift'
import React, { useEffect, useState } from 'react'
import Tooltip from 'react-tooltip-lite'
import styled from 'styled-components'

import { DialogueT, FAQT } from '@/Assistant/Dialogue/kind'
import { reverseCond } from '@/Assistant/DialogueCrew/Nodes/RecogniseDialog/action'
import { EntityT } from '@/Assistant/Entity/kind'
import { IntentT } from '@/Assistant/Intent/kind'
import {
    createIntentExample,
    deleteIntentExample,
    getAssistants,
    getDialogues,
    getEntities,
    getIntents,
    removeTrainingFAQ,
    updateEntityValue,
    updateTrainingFAQ
} from '@/Assistant/Mechanisms/action'
import { VideoButtonB } from '@/Videos/Videos'
import {
    AddIcon,
    DeleteIcon,
    DownArrowCircle,
    DropdownWithCircle,
    EditIcon,
    InfoIcon,
    NextIconCanvas,
    SearchIcon
} from '@/common/Icons/Icons'
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons'
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Header, HeaderChild } from '@/common/components/Header'
import { Loader } from '@/common/components/Loader'
import { StrippedModal } from '@/common/components/Modal'
import { usePrevious } from '@/common/hooks/usePrevious'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    CancelButton,
    DropdownUlWrapper,
    DropdownWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    PromptButton,
    ScrollUl,
    Table,
    TableContainer,
    Td,
    TdContent,
    Th,
    Thead,
    Tr
} from '@/common/styled/Workflow.Analytics.Dumb'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { getJson, logEvent, postJson, stripeGetApi, watsonLogsApiURL } from '@/common/utils/api-utils'
import { TryIt } from './DemoTryIt'
import { AssistantMainHeader, AssistantSubHeader } from '@/Assistant/BotHome/PortalElement'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'


export const DropdownLi = styled.div`
    width: 100%;
    float: left;
    padding: 0 10px;
    border-bottom: 1px solid #b6b6b6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding-top: 10px
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    background: #fff;
    cursor: pointer !important;
    color: #000;
    text-align: left;

    img {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    :first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
    }
    :last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #fff;
        border-bottom: 0.5px solid #fff;
    }
    :hover {
        background: #ddfeef;
        color: #333;
        border-bottom: 1px solid #ddfeef;
        .option-input.radio {
            border: 1px solid #2468f6;
        }
    }
`

const R = require('ramda')

// import { DateSelectInput } fro;;;;m '@/Flows/analytics/dateDropDown'

type DateFilterT = 'last7days' | 'yesterday' | 'today' | 'last14days' | 'last30days'

type OrderT = 'ascending' | 'descending'

const ASCENDING = 'ascending'

const DESCENDING = 'descending'

function TrainIntent(props: any) {
    const [showIntentPopup, setShow] = useState(false)

    const { log, intents, removeIntent, mapIntent, selectedIntent, setSelectedIntent, setCurrentLog, fromPopup } = props
    return (
        <>
            <ContentEllipse>
                <ContentLeft>
                    {log.intents.filter((e: any, i: any) => e.intent != null).length == 0 ? (
                        <React.Fragment>
                            <span className="irrelevant">Irrelevant</span>
                            {!fromPopup && (
                                <ActionWrapper className={'cursor_pointer'}>
                                    <ActionSpan
                                        onClick={() => {
                                            if (log._id) {
                                                setCurrentLog(log)
                                                log.intents.length > 0 && setSelectedIntent(log.intents[0].intent)
                                                setShow(true)
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </ActionSpan>
                                </ActionWrapper>
                            )}
                        </React.Fragment>
                    ) : (
                        log.intents
                            .filter((e: any, i: any) => i == 0)
                            .map((intent: any, i: number) => (
                                <React.Fragment key={i}>
                                    <div className="display_row training_intent" style={{ whiteSpace: 'initial' }}>
                                        <p className="training_intent">
                                            {intent.intent == null ? 'Irrelevant' : intent.intent}
                                        </p>
                                        {!fromPopup && (
                                            <ActionWrapper className={'cursor_pointer'}>
                                                <ActionSpan
                                                    onClick={() => {
                                                        if (log._id) {
                                                            setCurrentLog(log)
                                                            if (log.intents && log.intents.length > 0) {
                                                                setSelectedIntent(log.intents[0].intent)
                                                            }
                                                            setShow(true)
                                                        }
                                                    }}
                                                >
                                                    <EditIcon />
                                                </ActionSpan>
                                                <ActionSpan
                                                    onClick={() => {
                                                        if (log._id) {
                                                            log.intents.length > 0 &&
                                                                removeIntent(log.intents[0].intent, log)
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </ActionSpan>
                                            </ActionWrapper>
                                        )}
                                    </div>
                                    {log.trained_time ? (
                                        <div className="td_date">
                                            <span>Trained @ </span>
                                            <span>{formatDateInCard(log.trained_time)} </span>
                                            <span>{formatAMPM(log.trained_time)}</span>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ))
                    )}
                </ContentLeft>{' '}
            </ContentEllipse>
            {showIntentPopup && (
                <StrippedModal>
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            log.intents.length > 0 && setSelectedIntent('')
                            setShow(false)
                        }}
                    >
                        {() => (
                            <span>
                                <EntityWrapper>
                                    <TrainHeader>Train with intent</TrainHeader>
                                    <DropdownWithSearch
                                        header={'Intent'}
                                        keys={'intent'}
                                        placholder={'Search intent'}
                                        value={selectedIntent}
                                        content={intents}
                                        onClick={(intent: any) => {
                                            setSelectedIntent(intent)
                                        }}
                                        size={"small"}
                                    />
                                    <ButtonWrapper>
                                        <Button
                                            primary
                                            onClick={() => {
                                                setShow(false)
                                            }}
                                        >
                                            <label>Cancel</label>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                mapIntent(selectedIntent)
                                            }}
                                        >
                                            <label>Train</label>
                                        </Button>
                                    </ButtonWrapper>
                                </EntityWrapper>
                            </span>
                        )}
                    </Downshift>
                </StrippedModal>
            )}
        </>
    )
}

function TrainFAQ(props: any) {
    const [showIntentPopup, setShow] = useState(false)

    const { log, faqs, removeFAQ, mapFAQ, selectedFAQ, setSelectedFAQ, setCurrentLog, fromPopup } = props
    const faqsList = faqs.filter(
        (f: any) => reverseCond(f.conditions).filter((f: any) => f.type == 'User Queries').length > 0
    )

    return (
        <>
            <ContentEllipse>
                <ContentLeft>
                    {(!Array.isArray(log.faq) ? (
                        Object.keys(log.faq).length == 0
                    ) : (
                        log.faq.length == 0
                    )) ? (
                        <React.Fragment>
                            <span className="irrelevant">Irrelevant</span>
                            {!fromPopup && (
                                <ActionWrapper className={'cursor_pointer'}>
                                    <ActionSpan
                                        onClick={() => {
                                            if (log._id) {
                                                setCurrentLog(log)
                                                setShow(true)
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </ActionSpan>
                                </ActionWrapper>
                            )}
                        </React.Fragment>
                    ) : !Array.isArray(log.faq) ? (
                        <React.Fragment>
                            <div className="display_row training_intent" style={{ whiteSpace: 'initial' }}>
                                <p className="training_intent">{log.faq.faq}</p>
                                {!fromPopup && (
                                    <ActionWrapper className={'cursor_pointer'}>
                                        <ActionSpan
                                            onClick={() => {
                                                if (log._id) {
                                                    setCurrentLog(log)
                                                    faqs.filter((f: FAQT) => f.title == log.faq.faq)[0] &&
                                                        setSelectedFAQ(
                                                            faqs.filter((f: FAQT) => f.title == log.faq.faq)[0]
                                                        )
                                                    setShow(true)
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </ActionSpan>
                                        <ActionSpan
                                            onClick={() => {
                                                if (log._id) {
                                                    removeFAQ(log.faq, log)
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </ActionSpan>
                                    </ActionWrapper>
                                )}
                            </div>
                            {/* {log.trained_time ? ( */}
                            <div className="td_date">
                                <span>Trained @ </span>
                                <span>{formatDateInCard(log.faq.trained_date_time)} </span>
                                <span>{formatAMPM(log.faq.trained_date_time)}</span>
                            </div>
                            {/* ) : null} */}
                        </React.Fragment>
                    ) : (
                        log.faq.map((f: { name: string; id: string; confidence: number }) => (
                            <React.Fragment>
                                <div className="display_row training_intent" style={{ whiteSpace: 'initial' }}>
                                    <p className="training_intent">{f.name}</p>
                                    {!fromPopup && (
                                        <ActionWrapper className={'cursor_pointer'}>
                                            <ActionSpan
                                                onClick={() => {
                                                    if (log._id) {
                                                        setCurrentLog(log)
                                                        faqs.filter((fa: FAQT) => fa.title == f.name)[0] &&
                                                            setSelectedFAQ(
                                                                faqs.filter((fa: FAQT) => fa.title == f.name)[0]
                                                            )
                                                        setShow(true)
                                                    }
                                                }}
                                            >
                                                <EditIcon />
                                            </ActionSpan>
                                            <ActionSpan
                                                onClick={() => {
                                                    if (log._id) {
                                                        removeFAQ(f.name, log)
                                                    }
                                                }}
                                            >
                                                <DeleteIcon />
                                            </ActionSpan>
                                        </ActionWrapper>
                                    )}
                                </div>
                            </React.Fragment>
                        ))
                    )}
                </ContentLeft>{' '}
            </ContentEllipse>
            {showIntentPopup && (
                <StrippedModal>
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            setSelectedFAQ({})
                            setShow(false)
                        }}
                    >
                        {() => (
                            <span>
                                <EntityWrapper className="train_faq_popup">
                                    <TrainHeader>Train with FAQ</TrainHeader>
                                    <DropdownWithSearch
                                        header={'FAQ'}
                                        keys={'title'}
                                        placholder={'Search FAQ'}
                                        value={selectedFAQ.title}
                                        content={faqsList}
                                        returnAllData
                                        onClick={(faq: any) => {
                                            setSelectedFAQ(faq)
                                        }}
                                        size={"responsive"}
                                    />
                                    <ButtonWrapper style={{marginTop: 0}}>
                                        <Button
                                            primary
                                            onClick={() => {
                                                setSelectedFAQ({})
                                                setShow(false)
                                            }}
                                        >
                                            <label>Cancel</label>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                mapFAQ(selectedFAQ)
                                            }}
                                            className={
                                                Object.keys(selectedFAQ).length != 0 ? '' : 'editor_btn_disabled'
                                            }
                                        >
                                            <label>Train</label>
                                        </Button>
                                    </ButtonWrapper>
                                </EntityWrapper>
                            </span>
                        )}
                    </Downshift>
                </StrippedModal>
            )}
        </>
    )
}

function TrainEntity(props: any) {
    const [entityChnged, setEntityChanged] = useState<number[]>([])
    const {
        log,
        fromPopup,
        setCurrentLog,
        selectEntity,
        setSelectEntity,
        entities,
        setSelectWord,
        mapEntity,
        setOpenDeatilsPopup,
        groupBy,
        sentence,
        setSenetence,
        showIntentPopup,
        setShow,
        hoverEntity,
        entityTrainError,
        setEntityTrainError
    } = props

    const loc =
        sentence.length != 0
            ? [log.text.indexOf(sentence), log.text.indexOf(sentence) + sentence.length]
            : hoverEntity
            ? hoverEntity.location
            : null
    const selected = loc
        ? [
              log.text.substring(0, loc[0]),
              log.text.substring(loc[0], loc[1]),
              log.text.substring(loc[1], log.text.length)
          ]
        : []

    const isEntityIsEmpty =
        selectEntity.filter((e: any) => e.entity.length > 0 && e.value.length > 0).length == selectEntity.length

    return (
        <>
            <ContentEllipse>
                <TdContent className="td_header" style={{ whiteSpace: 'initial' }}>
                    <span id={log.user_text._id}>
                        {log.text.indexOf(sentence) != -1 && selected.length > 0
                            ? selected.map((t: any, i: number) =>
                                  i == 1 ? <TrainButton style={{ color: 'blue' }}>{t}</TrainButton> : t
                              )
                            : log.text}
                    </span>{' '}
                    {log.count && log.count > 1 && groupBy == 'user_text' && <Count>({log.count}) </Count>}{' '}
                    {log.count && log.count > 1 && !fromPopup && groupBy == 'user_text' && (
                        <LabelImage
                            onClick={() => {
                                if (log.user_text) {
                                    setCurrentLog(log)
                                    setOpenDeatilsPopup && setOpenDeatilsPopup(true)
                                }
                            }}
                        >
                            <NextIconCanvas />
                        </LabelImage>
                    )}{' '}
                </TdContent>
            </ContentEllipse>
            {log.created_date && (
                <div className="td_date">
                    Created on {formatDateInCard(log.created_date)} | {formatAMPM(log.created_date)}
                </div>
            )}
            {showIntentPopup && (
                <StrippedModal>
                    <Downshift
                        isOpen={true}
                        onOuterClick={() => {
                            setSenetence('')
                            setShow(false)
                        }}
                    >
                        {() => (
                            <span>
                                <TrainPopupWrapper>
                                    <TrainHeader>Train with appropriate entity</TrainHeader>
                                    <TrainBox>
                                        <InputWrapper>
                                            <InputContainer>
                                                <Input
                                                    type="text"
                                                    value={sentence}
                                                    onChange={(e: any) => {
                                                        setEntityTrainError(null)
                                                        setSenetence(e.target.value)
                                                        setSelectWord(e.target.value)
                                                    }}
                                                />
                                                {(log.text.length == 0 ||
                                                    log.text.indexOf(sentence) == -1 ||
                                                    entityTrainError) && (
                                                    <span className="connect_account_failed">
                                                        <Tooltip
                                                            className="target customTip"
                                                            zIndex={10000}
                                                            arrowSize={8}
                                                            tagName="span"
                                                            content={
                                                                entityTrainError
                                                                    ? entityTrainError
                                                                    : 'Invalid user text to train'
                                                            }
                                                            distance={5}
                                                        >
                                                            <WarningAlertIcon />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                                <InputContent>Selected user text</InputContent>
                                            </InputContainer>
                                        </InputWrapper>
                                    </TrainBox>
                                    <TrainBox>
                                        {selectEntity.map((e: { entity: string; value: string }, i: number) => (
                                            <>
                                                <DropdownWithSearch
                                                    keys={'entity'}
                                                    header={'Entity'}
                                                    placholder={'Search entity'}
                                                    content={entities.filter(
                                                        (enti: any) =>
                                                            !selectEntity.find(
                                                                (en: { entity: string; value: string }) =>
                                                                    en.entity == enti.entity
                                                            )
                                                    )}
                                                    value={e.entity}
                                                    onClick={(entity: any) => {
                                                        entity.entity != e.entity &&
                                                            setEntityChanged([...entityChnged, i])
                                                        setEntityTrainError(null)
                                                        setSelectEntity(
                                                            selectEntity.map((e: any, j: any) =>
                                                                i == j ? { ...e, entity } : e
                                                            )
                                                        )
                                                    }}
                                                    size={"responsive"}
                                                />
                                                <DropdownWithSearch
                                                    keys={'value'}
                                                    value={entityChnged.includes(i) ? '' : e.value}
                                                    placholder={'Search value'}
                                                    header={'Value'}
                                                    content={
                                                        e.entity != '' &&
                                                        entities.filter((en: any) => en.entity == e.entity)[0]
                                                            ? entities.filter((en: any) => en.entity == e.entity)[0]
                                                                  .values
                                                            : []
                                                    }
                                                    onClick={(value: any) => {
                                                        setEntityChanged(entityChnged.filter(v => v != i))
                                                        setEntityTrainError(null)
                                                        setSelectEntity(
                                                            selectEntity.map((e: any, j: any) =>
                                                                i == j ? { ...e, value } : e
                                                            )
                                                        )
                                                    }}
                                                    size={"responsive"}
                                                />
                                            </>
                                        ))}
                                    </TrainBox>
                                    <Emptydiv>
                                        {' '}
                                        <SmallButton
                                            primary
                                            onClick={() => {
                                                setSelectEntity([...selectEntity, { value: '', entity: '' }])
                                            }}
                                            className={
                                                log.text.length == 0 ||
                                                log.text.indexOf(sentence) == -1 ||
                                                !isEntityIsEmpty ||
                                                entityTrainError
                                                    ? 'editor_btn_disabled'
                                                    : ''
                                            }
                                            disabled={
                                                log.text.length == 0 ||
                                                log.text.indexOf(sentence) == -1 ||
                                                !isEntityIsEmpty ||
                                                entityTrainError
                                            }
                                        >
                                            <span>
                                                <AddIcon />
                                            </span>
                                            <label>Add More</label>
                                        </SmallButton>
                                    </Emptydiv>
                                    <ButtonWrapper>
                                        <Button
                                            primary
                                            onClick={() => {
                                                setEntityTrainError(null)
                                                setShow('')
                                                setSenetence('')
                                                setSelectEntity([{ entity: '', value: '' }])
                                                setCurrentLog(null)
                                            }}
                                        >
                                            <label>Cancel</label>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                mapEntity()
                                            }}
                                            className={
                                                log.text.length == 0 ||
                                                log.text.indexOf(sentence) == -1 ||
                                                !isEntityIsEmpty ||
                                                entityTrainError
                                                    ? 'editor_btn_disabled'
                                                    : ''
                                            }
                                            disabled={
                                                log.text.length == 0 ||
                                                log.text.indexOf(sentence) == -1 ||
                                                !isEntityIsEmpty ||
                                                entityTrainError
                                            }
                                        >
                                            <label>Train</label>
                                        </Button>
                                    </ButtonWrapper>
                                </TrainPopupWrapper>
                            </span>
                        )}
                    </Downshift>
                </StrippedModal>
            )}
        </>
    )
}

type ListViewP = {
    setDetails: Function
    openConvo: Function
    log: any
    groupBy: 'user_text' | 'user_email' | 'intent'
    intents: any
    removeIntent: any
    mapIntent: any
    selectedIntent: any
    setSelectedIntent: any
    removeFAQ: any
    mapFAQ: any
    selectedFAQ: any
    setSelectedFAQ: any
    selectEntity: any
    setSelectEntity: any
    entities: any
    setSelectWord: any
    mapEntity: any
    faqs: FAQT[]
    setOpenDeatilsPopup?: any
    fromPopup?: any
    setCurrentLog?: any
    popupLogs?: any
    entityTrainError?: null | string
    setEntityTrainError?: Function
}

function ListView(props: ListViewP) {
    const [showIntentPopup, setShow] = useState('')
    const [sentence, setSenetence] = useState('')
    const [hoverEntity, setHoverEntity] = useState(null)
    const {
        setDetails,
        log,
        openConvo,
        groupBy,
        fromPopup,
        setCurrentLog,
        popupLogs,
        setOpenDeatilsPopup,
        setSelectEntity,
        setSelectWord
    } = props
    return (
        <>
            {groupBy == 'user_text' ? (
                <>
                    <Td
                        size={fromPopup ? 3 : 3}
                        onClick={() => {
                            if (log.user_text && !fromPopup && log.count > 1) {
                                setCurrentLog(log)
                                setOpenDeatilsPopup && setOpenDeatilsPopup(true)
                            }
                        }}
                        className={fromPopup || log.count <= 1 ? 'cursor_default' : ''}
                    >
                        <TrainEntity
                            {...props}
                            sentence={sentence}
                            setSenetence={setSenetence}
                            showIntentPopup={log._id == showIntentPopup}
                            setShow={setShow}
                            log={log}
                            hoverEntity={hoverEntity}
                        />
                    </Td>
                    <Td size={fromPopup ? 1.5 : 1.5} className={'cursor_default'}>
                        <TrainFAQ {...props} log={log} />
                    </Td>
                    <Td size={fromPopup ? 1.5 : 1.5} className={'cursor_default'}>
                        <TrainIntent {...props} log={log} />
                    </Td>
                    <Td size={fromPopup ? 1.5 : 1.5} className={'cursor_default '}>
                        {!log.entities || log.entities.length == 0 ? (
                            <div className="display_row">
                                <span className="irrelevant">Irrelevant</span>
                                {!fromPopup && (
                                    <ActionWrapper className={'cursor_pointer'}>
                                        <ActionSpan
                                            onClick={() => {
                                                if (log._id && !fromPopup) {
                                                    setShow(log._id)
                                                    setSenetence(log.text)
                                                    setSelectWord(log.text)
                                                    setCurrentLog(log)
                                                }
                                            }}
                                        >
                                            <EditIcon />
                                        </ActionSpan>
                                    </ActionWrapper>
                                )}
                            </div>
                        ) : (
                            log.entities.map((e: any, i: number) => (
                                <>
                                    <div className="display_row">
                                        <div
                                            key={i}
                                            onMouseEnter={() => {
                                                setHoverEntity(e)
                                            }}
                                            onMouseLeave={() => {
                                                setHoverEntity(null)
                                            }}
                                            onClick={() => {
                                                if (log._id && !fromPopup) {
                                                    setShow(log._id)
                                                    setSelectEntity([
                                                        {
                                                            entity: e.entity,
                                                            value: e.value
                                                        }
                                                    ])
                                                    setSenetence(log.text.substring(e.location[0], e.location[1]))
                                                    setSelectWord(log.text)
                                                    setCurrentLog(log)
                                                }
                                            }}
                                            className={'cursor_pointer'}
                                        >
                                            {e.entity}
                                        </div>
                                        {!fromPopup && (
                                            <ActionWrapper className={'cursor_pointer'}>
                                                <ActionSpan
                                                    onClick={() => {
                                                        if (log._id) {
                                                            setShow(log._id)
                                                            setSelectEntity([
                                                                {
                                                                    entity: e.entity,
                                                                    value: e.value
                                                                }
                                                            ])
                                                            setSenetence(
                                                                log.text.substring(e.location[0], e.location[1])
                                                            )
                                                            setSelectWord(log.text)
                                                            setCurrentLog(log)
                                                        }
                                                    }}
                                                >
                                                    <EditIcon />
                                                </ActionSpan>
                                            </ActionWrapper>
                                        )}
                                    </div>
                                    {e.trained_date_time && (
                                        <div className="td_date">
                                            <span>Trained @ </span>
                                            <span>{formatDateInCard(e.trained_date_time)} </span>
                                            <span>{formatAMPM(e.trained_date_time)}</span>
                                        </div>
                                    )}
                                </>
                            ))
                        )}
                    </Td>
                    <Td size={fromPopup ? 2 : 1.5} className={'cursor_default'}>
                        {log.date != null ? (
                            <div className="td_date_wrapper">
                                {formatDateInCard(log.date)} | {formatAMPM(log.date)}
                            </div>
                        ) : (
                            <div className="td_date_wrapper">-</div>
                        )}
                    </Td>
                    <Td size={fromPopup ? 2.5 : 2} className={'cursor_default'}>
                        <div className="td_email">{log.user_email}</div>
                    </Td>

                    <Td size={fromPopup ? 1.5 : 1} className={'cursor_default'}>
                        <button
                            className="automation_btn_view cursor_pointer"
                            onClick={() => openConvo(log.conversation_id)}
                        >
                            <label>View</label>
                        </button>
                    </Td>
                </>
            ) : groupBy == 'user_email' ? (
                <>
                    <Td size={2.5}>
                        {' '}
                        <ContentEllipse>
                            <TdContent className="td_header">
                                <div className="td_email">{log.user_email ? log.user_email : 'anonymous user'}</div>{' '}
                                {log.count && log.count > 1 && <Count>({log.count}) </Count>}{' '}
                                {!fromPopup && log.count > 1 && (
                                    <LabelImage
                                        style={{ left: '-40px' }}
                                        onClick={() => {
                                            if (log.user_text) {
                                                setCurrentLog(log)
                                                setOpenDeatilsPopup && setOpenDeatilsPopup(true)
                                            }
                                        }}
                                    >
                                        <NextIconCanvas />
                                    </LabelImage>
                                )}{' '}
                            </TdContent>
                        </ContentEllipse>
                    </Td>

                    <Td size={fromPopup ? 3 : 3}>
                        <TrainEntity {...props} log={log} />
                    </Td>

                    <Td size={fromPopup ? 1.5 : 1.5}>
                        <TrainIntent {...props} log={log} />
                    </Td>
                    <Td size={fromPopup ? 1.5 : 1.5}>
                        {!log.entities || log.entities.length == 0 ? (
                            <span className="irrelevant">Irrelevant</span>
                        ) : (
                            log.entities
                                .filter((e: any, i: any) => i == 0)
                                .map((e: any, i: number) => (
                                    <>
                                        <div key={i}>{e.entity}</div>
                                        {e.trained_date_time && (
                                            <div className="td_date">
                                                <span>Trained @ </span>
                                                <span>{formatDateInCard(e.trained_date_time)} </span>
                                                <span>{formatAMPM(e.trained_date_time)}</span>
                                            </div>
                                        )}
                                    </>
                                ))
                        )}
                    </Td>
                    <Td size={fromPopup ? 2 : 2}>
                        {log.date != null ? (
                            <div className="td_date_wrapper">
                                {formatDateInCard(log.date)} | {formatAMPM(log.date)}
                            </div>
                        ) : (
                            <div className="td_date_wrapper">-</div>
                        )}
                    </Td>
                    <Td size={fromPopup ? 1.5 : 1.5}>
                        <button className="automation_btn_view" onClick={() => openConvo(log.conversation_id)}>
                            <label>View</label>
                        </button>
                    </Td>
                </>
            ) : (
                <>
                    <Td size={fromPopup ? 1.5 : 1.5}>
                        <ContentEllipse>
                            <TdContent className="td_header">
                                {log.intents.filter((e: any, i: any) => e.intent != null).length == 0 ? (
                                    <React.Fragment>
                                        <span className="irrelevant">Irrelevant</span>
                                    </React.Fragment>
                                ) : (
                                    log.intents
                                        .filter((e: any, i: any) => i == 0)
                                        .map((intent: any, i: number) => (
                                            <React.Fragment key={i}>
                                                {intent.intent == null ? 'Irrelevant' : intent.intent}

                                                {/* {log.trained_time ? (
                                                    <div className="td_date">
                                                        <span>Trained @ </span>
                                                        <span>{formatDateInCard(log.trained_time)} </span>
                                                        <span>{formatAMPM(log.trained_time)}</span>
                                                    </div>
                                                ) : null} */}
                                            </React.Fragment>
                                        ))
                                )}
                                {log.count && log.count > 1 && <Count>({log.count}) </Count>}
                                {!fromPopup && log.count > 1 && (
                                    <LabelImage
                                        onClick={() => {
                                            if (log.user_text) {
                                                setCurrentLog(log)
                                                props.setOpenDeatilsPopup && props.setOpenDeatilsPopup(true)
                                            }
                                        }}
                                    >
                                        <NextIconCanvas />
                                    </LabelImage>
                                )}{' '}
                            </TdContent>
                        </ContentEllipse>
                    </Td>
                    <Td size={fromPopup ? 3 : 3}>
                        <TrainEntity {...props} log={log} />
                    </Td>

                    <Td size={fromPopup ? 1.5 : 1.5}>
                        {!log.entities || log.entities.length == 0 ? (
                            <span className="irrelevant">Irrelevant</span>
                        ) : (
                            log.entities
                                .filter((e: any, i: any) => i == 0)
                                .map((e: any, i: number) => (
                                    <>
                                        <div key={i}>{e.entity}</div>
                                        {e.trained_date_time && (
                                            <div className="td_date">
                                                <span>Trained @ </span>
                                                <span>{formatDateInCard(e.trained_date_time)} </span>
                                                <span>{formatAMPM(e.trained_date_time)}</span>
                                            </div>
                                        )}
                                    </>
                                ))
                        )}
                    </Td>
                    <Td size={fromPopup ? 2 : 2}>
                        {log.date != null ? (
                            <div className="td_date_wrapper">
                                {formatDateInCard(log.date)} | {formatAMPM(log.date)}
                            </div>
                        ) : (
                            <div className="td_date_wrapper">-</div>
                        )}
                    </Td>
                    <Td size={fromPopup ? 2.5 : 2.5}>
                        <div className="td_email">{log.user_email}</div>
                    </Td>

                    <Td size={fromPopup ? 1.5 : 1.5}>
                        <button className="automation_btn_view" onClick={() => openConvo(log.conversation_id)}>
                            <label>View</label>
                        </button>
                    </Td>
                </>
            )}
        </>
    )
}

type TrainingP = {
    logs: any[]
    openConvo: Function
    changeOrder: Function
    order: OrderT
    intents: IntentT[]
    entities: EntityT[]
    metadata: any
    updateMetadata: Function
    updateFaqMetadata: Function
    pagination: any
    workspacename: string
    assistantId: string
    setLogs: any
    onlyLogs: boolean
    setOnlyLogs: any
    createOrUpdateEntityMap: any
    groupBy: 'intent' | 'user_email' | 'user_text'
    setGroupBy: Function
    currentAssistant: any
    setCurrentAssistant: Function
    assistants: any[]
    dateFilter: DateFilterT
    changeDateFilter: Function
    displayItems: DateSelectInput[]
    isTryItOpen: boolean
    faqs: FAQT[]
    disable?: boolean
}
const mapLogs = (log: any) => {
    const metainfo =
        (log.user_text && log.user_text.trained_intent && log.user_text.trained_intent.intent) ||
        (log.intent && log.intent.length) > 0
    const obj = {
        text: log.input,
        entities:
            log.trained_entity && log.trained_entity.length > 0 ? R.concat(log.trained_entity, log.entity) : log.entity,
        _id: log.user_text ? log.user_text._id : '',
        conversation_id: log.conversation_id,
        request_time: log.date,
        created_date: log.created_date,
        trained_intent: log.user_text ? log.user_text.trained_intent : null,
        trained_entity: log.trained_entity,
        date: log.date,
        user_email: log.user_email,
        user_text: log.user_text ? log.user_text : {},
        count: log.count,
        log_id: log.log_id,
        faq:
            log.user_text && log.user_text.trained_faq && log.user_text.trained_faq.faq != null
                ? log.user_text.trained_faq
                : log.user_text && log.user_text.trained_faq && log.user_text.trained_faq.faq == null
                ? {}
                : log.faq && log.faq.length > 0
                ? log.faq
                : {}
    }
    if (metainfo) {
        if (log.user_text && log.user_text.trained_intent && log.user_text.trained_intent) {
            obj['intents'] = [log.user_text.trained_intent]
            obj['trained_time'] = log.user_text.trained_intent.trained_date_time
        } else {
            obj['intents'] = log.intent
        }
        // obj['entity_map'] = metainfo.entity_map
    } else {
        obj['intents'] = []
    }
    return obj
}
function Training(props: TrainingP) {
    const [intentSearchKey, setIntentSearchKey] = useState<string>('')

    const [wordSearchKey, setWordSearchKey] = useState<string>('')

    const [search, setSearch] = useState<string>('')

    const [detailIndex, setDetailIndex] = useState<any>(null)

    const {
        dateFilter,
        logs,
        changeDateFilter,
        intents,
        metadata,
        updateMetadata,
        entities,
        workspacename,
        changeOrder,
        order,
        openConvo,
        assistantId,
        setLogs,
        onlyLogs,
        setOnlyLogs,
        createOrUpdateEntityMap,
        groupBy,
        setGroupBy,
        currentAssistant,
        setCurrentAssistant,
        assistants,
        displayItems,
        isTryItOpen,
        faqs,
        updateFaqMetadata,
        disable
    } = props

    const sortData = (order: OrderT, data: any[]) => {
        const ascending_sort = (a: any, b: any) => (a.trained_time < b.trained_time ? -1 : 1)

        const descending_sort = (a: any, b: any) => (a.trained_time < b.trained_time ? 1 : -1)

        return R.sort(order == ASCENDING ? ascending_sort : descending_sort, data)
    }

    const renderLogs = (logs: any) => {
        return logs.map((log: any) => mapLogs(log))
    }

    const mapSearch = (word: string, key: string) => word.toLowerCase() == key.toLowerCase()

    const mapSearchStartWith = (word: string, key: string) => word.toLowerCase().startsWith(key.toLowerCase())

    const Tbodycontent = renderLogs(logs)
        .filter((e: any) => e.text != '')
        .filter((e: any) =>
            intentSearchKey == ''
                ? true
                : intentSearchKey == '_'
                ? e.intents && e.intents.length == 0
                : e.intents.some((intent: any) => mapSearch(intent.intent, intentSearchKey))
        )
        .filter((e: any) => (wordSearchKey == '' ? true : mapSearchStartWith(e.text, wordSearchKey)))

    const chatBots = assistants.filter((b)=> b.deployed != null).map((data, index) => ({ label: data.name, value: data }))

    function selectedDate(date: any) {
        changeDateFilter(date)
    }
    const toDisplay = displayItems.find(x => x.date === dateFilter)
    const yellGreeting = R.compose(R.toLower, R.prop('label'))

    const byName = R.ascend(yellGreeting)

    return (
        <>
            <AssistantMainHeader>
                <>
                    <SerachInputWrapper>
                        <SerachInput
                            style={{width:'220px', height: '34px', marginRight: 10, padding: '8px 12px 5px 40px', marginTop: 2}}
                            type="search"
                            value={search}
                            placeholder={`Search ${groupBy == 'user_text' ? 'user utterances' : groupBy}`}
                            onChange={(e: any) => setSearch(e.target.value)}
                        />
                        <span style={{top: 10, left: 24}}>
                            <SearchIcon />  
                        </span>
                    </SerachInputWrapper>
                    {/* <div className="tab_container_right"> */}
                        <Dropdwon
                            header={'Show by'}
                            value={toDisplay ? toDisplay.label : 'invalid date'}
                            content={'IT_Bot'}
                            list={displayItems.map(d => ({ value: d, label: d.label }))}
                            onClick={(item: DateSelectInput) => selectedDate(item.date)}
                            className="train_chatbot"
                        />
                    {/* </div> */}
                </>
            </AssistantMainHeader>
            <TableUiContainer
                changeOrder={(e: OrderT) => changeOrder(e)}
                order={order}
                closeDetail={() => setDetailIndex(null)}
                dateFilter={dateFilter}
                openConvo={openConvo}
                intents={intents}
                entities={entities}
                metadata={metadata}
                updateMetadata={updateMetadata}
                updateFaqMetadata={updateFaqMetadata}
                workspacename={workspacename}
                assistantId={assistantId}
                setLogs={setLogs}
                createOrUpdateEntityMap={createOrUpdateEntityMap}
                faqs={faqs}
                Tbodycontent={Tbodycontent.filter(
                    (s: any) =>
                        search.length == 0 ||
                        (groupBy == 'user_text'
                            ? s.text.toLowerCase().indexOf(search.toLowerCase()) != -1
                            : groupBy == 'user_email'
                            ? s.user_email && s.user_email.toLowerCase().indexOf(search.toLowerCase()) != -1
                            : s.intents.length > 0 &&
                              s.intents[0].intent.toLowerCase().indexOf(search.toLowerCase()) != -1)
                )}
                setDetailIndex={setDetailIndex}
                detailIndex={detailIndex}
                groupBy={groupBy}
                isTryItOpen={isTryItOpen}
            />
        </>
    )
}

type DateSelectInput = {
    date: DateFilterT
    label: string
}

const items: DateSelectInput[] = [
    {
        date: 'yesterday',
        label: 'Yesterday'
    },
    {
        date: 'today',
        label: 'Today'
    }
    // {
    //     date: 'last14days',
    //     label: 'Last 14 days'
    // }
]
function returnDate(filter: DateFilterT) {
    // return 14
    switch (filter) {
        case 'last14days':
            return 14
        case 'last30days':
            return 30
        case 'last7days':
            return 7
        default:
            return filter
    }
}
export function WatsonTraining(props: any) {
    const queryClient = useQueryClient()
    const [logs, setLogs] = useState<any[]>([])
    const [onlyLogs, setOnlyLogs] = useState<any>(null)

    const [pagination, setPagination] = useState({})

    const [error, setError] = useState<any>({ error: null, info: null })

    const [unauthorized, setUnauthorized] = useState<boolean>(false)

    const [notDeployed, setNotDeployed] = useState<boolean>(false)

    const [dateOptions, setDateOptions] = useState<DateSelectInput[]>(items)

    const [entities, setEntities] = useState<EntityT[]>([])

    const [intents, setIntents] = useState<IntentT[]>([])
    const [faqs, setFaqs] = useState<FAQT[]>([])

    const [metadata, setMetada] = useState({})

    const [order, setOrder] = useState<OrderT>(DESCENDING)

    const [isDateFilterOpen, setIsDateFilterOpen] = useState<boolean>(false)

    const [dateFilter, setDateFilter] = useState<DateFilterT>('today')

    const [isTryItOpen, setIsTryItOpen] = useState<boolean>(false)

    const [tryItLog, setTryItLog] = useState([])

    const [tryItLoading, setTryItLoading] = useState<boolean>(false)

    // const [assistant, setAssistant] = useState<string>('')

    // const [BOT_ID, setBOT_ID] = useState<string>('')
    const [initial, setInitial] = useState<boolean>(true)

    const [groupBy, setGroupBy] = useState<'intent' | 'user_email' | 'user_text'>('user_text')

    const [isAssOpen, setIsAssOpen] = useState<boolean>(false)

    const [assistants, setAssistants] = useState<any[]>([])

    const [currentAssistant, setCurrentAssistant] = useState<any>({})

    const [pageLoading, setPageLoading] = useState<boolean>(false)

    const assignLogs = R.compose(R.tap(setLogs), R.prop('logs'))
    const assignPagination = R.compose(R.tap(setPagination), R.prop('pagination'))
    const assignEntities = R.compose(
        R.tap(setEntities),
        R.filter((e: EntityT) => e.is_system_entity === false)
    )
    const assignIntents = R.compose(R.tap(setIntents))

    const { workspacename } = props.match.params

    const params = useParams()

    useEffect(() => {
        stripeGetApi(`${workspacename}/info/logs_limit`).then((resp: { output: string; type: 'success' }) => {
            const maxDays = Number(resp.output)
            const daysList = [30, 14, 7]
                .filter(x => x <= maxDays)
                .map(value => ({ date: `last${value}days`, label: `Last ${value} Days` } as DateSelectInput))

            setDateOptions(daysList.concat(dateOptions))
        })
        fetchData()
    }, [])

    const fetchData = () => {
        const storedId = params["assistantname"]
        setPageLoading(true)

        queryClient
            .fetchQuery({
                queryKey : ['assistants'],
                queryFn : () => { 
                    return getAssistants(workspacename) 
                },
            })
            .then((data: any[]) => {
                setAssistants(data)
                if (data.length === 0) {
                    setNotDeployed(true)
                    setPageLoading(false)
                    return
                }
                const assistant = storedId
                    ? data.filter(e => e.deployed !== null).filter(e => e.name == storedId)[0]
                    : data[0]
                if (assistant !== null && assistant !== undefined) {
                    if (assistant.deployed === null) {
                        setNotDeployed(true)
                    } else {
                        setCurrentAssistant(assistant)
                    }
                }
                setPageLoading(false)
            })
            .catch((error: any) => {
                setPageLoading(false)
                if (error && error.response && error.response.status == 403) {
                    setUnauthorized(true)
                } else {
                    setError({
                        error: true,
                        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })
    }

    useEffect(() => {
        if (onlyLogs != null) {
            changeLogs()
        }
    }, [onlyLogs])

    useEffect(() => {
        if (currentAssistant.id && currentAssistant.deployed) {
            changeLogs()
        }
    }, [currentAssistant])

    useEffect(() => {
        !initial ? changeLogs() : setInitial(false)
    }, [groupBy])
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const changeLogs = () => {
        setPageLoading(true)
        Promise.all([
            queryClient
            .fetchQuery(['intents', currentAssistant.id], () =>
              getIntents(workspacename, currentAssistant.id)
            ),
            queryClient
            .fetchQuery(['entities', currentAssistant.id], () =>
              getEntities(workspacename, currentAssistant.id)
            ),
            queryClient
            .fetchQuery(['dialogs', currentAssistant.id], () =>
             getDialogues(workspacename, currentAssistant.id)
            ),
            queryClient
            .fetchQuery(['traininglogs', currentAssistant.id], () =>
                getJson(
                    watsonLogsApiURL(
                        // `/${workspacename}/logs/${'434981d3-5ec6-449b-ae12-08f6eca28d4c'}/30?page_no=1&no_of_records=50&group_by=${groupBy}`
                        `/${workspacename}/logs/${currentAssistant.id}/${returnDate(
                            dateFilter
                        )}?page_no=1&no_of_records=50&group_by=${groupBy}&sort_by_created_time=${
                            order == DESCENDING ? -1 : 1
                        }&timezone=${timezone}`
                    )
                )
            )
        ])
            .then(([intentRes, entityRes, dialogs, logsRes]) => {
                assignIntents(intentRes)
                assignEntities(entityRes)
                setPagination(logsRes)
                setLogs(logsRes.data)
                setFaqs(dialogs.filter((d: DialogueT) => d.type == 'faq_node'))
                setPageLoading(false)
            })
            .catch(error => {
                setPageLoading(false)
                if (error && error.response && error.response.status == 403) {
                    setUnauthorized(true)
                } else {
                    setError({
                        error: true,
                        info: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                    })
                }
            })
    }

    const changeOrder = (element: OrderT) => {
        setPageLoading(true)
        getJson(
            watsonLogsApiURL(
                `/${workspacename}/logs/${currentAssistant.id}/${returnDate(
                    dateFilter
                )}?page_no=1&no_of_records=50&sort_by_created_time=${
                    element == DESCENDING ? -1 : 1
                }&group_by=${groupBy}&timezone=${timezone}`
            )
        ).then(logsRes => {
            setPagination(logsRes.data)
            setLogs(logsRes.data)
            setOrder(element)
            setPageLoading(false)
        })
    }

    const changeDateFilter = (filter: DateFilterT) => {
        setPageLoading(true)
        getJson(
            watsonLogsApiURL(
                `/${workspacename}/logs/${currentAssistant.id}/${returnDate(
                    filter
                )}?page_no=1&no_of_records=50&sort_by_created_time=${
                    order == DESCENDING ? -1 : 1
                }&group_by=${groupBy}&timezone=${timezone}`
            )
        ).then(logsRes => {
            setIsDateFilterOpen(false)
            setPagination(logsRes.data)
            setLogs(logsRes.data)
            setDateFilter(filter)
            setPageLoading(false)
        })
    }

    const updateMetadata = (meta: any, id: string) => {
        setPageLoading(true)
        postJson(watsonLogsApiURL(`/${workspacename}/logs/${currentAssistant.id}/${id}/update_training_intent`))({
            updated_intent: meta ? meta : null,
            user_text_id: id
        }).then(() => {
            logEvent(workspacename, {
                event:"EVENT::CHAT_CHANNEL::TRAIN_CHATBOT", event_meta:{}
            })
            queryClient.invalidateQueries(['assistants'])
            queryClient.invalidateQueries(['traininglogs', currentAssistant.id])

            fetchData()
        })
    }

    const updateFaqMetadata = (meta: any, id: string) => {
        setPageLoading(true)
        postJson(watsonLogsApiURL(`/${workspacename}/logs/${currentAssistant.id}/${id}/update_training_faq`))({
            updated_faq: meta ? meta : null,
            user_text_id: id
        }).then(() => {
            logEvent(workspacename, {
                event:"EVENT::CHAT_CHANNEL::TRAIN_CHATBOT", event_meta:{}
            })
            queryClient.invalidateQueries(['assistants'])
            queryClient.invalidateQueries(['traininglogs', currentAssistant.id])

            fetchData()
        })
    }

    const createOrUpdateEntityMap = (
        newLoc: any,
        entity: { value: string; entity: string }[],
        id: string,
        update: any
    ) => {
        setPageLoading(true)
        Promise.all(
            entity.map(s =>
                postJson(
                    watsonLogsApiURL(`/${workspacename}/logs/${currentAssistant.id}/${id}/update_training_entity`)
                )(
                    !update
                        ? {
                              entity: s.entity,
                              location: newLoc,
                              value: s.value
                          }
                        : {
                              entity: s.entity,
                              value: s.value,
                              trained_entity_id: update._id
                          }
                )
            )
        ).then(() => {
            logEvent(workspacename, {
                event:"EVENT::CHAT_CHANNEL::TRAIN_CHATBOT", event_meta:{}
            })
            queryClient.invalidateQueries(['assistants'])
            queryClient.invalidateQueries(['traininglogs', currentAssistant.id])

            fetchData()
        })
    }

    const openConvo = (conversationId: string) => {
        setTryItLoading(true)
        setIsTryItOpen(true)
        queryClient
            .fetchQuery(['openconversation', currentAssistant.id,conversationId], 
            () =>
                getJson(
                    watsonLogsApiURL(
                        `/${workspacename}/logs/${currentAssistant.id}/${returnDate(
                            dateFilter
                        )}?sort_by_created_time=1&conversation_id=${conversationId}&timezone=${timezone}`
                    )
            )
        ).then((e: any) => {
            setTryItLog(
                e.map((e: any) => {
                    console.log(e, 'request')

                    const concatedResponse = e.response.reduce(
                        (acc: any, curr: any) => {
                            return {
                                ...acc,
                                intents: curr.intents,
                                entities: curr.entities,
                                context: curr.context,
                                faq:curr.faq,
                                output: acc.output.concat(curr.output)
                            }
                        },
                        { intents: [], entities: [], faq:[], context: null, output: [] }
                    )
                    return {
                        text: e.request.input,
                        masked: e.masked,
                        intents: concatedResponse.intents.map((intent: any) => intent),
                        entities: concatedResponse.entities.map((entity: any) => entity),
                        context: concatedResponse.context,
                        faq:concatedResponse.faq,
                        output: concatedResponse.output
                            .map((e: any) =>
                                e.output == 'text'
                                    ? {
                                          response_type: 'text',
                                          text: e.text
                                      }
                                    : e.output == 'option'
                                    ? {
                                          response_type: 'text',
                                          title: e.option.title,
                                          options: e.option.options.map((e: any) => ({
                                              label: e.label
                                          }))
                                      }
                                    :  e.output=='adaptive_card'?
                                    {
                                        response_type: 'text',
                                        title: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                        description: e.adaptive_card.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                        fields:  e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].fields,
                                        buttons: e.adaptive_card.blocks.filter((b:any)=>b.elements)[0].elements,
                                        image: e.adaptive_card.blocks.filter((b:any)=>b.fields)[0].accessory,
                                        adaptive_card:"adaptive_card"
                                    } : e.type == 'Carousal' ? {
                                        response_type: 'text',
                                        values:e.accumulate_carousal.map((carousal:any)=>({
                                            title: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="bold")[0].text.text,
                                            description: carousal.blocks.filter((b:any)=>b.text&&b.text.style=="normal")[0].text.text,
                                            fields:  carousal.blocks.filter((b:any)=>b.fields)[0].fields,
                                            buttons: carousal.blocks.filter((b:any)=>b.elements)[0].elements,
                                            image: carousal.blocks.filter((b:any)=>b.fields)[0].accessory,
                                        })),
                                        adaptive_card:"carousal"
                                    }
                                    : null
                            )
                            .filter(Boolean)
                    }
                })
            )
            setTryItLoading(false)
        })
    }
    return pageLoading == true ? (
        <Loader.PageLoader show={true} />
    ) : (
        <ErrorBoundary
            unauthorized={unauthorized}
            notDeployed={notDeployed}
            notDeployedFn={() => {
                assistants.length > 0
                    ? props.history.push(`/workspace/${workspacename}/assistant/${currentAssistant.name}/conversations`)
                    : props.history.push(`/workspace/${workspacename}/assistant/homepage`)
            }}
            notDeployedTitle={'No training data available!'}
            notDeployedDescription={'Please build and use your bot.'}
            error={error}
            render={(err: any, info: any) => {
                return (
                    err && (
                        <Error.PageLoadingError
                            onClick={() => {
                                window.location.reload()
                            }}
                            info={info}
                            btnName={'Retry'}
                        />
                    )
                )
            }}
        >
            {/* <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Chatbot Training</div>
                        <p className="content_header">Use this training module to train and improve your chatbot. </p>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    <VideoButtonB id={'TRAIN_THE_CHATBOT'} />
                </HeaderChild>
            </Header> */}
            <AssistantSubHeader>
                <VideoButtonB id={'TRAIN_THE_CHATBOT'} showOnlyIcon/>
            </AssistantSubHeader>

            <ErrorBoundary
                notDeployed={currentAssistant && Object.keys(currentAssistant).length > 0 && !currentAssistant.deployed}
                notDeployedTitle={'No training data available!'}
                notDeployedDescription={'Please build and use your bot.'}
                notDeployedFn={() => {
                    assistants.length > 0
                        ? props.history.push(`/workspace/${workspacename}/assistant/${currentAssistant.name}/conversations`)
                        : props.history.push(`/workspace/${workspacename}/assistant/homepage`)
                }}
                error={{ error: false, inof: null }}
            >
                <section className="entity_cards train_chatbot" style={{ overflow: 'hidden', height: 'calc(100vh - 64px)' }}>
                    <div className="col-md-12">
                        <div className="row">
                            {/* <section className="cogni_analytics"> */}
                            <Training
                                logs={logs}
                                onlyLogs={onlyLogs == null ? false : onlyLogs}
                                setOnlyLogs={setOnlyLogs}
                                dateFilter={dateFilter}
                                changeDateFilter={changeDateFilter}
                                changeOrder={changeOrder}
                                order={order}
                                intents={intents}
                                entities={entities}
                                metadata={metadata}
                                updateMetadata={updateMetadata}
                                updateFaqMetadata={updateFaqMetadata}
                                pagination={pagination}
                                workspacename={props.match.params.workspacename}
                                openConvo={openConvo}
                                assistantId={currentAssistant.id}
                                setLogs={(log: any) => setLogs(logs.map(l => (log._id == l._id ? log : l)))}
                                createOrUpdateEntityMap={createOrUpdateEntityMap}
                                groupBy={groupBy}
                                setGroupBy={setGroupBy}
                                currentAssistant={currentAssistant}
                                setCurrentAssistant={setCurrentAssistant}
                                assistants={assistants}
                                displayItems={dateOptions}
                                isTryItOpen={isTryItOpen}
                                faqs={faqs}
                            />
                            {isTryItOpen && (
                                <TryIt
                                    message={tryItLog}
                                    close={() => {
                                        setIsTryItOpen(false)
                                        setTryItLog([])
                                    }}
                                    loading={tryItLoading}
                                />
                            )}
                            {/* </section> */}
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        </ErrorBoundary>
    )
}

function RenderDownShift(props: any) {
    const { isOpen, onOuterClick, render } = props
    return (
        <Downshift isOpen={isOpen} onOuterClick={() => onOuterClick(false)}>
            {render}
        </Downshift>
    )
}

function FormInputSearch(props: any) {
    const { value, setValue, placeholder } = props
    return (
        <label className="form-input">
            <input
                type="text"
                className="form-control"
                placeholder={placeholder ? placeholder : 'Intent Name'}
                value={value === '_' ? 'Irrelevant' : value}
                onChange={e => setValue(e.target.value)}
            />
        </label>
    )
}

function PopUpLI(props: any) {
    const { onClick, text } = props
    return (
        <li className={text == 'Irrelevant' ? 'irrelevant_color' : ''} onClick={onClick}>
            <span>{text}</span>
            {/* <input type="radio" className="option-input radio" name="example" /> */}
        </li>
    )
}

function AssistantDropDown({
    isOpen,
    setIsOpen,
    currentAssistant,

    setCurrentAssistant,
    assistants
}: {
    isOpen: boolean
    setIsOpen: Function

    currentAssistant: any
    setCurrentAssistant: Function
    assistants: any[]
}) {
    return (
        <div className="analytics_dropdowns">
            <div className="analytics_dropdowns_left">
                <div className="ana_chart_drpdwn_left_sixty"></div>
                <div className="ana_chart_drpdwn_left_fourty">
                    <div className="form-group">
                        <label className="control-label">Chatbot :</label>
                        <div
                            className="date_dropdown_label training_date_dropdown_label"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>{currentAssistant.name}</span>
                            <div className="dropdown_down_arrowslot" onClick={() => setIsOpen(!isOpen)}>
                                <DownArrowCircle />
                            </div>
                            {isOpen ? (
                                <Downshift isOpen={true} onOuterClick={() => setIsOpen(!isOpen)}>
                                    {() => (
                                        <div
                                            className="dropdown_right_entity"
                                            role="combobox"
                                            aria-expanded="true"
                                            aria-haspopup="listbox"
                                            aria-owns="downshift-17-menu"
                                            aria-labelledby="downshift-17-label"
                                        >
                                            <ul className="ana_chart_dropdown_right_entity">
                                                {assistants.map((data, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            setCurrentAssistant(data)
                                                            setIsOpen(false)
                                                        }}
                                                    >
                                                        <p>{data.name}</p>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                className="option-input radio"
                                                                name="example"
                                                                checked={currentAssistant.id === data.id}
                                                                onChange={() => {}}
                                                            />
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </Downshift>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DateDropDown({
    isDateFilterOpen,
    setIsDateFilterOpen,
    dateFilter,
    changeDateFilter,
    displayItems
}: {
    isDateFilterOpen: boolean
    setIsDateFilterOpen: Function
    dateFilter: DateFilterT
    changeDateFilter: Function
    displayItems: DateSelectInput[]
}) {
    function selectedDate(date: any) {
        changeDateFilter(date)
    }

    const toDisplay = displayItems.find(x => x.date === dateFilter)

    return (
        <div className="analytics_dropdowns">
            <div className="analytics_dropdowns_left">
                <div className="ana_chart_drpdwn_left_sixty"></div>
                <div className="ana_chart_drpdwn_left_fourty">
                    <div className="form-group">
                        <label className="control-label">Show :</label>
                        <div className="date_dropdown_label" onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}>
                            {toDisplay ? toDisplay.label : 'invalid date'}
                            <div
                                className="dropdown_down_arrowslot"
                                onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
                            >
                                <DownArrowCircle />
                            </div>
                            {isDateFilterOpen ? (
                                <Downshift isOpen={true} onOuterClick={() => setIsDateFilterOpen(!isDateFilterOpen)}>
                                    {() => (
                                        <div
                                            className="dropdown_right_entity"
                                            role="combobox"
                                            aria-expanded="true"
                                            aria-haspopup="listbox"
                                            aria-owns="downshift-17-menu"
                                            aria-labelledby="downshift-17-label"
                                        >
                                            <ul className="ana_chart_dropdown_right_entity">
                                                {displayItems.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            selectedDate(item.date)
                                                            // selectedDate(item)
                                                        }}
                                                    >
                                                        <p>{item.label}</p>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                className="option-input radio"
                                                                name="example"
                                                                onChange={() => {}}
                                                                checked={dateFilter === item.date}
                                                            />
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </Downshift>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DropdownContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 66% 34%;
    height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 20px;

    ${DropdownUlWrapper} {
        margin-bottom: 0px;
    }
    ${InputContainer} {
        margin-bottom: 0px;
        ${Input} {
            padding-right: 30px;
        }
    }

    .tab_container_left {
        display: grid;
        grid-template-columns: 180px 180px 180px;
        grid-gap: 20px;
    }
    .tab_container_right {
        display: grid;
        grid-template-columns: 180px auto;
        justify-content: flex-end;
        .prev_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
                fill: #555;
                transform: rotate(90deg);
            }
        }
        .next_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                fill: #555;
                cursor: pointer;
                transform: rotate(-90deg);
            }
        }
        p {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
        }
    }
`
export const Dropdwon = (props: any) => {
    const [open, setOpen] = useState(false)

    return (
        <DropdownWrapper className={props.className}>
            <InputContainer className='train_chatbot_dropdown'>
                <Input onClick={() => setOpen(true)} type="text" value={props.value} />
                <InputContent>{props.header}</InputContent>
                <CancelButton onClick={() => setOpen(true)}>
                    <DropdownWithCircle />
                </CancelButton>
                {open && (
                    <Downshift isOpen={open} onOuterClick={() => setOpen(false)}>
                        {() => (
                            <span>
                                <DropdownUlWrapper>
                                    <DropdownUl className="trainning_dropdown">
                                        {props.list &&
                                            props.list.map((val: { label: string; value: string }, i: number) => (
                                                <DropdownLi
                                                    key={i}
                                                    onClick={() => {
                                                        if((val.value as any).date == 'custom'){
                                                            props.showCustomDatePicker()
                                                        }
                                                        props.forAutomationMarketplace ? props.onClick(val) : props.onClick(val.value)
                                                        setOpen(false)
                                                    }}
                                                >
                                                    {val.label}
                                                </DropdownLi>
                                            ))}
                                    </DropdownUl>
                                </DropdownUlWrapper>
                            </span>
                        )}
                    </Downshift>
                )}
            </InputContainer>
        </DropdownWrapper>
    )
}

export const DropdownWithSearch = (props: any) => {
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const { content, value, onClick, keys, placholder, returnAllData , size} = props

    const widthInPx = size === "responsive" ? "97%" : "auto";

    return (
        <DropdownWrapper>
            <InputContainer>
                <Input type="text" value={value} onClick={() => setOpen(true)} />
                <InputContent>{props.header}</InputContent>
                <CancelButton style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
                    <DropdownWithCircle />
                </CancelButton>
                {open && (
                    <Downshift isOpen={true} onOuterClick={() => setOpen(false)}>
                        {() => (
                            <span className="home_chatbot_upload_dropdown" style={{top: 48, right: 12 , width : widthInPx}}>
                                <DropdownUlWrapper>
                                    <DropdownUl style={{ overflow: 'hidden', maxHeight: 'none', marginTop: 0 }} size={props.size}>
                                        <SerachInputWrapper>
                                            <SerachInput
                                                type="search"
                                                placeholder={placholder}
                                                value={search}
                                                onChange={(e: any) => setSearch(e.target.value)}
                                                size={props.size}
                                            />
                                            <span>
                                                <SearchIcon />
                                            </span>
                                        </SerachInputWrapper>
                                        <ScrollUl>
                                            {content
                                                .filter(
                                                    (c: any) =>
                                                        c[`${keys}`].toLowerCase().indexOf(search.toLowerCase()) != -1
                                                )
                                                .map((intent: any, i: number) => (
                                                    <DropdownLi
                                                        key={i}
                                                        onClick={() => {
                                                            setOpen(false)
                                                            onClick(returnAllData ? intent : intent[`${keys}`])
                                                        }}
                                                        className="train_faq_li"
                                                    >
                                                        {intent[`${keys}`]}
                                                    </DropdownLi>
                                                ))}
                                        </ScrollUl>
                                    </DropdownUl>
                                </DropdownUlWrapper>
                            </span>
                        )}
                    </Downshift>
                )}
            </InputContainer>
        </DropdownWrapper>
    )
}

export const DropdownUl = styled.div`
    width: ${(props: any) => (props.size === 'small' ? '200px' : '100%')};
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    margin-top: 0px;
    right: -10px;
    cursor: pointer;
    padding: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 216px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
    margin-top: 30px;
`

export const DropdownUlForLogs = styled.div`
    width: ${(props: any) => (props.size === 'small' ? '200px' : 'auto')};
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    margin-top: 0px;
    right: -12px;
    cursor: pointer;
    padding: 0px;
    max-height: 212px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
    margin-top: 30px;

    ${DropdownLi}{
        width: 100%;
        float: left;
        border-bottom: 1px solid #b6b6b6;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        height: 36px;
        padding: 8px 10px;
        background: #fff;
        cursor: pointer !important;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;

        :hover{
            background: #ddfeef;
            color: #333;
            border-bottom: 1px solid #ddfeef;
        }
    }
`

export const SerachInputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;

    span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 17px;
        left: 14px;
        svg {
            width: 18px;
            height: 18px;
            color: #000;
            fill: #000;
        }
    }
`
export const SerachInput = styled.input`
  width: ${(props: any) => (props.size === 'responsive' ? '100%' : '180px')};
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  padding: 3px 12px 0px 36px;
  font-size: 14px;
  font-family: Gordita-Regular;
  color: #000;
  :placeholder {
    color: #999;
  }
  :hover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #009d5f;
  }
`;
const ContentLeft = styled.div`
    width: auto;
    float: left;
`
const TrainButton = styled.button`
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: 1px solid #cecece;
    border-radius: 12px;
    color: #000;
    width: auto;
    height: 20px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
    }
`
const ContentEllipse = styled.div`
    width: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${ContentLeft} {
        width: auto;
        float: left;
    }
`
const LabelImage = styled.div`
    width: 8px;
    float: left;
    height: 8px;
    margin-left: 6px;
    margin-bottom: 0px;
    position: relative;
    top: -1px;
    svg {
        width: 8px;
        height: 8px;
        float: left;
        margin-left: 0px;
    }
`
const ActionSpan = styled.div`
    width: auto;
    float: left;
    svg {
        width: 12px;
        height: 12px;
        color: #666;
        fill: #666;
    }
`

const ActionWrapper = styled.div`
    width: auto;
    float: right;
    display: none;
    ${ActionSpan} {
        :first-child {
            margin-right: 6px;
            margin-left: 6px;
        }
    }
`
const OpenSubTable = styled.div`
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    display: block;
    width: 100%;
    float: left;
    margin: -1px 0px;

    .no_data_available {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        p {
            font-size: 14px;
            font-family: 'Gordita-Regular';
            color: #000;
        }
    }

    ${TdContent} {
        svg {
            display: block;
            transform: rotate(90deg);
        }
    }

    ${Thead} {
        ${Tr} {
            box-shadow: none !important;
            background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px 10px 0px 0px;
            height: 48px;
            display: flex;
            align-items: end;
            :first-child {
                ${LabelImage} {
                    width: 8px;
                    float: left;
                    height: 8px;
                    margin-left: 6px;
                    margin-bottom: 0px;
                    position: relative;
                    top: -1px;
                    display: flex;
                    svg {
                        width: 8px;
                        height: 8px;
                        float: left;
                        margin-left: 0px;
                    }
                }
            }
            :hover {
                box-shadow: none !important;
                background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px 10px 0px 0px;
            }
        }
        ${Th} {
            color: #333;
            font-size: 12px;
            height: 48px;
            span {
                position: relative;
                top: 0px;
            }
            ${LabelImage} {
                top: 0px;
                transform: rotate(90deg);
            }
            :last-child {
                justify-content: center;
            }
        }
    }

    :hover {
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 15px #00000033 !important;
        border-radius: 10px;
        ${Thead} {
            ${Tr} {
                box-shadow: none;
                background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
            }
        }
        ${Tr} {
            background: #fff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #00000029;

            ${TdContent} {
                svg {
                    display: block;
                }
            }
        }
    }

    .is_active_tr {
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box !important;
        border-radius: 10px 10px 0px 0px;
        :hover {
            background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        }
    }
`
const Count = styled.span`
    font-family: 'Gordita-Regular';
    color: #fd7a00;
    font-size: 12px;
    margin-left: 3px;
`

const Tbody = styled.tbody`
    width: 100%;
    float: left;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow-y: scroll;
    max-height: calc(100vh - 350px);
    border-radius: 10px;

    .medium_loader {
        width: 48px !important;
        height: 48px !important;
    }

    ${Tr} {
        background: #ffffff 0% 0% no-repeat padding-box;
        padding: 0px 20px;
        min-height: 48px;
        line-height: normal;
        display: flex;
        border-bottom: 1px solid #e0e0e0;
        ${SmallButton} {
            justify-content: center;
        }
        ${Td} {
            padding-right: 15px;
            font-size: 12px;
            color: #000000;
            justify-content: center;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 16px;

            .irrelevant {
                color: #fd7a00;
            }
            :nth-child(1) {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: flex-start;
            }
            .td_date_wrapper {
                padding-right: 0px;
                justify-content: left;
                font-size: 9px;
            }
            .td_email {
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 70%;
                @media (max-width: 1441px) {
                    width: 65%;
                }
                @media (max-width: 1370px) {
                    width: 45%;
                }
            }
        }

        :hover {
            background: #ddfeef 0% 0% no-repeat padding-box;
            z-index: 1;
            position: relative;
            top: 0px;
            ${ActionWrapper} {
                display: flex;
            }
            ${TdContent} {
                svg {
                    display: block;
                }
            }
            .option-input.radio {
                border: 1px solid #2468f6 !important;
                visibility: visible !important;
            }
        }
    }

    .is_opacity_wrapper {
        width: 100%;
        float: left;
    }
    .is_opacity {
        opacity: 0.2;
        box-shadow: none;
        pointer-events: none;
        width: 100%;
        float: left;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    float: left;
    ${Table} {
        margin-top: 0px;
    }
    ${Thead} {
        ${Tr} {
            height: 48px;
            ${Th} {
                display: flex;
                align-items: center;
                .th_header {
                    display: flex;

                    position: relative;
                    align-items: flex-start;

                    label {
                        position: relative;
                        top: 0px;
                        margin: 0;
                    }
                    ${LabelImage} {
                        left: 10px;
                        margin: 0px;
                        top: 2px;
                    }

                    ${Count} {
                        top: 0px;
                        color: #fd7a00;
                    }
                }
                :nth-child(2) {
                    span {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        color: #000;
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #355d8b;
                            color: #355d8b;
                        }
                    }
                }
                :nth-child(3) {
                    span {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        color: #000;
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #355d8b;
                            color: #355d8b;
                        }
                    }
                }
                :nth-child(1) {
                    span {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        color: #000;
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #355d8b;
                            color: #355d8b;
                        }
                    }
                }
            }
        }
    }
    ${Tbody} {
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 290px);

        .no_data_available {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 253px);
            overflow: hidden;

            p {
                font-size: 14px;
                font-family: 'Gordita-Regular';
                color: #000;
            }
        }

        ${Thead} {
            height: 48px;
            ${Tr} {
                height: 48px;
                :hover {
                    box-shadow: none;
                }
            }
        }
        ${Tr} {
            height: auto;

            ${Td} {
                padding: 0px;
                height: auto;
                font-size: 12px;
                font-family: 'Gordita-Regular';
                display: flex;
                padding-top: 24px;

                :nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 0px;
                    justify-content: center;
                    // position: relative;
                    
                }
                :nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-right: 12px;
                    // position: relative;
                    
                    ${ContentEllipse} {
                        display: flex;
                        align-items: center;
                    }
                }
                :nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-right: 12px;
                    // position: relative;
                    ${ContentEllipse} {
                        display: flex;
                    }
                }

                :last-child {
                    justify-content: flex-start;
                    align-items: center;
                }

                .td_header {
                    font-size: 12px;
                    color: #000;
                    margin-bottom: 3px;
                    width: 100%;
                    margin-top: 10px;

                    span:nth-child(1) {
                        word-break: break-word;
                    }
                }
                .td_date {
                    font-size: 9px;
                    color: #355d8b;
                    line-height: 7px;
                    margin-top: 3px;
                    // margin-left: 10px;   
                    margin-bottom: 10px;
                    overflow: visible;
                }
                .td_cc_img {
                    width: 24px;
                    height: 24px;
                    margin-right: 6px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
`

const AscendingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10.8">
        <title>sort</title>
        <g id="Layer_2_1_">
            <g id="Layer_1-2">
                <path d="M15.3,0H0.8C0.3,0,0,0.3,0,0.8c0,0.4,0.3,0.7,0.8,0.8h14.5c0.4,0,0.7-0.3,0.8-0.8C16,0.3,15.7,0,15.3,0z" />
                <path d="M8,4.7H0.8C0.3,4.7,0,5,0,5.4s0.3,0.8,0.8,0.8H8c0.4,0,0.8-0.3,0.8-0.8S8.4,4.7,8,4.7z" />
                <path d="M8,9.3H0.8C0.3,9.3,0,9.6,0,10s0.3,0.8,0.8,0.8H8c0.4,0,0.8-0.3,0.8-0.8S8.4,9.3,8,9.3z" />
                <path d="M14.4,7.9c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l-2-2c-0.1-0.1-0.3-0.2-0.5-0.2h-0.1h-0.1l0,0 c-0.1,0-0.2,0.1-0.3,0.2l-2,2c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l0.7-0.7V10c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8 V7.2L14.4,7.9L14.4,7.9z" />
            </g>
        </g>
    </svg>
)

const DescendingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10.79">
        <title>sort</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M15.25,0H.75A.76.76,0,0,0,0,.75a.76.76,0,0,0,.75.75h14.5A.76.76,0,0,0,16,.75.76.76,0,0,0,15.25,0Z" />
                <path d="M8,4.65H.75a.75.75,0,0,0,0,1.5H8a.75.75,0,0,0,0-1.5Z" />
                <path d="M8,9.29H.75a.75.75,0,0,0,0,1.5H8a.75.75,0,0,0,0-1.5Z" />
                <path d="M14.41,7.49l-.74.74V5.39a.75.75,0,1,0-1.5,0V8.23l-.73-.74a.75.75,0,0,0-1.06,0,.77.77,0,0,0,0,1.07l2,2a.7.7,0,0,0,.26.19h0l.14,0h.15a.74.74,0,0,0,.53-.22l2-2a.75.75,0,1,0-1.06-1.07Z" />
            </g>
        </g>
    </svg>
)

export const TableUiContainer = (props: any) => {
    const {
        Tbodycontent,
        order,
        changeOrder,
        intents,
        updateMetadata,
        entities,
        workspacename,
        assistantId,
        openConvo,
        setLogs,
        createOrUpdateEntityMap,
        setDetailIndex,
        detailIndex,
        metadata,
        groupBy,
        currentAssistant,
        dateFilter,
        isTryItOpen,
        faqs,
        updateFaqMetadata,
        disable
    } = props

    const queryClient = useQueryClient()

    const [popUpLogs, setPopUpLogs] = useState<any>([])

    const [currentLog, setCurrentLog] = useState<null | any>(null)

    const [selectedIntent, setSelectedIntent] = useState<string>('')
    const [selectedFAQ, setSelectedFAQ] = useState<FAQT | {}>({})

    const [searchKey, setSearchKey] = useState<string>('')
    const [openDeatilsPopup, setOpenDeatilsPopup] = useState<boolean>(false)

    const [selectWord, setSelectWord] = useState<string>('')
    const [selectWordLocation, setSelectWordLocation] = useState<any>([])

    const [selectEntity, setSelectEntity] = useState([{ entity: '', value: '' }])
    const [selectedValue, setSelectedValue] = useState<string>('')
    const [elementsOrder, setElementsOrder] = useState<OrderT>(DESCENDING)
    const [loading, setPageLoading] = useState<boolean>(false)
    const [entityTrainError, setEntityTrainError] = useState<null | string>(null)
    const isTryMeOpen = usePrevious(isTryItOpen)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    useEffect(() => {
        if (currentLog && openDeatilsPopup) {
            setPageLoading(true)
            queryClient
            .fetchQuery(['trainingdetaillogs', assistantId, groupBy == 'user_email'
                ? currentLog.user_email
                : groupBy == 'user_text'
                ? currentLog.user_text._id
                : currentLog.intents[0].intent
                ? JSON.stringify({ intent: currentLog.intents[0].intent })
                : 'irrelevant'], 
            () =>
                getJson(
                    watsonLogsApiURL(
                        `/${workspacename}/logs/${assistantId}/${returnDate(dateFilter)}?sort_by_created_time=${
                            elementsOrder == DESCENDING ? -1 : 1
                        }&page_no=1&no_of_records=50&${groupBy == 'intent' ? 'used_intent' : groupBy}=${
                            groupBy == 'user_email'
                                ? currentLog.user_email
                                : groupBy == 'user_text'
                                ? currentLog.user_text._id
                                : currentLog.intents[0].intent
                                ? JSON.stringify({ intent: currentLog.intents[0].intent })
                                : 'irrelevant'
                        }&timezone=${timezone}`
                    )
                )
            )
                // getJson(
                //     `https://dev-assistant.workativ.ai/dialog-logs/skitter/logs/434981d3-5ec6-449b-ae12-08f6eca28d4c/30?page_no=1&no_of_records=50&${
                //         groupBy == 'intent' ? 'used_intent' : groupBy
                //     }=${
                //         groupBy == 'user_email'
                //             ? currentLog.user_email
                //             : groupBy == 'user_text'
                //             ? currentLog.user_text._id
                //             : { intent: currentLog.intents[0].intent }
                //     }`
                // )
                .then(response => {
                    setPopUpLogs(response)
                    setPageLoading(false)
                })
                .catch(() => {
                    setPageLoading(false)
                })
        }
    }, [currentLog])

    const changeOrderFilter = (element: OrderT) => {
        setPageLoading(true)

        getJson(
            watsonLogsApiURL(
                `/${workspacename}/logs/${assistantId}/${returnDate(dateFilter)}?sort_by_created_time=${
                    elementsOrder == DESCENDING ? -1 : 1
                }?page_no=1&no_of_records=50&${groupBy == 'intent' ? 'used_intent' : groupBy}=${
                    groupBy == 'user_email'
                        ? currentLog.user_email
                        : groupBy == 'user_text'
                        ? currentLog.user_text._id
                        : JSON.stringify({ intent: currentLog.intents[0].intent })
                }&timezone=${timezone}`
            )
        )
            // getJson(
            //     `https://dev-assistant.workativ.ai/dialog-logs/skitter/logs/434981d3-5ec6-449b-ae12-08f6eca28d4c/30?page_no=1&no_of_records=50&${
            //         groupBy == 'intent' ? 'used_intent' : groupBy
            //     }=${
            //         groupBy == 'user_email'
            //             ? currentLog.user_email
            //             : groupBy == 'user_text'
            //             ? currentLog.user_text._id
            //             : { intent: currentLog.intents[0].intent }
            //     }`
            // )
            .then(response => {
                openDeatilsPopup && setPopUpLogs(response)
                setElementsOrder(element)
                setPageLoading(false)
            })
            .catch(() => {
                setPageLoading(false)
            })
    }

    const getDefaultMap = () => ({
        ...currentLog,
        trained_time: new Date()
    })

    const mapFAQ = async () => {
        const selectedFaqMap =
            currentLog.user_text && currentLog.user_text.trained_faq
                ? faqs.filter((e: FAQT) => e.title == currentLog.user_text.trained_faq.faq)[0]
                : undefined
        const text: string = currentLog.text.toLowerCase().trim()

        if (selectedFaqMap) {
            await updateTrainingFAQ(
                workspacename,
                assistantId,
                (selectedFAQ as FAQT).uuid,
                {
                    faq: (selectedFAQ as FAQT).title,
                    prompts: text
                },
                selectedFaqMap.uuid
            )
        } else {
            await updateTrainingFAQ(workspacename, assistantId, (selectedFAQ as FAQT).uuid, {
                faq: (selectedFAQ as FAQT).title,
                prompts: text
            })
        }
        queryClient.invalidateQueries(['dialogRoots', assistantId])

        updateFaqMetadata((selectedFAQ as FAQT).title, currentLog._id)

        setSelectedFAQ({})
        setSearchKey('')
    }

    const removeFAQ = async (faq: any, currentLog: any) => {
        const selectedFaqMap =
            currentLog.user_text && currentLog.user_text.trained_faq
                ? faqs.filter((e: FAQT) => e.title == currentLog.user_text.trained_faq.faq)[0]
                : undefined
        const text: string = currentLog.text.toLowerCase().trim()

        if (selectedFaqMap) {
            await removeTrainingFAQ(workspacename, assistantId, (selectedFaqMap as FAQT).uuid, {
                faq: (selectedFaqMap as FAQT).title,
                prompts: text
            })
        }
        queryClient.invalidateQueries(['dialogRoots', assistantId])
        queryClient.invalidateQueries(['dialogs', assistantId])
        queryClient.invalidateQueries(['traininglogs', assistantId])

        updateFaqMetadata(undefined, currentLog._id)
    }

    const mapIntent = async (intent: string) => {
        const obj = getDefaultMap()

        const intentsMap = currentLog.intents.filter((e: any) => e.intent != selectedIntent)
        obj['intents'] = [...intentsMap, { intent }]
        const selectedIntentMap = currentLog.user_text.trained_intent
            ? intents.filter((e: IntentT) => e.intent == currentLog.user_text.trained_intent.intent)[0]
            : intents.filter((e: IntentT) => currentLog.intents.indexOf(e.intent) != -1)[0]

        if (selectedIntentMap) {
            await Promise.all(
                selectedIntentMap.examples
                    .filter((e: any) => e.text.toLowerCase().trim() == currentLog.text.toLowerCase().trim())
                    .map((e: any) =>
                        deleteIntentExample(
                            workspacename,
                            assistantId,
                            selectedIntentMap.id,
                            e.id,
                            selectedIntentMap.intent
                        )
                    )
            )
        }
        const currentIntent = intents.filter((e: IntentT) => e.intent == intent)[0]
        if (currentIntent) {
            const text: string = currentLog.text.trim()
            if (currentIntent.examples.filter((e: any) => e.text.trim() == text).length === 0) {
                await createIntentExample(workspacename, assistantId, currentIntent.id, [text], currentIntent.intent)
            }
        }

        queryClient.invalidateQueries(['intents', assistantId])

        updateMetadata(intent, currentLog._id)

        setSelectedIntent('')
        setSearchKey('')
    }

    const removeIntent = async (intent: string, currentLog: any) => {
        const obj = getDefaultMap()

        const intentsMap = currentLog.intents.filter((e: any) => e.intent != intent)
        const selectedIntentMap = currentLog.user_text.trained_intent
            ? intents.filter((e: IntentT) => e.intent == currentLog.user_text.trained_intent.intent)[0]
            : intents.filter((e: IntentT) => currentLog.intents[0].intent == e.intent)[0]

        obj['intents'] = intentsMap
        if (selectedIntentMap) {
            await Promise.all(
                selectedIntentMap.examples
                    .filter((e: any) => e.text.toLowerCase().trim() == currentLog.text.toLowerCase().trim())
                    .map((e: any) =>
                        deleteIntentExample(
                            workspacename,
                            assistantId,
                            selectedIntentMap.id,
                            e.id,
                            selectedIntentMap.intent
                        )
                    )
            )
        }
        queryClient.invalidateQueries(['intents', assistantId])
        queryClient.invalidateQueries(['traininglogs', assistantId])

        updateMetadata(undefined, currentLog._id)
    }

    const getMapEntity = (loc: any) => {
        if (currentLog.trained_entity && currentLog.trained_entity.filter((v: any) => R.equals(v.location, loc))[0]) {
            return currentLog.trained_entity.filter((v: any) => R.equals(v.location, loc))[0].entity
        } else if (currentLog.entities && currentLog.entities.filter((v: any) => R.equals(v.location, loc))[0]) {
            return currentLog.entities.filter((v: any) => R.equals(v.location, loc))[0].entity
        }
        return ''
    }

    const getMapValue = (loc: any) => {
        if (currentLog.trained_entity && currentLog.trained_entity.filter((v: any) => R.equals(v.location, loc))[0]) {
            return currentLog.trained_entity.filter((v: any) => R.equals(v.location, loc))[0].value
        } else if (currentLog.entities && currentLog.entities.filter((v: any) => R.equals(v.location, loc))[0]) {
            return currentLog.entities.filter((v: any) => R.equals(v.location, loc))[0].value
        }
        return ''
    }

    const mapEntity = async () => {
        const loc = [currentLog.text.indexOf(selectWord), currentLog.text.indexOf(selectWord) + selectWord.length]

        const previousEntity = getMapEntity(loc)
        const previousValue = getMapValue(loc)

        const obj = getDefaultMap()

        console.log(previousEntity)
        obj.trained_entity =
            !currentLog.trained_entity || currentLog.trained_entity.length == 0
                ? [
                      {
                          location: loc,
                          _id: '',
                          entity: selectEntity,
                          value: selectedValue,
                          trained_date_time: new Date()
                      }
                  ]
                : currentLog.trained_entity.map((t: any) =>
                      R.equals(t.location, loc)
                          ? { ...t, entity: selectEntity, value: selectedValue, trained_date_time: new Date() }
                          : t
                  )

        selectEntity.map(async entit => {
            const currentEntity = entities.filter((e: EntityT) => e.entity == entit.entity)[0]
            if (currentEntity) {
                const val = currentEntity.values.filter(
                    (v: any) => v.value.toLowerCase() == entit.value.toLowerCase()
                )[0] as any
                if (val) {
                    await updateEntityValue(
                        workspacename,
                        assistantId,
                        currentEntity.id,
                        val.id,
                        {
                            type: 'synonyms',
                            value: val.value,
                            synonyms: val.synonyms.length > 0 ? [...val.synonyms, selectWord] : [selectWord]
                        },
                        currentEntity.entity
                    )
                        .then(() => {
                            currentLog.trained_entity &&
                            currentLog.trained_entity.filter((t: any) => R.equals(t.location, loc))
                                ? createOrUpdateEntityMap(
                                      loc,
                                      selectEntity,
                                      currentLog.log_id,
                                      currentLog.trained_entity.filter((t: any) => R.equals(t.location, loc))[0]
                                  )
                                : createOrUpdateEntityMap(loc, selectEntity, currentLog.log_id)
                            queryClient.invalidateQueries(['entities', assistantId])

                            setSelectWord('')
                            setSelectedValue('')
                            setSelectEntity([{ entity: '', value: '' }])
                            setSelectWordLocation([])
                            setEntityTrainError(null)
                        })
                        .catch((error: any) => {
                            setEntityTrainError(
                                error.response && error.response.data.message
                                    ? error.response.data.message
                                    : 'Something went wrong! Please try again.'
                            )
                        })
                }
            }
        })
    }

    const renderLogs = (logs: any) => {
        return logs.map((log: any) => mapLogs(log))
    }

    const content = renderLogs(popUpLogs).filter((e: any) => e.text != '')

    return (
        <TableWrapper>
            <TableContainer>
                <Table>
                    <Thead>
                        {groupBy == 'user_text' ? (
                            <Tr className={openDeatilsPopup ? 'is_opacity' : ''} >
                                <Th size={3}>User utterances</Th>
                                <Th size={1.5}>
                                    FAQ{' '}
                                    <Tooltip
                                        className={'target_bot training_header_info'}
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        content={'Train the user utterance to add as user query in FAQ'}
                                    >
                                        <span>
                                            <InfoIcon />
                                        </span>
                                    </Tooltip>
                                </Th>
                                <Th size={1.5}>
                                    <span
                                        onClick={() =>
                                            order == ASCENDING ? changeOrder(DESCENDING) : changeOrder(ASCENDING)
                                        }
                                    >
                                        {order == ASCENDING ? <AscendingIcon /> : <DescendingIcon />}
                                    </span>
                                    Intent
                                    <Tooltip
                                        className={'target_bot training_header_info'}
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        content={'Train the user utterance to add as an example for Intent'}
                                    >
                                        <span>
                                            <InfoIcon />
                                        </span>
                                    </Tooltip>
                                </Th>
                                <Th size={1.5}>
                                    Entity
                                    <Tooltip
                                        className={'target_bot training_header_info'}
                                        tipContentHover
                                        zIndex={5000}
                                        arrowSize={5}
                                        content={
                                            'Train the user utterance to add as synonym for entity value in entity'
                                        }
                                    >
                                        <span>
                                            <InfoIcon />
                                        </span>
                                    </Tooltip>
                                </Th>
                                <Th size={1.5}>Date trained</Th>
                                <Th size={2}>User email</Th>
                                <Th contentCenter size={1}>
                                    Conversation
                                </Th>
                            </Tr>
                        ) : groupBy == 'user_email' ? (
                            <Tr>
                                <Th size={2.5}>User email</Th>
                                <Th size={3}>User utterances</Th>
                                <Th size={1.5}>
                                    <span
                                        onClick={() =>
                                            order == ASCENDING ? changeOrder(DESCENDING) : changeOrder(ASCENDING)
                                        }
                                    >
                                        {order == ASCENDING ? <AscendingIcon /> : <DescendingIcon />}
                                    </span>
                                    Intent
                                </Th>
                                <Th size={1.5}>Entity</Th>
                                <Th size={2}>Date trained</Th>
                                <Th contentCenter size={1.5}>
                                    Conversation
                                </Th>
                            </Tr>
                        ) : (
                            <Tr>
                                <Th size={1.5}>
                                    <span
                                        onClick={() =>
                                            order == ASCENDING ? changeOrder(DESCENDING) : changeOrder(ASCENDING)
                                        }
                                    >
                                        {order == ASCENDING ? <AscendingIcon /> : <DescendingIcon />}
                                    </span>
                                    Intent
                                </Th>
                                <Th size={3}>User utterances</Th>
                                <Th size={1.5}>Entity</Th>
                                <Th size={2}>Date trained</Th>
                                <Th size={2.5}>User email</Th>
                                <Th contentCenter size={1.5}>
                                    Conversation
                                </Th>
                            </Tr>
                        )}
                    </Thead>
                    <Tbody>
                        {Tbodycontent.length > 0 ? (
                            Tbodycontent.map((e: any, i: any) => (
                                <div className="is_opacity_wrapper">
                                    <Tr className={openDeatilsPopup ? 'is_opacity' : ''}>
                                        <ListView
                                            key={i}
                                            log={e}
                                            entities={entities}
                                            setDetails={setDetailIndex}
                                            openConvo={openConvo}
                                            groupBy={groupBy}
                                            fromPopup={false}
                                            setCurrentLog={setCurrentLog}
                                            popupLogs={content}
                                            intents={intents}
                                            removeIntent={removeIntent}
                                            mapIntent={mapIntent}
                                            removeFAQ={removeFAQ}
                                            mapFAQ={mapFAQ}
                                            selectedIntent={selectedIntent}
                                            setSelectedIntent={setSelectedIntent}
                                            selectedFAQ={selectedFAQ}
                                            setSelectedFAQ={setSelectedFAQ}
                                            selectEntity={selectEntity}
                                            setSelectEntity={setSelectEntity}
                                            setSelectWord={setSelectWord}
                                            mapEntity={mapEntity}
                                            setOpenDeatilsPopup={setOpenDeatilsPopup}
                                            entityTrainError={entityTrainError}
                                            setEntityTrainError={setEntityTrainError}
                                            faqs={faqs}
                                        />
                                    </Tr>
                                    {openDeatilsPopup && R.equals(currentLog, e) && (
                                        <Downshift
                                            isOpen={true}
                                            onOuterClick={() => {
                                                if (!isTryItOpen || !isTryMeOpen) {
                                                    setPopUpLogs([])
                                                    setOpenDeatilsPopup(false)
                                                    setCurrentLog(null)
                                                }
                                            }}
                                        >
                                            {() => (
                                                <span>
                                                    <OpenSubTable>
                                                        <Thead>
                                                            {groupBy == 'user_text' ? (
                                                                <Tr>
                                                                    <Th
                                                                        size={3}
                                                                        onClick={() => {
                                                                            setPopUpLogs([])
                                                                            setOpenDeatilsPopup(false)
                                                                            setCurrentLog(null)
                                                                        }}
                                                                        className="cursor_pointer"
                                                                    >
                                                                        {' '}
                                                                        <div className="th_header">
                                                                            <label className="cursor_pointer">
                                                                                User utterances
                                                                            </label>{' '}
                                                                            <Count>({currentLog.count})</Count>{' '}
                                                                            <LabelImage>
                                                                                <NextIconCanvas />
                                                                            </LabelImage>
                                                                        </div>
                                                                    </Th>
                                                                    <Th size={1.5}>FAQ</Th>
                                                                    <Th size={1.5}>
                                                                        {' '}
                                                                        <span
                                                                            onClick={() =>
                                                                                order == ASCENDING
                                                                                    ? changeOrderFilter(DESCENDING)
                                                                                    : changeOrderFilter(ASCENDING)
                                                                            }
                                                                        >
                                                                            {order == ASCENDING ? (
                                                                                <AscendingIcon />
                                                                            ) : (
                                                                                <DescendingIcon />
                                                                            )}
                                                                        </span>
                                                                        Intent
                                                                    </Th>
                                                                    <Th size={1.5}>Entity</Th>
                                                                    <Th size={2}>Date trained</Th>
                                                                    <Th size={2.5}>User email</Th>
                                                                    <Th size={1.5}>Conversation</Th>
                                                                </Tr>
                                                            ) : groupBy == 'user_email' ? (
                                                                <Tr>
                                                                    <Th size={2.5}>
                                                                        {' '}
                                                                        <div className="th_header">
                                                                            <label>User email</label>{' '}
                                                                            <Count>({currentLog.count})</Count>{' '}
                                                                            <LabelImage>
                                                                                <NextIconCanvas />
                                                                            </LabelImage>
                                                                        </div>
                                                                    </Th>
                                                                    <Th size={3}>User utterances</Th>
                                                                    <Th size={1.5}>
                                                                        {' '}
                                                                        <span
                                                                            onClick={() =>
                                                                                order == ASCENDING
                                                                                    ? changeOrderFilter(DESCENDING)
                                                                                    : changeOrderFilter(ASCENDING)
                                                                            }
                                                                        >
                                                                            {order == ASCENDING ? (
                                                                                <AscendingIcon />
                                                                            ) : (
                                                                                <DescendingIcon />
                                                                            )}
                                                                        </span>
                                                                        Intent
                                                                    </Th>
                                                                    <Th size={1.5}>Entity</Th>
                                                                    <Th size={2}>Date trained</Th>
                                                                    <Th size={1.5}>Conversation</Th>
                                                                </Tr>
                                                            ) : (
                                                                <Tr>
                                                                    <Th size={1.5}>
                                                                        {' '}
                                                                        <div className="th_header">
                                                                            <span
                                                                                onClick={() =>
                                                                                    order == ASCENDING
                                                                                        ? changeOrderFilter(DESCENDING)
                                                                                        : changeOrderFilter(ASCENDING)
                                                                                }
                                                                            >
                                                                                {order == ASCENDING ? (
                                                                                    <AscendingIcon />
                                                                                ) : (
                                                                                    <DescendingIcon />
                                                                                )}
                                                                            </span>
                                                                            <label>Intent</label>{' '}
                                                                            <Count>({currentLog.count})</Count>{' '}
                                                                            <LabelImage>
                                                                                <NextIconCanvas />
                                                                            </LabelImage>
                                                                        </div>
                                                                    </Th>
                                                                    <Th size={3}>User utterances</Th>
                                                                    <Th size={1.5}>Entity</Th>
                                                                    <Th size={2}>Date trained</Th>
                                                                    <Th size={2.5}>User email</Th>
                                                                    <Th size={1.5}>Conversation</Th>
                                                                </Tr>
                                                            )}
                                                        </Thead>
                                                        <PromptButton>
                                                            {content.map((e: any, i: any) => (
                                                                // <div className="is_opacity_wrapper">
                                                                <Tr>
                                                                    <ListView
                                                                        key={i}
                                                                        log={e}
                                                                        entities={entities}
                                                                        setDetails={setDetailIndex}
                                                                        openConvo={openConvo}
                                                                        groupBy={groupBy}
                                                                        fromPopup={true}
                                                                        intents={intents}
                                                                        removeIntent={removeIntent}
                                                                        mapIntent={mapIntent}
                                                                        removeFAQ={removeFAQ}
                                                                        mapFAQ={mapFAQ}
                                                                        selectedIntent={selectedIntent}
                                                                        setSelectedIntent={setSelectedIntent}
                                                                        selectedFAQ={selectedFAQ}
                                                                        setSelectedFAQ={setSelectedFAQ}
                                                                        selectEntity={selectEntity}
                                                                        setSelectEntity={setSelectEntity}
                                                                        setSelectWord={setSelectWord}
                                                                        mapEntity={mapEntity}
                                                                        faqs={faqs}
                                                                    />
                                                                </Tr>
                                                                // </div>
                                                            ))}
                                                        </PromptButton>
                                                        {content.length == 0 && (
                                                            <Tbody
                                                                style={{
                                                                    height: 140,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {loading ? (
                                                                    <Loader.PopupLoader
                                                                        show={true}
                                                                        className={'medium_loader'}
                                                                    />
                                                                ) : (
                                                                    <div className="no_data_available">
                                                                        <p style={{ margin: 0 }}>No data available</p>
                                                                    </div>
                                                                )}
                                                            </Tbody>
                                                        )}
                                                    </OpenSubTable>
                                                </span>
                                            )}
                                        </Downshift>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="no_data_available">
                                <p>No data available</p>
                            </div>
                        )}

                        {/* <div className="is_opacity_wrapper">
                            <Tr className="is_opacity">
                                <Td size={3}>
                                    <TdContent className="td_header">
                                        Unlock account <Count>(06)</Count>{' '}
                                        <LabelImage>
                                            <NextIconCanvas />
                                        </LabelImage>
                                    </TdContent>
                                    <div className="td_date">Created@ 25 May 2021 | 09:20PM</div>
                                </Td>
                                <Td size={3}>Unlock_acc</Td>
                                <Td size={3}>
                                    <div className="irrelevant">Irrelevant</div>
                                </Td>
                                <Td size={3}>25 May 2021 | 09:32PM</Td>
                            </Tr>
                        </div> */}
                    </Tbody>
                </Table>
            </TableContainer>
        </TableWrapper>
    )
}

const TrainHeader = styled.h4`
    width: 100%;
    float: left;
    justify-content: left;
    font-family: 'Gordita-Medium';
    color: #000;
    font-size: 16px;
    margin-bottom: 10px;
`
const TrainBox = styled.div`
    width: 100%;
    float: left;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px;
    :last-child {
        margin-bottom: 0px;
    }
    .connect_account_failed {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 12px;
        top: 14px;

        svg {
            width: 16px;
            height: 16px;
            fill: #eb6453;
        }
    }
    ${InputContainer} {
        margin-bottom: 10px;
        :last-child {
            margin-bottom: 0px;
        }
    }
    ${DropdownWrapper} {
        margin-bottom: 10px;
        :last-child {
            margin-bottom: 0px;
        }
    }
    ${DropdownUlWrapper} {
        margin-bottom: 10px;
        :last-child {
            margin-bottom: 0px;
        }
    }
`
const Emptydiv = styled.div`
    width: 100%;
    float: left;
    ${SmallButton} {
        justify-content: left;
        margin-bottom: 20px;
        margin-top: 12px;
        :hover {
            border: 1px solid #fff3eb !important;
        }
    }
`
const TrainPopupWrapper = styled.div`
    float: left;
    width: 360px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    padding: 30px 20px;
    // position: absolute;
    z-index: 10;
    margin-top: -10px;

    ${SerachInputWrapper} {
        padding: 12px 10px;
        background: #fff;
        border-bottom: 1px solid #e2e2e2;
        span {
            top: 22px;
            left: 22px;
        }
        ${SerachInput} {
            width: 100%;
        }
    }

    ${ButtonWrapper} {
        // background: #f4f4f4 0% 0% no-repeat padding-box;
        // border-radius: 0px 0px 10px 10px;
        // padding: 20px 0px;
        ${Button} {
            :fitrst-child {
                :hover {
                    border: 1px solid #ffe5d3 !important;
                }
            }
        }
        label {
            text-align: center;
        }
    }
`

const EntityWrapper = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    width: 360px;
    padding: 30px 20px;
    // position: absolute;
    z-index: 10;
    height : 300px
    margin :10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    ${SerachInputWrapper} {
        padding: 6px 10px;
        background: #fff;
        border-bottom: 1px solid #e2e2e2;
        span {
            top: 18px;
            left: 22px;
        }
    }
`
