/*eslint-disable*/
import React, { useEffect } from 'react'

import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'

import { MarketPlaceApi, automationAPIURLWithWorkspace, wrapperGetJson } from '@/common/utils/api-utils'
import { MarketPlaceFlowCanvas } from './MarketPlaceFlowCanvas'

export function MarketFlowCanvasWrapper(props: any) {
    const flowId = props.match.params.id

    const workspaceName = props.match.params.workspacename

    const [state, setState, promiseRef] = useFetchFromApi(() =>
        wrapperGetJson(
            automationAPIURLWithWorkspace(workspaceName, `${MarketPlaceApi.AutomationDetail}/${flowId}`)
        ).then((response: any) => response.data)
    )

    useEffect(() => {
        const flowId = props.match.params.id
        promiseRef.current = wrapperGetJson(
            automationAPIURLWithWorkspace(workspaceName, `${MarketPlaceApi.AutomationDetail}/${flowId}`)
        ).then((response: any) => response.data)
    }, [props.match.params.id])

    useEffect(() => {
        if (promiseRef.current != null) {
            const setStateDispatch = setState as React.Dispatch<any>
            setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
        }
    }, [promiseRef.current])

    const retry = () => {
        const setStateDispatch = setState as React.Dispatch<any>
        setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
    }

    return <MarketPlaceFlowCanvas state={state} retry={retry} {...props} />
}
