import {
  Button,
  ButtonContainer,
  Input,
  InputContainer,
  InputContent,
} from '@/common/styled/Workflow.Dumb';
import { useEffect, useRef, useState } from 'react';
import mfa_mail_img from '@/common/images/mfa_mail.png';
import { getJson, getJsonwithCredentials, loginApiURL, postJson } from '@/common/utils/api-utils';
import Tooltip from 'react-tooltip-lite';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import React from 'react';
import { authorizeToken } from '@/common/utils/auth-token-api-utils';
import Axios from 'axios';
import { Modal } from '@/common/components/Modal'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'

export enum OtpStatus{
    Error ="error",
    OtpCalling="otpcall",
    OtpSent="otpsent",
    WrongOtp="wrongotp",
    ResendOtp= "resendotp",
    LogOut= "logOut",
    MaxOtp = "maxOtp"
}

console.log("Mfa page calling")

export default function Multifactor(props:any) {
  const { workspacename } = props.match.params
  const router = props.history
  const [otp, setOtp] = useState<string>('')
  const [otpvalue,setOtpValue] = useState(OtpStatus.OtpCalling)
  const [counter, setCounter] = useState(0);
  console.log(otpvalue,'otpValue')

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  // const [otpSent,setOtpSent] = useState<boolean>(true)
  // const [resendOtp,setResendOtp] = useState<boolean>(false)
  // const [wrongOtp,setWrongOtp] = useState<boolean>(false)

  // useEffect(() => {
  //   postJson(loginApiURL('/users/sendOtpForMFA'))({})
  //   .then(()=>{
  //     // console.log("otpcalled")
  //   })
  //   .catch((e)=>{
  //     // console.log(e)
  //     // setOtpSent(false)
  //     setOtpValue(OtpStatus.Error)
  //   })
  // },[])

  const { initializedMFA } = props

  useEffect(() => {
      if(!initializedMFA.current){
        initializedMFA.current = true
        callOtpAPI()
      }
  },[])

  function callOtpAPI(){
    postJson(loginApiURL('/users/sendOtpForMFA'))({})
    .then(()=>{
      setOtpValue(OtpStatus.OtpSent)
    })
    .catch((e)=>{
      if (e.response) {
        setOtpValue(OtpStatus.MaxOtp)
      } else{
      console.log(e,e.status,'otperror')
      setOtpValue(OtpStatus.Error)
      }
    })
  }

  const verifyOtp = ()=>{
    postJson(loginApiURL('/users/verifyOtpForMFA'))({ otp: otp })
    .then((data)=>{
      // if(data.data.data === "success"){
        Axios.get(loginApiURL('/auth-tokens/access_token'), {
        withCredentials: true,
      })
        .then((data)=>{
          const token = data.data;
          authorizeToken(token)
          router.push(`/workspace/${workspacename}`)})
    }).catch((e)=>{
        setOtpValue(OtpStatus.WrongOtp)
        // setWrongOtp(true)
        })
  }

  const logOut =() => {
        // Promise.resolve('logout')
        getJsonwithCredentials(loginApiURL('/logout'))
            .then((response: any) => {
                postMessage({ logout: true })
                window.location.reload()
            })
            .catch(err => {})
    }  
  return (
    <section className="mfa_bg">
      <div className="mfa_logo">
        <img
          src="https://pp-assistant.workativ.ai/auth_static/static/media/wa_logo.cf670c27.svg"
          alt="logo"
        />
      </div>
    
      <div className="mfa_container">
        <div className="mfa_content">
          {otpvalue === OtpStatus.Error &&
          <div className='otp_alert_content'>
            <div><WarningAlertIcon /></div>
          <span>Issue in sending OTP to your email</span>
          </div>
          }
          <h4>Multi-Factor Authentication</h4>
          <p>
            A verification code has been sent to your email. <br />
            We’re asking for this code based on your multi-factor authentication
            preferences.
          </p>
          <InputContainer style={{ width: '55%', marginBottom: 10 }}>
            <Input type="text"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              maxLength ={6}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; })=>{
                setOtp(e.target.value)
              }}
                />
            <InputContent>Enter code</InputContent>
            {otpvalue === OtpStatus.WrongOtp &&
            <span className="info_icon info_icon_Adaptive alerticon">
              <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={'Wrong OTP entered. Please try again'}
                  distance={5}
              >
                  <WarningAlertIcon />
              </Tooltip>
          </span>
          }
          </InputContainer>
          <div>
          {otpvalue === OtpStatus.MaxOtp ? (
            <div className='otp_alert_content'>
            <div><WarningAlertIcon /></div>
          <span>Max OTP Sent.Please check your mail.. </span>
          </div>
          ) :(
          counter === 0 ? (
            <p>
              <span onClick={()=>{
                setOtpValue(OtpStatus.OtpCalling)
                callOtpAPI()
                setCounter(30)
                }}
              >Request another code</span>
            </p>
          ):(
            <p className='mfa_requestotp'>
              You can request another OTP in <span>{counter}</span> sec
            </p>
          ))}
            <ButtonContainer className="mfa_button_contaione">
              <div className="popup_footer_button_container">
                <Button type="button"
                  className={otp.length < 6 ? 'editor_btn_disabled' : ''}
                  onClick={()=>{
                    verifyOtp()
                  }}>
                  <label>Signin</label>
                </Button>
                <Button type="button" primary
                    onClick={()=> {
                      setOtpValue(OtpStatus.LogOut)
                    }
                    }>
                  <label>Cancel</label>
                </Button>
              </div>
            </ButtonContainer>
            <p>
              Having trouble? Reach out to <a href="mailto:support@workativ.com">Support</a>
            </p>
          </div>
        </div>
        <div className="mfa_img_container">
          <div className="mfa_img">
            <img src={mfa_mail_img} alt="mfa_mail" />
          </div>
          <p>We’re just making sure it’s you.</p>
        </div>
      </div>
      {otpvalue === OtpStatus.LogOut && 
        <Modal showPopupLoader={false}>
          <>
            <h2>Confirm Logout </h2>
            <Paragraph>Are you sure to logout? </Paragraph>
            <ButtonWrapper className="log_out_container">
                <Button className="log_out_button" primary type="button" onClick={() => setOtpValue(OtpStatus.OtpSent)}>
                    <label>No</label>
                </Button>
                <Button className="log_out_button" type="button" onClick={() => logOut()}>
                    <label>Yes</label>
                </Button>
            </ButtonWrapper>
          </>
        </Modal>}
    </section>
   
  );
}
