  import { Modal } from '@/common/components/Modal';
import {
  DeleteIcon,
  EditIcon,
  InfoIcon,
  SearchIcon,
  UserIconAnalytics,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import { TabelWrapper } from '@/common/styled/Dialogue.Dumb';
import { getCurrentUserInfo } from '@/common/utils/utils';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  BotsP,
  Button,
  ButtonContainer,
  DropdownUl,
  DropdownUlWrapper,
  InputContent,
  isBlockedDomain,
  isValidEmail,
  TabContainer,
  Table,
  TableContainer,
  TabRow,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from './ManageUsers';
import {
  AgentCreateT,
  TeamDetailsT,
  AgentEditT,
  AgentUpdateT,
  allowOnlyAlpha,
  initialAgent,
  useAgentCreate,
  useAgentDelete,
  useAgentUpdate,
  useAllAgents,
  useAllTeams,
  initialTeam,
  TeamCreateT,
  TeamUpdateT,
  useTeamCreate,
  TeamEditT,
  useTeamUpdate,
  useTeamDelete,
  TeamtoUpdateT,
} from './manageUsersApi';
import { Input } from '@/common/styled/Dialog.BotDetails.Dumb';
import Downshift from 'downshift';
import { User } from '../types.user';
import { Loader } from '@/common/components/Loader';
import { usePermissions } from '@/common/utils/auth-token-api-utils';
import { TabHeader } from '@/Flows/analytics/logs';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import styled from 'styled-components';
import { getLiveagentBots } from '@/LiveAgent/LivaAgentApi';
// import { Tooltip } from '@/common/styled/Subscription.Xtra.Dumb';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import Tooltip from 'react-tooltip-lite';
import { ProfileTabs } from '@/SidePanel/Sidebar';

export const DropdownLi = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #EBEBEB;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 10px;

  .liveAt_user_name{
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #355D8B;
  }

  // .dropdown_list {
  //   display: flex;
  //   align-items: center;
  //   border-bottom: 1px solid #EBEBEB;
  //   font-size: 12px;
  //   font-family: 'Gordita-Regular';
  //   height: 36px;
  //   padding: 0 10px;
  //   background: #fff;
  //   cursor: pointer !important;
  //   color: #355D8B;
  //   flex-direction: row-reverse;

  //   :hover {
  //     background: #ffe5d3;
  //     color: #333;
  //     border-bottom: 1px solid #ffe5d3;
  //   }
  // }

  img {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }

  :nth-child(2) {
    border-top: 1px solid #EBEBEB;
  }
  :last-child {
    border-bottom: 1px solid #fff;
  }
  :hover {
    background: rgb(221, 254, 239);
    color: #333;
  }

  .liveAt_user_name_box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: row-reverse;
  }

  .manageuser_teams_icon{
    width: 30px;
    height: 30px;
    border: 1px solid #B9B9B9;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      fill: #526A86;
      color: #526A86;
      width: 18px;
      height: 18px;
    }
  }

  .option-input.radio{
    border: 1px solid #D8D8D8;
    top: 0;
  }
`;

export const SerachInputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;

    span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 10px;
        left: 14px;
        svg {
            width: 16px;
            height: 16px;
            color: #999;
            fill: #999;
        }
    }
`

export const SerachInput = styled.input`
  width: 378px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #B9B9B9;
  border-radius: 19px;
  padding: 3px 12px 0px 36px;
  font-size: 14px;
  font-family: Gordita-Regular;
  color: #000;
  :placeholder {
    color: #999;
  }
  :hover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid rgb(0, 157, 95);
  }
`;

type teamT = {
  id: string;
  email: string;
};
type roleT = {
  id: number;
  name: string;
};

function TeamDetails(props: any) {
  const workspace = props.match.params.workspacename;
  // {
  //   bots: BotsP[];
  // }
  const [bots, setBots] = useState<BotsP[]>([]);
  useEffect(() => {
    getLiveagentBots(workspace)
      .then((res) => {
        const bots = res.map((b) => ({ id: b.id, name: b.name }));
        setBots(bots);
      })
      .catch((err) => console.log('Err::', err));
  }, []);
  const { AllAgentsData } = useAllAgents(workspace);
  const [createpopup, setCreatepopup] = useState<boolean>(false);

  type tab = 'Admins' | 'Agents' | 'Teams';
  const [tab, setTab] = useState<tab>('Teams');
  const { AllTeamsData, TeamFetching } = useAllTeams(workspace);
  console.log(AllTeamsData, props, 'AllTeamsData');
  const {
    data: currentUser,
    isLoading: currentUserInfoLoading,
    isError: currUserInfoIsErr,
    error: currUserInfoErr,
  } = useQuery(`currentUser`, () => getCurrentUserInfo());

  const [roles, setRoles] = useState<roleT[]>([
    {
      id: 1,
      name: 'Shared Inbox Admin',
    },
    {
      id: 2,
      name: 'Shared Inbox Agent',
    },
  ]);

  const userLinks = `SHARED_INBOX_MODULE/SHARED_INBOX_TEAMS`;
  const writeTeamPermission = usePermissions(workspace, userLinks, 'WRITE');
  const deleteTeamPermission = usePermissions(workspace, userLinks, 'DELETE');

  const openUpdate = () => {
    if (writeTeamPermission.value) {
      return true;
    } else {
      return false;
    }
  };
  const [teamUpdatePopup, setTeamUpdatePopup] = useState<{
    team: TeamDetailsT;
    popup: boolean;
  }>({
    team: initialTeam,
    popup: false,
  });
  // console.log(teamUpdatePopup, currentUser, 'teamUpdatePopup');
  const [teamDeletePopup, setTeamDeletePopup] = useState<{
    team: TeamDetailsT;
    popup: boolean;
  }>({
    team: initialTeam,
    popup: false,
  });

  const { teamDelete } = useTeamDelete(
    () => {
      setTeamDeletePopup({
        team: initialTeam,
        popup: false,
      });
    },
    () => {
      setAgentErr(true);
      setErrMsg('Error in deleting team');
    }
  );
  const removeTeam = (team: TeamDetailsT) => {
    console.log('useAgentDeleteapicalled');
    const update: TeamUpdateT = {
      workspace: workspace,
      agentID: team.id,
      body: {
        name: '',
        team_members: [''],
        chat_sources: [''],
      },
    };
    teamDelete(update);
  };
  const [agentErr, setAgentErr] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');

  const errComponent = agentErr ? (
    <React.Fragment>
      {errMsg}
      <Button
        primary
        onClick={() => {
          setAgentErr(false);
          setTeamDeletePopup({
            team: initialTeam,
            popup: false,
          });
        }}
        type="button"
      >
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  if (TeamFetching) {
    return <Loader.PageLoader show={true} />;
  } else {
    return (
      <>
        <section className="tour_automation_canvas manage_user_new">
          <div className="header_box_shadow" />
          <div className="flow_canvas1">
            <div className="flex_canvas1">
              <div className="flex_menu1 flow_group_home">
                <div className="indent_dialog_left">
                  <div className="indent_left">
                    <div className="indent_title  mb-0">Manage Users</div>
                  </div>
                  {/* <div className="indent_right">
                  <div className="btn_width_wrapper">
                    {users !== undefined &&
                      noOfUsersAllowed &&
                      users.length < noOfUsersAllowed && (
                        <Button
                          className="btn btn-primary"
                          onClick={invitePopUp.open}
                        >
                          <span className="header_button">
                            <svg
                              height="512pt"
                              viewBox="0 0 512 512"
                              width="512pt"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m512 412c0 11.046875-8.953125 20-20 20h-60v60c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-60h-60c-11.046875 0-20-8.953125-20-20s8.953125-20 20-20h60v-60c0-11.046875 8.953125-20 20-20s20 8.953125 20 20v60h60c11.046875 0 20 8.953125 20 20zm-160 80c0 11.046875-8.953125 20-20 20h-272c-33.085938 0-60-26.914062-60-60v-37c0-34.515625 14.804688-67.3125 40.613281-89.988281 20.894531-18.363281 60.910157-45.578125 125.238281-59.722657-35.15625-27.082031-57.851562-69.582031-57.851562-117.289062 0-81.605469 66.394531-148 148-148s148 66.394531 148 148-66.394531 148-148 148c-109.902344 0-168.777344 41.300781-188.984375 59.058594-17.167969 15.085937-27.015625 36.929687-27.015625 59.941406v37c0 11.027344 8.972656 20 20 20h272c11.046875 0 20 8.953125 20 20zm-96-236c59.550781 0 108-48.449219 108-108s-48.449219-108-108-108-108 48.449219-108 108 48.449219 108 108 108zm0 0" />
                            </svg>
                          </span>
                          <label>Invite User</label>
                        </Button>
                      )}
                  </div>
                </div> */}
                </div>
              </div>
              <div className="flex_side_menu1 intent_home flow_group_home">
                <div className="intent_dialog_right" />
                <ProfileTabs {...props as any}/>
              </div>
            </div>
          </div>
        </section>
        <TabContainer className="manageUser_tab">
          <TabRow className="manageUser_tabrow">
            <TabHeader
              className={tab === 'Admins' ? 'active' : ''}
              onClick={() =>
                props.history.push(
                  `/workspace/${props.match.params.workspacename}/users`
                )
              }
            >
              Admins
            </TabHeader>
            <TabHeader
              className={tab === 'Agents' ? 'active' : ''}
              onClick={() =>
                props.history.push(
                  `/workspace/${props.match.params.workspacename}/agents`
                )
              }
            >
              Agents
            </TabHeader>
            <TabHeader
              className={tab === 'Teams' ? 'active' : ''}
              onClick={() => {
                setTab('Teams');
              }}
            >
              Teams
            </TabHeader>
            {writeTeamPermission.value && (
              <div className="btn_width_wrapper manage_user_btn">
                {/* {users !== undefined &&
                noOfUsersAllowed &&
                users.length < noOfUsersAllowed && ( */}
                <Button
                  className="btn btn-primary"
                  onClick={() => setCreatepopup(true)}
                >
                  <span className="header_button">
                    <svg
                      height="512pt"
                      viewBox="0 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m512 412c0 11.046875-8.953125 20-20 20h-60v60c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-60h-60c-11.046875 0-20-8.953125-20-20s8.953125-20 20-20h60v-60c0-11.046875 8.953125-20 20-20s20 8.953125 20 20v60h60c11.046875 0 20 8.953125 20 20zm-160 80c0 11.046875-8.953125 20-20 20h-272c-33.085938 0-60-26.914062-60-60v-37c0-34.515625 14.804688-67.3125 40.613281-89.988281 20.894531-18.363281 60.910157-45.578125 125.238281-59.722657-35.15625-27.082031-57.851562-69.582031-57.851562-117.289062 0-81.605469 66.394531-148 148-148s148 66.394531 148 148-66.394531 148-148 148c-109.902344 0-168.777344 41.300781-188.984375 59.058594-17.167969 15.085937-27.015625 36.929687-27.015625 59.941406v37c0 11.027344 8.972656 20 20 20h272c11.046875 0 20 8.953125 20 20zm-96-236c59.550781 0 108-48.449219 108-108s-48.449219-108-108-108-108 48.449219-108 108 48.449219 108 108 108zm0 0" />
                    </svg>
                  </span>
                  <label>Create Team</label>
                </Button>
                {/* )} */}
              </div>
            )}
          </TabRow>
        </TabContainer>
        <section className="cogni_cards sup_acc_user_details z-index-1-important manage_uesr_table">
          <div className="w-100 float-left">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="sup_acc_user_details_header">
                  <TabelWrapper>
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th size={2}>Team Name</Th>
                            <Th size={4}>Team members</Th>
                            <Th size={4}>Chat Sources</Th>
                            <Th size={2} style={{justifyContent: 'flex-end'}}>Actions</Th>
                          </Tr>
                        </Thead>
                        {/* {console.log(team,"AllAgentsData")} */}

                        <Tbody>
                          {AllTeamsData && AllTeamsData.length > 0 ? (
                            AllTeamsData.map((team: TeamDetailsT, index) => (
                              <Tr
                                key={index}
                                onClick={() => {
                                  openUpdate() &&
                                    setTeamUpdatePopup({
                                      team: team,
                                      popup: true,
                                    });
                                }}
                              >
                                <Td size={2}>
                                  <div className="td_user_container">
                                    <div className="td_user_img">
                                      {' '}
                                      <UserIconAnalyticsMenu />{' '}
                                    </div>
                                    <div className="td_user_mail">
                                      {team.name}
                                    </div>
                                  </div>
                                </Td>

                                <Td size={4}>
                                  <div className="td_header">
                                    (
                                    {team.team_members.length > 0
                                      ? team.team_members.length < 9
                                        ? '0' + team.team_members.length
                                        : team.team_members.length
                                      : '0'}
                                    )
                                  </div>
                                </Td>
                                <Td size={4}>
                                  <div className="td_user_container">
                                    <div className="position-relative d-flex flex-column">
                                      {team.chat_sources.length > 0
                                        ? team.chat_sources.map(
                                            (chat) => chat.name
                                          ).join(', ')
                                        : null}
                                    </div>
                                  </div>
                                </Td>
                                {/* <Td size={3}>
                                <div className="td_header"> */}
                                {/* {
                                  <AgentActiveStatus
                                    user={team}
                                    actions={{ ...actions }}
                                    isOwner={isOwner}
                                    currentUser={currentUser.id === team.id}
                                  ></AgentActiveStatus>
                                } */}
                                {/* </div>
                              </Td> */}
                                <Td size={2}>
                                  <div className="list-view-icon td_header">
                                    <>
                                      {openUpdate() && (
                                        <span
                                          className="td_editicon"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setTeamUpdatePopup({
                                              team: team,
                                              popup: true,
                                            });
                                          }}
                                        >
                                          {currentUser &&
                                            currentUser.id !==
                                              parseInt(team.id) && (
                                              //   !isOwner &&
                                              <EditIcon />
                                            )}
                                        </span>
                                      )}
                                      {deleteTeamPermission.value && (
                                        <span
                                          className="td_editicon"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setTeamDeletePopup({
                                              team: team,
                                              popup: true,
                                            });
                                          }}
                                        >
                                          <DeleteIcon />
                                        </span>
                                      )}
                                    </>
                                  </div>
                                  {/* // <SmallButton primary onClick={() => actions.removeUser(user)}>
                                    //   <label>Remove user</label>
                                    // </SmallButton> */}
                                </Td>
                              </Tr>
                            ))
                          ) : (
                            <div className='liveAgent_inbox_conversation_trash'>
                                <p>No teams data</p>
                            </div>                            
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabelWrapper>
                </div>
              </div>
            </div>
          </div>
        </section>
        {createpopup && AllAgentsData && (
          <TeamCreate
            workspace={workspace}
            closePopup={() => setCreatepopup(false)}
            AllAgentsData={AllAgentsData}
            bots={bots}
            callbacks={{
              completed: () => {
                setCreatepopup(false);
              },
              failed: (e) => e.message,
              cancel: () => {
                setCreatepopup(false);
              },
            }}
          />
        )}
        {teamUpdatePopup.popup && AllAgentsData && (
          <TeamEdit
            teamToEdit={teamUpdatePopup.team}
            workspace={workspace}
            roles={roles}
            AllAgentsData={AllAgentsData}
            bots={bots}
            callbacks={{
              completed: () => {
                setTeamUpdatePopup({
                  ...teamUpdatePopup,
                  popup: false,
                });
                //   invalidateWsUsers();
                //   editRolesPopup.close();
              },
              failed: (e) => e.message,
              cancel: () => {
                setTeamUpdatePopup({
                  ...teamUpdatePopup,
                  popup: false,
                });
              },
            }}
            //   botlist={botlist_cbc(editRolesPopup.rolesPopup.user)}
            //   adminBotList={botlist_cbAdmin(editRolesPopup.rolesPopup.user)}
            //   rBACAcceess={mfaAllowed}
          ></TeamEdit>
        )}
        {teamDeletePopup.popup && teamDeletePopup.team !== null && (
          <Modal
            className="invite_new_user delete"
            //   close={!rolesMutation.isLoading ? cancel : R.identity}
            //   showPopupLoader={agentUpdateLoading}
          >
            {errComponent || (
              <React.Fragment>
                <h2>Are you sure to Delete the Team? </h2>
                <ButtonContainer className="invite_user_popup pb-0">
                  <Button
                    primary
                    onClick={() =>
                      setTeamDeletePopup({
                        team: initialTeam,
                        popup: false,
                      })
                    }
                    type="button"
                  >
                    <label>No</label>
                  </Button>
                  <Button
                    onClick={() => removeTeam(teamDeletePopup.team)}
                    type="button"
                    className="remove_uesr"
                  >
                    <label>Yes</label>
                  </Button>
                </ButtonContainer>
              </React.Fragment>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default TeamDetails;

const TeamEdit = ({
  teamToEdit,
  workspace,
  callbacks: { cancel, failed, completed },
  roles,
  AllAgentsData,
  bots,
}: TeamEditT) => {
  //   const { teamToEdit, workspace } = props;
  const [teamDetails, setTeamDetails] = useState<TeamtoUpdateT>(initialTeam);
  console.log(teamDetails, teamToEdit, AllAgentsData, 'teamUpdatePopup');
  const [memberSearch,setMemberSearch] = useState<string>("")
  const [botSearch,setBotSearch] = useState<string>("")
  // useEffect(() => {
  //   const teamDate = {
  //     id: teamToEdit.id,
  //     name:teamToEdit.name,
  //     team_members:teamToEdit.team_members.map(team=>team.id),
  //     chat_sources:teamToEdit.chat_sources.map(chat=> chat.id)
  //   }
  //   setTeamDetails(teamDate);
  // }, [teamToEdit]);
  const [allAdmins, setAllAdmins] = useState<string[]>([]);
  console.log(allAdmins, AllAgentsData, 'skfhaks');
  const agentstoShow = memberSearch !== "" ? AllAgentsData.filter((agent)=> agent.first_name.toLowerCase().includes(memberSearch.toLowerCase())) : AllAgentsData
  const botstoShow = botSearch !== "" ? bots.filter((bot)=> bot.name.toLowerCase().includes(botSearch.toLowerCase())) : bots

  useEffect(() => {
    if (AllAgentsData) {
      const admins = AllAgentsData.filter((agent) => agent.role === "shared_inbox_admin" || agent.role === "super_admin").map(
        (ag) => ag.id
      );
      console.log(admins, AllAgentsData, 'skfhaks');
      setAllAdmins(admins);
      // setTeamDetails({
      //   ...teamDetails,
      //   team_members: admins,
      // });
    }
    const teamDate = {
      id: teamToEdit.id,
      name: teamToEdit.name,
      team_members: teamToEdit.team_members.map((team) => team.id),
      chat_sources: teamToEdit.chat_sources.map((chat) => chat.id),
    };
    setTeamDetails(teamDate);
  }, [AllAgentsData]);

  const teamList = (team: teamT) => {
    const isAdmin = allAdmins.includes(team.id);
    const list = teamDetails.team_members;
    const checkAvailable = list.filter((li) => li === team.id);
    if (!isAdmin) {
      if (checkAvailable.length > 0) {
        const removedList = list.filter((li) => li !== team.id);
        setTeamDetails({
          ...teamDetails,
          team_members: removedList,
        });
      } else {
        list.push(team.id);
        setTeamDetails({
          ...teamDetails,
          team_members: list,
        });
      }
    }
  };

  // const botList = (bot: BotsP) => {
  //   const list = teamDetails.chat_sources;
  //   const checkAvailable = list.filter((li) => li === bot.id);
  //   if (checkAvailable.length > 0) {
  //     const removedList = list.filter((li) => li !== bot.id);
  //     setTeamDetails({
  //       ...teamDetails,
  //       chat_sources: removedList,
  //     });
  //   } else {
  //     list.push(bot.id);
  //     setTeamDetails({
  //       ...teamDetails,
  //       chat_sources: list,
  //     });
  //   }
  // };
  const [filterDropDown, setFilterDropDown] = useState<boolean>(false);
  const [roleDropDown, setRoleDropDown] = useState<boolean>(false);
  const [editErr, setEditErr] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<String>('');
  const { teamUpdate } = useTeamUpdate(
    () => {
      cancel();
    },
    () => {
      setEditErr(true);
      setErrMsg('Error in Updating the Team');
    }
  );
  const editAgent = (team: TeamDetailsT) => {
    const update: TeamUpdateT = {
      workspace: workspace,
      agentID: team.id,
      body: {
        name: teamDetails.name,
        team_members: teamDetails.team_members,
        // chat_sources: teamDetails.chat_sources,
      },
    };
    teamUpdate(update);
  };

  const errComponent = editErr ? (
    <React.Fragment>
      {errMsg}
      <Button
        primary
        onClick={() => {
          setEditErr(false);
          cancel();
        }}
        type="button"
      >
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  return (
    <Modal
      className="manageuser_teams"
      //   close={!rolesMutation.isLoading ? cancel : R.identity}
      //   showPopupLoader={agentUpdateLoading}
    >
      {errComponent || (
        <React.Fragment>
          <h2>Edit Team </h2>
          {teamDetails.id === '' ? (
            <Loader.PopupLoader
              show={true}
              className="remove_connection_popuploader"
            />
          ) : (
            <div className="invite_new_user_box">
              <div className="account_popup_user_input automate">
                <div className="d-flex justify-content-around">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      {/* <label>First Name</label> */}
                      <Input
                        type="text"
                        className="form-control"
                        // placeholder="Enter first name"
                        value={teamDetails.name}
                        onChange={(e: { target: { value: any } }) => {
                          setTeamDetails({
                            ...teamDetails,
                            name: e.target.value,
                          });
                        }}
                      />
                      <InputContent className="input_content">
                        Team name
                      </InputContent>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <Input
                        type="text"
                        className='info_with_content'
                        // className="form-control"
                        value={teamDetails.team_members.length>0?
                          teamDetails.team_members.map(
                          (id) =>
                            AllAgentsData.filter((ag) => ag.id === id)[0]
                              ?.first_name
                        ).join(', '):
                      null}  
                        onClick={() => setFilterDropDown(true)}
                      />
                      <InputContent
                      className="input_content edit_team"
                      >
                        Team members
                        {/* <Tooltip
                            className="target agent"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'all the Shared Inbox Admins/Admins will be a part of all teams by default. they cannot be removed form the list'}
                            distance={5}
                          >
                            <InfoIcon />
                          </Tooltip> */}
                      </InputContent>
                      {filterDropDown && (
                        <Downshift
                          isOpen={filterDropDown}
                          onOuterClick={() => setFilterDropDown(false)}
                        >
                          {() => (
                            <div className="automation_scroly mb-0">
                              <DropdownUl
                                style={{
                                  marginTop: 48,
                                  left: 8,
                                  width: '95%',
                                  background: '#fff'
                                }}
                              >
                                <SerachInputWrapper>
                                      <SerachInput
                                          type="search"
                                          placeholder={'Search members'}
                                          onChange={(e: { target: { value: any; }; })=>{
                                            setMemberSearch(e.target.value)
                                          }}
                                      />
                                      <span>
                                          <SearchIcon />
                                      </span>
                                  </SerachInputWrapper>
                                {AllAgentsData && AllAgentsData.length > 0 ? (
                                  agentstoShow.map(
                                    (
                                      val: {
                                        id: string;
                                        email: string;
                                        first_name: string;
                                      },
                                      i: number
                                    ) => (
                                      <DropdownLi
                                        key={i}
                                        onClick={() => {
                                          teamList(val);
                                        }}
                                        className="mange_role_li"
                                      >
                                        {/* <div
                                          className={
                                            allAdmins.includes(val.id)
                                              ? 'dropdown_list delete'
                                              : 'dropdown_list'
                                          }
                                        > */}

                                        <div className="liveAt_user_select">
                                          <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={teamDetails.team_members.includes(
                                              val.id
                                            )}
                                          />
                                        </div>
                                        <div className='liveAt_user_name_box'>
                                          <div className="liveAt_user_name">
                                          {val.email}
                                          </div>
                                          <div className='manageuser_teams_icon'>
                                            <UserIconAnalytics />
                                          </div>
                                        </div>
                                          {/* <label>
                                            <input
                                              type="radio"
                                              className="option-input radio"
                                              checked={teamDetails.team_members.includes(
                                                val.id
                                              )}
                                            />
                                          </label>

                                          {val.email} */}
                                        {/* </div> */}
                                      </DropdownLi>
                                    )
                                  )
                                ) : (
                                  <p>No Agent Information to display</p>
                                )}
                              </DropdownUl>
                            </div>
                          )}
                        </Downshift>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <Input
                        type="text"
                        className="form-control"
                        value={teamDetails.chat_sources.map(
                          (chat) =>
                            bots.filter((bot) => bot.id === chat)[0]?.name
                        )}
                        onClick={() => setRoleDropDown(true)}
                      />
                      <InputContent className="input_content">
                        Select chat sources
                      </InputContent>
                      {roleDropDown && (
                        <Downshift
                          isOpen={roleDropDown}
                          onOuterClick={() => setRoleDropDown(false)}
                        >
                          {() => (
                            <span>
                              <DropdownUlWrapper>
                                <DropdownUl
                                  style={{
                                    marginTop: 0,
                                    right: 0,
                                    width: '100%',
                                    background: '#fff'
                                  }}
                                >
                                  <SerachInputWrapper className="search_bot">
                                      <SerachInput
                                          type="search"
                                          placeholder={'Search bot'}
                                          onChange={(e: { target: { value: any; }; })=>{
                                            setBotSearch(e.target.value)
                                          }}
                                      />
                                      <span>
                                          <SearchIcon />
                                      </span>
                                  </SerachInputWrapper>
                                  {bots && bots.length > 0 ? (
                                    botstoShow.map((val: BotsP, i: number) => (
                                      <DropdownLi
                                        key={i}
                                        onClick={() => {
                                          botList(val);
                                        }}
                                      >
                                        {console.log(val, 'hceknklad')}
                                        <div className="liveAt_user_select">
                                          <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={teamDetails.chat_sources.includes(
                                              val.id
                                            )}
                                          />
                                        </div>
                                        <div className='liveAt_user_name_box'>
                                          <div className="liveAt_user_name">
                                            {val.name}
                                          </div>
                                          <div className='manageuser_teams_icon'>
                                            <UserIconAnalytics />
                                          </div>
                                        </div>
                                      </DropdownLi>
                                    ))
                                  ) : (
                                    <p>No Team Information to display</p>
                                  )}
                                </DropdownUl>
                              </DropdownUlWrapper>
                            </span>
                          )}
                        </Downshift>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>

              <ButtonContainer className="invite_user_popup teams pb-0">
                <Button primary onClick={cancel} type="button">
                  <label>Cancel</label>
                </Button>
                <Button onClick={() => editAgent(teamDetails)} type="button">
                  <label>Update</label>
                </Button>
              </ButtonContainer>
            </div>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};
const TeamCreate = ({
  workspace,
  closePopup,
  AllAgentsData,
  callbacks: { cancel, failed, completed },
  bots,
}: TeamCreateT) => {
  //   const { teamToEdit, workspace } = props;
  const [teamDetails, setTeamDetails] = useState<TeamtoUpdateT>({
    id: '',
    name: '',
    team_members: [],
    chat_sources: [],
  });
  console.log(teamDetails, 'teamDetails');
  const [filterDropDown, setFilterDropDown] = useState<boolean>(false);
  const [memberSearch,setMemberSearch] = useState<string>("")
  const [botSearch,setBotSearch] = useState<string>("")

  const [roleDropDown, setRoleDropDown] = useState<boolean>(false);
  const [allAdmins, setAllAdmins] = useState<string[]>([]);
  useEffect(() => {
    if (AllAgentsData) {
      const admins = AllAgentsData.filter((agent) => agent.role === "shared_inbox_admin" || agent.role === "super_admin").map(
        (ag) => ag.id
      );
      setAllAdmins(admins);
      setTeamDetails({
        ...teamDetails,
        team_members: admins,
      });
    }
  }, [AllAgentsData]);

  const agentstoShow = memberSearch !== "" ? AllAgentsData.filter((agent)=> agent.first_name.toLowerCase().includes(memberSearch.toLowerCase())) : AllAgentsData
  const botstoShow = botSearch !== "" ? bots.filter((bot)=> bot.name.toLowerCase().includes(botSearch.toLowerCase())) : bots

  const checkAvail = (id: string) => {
    return allAdmins.filter((ad) => ad === id).length > 0;
  };
  const teamList = (team: teamT) => {
    const isAdmin = checkAvail(team.id);
    const list = teamDetails.team_members;
    const checkAvailable = list.filter((li) => li === team.id);
    if (!isAdmin) {
      if (checkAvailable.length > 0) {
        const removedList = list.filter((li) => li !== team.id);
        setTeamDetails({
          ...teamDetails,
          team_members: removedList,
        });
      } else {
        setTeamDetails((prevState) => {
          return {
            ...prevState,
            team_members: [...prevState.team_members, team.id],
          };
        });
      }
    }
  };
  // const botList = (bot: BotsP) => {
  //   const list = teamDetails.chat_sources;
  //   const checkAvailable = list.filter((li) => li === bot.id);
  //   if (checkAvailable.length > 0) {
  //     const removedList = list.filter((li) => li !== bot.id);
  //     setTeamDetails({
  //       ...teamDetails,
  //       chat_sources: removedList,
  //     });
  //   } else {
  //     list.push(bot.id);
  //     setTeamDetails({
  //       ...teamDetails,
  //       chat_sources: list,
  //     });
  //   }
  // };
  const { teamCreate } = useTeamCreate(
    () => {
      closePopup();
      setTeamDetails(initialTeam);
    },
    () => {
      setCrateErr(true);
    }
  );
  const [createErr, setCrateErr] = useState<boolean>(false);
  const createTeam = () => {
    const update: TeamUpdateT = {
      workspace: workspace,
      // agentID: team.id,
      body: {
        name: teamDetails.name,
        team_members: teamDetails.team_members,
        // chat_sources: teamDetails.chat_sources,
      },
    };
    teamCreate(update);
  };
  const errComponent = createErr ? (
    <React.Fragment>
      Error in Creating Team
      <Button primary onClick={cancel()} type="button">
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  return (
    <Modal className="invite_new_teams">
      {/* {errComponent || ( */}
        <React.Fragment>
          <h2>Create Team</h2>
          <div className="invite_new_user_box">
            <div className="account_popup_user_input automate">
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Input
                      type="text"
                      className="form-control"
                      // pattern="[A-Za-z]"
                      value={teamDetails.name}
                      onChange={(e: any) => {
                        setTeamDetails({
                          ...teamDetails,
                          name: e.target.value,
                        });
                        // setFirstName(allowOnlyAlpha(e.target.value));
                      }}
                    />
                    <InputContent className="input_content">
                      Team name
                    </InputContent>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Input
                      type="text"
                      className="form-control"
                      value={teamDetails.team_members.length > 0 ? teamDetails.team_members.map(
                        (id) =>
                          AllAgentsData.filter((ag) => ag.id === id)[0]
                            ?.first_name
                      ).join(', ') : null}
                      onClick={() => setFilterDropDown(true)}
                    />
                    <InputContent className="input_content">
                      Team members
                      {/* <Tooltip
                            className="target customTip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content={'all the Shared Inbox Admins/Admins will be a part of all teams by default. they cannot be removed form the list'}
                            distance={5}
                          >
                            <InfoIcon />
                        </Tooltip> */}
                    </InputContent>
                    {filterDropDown && (
                      <Downshift
                        isOpen={filterDropDown}
                        onOuterClick={() => setFilterDropDown(false)}
                      >
                        {() => (
                          <div className="automation_scroly mb-0">
                            <DropdownUl
                              style={{
                                marginTop: 48,
                                left: 8,
                                width: '96%',
                                background: '#fff',
                              }}
                            >
                              <SerachInputWrapper>
                                      <SerachInput
                                          type="search"
                                          placeholder={'Search members'}
                                          onChange={(e: { target: { value: any; }; })=>{
                                            setMemberSearch(e.target.value)
                                          }}
                                          
                                      />
                                      <span>
                                          <SearchIcon />
                                      </span>
                                  </SerachInputWrapper>
                              {AllAgentsData && AllAgentsData.length > 0 ? (
                                agentstoShow.map(
                                  (
                                    val: {
                                      id: string;
                                      email: string;
                                      first_name: string;
                                    },
                                    i: number
                                  ) => (
                                    <DropdownLi
                                      key={i}
                                      onClick={() => {
                                        teamList(val);
                                      }}
                                      className="mangae_role_li"
                                    >
                                      {/* <div
                                        className={
                                          checkAvail(val.id)
                                            ? 'dropdown_list delete'
                                            : 'dropdown_list'
                                        }
                                      > */}

                                        <div className="liveAt_user_select">
                                          <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={teamDetails.team_members.includes(
                                              val.id
                                            )}
                                          />
                                        </div>
                                        <div className='liveAt_user_name_box'>
                                          <div className="liveAt_user_name">
                                          {val.email}
                                          </div>
                                          <div className='manageuser_teams_icon'>
                                            <UserIconAnalytics />
                                          </div>
                                        </div>
                                        {/* <label>
                                          <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={teamDetails.team_members.includes(
                                              val.id
                                            )}
                                          />
                                        </label> */}
                                        
                                      {/* </div> */}
                                    </DropdownLi>
                                  )
                                )
                              ) : (
                                <p style={{marginBottom: '10px !important'}}>No Team Information to display</p>
                              )}
                            </DropdownUl>
                          </div>
                        )}
                      </Downshift>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Input
                      type="text"
                      className="form-control"
                      value={teamDetails.chat_sources.map(
                        (chat) => bots.filter((bot) => bot.id === chat)[0]?.name
                      )}
                      onClick={() => setRoleDropDown(true)}
                    />
                    <InputContent className="input_content">
                      Select chat sources
                    </InputContent>
                    {roleDropDown && (
                      <Downshift
                        isOpen={roleDropDown}
                        onOuterClick={() => setRoleDropDown(false)}
                      >
                        {() => (
                          <div className="automation_scroly mb-0">
                            <DropdownUl
                              style={{
                                marginTop: 48,
                                left: 8,
                                width: '95%',
                                background: '#fff'
                              }}
                            >
                              <SerachInputWrapper>
                                      <SerachInput
                                          type="search"
                                          placeholder={'Search bot'}
                                          onChange={(e: { target: { value: any; }; })=>{
                                            setBotSearch(e.target.value)
                                          }}
                                      />
                                      <span>
                                          <SearchIcon />
                                      </span>
                                  </SerachInputWrapper> 
                              {bots && bots.length > 0 ? (
                                botstoShow.map((val: BotsP, i: number) => (
                                  <DropdownLi
                                    key={i}
                                    onClick={() => {
                                      botList(val);
                                    }}
                                    className="mangae_role_li"
                                  >
                                    {/* <div className="dropdown_list"> */}

                                        {/* <div className="liveAt_user_select">
                                          <input
                                            type="radio"
                                            className="option-input radio"
                                            checked={teamDetails.team_members.includes(
                                              val.id
                                            )}
                                          />
                                        </div>
                                        <div className='liveAt_user_name_box'>
                                          <div className="liveAt_user_name">
                                          {val.name}
                                          </div>
                                          <div className='manageuser_teams_icon'>
                                            <UserIconAnalytics />
                                          </div>
                                        </div> */}
                                      {/* <label>
                                        <input
                                          type="radio"
                                          className="option-input radio"
                                          checked={teamDetails.chat_sources.includes(
                                            val.id
                                          )}
                                        />
                                      </label>
                                      {val.name} */}
                                    {/* </div> */}
                                  {/* </DropdownLi>
                                ))
                              ) : (
                                <p>No Team Information to display</p>
                              )}
                            </DropdownUl>
                          </div>
                        )}
                      </Downshift>
                    )}
                  </div>
                </div>
              </div> */}
            </div>

            <ButtonContainer className="invite_user_popup teams pb-0">
              <Button
                primary
                onClick={() => {
                  setTeamDetails(initialTeam);
                  closePopup();
                }}
                type="button"
              >
                <label>Cancel</label>
              </Button>
              <Button
                disabled={
                  teamDetails.name === ""
                }
                onClick={() => createTeam()}
                type="button"
                    className={teamDetails.name === "" ? "editor_btn_disabled btn_invite" : "btn_invite" }
              >
                <label>Create Team</label>
              </Button>
            </ButtonContainer>
          </div>
        </React.Fragment>
      {/* )} */}
    </Modal>
  );
};
// function AgentActiveStatus({
//   user,
//   actions,
//   isOwner,
//   currentUser,
// }: {
//   user: User;
//   actions: UserActions;
//   isOwner: boolean;
//   currentUser: boolean;
// }) {
//   if (isOwner) {
//     return (
//       <div className="manage_user_status_section active_user">
//         <div className="show_hide">
//           <div className="switch_box box_1"></div>
//         </div>
//         <span>Workpace Owner</span>
//       </div>
//     );
//   } else if (currentUser) {
//     return (
//       <div className="manage_user_status_section active_user">
//         <div className="show_hide">
//           <div className="switch_box box_1"></div>
//         </div>
//         <span>Has workspace access</span>
//       </div>
//     );
//   } else {
//     return user.has_password ? (
//       user.AccessibleWorkspaces[0].WorkspaceAccess.valid ? (
//         <div className="manage_user_status_section active_user">
//           <div className="show_hide">
//             <div className="switch_box box_1">
//               <input
//                 type="checkbox"
//                 className={`switch_1 disabled`}
//                 checked={true}
//                 onChange={() => actions.disableUser(user)}
//               />
//             </div>
//           </div>
//           <span>Has workspace access</span>
//         </div>
//       ) : (
//         <div className="manage_user_status_section inactive_user">
//           <div className="show_hide">
//             <div className="switch_box box_1">
//               <input
//                 type="checkbox"
//                 className={`switch_1`}
//                 checked={false}
//                 onChange={() => actions.enableUser(user)}
//               />
//             </div>
//           </div>
//           <span>No Workspace Access</span>
//         </div>
//       )
//     ) : (
//       <span className="td_user_invited">Invited</span>
//     );
//   }
// }
