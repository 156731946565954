/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TabContainer,
  TabHeader,
  TabRow,
  capitalizeFirstLetter,
  filterData,
  TabLeft,
  TabRight,
} from '@/Flows/analytics/logs';
import { VideoButtonB } from '@/Videos/Videos';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild, PageWrapper } from '@/common/components/Header';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ProfileTabs } from '@/SidePanel/Sidebar'

export function AutomationMarketPlacehome(props: any) {
    const { match } = props;
    const { workspacename } = match.params;
    const { params } = match
    const navigate = useNavigate()
  
    useEffect(()=>{
      if(location.pathname == `/workspace/${workspacename}/marketplace`){
        navigate(`/workspace/${workspacename}/marketplace/workflows`,{ replace:true })
      }
    },[props.location.pathname])
    
    return params['id']?<HeaderForCanvasPage {...props}/>:<BotHomeTab {...props}/>
  }

  export function HeaderForCanvasPage(props:any) {
    const params = useParams();
    console.log(params,"params")
    return (
      <>
        <Header>
          <HeaderChild type="first">
            <div className="indent_left">
              <div className="indent_title">Marketplace</div>
            </div>
          </HeaderChild>
          <HeaderChild type="second">
            <>
              <ProfileTabs {...props}/>
            </>
            {/* <VideoButtonB id={'READ_CHATBOT_LOGS'} /> */}
          </HeaderChild>
        </Header>
        {params['id'] && <Outlet />}
      </>
    );
  }
  
  function BotHomeTab(props:any){
    const { history, match } = props;
    const { workspacename, assistantname } = match.params;
    const [currentTab, setCurrentTab] = useState<string>('workflows');
    const navigate = useNavigate()
  
    // useEffect(()=>{
    //   console.log(props,"propsss")
    //   if(props.location.pathname.includes("automation/dashboard")){
    //     setCurrentTab("dashboard")
    //   }else if(props.location.pathname.includes("automation/logs")){
    //     setCurrentTab("logs")
    //   }else if(props.location.pathname.includes("automation/onprem-agent")){
    //     setCurrentTab("onprem-agent")
    //   }
    // },[props.location.pathname])
  
    return (
      <>
        <HeaderForCanvasPage {...props}/>
        <div className="header_loader_height_change">
            <PageWrapper>
              <TabContainer className="main_menu_section">
                <TabRow className="main_menu">
                  <TabLeft style={{top: 10}}>
                  {/* <TabHeader
                      active={
                        currentTab == 'dashboard'
                      }
                      onClick={() => {
                        setCurrentTab('dashboard');
                      }}
                      className={
                        currentTab == 'dashboard'
                          ? 'active'
                          : ''
                      }
                    >
                      <Link to={`/workspace/${workspacename}/automation/dashboard`}>
                        Bots
                      </Link>
                    </TabHeader> */}
                    <TabHeader
                      active={
                        currentTab == 'workflows'
                      }
                      onClick={() => {
                        setCurrentTab('workflows');
                      }}
                      className={
                        currentTab == 'workflows'
                          ? 'active'
                          : ''
                      }
                    >
                      {/* <Link to={`/workspace/${workspacename}/automation/view/active`}> */}
                        App Workflows
                      {/* </Link> */}
                    </TabHeader>
                  </TabLeft>
                  <TabRight>
                    <div id="assistant-main-header"></div>
                  </TabRight>
                </TabRow>
              </TabContainer>
              <Outlet />
            </PageWrapper>
        </div>
      </>
    );
  }