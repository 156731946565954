import React from 'react'

import { ApprovalIcon, CloseIconWithCircle, NextIconCanvas } from '@/common/Icons/Icons'
import { GreenTickIconMarketplace } from '@/common/Icons/Workflow.Icons'
import {
    AppButton,
    ApprovalBg,
    ApprovalHeader,
    ApprovalRow,
    ApprovalWrapper,
    Button,
    CancelButton,
    Count,
    DivisionDiv,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    DropdownWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    PromptButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    TdContent,
    TdSpan,
    Th,
    Thead,
    Tr,
    WidthContainer
} from '@/common/styled/Workflow.Analytics.Dumb'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'
import { ApprovalHistoryT } from './logs'

export const TableWrapper = () => {
    return (
        <TableContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th size={3}>FAQ Name</Th>
                        <Th size={4}>If user queries</Th>
                        <Th size={5}>Answer</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Troubleshooting software
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Troubleshooting not working <Count>08</Count>{' '}
                                    <span>
                                        <NextIconCanvas />
                                    </span>
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer Troubleshooting doc"</TdSpan> to resolve Printer
                                Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_active_wrapper">
                        <Tr className="is_active_tr">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                I have a paper jam!
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Paper loaded incorrectly <Count>06</Count>{' '}
                                    <span>
                                        <NextIconCanvas />
                                    </span>
                                </TdContent>
                            </Td>
                            <Td size={5}>Check this link “ Printer resolve doc “ to resolve Printer Issues.</Td>
                        </Tr>
                        <PromptButton>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Paper loaded incorrectly</Td>
                                <Td size={5}></Td>
                            </Tr>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Paper rollers are damaged</Td>

                                <Td size={5}></Td>
                            </Tr>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Loaded paper that is torn, dirty, or damp</Td>
                                <Td size={5}></Td>
                            </Tr>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Paper incorrectly loading</Td>
                                <Td size={5}></Td>
                            </Tr>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Paper damaged</Td>
                                <Td size={5}></Td>
                            </Tr>
                            <Tr>
                                <Td size={3}></Td>
                                <Td size={4}>Paper rollers are dirty</Td>
                                <Td size={5}></Td>
                            </Tr>
                        </PromptButton>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Bad print quality
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Bad print quality <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer won’t print
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    It comes letters shadow <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                How do I reset my printer?
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer reset <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer cost
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer cost <Count>04</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer installation
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    How to printer installation <Count>05</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer cable
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer cable damage <Count>05</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Bad print quality
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Bad print quality <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer won’t print
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    It comes letters shadow <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                How do I reset my printer?
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer reset <Count>08</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer cost
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer cost <Count>04</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer installation
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    How to printer installation <Count>05</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                    <div className="is_opacity_wrapper">
                        <Tr className="is_opacity">
                            <Td size={3}>
                                <span>
                                    <GreenTickIconMarketplace />
                                </span>{' '}
                                Printer cable
                            </Td>
                            <Td size={4}>
                                <TdContent>
                                    Printer cable damage <Count>05</Count>{' '}
                                </TdContent>
                            </Td>
                            <Td size={5}>
                                Check this link <TdSpan>"Printer resolve doc"</TdSpan> to resolve Printer Issues.
                            </Td>
                        </Tr>
                    </div>
                </Tbody>
            </Table>
        </TableContainer>
    )
}

const CommonInputs = () => {
    return (
        <InputContainer>
            <InputWrapper>
                <Input type="text" />
                <InputContent>Text</InputContent>
            </InputWrapper>
        </InputContainer>
    )
}

export const Dropdwon = (props: any) => {
    return (
        <DropdownWrapper>
            <InputContainer>
                <Input type="text" />
                <InputContent>{props.header}</InputContent>
                <CancelButton>{/* <DropdownWithCircle /> */}</CancelButton>
                <DropdownUlWrapper>
                    <DropdownUl>
                        <DropdownLi>{props.content}</DropdownLi>
                    </DropdownUl>
                </DropdownUlWrapper>
            </InputContainer>
        </DropdownWrapper>
    )
}

export const ApprovalPopup = (props: { historyPopup: ApprovalHistoryT; closePopup: any }) => {
    const { historyPopup, closePopup } = props

    const comments =
        historyPopup.approval[0].comments && historyPopup.approval[0].comments != ''
            ? historyPopup.approval[0].comments
            : '-'

    var someDate = new Date(historyPopup.createdDate)
    var numberOfDaysToAdd = historyPopup.approval[0].expiry.days ? historyPopup.approval[0].expiry.days : 0
    var numberOfHoursToAdd = historyPopup.approval[0].expiry.hours ? historyPopup.approval[0].expiry.hours : 0
    var numberOfMinsToAdd = historyPopup.approval[0].expiry.mins ? historyPopup.approval[0].expiry.mins : 0

    someDate.setDate(someDate.getDate() + numberOfDaysToAdd)
    someDate.setHours(someDate.getHours() + numberOfHoursToAdd)
    someDate.setMinutes(someDate.getMinutes() + numberOfMinsToAdd)

    return (
        <ApprovalWrapper>
            <ApprovalBg>
                <ApprovalHeader>
                    <div className="approval_img">
                        <ApprovalIcon />
                    </div>
                    <div className="approval_header">
                        <h6>Approval Request</h6>
                        <p>Request details</p>
                    </div>
                    <DivisionDiv onClick={closePopup}>
                        <CloseIconWithCircle />
                    </DivisionDiv>
                </ApprovalHeader>
                <WidthContainer>
                    <AppButton
                        background={
                            historyPopup.request_status == 'Waiting for approval'
                                ? '#FFE5D3'
                                : historyPopup.request_status == 'Approved'
                                ? '#dbffea'
                                : '#FFE5E5'
                        }
                        color={
                            historyPopup.request_status == 'Waiting for approval'
                                ? '#E8790A'
                                : historyPopup.request_status == 'Approved'
                                ? '#0BB251'
                                : 'red'
                        }
                    >
                        {historyPopup.request_status == 'Waiting for approval'
                            ? 'Requested'
                            : historyPopup.request_status}
                    </AppButton>
                </WidthContainer>
                <ApprovalHeader>
                    <div className="approval_header">
                        <h6>{historyPopup.title}</h6>
                        <p>{historyPopup.details}</p>
                    </div>
                </ApprovalHeader>

                <ApprovalRow>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Requester Name: </h6>
                            <p>{historyPopup.requester_name}</p>
                        </div>
                    </ApprovalHeader>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Requester Email: </h6>
                            <p>{historyPopup.requester_email}</p>
                        </div>
                    </ApprovalHeader>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Requested Time: </h6>
                            <p>
                                {formatDateInCard(historyPopup.createdDate)} {formatAMPM(historyPopup.createdDate)}
                            </p>
                        </div>
                    </ApprovalHeader>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Status: </h6>
                            <p>
                                {historyPopup.request_status == 'Waiting for approval'
                                    ? 'Pending'
                                    : historyPopup.request_status}
                            </p>
                        </div>
                    </ApprovalHeader>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Request Expiry Time:</h6>
                            <p>
                                {formatDateInCard(someDate.toString())} {formatAMPM(someDate.toString())}
                            </p>
                        </div>
                    </ApprovalHeader>
                    <ApprovalHeader>
                        <div className="approval_header">
                            <h6>Comments:</h6>
                            <p>{comments}</p>
                        </div>
                    </ApprovalHeader>
                </ApprovalRow>
                {historyPopup.details_link && (
                    <WidthContainer>
                        <Button onClick={() => window.open(historyPopup.details_link, '_blank')}>
                            {historyPopup.link_description}
                        </Button>
                    </WidthContainer>
                )}
            </ApprovalBg>
        </ApprovalWrapper>
    )
}
