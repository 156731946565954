/*eslint-disable*/
import React, { useCallback, useEffect, useState } from 'react'

//TYPE IMPORTS
import { AssistantT, DumpAssistant } from '@/Assistant/Construction/kind'
import { DefaultDialogue, DialogueT, ResponseNodeE, slotsT } from '@/Assistant/Dialogue/kind'
//COMPONENT IMPORT
import { DialogueContext } from '@/Assistant/DialogueCrew/Context'
import { sortBySibling } from '@/Assistant/DialogueCrew/action'
import { ActionT, LOADING, PageStateT, VIEW } from '@/Assistant/DialogueCrew/kind'
import { ExpressionT } from '@/Assistant/Editor/Action'
import { EntityT } from '@/Assistant/Entity/kind'
import { IntentT } from '@/Assistant/Intent/kind'
//ACTIONS IMPORTS
import { getAssistants, getDialogueRootDescendants, getDialogueRoots, getDialogues, getEntities } from '@/Assistant/Mechanisms/action'

import { FaqPopup } from './FaqPopup'
import { useQueryClient } from 'react-query'

const Ramda = require('ramda')

//CONTENT IMPORT

const actionMap = (arr: any[], result: string[]): string[] =>
    arr.reduce((accumulator, currentvalue): string[] => {
        if (currentvalue.children.length > 0) {
            return actionMap(
                currentvalue.children,
                currentvalue.value.meta_data.action_id
                    ? accumulator.concat(currentvalue.value.meta_data.action_id)
                    : accumulator
            )
        }
        if (currentvalue.value.meta_data.action_id) {
            return accumulator.concat(currentvalue.value.meta_data.action_id)
        }
        return accumulator
    }, result)

const fetchDes = (dialogues: DialogueT[], data: DialogueT, result: DialogueT[]): DialogueT[] => {
    return dialogues
        .filter(e => e.parent === data.uuid)
        .reduce((accum: DialogueT[], val: DialogueT): DialogueT[] => {
            return [...fetchDes(dialogues, val, accum), val]
        }, result)
}
const watsoncontext = (dialogues: DialogueT[]) =>
    dialogues
        .filter(e => e.type === 'if_node' || e.type === 'faq_node')
        .reduce((accum, val: DialogueT) => {
            let fetch = fetchDes(dialogues, val, [val])
            if (val.type === 'if_node') {
                const resnodes = Object.values(
                    fetch
                        .concat([val])
                        .map(e => e.responses)
                        .reduce((accu: ResponseNodeE[], val) => accu.concat(val as ResponseNodeE[]), [])
                        .reduce((accum, val: ResponseNodeE) => {
                            if (val.type === 'slot_container') {
                                const slots = val.slots.reduce((accum: any, slot_val: slotsT) => {
                                    return {
                                        ...accum,
                                        [slot_val.variable]: {
                                            variable: slot_val.variable,
                                            node: { ...val, slots: slot_val }
                                        }
                                    }
                                }, {})
                                return {
                                    ...accum,
                                    ...slots
                                }
                            } else if (val.type === 'ask_a_question') {
                                return {
                                    ...accum,
                                    [val.variable]: { variable: val.variable, node: val }
                                }
                            } else if (val.type === 'file_upload') {
                                return {
                                    ...accum,
                                    [val.variable]: { variable: val.variable, node: val }
                                }
                            } else if (val.type === 'context_variable') {
                                const variables = val.variables.reduce(
                                    (acc: any, values: { name: string; expression: ExpressionT }) => {
                                        return [...acc, { variable: values.name, node: val }]
                                    },
                                    []
                                )
                                return {
                                    ...accum,
                                    variables: variables
                                }
                            }
                            return accum
                        }, {})
                )
                return {
                    ...accum,
                    [val.id]: resnodes
                }
            } else {
                return { ...accum, [val.id]: [] }
            }
        }, {})

const AssistantDialogueFAQ = (props: any) => {
    const queryClient = useQueryClient();

    const { workspacename, assistantname, dialogue_node } = props.match.params
    const { onCancel, dialougeId, state, node, refresh, assistants } = props

    const [PageState, setPageState] = useState<PageStateT>(LOADING)

    const [intents, setIntents] = useState<IntentT[]>([])

    const [entities, setEntities] = useState<EntityT[]>([])

    const [assistantID, setAssistantID] = useState<string>('')

    const [assistant, setAssistant] = useState<AssistantT>(DumpAssistant)

    const [currentDialog, setCurrentDialog] = useState<DialogueT>(DefaultDialogue)

    const [dialogues, setDialogues] = useState<DialogueT[]>([])

    const [workspaceDescendents, setWorkspaceDescendents] = useState<DialogueT[]>([])

    const [dialogueContext, setDialogueContext] = useState({})

    const [automationContext, setAutomationContext] = useState({})

    const [actionMixture, setActionMixture] = useState<any[]>([])

    const [defaultNodes, setDefaultNodes] = useState<DialogueT[]>([])

    const [rootDialogs, setRootDialogs] = useState<DialogueT[]>([])

    const initialization = async () => {
        try {
            const assis = await queryClient
            .fetchQuery({
              queryKey : ['assistants'],
              queryFn : () => { 
               return getAssistants(workspacename) 
              },
            })

            const assistantID = assis.reduce((accum: any, val: any) => {
                if (val.name == assistantname) return val.id
                else return accum
            }, '')

            if (assistantID == '') {
                setPageState({
                    action: ActionT.RESOURCE_NOT_FOUND,
                    resource: 'ASSISTANT'
                })
            } else {
                setAssistantID(assistantID)

                setAssistant(
                    assis.reduce((accum: AssistantT, val: AssistantT): AssistantT => {
                        if (val.name.toLowerCase() == assistantname.toLowerCase()) return val
                        else return accum
                    }, DumpAssistant)
                )

                const entityData = await queryClient
                .fetchQuery(['entities', assistantID], () =>
                  getEntities(workspacename, assistantID)
                )

                setEntities(entityData)

                const dialogRootData = await queryClient
                .fetchQuery(['dialogRoots', assistantID], () =>
                  getDialogueRoots(workspacename, assistantID)
                )

                setRootDialogs(dialogRootData)
                const checkRootData = await Promise.all (dialogRootData.map(async(resp)=>{
                    // console.log(resp,resp.uuid,'response')
                    if (resp.type === 'folder'){
                        return await queryClient.fetchQuery(
                            ['dialogDescendants', resp.uuid],
                            () =>
                              getDialogueRootDescendants(
                                workspacename,
                                assistant.id,
                                resp.uuid
                              )
                          );
                    } else {
                        return resp
                    }

                }))
                const removesub = checkRootData.map((ch)=>{
                    if (ch.hasOwnProperty('id')){
                        return ch
                    } else {
                        const folderid = ch.filter((ch:DialogueT) => ch.type === 'folder')[0].uuid
                        return ch.filter((ch:DialogueT) => ch.parent === folderid || ch.type === "faq_node")
                    }
                    })

                setDefaultNodes(
                    dialogRootData.length == 0
                        ? []
                        : sortBySibling(dialogRootData).filter((node: DialogueT) => {
                              if (
                                  node.conditions &&
                                  (node.conditions.type === 'WelcomeExpression' ||
                                      node.conditions.type === 'FallBackExpression')
                              )
                                  return false
                              return true
                          })
                )

                // const workspaceDes: DialogueT[] = await getDialogues(workspacename, assistantID)
                const dialogRoot =Ramda.flatten(removesub)
                setWorkspaceDescendents(dialogRoot)

                setDialogueContext(watsoncontext(Ramda.flatten(removesub)))
                setPageState(VIEW)
            }
        } catch (err) {
            let error = err as any
            if (error.response && error.response.status == 403) {
                setPageState({
                    action: ActionT.UN_AUTHORIZED
                })
            } else {
                setPageState({
                    action: ActionT.ERROR,
                    reason: 'There was a problem in loading Page! <br/> Please click on "Retry"'
                })
            }
        }
    }

    useEffect(() => {
        initialization()
    }, [])

    const fetchCallback = (callback: Function) => initialization().then(() => callback())

    const MixChild = (Mixture: any) => (element: DialogueT) =>
        Ramda.compose(
            sortBySibling,
            Ramda.filter((e: DialogueT) => e.parent == element.uuid)
        )(Mixture)

    const fetchChild = useCallback(() => MixChild(dialogues), [dialogues])()

    // for canvas purpose
    // const rootDialogs: any = []

    return (
        <DialogueContext.Provider
            value={{
                navigateHomePage: () =>
                    props.history.replace(`/workspace/${workspacename}/dialog/${assistantname}/dialogs`),
                dialogueName: currentDialog.title,
                workspaceName: workspacename,
                assistant: assistant,
                assistantID: assistantID,
                dialogueNode: dialogue_node,

                intents: intents,
                setIntents: setIntents,

                entities: entities,

                PageState: PageState,
                setPageState: setPageState,
                fetchChild: fetchChild,

                fetchCallback: fetchCallback,

                dialogueContext,
                automationContext,
                workspaceDescendents,
                actionMixture,
                rootDialogs,
                parentIfnode: {}
            }}
        >
            <FaqPopup
                onCancel={onCancel}
                workspacename={workspacename}
                dialougeId={dialougeId}
                node={node}
                state={state}
                refresh={refresh}
                assistants={assistants}
                props={props}
            />
        </DialogueContext.Provider>
    )
}

export default AssistantDialogueFAQ
