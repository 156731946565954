/*eslint-disable*/
import React from 'react'
/*eslint-disable*/
import ReactTooltip from 'react-tooltip'

import { FlagIcon, MapIcon, MenWithArrowIcon, MenWithFlagIcon } from '@/common/Icons/Icons'

import banner_dashboard from '@/common/images/banner_dashboard.png'

export function DashboardBannerContainer(props: any) {
    return (
        <div className="dashboard_banner_image">
            <img src={banner_dashboard} alt="dashboard" title="dashboard" />
            <div className="dashboard_start">
                <div className="hover_dashboard_content" data-tip data-for="sadFace">
                    <MenWithArrowIcon />
                    <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                        <span>Show sad face</span>
                    </ReactTooltip>
                </div>
            </div>
            <div className="dashboard_steps">
                <div className="dashboard_step_1">
                    <div className="dashboard_step_1_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_2">
                    <div className="dashboard_step_2_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_3">
                    <div className="dashboard_step_3_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_4">
                    <div className="dashboard_step_4_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_5">
                    <div className="dashboard_step_5_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_6">
                    <div className="dashboard_step_6_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_7">
                    <div className="dashboard_step_7_map">
                        <div className="hover_dashboard_content" data-tip data-for="sadFace">
                            <MapIcon />
                            <ReactTooltip className="dashboard_step_1_hover" id="sadFace" type="warning" effect="solid">
                                <span>Show sad face</span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_8">
                    <div className="dashboard_step_8_map">
                        <div className="dashboard_step_flag">
                            <div className="hover_dashboard_content" data-tip data-for="sadFace">
                                <FlagIcon />
                                <ReactTooltip
                                    className="dashboard_step_1_hover"
                                    id="sadFace"
                                    type="warning"
                                    effect="solid"
                                >
                                    <span>Show sad face</span>
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_9">
                    <div className="dashboard_step_9_map">
                        <div className="dashboard_step_success">
                            <div className="hover_men_with_flag" data-tip data-for="sadFace">
                                <MenWithFlagIcon />
                                <ReactTooltip
                                    className="dashboard_step_1_hover"
                                    id="sadFace"
                                    type="warning"
                                    effect="solid"
                                >
                                    <span>Show sad face</span>
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard_step_10">
                    <div className="dashboard_step_10_map">
                        <div className="dashboard_step_8_map">
                            <div className="hover_dashboard_content" data-tip data-for="sadFace">
                                <MapIcon />
                                <ReactTooltip
                                    className="dashboard_step_1_hover"
                                    id="sadFace"
                                    type="warning"
                                    effect="solid"
                                >
                                    <span>Show sad face</span>
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
