import {
  deleteJson,
  getJson,
  knowledgeApiUrl,
  loginApiURL,
  postJson,
  putJson,
} from '@/common/utils/api-utils';
import { sortAnArrayAlphabetically } from '@/Flows/canvas/utils';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  KnowledgeBase,
  knowledgeCategory,
  Output,
  Resp,
  Root,
  SyncOutput,
} from './MockFiles';
const MockValue = false
const fetchKBCategory = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;

  const data = MockValue ? knowledgeCategory :
  await getJson(
    knowledgeApiUrl(workspace, 'knowledge-base/category')
  );

  // const redata:Output[] = []
  // Promise.resolve(knowledgeCategory)
  //   .then((data)=> {
  //       redata.push(...data.output)})
  //       // (data.output[Math.floor((Math.random()*data.output.length))])})

  // return redata;
  return data.output;
};

export const useKBCategory = (workspace: string) => {
  const {
    data: kbCategorydata,
    status: kbCategorydataStatus,
    refetch: kbCategoryrefetch,
    dataUpdatedAt,
    isRefetching,
  } = useQuery(['kbcategory', workspace], fetchKBCategory, {
    refetchInterval: 15000,
  });
  return {
    kbCategorydata,
    kbCategorydataStatus,
    kbCategoryrefetch,
    dataUpdatedAt,
    isRefetching,
  };
};

export const useExternalCategory = (workspace: string) => {
  const { kbCategorydata } = useKBCategory(workspace);

  const external: Output[] | [] =
    kbCategorydata && kbCategorydata.length > 0
      ? sortAnArrayAlphabetically(kbCategorydata.filter((kb) => kb.group === 'EXTERNAL'),"name")
      : [];

  const externalCategory =
    external.length > 0 ? external[0]?.knowledgeBase : [];
  const combinedKB =
    external.length > 0 ? external.map((e) => e.knowledgeBase) : [];
  const combine: KnowledgeBase[] = Array.prototype.concat.apply([], combinedKB);
  const exCatResult =
    combine &&
    combine.reduce((r, a) => {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));
  // console.log(external,'exCatResult')
  return {
    kbCategorydata,
    exCatResult,
    external,
    externalCategory,
  };
};

const fetchSyncHistory = async ({ queryKey }: any) => {
  const [_, workspace, id] = queryKey;
  console.log(workspace, 'fromapi');

  const data: SyncOutput = await getJson(
    knowledgeApiUrl(workspace, `knowledge-base/${id}/sync-history`)
  );
  return data.output;
};

export const useSyncHistory = (workspace: string, id: string) => {
  const {
    data: kbSyncData,
    status: overAllSummaryStatus,
    error:dataError,
    refetch: kbCategoryrefetch,
    isRefetching,
    isFetching:kbSyncFetching
  } = useQuery(['kbSync', workspace, id], fetchSyncHistory, {
    refetchInterval: 15000,
  });
  // console.log(kbSyncData, 'kbSyncData');
  return {
    kbSyncData,
    overAllSummaryStatus,
    kbCategoryrefetch,
    isRefetching,
    dataError,
    kbSyncFetching
  };
};

// const fetchKb = async ({ queryKey }: any) => {
//   const [_, workspace, id] = queryKey;

//   const data:SyncOutput = await getJson(
//     knowledgeApiUrl(workspace, `knowledge-base/v2/${id}`)
//   )
//   return data.output;
// };

// export const useGetKb= (workspace: string, id: string) => {
//   const {
//     data: kbData,
//     status,
//     isFetching,
//   } = useQuery(['kbSync', workspace, id], fetchKb, {
//     refetchInterval:false
//   });
//   // console.log(kbSyncData, 'kbSyncData');
//   return {
//     kbData,
//     status,
//     isFetching,
//   };
// };

export const useSaveConnection = (cancel: () => void, err: () => void,loader?:()=>void) => {
  const queryClient = useQueryClient();

  const { mutate: saveConmutate } = useMutation(
    async ({ workspace, connectionData }: any) =>
      await postJson(knowledgeApiUrl(workspace, `knowledge-base/v2`))(
        connectionData
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        cancel();
      },
      onError: () => {
        err();
      },
      onMutate:()=>{
        loader && loader()
      }
    }
  );

  return { saveConmutate };
};
export const useCustomConnection = (cancel: () => void, err: () => void,loader?:()=>void) => {
  const queryClient = useQueryClient();

  const { mutate: saveCustommutate } = useMutation(
    async ({ workspace, connectionData }: any) =>
      await postJson(knowledgeApiUrl(workspace, `knowledge-base/custom`))(
        connectionData
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        cancel();
      },
      onError: () => {
        err();
      },
      onMutate:()=>{
        loader && loader()
      }
    }
  );

  return { saveCustommutate };
};
export const useSaveConnectionVivantio = (cancel: () => void, err: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: saveConVivantioMutate } = useMutation(
    async ({ workspace, connectionData }: any) =>
      await postJson(knowledgeApiUrl(workspace, `knowledge-base/custom`))(
        connectionData
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        cancel();
      },
      onError: () => {
        err();
      },
    }
  );

  return { saveConVivantioMutate };
};

export const useUpdateConnection = (cancel: () => void, err: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: updateConmutate } = useMutation(
    async ({ workspace, connectionData, id,Connection }: any) =>
      await putJson(knowledgeApiUrl(workspace, `knowledge-base/${Connection == 'vivantio' || Connection == 'freshservice' || Connection == "amazons3" || Connection == "sharepointcustom"  ? 'custom' :'v2'}/${id}`))()(
        connectionData
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        cancel();
      },
      onError: () => {
        err();
      },
    }
  );

  return { updateConmutate };
};

export const useSaveCred = (
  success: (Response: Resp) => void,
  error: () => void
) => {
  const queryClient = useQueryClient();
  const { mutate: saveCredMutate } = useMutation(
    async ({ workspace, credBody }: any) =>
      await postJson(
        knowledgeApiUrl(workspace, `knowledge-base/v2/credential`)
      )(credBody),
    {
      onSuccess: (response) => {
          success(response);
          queryClient.invalidateQueries('kbcategory');
      },
      onError: () => {
        error();
      },
    }
  );
  return { saveCredMutate };
};

export const useUpdateCred = (success: (Response: Resp) => void, error: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: updateCredMutate } = useMutation(
    async ({ workspace, id, updateCredBody }: any) =>
      await putJson(
        knowledgeApiUrl(workspace, `knowledge-base/v2/credential/${id}`)
      )()(updateCredBody),
    {
      onSuccess: (response) => {
        success(response);
        queryClient.invalidateQueries('kbcategory');
      },
      onError: () => {
        error();
      },
    }
  );

  return { updateCredMutate };
};

export const useAuthSaveCred = (
  success: (Response: Resp) => void,
  error: () => void
) => {
  const queryClient = useQueryClient();
  const { mutate: saveAuthCredMutate } = useMutation(
    async ({ workspace, credBody }: any) =>
      await postJson(
        knowledgeApiUrl(workspace, `knowledge-base/v2/credential`)
      )(credBody),
    {
      onSuccess: (response) => {
          success(response);
          queryClient.invalidateQueries('kbcategory');
      },
      onError: () => {
        error();
      },
    }
  );
  return { saveAuthCredMutate };
};
export const useAuthUpdateCred = (success: (response:Resp) => void, error: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: updateAuthCredMutate } = useMutation(
    async ({ workspace, id, updateCredBody }: any) =>
      await putJson(
        knowledgeApiUrl(workspace, `knowledge-base/v2/credential/${id}`)
      )()(updateCredBody),
    {
      onSuccess: (response) => {
        success(response);
        queryClient.invalidateQueries('kbcategory');
      },
      onError: () => {
        error();
      },
    }
  );

  return { updateAuthCredMutate };
};

export const useDeleteConnection = (success: () => void, error: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: deleteConnMutate } = useMutation(
    async ({ workspace, selectedID }: any) =>
      await deleteJson(
        knowledgeApiUrl(workspace, `knowledge-base/${selectedID}`)
      )(),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        success();
      },
      onError: () => {
        error();
      },
    }
  );

  return { deleteConnMutate };
};

export const useTrainMode = (success: () => void, error: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: trainMutate } = useMutation(
    async ({ workspace, selectedID, trainBody,Connection }: any) =>
      await postJson(
        knowledgeApiUrl(workspace, `knowledge-base/${(Connection === 'vivantio'|| Connection === 'freshservice' || Connection == "amazons3" || Connection == "sharepointcustom")? '' : 'v2/'}${selectedID}/${(Connection === 'vivantio' || Connection === 'freshservice'|| Connection == "amazons3" || Connection == "sharepointcustom") ? 'custom/sync':'modesync'}`)
      )(trainBody),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        success();
      },
      onError: () => {
        error();
      },
    }
  );

  return { trainMutate };
};

const fetchCategories = async ({ queryKey }: any) => {
  const [_, workspace, id] = queryKey;
  console.log(workspace, 'fromapi');

  const data = await getJson(
    knowledgeApiUrl(workspace, `knowledge-base/${id}/custom/filter`)
    // loginApiURL(`/kb-api/${workspace}/knowledge-base/${id}/sync-history`)
  );
  return data.output;
};

export const usegetCategories = (workspace: string, id: string) => {
  const {
    data: allCategories,
    status: overAllSummaryStatus,
    refetch: kbCategoryrefetch,
    isRefetching,
  } = useQuery(['kbSync', workspace, id], fetchCategories, {
    refetchInterval: 15000,
    enabled: id!==""
  });
  // console.log(kbSyncData, 'kbSyncData');
  return {
    allCategories,
    overAllSummaryStatus,
    kbCategoryrefetch,
    isRefetching,
  };
};
export const useUpdateWebsite = (cancel: () => void, err: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: updateWebsiteapi } = useMutation(
    async ({ workspace, connectionData, id }: any) =>
      await putJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${id}`))()(
        connectionData
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        cancel();
      },
      onError: () => {
        err();
      },
    }
  );

  return { updateWebsiteapi };
};
export const useWebsiteTrainMode = (success: () => void, error: () => void) => {
  console.log("traing")
  const queryClient = useQueryClient();
  const { mutate: webSitetrainMutate } = useMutation(
    async ({ workspace, selectedID }: any) =>
      await postJson(
        knowledgeApiUrl(workspace, `knowledge-base/${selectedID}/sync`)
      )({}),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('kbcategory');
        success();
      },
      onError: () => {
        error();
      },
    }
  );

  return { webSitetrainMutate };
};