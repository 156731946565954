/*eslint-disable*/
import React from 'react'
import styled from 'styled-components'

import { CloseIcon, DownArrowCircle } from '@/common/Icons/Icons'

import { Application, Items } from './types'

export const SelectApplications: any = {}
SelectApplications.Main = styled.div`
    display: flex;
    width: 100%;
    float: left;
    flex-direction: column;
    border-bottom: ${props => (props.border ? 'none' : '0.5px solid #e2e2e2')};
    padding: 0px 20px 5px 20px;
`
const ApplicationsHeader: any = {}
ApplicationsHeader.Main = styled.h5`
    width: 100%;
    float: left;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    color: #000;
    margin-bottom: 10px;
    margin-top: 15px;
`
ApplicationsHeader.Span = styled.span<{ emphasizeInfo: boolean }>`
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: ${props => (props.emphasizeInfo === true ? '#ff7900' : '#617995')};
`
export const ApplicationsCard: any = {}

ApplicationsCard.Main = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    cursor: default;
`
ApplicationsCard.Left = styled.div`
    width: 83%;
    float: left;
`
ApplicationsCard.Right = styled.div``

const CardButtonSpan = styled.span`
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: left;
    justify-content: left;
`
const CardButtonSpanRight = styled(CardButtonSpan)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CancelButton = styled.div`
    width: 15px;
    height: 15px;
    background: #999;
    border: 0.5px solid #999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -5px;
    cursor: pointer;
    visibility: hidden;

    :hover {
        background: #0a89e8;
        border: 0.5px solid #fff;
    }
`
const CardButton = styled('h6')`
    width: 18%;
    height: 36px;
    border-radius: 36px;
    cursor: default;
    float: left;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-right: 15px;
    background: #fff;
    border: 0.5px solid #e6e6e6;

    :hover {
        background: #0a89e8;
        color: #fff;
        cursor: pointer;
        border: 0.5px solid #0a89e8;
    }

    &:hover ${CancelButton} {
        visibility: visible;
    }
`

const RightButton = styled(CardButton)<{ disabled?: boolean }>`
    width: 140px;
    font-size: 16px;
    color: #fff;
    background: ${props => (props.disabled == false ? '#0a89e8' : '#0a89e8')};
    opacity: ${props => (props.disabled == false ? 1 : 0.8)};
    cursor: ${props => (props.disabled == false ? 'default' : 'pointer')};
    border: '0.5px solid #0a89e8';
    border: 0.5px solid #0a89e8;
    margin-right: 0px;
`
const Avatar = styled.img`
    width: 26px;
    float: left;
    height: 26px;
    margin: 0;
    border-radius: 50%;
    margin: 0px 5px;
`
const Cancel = styled.span`
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6px;
    height: 6px;

    & > svg {
        fill: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const Next = styled.span`
    width: 15px;
    height: 15px;
    & svg {
        fill: #fff;
        transform: rotate(-90deg);
        width: 12px;
        height: 12px;
        display: inline-flex;
        margin-left: 5px;
    }
`

export function SubHeader(props: any) {
    const { heading, info, emphasizeInfo } = props
    return (
        <ApplicationsHeader.Main>
            {heading} <ApplicationsHeader.Span emphasizeInfo={emphasizeInfo}>({info})</ApplicationsHeader.Span>
        </ApplicationsHeader.Main>
    )
}

export function NextStepButton(props: { disabled?: boolean; onClick: () => void }) {
    const doNothing = () => {}
    return (
        <ApplicationsCard.Right>
            <RightButton disabled={props.disabled} onClick={props.disabled ? doNothing : props.onClick}>
                <CardButtonSpanRight>
                    Next
                    <Next>
                        <DownArrowCircle />
                    </Next>
                </CardButtonSpanRight>
            </RightButton>
        </ApplicationsCard.Right>
    )
}

type SelectedItemsP = {
    items: Items<Application>
    deselectItem: (item: any) => void
    allowDeselection: boolean
}

export function SelectedItems(props: SelectedItemsP) {
    const { items } = props

    return (
        <ApplicationsCard.Main>
            <ApplicationsCard.Left>
                {items.map(item => (
                    <CardButton key={item.name}>
                        <Avatar src={item.image} alt="marketplace" title="marketplace" />
                        <CardButtonSpan>{item.name}</CardButtonSpan>
                        {props.allowDeselection ? (
                            <CancelButton onClick={() => props.deselectItem(item)}>
                                <Cancel>
                                    <CloseIcon />
                                </Cancel>
                            </CancelButton>
                        ) : null}
                    </CardButton>
                ))}
            </ApplicationsCard.Left>
        </ApplicationsCard.Main>
    )
}

SelectedItems.Wrapper = (props: any) => {
    return <SelectApplications.Main border>{props.children}</SelectApplications.Main>
}
SelectedItems.Main = (props: any) => {
    return <ApplicationsCard.Main>{props.children}</ApplicationsCard.Main>
}

type SelectedItemsHeaderP = {
    heading: string
    info: string
    emphasizeInfo: boolean
}

SelectedItems.Header = (props: SelectedItemsHeaderP) => {
    const { heading, info, emphasizeInfo } = props
    return (
        <ApplicationsHeader.Main>
            {heading} <ApplicationsHeader.Span emphasizeInfo={emphasizeInfo}></ApplicationsHeader.Span>
        </ApplicationsHeader.Main>
    )
}
