import {
  AddIcon,
  CheckColorIcon,
  DialogCardIcon,
  DropdownWithCircle,
  FolderColorIcon,
  NewThumbsDownIcon,
  NewThumbsUpIcon,
  VideoIcon,
  WorkativKBIcon,
} from '@/common/Icons/Icons';
import knowledgeWorkativImg from '@/common/images/no_kb.svg';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { Modal } from "@/common/components/Modal";
import { TabelWrapper } from "@/common/styled/Dialogue.Dumb";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@/common/styled/Workflow.Analytics.Dumb";
import { ButtonContainer, CancelButton, Input, InputContainer, InputContent, InputWrapper } from "@/common/styled/Workflow.Dumb";

import React from 'react';
import { ToolBox } from '@/common/components/Card';

export function KnowledgeWorkativKB() {
  return (
    <>
      <section className="knowledge_container hide">
        <div className="knowledge_content">
          <div>
            <img src={knowledgeWorkativImg} alt="Knowledge Ai img" />
          </div>
          <div>
            <p>Generate answers from your knowledge base.</p>
            <p> Empty as wind. Name your knowledge base.</p>
          </div>
          <InputContainer>
            <Input primary type="text" />
            <InputContent>IT Support</InputContent>
          </InputContainer>

          <Button className="" primary>
            <span className="kb_external_btn">
              <AddIcon />
            </span>
            <label>Create Knowledge Base</label>
          </Button>
        </div>
        <div className="knowledge_video">
          <span>
            <VideoIcon />
          </span>
        </div>
      </section>

      <section className="entity_cards knowledgeWorkativKB hide">
        <div className="col-md-12">
          <div className="row card_grid_view">

            <div className="col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer">
              <div className="dialog_icon">
                <span>
                  <WorkativKBIcon />
                </span>
                <span>
                  <CheckColorIcon />
                </span>
              </div>
              <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                  <div className="d_card_right">
                    <div className="search-card-title d-inline">
                      <h3 style={{ paddingTop: 0, marginBottom: 10 }}>IT Support</h3>
                    </div>
                    <p>
                      Total Items: <span>0</span>
                    </p>
                    <p>
                    Synced Items: <span>0</span>
                    </p>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Updated: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Synced: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer">
              <div className="dialog_icon">
                <span>
                  <WorkativKBIcon />
                </span>
                <span>
                  <CheckColorIcon />
                </span>
              </div>
              <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                  <div className="d_card_right">
                    <div className="search-card-title d-inline">
                      <h3 style={{ paddingTop: 0, marginBottom: 10 }}>HR Help Docs</h3>
                    </div>
                    <p>
                      Total Items: <span>0</span>
                    </p>
                    <p>
                    Synced Items: <span>0</span>
                    </p>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Updated: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Synced: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer">
              <div className="dialog_icon">
                <span>
                  <WorkativKBIcon />
                </span>
                <span>
                  <CheckColorIcon />
                </span>
              </div>
              <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                  <div className="d_card_right">
                    <div className="search-card-title d-inline">
                      <h3 style={{ paddingTop: 0, marginBottom: 10 }}>Finance KB</h3>
                    </div>
                    <p>
                      Total Items: <span>0</span>
                    </p>
                    <p>
                    Synced Items: <span>0</span>
                    </p>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Updated: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Synced: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer">
              <div className="dialog_icon">
                <span>
                  <WorkativKBIcon />
                </span>
                <span>
                  <CheckColorIcon />
                </span>
              </div>
              <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                  <div className="d_card_right">
                    <div className="search-card-title d-inline">
                      <h3 style={{ paddingTop: 0, marginBottom: 10 }}>Marketing KB</h3>
                    </div>
                    <p>
                      Total Items: <span>0</span>
                    </p>
                    <p>
                    Synced Items: <span>0</span>
                    </p>
                  </div>
                </div>
                <div className="error_icon_wrapper">
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Updated: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Synced: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-12 dialog_cards_layer">
              <div className="dialog_icon">
                <span>
                  <WorkativKBIcon />
                </span>
                <span>
                  <CheckColorIcon />
                </span>
              </div>
              <div className="search_word_highligter">
                <div className="d_card_wrapper ">
                  <div className="d_card_right">
                    <div className="search-card-title d-inline">
                      <h3 style={{ paddingTop: 0, marginBottom: 10 }}>Legal knowledge base</h3>
                    </div>
                    <p>
                      Total Items: <span>0</span>
                    </p>
                    <div className='d_card_wrapper_btn'>
                      <p>
                        Synced Items: <span>01</span>
                      </p>
                      <p>Error Items: <span>02</span></p>
                      <Button primary>
                        Retry
                      </Button>
                      </div>
                    
                  </div>
                </div>
                <div className="error_icon_wrapper">
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Updated: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                  <div className="error_icon_wrapper_left">
                    <span className="entity_span">Last Synced: 05 Jun 2023</span>
                    <span className="entity_span">|</span>
                    <span className="entity_span">7:44PM</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className='knowledgeWorkativKB empty hide'>
        <h2>IT Support</h2>
        <div className='empty_container'>
          <div className='empty_container_box'>
            <img src={knowledgeWorkativImg} alt="Knowledge Workativ img" />
          </div>
        </div>
      </section>


      <section className="entity_cards knowledgeAi_table hide">
            <h3>All Files</h3>
            <div className="col-md-12">
                <div className="row">
                    <TabelWrapper>
                        <TableContainer>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th size="2">Name</Th>
                                        <Th size="1.5">Last Update</Th>
                                        <Th size="1">Author</Th>
                                        <Th size="1">Site</Th>
                                        <Th size="1">Department</Th>
                                        <Th size="1">Category</Th>
                                        <Th size="1">Subcategory</Th>
                                        <Th size="1">Publish</Th>
                                        <Th size="1.5">Feedback</Th>
                                        <Th size="1">Option </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td
                                            size="2"
                                            className={
                                                'list_flow_hr'
                                            }
                                        >
                                            <div className="knowledge_table_name">
                                                <div><FolderColorIcon /></div>
                                                <div>IT Support Document</div>
                                            </div>
                                        
                                        <label>
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                />
                                        </label>               
                                        </Td>
                                        <Td
                                            size="1.5"
                                            className="list_flow_p"
                                        >
                                            <p className="listite_centre">
                                                30 May 2023 | 5:44PM
                                            </p>
                                        </Td>

                                        <Td size="1">
                                            Katherine Smith
                                        </Td>
                                        <Td
                                            size="1"
                                            className="list_flow_span"
                                        >
                                            <span className="entity_span">
                                                Headquaters
                                            </span>
                                        </Td>
                                        <Td
                                            size="1"
                                            className="list_flow_span"
                                        >
                                            IT
                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                Software

                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                Wifi

                                        </Td>
                                        <Td  size="1"
                                            className="list_flow_span">
                                                <div className="show_hide">
                                                    <div className="switch_box box_1">
                                                        <input
                                                        type="checkbox"
                                                        className={`switch_1 disabled`}
                                                        checked={true}
                                                        />
                                                    </div>
                                                </div>

                                        </Td>
                                        <Td  size="1.5">
                                            <div className="knowledge_table_name feedback">
                                                <div><NewThumbsUpIcon /></div>
                                                <div>0</div>
                                            </div>
                                            <div className="knowledge_table_name feedback">
                                                <div><NewThumbsUpIcon /></div>
                                                <div>0</div>
                                            </div>
                                            <div className="knowledge_table_name feedback">
                                                <div><NewThumbsDownIcon /></div>
                                                <div>0</div>
                                            </div>

                                        </Td>
                                        <Td size="1">
                                            <ToolBox>
                                                
                                            </ToolBox>
                                        </Td>
                                            
                                    </Tr>
                                    
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </TabelWrapper>
                </div>
            </div>
      </section>
       {/* Creae Article Popup */}
      <Modal className="knowledge_edit_article hide">
            <h4>Create File</h4>
            <p>Enter name and category</p>
               
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            primary
                            type="text"
                        />
                        <InputContent>File name</InputContent>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Department</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Category</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                        
                    </InputContainer>
                    <InputContainer>
                        <Input
                            type="text"
                        />
                        <InputContent>Subcategory</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                <div className="edit_article_input_box">
                    <InputContainer style={{width: '48.5%'}}>
                        <Input
                            type="text"
                        />
                        <InputContent>Site</InputContent>
                        <CancelButton style={{display: 'block', top: 14}}>
                            <DropdownWithCircle />
                        </CancelButton>
                    </InputContainer>
                </div>
                
            <ButtonContainer style={{padding: 0, justifyContent: 'flex-start'}}>
                <Button style={{margin: '0 20px 0 0'}} primary type="button" data-dismiss="modal">
                    Cancel
                </Button>
                <div className="popup_footer_button_container">
                    <Button style={{margin: 0}}
                        // className={false ? 'ditor_btn_disabled' : ''}
                        type="button"
                        data-dismiss="modal"
                    >
                        Create
                    </Button>
                </div>
            </ButtonContainer>
      </Modal>

      {/* Edit Article Popup */}
      <Modal className="knowledge_edit_article hide">
          <h4>Edit article</h4>
          <p>Enter name and category</p>
              
              <div className="edit_article_input_box">
                  <InputContainer>
                      <Input
                          primary
                          type="text"
                      />
                      <InputContent>Article name</InputContent>
                      
                  </InputContainer>
                  <InputContainer>
                      <Input
                          type="text"
                      />
                      <InputContent>Department</InputContent>
                      <CancelButton style={{display: 'block', top: 14}}>
                          <DropdownWithCircle />
                      </CancelButton>
                      
                  </InputContainer>
              </div>
              <div className="edit_article_input_box">
                  <InputContainer>
                      <Input
                          type="text"
                      />
                      <InputContent>Category</InputContent>
                      <CancelButton style={{display: 'block', top: 14}}>
                          <DropdownWithCircle />
                      </CancelButton>
                      
                  </InputContainer>
                  <InputContainer>
                      <Input
                          type="text"
                      />
                      <InputContent>Subcategory</InputContent>
                      <CancelButton style={{display: 'block', top: 14}}>
                          <DropdownWithCircle />
                      </CancelButton>
                  </InputContainer>
              </div>
              <div className="edit_article_input_box">
                  <InputContainer style={{width: '48.5%'}}>
                      <Input
                          type="text"
                      />
                      <InputContent>Site</InputContent>
                      <CancelButton style={{display: 'block', top: 14}}>
                          <DropdownWithCircle />
                      </CancelButton>
                  </InputContainer>
              </div>
              
          <ButtonContainer style={{padding: 0, justifyContent: 'flex-start'}}>
              <Button style={{margin: '0 20px 0 0'}} primary type="button" data-dismiss="modal">
                  Cancel
              </Button>
              <div className="popup_footer_button_container">
                  <Button style={{margin: 0}}
                      // className={false ? 'ditor_btn_disabled' : ''}
                      type="button"
                      data-dismiss="modal"
                  >
                      Create
                  </Button>
              </div>
          </ButtonContainer>
      </Modal>

        {/* Create Folder Popup */}                                    
        <Modal className="knowledge_create_folder">
            <h4>Create Folder</h4>
            <p>Enter name and description</p>
                {/* <ErrorMessage>{!valid ? errorMsg : newLine}</ErrorMessage> */}
                <InputContainer>
                    <Input
                        primary
                        type="text"
                    />
                    <InputContent>Folder name</InputContent>
                    {/* {!valid && (
                        <InputErrorIcon>
                            <ErrorIcon />
                        </InputErrorIcon>
                    )} */}
                </InputContainer>
                <InputContainer>
                    <Input
                        primary
                        type="text"
                    />
                    <InputContent>Folder description</InputContent>
                    {/* {!valid && (
                        <InputErrorIcon>
                            <ErrorIcon />
                        </InputErrorIcon>
                    )} */}
                </InputContainer>
            <ButtonContainer style={{padding: 0}}>
                <Button style={{margin: '0 20px 0 0'}} primary type="button" data-dismiss="modal">
                    Cancel
                </Button>
                <div className="popup_footer_button_container">
                    <Button style={{margin: 0}}
                        // className={false ? 'ditor_btn_disabled' : ''}
                        type="button"
                        data-dismiss="modal"
                    >
                        Create
                    </Button>
                </div>
            </ButtonContainer>
        </Modal>
        
       

    </>
  );
}
