const regex = /\s/
const specialCharacterRegex = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/

export const ExamplesValidations = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length === 0,
        Error: 'Text Field is Empty'
    }
]
export const ExamplesValidations1 = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    }
]

export const EmptyValidation = [
    {
        validationFn: (value: string) => value.length === 0,
        Error: 'Text Field is Empty'
    }
]

export const inputTextAreaValidations = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length > 255,
        Error: 'Text limit is Exceeded'
    }
]

export const inputTextAreaValidationsForWorkflow = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length > 124,
        Error: 'Text limit is Exceeded'
    }
]
export const onPremTextAreaValidations = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length > 80,
        Error: 'Text limit is Exceeded'
    }
]
export const inputTextAreaValidations1 = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length > 124,
        Error: 'Text limit is Exceeded'
    }
]

const inputValidations = [
    {
        validationFn: regex.test.bind(regex),
        Error: 'Value should not contain space.'
    },
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length === 0,
        Error: 'Text Field is Empty'
    }
]

export const intentValidation = inputValidations.concat([
    {
        validationFn: (value: string) => value.length > 26,
        Error: 'Text limit is Exceeded'
    }
])

export const entityValidation = inputValidations.concat([
    {
        validationFn: (value: string) => value.substring(0, 4) == 'sys-',
        Error: 'Entity name should not contain sys- prefix'
    },
    {
        validationFn: (value: string) => value.substring(0, 10) == 'workativ-',
        Error: 'Entity name should not contain workativ- prefix'
    },
    {
        validationFn: (value: string) => value.length > 64,
        Error: 'Text limit is Exceeded'
    }
])

export const flowValidations = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length === 0,
        Error: 'Text Field is Empty'
    },
    {
        validationFn: (value: string) => value.length > 26,
        Error: 'Text limit is Exceeded'
    }
]

export const validations = [
    {
        validationFn: specialCharacterRegex.test.bind(specialCharacterRegex),
        Error: 'Special characters are not allowed except - and _'
    },
    {
        validationFn: (value: string) => value.length === 0,
        Error: 'Text Field is Empty'
    },
    {
        validationFn: (value: string) => value.length > 26,
        Error: 'Text limit is Exceeded'
    }
]

export const dialogNameValidation = ExamplesValidations.concat([
    {
        validationFn: (value: string) => value.length > 80,
        Error: 'Text limit is Exceeded'
    }
])

export const dialogDescriptionValidation = ExamplesValidations1.concat([
    {
        validationFn: (value: string) => value.length > 80,
        Error: 'Text limit is Exceeded'
    }
])

export const newLine = require('html-react-parser')('')
