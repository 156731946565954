import * as React from 'react';
import * as R from 'ramda';
import produce from 'immer';
import { getProductId } from '../../../shared/common/src/utils/api-utils';
// import { PricingYesIcon; } from '../../../shared/common/src/Icons/Icons';
import {
  BasicIcon,
  BotsIcon,
  ConversationMessageIcon,
  PriceEnterpriseIcon,
  PriceFreeIcon,
  PriceProIcon,
  PriceStandardIcon,
  PricingNoIcon,
  PricingYesIcon,
  WorkflowExecutionIcon,
  WorkflowIcon,
} from '@/common/Icons/Icons';

import {
  PlanResponse,
  ResourceConsumption,
  StripeData,
} from './SubsriptionResponse';

type Required<T> = {
  [P in keyof T]-?: T[P];
};

export interface InvoiceHistoryT {
  startDate: number;
  endDate: number;
  amount: number;
  currency: string;
  invoicePdf: string;
  invoiceId: string;
  status: string;
}

export enum ActionT {
  View = 'View',
  ChangesCard = 'View',
  Loading = 'loading',
  CustomerNotFound = 'CustomerNotFound',
  CustomerInTrial = 'CustomerInTrial',
  Unauthorized = 'Unauthorized',
  Error = 'Error',
}

export type DefaultPlan = {
  defaultPlanId: string;
  defaultPlanName: string;
  defaultPlanDisplayName: string;
  mappedPlanName: string;
  mappedPlanDisplayName: string;
  mappedPlanId: string;
};
export type ViewT = {
  action: ActionT.View;
  plans: PlanResponse[];
  subscription: SubscribedPlan;
  planLimits: {
    [index: string]: ResourceLimitMeta[];
  };
  defaultPlan: DefaultPlan;
  planInfo: PlanInfo;
  workspace: string;
};

export type CustomerInTrialT = {
  action: ActionT.CustomerInTrial;
  subscription: SubscribedPlan;
};

export type LoadingT = {
  action: ActionT.Loading;
};

export type UnauthorizedT = {
  action: ActionT.Unauthorized;
};

export type CustomerNotFoundT = {
  action: ActionT.CustomerNotFound;
};

export type ErrorT = {
  action: ActionT.Error;
  error?: { error: boolean; info: string };
};

export const ErrorPage: ErrorT = {
  action: ActionT.Error,
  error: { error: false, info: '' },
};

export type PageStateT =
  | ViewT
  | LoadingT
  | UnauthorizedT
  | CustomerNotFoundT
  | CustomerInTrialT
  | ErrorT;

export type TrialStatus = 'trialing';

export enum SubscribedStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

type SubscribedCustomerDetails =
  | {
      status: TrialStatus;
      trialEndDate: Date;
      trialStartDate: Date;
      hasTrialEnded: boolean;
    }
  | {
      status: SubscribedStatus;
      stripeSubscription: StripeData;
      current_period_end: number;
      current_period_start: number;
      productSubscriptionId: string;
      addOnSubscriptionId: string;
      hasHadTrial: boolean;
    };

type PlanDetails = {
  referenceName: string;
  displayName: string;
  id: string;
  billed: 'Monthly' | 'Yearly' | 'Not_billed';
  price: string;
};

export const Plans = [
  {
    plans: 'Basic',
    referenceName: 'BASIC',
    type: 'Monthly',
    priority: 1,
    dollar: '$',
    price: '199',
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '2',
        category: 'Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '2000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '4000',
        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '5',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '48 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Standard',
    priority: 2,
    referenceName: 'STANDARD',
    type: 'Monthly',
    dollar: '$',
    price: '399',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
  {
    plans: 'Professional',
    priority: 3,
    referenceName: 'PRO',
    type: 'Monthly',
    dollar: '$',
    price: '699',

    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent: 'To built purposeful conversations',
      },
      {
        category: 'Workflow Builder',
        hoverContent: 'To built automations to resolve an issue',
      },
      {
        category: 'VA on Multi chat channel',
        hoverContent:
          'Deploy the chatbot on a chat channel of your choice like Slack and Microsoft Teams',
      },

      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },

      {
        bolder: '5000',
        category: ' Automation executions',
        hoverContent:
          'The total number of automations that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '10000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },

      {
        bolder: '10',
        category: ' Active automations',
        hoverContent:
          ' At a time, the number of active automations available for use in the Chatbot Builder. Admin will be able to disable existing and enable new automations as per choice. ',
      },
      {
        bolder: '24 Hours',
        category: '  Response time ',
        hoverContent: 'Response time to answer/resolve the client queires.',
      },
      {
        bolder: 'Add-Ons',
        category: ' for 0.1 $',
        hoverContent:
          'Additional cost for each automation execution and message.',
      },
    ],
  },
];

export const AdditionalPlans = [
  {
    plans: 'Free',
    description: 'For individuals who needs to build a basic chatbot.',
    referenceName: 'FREE',
    type: 'Monthly',
    priority: -1,
    image: <PriceFreeIcon />,
    dollar: '$',
    price: '0',
    additionalMessage: '',
    discount: 'free forever',
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '1',
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '1',
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '100',
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '1',
        },
        category: ' Active App Workflows',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '100',
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '0 Agents',
        rightContent: {
          type: 'text' as const,
          text: '0 Agents',
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'text' as const,
          text: '0 GB',
        },
        category: ' Knowledge AI',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Bot Builder',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'FAQ Builder',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Try Me',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Bot Marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Bot with Chat Channels',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Live Agent Handover',
            icon: <PricingNoIcon />,
            hover: {
              type: 'HAS-CONTENT',
              content:
                ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
            },
          },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Workflow Builder',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Workflow Marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Step App Workflows',
            icon: <PricingNoIcon />,
          },
          {
            category: 'Approval Management',
            hover: {
              type: 'HAS-CONTENT',
              content:
                ' Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
            },

            icon: <PricingNoIcon />,
          },
          {
            category: 'App Triggers ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: ' Dynamic Bot Notifications',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Send dynamic chatbot notification to users and initiate bot conversations. ',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: 'On-prem App Connector',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Shared Inbox',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Chat Summary',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Workflows',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'knowledge ai',
        header: 'Knowledge AI',
        status: 'coming-soon',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Connect your Knowledge Base',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Connect your Website',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Workativ Knowledge Base',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingNoIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        features: [],
      },
      {
        category: 'Email, Help Doc Support',
        header: 'Email, Help Doc Support',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
] as const;

export const NonSubscribeblePlans = [
  {
    plans: 'Enterprise',
    description:
      'All-in-one support automation platform for companies to build advanced chatbots, connect knowledge base with Gen AI, trigger app workflows, and provide live chat support.',
    referenceName: 'ENTERPRISE',
    type: 'Monthly',
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    // dollar: '$',
    // price: '595',
    additionalMessage: '',
    discount: 'For pricing, ',
    discountLink:'Book Demo', 
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' Active App Workflows',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '0 Agents',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        category: ' Knowledge AI GPT',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot Studio',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create simple & complex dialogs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Sensitive data masking',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generative AI answers from your knowledge base',
            icon: <PricingYesIcon  />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built bot marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Automate repetitive FAQs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Apps for workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Triggers App actions from bot',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Test your bot in real time',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Live Agent handover',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Deploy bot in multiple chat channels',
            icon: <PricingYesIcon />,
          },
          // {
          //   category: '',
          //   icon: <PricingYesIcon />,
          //   hover: {
          //     type: 'HAS-CONTENT',
          //     content:
          //       ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
          //   },
          // },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation Studio',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Automate simple & complex App workflows',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create multi-step App workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built App Workflow marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced conditions',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Approval management for workflows ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc ',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: ' Trigger workflows based on data',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Send dynamic notifications',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Send dynamic chatbot notification to users and initiate bot conversations.',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Connect with On-prem Apps',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingYesIcon />,
          },
           {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Test & debug workflows',            
            icon: <PricingYesIcon />,
          },
        ],
      },      
      {
        category: 'knowledge ai',
        header: 'Knowledge AI GPT',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Knowledge Base',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Upload your own documents',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Website',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Select multiple Knowledge Sources',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-channel shared Inbox for Bot, Slack, MS Teams, and Website',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create and manage multiple teams',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role based access control for Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Analytics & dashboard for Chats & Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'User personalization',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced chat customizations',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Canned responses',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set business hours',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set chat routing',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Case management',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set SLAs',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Live Chat',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Summary for agents',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions for user queries',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Co-pilot for agent productivity',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations & workflows for agents',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
        ],
      }, 
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'SSO',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingYesIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },
        features: [],
      },
      {
        category: 'Email, Help Doc & Phone Support',
        header: 'Customer Success Manager',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
] as const;

export const PlansV2 = [
  {
    plans: 'Starter',
    description: 'For teams to solve simple use-cases using chatbot and app integrations.',
    priority: 2,
    referenceName: 'STANDARD',
    type: 'Monthly',
    image: <PriceStandardIcon />,
    dollar: '$',
    price: '1,700',
    additionalMessage: '',
    discount: 'per month, billed annually or $1700 USD month-to-month',
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '2',
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '1',
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '2000',
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        category: ' Active App Workflows',
        rightContent: {
          type: 'text' as const,
          text: '5',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '2000',
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        // status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: '  Knowledge AI GPT',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        // status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot Studio',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create simple & complex dialogs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Sensitive data masking',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generative AI answers from your knowledge base',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built bot marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Automate repetitive FAQs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Apps for workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Triggers App actions from bot',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Test your bot in real time',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Live Agent handover',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Deploy bot in multiple chat channels',
            icon: <PricingYesIcon />,
          },
          // {
          //   category: '',
          //   icon: <PricingYesIcon />,
          //   hover: {
          //     type: 'HAS-CONTENT',
          //     content:
          //       ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
          //   },
          // },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation Studio',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Automate simple & complex App workflows',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create multi-step App workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built App Workflow marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced conditions',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Approval management for workflows ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc ',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: ' Trigger workflows based on data',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: 'Send dynamic notifications',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Send dynamic chatbot notification to users and initiate bot conversations.',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: 'Connect with On-prem Apps',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingNoIcon />,
          },
           {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Test & debug workflows',            
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'knowledge ai',
        header: 'Knowledge AI GPT',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Knowledge Base',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Upload your own documents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Website',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Select multiple Knowledge Sources',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        // `status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-channel shared Inbox for Bot, Slack, MS Teams, and Website',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create and manage multiple teams',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role based access control for Agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Analytics & dashboard for Chats & Agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'User personalization',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced chat customizations',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Canned responses',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set business hours',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set chat routing',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Case management',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set SLAs',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Live Chat',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Summary for agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions for user queries',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Co-pilot for agent productivity',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations & workflows for agents',
            icon: <PricingNoIcon />,
          },
        ],
      },      
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'SSO',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingNoIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        features: [],
      },
      {
        category: 'Email, Help Doc Support',
        header: 'Email, Help Doc Support',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
  {
    plans: 'Professional',
    description:
      'For teams to build simple to complex chatbots, trigger app workflows, and provide live chat support. ',
    priority: 3,
    referenceName: 'PRO',
    type: 'Monthly',
    image: <PriceProIcon />,
    dollar: '$',
    price: '2,500',
    additionalMessage: '',
    discount: 'per month, billed annually or $2500 USD month-to-month',
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '3',
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '2',
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '4000',
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '10',
        },
        category: ' Active App Workflows',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '4000',
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '5 Agents',
        rightContent: {
          type: 'text' as const,
          text: '5 Agents',
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: '  Knowledge AI GPT',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        // status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot Studio',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create simple & complex dialogs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Sensitive data masking',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generative AI answers from your knowledge base',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built bot marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Automate repetitive FAQs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Apps for workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Triggers App actions from bot',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Test your bot in real time',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Live Agent handover',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Deploy bot in multiple chat channels',
            icon: <PricingYesIcon />,
          },
          // {
          //   category: '',
          //   icon: <PricingYesIcon />,
          //   hover: {
          //     type: 'HAS-CONTENT',
          //     content:
          //       ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
          //   },
          // },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation Studio',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Automate simple & complex App workflows',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create multi-step App workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built App Workflow marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced conditions',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Approval management for workflows ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc ',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: ' Trigger workflows based on data',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Send dynamic notifications',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Send dynamic chatbot notification to users and initiate bot conversations.',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Connect with On-prem Apps',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingYesIcon />,
          },
           {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Test & debug workflows',            
            icon: <PricingYesIcon />,
          },
        ],
      },
      {
        category: 'knowledge ai',
        header: 'Knowledge AI GPT',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Knowledge Base',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Upload your own documents',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Website',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Select multiple Knowledge Sources',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-channel shared Inbox for Bot, Slack, MS Teams, and Website',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create and manage multiple teams',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role based access control for Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Analytics & dashboard for Chats & Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'User personalization',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced chat customizations',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Canned responses',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set business hours',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set chat routing',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Case management',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set SLAs',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Live Chat',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Summary for agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions for user queries',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Co-pilot for agent productivity',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations & workflows for agents',
            icon: <PricingNoIcon />,
          },
        ],
      }, 
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'SSO',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingNoIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'text' as const,
          text: '$0.1 each',
        },
        features: [],
      },
      {
        category: 'Email, Help Doc & Phone Support',
        header: 'Email, Help Doc & Phone Support',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
  {
    plans: 'Starter',
    description: 'For teams to solve simple use-cases using chatbot and app integrations.',
    priority: 2,
    referenceName: 'STANDARD_YEAR',
    type: 'Yearly',
    image: <PriceStandardIcon />,
    dollar: '$',
    price: '1,530',
    additionalMessage: '',
    discount: 'per month, billed annually or $1700 USD month-to-month',
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '2',
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '1',
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '2000',
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        category: ' Active App Workflows',
        rightContent: {
          type: 'text' as const,
          text: '5',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '2000',
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        // status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: '  Knowledge AI GPT',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        // status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot Studio',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create simple & complex dialogs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Sensitive data masking',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generative AI answers from your knowledge base',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built bot marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Automate repetitive FAQs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Apps for workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Triggers App actions from bot',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Test your bot in real time',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Live Agent handover',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Deploy bot in multiple chat channels',
            icon: <PricingYesIcon />,
          },
          // {
          //   category: '',
          //   icon: <PricingYesIcon />,
          //   hover: {
          //     type: 'HAS-CONTENT',
          //     content:
          //       ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
          //   },
          // },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation Studio',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Automate simple & complex App workflows',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create multi-step App workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built App Workflow marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced conditions',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Approval management for workflows ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc ',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: ' Trigger workflows based on data',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: 'Send dynamic notifications',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Send dynamic chatbot notification to users and initiate bot conversations.',
            },
            icon: <PricingNoIcon />,
          },
          {
            category: 'Connect with On-prem Apps',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingNoIcon />,
          },
           {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Test & debug workflows',            
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'knowledge ai',
        header: 'Knowledge AI GPT',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Knowledge Base',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Upload your own documents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Website',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Select multiple Knowledge Sources',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        // `status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-channel shared Inbox for Bot, Slack, MS Teams, and Website',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create and manage multiple teams',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role based access control for Agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Analytics & dashboard for Chats & Agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'User personalization',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced chat customizations',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Canned responses',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set business hours',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set chat routing',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Case management',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set SLAs',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Live Chat',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Summary for agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions for user queries',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Co-pilot for agent productivity',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations & workflows for agents',
            icon: <PricingNoIcon />,
          },
        ],
      },      
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'SSO',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingNoIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        features: [],
      },
      {
        category: 'Email, Help Doc Support',
        header: 'Email, Help Doc Support',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
  {
    plans: 'Professional',
    description:
      'For teams to build simple to complex chatbots, trigger app workflows, and provide live chat support. ',
    priority: 3,
    referenceName: 'PRO_YEAR',
    type: 'Yearly',
    image: <PriceProIcon />,
    dollar: '$',
    price: '2,250',
    additionalMessage: '',
    discount: 'per month, billed annually or $2500 USD month-to-month',
    resources: [
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '3',
        },
        category: 'Admin',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Number of admins access.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '2',
        },
        category: ' Chatbots',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Create multiple chatbots (IT, HR etc)',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '4000',
        },
        category: ' Chatbot Sessions',
        hover: {
          type: 'HAS-CONTENT',
          content: 'Total number of bot sessions per month.',
        },
        status: 'completed',
      },
      {
        bolder: '1',
        rightContent: {
          type: 'text' as const,
          text: '10',
        },
        category: ' Active App Workflows',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
        },
        status: 'completed',
      },
      {
        bolder: '100',
        rightContent: {
          type: 'text' as const,
          text: '4000',
        },
        category: ' App Workflow Executions',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
        },
        status: 'completed',
      },
      {
        bolder: '5 Agents',
        rightContent: {
          type: 'text' as const,
          text: '5 Agents',
        },
        category: ' Live Chat Inbox',
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
      },
      {
        bolder: '0 GB',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        },
        category: '  Knowledge AI GPT',
        hover: {
          type: 'HAS-CONTENT',
          content:
            ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
        },
        // status: 'coming-soon',
      },
    ],
    productModules: [
      {
        category: 'chatbot',
        header: 'Chatbot Studio',
        hover: {
          type: 'NO-CONTENT',
        },
        rightContent: {
          type: 'none',
        },
        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create simple & complex dialogs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Sensitive data masking',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generative AI answers from your knowledge base',
            icon: <PricingNoIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built bot marketplace',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Automate repetitive FAQs',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Integrate Apps for workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Triggers App actions from bot',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Test your bot in real time',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Live Agent handover',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: ' Deploy bot in multiple chat channels',
            icon: <PricingYesIcon />,
          },
          // {
          //   category: '',
          //   icon: <PricingYesIcon />,
          //   hover: {
          //     type: 'HAS-CONTENT',
          //     content:
          //       ' Ability to transfer chatbot conversation to Live Agent in MS Teams. ',
          //   },
          // },
        ],
      },
      {
        category: 'App Workflow Automation',
        header: 'App Workflow Automation Studio',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'No-code workflow builder to create app based workflow automations for chatbot.',
        },

        status: 'completed',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Automate simple & complex App workflows',
            icon: <PricingYesIcon />,
          },

          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create multi-step App workflows',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Pre-built App Workflow marketplace',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced conditions',
            icon: <PricingYesIcon />,
          },
          {
            category: 'Approval management for workflows ',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc ',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: ' Trigger workflows based on data',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Set app triggers to initiate bot workflows and conversations',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Send dynamic notifications',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Send dynamic chatbot notification to users and initiate bot conversations.',
            },
            icon: <PricingYesIcon />,
          },
          {
            category: 'Connect with On-prem Apps',
            hover: {
              type: 'HAS-CONTENT',
              content: ' Connect chatbot with on-prem applications.',
            },
            icon: <PricingYesIcon />,
          },
           {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Test & debug workflows',            
            icon: <PricingYesIcon />,
          },
        ],
      },
      {
        category: 'knowledge ai',
        header: 'Knowledge AI GPT',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries. ',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Knowledge Base',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Upload your own documents',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Generate answers from your Website',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Select multiple Knowledge Sources',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced customizations',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Live chat inbox',
        header: 'Live Chat Inbox',
        rightContent: {
          type: 'none',
        },
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
        },
        status: 'coming-soon',
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-channel shared Inbox for Bot, Slack, MS Teams, and Website',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Create and manage multiple teams',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role based access control for Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Analytics & dashboard for Chats & Agents',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'User personalization',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Advanced chat customizations',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Canned responses',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set business hours',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set chat routing',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Case management',
            status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Set SLAs',
            status: 'coming-soon',
            icon: <PricingYesIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Live Chat',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Summary for agents',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'AI Suggestions for user queries',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Co-pilot for agent productivity',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'App Integrations & workflows for agents',
            icon: <PricingNoIcon />,
          },
        ],
      }, 
      {
        category: 'Enterprise Security',
        header: 'Enterprise Security',
        hover: {
          type: 'NO-CONTENT',
        },
        status: 'completed',
        rightContent: {
          type: 'none',
        },
        features: [
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Multi-Factor Authentication',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'Role Based Access Control',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
          {
            hover: {
              type: 'NO-CONTENT',
            },
            category: 'SSO',
            // status: 'coming-soon',
            icon: <PricingNoIcon />,
          },
        ],
      },
      {
        category: 'Add-on Consumptions',
        header: 'Add-on Consumptions',
        headerrighticon: <PricingNoIcon />,
        hover: {
          type: 'HAS-CONTENT',
          content:
            'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        },
        rightContent: {
          type: 'text' as const,
          text: '$0.1 each',
        },
        features: [],
      },
      {
        category: 'Email, Help Doc & Phone Support',
        header: 'Email, Help Doc & Phone Support',
        rightContent: {
          type: 'icon' as const,
          icon: <PricingYesIcon />,
        },

        hover: {
          type: 'NO-CONTENT',
        },
        features: [],
      },
    ],
  },
];

type UnwrapArray<T> = T extends Array<infer U> ? U : never;

type PickKey<T, K extends keyof T> = T[K];

export type PlanMetaForUI = UnwrapArray<typeof Plans>;

export type PlanMetaForUIV2 = UnwrapArray<typeof PlansV2>;

export type AdditionalPlansT = UnwrapArray<typeof AdditionalPlans>;

export type NonSubscribeblePlansT = UnwrapArray<typeof NonSubscribeblePlans>;

export type ResourcesToDisplay =
  | UnwrapArray<PickKey<PlanMetaForUIV2, 'resources'>>
  | UnwrapArray<PickKey<AdditionalPlansT, 'resources'>>
  | UnwrapArray<PickKey<NonSubscribeblePlansT, 'resources'>>;

export type ProductModulesToDisplay =
  | UnwrapArray<PickKey<PlanMetaForUIV2, 'productModules'>>
  | UnwrapArray<PickKey<AdditionalPlansT, 'productModules'>>
  | UnwrapArray<PickKey<NonSubscribeblePlansT, 'productModules'>>;

export type AllResourceTypes =
  | 'ADMIN'
  | 'BOT'
  | 'ACTIVE_AUTOMATION'
  | 'AUTOMATION'
  | 'SESSION';

type ResourceCommonDisplay = {
  title: string;
  icon: JSX.Element;
  name: AllResourceTypes;
  priority: number;
};

type FiniteResourceDisplay = ResourceCommonDisplay & {
  type: 'FINITE';
  totalText: string;
  userText: string;
  remainingText: string;
};

type InfiniteResourceDisplay = ResourceCommonDisplay & {
  type: 'INFINITE';
  totalText: string;
  userText: string;
  remainingText: string;
  addOntext: {
    text: string;
    hoverContent: string;
  };
};

export type ResourceDisplay = FiniteResourceDisplay | InfiniteResourceDisplay;

export const ResourceDetails: ResourceDisplay[] = [
  {
    title: 'Active App Workflows',
    icon: <WorkflowIcon />,
    name: 'ACTIVE_AUTOMATION',
    type: 'FINITE',
    totalText: 'Active Workflows Quota',
    userText: 'Used Workflows ',
    remainingText: ' Remaining Workflows ',
    priority: 3,
  },
  {
    title: 'App Workflow Executions',
    icon: <WorkflowExecutionIcon />,
    name: 'AUTOMATION',
    type: 'INFINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Executions ',
    remainingText: 'Remaining Executions',
    addOntext: {
      text: 'Add-On Executions',
      hoverContent: 'Exceeded Executions',
    },
    priority: 4,
  },
  {
    title: 'Chatbots',
    icon: <BotsIcon />,
    name: 'BOT',
    type: 'FINITE',
    totalText: 'Active Bots Quota',
    userText: 'Used Bots ',
    remainingText: ' Remaining Bots ',
    priority: 1,
  },
  {
    title: 'Chatbot Sessions',
    icon: <ConversationMessageIcon />,
    name: 'SESSION',
    type: 'INFINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Sessions ',
    remainingText: 'Remaining Sessions',
    addOntext: {
      text: 'Add-On Sessions',
      hoverContent: 'Exceeded Sessions',
    },
    priority: 2,
  },
  {
    title: 'App Workflow Executions',
    icon: <WorkflowExecutionIcon />,
    name: 'AUTOMATION',
    type: 'FINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Executions ',
    remainingText: 'Remaining Executions',
    priority: 4,
  },
  {
    title: 'Chatbot Sessions',
    icon: <ConversationMessageIcon />,
    name: 'SESSION',
    type: 'FINITE',
    totalText: 'Montly Quota ',
    userText: 'Used Sessions ',
    remainingText: 'Remaining Sessions',
    priority: 2,
  },
];

export type ResourceConsumptionForDisplay = {
  resourceId: string;
  name: string;
  id: AllResourceTypes;
  consumptionType: 'FINITE' | 'INFINITE';
  consumed: string;
  resourceLimit: number;
  priority: number;
};

export type SubscribedPlan = {
  workspace: string;
  displayName: string;
  referenceName: string;
  id: string;
  subscription: SubscribedCustomerDetails;
  planDetails: PlanDetails;
  metaForUI: PlanMetaForUI;
  resourceConsumption: ResourceConsumptionForDisplay[];
  finiteResourceConsumption: ResourceConsumption[];
  infiniteResourceConsumption: ResourceConsumption[];
};

export const planIconMap = {
  BASIC_YEAR: <PriceFreeIcon />,
  STANDARD_YEAR: <PriceStandardIcon />,
  PRO_YEAR: <PriceProIcon />,
  BASIC: <PriceFreeIcon />,
  STANDARD: <PriceStandardIcon />,
  PRO: <PriceProIcon />,
  FREE: <PriceFreeIcon />,
  ENTERPRISE: <PriceEnterpriseIcon />,
};

export const subscriptionStatusMap = {
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete expired',
  trialing: 'Trialing',
  active: 'Active',
  past_due: 'Payment Due',
  unpaid: 'Unpaid',
  canceled: 'Cancelled',
  scheduled_for_cancellation: 'Cancelation Scheduled',
};

export const EnterprisePlan = [
  {
    plans: 'Enterprise',
    referenceName: 'ENTERPRISE',
    type: 'Monthly',
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    dollar: '$',
    price: '0',
    additionalMessage: '',
    discount: '',
    resources: [
      {
        bolder: '1',
        category: 'Admin',
        hoverContent: 'Number of admins access.',
      },
      {
        bolder: '1',
        category: ' Chatbots',
        hoverContent: 'Create multiple chatbots (IT, HR etc)',
      },
      {
        bolder: '200',
        category: ' Chatbot Sessions',
        hoverContent: 'Total number of bot sessions per month.',
      },
      {
        bolder: '1',
        category: ' App Workflows',
        hoverContent:
          'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
      },
      // {
      //     bolder: '300',
      //     category: ' Faq Answers',
      //     isComingSoon: true,
      //     hoverContent: 'Total number of FAQ answer limit per month.'
      // },
      {
        bolder: '100',
        category: ' App Workflow Executions',
        hoverContent:
          'Total number of app workflow executions (from bot or via app triggers) per month.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code FAQ builder to create and automate FAQs for user queries.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent:
          'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent:
          'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Multi-Step App Workflows',
        hoverContent: 'Ability to create multi-step app workflows.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Ability to transfer chatbot session to Live Agent in MS Teams. ',
        icon: <PricingNoIcon />,
      },

      {
        category: 'On-prem App Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent:
          'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc & Phone Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
];

export type ResourceLimitMeta = {
  planDisplayName: string;
  planName: string;
  resourceDisplayName: string;
  resourceLimit: number;
  resourceName: AllResourceTypes;
  resourceType: 'finite' | 'infinite';
};

export type PlanResourcesLimit = Record<string, ResourceLimitMeta[]>;

export type PlanInfo = Record<string, InfoMeta[]>;

export interface InfoMeta {
  infoType: string;
  name: string;
  planDisplayName: string;
  planName: string;
  value: string;
}

export const makeEnterprisePlanUIStructure = (
  resourceLimitMeta: ResourceLimitMeta[],
  planInfoMeta: InfoMeta[],
  planDisplayName: string,
  planReferencename: string,
  price: string,
  planType: string
) => {
  // console.log(
  //   'DOING :: makeEnterprisePlanUIStructure',
  //   resourceLimitMeta,
  //   planInfoMeta,
  //   planDisplayName,
  //   planReferencename
  // );

  const limitUIMeta = resourceLimitMeta.map((x) => {
    switch (x.resourceName) {
      case 'ADMIN': {
        return {
          bolder: `${x.resourceLimit}`,
          category: 'Admin',
          hoverContent: 'Number of admins access.',
          order: 1,
        };
      }
      case 'BOT': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' Chatbots',
          hoverContent: 'Create multiple chatbots (IT, HR etc)',
          order: 2,
        };
      }
      case 'SESSION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' Chatbot Sessions',
          hoverContent: 'Total number of bot sessions per month.',
          order: 3,
        };
      }
      case 'ACTIVE_AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' App Workflows',
          hoverContent:
            'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
          order: 4,
        };
      }
      case 'AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          category: ' App Workflow Executions',
          hoverContent:
            'Total number of app workflow executions (from bot or via app triggers) per month.',
          order: 5,
        };
      }
    }
  });

  const hasAddon = resourceLimitMeta.find((x) => x.resourceType === 'infinite')
    ? {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        isConsumption: '$0.1 each',
      }
    : {
        category: 'Add-On Consumptions',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit.',
        icon: <PricingNoIcon />,
      };

  //   0: {name: "LOGS_LIMIT", value: "30", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "standard"}
  // 1: {name: "ON_PREM_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 2: {name: "AGENT_HANDOFF_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 3: {name: "MULTI_STEP_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}
  // 4: {name: "APPROVAL_ACCESS", value: "yes", planName: "ENTERPRISE_GOTO", planDisplayName: "Enterprise", infoType: "permission"}

  const infoUIMeta = planInfoMeta.map((x) => {
    switch (x.name) {
      case 'MULTI_STEP_ACCESS': {
        return {
          category: 'Multi-Step App Workflows',
          hoverContent: 'Ability to create multi-step app workflows.',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 1,
        };
      }
      case 'AGENT_HANDOFF_ACCESS': {
        return {
          category: 'Agent Handover',
          hoverContent:
            'Ability to transfer chatbot session to Live Agent in MS Teams. ',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 2,
        };
      }
      case 'ON_PREM_ACCESS': {
        return {
          category: 'On-prem App Connect',
          hoverContent: 'Connect chatbot with on-prem applications.',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 3,
        };
      }

      case 'APPROVAL_ACCESS': {
        return {
          category: 'Approval Management',
          hoverContent:
            'Manage approvals for app workflows or service request automated via chatbot. eg., new laptop, account activation, reset password etc',
          icon: x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />,
          order: 4,
        };
      }
    }

    return { order: -1 };
  });

  //  type ReqInfo = Required<UnwrapArray<typeof infoUIMeta>>

  const sorted = R.sortBy(R.prop('order'), limitUIMeta);

  const sortedInfo = R.sortBy(R.prop('order'), infoUIMeta).filter(
    (x) => x.order !== -1
  ) as {
    category: string;
    hoverContent: string;
    icon: JSX.Element;
    order: number;
  }[];

  const x = {
    plans: planDisplayName,
    referenceName: planReferencename,
    type: planType,
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    dollar: '$',
    price: price,
    additionalMessage: '',
    discount: '',
    resources: [...sorted],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code FAQ builder to create and automate FAQs for user queries.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Marketplace',
        hoverContent:
          'Pre-built app workflows, Bots, dialogs*, and FAQs* for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent:
          'Deploy chatbot on your Slack, MS Teams, and as a Chat Widget.',
        icon: <PricingYesIcon />,
      },
      ...sortedInfo,
      ...[hasAddon],
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  };

  // console.log('DOING :: makeEnterprisePlanUIStructure :: output', x);
  return x;
};

export const makeEnterprisePlanUIStructureV2 = (
  resourceLimitMeta: ResourceLimitMeta[],
  planInfoMeta: InfoMeta[],
  planDisplayName: string,
  planReferencename: string,
  price: string,
  planType: string
) => {
  // console.log(
  //   'DOING :: makeEnterprisePlanUIStructure',
  //   resourceLimitMeta,
  //   planInfoMeta,
  //   planDisplayName,
  //   planReferencename
  // );
  const enterprisePlanSchema = NonSubscribeblePlans.filter(
    (x) => x.referenceName === 'ENTERPRISE'
  )[0];

  const limitUIMeta = resourceLimitMeta.map((x) => {
    switch (x.resourceName) {
      case 'ADMIN': {
        return {
          bolder: `${x.resourceLimit}`,
          rightContent: {
            type: 'text' as const,
            text: `${x.resourceLimit}`,
          },
          category: 'Admin',
          hover: {
            type: 'HAS-CONTENT',
            content: 'Number of admins access.',
          },
          order: 1,
          status: 'completed',
        };
      }
      case 'BOT': {
        return {
          bolder: `${x.resourceLimit}`,
          rightContent: {
            type: 'text' as const,
            text: `${x.resourceLimit}`,
          },
          category: ' Chatbots',
          hover: {
            type: 'HAS-CONTENT',
            content: 'Create multiple chatbots (IT, HR etc)',
          },
          order: 2,
          status: 'completed',
        };
      }
      case 'SESSION': {
        return {
          bolder: `${x.resourceLimit}`,
          rightContent: {
            type: 'text' as const,
            text: `${x.resourceLimit}`,
          },
          category: ' Chatbot Sessions',
          hover: {
            type: 'HAS-CONTENT',
            content: 'Total number of bot sessions per month.',
          },
          order: 3,
          status: 'completed',
        };
      }
      case 'ACTIVE_AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          rightContent: {
            type: 'text' as const,
            text: `${x.resourceLimit}`,
          },
          category: ' App Workflows',
          hover: {
            type: 'HAS-CONTENT',
            content:
              'Total no. of active app workflow automations to use within chatbot. Admin can disable old and enable new workflows.',
          },
          order: 4,
          status: 'completed',
        };
      }
      case 'AUTOMATION': {
        return {
          bolder: `${x.resourceLimit}`,
          rightContent: {
            type: 'text' as const,
            text: `${x.resourceLimit}`,
          },
          category: ' App Workflow Executions',
          hover: {
            type: 'HAS-CONTENT',
            content:
              'Total number of app workflow executions (from bot or via app triggers) per month.',
          },
          order: 5,
          status: 'completed',
        };
      }
    }
  });

  const enterpriseProductModules = enterprisePlanSchema.productModules;

  const enterpriseOrCustom = planReferencename.startsWith('ENTERPRISE_')
    ? ('enterprise' as const)
    : ('custom' as const);

  let toUseProductModules =
    enterpriseOrCustom === 'enterprise'
      ? enterpriseProductModules
      : enterpriseProductModules;

  const hasAddon = resourceLimitMeta.find((x) => x.resourceType === 'infinite')
    ? true
    : false;

  let customPM = toUseProductModules;

  if (enterpriseOrCustom === 'custom') {
    // let draftState = toUseProductModules as any;

    customPM = produce(toUseProductModules, (draftState) => {
      planInfoMeta.forEach((x) => {
        switch (x.name) {
          case 'MULTI_STEP_ACCESS': {
            draftState
              .filter(
                (p: any) => p['category'] === 'App Workflow Automation'
              )[0]
              .features.filter(
                (f: any) => f['category'] === 'Multi-Step App Workflows'
              )[0].icon =
              x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />;
            break;
          }
          case 'AGENT_HANDOFF_ACCESS': {
            draftState
              .filter((p: any) => p['category'] === 'chatbot')[0]
              .features.filter(
                (f: any) => f['category'] === 'Live Agent Handover'
              )[0].icon =
              x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />;
            break;
          }
          case 'ON_PREM_ACCESS': {
            draftState
              .filter(
                (p: any) => p['category'] === 'App Workflow Automation'
              )[0]
              .features.filter(
                (f: any) => f['category'] === 'On-prem App Connector'
              )[0].icon =
              x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />;
            break;
          }
          case 'APPROVAL_ACCESS': {
            draftState
              .filter(
                (p: any) => p['category'] === 'App Workflow Automation'
              )[0]
              .features.filter(
                (f: any) => f['category'] === 'Approval Management'
              )[0].icon =
              x.value === 'no' ? <PricingNoIcon /> : <PricingYesIcon />;
            break;
          }
        }
      });

      draftState
        .filter((p: any) => p['category'] === 'Live chat inbox')[0]
        .features.forEach((f: any) => (f.icon = <PricingNoIcon />));

      draftState
        .filter((p: any) => p['category'] === 'knowledge ai')[0]
        .features.forEach((f: any) => (f.icon = <PricingNoIcon />));

      draftState
        .filter((p: any) => p['category'] === 'Enterprise Security')[0]
        .features.forEach((f: any) => (f.icon = <PricingNoIcon />));

      if (hasAddon === false) {
        draftState.filter(
          (p: any) => p['category'] === 'Add-on Consumptions'
        )[0].rightContent = {
          type: 'icon' as const,
          icon: <PricingNoIcon />,
        };
      }
    });
  }
  //  type ReqInfo = Required<UnwrapArray<typeof infoUIMeta>>

  const sorted = R.sortBy(R.prop('order'), limitUIMeta);

  const withAllResources =
    enterpriseOrCustom === 'enterprise'
      ? [
          ...sorted,
          {
            bolder: '0 Agents',
            rightContent: {
              type: 'icon' as const,
              icon: <PricingYesIcon />,
            },
            category: ' Live Chat Inbox',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
            },
            status: 'coming-soon',
          },
          {
            bolder: '0 GB',
            rightContent: {
              type: 'icon' as const,
              icon: <PricingNoIcon />,
            },
            category: '  Knowledge AI GPT',
            hover: {
              type: 'HAS-CONTENT',
              content:
                ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
            },
            status: 'coming-soon',
          },
        ]
      : [
          ...sorted,
          {
            bolder: '0 Agents',
            rightContent: {
              type: 'icon' as const,
              icon: <PricingNoIcon />,
            },
            category: ' Live Chat Inbox',
            hover: {
              type: 'HAS-CONTENT',
              content:
                'Multi-channel shared inbox for your agents or teams to chat with end-users. ',
            },
            status: 'coming-soon',
          },
          {
            bolder: '0 GB',
            rightContent: {
              type: 'icon' as const,
              icon: <PricingNoIcon />,
            },
            category: '  Knowledge AI GPT',
            hover: {
              type: 'HAS-CONTENT',
              content:
                ' Knowledge AI connects with your Knowledge Base and uses Generative AI & LLM to respond to enduser queries.',
            },
            status: 'coming-soon',
          },
        ];

  const planMetaForDisplay = {
    plans: planDisplayName,
    referenceName: planReferencename,
    description:
      enterpriseOrCustom === 'custom'
        ? 'For teams to solve complex use cases across the organisation. '
        : 'For companies to build enterprise level all-in-one chatbot for end users and support agents with enterprise security features.',
    type: planType,
    priority: Infinity,
    image: <PriceEnterpriseIcon />,
    dollar: '$',
    price: price,
    additionalMessage: '',
    discount: '',
    resources: withAllResources,
    productModules:
      enterpriseOrCustom === 'enterprise' ? enterpriseProductModules : customPM,
  };

  // console.log('DOING :: makeEnterprisePlanUIStructure :: output', x);
  return planMetaForDisplay;
};
