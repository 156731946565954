import React from 'react'
import Tooltip from 'react-tooltip-lite'

import { InfoIcon } from '@/common/Icons/Icons'

import { combinatorValueT, conditionT, conditionTYPES, operatorsT } from './kind'

const Summary = ({ conditions }: any) => {
    return (
        <div className="automation_form_req automation_form_req_condition" style={{ background: '#F0FFF9' }}>
            <div className="automation_global_form">
                <div className="select_work_form">
                    <h4>Dialog Summary</h4>
                    <div className="dialog_recognise_none">
                        {conditions.map((val: conditionT) => {
                            if (val.type == conditionTYPES.INTENT) {
                                return <span>{`Intent: "${val.name}" Is recognized`}</span>
                            } else if (val.type == conditionTYPES.COMBINATOR) {
                                return <span>{` "${val.value == combinatorValueT.AND ? 'And' : 'Or'}" `}</span>
                            } else if (val.type == conditionTYPES.ENTITY) {
                                if (val.operator == operatorsT.IS_PRESENT) {
                                    return <span>{`Entity: "${val.name}" is recognized`}</span>
                                } else if (val.operator == operatorsT.IS_NOT_PRESENT) {
                                    return <span>{`Entity: "${val.name}" is not recognized`}</span>
                                } else if (val.operator == operatorsT.IS) {
                                    return <span>{`Entity: "${val.name}" value is "${val.value}"`}</span>
                                } else if (val.operator == operatorsT.IS_NOT) {
                                    return <span>{`Entity: "${val.name}" value is not "${val.value}"`}</span>
                                }
                            } else if (val.type == conditionTYPES.CONTEXT) {
                                if (
                                    val.operator == operatorsT.SELECT &&
                                    Object.keys(val.LHS).length &&
                                    val.LHS.type === 'context' &&
                                    val.LHS.variable !== ''
                                ) {
                                    return <span>{`Variable: "${val.LHS.variable}"`}</span>
                                }
                                if (val.operator == operatorsT.EQUALS_TEXT && val.LHS.type === 'context') {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" equals to (text) "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (
                                    val.operator == operatorsT.DOES_NOT_EQUALS_TEXT &&
                                    val.LHS.type === 'context'
                                ) {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" does not equals to (text) "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (val.operator == operatorsT.EQUALS_NUMBER && val.LHS.type === 'context') {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" equals to (number) "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (
                                    val.operator == operatorsT.DOES_NOT_EQUALS_NUMBER &&
                                    val.LHS.type === 'context'
                                ) {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" does not equals to (number) "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (val.operator == operatorsT.GREATER_THAN && val.LHS.type === 'context') {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" greater than "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (val.operator == operatorsT.LESS_THAN && val.LHS.type === 'context') {
                                    const data: any = val.RHS
                                    return (
                                        <span>
                                            {`Variable: "${val.LHS.variable}" less than "`}
                                            {data.blocks[0].text}
                                            {'"'}
                                        </span>
                                    )
                                } else if (val.operator == operatorsT.IS_TRUE && val.LHS.type === 'context') {
                                    return <span>{`Variable: "${val.LHS.variable}" is true`}</span>
                                } else if (val.operator == operatorsT.IS_FALSE && val.LHS.type === 'context') {
                                    return <span>{`Variable: "${val.LHS.variable}" is false`}</span>
                                }
                            }
                            return <span />
                        })}
                        <div className="float_content">
                            Description{' '}
                            <Tooltip
                                className="target customTip _description"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content="Condition to execute this dialog will be displayed here."
                                distance={5}
                            >
                                <InfoIcon />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary
