import * as R from 'ramda';

import {
  CardDisplayT,
  CardTypes,
  FlowFilterE,
  FlowFilterT,
  FlowResponseT,
  FlowsResponseT,
  GroupResponseT,
  GroupT,
  SortT,
  SortType,
} from './types';

const flowIndefaultGroup = (flow: FlowResponseT) =>
  flow.name == defaultGroupName;

const flowToCard = (flow: FlowResponseT): CardDisplayT => {
  const toReturn = {
    ...flow,
    linkedDialogs: flow.linkedDialogs ? flow.linkedDialogs : undefined,
    displayLabel: flow.name,
    cardType: CardTypes.Flow,
  };

  return toReturn;
};

const notDefaultGroup = (group: GroupResponseT) =>
  group.name !== defaultGroupName;

const groupToCard = (group: GroupResponseT): CardDisplayT => ({
  id: group.id.toString(),
  name: group.name,
  type: 'no_trigger',
  deployed_status: 'saved',
  active_status: false,
  displayLabel: group.name,
  description: group.description,
  modified_date: group.modified_date,
  cardType: CardTypes.Group,
});

export const flowsResponseToCards = (
  flowList: FlowsResponseT,
  sortBy: SortT,
  view?: FlowFilterT,
  innerFilter?: FlowFilterT
) => {
      //   console.log('flowList', flowList);
  const list = flowList.automationListWithApproval;
  const homeFilter = {
    [FlowFilterE.Home]: {
      flow: (flow: FlowResponseT) =>
        true,
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Draft]: {
      flow: (flow: FlowResponseT) => flow.deployed_status === 'saved',
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Active]: {
      flow: (flow: FlowResponseT) =>
        flow.deployed_status !== 'saved' && flow.active_status === true,
      group: (_: GroupT) => true,
    },
    [FlowFilterE.InActive]: {
      flow: (flow: FlowResponseT) => {
        return flow.deployed_status !== 'saved' && flow.active_status === false;
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.EventTrigger]: {
      flow: (flow: FlowResponseT) => {
        return flow.type === 'event_trigger';
      },
      group: (_: GroupT) => true,
    },[FlowFilterE.Trigger]: {
      flow: (flow: FlowResponseT) => {
        return flow.type === 'trigger';
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.AppTrigger]: {
      flow: (flow: FlowResponseT) => {
        return flow.type === 'trigger'||flow.type === 'event_trigger';
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.WebhookTrigger]: {
      flow: (flow: FlowResponseT) => {
        return flow.type === 'webhook_trigger';
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.ChatbotTrigger]: {
      flow: (flow: FlowResponseT) => {
        return flow.type === 'no_trigger';
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Approval]: {
      flow: (flow: FlowResponseT) =>
        // flow.type !== 'trigger' &&
        // flow.type !== 'event_trigger' &&
        list.filter((a) => a.id === flow.id).length > 0,
      group: (_: GroupT) => true,
    },
    // [FlowFilterE.NoTrigger]: {
    //     flow: (flow: FlowResponseT) =>
    //         flow.deployed_status != 'saved' && flow.type != 'no_trigger' && flow.type != 'event_trigger',
    //     group: (_: GroupT) => false
    // }
  };
  const filterPredicate = {
    [FlowFilterE.Home]: {
      flow: (flow: FlowResponseT) => true,
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Draft]: {
      flow: (flow: FlowResponseT) =>
        flow.deployed_status === 'saved',
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Active]: {
      flow: (flow: FlowResponseT) =>
        flow.deployed_status !== 'saved' &&
        flow.active_status === true,
      group: (_: GroupT) => true,
    },
    [FlowFilterE.InActive]: {
      flow: (flow: FlowResponseT) => {
        return (
          flow.deployed_status !== 'saved' &&
          flow.active_status === false
        );
      },
      group: (_: GroupT) => true,
    },
    [FlowFilterE.Approval]: {
      flow: (flow: FlowResponseT) =>
      list.filter((a) => a.id === flow.id).length > 0,
      group: (_: GroupT) => true,
    },
    [FlowFilterE.AppTrigger]: {
      flow: (flow: FlowResponseT) => flow.type === 'event_trigger' || flow.type === 'trigger',
      group: (_: GroupT) => false,
    },
    [FlowFilterE.WebhookTrigger]: {
      flow: (flow: FlowResponseT) => flow.type === 'webhook_trigger',
      group: (_: GroupT) => false,
    },
    [FlowFilterE.ChatbotTrigger]: {
      flow: (flow: FlowResponseT) => flow.type === 'no_trigger',
      group: (_: GroupT) => false,
    },
    [FlowFilterE.UserTrigger]: {
      flow: (flow: FlowResponseT) => flow.type === 'user_trigger',
      group: (_: GroupT) => false,
    },
    // [FlowFilterE.Trigger]: {
    //     flow: (flow: FlowResponseT) =>
    //         flow.deployed_status != 'saved' && (flow.type == 'trigger' || flow.type == 'event_trigger'),
    //     group: (_: GroupT) => false
    // },
    // [FlowFilterE.NoTrigger]: {
    //     flow: (flow: FlowResponseT) => flow.deployed_status != 'saved' && flow.type == 'no_trigger',
    //     group: (_: GroupT) => false
    // }
  };
  view = view ? view : FlowFilterE.Draft;

  const predicate = filterPredicate[view];
  const selectedInnerFilter =
    innerFilter === undefined ? FlowFilterE.Home : innerFilter;
  const innerHomeFilter =
    view === FlowFilterE.Home
      ? homeFilter[selectedInnerFilter].flow
      : (_: FlowResponseT) => true;

  // console.log("DOING :: innerHomeFilter",innerHomeFilter)
  if (flowList.sortedList) {
    const sortedList = flowList.sortedList;
    const cards = sortedList.map((sorted) => {
      switch (sorted.type) {
        case 'automation': {
          const card = flowList.automations
            .filter(predicate.flow)
            .filter(innerHomeFilter)
            .filter((x) => x.id === sorted.id)
            .filter(
              (x) =>
                view !== FlowFilterE.Approval ||
                flowList.automationListWithApproval.findIndex(
                  (y) => y.id === x.id
                ) !== -1
            )
            .map(flowToCard);

          return card[0];
        }
        case 'group': {
          const gCard = flowList.groups
            .filter(predicate.group)
            .filter(notDefaultGroup)
            .filter((x) => x.id === sorted.id)
            .map(groupToCard);
          return gCard[0];
        }
        default: {
          const dcard = flowList.automations
            .filter((x) => x.id === sorted.id)
            .map(flowToCard);
          return dcard[0];
        }
      }
    });
    const sortedCards = cards.filter(Boolean);

    if (sortBy === SortType.Type) {
      const isFlow = R.compose<any, string, boolean>(
        R.equals('trigger'),
        R.prop('type')
      );

      const flattened = R.compose<any[], [any[], any[]], any[]>(
        R.flatten,
        R.partition(isFlow)
      )(sortedCards);

      return flattened;
    }
    if (sortBy === SortType.Name) {
      const sortByNameCaseInsensitive = R.compose(
        R.toLower,
        R.prop<string, any>('name')
      );
      return R.sortBy(sortByNameCaseInsensitive, sortedCards);
    }

    return sortedCards;
  }
  return [];
};

export const responseToFlowCards = (
  flowList: FlowsResponseT,
  groupId: string
) =>
  flowList.automations
    .filter((flow) => flow.groupId == groupId)
    .map(flowToCard);

export const selectedGroupList = (cards: CardDisplayT[]) =>
  cards
    .filter((card) => card.cardType == CardTypes.Group)
    .map((card) => card.id);

export const selectedGroupCards = (cards: CardDisplayT[]) =>
  cards.filter((card) => card.cardType == CardTypes.Group);

export const selectedFlowCards = (cards: CardDisplayT[]) =>
  cards.filter((card) => card.cardType == CardTypes.Flow);

export const selectedFlowList = (cards: CardDisplayT[]) =>
  cards
    .filter((card) => card.cardType == CardTypes.Flow)
    .map((card) => card.id);

export const defaultGroupName = 'Default';

export const isDeployedNoTrigger = (card: CardDisplayT) =>
  card.deployed_status &&
  card.deployed_status !== 'saved' &&
  card.type == 'no_trigger';

const findRoot = (dialogues: any, node: any): any => {
  if (node.parent === null) return node;
  
  if(node.type === "folder") return node;

  const data = dialogues.filter((e: any) => e.uuid === node.parent)[0];
  if (data && data.parent !== null) {
    return findRoot(dialogues, data);
  } else if (data && data.parent === null) {
    return data;
  } else {
    throw new Error('INVALID FORMAT');
  }
};

export const fetchRoots = (dialogues: any, flowId: any) => {
  const roots = dialogues.filter((e: any) => {
    const index =
      e.type === 'if_node' &&
      e.responses.filter(
        (x: any) => x.type === 'automation' && x.automation === flowId
      ).length > 0;
    return index;
  });

  const vals = roots.map((e: any) => findRoot(dialogues, e));
  //console.log('roots', roots, vals)
  const rootsReduced = vals.reduce((accum: any, val: any) => {
    return accum.findIndex((e: any) => e.id === val) === -1
      ? accum.concat([val])
      : val;
  }, []);

  // console.log('roots', rootsReduced, R.uniq(rootsReduced))
  return R.uniq(rootsReduced);
};

export const mockLinkingData = [
  {
    id: '012af52c-06e1-4a36-aad0-e339c75e42e9',
    conditions: { type: 'FallBackExpression' },
    responses: [],
    parent: null,
    title: 'Fallback',
    previous_sibling: 'eb651abd-a48c-4cc6-8a0f-076b213e38dd',
    next_step: 'wait_for_user_input',
    created: '2020-04-03T14:00:28',
    updated: '2020-04-08T04:24:25',
  },
  {
    id: '0a587084-4e87-4d4d-90d6-d6c04876853a',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_1db16447_b88a_4405_bbea_39c15d4af65e',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: true,
      op: 'equals',
    },
    responses: [],
    parent: 'eb651abd-a48c-4cc6-8a0f-076b213e38dd',
    title: 'success',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:25:15',
    updated: '2020-04-08T04:25:15',
  },
  {
    id: '1e293d8d-e6f6-48f2-a7ea-a34b8f9d06df',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_1db16447_b88a_4405_bbea_39c15d4af65e',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: false,
      op: 'equals',
    },
    responses: [],
    parent: 'eb651abd-a48c-4cc6-8a0f-076b213e38dd',
    title: 'failure',
    previous_sibling: '0a587084-4e87-4d4d-90d6-d6c04876853a',
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:25:16',
    updated: '2020-04-08T04:25:16',
  },
  {
    id: '2ecc119e-ec49-4866-a7a6-4cf042613b65',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_d38064d9_fe62_422e_ab14_123f0a627c68',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: true,
      op: 'equals',
    },
    responses: [],
    parent: 'e457d9ed-aef9-4751-aebf-e21ec46c61d9',
    title: 'success',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:06:25',
    updated: '2020-04-08T04:06:25',
  },
  {
    id: '34514829-f17c-4fc9-a501-6232266a9014',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_aa19a935_9a3f_41fa_b151_ec63b1bdbe2a',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: false,
      op: 'equals',
    },
    responses: [],
    parent: 'f83c8604-ad89-455c-8b59-3f6bbbc70827',
    title: 'failure',
    previous_sibling: 'e457d9ed-aef9-4751-aebf-e21ec46c61d9',
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:05:55',
    updated: '2020-04-08T04:05:55',
  },
  {
    id: '4844cdbc-98fa-41e1-806c-643e293a13d8',
    conditions: { type: 'EntityExpression', entity: 'workativ-mobilenumber' },
    responses: [
      {
        type: 'automation',
        id: '6417cad6-620d-488e-847f-f868495b8d15',
        name: '1',
        automation_name: 'ssssss',
        previous_sibling: null,
        parent: '4844cdbc-98fa-41e1-806c-643e293a13d8',
        automation: '2a01f710-aadb-4202-a2e9-622e87d5d66f',
        input: {
          Hwo_768b5342_5455ec: { type: 'path', path: ['SYS_FirstName'] },
          WSMOD_6e3ef12e_7b661: { type: 'path', path: ['SYS_FirstName'] },
        },
        output_variable: 'SYS_1cf410e2_01a0_4bd8_8ca3_676fbccdd0fc',
      },
    ],
    parent: null,
    title: 'automation mapping',
    previous_sibling: 'cb621869-8f40-40b6-945d-10c9ca477b5e',
    next_step: 'wait_for_user_input',
    created: '2020-04-03T14:00:28',
    updated: '2020-04-08T04:05:18',
  },
  {
    id: '9692ee8e-57b4-4a98-8485-1e52bbe9ab3f',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_d38064d9_fe62_422e_ab14_123f0a627c68',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: false,
      op: 'equals',
    },
    responses: [],
    parent: 'e457d9ed-aef9-4751-aebf-e21ec46c61d9',
    title: 'failure',
    previous_sibling: '2ecc119e-ec49-4866-a7a6-4cf042613b65',
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:06:25',
    updated: '2020-04-08T04:06:25',
  },
  {
    id: 'b2186709-2aba-4a54-aa40-6d78795087dd',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_1cf410e2_01a0_4bd8_8ca3_676fbccdd0fc',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: true,
      op: 'equals',
    },
    responses: [
      {
        type: 'automation',
        id: '1dac35b0-244d-40c4-8505-544fa3a3ce80',
        name: '2',
        automation_name: 'freshservice',
        previous_sibling: null,
        parent: 'b2186709-2aba-4a54-aa40-6d78795087dd',
        automation: '23a7cefb-440e-4849-afcc-83d9a930ceb5',
        input: {
          wyd_b8e471ff_5d347: { type: 'text', value: 'asd' },
          rcBsL_9111e8b6_707cb8: { type: 'text', value: 'asd' },
          pKSj_72f53b3c_9083a4: { type: 'text', value: 'asd' },
        },
        output_variable: 'SYS_0f1ad569_872f_428d_8ef1_29674495da20',
      },
    ],
    parent: '4844cdbc-98fa-41e1-806c-643e293a13d8',
    title: 'success',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-07T13:11:53',
    updated: '2020-04-07T13:11:53',
  },
  {
    id: 'c7d80b2e-d295-4902-bcbb-d8d2e3efd77a',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_1cf410e2_01a0_4bd8_8ca3_676fbccdd0fc',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: false,
      op: 'equals',
    },
    responses: [],
    parent: '4844cdbc-98fa-41e1-806c-643e293a13d8',
    title: 'failure',
    previous_sibling: 'b2186709-2aba-4a54-aa40-6d78795087dd',
    next_step: 'wait_for_user_input',
    created: '2020-04-07T13:11:54',
    updated: '2020-04-07T13:11:54',
  },
  {
    id: 'cb621869-8f40-40b6-945d-10c9ca477b5e',
    conditions: { type: 'WelcomeExpression' },
    responses: [],
    parent: null,
    title: 'Welcome',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-03T14:00:28',
    updated: '2020-04-03T14:00:28',
  },
  {
    id: 'cbcb5769-46d0-4f10-8b2a-5183c55ce57e',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_0f1ad569_872f_428d_8ef1_29674495da20',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: false,
      op: 'equals',
    },
    responses: [],
    parent: 'b2186709-2aba-4a54-aa40-6d78795087dd',
    title: 'failure',
    previous_sibling: 'f83c8604-ad89-455c-8b59-3f6bbbc70827',
    next_step: 'wait_for_user_input',
    created: '2020-04-08T03:30:00',
    updated: '2020-04-08T03:30:00',
  },
  {
    id: 'e457d9ed-aef9-4751-aebf-e21ec46c61d9',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_aa19a935_9a3f_41fa_b151_ec63b1bdbe2a',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: true,
      op: 'equals',
    },
    responses: [
      {
        type: 'automation',
        id: '5d373b70-f562-43cf-8f6b-04189ddd159a',
        name: '4',
        automation_name: 'search document',
        previous_sibling: null,
        parent: 'e457d9ed-aef9-4751-aebf-e21ec46c61d9',
        automation: '1967ad6e-c65d-46ab-88fd-435efdce40d0',
        input: { Mnc_c79830c8_b50aa: { type: 'text', value: '12' } },
        output_variable: 'SYS_d38064d9_fe62_422e_ab14_123f0a627c68',
      },
    ],
    parent: 'f83c8604-ad89-455c-8b59-3f6bbbc70827',
    title: 'success',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:05:54',
    updated: '2020-04-08T04:05:54',
  },
  {
    id: 'eb651abd-a48c-4cc6-8a0f-076b213e38dd',
    conditions: { type: 'EntityExpression', entity: 'workativ-mobilenumber' },
    responses: [
      {
        type: 'automation',
        id: '6633286f-1207-451d-90af-495496c713f6',
        name: '6',
        automation_name: 'ssssss',
        previous_sibling: null,
        parent: 'eb651abd-a48c-4cc6-8a0f-076b213e38dd',
        automation: '2a01f710-aadb-4202-a2e9-622e87d5d66f',
        input: {
          Hwo_768b5342_5455ec: { type: 'text', value: '12' },
          WSMOD_6e3ef12e_7b661: { type: 'text', value: '123' },
        },
        output_variable: 'SYS_1db16447_b88a_4405_bbea_39c15d4af65e',
      },
    ],
    parent: null,
    title: 'mapping2',
    previous_sibling: '4844cdbc-98fa-41e1-806c-643e293a13d8',
    next_step: 'wait_for_user_input',
    created: '2020-04-08T04:24:25',
    updated: '2020-04-08T04:24:37',
  },
  {
    id: 'f83c8604-ad89-455c-8b59-3f6bbbc70827',
    conditions: {
      type: 'ComparisionExpression',
      lhs: {
        path: [
          'SYS_0f1ad569_872f_428d_8ef1_29674495da20',
          'output',
          'executionContext',
          'output',
          'success',
        ],
      },
      rhs: true,
      op: 'equals',
    },
    responses: [
      {
        type: 'automation',
        id: '2cdeadd4-9c82-47b4-b5a3-9d3ef2f15046',
        name: '3',
        automation_name: 'ssssss',
        previous_sibling: null,
        parent: 'f83c8604-ad89-455c-8b59-3f6bbbc70827',
        automation: '2a01f710-aadb-4202-a2e9-622e87d5d66f',
        input: {
          Hwo_768b5342_5455ec: { type: 'text', value: 'qqq' },
          WSMOD_6e3ef12e_7b661: { type: 'text', value: 'qqq' },
        },
        output_variable: 'SYS_aa19a935_9a3f_41fa_b151_ec63b1bdbe2a',
      },
    ],
    parent: 'b2186709-2aba-4a54-aa40-6d78795087dd',
    title: 'success',
    previous_sibling: null,
    next_step: 'wait_for_user_input',
    created: '2020-04-08T03:30:00',
    updated: '2020-04-08T03:30:00',
  },
];

function isNumeric(str: string) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as any) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export function getDuplicateName(cardName: string,automations: FlowResponseT[]){
  const filteredAutomationNames = automations
      .map((x) => x.name)
      .filter((x) => x.includes(`${cardName}`))
      .map((x) => x.includes("_") ? x.split(`_`) : [""]);

    const latestCopyCount = R
      .flatten<string>(filteredAutomationNames)
      .filter((x) => x != '' && isNumeric(x))
      .map((x) => parseInt(x))
      .reduce(R.max, 0);

    let name = ''
    if(cardName.includes("_")){
      const splitted = cardName.split("_")
      name = splitted.reduce((accum,val,i)=>{
        if(i+1 == splitted.length){
          return accum += isNumeric(val)?`_${latestCopyCount+1}`:`_${val}_1`
        }else{
          return accum +=val
        }
      },"")
    }else if(latestCopyCount>0){
      name = `${cardName}_${latestCopyCount+1}`
    }else{
      name = `${cardName}_1`
    }

    return name
}