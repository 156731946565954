/*eslint-disable*/
import React from 'react'
import styled, { StyledComponent, css } from 'styled-components'

import { SelectApplication } from './types'

type WrapperP = {
    paddingWrapper: string
    marginWrapper: string
}

export const Wrapper: StyledComponent<'div', any, any, never> = styled.div`
    width: 100%;
    float: left;
    overflow-y: scroll;
    // height: ${(props: any) => props.heightScroll || 'calc(100vh - 100px)'};
    height: ${(props: any) => props.heightScroll || 'auto'};
    // padding: ${(props: any) => props.paddingWrapper || '0px'};
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (max-width: 1400px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }
`

const Item = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    position: relative;
    position: absolute;
    top: 0;
    right: 0;
`
export const SelectApplications: StyledComponent<'div', any, any, never> = styled.div`
    width: 100%;
    float: left;
    margin: ${(props: any) => props.marginWrapper || '0px'};
    display: ${(props: any) => props.display || 'flex'};
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: left;
    padding: ${(props: any) => props.paddingWrapper || '0px'};
`

const Avatar = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 50%;
`

const RadioButtonLabel = styled.label`
    position: absolute;
    top: 25%;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: none;
    cursor: pointer;
`
const RadioButton = styled.input<{ isSelected: boolean }>`
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;

    & + ${RadioButtonLabel} {
        opacity: ${(props: any) => (props.isSelected ? '1' : '0')};
        background: #ff7900;
        border: 1px solid #ff7900;
        display: block;
        cursor: pointer;

        &::after {
            content: '';
            height: 10px;
            width: 10px;
            position: absolute;
            content: '';
            background-image: url(./images/tick.svg);
            background-repeat: no-repeat;
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            top: 4px;
            left: 3px;
            z-index: 2;
        }
    }
`

const ApplicationName = styled.h6`
    width: 100%;
    float: left;
    font-size: 14px;
    font-family: 'Gordita-Medium';
    color: #000;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
`
const Card = styled.div<any>`
    width: 100%;
    min-height: 150px;
    float: left;
    margin: 0px;
    margin-bottom: 0px;
    border-radius: 6px;
    border: 0.5px solid #dad9d9;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: #fff;
    box-shadow: ${(props: any) => (props.isSelected ? '10px 10px 30px #00000029' : 'none')};
    margin-bottom: 0px;

    :hover {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 10px 10px 30px #00000029;
        color: #494949;
        cursor: pointer;
        border: 0.5px solid #fff;
    }
    :hover ${RadioButtonLabel} {
        opacity: 1;
        background: ${(props: any) => (props.isSelected ? '#ff7900' : '#0a89e8')};
        border: 1px solid #fff;
        display: block;
        cursor: pointer;
        &::after {
            content: '';
            height: 10px;
            width: 10px;
            position: absolute;
            content: '';
            background: ${(props: any) => (props.isSelected ? 'url(./images/tick.svg);' : 'none')};
            background-repeat: no-repeat;
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            top: 4px;
            left: 3px;
        }
    }
    :hover ${ApplicationName} {
        color: #494949;
    }
`
type ItemsSelectionP<T> = {
    items: T[]
    selectionLimit: number
    toggleItemSelection: (item: any) => void
    selectedApp:{ name:"All"|string, value:"all"|string }
}

export function MarketPlaceItems(props: ItemsSelectionP<SelectApplication>) {
    const { items, selectedApp } = props
    return (
        <Wrapper style={{ paddingBottom: '80px' }}>
            <SelectApplications marginWrapper="0px 0px 20px 0px;" paddingWrapper="0px 0px" display={'grid'}>
                {items.filter((app)=>{
                 return selectedApp.value == "all" ||  app.id == selectedApp.value 
                }).map(item => (
                    <Card
                        key={item.name}
                        isSelected={item.isSelected}
                        onClick={() => {
                            props.toggleItemSelection(item)
                        }}
                    >
                        <Avatar src={item.image} />
                        <ApplicationName>{item.name}</ApplicationName>
                        {/* <Item>
                            <RadioButton
                                type="radio"
                                name="radio"
                                value="betterPriceOnly"
                                isSelected={item.isSelected}
                            />
                            <RadioButtonLabel />
                        </Item> */}
                    </Card>
                ))}
            </SelectApplications>
        </Wrapper>
    )
}
