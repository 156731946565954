/*eslint-disable*/
import * as React from 'react'

type props = {
    checkBoxShow: boolean
    showCheckBox: boolean
    render: Function
}

type CardS = {
    show: boolean
}

export class List extends React.Component<props, CardS> {
    state: CardS = {
        show: false
    }

    componentDidUpdate() {
        let modal = document.getElementById('modal-root')
        if (modal && modal.children.length > 0 && !this.props.checkBoxShow && this.state.show) {
            this.setState({ show: false })
        }
    }
    setShow = () => {
        this.setState(({ show }) => ({
            show: true
        }))
    }

    clearShow = () => {
        this.setState(({ show }) => ({
            show: false
        }))
    }

    handleDrag = () => {
        this.setState(({ show }) => ({
            show: !show
        }))
    }

    showSelection = () => {
        let { checkBoxShow } = this.props

        return checkBoxShow || this.state.show
    }

    render() {
        let { showCheckBox } = this.props

        return showCheckBox ? (
            <div onMouseOver={this.setShow} onMouseLeave={this.clearShow} className="list-full-width">
                {this.props.render(this.showSelection(), ToolBox)}
            </div>
        ) : (
            <div className="list-full-width">{this.props.render(false, ToolBox)}</div>
        )
    }
}

const ToolBox: React.FunctionComponent = (props: any) => {
    return (
        <div className={!props.className ? 'list-view-icon' : 'ist-view-icon ' + props.className}>{props.children}</div>
    )
}
