/*eslint-disable*/
import React from 'react'
import styled, { StyledComponent } from 'styled-components/macro'

import { InfoIcon, UserIconAnalytics } from '@/common/Icons/Icons'
import { Container, CurrentPlanHeader, HoverUserIcon, Wrapper } from '@/common/styled/Subscription.Dumb'

import visa_img from '@/common/images/visa.png'
import { BillingWrapper, Plan } from './PaidUsersPlan'
import { PlansHeader } from './PlansWrapper'
import { ResourceConsumptionForDisplay, ResourceDisplay } from './kind'

export const AccountAndBilling = () => {
    return (
        <div className="plansWrapper">
            <PlansHeader backButton name={'Billing Estimate'} />
            <AccountAndBillingM />
        </div>
    )
}

export const AccountAndBillingM = () => {
    return (
        <div className="current_plan_wrapper">
            <BodyWrapper>
                <Wrapper>
                    <Container>
                        <BillingWrapper>
                            <Plan />
                            <BillingContainer>
                                <BillingContactWrapper />
                                <BillingEstimation />
                            </BillingContainer>
                        </BillingWrapper>
                    </Container>
                </Wrapper>
            </BodyWrapper>
        </div>
    )
}

//Billing Warpper
export const EllipsisContent = styled.span`
    width: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
`
const ImgContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 0px 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d3d3d3;
    @media (max-width: 1600px) {
        margin-top: 10px;
        padding-bottom: 10px;
    }
`
const ProfileImg = styled.span`
    width: 48px;
    height: 48px;
    float: left;
    border-radius: 50%;
    padding: 10px;
    background: #e0f6ff 0% 0% no-repeat padding-box;
    border: 1px solid #e0f6ff;
    border-radius: 36px;

    svg {
        width: 100%;
        height: 100%;
        fill: #355d8b;
        color: #355d8b;
    }
`
const EmailWrapper = styled.div`
    width: 85%;
    float: left;
    padding-left: 20px;
`
const UserID = styled.div`
    width: 100%;
    float: left;
    font-size: 14px;
    color: #466af3;
    font-family: 'Gordita-Regular';
`
const UserName = styled.div`
    width: 100%;
    float: left;
    font-size: 14px;
    color: #333;
    font-family: 'Gordita-Medium';
`

const VisaImgV2 = styled.img`
    width: 40px;
    height: auto;
    float: left;
`
export const UpgradePlanContent = styled.span`
    color: ${props => (props.fontSpan ? '#2D61FF' : '#355D8B')};
    font-size: 16px;
    font-family: ${props => (props.fontSpan ? 'Gordita-Medium' : 'Gordita-Regular')};

    :hover {
        text-decoration: ${props => (props.fontSpan ? 'underline' : 'none')};
    }

    @media (max-width: 1700px) {
        font-size: 14px;
    }
`
export const MonthlySubscriptionV2 = styled.p`
    width: ${props => (props.fontBold ? 'max-content' : '100%')};
    float: left;
    font-family: ${props => (props.fontBold ? 'Gordita-Medium' : 'Gordita-Regular')};
    font-size: ${props => (props.fontBold ? '14px' : '14px')};
    color: ${props => (props.fontBold ? '#466AF3' : '#000')};
    cursor: ${props => (props.fontBold ? 'pointer' : 'default')};
    margin-top: 10px;
    margin-bottom: 0px;
    padding: ${props => (props.padding ? '0px 20px' : '0px')};

    :hover {
        text-decoration: ${props => (props.fontBold ? 'underline' : 'none')};
    }

    @media (max-width: 1700px) {
        font-size: ${props => (props.fontBold ? '16px' : '16px')};
    }
    @media (max-width: 1500px) {
        font-size: ${props => (props.fontBold ? '15px' : '15px')};
        margin-top: 7px;
    }
    @media (max-width: 1400px) {
        font-size: ${props => (props.fontBold ? '14px' : '14px')};
    }
    a {
        color: #466af3;
        :hover {
            color: #466af3;
        }
    }
`
export const SwitchPayment = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        width: auto;
        :last-child {
            margin-left: 30px;
        }
    }
`

export const UserDetails = (props: any) => {
    return (
        <ImgContainer>
            <ProfileImg>
                <UserIconAnalytics />
            </ProfileImg>
            <EmailWrapper>
                <UserName>
                    <EllipsisContent>{props.name}</EllipsisContent>
                </UserName>
                <UserID>
                    <EllipsisContent>{props.email}</EllipsisContent>
                </UserID>
            </EmailWrapper>
        </ImgContainer>
    )
}
export const PaymentWrapperV2 = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 15px 12px 12px;
    margin-bottom: 10px;
    margin-top: 10px;
`
export const CardDetailsV2 = styled.div`
    width: 100%;
    float: left;
`
export const CardNumberV2 = styled.div`
    width: 80%;
    float: left;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-top: ${props => props.marginTop};

    @media (max-width: 1700px) {
        margin-top: ${props => (props.marginTop ? '7px' : '10px')};
    }
    @media (max-width: 1600px) {
        margin-top: ${props => (props.marginTop ? '7px' : '10px')};
    }
`

export const CardExpiryDetailsV2 = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
`
export const CardHolderDetailsV2 = styled.div`
    width: 100%;
    float: left;
`
export const CardUserV2 = styled.div`
    width: 80%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
    :last-child {
        margin-bottom: 0px;
    }
`
export const CardTypeV2 = styled.div`
    width: 20%;
    float: left;
    display: flex;
    justify-content: flex-end;
`
export const BillingPeriod = () => {
    return (
        <>
            <BillingPeriodContainer>
                <CurrentPlanHeader>Billing Period</CurrentPlanHeader>
                <MonthlySubscriptionV2>Monthly</MonthlySubscriptionV2>
                <MonthlySubscriptionV2 fontBold>Switch to annual payment</MonthlySubscriptionV2>
                <SwitchPayment>
                    <MonthlySubscriptionV2>Payment Method</MonthlySubscriptionV2>
                    <MonthlySubscriptionV2 fontBold>Change Card</MonthlySubscriptionV2>
                </SwitchPayment>
                <PaymentWrapperV2>
                    <CardDetailsV2>
                        <CardNumberV2>{`**** **** **** 4444`}</CardNumberV2>
                        <CardTypeV2>
                            <VisaImgV2 src={visa_img}></VisaImgV2>
                        </CardTypeV2>
                    </CardDetailsV2>
                    <CardExpiryDetailsV2>
                        <CardHolderDetailsV2>
                            <CardUserV2>Imran Quraishy </CardUserV2>
                            <CardUserV2>Expires: 12/2024</CardUserV2>
                        </CardHolderDetailsV2>
                    </CardExpiryDetailsV2>
                </PaymentWrapperV2>
                <MonthlySubscriptionV2>Active Since</MonthlySubscriptionV2>
                <CardNumberV2 marginTop={'10px'}>14 Dec 2020</CardNumberV2>
                <MonthlySubscriptionV2 fontBold>Cancel Subscription</MonthlySubscriptionV2>
            </BillingPeriodContainer>
        </>
    )
}

export const BillingContactWrapper = (props: any) => {
    return (
        <BillingContact>
            <CurrentPlanHeader>Billing Contacts</CurrentPlanHeader>
            <UserDetails name={'Imran Quraishy'} email={'Imran.q@workativ.com'} />
            <BillingPeriod />
        </BillingContact>
    )
}
//Table
export const Td: StyledComponent<'td', any, any, never> = styled.td`
    width: auto;
    float: left;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    display: flex;
    justify-content: ${(props: any) => (props.ContentRight ? 'flex-start' : 'flex-end')};
    align-items: center;
    color: #000;
    margin-top: 5px;
`
export const Th: StyledComponent<'th', any, any, never> = styled.th`
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    float: left;
    font-size: 16px;
    display: flex;
    justify-content: ${(props: any) => (props.primary ? 'flex-start' : 'flex-end')};
    font-family: 'Gordita-Medium';
    color: #000;
    margin-bottom: 15px;
    font-weight: normal;
`
export const Tr = styled.tr`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    :last-child ${Th} {
        margin-bottom: 0px;
    }
`
export const Tbody: StyledComponent<'tbody', any, any, never> = styled.tbody`
    width: 100%;
    float: left;
    display: ${(props: any) => (props.primary ? 'block' : 'flex')};
    flex-direction: column;
    justify-content: space-between;
    height: ${(props: any) => (props.primary ? 'calc(100vh - 35vh)' : '100%')};
    overflow-y: ${(props: any) => (props.primary ? 'scroll' : 'inherit')};

    @media only screen and (max-width: 1500px) {
        height: ${(props: any) => (props.primary ? 'calc(100vh - 45vh)' : '100%')};
    }
    @media only screen and (max-width: 1400px) {
        height: ${(props: any) => (props.primary ? 'calc(100vh - 50vh)' : '100%')};
    }
    @media only screen and (max-width: 1300px) {
        max-height: ${(props: any) => (props.primary ? 'calc(100vh - 48vh)' : '100%')};
    }
    position: relative;
    ${Tr} {
        padding: 0px;
    }
`
export const Thead: StyledComponent<'thead', any, any, never> = styled.thead`
    border-bottom: ${(props: any) => (props.primary ? '1px solid #ccc' : 'none')};
    width: 100%;
    float: left;
    display: flex;
    margin: ${(props: any) => (props.primary ? '10px 0px' : '0px')};
    padding: ${(props: any) => (props.primary ? '10px 0px' : '0px')};
    flex-direction: column;

    ${Tr} {
        padding: 0px;
    }
`
export const Table = styled.table`
    width: 100%;
    float: left;
    position: relative;
`
const TableWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    border-bottom: 1px solid #d3d3d3;
    @media (max-width: 1600px) {
        padding: 15px 20px;
        ${Tbody} {
            margin-top: 5px;
        }
    }
`
const TableWrapperAddon = styled.div`
    width: 100%;
    float: left;
    padding: 20px 0px;
    border-bottom: ${props => (props.borderNone ? 'none' : '1px solid #d3d3d3')};
    ${Tr}:last-child {
        ${Th} {
            margin-bottom: 0px;
        }
    }
    ${Tr} {
        padding: 0px 20px;
    }

    @media (max-width: 1600px) {
        padding: 15px 0px;
    }
`
export const BillingEstimationContainer = styled.div`
    width: 100%;
    float: left;
    ${MonthlySubscriptionV2} {
        :hover {
            text-decoration: none;
        }
    }
    @media (max-width: 1700px) {
        ${MonthlySubscriptionV2} {
            font-size: 14px;
        }
    }
    @media (max-width: 1400px) {
        ${MonthlySubscriptionV2} {
            font-size: 14px;
        }
    }
`
//Table
export const BillingPeriodContainer = styled.div`
    width: 100%;
    float: left;
    padding: 20px;
    @media (max-width: 1600px) {
        padding: 15px 20px;
    }
`
export const BillingContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    grid-gap: 40px;
    padding: 30px 20px 0px;
    justify-content: flex-start;
    height: calc(100vh - 360px);
    overflow-y: scroll;

    @media (max-width: 1700px) {
        padding: 20px 20px 0px;
        height: calc(100vh - 310px);
        overflow-y: scroll;
        margin-bottom: 20px;
    }
    @media (max-width: 1600px) {
        height: calc(100vh - 280px);
        overflow-y: scroll;
        padding: 20px 20px 0px;
    }
`
export const DateWrapper = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-weight: normal;
    font-size: 14px;
    color: #000;
    padding: 20px;
    border-bottom: ${props => (props.borderNone ? 'none' : ' 1px solid #d3d3d3')};
    ${MonthlySubscriptionV2} {
        margin-top: 0px;
    }
`
export const BillingContact = styled.section`
    width: 100%;
    float: left;
    border-radius: 10px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    cursor: default;
    flex-direction: column;
    align-self: flex-start;
    ${CurrentPlanHeader} {
        margin-bottom: 0px;
        padding: 20px 20px 12px;
        border-bottom: 1px solid #d3d3d3;
    }
    ${BillingPeriodContainer} {
        ${CurrentPlanHeader} {
            border-bottom: none;
            padding: 0px;
        }
    }
`
export const TableContainer = () => {
    return (
        <TableWrapper>
            <Table>
                <Thead>
                    <Tr>
                        <Th size={4} primary>
                            Consumption
                        </Th>
                        <Th size={2}>Quota</Th>
                        <Th size={2}>Used</Th>
                        <Th size={2}>Remaining</Th>
                        <Th size={2}>Add-Ons</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td size={4}>
                            <EllipsisContent>Workflow Executions</EllipsisContent>
                        </Td>
                        <Td size={2}>1500</Td>
                        <Td size={2}>1536</Td>
                        <Td size={2}>0</Td>
                        <Td size={2}>
                            36{' '}
                            <HoverUserIcon>
                                <span className="info_icon">
                                    <InfoIcon />
                                    <span className="side_menu_hvr">
                                        Actual price may vary based on the exchange rate in place between USD and EUR at
                                        the time of payment processing or invoicing. Prices exclude all taxes, levies
                                        and duties.
                                    </span>
                                </span>
                            </HoverUserIcon>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td size={4}>
                            <EllipsisContent>FAQ Answers</EllipsisContent>
                        </Td>
                        <Td size={2}>2000</Td>
                        <Td size={2}>1782</Td>
                        <Td size={2}>218</Td>
                        <Td size={2}>
                            0{' '}
                            <HoverUserIcon>
                                <span className="info_icon">
                                    <InfoIcon />
                                    <span className="side_menu_hvr">
                                        Actual price may vary based on the exchange rate in place between USD and EUR at
                                        the time of payment processing or invoicing. Prices exclude all taxes, levies
                                        and duties.
                                    </span>
                                </span>
                            </HoverUserIcon>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td size={4}>
                            <EllipsisContent> Messages</EllipsisContent>
                        </Td>
                        <Td size={2}>7000</Td>
                        <Td size={2}>6676</Td>
                        <Td size={2}>324</Td>
                        <Td size={2}>
                            0{' '}
                            <HoverUserIcon>
                                <span className="info_icon">
                                    <InfoIcon />
                                    <span className="side_menu_hvr">
                                        Actual price may vary based on the exchange rate in place between USD and EUR at
                                        the time of payment processing or invoicing. Prices exclude all taxes, levies
                                        and duties.
                                    </span>
                                </span>
                            </HoverUserIcon>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableWrapper>
    )
}

export const TableContainerV2 = (props: { resources: (ResourceDisplay & ResourceConsumptionForDisplay)[] }) => {
    return (
        <TableWrapper>
            <Table>
                <Thead>
                    <Tr>
                        <Th size={4} primary>
                            Consumption
                        </Th>
                        <Th size={2}>Quota</Th>
                        <Th size={2}>Used</Th>
                        <Th size={2}>Remaining</Th>
                        <Th size={2}>Add-Ons</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.resources.map(resource =>
                        resource ? (
                            <Tr>
                                <Td size={4}>
                                    <EllipsisContent>{resource.title}</EllipsisContent>
                                </Td>
                                <Td size={2}>{resource.resourceLimit}</Td>
                                <Td size={2}>{resource.consumed}</Td>
                                <Td size={2}>{Math.max(resource.resourceLimit - parseInt(resource.consumed), 0)}</Td>
                                <Td size={2}>
                                    {Math.max(parseInt(resource.consumed) - resource.resourceLimit, 0)}
                                    <HoverUserIcon>
                                        <span className="info_icon">
                                            <InfoIcon />
                                            <span className="side_menu_hvr">Execeeded consumptions</span>
                                        </span>
                                    </HoverUserIcon>
                                </Td>
                            </Tr>
                        ) : null
                    )}
                </Tbody>
            </Table>
        </TableWrapper>
    )
}
export const AddonTable = () => {
    return (
        <TableWrapper>
            <Table>
                <Thead>
                    <Tr size={12}>
                        <Th size={10}>Add-Ons</Th>
                        <Th size={2}>$0.36</Th>
                    </Tr>
                    <Tr size={12}>
                        <Th size={10}>Standard Plan</Th>
                        <Th size={2}> $599.00</Th>
                    </Tr>
                </Thead>
            </Table>
        </TableWrapper>
    )
}

export const BillItemsV2 = (props: {
    addOnAmount: string
    planAmount: { tag: 'NONE' } | { tag: 'HAS_PLAN_AMOUNT'; amount: string }
    planName: string
    billingDate: string
    borderNone: boolean
}) => {
    console.log('data', props)
    return (
        <>
            <TableWrapper>
                <Table>
                    <Thead>
                        <Tr size={12}>
                            <Th size={10}>Add-Ons</Th>
                            <Th size={2}>${parseFloat(props.addOnAmount).toFixed(2)}</Th>
                        </Tr>
                        {props.planAmount.tag == 'HAS_PLAN_AMOUNT' ? (
                            <Tr size={12}>
                                <Th size={10}>{props.planName} Plan</Th>
                                <Th size={2}> ${parseFloat(props.planAmount.amount).toFixed(2)}</Th>
                            </Tr>
                        ) : null}
                    </Thead>
                </Table>
            </TableWrapper>
            <TableWrapperAddon borderNone={props.borderNone}>
                <Table>
                    <Thead>
                        <Tr size={12}>
                            <Th size={10} primary>
                                Next Billing {props.billingDate}
                            </Th>
                            <Th size={2}>
                                {props.planAmount.tag == 'HAS_PLAN_AMOUNT'
                                    ? `$${parseFloat(props.addOnAmount) + parseFloat(props.planAmount.amount)}`
                                    : `$${parseFloat(props.addOnAmount).toFixed(2)}`}
                            </Th>
                        </Tr>
                    </Thead>
                </Table>
            </TableWrapperAddon>
        </>
    )
}

export const NextBilling = () => {
    return (
        <TableWrapperAddon>
            <Table>
                <Thead>
                    <Tr size={12}>
                        <Th size={10} primary>
                            Next Billing 14 Mar 2021
                        </Th>
                        <Th size={2}>$599.36</Th>
                    </Tr>
                </Thead>
            </Table>
        </TableWrapperAddon>
    )
}
export const BillingEstimation = (props: any) => {
    return (
        <BillingContact>
            <BillingEstimationContainer>
                <CurrentPlanHeader>Billing Estimate</CurrentPlanHeader>
                <DateWrapper>14 Feb 2021 - 13 Mar 2021</DateWrapper>
                {props.upgradePlan && (
                    <MonthlySubscriptionV2 fontBold padding>
                        Standard
                        <UpgradePlanContent>
                            (Standard (To upgrade your plan, go to{' '}
                            <UpgradePlanContent fontSpan={true}>Pricing Plans</UpgradePlanContent>)
                        </UpgradePlanContent>
                    </MonthlySubscriptionV2>
                )}
            </BillingEstimationContainer>
            <TableContainer />
            <AddonTable />
            <NextBilling />
            <DateWrapper borderNone>
                <MonthlySubscriptionV2 fontBold>View Full Estimate</MonthlySubscriptionV2>
            </DateWrapper>
        </BillingContact>
    )
}

export const BillingEstimationV2 = (props: any) => {
    return (
        <BillingContact>
            <BillingEstimationContainer>
                <CurrentPlanHeader>Billing Estimate</CurrentPlanHeader>
                <DateWrapper>14 Feb 2021 - 13 Mar 2021</DateWrapper>
                {props.upgradePlan && (
                    <MonthlySubscriptionV2 fontBold padding>
                        Standard
                        <UpgradePlanContent>
                            (Standard (To upgrade your plan, go to{' '}
                            <UpgradePlanContent fontSpan={true}>Pricing Plans</UpgradePlanContent>)
                        </UpgradePlanContent>
                    </MonthlySubscriptionV2>
                )}
            </BillingEstimationContainer>
            <TableContainer />
            <AddonTable />
            <NextBilling />
            <DateWrapper borderNone>
                <MonthlySubscriptionV2 fontBold>View Full Estimate</MonthlySubscriptionV2>
            </DateWrapper>
        </BillingContact>
    )
}

const BodyWrapper = styled.section`
    width: 100%;
    float: left;
    padding: 20px;
    height: calc(100vh - 100px);
    overflow-y: inherit;
    ${BillingWrapper} {
        padding-bottom: 40px;
    }

    @media (max-width: 1700px) {
        ${BillingWrapper} {
            padding-bottom: 20px;
        }
    }
`
