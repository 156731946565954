export type Modes = EyeT | PassWordT | InitialT

export enum Mode {
    eye,
    password,
    initial
}

export type InitialT = {
    type: Mode.initial
    eye: EyeT
    password: PassWordT
}

export type EyeT = {
    type: Mode.eye
    currentEyeKey: string
    currentPasswordEyeOpen: boolean
    newPasswordEyeOpen: boolean
    confirmPasswordEyeOpen: boolean
}

export type PassWordT = {
    type: Mode.password
    currentPasswordKey: string
    currentPasswordValue: string
    currentErrorPasswordKey: string
    currentErrorPasswordValue: string
    current: string
    new: string
    confirm: string
}

export type ErrorT = {
    type: Mode.password
    currentPasswordKey: string
    currentPasswordValue: string
    currentErrorPasswordKey: string
    currentErrorPasswordValue: string
    current: string
    new: string
    confirm: string
}

export const EyeType: EyeT = {
    type: Mode.eye,
    currentEyeKey: 'eyeOpen1',
    currentPasswordEyeOpen: false,
    newPasswordEyeOpen: false,
    confirmPasswordEyeOpen: false
}

export const PasswordType: PassWordT = {
    type: Mode.password,
    currentPasswordKey: 'current',
    currentPasswordValue: '',
    currentErrorPasswordKey: '',
    currentErrorPasswordValue: '',
    current: '',
    new: '',
    confirm: ''
}

export const initialData: InitialT = {
    type: Mode.initial,
    eye: EyeType,
    password: PasswordType
}

export type profileDataT = {
    id: number
    first_name: string
    last_name: string
    email: string
    org: string | null
    role: string | null
    image?: string
    verified: boolean
}

export const defaultProfileData: profileDataT = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    org: null,
    role: null,
    verified: true
}
