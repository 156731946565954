import Axios from 'axios';

import { Product } from '../../../../feature/subscription/src/SubsriptionResponse';
import { executeWithAuthToken } from './auth-token-api-utils';

const excludedRoutes = ['/authentication/update_password'] as const;

Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (
      error.response &&
      error.response.status === 401 &&
      !excludedRoutes.includes(error.response.config.url)
    ) {
      window.location.replace(window.location.origin + '/authentication/u');
    }
    return Promise.reject(error);
  }
);

const jsonPostReq = () => (url: string) => (data: any) =>
  executeWithAuthToken(() => Axios.post(url, data));

export const patchJson = (url: string, data: any) =>
  executeWithAuthToken(() => Axios.patch(url, data));

const jsonPutReq = () => (url: string) => (header?: any) => (data: any) =>
  header
    ? executeWithAuthToken(() => Axios.put(url, { headers: header }, data))
    : executeWithAuthToken(() => Axios.put(url, data));

const jsonDeleteReq = () => (url: string) => (data?: any) =>
  executeWithAuthToken(() =>
    data ? Axios.delete(url, { data }) : Axios.delete(url)
  );

export const postJson = jsonPostReq();

export const deleteJson = jsonDeleteReq();

export const putJson = jsonPutReq();

export const getJson: <ResData = any>(url: string) => Promise<ResData> = (
  url: string
) =>
  executeWithAuthToken(() =>
    Axios.get(url).then((resp) => resp.data as ResData)
  );

export const wrapperGetJson = (url: string) =>
  executeWithAuthToken(() => Axios.get(url));

export const wrapperPostJson = (url: string, data: any) =>
  executeWithAuthToken(() => Axios.post(url, data));

export const logsGetJson = (url: string) => (data: any) =>
  executeWithAuthToken(() =>
    Axios.get(url, { params: data }).then((resp) => resp.data)
  );

export const getJsonwithCredentials = (url: string) =>
  executeWithAuthToken(() =>
    Axios.get(url, { withCredentials: true }).then((resp) => resp.data)
  );

export const loginApiURL = (api: string) => {
  return process.env['REACT_APP_LOGIN_API'] + api;
};

export const knowledgeApiUrl = (workspacename: string, api: string) =>
  `${process.env['REACT_APP_KNOWLEDGE_AI_EXTERNAL_ROUTE']}/${workspacename}/${api}`;

export const subscriptionApiURL = (api: string) =>
  `${process.env['REACT_APP_SUBSCRIPTION_API']}${api}`;

export const analyticsGetJson = (url: string) => (data: any) => {
  return executeWithAuthToken(() =>
    Axios.get(url, { params: data }).then((resp) => resp.data)
  );
};

export const stripeGetApi = (url: string) =>
  getJson(`${process.env['REACT_APP_SUBSCRIPTION_API']}/${url}`);

export const stripePost = (url: string) =>
  postJson(`${process.env['REACT_APP_SUBSCRIPTION_API']}/${url}`);

type header = {
  authorization: string;
};

export const loginGetJson = (url: string) => (header: header) =>
  executeWithAuthToken(() =>
    Axios.get(url, { headers: header }).then((resp) => resp.data)
  );

export enum FlowApi {
  AddFlow = '/automation/create',
  DeleteFlow = '/automation/delete',
  AllFlows = '/flow-editor/flows',
  UpdateFlow = '/flow-editor/flow/update',
  DeleteFlows = '/flow-editor/flows/delete',
  UpdateGroup = '/group/update',
  DeleteGroup = '/group/delete',
  DeleteGroups = '/groups/delete',
  UnGroup = '/ungroup',
  UnGroupFlows = '/flow-editor/ungroup',
  AddGroup = '/group',
  SaveAs = '/flow-editor/flow/saveAs',
  MoveToGroup = '/group/moveToGroup',
  MapBetweenDialogAndFlow = '/map/dialogAndFlow',
}

export enum MarketPlaceApi {
  AllMarketPlaceAutomation = '/marketplace/app/list',
  FilteredMarketplaceAutomation = '/marketplace/automation/list',
  FilteredMarketplaceCategory = '/marketplace/app/category',
  AllCategories = '/category/app',
  GetMyUploads = '/marketplace/myuploads',
  EditUpload = '/marketplace/update',
  DeleteUpload = '/marketplace/delete',
  AddToWorkSpace = '/marketplace/add',
  UploadToMarketPlace = '/marketplace/upload',
  AutomationDetail = '/marketplace/detail',
  MarketplaceUploadAccess = '/marketplace/upload/access',
}

export enum AutomationApi {
  GetAll = '/automation',
  GetDetail = '/automation/detail',
  Create = '/automation/create',
  Update = '/automation/update',
  SaveAs = '/automation/copy',
  Delete = '/automation/delete',
  Deploy = '/automation/deploy',
}

export enum AutomationOnPremApi {
  GetAll = '/on-prem/agent/list',
  Create = '/on-prem/agent/create',
  Update = '/on-prem/agent/',
  DownloadApp = '/on-prem/agent/download/installer',
}

export enum DialogIntegration {
  LinkedDialogs = '/dialog_nodes/automation?automationIds=[',
}

export enum AuthAppMiscellaneous {
  SubmitSupportTicket = '/misc/createTicket',
}

export const settingsApiURL = (workspace: string, api: string) => {
  return process.env['REACT_APP_SETTINGS_API'] + '/' + workspace + api;
};

export const watsonApiURLWithoutWorkspace = (api: string) => {
  return process.env['REACT_APP_WATSON_API'] + api;
};

export const watsonLogsApiURL = (api: string) => {
  return process.env['REACT_APP_WATSON_API'] + api;
};

export const watsonApiURL = (workspace: string, api: string) => {
  return process.env['REACT_APP_WATSON_API'] + '/' + workspace + api;
};

export const sessionApiURL = (api: string) => {
  return process.env['REACT_APP_SESSION_API'] + api;
};

export const watsonConversationApiURLWithBotId = (
  bot_id: string,
  workspace: string,
  api: string
) => {
  return (
    process.env['REACT_APP_WATSON_API'] + '/' + bot_id + '/' + workspace + api
  );
};

export const submitSupportTicket = postJson(
  loginApiURL(AuthAppMiscellaneous.SubmitSupportTicket)
);

export const flowApiURL = (workSpace: string, api: string) => {
  return process.env['NODE_ENV'] == 'development'
    ? process.env['REACT_APP_FLOW_API'] + api
    : process.env['REACT_APP_FLOW_API'] + '/' + workSpace + api;
};

export const flowApiURLWithNoWorkSpace = (api: string) => {
  return process.env['REACT_APP_FLOW_API'] + api;
};

export const automationAPIURL = (api: string) => {
  return process.env['REACT_APP_AUTOMATION_API'] + api;
};

export const widgetIntegrationURL = (workSpace: string, api: string) => {
  return process.env['REACT_APP_WIDGET_API'] + '/' + workSpace + api;
};

export const automationAPIURLWithWorkspace = (
  workSpace: string,
  api: string
) => {
  // console.log('automationAPIURLWithWorkspace', workSpace, api)
  return process.env['REACT_APP_AUTOMATION_API'] + '/' + workSpace + api;
};

export const webHookAPIURLWithWorkspace = (workSpace: string, api: string) => {
  // console.log('automationAPIURLWithWorkspace', workSpace, api)
  return process.env['REACT_APP_WEBHOOK_API'] + '/' + workSpace + api;
};

export const automationApprovalAPIURLWithWorkspace = (
  workSpace: string,
  api: string
) => {
  return process.env['REACT_APP_APPROVAL'] + '/' + workSpace + api;
};

export const automationApprovalAPIURLWithOutWorkspace = (api: string) => {
  return process.env['REACT_APP_APPROVAL'] + api;
};

export const slackIntegrationUrl = (api: string) => {
  // const configUrl = process.env.REACT_APP_SLACK_INTEGRATION_URL
  //     ? process.env.REACT_APP_SLACK_INTEGRATION_URL.toString().replace('&{ws}', workSpace)
  //     : process.env.REACT_APP_SLACK_INTEGRATION_URL

  // console.log(process.env.REACT_APP_NEW_SLACK_INTEGRATION_URL + api)
  return process.env['REACT_APP_NEW_SLACK_INTEGRATION_URL'] + api;
};
export const slackRedirectionUrl = (api: string) => {
  return process.env['REACT_APP_SLACK_EXTERNAL_ROUTE'] + api;
};

export const agenHandoffUrl = (
  workSpace: string,
  api: string,
  botId?: string
) => {
  const configUrl = process.env['REACT_APP_AGENT_HANDOFF'];
  return botId
    ? configUrl + api + workSpace + '/' + botId
    : configUrl + '/' + workSpace + api;
};

export const agenHandoffBackendUrl = (
  workSpace: string,
  api: string,
  botId?: string
) => {
  const configUrl = process.env['REACT_APP_AGENT_EXTERNAL_ROUTE'];
  return botId
    ? configUrl + api + workSpace + '/' + botId
    : configUrl + '/' + workSpace + api;
};

export const userJourneyWithWorkspace = (workSpace: string, api: string) => {
  return process.env['REACT_APP_USER_JOURNEY'] + '/' + workSpace + api;
};

export const slackIntegrationClientId = () => {
  return process.env['REACT_APP_SLACK_INTEGRATION_CLIENT_ID'];
};

export const teamsIntegrationUrl = (
  workSpace: string,
  api: string,
  botId: string
) => {
  const configUrl = process.env['REACT_APP_TEAMS_INTEGRATION_URL'] + '/api';
  return configUrl + api + workSpace + '/' + botId;
};

export const teamsIntegrationBackendUrl = (
  workSpace: string,
  api: string,
  botId: string
) => {
  const configUrl = process.env['REACT_APP_TEAMS_INTEGRATION_URL'] + '/api';
  return configUrl + api + workSpace + '/' + botId;
};

export const teamsIntegrationGetListUrl = (workSpace: string, api: string) => {
  const configUrl = process.env['REACT_APP_TEAMS_INTEGRATION_URL'] + '/api';
  return configUrl + api + workSpace;
};
export const agentHandoffIntegraionList = (
  workSpace: string,
  botId: string,
  api: string
) => {
  const configUrl = process.env['REACT_APP_AGENT_HANDOFF'];
  return configUrl + '/' + workSpace + '/' + botId + api;
};

export const agentHandoffIntegraion = (workSpace: string, botId: string) => {
  const configUrl = process.env['REACT_APP_AGENT_HANDOFF'];

  return configUrl + '/agent/messages/' + workSpace + '/' + botId;
};

export const agentHandoffBackendIntegraion = (
  workSpace: string,
  botId: string
) => {
  const configUrl = process.env['REACT_APP_AGENT_EXTERNAL_ROUTE'];

  return configUrl + '/agent/messages/' + workSpace + '/' + botId;
};

export const teamsBackendIntegrationGenerateUrl = (
  workSpace: string,
  botId: string
) => {
  const configUrl = process.env['REACT_APP_TEAMS_EXTERNAL_ROUTE'] + '/api';

  return configUrl + '/teams/messages/' + workSpace + '/' + botId;
};
export const teamsIntegrationGenerateUrl = (
  workSpace: string,
  botId: string
) => {
  const configUrl = process.env['REACT_APP_TEAMS_INTEGRATION_URL'] + '/api';

  return configUrl + '/teams/messages/' + workSpace + '/' + botId;
};

export const updatePasswordUrl = (api: string) => {
  return process.env['REACT_APP_LOGIN_API'] + api;
};

export const helpVideoUrl = (workSpace: string, api: string) => {
  const configUrl = process.env['REACT_APP_HELP_VIDEOS_URL'];
  return configUrl + '/videostore/' + workSpace + api;
};

export const EventLogApi = (workspace: string) => {
  return userJourneyWithWorkspace(workspace, '/journey/amplitude/event/log');
};

export const logEvent = (
  workspace: string,
  data: {
    event_meta: { name?: string; description?: string };
    event: string;
    location?: { latitude: number; longitude: number };
  }
) => {
  return postJson(EventLogApi(workspace))(data);
};

export type ProductId = 'ASSISTANT' | 'AUTOMATE';

export const getProductId = (): ProductId => {
  const productId = process.env['REACT_APP_PRODUCT_ID'];
  // console.log('DOING :: productId', productId);
  return productId !== 'AUTOMATE' ? 'ASSISTANT' : 'AUTOMATE';
};

export const isAutomate = () => {
  const productId = getProductId();
  return productId === 'AUTOMATE' ? true : false;
};

export const isNotAutomate = () => (isAutomate() ? false : true);
