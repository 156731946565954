// import { differenceInDays } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { PriceStandardIcon } from '@/common/Icons/Icons';

import { PlanV2 } from './Plans-v2';
import { DefaultPlan, SubscribedPlan } from './kind';

const PlanWrapper = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 5px;
  background: #fff;
  display: flex;
  margin-bottom: 30px;
`;
const PlanLeft = styled.div`
  width: 100%;
  float: left;
  display: flex;
`;

const PlanRight = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PlanRightBorder = styled.div`
  width: 62%;
  float: left;
  border: 1px solid red;
  border-radius: 6px;
  padding: 10px;
`;
const Error = styled.p`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: red;
  margin-bottom: 0px;
  text-align: center;
`;
const ErrorPlan = styled.h4`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 15px;
  color: red;
  margin-bottom: 5px;
  text-align: center;
`;

const ImgWrapper = styled.span`
  width: 120px;
  height: 120px;
  float: left;
  border: 1px solid #2468f6;
  border-radius: 6px;
  fill: #2468f6;
  color: #2468f6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
`;

const PlanDetials = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 15px;
  color: #494949;
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const CurrentPlan = styled.span`
  width: auto;
  float: left;
  color: #0a89e8;
  font-family: 'Gordita-Medium';
  font-size: 15px;
  white-space: pre;
`;
const Trial = styled.span`
  width: auto;
  float: left;
  color: #999;
  font-family: 'Gordita-Regular';
  font-size: 11px;
  margin-left: 3px;
`;

const TrialRemaining = styled.span`
  width: auto;
  float: left;
  color: #fa904b;
  font-family: 'Gordita-Regular';
  font-size: 15px;
  margin-left: 3px;
`;

const AmountBalance = styled.div`
  width: 65%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 15px;
  color: #494949;
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-top: 45px;
`;
const BalanceContent = styled.div`
  width: 65%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: #494949;
  padding-left: 20px;
  display: flex;
  align-items: center;
`;
const StatusContent = styled.span`
    width: auto;
    float: left
    font-family: ${(props: any) =>
      props.primary ? 'Gordita-Medium' : 'Gordita-Regular'};
    font-size: 14px;
    color: ${(props: any) => (props.primary ? '#2e5ce6' : '#494949')};
    margin-left: ${(props: any) => (props.primary ? '0px' : '3px')};
`;
const StatusSection = styled.div`
  width: max-content;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StatusWrapper = styled.div`
  width: auto;
  float: left;
  background: #f4f4f4;
  height: 40px;
  color: #657993;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  ${StatusContent} {
    color: #657993;
  }
`;

const getPlanImage = (planName: string) => {
  return PriceStandardIcon;
};

const getCreditAmount = (plan: SubscriptionDetailsToDisplay) => {
  return plan.credit;
};

export const getSubscriptionDisplayDetails = (
  subscribedPlan: SubscribedPlan,
  defaultPlan: DefaultPlan
): SubscriptionDetailsToDisplay => {
  if (subscribedPlan.subscription.status === 'trialing') {
    return {
      isInTrial: true,
      hasHadTrial: true,
      hasTrialEnded: subscribedPlan.subscription.hasTrialEnded,
      credit: Number('0.0'),
      planDisplayName: defaultPlan.mappedPlanDisplayName,
      planReferenceName: defaultPlan.mappedPlanName,
      status: subscribedPlan.subscription.status,
      trialEndDate: subscribedPlan.subscription.trialEndDate,
      trialStartDate: subscribedPlan.subscription.trialStartDate,
      currentPeriondEndDate: 12,
      currentPeriodStartDate: 12,
      price: '',
      planType : subscribedPlan.planDetails.billed
    };
  } else {
    // const currentCycleStartDate = stripeUTCDate(PageState.subscription.subscription.current_period_start)
    //     const currentCycleEndDate = stripeUTCDate(PageState.subscription.subscription.current_period_end)

    return {
      isInTrial: false,
      hasTrialEnded: true,
      hasHadTrial: subscribedPlan.subscription.hasHadTrial,
      credit: Number(subscribedPlan.subscription.stripeSubscription.balance),
      planDisplayName: subscribedPlan.displayName,
      status: subscribedPlan.subscription.status,
      planReferenceName: subscribedPlan.referenceName,
      trialEndDate: new Date(1970, 0, 0),
      trialStartDate: new Date(1970, 0, 0),
      currentPeriondEndDate: subscribedPlan.subscription.current_period_end,
      currentPeriodStartDate: subscribedPlan.subscription.current_period_start,
      price: subscribedPlan.planDetails.price
        ? subscribedPlan.planDetails.price
        : '0',
    planType : subscribedPlan.planDetails.billed
    };
  }
};

export type SubscriptionDetailsToDisplay = {
  status: string;
  isInTrial: boolean;
  hasTrialEnded: boolean;
  hasHadTrial: boolean;
  trialEndDate: Date;
  trialStartDate: Date;
  credit: number;
  planDisplayName: string;
  planReferenceName: string;
  currentPeriondEndDate: number;
  currentPeriodStartDate: number;
  price: string;
  planType : string
};

export const SubscriptionDetailsB = ({
  subscriptionDetails,
}: {
  subscriptionDetails: SubscriptionDetailsToDisplay;
}) => {
  const todayInUTC = new Date(new Date().toUTCString());
  // const trialDaysRemaining = differenceInDays(
  //   subscriptionDetails.trialEndDate,
  //   todayInUTC
  // );

  return <PlanV2 />;
  {
    /*  return (  */
  }
  {
    /*  <PlanWrapper>  */
  }
  {
    /*  <PlanLeft>  */
  }
  {
    /*  <ImgWrapper>{planIconMap[subscriptionDetails.planReferenceName]}</ImgWrapper>  */
  }
  {
    /*  <StatusSection>  */
  }
  {
    /*  <PlanDetials>  */
  }
  {
    /*  Current Plan - <CurrentPlan>{` ${subscriptionDetails.planDisplayName}`}</CurrentPlan>  */
  }
  {
    /*  {subscriptionDetails.isInTrial ? (  */
  }
  {
    /*  <TrialRemaining>  */
  }
  {
    /*  {trialDaysRemaining >= 0  */
  }
  {
    /*  ? `(${trialDaysRemaining} ${plurarize(  */
  }
  {
    /*  trialDaysRemaining,  */
  }
  {
    /*  'day'  */
  }
  {
    /*  )} remaining in your trial period)`  */
  }
  {
    /*  : `(Your trial period has expired)`}  */
  }
  {
    /*  </TrialRemaining>  */
  }
  {
    /*  ) : (  */
  }
  {
    /*  <>  */
  }
  {
    /*  <StatusContent> (Account Status - </StatusContent>  */
  }
  {
    /*  <Trial>  */
  }
  {
    /*  <StatusContent primary>  */
  }
  {
    /*  {subscriptionStatusMap[subscriptionDetails.status]}  */
  }
  {
    /*  </StatusContent>  */
  }
  {
    /*  </Trial>  */
  }
  {
    /*  )  */
  }
  {
    /*  </>  */
  }
  {
    /*  )}  */
  }
  {
    /*  </PlanDetials>  */
  }
  {
    /*  {subscriptionDetails.isInTrial ? (  */
  }
  {
    /*  <StatusWrapper>  */
  }
  {
    /*  <StatusContent>  */
  }
  {
    /*  {trialDaysRemaining >= 0  */
  }
  {
    /*  ? `Your account services will be suspended after the trial period.Choose a plan below to  */
  }
  {
    /*  continue using Workativ Assistant.`  */
  }
  {
    /*  : `Your account has been suspended.Choose a plan below to  */
  }
  {
    /*  continue using Workativ Assistant.`}  */
  }
  {
    /*  </StatusContent>  */
  }
  {
    /*  </StatusWrapper>  */
  }
  {
    /*  ) : null}  */
  }
  {
    /*  </StatusSection>  */
  }
  {
    /*  </PlanLeft>  */
  }
  {
    /*  <PlanRight></PlanRight>  */
  }
  {
    /*  </PlanWrapper>  */
  }
  {
    /*  )  */
  }
};
