import * as R from 'ramda'

import { NodeT, TreeT } from '@/Flows/canvas/types'

import { formatAMPM, formatDateInCard } from '@/common/utils/utils'

// Assistant

export const traverseTree = (expression: any, acc: any) => {
    function traverse(acc: any, curr: any): any {
        if (curr[0] == 'type') {
            return acc
        } else if (curr[0] == 'and' || curr[0] == 'or') {
            return R.concat(
                [...acc, curr[1].lhs],
                curr[1].rhs.type != 'LogicalExpression' ? [curr[1].rhs] : traverseTree(curr[1].rhs, acc)
            )
        }
        return acc
    }

    return Object.entries(expression).reduce(traverse, acc ? acc : [])
}
export function findActionNode(id: string, currentNode: TreeT<NodeT>) {
    var i: any, currentChild: any, result: any

    if (id == currentNode.value.id) {
        return currentNode as any
    } else {
        for (i = 0; i < currentNode.children.length; i += 1) {
            currentChild = currentNode.children[i]
            result = findActionNode(id, currentChild)
            if (result !== false) {
                return result as any
            }
        }
        return false as any
    }
}
export const FormatwithUTCDate = (date: string) => {
    let utcDate = `${date}Z`
    return formatDateInCard(utcDate)
}
export const FormatwithUTCTime = (date: string) => {
    let utcDate = `${date}Z`
    return formatAMPM(utcDate)
}
