/*eslint-disable*/
import * as React from 'react'

export class ThankYouPage extends React.Component<any> {
    async componentDidMount() {
        const code = this.props.location.search && this.props.location.search.split('code=')[1].split('&state=')[0]
        // if (code)
        // await postJson(integrationUrl('/onboard'))({ code: code }).then(
        //     res => {
        //         this.setState({ workspaces: res })
        //     },
        // )
    }

    close = () => {
        window.close()
    }

    render() {
        return (
            <React.Fragment>
                <div className="jumbotron text-center thankyou-page">
                    <h1 className="display-3">Hooooooorey :)</h1>
                    <p className="lead">
                        Your <strong>Workativ</strong> chatbot has been successfuly integrated with your{' '}
                        <strong>Slack</strong> workspace.
                    </p>
                    {/* <p className="lead">Your <strong>Workativ</strong> chatbot has been successfuly integrated with your selected chat channel.</p> */}
                    <hr />
                    <p className="lead">
                        <a className="btn btn-primary btn-sm" role="button" onClick={this.close}>
                            Done
                        </a>
                    </p>
                </div>
            </React.Fragment>
        )
    }
}
