import styled from 'styled-components/macro'

export const FocusContent = styled.div`
    width: auto;
    float: left;
    color: #999999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    left: 12px;
    top: 19px;
    line-height: 10px;
    transition: 0.26s ease-in;

    .target.customTip {
        pointer-events: initial;
        cursor: pointer;
        padding-left: 3px;
        position: relative;
        top: 0px;
        pointer-events: all;
    }
    .target.customTip svg {
        width: 9px;
        height: 9px;
        position: relative;
        top: 0px;
        right: 0px;
        color: #999;
        fill: #999;
        pointer-events: all;
    }
`
export const DraftInputWrapper = styled.div`
    width: 100%;
    float: left;

    .dialog_input_edit_true.dialog_context_input_edit_true {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        min-height: 48px;
        cursor: text;
        position: relative;
        .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
            padding: 0px 7px 3px 7px;
        }
    }

    
    .DraftEditor-root {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000000;
        padding-top: 20px;
    }

    .dialog_input_edit_true.dialog_context_input_edit_true.editor_btn_disabled{
        .DraftEditor-root{
            padding-top: 16px;
        }
        .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
            padding: 0px 7px 3px 0px;
        }
    }

    .DraftEditor-editorContainer {
        min-height: 18px;
    }
    
    .dialog_context_input_edit_true ${FocusContent}{
        top: 14px;
    }
    .menu_option  ${FocusContent}{
        top: 18px;
    }
    .input_selected_dialog ${FocusContent} {
        top: 3px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
    .menu_option .input_selected_dialog ${FocusContent} {
        top: 8px;
    }
    .focus_input ${FocusContent} {
        top: 8px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }

    .focus_input.menu_popup ${FocusContent}{
        top: 2.5px;
    }    

    .select_work_form{
        .dialog_context_input_edit_true ${FocusContent}{
            top: 18px;
        }

        .focus_input ${FocusContent} {
            top: 8px;
        }
    }

    .faq_popup{
        .dialog_context_input_edit_true.unfocus_input_faq ${FocusContent}{
            top: 19px;
        }
        .dialog_context_input_edit_true.unfocus_input_faq.focus_input ${FocusContent}{
            top: 8px;
        }
    }
        
        .dialog_context_input_edit_true.function_focus ${FocusContent}{
            top: 8px;
        }

    .faq_adaptive_card {
        .faq_adaptive_card_radios {
            display: inline-flex;
            p {
                padding: 10px;
                padding-left: 0px;
                margin-bottom: 0px;
            }
        }
        .adaptive_card {
            padding: 0px;
            margin-bottom: 0;
        }
    }
    :first-child{
        margin-top: 0px;
    }
`
