import { EmojiIcon } from '@/common/Icons/Icons';
import React from 'react';

/* list of emoji's sourced from http://getemoji.com */
/* list of emoji's sourced from http://getemoji.com */
const PEOPLE_EMOJIS = [
  '😀',
  '😃',
  '😄',
  '😁',
  '😆',
  '😅',
  '😂',
  '😇',
  '🤣',
  '😊',
  '🙂',
  '🙃',
  '😉',
  '😌',
  '😍',
  '😘',
  '😗',
  '😙',
  '😚',
  '😋',
  '😜',
  '😝',
  '😛',
  '🤑',
  '🤗',
  '🤓',
  '😎',
  '🤡',
  '🤠',
  '😏',
  '😒',
  '😞',
  '😔',
  '😟',
  '😕',
  '🙁',
  '😣',
  '😖',
  '😫',
  '😩',
  '😤',
  '😠',
  '😡',
  '😶',
  '😐',
  '😑',
  '😯',
  '😦',
  '😧',
  '😮',
  '😲',
  '😵',
  '😳',
  '😱',
  '😨',
  '😰',
  '😢',
  '😥',
  '🤤',
  '😭',
  '😓',
  '😪',
  '😴',
  '🙄',
  '🤔',
  '🤥',
  '😬',
  '🤐',
  '🤢',
  '🤧',
  '😷',
  '🤒',
  '🤕',
  '😈',
  '👿',
  '👹',
  '👺',
  '💩',
  '👻',
  '💀',
  '☠️',
  '👽',
  '👾',
  '🤖',
  '🎃',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '👐',
  '🙌',
  '👏',
  '🙏',
  '🤝',
  '👍',
  '👎',
  '👊',
  '✊',
  '🤛',
  '🤜',
  '🤞',
  '✌️',
  '🤘',
  '👌',
  '👈',
  '👉',
  '👆',
  '👇',
  '☝️',
  '✋',
  '🤚',
  '🖐',
  '🖖',
  '👋',
  '🤙',
  '💪',
  '🖕',
  '✍️',
  '🤳',
  '💅',
  '🖖',
  '💄',
  '💋',
  '👄',
  '👅',
  '👂',
  '👃',
  '👣',
  '👁',
  '👀',
  '👗',
  '👠',
  '👞',
  '👟',
  '👒',
  '🎩',
  '🎓',
  '👑',
  '⛑',
  '🎒',
  '👝',
  '👛',
  '👜',
  '💼',
  '👓',
  '🕶',
  '☂️',
];
const NATURE_EMOJIS = [
  '🐶',
  '🐱',
  '🐭',
  '🐹',
  '🐰',
  '🦊',
  '🐻',
  '🐼',
  '🐨',
  '🐯',
  '🦁',
  '🐮',
  '🐷',
  '🐽',
  '🐸',
  '🐵',
  '🙊',
  '🙉',
  '🙊',
  '🐒',
  '🐔',
  '🐧',
  '🐦',
  '🐤',
  '🐣',
  '🐥',
  '🦆',
  '🦅',
  '🦉',
  '🦇',
  '🐺',
  '🐗',
  '🐴',
  '🦄',
  '🐝',
  '🐛',
  '🦋',
  '🐌',
  '🐚',
  '🐞',
  '🐜',
  '🕷',
  '🕸',
  '🐢',
  '🐍',
  '🦎',
  '🦂',
  '🦀',
  '🦑',
  '🐙',
  '🦐',
  '🐠',
  '🐟',
  '🐡',
  '🐬',
  '🦈',
  '🐳',
  '🐋',
  '🐊',
  '🐆',
  '🐅',
  '🐃',
  '🐂',
  '🐄',
  '🦌',
  '🐪',
  '🐫',
  '🐘',
  '🦏',
  '🦍',
  '🐎',
  '🐖',
  '🐐',
  '🐏',
  '🐑',
  '🐕',
  '🐩',
  '🐈',
  '🐓',
  '🦃',
  '🕊',
  '🐇',
  '🐁',
  '🐀',
  '🐿',
  '🐾',
  '🐉',
  '🐲',
  '🌵',
  '🎄',
  '🌲',
  '🌳',
  '🌴',
  '🌱',
  '🌿',
  '☘️',
  '🍀',
  '🎍',
  '🎋',
  '🍃',
  '🍂',
  '🍁',
  '🍄',
  '🌾',
  '💐',
  '🌷',
  '🌹',
  '🥀',
  '🌻',
  '🌼',
  '🌸',
  '🌺',
  '🌎',
  '🌍',
  '🌏',
  '🌕',
  '🌖',
  '🌔',
  '🌚',
  '🌝',
  '🌞',
  '🌛',
  '🌜',
  '🌙',
  '💫',
  '⭐️',
  '🌟',
  '✨',
  '⚡️',
  '🔥',
  '💥',
  '☄️',
  '☀️',
  '🌤',
  '⛅️',
  '🌥',
  '🌦',
  '🌈',
  '☁️',
  '🌧',
  '⛈',
  '🌩',
  '🌨',
  '☃️',
  '⛄️',
  '❄️',
  '🌬',
  '💨',
  '🌪',
  '🌫',
  '🌊',
  '💧',
  '💦',
  '☔️',
];
const FOOD_EMOJIS = [
  '🍏',
  '🍎',
  '🍐',
  '🍊',
  '🍋',
  '🍌',
  '🍉',
  '🍇',
  '🍓',
  '🍈',
  '🍒',
  '🍑',
  '🍍',
  '🥝',
  '🥑',
  '🍅',
  '🍆',
  '🥒',
  '🥕',
  '🌽',
  '🌶',
  '🥔',
  '🍠',
  '🌰',
  '🥜',
  '🍯',
  '🥐',
  '🍞',
  '🥖',
  '🧀',
  '🥚',
  '🍳',
  '🥓',
  '🥞',
  '🍤',
  '🍗',
  '🍖',
  '🍕',
  '🌭',
  '🍔',
  '🍟',
  '🥙',
  '🌮',
  '🌯',
  '🥗',
  '🥘',
  '🍝',
  '🍜',
  '🍲',
  '🍥',
  '🍣',
  '🍱',
  '🍛',
  '🍚',
  '🍙',
  '🍘',
  '🍢',
  '🍡',
  '🍧',
  '🍨',
  '🍦',
  '🍰',
  '🎂',
  '🍮',
  '🍭',
  '🍬',
  '🍫',
  '🍿',
  '🍩',
  '🍪',
  '🥛',
  '🍼',
  '☕️',
  '🍵',
  '🍶',
  '🍺',
  '🍻',
  '🥂',
  '🍷',
  '🥃',
  '🍸',
  '🍹',
  '🍾',
  '🥄',
  '🍴',
  '🍽',
];
const SPORTS_EMOJIS = [
  '⚽️',
  '🏀',
  '🏈',
  '⚾️',
  '🎾',
  '🏐',
  '🏉',
  '🎱',
  '🏓',
  '🏸',
  '🥅',
  '🏒',
  '🏑',
  '🏏',
  '⛳️',
  '🏹',
  '🎣',
  '🥊',
  '🥋',
  '⛸',
  '🎿',
  '⛷',
  '🏂',
  '🏋',
  '🤺',
  '⛹️',
  '🏌',
  '🏄',
  '🏊',
  '🤽',
  '🚣',
  '🏇',
  '🚴',
  '🚵',
  '🎬',
  '🎤',
  '🎧',
  '🎼',
  '🎹',
  '🥁',
  '🎷',
  '🎺',
  '🎸',
  '🎻',
  '🎲',
  '🎯',
  '🎳',
  '🎮',
  '🏳',
  '🏴',
  '🏁',
  '🚩',
  '🎽',
  '🥇',
  '🥈',
  '🥉',
  '🏆',
];
const TRAVEL_EMOJIS = [
  '🚗',
  '🚕',
  '🚙',
  '🚌',
  '🚎',
  '🏎',
  '🚓',
  '🚑',
  '🚒',
  '🚐',
  '🚚',
  '🚛',
  '🚜',
  '🛴',
  '🚲',
  '🛵',
  '🏍',
  '🚨',
  '🚔',
  '🚍',
  '🚘',
  '🚖',
  '🚡',
  '🚠',
  '🚟',
  '🚃',
  '🚋',
  '🚞',
  '🚝',
  '🚄',
  '🚅',
  '🚈',
  '🚂',
  '🚆',
  '🚇',
  '🚊',
  '🚉',
  '🚁',
  '🛩',
  '✈️',
  '🛫',
  '🛬',
  '🚀',
  '🛰',
  '💺',
  '🛶',
  '⛵️',
  '🛥',
  '🚤',
  '🛳',
  '⛴',
  '🚢',
  '⚓️',
  '🚧',
  '⛽️',
  '🚏',
  '🚦',
  '🚥',
  '🗺',
  '🗿',
  '🗽',
  '⛲️',
  '🗼',
  '🏰',
  '🏯',
  '🏟',
  '🎡',
  '🎢',
  '🎠',
  '⛱',
  '🏖',
  '🏝',
  '⛰',
  '🏔',
  '🗻',
  '🌋',
  '🏜',
  '🏕',
  '⛺️',
  '🛤',
  '🛣',
  '🏗',
  '🏭',
  '🏠',
  '🏡',
  '🏘',
  '🏚',
  '🏢',
  '🏬',
  '🏣',
  '🏤',
  '🏥',
  '🏦',
  '🏨',
  '🏪',
  '🏫',
  '🏩',
  '💒',
  '🏛',
  '⛪️',
  '🕌',
  '🕍',
  '🕋',
  '⛩',
  '🗾',
  '🎑',
  '🏞',
  '🌅',
  '🌄',
  '🌠',
  '🎇',
  '🎆',
  '🌇',
  '🌆',
  '🏙',
  '🌃',
  '🌌',
  '🌉',
  '🌁',
  '🎭',
  '🎨',
];
const OBJECTS_EMOJIS = [
  '🆓',
  '📗',
  '📕',
  '⌚️',
  '📱',
  '📲',
  '💻',
  '⌨️',
  '🖥',
  '🖨',
  '🖱',
  '🖲',
  '🕹',
  '🗜',
  '💽',
  '💾',
  '💿',
  '📀',
  '📼',
  '📷',
  '📸',
  '📹',
  '🎥',
  '📽',
  '🎞',
  '📞',
  '☎️',
  '📟',
  '📠',
  '📺',
  '📻',
  '🎙',
  '🎚',
  '🎛',
  '⏱',
  '⏲',
  '⏰',
  '🕰',
  '⌛️',
  '⏳',
  '📡',
  '🔋',
  '🔌',
  '💡',
  '🔦',
  '🕯',
  '🗑',
  '🛢',
  '💸',
  '💵',
  '💴',
  '💶',
  '💷',
  '💰',
  '💳',
  '💎',
  '⚖️',
  '🔧',
  '🔨',
  '⚒',
  '⛏',
  '⚙️',
  '💣',
  '🔪',
  '🗡',
  '⚔️',
  '🛡',
  '🚬',
  '⚰️',
  '⚱️',
  '🏺',
  '🔮',
  '📿',
  '💈',
  '⚗️',
  '🔭',
  '🔬',
  '🕳',
  '💊',
  '💉',
  '🌡',
  '🚽',
  '🚰',
  '🚿',
  '🛁',
  '🛀',
  '🛎',
  '🔑',
  '🗝',
  '🚪',
  '🛋',
  '🛏',
  '🖼',
  '🛍',
  '🛒',
  '🎁',
  '🎈',
  '🎏',
  '🎀',
  '🎊',
  '🎉',
  '🎎',
  '🏮',
  '🎐',
  '✉️',
  '📪',
  '📮',
  '📯',
  '📜',
  '📃',
  '📄',
  '📑',
  '📊',
  '📈',
  '📉',
  '🗒',
  '🗓',
  '📆',
  '📅',
  '📇',
  '🗃',
  '🗳',
  '🗄',
  '📋',
  '🗞',
  '📰',
  '📘',
  '📚',
  '📖',
  '🔖',
  '🔗',
  '📎',
  '📐',
  '📏',
  '📍',
  '📌',
  '🖊',
  '🖌',
  '🖍',
  '📝',
  '✏️',
  '🔍',
  '🔓',
];
const SYMBOLS_EMOJIS = [
  '❤️',
  '💛',
  '💚',
  '💙',
  '💜',
  '🖤',
  '💔',
  '❣️',
  '💕',
  '💞',
  '💓',
  '💗',
  '💖',
  '💘',
  '💝',
  '💟',
  '☮️',
  '✝️',
  '☪️',
  '🕉',
  '☸️',
  '✡️',
  '🔯',
  '🕎',
  '☯️',
  '☦️',
  '🛐',
  '⛎',
  '♈️',
  '♉️',
  '♊️',
  '♋️',
  '♌️',
  '♍️',
  '♎️',
  '♏️',
  '♐️',
  '♑️',
  '♒️',
  '♓️',
  '🆔',
  '⚛️',
  '🉑',
  '☢️',
  '☣️',
  '📴',
  '📳',
  '🈶',
  '🈚',
  '🈸',
  '🈺',
  '🈷',
  '✴️',
  '🆚',
  '💮',
  '🉐',
  '㊙️',
  '㊗️',
  '🈴',
  '🈵',
  '🈹',
  '🈲',
  '❌',
  '⭕️',
  '🛑',
  '⛔️',
  '📛',
  '🚫',
  '💯',
  '💢',
  '♨️',
  '🚷',
  '🚯',
  '🚳',
  '🚱',
  '🔞',
  '📵',
  '🚭',
  '❕',
  '❔',
  '‼️',
  '⁉️',
  '🔅',
  '🔆',
  '〽️',
  '⚠️',
  '🚸',
  '🔱',
  '⚜️',
  '🔰',
  '♻️',
  '✅',
  '🈯',
  '💹',
  '❇️',
  '✳️',
  '❎',
  '🌐',
  '💠',
  'Ⓜ️',
  '🌀',
  '💤',
  '🚺',
  '🚼',
  '🎵',
  '🎶',
  '➕',
  '➖',
  '➗',
  '✖️',
  '💲',
  '💱',
  '™️',
  '©️',
  '®️',
  '〰️',
  '➰',
  '➿',
  '🔚',
  '🔙',
  '🔛',
  '🔝',
  '✔️',
  '☑️',
  '🔈',
  '🔇',
  '🔉',
  '🔊',
  '🔔',
  '🔕',
  '📣',
  '📢',
  '🗨',
  '💬',
  '💭',
  '🗯',
  '♠️',
  '♣️',
  '♥️',
  '♦️',
  '🃏',
  '🎴',
  '🀄',
];

type EmojiCatogory =
  | 'PEOPLE'
  | 'NATURE'
  | 'FOOD'
  | 'SPORTS'
  | 'TRAVEL'
  | 'OBJECTS'
  | 'SYMBOLS';

// const emojiListPicker = (catogory: EmojiCatogory) => {
//   switch (catogory) {
//     case 'PEOPLE':
//       return PEOPLE_EMOJIS;
//     case 'NATURE':
//       return NATURE_EMOJIS;
//     case 'FOOD':
//       return FOOD_EMOJIS;
//     case 'SPORTS':
//       return SPORTS_EMOJIS;
//     case 'TRAVEL':
//       return TRAVEL_EMOJIS;
//     case 'OBJECTS':
//       return OBJECTS_EMOJIS;
//     case 'SYMBOLS':
//       return SYMBOLS_EMOJIS;
//   }
// };

type EmojiCatogoryWithIcon = {
  category: EmojiCatogory;
  icon: () => JSX.Element;
  emojiList: string[];
};

const EmojiCatogoryList: EmojiCatogoryWithIcon[] = [
  {
    category: 'PEOPLE',
    icon: PeopleCategory,
    emojiList: PEOPLE_EMOJIS,
  },
  {
    category: 'NATURE',
    icon: AnimalCatogory,
    emojiList: NATURE_EMOJIS,
  },
  {
    category: 'FOOD',
    icon: FoodCategory,
    emojiList: FOOD_EMOJIS,
  },
  {
    category: 'SPORTS',
    icon: SportsCategory,
    emojiList: SPORTS_EMOJIS,
  },
  {
    category: 'TRAVEL',
    icon: TravelCategory,
    emojiList: TRAVEL_EMOJIS,
  },
  {
    category: 'OBJECTS',
    icon: ObjectsCategory,
    emojiList: OBJECTS_EMOJIS,
  },
  {
    category: 'SYMBOLS',
    icon: SymbolsComponent,
    emojiList: SYMBOLS_EMOJIS,
  },
];

export function EmojiPopup(props: { onSelected: (emoji: string) => void }) {
  const [emojiCategory, setEmojiCategory] =
    React.useState<EmojiCatogory>('PEOPLE');

  function onEmojiSelect(emoji: string) {
    props.onSelected(emoji);
    console.log(emoji.length, 'emoji');
  }

  // const emojisToDisplay = emojiListPicker(emojiCategory);
  const _emojisToDisplay = EmojiCatogoryList.find(
    (x) => x.category === emojiCategory
  )?.emojiList;

  return (
    <div className={'emoji_wrapper'}>
      <div className={'emoji_picker'}>
        {_emojisToDisplay !== undefined
          ? _emojisToDisplay.map((emoji, index) => (
              <span
                className={'emoji_emoji'}
                key={index}
                onClick={() => {
                  onEmojiSelect(emoji);
                }}
              >
                {emoji}
              </span>
            ))
          : null}
      </div>
      <div className="message_toolbar_emojiButton">
        {EmojiCatogoryList.map((x) => (
          <span
            className={
              x.category !== emojiCategory
                ? 'emoji_category'
                : 'emoji_category color-pink'
            }
            role="img"
            title={x.category.toLowerCase()}
            aria-label={x.category.toLowerCase()}
            onClick={() => setEmojiCategory(x.category)}
          >
            <x.icon />
          </span>
        ))}
        {/* <span
          className="emoji_category"
          role="img"
          title="people"
          aria-label="people"
          onClick={() => setEmojiCategory("PEOPLE")}
        >
          <PeopleCategory />
        </span>

        <span
          className="emoji_category"
          role="img"
          title="people"
          aria-label="people"
          onClick={() => setEmojiCategory("NATURE")}
        >
          <AnimalCatogory />
        </span> */}
      </div>
    </div>
  );
}

// const PeopleCategory =

function PeopleCategory() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 496 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 168c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm-160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm194.8 170.2C334.3 380.4 292.5 400 248 400s-86.3-19.6-114.8-53.8c-13.6-16.3 11-36.7 24.6-20.5 22.4 26.9 55.2 42.2 90.2 42.2s67.8-15.4 90.2-42.2c13.4-16.2 38.1 4.2 24.6 20.5z"
        stroke="none"
      />
    </svg>
  );
}

function AnimalCatogory() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256 224c-79.41 0-192 122.76-192 200.25 0 34.9 26.81 55.75 71.74 55.75 48.84 0 81.09-25.08 120.26-25.08 39.51 0 71.85 25.08 120.26 25.08 44.93 0 71.74-20.85 71.74-55.75C448 346.76 335.41 224 256 224zm-147.28-12.61c-10.4-34.65-42.44-57.09-71.56-50.13-29.12 6.96-44.29 40.69-33.89 75.34 10.4 34.65 42.44 57.09 71.56 50.13 29.12-6.96 44.29-40.69 33.89-75.34zm84.72-20.78c30.94-8.14 46.42-49.94 34.58-93.36s-46.52-72.01-77.46-63.87-46.42 49.94-34.58 93.36c11.84 43.42 46.53 72.02 77.46 63.87zm281.39-29.34c-29.12-6.96-61.15 15.48-71.56 50.13-10.4 34.65 4.77 68.38 33.89 75.34 29.12 6.96 61.15-15.48 71.56-50.13 10.4-34.65-4.77-68.38-33.89-75.34zm-156.27 29.34c30.94 8.14 65.62-20.45 77.46-63.87 11.84-43.42-3.64-85.21-34.58-93.36s-65.62 20.45-77.46 63.87c-11.84 43.42 3.64 85.22 34.58 93.36z"
        stroke="none"
      />
    </svg>
  );
}

function FoodCategory() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 416 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8.9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z"
        stroke="none"
      />
    </svg>
  );
}

function SportsCategory() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-48 0l-.003-.282-26.064 22.741-62.679-58.5 16.454-84.355 34.303 3.072c-24.889-34.216-60.004-60.089-100.709-73.141l13.651 31.939L256 139l-74.953-41.525 13.651-31.939c-40.631 13.028-75.78 38.87-100.709 73.141l34.565-3.073 16.192 84.355-62.678 58.5-26.064-22.741-.003.282c0 43.015 13.497 83.952 38.472 117.991l7.704-33.897 85.138 10.447 36.301 77.826-29.902 17.786c40.202 13.122 84.29 13.148 124.572 0l-29.902-17.786 36.301-77.826 85.138-10.447 7.704 33.897C442.503 339.952 456 299.015 456 256zm-248.102 69.571l-29.894-91.312L256 177.732l77.996 56.527-29.622 91.312h-96.476z"
        stroke="none"
      />
    </svg>
  );
}

function TravelCategory() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 576 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M480 192H365.71L260.61 8.06A16.014 16.014 0 00246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"
        stroke="none"
      />
    </svg>
  );
}

function ObjectsCategory() {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={2}
      // viewBox="0 0 448 512"
      height="1em"
      width="1em"
    >
      <style>{'.objectsCategory{fill:#3a5f88}'}</style>
      <path
        className="objectsCategory"
        d="M30 22.9H2v-.5V6.8c0-.9.3-1.2 1.2-1.2h25.6c.9 0 1.2.3 1.2 1.2v16.1zM16 6.7H3.8c-.6 0-.7 0-.7.7v13.7c0 .7 0 .7.7.7h24.3c.7 0 .7 0 .7-.7V7.4c0-.7 0-.7-.7-.7H16zM.1 23.6h12l.3 1.2c.1.3.2.5.6.5h6c.4 0 .5-.1.6-.5l.3-1.2h11.9c.5 1.4-.5 2.8-2 2.8H2.2C1 26.4.1 25.6.1 24.3v-.7z"
      />
      <path
        className="objectsCategory"
        d="M12.8 23.6h6.4c-.1.3-.1.5-.2.7-.1.1-.2.3-.3.3h-5.3c-.1 0-.3-.2-.4-.3-.1-.2-.1-.4-.2-.7z"
      />
    </svg>
  );
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 448 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
        stroke="none"
      />
      <svg
        viewBox="0 0 576 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M480 192H365.71L260.61 8.06A16.014 16.014 0 00246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"
          stroke="none"
        />
      </svg>
      <svg
        viewBox="0 0 576 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M480 192H365.71L260.61 8.06A16.014 16.014 0 00246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"
          stroke="none"
        />
      </svg>
      <svg
        viewBox="0 0 576 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M480 192H365.71L260.61 8.06A16.014 16.014 0 00246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"
          stroke="none"
        />
      </svg>
    </svg>
  );
}

function SymbolsComponent() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
        stroke="none"
      />
    </svg>
  );
}
