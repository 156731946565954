import * as R from 'ramda'

import { Action, Application, MarketPlaceApplication, MarketPlaceAutomation, MarketPlaceAutomationRaw } from './types'

export const getAllSubsets = <T extends unknown>(theArray: T[]) =>
    theArray.reduce((subsets, value) => subsets.concat(subsets.map(set => [value, ...set])), [[]] as T[][])

export const allAreSame = <T extends unknown>(...theArray: T[]) => {
    const first = R.head(theArray)
    return R.all(R.equals(first))(theArray)
}
export type SortBy = 'Alphabetical' | 'Time'
export const uniqueMarketPlaceApps = (
    x: Record<string, MarketPlaceAutomationRaw[]>,
    sortBy: SortBy = 'Alphabetical'
): MarketPlaceApplication[] => {
    const equalApps = (a: MarketPlaceApplication, b: MarketPlaceApplication) => {
        return a.id === b.id
    }
    const fn = (x: MarketPlaceAutomationRaw) => {
        const actionData = x.action_meta_data
        const modifiedDate = new Date(x.modified_date)
        const app: MarketPlaceApplication = {
            id: actionData.appId,
            name: actionData.appName,
            image: actionData.icon,
            lastAutomationModifiedDate: modifiedDate
        }
        return app
    }

    const sortByNameCaseInsensitive = R.compose(R.toLower, R.prop<string, string>('name'))
    const sortByNameDate = R.prop<string, string>('lastAutomationModifiedDate')

    const flatteed = R.compose<any, any, any, any>(R.map(fn), x => [].concat.apply([], x), R.values)(x)
    const uniqueApps = R.uniqWith(equalApps, flatteed)
    return sortBy === 'Alphabetical'
        ? (R.sortBy(sortByNameCaseInsensitive, uniqueApps as any) as any)
        : (R.sortBy(sortByNameDate, uniqueApps as any) as any)
}
export const uniqueMarketPlaceAutomations = (x: Record<string, MarketPlaceAutomationRaw[]>) => {
    const x1 = R.values(x)

    const automationReducer = (curr: MarketPlaceAutomationRaw[]) => {
        const first = curr[0] as MarketPlaceAutomationRaw

        const apps = curr.map(x => {
            const { appName, appId, icon, id, action, type } = x.action_meta_data
            return {
                id: appId,
                name: appName,
                image: icon,
                actionId: id,
                actionName: action,
                actionType: type
            } as Application & Action
        })

        return {
            id: first.id,
            name: first.name,
            description: first.description,
            workspace: first.workspace,
            type: first.type,
            modified_date: new Date(first.modified_date),
            count: first.count,
            apps: apps
        }
    }

    let flatener = R.values(R.map(automationReducer)(x1))
    const flatteed: MarketPlaceAutomation[] = flatener as any
    return flatteed
}
export const padWithZero = (num: number) => (num === 0 ? '0' : num.toString().padStart(2, '0'))
