/*eslint-disable*/
import React from 'react'

import { ThumbsDownIcon, ThumbsUpIcon } from '@/common/Icons/Icons'
import { ResponsivePie } from '@nivo/pie'

// import { PieCharts } from '@/Flows/analytics/home'

export function CardsChatDivide({ automationLogs }: any) {
    return (
        <div className="cards_chat_home">
            <CardsChat type={'automation'} logs={automationLogs} />
            <CardsChat />
            <CardsChat />
        </div>
    )
}

function CardsChat({ type, logs }: any) {
    if (type == 'automation') {
        return (
            <div className="cards_chat">
                <CardsChatTop count={logs.totalExecutionCount} />
                <PieChat logs={logs} />
                <CardsChatBottom
                    totalSuccessCount={logs.totalSuccessCount}
                    totalFailureCount={logs.totalFailureCount}
                />
                {/* <CardsChatBottomWithICon /> */}
            </div>
        )
    }
    return null
}

function CardsChatTop({ count }: any) {
    return (
        <div className="cards_chat_top">
            <div className="cards_chat_top_left">
                <h4>
                    Total Executions : <span> {count}</span>
                </h4>
                <div className="cards_chat_top_right">
                    <h6>
                        <span />
                        Success
                    </h6>
                    <h5>
                        <span />
                        Failed
                    </h5>
                </div>
            </div>
        </div>
    )
}

function PieChat({ logs }: any) {
    return (
        <div className="pie_chat">
            <PieCharts automations={logs} />
        </div>
    )
}

export const CustomPieTooltip = (props: any) => {
    if (props) {
        return (
            <div className={'tooltiptext'}>
                {props.label}: {props.value}%
            </div>
        )
    }
    return null
}

export function PieCharts(props: { automations: any }) {
    const { automations } = props

    const successRate = (automations.totalSuccessCount / automations.totalExecutionCount) * 100
    const failRate = (automations.totalFailureCount / automations.totalExecutionCount) * 100
    const data = [
        { value: Math.round(successRate), id: 'successRate', label: 'Success' },
        { value: Math.round(failRate), id: 'failRate', label: 'Failure' }
    ]
    return (
        <div className="analytics_charts_left_img pie-chart">
            <div style={{ height: 200, width: 200, marginBottom: 0 }}>
                <ResponsivePie
                    sortByValue={true}
                    data={data}
                    startAngle={-180}
                    innerRadius={0.65}
                    borderWidth={0}
                    enableSlicesLabels={false}
                    animate={true}
                    tooltip={CustomPieTooltip}
                    colors={['#A1E89B', '#FF8484']}
                    enableRadialLabels={false}
                    theme={{
                        tooltip: {
                            container: {
                                background: 'transparent',
                                padding: 0,
                                borderRadius: 6
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

function CardsChatBottom({ totalSuccessCount, totalFailureCount }: any) {
    return (
        <div className="cards_chat_top">
            <div className="cards_chat_top_left">
                <h6>
                    Success : <span className="cards_chat_bottom_left">{totalSuccessCount}</span>
                </h6>
                <h5>
                    Failed : <span className="cards_chat_bottom_right">{totalFailureCount}</span>
                </h5>
            </div>
        </div>
    )
}

function CardsChatBottomWithICon(props: any) {
    return (
        <div className="cards_chat_bottom_icon">
            <div className="cards_chat_bottom_icon_head">
                <h6>
                    <ThumbsUpIcon /> <span className="cards_chat_bottom_icon_left">1079</span>
                </h6>
                <h6>
                    <ThumbsDownIcon /> <span className="cards_chat_bottom_icon_right">456 </span>
                </h6>
            </div>
        </div>
    )
}
