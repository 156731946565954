import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { StyledComponent } from 'styled-components'

import { InfoIcon } from '@/common/Icons/Icons'
import {
    AnchorTag,
    AnchorTagContent,
    CreditMoney,
    DollarMonth,
    DollerAmount,
    DollerSup,
    HeaderLeft,
    HoverSpan,
    Tooltip
} from '@/common/styled/Subscription.Xtra.Dumb'

import { SubscriptionDetailsToDisplay } from './CurrentPlan'
import { PlanMetaForUI, planIconMap } from './kind'

const BoxLeftPricing = styled.div`
    width: 30.33%;
    float: left;
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    background: #fff;
    padding: 30px;
    cursor: default;
    margin-right: 0px;
    height: max-content;
`
const CapitalLetters = styled.div`
    width: 100%;
    float: left;
    ${AnchorTagContent} {
        text-transform: uppercase;
    }
`

export const HeaderPrice: StyledComponent<'h2', any, any, never> = styled.h2`
    justify-content: ${(props: any) => (props.alignCenter ? 'center' : 'left')};
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    cursor: default;
    margin-right: 20px;
    color: #2e5ce6;
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
`

const Image = styled.div`
    width: 100%;
    float: left;
`
const ContentDiv = styled.div`
    width: 100%;
    float: left;
    text-align: left;
`
const ImageContainer = styled.div`
    fill: #2e5ce6;
    width: 84px;
    height: 84px;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    display: flex;
`
const PlanDetials = styled.div`
    width: 100%;
    float: left;
    overflow: scroll;
    height: 250px;
`
const ContentDetials = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
`
const CircleSpan = styled.div`
    width: 6px;
    height: 6px;
    float: left;
    border-radius: 50%;
    border: 1px solid #2e5ce6;
    margin-right: 5px;
`
const ContetntSpan = styled.div`
    max-width: 84%;
    float: left;
    margin-right: 5px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #494949;
`

const ContetntInformation = styled.div`
    width: 12px;
    height: 12px;
    float: left;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`
export const CurrentPlanContainer = ({
    goPlansPage,
    subscribedPlan,
    metaForUI
}: {
    goPlansPage: Function
    subscribedPlan: SubscriptionDetailsToDisplay
    metaForUI: PlanMetaForUI
}) => {
    const data = metaForUI
    // console.log('sub scribedPlan', subscribedPlan)

    const image = planIconMap[metaForUI.referenceName]
    return (
        <BoxLeftPricing>
            <HeaderLeft>Current Plan</HeaderLeft>
            <Image>
                <ImageContainer>{image}</ImageContainer>
            </Image>
            <HeaderPrice alignCenter>{data.plans}</HeaderPrice>
            <CreditMoney CreditsAlign="center" CreditsMargin="10px 0px 0px 0px">
                <DollerSup SupFont="16px">{data.dollar}</DollerSup>
                <DollerAmount DollerFont="28px">
                    {data.type == 'Monthly' ? data.price : Math.floor(Number(data.price) / 12)}
                    <DollarMonth DollerMonth="16px">
                        /{data.type == 'Monthly' ? 'month' : 'month billed yearly'}
                    </DollarMonth>
                </DollerAmount>
            </CreditMoney>
            <CapitalLetters>
                <AnchorTag>
                    <AnchorTagContent alignUnderline onClick={() => goPlansPage()}>
                        Change Plan
                    </AnchorTagContent>
                </AnchorTag>
            </CapitalLetters>
            <PlanDetials>
                {data.steps.map((step, index: number) => (
                    <ContentDetials key={index}>
                        <CircleSpan></CircleSpan>
                        <React.Fragment>
                            <ContetntSpan>
                                <b>{step.bolder} </b>
                                {step.category}
                            </ContetntSpan>
                            <ContetntInformation data-tip data-for={step.category}>
                                <InfoIcon />
                                <Tooltip>
                                    <ReactTooltip
                                        className="subscription_tooltip"
                                        id={step.category}
                                        type="warning"
                                        effect="solid"
                                    >
                                        <HoverSpan>{step.hoverContent}</HoverSpan>
                                    </ReactTooltip>
                                </Tooltip>
                            </ContetntInformation>
                        </React.Fragment>
                    </ContentDetials>
                ))}
            </PlanDetials>
        </BoxLeftPricing>
    )
}
