import './selectBox.css'

import Downshift from 'downshift'
import * as R from 'ramda'
/*eslint-disable*/
import React, { useEffect, useState } from 'react'

import { InputWithLabel } from '@/Profile/Profile.Page'
import { DropdownWithCircle } from '@/common/Icons/Icons'
import { Error } from '@/common/components/ErrorBoundary'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper } from '@/common/styled/Dialogue.Dumb'
import {
    CancelButton,
    DropdownLi,
    DropdownUl,
    DropdownUlWrapper,
    Input,
    InputContainer,
    InputContent,
    InputWrapper,
    Textarea
} from '@/common/styled/Workflow.Analytics.Dumb'

import { getCurrentUserInfo } from '@/common/utils/utils'
import { logEvent, submitSupportTicket } from '@/common/utils/api-utils'
import { ProfileTabs } from '@/SidePanel/Sidebar'

type Item<T> = {
    display: string
    value: T
}

type SelectBoxP<T> = {
    className?: string
    label: string
    placeholder?: string
    items: Item<T>[]
    selected: T | Number
    onChange?: (selection: Item<T> | null) => any
    menu?: {
        className?: string
    }
}

type SupportReqField = 'name' | 'email' | 'phone_number' | 'type' | 'priority' | 'workspace' | 'subject' | 'description'
type TicketType = 'Feedback' | 'Defect' | 'Feature Request' | 'Question' | 'Support'
type TicketPriority = 'Low' | 'Medium' | 'High' | 'Urgent'

type SupportReq = {
    name: string
    email: string
    phone_number?: string
    type: TicketType
    priority: TicketPriority
    workspace: string
    subject: string
    description: string
}

const ticketTypes: Item<TicketType>[] = [
    { value: 'Support', display: 'Technical Support' },
    { value: 'Defect', display: 'Report a Bug' },
    { value: 'Feature Request', display: 'Suggest a New Feature' },
    { value: 'Feedback', display: 'Suggest Improvement' },
    { value: 'Question', display: 'Other Questions' }
]

const defaultTicketType = ticketTypes[0]

const ticketPriority: Item<TicketPriority>[] = [
    {
        value: 'Low',
        display: 'Low'
    },
    { value: 'Medium', display: 'Medium' },
    { value: 'High', display: 'High' },
    { value: 'Urgent', display: 'Urgent' }
]
const defaultPriority = ticketPriority[0]

function useFetchUser(fetch: any, success: any, failure: any) {
    useEffect(() => {
        async function fetchUser() {
            try {
                success(await getCurrentUserInfo())
            } catch (e) {
                failure(e)
            }
        }
        fetchUser()
    }, [fetch])
}

function userInfoForSupportForm(user: any) {
    return {
        name: R.join(' ', [R.prop('first_name', user), R.prop('last_name', user)]),
        email: R.prop('email', user)
    }
}

type FormStatus = 'Loaded' | 'Submitting' | 'Submitted'

type SupportHookOutput = SupportReq & {
    updateField: R.CurriedFunction2<SupportReqField, any, any>
    reset: (newReq: SupportReq) => any
    userIsLoading: boolean
    errorLoadingUser: any
    submit: () => any
    formStatus: FormStatus
    submissionError: any
    setFormStatus: (status: FormStatus) => any
    allValid: boolean
}

function useSupportForm(options: SupportReq): SupportHookOutput {
    const [fieldValues, setFieldValues] = useState(options)
    const [userIsLoading, setLoadingUser] = useState(true)
    const [errorLoadingUser, setErrorLoadingUser] = useState(null)
    const [formStatus, setFormStatus] = useState<FormStatus>('Loaded')
    const [submissionError, setSubmissionError] = useState(null)
    const [loadUser, setLoadUser] = useState(true)
    const subjectIsValid = !!R.trim(fieldValues.subject).length
    const descriptionIsValid = !!R.trim(fieldValues.description).length

    useFetchUser(
        loadUser,
        (user: any) => {
            console.log('user is loaded ::: ', user)
            setFieldValues({ ...fieldValues, ...(userInfoForSupportForm(user) as any) })

            setLoadingUser(false)
        },
        (e: any) => {
            setErrorLoadingUser(e.response ? e.response.status : 500)
            setLoadingUser(false)
        }
    )

    return {
        ...fieldValues,
        formStatus,
        setFormStatus,
        submissionError,
        updateField: R.curry((fieldName: SupportReqField, value: any) =>
            setFieldValues(R.assoc(fieldName, value, fieldValues))
        ),
        reset: req => {
            setFieldValues(req)
            setLoadingUser(false)
            setErrorLoadingUser(null)
            setFormStatus('Loaded')
            setSubmissionError(null)
            setLoadUser(!!loadUser)
        },
        submit: async () => {
            setFormStatus('Submitting')
            try {
                await submitSupportTicket(R.omit(['priority'], fieldValues))
                logEvent(options.workspace, {
                    event:"EVENT::SUPPORT::CREATE_TICKET",event_meta:{}
                })
            } catch (e) {
                setSubmissionError(e.response ? e.response.status : 500)
            } finally {
                setFormStatus('Submitted')
            }
        },
        userIsLoading,
        errorLoadingUser,
        allValid: R.reduce<boolean, boolean>(R.and, true, [subjectIsValid, descriptionIsValid])
    }
}

const SelectBox = <T extends {}>(props: SelectBoxP<T>) => {
    const items = props.items
    const itemToString = (item: Item<T> | null) => (item ? item.display : '')
    return (
        <Downshift
            onChange={selection => (props.onChange ? props.onChange(selection) : null)}
            itemToString={itemToString}
            selectedItem={
                typeof props.selected === 'number'
                    ? items[props.selected]
                    : (R.find(R.compose(R.equals(props.selected), R.prop('value')), items) as any)
            }
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                toggleMenu
            }) => (
                <div
                    className={
                        props.className
                            ? 'assistant-select-box ' +
                              props.className +
                              ((isOpen && ' assistant-select-box-open') || '')
                            : 'assistant-select-box' + ((isOpen && ' assistant-select-box-open') || '')
                    }
                >
                    <InputContainer className="mb-0">
                        <Input
                            {...getInputProps()}
                            onClick={() => {
                                toggleMenu()
                            }}
                            value={inputValue || ''}
                            className={
                                props.menu && props.menu.className
                                    ? props.menu.className + ' assistant-select-box-input'
                                    : 'assistant-select-box-input'
                            }
                        />
                        <InputContent {...getLabelProps()}>{props.label}</InputContent>
                        <CancelButton className="d-flex">
                            <span
                                className="rotate_dropdown_support"
                                onClick={() => {
                                    toggleMenu()
                                }}
                            >
                                <DropdownWithCircle />
                            </span>{' '}
                        </CancelButton>
                    </InputContainer>
                    <DropdownUlWrapper className="mb-0">
                        {isOpen && (
                            <div
                                className="dropdown_right_entity"
                                role="combobox"
                                aria-expanded="true"
                                aria-haspopup="listbox"
                                aria-owns="downshift-17-menu"
                                aria-labelledby="downshift-17-label"
                            >
                                <DropdownUl {...getMenuProps()}>
                                    {isOpen
                                        ? items.map((item, index) => (
                                              <DropdownLi
                                                  {...getItemProps({
                                                      key: index,
                                                      index,
                                                      item,
                                                      className:
                                                          highlightedIndex === index
                                                              ? 'assistant-selectbox-option-highlight'
                                                              : 'assistant-selectbox-option-normal'
                                                  })}
                                                  style={{padding: '4px 10px 0 10px'}}
                                              >
                                                  {item.display}
                                              </DropdownLi>
                                          ))
                                        : null}
                                </DropdownUl>
                            </div>
                        )}
                    </DropdownUlWrapper>
                </div>
            )}
        </Downshift>
    )
}

const Header = () => (
    <div className="profile_collaspe_menu_head">
        <span className="profile_collaspe_menu profile_collaspe_menu_click">Contact us</span>
    </div>
)

const lastName = R.compose<string, string[], string>(R.last, R.split(' '))

const firstName = R.compose<string, string[], string>(R.head, R.split(' '))

const updateFirstName = (name: string) =>
    R.compose<string, string[], string>(R.join(' '), firstName => R.prepend(firstName, [lastName(name)]))

const updateLastName = (name: string) =>
    R.compose<string, string[], string>(R.join(' '), lastName => R.append(lastName, [firstName(name)]))

const SupportForm = (props: any) => {
    const defaultFormValues = {
        name: '',
        email: '',
        workspace: props.ws,
        description: '',
        subject: '',
        type: defaultTicketType['value'],
        priority: defaultPriority['value']
    }
    const {
        name,
        email,
        workspace,
        description,
        subject,
        type,
        priority,
        updateField,
        userIsLoading,
        formStatus,
        submissionError,
        setFormStatus,
        reset,
        submit,
        errorLoadingUser,
        allValid
    } = useSupportForm(defaultFormValues)
    props.loadingState(userIsLoading)
    props.loadingError(errorLoadingUser)

    const resetForm = () => reset(R.assoc('email', email, R.assoc('name', name, defaultFormValues)))

    const [firstName, lastName] = R.split(' ', name)

    const updateAndSaveFirstName = R.compose<string, string, any>(updateField('name'), updateFirstName(name))

    const updateAndSaveLastName = R.compose(updateField('name'), updateLastName(name))

    return (
        <React.Fragment>
            <div className="login_bg_width_left_right_form">
                <div className="col-md-12 col-lg-12 form_w100">
                    <InputWithLabel
                        type="text"
                        label="First name"
                        placeHolder="Enter your first name"
                        additionClass="form_w50_pl"
                        disabled={true}
                        value={firstName}
                        onChange={updateAndSaveFirstName}
                    />
                    <InputWithLabel
                        type="text"
                        label="Last name"
                        placeHolder="Enter your last name"
                        additionClass="form_w50_pl"
                        disabled={true}
                        value={lastName}
                        onChange={updateAndSaveLastName}
                    />
                    <InputWithLabel
                        type="text"
                        label="User email"
                        placeHolder="Enter your email"
                        additionClass="form_w50_pl"
                        disabled={true}
                        value={email}
                        onChange={updateField('email')}
                    />
                    <InputWithLabel
                        type="text"
                        label="Workspace name"
                        placeHolder="Enter Your Workspace name"
                        additionClass="form_w50_pl"
                        disabled={true}
                        value={workspace}
                        onChange={updateField('workspace')}
                    />
                    <SelectBox
                        items={ticketTypes}
                        className="form_w50_pl country_zones ticket_zones"
                        selected={type}
                        onChange={R.compose<any, any, any>(updateField('type'), R.prop('value'))}
                        placeholder="Select Ticket type"
                        label="Ticket type"
                        menu={{ className: 'form-control' }}
                    />
                    {/* <SelectBox
                        items={ticketPriority}
                        className="col-md-6 col-lg-6 form_w50 form_w50_pl country_zones ticket_zones"
                        selected={priority}
                        onChange={R.compose<Item<TicketPriority>, TicketPriority, any>(
                            updateField('priority'),
                            R.prop('value')
                        )}
                        placeholder="Select Ticket Priority"
                        label="Ticket Priority"
                        menu={{ className: 'form-control' }}
                    /> */}
                    <div className=" form_w50 form_w50_pl">
                        <InputWithLabel
                            type="text"
                            label="Subject"
                            placeHolder="Enter your short description of ticket"
                            disabled={false}
                            value={subject}
                            onChange={updateField('subject')}
                            maxLength={80}
                        />
                    </div>
                </div>
                <div className="w-100 float-left">
                    <div className="form_support_content">
                        <div className="form_support_content_w_100">
                            <InputWrapper>
                                <InputContainer>
                                    <Textarea
                                        className="h-auto"
                                        rows="3"
                                        value={description}
                                        onChange={R.compose(updateField('description'), R.path(['target', 'value']))}
                                        maxLength={255}
                                    />
                                    <InputContent>Description</InputContent>
                                </InputContainer>
                            </InputWrapper>
                        </div>
                    </div>
                    <ButtonWrapper className="justify-content-start">
                        <Button
                            className={allValid ? '' : ' editor_btn_disabled'}
                            onClick={submit}
                            disabled={!allValid}
                        >
                            <label>Submit</label>
                        </Button>
                    </ButtonWrapper>
                </div>
            </div>
            {formStatus === 'Submitting' ? <Modal close={() => {}} showPopupLoader={true} /> : null}
            {formStatus === 'Submitted' && submissionError ? (
                <Modal close={() => setFormStatus('Loaded')}>
                    {'Something Went Wrong! Please try again'}
                    <Button primary type="button" onClick={submit} style={{ minWidth: 'max-content' }}>
                        Retry
                    </Button>
                </Modal>
            ) : (
                formStatus === 'Submitted' && (
                    <Modal close={() => {}}>
                        <div className="support_content_div">Thanks! We’ll get in touch with you soon.</div>
                        <Button className="btn btn-primary" type="button" onClick={resetForm}>
                            Okay
                        </Button>
                    </Modal>
                )
            )}
        </React.Fragment>
    )
}

const SupportPage = (props: any) => {
    const [loading, setLoading] = useState(true)
    const [loadingError, setLoadingError] = useState(null)
    return (
        <>
        <section className="cogni_cards tour_automation_canvas">
                    <div className="header_box_shadow" />
                    <div className="flow_canvas1">
                        {/* <div className="flex_canvas1"> */}
                            <div className="flex_menu1 flow_group_home">
                                <div className="indent_dialog_left">
                                    <div className="indent_left">
                                        <div className="indent_title mb-0">Support</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex_side_menu1 intent_home flow_group_home">
                                <div className="intent_dialog_right">
                                    <ProfileTabs {...props}/>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </section>
                {!loadingError ? (
                    <React.Fragment>
                        <div
                            className="entity_cards entity_cards_without_scroll"
                            style={loading ? { display: 'none' } : undefined}
                        >
                            <div className="col-md-12 profile_page_form">
                                <div className="row">
                                    <div className="profile_page_uform">
                                        <div className="profile_form-page">
                                            <Header />
                                            <SupportForm
                                                ws={props.match.params.workspacename}
                                                loadingState={setLoading}
                                                loadingError={setLoadingError}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Loader.PageLoader show={loading} />
                    </React.Fragment>
                ): <Error.PageLoadingError btnName="Retry Now" onClick={() => window.location.reload()} />
            }
        </>
    )
}
export default SupportPage
