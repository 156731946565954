import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { DateAndTime } from './WelcomePageWithJourney';
import GetNewImg from '@/common/images/get_new_img.png';
import { Modal } from '@/common/components/Modal';
import { Paragraph } from '@/common/styled/Dialogue.Dumb';
import { Button, CardContainer } from '@/common/styled/Dialog.BotDetails.Dumb';
import { DialogIcon, KnowledgeSideIcon } from '@/common/Icons/Icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { getJson, logEvent, postJson, userJourneyWithWorkspace } from '@/common/utils/api-utils';
import {
  BotMarketPlace,
  DownloadBot,
} from '@/DialogEditor/Home/BotMarketplaces/MarketPlaceUtils';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { useJourney } from '@/Journey/JourneyContext';
import { getAssistants, getDialogueRoots } from '@/Assistant/Mechanisms/action';
import { DialogueT } from '@/Assistant/Dialogue/kind';
import { useQueryClient } from 'react-query';
import { days, formatDateInCard } from '@/common/utils/utils';
import { Loader } from '@/common/components/Loader';
import { HeaderForCanvasPage } from './NewGetStartedPage';

const StartWrapper = styled.section`
  width: 100%;
  float: left;
  padding: 20px 20px 60px;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;
const BoxWrapper = styled.section`
  width: 100%;
  float: left;
  padding: 30px 40px 60px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px;
  border-radius: 10px;
  position: relative;
  // margin-top: 66px;

  .box_wrapper_top {
    width: 100%;
    display: flex;
    flex-direction: column;

    :before {
      content: '';
      position: absolute;
      border-bottom: 1px solid rgb(224, 224, 224);
      width: 100%;
      height: 1px;
      top: 16%;
      left: 0;
      z-index: 0;
    }

    h2 {
      margin-bottom: 14px;
      line-height: 24px;
      font-size: 24px;
      span {
        color: #466AF2;
      }
    }
    p {
      line-height: 14px;
      color: #355d8b;
      font-family: 'Gordita-Regular';
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
`;
const BoxWrapperTop = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
`;

const DownloadBotDiv = styled.div`
  width: 100%;
  float: left;
  padding: 20px 0 40px;
`;
const TitleBox = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;

  h4 {
    font-family: 'Gordita-Medium';
    font-size: 16px;
  }

  p {
    line-height: 14px;
    color: #355d8b;
    font-family: 'Gordita-Regular';
    font-size: 14px;
  }

  div:last-child {
    width: 170px;
    position: absolute;
    right: 10%;

    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  // padding-bottom: 40px;
  ${Button} {
    margin: 0px;
    max-width: max-content;
    min-width: 116px;
    padding: 5.5px 14px;
    font-size: 14px;
  }
`;

export function JourneyMarketPlace(props: any) {
  const { match } = props;
  const { workspacename } = match.params;
  const [loading, setLoading] = useState('loading');
  const navigate = useNavigate();
  const journeyData = useJourney();
  const queryClient = useQueryClient();
  const [state, setState] = useState([]);
  const [downloadBot, setDownloadBot] = useState<any>(null);
  const [isDownload, setIsDownload] = useState({ error: '', loading: false });

  const secondCounter = new Date().getHours();
  const minuteCounter = new Date().getMinutes();
  const secondsCounter = new Date().getSeconds();

  const computedHour =
    String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
  const computedMinute =
    String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;
  const computedSeconds =
    String(secondsCounter).length === 1 ? `0${secondsCounter}` : secondsCounter;

  const greetMessage = (hrs: number) => {
    let msg = 'Morning';
    // Before 6am
    if (hrs < 7) return 'Morning';
    // After 6am
    else if (hrs < 13) return 'Good Morning';
    // After 12pm
    else if (hrs < 18) return 'Good afternoon';
    // After 5pm
    else if (hrs < 23) return 'Good evening';
    else return 'Go to bed';
    return msg;
  };

  const [day, setDay] = useState<any>({
    hour: computedHour,
    minute: computedMinute,
    seconds: computedSeconds,
    date: new Date(),
  });

  useEffect(() => {
    let intervalId: number;

    getJson(`${BotMarketPlace}/${workspacename}/user_journey_bots`).then(
      (resp) => {
        setLoading('loaded');
        setState(resp.output);
      }
    );

    intervalId = setInterval(() => {
      const hourCounter = new Date().getHours();
      const minuteCounter = new Date().getMinutes();
      const secondsCounter = new Date().getSeconds();

      const computedHour =
        String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
      const computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;
      const computedSeconds =
        String(secondsCounter).length === 1
          ? `0${secondsCounter}`
          : secondsCounter;
      // console.log('hour', {
      //     hour: computedHour,
      //     minute: computedMinute,
      //     seconds: computedSeconds,
      //     date: new Date()
      // })

      setDay({
        hour: computedHour,
        minute: computedMinute,
        seconds: computedSeconds,
        date: new Date(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const DownloadEvent = (botId: string) => {
    setIsDownload({ error: '', loading: true });
    DownloadBot(workspacename, botId)
      .then(async (downloadedResult: any) => {
        await queryClient.invalidateQueries(['assistants']);
        logEvent(workspacename, {
          event: 'EVENT::ONBOARDING::DOWNLOAD_BOT',
          event_meta: {
            name: downloadBot.name,
            description: '',
          },
        });

        console.log("JOurney Marketplace" , JSON.stringify(journeyData))
        queryClient
            .fetchQuery({
                queryKey : ['assistants'],
                queryFn : () => { 
                return getAssistants(workspacename) 
                },
            })
            .then(async(res) => {
                const latestBot = res[0]
                if (journeyData.type === 'success') {
                  journeyData.value.send({
                    type: 'EVENT::BOT::DOWNLOADED',
                    tag: 'BOT_EVENT',
                    botId: latestBot.id,
                    botName: latestBot.name,
                    journeyType: 'Main',
                  });

                  journeyData.value.send({
                    type: 'EVENT::BOT::CREATED',
                    tag: 'BOT_EVENT',
                    botId: latestBot.id,
                    botName: latestBot.name,
                    journeyType: 'Main'
                  })

                  const value = journeyData.value.state()
                  await postJson(userJourneyWithWorkspace(workspacename, '/journey/update'))({
                    journeyJson: { state: value.value, context: value.context.executionContext }
                  })


                  await queryClient
                      .fetchQuery(['dialogRoots', latestBot.id], () =>
                        getDialogueRoots(workspacename, latestBot.id)
                      )
                      .then(async (roots: DialogueT[]) => {
                        const hasIfNode =
                          roots.find((x) => x.type === 'if_node') !== undefined;
                        const hasFaqNode =
                          roots.find((x) => x.type === 'faq_node') !== undefined;

                        if (hasFaqNode) {
                          await journeyData.value.send({
                            type: 'EVENT::FAQ::CREATED',
                            tag: 'GENERIC',
                            journeyType: 'FAQ',
                            refId: latestBot.id,
                          });
                        }

                        if (hasIfNode) {
                          await journeyData.value.send({
                            type: 'EVENT::DIALOG::CREATED',
                            tag: 'GENERIC',
                            journeyType: 'Dialog',
                            refId: latestBot.id,
                          });
                        }
                      });
                  // setIsDownload({ error: '', loading: false })

                  setTimeout(() => {
                    navigate(
                      `/workspace/${workspacename}/assistant/${latestBot.name}/conversations`,
                      { replace: true }
                    );
                    setIsDownload({ error: '', loading: false });
                    setDownloadBot(null);
                  }, 500);
                  // TODO :: tt reloeading page to update Sidebar.needs a fix
                }
              })
      })
      .catch((err) => {
        setIsDownload({
          error: 'Could not download the bot. Please try again!',
          loading: false,
        });
      });
  };

  function showDownaload() {
    return (
      <Modal showPopupLoader={isDownload.loading}>
        <ErrorBoundary
          error={
            isDownload.error.length > 0
              ? { error: true, info: isDownload.error }
              : { error: false, info: '' }
          }
          render={(err: any, info: any) => {
            return (
              err && (
                <Error.Delete
                  onClick={() => setIsDownload({ error: '', loading: false })}
                  info={info}
                />
              )
            );
          }}
        >
          {' '}
          <h2>You have selected</h2>
          <Paragraph
            style={{
              color: '#EB974E',
              fontSize: '18px',
              fontFamily: 'Gordita-Medium',
            }}
          >
            {downloadBot.name}
          </Paragraph>
          <ButtonWrapper style={{ gap: '20px', marginTop: 10 }}>
            <Button
              primary
              type="button"
              data-dismiss="modal"
              style={{ minWidth: '90px', fontSize: '12px' }}
              onClick={() => setDownloadBot(null)}
            >
              <label style={{top: 1}}>Cancel</label>
            </Button>
            <Button
              type="button"
              data-dismiss="modal"
              style={{ minWidth: '90px', fontSize: '12px' }}
              onClick={() => {
                DownloadEvent(downloadBot.id);
              }}
            >
              <label style={{top: 1}}>Download</label>
            </Button>
          </ButtonWrapper>
        </ErrorBoundary>
      </Modal>
    );
  }

  const currentDate = day.date;

  if (loading === 'loading' || journeyData.type === 'loading') {
    return <Loader.PageLoader show={true} />;
  }

  if (
    journeyData.type === 'success' &&
    journeyData.value.state().value.type === 'MAIN' &&
    journeyData.value.state().value['JOURNEY'] !== 'JOURNEY::NOT_STARTED' &&
    journeyData.value.state().value['JOURNEY'] !== 'JOURNEY::BOT::DOWNLOADED'
  ) {
    return <Navigate to={`/workspace/${workspacename}/welcome`} replace />;
  }
  // console.log(props.userData,"props.userData")
  return (
    <>
      <HeaderForCanvasPage {...props} />
      <div className="getstart_page_box"></div>
      <div className='class="header_loader_height_change getstart_page'>
        <StartWrapper>
          {downloadBot && downloadBot.id.length > 0 && showDownaload()}
          <BoxWrapper>
            <BoxWrapperTop>
              <div className="box_wrapper_top">
                <h2>
                  {greetMessage(day.hour)},
                  <span> {props.userData.first_name}!</span>
                </h2>
                <p>Welcome and let’s start building your new bot.</p>
              </div>
              <DateAndTime>
                <h5>
                  {days[currentDate.getDay()]},{' '}
                  {formatDateInCard(currentDate.toString())}
                </h5>
                <h6>
                  {day.hour > 12 ? day.hour % 12 : day.hour}:{day.minute}:
                  {day.seconds} {day.hour >= 12 ? 'PM' : 'AM'}
                </h6>
              </DateAndTime>
            </BoxWrapperTop>
            <DownloadBotDiv>
              <TitleBox>
                <div>
                  <h4>Start your trial with our pre-built bot templates.</h4>
                  <p>You can always customize or add new topics later.</p>
                </div>
                <div>
                  <img src={GetNewImg} width="100%" height="100%" alt="new" />
                </div>
              </TitleBox>

              <div className="col-md-12 get-start-new">
                <div className="row card_grid_view">
                  {state.slice(0, 3).map((bot: any) => (
                    <div className="col-3 dialog_grids" onClick={() => setDownloadBot(bot)}>
                      <div className="entity_cards_layer workflow_cards">
                        <div className="dialog_top_section workflows">
                          <div className="dialog_icon">
                            <DialogIcon />
                          </div>
                        </div>
                        <div className="automation_card">
                          <div className="search-card-title flow_create_new">
                            <div className="flow_card_right">
                              <h5>{bot.name}</h5>
                              <p>{bot.description}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flow_entity_span">
                          <span
                            className="entity_span"
                          >
                            Download this bot <span> &gt; </span>
                          </span>
                          <span className="entity_span">
                            {' '}
                            <span>
                              {bot.downloaded_count > 9
                                ? bot.downloaded_count
                                : '0' + bot.downloaded_count}
                            </span>{' '}
                            Downloads
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 get-start-new">
                <div className="row card_grid_view">
                  {state.slice(3, 7).map((bot: any) => (
                    <div className="col-3 dialog_grids"  onClick={() => setDownloadBot(bot)}>
                      <div className="entity_cards_layer workflow_cards">
                        <div className="dialog_top_section workflows">
                          <div className="dialog_icon">
                            <DialogIcon />
                          </div>
                        </div>
                        <div className="automation_card">
                          <div className="search-card-title flow_create_new">
                            <div className="flow_card_right">
                              <h5>{bot.name}</h5>
                              <p>{bot.description}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flow_entity_span">
                          <span
                            className="entity_span"
                          >
                            Download this bot <span> &gt; </span>
                          </span>
                          <span className="entity_span">
                            {' '}
                            <span>
                              {bot.downloaded_count > 9
                                ? bot.downloaded_count
                                : '0' + bot.downloaded_count}
                            </span>{' '}
                            Downloads
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </DownloadBotDiv>
          </BoxWrapper>
        </StartWrapper>
      </div>
    </>
  );
}
