/*eslint-disable*/
import React, { useState } from 'react'

//ICON IMPORT
import {
    AdaptiveIcon,
    AddIcon,
    AttachmentIcon,
    DeleteIcon,
    DialogIcon,
    DownArrowCircle,
    EditIcon,
    ExitIcon,
    FlowIcon,
    IconViewICon,
    JumpIcon,
    MoreIcon,
    NextDetailICon,
    OptionIcon,
    QaIcon,
    ResponseIcon,
    SlotsIcon,
    SubPanelIcons,
    VariableIcon
} from '@/common/Icons/Icons'

export const DialogDetail = () => {
    const [message, setMessage] = useState(true)

    const HeaderContent = (props: { icon: any; header: string }) => {
        return (
            <>
                <div className="dialog_detail_header">
                    <div className="parent_node_no">
                        <span className="parent_svg">{props.icon}</span>
                    </div>
                    <h4>{props.header}</h4>
                </div>
                <div className="border-bottom_flow" />
            </>
        )
    }

    function returnUI(params: any) {
        switch (params) {
            case 'dialog':
                return (
                    <>
                        <HeaderContent icon={<DialogIcon />} header={'Unlock account'} />
                        <div className="recognise_dialog_details">
                            <div className="dialog_detail_borderbottom">
                                <h6>If user says</h6>
                                <p>Unlock_account</p>
                                <div className="dailog_btn_wrapper">
                                    <button>
                                        <span>
                                            <FlowIcon />
                                        </span>
                                        Success
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case 'message':
                return (
                    <>
                        <HeaderContent icon={<ResponseIcon />} header={'Send a Message'} />
                        <div className="send_message_details">
                            <div className="dialog_detail_borderbottom">
                                <h6>Respond with</h6>
                                <div className="dialog_detail_borderbottom_span">
                                    <span>
                                        <label>1</label>
                                    </span>
                                    <p>Note that the development build is not optimized.</p>
                                </div>
                            </div>
                            {!message && (
                                <div className="send_message_details send_message_hover">
                                    <div className="dialog_detail_borderbottom">
                                        <h6>Respond with arun</h6>
                                        <div className="max_scroll_limit">
                                            <div className="dialog_detail_borderbottom_span">
                                                <span>
                                                    <label>1</label>
                                                </span>
                                                <p>Note that the development build is not optimized.</p>
                                            </div>
                                            <div className="dialog_detail_borderbottom_span">
                                                <span>
                                                    <label>3</label>
                                                </span>
                                                <p>Note that the development build is not optimized.</p>
                                            </div>
                                            <div className="dialog_detail_borderbottom_span">
                                                <span>
                                                    <label>3</label>
                                                </span>
                                                <p>Note that the development build is not optimized.</p>
                                            </div>
                                            <div className="dialog_detail_borderbottom_span">
                                                <span>
                                                    <label>4</label>
                                                </span>
                                                <p>Note that the development build is not optimized.</p>
                                            </div>
                                            <div className="dialog_detail_borderbottom_span">
                                                <span>
                                                    <label>5</label>
                                                </span>
                                                <p>Note that the development build is not optimized.</p>
                                            </div>
                                        </div>
                                        <div className="add_respond" onClick={() => setMessage(!message)}>
                                            <span className="icon_add_respond">
                                                <DownArrowCircle />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )
            case 'user_details':
                return (
                    <>
                        <HeaderContent icon={<SlotsIcon />} header={'Verify User Details'} />
                        <div className="get_details_bottom">
                            <h6>Verify</h6>
                            <div className="get_detils_data">
                                <p>
                                    If user <label> Workativ-email</label> is available else ask user{' '}
                                    <label> Please provide a valid email id</label>
                                </p>
                            </div>
                        </div>
                    </>
                )
            case 'ask_question':
                return (
                    <>
                        <HeaderContent icon={<QaIcon />} header={'Ask a question'} />
                        <div className="get_details_bottom">
                            <h6>Ask user</h6>
                            <div className="ask_question">
                                <p>May I know your name?</p>
                            </div>
                        </div>
                    </>
                )

            case 'options':
                return (
                    <>
                        <HeaderContent icon={<OptionIcon />} header={'Options'} />
                        <h6>Prompt user</h6>
                        <div className="options_details">
                            <h6>Do you need anything else ?</h6>
                            <div className="options_details_button">
                                <button>Yes</button>
                                <button>No</button>
                            </div>
                        </div>
                    </>
                )

            case 'jump':
                return (
                    <>
                        <HeaderContent icon={<JumpIcon />} header={'Jump'} />
                        <h6>Then jump to</h6>
                        <div className="get_details_bottom">
                            <div className="jump_details">
                                <p>
                                    <label>Welcome</label> dialog and execute dialog
                                </p>
                            </div>
                        </div>
                    </>
                )
            case 'automation':
                return (
                    <>
                        <HeaderContent icon={<FlowIcon />} header={'Automation'} />
                        <h6>Execute workflow</h6>
                        <div className="automation_details">
                            <div className="get_detils_data">
                                <p>AD - Unlock Account</p>
                            </div>
                        </div>
                    </>
                )
            case 'agent_handover':
                return (
                    <>
                        <HeaderContent icon={<SubPanelIcons.AgentHandoff />} header={'Agent Handover'} />
                        <h6>Handover chat to</h6>
                        <div className="get_details_bottom">
                            <div className="jump_details">
                                <p>
                                    Agent in <label>Teams</label>
                                </p>
                            </div>
                        </div>
                    </>
                )
            case 'exit':
                return (
                    <>
                        <HeaderContent icon={<ExitIcon />} header={'Exit'} />
                        <h6>Exit without collecting feedback</h6>
                        <div className="get_details_bottom">
                            <div className="jump_details">
                                <p>See you :)</p>
                            </div>
                        </div>
                    </>
                )

            default:
                return null
        }
    }

    return (
        <section>
            <div className="flow_canvas1 dialog__header">
                <div className="flex_canvas1">
                    <div className="flex_menu1 intent_home flow_group_home">
                        <div className="indent_dialog_left">
                            <h2 className="">
                                <span className="buton_back inner_pages">
                                    <svg x="0px" y="0px" viewBox="0 0 31.5 31.5">
                                        <path d="M21.2 5c-.4-.4-1.1-.4-1.6 0-.4.4-.4 1.1 0 1.6l8 8H1.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h26.6l-8 8c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l10-10c.4-.4.4-1.1 0-1.6L21.2 5z" />
                                    </svg>{' '}
                                    Back
                                </span>
                                Unlock<span className="dialog_number">03</span>
                            </h2>
                        </div>
                    </div>
                    <div className="flex_side_menu1 intent_home flow_group_home">
                        <div className="intent_dialog_right">
                            <button className="btn btn-primary">
                                <IconViewICon />
                                <span>Icon View</span>
                            </button>
                            <button className="btn btn-primary">
                                <IconViewICon />
                                <span>Detail View</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <section className="dialog_editor dialog_detail">
                <ul className="tree zontal_lines">
                    <li className="first_add_node">
                        <label className="tree_label">
                            <div className="first">
                                <div className="first_parent_node">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('dialog')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <CenterDialog />
                                </div>

                                {/* <div className="respond_dialog_page">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('message')}
                                            <div className="add_respond">
                                                <span>+2</span>
                                            </div>
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div> */}
                                <div
                                    className="respond_dialog_page"
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('ask_question')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <CenterDialog />
                                </div>
                                <div
                                    className="respond_dialog_page"
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('user_details')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <CenterDialog />
                                </div>
                                <div
                                    className="respond_dialog_page"
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('automation')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div>

                                {/* <div className="respond_dialog_page">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('options')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div> */}

                                {/* <div className="respond_dialog_page">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('jump')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div> */}

                                {/* <div className="respond_dialog_page">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('agent_handover')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div> */}

                                {/* <div className="respond_dialog_page">
                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                        <div className="dialog_detail_parent_node">
                                            {returnUI('exit')}
                                            <HoverIcons />
                                        </div>
                                    </div>
                                    <NextDialog />
                                </div> */}
                            </div>
                        </label>
                        <ul className="dialog_detail_ul_parent">
                            <li>
                                <input type="checkbox" checked />
                                <label className="tree_label">
                                    <div className="first">
                                        <div className="first_parent_node">
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('dialog')}

                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <CenterDialog />
                                        </div>
                                        <div
                                            className="respond_dialog_page"
                                            onClick={e => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                        >
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('message')}
                                                    <div className="add_respond" onClick={() => setMessage(!message)}>
                                                        <span>+2</span>
                                                    </div>
                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <CenterDialog />
                                        </div>
                                        <div
                                            className="respond_dialog_page"
                                            onClick={e => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                        >
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('options')}
                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <NextDialog />
                                        </div>
                                    </div>
                                    {/* child dialogs */}
                                </label>
                                <ul className="dialog_detail_ul">
                                    <li>
                                        <input type="checkbox" checked />
                                        <label className="tree_label">
                                            <div className="first">
                                                <div className="first_parent_node">
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('dialog')}

                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                                <div
                                                    className="respond_dialog_page"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('jump')}
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                            </div>
                                        </label>
                                    </li>
                                    <li>
                                        <input type="checkbox" checked />
                                        <label className="tree_label">
                                            <div className="first">
                                                <div className="first_parent_node">
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('dialog')}

                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                                <div
                                                    className="respond_dialog_page"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('exit')}
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <input type="checkbox" checked />
                                <label className="tree_label">
                                    <div className="first">
                                        <div className="first_parent_node">
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('dialog')}

                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <CenterDialog />
                                        </div>
                                        <div
                                            className="respond_dialog_page"
                                            onClick={e => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                        >
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('message')}
                                                    <div className="add_respond">
                                                        <span>+2</span>
                                                    </div>
                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <CenterDialog />
                                        </div>
                                        <div
                                            className="respond_dialog_page"
                                            onClick={e => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                            }}
                                        >
                                            <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                <div className="dialog_detail_parent_node">
                                                    {returnUI('options')}
                                                    <HoverIcons />
                                                </div>
                                            </div>
                                            <NextDialog />
                                        </div>
                                    </div>
                                    {/* child dialogs */}
                                </label>
                                <ul className="dialog_detail_ul">
                                    <li>
                                        <input type="checkbox" checked />
                                        <label className="tree_label">
                                            <div className="first">
                                                <div className="first_parent_node">
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('dialog')}
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <CenterDialog />
                                                </div>
                                                <div
                                                    className="respond_dialog_page"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('agent_handover')}
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                            </div>
                                        </label>
                                    </li>
                                    <li>
                                        <input type="checkbox" checked />
                                        <label className="tree_label">
                                            <div className="first">
                                                <div className="first_parent_node">
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account dialog_detail_box_shadow">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('dialog')}

                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <CenterDialog />
                                                </div>
                                                <div
                                                    className="respond_dialog_page"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('message')}
                                                            <div className="add_respond">
                                                                <span>+2</span>
                                                            </div>
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <CenterDialog />
                                                </div>
                                                <div
                                                    className="respond_dialog_page"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <div className="col-md-12 parent_node parent_node_left dialog_detail_account">
                                                        <div className="dialog_detail_parent_node">
                                                            {returnUI('exit')}
                                                            <HoverIcons />
                                                        </div>
                                                    </div>
                                                    <NextDialog />
                                                </div>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </section>
    )
}

const RecogniseDialog = (props: { icon: any; headerTop: string; header: string; paragraph: string }) => {
    return (
        <>
            <div className="dialog_detail_parent_node">
                <div className="dialog_detail_header">
                    <div className="parent_node_no">
                        <span className="parent_svg">{props.icon}</span>
                    </div>
                    <h4 className="parent_h4">{props.headerTop}</h4>
                </div>
                <div className="border-bottom_flow" />
                <div className="dialog_detail_borderbottom">
                    <h6>{props.header}</h6>
                    <p>{props.paragraph}</p>
                </div>
            </div>
            <HoverIcons />
        </>
    )
}

const HoverIcons = () => {
    return (
        <>
            <div className="flow_buttons ">
                <div className="flow_buttons_icons ">
                    <div className="flow_buttons_icons_content">
                        <MoreIcon />
                    </div>
                    <div className="flow_buttons_icons_content">
                        <EditIcon />
                    </div>
                    <div className="flow_buttons_icons_content">
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        </>
    )
}

const CenterDialog = () => {
    const [hoverIcon, setHoverIcon] = useState(true)
    return (
        <div className="next_arrow_button">
            <div className="next_icon" onClick={() => setHoverIcon(!hoverIcon)}>
                <NextDetailICon />
                <div className="dialog_tree_next_hover">
                    <div className="child_node_button">
                        <div className="parent_node_no">
                            <span className="parent_svg">
                                <AddIcon />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {!hoverIcon ? (
                <div className="dialog_tree_next_toggle">
                    <div className={!hoverIcon ? 'dialog_tree_next_hover d-block' : 'dialog_tree_next_hover'}>
                        <div className="child_node_button">
                            <div className="parent_node_no">
                                <span className="parent_svg">
                                    <AddIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <>
                {!hoverIcon ? (
                    <div className="detail_center_dropdown">
                        <div className="dialog_tree_render dropdown_content plus_dropdown_list dialog_options_dynamic">
                            <div className="drpdown_dialog">
                                <div className="drodown_li_span">
                                    <span>
                                        <ResponseIcon />
                                    </span>
                                    <label>Send a Message</label>
                                </div>

                                <div className="drodown_li_span">
                                    <span>
                                        <QaIcon />
                                    </span>
                                    <label>Ask a Question</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <SlotsIcon />
                                    </span>
                                    <label>Verify User Details</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <AdaptiveIcon />
                                    </span>
                                    <label>Adaptive Card</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <VariableIcon />
                                    </span>
                                    <label>Create Custom Variables</label>
                                </div>

                                <div className="drodown_li_span">
                                    <span>
                                        <AttachmentIcon />
                                    </span>
                                    <label>Upload Attachment</label>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        </div>
    )
}

const NextDialog = () => {
    const [hoverIcon, setHoverIcon] = useState(true)
    return (
        <div className="next_arrow_button next_detail_dropdown">
            <div className="next_icon" onClick={() => setHoverIcon(!hoverIcon)}>
                <NextDetailICon />
                <div className="dialog_tree_next_hover">
                    <div className="child_node_button">
                        <div className="parent_node_no">
                            <span className="parent_svg">
                                <AddIcon />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {!hoverIcon ? (
                <div className="dialog_tree_next_toggle">
                    <div className={!hoverIcon ? 'dialog_tree_next_hover d-block' : 'dialog_tree_next_hover'}>
                        <div className="child_node_button">
                            <div className="parent_node_no">
                                <span className="parent_svg">
                                    <AddIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <>
                {!hoverIcon ? (
                    <div className="detail_last_dropdown ">
                        <div className="dialog_tree_render dropdown_content plus_dropdown_list dialog_options_dynamic">
                            <div className="drpdown_dialog">
                                <div className="drodown_li_span">
                                    <span>
                                        <ResponseIcon />
                                    </span>
                                    <label>Send a Message</label>
                                </div>

                                <div className="drodown_li_span">
                                    <span>
                                        <QaIcon />
                                    </span>
                                    <label>Ask a Question</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <SlotsIcon />
                                    </span>
                                    <label>Verify User Details</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <AdaptiveIcon />
                                    </span>
                                    <label>Adaptive Card</label>
                                </div>
                                <div className="drodown_li_span">
                                    <span>
                                        <VariableIcon />
                                    </span>
                                    <label>Create Custom Variables</label>
                                </div>

                                <div className="drodown_li_span">
                                    <span>
                                        <AttachmentIcon />
                                    </span>
                                    <label>Upload Attachment</label>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        </div>
    )
}
