const socketIOClient = require('socket.io-client')

let socket: any

export const initiateSocket = (value: any) => {
    // console.log('initialise socker')
    const { url, ...rest } = value
    socket = socketIOClient(url, { ...rest })
    return socket
}
export const disconnectSocket = () => {
    // console.log('disconnect called')
    if (socket) socket.disconnect()
}
export const subscribeToChat = (cb: any) => {
    if (!socket) {
    } else {
        // console.log('initialize socket', socket)
        socket.on('message', (msg: any) => {
            return cb(msg)
        })
        // socket.on('connect_failed', (err: any) => {
        //     console.log('socket error ::: connection failed', err)
        // })
        // socket.on('connect_error', (err: any) => {
        //     console.log(`socket error ::: connect_error due to`, err)
        // })
        // socket.on('disconnect', (err: any) => {
        //     console.log(`socket error ::: connect_error due to`, err)
        // })
    }
}

export const subscribeToChatWithErrorHandling = (cb: any, errorCallback?: any, restartCallback?: any) => {
    if (!socket) {
    } else {
        // console.log('initialize socket', socket)
        socket.on('message', (msg: any) => {
            return cb(msg)
        })
        socket.on('connection', () => {
            // console.log('connected ')
        })
        socket.on('connect_failed', () => {
            // console.log('connect_failed')
            errorCallback && errorCallback()
        })
        socket.on('connect_error', (err: any) => {
            // console.log('connect_error')
            errorCallback && errorCallback()
        })
        socket.on('disconnect', (reason: any) => {
            // console.log('disconneced', reason)
            // if (reason === 'io server disconnect') {
            //     // the disconnection was initiated by the server, you need to reconnect manually
            //     socket.connect()
            // } else {
            restartCallback && restartCallback()
            // }
        })

        socket.on('reconnect', (attemptNumber: any) => {
            // console.log('reconnect', attemptNumber)
            // ...
        })
        socket.on('reconnect_attempt', (attemptNumber: any) => {
            // console.log('reconnect_attempt', attemptNumber)
        })
        socket.on('ping', () => {
            // console.log('pinging')
        })
        socket.onclose = (reason: any) => {
            // console.error('onclose :: event', reason)
            // restartCallback && restartCallback()
        }
    }
}

export const subscribeToTypingEnd = (cb: any) => {
    if (!socket) {
    } else {
        socket.on('typingend', (msg: any) => {
            return cb(msg)
        })
    }
}

export const sendMessageToSocket = (message: any) => {
    if (socket) socket.emit('message', { message })
}

export const errorFromSocket = (message: any) => {
    if (socket) socket.on('connect_failed', { message })
}

export function findNode(id: string, currentNode: any): any {
    var i, currentChild, result

    if (id == currentNode.structValue.fields.text.structValue.style.stringValue) {
        return currentNode
    } else {
        // Use a for loop instead of forEach to avoid nested functions
        // Otherwise "return" will not work properly
        for (i = 0; i < currentNode.values.length; i += 1) {
            currentChild = currentNode.children[i]

            // Search in the current child
            result = findNode(id, currentChild)

            // Return the result if the node has been found
            if (result !== false) {
                return result
            }
        }

        // The node has not been found and we have no more options
        return false
    }
}
