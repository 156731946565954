/*eslint-disable*/
import * as React from 'react'
import { PageWrapper } from './Header'

type Loader = {
    PopupLoader: React.SFC<any>
    PageLoader: React.SFC<any>
    ErrorPage: React.SFC<any>
}
const Wrapper = PageWrapper as any

export const Loader: Loader = {
    PopupLoader: ({ show, className }) => show && <div id="popuploader" className={className ? className : ''} />,
    PageLoader: ({ show, className }) =>
        show && (
            <Wrapper extraClass="entity_cards_loader">
                <div className="preloader_center">
                    <div id="preloader" data-testid="PAGE-LOADER" className={className ? className : ''} />
                </div>
            </Wrapper>
        ),
    ErrorPage: ({ show }) => show && <div id="oops_loader" />
}
