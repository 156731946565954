import { format } from 'date-fns'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import styled from 'styled-components'

import { ButtonWrapper } from '@/DialogEditor/Home/BotMarketplaces/BotMarketplace'
import { getUsersAPI } from '@/ManageUsers/op.manageUsers'
import { User } from '@/ManageUsers/types.user'
import { DownArrowCircle } from '@/common/Icons/Icons'
import { Loader } from '@/common/components/Loader'
import { Modal } from '@/common/components/Modal'
import {
    Box,
    BoxOne,
    BoxRight,
    BoxWrapper,
    HeaderRight,
    InformationIcon,
    VisaImg
} from '@/common/styled/Subscription.Xtra.Dumb'
import {
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import stripeJs from '@stripe/stripe-js'

import visa_img from '@/common/images/visa.png'
import { logEvent, stripeGetApi, stripePost } from '@/common/utils/api-utils'
import {
    BillItemsV2,
    BillingContact,
    BillingEstimationContainer,
    BillingPeriodContainer,
    CardDetailsV2,
    CardExpiryDetailsV2,
    CardHolderDetailsV2,
    CardNumberV2,
    CardTypeV2,
    CardUserV2,
    DateWrapper,
    MonthlySubscriptionV2,
    SwitchPayment,
    TableContainerV2,
    UserDetails as UserDetailsV2
} from './AccountAndBillingv2'
import { formatStripeDate, toBillingEstimateV2 } from './AccountEstimateV2'
import { Logsspan } from './BillingEstimatePage'
import { BillingWrapper as BillingWrapperV2, OverviewPlanV2 } from './BillingOverview-v2'
import { Container, Header, HeaderName, Wrapper as WrapperH } from './Headers'
import { ContainerV2, CurrentPlanHeader, PlansHeaderV2, WrapperV2 } from './Plans-v2'
import {
    BillingEstimateWrapper,
    SubscriptionWrapperH,
    UserDetails,
    loadCurrentSubscriptionData,
    toBillingEstimate
} from './Subscription'
import { PaymentMethod } from './SubsriptionResponse'
import {
    ActionT,
    ErrorPage,
    PageStateT,
    ResourceConsumptionForDisplay,
    ResourceDetails,
    ResourceDisplay,
    TrialStatus
} from './kind'
import { getStripe, stripeUTCDate } from './util-stripe'

export const NoCreditCard = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Medium';
    font-size: 14px;
    color: #494949;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 75px;
    margin: auto;
    text-align: center;
    vertical-align: top;
    margin-top: 47px;
`

const Wrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;

    button:first-child{
        margin-right: 20px;
    }
`
const Plan = styled.div`
    width: 100%;
    float: left;
    margin-top: 15px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #494949;
    ${Logsspan} {
        float: unset;
    }
`

const PlanType = styled.div`
    width: 100%;
    float: left;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    color: #494949;
`

const Description = styled.p`
    width: 100%;
    float: left;
    margin-bottom: 0px;
`

const PaymentWrapper = styled.div`
    width: 60%;
    float: left;
    border: 1px solid #999;
    border-radius: 6px;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
`
const CardDetails = styled.div`
    width: 100%;
    float: left;
`
const CardNumber = styled.div`
    width: 80%;
    float: left;
    font-size: 16px;
    font-family: 'Gordita-Regular';
    color: #494949;
`
const EditIconSvg = styled.div`
    width: 20%;
    float: left;
    display: flex;
    justify-content: flex-end;
`

const CardExpiryDetails = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
`
const CardHolderDetails = styled.div`
    width: 80%;
    float: left;
`
const CardUser = styled.div`
    width: 80%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 13px;
    color: #494949;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
`
const CardType = styled.div`
    width: 20%;
    float: left;
    display: flex;
    justify-content: flex-end;
`

const FormWrapper = styled.div`
    width: 100%;
    float: left;
`
const FieldName = styled.div`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #494949;
    margin-top: 15px;
    display: flex;
    align-items: center;
`
const InputField = styled.input`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 13px;
    color: #494949;
    border-radius: 4px;
    height: 30px;
    border: 1px solid #dbd7d7;
    margin-bottom: 5px;
    padding: 0px 10px;
    background: #f5f7fe;
`

export const Button = styled.button`
    min-width: 90px;
    height: 32px;
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    padding: 6px 12px;
    font-family: 'Gordita-Medium';
    color: ${props => (props.primary ? '#333' : '#fff')};
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;

    :hover {
        background: #ddfeef;
        color: #333;
        border: 1px solid #ddfeef;

        svg {
            color: ${props => (props.primary ? '#333' : '#333')};
            fill: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 12px;
        height: 12px;
        float: left;
        margin-right: 6px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 12px;
            height: 12px;
            color: ${props => (props.primary ? '#333' : '#fff')};
            fill: ${props => (props.primary ? '#333' : '#fff')};
            margin-right: 0px;
        }
    }
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
`
export const ButtonS = styled.button`
    min-width: 90px;
    height: 32px;
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    padding: 6px 12px;
    font-family: 'Gordita-Medium';
    color: ${props => (props.primary ? '#333' : '#fff')};
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;

    :hover {
        background: #ddfeef;
        color: ${props => (props.primary ? '#333' : '#333')};
        border: 1px solid #ffe5d3;

        label {
            color: ${props => (props.primary ? '#333' : '#333')};
        }

        svg {
            color: ${props => (props.primary ? '#333' : '#333')};
            fill: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#ffe5d3' : '#ffe5d3')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 12px;
        height: 12px;
        float: left;
        margin-right: 6px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 12px;
            height: 12px;
            color: ${props => (props.primary ? '#333' : '#fff')};
            fill: ${props => (props.primary ? '#333' : '#fff')};
            margin-right: 0px;
        }
    }
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
        color: ${props => (props.primary ? '#333' : '#fff')};
    }
`

const DropdownIcon = styled.span`
    width: 15px;
    height: 15px;
    float: left;
    cursor: pointer;
    position: relative;
    left: -25px;
    top: 2px;
    fill: #494949;
`
const DropdownWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
`
const DropdownUl = styled.ul`
    width: 60%;
    float: left;
    background: #fff;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    position: absolute;
    z-index: 1;
    display: none;
`
const DropdownLi = styled.li`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 14px;
    color: #494949;
    height: 30px;
    list-style: none;
    border-bottom: 1px solid #ccc;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    :hover {
        background: #0a89e8;
        border-bottom: 1px solid #0a89e8;
        color: #fff;
    }
    :last-child {
        border-bottom: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    :first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
`

const WidthAutoWrapper = styled.span`
    width: auto;
    float: left;
`
const Form = styled.form`
    width: 100%;
    float: left;
    font-family: 'Gordita-Regular';
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    padding: 5px 20px 15px 20px;
    background: #fff;
`
const SubscriptionWrapper = styled.div`
    width: 100%;
    float: left;
    ${Logsspan}:first-child {
        font-family: 'Gordita-Regular';
        color: #494949;
        font-size: 13px;
    }

    ${Logsspan} {
        width: 100%;
        float: left;
        &:nth-child(2) {
            color: red;
            font-size: 12px;
            color: #657993;
            font-family: 'Gordita-Regular';
        }
        &:nth-child(4) {
            color: red;
            font-size: 11px;
            color: #657993;
            font-family: 'Gordita-Regular';
        }
    }
`

function useResponsiveFontSize() {
    const getFontSize = () => (window.innerWidth < 450 ? '14px' : '14px')
    const [fontSize, setFontSize] = useState(getFontSize)

    useEffect(() => {
        const onResize = () => {
            setFontSize(getFontSize())
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    return fontSize
}

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#494949',
                    fontFamily: 'Gordita-Regular',

                    '::placeholder': {
                        color: '#494949',
                        fontFamily: 'Gordita-Regular'
                    }
                },
                invalid: {
                    color: 'red',
                    fontFamily: 'Gordita-Regular'
                },
                complete: {
                    color: 'green',
                    fontFamily: 'Gordita-Regular'
                }
            }
        }),
        [fontSize]
    )

    return options
}

const CheckoutForm = () => {
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (elements != null) {
            const card = elements.getElement(CardElement)

            if (stripe && card != null) {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card
                })
                console.log(paymentMethod)
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
        </form>
    )
}
type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any) => Promise<infer U>
    ? U
    : T extends (...args: any) => infer U
    ? U
    : T

type CardComplete = {
    cardValid: boolean
    cvvValid: boolean
    expiryDateValid: boolean
}

type CardValidActions = 'card_number::complete' | 'expiry_date::complete' | 'cvv::complete'
type CardInValidActions = 'card_number::incomplete' | 'expiry_date::incomplete' | 'cvv::incomplete'

function reducerCards(state: CardComplete, action: { type: CardValidActions | CardInValidActions }): CardComplete {
    switch (action.type) {
        case 'card_number::complete':
            return { ...state, cardValid: true }
        case 'card_number::incomplete':
            return { ...state, cardValid: false }
        case 'expiry_date::complete':
            return { ...state, cvvValid: true }
        case 'expiry_date::incomplete':
            return { ...state, cvvValid: false }
        case 'cvv::complete':
            return { ...state, expiryDateValid: true }
        case 'cvv::incomplete':
            return { ...state, expiryDateValid: false }
    }
}

type CardActions = 'ChangeCard' | 'None'

export enum PaymentDetailsActions {
    CancelSubscription = 'CancelSubscription',
    EnableSubscription = 'EnableSubscription',
    LoadingInPopup = 'LoadingInPopup'
}

export type PaymentBillingActions = CancelSubscriptionT | LoadingT | EnableSubscriptionT

export type CancelSubscriptionT = {
    mode: PaymentDetailsActions.CancelSubscription
    workspace: string
    currentEndDate: Date
}

export type EnableSubscriptionT = {
    mode: PaymentDetailsActions.EnableSubscription
}
export type LoadingT = {
    mode: PaymentDetailsActions.LoadingInPopup
}
export const Loading: LoadingT = {
    mode: PaymentDetailsActions.LoadingInPopup
}

const isCancellableStatus = (status: string) => {
    return !['scheduled_for_cancellation'].includes(status)
}

export const PaymentDetails = (props: any) => {
    const { workspacename } = props.match.params
    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    const [paymentDetailActions, setActions] = useState<CardActions | PaymentBillingActions>('None')
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const [userList, setUserList] = useState<User[]>([])
    const [subscriptionStatus, setStatus] = useState<string>('')
    // console.log('props', props)

    useEffect(() => {
        getUsersAPI(workspacename).then((users: User[]) => {
            setUserList(users)
        })
    }, [])

    const [validCard, dispatchCardValidity] = useReducer(reducerCards, {
        cardValid: false,
        cvvValid: false,
        expiryDateValid: false
    })

    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    useEffect(() => {
        if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
            setStatus(PageState.subscription.subscription.status)
        }
    }, [PageState])

    console.log('sd', PageState)

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }
    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        const card: PaymentMethod | undefined =
            PageState.subscription.subscription.stripeSubscription.paymentMethods.length > 0
                ? PageState.subscription.subscription.stripeSubscription.paymentMethods[0]
                : undefined

        const productSubscriptionId = PageState.subscription.subscription.productSubscriptionId
        const addOnSubscriptionId = PageState.subscription.subscription.addOnSubscriptionId
        const planBillingPeriod = PageState.subscription.planDetails.billed

        const stripeProductSubscriptionData = PageState.subscription.subscription.stripeSubscription.subscription.find(
            x => x.id === productSubscriptionId
        )

        const customer = PageState.subscription.subscription.stripeSubscription.customerId
        const upComingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const email = PageState.subscription.subscription.stripeSubscription.email
        const user = userList.find(user => user.email === email)
        const name = user ? `${user.first_name} ${user.last_name}` : 'Unknown user'
        const emailToShow = user ? user.email : 'unknown@unknown.un'

        const subsriptionCreatedDate = stripeUTCDate(stripeProductSubscriptionData.created)
        // const subsriptionCreatedDate = stripeUTCDate(stripeProductSubscriptionData.)
        const setCancelMode = () => {
            setActions({
                mode: PaymentDetailsActions.CancelSubscription,
                workspace: workspacename,
                currentEndDate: stripeUTCDate(stripeProductSubscriptionData.current_period_end)
            })
        }

        const setReenableSubscription = () => {
            setActions({
                mode: PaymentDetailsActions.EnableSubscription
            })
        }

        const goChangePlan = (planType?: string) => {
            props.history.push(
                `/workspace/${workspacename}/billing/plans?from=paymentDetails&planType=${
                    planType ? planType.toLowerCase() : planBillingPeriod.toLowerCase()
                }`
            )
        }

        const cancelSubscriptionFn = (workspace: string) => {
            stripePost(`${workspace}/cancel`)({}).then(_ => {
                setPageState({ action: ActionT.Loading })
                window.location.reload()
            })
        }
        const reenableSubscriptionFn = () => {
            stripePost(`${workspacename}/revoke-cancellation`)({}).then(_ => {
                setPageState({ action: ActionT.Loading })
                window.location.reload()
            })
        }

        const createAndUpdatePaymentMethod = async (event: any) => {
            event.preventDefault()
            console.log('elements', elements)
            if (elements != null) {
                const card = elements.getElement(CardNumberElement)
                if (stripe && card != null) {
                    stripeGetApi(`${workspacename}/payment/intent/generate`)
                        .then(
                            data =>
                                data.output as {
                                    client_secret: string
                                }
                        )
                        .then(async ({ client_secret }) => {
                            const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
                                payment_method: {
                                    card: card,
                                    billing_details: {
                                        name: '-'
                                    }
                                }
                            })
                            const paymentMethod = setupIntent ? setupIntent.payment_method : null
                            if (paymentMethod) {
                                if (productSubscriptionId === addOnSubscriptionId) {
                                    return stripePost(`${workspacename}/payment-method/update`)({
                                        subscriptionId: productSubscriptionId,
                                        customerId: customer,
                                        paymentMethodId: paymentMethod
                                    }).then(_ => {
                                        window.location.reload()
                                    })
                                } else {
                                    return Promise.all([
                                        stripePost(`${workspacename}/payment-method/update`)({
                                            subscriptionId: productSubscriptionId,
                                            customerId: customer,
                                            paymentMethodId: paymentMethod
                                        }),
                                        stripePost(`${workspacename}/payment-method/update`)({
                                            subscriptionId: addOnSubscriptionId,
                                            customerId: customer,
                                            paymentMethodId: paymentMethod
                                        })
                                    ]).then(_ => {
                                        window.location.reload()
                                    })
                                }
                            }
                            if (error) {
                                throw error
                            }
                            return null
                        })
                        .then(console.log)
                }
            }
        }
        const checkCardValidity = async (event: stripeJs.StripeCardNumberElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'card_number::complete' })
            } else {
                dispatchCardValidity({ type: 'card_number::incomplete' })
            }
        }
        const checkExpiryDataValidity = async (event: stripeJs.StripeCardExpiryElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'expiry_date::complete' })
            } else {
                dispatchCardValidity({ type: 'expiry_date::incomplete' })
            }
        }
        const checkCvvValidity = async (event: stripeJs.StripeCardCvcElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'cvv::complete' })
            } else {
                dispatchCardValidity({ type: 'cvv::incomplete' })
            }
        }

        const dieplaySubscriptionDetails = () => {
            if (subscriptionStatus === 'canceled') {
                return (
                    <SubscriptionWrapper>
                        <Logsspan>Your subscription was cancelled on</Logsspan>
                        <Logsspan dateWrapper>
                            {format(stripeUTCDate(stripeProductSubscriptionData.canceled_at), 'do MMM yyyy')}
                        </Logsspan>
                        <Logsspan primary secondary onClick={() => goChangePlan('monthly')}>
                            Create Subscription
                        </Logsspan>
                    </SubscriptionWrapper>
                )
            } else if (isCancellableStatus(subscriptionStatus)) {
                return (
                    <SubscriptionWrapper>
                        <Logsspan>Active Since</Logsspan>
                        <Logsspan dateWrapper>{format(subsriptionCreatedDate, 'd MMM yyyy')}</Logsspan>
                        <Logsspan primary secondary onClick={setCancelMode}>
                            Cancel Subscription
                        </Logsspan>
                    </SubscriptionWrapper>
                )
            } else if (subscriptionStatus === 'scheduled_for_cancellation') {
                return (
                    <SubscriptionWrapper>
                        <Logsspan>Active Since</Logsspan>
                        <Logsspan dateWrapper>{format(subsriptionCreatedDate, 'd MMM yyyy')}</Logsspan>
                        <Logsspan primary secondary onClick={setReenableSubscription}>
                            Enable Subscription
                        </Logsspan>
                        <Logsspan>
                            subscription will be cancelled on{' '}
                            {format(stripeUTCDate(stripeProductSubscriptionData.cancel_at), 'do MMM yyyy')}
                        </Logsspan>
                    </SubscriptionWrapper>
                )
            }
            return null
        }

        return (
            <React.Fragment>
                {typeof paymentDetailActions != 'string' &&
                paymentDetailActions.mode === PaymentDetailsActions.CancelSubscription ? (
                    <Modal>
                        <h2>Cancel subscription</h2>
                        <p>
                            Subscription will be cancelled on{' '}
                            {format(paymentDetailActions.currentEndDate, 'Do MMMM YYYY')}. You can always enable
                            subscription before cancellation date.
                        </p>
                        <div>
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    cancelSubscriptionFn(paymentDetailActions.workspace)
                                    // console.log('cancel')
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn-success"
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setActions('None')
                                }}
                            >
                                No
                            </button>
                        </div>
                    </Modal>
                ) : null}
                {typeof paymentDetailActions != 'string' &&
                paymentDetailActions.mode === PaymentDetailsActions.EnableSubscription ? (
                    <Modal>
                        <h2>Enable cancelled subscription</h2>
                        <p>The cancellation request for the subscription will be revoked.Do you want to proceed?</p>
                        <div>
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    reenableSubscriptionFn()
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn-success"
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setActions('None')
                                }}
                            >
                                No
                            </button>
                        </div>
                    </Modal>
                ) : null}
                <WrapperH>
                    <Header>
                        <Container>
                            <span className="flow_canvas_header_back_button">
                                <span className="buton_back" onClick={() => window.history.back()}>
                                    <DownArrowCircle />
                                </span>
                                <HeaderName>Account & Billing</HeaderName>
                            </span>
                        </Container>
                    </Header>
                </WrapperH>

                <DetialsWrapper>
                    <BoxWrapper primary>
                        <Container>
                            <Box primary>
                                <BoxRight width={6.5} secondary>
                                    <HeaderRight>Billing Contact</HeaderRight>
                                    <UserDetails name={name} email={emailToShow}></UserDetails>
                                    <Wrapper>
                                        <HeaderRight>Billing Type</HeaderRight>
                                        <PlanType>{planBillingPeriod}</PlanType>
                                    </Wrapper>
                                    <Description>
                                        {planBillingPeriod === 'Monthly' ? (
                                            <Logsspan primary secondary onClick={() => goChangePlan('yearly')}>
                                                Switch to annual payment
                                            </Logsspan>
                                        ) : null}
                                    </Description>
                                    {/* {console.log('paymentDetailActions', paymentDetailActions)} */}
                                    {paymentDetailActions != 'ChangeCard' ? (
                                        card != undefined ? (
                                            <>
                                                <Plan>
                                                    Payment Method{' '}
                                                    <Logsspan
                                                        primary
                                                        secondary
                                                        onClick={() => setActions('ChangeCard')}
                                                    >
                                                        Change Card
                                                    </Logsspan>
                                                </Plan>
                                                <PaymentWrapper>
                                                    <CardDetails>
                                                        <CardNumber>{`**** **** **** ${card.card.last4}`}</CardNumber>
                                                    </CardDetails>
                                                    <CardExpiryDetails>
                                                        <CardHolderDetails>
                                                            <CardUser>{card.billing_details.name}</CardUser>
                                                            <CardUser>
                                                                Expires:
                                                                {card.card.exp_month.toLocaleString(undefined, {
                                                                    minimumIntegerDigits: 2
                                                                })}
                                                                /{card.card.exp_year}
                                                            </CardUser>
                                                        </CardHolderDetails>
                                                        <CardType>
                                                            <VisaImg src={visa_img}></VisaImg>
                                                        </CardType>
                                                    </CardExpiryDetails>
                                                </PaymentWrapper>{' '}
                                            </>
                                        ) : (
                                            <>
                                                <Plan>
                                                    Payment Method{' '}
                                                    <Logsspan
                                                        primary
                                                        secondary
                                                        onClick={() => setActions('ChangeCard')}
                                                    >
                                                        Change Card
                                                    </Logsspan>
                                                </Plan>
                                                <PaymentWrapper>
                                                    <NoCreditCard> No Credit card details found</NoCreditCard>
                                                </PaymentWrapper>{' '}
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <Wrapper></Wrapper>
                                            <FormWrapper>
                                                <Form onSubmit={createAndUpdatePaymentMethod}>
                                                    <FieldName>Card number</FieldName>
                                                    <CardNumberElement
                                                        options={options}
                                                        onReady={() => {
                                                            console.log('CardNumberElement [ready]')
                                                        }}
                                                        onChange={checkCardValidity}
                                                    />
                                                    <FieldName>Expiration date</FieldName>
                                                    <CardExpiryElement
                                                        options={options}
                                                        onReady={() => {
                                                            console.log('CardExpiry [ready]')
                                                        }}
                                                        onChange={checkExpiryDataValidity}
                                                    />
                                                    <FieldName>CVC</FieldName>
                                                    <CardCvcElement
                                                        options={options}
                                                        onReady={() => {
                                                            console.log('CardCvc [ready]')
                                                        }}
                                                        onChange={checkCvvValidity}
                                                    />
                                                    <Wrapper>
                                                        <Button
                                                            type="submit"
                                                            disabled={
                                                                !(
                                                                    validCard.cvvValid &&
                                                                    validCard.cardValid &&
                                                                    validCard.expiryDateValid
                                                                )
                                                            }
                                                        >
                                                            <label>Update</label>
                                                            
                                                        </Button>
                                                        <Button
                                                            type="cancel"
                                                            onClick={() => {
                                                                setActions('None')
                                                            }}
                                                        >
                                                            <label>Cancel</label>
                                                            
                                                        </Button>
                                                    </Wrapper>
                                                </Form>
                                            </FormWrapper>
                                        </>
                                    )}
                                    {dieplaySubscriptionDetails()}
                                </BoxRight>
                                <BoxOne BoxWidth={3.1} BoxMarginRight="0px;">
                                    <BillingEstimateWrapper
                                        estimate={toBillingEstimate(upComingInvoice)}
                                        goEstimatePage={() =>
                                            props.history.push(`/workspace/${workspacename}/billing/estimate`)
                                        }
                                    />
                                </BoxOne>
                            </Box>
                        </Container>
                    </BoxWrapper>
                </DetialsWrapper>
            </React.Fragment>
        )
    }
    return <div> UnHandled </div>
}

export const BillingContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 410px 1fr;
    grid-gap: 20px;
    padding: 30px 20px 0px;
    justify-content: flex-start;
    height: calc(100vh - 300px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
        display: block;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        height: 20px;
        border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
        background: #fff;
    }

    ${BillingContact} {
        ${CurrentPlanHeader} {
            padding: 20px 20px 15px 20px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 0px;

            // @media (max-width: 1700px) {
            //     padding: 15px 20px 10px 20px;
            // }
            // @media (max-width: 1500px) {
            //     padding: 15px 20px 10px 20px;
            // }
        }
    }
    ${BillingPeriodContainer} {
        ${CurrentPlanHeader} {
            padding: 0px;
            border-bottom: none;
            margin-bottom: 0px;
        }
    }

    @media (max-width: 1700px) {
        padding: 20px 20px 0px;
        height: calc(100vh - 300px);
        overflow-y: scroll;
        margin-bottom: 20px;
    }
    @media (max-width: 1600px) {
        overflow-y: scroll;
        padding: 20px 20px 0px;
        height: calc(100vh - 280px);
        margin-bottom: 20px;
    }
`

export const BodyWrapperV2 = styled.section`
    width: 100%;
    float: left;
    padding: 0px 20px 20px 20px;
    height: ${props => (props.scrollHeight ? 'calc(100vh - 100px)' : 'calc(100vh - 100px)')};
    overflow-y: ${props => (props.overflowScroll ? 'inherit' : 'scroll')};
    padding-top: 20px;
`

export const PaymentDetailsV2 = (props: any) => {
    const { workspacename } = props.match.params
    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    const [paymentDetailActions, setActions] = useState<CardActions | PaymentBillingActions>('None')
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const [userList, setUserList] = useState<User[]>([])
    const [subscriptionStatus, setStatus] = useState<string>('')
    // console.log('props', props)

    useEffect(() => {
        getUsersAPI(workspacename).then((users: User[]) => {
            setUserList(users)
        })
    }, [])

    const [validCard, dispatchCardValidity] = useReducer(reducerCards, {
        cardValid: false,
        cvvValid: false,
        expiryDateValid: false
    })

    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    useEffect(() => {
        if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
            setStatus(PageState.subscription.subscription.status)
        }
    }, [PageState])

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }
    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        const card: PaymentMethod | undefined =
            PageState.subscription.subscription.stripeSubscription.paymentMethods.length > 0
                ? PageState.subscription.subscription.stripeSubscription.paymentMethods[0]
                : undefined

        const productSubscriptionId = PageState.subscription.subscription.productSubscriptionId
        const addOnSubscriptionId = PageState.subscription.subscription.addOnSubscriptionId
        // const planBillingPeriod = PageState.subscription.planDetails.billed

        const upcomingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const planBillingPeriod = PageState.subscription.planDetails.billed
        const invoices = PageState.subscription.subscription.stripeSubscription.invoice
        const currentPlanType = PageState.subscription.planDetails.billed

        const planName = PageState.subscription.displayName
        const currentCycleStartDate = stripeUTCDate(PageState.subscription.subscription.current_period_start)
        const currentCycleEndDate = stripeUTCDate(PageState.subscription.subscription.current_period_end)
        // console.log('upcomingInvoice', upcomingInvoice)

        const detailsToDisplay = {
            planName: PageState.subscription.displayName,
            startDate: formatStripeDate(upcomingInvoice.period_start),
            endDate: formatStripeDate(upcomingInvoice.period_end),
            toPay: upcomingInvoice.amount_due / 100
        }

        const resourceWithDisplayDetails: (ResourceDisplay &
            ResourceConsumptionForDisplay)[] = PageState.subscription.resourceConsumption
            .map(resource => {
                const displayDetails = ResourceDetails.find(displayDetail => displayDetail.name === resource.id)
                if (displayDetails === undefined) {
                    return undefined
                }

                return {
                    ...resource,
                    ...displayDetails
                }
            })
            .filter(x => x !== undefined && (x.name === 'SESSION' || x.name === 'AUTOMATION'))
            .filter((item): item is ResourceDisplay & ResourceConsumptionForDisplay => !!item)

        const addOnAmount = resourceWithDisplayDetails
            .map(resource => ({
                exceeded: Math.max(parseInt(resource.consumed) - resource.resourceLimit, 0)
            }))
            .reduce((acc, curr) => acc + curr.exceeded * 0.1, 0)

        const gotoPlans = () => {
            // console.log('planTYpe', planType)
            props.history.push(`/workspace/${workspacename}/billing/plans`)
        }

        const stripeProductSubscriptionData = PageState.subscription.subscription.stripeSubscription.subscription.find(
            x => x.id === productSubscriptionId
        )

        const customer = PageState.subscription.subscription.stripeSubscription.customerId
        const upComingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const email = PageState.subscription.subscription.stripeSubscription.email
        const user = userList.find(user => user.email === email)
        const name = user ? `${user.first_name} ${user.last_name}` : 'Unknown user'
        const emailToShow = user ? user.email : 'unknown@unknown.un'
        const toPlanEstimate = toBillingEstimateV2(upcomingInvoice)
        const subsriptionCreatedDate = stripeProductSubscriptionData && stripeUTCDate(stripeProductSubscriptionData.created)
        // const subsriptionCreatedDate = stripeUTCDate(stripeProductSubscriptionData.)
        const setCancelMode = () => {
            setActions({
                mode: PaymentDetailsActions.CancelSubscription,
                workspace: workspacename,
                currentEndDate: stripeUTCDate(stripeProductSubscriptionData.current_period_end)
            })
        }

        const setReenableSubscription = () => {
            setActions({
                mode: PaymentDetailsActions.EnableSubscription
            })
        }

        const goChangePlan = (planType?: string) => {
            props.history.push(
                `/workspace/${workspacename}/billing/plans?from=paymentDetails&planType=${
                    planType ? planType.toLowerCase() : planBillingPeriod.toLowerCase()
                }`
            )
        }

        const cancelSubscriptionFn = (workspace: string) => {
            stripePost(`${workspace}/cancel`)({}).then(_ => {
                setPageState({ action: ActionT.Loading })
                logEvent(workspace, {
                    event:"EVENT::BILLING::UNSUBSCRIBE",event_meta:{}
                }).finally(()=> {
                    window.location.reload()
                })
            })
        }
        const reenableSubscriptionFn = () => {
            stripePost(`${workspacename}/revoke-cancellation`)({}).then(_ => {
                setPageState({ action: ActionT.Loading })
                window.location.reload()
            })
        }

        const createAndUpdatePaymentMethod = async (event: any) => {
            event.preventDefault()
            console.log('elements', elements)
            if (elements != null) {
                const card = elements.getElement(CardNumberElement)
                if (stripe && card != null) {
                    stripeGetApi(`${workspacename}/payment/intent/generate`)
                        .then(
                            data =>
                                data.output as {
                                    client_secret: string
                                }
                        )
                        .then(async ({ client_secret }) => {
                            const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
                                payment_method: {
                                    card: card,
                                    billing_details: {
                                        name: '-'
                                    }
                                }
                            })
                            const paymentMethod = setupIntent ? setupIntent.payment_method : null
                            if (paymentMethod) {
                                if (productSubscriptionId === addOnSubscriptionId) {
                                    return stripePost(`${workspacename}/payment-method/update`)({
                                        subscriptionId: productSubscriptionId,
                                        customerId: customer,
                                        paymentMethodId: paymentMethod
                                    }).then(_ => {
                                        window.location.reload()
                                    })
                                } else {
                                    return Promise.all([
                                        stripePost(`${workspacename}/payment-method/update`)({
                                            subscriptionId: productSubscriptionId,
                                            customerId: customer,
                                            paymentMethodId: paymentMethod
                                        }),
                                        stripePost(`${workspacename}/payment-method/update`)({
                                            subscriptionId: addOnSubscriptionId,
                                            customerId: customer,
                                            paymentMethodId: paymentMethod
                                        })
                                    ]).then(_ => {
                                        window.location.reload()
                                    })
                                }
                            }
                            if (error) {
                                throw error
                            }
                            return null
                        })
                        .then(console.log)
                }
            }
        }
        const checkCardValidity = async (event: stripeJs.StripeCardNumberElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'card_number::complete' })
            } else {
                dispatchCardValidity({ type: 'card_number::incomplete' })
            }
        }
        const checkExpiryDataValidity = async (event: stripeJs.StripeCardExpiryElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'expiry_date::complete' })
            } else {
                dispatchCardValidity({ type: 'expiry_date::incomplete' })
            }
        }
        const checkCvvValidity = async (event: stripeJs.StripeCardCvcElementChangeEvent) => {
            if (event.complete) {
                dispatchCardValidity({ type: 'cvv::complete' })
            } else {
                dispatchCardValidity({ type: 'cvv::incomplete' })
            }
        }
        const displaySubscriptionDetails = () => {
            if (subscriptionStatus === 'canceled') {
                return (
                    <>
                        <MonthlySubscriptionV2>Your subscription was cancelled on</MonthlySubscriptionV2>
                        <CardNumberV2 marginTop={'10px'}>
                            {format(stripeUTCDate(stripeProductSubscriptionData.canceled_at), 'ddd , Do MMM YYYY')}
                        </CardNumberV2>
                        <MonthlySubscriptionV2 fontBold onClick={() => goChangePlan('monthly')}>
                            Create Subscription
                        </MonthlySubscriptionV2>
                    </>
                )
            } else if (isCancellableStatus(subscriptionStatus)) {
                return (
                    <>
                        <MonthlySubscriptionV2>Active Since</MonthlySubscriptionV2>
                        <CardNumberV2 marginTop={'10px'}>{format(subsriptionCreatedDate, 'D MMM YYYY')}</CardNumberV2>
                        <MonthlySubscriptionV2 fontBold onClick={setCancelMode}>
                            Cancel Subscription
                        </MonthlySubscriptionV2>
                    </>
                )
            } else if (subscriptionStatus === 'scheduled_for_cancellation') {
                return (
                    <SubscriptionWrapper>
                        <MonthlySubscriptionV2>Active Since</MonthlySubscriptionV2>
                        <CardNumberV2 marginTop={'10px'}>{format(subsriptionCreatedDate, 'D MMM YYYY')}</CardNumberV2>
                        <MonthlySubscriptionV2 fontBold onClick={setReenableSubscription}>
                            Enable Subscription
                        </MonthlySubscriptionV2>
                        <MonthlySubscriptionV2 onClick={setReenableSubscription}>
                            Subscription will be cancelled on{' '}
                            {format(stripeUTCDate(stripeProductSubscriptionData.cancel_at), 'Do MMMM YYYY')}
                        </MonthlySubscriptionV2>
                    </SubscriptionWrapper>
                )
            }
            return null
        }

        return (
            <React.Fragment>
                {typeof paymentDetailActions != 'string' &&
                paymentDetailActions.mode === PaymentDetailsActions.CancelSubscription ? (
                    <Modal>
                        <h2>Cancel subscription</h2>
                        <p>
                            Subscription will be cancelled on{' '}
                            {format(paymentDetailActions.currentEndDate, 'Do MMMM YYYY')}. You can always enable
                            subscription before cancellation date.
                        </p>
                        <ButtonWrapper style={{ marginTop: '5px' }}>
                            <Button
                                primary
                                style={{ margin: '0px', marginRight: '10px' }}
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setActions('None')
                                }}
                            >
                                <label className="otp_popup_label">No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => cancelSubscriptionFn(paymentDetailActions.workspace)}
                            >
                                <label className="otp_popup_label">Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </Modal>
                ) : null}
                {typeof paymentDetailActions != 'string' &&
                paymentDetailActions.mode === PaymentDetailsActions.EnableSubscription ? (
                    <Modal>
                        <h2>Enable cancelled subscription</h2>
                        <p>The cancellation request for the subscription will be revoked.Do you want to proceed?</p>
                        <ButtonWrapper style={{ marginTop: 0 }}>
                            <Button
                                style={{ marginRight: '10px' }}
                                primary
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    setActions('None')
                                }}
                            >
                                <label style={{ margin: 'auto' }}>No</label>
                            </Button>
                            <Button
                                type="button"
                                data-dismiss="modal"
                                onClick={() => {
                                    reenableSubscriptionFn()
                                }}
                            >
                                <label style={{ margin: 'auto' }}>Yes</label>
                            </Button>
                        </ButtonWrapper>
                    </Modal>
                ) : null}
                <div className="plansWrapper">
                    <PlansHeaderV2 backButton name={'Account and Billing'} goBack={() => window.history.back()} />
                    <div className="current_plan_wrapper">
                        <BodyWrapperV2 overflowScroll>
                            <WrapperV2>
                                <ContainerV2>
                                    <BillingWrapperV2>
                                        <OverviewPlanV2
                                            currentPlan={planName}
                                            currentPeriodEnd={currentCycleEndDate}
                                            currentPeriodStart={currentCycleStartDate}
                                            gotoPlans={gotoPlans}
                                            pricing={{ tag: 'NONE' }}
                                            planReferenceName={PageState.subscription.referenceName}
                                        />
                                        <BillingContainer>
                                            <BillingContact>
                                                <CurrentPlanHeader>Billing Contacts</CurrentPlanHeader>
                                                <UserDetailsV2 name={name} email={emailToShow} />
                                                <BillingPeriodContainer>
                                                    <CurrentPlanHeader>Billing Period </CurrentPlanHeader>
                                                    <MonthlySubscriptionV2>{planBillingPeriod}</MonthlySubscriptionV2>
                                                    {planBillingPeriod === 'Monthly' ? (
                                                        <MonthlySubscriptionV2
                                                            fontBold
                                                            onClick={() => goChangePlan('yearly')}
                                                        >
                                                            Switch to annual payment
                                                        </MonthlySubscriptionV2>
                                                    ) : null}
                                                    {/* {console.log('paymentDetailActions :: 1', paymentDetailActions)} */}
                                                    {paymentDetailActions != 'ChangeCard' ? (
                                                        card != undefined ? (
                                                            <>
                                                                <SwitchPayment>
                                                                    <MonthlySubscriptionV2>
                                                                        Payment Method
                                                                    </MonthlySubscriptionV2>
                                                                    <MonthlySubscriptionV2
                                                                        fontBold
                                                                        onClick={() => setActions('ChangeCard')}
                                                                    >
                                                                        Change Card
                                                                    </MonthlySubscriptionV2>
                                                                </SwitchPayment>
                                                                <PaymentWrapperV2>
                                                                    <CardDetailsV2>
                                                                        <CardNumberV2>{`**** **** **** ${card.card.last4}`}</CardNumberV2>
                                                                        <CardTypeV2>
                                                                            <VisaImg src={visa_img}></VisaImg>
                                                                        </CardTypeV2>
                                                                    </CardDetailsV2>
                                                                    <CardExpiryDetailsV2>
                                                                        <CardHolderDetailsV2>
                                                                            <CardUserV2>
                                                                                {card.billing_details.name}{' '}
                                                                            </CardUserV2>
                                                                            <CardUserV2>
                                                                                Expires:{' '}
                                                                                {card.card.exp_month.toLocaleString(
                                                                                    undefined,
                                                                                    {
                                                                                        minimumIntegerDigits: 2
                                                                                    }
                                                                                )}
                                                                                /{card.card.exp_year}
                                                                            </CardUserV2>
                                                                        </CardHolderDetailsV2>
                                                                    </CardExpiryDetailsV2>
                                                                </PaymentWrapperV2>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <SwitchPayment>
                                                                    <MonthlySubscriptionV2>
                                                                        Payment Method
                                                                    </MonthlySubscriptionV2>
                                                                    <MonthlySubscriptionV2
                                                                        fontBold
                                                                        onClick={() => setActions('ChangeCard')}
                                                                    >
                                                                        Change Card
                                                                    </MonthlySubscriptionV2>
                                                                </SwitchPayment>
                                                                <PaymentWrapperV2>
                                                                    <MonthlySubscriptionV2>
                                                                        {' '}
                                                                        No Credit card details found
                                                                    </MonthlySubscriptionV2>
                                                                </PaymentWrapperV2>
                                                            </>
                                                        )
                                                    ) : (
                                                        <>
                                                            <Wrapper></Wrapper>
                                                            <FormWrapper>
                                                                <Form onSubmit={createAndUpdatePaymentMethod}>
                                                                    <FieldName>Card number</FieldName>
                                                                    <CardNumberElement
                                                                        options={options}
                                                                        onReady={() => {
                                                                            console.log('CardNumberElement [ready]')
                                                                        }}
                                                                        onChange={checkCardValidity}
                                                                    />
                                                                    <FieldName>Expiration date</FieldName>
                                                                    <CardExpiryElement
                                                                        options={options}
                                                                        onReady={() => {
                                                                            console.log('CardExpiry [ready]')
                                                                        }}
                                                                        onChange={checkExpiryDataValidity}
                                                                    />
                                                                    <FieldName>CVC</FieldName>
                                                                    <CardCvcElement
                                                                        options={options}
                                                                        onReady={() => {
                                                                            console.log('CardCvc [ready]')
                                                                        }}
                                                                        onChange={checkCvvValidity}
                                                                    />
                                                                    <Wrapper>
                                                                        <Button
                                                                            type="submit"
                                                                            disabled={
                                                                                !(
                                                                                    validCard.cvvValid &&
                                                                                    validCard.cardValid &&
                                                                                    validCard.expiryDateValid
                                                                                )
                                                                            }
                                                                        >
                                                                            <label className="payment_card_button">Update</label>
                                                                            
                                                                        </Button>
                                                                        <Button
                                                                            type="cancel"
                                                                            onClick={() => {
                                                                                setActions('None')
                                                                            }}
                                                                        >
                                                                            <label className="payment_card_button">Cancel</label>
                                                                            
                                                                        </Button>
                                                                    </Wrapper>
                                                                </Form>
                                                            </FormWrapper>
                                                        </>
                                                    )}
                                                    {/* TODO :: Credit card update */}

                                                    {displaySubscriptionDetails()}
                                                </BillingPeriodContainer>
                                            </BillingContact>
                                            <BillingContact>
                                                <BillingEstimationContainer>
                                                    <CurrentPlanHeader>Billing Estimate</CurrentPlanHeader>
                                                    <DateWrapper>
                                                        {detailsToDisplay.startDate} - {detailsToDisplay.endDate}
                                                    </DateWrapper>
                                                </BillingEstimationContainer>
                                                <TableContainerV2 resources={resourceWithDisplayDetails} />
                                                <BillItemsV2
                                                    addOnAmount={addOnAmount.toString()}
                                                    planAmount={toPlanEstimate}
                                                    planName={planName}
                                                    borderNone={false}
                                                    billingDate={formatStripeDate(upcomingInvoice.period_end)}
                                                />
                                                <DateWrapper
                                                    borderNone
                                                    onClick={() =>
                                                        props.history.push(
                                                            `/workspace/${workspacename}/billing/estimate`
                                                        )
                                                    }
                                                >
                                                    <MonthlySubscriptionV2 fontBold>
                                                        View Full Estimate
                                                    </MonthlySubscriptionV2>
                                                </DateWrapper>
                                            </BillingContact>
                                        </BillingContainer>
                                    </BillingWrapperV2>
                                </ContainerV2>
                            </WrapperV2>
                        </BodyWrapperV2>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    return <div> UnHandled </div>
}

const PaymentWithElements = (props: any) => {
    return (
        <Elements stripe={getStripe()}>
            <PaymentDetailsV2 {...props} />
        </Elements>
    )
}

export const ManagePaymentWrapper = SubscriptionWrapperH(PaymentWithElements)

const BillingWrapper = styled.div`
    width: 60%;
    float: left;
    margin-top: 30px;
`
const DetialsWrapper = styled.div`
    width: 100%;
    float: left;
    ${BoxRight} {
        margin-bottom: 50px;
    }
    ${BoxOne} {
        position: sticky;
        top: 0;
    }
    ${InformationIcon} {
        position: relative;
        left: 3px;
        margin-top: 0px;
    }
`
const BillingInput = styled.div`
    width: 100%;
    float: left;
    position: relative;
`
const CancelSubscription = styled.button`
    width: auto;
    float: left;
    color: #0a89e8;
    font-family: 'Gordita-Regular';
    font-size: 15px;
    border-radius: 4px;
    margin-top: 10px;
    margin-right: 15px;
    height: 38px;
    cursor: pointer;
    background: inherit;
    border: none;
    position: relative;
    :hover {
        text-decoration: underline;
    }
    :hover ${InformationIcon} {
        &svg {
            fill: #0a89e8;
        }
    }
    ${InformationIcon} {
        position: absolute;
        right: -7px;
        margin-top: 5px;
        left: inherit;
    }
`
export const PaymentWrapperV2 = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 6px;

    @media (max-width: 1700px) {
        padding: 10px 12px;
        margin-bottom: 0px;
    }
    @media (max-width: 1440px) {
        padding: 15px 12px;
        margin-bottom: 6px;
    }
    @media (max-width: 1370px) {
        margin-bottom: 8px;
    }

`
//<BillingWrapper> above <BoxRIght>
//{' '}
//<Wrapper>
//<HeaderRight>Billing Address</HeaderRight>
//</Wrapper>
//<BillingInput>
//{' '}
//<FieldName>Company Name</FieldName>
//<InputField placeholder="Comapny Name"></InputField>
//</BillingInput>
//<BillingInput>
//<FieldName>Country</FieldName>{' '}
//<div className="select_work_form select_country">
//<InputField placeholder="Country"></InputField>
//<div
//className="automation_scroly"
//role="combobox"
//aria-expanded="true"
//aria-haspopup="listbox"
//aria-owns="downshift-1-menu"
//aria-labelledby="downshift-1-label"
//>
//<ul>
//<li>India</li>
//<li>Japan</li>
//</ul>{' '}
//</div>
//<div className="arrow_up_form">
//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.75">
//<title />
//<g id="Layer_2" data-name="Layer 2">
//<g id="Layer_1-2" data-name="Layer 1">
//<path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z" />
//</g>
//</g>
//</svg>
//</div>
//</div>
//</BillingInput>
//<BillingInput>
//{' '}
//<FieldName>Company Address</FieldName>
//<InputField placeholder="Company Address"></InputField>
//</BillingInput>
//<BillingInput>
//{' '}
//<FieldName>
//Tax ID
//<InformationIcon>
//<ContetntInformationSpan data-tip data-for="subscription_tooltip">
//<InfoIcon />
//<Tooltip>
//<ReactTooltip
//className="subscription_tooltip"
//id="subscription_tooltip"
//type="warning"
//effect="solid"
//>
//<HoverSpan>subscription_tooltip</HoverSpan>
//</ReactTooltip>
//</Tooltip>
//</ContetntInformationSpan>
//</InformationIcon>
//</FieldName>
//<InputField placeholder="Tax ID"></InputField>
//</BillingInput>
//<Button type="submit">Update</Button>
//<CancelSubscription>
//Cancel Subscription
//<InformationIcon>
//<ContetntInformationSpan data-tip data-for="subscription_tooltip">
//<InfoIcon />
//<Tooltip>
//<ReactTooltip
//className="subscription_tooltip"
//id="subscription_tooltip"
//type="warning"
//effect="solid"
//>
//<HoverSpan>subscription_tooltip</HoverSpan>
//</ReactTooltip>
//</Tooltip>
//</ContetntInformationSpan>
//</InformationIcon>
//</CancelSubscription>
//</BillingWrapper>
