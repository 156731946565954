import { useEffect } from 'react'

export default function useToggleOnClickWithOutsideClickHandled(
    ref: any,
    outerClickhandler: () => void,
    elementClickHandler: () => void,
    ignoreRefs: any[]
) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const e: any = event
            if (
                e.target &&
                e.target.className &&
                typeof e.target.className == 'string' &&
                e.target.className.trim() == 'infinite-scroll-component'
            ) {
                return false
            }

            if (e.target.className == 'sidepeanel_settings' || e.target.className == '_head_notifications_panel') {
                outerClickhandler()
                return true
            } else {
                // to detect wheather the popup element has click action or element parent, if it has to execute
                if (e.target.onclick !== null) {
                    e.target.onclick()
                    return false
                } else if (e.target.parentNode !== null && e.target.parentNode.onclick !== null) {
                    e.target.parentNode.onclick()
                    return false
                }
            }

            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                elementClickHandler()
                return false
            }
            if (ignoreRefs.map(x => x.current).filter(curr => curr && curr.contains(event.target)).length > 0) {
                return false
            }

            if (e.target.closest('.pro_user_svg') !== null && e.target.closest('.pro_user_svg').onclick !== null) {
                e.target.closest('.pro_user_svg').onclick()
                return false
            }

            outerClickhandler()
            return true
        }

        document.addEventListener('mousedown', listener)
        // document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            // document.removeEventListener('touchstart', listener)
        }
    }, [ref, outerClickhandler, elementClickHandler]) // ... passing it into this hook. // ... but to optimize you can wrap handler in useCallback before ... // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
}
