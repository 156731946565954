import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react'

import { AttachmentIcon, FlowCanvasIcons, TestAutomationIcon } from '@/common/Icons/Icons'
import { BooleanIcon, DeleteInputKeyIcon, NumberIcon, PlusIcon, StringIcon } from '@/common/Icons/Workflow.Icons'
import alphabet_img from '@/common/images/alphabet.svg'
import file_img from '@/common/images/attachment.svg'
import number_img from '@/common/images/num.svg'
import boolean_img from '@/common/images/true_false.svg'

import { generateUniqueId } from '@/common/utils/utils'
import { SidePanelContext } from './Canvas'
import { ContextOutputTree } from './ContextPopup'
import { InputKey, KeyDataType, Nodes } from './types'
import { validInputKeys } from './utils'

export const FileDefaultObject = (title: string) =>
    ({
        $id: `/properties/${title}`,
        type: 'file',
        properties: {
            Filename: {
                $id: `/properties/${title}/Filename`,
                examples: ['Invoice.img'],
                title: 'Filename',
                type: 'string'
            },
            PublicURL: {
                $id: `/properties/${title}/PublicURL`,
                examples: ['https://assistant_invoice.img'],
                title: 'Public URL',
                type: 'string'
            },
            Category: {
                $id: `/properties/${title}/Category`,
                examples: ['Image'],
                title: 'Category',
                type: 'string'
            },
            Mimetype: {
                $id: `/properties/${title}/Mimetype`,
                examples: ['image/jpeg'],
                title: 'Mimetype',
                type: 'string'
            },
            Assistant_data_type: {
                $id: `/properties/${title}/Assistant_data_type`,
                examples: ['Assistant_data_type'],
                title: 'Assistant Data Type',
                type: 'string'
            }
        },
        title: title
    } as any)

const NoTriggerDescription = (_: any) => {
    return (
        <div className="automation_notification_message">
            <FlowCanvasIcons.TriggerDescriptionIcon />
            <span>Use this workflow to execute an automation by calling inside a dialog</span>
        </div>
    )
}
export const makeDefaultInputKey = (): InputKey => {
    const uniqueId = generateUniqueId()
        .split('.')
        .join('_')
    return {
        title: '',
        type: 'String',
        uniqueKey: uniqueId
    }
}

export const NoTriggerTree = (props: any) => {
    const { rightPanelState, setRightPanelState, setAutomationMetaType } = useContext(SidePanelContext)

    const [isAddBtnDisabled, setAddBtnDisabled] = useState<boolean>(false)
    const enableAddBtn = () => setAddBtnDisabled(false)
    const disableAddBtn = () => setAddBtnDisabled(true)

    const [inputKeyList, setInputKeys] = useState<InputKey[]>(props.initialItem ? props.initialItem : [])
    const addNewInputKey = () => {
        const newKey = makeDefaultInputKey()
        setInputKeys([...inputKeyList, newKey])
    }

    useEffect(() => {
        setRightPanelState({
            ...rightPanelState,
            currentNode: {
                nodeType: Nodes.Trigger,
                trigger: {
                    triggerMeta: {
                        triggerType: 'NoTrigger',
                        inputKeys: inputKeyList
                    }
                }
            }
        })
    }, [inputKeyList])

    const removeKey = (selectedKey: string) => {
        const filteredKeys = inputKeyList.filter(key => key.uniqueKey != selectedKey)
        setInputKeys([...filteredKeys])
        setRightPanelState({
            ...rightPanelState,
            currentNode: {
                nodeType: Nodes.Trigger,
                trigger: {
                    triggerMeta: {
                        triggerType: 'NoTrigger',
                        inputKeys: filteredKeys
                    }
                }
            }
        })
        enableAddBtn()
    }
    const updateKey = (selectedKey: InputKey) => {
        const updatedKeys = inputKeyList.map(key => {
            if (key.uniqueKey === selectedKey.uniqueKey) {
                return { ...selectedKey }
            }
            return key
        })
        setInputKeys(updatedKeys)
        enableAddBtn()
    }

    const disableSave = () => {
        const keysWithData = validInputKeys(inputKeyList)
        return keysWithData.length > 0 && inputKeyList.length === keysWithData.length ? false : true
    }

    const { treeData, active_automation } = useContext(SidePanelContext)

    return (
        <React.Fragment>
            <NoTriggerDescription />
            <InputKeysFormWrapper>
                <AddInputKeyInfo />
                <InputKeysForm>
                    {inputKeyList.map(inputKey => {
                        return (
                            <InputKeyFormControl
                                key={inputKey.uniqueKey}
                                inputKey={inputKey}
                                removeInputKey={removeKey}
                                updateInputKey={updateKey}
                                disableAddBtn={disableAddBtn}
                            />
                        )
                    })}
                    <div className="notrigger_add_buton">
                        <AddInputBtn onClick={addNewInputKey} disable={isAddBtnDisabled} />
                    </div>
                    <div className="margin-bottom-75" />
                </InputKeysForm>
            </InputKeysFormWrapper>
            <div className="automation_condition_button">
                <div className="automation_condition_button_center">
                    <button
                        className="automation_condition_button_btn"
                        onClick={props.cancelAction}
                        style={{ display: 'block' }}
                    >
                        {treeData.firstStepDone ? 'Cancel' : 'Skip'}
                    </button>
                    {!active_automation && (
                        <button
                            className={
                                disableSave()
                                    ? 'automation_condition_button_btn editor_btn_disabled'
                                    : 'automation_condition_button_btn'
                            }
                            onClick={() => {
                                if (treeData && treeData.tree && treeData.tree.children[0].children.length > 0) {
                                    props.showWarningModal(() => props.saveTrigger(inputKeyList))
                                } else {
                                    props.saveTrigger(inputKeyList)
                                    setAutomationMetaType('no_trigger')
                                }
                            }}
                            disabled={disableSave()}
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export const InputKeyFormControl = ({
    inputKey,
    removeInputKey,
    updateInputKey,
    disableAddBtn,
    children,
    edit
}: {
    inputKey: InputKey
    removeInputKey: (a: string) => void
    updateInputKey: (a: InputKey) => void
    disableAddBtn: () => void
    children?: any
    edit?: boolean
}) => {
    const MAX_KEY_TITLE_LENGTH = 40
    const [dataTypeDropdownOpen, openDataTypeDropDown] = useState<boolean>(false)
    const [isKeyTitleContentEditable, makeKeyTitleContentEditable] = useState<boolean>(true)
    const keyTitleContentElementRef = useRef<HTMLElement>(null)

    const focusOutKeyTitleContentDiv = () => {
        makeKeyTitleContentEditable(false)
        Promise.resolve().then(_ => {
            makeKeyTitleContentEditable(true)
        })
    }

    // useEffect(() => {
    //     const target = document.querySelector('div.button_auto')

    //     if (target) {
    //         target.addEventListener('paste', (event: any) => {
    //             let paste = event.clipboardData.getData('text')
    //             paste = paste.toUpperCase()

    //             const selection = window.getSelection()
    //             if (selection) {
    //                 if (!selection.rangeCount) {
    //                     // return false
    //                 } else {
    //                     selection.deleteFromDocument()
    //                     selection.getRangeAt(0).insertNode(document.createTextNode(paste))
    //                 }
    //             }

    //             event.preventDefault()
    //         })
    //     }
    // }, [])

    // delete key of no Input on outer click
    useEffect(() => {
        if (inputKey.title == '') {
            keyTitleContentElementRef.current && keyTitleContentElementRef.current.focus()
            disableAddBtn()
        }
    }, [inputKey])

    // delete key of no Input on blur
    const inputFormBlur = (_: any) => {
        if (keyTitleContentElementRef.current && keyTitleContentElementRef.current.innerText.length == 0) {
            removeInputKey(inputKey.uniqueKey)
        }
    }
    const contentEditableBlur = (_: any) => {
        _.stopPropagation()
    }
    const onDataTypeChange = (dataType: KeyDataType) => {
        updateInputKey({ ...inputKey, type: dataType })
        openDataTypeDropDown(false)
    }
    //focus out keycontent div on Enter key press
    const titleContentKeyDown = (evt: any) => {
        if (evt.key === 'Enter') {
            focusOutKeyTitleContentDiv()
        }
    }
    const onKeyTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        // .replace('<br>', '')
        // if (value.length <= MAX_KEY_TITLE_LENGTH) {
        updateInputKey({ ...inputKey, title: value })
        // } else {
        //     focusOutKeyTitleContentDiv()
        // }
    }
    function resizeInput() {
        var input = document.getElementById('no_trigger_input') as HTMLInputElement
        // const newWidth = input.value.length > 7 ? width + (input.value.length - minLength) * 5 : width
        input.style.width = input.value.length + 'ch'
        // width = input.value.length > 7 ? newWidth : 70
    }
    useEffect(() => {
        var input = document.getElementById('no_trigger_input')
        if (input) {
            // get the input element
            input.addEventListener('input', resizeInput) // bind the "resizeInput" callback on "input" event
            resizeInput.call(input) // immediately call the function
        }

        return () => {
            input && input.removeEventListener('input', resizeInput)
        }
    }, [])
    return (
        <React.Fragment>
            <div className={edit ? 'nortrigger_flex_wrapper edit' : 'nortrigger_flex_wrapper'}>
                <div className="context_popup_buton_content_span" onBlur={inputFormBlur}>
                    <span className="content_capital" onClick={() => openDataTypeDropDown(true)}>
                        <span
                            style={{
                                width: '30px',
                                height: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <img src={imageSource(inputKey.type)} alt="" />
                            <span className="content_capital_svg">
                                <TestAutomationIcon />
                            </span>
                        </span>
                    </span>
                    <DataTypeSelect
                        isOpen={dataTypeDropdownOpen}
                        close={() => openDataTypeDropDown(false)}
                        onSelect={onDataTypeChange}
                    />
                    <div className="notrigger_span_buton_auto">
                        {/* <ContentEditable
                            innerRef={keyTitleContentElementRef}
                            disabled={!isKeyTitleContentEditable}
                            html={inputKey.title.replace(/\&nbsp;/g, ' ')}
                            onKeyDown={titleContentKeyDown}
                            onChange={onKeyTitleChange}
                            onBlur={contentEditableBlur}
                            className="button_auto"
                        /> */}
                        <input
                            type="text"
                            id="no_trigger_input"
                            className="button_auto"
                            maxLength={40}
                            value={inputKey.title}
                            onChange={onKeyTitleChange}
                            style={{ maxWidth: '280px' }}
                        />
                        <div className="add_edit_variable_wrapper"> {children}</div>
                        <div className="notrigger_span" onClick={() => removeInputKey(inputKey.uniqueKey)}>
                            <span className="notrigger_span_svg">
                                <DeleteInputKeyIcon />
                            </span>
                        </div>
                    </div>
                </div>
                {inputKey.type == 'File' && (
                    <div className="step1_trigger_wrapper">
                        <div className={'form_req_step_two_global_form'}>
                            <div className="collection_picker_in_input_page">
                                <ContextOutputTree
                                    output={{
                                        name: '',
                                        value: FileDefaultObject(inputKey.uniqueKey)
                                    }}
                                    onClick={() => {}}
                                    usingForInputs={true}
                                    hideObjectHead
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export const DataTypeSelect = (props: any) => {
    const selectItem = (item: KeyDataType) => {
        props.onSelect(item)
    }

    return (
        <Downshift isOpen={props.isOpen} onOuterClick={props.close}>
            {({ isOpen }) =>
                isOpen ? (
                    <div className="notrigger_dropdown_tree">
                        <div className="notrigger_dropdown_tree_ul">
                            <div className="notrigger_dropdown_tree_ul_svg" onClick={() => selectItem('String')}>
                                <span>
                                    <StringIcon />
                                </span>
                            </div>
                            <div className="notrigger_dropdown_tree_ul_svg" onClick={() => selectItem('Number')}>
                                <span>
                                    <NumberIcon />
                                </span>
                            </div>
                            <div className="notrigger_dropdown_tree_ul_svg" onClick={() => selectItem('Boolean')}>
                                <span>
                                    <BooleanIcon />
                                </span>
                            </div>
                            <div className="notrigger_dropdown_tree_ul_svg" onClick={() => selectItem('File')}>
                                <span>
                                    <AttachmentIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </Downshift>
    )
}

const AddInputKeyInfo: React.FunctionComponent = () => {
    return (
        <div className="notrigger_tree_header_top">
            <div className=" notrigger_tree_header">
                <h4>Specify Input fields for your workflow</h4>
                <p>
                    Add input fields (or variables) to help execute this workflow. <br></br>e.g., to create a ticket,
                    you will ned input fields such as Email ID, Name, Description etc
                </p>
            </div>
        </div>
    )
}

const AddInputBtn: React.FunctionComponent<any> = ({ onClick, disable }: { onClick: () => void; disable: boolean }) => {
    const buttonWrapperClass = disable ? 'notrigger_add_buton_span_disabled' : 'notrigger_add_buton_span'
    return (
        <div className={buttonWrapperClass}>
            <button onClick={onClick} disabled={disable} className='workflow_variable_add_input'>
                <span>
                    <PlusIcon />
                </span>
                Add Input
            </button>
        </div>
    )
}

const InputKeysFormWrapper: React.FunctionComponent = (props: any) => {
    return (
        <div className="select_application select_application_notrigger">
            <div className="notrigger_popup_tree">
                <div className="context_popup_accordian_open">
                    <div className="context_popup_buton_content">{props.children}</div>
                </div>
            </div>
        </div>
    )
}

const InputKeysForm: React.FunctionComponent = (props: any) => {
    return (
        <div className="context_popup_buton_content_span">
            <div className="popup_tree">
                <div className="tree_structure_body notrigger_tree_structure_body">
                    <div className=" treeview_automatiom">
                        <div className="right_panel_ht">
                            <div className="notrigger_tree_structure_block">{props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const imageSource = (type: KeyDataType) => {
    switch (type) {
        case 'String':
            return alphabet_img
        case 'Boolean':
            return boolean_img
        case 'Number':
            return number_img
        case 'File':
            return file_img

        default:
            return alphabet_img
    }
}
