/*eslint-disable*/
import React from 'react';
/*eslint-disable*/
import { useNavigate } from 'react-router-dom';

import { Loader } from '@/common/components/Loader';
import { getJson } from '@/common/utils/axios-utils';

import { userJourneyWithWorkspace } from '@/common/utils/api-utils';
// import { Loading } from '@/DialogEditor/Intent/types'
// import { WorkspaceContext } from '@/common/workspaceContext';
// import { workspace } from '@/Integrations/Widget';
import { useJourney, useWorkspace } from './JourneyContext';
import { createUserJourney } from './JourneyMachine';
import { getProductId } from '../../../shared/common/src/utils/api-utils';
import { usePermissions } from '@/common/utils/auth-token-api-utils';

export type LoadedState<T, U> =
  | {
      type: 'yet-to-load';
    }
  | {
      type: 'loading';
    }
  | {
      type: 'success';
      value: T;
    }
  | {
      type: 'error';
      error: U;
    };

export type LoadedStateWithRerender<T, U> =
  | {
      type: 'yet-to-load';
    }
  | {
      type: 'loading';
    }
  | {
      type: 'success';
      value: T;
      id: number;
    }
  | {
      type: 'error';
      error: U;
    };

// type IM = typeof S

type IM_ET = ReturnType<typeof createUserJourney>;

export type JourneyState = LoadedStateWithRerender<IM_ET, any>;

export type WorkspaceS = LoadedState<string, any>;
interface MatchParams {
  workspacename: string;
}

export const Journey = (
  props: {
    setJourneyState: React.Dispatch<React.SetStateAction<JourneyState>>;
  } & { match: { params: MatchParams }; history: any; location: Location }
) => {
  const workspace = props.match.params.workspacename;
  const router = props.history;
  // console.log('props', props)

  // const journeyMachine = S
  const rootJourneyContext = useJourney();
  const rootWorkspace = useWorkspace();
  const product = getProductId();
  const hasPermission = usePermissions(
    props.match.params.workspacename,
    'WELCOME_PAGE',
    'READ'
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    getJson(userJourneyWithWorkspace(workspace, '/journey'))
      .then((saved) => {
        return saved.output.journeyJson;
      })
      .then((saved) => {
        console.log('saved', saved);
        const S1 = createUserJourney(workspace);
        if (saved) {
          S1.start({
            value: saved.state,
            context: {
              systemContext: {
                workspace: workspace,
              },
              executionContext: saved.context,
            },
          });
        } else {
          S1.start({
            value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
            context: {
              systemContext: {
                workspace: workspace,
              },
              executionContext: {
                pendingActions: [],
              },
            },
          });
        }

        const y = {
          type: 'success' as 'success',
          value: S1,
          id: JSON.stringify(S1.state()).length,
        };
        props.setJourneyState(y);
      })
      .catch((err) => {
        // x.response.data.tag === JOURNEY_NOT_FOUND

        if (
          (err.response && err.response.status == 401) ||
          (err.response && err.response.status == 403)
        ) {
          console.log('Unauthorised || Forbidden access');
          location.replace(window.location.origin + '/authentication/u');
        }

        if (
          err.response &&
          err.response.data &&
          err.response.data.tag === 'JOURNEY_NOT_FOUND'
        ) {
          const S1 = createUserJourney(workspace);
          S1.start({
            value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
            context: {
              systemContext: {
                workspace: workspace,
              },
              executionContext: {
                pendingActions: [],
              },
            },
          });
          const y = {
            type: 'success' as 'success',
            value: S1,
            id: Math.random(),
          };
          props.setJourneyState(y);
        }
        console.log('saved :: error', err);
      });

    // const x = sleeper<LoadedStateWithRerender<IM_ET, any>>(2000)(() => {
    //     const saved = window.localStorage.getItem(workspace)
    //     const S1 = createUserJourney(workspace)
    //     if (saved) {
    //         S1.start({
    //             value: JSON.parse(saved).state,
    //             context: {
    //                 systemContext: {
    //                     workspace: workspace
    //                 },
    //                 executionContext: JSON.parse(saved).context
    //             }
    //         })
    //     } else {
    //         S1.start({
    //             value: { type: 'MAIN', JOURNEY: 'JOURNEY::NOT_STARTED' },
    //             context: {
    //                 systemContext: {
    //                     workspace: workspace
    //                 },
    //                 executionContext: {}
    //             }
    //         })
    //     }

    //     return {
    //         type: 'success',
    //         value: S1,
    //         id: Math.random()
    //     }
    // })

    // x.then(val =>)
  }, []);
  console.log("DOING ::: ", JSON.stringify(rootJourneyContext))
  if (rootJourneyContext.type === 'success') {
    if (
      rootJourneyContext.value.state().value.type == 'MAIN' &&
      rootJourneyContext.value.state().value['JOURNEY'] ===
        'JOURNEY::NOT_STARTED'
    ) {
      if (product === 'AUTOMATE') {
        router.push(`/workspace/${workspace}/welcome`);
      } else {
        console.log("DOING ::: JOURNEY setTimeout 1")
        setTimeout(() => {
          if (
            props.location.pathname !==
            `/workspace/${workspace}/get-started/marketplace`
          ) {
            navigate(`/workspace/${workspace}/get-started`, { replace: true });
          }
        });
      }
    } else {
      // console.log('routing to welcome')
      console.log("DOING ::: JOURNEY setTimeout 2")
      hasPermission.value
        ? setTimeout(() => { router.push(`/workspace/${workspace}/welcome`)})
        : setTimeout(() => {router.push(
            `/workspace/${workspace}/assistant/homepage`
          )});
    }

    return null
  }
  return <Loader.PageLoader show={true} />;
};
