/*eslint-disable*/
import produce from 'immer'
import React, { useContext, useEffect, useState } from 'react'

import { DeleteIcon, DownArrowCircle, EyeOpenIcon, FlowCanvasIcons } from '@/common/Icons/Icons'
import { Modal } from '@/common/components/Modal'
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

import workDayImg from '@/common/images/workday.png'
import { AppTriggerPanel } from './AppTriggerPanel'
import { SidePanelContext } from './Canvas'
import { CanvasRightPanel as CRP } from './CanvasRightPanel'
import { NoTriggerTree } from './NoTriggerPanel'
import { ActionInitialState, NodeAddT, NodeEdit, NodeView, RightPanelStateT, Tree, WebhookInitialState } from './types'
import {
    AppTriggerResponseT,
    InputKey,
    KeyDataType,
    NoTriggerInitialState,
    NodeMode,
    Nodes,
    TriggerResponseT,
    TriggerT
} from './types'
import { validInputKeys } from './utils'
import {
    PopupContainer,
    PopupScrollHeight,
    PopupWrapper,
    Wrapper as ActionWrapper,
    ActionPanelPopup,
    Button,
    ButtonContainer,
    EmptyDiv
} from '@/common/styled/Workflow.Dumb'
import { getProductId } from '@/common/utils/api-utils'
import { ChatbotTriggeredPanel } from './ChatbotTriggeredPanel'

const R = require('ramda')


export const noTriggerInitialData = (treeData: any) => {
    const value = R.path(["tree", "value", "output", "properties"])(treeData);
    if (value) {
      return Object.entries(treeData.tree.value.output.properties).map(
        ([key, v]) => {
          const value = v as any;
          return value.optional
            ? {
                title: value.title,
                uniqueKey: key,
                type: stringToKeyDataType(value.type),
                optional: value.optional,
              }
            : {
                title: value.title,
                uniqueKey: key,
                type: stringToKeyDataType(value.type),
              };
        }
      );
    }
  
    return [];
  };

  const stringToKeyDataType = (value: string): KeyDataType => {
    switch (value) {
      case "string":
        return "String";
      case "number":
        return "Number";
      case "boolean":
        return "Boolean";
      case "date":
        return "Date";
      default:
        return "String";
    }
  };


export type TriggerS = {
    node: TriggerResponseT
}

export const TriggerPanel = (props: any) => {
    const { rightPanelState, setRightPanelState, treeData, setEditTreeData, saveTree } = useContext(SidePanelContext)
    const stringToKeyDataType = (value: string): KeyDataType => {
        switch (value) {
            case 'string':
                return 'String'
            case 'number':
                return 'Number'
            case 'boolean':
                return 'Boolean'
            case 'date':
                return 'Date'
            case 'file':
                return 'File'
            default:
                return 'String'
        }
    }

    const current = rightPanelState.currentNode as TriggerResponseT | AppTriggerResponseT

    const [triggerSelected, setTriggerSelected] = useState<TriggerT>(current.trigger)

    useEffect(()=>{
        setTriggerSelected(current.trigger)
    },[current])

    const [showWarningModal, setShowWarningModal] = useState({ show: false, callback: () => {} })

    const saveTrigger = (keys: InputKey[]) => {
        const nextState = produce(rightPanelState.currentNode, draftState => {
            if (draftState.nodeType == Nodes.Trigger && draftState.trigger.triggerMeta.triggerType == 'NoTrigger') {
                draftState.trigger.triggerMeta.inputKeys = validInputKeys(keys)
            }
        })

        setRightPanelState({
            ...rightPanelState,
            currentNode: nextState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] }
        })
        saveTree(nextState)
    }

    const resetRightPanel = () => {
        setRightPanelState({
            ...rightPanelState,
            currentNode: ActionInitialState,
            show: false,
            mode: NodeView,
            parentNode: { value: Tree, children: [] }
        })
        setEditTreeData(treeData)
    }

    const noTriggerInitialData = () => {
        const value = R.path(['tree', 'value', 'output', 'properties'])(treeData)
        if (value) {
            return Object.entries(treeData.tree.value.output.properties).map(([key, v]) => {
                const value = v as any
                return {
                    title: value.title,
                    uniqueKey: key,
                    type: stringToKeyDataType(value.type)
                }
            })
        }

        return []
    }

    const renderWarningModal = () => {
        if (showWarningModal.show) {
            return (
                <Modal showPopupLoader={false}>
                    <React.Fragment>
                        <h2>Do you want to save?</h2>
                        <p>Modifying the automation step 1 might affect the step 2 automation flow.</p>
                    </React.Fragment>
                    <ButtonContainer style={{paddingBottom: 0}}>
                        <Button
                            className="btn btn-primary"
                            type="button"
                            data-dismiss="modal"
                            onClick={() => {
                                showWarningModal.callback()
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            className="btn btn-success variable_update"
                            type="button"
                            data-dismiss="modal"
                            onClick={() => {
                                setShowWarningModal({ show: false, callback: () => {} })
                            }}
                        >
                            No
                        </Button>
                    </ButtonContainer>
                </Modal>
            )
        } else return null
    }
    const isAssistant = getProductId()=='ASSISTANT'

    // console.log("treeData.tree.value.meta_data",treeData.tree.value.meta_data,!treeData.tree.value.meta_data)

    return (
        <Wrapper>
            <CRP.StepHeader title={R.isEmpty(treeData.tree.value.meta_data)
                ? 'Start your app workflow' : 'Edit your app workflow'} description={R.isEmpty(treeData.tree.value.meta_data) ? 'Set Trigger' : 'Edit Trigger'} 
                     infocontent={"Create workflows using application triggers. A workflow is triggered automatically when an event occurs in an app. e.g When a new user is created in ServiceNow (app trigger), execute a workflow to add the new user in Office 365."}
                    helpcontent="Help document"
                    helplink="https://help.workativ.com/knowledgebase/app-trigger/"
                />

            {/* <TriggerPanel.TriggerSelection trigger={[triggerSelected, setTriggerSelected]} /> */}

            {triggerSelected.triggerMeta.triggerType === 'AppTrigger' ? (
                <React.Fragment>
                    <PopupScrollHeight>
                        <EmptyDiv className="assistant_trigger_popup"   >
                            <div className="automation_canvas_right action_popup automate_apptrigger_popup">
                            {/* <TriggerDescription /> */}
                                <AppTriggerPanel
                                    {...props}
                                    cancelAction={resetRightPanel}
                                    saveTrigger={saveTrigger}
                                    treeData={props.TreeData}
                                    showWarningModal={(callback: () => void) => {
                                        setShowWarningModal({ show: true, callback: callback })
                                    }}
                                />
                            </div>
                        </EmptyDiv>
                    </PopupScrollHeight>
                </React.Fragment>
            ) : (
                <PopupScrollHeight>
                    <EmptyDiv className="assistant_trigger_popup">
                        <div className="automation_canvas_right action_popup automate_apptrigger_popup">
                            {/* <NoTriggerTree
                                cancelAction={resetRightPanel}
                                saveTrigger={saveTrigger}
                                initialItem={noTriggerInitialData()}
                                treeData={props.TreeData}
                                showWarningModal={(callback: () => void) => {
                                    setShowWarningModal({ show: true, callback: callback })
                                }}
                            /> */}
                            <ChatbotTriggeredPanel 
                                cancelAction={resetRightPanel}
                            />
                        </div>
                    </EmptyDiv>
                </PopupScrollHeight>
            )}
            {renderWarningModal()}
        </Wrapper>
    )
}

export const Wrapper = (props: any) => (
    // <div className="automation_canvas_right">
    //     <div className="automation_canvas_right_popup automation_canvas_right_popup_notrigger">
    //         <div className="automation_canvas_right_popup_form">
    //             <div className="automation_rightside_form">{props.children}</div>
    //         </div>
    //     </div>
    // </div>

    <ActionWrapper>
      <ActionPanelPopup>
        <PopupWrapper>
          <PopupContainer>
            <div className="automation_canvas_right_popup">
              <div className="automation_canvas_right_popup_form">
                <div className="automation_rightside_form automation_rightside_step_two edit_action_height">
                  {props.children}
                </div>
              </div>
            </div>
          </PopupContainer>
        </PopupWrapper>
      </ActionPanelPopup>
    </ActionWrapper>
)

TriggerPanel.TriggerSelection = (props: any) => {
    const [triggerSelected, setTriggerSelected] = props.trigger as [
        TriggerT,
        React.Dispatch<React.SetStateAction<TriggerT>>
    ]

    const { rightPanelState, setRightPanelState, editTreeData, treeData, active_automation } = useContext(
        SidePanelContext
    )

    const state = rightPanelState as RightPanelStateT
    const currentTree = rightPanelState.mode.mode !== NodeMode.Add ? treeData : editTreeData

    return (
        <React.Fragment>
            <div className="automation_trigger">
                {/* <div className="radio">
                    <label>
                        <input
                            type="radio"
                            name="right_panel"
                            className="radio-warning"
                            disabled={active_automation && triggerSelected.triggerMeta.triggerType !== 'AppTrigger'}
                            checked={triggerSelected.triggerMeta.triggerType == 'AppTrigger'}
                            onChange={() => {
                                const nextState: RightPanelStateT = produce(state, draftState => {
                                    draftState.currentNode = AppTriggerInitialState
                                    draftState.show = true
                                    draftState.mode = NodeAddT
                                    draftState.mode = {
                                        ...NodeEdit,
                                        nodeEditInput: currentTree.tree.value
                                    }
                                })

                                setRightPanelState(nextState)

                                setTriggerSelected(AppTriggerInitialState.trigger)

                                // setTriggerSelected(NoTriggerInitialState.trigger)
                            }}
                        />
                    </label>
                    <span
                        className={`${
                            active_automation && triggerSelected.triggerMeta.triggerType !== 'AppTrigger'
                                ? 'editor_btn_disabled'
                                : ''
                        }`}
                    >
                        Trigger
                    </span>
                </div> */}
                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            name="right_panel"
                            disabled={active_automation && triggerSelected.triggerMeta.triggerType !== 'NoTrigger'}
                            checked={triggerSelected.triggerMeta.triggerType == 'NoTrigger'}
                            onChange={() => {
                                const nextState: RightPanelStateT = produce(state, draftState => {
                                    draftState.currentNode = NoTriggerInitialState
                                    draftState.show = true
                                    draftState.mode = NodeAddT
                                    draftState.mode = {
                                        ...NodeEdit,
                                        nodeEditInput: currentTree.tree.value
                                    }
                                })

                                setRightPanelState(nextState)

                                setTriggerSelected(NoTriggerInitialState.trigger)
                            }}
                        />
                    </label>
                    <span
                        className={`${
                            active_automation && triggerSelected.triggerMeta.triggerType !== 'NoTrigger'
                                ? 'editor_btn_disabled'
                                : ''
                        }`}
                    >
                        No Trigger
                    </span>
                </div>
            </div>
        </React.Fragment>
    )
}

TriggerPanel.SelectApplication = () => {
    const triggerArray = [{ name: 'New account created' }, { name: 'Account updated' }]

    return (
        <div className="select_application">
            <div className="select_work_form">
                <CRP.AppsDropDown
                    input={[{ label: 'intent', value: 'entity' }]}
                    addDropDown
                    render={(makeDropDownVisible: () => void) => (
                        <React.Fragment>
                            <h4>Select application</h4>
                            <input type="text" value="Workday" onClick={makeDropDownVisible} />
                            <div className="arrow_up_form">
                                <DownArrowCircle />
                            </div>
                            <div className="input_img_left">
                                <img src={workDayImg} />
                            </div>
                        </React.Fragment>
                    )}
                />
            </div>

            <div className="trigger_auto_buttons">
                <h4>Select Trigger</h4>
                <div className="trigger_auto_width">
                    {triggerArray.map((trigger, i) => (
                        <div className="button_auto" key={i}>
                            {trigger.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

TriggerPanel.SelectAction = () => {
    return (
        <div className="automation_form_req">
            <div className="automation_global_form">
                <div className="select_work_form">
                    <CRP.AppsDropDown
                        addDropDown
                        input={[
                            {
                                label: 'intent',
                                value: 'entity'
                            }
                        ]}
                        render={(makeDropDownVisible: () => void) => (
                            <React.Fragment>
                                <h4>
                                    Select Time Intervel <span>(Required)</span>
                                </h4>
                                <input type="text" value="Workday" onClick={makeDropDownVisible} />
                                <div className="arrow_up_form">
                                    <DownArrowCircle />
                                </div>
                            </React.Fragment>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

TriggerPanel.SelectConnection = () => {
    return (
        <div className="autoamtion_connection" style={{ marginTop: '0px' }}>
            <div className="autoamtion_connection_account">
                <h4>Connection Name</h4>
                <h6>
                    <span>
                        <FlowCanvasIcons.SwitchAccountIcon />
                    </span>
                    Switch Account
                </h6>
                <div className="autoamtion_connection_name">
                    <div className="autoamtion_connection_name_left">
                        <h5>My Zendesk account</h5>
                        <p>ashik@Zendesk.com</p>
                    </div>
                    <div className="autoamtion_connection_name_center">
                        <h5>Connected</h5>
                    </div>
                    <div className="autoamtion_connection_name_right">
                        <button>Test</button>
                    </div>
                </div>

                <div className="link_new_account">
                    <input type="text" value="My workay account" />
                    <p>Name your connection. You can reuse it in other application.</p>
                    <button>Link New Account</button>
                </div>

                <div className="automation_existing">
                    <h5>
                        You have <span>1</span> existing Workday account
                    </h5>
                    <p>Select an existing connection, or create a new one.</p>
                    <div className="select_work_form">
                        <CRP.AppsDropDown
                            addDropDown
                            input={[
                                {
                                    label: 'intent',
                                    value: 'entity'
                                },
                                {
                                    label: 'intent1',
                                    value: 'entity1'
                                }
                            ]}
                            render={(makeDropDownVisible: () => void) => (
                                <React.Fragment>
                                    <h4>Existing Connections</h4>
                                    <input type="text" value="Workday" onClick={makeDropDownVisible} />
                                    <div className="arrow_up_form">
                                        <DownArrowCircle />
                                    </div>
                                </React.Fragment>
                            )}
                        />

                        <CRP.AppsDropDown
                            input={[
                                {
                                    label: 'intent',
                                    value: 'entity'
                                }
                            ]}
                            addDropDown={false}
                            render={() => (
                                <React.Fragment>
                                    <div className="account_connected">
                                        <input type="text" value="Workday" />
                                        <div className="arrow_up_form">
                                            <DownArrowCircle />
                                        </div>

                                        <div className="my_account_connect">
                                            <div className="my_account_connect_left">
                                                <h5>My Workday Account</h5>
                                                <p>ashik@workativ.com</p>
                                            </div>
                                            <div className="my_account_connect_right">
                                                <h6>Connected</h6>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        />
                    </div>
                    <div className="add_connection_automation">
                        <SmallButton primary>
                            <span>
                                <FlowCanvasIcons.ArrowPlusIcon />
                            </span>{' '}
                            <label>Create a new connection</label>
                        </SmallButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

TriggerPanel.TestResult = () => {
    return (
        <div className="test_buton_result">
            <div className="test_buton_view">
                <h6>Test Result</h6>
                <p>Getting Sample Data</p>
                <div className="test_buton_input_show">
                    <div className="test_buton_input">
                        <div className="test_buton_input_left">
                            <h5>Account 1</h5>
                        </div>
                        <div className="test_buton_input_right">
                            <span className="active_test_buton_input_right">
                                <EyeOpenIcon />
                                View Details
                            </span>
                            <span>
                                <DeleteIcon />
                                Delete
                            </span>
                        </div>
                    </div>

                    <div className="test_buton_input_hide">
                        <div className="test_buton_input_hide_buton">
                            <button className="button_auto">New account created </button>
                            <span>Ashik</span>
                        </div>
                        <div className="test_buton_input_hide_buton">
                            <button className="button_auto">Email ID </button>
                            <span>ashik@workativ.com</span>
                        </div>
                        <div className="test_buton_input_hide_buton">
                            <button className="button_auto">Name </button>
                            <span>Ashik</span>
                        </div>
                    </div>
                </div>
                <div className="test_buton_input_show">
                    <div className="test_buton_input">
                        <div className="test_buton_input_left">
                            <h5>Account 1</h5>
                        </div>
                        <div className="test_buton_input_right">
                            <span className="active_test_buton_input_right">
                                <EyeOpenIcon />
                                View Details
                            </span>
                            <span>
                                <DeleteIcon />
                                Delete
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TriggerDescription = (_: any) => {
    return (
        <div className="automation_notification_message">
            <FlowCanvasIcons.TriggerDescriptionIcon />
            <span>Use Trigger to execute an automation when an application-related event takes place.</span>
        </div>
    )
}
