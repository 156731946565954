import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { DownloadFromMarketplace } from '@/common/Icons/Icons'
import { Loader } from '@/common/components/Loader'

import { formatDate } from '@/common/utils/utils'
import { BillingWrapper, CardExpiryWrapper, OverviewPlanV2, PlanD, Table, Tbody, Td, TdSpan, Th, Thead, Tr } from './BillingOverview-v2'
import { BodyWrapperV2, ContainerV2, PlansHeaderV2, WrapperV2 } from './Plans-v2'
import { loadCurrentSubscriptionData } from './Subscription'
import { ActionT, ErrorPage, InvoiceHistoryT, PageStateT, TrialStatus } from './kind'
import { stripeUTCDate, toTitleCase } from './util-stripe'
import { NoCreditCard } from '@/common/styled/Subscription.Xtra.Dumb'

export const NoDataWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(50vh);
    overflow-y: hidden;
    position: relative;
`
export const NoDataHeader = styled.h3`
    width: 100%;
    float: left;
    dispaly: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
    font-size: 18px;
    font-family: 'Gordita-Medium';
    text-align: center;
`
type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any) => Promise<infer U>
    ? U
    : T extends (...args: any) => infer U
    ? U
    : T

export const BillingHistoryNew = () => {
    return (
        <div className="plansWrapper">
            <PlansHeaderV2 backButton name={'Billing History'} />
            <CurrentPlan />
        </div>
    )
}

export const CurrentPlan = () => {
    return (
        <div className="current_plan_wrapper">
            <BodyWrapperV2 paddingTop>
                <WrapperV2>
                    <ContainerV2>
                        <BillingWrapper>
                            <PlanD />
                            {/* <BillingHistoryTable /> */}
                        </BillingWrapper>
                    </ContainerV2>
                </WrapperV2>
            </BodyWrapperV2>
        </div>
    )
}

const BillingHistoryTable = ({ invoices: invoiceHistory }: { invoices: InvoiceHistoryT[] }) => {
    if (invoiceHistory.length === 0) {
      return (
        <CardExpiryWrapper>
          <NoCreditCard>No invoice details found</NoCreditCard>
        </CardExpiryWrapper>
      );
    }

    return (
      <BillingContainer>
        <BillingHistoryWrapper style={{ height: 'calc(100vh - 290px)' }}>
          <Table>
            <Thead primary>
              <Tr>
                <Th primary size={2}>
                  Date
                </Th>
                <Th primary size={3}>
                  Invoice Number
                </Th>
                {/* <Th primary size={1.5}>
                  Type
                </Th> */}

                <Th size={2} style={{ justifyContent: 'flex-end' }}>
                  Amount
                </Th>
                <Th size={3}>Download</Th>
                <Th size={2}>Payment Status</Th>
              </Tr>
            </Thead>
            <div className="table_scroll">
              <Tbody>
                {invoiceHistory.map((data: InvoiceHistoryT, index: number) => (
                  <Tr key={index}>
                    <Td ContentRight size={2}>
                      {formatDate(new Date(data.endDate * 1000))}
                    </Td>
                    <Td ContentRight size={3}>
                      {data.invoiceId}
                    </Td>
                    {/* <Td ContentRight size={1.5}>
                      Monthly
                    </Td> */}
                    <Td
                      ContentRight
                      size={2}
                      style={{ justifyContent: 'flex-end' }}
                    >
                      ${data.amount / 100}
                    </Td>
                    <Td size={3}>
                      <span
                        onClick={() => window.open(data.invoicePdf, '_blank')}
                      >
                        <DownloadFromMarketplace />
                      </span>
                    </Td>
                    <Td size={2}>
                      <TdSpan bgPending={data.status === 'paid' ? false : true}>
                        <span>{toTitleCase(data.status)}</span>
                      </TdSpan>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </div>
          </Table>
        </BillingHistoryWrapper>
      </BillingContainer>
    );
}

const BillingHistoryWrapper = styled.section`
width:100%;
float:left;
.table_scroll{
    width: 100%;
    float: left;
    height: calc(100vh - 400px);

    @media(max-width:1800px){
        height: calc(100vh - 390px);
    }
    @media(max-width:1700px){
        height: calc(100vh - 390px);
    }
    @media(max-width:1600px){
        height: calc(100vh - 355px);
    }
    

    ${Tbody}{
        border:1px solid #c6c6c6;
        border-top:none;
        border-radius: 0px 0px 10px 10px;
        max-height: calc(100vh - 400px);
    overflow-y: scroll;
    place-content: flex-start;
    display: grid;
    grid-template-columns: 100%;

    ::-webkit-scrollbar {
        width: 4px;
        display: block;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        height: 20px;
        border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
        background: inherit;
    }
    @media(max-width:1800px){
        max-height: calc(100vh - 390px);
    }
    @media(max-width:1700px){
        max-height: calc(100vh - 390px);
    }
    @media(max-width:1600px){
        max-height: calc(100vh - 355px);
    }
    }

   
 
}
${Thead}{
padding:0px 20px;
height:84px;
display:flex;
align-items:center;
background:#F4F4F4;
border-radius:10px 10px 0px 0px;

@media (max-width:1800px){
    height:72px;
}
@media (max-width:1600px){
    height:64px;
}
${Tr}{
    padding-bottom:0px;
}
}
${Tbody}{
    padding:0px;
    ${Tr}{
        height: 48px;
        padding:0px 20px 0px 20px;
        :nth-child(odd) {background: #fff;}
        :nth-child(even) {background: #F4F4F4;}
        :last-child{
            border-radius:0px 0px 10px 10px;
        }
       
@media (max-width:1600px){
    height:48px;
}
@media (max-width:1400px){
    height:44px;
}
    }
}


`
const BillingContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 40px;
    padding: 0px 20px 10px;
    justify-content: flex-start;
    margin-top: 20px;
`

export const BillingHistory = (props: any) => {
    const { workspacename } = props.match.params

    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }

    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        const invoices = PageState.subscription.subscription.stripeSubscription.invoice
        const currentPlanType = PageState.subscription.planDetails.billed

        const planName = PageState.subscription.displayName
        const currentCycleStartDate = stripeUTCDate(PageState.subscription.subscription.current_period_start)
        const currentCycleEndDate = stripeUTCDate(PageState.subscription.subscription.current_period_end)

        const gotoPlans = (planType?: string) => {
            // console.log('planTYpe', planType)
            props.history.push(
                `/workspace/${workspacename}/billing/plans?from=billing&planType=${
                    planType ? planType.toLowerCase() : currentPlanType.toLowerCase()
                }`
            )
        }

        return (
            <div className="plansWrapper">
                <PlansHeaderV2 name={'Billing History'} backButton goBack={() => window.history.back()} />
                <div className="current_plan_wrapper">
                    <BodyWrapperV2>
                        <WrapperV2>
                            <ContainerV2>
                                <BillingWrapper>
                                    <OverviewPlanV2
                                        currentPlan={planName}
                                        currentPeriodEnd={currentCycleEndDate}
                                        currentPeriodStart={currentCycleStartDate}
                                        gotoPlans={gotoPlans}
                                        pricing={{ tag: 'NONE' }}
                                        planReferenceName={PageState.subscription.referenceName}
                                    />
                                    <BillingHistoryTable invoices={invoices} />
                                </BillingWrapper>
                            </ContainerV2>
                        </WrapperV2>
                    </BodyWrapperV2>
                </div>
            </div>
        )
    }
    return <div> UnHandled </div>
}
