import { RawDraftContentBlock } from 'draft-js';
/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components/macro';

import {
  convertActionObjectToArray,
  convertAsaSingleString,
} from '@/Flows/canvas/utils';
import { VideoLink } from '@/Videos/Videos';
import {
  AddIcon,
  CloseBtnWithCircle,
  CloseIcon,
  DeleteIcon,
  DropdownWithCircle,
  InfoIcon,
  KnowledgeSideIcon,
  NextIconCanvas,
  QaIconWithCircle,
} from '@/common/Icons/Icons';
//ICONS IMPORTS
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
//COMMON MODULE IMPORTS
import { Loader } from '@/common/components/Loader';
import lazyGA from '@/common/google-analytics/tracking';
import {
  CancelButton,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
  DropdownWrapper,
  EmptyDiv,
  Header,
  HeaderLeft,
  HoverInputContainer,
  InputContainer,
  InputWrapper,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { DraftInputWrapper } from '@/common/styled/DraftJs.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';

import { Button } from '../../Common/Effective';
// TYPE IMPORTS
import { DialogueT, KnowledgeAINodeT, TextNodeT } from '../../Dialogue/kind';
import {
  DraftJST,
  blocks,
  converterToEditor,
  converterToServer,
} from '../../Editor/Action';
//Rich Editor Imports
import Editor from '../../Editor/Editor';
import { DialogueContext, VariableContext, VariableContextT } from '../Context';
import {
  ErrorState,
  ErrorT,
  KNOWLEDGEAI_RESPONSE,
  TEXT_RESPONSE,
  VIEW,
} from '../kind';
import { logEvent } from '@/common/utils/api-utils';
import { Input } from '@/common/styled/Workflow.Dumb';
import Downshift from 'downshift';
import { FlowInput, NextStep } from './Automation';
import { DialogT } from '@/DialogEditor/Canvas/Components/useFaqState';
import * as ramda from 'ramda';
import {
  createResponseDialogue,
  updateResponseDialogue,
} from '@/Assistant/Mechanisms/action';
import { ActionMeta, MultiValue, default as ReactSelect } from 'react-select';
import { components } from "react-select";

const KBs = [
  { name: 'All', value: 'all' },
  { name: 'Website KB', value: 'website' },
  { name: 'External KB', value: 'external' },
  { name: 'Workativ KB', value: 'workativ' },
];

const KnowledgeAITips = () => (
  <>
    <p>
      <a className="sidepanel_href_color" target="_blank" href="#">
        Knowledge AI
      </a>{' '}
    </p>
    <p></p>
  </>
);

export function grouping(array: any, key: string) {
  if (!array || array.length === 0) {
    return [];
  }

  const filteredValue = ramda.groupBy((x: any) => x[key])(array);

  return convertActionObjectToArray(filteredValue, false);
}

export const OptionWithCheckboxs = (props:any) => {
  return (
    <div className="action_service_desk_list_contanier">
      <components.Option
        {...props}
        className={
          props.isSelected
            ? 'action_service_desk_checked'
            : 'action_service_desk_list'
        }
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="action_check_box"
        />{' '}
        <label>{props.label} </label>
        <span className="multi_select_name_li_icon">
          <NextIconCanvas />
        </span>
        <label>{props.data.label2}</label>
      </components.Option>
    </div>
  )
};
export const MultiValueLabel = (props: any) => {
  const splittedValue = props.data.label.split("-")
  return (
      <components.MultiValueLabel {...props}>
          <div className="action_service_desk_list_contanier ">
              <label>{splittedValue[0]} </label>
              <span className="multi_select_name_li_icon">
                <NextIconCanvas />
              </span>
              <label>{splittedValue[1]?splittedValue[1]:props.data.label2}</label>
          </div>
      </components.MultiValueLabel>
  );
};
export const NoOptionsMessage = (props:any) => {
  const redirectToWorkflow = () => {
    window.location.href = `/workspace/${props.workspaceName}/knowledge/home`
  }

  return (
    <components.NoOptionsMessage {...props}>
      {/* <span className="custom-css-class">No items to select</span>  */}
      <DropdownLi className="d-flex flex-column h-auto p-2 align-items-start">
          <div>No Knowledge base found. Create new Knowledge bases</div>
                                <div
                                    className="workflow_link"
                                    onClick={(event: any) => {
                                        event.stopPropagation()
                                        redirectToWorkflow()
                                    }}
                                >
                                    <a
                                        href="#"
                                    >
                                        <div className="drodown_li_span">
                                            <span>
                                                <KnowledgeSideIcon/>
                                            </span>
                                        </div>
                                        Go to Knowledge AI Builder
                                    </a>
                                </div>
      </DropdownLi>
    </components.NoOptionsMessage>
  );
};


export const InputContent = styled.div`
  color: #999;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 16px;
  transition: 0.26s ease-in;
  ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
    top: 10px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
  }
`;

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 20px;

  h4 {
    font-size: 16px;
    font-family: 'Gordita-Medium';
    color: #000;
  }

  .target.customTip {
    top: 2px;
    margin-left: 5px;
    svg {
      width: 12px;
      height: 12px;
      color: #999;
    }
  }
`;

const SendmMessageWrapper = styled.div`
  width: 100%;
  float: left;
  position: relative;
  background: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;
  .right_pop_icons {
    float: right;
    width: max-content;
    position: absolute;
    right: 32px;
    margin-top: 7px;
    z-index: 1;
    span {
      width: 18px;
      height: 18px;
      border: 1px solid #999;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
      svg {
        fill: #999;
        cursor: pointer;
        width: 8px;
        height: 8px;
      }
      :hover {
        background: #ddfeef;
        border: 1px solid #009d5f;
        svg {
          color: #333;
        }
      }
    }
  }
`;
const SaveMessageWrapper = styled.div`
  width: 100%;
  float: left;
  position: relative;
  background: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;

  .plus_add_more {
    position: absolute;
    right: 32px;
    z-index: 1;
    width: auto;
    top: 27px;
    display: none;
  }
  :hover {
    .plus_add_more {
      display: flex;
    }
  }
`;

export type KBT = {
  id: string;
  name: string;
  status: string;
  type: string;
  workspaceId: string;
  knowledgeBaseCategoryId: string;
  createdAt: string;
  updatedAt: string;
};

export type KBsListT = {
  isActive: boolean;
  id: string;
  name: string;
  type: string;
  group: string;
  knowledgeBase: KBT[];
};

export type OptionStateT = { name: string; value: string };

export function returnKBName(type: string) {
  switch (type) {
    case 'EXTERNAL':
      return 'External KB';
    case 'INTERNAL':
        return 'Internal KB';
    default:
      return 'All';
  }
}

export function list(KBsList: KBsListT[],selectedKBApp:string) {
  return KBsList.filter((k) => k.name === selectedKBApp).reduce(
    (accum: KBT[], v) => [...accum, ...v.knowledgeBase] as KBT[],
    []
  );
}

const KnowledgeAISearch = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<ErrorT>(ErrorState);

  const [optionState, setOptionState] = useState<any>({ optionSelected: null });

  const [updated, setIsupdate] = useState<number>(Math.random());

  const [failureThen, setFailureThen] = useState<string>('');
  const [failureDialog, setFailureDialog] = useState<DialogT>({
    id: '',
    title: '',
    uuid: '',
  });
  const [failureMssage, setFailureMssage] = useState<DraftJST>({
    blocks: [],
    entityMap: {},
  });

  const [successThen, setSuccessThen] = useState<string>('');
  const [successDialog, setSuccessDialog] = useState<DialogT>({
    id: '',
    title: '',
    uuid: '',
  });
  const [successMessage, setSuccessMessage] = useState<DraftJST>({
    blocks: [],
    entityMap: {},
  });

  const [state, setState] = useState<{
    showSelectKB: boolean;
    showSelectApp: boolean;
    showSelectConnection: boolean;
    selectedConnection: string;
    selectedKBApp: string;
    selectedKB: string;
    name: string;
    query: {
      blocks: blocks[];
      entityMap: {};
    };
  }>({
    showSelectKB: false,
    showSelectApp: false,
    name: '',
    selectedKBApp: '',
    selectedKB: 'All',
    showSelectConnection: false,
    selectedConnection: '',
    query: {
      blocks: [],
      entityMap: {},
    },
  });

  const context = useContext(DialogueContext);

  const {
    workspaceName,
    setPageState,
    PageState,
    jumptoRoutes,
    fetchCallback,
    dialogueName,
    assistantID,
    dialogueNode,
    queryClient,
    parentIfnode
  } = context;
  const { data } = PageState as KNOWLEDGEAI_RESPONSE;
  const KBsList = context.KBsList as KBsListT[];
  useEffect(() => {
    callKBsAPI();
  }, []);

  function callKBsAPI() {
    // console.log("API::DATA", data)
    Promise.resolve(KBsList as KBsListT[])
      .then((resp) => {
        const d = data as any;
        if (d.id != '') {
          setSuccessMessage(
            converterToEditor(
              d.on_sucess.response,
              context,
              context.dialogueNode
            )
          );
          setFailureMssage(
            converterToEditor(
              d.on_failure.response,
              context,
              context.dialogueNode
            )
          );
          setSuccessThen(d.on_sucess.next_step['jump_to'] ? 'jump_to' : 'exit');
          setFailureThen(
            d.on_failure.next_step['jump_to'] ? 'jump_to' : 'exit'
          );
          d.on_sucess.next_step['jump_to'] &&
            setSuccessDialog({
              id: d.on_sucess.next_step['jump_to'].to,
              title: (jumptoRoutes as DialogueT[]).filter(
                (dial) =>
                  dial.id == d.on_sucess.next_step['jump_to'].to ||
                  dial.uuid == d.on_sucess.next_step['jump_to'].to
              )[0]
                ? (jumptoRoutes as DialogueT[]).filter(
                    (dial) =>
                      dial.id == d.on_sucess.next_step['jump_to'].to ||
                      dial.uuid == d.on_sucess.next_step['jump_to'].to
                  )[0].title
                : '',
              uuid: d.on_sucess.next_step['jump_to'].to,
            });
          d.on_failure.next_step['jump_to'] &&
            setFailureDialog({
              id: d.on_failure.next_step['jump_to'].to,
              title: (jumptoRoutes as DialogueT[]).filter(
                (dial) =>
                  dial.id == d.on_failure.next_step['jump_to'].to ||
                  dial.uuid == d.on_failure.next_step['jump_to'].to
              )[0]
                ? (jumptoRoutes as DialogueT[]).filter(
                    (dial) =>
                      dial.id == d.on_failure.next_step['jump_to'].to ||
                      dial.uuid == d.on_failure.next_step['jump_to'].to
                  )[0].title
                : '',
              uuid: d.on_failure.next_step['jump_to'].to,
            });
          const isAll = d.source === 'All';
          let selectedKBApp = '';
          let selectedConnection = '';
          const kbs = resp
            .reduce(
              (accum: KBT[], v) => [...accum, ...v.knowledgeBase] as KBT[],
              []
            )
            .filter((kbs) => data.bases.includes(kbs.id));

            if (!isAll && kbs.length) {
            selectedKBApp = resp.filter(
              (kb) => kb.id === kbs[0].knowledgeBaseCategoryId
            )[0].name;
            selectedConnection = kbs[0].id;
            setState({
              ...state,
              selectedKB: d.source,
              selectedKBApp,
              selectedConnection,
              name: d.name,
              query: data.query
                ? converterToEditor(data.query, context, dialogueNode)
                : {
                    blocks: [],
                    entityMap: {},
                  },
            });
          } else {
            const selectedOption = kbs.map((kb) => {
              const op = resp.filter(
                (k) => k.id === kb.knowledgeBaseCategoryId
              )[0];
              return {
                value: kb.id,
                label: `${returnKBName(op.group)} - ${kb.name}`,
              };
            });

            setState({
              ...state,
              selectedKB: d.source,
              name: d.name,
              query: data.query
                ? converterToEditor(data.query, context, dialogueNode)
                : {
                    blocks: [],
                    entityMap: {},
                  },
            });

            setOptionState({ optionSelected: selectedOption } );
          }
        }
      })
      .finally(() => {
        // for updating draftjs
        setIsupdate(Math.random());
      });
  }

  const handleSave = async () => {
    // return
    setLoading(true);

    const req = {
      ...data,
      name: state.name,
      bases:
        state.selectedKB !== 'All'
          ? [state.selectedConnection]
          : optionState.optionSelected.map((op: OptionStateT) => op.value),
      source: state.selectedKB,
      on_sucess: {
        response: { type:"path", path:["SYS_knowledge_ai_search_text"] },
        next_step:
          successThen == 'jump_to'
            ? {
                jump_to: {
                  to: successDialog.id,
                  selector: 'body',
                },
              }
            : {
                exit: null,
              },
      },
      on_failure: {
        response: converterToServer(failureMssage),
        next_step:
          failureThen == 'jump_to'
            ? {
                jump_to: {
                  to: failureDialog.id,
                  selector: 'body',
                },
              }
            : {
                exit: null,
              },
      },
      query: converterToServer(state.query),
    };
    console.log(req, 'req');
    // return;
    try {
      if (data.id == '') {
        await createResponseDialogue(
          workspaceName,
          assistantID,
          data.parent,
          req as KnowledgeAINodeT,
          dialogueName
        );
        await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
        await queryClient.invalidateQueries(['assistants'])

        logEvent(workspaceName, {
          event: 'EVENT::DIALOG::ADD_SEND_MESSAGE',
          event_meta: {
            name: dialogueName,
            description: '',
          },
        });
        lazyGA().event({
          category: 'Dialog Editor',
          action: 'Send Message Node Created',
        });
      } else {
        await updateResponseDialogue(
          workspaceName,
          assistantID,
          data.parent,
          data.id,
          req as KnowledgeAINodeT,
          dialogueName
        );
        await queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as any).uuid])
        await queryClient.invalidateQueries(['assistants'])
      
      }
      fetchCallback(() => setLoading(false));
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError({
        error: true,
        info: 'Something went wrong. Please try again!',
      });
    }
  };

  return (
    <Wrapper>
      <PopupWrapper>
        <PopupContainer>
          <Header>
            <HeaderLeft minWidth={300}>
              Knowldge AI Search
              {/* <Tooltip
                className="target customTip"
                zIndex={10000}
                arrowSize={8}
                tagName="span"
                content={<KnowledgeAITips />}
                distance={5}
              >
                <InfoIcon />
              </Tooltip> */}
              <a href="#" target="_blank" rel="noopener" title="Send message">
                <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content="View help document"
                  distance={5}
                >
                  <QaIconWithCircle />
                </Tooltip>
              </a>
            </HeaderLeft>
            <HeaderRight>
              <VideoLink id={'SEND_MESSAGE'} text={'How to'} />
            </HeaderRight>
          </Header>
          <Paragraph>Execute knowledge base search.</Paragraph>
          <PopupScrollHeight>
            <ErrorBoundary
              error={error}
              render={(err: any, info: any) => {
                return (
                  err && (
                    <Error.Delete
                      onClick={() => setPageState(VIEW)}
                      info={info}
                    />
                  )
                );
              }}
            >
              {loading ? (
                <div className="popup_loader">
                  <Loader.PopupLoader show={loading} />
                </div>
              ) : (
                <React.Fragment>
                  <EmptyDiv>
                    <BgWrapper>
                      <DropdownWrapper>
                        <InputContainer>
                          <Input
                            className={``}
                            type="text"
                            value={returnKBName(state.selectedKB)}
                            onClick={() =>
                              setState({ ...state, showSelectKB: true })
                            }
                          />
                          <InputContent>Select knowledge source</InputContent>
                          <CancelButton
                            className="d-flex"
                            onClick={() =>
                              setState({ ...state, showSelectKB: true })
                            }
                          >
                            <DropdownWithCircle />
                          </CancelButton>
                          {state.showSelectKB && (
                            <DropdownUlWrapper>
                              <Downshift
                                isOpen={true}
                                onOuterClick={() =>
                                  setState({ ...state, showSelectKB: false })
                                }
                              >
                                {() => (
                                  <div className="automation_scroly">
                                    <DropdownUl>
                                      <DropdownLi
                                        onClick={() => {
                                          setState({
                                            ...state,
                                            showSelectKB: false,
                                            selectedKB: 'All',
                                            selectedKBApp: '',
                                            selectedConnection: ""
                                          });
                                        }}
                                      >
                                        {returnKBName('All')}
                                      </DropdownLi>
                                      {grouping(KBsList, 'group').map(
                                        (kb, i) => (
                                          <DropdownLi
                                            key={i}
                                            onClick={() => {
                                              setState({
                                                ...state,
                                                showSelectKB: false,
                                                selectedKB: kb.name,
                                                selectedKBApp: '',
                                                selectedConnection:""
                                              });
                                              setOptionState({
                                                optionState: null,
                                              });
                                            }}
                                          >
                                            {returnKBName(kb.name)}
                                          </DropdownLi>
                                        )
                                      )}
                                    </DropdownUl>
                                  </div>
                                )}
                              </Downshift>
                            </DropdownUlWrapper>
                          )}
                        </InputContainer>

                        {state.selectedKB !== 'All' ? (
                          <InputContainer>
                            <Input
                              className={``}
                              type="text"
                              value={state.selectedKBApp}
                              onClick={() =>
                                setState({ ...state, showSelectApp: true })
                              }
                            />
                            <InputContent>Select knowledge base</InputContent>
                            <CancelButton
                              className="d-flex"
                              onClick={() =>
                                setState({ ...state, showSelectApp: true })
                              }
                            >
                              <DropdownWithCircle />
                            </CancelButton>
                            {state.showSelectApp && (
                              <DropdownUlWrapper>
                                <Downshift
                                  isOpen={true}
                                  onOuterClick={() =>
                                    setState({ ...state, showSelectApp: false })
                                  }
                                >
                                  {() => (
                                    <div className="automation_scroly">
                                      <DropdownUl>
                                        {grouping(
                                          KBsList.filter(
                                            (k) => k.group === state.selectedKB
                                          ),
                                          'name'
                                        ).map((kb, i) => (
                                          <DropdownLi
                                            key={i}
                                            onClick={() => {
                                              setState({
                                                ...state,
                                                showSelectApp: false,
                                                selectedKBApp: kb.name,
                                              });
                                            }}
                                          >
                                            {kb.name}
                                          </DropdownLi>
                                        ))}
                                      </DropdownUl>
                                    </div>
                                  )}
                                </Downshift>
                              </DropdownUlWrapper>
                            )}
                          </InputContainer>
                        ) : (
                          <InputContainer>
                            {console.log(
                              optionState.optionSelected,
                              'optionSelected'
                            )}
                            <ReactSelect
                              options={ramda.reduce(
                                (acc: OptionStateT[], op: KBsListT) => {
                                  return [
                                    ...acc,
                                    ...op.knowledgeBase.map((kb) => ({
                                      value: kb.id,
                                      label: `${returnKBName(op.group)}`,
                                      label2: kb.name
                                    })),
                                  ] as OptionStateT[];
                                },
                                [] as OptionStateT[],
                                KBsList
                              )}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option: OptionWithCheckboxs,
                                NoOptionsMessage:(props:any)=><NoOptionsMessage {...props} workspaceName={workspaceName}/>,
                                MultiValueLabel
                              }}
                              styles={{
                                multiValueLabel: (base) => ({
                                  ...base,
                                  backgroundColor: "red",
                                  color: 'white',
                                }),
                              }}
                              onChange={(
                                newValue: MultiValue<any>,
                                actionMeta: ActionMeta<any>
                              ) => {
                                setOptionState({
                                  optionSelected: newValue,
                                });
                              }}
                              value={optionState.optionSelected}
                              placeholder={'Select knowledge base'}
                              className="action_select_deck multi_select_list"
                            />

                              <InputContent style={{top: 6, fontSize: 10}}>Select knowledge base</InputContent>
                          </InputContainer>
                        )}

                        {state.selectedKBApp.length > 0 && (
                          <InputContainer>
                            <Input
                              className={``}
                              type="text"
                              value={
                                list(KBsList,state.selectedKBApp).filter(
                                  (c) => c.id === state.selectedConnection
                                ).length
                                  ? list(KBsList,state.selectedKBApp).filter(
                                      (c) => c.id === state.selectedConnection
                                    )[0].name
                                  : ''
                              }
                              onClick={() =>
                                setState({
                                  ...state,
                                  showSelectConnection: true,
                                })
                              }
                            />
                            <InputContent>Select connection name</InputContent>
                            <CancelButton
                              className="d-flex"
                              onClick={() =>
                                setState({
                                  ...state,
                                  showSelectConnection: true,
                                })
                              }
                            >
                              <DropdownWithCircle />
                            </CancelButton>
                            {state.showSelectConnection && (
                              <DropdownUlWrapper>
                                <Downshift
                                  isOpen={true}
                                  onOuterClick={() =>
                                    setState({
                                      ...state,
                                      showSelectConnection: false,
                                    })
                                  }
                                >
                                  {() => (
                                    <div className="automation_scroly">
                                      <DropdownUl>
                                        {list(KBsList,state.selectedKBApp).map((kb, i) => (
                                          <DropdownLi
                                            key={i}
                                            onClick={() => {
                                              setState({
                                                ...state,
                                                showSelectConnection: false,
                                                selectedConnection: kb.id,
                                              });
                                            }}
                                          >
                                            {kb.name}
                                          </DropdownLi>
                                        ))}
                                      </DropdownUl>
                                    </div>
                                  )}
                                </Downshift>
                              </DropdownUlWrapper>
                            )}
                          </InputContainer>
                        )}
                        <InputContainer style={{marginBottom: 0}}>
                          <Input
                            type="text"
                            value={state.name}
                            onChange={(e: any) => {
                              setState({ ...state, name: e.target.value });
                            }}
                          />
                          <InputContent>Name this search function</InputContent>
                        </InputContainer>
                      </DropdownWrapper>
                    </BgWrapper>

                    <BgWrapper style={{ marginTop: 20 }}>
                      <h4>User query</h4>
                     
                      <DropdownWrapper>
                        <InputContainer style={{marginBottom: 0}}>
                          <DraftInputWrapper className="draft_focus_content">
                            <Editor
                              focusContent={'Search input'}
                              DraftMap={state.query}
                              setDraftMap={(draft: DraftJST) =>
                                setState({ ...state, query: draft })
                              }
                              hideToolbar
                              focus={
                                convertAsaSingleString(
                                  state.query.blocks as RawDraftContentBlock[]
                                ).replace(/\s/g, '').length
                                  ? 'focus_input'
                                  : ''
                              }
                              updated={updated}
                            />
                          </DraftInputWrapper>
                        </InputContainer>
                      </DropdownWrapper>
                    </BgWrapper>

                    <BgWrapper style={{ marginTop: 20, padding: 0 }}>
                      <InputWrapper>
                        <Header>
                          Answer{' '}
                          <Tooltip
                            className="target customTip knowledge_tooltip"
                            zIndex={10000}
                            arrowSize={8}
                            tagName="span"
                            content="Setup how you want the bot to respond if workflow executed successfully"
                            distance={3}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Header>
                        <HoverInputContainer>
                          {/* <InputContainer>
                            <FlowInput
                              input={'Send message to user'}
                              value={successMessage}
                              onChange={(value: DraftJST) => {
                                setSuccessMessage(value);
                              }}
                              updated={updated}
                            />
                          </InputContainer> */}
                          <InputContainer>
                          <DraftInputWrapper className="draft_focus_content">
                            <Editor
                              focusContent={'Send message to user'}
                              DraftMap={
                                {
                                  blocks: [
                                    {
                                      key: 'rw8fsi8h5g',
                                      text: 'Knowledge AI Answer',
                                      type: 'unstyled',
                                      depth: 0,
                                      inlineStyleRanges: [],
                                      entityRanges: [
                                        {
                                          offset: 0,
                                          length: 19,
                                          key: 0,
                                        },
                                      ],
                                      data: {},
                                    },
                                  ],
                                  entityMap: {
                                    '0': {
                                      type: 'VARIABLE',
                                      mutability: 'IMMUTABLE',
                                      data: {
                                        value: 'SYS_knowledge_ai_search_text',
                                        visible: 'SYS_knowledge_ai_search_text',
                                        icon: '/static/media/logo.35c69758a3bb2740e612.png',
                                      } as any,
                                    },
                                  },
                                } as any
                              }
                              setDraftMap={(draft: DraftJST) => {}}
                              className="editor_btn_disabled"
                              hideToolbar
                              focus={
                               'focus_input'
                              }
                            />
                          </DraftInputWrapper>
                        </InputContainer>
                          <NextStep
                            then={successThen}
                            dialog={successDialog}
                            setDialog={setSuccessDialog as any}
                            GetDialogs={() => {}}
                            dialogs={jumptoRoutes}
                            setThen={setSuccessThen as any}
                            workspacename={workspaceName}
                            dialougeId={successDialog.id}
                          />
                        </HoverInputContainer>
                      </InputWrapper>
                      {/* <InputWrapper>
                        <Header className='kanoledge_answer'>
                          <div>
                            Answer link{' '}
                            <Tooltip
                              className="target customTip"
                              zIndex={10000}
                              arrowSize={8}
                              tagName="span"
                              content="test"
                              distance={3}
                            >
                              <InfoIcon />
                            </Tooltip>
                            </div>
                              <div className="show_hide">
                                  <div className="switch_box box_1">
                                      <input
                                          type="checkbox"
                                          className={`switch_1`}
                                          // checked={maskInput}
                                          // onChange={() => {
                                          //     setMaskInput(!maskInput)
                                          // }}
                                      />
                                  </div>
                              </div>
                          </Header>

                      </InputWrapper> */}
                      {/* <InputWrapper>
                        <Header className='kanoledge_answer'>
                          <div>
                            Get user feedback upon exit{' '}
                            <Paragraph style={{fontSize: 11}}>Note: You can edit feedback messages in the <span style={{marginLeft: 0}}><a href="#">settings</a></span> page.</Paragraph>
                            </div>
                              <div className="show_hide">
                                  <div className="switch_box box_1">
                                      <input
                                          type="checkbox"
                                          className={`switch_1`}
                                          // checked={maskInput}
                                          // onChange={() => {
                                          //     setMaskInput(!maskInput)
                                          // }}
                                      />
                                  </div>
                              </div>
                          </Header>
                          <InputContainer>
                          <Input
                            type="text"
                          />
                          </InputContainer>
                          
                      </InputWrapper> */}
                    </BgWrapper>

                    <BgWrapper style={{ marginTop: 20, padding: 0 }}>
                    <InputWrapper>
                      <Header>
                        Answer not found{' '}
                        <Tooltip
                          className="target customTip knowledge_tooltip"
                          zIndex={10000}
                          arrowSize={8}
                          tagName="span"
                          content="Setup how you want the bot to respond if workflow execution failed"
                          distance={3}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Header>
                      <HoverInputContainer>
                        <InputContainer>
                          <FlowInput
                            input={'Send message to user'}
                            value={failureMssage}
                            onChange={(value: DraftJST) => {
                              setFailureMssage(value);
                            }}
                            updated={updated}
                          />
                        </InputContainer>
                        <NextStep
                          then={failureThen}
                          dialog={failureDialog}
                          setDialog={setFailureDialog as any}
                          GetDialogs={() => {}}
                          dialogs={jumptoRoutes}
                          setThen={setFailureThen as any}
                          workspacename={workspaceName}
                          dialougeId={failureDialog.id}
                        />
                      </HoverInputContainer>
                    </InputWrapper>
                    </BgWrapper>
                  </EmptyDiv>


                  <Button
                    className="footer_button_fixed"
                    // disable={
                    //     values.filter(data => data.blocks[0].text !== '').length !== values.length ||
                    //     alreadyOpen
                    // }
                    disable={
                      state.name.length == 0 ||
                      (state.selectedKB === 'All' &&
                        (optionState.optionSelected == null||optionState.optionSelected.length==0)) || 
                        (state.selectedKB != "All" && state.selectedConnection.length == 0) ||
                      !convertAsaSingleString(
                        state.query.blocks as RawDraftContentBlock[]
                      ).replace(/\s/g, '').length ||
                      // !convertAsaSingleString(
                      //   successMessage.blocks as RawDraftContentBlock[]
                      // ).replace(/\s/g, '').length ||
                      !convertAsaSingleString(
                        failureMssage.blocks as RawDraftContentBlock[]
                      ).replace(/\s/g, '').length ||
                      (successThen == 'jump_to' && successDialog.uuid == '') ||
                      (failureThen == 'jump_to' && failureDialog.uuid == '')
                    }
                    onCancel={() => setPageState(VIEW)}
                    onSave={handleSave}
                  />
                </React.Fragment>
              )}
            </ErrorBoundary>
          </PopupScrollHeight>
        </PopupContainer>
      </PopupWrapper>
    </Wrapper>
  );
};

const TextResponseCard = ({
  value,
  values,
  index,
  deleteMe,
  onchange,
  isSomeOneOpen,
  heyImOpen,
  updated,
}: {
  value: DraftJST;
  values: DraftJST[];
  index: string;
  deleteMe: Function;
  onchange: Function;
  isSomeOneOpen: boolean;
  heyImOpen: Function;
  updated: number;
}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const [initValue, setInitValue] = useState<DraftJST>();

  const [draft, setDraft] = useState<DraftJST>(value);
  const [updatedNewValue, setUpdated] = useState(updated);

  // useEffect(() => {
  //     setInitValue(value)
  // }, [])

  useEffect(() => {
    setUpdated(updated);
  }, [updated]);

  return (
    <div className="text_response">
      {/* {mode == 'view' ? ( */}
      <SaveMessageWrapper>
        <div className="plus_add_more">
          {!isSomeOneOpen ? (
            <div className="right_pop_icons">
              {values.length > 1 ? (
                <span
                  className="send_message_close_icon"
                  onClick={() => deleteMe()}
                >
                  <CloseIcon />
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
        <DraftInputWrapper>
          <Editor
            DraftMap={value}
            setDraftMap={onchange}
            focusContent={`Message ${index}`}
            updated={updatedNewValue}
            focus={
              convertAsaSingleString(
                value.blocks as RawDraftContentBlock[]
              ).replace(/\s/g, '').length
                ? 'focus_input'
                : ''
            }
          />
        </DraftInputWrapper>
      </SaveMessageWrapper>
      {/* ) : (
                <SendmMessageWrapper>
                    <div className="right_pop_content">
                        <div className="textarea_dropdown">
                            <div className="send_msg_dialog">
                                <div className="right_pop_icons">
                                    <span
                                        onClick={() => {
                                            setMode('view')
                                            heyImOpen(false)
                                            onchange(draft)
                                        }}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {values.length > 1 ? (
                                        <span
                                            onClick={() => {
                                                if (initValue && initValue.blocks[0].text === '') {
                                                    heyImOpen(false)
                                                    deleteMe()
                                                } else {
                                                    setMode('view')
                                                    heyImOpen(false)
                                                    onchange(initValue)
                                                    if (initValue) setDraft(initValue)
                                                }
                                            }}
                                        >
                                            <CrossIcon />
                                        </span>
                                    ) : null}
                                </div>
                                <DraftInputWrapper>
                                    <Editor
                                        DraftMap={draft}
                                        setDraftMap={setDraft}
                                        focusContent={`Message ${index}`}
                                        focus={
                                            convertAsaSingleString(draft.blocks as RawDraftContentBlock[]).replace(
                                                /\s/g,
                                                ''
                                            ).length
                                                ? 'focus_input'
                                                : ''
                                        }
                                    />
                                </DraftInputWrapper>
                            </div>
                        </div>
                    </div>
                </SendmMessageWrapper>
            )} */}
    </div>
  );
};

export default KnowledgeAISearch;
