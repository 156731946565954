/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { getAssistants, getDialogues } from '@/Assistant/Mechanisms/action';
import { useGenericCached } from '@/common/hooks/useCachedQuery';
import {
  FetchReducerT,
  initialLoadingData,
} from '@/common/hooks/usefetch-from-api';
import {
  AutomationApi,
  automationAPIURLWithWorkspace,
  wrapperGetJson,
} from '@/common/utils/api-utils';

import { usePermissions } from '@/common/utils/auth-token-api-utils';

import { getProductId } from '@/common/utils/utils';

import { Flow } from './Flow';
import { fetchRoots, mockLinkingData } from './utils';

const R = require('ramda');

export const getDialogData = async (workspaceName: string) => {
  const productId = getProductId();

  console.log('ProductId :: ', productId);

  if (productId === 'AUTOMATE') {
    return [];
  } else {
    const MOCK_DIALOG_API = false;
    if (MOCK_DIALOG_API) {
      return Promise.resolve(mockLinkingData);
      // return Promise.reject({ type: 'error', error: 'site is down' })
    } else {
      const assis = await getAssistants(workspaceName);
      const bots = assis.map((x) => ({ id: x.id, name: x.name }));

      const allNodes = await Promise.all(
        bots.map((x) => getDialogues(workspaceName, x.id))
      );
      const toReturn = allNodes
        .map((x, i) => {
          const bot = bots[i];
          return x.map((item: any) => ({
            ...item,
            botName: bot.name,
            botId: bot.id,
          }));
        })
        .reduce(function (a: any, b: any) {
          return [...a, ...b];
        }, []);
      return toReturn;
    }
  }

  //return MOCK_DIALOG_API ? Promise.resolve(mockLinkingData) : Promise.resolve(mockLinkingData)
};

export const loadAutomations = (wName: string) =>
  wrapperGetJson(
    automationAPIURLWithWorkspace(wName, AutomationApi.GetAll)
  ).then((resp) => {
    return getDialogData(wName)
      .then((dialogs) => {
        const response = resp.data;
        if (response.type === 'success') {
          const automations: any[] = response.output.automations;
          const sortedList: any[] = response.output.sortedList;

          // console.log('sortedList', sortedList)

          automations.map((automation: any) => {
            automation.linkedDialogs = fetchRoots(dialogs, automation.id);
            //console.log('autimations linked', automation.linkedDialogs)
            return automation;
          });
          sortedList.map((sAutomation: any) => {
            //console.log('Automations', response.output)
            sAutomation.linkedDialogs = fetchRoots(dialogs, sAutomation.id);
            //console.log('autimations linked', automation.linkedDialogs)
            return sAutomation;
          });
          // response.output.automations = []
          // response.output.sortedList = []
          return response;
        } else {
          return response;
        }
      })
      .catch((_) => {
        //console.log('err', _)
        return Promise.reject({ type: 'error', error: _ });
      });
  });

export function FlowWrapper(props: any) {
  const workspaceName = props.match.params.workspacename;

  const queryClient = useQueryClient();

  // const [state, setState, promiseRef, _ , dispatch] = useFetchFromApi(() => loadAutomations(workspaceName))

  const automationData = useGenericCached(
    'workflows',
    () =>
      wrapperGetJson(
        automationAPIURLWithWorkspace(workspaceName, AutomationApi.GetAll)
      ),
    {
      refetchOnMount: true,
      staleTime: 0,
    },
    {
      everyTime: true,
    }
  );

  const [cachedData, setCachedData] =
    useState<FetchReducerT>(initialLoadingData);

  const dialogData = useGenericCached('workflow-bots', () =>
    getDialogData(workspaceName)
  );

  // console.log("dialogData",dialogData)

  useEffect(() => {
    if (
      automationData.status === 'fetched' &&
      dialogData.status === 'fetched'
    ) {
      const response = automationData.data.data;
      const dialogs = dialogData.data;

      console.log('dialogs', dialogData);
      if (response.type === 'success') {
        const automations: any[] = response.output.automations;
        const sortedList: any[] = response.output.sortedList;

        // console.log('sortedList', sortedList)

        automations.map((automation: any) => {
          automation.linkedDialogs = fetchRoots(dialogs, automation.id);
          //console.log('autimations linked', automation.linkedDialogs)
          return automation;
        });
        sortedList.map((sAutomation: any) => {
          //console.log('Automations', response.output)
          sAutomation.linkedDialogs = fetchRoots(dialogs, sAutomation.id);
          //console.log('autimations linked', automation.linkedDialogs)
          return sAutomation;
        });
        // response.output.automations = []
        // response.output.sortedList = []
        setCachedData({
          type: 'success',
          data: response.output,
        });
      } else {
        setCachedData({
          type: 'success',
          data: response.output,
        });
      }
    } else {
      setCachedData(initialLoadingData);
    }
  }, [automationData.status, dialogData.status]);

  const retry = () => {
    queryClient.invalidateQueries('workflows');
  };

  const invalidate = (id?: string) => {
    // console.log('COG1-7200 :: invalidate workflow query')
    // queryClient.invalidateQueries('bots')
    queryClient.invalidateQueries('workflows');

    if (id) {
      // console.log('COG1-7200 :: invalidate workflow id')
      queryClient.invalidateQueries(`workflow::${id}`);
    }
  };
  // const newState = state as FetchReducerT

  //console.log('newState', newState)
  return (
    <Flow
      state={cachedData}
      retry={invalidate}
      invalidate={invalidate}
      {...props}
    />
  );
}

export function ProtectedFlowWrapper(props: any) {
  // const workspaceName = props.match.params.workspacename;
  // const moduleName = 'Workflow';
  // const permissions = 'VIEW';

  // const hasPermission = usePermissions(workspaceName, moduleName, permissions);

  // if (hasPermission.value === null) {
  //   return <div>Loading permissions</div>;
  // }

  // if (hasPermission.value === true) {
    return <FlowWrapper {...props} />;
  // }
  // return <div>You do not have access to this module</div>;
}
