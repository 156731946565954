/*eslint-disable*/
import produce from 'immer'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useJourney } from '@/Journey/JourneyContext'
import { VideoLinkSimple } from '@/Videos/Videos'
import { DownarrowWithCircle, NextDetailICon, TestWorkflowIcon, TimerNodeIcon } from '@/common/Icons/Icons'
import { ApprovalIcon, ForEachNodeIcon, FunctionNodeIcon, IfNodeIcon, NotificationNodeIcon } from '@/common/Icons/Workflow.Icons'
import { Loader } from '@/common/components/Loader'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonContainer, SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'
import { Input, InputContainer, InputContent, InputWrapper } from '@/common/styled/Workflow.Dumb'

import { convertObjectToArray, convertToMDText, convertToSingleStringforTryme, delay } from '@/common/utils/utils'
import { automationAPIURLWithWorkspace, getJson, logEvent, loginApiURL, postJson, getProductId } from '@/common/utils/api-utils'
import { ContextOutputTree, OutputKeyDetailedView } from './ContextPopup'
import { useDropDown } from './Tree'
import {
    ActionNodeLogT,
    ApprovalNodeLogT,
    AuthType,
    ConditionNodeLogT,
    EndNodeLogT,
    EventTriggerNodeLogT,
    ForEachNodeLogT,
    InputSchema,
    LogsT,
    NodeTypeT,
    NormalViewActionNodeLogT,
    ObjectOutputListTypeT,
    OutputListTypeT,
    PollingNodeLogT,
    TriggerLogsT,
    WebHookNodeLogT,
    outputConfig,
    TimerNodeLogT,
    TreeT,
    NodeT,
    NotificationNodeLogT
} from './types'
import AssistantMd from '@skitter/assistant_md'

const InputRuntimelogs = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 0px;
    :last-child {
        margin-bottom: 0px;
    }
    ${InputWrapper} {
        background: #fff;
        padding: 0px;
    }
    ${Input} {
        width: 100%;
    }
`

const JSONPretty = require('react-json-pretty')

export const AutomationTestPanel = (props: {
    closeTestingPanel: () => void
    match: any
    flowName: string
    flowtype: string
    state: any
    webhookTrigger: boolean;
    scope: "AUTOMATE" | "ASSISTANT",
    eventTrigger: boolean
    treeData:TreeT<NodeT>
}) => {
    const { closeTestingPanel, flowtype } = props

    const journeyData = useJourney()

    const [hasTested, setHasTested] = useState(false)

    const intervalRef = useRef<null | NodeJS.Timeout>(null)
    const intervalApprovalRef = useRef<null | NodeJS.Timeout>(null)

    const timeoutRef = useRef<null | NodeJS.Timeout>(null)

    // states
    const [developerView, setView] = useState(false)

    const [subscriptionId, setSubscriptionId] = useState<null | string>(null)

    const [currentJob, setCurrentJob] = useState<number>(0)

    const [config, setConfig] = useState<outputConfig>({ type: '', output: {} })

    const [executionStatus, setExecutionStatus] = useState<any>({})

    // const [logs, setLogs] = useState<LogsT | TriggerLogsT>(
    const [logs, setLogs] = useState<any>(
        flowtype != 'trigger'
            ? {
                  type: '',
                  output: {
                      developerView: [],
                      normalView: [],
                      status: '-'
                  }
              }
            : {
                  type: '',
                  output: []
              }
    )

    const [minOrMax, setMinOrMax] = useState('minimized')

    const [executionState, setExecutionState] = useState({ loading: false, error: false })

    // states

    useEffect(() => {
        flowtype !== 'trigger' ? callApi() : executeLogs()
        return () => {
            intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
        }
    }, [])

    const automationId = props.match.params.id
    const workspaceName = props.match.params.workspacename

    const triggerJSON =
        props.treeData.value.meta_data ? props.treeData.value :
            props.flowtype != 'notrigger' &&
            props.state &&
            props.state.data &&
            props.state.data.output &&
            props.state.data.output.custom_json &&
            props.state.data.output.custom_json.value
                ? props.state.data.output.custom_json.value
                :  null
    const triggerName = triggerJSON && triggerJSON.name ? triggerJSON.name : ''

    const appName =
        triggerJSON && triggerJSON.meta_data && triggerJSON.meta_data.app && triggerJSON.meta_data.app.appName
            ? triggerJSON.meta_data.app.appName
            : ''

    let triggerLogs: null | TriggerLogsT = null

    if (flowtype !== 'notrigger') {
        triggerLogs = logs as TriggerLogsT
    }

    const callApi = async () => {
        const configuration: outputConfig = await getJson(
            automationAPIURLWithWorkspace(workspaceName, '/automation/' + automationId + '/config')
        )
        setConfig(configuration)
    }

    const toObject = (array: any) => {
        let object = undefined

        for (let i in array) {
            if (!object) {
                object = array[i]
            } else {
                const key = Object.keys(array[i])[0]
                const value = Object.values(array[i])[0]
                object[key] = value
            }
        }

        return object
    }

    const executeLogs = () => {
        console.log("CALLING EXECUTE LOGS")
        let input: any[] = []

        if (flowtype !== 'trigger') {
            const mappingArray = convertObjectToArray(config.output)

            input = mappingArray.map((x) => {
                const value = props.webhookTrigger
                  ? { [`${x.value.title}`]: x.value.value }
                  : { [`${x.name}`]: x.value.value };
        
                return value;
            });
        }

        setExecutionState({ ...executionState, loading: true })

        setLogs({
            type: '',
            output: {
                developerView: [],
                normalView: [],
                status: 'in progress'
            }
        })

        setExecutionStatus({})
        if (flowtype !== "trigger") {
            // Promise.resolve({ email: 'selva.g@workativ.com' })
            //     .then(res => {
             getJson(loginApiURL('/user_info'))
                .then(res => {
                    const requestWithEmail = {
                        automation: automationId,
                        platform: 'test',
                        input: toObject(input) ? toObject(input) : {}
                    }

                    const request = res.email ? { ...requestWithEmail, executed_by: res.email } : requestWithEmail
                    // console.log('response :::::: 1', requestWithEmail)

                    return postJson(automationAPIURLWithWorkspace(workspaceName, '/automation/execute'))(request).then(
                        response => {
                            console.log('response', response.data)
                            setExecutionStatus(response.data)
                            logEvent(workspaceName, {
                                event:"EVENT::WORKFLOW::EXECUTE", event_meta:{
                                name: props.flowName,
                                description: ""
                            }})
                            if (!response.data.error && (response.data.output.executionId || response.data.output.jobId)) {
                                setHasTested(true)
                                if (journeyData.type === 'success') {
                                    journeyData.value.send({
                                        type: 'EVENT::WORKFLOW::TESTED',
                                        tag: 'WORKFLOW_GENERIC_EVENT',
                                        journeyType: 'Workflow'
                                    })
                                }
                                // to handle polling trigger if jobid exists
                                // response.data.output.executionId ? 
                                callLogsApi(response.data.output.executionId)
                                //  : callPollingApi(response.data.output.jobId)
                            } else {
                                // console.log('response :::::: 2', response)
                                if (
                                    response.data &&
                                    response.data.error &&
                                    response.data.tag === 'SUBSCRIPTION_STATUS_IS_NOT_VALID'
                                ) {
                                    const errorMessage = `Your subscription for ${workspaceName} has expired. Kindly upgrade your plan to continue execution.`
                                    setExecutionState({
                                        loading: false,
                                        error: errorMessage as any
                                    })
                                    return
                                }

                                setExecutionState({
                                    loading: false,
                                    error:
                                        response.data && response.data.error
                                            ? response.data.error
                                            : 'Something went wrong! Please try again after some time.'
                                })
                            }
                        }
                    )
                })
                .catch(err => {
                    // console.log('error :::::: 2', err)
                    setExecutionStatus({})
                    logEvent(workspaceName, {
                        event:"EVENT::WORKFLOW::EXECUTE", event_meta:{
                        name: props.flowName,
                        description: ""
                    }})
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.error &&
                        err.response.data.tag === 'SUBSCRIPTION_STATUS_IS_NOT_VALID'
                    ) {
                        const errorMessage = `Your subscription for ${workspaceName} has expired. Kindly upgrade your plan to continue execution.`
                        setExecutionState({
                            loading: false,
                            error: errorMessage as any
                        })
                        return
                    }
                    setExecutionState({
                        loading: false,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong! Please try again after some time.'
                    })
                })
        } else {
            // const request = { automation: automationId }
            // Promise.resolve({ email: 'selva.g@workativ.com' })
            getJson(loginApiURL('/user_info'))
            .then(res => {
                const request = res.email
                    ? { automation: automationId, executed_by: res.email }
                    : { automation: automationId }
                postJson(automationAPIURLWithWorkspace(workspaceName, !props.eventTrigger ?'/automation/execute/polling':'/automation/trigger/event/run'))(request)
                    .then(response => {
                        // console.log('response ::: 3')
                        if (!response.data.error &&( response.data.output.subscriptionId || response.data.output.jobId)) {
                            setSubscriptionId(response.data.output.subscriptionId?response.data.output.subscriptionId :response.data.output.jobId)
                            intervalRef.current = setInterval(function() {
                                // console.log('heloo')
                                callPollingApi(response.data.output.subscriptionId?response.data.output.subscriptionId :response.data.output.jobId)
                            }, 10000)
                            
                                timeoutRef.current = setTimeout(() => {
                                    // intervalRef.current && clearInterval(intervalRef.current)
                                    if(props.eventTrigger){
                                        postJson(
                                            automationAPIURLWithWorkspace(workspaceName, '/automation/trigger/event/stop')
                                        )({
                                            subscriptionId: response.data.output.subscriptionId,
                                            automationId
                                        })
                                            .then(_ => {
                                                const logsOutput = logs as TriggerLogsT
                                                if (logs && logsOutput.output.length === 0) {
                                                    setExecutionState({
                                                        loading: false,
                                                        error: false
                                                    })
                                                }
                                            })
                                            .catch(err =>
                                                setExecutionState({
                                                    loading: false,
                                                    error: err.response
                                                        ? err.response.data.error
                                                        : 'Something went wrong! Please try again after some time.'
                                                })
                                        )
                                    }else{
                                        setExecutionState({
                                            loading: false,
                                            error: false
                                        })
                                    }
                                }, 61000)
                            
                        } else {
                            setExecutionState({
                                loading: false,
                                error:
                                    response.data && response.data.error
                                        ? response.data.error
                                        : 'Something went wrong! Please try again after some time.'
                            })
                        }
                    })
                    .catch(err => {
                        // console.log('error ::: 4')
                        setExecutionState({
                            loading: false,
                            error: err.response
                                ? err.response.data.error
                                : 'Something went wrong! Please try again after some time.'
                        })
                    })
            })
        }
    }

    const callLogsApi = (executionId: string) => {
        console.log("CALLING LOGS API")
        getJson(automationAPIURLWithWorkspace(workspaceName, '/automation/' + executionId + '/runtime/logs'))
            .then((response: LogsT) => {
                const l = logs as LogsT
                if (response.output.status == 'in progress' && l.output.status != 'in progress') {
                    intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
                    intervalApprovalRef.current = setInterval(function() {
                        callLogsApi(executionId)
                    }, 10000)
                } else if (response.output.status != 'in progress') {
                    intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
                }

                setLogs(response)
                setExecutionState({ ...executionState, loading: false })
            })
            .catch(err => {
                console.log('err', err.response.data)
                if (err.response && err.response.data && err.response.data.tag == 'INVALID_EXECUTION_ID') {
                    delay(1000).then(_ =>
                        getJson(
                            automationAPIURLWithWorkspace(workspaceName, '/automation/' + executionId + '/runtime/logs')
                        )
                            .then((response: LogsT) => {
                                const l = logs as LogsT
                                if (response.output.status == 'in progress' && l.output.status != 'in progress') {
                                    intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
                                    intervalApprovalRef.current = setInterval(function() {
                                        callLogsApi(executionId)
                                    }, 10000)
                                } else if (response.output.status != 'in progress') {
                                    intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
                                }

                                setLogs(response)
                                setExecutionState({ ...executionState, loading: false })
                            })
                            .catch(err => {
                                setExecutionState({
                                    loading: false,
                                    error: err.response
                                        ? err.response.data.error
                                        : 'Something went wrong! Please try again after some time.'
                                })
                            })
                    )
                } else {
                    setExecutionState({
                        loading: false,
                        error: err.response
                            ? err.response.data.error
                            : 'Something went wrong! Please try again after some time.'
                    })
                }
            })
    }

    const callPollingApi = (subscriptionId: string) => {
        console.log("CALLING POLLING API", subscriptionId)
        // getJson(
        //     automationAPIURLWithWorkspace(
        //         workspaceName,
        //         '/automation/trigger/event/execution/' + '018951a9-2361-4fc9-8402-442635e2c706'
        //     )
        // )
        // ternary to handle polling and event trigger logs
        getJson(automationAPIURLWithWorkspace(workspaceName, (flowtype == "trigger" && !props.eventTrigger ? '/automation/trigger/polling/execution/':'/automation/trigger/event/execution/') + subscriptionId))
        .then((response: TriggerLogsT) => {
                console.log("CALLING POLLING API" , response)
                // to stop event trigger
                 const isAnyInprogress = response.output.map(x => x.status).filter(x => x === "in progress")
                    if (response.output.length > 0) {
                        setLogs(response)
                        setExecutionState({ ...executionState, loading: false })

                        if(!isAnyInprogress) {
                            intervalRef.current && clearInterval(intervalRef.current)
                        }

                        // console.log("timeoutRef.current",timeoutRef.current)
                    if(flowtype == "trigger" && props.eventTrigger && timeoutRef.current !== null){
                            postJson(automationAPIURLWithWorkspace(workspaceName, '/automation/trigger/event/stop'))({
                                subscriptionId: subscriptionId,
                                automationId
                            })
                                .then(_ => {
                                    if(timeoutRef.current ){
                                        clearTimeout(timeoutRef.current)
                                        timeoutRef.current = null
                                    }
                                      
                                    const logsOutput = logs as TriggerLogsT
                                    if (logs && logsOutput.output.length === 0) {
                                        setExecutionState({
                                            loading: false,
                                            error: false
                                        })
                                    }
                                })
                                .catch(err =>
                                    setExecutionState({
                                        loading: false,
                                        error: err.response
                                            ? err.response.data.error
                                            : 'Something went wrong! Please try again after some time.'
                                    })
                                )
                    }
                }else{
                    setLogs(response)
                    setExecutionState({ ...executionState, loading: true, error: false })    
                }
            })
            .catch(err => {
                setExecutionState({
                    loading: false,
                    error: err.response
                        ? err.response.data.error
                        : 'Something went wrong! Please try again after some time.'
                })
            })
    }

    useEffect(() => {
        const parenElement: any = document.getElementById('automation_logs_width')
        if (parenElement) {
            parenElement.style.height = minOrMax !== 'minimized' ? '670px' : '510px'
        }

        const element: any = document.getElementsByClassName('automation_logs_collapse')
        if (element && minOrMax.length > 0) {
            minOrMax !== 'minimized' ? element[0].classList.add('maximized') : element[0].classList.remove('maximized')
        }
    }, [minOrMax])

    function renderElements() {
       


        if (flowtype !== 'trigger') {
            if (logs && logs.type === 'success') {
                const mereLogs = logs as LogsT
                const inProgressNodes = mereLogs.output.normalView.filter(x => x.node_execution_status === "in_progress")
                const firstInprogressNode = inProgressNodes[0]
                return (
                    <>
                        <AutomationTestPanel.NoTriggerInput
                            step={'Start workflow if'}
                            executionStatus={executionStatus}
                            config={config}
                            setConfig={setConfig}
                            executeLogs={executeLogs}
                            executionState={executionState}
                            closePanel={closeTestingPanel}
                            webhookTrigger={props.webhookTrigger}
                        />
                        {mereLogs.output.normalView.length > 0 && (
                            <>
                                <AutomationTestPanel.Steps
                                    logs={{
                                        ...logs,
                                        output: {
                                            developerView: mereLogs.output.developerView.filter((val, i) => i != 0),
                                            normalView: mereLogs.output.normalView.filter((val, i) => i != 0),
                                            status: mereLogs.output.status
                                        }
                                    }}
                                    showSteps={true}
                                    step={'Then'}
                                    developerView={developerView}
                                />
                                {mereLogs.output.status == 'in progress' && (
                                    <div className="waiting_approval">
                                        <span />
                                        Waiting for {firstInprogressNode.node_type as string === "timer" ? "timer" : "approval"}
                                        {/* <label>Permission to Unlock account</label> */}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )
            }
            return (
                <AutomationTestPanel.NoTriggerInput
                    step={'Start workflow if'}
                    executionStatus={executionStatus}
                    config={config}
                    setConfig={setConfig}
                    executeLogs={executeLogs}
                    executionState={executionState}
                    closePanel={closeTestingPanel}
                    webhookTrigger={props.webhookTrigger}
                />
            )
        } else if (executionState.error) {
            return <p style={{ color: 'red', marginTop: 5 }}>{executionState.error}</p>
        } else if (logs && logs.output) {
            const triggerLogs = logs as TriggerLogsT
            if (triggerLogs.output.length > 0) {
                const inProgressNodes = triggerLogs.output[currentJob].job.normalView.filter((x) => x.node_execution_status === 'in_progress');
                const firstInprogressNode = inProgressNodes[0];
                return (
                    <>
                        <div className="automation_logs_collapse_step2">
                            <div className="btn_job_wrapper">
                                <div className="btn_job_left_container">
                                    {triggerLogs.output.map((log, i) => {
                                         

                                        return log.status === 'failed' ? (
                                            <button className={i===currentJob?"error_job_btn active_job":"error_job_btn"} onClick={() => setCurrentJob(i)}>
                                                Event{i + 1}
                                                <span className="no_input_value">
                                                    <div className="error_input_show">
                                                        <svg x="0px" y="0px" viewBox="0 0 483.5 483.5">
                                                            <path d="M480 425L269.1 29.9c-5.3-9.9-15.6-16.1-26.8-16.1-11.2 0-21.5 6.2-26.8 16.1L3.6 425c-5 9.5-4.8 20.9.7 30 5.5 9.1 15.4 14.8 26.1 14.8h422.7c10.7 0 20.6-5.6 26.1-14.8 5.5-9.2 5.8-20.5.8-30zm-237.8-16c-16.8 0-30.4-13.6-30.4-30.4s13.6-30.4 30.4-30.4c16.8 0 30.4 13.6 30.4 30.4S259 409 242.2 409zm30.5-121.1c0 16.8-13.6 30.4-30.4 30.4-16.8 0-30.4-13.6-30.4-30.4V166.3c0-16.8 13.6-30.4 30.4-30.4 16.8 0 30.4 13.6 30.4 30.4v121.6z"></path>
                                                        </svg>
                                                    </div>
                                                </span>
                                            </button>
                                        ) : (
                                            <button key={i} className={i===currentJob?"active_job":""} onClick={() => setCurrentJob(i)}>
                                                Event{i + 1}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <AutomationTestPanel.Steps
                            logs={{
                                output: {
                                    developerView: triggerLogs.output[currentJob].job.developerView.filter(
                                        (val, i) => i == 0
                                    ),
                                    normalView: triggerLogs.output[currentJob].job.normalView.filter(
                                        (val, i) => i == 0
                                    ),
                                    status: logs.output.status
                                },
                                type: 'success'
                            }}
                            step={'Start workflow if'}
                            showSteps={true}
                            developerView={developerView}
                        />

                         <AutomationTestPanel.Steps
                            logs={{
                                output: {
                                    developerView: triggerLogs.output[currentJob].job.developerView.filter(
                                        (val, i) => i != 0
                                    ),
                                    normalView: triggerLogs.output[currentJob].job.normalView.filter(
                                        (val, i) => i != 0
                                    ),
                                    status: logs.output.status
                                },
                                type: 'success'
                            }}
                            showSteps={true}
                            step={'Then'}
                            developerView={developerView}
                        />
                              {triggerLogs.output[currentJob].job.status == 'in progress' && inProgressNodes.length > 0 && (
                                    <div className="waiting_approval">
                                        <span />
                                        Waiting for {firstInprogressNode.node_type as string === "timer" ? "timer" : "approval"}
                                        {/* <label>Permission to Unlock account</label> */}
                                    </div>
                                )}
                    </>
                )
            } else {
                return (
                    <p>
                       No new<span>{triggerName}</span>.
                    </p>
                )
            }
        }

        return null
    }

    return (
        <AutomationTestPanel.Wrapper>
            <h4 className="automation_logs_width_h4">
                <div>
                    <span>
                        <TestWorkflowIcon />
                    </span>
                    <label>Test workflow</label>
                </div>
                <div className="video_right_ct svg-position">
                    <VideoLinkSimple id={'TEST_THE_APP_WORKFLOW'} text={'How to test the workflow'} />
                </div>
            </h4>
            <span
                className={
                    minOrMax === 'minimized'
                        ? 'runtime_arrow runtime_arrow_minimized'
                        : 'runtime_arrow runtime_arrow_maximized'
                }
                onClick={() => setMinOrMax(minOrMax === 'minimized' ? 'maximized' : 'minimized')}
            >
                <label>{minOrMax === 'minimized' ? 'Maximise' : 'Minimise'}</label>
            </span>

            <span
                className="runtime_arrow"
                onClick={() => {
                    closeTestingPanel()
                    intervalApprovalRef.current && clearInterval(intervalApprovalRef.current)
                    intervalRef.current  && clearInterval(intervalRef.current)
                    if (journeyData.type === 'success' && hasTested) {
                        journeyData.value.send({
                            type: 'EVENT::WORKFLOW::TO_BE_ACTIVATED',
                            tag: 'WORKFLOW_GENERIC_EVENT',
                            journeyType: 'Workflow'
                        })
                    }
                }}
            >
                <label>Close</label>
            </span>
            {props.scope != "AUTOMATE" && flowtype != "trigger" ?
                <AutomationTestPanel.DetailHeader
                    executionStatus={executionStatus}
                    flowName={props.flowName}
                    developerView={developerView}
                    setView={setView}
                    flowtype={flowtype}
                    executionState={executionState}
                    // logs={flowtype == 'trigger' ? (logs as TriggerLogsT) : undefined}
                    logs={logs as LogsT}
                    eventTrigger={props.eventTrigger}
                />:
                <AutomationTestPanel.TriggerDetailHeader
                    executionStatus={executionStatus}
                    flowName={props.flowName}
                    developerView={developerView}
                    setView={setView}
                    flowtype={flowtype}
                    logs={flowtype == "trigger" ? (logs as TriggerLogsT) : undefined}
                />
            }
            {executionState.loading ? (
                flowtype != 'notrigger' ? (
                    <div className="automation_logs_collapse server_error">
                        <Loader.PopupLoader show={true} />
                        <p>
                            Checking for new <span>{triggerName}</span> in <span>{appName}</span>. Please wait a moment.
                        </p>
                    </div>
                ) : (
                    <div className="automation_logs_collapse">
                        <Loader.PopupLoader show={true} />
                    </div>
                )
            ) : (
                <div
                    className={
                        (flowtype == 'trigger' && triggerLogs && triggerLogs.output.length) || flowtype == 'notrigger'
                            ? 'automation_logs_collapse'
                            : 'automation_logs_collapse server_error'
                    }
                    style={{ justifyContent: !props.eventTrigger ? "initial" : "center"}}
                >
                    {renderElements()}
                </div>
            )}
        </AutomationTestPanel.Wrapper>
    )
}

AutomationTestPanel.Wrapper = (props: any) => (
    <section className="automation_logs automation_logs_runtime_logs">
        <div className="automation_logs_width" id="automation_logs_width">
            {props.children}
        </div>
    </section>
)

AutomationTestPanel.TriggerDetailHeader = (props: {
    flowName: string;
    executionStatus: any;
    developerView: boolean;
    setView: (view: boolean) => void;
    flowtype: string;
    logs?: TriggerLogsT;
  }) => {
    const {
      executionStatus,
      flowName,
      developerView,
      setView,
      flowtype,
      logs,
    } = props;
  
    const executionFailCount =
      executionStatus.output && executionStatus.output.executionContext
        ? Object.entries(executionStatus.output.executionContext)
            .filter((value) => value[0] != "output")
            .filter((value: any) => !value[1].success)
            .concat(Object.entries(executionStatus.output.errorContext))
        : [];
  
    return (
      <div className="automation_logs_name">
        <div className="automation_logs_header">
          <div className="automation_logs_header_left">
            <div className="automation_logs_header_left_name">
              <p>Name</p>
              <h6 className="automation_logs_header_left_content">{flowName}</h6>
            </div>
            {flowtype != "trigger" && (
              <div className="automation_logs_header_left_name">
                <p>Duration</p>
                <h6 className="automation_logs_header_left_content">
                  {executionStatus.output
                    ? executionStatus.output.executionTime
                    : "-"}
                </h6>
              </div>
            )}
            {flowtype == "trigger" && (
              <div className="automation_logs_header_left_name">
                <p>Events</p>
                <h6
                  className="automation_logs_header_left_content"
                  style={{
                    color: "#494949",
                  }}
                >
                  {logs && logs.output.length > 0 ? logs.output.length : "-"}
                </h6>
              </div>
            )}
              <>
                <div className="automation_logs_header_left_name">
                  <p>Success</p>
                  <h6
                    className="automation_logs_header_left_content"
                    style={{
                      color:
                        logs && logs.output.length > 0 ? "#2DB677" : "#494949",
                    }}
                  >
                    {logs && logs.output.length > 0
                      ? logs.output.filter((l) => l.status == "success").length
                      : "-"}
                  </h6>
                </div>
                <div className="automation_logs_header_left_name">
                  <p>Failed</p>
                  <h6
                    className="automation_logs_header_left_content"
                    style={{
                      color: logs && logs.output.length > 0 ? "red" : "#494949",
                    }}
                  >
                    {logs && logs.output.length > 0
                      ? logs.output.filter((l) => l.status == "failed").length
                      : "-"}
                  </h6>
                </div>
              </>
          </div>
          <RadioButtons developerView={developerView} setView={setView}/>
        </div>
      </div>
    );
  };

AutomationTestPanel.DetailHeader = (props: {
    flowName: string
    executionStatus: any
    developerView: boolean
    setView: (view: boolean) => void
    flowtype: string
    executionState: {
        loading: boolean
        error: boolean
    }
    eventTrigger:boolean
    logs?: LogsT
}) => {
    const { executionStatus, flowName, developerView, setView, flowtype, logs, executionState, eventTrigger } = props

    console.log('status', props)

    const executionFailCount =
        executionStatus.output && executionStatus.output.executionContext
            ? Object.entries(executionStatus.output.executionContext)
                  .filter(value => value[0] != 'output')
                  .filter((value: any) => !value[1].success)
                  .concat(Object.entries(executionStatus.output.errorContext))
            : []

    const executionStatusText = () => {
        if (executionState.error) {
            return 'failed'
        }
        if (executionState.loading) {
            return '-'
        }

        if (logs) {
            return logs.output.status
        }

        return '-'
    }

    const exectionStatusColor = () => {
        const status = executionStatusText()

        if (status === 'in progress') {
            return '#E8790A'
        }
        if (status === 'success') {
            return '#2DB677'
        }
        if (status === 'failed') {
            return 'red'
        }

        return 'black'
    }

    const titleCase = (str: string) => {
        return str && str.charAt(0).toUpperCase() + str.slice(1)
    }

    return (
        <div className="automation_logs_name">
            <div className="automation_logs_header">
                <div className="automation_logs_header_left">
                    <div className="automation_logs_header_left_name">
                        <p>Name</p>
                        <h6 className="automation_logs_header_left_content">{flowName}</h6>
                    </div>
                    {(flowtype != 'trigger'|| !eventTrigger) && (
                        <div className="automation_logs_header_left_name">
                            <p>Duration</p>
                            <h6 className="automation_logs_header_left_content">
                                {executionStatus.output && logs && logs.output.status != 'in progress'
                                    ? executionStatus.output.executionTime
                                    : '-'}
                            </h6>
                        </div>
                    )}
                        <>
                            <div className="automation_logs_header_left_name">
                                <p>Status</p>
                                <h6
                                    className="automation_logs_header_left_content"
                                    style={{ color: exectionStatusColor() }}
                                >
                                    {titleCase(executionStatusText())}
                                </h6>
                            </div>
                            <div className="automation_logs_header_left_name">
                                <p>Error</p>
                                <h6 className="automation_logs_header_left_content">
                                    {executionStatus.output ? (
                                        executionFailCount.length == 0 ? (
                                            'No error'
                                        ) : (
                                            <span style={{ color: 'red' }}>
                                                {' '}
                                                {`${
                                                    executionFailCount.length < 2
                                                        ? '1 Error'
                                                        : Object.keys(executionStatus.output.errorContext).length +
                                                          Object.entries(executionStatus.output.executionContext)
                                                              .filter(value => value[0] != 'output')
                                                              .filter((value: any) => !value[1].success).length +
                                                          ' Errors'
                                                }`}
                                            </span>
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </h6>
                            </div>
                        </>
                </div>
                <RadioButtons developerView={developerView} setView={setView}/>
            </div>
            {/* <div className="btn_job_wrapper">
                <div className="btn_job_left_container">
                    <button>Job1</button>
                    <button className="error_job_btn">
                        Job1{' '}
                        <span className="no_input_value">
                            <div className="error_input_show">
                                <svg x="0px" y="0px" viewBox="0 0 483.5 483.5">
                                    <path d="M480 425L269.1 29.9c-5.3-9.9-15.6-16.1-26.8-16.1-11.2 0-21.5 6.2-26.8 16.1L3.6 425c-5 9.5-4.8 20.9.7 30 5.5 9.1 15.4 14.8 26.1 14.8h422.7c10.7 0 20.6-5.6 26.1-14.8 5.5-9.2 5.8-20.5.8-30zm-237.8-16c-16.8 0-30.4-13.6-30.4-30.4s13.6-30.4 30.4-30.4c16.8 0 30.4 13.6 30.4 30.4S259 409 242.2 409zm30.5-121.1c0 16.8-13.6 30.4-30.4 30.4-16.8 0-30.4-13.6-30.4-30.4V166.3c0-16.8 13.6-30.4 30.4-30.4 16.8 0 30.4 13.6 30.4 30.4v121.6z"></path>
                                </svg>
                                <span className="error_menu_hvr">Connection details are missing.</span>
                            </div>
                        </span>
                    </button>
                    <button>Job1</button>
                </div>
            </div> */}
        </div>
    )
}

const RadioButtons = (props:{developerView:boolean, setView: Function}) =>{
    const { developerView , setView } = props
    return(
        <div className="automation_logs_header_right">
                    <div className="automation_logs_radio">
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    className="radio-warning"
                                    checked={!developerView}
                                    onClick={() => setView(false)}
                                    onChange={() => {}}
                                />
                            </label>
                            <span>Normal View</span>
                        </div>
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    checked={developerView}
                                    onChange={() => {}}
                                    onClick={() => setView(true)}
                                />
                            </label>
                            <span>Developer View</span>
                        </div>
                    </div>
                </div>
    )
}

AutomationTestPanel.NoTriggerInput = (props: {
    step: string
    config: outputConfig
    setConfig: (object: any) => void
    executeLogs: () => void
    executionState: { loading: boolean; error: boolean }
    closePanel: () => void
    executionStatus: any
    webhookTrigger: boolean;
}) => {
    const { step, config, setConfig, executeLogs, executionStatus } = props
    const [showDropDown, makeVisible, makeHidden] = useDropDown(true)

    const mappingArray = config.output ? convertObjectToArray(config.output) : []

    const configHasTypeFile = mappingArray.filter(a => a.value.type == 'file').length > 0

    const enableSave = () => {
        const enableSave = mappingArray
            .map(x => {
                if (x.value.value && x.value.value.length && x.value.value.replace(/\s/g, '').length) {
                    return x
                }
                return undefined
            })
            .filter(Boolean)

        return mappingArray.length === enableSave.length
    }

    useEffect(() => {
        if (executionStatus && Object.keys(executionStatus).length > 0) {
            makeHidden()
        }
    }, [executionStatus])

    const getFirstStepTitle =()=>{
        return getProductId() == 'AUTOMATE'? (props.webhookTrigger? "Started with Webhook Trigger": "Started with Trigger"): 'Triggered by Chatbot'
    }

    return (
        <div className="automation_logs_collapse_step2">
            {/* <div className="automation_logs_collapse_left">
                <button>{step}</button>
            </div> */}
            <div className="automation_logs_collapse_right">
                {!showDropDown && (
                    <div className="automation_logs_collapse_close">
                        <h4 onClick={makeVisible}>
                            {/* <span>
                                <img src={TriggerIcon} />
                            </span> */}
                            {executionStatus && Object.keys(executionStatus).length > 0
                                ? getFirstStepTitle()
                                : 'Workflow inputs - Provide input to test this workflow'}
                        </h4>
                        {/* <div className="span_time_right" /> */}
                    </div>
                )}

                {showDropDown && (
                    <div className="automation_logs_collapse_open">
                        <h4 onClick={makeHidden}>
                            {/* <span>
                                <img src={TriggerIcon} />
                            </span> */}
                            {executionStatus && Object.keys(executionStatus).length > 0
                                ? getFirstStepTitle()
                                : 'Workflow inputs - Provide input to test this workflow'}
                        </h4>
                        {/* <div className="span_time_right" onClick={makeHidden} /> */}
                        <div className="automation_logs_collapse_open_content">
                            <div className="automation_logs_collapse_open_content_scroll">
                                {/* <h5>Fill in the input details </h5>
                                <p className="form_logs_p">
                                    Provide required inputs and click continue to test this workflow.
                                </p> */}
                                {mappingArray.length>0 && <div className="form_logs">
                                    {mappingArray.map((input, i) => (
                                        <InputRuntimelogs key={i}>
                                            {/* <div className="form_logs_input">
                                                <span className="form_logs_input_span">{input.value.title}</span>{' '}
                                                <span>:</span>
                                            </div> */}
                                            <InputWrapper className="p-0">
                                                <InputContainer>
                                                    <Input
                                                        type="text"
                                                        onChange={(e: any) => {
                                                            const newValue = produce(config, draftState => {
                                                                draftState.output[input.name].value = e.target.value
                                                            })
                                                            setConfig(newValue)
                                                            e.stopPropagation()
                                                        }}
                                                        onClick={(event: any) => {
                                                            event.stopPropagation()
                                                        }}
                                                        value={config.output[input.name].value}
                                                    />
                                                    <InputContent>{`Enter ${input.value.title}`}</InputContent>
                                                </InputContainer>
                                            </InputWrapper>
                                            {/* <input
                                                type="text"
                                                placeholder={`Enter ${input.value.title}`}
                                                onChange={e => {
                                                    const newValue = produce(config, draftState => {
                                                        draftState.output[input.name].value = e.target.value
                                                    })
                                                    setConfig(newValue)
                                                }}
                                                value={config.output[input.name].value}
                                            /> */}
                                        </InputRuntimelogs>
                                    ))}
                                </div>}
                                {(props.executionState.error || configHasTypeFile) && (
                                    <p style={{ color: 'red', marginTop: 5 }}>
                                        {configHasTypeFile
                                            ? 'File input type is not supported for execution yet'
                                            : props.executionState.error}
                                    </p>
                                )}
                                <ButtonContainer style={{paddingBottom: 0, paddingTop: 18}}>
                                    <Button primary onClick={props.closePanel}>
                                        <label>Cancel</label>
                                    </Button>
                                    <Button
                                        onClick={executeLogs}
                                        className={!enableSave() || configHasTypeFile ? 'editor_btn_disabled' : ''}
                                    >
                                        <label>Test</label>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

AutomationTestPanel.Steps = (props: { step: string; logs: LogsT; developerView: boolean; showSteps: boolean }) => {
    const { step, logs, developerView, showSteps } = props
    const [showDropDown, makeVisible, makeHidden] = useDropDown(false)

    const [currentlyOpenedNode, setNodeIndex] = useState(0)
    const currentSelectedViewLogs: any = developerView ? logs.output.developerView : logs.output.normalView

    const approvalHeader = (node: ApprovalNodeLogT | TimerNodeLogT, isDeveloperView: boolean) => {
        // console.log('node ::: ', node)

        if (isDeveloperView) {
            const approvalTitle = node.input_schema['approval_title']
            if (approvalTitle) {
                return approvalTitle
            }
            return 'Approval'
        }

        if (!isDeveloperView) {
            const approvalTitle = node.input_schema.length
                ? node.input_schema.filter(x => x.key === 'approval_title')[0]
                : ''
            if (approvalTitle) {
                return approvalTitle.value
            }
            return 'Approval'
        }

        return 'Approval'
    }

    return (
        <>
            <div className="automation_logs_collapse_step2">
                {/* {showSteps && (
                <div className="automation_logs_collapse_left">
                    <button>{step}</button>
                </div>
            )} */}
                <div className="automation_logs_collapse_right">
                    {currentSelectedViewLogs.map(
                        (
                            output:
                                | ActionNodeLogT
                                | WebHookNodeLogT
                                | EndNodeLogT
                                | NormalViewActionNodeLogT
                                | ConditionNodeLogT
                                | PollingNodeLogT
                                | ForEachNodeLogT
                                | EventTriggerNodeLogT
                                | ApprovalNodeLogT
                                | TimerNodeLogT
                                | NotificationNodeLogT,
                            index: number
                        ) => {
                            if (
                                output.node_type !== NodeTypeT.web_hook &&
                                output.node_type !== NodeTypeT.end &&
                                output.node_type != NodeTypeT.loop 
                            ) {
                                const name =
                                    output.node_type === NodeTypeT.action ||
                                    output.node_type == NodeTypeT.polling ||
                                    output.node_type === NodeTypeT.event_trigger
                                        ? output.node_meta.action.name
                                        : output.node_type === NodeTypeT.condition
                                        ? output.node_meta.name
                                        : output.node_type === NodeTypeT.notification
                                        ? "Send Notification"
                                        : ''

                                const icon =
                                    output.node_type === NodeTypeT.action ||
                                    output.node_type == NodeTypeT.polling ||
                                    output.node_type === NodeTypeT.event_trigger
                                        ? output.node_meta.action.auth_type == AuthType.NoAuth
                                            ? FunctionNodeIcon
                                            : output.node_meta.action.icon
                                        : output.node_type === NodeTypeT.condition
                                        ? IfNodeIcon
                                        : output.node_type === NodeTypeT.approval
                                        ? ApprovalIcon
                                        : output.node_type === NodeTypeT.timer ? TimerNodeIcon
                                        : output.node_type === NodeTypeT.notification
                                        ? NotificationNodeIcon
                                        : FunctionNodeIcon

                                return !showDropDown || currentlyOpenedNode !== index ? (
                                    <>
                                        <div
                                            className={
                                                output.node_execution_status == 'skipped' ||
                                                (output.node_type !== NodeTypeT.condition &&
                                                    output.node_execution_status === 'success') ||
                                                (output.node_execution_status === 'success' &&
                                                    output.output_schema[output.node_id])
                                                    ? 'automation_logs_collapse_close'
                                                    : 'automation_logs_collapse_close automation_failed_node'
                                            }
                                            onClick={() => {
                                                makeVisible()
                                                setNodeIndex(index)
                                            }}
                                            key={index}
                                        >
                                            <div className="logs_hedaer_wrapper">
                                                <h4>
                                                    <span className="runtime_left_img">
                                                        <img src={icon} alt="A" style={{ color: 'rgb(73, 73, 73)' }} />
                                                    </span>
                                                    <span className="runtime_dropdown_arrow">
                                                        <DownarrowWithCircle />
                                                    </span>
                                                    <span className="runtime_counters">
                                                        <span className="counters_length">{output.index}</span>
                                                    </span>

                                                    <span className='runtime_title'>
                                                        {output.node_type == NodeTypeT.polling
                                                        ? 'Trigger - ' + name
                                                        : output.node_type == NodeTypeT.approval
                                                        ? approvalHeader(output, developerView)
                                                        : output.node_type == NodeTypeT.timer
                                                        ? "Timer"
                                                        : name}
                                                    </span>
                                                    {output.node_execution_status == 'skipped' && (
                                                        <p className="skipped_text">(Skipped)</p>
                                                    )}
                                                </h4>
                                                {/* <div className="span_time_right">
                                                {DateFns.format(output.inserted_date, 'YYYY-MM-DD')}
                                                <span className="ml_5">
                                                    {DateFns.format(new Date(output.inserted_date), 'hh:mm:ss:SS A')}
                                                </span>
                                            </div> */}
                                            </div>
                                        </div>
                                    </>
                                ) : output.node_type === NodeTypeT.action ||
                                  output.node_type == NodeTypeT.polling ||
                                  output.node_type == NodeTypeT.event_trigger ? (
                                    <BuildActionOutput
                                        output={output}
                                        makeHidden={makeHidden}
                                        developerView={developerView}
                                        index={index}
                                        key={index}
                                    />
                                ) : output.node_type === NodeTypeT.condition ? (
                                    <BuildConditionOutput
                                        output={output}
                                        makeHidden={makeHidden}
                                        developerView={developerView}
                                        index={index}
                                        key={index}
                                    />
                                ) : output.node_type===NodeTypeT.timer?(
                                    <BuildTimerOutput
                                        output={output}
                                        makeHidden={makeHidden}
                                        developerView={developerView}
                                        index={index}
                                        key={index}
                                    />
                                ): 
                                 output.node_type === NodeTypeT.notification ? ( 
                                    <BuildNotificationOutput
                                    output={output}
                                    makeHidden={makeHidden}
                                    developerView={developerView}
                                    index={index}
                                    key={index}
                                    />
                                 )
                                : (
                                    <BuildApprovalOutput
                                        output={output}
                                        makeHidden={makeHidden}
                                        developerView={developerView}
                                        index={index}
                                        key={index}
                                    />
                                )
                            } else if (output.node_type == NodeTypeT.loop) {
                                return (
                                    <div className="foreach_loop_logs">
                                        <>
                                            <div className="foreach_loop_logs_wrapper_ul">
                                                <div className="runtime_logs_collapse">
                                                    <div className="runtime_foreach_wrapper">
                                                        {output.node_execution_status == 'skipped' && (
                                                            <div className="runtime_logs_collapse_odd automation_logs_collapse_close" style={{cursor : "default"}}>
                                                                <h4>
                                                                    <span className="runtime_left_img">
                                                                        <img
                                                                            src={ForEachNodeIcon}
                                                                            alt="A"
                                                                            style={{ color: 'rgb(73, 73, 73)' }}
                                                                        />
                                                                    </span>
                                                                    <span className="runtime_counters">
                                                                        <span className="counters_length">
                                                                            {output.index}
                                                                        </span>
                                                                    </span>
                                                                    For Each - {output.node_meta.loop.name}{' '}
                                                                    <p className="skipped_text">(Skipped)</p>
                                                                </h4>
                                                                {/* <div className="span_time_right">
                                                                2020-06-05
                                                                <span className="ml_5">05:31:41:60 PM</span>
                                                            </div> */}
                                                            </div>
                                                        )}
                                                        {output.jobs.map((job: any, i: number) => (
                                                            <>
                                                                <div className="automation_logs_collapse_close runtime_logs_collapse_odd">
                                                                    <div className="logs_hedaer_wrapper">
                                                                        <h4>
                                                                            <span className="runtime_left_img">
                                                                                <img
                                                                                    src={ForEachNodeIcon}
                                                                                    alt="A"
                                                                                    style={{ color: 'rgb(73, 73, 73)' }}
                                                                                />
                                                                            </span>
                                                                            <span className="runtime_counters">
                                                                                <span className="counters_length">
                                                                                    {output.index}
                                                                                </span>
                                                                            </span>
                                                                            For Each - {output.node_meta.loop.name}{' '}
                                                                            <span className="job_span_color">
                                                                                Event {job.jobId}
                                                                            </span>
                                                                        </h4>
                                                                    </div>
                                                                    {/* <div className="span_time_right">
                                                                    2020-06-05
                                                                    <span className="ml_5">05:31:41:60 PM</span>
                                                                </div> */}
                                                                </div>
                                                                <AutomationTestPanel.Steps
                                                                    key={i}
                                                                    showSteps={false}
                                                                    logs={{
                                                                        ...logs,
                                                                        output: {
                                                                            developerView: job.actions as (
                                                                                | ActionNodeLogT
                                                                                | WebHookNodeLogT
                                                                                | EndNodeLogT
                                                                                | PollingNodeLogT
                                                                                | ForEachNodeLogT
                                                                            )[],
                                                                            normalView: job.actions as (
                                                                                | NormalViewActionNodeLogT
                                                                                | WebHookNodeLogT
                                                                                | EndNodeLogT
                                                                                | PollingNodeLogT
                                                                                | ForEachNodeLogT
                                                                            )[],
                                                                            status: logs.output.status
                                                                        }
                                                                    }}
                                                                    step={'Then'}
                                                                    developerView={developerView}
                                                                />
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                )
                            }
                            return null
                        }
                    )}
                </div>
            </div>
        </>
    )
}

function BuildActionOutput(props: {
    output: ActionNodeLogT | NormalViewActionNodeLogT | PollingNodeLogT | EventTriggerNodeLogT
    makeHidden: () => void
    developerView: boolean
    index: number
}) {
    const { developerView, index, makeHidden, output } = props
    const [showInputs, makeInputVisible, makeInputHidden] = useDropDown(true)

    const [showOutputs, makeOutputVisible, makeOutputHidden] = useDropDown(true)

    const authentication =
        output.node_execution_status !== 'skipped' ? Object.values(output.node_meta.authentication)[0] : ''

    return (
        <div
            key={index}
            className={
                output.node_execution_status === 'success' || output.node_execution_status == 'skipped'
                    ? 'automation_logs_collapse_open'
                    : 'automation_logs_collapse_open automation_failed_node'
            }
        >
            <div onClick={makeHidden} >
                <h4>
                    <span>
                        <img
                            src={
                                output.node_meta.action.auth_type == AuthType.NoAuth
                                    ? FunctionNodeIcon
                                    : output.node_meta.action.icon
                            }
                            alt="A"
                            style={{ color: 'rgb(73, 73, 73)' }}
                        />
                    </span>
                    {output.node_type == NodeTypeT.polling
                        ? 'Trigger - ' + output.node_meta.action.name
                        : output.node_meta.action.name}
                    {output.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>}

                    <span className="runtime_dropdown_uparrow">
                        <DownarrowWithCircle />
                    </span>
                </h4>
                {/* <div className="span_time_right">
                    {DateFns.format(output.inserted_date, 'YYYY-MM-DD')}
                    <span className="ml_5"> {DateFns.format(new Date(output.inserted_date), 'hh:mm:ss:SS A')}</span>
                </div> */}
            </div>
            {output.node_execution_status === 'skipped' ? (
                <div className="automation_logs_collapse_open_content">
                    <div className={'automation_logs_collapse_open_content_scroll'}>
                        <h5>Action not executed!</h5>
                        <p>Previous action/condition is failed. So, the action node is not executed.</p>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="automation_logs_collapse_open_content">
                        {output.node_meta.action.auth_type != AuthType.NoAuth && (
                            <p className="auth_content">
                                Authenticating with <span className="email_logs_color">{authentication}</span>
                            </p>
                        )}
                        <div className={'automation_logs_collapse_open_content_scroll'}>
                            {output.node_meta.action.auth_type != AuthType.NoAuth && output.node_type === 'action' && (
                                <h5
                                    onClick={() => {
                                            showInputs ? makeInputHidden() : makeInputVisible()
                                        }}>
                                    Input details provided{' '}
                                    <span
                                        className={
                                            showInputs
                                                ? 'dialog_context_tree_head_right_svg'
                                                : 'dialog_context_tree_head_right_svg collapsed'
                                        }
                                        onClick={() => {
                                            showInputs ? makeInputHidden() : makeInputVisible()
                                        }}
                                    >
                                        <NextDetailICon />
                                    </span>
                                </h5>
                            )}
                            {showInputs &&
                                output.node_meta.action.auth_type != AuthType.NoAuth &&
                                output.node_type === 'action' && (
                                    <AutomationTestPanel.InputDetails
                                        inputs={output.input_schema}
                                        developerView={developerView}
                                    />
                                )}
                            <p>
                                {output.node_execution_status === 'success'
                                    ? 'Automation executed successfully.'
                                    : 'Automation execution failed'}
                            </p>
                        </div>
                    </div>

                    <div className="automation_logs_collapse_open_content">
                        <div className="automation_logs_collapse_open_content_scroll">
                            <h5
                             onClick={() => {
                                        showOutputs ? makeOutputHidden() : makeOutputVisible()
                                    }}>
                                Output details received from {output.node_meta.action.name}
                                <span
                                    className={
                                        showOutputs
                                            ? 'dialog_context_tree_head_right_svg'
                                            : 'dialog_context_tree_head_right_svg collapsed'
                                    }
                                    onClick={() => {
                                        showOutputs ? makeOutputHidden() : makeOutputVisible()
                                    }}
                                >
                                    <NextDetailICon />
                                </span>
                            </h5>
                            {showOutputs && (
                                <div className="trigger_tree_wrapper">
                                    <LogsOutputTree
                                        executionSuccess={output.node_execution_status === 'success'}
                                        output={output.output_schema}
                                        developerView={developerView}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

function BuildTimerOutput(props: {
    output: TimerNodeLogT
    makeHidden: () => void
    developerView: boolean
    index: number
}) {
    const { developerView, index, makeHidden, output } = props
    const [showInputs, makeInputVisible, makeInputHidden] = useDropDown(true)

    const [showOutputs, makeOutputVisible, makeOutputHidden] = useDropDown(true)

    return (
        <div
            key={index}
            className={
                output.node_execution_status === 'success' || output.node_execution_status == 'skipped'
                    ? 'automation_logs_collapse_open'
                    : 'automation_logs_collapse_open automation_failed_node'
            }
        >
            <span onClick={makeHidden}>
                <h4>
                    <span>
                        <img src={TimerNodeIcon} alt="A" style={{ color: 'rgb(73, 73, 73)' }} />
                    </span>
                    Timer
                    {output.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>}
                </h4>
            </span>
            {output.node_execution_status === 'skipped' ? (
                <div className="automation_logs_collapse_open_content">
                    <div className={'automation_logs_collapse_open_content_scroll'}>
                        <h5>Action not executed!</h5>
                        <p>Previous action/condition is failed. So, the action node is not executed.</p>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="automation_logs_collapse_open_content">
                        <div className={'automation_logs_collapse_open_content_scroll'}>
                            <h5>
                                Input details provided{' '}
                                <span
                                    className={
                                        showInputs
                                            ? 'dialog_context_tree_head_right_svg'
                                            : 'dialog_context_tree_head_right_svg collapsed'
                                    }
                                    onClick={() => {
                                        showInputs ? makeInputHidden() : makeInputVisible()
                                    }}
                                >
                                    <NextDetailICon />
                                </span>
                            </h5>

                            {showInputs&&
                                (
                                !developerView ? (
                                    <ul>
                                        {
                                            
                                            output.input_schema["by"]?(
                                                <li><span style={{ color: '#494949' }}>Wait for:</span> {output.input_schema["by"]} {output.input_schema["period"]}</li>
                                            )
                                            :output.input_schema["date"]?(
                                                    <>
                                                    <li><span style={{ color: '#494949' }}>Condition:</span> Date & Time</li>
                                                    <li><span style={{ color: '#494949' }}>Current day of month is:</span> {output.input_schema["date"]}</li>
                                                    <li><span style={{ color: '#494949' }}>Time is:</span> {`${ output.input_schema["time"]}h (${ output.input_schema["time"] === 0 ? 12 :  output.input_schema["time"] < 13 ?  output.input_schema["time"] :  (output.input_schema["time"] as number) % 12}${
                                                        output.input_schema["time"] < 12 ? 'AM' : 'PM'
                                                    })`}</li>
                                                    <li><span style={{ color: '#494949' }}>Timezone:</span> {output.input_schema["timezone"]}</li>
                                                    </>
                                                ):(
                                                    <>
                                                        <li><span style={{ color: '#494949' }}>Condition:</span> Current time</li>
                                                        <li><span style={{ color: '#494949' }}>Time is:</span> {`${ output.input_schema["time"]}h (${ output.input_schema["time"] === 0 ? 12 :  output.input_schema["time"] < 13 ?  output.input_schema["time"] :  (output.input_schema["time"] as number) % 12}${
                                                            output.input_schema["time"] < 12 ? 'AM' : 'PM'
                                                        })`}</li>
                                                        <li><span style={{ color: '#494949' }}>Timezone:</span> {output.input_schema["timezone"]}</li>
                                                    </>
                                                )
                                            
                                        }
                                    </ul>
                                )
                                :<ul>
                                    <li>
                                        <JSONPretty data={output.input_schema} />
                                    </li>
                                </ul>
                                )
                            }
                            {/* <p>
                                {output.node_execution_status === 'success'
                                    ? 'Automation executed successfully.'
                                    : 'Automation execution failed'}
                            </p> */}
                        </div>
                    </div>
                    <div className="automation_logs_collapse_open_content">
                        <div className="automation_logs_collapse_open_content_scroll">
                            <h5>
                                Output details received from Timer
                                <span
                                    className={
                                        showOutputs
                                            ? 'dialog_context_tree_head_right_svg'
                                            : 'dialog_context_tree_head_right_svg collapsed'
                                    }
                                    onClick={() => {
                                        showOutputs ? makeOutputHidden() : makeOutputVisible()
                                    }}
                                >
                                    <NextDetailICon />
                                </span>
                            </h5>
                            {developerView?
                                <ul><li><JSONPretty data={output.output_schema} /></li></ul>
                            :(
                                <p>
                                    Timer excution status: {output.node_execution_status === 'success'? (
                                        <span style={{ color: '#2DB677', padding: '0px 3px' }}> Success </span>
                                    ) : output.node_execution_status === 'in_progress' ? (
                                        <span style={{ color: 'red', padding: '0px 3px' }}> Inprogress </span>
                                    ) : (
                                        <span style={{ color: 'red', padding: '0px 3px' }}> Failed </span>
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

function BuildApprovalOutput(props: {
    output: ApprovalNodeLogT 
    makeHidden: () => void
    developerView: boolean
    index: number
}) {
    const { developerView, index, makeHidden, output } = props
    const [showInputs, makeInputVisible, makeInputHidden] = useDropDown(true)

    const [showOutputs, makeOutputVisible, makeOutputHidden] = useDropDown(true)

    const approvalHeader = (node: ApprovalNodeLogT | TimerNodeLogT, isDeveloperView: boolean) => {
        console.log('node ::: ', node)

        if (isDeveloperView) {
            const approvalTitle = node.input_schema['approval_title']
            if (approvalTitle) {
                return approvalTitle
            }
            return 'Approval'
        }

        if (!isDeveloperView) {
            const approvalTitle = node.input_schema.length
                ? node.input_schema.filter(x => x.key === 'approval_title')[0]
                : ''
            if (approvalTitle) {
                return approvalTitle.value
            }
            return 'Approval'
        }

        return 'Approval'
    }

    // const authentication =
    //     output.node_execution_status !== 'skipped' ? Object.values(output.node_meta.authentication)[0] : ''

    return (
        <div
            key={index}
            className={
                output.node_execution_status === 'success' || output.node_execution_status == 'skipped'
                    ? 'automation_logs_collapse_open'
                    : 'automation_logs_collapse_open automation_failed_node'
            }
        >
            <div onClick={makeHidden}>
                <h4>
                    <span>
                        <img src={ApprovalIcon} alt="A" style={{ color: 'rgb(73, 73, 73)' }} />
                    </span>
                    {approvalHeader(output, developerView)}

                    {output.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>}
                    <span className="runtime_dropdown_uparrow">
                        <DownarrowWithCircle />
                    </span>
                </h4>
            </div>
            {output.node_execution_status === 'skipped' ? (
                <div className="automation_logs_collapse_open_content">
                    <div className={'automation_logs_collapse_open_content_scroll'}>
                        <h5>Action not executed!</h5>
                        <p>Previous action/condition is failed. So, the action node is not executed.</p>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="automation_logs_collapse_open_content">
                        <div className={'automation_logs_collapse_open_content_scroll'}>
                            <h5>
                                Input details provided{' '}
                                <span
                                    className={
                                        showInputs
                                            ? 'dialog_context_tree_head_right_svg'
                                            : 'dialog_context_tree_head_right_svg collapsed'
                                    }
                                    onClick={() => {
                                        showInputs ? makeInputHidden() : makeInputVisible()
                                    }}
                                >
                                    <NextDetailICon />
                                </span>
                            </h5>

                            {showInputs && (
                                <AutomationTestPanel.InputDetails
                                    inputs={output.input_schema as InputSchema[]}
                                    developerView={developerView}
                                />
                            )}
                            <p>
                                {output.node_execution_status === 'success'
                                    ? 'Automation executed successfully.'
                                    : output.node_execution_status === 'in_progress' ? "Automation execution in progress" :'Automation execution failed'}
                            </p>
                        </div>
                    </div>
                    {output.node_execution_status !== 'in_progress' ? (<div className="automation_logs_collapse_open_content">
                        <div className="automation_logs_collapse_open_content_scroll">
                            <h5>
                                Output details received from Approval
                                <span
                                    className={
                                        showOutputs
                                            ? 'dialog_context_tree_head_right_svg'
                                            : 'dialog_context_tree_head_right_svg collapsed'
                                    }
                                    onClick={() => {
                                        showOutputs ? makeOutputHidden() : makeOutputVisible()
                                    }}
                                >
                                    <NextDetailICon />
                                </span>
                            </h5>
                            {showOutputs && (
                                <div className="trigger_tree_wrapper">
                                    <LogsOutputTree
                                        executionSuccess={output.node_execution_status === 'success'}
                                        output={output.output_schema}
                                        developerView={developerView}
                                    />
                                </div>
                            )}
                        </div>
                    </div>) : null}
                   
                </React.Fragment>
            )}
        </div>
    )
}

function BuildNotificationOutput(props:{
    output: NotificationNodeLogT
    makeHidden: () => void
    developerView: boolean
    index: number
}){
    const {developerView,index,makeHidden,output} = props
    return(
        <div
            key={index}
            className={
                // "automation_logs_collapse_open"
                output.node_execution_status === 'success' || output.node_execution_status == 'skipped'
                    ? 'automation_logs_collapse_open'
                    : 'automation_logs_collapse_open automation_failed_node'
            }
        >
            <div onClick={makeHidden} >
                <h4>
                    <span>
                        <img
                            src={
                                NotificationNodeIcon
                            }
                            alt="A"
                            style={{ color: 'rgb(73, 73, 73)' }}
                        />
                    </span>
                    {"Send Notification"}
                    {output.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>}

                    <span className="runtime_dropdown_uparrow">
                        <DownarrowWithCircle />
                    </span>
                </h4>
                
            </div>
        {output.node_execution_status === 'skipped' || output.output_schema.error ? (
            <div className="automation_logs_collapse_open_content">
                <div className={'automation_logs_collapse_open_content_scroll'}>
                    <h5>Notification not executed!</h5>
                    <p>{output.output_schema.error?output.output_schema.error:"Previous action/condition is failed. So, the notification node is not executed."}</p>
                </div>
            </div>
        ) :
        output.output_schema.notification_input.notification_input_type === 'option'?(
        <>
            <div className="tryit_understand_parent test_run">
                <h6>{output.output_schema.notification_input.notification_title}</h6>
                <p className="tryit_understand tryme_option_title">
                    <AssistantMd mdText={convertToMDText(output.output_schema.notification_input.message)} />
                </p>
                <div className="tryit_option test_run">
            {output.output_schema.notification_input.option.map((option: any, index: number) => (
                <button
                    key={index}
                    className={
                        option.label.length < 57
                            ? 'btn btn-primary tryme_option_button'
                            : 'btn btn-primary tryme_option_button'
                    }
                >
                    <span>
                        <AssistantMd mdText={convertToMDText(option.label)} />
                    </span>
                </button>
            ))}
        </div>
            </div>
        
        </>
        ):output.output_schema.notification_input.notification_input_type==="adaptive_card"?(
    <div className="position-realtive tryit_wrapper_card adaptive_card">
        <div className="tryit_cards">
            <div className="tryit_cards_top_right">
                    {output.output_schema.notification_input.adaptive_card.title && (
                        <h4 className="word_break">
                            <AssistantMd mdText={convertToMDText(output.output_schema.notification_input.adaptive_card.title)} />
                        </h4>
                    )}
                    {output.output_schema.notification_input.adaptive_card.description && (
                        <p>
                            <AssistantMd mdText={convertToMDText(output.output_schema.notification_input.adaptive_card.description)} />
                        </p>
                    )}
                </div>
            <div className="tryit_cards_top">
                {/* <div className="tryit_cards_top_right">
                    {output.output_schema.adaptive_card.title && (
                        <h4 className="word_break">
                            <AssistantMd mdText={convertToMDText(output.output_schema.adaptive_card.title)} />
                        </h4>
                    )}
                    {output.output_schema.adaptive_card.description && (
                        <p>
                            <AssistantMd mdText={convertToMDText(output.output_schema.adaptive_card.description)} />
                        </p>
                    )}
                </div> */}
                <div className="tryit_cards_left">
                    <div className="tryit_ticket_wrapper tryit_content_wrapper">
                        {output.output_schema.notification_input.adaptive_card.fields.length>0 && (
                            <div className="tryit_ticket_details_wrapper">
                                {output.output_schema.notification_input.adaptive_card.fields.map((field: any, key: number) => (
                                    <div className="tryit_ticket_details" key={key}>
                                        <h6>
                                            {Object.keys(field.title).length>0&&<AssistantMd mdText={convertToMDText(field.title)} />}
                                        </h6>
                                        <p>:</p>
                                        <p>
                                            <AssistantMd mdText={convertToMDText(field.value)} />
                                        </p>
                                    </div>
                                ))}
                </div>
            )}
                    {output.output_schema.notification_input.adaptive_card.buttons.length>0 && (
                        <div className="tryit_ticket_details_wrapper">
                            {output.output_schema.notification_input.adaptive_card.buttons.map((field: any, key: number) => {
                                return field.text.value.length>0 && (
                                    <div className="tryit_ticket_details">
                                        <SmallButton
                                            style={{ marginTop: 6, minWidth: 'max-content'}}
                                            primary
                                            key={key}
                                            onClick={() => window.open(convertToSingleStringforTryme(convertToMDText(field.url),""))}
                                        >
                                            <label style={{ width: 60}}>
                                                {/* {field.structValue.fields.text.stringValue} */}
                                                <AssistantMd mdText={convertToMDText(field.text)} />
                                            </label>
                                        </SmallButton>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                </div>
                <div className="tryit_cards_top_left">
                    {output.output_schema.notification_input.adaptive_card.logo &&
                            <img
                                src={
                                    output.output_schema.notification_input.adaptive_card.logo
                                }
                                alt={
                                'image'
                                }
                            />
                    }
                </div>
            </div>
            {/* <div className="tryit_cards_wrapper">
                <div className="tryit_cards_top_left">
                    {output.output_schema.adaptive_card.logo &&
                            <img
                                src={
                                    output.output_schema.adaptive_card.logo
                                }
                                alt={
                                'image'
                                }
                            />
                    }
                </div>
            </div> */}
        </div>
    </div>
        )
    :(
            <div className="tryit_understand_parent test_run">
                <h1>{output.output_schema.notification_input.notification_title}</h1>
                <p className="tryit_understand tryme_option_title">
                    <AssistantMd mdText={convertToMDText(output.output_schema.notification_input.message)} />
                </p>
            </div>
        )
    }
    </div>)
}

function BuildConditionOutput(props: {
    output: ConditionNodeLogT
    makeHidden: () => void
    developerView: boolean
    index: number
}) {
    const { developerView, index, makeHidden, output } = props

    return (
        <div
            key={index}
            className={
                output.node_execution_status == 'skipped' ||
                (output.node_type !== NodeTypeT.condition && output.node_execution_status === 'success') ||
                (output.node_execution_status === 'success' && output.output_schema[output.node_id])
                    ? 'automation_logs_collapse_open'
                    : 'automation_logs_collapse_open automation_failed_node'
            }
        >
            <div onClick={makeHidden}>
                <h4>
                    <span>
                        <img src={IfNodeIcon} alt="A" style={{ color: 'rgb(73, 73, 73)' }} />
                    </span>
                    {output.node_meta.name}
                    {output.node_execution_status == 'skipped' && <p className="skipped_text">(Skipped)</p>}
                    <span className="runtime_dropdown_uparrow">
                        <DownarrowWithCircle />
                    </span>
                </h4>
                {/* <div className="span_time_right">
                    {DateFns.format(output.inserted_date, 'YYYY-MM-DD')}
                    <span className="ml_5"> {DateFns.format(new Date(output.inserted_date), 'hh:mm:ss:SS A')}</span>
                </div> */}
            </div>

            {output.node_execution_status === 'skipped' ? (
                <div className="automation_logs_collapse_open_content">
                    <div className={'automation_logs_collapse_open_content_scroll'}>
                        <h5>Condition not executed!</h5>
                        <p>Previous action/condition is failed. So, the condition node is not executed.</p>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="automation_logs_collapse_open_content">
                        <div className={'automation_logs_collapse_open_content_scroll'}>
                            <h5>
                                Condition:
                                <p style={{ marginBottom: 0, paddingLeft: 5 }}>{output.input_schema.description}</p>
                            </h5>
                            <p>
                                Condition excution status:
                                {(output.node_type !== NodeTypeT.condition &&
                                    output.node_execution_status === 'success') ||
                                (output.node_execution_status === 'success' && output.output_schema[output.node_id]) ? (
                                    <span style={{ color: '#2DB677', padding: '0px 3px' }}> true </span>
                                ) : (
                                    <span style={{ color: 'red', padding: '0px 3px' }}> false </span>
                                )}
                                {(output.node_type !== NodeTypeT.condition &&
                                    output.node_execution_status === 'success') ||
                                (output.node_execution_status === 'success' && output.output_schema[output.node_id])
                                    ? '(condition satisfied)'
                                    : '(condition not satisfied)'}
                            </p>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

AutomationTestPanel.InputDetails = (props: {
    inputs:
        | {
              [key: string]: string
          }
        | InputSchema[]

    developerView: boolean
}) => {
    return (
        <ul>
            {Array.isArray(props.inputs) && !props.developerView ? (
                props.inputs.map((input: InputSchema, i: number) => {
                    if (input.config.kind === 'dictionary') {
                        return (
                            <React.Fragment>
                                <li key={i}>
                                    <span style={{ color: '#494949' }}>{input.displayTitle}</span>:{' '}
                                </li>
                                <ul>
                                    {Object.entries((input.value as any) as Object).map(([key, value]) => (
                                        <li key={i}>
                                            <span style={{ color: '#494949' }}>{key}</span> : <pre>{value}</pre>
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )
                    }
                    return (
                        <li key={i}>
                            <span style={{ color: '#494949' }}>{input.displayTitle}</span> : <span className="testPannel_second_span">{input.value}</span>
                        </li>
                    )
                })
            ) : (
                <li>
                    <JSONPretty data={props.inputs} />
                </li>
            )}
        </ul>
    )
}

export const LogsOutputTree = (props: { output: any; executionSuccess: boolean; developerView: boolean }) => {
    return (
        <ul className="automation_logs_content_wrapper">
            {props.executionSuccess && !props.developerView ? (
                convertObjectToArray(props.output.properties).map((output, i) => {
                    if (output.value.type == 'collection') {
                        return (
                            <div className="automation_logs_tree test_workflow_panel">
                                <div className="context_popup_buton_content" key={i}>
                                    <ContextOutputTree output={output} usingForLogsPage onClick={() => {}} />
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="context_popup_buton_content" key={i}>
                            {output.value.type !== 'object' && (
                                <OutputKeyDetailedView
                                    output={
                                        output as {
                                            name: string
                                            value: OutputListTypeT
                                        }
                                    }
                                    onClick={() => {}}
                                    usingForLogsPage
                                />
                            )}
                            {output.value.type === 'object' && (
                                <ContextOutputTree
                                    onClick={() => {}}
                                    output={
                                        output as {
                                            name: string
                                            value: ObjectOutputListTypeT
                                        }
                                    }
                                    usingForLogsPage
                                />
                            )}
                        </div>
                    )
                })
            ) : props.output.error ? (
                <li style={{ color: 'red' }}>
                    Error: <span className="error_span">{props.output.error}</span>
                </li>
            ) : (
                <li>
                    <JSONPretty data={props.output} />
                </li>
            )}
        </ul>
    )
}

// <li>
//     EmailID: <span className="email_logs_color">"admin@workativ.com"</span>
// </li>
