import styled from 'styled-components/macro'

// const m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
// const ResponsiveLine = require('@nivo/line')

export const Wrapper = styled.section`
    width: 100%;
    float: left;
    padding: 10px 0 0 0;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    padding-bottom: 40px;
    scroll-behavior: smooth;
    
    @media (max-width: 1300px) {
        height: calc(100vh - 250px);
        padding: 8px 0 0;
    }
`

export const HeaderTop = styled.h3`
    width: 100%;
    float: left;
    font-size: 24px;
    font-family: 'Gordita-Medium';
    color: #000;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .header_top_right {
        display: flex;
        flex-direction: column;
        width: 85%;
        float: left;

        div {
            margin-top: 4px;
            width: 100%;
            float: left;
        }
    }
    span {
        width: 64px;
        height: 64px;
        background: #e0f6ff;
        border-radius: 50%;
        float: left;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 34px;
            height: 34px;
            fill: #355d8b;
        }
    }
`
export const Content = styled.div`
    float: left;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #355d8b;
    line-height: 12px;
`
export const DropdownLiSpan = styled.div`
    position: relative;
    top: 1px;
    width: auto;
    float: left;
`
export const DropdownLi = styled.div`
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding: 0 12px;
    background: #fff;
    cursor: pointer !important;
    color: #000;

    label {
        width: 48px;
        float: left;
        margin-bottom: 0px;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 24px;
            height: 24px;
        }
    }

    :first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
    }

    :last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #fff;
    }

    :hover {
        background: #ddfeef;
        color: #333;
        border-bottom: 0.5px solid #ddfeef;

        svg {
            fill: #333;
        }
    }
`
export const DropdownUl = styled.div`
    width: 100%;
    position: absolute;
    margin-top: 48px;
    right: 0px;
    left: inherit;
    cursor: pointer;
    padding: 0px;
    max-height: 251px;
    margin-bottom: 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 10px #00000029;
    border-radius: 19px;
    z-index: 10;
`
export const Input = styled.input.attrs((boolean: any) => ({
    required: true
}))`
    width: ${props => (props.width ? props.width : '100%')};
    float: left;
    padding: 26px 20px 6px 15px;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000000;

    ${({ activeDropdown }) =>
        activeDropdown &&
        `
border: 1px solid #009d5f;

`}
`
export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 18px;
    transition: 0.26s ease-in;
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
        top: 10px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
`
const CustomHeader = styled.div`
    width: 100%;
    float: left;
    font-size: 16px;
    font-family: 'Gordita-Medium';
    color: #000;
    margin: 10px 0px;

    @media (max-width: 1536px) {
        font-size: 14px;
        margin: 7px 0px;
    }
    @media (max-width: 1400px) {
        font-size: 12px;
        margin: 6px 0px;
    }
`
export const Button = styled.button`
    min-width: 90px;
    height: 32px;
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    padding: 7px 12px;
    font-family: 'Gordita-Medium';
    color: ${props => (props.primary ? '#333' : '#fff')};
    margin: 10px 0px;
    // margin-bottom: ${props => (props.marginbottom ? '0px' : '20px')}!important;
    cursor: pointer;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    :hover {
        background: #ddfeef;
        color: ${props => (props.primary ? '#333' : '#333')};
        border: 1px solid #009d5f;

        label {
            color: ${props => (props.primary ? '#333' : '#333')};
        }

        svg {
            color: ${props => (props.primary ? '#333' : '#333')};
            fill: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 18px;
        height: 18px;
        float: left;
        margin-right: 4px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 18px;
            height: 18px;
            color: ${props => (props.primary ? '#333' : '#fff')};
            fill: ${props => (props.primary ? '#333' : '#fff')};
            margin-right: 0px;
        }
    }

    span.slack_integration {
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    label {
        margin-bottom: 0px;
        position: relative;
        top: 0px;
        cursor: pointer;
        color: ${props => (props.primary ? '#333' : '#fff')};
    }

    span.intergration-delete-button-text {
        float: unset;
        display: unset;
        top: 1px;
        backgroud: #fff;
    }
    span.slack-content,
    span.slack-content-button {
        margin-left: 4px;
    }
    span .teams-intergration-button {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(185, 185, 185);
        color: rgb(51, 51, 51);
    }

    .intergration-delete-button-text.delete_no_button {
        baground: #fff;
        border: 1px solid rgb(185, 185, 185);
        color: rgb(51, 51, 51);
    }
`
export const PopupButton = styled.button`
    min-width: ${props => (props.small ? '100px' : '100px')};
    height: 32px;
    background: #fff;
    border-radius: 18px;
    border: 1px solid #b9b9b9;
    outline: 0;
    font-size: 12px;
    padding: 6px 12px;
    font-family: 'Gordita-Medium';
    color: #333;
    // margin: ${props => (props.center ? 'auto' : '10px 0px')};
    cursor: pointer;
    margin-bottom: 10px;

    :first-child{
        margin: 0;
    }

    :hover {
        background: #ddfeef;
        color: ${props => (props.primary ? '#333' : '#333')};
        border: 1px solid #009d5f;

        label {
            color: ${props => (props.primary ? '#333' : '#333')};
        }

        svg {
            color: ${props => (props.primary ? '#333' : '#333')};
            fill: ${props => (props.primary ? '#333' : '#333')};
        }
    }
    :active {
        background: ${props => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
        border: ${props => (props.primary ? '1px solid #009d5f' : '1px solid #009d5f')} !important;
        color: ${props => (props.primary ? '#333' : '#333')}!important;
    }

    span {
        width: 12px;
        height: 12px;
        float: left;
        margin-right: 6px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 12px;
            height: 12px;
            color: ${props => (props.primary ? '#333' : '#fff')};
            fill: ${props => (props.primary ? '#333' : '#fff')};
            margin-right: 0px;
        }
    }
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
        color: #333;
    }

    span.intergration-delete-button-text {
        float: unset;
        display: unset;
        top: 1px;
    }
    span.slack-content,
    span.slack-content-button {
        margin-left: 4px;
    }
    span .teams-intergration-button {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(185, 185, 185);
        color: rgb(51, 51, 51);
    }
`
const InputWithImage = styled.div`
    width: 100%;
    float: left;
    position: relative;

    span {
        width: 12px !important;
        height: 12px !important;
        right: 5px !important;
        top: 3px !important;

        svg {
            width: 12px !important;
            height: 12px !important;
            color: #355d8b !important;
            fill: #355d8b !important;
        }
    }
`
const DateWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    ${Input} {
        width: 100%;
        height: 32px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        font-size: 10px;
        font-family: 'Gordita-Medium';
        color: #000;
        padding: 0px 12px;
    }
    label {
        width: auto;
        margin: 0px 3px;
    }
`
const CustomWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 0px 20px;
    padding-bottom: 15px;
`
export const DropdownWrapper = styled.div`
    // width: 100%;
    width: ${props => (props.width ? props.width : 'auto')};
    float: left;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    margin: 0px 0px 0px 14px;

    ${Content} {
        color: #000;
        margin-right: 7px;
        display: flex;
        align-items: baseline;
        position: relative;
        top: 2px;
    }

    span {
        position: absolute;
        top: 15px;
        width: 16px;
        height: 16px;
        right: 12px;
        transform: rotate(0deg);
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
            color: #555555;
        }
    }

    span.copy_dropdown_icon{
        position: absolute;
        top: 15px;
        width: 16px;
        height: 16px;
        right: 20px;
        transform: rotate(0deg);
        cursor: pointer;
    }

    span.copy_dropdown_boticon{
        position: absolute;
        top: 15px;
        width: 28px;
        height: 28px;
        left: 8px;
        svg {
            width: 20px;
            height: 20px;
            color: #555555;
        }
    }
`
export const HeaderWrapper = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #0000001a;
    border-radius: 10px;
    padding: 30px 20px 20px;
    .first_section_loader{
        // width: 100%;
        float: left;
        border-radius: 10px;
        padding: 30px 20px 20px;
        z-index: 1;
        width: 96.8%;
        position: absolute;
        height: 294px;
        top: 84px;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(4px);
        .popuploader{
            opacity: 1;
        }
    }

`
export const HeaderTabWrapper = styled.div`
    width: 100%;
    float: left;
    padding: 30px 20px 20px;
    margin-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #0000001A;
    border: 1px solid #B9B9B9;
    border-radius: 10px;
`
export const InputWrapper = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;

    ${Input} {
        width: 48px;
        height: 32px;
        padding: 0px 8px;
        font-size: 12px;
        font-family: 'Gordita-Medium';
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b9b9b9;
        border-radius: 6px;
        opacity: 1;
        color: #000;
    }

    ${Button} {
        margin: 0px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span {
        margin-left: 6px;
        width: auto;
        color: #355d8b;
        font-size: 12px;
    }
`
export const HoverContent = styled.div`
    max-width: 232px;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 19px;
    padding: 20px;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    position: absolute;
    right: 0;
    margin-top: -6px;
    display: block;
    z-index: 1;

    ${Content} {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        margin: 0px;
        color: #000000;
        margin-bottom: 10px;
        line-height: 18px;
    }
`
export const SettingsWrapper = styled.div`
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #355D8B;
    position: relative;
    bottom: 5px;

    svg {
        width: 16px;
        height: 16px;
        fill: #355D8B;
    }

    // :hover ${HoverContent} {
    //     display: block;
    //     animation: 300ms ease forwards;
    // }
`
export const Count = styled.label`
    width: 16px;
    height: 16px;
    margin: 0px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 50%;
    color: #333;
    font-size: 8px;
    font-family: 'Gordita-MEdium';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -17px;
    right: -3px;
    line-height: normal;
`
export const ChannelContainer = styled.div`
    width: auto;
    float: left;

    span {
        width: 30px;
        height: 30px;
        margin-left: 7px;
        position: relative;
        :first-child {
            margin-left: 0px;
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }
`
export const BotChannelsWrapper = styled.div`
    width: 100%;
    float: left;
`
export const CountWrapper = styled.div`
    width: 100%;
    float: left;
    font-size: 26px;
    font-family: 'Gordita-Medium';
    color: #000;
    display: flex;

    div{
        position: relative;
        width: 36px;
        height: 36px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            fill: #73A4DB;
            width: 18px;
            height: 18px;
        }
    }

    span {
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #355d8b;
        margin-left: 6px;
        margin-right: 2px;
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;
    }

    .header_count_percentage{
        font-size: 20px;
        font-family: 'Gordita-Medium';
        color: #007BFF;
        margin-left: 6px;
    }

    p{
        margin: 0;
        font-size: 14px;
        font-family: 'Gordita-Regular';
        color: #355D8B;
        margin-right: 4px;
        padding-top: 4px;
    }

    .chat_count{
        font-size: 24px;
        font-family: 'Gordita-Medium';
        color: #000;
    }
`
export const CountNumber = styled.div`
    width: 100%;
    float: left;
    font-size: 24px;
    font-family: 'Gordita-Medium';
    color: #000;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    align-items: center;
    // padding: 10px 20px;
`
export const CardHeader = styled.div`
    width: 100%;
    float: left;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 1399px) {
        font-size: 11px;
    }

    p {
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        height: 12px;
        font-size: 13px;
        position: relative;
        bottom: 12px;
        @media (max-width: 1440px) {
            line-height: 1.4;
            bottom: 10px;
        }
        @media (max-width: 1399px) {
            font-size: 11px;
            bottom: 13px;
        }
        @media (max-width: 1280px) {
            line-height: 1.4;
            bottom: 8px;
        }
       
        span{
            color:#007BFF;
            font-family: 'Gordita-Medium';
        }

    }
    p.user_queries_2box_last_text{
        bottom: 5px;
    }

    .target_bot{
        margin-left: 5px;
        bottom: 1px;
        margin-bottom: 0;
    }

    span{
        margin-bottom: 18px;
    }
`
export const Card = styled.div`
    width: 100%;
    float: left;
    background: #EBFAFD 0% 0% no-repeat padding-box;
    // box-shadow: 0px 2px 4px #0000001a;
    // border: 1px solid #cecece;
    border-radius: 10px;
    padding: 15px 20px;
    position: relative;
    height: 140px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    :nth-child(1){
        svg{
            width: 20pxx;
            height: 20px;
            fill: #73A4DB;
        }
    }

    .target_bot {
        width: 12px;
        height: 12px;
        cursor: pointer;
        span {
            width: 12px;
            height: 12px;
            svg {
                position: absolute;
                width: 12px;
                height: 12px;
                color: #999999;
                fill: #999999;
                top: 1px;
            }
        }
    }
    
`
export const CardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    grid-gap: 20px;
    margin-top: 10px;
`
export const TabCardContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    grid-gap: 10px;
    margin: 10px 0 30px 0;

    ${Card} {
        background: #F5F5FF 0% 0% no-repeat padding-box;
        height: 120px;
        padding: 20px 20px 15px 20px;
    }

    .user_queries_2box_first_text{
        line-height: 1.7;
        margin-bottom: 15px;
    }

    .user_queries_2box_last_text p{
        bottom: 2px;
    }

`
export const Header = styled.div`
    position: relative;
    margin-bottom: 0px !important;

    h4{
        font-size: 16px;
        font-family: 'Gordita-Medium';
        color: #000;
        margin-bottom: 0;
        line-height: 24px;
        padding-top: 4px;

        span{
            color: #355D8B;
            font-size: 14px;
            font-family: 'Gordita-Regular';
        }
    }

    p{
        color: #355D8B;
        margin-bottom: 0;
        font-size: 13px;
        font-family: 'Gordita-Regular';
        position: relative;
        top: 2px;
    }

    ${({ HeaderCardWorkflows }) =>
        HeaderCardWorkflows &&
        `
    margin-bottom: 0px !important;
    padding-top:1px !important;
    padding-top: 0px !important;
    position: relative;
    top: 1px;

    label {
        position: absolute;
        top: 0 !important;
        right: 0 !important;
        margin: 0px;
    }
    `}
    .target_bot{
        margin-left: 5px;
        top: 2px;

        .info_icon{
            color: #999999;
            cursor: pointer;
        }
    }
`
export const ChatHeaderWrapper = styled.div`{
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #CECECE;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 20px;
    width: 100%;
    align-items: center;
}
`
export const StatusWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: ${props => (props.marginTop ? '20px' : '0px')};
    ${CardContainer} {
        margin-top: 10px;
    }
    ${HeaderWrapper} {
        padding-top: 20px;
    }
    ${Header} {
        margin-bottom: 0px;
    }
    ${CountWrapper} {
        width: auto;
    }
    ${BotChannelsWrapper} {
        width: auto;
    }
`
export const ChatWrapperLeft = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #CECECE;
    padding: ${props => (props.chartsPadding ? '10px 20px' : '0px')};
    margin-top: 20px;
    .bar_charts_wrapper {
        height: 300px;
        width: 100%;
        float: left;
        margin-bottom: 0px;
        display: flex;
        margin-top: 14px;
    }
    .bar_charts_wrapper.three_col{
        height: 260px;
    }
    .bar_no_charts_wrapper {
        height: 100%;
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -20px;
        p {
            padding-top: 0px !important;
        }
    }
    ${Header} {
        margin-bottom: 30px;
        @media (max-width: 1536px) {
            margin-bottom: 30px;
        }
    }

    .recharts-responsive-container {
        width: 100%;
        height: 300px !important;
    }
    .bot_session_chart {
        width: 100%;
        height: 270px !important;
    }
`
export const ChatWrapperRight = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid #CECECE;
    // padding: ${props => (props.chartsPadding ? '22px 20px' : '0px')};
    ${Header} {
        margin-bottom: 40px;
    }

    .pie_chart_img {
        display: flex;
        gap: 0 30px;
        padding: 0 30px;
        flex-direction: row;
        position: relative;
        margin-top: 5px;

        @media (max-width: 1367px) {
            gap: 0 20px;   
            padding: 0 20px;      
        }
        @media (max-width: 1280px) {
            gap: 0 10px;   
            padding: 0 10px;      
        }
        
        .pie_chart_img_box{
            width: 180px;
            height: 180px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1440px) {
                width: 220px;
                height: 220px;     
            }

           
            .pie_chart_center {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 30px;
                margin-left: 5px;                       
    
                p:first-child {
                    font-size: 36px;
                    font-family: 'Gordita-Medium';
                    margin: 0px;
                    line-height: normal;
                    line-height: 24px;
                    margin-bottom: 4px;
    
                    @media (max-width: 1367px) {
                        font-size: 28px;
                        margin-bottom: 0;
                    }
                }
                p:last-child {
                    font-size: 18px;
                    font-family: 'Gordita-Medium';
                    margin: 0px;
                    line-height: 18px;

                    @media (max-width: 1367px) {
                        font-size: 14px;
                    }
                }
            }
        }      

        .pie_chart_center.user_queries{
            width: 100%;
            left: 0;
            top: 82px;
        }
    }
    .pie_chart_img.with_out_data{
        // margin-top: 35px;
        // padding-top: 40px;
        height: 240px;
    }

    div.flex{
        margin-top: 40px;
    }

    .pie_chart_img.agent_handover{
        display: flex;
        gap: 0 30px;
        padding: 0px;
        flex-direction: row;
        margin-bottom: 0px;
        position: relative;
        margin-top: 5px;

        .pie_chart_img_box{
            width: 250px;
            height: 200px;
            display: flex;
            position: relative;            
            flex-direction: row;
            position: relative;
            left: 0px;           
        }        
    }

    .pie_chart_img.user_queries{
        width: 100%;
        height: 200px;
        margin-bottom: 0px;
        position: relative;
        margin-top: 5px;
        display: flex;
        padding: 0px 10px;
        flex-direction: row;

        .pie_chart_center {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;                       

            p:first-child {
                font-size: 36px;
                font-family: 'Gordita-Medium';
                margin: 0px;
                line-height: normal;
                line-height: 24px;
                margin-bottom: 4px;

                @media (max-width: 1367px) {
                    font-size: 28px;
                    margin-bottom: 0;
                }
            }
            p:last-child {
                font-size: 18px;
                font-family: 'Gordita-Medium';
                margin: 0px;
                line-height: 18px;

                @media (max-width: 1367px) {
                    font-size: 14px;
                }
            }
        }
    }

    .pie_chart_userQueries{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(50%,0.5fr));
        align-items: center;
        width: 100%;
        margin-top: 64px;
        margin-left: 40px;
        @media (max-width: 1367px) {
            margin-left: 10px;
        }
    }
`
export const ChartWrapper = styled.section`
width:100%;
float:left;
background: ${props => (props.chartsBg ? 'inherit' : '#FFFFFF 0% 0% no-repeat padding-box;')};
// box-shadow: 0px 2px 4px #0000001A;
border-radius: 10px;
padding:${props => (props.chartsBg ? '0px' : '22px 20px')};
display:grid;
// grid-template-columns: ${props => (props.chartsBg ? '66.2% 1fr' : '62.5% 33%')};
grid-template-columns: 100%;
// margin-top:20px;
grid-gap: ${props => (props.chartsBg ? '20px' : '60px')};
justify-content:space-between;

.custom-tooltip {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000029;
    min-width:118px;
    padding:10px 8px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 20px #00000029;
}
.custom-tooltip p{
    color:#526A86;
    margin-bottom:0px;
    font-size: 10px;
    font-family: 'Gordita-Regular';
    text-laign:left;
    display:flex;
    justify-content:left;
    line-height:normal;
    margin-bottom:3px;
    margin-top: 4px;
    :last-child{
        margin-bottom:0px;
    }
    span {
        margin-left:3px;
        display: flex;
    }
    :nth-child(1){
        span {
        font-family: 'Gordita-Medium';
        color:#000;
        }
    }
    :nth-child(2){
        span {
        font-family: 'Gordita-Medium';
        color:#5AD1C9;
        }
    }
    :nth-child(3){
        span {
        font-family: 'Gordita-Medium';
        color:#eb6453;
        }
    }
    :nth-child(4){
        span {
        font-family: 'Gordita-Medium';
        color:#FFAB57;
        }
    }
    .rejected{
        font-family: 'Gordita-Medium';
        color:#eb6453 !important;
    }
    .approved{
        font-family: 'Gordita-Medium';
        color:#a1e89b !important;
    }
    .waiting_for_approval{
        font-family: 'Gordita-Medium';
        color:#FFAB57 !important;
    }
}
._chart_tip p{
    color:#526A86 !important;
    margin-bottom:0px;
    font-size: 10px;
    font-family: 'Gordita-Regular';
    text-laign:left;
    display:flex;
    justify-content:left;
    line-height:normal;
    margin-bottom:3px;
    :last-child{
        margin-bottom:0px;
    }
    span {
        margin-left:3px;
    }
    :nth-child(1){
        span {
        font-family: 'Gordita-Medium';
        color:#000;
        }
    }
    :nth-child(2){
        span {
        font-family: 'Gordita-Medium';
        color:#5AD1C9;
        }
    }
    :nth-child(3){
        span {
        font-family: 'Gordita-Medium';
        color:"#eb6453";
        }
    }
    :nth-child(4){
        span {
        font-family: 'Gordita-Medium';
        color:#eb6453;
        }
    }
}
.custom-tooltip._resolved p{
    :nth-child(1){
        span {
        font-family: 'Gordita-Medium';
        color:#BD60C7;
        }
    }
    :nth-child(2){
        span {
        font-family: 'Gordita-Medium';
        color:#5AD1C9;
        }
    }
    :nth-child(3){
        span {
        font-family: 'Gordita-Medium';
        color:#333;
        }
    }
}
.custom-tooltip p.custom_tooltip_persentage_color{
    padding-left: 5px;
    margin-top: -1px;
}

`
export const PieContent = styled.div`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    white-space: pre;
    display: flex;
    gap: 10px 0;

    div {
        width: auto;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        color: #000;
        position: relative;
        margin-left: 20px;
        @media (max-width: 1280px) {
            margin-left: 14px;
        }

        // :nth-child(1){
        //     margin-bottom: 20px;
        //     display: flex;

            .target.customTip{
                position: absolute !important;
                margin-left: 4px;
                cursor: pointer;
                top: 1px;

                @media (min-width: 1920px) {
                    right: 48px;
                }
                @media (max-width: 1440px) {
                    right: -22px;
                }
                @media (max-width: 1370px) {
                    right: -12px;
                }
                
                svg{
                    color: #999999;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        span {
            width: 16px;
            height: 16px;
            float: left;
            background: #fff;
            position: relative;
            border-radius: 50%;
            margin-right: 6px;
        }
        ._dot_1 {
            background: #7AE3DC;
        }
        ._dot_2 {
            background: #FF7462;
        }
        ._dot_3 {
            background: #FFAB57;
        }
        ._dot_4 {
            background: #FBE699;
        }
    }
`
export const Row = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: baseline;
    ${PieContent} {
        justify-content: flex-end;
        margin-top: 0px;

        div {
            margin-left: 20px;
        }
        ._dot_1 {
            background: #5a9bea;
        }
        ._dot_2 {
            background: #bd60c7;
        }
        ._dot_3 {
            background: '#EB6453';
        }
    }
    ${Header} {
        width: 100%;
        display: flex;
        align-items: center;
    }
`
export const FeedbackWrapper = styled.div`
    width: 70%;
    float: left;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 20px 0;
    position: relative; 

    label {
        width: 85%;
        float: left;
        margin: 0px;
        margin-right: 40px;
        display: flex;
        place-items: flex-end;
        position: relative; 

        :last-child {
            margin-right: 0px;
        }

        img {
            width: 18px;
            height: 18px;
            margin: 0px;
            float: left;
            margin-right: 6px;
        }
        span {
            width: auto;
            float: left;
            font-size: 12px;
            font-family: 'Gordita-Regular';
            color: #000;
            position: relative;
            top: 2px;
        }
        p{
            position: absolute;
            right: 0px;
            top: 2px;
            font-size: 12px;
            font-family: 'Gordita-Medium';
            color: #000;
            margin: 0;
        }
    }
`
export const CostSaving = styled.span`
    position: absolute;
    left: ${props => (props.contentLeft ? ' -22px' : '-22px')};
    top: 36%;
    transform: rotate(270deg);
    color: #000;
    font-size: 14px;
    font-family: 'Gordita-Regular';
`
export const TimerSavings = styled.span`
    position: absolute;
    left: ${props => (props.contentLeft ? ' 330px' : '330px')};
    top: 100%;
    transform: rotate(0deg);
    color: #000;
    font-size: 14px;
    font-family: 'Gordita-Regular';
`
export const ChartLegend = styled.span`
    position: absolute;
    left: ${props => (props.contentLeft ? ' 330px' : '330px')};
    top: 100%;
    transform: rotate(0deg);
    color: #000;
    font-size: 14px;
    font-family: 'Gordita-Regular';
    padding: 12px;
`
export const ChartFeedbackWrapper = styled.div`
    width: 100%;
    float: left;
    ${ChatWrapperLeft} {
        // box-shadow: 0px 2px 4px #0000001a;
        border-radius: 10px;
    }
    ${ChatWrapperRight} {
        // box-shadow: 0px 2px 4px #0000001a;
        border-radius: 10px;
    }
    ${CostSaving} {
        top: 48%;
        left: ${props => (props.contentLeft ? ' 0px' : '-22px')};
    }
    .cost_saving {
        left: 0px;
    }
    .apexcharts-canvas {
        padding-left: ${props => (props.contentLeft ? ' 10px' : '20px')};
    }
    .apexcharts-svg {
        width: 100%;
        .apexcharts-xaxis-texts-g {
            transform: translate(0px, 15px) !important;
        }
        .apexcharts-xaxis-texts-g tspan {
            font-family: 'Gordita-Regular' !important;
            color: #555555;
            font-size: 10px;
        }
    }
    .apexcharts-svg.apexcharts-zoomable {
        overflow: inherit;
        margin-left: -10px;
        width: 100%;
        .apexcharts-xaxis-texts-g {
            transform: translate(8px, 15px) !important;
        }
        .apexcharts-xaxis-texts-g tspan {
            font-family: 'Gordita-Regular' !important;
            color: #555555;
            font-size: 10px;
        }
    }

    .pie-chart{
        // margin-top: 32px;
    }
`
const LineChart = styled.div`
  width: 100%;
  float: left;
  position relative;

  .month_wrapper{
      padding-top:0px;
      color:#526A86;
      font-size: 18px;
    font-family: 'Gordita-Regular';
    @media (max-width:1536px){
        font-size: 16px;
      }
  }

  .bot_session_chart{
    height: 350px !important;
    max-width: 100%;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 70px;

    @media (max-width:1700px){
        height:300px !important; 
      }
      @media (max-width:1536px){
        height:270px !important; 
      }
      @media (max-width:1400px){
          height:240px !important; 
        }
  }
  .no_data_bot_session_chart{
    height: 100% !important;
    width: 100%;
    margin-top: 0;
  }
             
  
`
const ChartsWrapper = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 70% 1fr;
    grid-gap: 0px 30px;
`
const Container = styled.section`
    width: 100%;
    float: left;
    display: grid;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 30px;
    overflow: auto;
`
export const BgLine = styled.div`
    width: 95%;
    background-color: #fff;
    padding: 0px;
    float: right;
    height: 16px;
    margin-left: 15px;
`
export const Span = styled.span`
    float: left;
    width: max-content;
    padding: 0px;
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 12px;
`
export const ProgressBar = styled.div`
    width: ${(props: any) => (props.width ? props.width : '40%')};
    float: left;
    display: flex;
    align-items: center;
    position: relative;
    top: 3px;

    ${Span} {
        width: 50px;
        display: flex;
        justify-content: flex-end;
    }
`
export const ProgressContent = styled.div`
    width: ${(props: any) => (props.width ? props.width : '60%')};
    float: left;
    color: #333;
    font-size: 14px;
    font-family: 'Gordita-Medium';
    cursor: ${(props: any) => (props.nolink ? "default" : "pointer")};
    transition: 0.5s ease;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    top: 3px;

    :hover {
        color: ${(props: any) => (props.nolink ? "#333" : "#2468f6")};
        text-decoration: ${(props: any) => (props.nolink ? "unset" : "underline")};
    }
`
export const ProgressContentWithoutHover = styled.div`
    width: ${(props: any) => (props.width ? props.width : '60%')};
    float: left;
    color: #355d8b;
    font-size: 12px;
    font-family: 'Gordita-Medium';
    transition: 0.5s ease;
    cursor: default;
`
export const ProgressLi = styled.div`
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    height: auto;
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px 20px;
    progress {
        text-align: right;
        color: white;
        background: #fff;
        width: 100%;
        border-radius: 0px 6px 6px 0px;
        float: left;
        height: 20px;
        position: relative;
        :before {
            position: absolute;
            content: '';
            height: 40px;
            border-left: 1px solid #e5e5e5;
            width: 1px;
            left: 0;
            top: -18px;
        }
        :after {
            position: absolute;
            content: '';
            height: 40px;
            border-left: 1px solid #e5e5e5;
            width: 1px;
            left: 0;
            top: 0px;
        }
    }

    progress::-webkit-progress-value {
        background: rgb(218, 237, 137);
        border-radius: 0px 8px 8px 0px;
    }

    progress::-moz-progress-bar {
        background:rgb(218, 237, 137);
        border-radius: 0px 8px 8px 0px;
    }

    progress::-webkit-progress-value {
        background: rgb(218, 237, 137);
        border-radius: 0px 8px 8px 0px;
    }

    progress::-webkit-progress-bar {
        background: #fff;
        border-radius: 0px 8px 8px 0px;
    }

    .progress_3 {
        progress::-webkit-progress-value {
            background: #eb6453;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-moz-progress-bar {
            background: #eb6453;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-webkit-progress-value {
            background: #eb6453;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-webkit-progress-bar {
            background: #fff;
            border-radius: 0px 8px 8px 0px;
        }
    }
    .progress_2 {
        progress::-webkit-progress-value {
            background: #ffab57;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-moz-progress-bar {
            background: #ffab57;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-webkit-progress-value {
            background: #ffab57;
            border-radius: 0px 8px 8px 0px;
        }

        progress::-webkit-progress-bar {
            background: #fff;
            border-radius: 0px 8px 8px 0px;
        }
    }

    .summary_progress_container{
        height: 12px;
        display: flex;
        position: relative;
        width: 100%;
        :before {
            position: absolute;
            content: '';
            height: 40px;
            border-left: 1px solid #e5e5e5;
            width: 1px;
            left: 0;
            top: -18px;
        }
        :after {
            position: absolute;
            content: '';
            height: 33px;
            border-left: 1px solid #e5e5e5;
            width: 1px;
            left: 0;
            top: 0px;
        }

        .summary_progress_bar{
            border-radius: 0 10px 10px 0;
            justify-content: center;
            overflow: hidden;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            display: flex;
        }
    }
`
export const ProgressWrapper = styled.div`
    width: 100%;
    float: left;
    // padding: 2px 20px;
    height: 280px;
    overflow-y: scroll;

    ${ProgressLi}:last-child {
        // border: none;
        progress::after {
            height: 20px;

            @media (max-width: 1700px) {
                height: 16px;
            }
        }
    }
`
export const BotCard = styled.div`
    width: 100%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #0000001a;
    border-radius: 10px;
    position: relative;

    ${CardHeader} {
        padding: 22px 20px;
        border-bottom: 1px solid #0000001a;
        margin-bottom: 0px;
        text-transform: inherit;
        display: flex;
        flex-direction: column;

        ${Header} {
            margin-bottom: 12px;
            line-height: 16px;
        p {
            font-family: 'Gordita-Regular';
            color: #000;
            font-size: 14px;
            padding-bottom: 0px;
            margin-bottom: 0px;
            width: 100%;
            float: left;
            line-height: 14px;
        }
    }
    :nth-child(2) {
        ${ProgressWrapper} {
            progress::-webkit-progress-value {
                background: #ffab57;
            }

            progress::-moz-progress-bar {
                background: #ffab57;
            }

            progress::-webkit-progress-value {
                background: #ffab57;
            }

            progress::-webkit-progress-bar {
                background: #fff;
            }
        }
    }
    :nth-child(3) {
        ${ProgressWrapper} {
            progress::-webkit-progress-value {
                background: #eb6453;
            }

            progress::-moz-progress-bar {
                background: #eb6453;
            }

            progress::-webkit-progress-value {
                background: #eb6453;
            }

            progress::-webkit-progress-bar {
                background: #eb6453;
            }
        }
    }

    label {
        position: absolute;
        top: 20px;
        right: 20px;
        margin: 0px;

        span {
            width: 16px;
            height: 16px;
            cursor: pointer;

            svg {
                width: 16px;
                height: 16px;
                color: #999;
            }
        }
    }
`
export const BotCardWrapper = styled.div`
    width: 100%;
    float: left;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px;

    ${({ WorkflowCardWidth }) =>
        WorkflowCardWidth &&
        `
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    `}
`
export const NodataContent = styled.p`
    width: 100%;
    float: left;
    margin-bottom: 0px;
    dispalay: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gordita-Regular';
    font-size: 16px;
    color: #000;
    text-align: center;
    height: 100%;
    display: flex;
    // padding-top: 65px;
`
export const HeaderContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;

    .tab_user_queris_one{
        grid-template-columns: repeat(auto-fill,minmax(20%,1fr));
        margin-bottom: 10px;
        grid-gap: 10px;

        @media (max-width: 1441px) {
            // grid-template-columns: repeat(auto-fill,minmax(22%,1fr));
          }
    }

    .tab_user_queris_one.agent_handover{
        grid-template-columns: repeat(auto-fill,minmax(20%,1fr));
        ${Card}{
            // height: 120px;
        }
    }    
    .tab_user_queris_two{
        grid-template-columns: repeat(auto-fill,minmax(16%,1fr));
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .bot_sessions{
        margin-bottom: 16px;
    }

    .tab_user_queris_two.user_metric{
        margin-bottom: 0px;
    }

    .tab_user_queris_two.user_queries, .tab_user_queris_two.live_bot, 
    .tab_user_queris_two.user_metrics, .tab_user_queris_two.roi{
        grid-template-columns: repeat(auto-fill,minmax(18%,1fr));
    }

    ${Card}{
        ${CardHeader}{
            .target_bot{
                span{
                    svg{
                        top: 0;
                    }
                }
            }
        }
    }
    
`