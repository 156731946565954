import 'react-circular-progressbar/dist/styles.css';

import { differenceInDays } from 'date-fns';
/*eslint-disable*/
import * as R from 'ramda';
import { props } from 'ramda';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled, { StyledComponent } from 'styled-components/macro';

import {
  ArrowIcon,
  BasicIcon,
  DownarrowWithCircle,
  InfoColorIcon,
  InfoIcon,
  InformationIcon,
  PriceEnterpriseIcon,
  PriceFreeIcon,
  PriceProIcon,
  PriceStandardIcon,
  PricingNoIcon,
  PricingYesIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';

import { formatDateInCard, generateUniqueId } from '@/common/utils/utils';
import { getProductId, loginApiURL, postJson } from '@/common/utils/api-utils';
import { PlanV2HeadProps } from './BillingOverview-v2';
import { SubscriptionDetailsToDisplay } from './CurrentPlan';
import { plurarize } from './Plans';
import {
  ResourceConsumptionForDisplay,
  ResourceDetails,
  ResourceDisplay,
  planIconMap,
} from './kind';
import { stripeUTCDate } from './util-stripe';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { ProfileTabs } from '@/SidePanel/Sidebar'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const WrapperV2 = styled.section`
  border-radius: 6px;
  width: 100%;
  display: flex;
`;
export const ContainerV2: StyledComponent<'div', any, any, never> = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: ${(props) => (props.flexDirection ? 'row' : 'column')};
`;
export const BackButton = styled.button`
  background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
  border-radius: 18px;
  border: ${(props) =>
    props.primary ? '1px solid #cecece' : '1px solid #2468f6'};
  outline: 0;
  font-size: 12px;
  color: ${(props) => (props.primary ? '#333' : '#fff')};
  font-family: 'Gordita-Regular';
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 24px;
  transition: 0.346s ease;
  padding: 0px 10px;
  cursor: pointer;
  margin-right: 10px;

  label {
    margin-bottom: 0px;
    position: relative;
    top: 1.5px;
    cursor: pointer;
  }
  :hover {
    border: 1px solid #009d5f;
    background: #ddfeef;
    color: #333;
    fill: #333;
    outline: 0;
    cursor: pointer;
    span svg {
      fill: ${(props) => (props.primary ? '#333' : '#333')};
      color: ${(props) => (props.primary ? '#333' : '#333')};
    }
  }
  :active {
    background: ${(props) => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
    border: ${(props) =>
      props.primary ? '1px solid #009d5f' : '1px solid #009d5f'} !important;
    color: ${(props) => (props.primary ? '#333' : '#333')}!important;
  }

  span {
    width: 10px;
    height: 10px;
    float: left;
    margin-right: 3px;
    display: flex;
    align-items: center;
    position: relative;
    top: 0.5px;
    svg {
      width: 10px;
      height: 10px;
      fill: ${(props) => (props.primary ? '#333' : '#fff')};
      color: ${(props) => (props.primary ? '#333' : '#fff')};
      transform: rotate(180deg);
    }
  }
`;
export const BackButtonWrapper = (props: any) => {
  return (
    <BackButton {...props}>
      <span>
        <ArrowIcon />
      </span>{' '}
      <label>Back</label>
    </BackButton>
  );
};
export const HeaderName = styled.h4`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 20px;
  color: #466AF2;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  div {
    position: relative;
    top: 2px;
    margin: 0px;
    // padding-left: 10px;
    width: auto;
  }
`;
const HeaderLinkRight = styled.div`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 16px;
  color: #466af3;
  margin-bottom: 0px;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 0px;
  position: relative;

  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;
const CurrentPlanCardWrapper = styled.div`
  width: 100%;
  float: left;
  width: 100%;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #16161629;
  border-radius: 10px;
  padding: 20px;
  display: grid;

  @media (max-width: 1600px) {
    padding: 15px 20px;
  }
`;

const CurrentPlanCard = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 31.3% 16% 16% 16% 16%;
  grid-gap: 20px;
  align-items: center;

  @media (max-width: 1600px) {
    grid-gap: 15px;
  }
  @media (max-width: 1500px) {
    grid-gap: 12px;
    grid-template-columns: 32% 16% 16% 16% 16%;
  }
  @media (max-width: 1400px) {
    grid-gap: 10px;
    grid-template-columns: 28% 17% 17% 17% 17%;
  }
  @media (max-width: 1400px) {
    grid-gap: 10px;
  }
`;
export const BodyWrapperV2 = styled.section`
  width: 100%;
  float: left;
  padding: 0px 20px 20px 20px;
  height: ${(props) =>
    props.unVerified? 'calc(100vh - 120px)': props.scrollHeight ?  'calc(100vh - 60px)' : 'calc(100vh - 65px)'};
  overflow-y: ${(props) => (props.overflowScroll ? 'inherit' : 'scroll')};
  padding-top: 20px;
`;

export const PlansWrapper = () => {
  return (
    <div className="plansWrapper">
      <AlertWrapper />
      <PlansHeaderV2 name={'Usage'} />
      <PlansBodyWrapper />
    </div>
  );
};

export const PlansHeaderV2 = (props: any) => {
  return (
    <WrapperV2>
      <Header>
        <ContainerV2 flexDirection>
          <TopHeaderWrapper>
            <HeaderName>
              {props.backButton && (
                <BackButtonWrapper onClick={() => props.goBack()} />
              )}
              <div>{props.name}</div>
            </HeaderName>
            <QuestionYourPlan />
          </TopHeaderWrapper>
        </ContainerV2>
      </Header>
    </WrapperV2>
  );
};

export const Button = styled.button`
  min-width: 90px;
  height: 32px;
  background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
  border-radius: 18px;
  border: ${(props) =>
    props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6'};
  outline: 0;
  font-size: 12px;
  padding: 7px 12px;
  font-family: 'Gordita-Medium';
  color: ${(props) => (props.primary ? '#333' : '#fff')};
  margin: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0 0;

  :hover {
    background: #ddfeef;
    color: #333;
    border: 1px solid #009d5f;

    svg {
      color: ${(props) => (props.primary ? '#333' : '#333')};
      fill: ${(props) => (props.primary ? '#333' : '#333')};
    }
  }
  :active {
    background: ${(props) => (props.primary ? '#ddfeef' : '#ddfeef')}!important;
    border: ${(props) =>
      props.primary ? '1px solid #009d5f' : '1px solid #009d5f'} !important;
    color: ${(props) => (props.primary ? '#333' : '#333')}!important;
  }

  span {
    width: 12px;
    height: 12px;
    float: left;
    margin-right: 6px;
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
      color: ${(props) => (props.primary ? '#333' : '#fff')};
      fill: ${(props) => (props.primary ? '#333' : '#fff')};
      margin-right: 0px;
    }
  }
  label {
    margin-bottom: 0px;
    position: relative;
    top: 1px;
    cursor: pointer;
  }
`;

export const PlansBodyWrapper = () => {
  return (
    <BodyWrapperV2 scrollHeight>
      <WrapperV2>
        <ContainerV2>
          <PlanV2 />
          <UpgradePlanV2 />
          <PricingWrapperV2 />
        </ContainerV2>
      </WrapperV2>
    </BodyWrapperV2>
  );
};

export const PlanV2 = () => {
  return (
    <CurrentPlanCardWrapper>
      <CurrentPlanCard>
        <>
          <PricingPlanCards
            plan={'Your Current Plan'}
            currentPlan={'Pro Trial'}
            CurrentPlanCounts={'12 days remaining'}
          />
          <WorkflowCards
            name={'Active Workflows'}
            count={'2/2'}
            value={'100'}
          />
          <WorkflowCards
            name={'Workflow Executions'}
            count={'36/100'}
            value={'36'}
          />
          <WorkflowCards name={'FAQ Answers'} count={'108/300'} value={'36'} />
          <WorkflowCards name={'Messages'} count={'308/500'} value={'63'} />
        </>
      </CurrentPlanCard>

      <HeaderLinkRight>
        <span>View Previous Consumptions</span>
      </HeaderLinkRight>
    </CurrentPlanCardWrapper>
  );
};
export const HeaderWidthAuto = styled.div`
  width: ${(props) => (props.progressWidth ? '100%' : 'auto')};
  float: left;
  display: flex;
  flex-direction: column;

  h4,
  h5 {
    width: auto;
  }
  h6 {
    width: auto;
    color: #355d8b;
    margin-left: 3px;
  }
`;
export const UpgradeButton = styled.div`
  width: max-content;
  align-items: flex-end;
  display: flex;
  position: relative;
  top: 16px;

  button {
    margin-right: 0px;
    margin-left: 15px;
  }
`;

export const ImgWrapper = styled.span`
  width: 100px;
  height: 100px;
  float: left;
  border: 1px solid #009d5f;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  background: #ddfeef;
  svg {
    fill: #000;
    color: #000;
  }
`;
export const PlanContetnWrapper = styled.div`
  float: left;
  padding-left: 15px;
  width: ${(props) => (props.progressWidth ? '60%' : '75%')} @media
    (max-width: 1600px) {
    padding-left: 10px;
  }
  @media (max-width: 1300px) {
    padding-left: 7px;
  }
`;
export const CurrentPlanWrapper = styled.div`
    width: auto;
    float: left;
    display: flex;
    align-items: center;

    ${PlanContetnWrapper} {
        display: flex;
        width: ${(props) => (props.progressWidth ? '60%' : 'max-content')}
    }
    progress {
        text-align: right;
        color: white;
        background: #f4f4f4;
        width: 100%;
        border-radius: 5px;
        float: left;
        height: 10px;
      }

      progress::-webkit-progress-value {
        background: #F4CAAD;
        border-radius: 5px;
      }

      progress::-moz-progress-bar {
        background: #f4f4f4;
        border-radius: 5px;
      }

      progress::-webkit-progress-value {
        background: #F4CAAD;
        border-radius: 5px;
      }

      progress::-webkit-progress-bar {
        background: #f4f4f4;
        border-radius: 5px;
      }
    }
`;
export const CurrentPlanHeader = styled.h4`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 16px;
  color: #000;
  margin-bottom: 0px;
`;
export const Span = styled.span`
  width: auto;
`;
export const CurrentPlanDetail = styled.h5`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 14px;
  color: #466af3;
  margin-bottom: 6px !important;
  margin-left: 2px;
  margin-top: 6px;

  ${Span} {
    font-size: 14px;
    color: #355d8b;
    font-family: 'Gordita-Regular';
    display: flex;
    align-items: center;
  }

  @media (max-width: 1700px) {
    ${Span} {
      font-size: 12px;
    }
  }
  @media (max-width: 1500px) {
    ${Span} {
      font-size: 10px;
    }
  }

  @media (max-width: 1300px) {
    ${Span} {
      font-size: 10px;
    }
  }
`;
export const TrialDate = styled.h6`
  width: 100%;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: #333;
  margin-bottom: 0px;
  ${Span} {
    font-size: 14px;
    color: #e88600;
    font-family: 'Gordita-Regular';
    cursor: pointer;
  }
  @media (max-width: 1600px) {
    ${Span} {
      font-size: 12px;
    }
  }

  @media (max-width: 1300px) {
    ${Span} {
      font-size: 11px;
    }
  }
`;
export const CurrentPlanCards = (props: any) => {
  return (
    <CurrentPlanWrapper>
      <ImgWrapper>
        <PriceProIcon />
      </ImgWrapper>
      <PlanContetnWrapper>
        <HeaderWidthAuto>
          <CurrentPlanHeader>{props.plan}</CurrentPlanHeader>
          <CurrentPlanDetail>
            {props.CurrentPlan}
            <Span> {props.CurrentPlanCounts}</Span>
          </CurrentPlanDetail>
          <TrialDate> Feb 14, 2021 - Mar 07, 2021</TrialDate>
        </HeaderWidthAuto>
        {props.upgradeButton ? (
          <UpgradeButton>
            <Button>
              <label>{props.btnName}</label>
            </Button>
          </UpgradeButton>
        ) : null}
        {props.progressBar ? (
          <CurrentPlanDetail>
            {' '}
            {props.CurrentPlan}
            <Span> {props.CurrentPlanCounts}</Span>
            <progress value="32" max="100">
              {' '}
              32%{' '}
            </progress>
          </CurrentPlanDetail>
        ) : null}
      </PlanContetnWrapper>
    </CurrentPlanWrapper>
  );
};

export const CurrentPlanCardsV2 = (props: PlanV2HeadProps) => {
  let image = planIconMap[props.planReferenceName];
  // console.log('props', props)
  if (
    (image === null || image === undefined) &&
    (props.planReferenceName.includes('ENTERPRISE') ||
      props.planReferenceName.includes('CUSTOM'))
  ) {
    image = planIconMap['ENTERPRISE'];
  }

  return (
    <CurrentPlanWrapper>
      <ImgWrapper>{image}</ImgWrapper>
      <PlanContetnWrapper>
        <HeaderWidthAuto>
          <CurrentPlanHeader>Your Current Plan</CurrentPlanHeader>
          <CurrentPlanDetail>{props.currentPlan}</CurrentPlanDetail>
          <TrialDate>
            {formatDateInCard(props.currentPeriodStart.toString())} - &nbsp;
            {formatDateInCard(props.currentPeriodEnd.toString())}
          </TrialDate>
        </HeaderWidthAuto>

        <UpgradeButton onClick={() => props.gotoPlans()}>
          <Button>
            <label className='upgrade_btn'>
              {props.currentPlan.toLowerCase() == 'pro' &&
              props.pricing.tag === 'PAY' &&
              props.pricing.planType.toLowerCase() === 'yearly'
                ? 'View Plans'
                : 'Upgrade'}
            </label>
          </Button>
        </UpgradeButton>
      </PlanContetnWrapper>
    </CurrentPlanWrapper>
  );
};

export const PricingPlanCards = (props: any) => {
  const percentageRemaining = (props.daysRemaining / 21) * 100;
  // console.log('percentageRemaining', percentageRemaining)
  return (
    <CurrentPlanWrapper progressWidth>
      <ImgWrapper>
        <PriceProIcon />
      </ImgWrapper>
      <PlanContetnWrapper>
        <HeaderWidthAuto progressWidth>
          <CurrentPlanHeader>{'Your Current Plan'}</CurrentPlanHeader>
          <CurrentPlanDetail className="d-flex justify-content-between mb-1">
            {props.currentPlan}
            <Span>
              {' '}
              {props.daysRemaining > 0
                ? `${props.daysRemaining} days remaining`
                : 'Plan Expired'}
            </Span>
          </CurrentPlanDetail>
          <progress value="" max="100">
            ${percentageRemaining}%{' '}
          </progress>
        </HeaderWidthAuto>
      </PlanContetnWrapper>
    </CurrentPlanWrapper>
  );
};

function addDays(date: any, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export const SubscriptionDetails = ({
  subscriptionDetails,
  resources,
}: {
  subscriptionDetails: SubscriptionDetailsToDisplay;
  resources: ResourceConsumptionForDisplay[];
}) => {
  const todayInUTC = new Date(new Date().toUTCString());
  const trialDaysRemaining = differenceInDays(
    subscriptionDetails.trialEndDate,
    todayInUTC
  );
  const totalTrialDays = differenceInDays(
    subscriptionDetails.trialEndDate,
    subscriptionDetails.trialStartDate
  );
  const percentageRemaining = (trialDaysRemaining / totalTrialDays) * 100;

  // console.log('percentageRemaining', percentageRemaining, trialDaysRemaining, totalTrialDays)

  const resourceWithDisplayDetails = resources
    .map((resource) => {
      const displayDetails = ResourceDetails.find(
        (displayDetail) => displayDetail.name === resource.id
      );
      if (displayDetails === undefined) {
        return undefined;
      }

      return {
        ...resource,
        ...displayDetails,
      };
    })
    .filter((x) => x !== undefined)
    .filter(
      (item): item is ResourceDisplay & ResourceConsumptionForDisplay => !!item
    );

  resourceWithDisplayDetails.sort((resource1, resource2) => {
    if (resource1.priority < resource2.priority) {
      return -1;
    }
    if (resource1.priority > resource2.priority) {
      return 1;
    }
    return 0;
  });

  let image = planIconMap[subscriptionDetails.planReferenceName];
  if (
    (image === null || image === undefined) &&
    (subscriptionDetails.planReferenceName.includes('ENTERPRISE') ||
      subscriptionDetails.planReferenceName.includes('CUSTOM'))
  ) {
    image = planIconMap['ENTERPRISE'];
  }

  return (
    <>
      <CurrentPlanCardWrapper>
        <CurrentPlanCard className="automate_billing_page">
          <>
            <CurrentPlanWrapper progressWidth>
              <ImgWrapper>{image}</ImgWrapper>
              <PlanContetnWrapper>
                <HeaderWidthAuto progressWidth>
                  <CurrentPlanHeader>{'Your Current Plan'}</CurrentPlanHeader>
                  <CurrentPlanDetail className="d-flex justify-content-between mb-1">
                    {`${subscriptionDetails.planDisplayName} ${
                      subscriptionDetails.isInTrial ? ' Trial' : ''
                    }
`}
                    {subscriptionDetails.isInTrial ? (
                      <Span>
                        {trialDaysRemaining > 0 ?
                          (<span style={{position: 'relative', top: 2}}>
                            {trialDaysRemaining} {plurarize(
                              trialDaysRemaining,
                              'day'
                            )} remaining
                          </span>)
                          :
                          (
                            <>
                            <div className='automate_billing_plan_expired'>
                              <Span style={{color: 'red', position: 'relative', top: 2}}>
                                Plan Expired
                              </Span>
                              <WarningAlertIcon />
                              </div>
                            </>
                          )
                        }
                      </Span>
                    ) : null}
                  </CurrentPlanDetail>
                  {subscriptionDetails.isInTrial ? (
                    <progress
                      className="progressbar_color"
                      value={percentageRemaining}
                      max="100"
                    >
                      ${percentageRemaining}%{' '}
                    </progress>
                  ) : (
                    <TrialDate>
                      {formatDateInCard(
                        stripeUTCDate(
                          subscriptionDetails.currentPeriodStartDate
                        ).toString()
                      )}{' '}
                      -&nbsp;
                      {formatDateInCard(
                        stripeUTCDate(
                          subscriptionDetails.currentPeriondEndDate
                        ).toString()
                      )}
                    </TrialDate>
                  )}
                </HeaderWidthAuto>
              </PlanContetnWrapper>
            </CurrentPlanWrapper>

            {resourceWithDisplayDetails.map((resource) => (
              <WorkflowCards
                name={resource.title}
                count={`${resource.consumed}/${resource.resourceLimit}`}
                value={
                  (parseInt(resource.consumed) / resource.resourceLimit) * 100
                }
              />
            ))}
          </>
        </CurrentPlanCard>
        {/*  TODO :: Hidden as not implemented  */}
        {/*  <HeaderLinkRight>  */}
        {/*  <span>View Previous Consumptions</span>  */}
        {/*  </HeaderLinkRight>  */}
      </CurrentPlanCardWrapper>
    </>
  );
};
export const CardContent = styled.div`
  width: max-content;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-right: 7px;
`;
export const CardCircle = styled.div`
  width: 48px;
  height: 48px;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1600px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 1500px) {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 1300px) {
    width: 30px;
    height: 30px;
  }
`;
export const WorkflowWrapper = styled.div`
  background: #f1f3f5 0% 0% no-repeat padding-box;
  border-radius: 6px;
  height: 100px;
  padding: 20px;
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #466af3;
  }

  @media (max-width: 1600px) {
    height: 84px;
    padding: 15px;
  }
  @media (max-width: 1500px) {
    height: 72px;
    padding: 12px;
  }
  @media (max-width: 1400px) {
    padding: 10px 8px;
  }
`;
export const WorkflowHeader = styled.h5`
  width: 100%;
  float: left;
  font-size: 14px;
  color: #000;
  font-family: 'Gordita-Regular';
  margin-bottom: 10px;
`;
export const WorkflowCount = styled.h6`
  width: 100%;
  float: left;
  font-size: 14px;
  font-family: 'Gordita-Regular';
  color: #526a86;
  margin-bottom: 0px;
`;
export const WorkflowCards = (props: any) => {
  return (
    <WorkflowWrapper>
      <CardContent>
        <WorkflowHeader>{props.name}</WorkflowHeader>
        <WorkflowCount>{props.count}</WorkflowCount>
      </CardContent>
      <CardCircle>
        <CircularProgressbar value={props.value} />
      </CardCircle>
    </WorkflowWrapper>
  );
};

export const QuestionPlan = styled.div`
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  height: 100px;
  width: 100%;
  float: left;
  padding: 20px 0px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px 0;
  justify-content: flex-end;
  padding: 0;

  .billing_question_box{
    display: flex;
    gap; 6px;
    flex-direction: column;
    margin-top: 4px;
    margin-right: 12px;
    text-align: right;
  }

  .item-group.bottom_bar_sidemenu{
    margin-right: 0;
  }

  @media (max-width: 1600px) {
    padding: 15px 0px 15px 15px;
    height: 84px;
  }
  @media (max-width: 1500px) {
    padding: 12px 0px 12px 12px;
    height: 72px;
  }
  @media (max-width: 1400px) {
    padding: 10px 0px 10px 10px;
  }

  ${WorkflowHeader} {
    @media (max-width: 1600px) {
      font-size: 13px;
    }

    @media (max-width: 1500px) {
      font-size: 12px;
    }

    @media (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;
export const QuestionPlanLink = styled.h6`
  width: 100%;
  float: left;
  font-size: 14px;
  font-family: 'Gordita-Regular';
  color: #466af3;
  margin-bottom: 0px;

  a {
    color: #466af3;
    :hover {
      color: #466af3;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  float: left;
  height: 64px;
  border-bottom: 0.5px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #fff;
  padding: 0px 20px 0 10px;
  ${QuestionPlan} {
    height: auto;
    border: none;
    width: 100%;
    display: flex;
    align-items: flex-end;

    h5,
    h6 {
      width: auto;
    }
    ${WorkflowHeader} {
      margin-bottom: 4px;

      @media (max-width: 1600px) {
        font-size: 16px;
      }

      @media (max-width: 1500px) {
        font-size: 14px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }
    ${QuestionPlanLink} {
      @media (max-width: 1600px) {
        font-size: 16px;
      }

      @media (max-width: 1500px) {
        font-size: 14px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }
  }
`;

function AssistantPlanHeader(){
  const params = useParams()
  const location  = useLocation()
  const navigate = useNavigate()
  
  return(
    <QuestionPlan>
      <div className="billing_question_box">
        <WorkflowHeader>Have questions about your plan?</WorkflowHeader>
        <QuestionPlanLink>
          <a
            href="https://workativ.com/conversational-ai-platform/pricing"
            target="blank"
          >
            Learn about plans and pricing
          </a>
        </QuestionPlanLink>
      </div>
      <ProfileTabs {...{match:{params,path:location.pathname},location, history: { push: (url: string) => navigate(url),replace: (url: string) => navigate(url,{replace:true}), location } }}/>
    </QuestionPlan>
  )
}

export const QuestionYourPlan = () => {
  return (
    <>
    {getProductId() === "ASSISTANT" ? (
      <AssistantPlanHeader/>
     ) : ( 
      <QuestionPlan>
        <div className="billing_question_box">
          <WorkflowHeader>Have questions about your plan?</WorkflowHeader>
          <QuestionPlanLink>
            <a
              href="https://workativ.com/workflow-automation-platform/pricing"
              target="blank"
            >
              Learn about plans and pricing
            </a>
          </QuestionPlanLink>
        </div>
    </QuestionPlan>
     ) }
    {/* <QuestionPlan>
      <WorkflowHeader>Have questions about your plan?</WorkflowHeader>
      <QuestionPlanLink>
        <a
          href="https://workativ.com/workflow-automation-platform/pricing"
          target="blank"
        >
          Learn about plans and pricing
        </a>
      </QuestionPlanLink>
    </QuestionPlan> */}
    </>
  );
};

// Select Plan

export const UpgradePlanWrapper = styled.section`
  width: 100%;
  float: left;
  padding: 16px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #16161629;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UpgradeHeader = styled.h4`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 16px;
  color: #000;
  margin-bottom: 0px;
`;
export const UpgradePlanLeft = styled.div`
  width: auto;
  float: left;
`;
export const UpgradePlanRight = styled.div`
  width: auto;
  float: right;
  display: flex;
  z-index: 0;
`;

export const UpgradePlanV2 = () => {
  return (
    <UpgradePlanWrapper>
      <UpgradePlanLeft>
        <UpgradeHeader>Select Plan</UpgradeHeader>
      </UpgradePlanLeft>
      <UpgradePlanRight>
        <SubscriptionPlanChange />
        <Border />
        <Dropdown />
        <HoverUserIcon>
          <span className="info_icon">
            <InfoIcon />
            <span className="side_menu_hvr">
              Actual price may vary based on the exchange rate in place between
              USD and EUR at the time of payment processing or invoicing. Prices
              exclude all taxes, levies and duties.
            </span>
          </span>
        </HoverUserIcon>
      </UpgradePlanRight>
    </UpgradePlanWrapper>
  );
};

export const HoverUserIcon = styled.span`
width:auto;
float:left;
display: flex;
align-items: center;

.info_icon {
    position: relative;
    width: auto;
}
.info_icon {
    svg {
        width: 18px;
        height: 18px;
        color: #FF9922;
        cursor: pointer;
        margin-left: 7px;
        transition: all 0.8s ease 0s;
    }
    .side_menu_hvr {
        position: absolute;
        width: max-content;
        background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        color: rgb(49, 81, 118);
        max-width: 315px;
        bottom: 25px;
        right: -23px;
        font-family: Gordita-Regular;
        display: none;
        :before {
            position: absolute;
            content: ' ';
            background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
            bottom: -11px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent white;
            top: inherit;
            right: 25px;
            transform: rotate(180deg);
        }
    }
}
.info_icon:hover .side_menu_hvr {
    display: flex;
}

.verify_content{
    font-family: 'Gordita-Regular';
    font-size:12px;
    padding-top: 6px;
    label:first-child{
        color: rgb(0, 123, 255);
        cursor: pointer;
        padding-left: 10px;
        padding-right: 5px;
    }
    label:nth-child(2){
        color: rgb(0, 123, 255);
        cursor: pointer;
        padding-left: 5px;
    }
    .connect_account_loading img{
        width:14px;
        height:14px;
    }
}
@media (max-width:1600px){
    font-size: 14px;
    .info_icon {
        svg {
            width: 15px;
            height: 15px;
        }
        .side_menu_hvr {
            bottom: 25px;
            right: -24px;
            font-size: 14px;
            max-width: 290px;
        }
}

`;

export const Border = styled.span`
  width: auto;
  float: left;
  margin: 0px 10px;
  border-right: 1px solid #c6c6c6;
`;

const Toggler = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Toggle = styled.span`
  color: #000;
  transition: 0.2s;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: left;
  font-family: 'Gordita-Regular';
  font-size: 16px;
`;
const ToggleSwitch = styled.span`
  position: relative;
  width: 48px;
  float: left;
  height: 22px;
  border-radius: 100px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #2468f6;
  margin: 0px 10px;
`;

const Switch = styled.span`
  position: absolute;
  left: 3px;
  top: 3px;
  bottom: 2px;
  right: 57.5%;
  background-color: #2468f6;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 14px;
  height: 14px;
`;
const CheckBox = styled.input`
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
  &:checked ~ ${Switch} {
    right: 2px;
    left: 61.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0.08s, 0s;
  }
`;

export const SubscriptionPlanChange = (props: any) => {
  const [checkedBox, setCheckbox] = React.useState<Boolean>(
    props.defaultValue === 'Monthly' ? false : true
  );

  const toggleCheckbox = (_: any) => {
    if (checkedBox) {
      setCheckbox(false);
      props.toggleSwitch('Monthly');
    } else {
      setCheckbox(true);
      props.toggleSwitch('Yearly');
    }
  };

  return (
    <Toggler>
      <Toggle id="filt-monthly">Bill Monthly</Toggle>
      <ToggleSwitch>
        <CheckBox
          id="checkbox"
          type="checkbox"
          onChange={toggleCheckbox}
          checked={checkedBox}
        />
        <Switch></Switch>
      </ToggleSwitch>
      <Toggle id="filt-hourly">Bill Yearly</Toggle>
    </Toggler>
  );
};

export const SubscriptionCurrency = (props: any) => {

    return (
        <Toggler>
            <span className="verticalLine"></span>
            <Toggle>Choose Currency </Toggle>
                <HoverUserIcon>
                    <span className="info_icon">
                        <InfoIcon />
                        <span className="side_menu_hvr">Currenct converter information goes here </span>
                    </span>
                </HoverUserIcon>
        </Toggler>
    )
}

const DropdownWrapper = styled.div`
  width: max-content;
  float: left;
  display: flex;
  align-items: center;
`;

const DropdownLiWrapper = styled.div`
  width: 100%;
  position: absolute;
  border-radius: 8px;
  right: 0px;
  left: 0%;
  top: 30px;

  ul {
    padding: 0px;
    height: 100%;
    overflow-y: scroll;
    max-height: 305px;
    margin-bottom: 0px;
    box-shadow: rgb(0 0 0 / 16%) 0 3px 6px;
    border-radius: 6px;
    position: relative;
    width: 100%;
    float: left;
    z-index: 1;

    li {
      width: 100%;
      float: left;
      display: inline-flex;
      align-items: center;
      border-bottom: 0.5px solid #e6e6e6;
      font-size: 14px;
      font-family: 'Gordita-light';
      height: 30px;
      padding: 0 10px;
      background: #fff;
      cursor: pointer;

      label {
        margin-bottom: 0px;
        cursor: pointer;
        width: auto;
      }
      img {
        width: 22px;
        height: 12px;
        margin-right: 7px;
        cursor: pointer;
      }
    }
    li:hover {
      background: #0a89e8;
      color: #fff;
      border-bottom: 0.5px solid #0a89e8;
    }
    li:last-child:hover {
      background: #0a89e8;
      color: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 0.5px solid #0a89e8;
    }
    li:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: #fff;
      border: none;
    }
    li:first-child:hover {
      background: #0a89e8;
      color: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 0.5px solid #0a89e8;
    }
    li:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: #fff;
    }
  }
`;
const DropdownUl = styled.ul`
  width: 124px;
  height: 28px;
  background: #f1f3f5 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: flex;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding: 0px 10px;
  justify-content: space-between;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  label {
    margin-bottom: 0px;
  }
  img {
    width: 22px;
    height: 12px;
    margin-right: 7px;
  }
  .arrow_circle {
    display: flex;
    align-items: center;
    transform: rotate(90deg);
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

const Dropdown = () => {
  return (
    <DropdownWrapper>
      <Toggle>Choose Currency</Toggle>
      <DropdownUl>
        <label>
          <img
            src={'https://flagcdn.com/24x18/us.png'}
            alt="Workflows"
            title="Workflows"
          />
          <Span>USD $</Span>
        </label>
        <Span className="arrow_circle">
          <DownarrowWithCircle />
        </Span>

        <DropdownLiWrapper>
          <ul>
            <li>
              <label>
                <img
                  src={'https://flagcdn.com/24x18/us.png'}
                  alt="Workflows"
                  title="Workflows"
                />
                <Span>IND $</Span>
              </label>
            </li>
            <li>
              <label>
                <img
                  src={'https://flagcdn.com/24x18/us.png'}
                  alt="Workflows"
                  title="Workflows"
                />
                <Span>ENG $</Span>
              </label>
            </li>
          </ul>
        </DropdownLiWrapper>
      </DropdownUl>
    </DropdownWrapper>
  );
};

// Pricing Plan Ui

export const PricingHeaderRight = styled.div`
  width: auto;
  float: left;
  ${ImgWrapper} {
    width: 84px;
    height: 84px;
  }
`;
export const Paragraph = styled.p`
  width: 100%;
  float: left;
  text-align: left;
  font-family: 'Gordita-Regular';
  color: #526a86;
  font-size: 12px;
  margin-bottom: 0px;
`;

export const AmountContent = styled.div`
  width: 100%;
  float: left;
  text-align: left;
  font-family: 'Gordita-Medium';
  font-size: 28px;
  color: #000;
  line-height: normal;

  sub {
    font-family: 'Gordita-Regular';
    font-size: 16px;
    color: #000;
    line-height: normal;
  }
  sup {
    font-family: 'Gordita-Regular';
    font-size: 16px;
    color: #000;
    line-height: normal;
    top: -1rem;
  }
`;
export const HeaderContent = styled.div`
  width: 100%;
  float: left;
  text-align: left;
  font-family: 'Gordita-Medium';
  color: #526a86;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0px;
`;

export const Headerdescription = styled.div`
  width: 100%;
  float: left;
  text-align: left;
  font-family: 'Gordita-Regular';
  color: #000;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 8px;
`;
export const PricingHeaderLeft = styled.div`
  width: auto;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PricingHeader = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  height: 145px;
  gap: 20px;
  align-items: center;

  .discount_price {
    font-size: 11px;
    font-family: 'Gordita-Regular';
    color: #000;
    margin-top: 2px;
    @media (max-width: 1500px) {
      font-size: 10px;
    }
    span{
      position: relative;
      top: 1px;
    }
  }
  @media (max-width: 1400px) {
    padding: 0px 15px;
  }
`;

export const PricingSection = styled.section`
  width: 100%;
  float: left;
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 5px;
  display: grid;
`;
export const ComingSoonWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: left;
  font-family: 'Gordita-Medium';
  color: #000;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 15px;
  padding-left: 10px;

  // @media (max-width: 1600px) {
  //   font-size: 14px;
  //   margin-bottom: 5px;
  // }
`;

export const PricingWrapperV2 = () => {
  return (
    <>
      <PricingSection>
        <PricingContainer>
          {YearlyPlans.map((data) => (
            <PricingCard>
              <PricingHeader>
                <PricingHeaderLeft>
                  <HeaderContent>{data.plans}</HeaderContent>
                  {/* <Headerdescription>{data.description}</Headerdescription> */}
                  <AmountContent>
                    <sup>{data.dollar}</sup>
                    {data.price}
                    <sub>/month</sub>
                  </AmountContent>
                  <Paragraph>{data.additionalMessage}</Paragraph>
                </PricingHeaderLeft>
                <PricingHeaderRight>
                  <ImgWrapper>{data.image}</ImgWrapper>
                </PricingHeaderRight>
              </PricingHeader>
              <MessageWrapper>
                {data.topMessages.map((data) => (
                  <MessageContainer>
                    <MessageLeft>
                      <MessageHeader>
                        <label>
                          {data.category}
                          <label>
                            <span>
                              <InfoIcon />
                            </span>
                            <span data-tooltip={data.hoverContent}></span>
                          </label>
                        </label>
                      </MessageHeader>
                    </MessageLeft>
                    <MessageRight>{data.bolder}</MessageRight>
                  </MessageContainer>
                ))}
                <ButtonWrapper>
                  <Button>
                    <label>Select Plan</label>{' '}
                  </Button>
                  <HoverUserIcon>
                    <span className="info_icon">
                      <InfoIcon />
                      <span className="side_menu_hvr">
                        Downgrade not available
                      </span>
                    </span>
                  </HoverUserIcon>
                </ButtonWrapper>
              </MessageWrapper>

              <ContentWrapper
                className={data.plans === 'Pro' ? 'two_btn_wrapper' : null}
              >
                <MessageWrapper>
                  {data.steps.map((steps) => (
                    <MessageContainer>
                      <MessageLeft>
                        <MessageHeader>
                          <label>
                            {steps.category}{' '}
                            <sup style={{ top: '-.2em', color: '#E05959' }}>
                              *
                            </sup>
                            <label>
                              <span>
                                <InfoIcon />
                              </span>
                              <span data-tooltip={steps.hoverContent}></span>
                            </label>
                          </label>
                        </MessageHeader>
                      </MessageLeft>
                      <MessageRight>
                        {steps.count && <span>{steps.icon}</span> ? (
                          <span>
                            {steps.icon}
                            <Count className="badge">{steps.count}</Count>
                          </span>
                        ) : (
                          <span>{steps.icon}</span>
                        )}
                      </MessageRight>
                    </MessageContainer>
                  ))}
                </MessageWrapper>
              </ContentWrapper>
            </PricingCard>
          ))}
          <PriceEnterpriseWrapper>
            <PricingCard>
              <PricingHeader>
                <PricingHeaderLeft>
                  <HeaderContent>Enterprise</HeaderContent>
                  <AmountContent>
                    {/* <sup>$</sup>-<sub>/month</sub> */}
                  </AmountContent>
                </PricingHeaderLeft>
                <PricingHeaderRight>
                  <ImgWrapper>
                    <PriceEnterpriseIcon />
                  </ImgWrapper>
                </PricingHeaderRight>
              </PricingHeader>
              <MessageWrapper>
                <MessageContainer>
                  <Paragraph>
                    <b>Looking for More</b> <br /> Enterprise plans are
                    customised. Please reach out to us at{' '}
                    <a href="mailto:sales@workativ.com">sales@workativ.com</a>{' '}
                    and we’d be happy to discuss.
                  </Paragraph>
                  <ButtonWrapper>
                    <Button>
                      <label>Book Demo</label>
                    </Button>
                  </ButtonWrapper>
                </MessageContainer>
              </MessageWrapper>
            </PricingCard>
          </PriceEnterpriseWrapper>
        </PricingContainer>
      </PricingSection>
      {/* <ComingSoonWrapper>
                <span style={{ color: 'red' }}>* </span> Coming soon
            </ComingSoonWrapper> */}
    </>
  );
};
export const MessageRight = styled.div`
  width: auto;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 12px;
  color: #333;
  display: flex;
  align-items: center;

  svg{
    width: 16px;
    height: 16px;
  }
`;

export const MessageSection = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 6px;
`;

export const MessageTitle = styled.h5`
    font-family: 'Gordita-Medium';
    font-size: 14px;
    color: #333;
    margin-bottom: 0;
  
    :before{
      content: '';
      position: relative;
      width: 100%;
      height: 1px;
      display: block;
      background: #D0D0D0;
      bottom: 10px;
    }

    span.target span{
      margin-left: 3px;
      top: 2px;
      position: relative;
    }
    span.target span svg{
      width: 12px;
      height: 12px;
      color: #666666;
      cursor: pointer;
    }
    .messageTitle_icon{
      position: absolute;
      right: 16px;  

      @media (min-width: 1920px) {
        right: 21px;  
      }

      @media (max-width: 1440px) {
        right: 24px;  
      }


      svg{
        width: 14px;
        height: 14px;
      }
    }
    
`;
export const MessageTitleImg = styled.h5`
    font-family: 'Gordita-Medium';
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg{
      width: 16px;
      height: 16px;
    }
`;
export const MessageHeader = styled.h6`
  font-family: 'Gordita-Regular';
  font-size: 12px;
  color: #333;
  margin-bottom: 0px;
  display: flex;
  align-items: flex-start;
  line-height: inherit;

  label {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    color: #333333;
    position: relative;
    top: 2px;
  }
  .target {
    margin-left: 5px;
  }

  span {
    margin-left: 0px;
    position: relative;
    cursor: pointer;
    display: flex;
    margin-right: 0px;
    margin-top: 2px;
  }
  svg {
    width: 12px;
    height: 12px;
    color: #333333;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
    .tooltip_text {
      max-width: 270px;
      font-size: 14px;
    }
  }
  @media (max-width: 1500px) {
    font-size: 12px;
    
    .tooltip_text {
      max-width: 270px;
      font-size: 13px;
    }

    span {
      svg {
        width: 11px;
        height: 11px;
        color: #333333;
      }
    }
  }
  @media (max-width: 1400px) {
    font-size: 12px;

    .tooltip_text {
      max-width: 270px;
      font-size: 12px;
    }
  }
  @media (max-width: 1300px) {
    font-size: 12px;
  }

  /* START TOOLTIP STYLES */
  [data-tooltip] {
    position: absolute;
  }

  /* Applies to all tooltips */
  [data-tooltip]::before,
  [data-tooltip]::after {
    text-transform: none;
    font-size: 0.9em;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
  }

  [data-tooltip]::before {
    content: '';
    border: 5px solid transparent;
    z-index: 1001;
  }

  [data-tooltip]::after {
    content: attr(data-tooltip);
    font-family: 'Gordita-Regular';
    color: #333;
    font-size: 16px;
    max-width: 270px;
    width: max-content;
    line-height: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    background: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    z-index: 1000;
    white-space: pre-wrap;
  }

  /* Make the tooltips respond to hover */
  [data-tooltip]:hover::before,
  [data-tooltip]:hover::after {
    display: block;
    font-size: 9px;
    color: #476b95;
  }

  /* don't show empty tooltips */
  [data-tooltip='']::before,
  [data-tooltip='']::after {
    display: none !important;
  }

  /* FLOW: UP */
  [data-tooltip]:not([flow])::before,
  [data-tooltip][flow^='up']::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #fff;
  }

  [data-tooltip]:not([flow])::after,
  [data-tooltip][flow^='up']::after {
    bottom: calc(100% + 5px);
  }

  [data-tooltip]:not([flow])::before,
  [data-tooltip]:not([flow])::after,
  [data-tooltip][flow^='up']::before,
  [data-tooltip][flow^='up']::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
  }

  /* KEYFRAMES */
  @keyframes tooltips-vert {
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes tooltips-horz {
    to {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }

  /* FX All The Things */
  [data-tooltip]:not([flow]):hover::before,
  [data-tooltip]:not([flow]):hover::after,
  [data-tooltip][flow^='up']:hover::before,
  [data-tooltip][flow^='up']:hover::after,
  [data-tooltip][flow^='down']:hover::before,
  [data-tooltip][flow^='down']:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }

  [data-tooltip][flow^='left']:hover::before,
  [data-tooltip][flow^='left']:hover::after,
  [data-tooltip][flow^='right']:hover::before,
  [data-tooltip][flow^='right']:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
    [data-tooltip]::after {
      font-size: 14px;
    }
  }
  @media (max-width: 1500px) {
    font-size: 12px;
    [data-tooltip]::after {
      font-size: 13px;
    }
    span {
      svg {
        width: 11px;
        height: 11px;
        color: #333333;
      }
    }
  }
  @media (max-width: 1400px) {
    font-size: 12px;
    [data-tooltip]::after {
      font-size: 12px;
    }
  }
  @media (max-width: 1300px) {
    font-size: 12px;
    [data-tooltip]::after {
      font-size: 12px;
    }
  }
`;
export const MessageLeft = styled.div`
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  font-size: 13px;

  span.target span{
    position: relative;
    top: 2px;
    margin-left: 4px;
    svg{
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }

  sup{
    font-size: 14px;
    left: 2px;
  }

`;

export const MessageContainer = styled.div`
  width: 100%;
  float: left;

  ${Paragraph} {
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #333333;
    text-align: center;
    padding: 0px 20px;

    a {
      color: #466af3;
      :hover {
        text-decoration: none;
      }
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 1600px) {
    ${Paragraph} {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  @media (max-width: 1400px) {
    ${Paragraph} {
      font-size: 12px;
    }
  }
  @media (max-width: 1300px) {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    ${Paragraph} {
      font-size: 11px;
      padding: 20px 15px;
    }
  }
`;

export const MessageContainerBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 16px;
    margin-bottom: 6px;
`;

export const MessageWrapper = styled.div`
  width: 100%;
  float: left;
  background: #f8f8f8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 15px;

  @media (max-width: 1400px) {
    padding: 15px;
  }
`;
export const Count = styled.div`
  position: absolute;
  background: #eb974e;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  font-family: 'Gordita-Medium';
  z-index: 1;
  right: -5px;
  top: -5px;
`;
export const ContentWrapper = styled.div`
    width: 100%;
    float: left;
    ${MessageWrapper} {
        margin-top: 0px;
        background:#fff;
        padding-top:0px;
        padding-bottom:0px;
        :first-child{
          ${MessageTitle}{
            :before{
              display: none;
            }
          }
        }
    }
    ${MessageRight} {
        position:relative;
        span {
        width: max-content;
        height: 16px;
        position:relative;
        font-size:12px;
        font-family: 'Gordita-Regular';
        svg {
            width: 16px;
            height: 16px;
        }
    }
    @media (max-width: 1500px) {
        span {
            width: max-content;
            height: 15px;
            position:relative;
            font-size:10px;
        svg {
            width: 14px;
            height: 14px;
        }
    }
`;

// Button

export const ButtonWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 0px 20px;
  position: relative;
  ${HoverUserIcon} {
    position: absolute;
    top: 17px;
    right: 35px;
  }
  button {
    width: ${(props) => (props.width ? props.width : '100%')};
    border: ${(props) =>
      props.primary ? '1px solid #b9b9b9' : '1px solid #009d5f'};
    border-radius: 21px;
    float: left;
    height: 42px;
    text-align: center;
    font-family: 'Gordita-Medium';
    font-size: 16px;
    color: #000;
    outline: 0;
    background: ${(props) => (props.primary ? '#fff' : '#ddfeef')};
    margin-bottom: 0px;
    margin-top: 5px;
    cursor: ${(props) => (props.dontAllowClick ? 'default' : 'pointer')};
    opacity: ${(props) => (props.dontAllowClick ? '0.3' : '1')};
    transition: 0.3s linear;

    :hover {
      background: ${(props) => (props.primary ? '#ddfeef' : '#fff')};
      color: #000;
    }
    focus: {
      outline: 0;
    }
  }
  @media (max-width: 1600px) {
    button {
      height: 36px;
      margin-top: 5px;
      font-size: 14px;
    }
  }

  @media (max-width: 1400px) {
    button {
      height: 36px;
      margin-top: 5px;
      font-size: 14px;
    }
    padding: 0px 15px;
  }
`;
export const FreeTrialButton = styled.div`
  button {
    background: #fff;
    color: #466af3;
    border: 1px solid #466af3;
    margin-bottom: 0px;
    margin-top: 15px;

    :hover {
      background: #466af3;
      color: #fff;
    }
  }
`;
export const PricingCard = styled.div`
  width: 100%;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  padding: 15px 0px;
  padding-bottom: 5px;
  position: relative;
  ${ContentWrapper} {
    margin-top: 0px;
    background: none;

    ${MessageWrapper} {
      ::-webkit-scrollbar-track {
        background: #fff;
      }
      ::-webkit-scrollbar {
        display: block;
      }
    }
  }

  ${MessageWrapper} {
    padding: 10px 20px;
  }
  ${PricingHeader} {
    ${PricingHeaderRight} {
      ${ImgWrapper} {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #466af3;
        border-radius: 6px;
        svg {
          fill: #466af3;
        }
      }
    }
  }
  ${({ isCurrent }) =>
    isCurrent &&
    `background: #ddfeef 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 10px;
        ${MessageWrapper} {
            background: #ddfeef;
        }
        ${ContentWrapper} {
        padding-right: 0px;
        background: none;
        ${MessageWrapper} {
            background: #ddfeef;
            ::-webkit-scrollbar-thumb {
                background: #f0af83 !important;
            }
            ::-webkit-scrollbar-track {
                background: #ddfeef;
            }
            
            ::-webkit-scrollbar {
                display: block;
            }
        }
    }
    ${ButtonWrapper} {
        button {
            background: #fff;
            color: #000;
        }
        ${FreeTrialButton} {
            button {
                background: #ddfeef;
                color: #000;
                border: 1px solid #009d5f;
            }
        }
    }
    ${PricingHeader} {
        ${PricingHeaderRight} {
            ${ImgWrapper} {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #009d5f;
                border-radius: 6px;
                svg {
                    fill: #000;
                }
            }
        }
    }
  `}
  :hover {
    background: #ddfeef 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    ${MessageWrapper} {
      background: #ddfeef;
    }
    ${ContentWrapper} {
      padding-right: 0px;
      background: none;
      ${MessageWrapper} {
        background: #ddfeef;
        ::-webkit-scrollbar-track {
          background: #ddfeef;
        }
        ::-webkit-scrollbar {
          display: block;
        }
      }
    }
    ${ButtonWrapper} {
      button {
        background: #fff;
        color: #000;
      }
      ${FreeTrialButton} {
        button {
          background: #ddfeef;
          color: #000;
          border: 1px solid #009d5f;
        }
      }
    }
    ${PricingHeader} {
      ${PricingHeaderRight} {
        ${ImgWrapper} {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #009d5f;
          border-radius: 6px;
          svg {
            fill: #000;
          }
        }
      }
    }
  }
  @media (max-width: 1800px) and (min-width: 1601px) {
    ${MessageWrapper} {
      padding: 10px 15px 5px 15px; 
    }
    ${ButtonWrapper} {
      padding: 0px 15px;
    }
    ${MessageHeader} {
      font-size: 13px;
    }
  }
  @media (max-width: 1600px) {
    ${ContentWrapper} {
      ${MessageWrapper} {
        max-height: 100%;
        overflow-y: scroll;
        width: 100%;
        float: left;
        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #fff;
          height: 20px;
          border-radius: 2px;
        }
        // ::-webkit-scrollbar-track {
        //     background: #fff;
        // }

        :hover {
          ::-webkit-scrollbar-thumb {
            background: #f0af83;
          }
        }
      }
      .two_btn_wrapper {
        max-height: 200px;
        overflow-y: scroll;
      }
    }
  }

  .free_plan{
    ${MessageWrapper}{
      :last-child{
        ${MessageTitle}{
          :after{
            content: '';
            position: relative;
            width: 100%;
            height: 1px;
            display: block;
            background: #D0D0D0;
            bottom: -10px;              
          }
        }       
      }
      :nth-last-child(2){
        ${MessageSection}{
          margin-bottom: 0;
        }
        ${MessageTitle}{
          margin-bottom: 0;
        }
      }
    } 
  }

  .paid_plan{
    ${MessageWrapper}{
      :last-child{
        .message_title{
          // padding-top: 10px;
          // position: absolute;
        }
        .messageTitle_icon{
          // margin-top: 10px;
        }
      }
      :nth-last-child(2){
        ${MessageSection}{
          margin-bottom: 0;
        }
        ${MessageTitle}{
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const PriceEnterpriseWrapper = styled.div`
  width: 100%;
  float: left;
  display: grid;

  button {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  ${MessageWrapper} {
    height: max-content;
    display: flex;
    justify-content: space-evenly;
    padding-top: 60px;
  }
  .message_first_wrapper {
    ${Paragraph} {
      position: relative;
      top: -20px;
    }
  }
  ${MessageContainer} {
    flex-wrap: wrap;
  }
  ${PricingHeaderLeft} {
    justify-content: center;
  }

  @media (max-width: 1800px) {
    ${MessageWrapper} {
      padding-top: 65px;
    }
  }
  @media (max-width: 1700px) {
    ${MessageWrapper} {
      padding-top: 40px;
      ${Paragraph} {
        position: relative;
        top: -10px;
      }
    }
  }
  @media (max-width: 1600px) {
    ${MessageWrapper} {
      padding-top: 35px;
      ${Paragraph} {
        position: relative;
        top: -5px;
      }
    }
  }
  @media (max-width: 1500px) {
    ${MessageWrapper} {
      padding-top: 27px;
      ${Paragraph} {
        position: relative;
        top: 0px;
      }
    }
  }
  @media (max-width: 1400px) {
    ${MessageWrapper} {
      padding-top: 50px;
    }
  }

  // @media (max-width: 1600px) {
  //     grid-column-start: 1;
  //     grid-column-end: 5;
  //     grid-row-start: 5;
  //     grid-row-end: 5;
  //     grid-template-columns: 100%;
  //     align-items: center;
  //     margin-top: 30px;

  //     ${PricingCard} {
  //         grid-template-columns: 25% 45% 25%;
  //         display: grid;
  //         justify-content: space-between;
  //         align-items: center;

  //         ${MessageWrapper} {
  //             margin-top: 0px;
  //         }
  //         ${PricingHeader} {
  //             justify-content: flex-start;
  //             align-items:center;
  //             ${PricingHeaderLeft}{
  //                 display: flex;
  //                 flex-direction: column;
  //                 margin-right:15px;
  //                 ${HeaderContent}{
  //                     width:auto;
  //                 }
  //                 ${AmountContent}{
  //                     AmountContent
  //                 }
  //             }
  //         }
  //         ${ButtonWrapper} {
  //             display: flex;
  //             justify-content: flex-end;

  //             button {
  //                 width: 140px;
  //             }
  //         }
  //     }
  // }
`;

export const PricingContainer = styled.div`
  width: 100%;
  float: left;
  display: grid; 
  grid-template-columns: ${(props) => (props.columns === 3 ? 'repeat(auto-fill, minmax(30%, 1fr)' : 'repeat(auto-fill, minmax(24%, 1fr)')});
  grid-auto-flow: column;
  grid-auto-columns: minmax(24%, 1fr);
  overflow-x: inherit;
  overflow-y: hidden;
  grid-gap: 20px;
  padding: 0px 20px 5px 5px;
  padding-right: 0px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(315px, 1fr);
    overflow-x: scroll;
    grid-gap: 20px;
    padding: 0px 20px 15px 5px;

    :hover {
      cursor: pointer;
    }
    ::-webkit-scrollbar {
      width: 4px;
      display: block !important;
      height: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #f0af83;
      height: 20px;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
  }

  // ${PricingCard}{
  //   :last-child{
  //     ${MessageWrapper}{
  //       margin-top: 0;
  //     }      
  //   }
  // }
`;

export const YearlyPlans = [
  {
    plans: 'Free',
    referenceName: 'BASIC',
    type: 'Monthly',
    priority: 1,
    image: <PriceFreeIcon />,
    dollar: '$',
    price: '0',
    additionalMessage: '',
    topMessages: [
      {
        bolder: '1',
        category: 'Admin',
        hoverContent: 'No of admins who can work on the application',
      },
      {
        bolder: '1',
        category: ' App Workflows',
        hoverContent:
          ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. ',
      },
      {
        bolder: '300',
        category: ' Faq Answers',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },
      {
        bolder: '100',
        category: ' Workflow executions',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '500',
        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Single Chatbot',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent: 'Pre-built app based workflows for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
        icon: <PricingYesIcon />,
      },
      {
        category: 'On-prem Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: ' Add-On Consumptions',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
  {
    plans: 'Basic',
    referenceName: 'BASIC',
    type: 'Monthly',
    priority: 1,
    image: <BasicIcon />,
    dollar: '$',
    price: '399',
    additionalMessage: '',
    topMessages: [
      {
        bolder: '1',
        category: 'Admin',
        hoverContent: 'No of admins who can work on the application',
      },
      {
        bolder: '2',
        category: ' App Workflows',
        hoverContent:
          ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. ',
      },
      {
        bolder: '600',
        category: ' Faq Answers',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },
      {
        bolder: '600',
        category: ' Workflow executions',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '3000',
        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Single Chatbot',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent: 'Pre-built app based workflows for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
        icon: <PricingYesIcon />,
      },
      {
        category: 'On-prem Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: ' Add-On Consumptions',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
  {
    plans: 'Standard',
    priority: 2,
    referenceName: 'STANDARD',
    type: 'Monthly',
    image: <PriceStandardIcon />,
    dollar: '$',
    price: '599',
    additionalMessage: '',
    topMessages: [
      {
        bolder: '2',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },
      {
        bolder: '5',
        category: ' App Workflows',
        hoverContent:
          ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. ',
      },
      {
        bolder: '2000',
        category: ' Faq Answers',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },
      {
        bolder: '1500',
        category: ' Workflow executions',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '7000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbots',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingYesIcon />,
        count: '2',
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent: 'Pre-built app based workflows for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
        icon: <PricingYesIcon />,
      },
      {
        category: 'On-prem Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: ' Add-On Consumptions',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
  {
    plans: 'Pro',
    priority: 3,
    referenceName: 'PRO',
    type: 'Monthly',
    image: <PriceProIcon />,
    dollar: '$',
    price: '899',
    additionalMessage: '',

    topMessages: [
      {
        bolder: '3',
        category: ' Admins',
        hoverContent: 'No of admins who can work on the application',
      },
      {
        bolder: '8',
        category: ' App Workflows',
        hoverContent:
          ' At a time, the number of active Workflows available for use in the Chatbot Builder. Admin will be able to disable existing and enable new Workflows as per choice. ',
      },
      {
        bolder: '5000',
        category: ' Faq Answers',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '3000',
        category: ' Workflow executions',
        hoverContent:
          'The total number of Workflows that can be executed over one month / 30 day period by the users.',
      },

      {
        bolder: '17000',

        category: ' Messages',
        hoverContent:
          'The total number of messages sent to the chatbot over a one month / 30 day period by the users.',
      },
    ],
    steps: [
      {
        category: 'Chatbot Builder',
        hoverContent:
          'No-code chatbot builder tools to create and manage chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbot Automation',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'FAQ Builder',
        hoverContent:
          'No-code workflow builder to create app based workflow automations for chatbot.',
        icon: <PricingYesIcon />,
      },
      {
        category: 'Chatbots',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingYesIcon />,
        count: '2',
      },
      {
        category: 'App Integrations',
        hoverContent: 'Pre-built app integrations or connectors for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Marketplace',
        hoverContent: 'Pre-built app based workflows for chatbot.',
        icon: <PricingYesIcon />,
      },

      {
        category: 'Integrate with Chat Channels',
        hoverContent: 'Supports multiple chatbot creation.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Agent Handover',
        hoverContent:
          'Cost of each additional bot session, and each app workflow execution over the monthly limit. ',
        icon: <PricingYesIcon />,
      },
      {
        category: 'On-prem Connect',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Approval Management',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: ' Add-On Consumptions',
        hoverContent: 'Connect chatbot with on-prem applications.',
        icon: <PricingNoIcon />,
      },
      {
        category: 'Email, Help Doc Support',
        hoverContent: 'Phone support is outbound calls only. ',
        icon: <PricingYesIcon />,
      },
    ],
  },
];

export const InformationContent = styled.p`
  width: 100%;
  float: left;
  font-family: 'Gordita-Medium';
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.alertColor ? props.alertColor : '#D97500')};
  ${Button} {
    height: 28px;
    margin-left: 15px;
    font-size: 14px;
  }
  ${HoverUserIcon} {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .info_icon {
      position: relative;
      width: auto;
      height: auto;
      margin-right: 0px;
      top: 4px;
      left: 3px;
      svg {
        margin-left: 0px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .side_menu_hvr {
      font-size: 14px;
      max-width: calc(100% - -132px);
      z-index: 9;
      height: fit-content;
      top: 34px;
      position: absolute;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 20px #00000029;
      border-radius: 6px;
      padding: 12px;
      left: -28px;
      color: #365579;
      font-family: 'Gordita-Regular';
      display: none;
      transition: 0.5s ease;
    }
    .info_icon:hover ~ .side_menu_hvr {
      display: flex;
    }
    .content_medium {
      font-family: 'Gordita-Medium';
      color: #000;
      margin-bottom: 0px;
    }
    .info_icon svg {
      fill: ${(props) => (props.alertColor ? '#F44949' : '#FF9922')};
    }
    .side_menu_hvr label {
      margin-bottom: 0px;
    }

    .side_menu_hvr:before {
      position: fixed;
      content: ' ';
      background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
      border-width: 15px;
      border-style: solid;
      border-color: #fff transparent transparent;
      margin-top: -35px;
      margin-left: 9px;
      bottom: unset;
      top: unset;
      right: unset;
      transform: rotate(180deg);
    }

    .alert_text_color {
      color: red;
    }
  }
`;
const Information = styled.div`
  background: ${(props) => (props.bgColor ? props.bgColor : '#ffefef')} 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 1px 5px #16161629;
  border-radius: 0px;
  width: 100%;
  float: left;
  margin-top: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  // position: absolute;
  // z-index: 9999;
  @media (max-width: 1500px) {
    padding: 0px 15px;
  }
`;

export const AlertWrapper = (props: any) => {
  const [loading, setLoading] = useState(false);
  function verify() {
    setLoading(true);
    postJson(loginApiURL('/twilio_send_otp'))({ email: props.userData.email })
      .then(() => {
        const workspace = props.match.params.workspacename;
        setLoading(false);
        props.history.push(
          `/workspace/${workspace}/profile?verify-${generateUniqueId()}`
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function changeEmail() {
    const workspace = props.match.params.workspacename;

    props.history.push(`/workspace/${workspace}/profile?change-email`);
  }
  return (
    <Information bgColor={!props.userData.verified ? '#ffeeed' : '#ffefef'}>
      {!props.userData.verified ? (
        <InformationContent alertColor="#000">
          <HoverUserIcon>
            <label className="m-0 verify_content display-contents">
              <span className="info_icon">
                <InfoColorIcon />
              </span>
              <span className="alert_text_color">Action required!&nbsp;</span>{' '}
              Your email verification was sent to{' '}
              <span className="link_color">"{props.userData.email}"</span>.
              Please verify your email.
              {'    '}
              <label
                className="display-contents link_color"
                onClick={() => {
                  verify();
                }}
              >
                {loading ? (
                  <span className="connect_account_loading">
                    <img
                      src={LoadingSvgImage}
                      style={{
                        marginLeft: 10,
                        marginTop: -4,
                      }}
                    />
                  </span>
                ) : (
                  'Verify Now'
                )}
              </label>{' '}
              |{' '}
              <label
                className="display-contents link_color"
                onClick={changeEmail}
              >
                Change your email
              </label>
            </label>
          </HoverUserIcon>
        </InformationContent>
      ) : props.alertWrapper ? (
        <InformationContent alertColor="#CE2D2D">
          <HoverUserIcon>
            <span className="info_icon">
              <InformationIcon />
            </span>
            <label className="m-0">
              “Your payment is past due date. Please pay before{' '}
              <label className="content_medium m-0"> 21 March 2021 </label> to
              avoid account suspension”
            </label>
            <label className="side_menu_hvr">
              <label>
                If you don’t resolve the payment by{' '}
                <label className="content_medium"> 21 March 2021 </label>, your
                account will enter our suspension process. Suspension means, you
                will no longer have access to workativ chatbot services,
                preventing you from managing existing or creating new chatbots.
                While suspended, you will still have access to billing page to
                make your due payment.
              </label>
            </label>

            <Button bgColor>
              <label>Pay Now</label>
            </Button>
          </HoverUserIcon>
        </InformationContent>
      ) : (
        <InformationContent>
          <HoverUserIcon>
            <span className="info_icon">
              <InformationIcon />
            </span>
            <label className="m-0">
              There was an error processing your credit card for payment.
            </label>
            <span className="side_menu_hvr">
              <label>
                Please update your credit card or make your payment manually (by
                clicking pay now) before{' '}
                <label className="content_medium">21 March 2021</label>
              </label>
            </span>
            <Button bgColor>
              <label>Pay Now</label>
            </Button>
          </HoverUserIcon>
        </InformationContent>
      )}
    </Information>
  );
};

const TopHeaderWrapper = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
`;
export const UpgradePlanV2C = () => {
  return (
    <UpgradePlanWrapper>
      <UpgradePlanLeft>
        <UpgradeHeader>Select Plan</UpgradeHeader>
      </UpgradePlanLeft>
      <UpgradePlanRight>
        <SubscriptionPlanChange />
      </UpgradePlanRight>
    </UpgradePlanWrapper>
  );
};

export let PlanV2s = {
  UpgradePlan: UpgradePlanV2C,
};
