import styled from 'styled-components/macro'

export const TdSpan = styled.div`
    width: auto;
    float: left;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    color: #2468f6;
    display: contents;
`
export const Count = styled.span`
    font-family: 'Gordita-Medium';
    color: #ff7900;
    font-size: 10px;
    margin-left: 2px;
`
export const TdContent = styled.div`
    width: max-content;
    float: left;
    display: flex;
    align-items: center;

    svg {
        width: 8px;
        height: 8px;
        fill: #000;
        margin-left: 5px;
        display: none;
    }
    ${Count} {
        width: 24px;
        height: 24px;
        background: #d5ffee 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #333333;
        font-family: 'Gordita-Regular';
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`

export const Td = styled.td`
    width: auto;
    float: left;
    font-family: 'Gordita-Regular';
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    display: flex;
    justify-content: ${(props: any) => (props.contentCenter ? 'center' : 'left')};
    align-items: left;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 64px;
    cursor: pointer;
    .synonyms_p{
        overflow-y: auto;
        white-space: normal;
        word-break: break-all;
        padding-right: 5px;
        max-height: 30px;
        margin-bottom: 0px;
    }
    // button {
    //     color: ${(props: any) => props.color}!important;
    //     border-color: ${(props: any) => props.color}!important;
    // }

    .logs_feedback_content{
        display: block;
        width: 95%;
    }
    .knowledge_table_name{
        display: flex;
        align-items: center;
        div:first-child{
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    .liveAgent_contentEllipse{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .td_date.liveAgent{
        font-family: 'Gordita-Regular';
        font-size: 7px;
        color: #355D8B;
        margin-left: 6px;
    }

    .liveAgent_chat{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div:first-child{
            width: 24px;
            height: 24px;
            margin-right: 6px;
        }
        .td_header{
            font-family: 'Gordita-Regular';
            font-size: 12px;
            color: #000;
        }
    }
`
export const Th = styled.th`
    width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
    float: left;
    font-size: 14px;
    display: flex;
    justify-content: ${(props: any) => (props.contentCenter ? 'center' : 'left')};
    font-family: 'Gordita-Medium';
    color: #355d8b;
    .tab_container_right {
        display: grid;
        grid-template-columns: 180px auto;
        justify-content: flex-end;
        .prev_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
                fill: #555;
                transform: rotate(90deg);
            }
        }
        .next_icon {
            margin-left: 6px;
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            svg {
                width: 16px;
                height: 16px;
                fill: #555;
                cursor: pointer;
                transform: rotate(-90deg);
            }
        }
        p {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
        }
    }
`
export const Tr = styled.tr`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    padding-bottom: 0px;
    color: #000;
    :hover {
        border: none !important;
    }

    ${Td}:nth-child(2) div{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`
export const Thead = styled.thead`
    width: 100%;
    float: left;
    display: flex;

    ${Tr} {
        height: 48px;
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        align-items: flex-start;
        padding: 0px 20px;
        padding-top: 17px;
    }
    ${Th} {
        line-height: 14px;
    }
    .is_opacity {
        opacity: 0.2;
        box-shadow: none;
        pointer-events: none;
        width: 100%;
        float: left;
    }
`

export const PromptButton = styled.div`
    width: 100%;
    float: left;

    ${Tr} {
        border-bottom: 1px solid #e0e0e0;
        box-shadow: none !important;
        :last-child {
            border-bottom: none !important;
            border-radius: 0px 0px 10px 10px;
        }
    }
`
export const Tbody = styled.tbody`
    width: 100%;
    float: left;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    position: relative;
    overflow-y: scroll;
    height: calc(100vh - 100px);
    border-radius: 10px;

    ${Tr} {
        background: #ffffff 0% 0% no-repeat padding-box;
        padding: 0px 20px;
        height: 48px;
        line-height: normal;
        display: flex;
        border-bottom: 1px solid #e0e0e0;
        // :nth-child(1){
        //     border-bottom: none;
        // }

        ${Td} {
            padding-right: 15px;
            font-size: 12px;
            color: #000000;
            justify-content: center;
            :last-child {
                padding-right: 0px;
                justify-content: center;
            }
            :first-child {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;

                span {
                    width: 18px;
                    height: 18px;
                    float: left;
                    margin-right: 7px;
                    svg {
                        width: 18px;
                        height: 18px;
                        float: left;
                    }
                }
            }
        }

        :hover {
            background: #ddfeef 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #00000029;
            z-index: 0;
            position: relative;
            top: 0px;
            cursor: pointer;

            ${TdContent} {
                svg {
                    display: block;
                }
                ${Count} {
                    background: #fff;
                }
            }
            .option-input.radio {
                border: 1px solid #2468f6 !important;
                visibility: visible !important;
            }
            .hide{
                display: flex !important;
            }
        }
    }
    .is_active_wrapper {
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px 5px 15px #00000033;
        border-radius: 10px;
        display: block;
        width: 100%;
        float: left;
        margin: -1px 0px;

        ${TdContent} {
            svg {
                display: block;
                transform: rotate(90deg);
            }
        }

        :hover {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #00000033;
            border-radius: 10px;
            ${Tr} {
                // background: #fff 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 4px #00000029;

                ${TdContent} {
                    svg {
                        display: block;
                    }
                    ${Count} {
                        background: #d5ffee;
                    }
                }
            }
        }
    }

    .is_active_wrapper .is_active_tr {
        background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box !important;
        border-radius: 10px 10px 0px 0px;

        :hover {
            background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat padding-box;
        }
    }
    .is_opacity_wrapper {
        width: 100%;
        float: left;
    }
    .is_opacity {
        // opacity: 0.5;
        // box-shadow: none;
        // pointer-events: none;
        width: 100%;
        float: left;
    }
`
export const Table = styled.table`
    width: 100%;
    float: left;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
`

export const TableContainer = styled.div`
    width: 100%;
    float: left;

    .notification_std_table{
        margin-top: 60px;
        ${Th}{
            :nth-child(2){
                justify-content: center;
            }
            :nth-child(3){
                justify-content: center;
            }
            :nth-child(4){
                justify-content: center;
            }
        }
        ${Td}{
            :nth-child(5){
                label{
                    font-family:'Gordita-Medium';
                    font-size: 10px;
                    top: 1.5px;
                    
                    span{
                        width: auto;
                        height: auto;
                    }
                }
            }
            
            :nth-child(2){
                justify-content: center !important;
            }
            :nth-child(3){
                justify-content: center !important;
            }
            :nth-child(4){
                justify-content: center !important;
            }
        }
    }

    .notification_inner_table{
        ${Thead}{
            ${Tr}{
                padding-right: 0;
                ${Th}{
                    :last-child{
                        justify-content: end;
                        position: relative;
                        left: 78px;
                    }
                }
            }
        }

        ${Tbody}{
            @media (max-width: 1440px){
                max-height: calc(100vh - 370px);
            }            
        }
        
        ${Td}{
            label{
                font-family:'Gordita-Medium';
                font-size: 10px;
                top: 1.5px;
                
                span{
                    width: auto;
                    height: auto;
                }
            }

            .td_date{
                font-size: 10px !important;
                color: #000 !important;
                line-height: normal !important;
            }

            :last-child{
                justify-content: end;
                position: relative;
                left: 90px;
            }
        }
`
export const Textarea = styled.textarea.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: ${props => (props.primary ? '24px 12px 8px 32px' : '24px 12px 8px 12px')};
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    min-height: 48px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000000;
    outline: 0;
    resize: none;

    :hover {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: none;
    }
    :active {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: none;
    }
`

export const TextareaV2 = styled.textarea.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: ${props => (props.primary ? '0px 12px 8px 32px' : '0px 12px 8px 12px')};
    border: none;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000000;
    outline: 0;
    resize: none;
    margin-top: 12px;
`
export const Input = styled.input.attrs((boolean: any) => ({
    required: true
}))`
    width: 100%;
    float: left;
    padding: ${props => (props.primary ? '26px 32px 6px 12px' : '26px 12px 6px 12px')};
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    background: #fff;
    height: 48px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000000;
    outline: 0;

    :hover {
        border: 1px solid #009d5f;
        outline: 0;
        box-shadow: none;
    }

    :active {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: none;
    }

    ${({ activeDropdown }) =>
        activeDropdown &&
        `
        border: 1px solid #009d5f;

`}
`
export const InputContent = styled.div`
    color: #999;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: ${props => (props.topContent ? '20px' : '16px')};
    transition: 0.26s ease-in;
    ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:disabled ~ &,
    ${Input}:valid ~ & {
        top: 10px;
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
    }
    ${Textarea}:focus ~ &,
    ${Textarea}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Textarea}:disabled ~ &,
    ${Textarea}:valid ~ & {
        top: ${props => (props.topContent ? '4px' : '1px')};
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        background: #fff;
        width: 95%;
        text-align: left;
        height: 24px;
        padding-top: 8px;
    }
    ${TextareaV2}:focus ~ &,
    ${TextareaV2}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${TextareaV2}:disabled ~ &,
    ${TextareaV2}:valid ~ & {
        top: ${props => (props.topContent ? '4px' : '1px')};
        font-size: 10px;
        transition: 0.26s ease-out;
        line-height: 10px;
        background: #fff;
        width: 95%;
        text-align: left;
        height: 24px;
        padding-top: 8px;
    }
`

export const CancelButton = styled.span`
    width: 18px;
    height: 18px;
    position: absolute;
    right: 12px;
    top: 8px;
    display: none;
    cursor: pointer;

    svg {
        width: 18px;
        height: 18px;
    }
`
export const InputErrorIcon = styled.span`
    width: 10px;
    height: 10px;
    position: absolute;
    right: 12px;
    top: 11px;

    svg {
        width: 10px;
        height: 10px;
    }
`
export const ErrorMessage = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-family: 'Gordita-Regular';
    margin-bottom: 0px;
    color: #fc2a2a;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const InputWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    label {
        width: 24px;
        height: 24px;
        margin: 0px;
        position: absolute;
        right: 20px;
        top: 21px;

        svg {
            width: 24px;
            height: 24px;
        }
    }
`
export const DropdownLi = styled.div`
    width: 100%;
    float: left;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer !important;
    color: #000;

    img {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    .marketplace_dd_img{
        position: relative;
        bottom: 2px;
    }

    :first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
    }
    :last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #fff;
        border-bottom: 0.5px solid #fff;
    }
    :hover {
        background: #ddfeef;
        color: #333;
        border-bottom: 1px solid transparent;
        .option-input.radio {
            border: 1px solid #2468f6;
        }
    }

    .dropdown_list.flex{
        label{
            margin-bottom: 0;
            input {
                top: 4px;
            }
        }
        p{
            margin: 0;
            position: relative;
            top: 3px;
        }
    }
`
export const DropdownUl = styled.div`
    width: 100%;
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    margin-top: 0px;
    right: 0px;
    left: 0%;
    cursor: pointer;
    padding: 0px;
    overflow-y: scroll;
    max-height: 212px;
    margin-bottom: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    z-index: 2;
    margin-bottom: 0px;
`

export const InputContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 10px;

    .agent_bot_long_desc_box{
        overflow-y: scroll;
    }

    .call_automation_dropdown{
        ${DropdownLi}{
            padding: 9px 10px 0 10px;
        }
    }

`

export const InputContainerForTextArea = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px;
    padding-top: 20px;
    border: 1px solid #009d5f;
    border-radius: 6px;
    :hover {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: none;
    }
    :active {
        outline: 0;
        border: 1px solid #009d5f;
        box-shadow: none;
    }
`
export const InputContainerWithNoMargin = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 10px;
`
export const ScrollUl = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 0px;
    overflow-y: scroll;
    max-height: 240px;
    z-index: 2;
    ${DropdownLi} {
        :hover {
            :first-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
    }
`
export const DropdownUlWrapper = styled.div`
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 40px;
`
export const DropdownWrapper = styled.div`
    width: 100%;
    float: left;
    ${InputContainer} {
        ${Input} {
            padding-right: 40px;
            :hover {
                :hover {
                    outline: 0;
                    border: 1px solid #009d5f;
                    box-shadow: 0px 3px 6px #00000029;
                }
                :focus {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #009d5f;
                    box-shadow: none;
                }
                :active {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #009d5f;
                    box-shadow: none;
                }
            }
        }
        ${CancelButton} {
            width: 16px;
            height: 16px;
            display: flex;
            right: 10px;
            top: 16px;
            position: absolute;
            svg {
                width: 16px;
                height: 16px;
                color: #555555;
                fill: #555555;
            }
        }
    }

    span {
        //position: absolute;
        top: 10px;
        width: 18px;
        height: 18px;
        right: 12px;
        //transform: rotate(90deg);
        svg {
            width: 18px;
            height: 18px;
            color: #555555;
        }
    }

    ${DropdownUlWrapper}{
        margin-bottom: 0;
    }
`
export const ApprovalHeader = styled.div`
    width: 100%;
    float: left;
    display: flex;
    position: relative;
    .approval_img {
        width: 36px;
        height: 36px;
        float: left;
        margin-right: 10px;
    }
    .approval_header {
        width: auto;
        float: left;

        h6 {
            font-size: 14px;
            font-family: 'Gordita-Medium';
            color: #000;
            line-height: 14px;
            margin-bottom: 6px;
        }
        p {
            font-size: 12px;
            font-family: 'Gordita-Regular';
            color: #000;
            line-height: inherit;
            margin-bottom: 0px;
        }
    }
`
export const ApprovalRow = styled.div`
width:100%;
float:left;
display:grid;
grid-template-columns: 48% 48%;
grid-gap: 17px;
margin-top:20px;

${ApprovalHeader}{
    .approval_header {
        width: 100%;
        float: left;

        h6 {
            font-size: 12px;
            font-family: 'Gordita-Medium';
            color: #000;
            line-height: 12px;
            margin-bottom: 10px;
        }
        p {
            font-size: 12px;
            font-family: 'Gordita-Regular';
            color: #555555;
            line-height: inherit;
            margin-bottom: 0px;
        }
}
`
export const AppButton = styled.div`
    width: 85px;
    height: 23px;
    background: ${(props: any) => props.background} 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: ${(props: any) => props.color}!important;
    font-family: 'Gordita-Medium';
    line-height: 12px;
    margin-top: 12px;
    margin-bottom: 20px;
    float: left;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    ${({ requested }) =>
        requested &&
        `
color:#D86618;
background:#FFF0E5;
`}
    ${({ rejected }) =>
        rejected &&
        `
background:#FFE5E5;
color:#DD4330;
`}
`
export const Button = styled.button`
    min-width: 90px;
    height: 32px;
    background: ${props => (props.primary ? '#fff' : '#2468f6')};
    border-radius: 18px;
    border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
    outline: 0;
    font-size: 12px;
    padding: 7px 12px;
    font-family: 'Gordita-Medium';
    color: ${props => (props.primary ? '#333' : '#fff')};
    margin: 10px 0px 0px;
    cursor: pointer;

    :hover {
        background: #ddfeef;
        color: #333;
        border: 1px solid #ddfeef;
    }
    :active {
        background: ${props => (props.primary ? '#fff' : '#2468f6')};
        border: ${props => (props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6')};
        color: ${props => (props.primary ? '#333' : '#fff')};
    }
    :focus {
        background: #ddfeef;
        color: #333;
        border: 1px solid #009d5f;
    }

    span {
        width: 12px;
        height: 12px;
        float: left;
        margin-right: 6px;
        display: flex;
        align-items: center;
        position: relative;
        top: 0px;
        cursor: pointer;
        svg {
            width: 12px;
            height: 12px;
            fill: #333;
            color: #333;
            margin-right: 0px;
        }
    }
    label {
        margin-bottom: 0px;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
`
export const WidthContainer = styled.div`
    width: 100%;
    float: left;

    ${Button} {
        margin-top: 20px;
        margin-bottom: 0px;
    }
`
export const ApprovalBg = styled.div`
    width: 540px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 40px;
`
export const ApprovalWrapper = styled.section`
    width: 100%;
    position: fixed;
    background: #0000004f 0% 0% no-repeat padding-box;
    z-index: 1111;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 150px;
`
export const DivisionDiv = styled.div`
    position: absolute;
    float: left;
    right: -20px;
    bottom: 0;
    top: -20px;
    svg {
        width: 16px;
        height: 16px;
        color: #666;
        cursor: pointer;
        fill: #666;
        :hover {
            color: #000;
            fill: #000;
        }
    }
`
