import styled from 'styled-components/macro'

import { Button } from './Dialog.BotDetails.Dumb'
import {
    DialogCount,
    DropdownWrapper,
    HeaderContainer,
    HeaderTop,
    Input
} from './GetStarted.Dumb'

export const Image = styled.img`
    width: 100%;
    float: left;
`
export const ButtonWrapper = styled.div`
    width: 100%;
    float: left;
    ${Button} {
        margin: 0px;
    }
`
export const HoverLink = styled.div`
    width: 100%;
    float: left;
    position: absolute;
    left: 20px;
    font-size: 14px;
    font-family: Gordita-Regular;
    color: rgb(24, 87, 220);
`
export const ImgContainer = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
    }
    :hover {
        ${HoverLink} {
            text-decoration: underline;
        }
    }
`
export const Col6 = styled.div`
    width: 100%;
    float: left;
    :first-child {
        ${Image} {
            width: 292px;
            height: 220px;
        }
    }
    :last-child {
        display: flex;
        align-items: flex-end;

        ${Image} {
            width: 480px;
            height: 300px;
        }

        @media (max-width: 1400px) {
            ${Image} {
                width: 100%;
                height: 300px;
            }
        }
    }
    ${DropdownWrapper} {
        width: 100%;
        margin-top: 32px;
        ${Input} {
            width: 100%;
        }
        ${DialogCount} {
            svg {
                fill: #ccc;
                color: #ccc;
            }
        }
    }
`
export const ColContainer = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 46.1% 46.1%;
    grid-gap: 80px;
`
export const BoxWrapper = styled.div`
    width: 1120px;
    float: left;
    padding: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 10px;
    padding-bottom: 40px;
    @media (max-width: 1400px) {
        width: 100%;
    }
`
export const BoxWrapperBg = styled.section`
width: 100%;
background: #f2f2f2 0% 0% no-repeat padding-box;
border-radius: 10px;
margin-top: 0;
float: left;
padding: 20px;
display: flex;
justify-content: center;
}
`
export const HeaderCount = styled.div`
    width: auto;
    float: left;
    font-size: 12px;
    font-family: Gordita-Medium;
    color: #f07f31;
    line-height: 18px;
    margin-top: 6px;
`
export const HeaderRow = styled.div`
    width: auto;
    float: left;
    display: flex;
    flex-direction: column;
`
export const OrCenter = styled.div`
    width: auto;
    float: left;
    margin: 0px 10px;
    display: flex;
    font-size: 18px;
    font-family: Gordita-Regular;
    color: #355d8b;
    color: #355d8b;
    line-height: 18px;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 1px;
`
export const HeaderLeft = styled.div`
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    ${HeaderTop} {
        width: auto;
    }
`
export const HeaderSearch = styled.div`
    width: auto;
    float: left;
    position: relative;

    input {
        width: 120px;
        height: 32px;
        border: 1px solid #b9b9b9;
        font-size: 12px;
        font-family: Gordita-Medium;
        padding: 7px 12px 7px 30px;
        border-radius: 18px;
        color: #333;
    }

    span {
        width: 12px;
        height: 12px;
        fill: #999;
        position: absolute;
        left: 12px;
        top: 4px;

        svg {
            width: 12px;
            height: 12px;
            fill: #333;
            color: #333;
        }
    }
`
export const HeaderRight = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${ButtonWrapper} {
        justify-content: flex-end;

        ${Button} {
            margin-right: 10px;
            :last-child {
                margin-right: 0px;
            }
        }
    }
`
export const HeaderWithButton = styled.section`
    width: 100%;
    float: left;
    ${HeaderContainer} {
        display: grid;
        grid-template-columns: 49.2% 49.2%;
        grid-gap: 20px;
    }
    ${ButtonWrapper} {
        display: flex;
        align-items: center;
    }
`
