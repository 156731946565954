/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { getUsersAPI } from '@/ManageUsers/op.manageUsers'
import { User } from '@/ManageUsers/types.user'
import { DownloadFromMarketplace, InfoIcon } from '@/common/Icons/Icons'
import { Loader } from '@/common/components/Loader'
import { useFetchFromApi } from '@/common/hooks/usefetch-from-api'
import user_img from '@/common/images/user.svg'
import visa_img from '@/common/images/visa.png'
import {
    AnchorTag,
    AnchorTagContent,
    Billing,
    Box,
    BoxOne,
    BoxRight,
    BoxRightContainer,
    BoxRightWrapper,
    BoxWrapper,
    CardDetails,
    CardNumber,
    ContetntInformationSpan,
    CreditMoney,
    CreditWrapper,
    DateEstimate,
    DollarMonth,
    DollerAmount,
    DollerSup,
    EllipsisContent,
    EmailWrapper,
    HeaderRight,
    HoverSpan,
    ImgContainer,
    InformationIcon,
    MonthlySubscription,
    NoCreditCard,
    NoEstimate,
    ProfileImg,
    SvgWidth,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    UserID,
    UserName,
    VisaImg
} from '@/common/styled/Subscription.Xtra.Dumb'

import { formatDate, formatDateInCard, getCurrentUserInfo } from '@/common/utils/utils'
// import { stripeGetApi, formatDate, formatDateInCard } from '../util';
//ACTIONS IMPORT
import { logEvent, stripeGetApi, stripePost } from '@/common/utils/api-utils'
import { EstimateV2, toBillingEstimateV2 } from './AccountEstimateV2'
import { DateLogs, Logsspan } from './BillEstimated'
import { BillingHistory, NoDataHeader, NoDataWrapper } from './BillingHistoryV2'
import {
    AccountWrapper,
    AdminDetailWrapper,
    AdminWrappers,
    BillingHistoryV2,
    BillingTableWrapper,
    BillingWrapper,
    CardUserDetailsV2,
    CurrentPlanHeader,
    DisplayCardsV2,
    OverviewPlanV2,
    PaidUserWrapper,
    Paragraph,
    UserDetailsV2
} from './BillingOverview-v2'
import { CurrentPlanContainer } from './BoxLeft'
import { getSubscriptionDisplayDetails } from './CurrentPlan'
//COMPONRNTS IMPORT
import { Container, SubscriptionHeader } from './Headers'
import { plurarize } from './Plans'
import { BodyWrapperV2, ContainerV2, PlansHeaderV2, WrapperV2 } from './Plans-v2'
import { CurrentSubscription, PaymentMethod, UpcomingInvoice } from './SubsriptionResponse'
//TYPE IMPORT
import {
    ActionT,
    AdditionalPlans,
    AllResourceTypes,
    ErrorPage,
    InvoiceHistoryT,
    PageStateT,
    PlansV2,
    ResourceConsumptionForDisplay,
    ResourceDetails,
    ResourceDisplay,
    SubscribedPlan,
    SubscribedStatus,
    TrialStatus,
    subscriptionStatusMap,
    EnterprisePlan,
} from './kind'
import { stripeUTCDate } from './util-stripe'

// import { NoDataHeader, NoDataWrapper } from './BillingHistory'
import { makeEnterprisePlanUIStructure } from './kind';
import { SideBarContext } from '@/SidePanel/SideBarContext'

let hiddenContent = (x: string) => x.replace(/[0-9]{4}$/, '****')

type BillingEstimate = {
    type: 'HAS_BILLING'
    current: Date
    nextBilling: Date
    amount: number
    consumption: ConsumptionCount[]
}

type NoBilling = {
    type: 'NO_BILLING_DATA'
}

enum ResourceType {
    Automation = 'Automation',
    Conversation = 'Conversation'
}

enum ConsumptionTier {
    One = 'one',
    Two = 'two'
}

type ConsumptionCount = {
    resourceType: ResourceType
    tire: ConsumptionTier
    count: number
}

const descriptitonToResourceType = (description: string) => {
    if (description.includes('Automation')) {
        return ResourceType.Automation
    }

    if (description.includes('Conversation')) {
        return ResourceType.Conversation
    }

    return null
}

const descriptitonToConsumptionTier = (description: string) => {
    if (description.includes('Tier 1')) {
        return ConsumptionTier.One
    }

    if (description.includes('Tier 2')) {
        return ConsumptionTier.Two
    }

    return null
}

export const toBillingEstimate = (invoice: UpcomingInvoice): BillingEstimate | NoBilling => {
    if (invoice.lines === undefined) {
        return { type: 'NO_BILLING_DATA' }
    }

    const lines = invoice.lines.data

    const consumption = lines
        .filter(line => {
            const rType = descriptitonToResourceType(line.description)

            return rType != null
        })
        .map(line => {
            const consumption: ConsumptionCount = {
                resourceType: descriptitonToResourceType(line.description) as ResourceType,
                tire: descriptitonToConsumptionTier(line.description) as ConsumptionTier,
                count: line.quantity
            }

            return consumption
        })

    return {
        type: 'HAS_BILLING',
        current: new Date(invoice.period_start * 1000),
        nextBilling: new Date(invoice.period_end * 1000),
        amount: invoice.amount_due / 100,
        consumption: consumption
    }
}

export const BillingEstimateWrapper = ({
    goEstimatePage,
    estimate
}: {
    goEstimatePage: Function
    estimate: BillingEstimate | NoBilling
}) => {
    if (estimate.type === 'NO_BILLING_DATA') {
        return (
            <>
                <HeaderRight>Billing Estimate</HeaderRight>
                <DateEstimate></DateEstimate>
                <NoEstimate>No estimate available</NoEstimate>
            </>
        )
    }

    return (
        <>
            <HeaderRight>Billing Estimate</HeaderRight>
            <DateEstimate>
                <DateLogs>
                    <Logsspan>{formatDate(estimate.current)}</Logsspan>
                    <Logsspan>-</Logsspan>
                    <Logsspan>{formatDate(estimate.nextBilling)}</Logsspan>
                </DateLogs>
            </DateEstimate>

            <EstimateTables consumption={estimate.consumption}></EstimateTables>
            <CreditWrapper>
                <CreditMoney CreditsAlign="left" CreditsMargin="0px">
                    <DollerAmount DollerFont="14px" alignLeft="left">
                        Next Billing: {formatDate(estimate.nextBilling)} <DollarMonth DollerMonth="14px"> </DollarMonth>{' '}
                    </DollerAmount>
                    <DollerAmount DollerFont="20px">
                        {' '}
                        <DollerSup SupFont="12px">$</DollerSup>
                        {estimate.amount}
                    </DollerAmount>
                </CreditMoney>

                <AnchorTag>
                    <AnchorTagContent onClick={goEstimatePage}>View Full Estimate</AnchorTagContent>
                </AnchorTag>
            </CreditWrapper>
        </>
    )
}
export const UserDetails = (props: any) => {
    return (
        <ImgContainer>
            <ProfileImg src={user_img}></ProfileImg>
            <EmailWrapper>
                <UserName>
                    <EllipsisContent>{props.name}</EllipsisContent>
                </UserName>
                <UserID>
                    {' '}
                    <EllipsisContent>{props.email}</EllipsisContent>
                </UserID>
            </EmailWrapper>
        </ImgContainer>
    )
}
export const BillingDetailsWrapper = ({
    goPaymentPage,
    card,
    user
}: {
    goPaymentPage: Function
    card: PaymentMethod | undefined
    user: User | undefined
}) => {
    const name = user ? `${user.first_name} ${user.last_name}` : 'Unknown user'
    const email = user ? user.email : 'unknown@unknown.un'

    if (card === undefined) {
        return (
            <>
                <HeaderRight>Account & Billing</HeaderRight>
                <UserDetails name={name} email={email} />
                <NoCreditCard>No Credit Card details found</NoCreditCard>
                <CreditWrapper>
                    <AnchorTag>
                        <AnchorTagContent alignText onClick={goPaymentPage}>
                            Manage Details
                        </AnchorTagContent>
                    </AnchorTag>
                </CreditWrapper>
            </>
        )
    }

    return (
        <>
            <HeaderRight>Account & Billing</HeaderRight>
            <UserDetails name={name} email={email} />
            <MonthlySubscription>Credit Card</MonthlySubscription>
            <CardDetails>
                <CardNumber>{`**** **** **** ${card.card.last4}`}</CardNumber>
                <VisaImg src={visa_img}></VisaImg>
            </CardDetails>
            <Billing>{card.billing_details.name}</Billing>
            <Billing>
                Expires: {card.card.exp_month.toLocaleString(undefined, { minimumIntegerDigits: 2 })}/
                {card.card.exp_year}
            </Billing>{' '}
            <CreditWrapper>
                <AnchorTag>
                    <AnchorTagContent alignText onClick={goPaymentPage}>
                        Manage Details
                    </AnchorTagContent>
                </AnchorTag>
            </CreditWrapper>
        </>
    )
}
export const EstimateTables = ({ consumption }: { consumption: ConsumptionCount[] }) => {
    const dialogData = consumption.filter(rec => rec.resourceType === ResourceType.Conversation)

    const dialogUsed = dialogData.find(data => data.tire === ConsumptionTier.One)
    const dialogExcess = dialogData.find(data => data.tire === ConsumptionTier.Two)

    const automationData = consumption.filter(rec => rec.resourceType === ResourceType.Automation)
    const automationUsed = automationData.find(data => data.tire === ConsumptionTier.One)
    const automationExcess = automationData.find(data => data.tire === ConsumptionTier.Two)

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th size={7} primary>
                        Consumptions
                    </Th>
                    <Th size={2}>Used</Th>
                    <Th size={3}>Excess</Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td size={7}>
                        <EllipsisContent>Messages</EllipsisContent>
                    </Td>
                    <Td size={2}>{dialogUsed ? dialogUsed.count : 0}</Td>
                    <Td size={3} primary>
                        {dialogExcess ? dialogExcess.count : 0}
                    </Td>
                    <InformationIcon>
                        <ContetntInformationSpan data-tip data-for="conversion_tooltip">
                            <InfoIcon />
                            <Tooltip>
                                <ReactTooltip
                                    className="subscription_tooltip"
                                    id="conversion_tooltip"
                                    type="warning"
                                    effect="solid"
                                >
                                    <HoverSpan>Messages that are exceeded from your monthly limit.</HoverSpan>
                                </ReactTooltip>
                            </Tooltip>
                        </ContetntInformationSpan>
                    </InformationIcon>
                </Tr>
                <Tr>
                    <Td size={7}>
                        <EllipsisContent>Automation Executions</EllipsisContent>
                    </Td>
                    <Td size={2}>{automationUsed ? automationUsed.count : 0}</Td>
                    <Td size={3} primary>
                        {automationExcess ? automationExcess.count : 0}
                    </Td>
                    <InformationIcon>
                        <ContetntInformationSpan data-tip data-for="automation_tooltip">
                            <InfoIcon />
                            <Tooltip>
                                <ReactTooltip
                                    className="subscription_tooltip"
                                    id="automation_tooltip"
                                    type="warning"
                                    effect="solid"
                                >
                                    <HoverSpan>
                                        Automation executions that are exceeded from your monthly limit
                                    </HoverSpan>
                                </ReactTooltip>
                            </Tooltip>
                        </ContetntInformationSpan>
                    </InformationIcon>
                </Tr>
            </Tbody>
        </Table>
    )
}

export const BillingHistoryTables = ({ invoices: invoiceHistory }: { invoices: InvoiceHistoryT[] }) => {
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th primary size={2}>
                        Date
                    </Th>
                    <Th size={4}>Invoice Number</Th>
                    {/* <Th size={3}>Billing Type</Th> */}
                    <Th size={3}>Amount</Th>
                    <Th size={2}>Actions</Th>
                </Tr>
            </Thead>
            <Tbody>
                {invoiceHistory.length === 0 ? (
                    <Tr>
                        <Td size={12}>
                            <NoDataWrapper>
                                <NoDataHeader>No Invoices found</NoDataHeader>
                            </NoDataWrapper>
                        </Td>
                    </Tr>
                ) : (
                    invoiceHistory
                        .filter((_, index) => index < 3)
                        .map((data: InvoiceHistoryT, index: number) => (
                            <Tr key={index}>
                                <Td ContentRight size={2}>
                                    {formatDate(new Date(data.endDate * 1000))}
                                </Td>
                                <Td size={4}>{data.invoiceId}</Td>
                                {/* <Td size={3}>Monthly</Td> */}
                                <Td size={3}>${data.amount / 100}</Td>
                                <Td size={2}>
                                    <SvgWidth onClick={() => window.open(data.invoicePdf, '_blank')}>
                                        <DownloadFromMarketplace />
                                    </SvgWidth>{' '}
                                </Td>
                            </Tr>
                        ))
                )}
            </Tbody>
        </Table>
    )
}

export const makeUiDomainFromResponse = (data: CurrentSubscription): SubscribedPlan => {
    const subscription = data.subscription
    if (subscription.status === 'trialing') {
        // console.log('in trial')
        const finiteCosumption: ResourceConsumptionForDisplay[] = data.finiteResourceConsumption.map(consump => {
            const staticResource = ResourceDetails.filter(
                statircesource => consump.resourceName === statircesource.name
            )[0]

            return {
                resourceId: consump.resourceId,
                name: consump.resourceName,
                id: consump.resourceName as AllResourceTypes,
                consumptionType: 'FINITE' as 'FINITE',
                consumed: consump.resourceUsedCount,
                resourceLimit: consump.resourceLimit,
                priority: staticResource ? staticResource.priority : 1000
            }
        })

        const infiniteCosumption: ResourceConsumptionForDisplay[] = data.infiniteResourceConsumption.map(consump => {
            const staticResource = ResourceDetails.filter(
                statircesource => consump.resourceName === statircesource.name
            )[0]

            return {
                resourceId: consump.resourceId,
                name: consump.resourceName,
                id: consump.resourceName as AllResourceTypes,
                consumptionType: 'INFINITE' as 'INFINITE',
                consumed: consump.resourceUsedCount,
                resourceLimit: consump.resourceLimit,
                priority: staticResource ? staticResource.priority : 1000
            }
        })
        const toReturn: SubscribedPlan = {
            workspace: data.workspace,
            displayName: subscription.productPlan.plan.displayName,
            referenceName: subscription.productPlan.plan.name,
            id: subscription.id,
            subscription: {
                status: 'trialing' as TrialStatus,
                trialEndDate: new Date(subscription.trialEndDate),
                trialStartDate: new Date(subscription.trialStartDate),
                hasTrialEnded: subscription.hasTrialEnded
            },
            resourceConsumption: finiteCosumption.concat(infiniteCosumption),
            finiteResourceConsumption: data.finiteResourceConsumption,
            infiniteResourceConsumption: data.infiniteResourceConsumption,
            metaForUI: subscription.productPlan.metaForUI,
            planDetails: subscription.productPlan.plan as any
        }
        return toReturn
    } else {
        // resourceId: string
        // name: string
        // id: AllResourceTypes
        // consumptionType: 'FINITE' | 'INFINITE'
        // consumed: string
        // resourceLimit: number

        const finiteCosumption: ResourceConsumptionForDisplay[] = data.finiteResourceConsumption.map(consump => {
            const staticResource = ResourceDetails.filter(
                statircesource => consump.resourceName === statircesource.name
            )[0]
            return {
                resourceId: consump.resourceId,
                name: consump.resourceName,
                id: consump.resourceName as AllResourceTypes,
                consumptionType: 'FINITE' as 'FINITE',
                consumed: consump.resourceUsedCount,
                resourceLimit: consump.resourceLimit,
                priority: staticResource ? staticResource.priority : 1000
            }
        })

        const infiniteCosumption: ResourceConsumptionForDisplay[] = data.infiniteResourceConsumption.map(consump => {
            const staticResource = ResourceDetails.filter(
                statircesource => consump.resourceName === statircesource.name
            )[0]
            return {
                resourceId: consump.resourceId,
                name: consump.resourceName,
                id: consump.resourceName as AllResourceTypes,
                consumptionType: 'INFINITE' as 'INFINITE',
                consumed: consump.resourceUsedCount,
                resourceLimit: consump.resourceLimit,
                priority: staticResource ? staticResource.priority : 1000
            }
        })

        const toReturn = {
            workspace: data.workspace,
            displayName: subscription.productPlan.plan.displayName,
            referenceName: subscription.productPlan.plan.name,
            id: subscription.id,
            subscription: {
                status: subscription.status as SubscribedStatus,
                stripeSubscription: data.stripeData,
                productSubscriptionId: subscription.stripeProductSubscriptionId,
                addOnSubscriptionId: subscription.stripeSubscriptionId,
                current_period_end: data.stripeData.subscription[0]
                    ? data.stripeData.subscription[0].current_period_end
                    : null,
                current_period_start: data.stripeData.subscription[0]
                    ? data.stripeData.subscription[0].current_period_start
                    : null,
                hasHadTrial: subscription.hasHadTrial
            },
            resourceConsumption: finiteCosumption.concat(infiniteCosumption),
            finiteResourceConsumption: data.finiteResourceConsumption,
            infiniteResourceConsumption: data.infiniteResourceConsumption,
            metaForUI: subscription.productPlan.metaForUI,
            planDetails: subscription.productPlan.plan as any
        }
        return toReturn
    }
}

export const loadCurrentSubscriptionData = async (workspace: string) => {
    const plansAndSubscription = await stripeGetApi(`${workspace}/subscription/current`).then(data => {
        const subscriptionDetails = makeUiDomainFromResponse(data.output)
        return subscriptionDetails
    })

    // const admins = getUsersAPI(workspace).then((users: User[]) => {
    //     const resources = users
    //         // .filter(user => user.verified && user.WorkspaceAccess.valid)
    //         .map(user => ({
    //             type: 'ADMIN',
    //             name: `${user.first_name} ${user.last_name}`,
    //             email: user.email,
    //             accessLevel: user.WorkspaceAccess.accessLevel
    //         }))

    //     return resources
    // })

    return plansAndSubscription
}
type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any) => Promise<infer U>
    ? U
    : T extends (...args: any) => infer U
    ? U
    : T

export const SubscriptionWrapperH = (Component: (props: any) => JSX.Element) => (props: any) => {
    const workspaceName = props.match.params.workspacename

    const [state, setState, promiseRef] = useFetchFromApi(() => loadCurrentSubscriptionData(workspaceName))

    useEffect(() => {
        if (promiseRef.current != null) {
            const setStateDispatch = setState as React.Dispatch<any>
            setStateDispatch(promiseRef.current ? promiseRef.current.then((response: any) => response.data) : null)
        }
    }, [promiseRef.current])

    const retry = () => {
        const setStateDispatch = setState as React.Dispatch<any>
        setStateDispatch(promiseRef.current ? loadCurrentSubscriptionData(workspaceName) : null)
    }
    // console.log('SubscriptionWrapperH', Component)

    return <Component state={state} retry={retry} {...props} workspace={workspaceName} />
}

export const Subscription = (props: any) => {
    const { workspacename } = props.match.params
    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    const [userList, setUserList] = useState<User[]>([])

    useEffect(() => {
        getUsersAPI(workspacename).then((users: User[]) => {
            setUserList(users)
        })
    }, [])

    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    const startTrail = (e: any) => {
        getCurrentUserInfo()
        Promise.resolve({ email: 'cp1@skitter.in' })
            .then((x: any) => x.email)
            .then((email: string) => stripePost(`${workspacename}/trial/start`)({ email }))
            .then(_ => stripeGetApi(`${workspacename}/subscription/current`))
            .then(_ => location.reload())
            .then(console.log)
    }

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }

    if (PageState.action === ActionT.CustomerNotFound) {
        return (
            <div className="container" style={{ marginTop: '20%' }}>
                <div className="row h-50">
                    <div className="col-sm-12 h-100 d-table">
                        <div className="card card-block d-table-cell align-middle">
                            unable to find customer
                            {<button onClick={startTrail}> Start trial</button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        // console.log(PageState.subscription)
        const upcomingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const email = PageState.subscription.subscription.stripeSubscription.email
        const metaForUI = PageState.subscription.metaForUI
        const user = userList.find(user => user.email === email)
        const status = PageState.subscription.subscription.status
        const currentPlanType = PageState.subscription.planDetails.billed

        const gotoPlans = (planType?: string) => {
            // console.log('planTYpe', planType)
            props.history.push(
                `/workspace/${workspacename}/billing/plans?from=billing&planType=${
                    planType ? planType.toLowerCase() : currentPlanType.toLowerCase()
                }`
            )
        }

        return (
            <>
                <SubscriptionHeader subscriptionStatus={subscriptionStatusMap[status]} />
                {Object.keys(PageState.action).length > 1 ? (
                    <BoxWrapper primary>
                        <Container>
                            <Box primary>
                                <CurrentPlanContainer
                                    goPlansPage={gotoPlans}
                                    subscribedPlan={getSubscriptionDisplayDetails(
                                        PageState.subscription,
                                        PageState.defaultPlan
                                    )}
                                    metaForUI={metaForUI}
                                />
                                <BoxRightContainer>
                                    <BoxRightWrapper>
                                        <BoxOne BoxWidth={4.7} BoxMarginRight="30px;">
                                            <BillingEstimateWrapper
                                                estimate={toBillingEstimate(upcomingInvoice)}
                                                goEstimatePage={() =>
                                                    props.history.push(`/workspace/${workspacename}/billing/estimate`)
                                                }
                                            />
                                        </BoxOne>
                                        <BoxOne BoxWidth={4.7}>
                                            <BillingDetailsWrapper
                                                user={user}
                                                card={
                                                    PageState.subscription.subscription.stripeSubscription
                                                        .paymentMethods[0]
                                                }
                                                goPaymentPage={() =>
                                                    props.history.push(
                                                        `/workspace/${workspacename}/billing/paymentdetails`
                                                    )
                                                }
                                            />
                                        </BoxOne>
                                    </BoxRightWrapper>
                                    <BoxRight>
                                        <HeaderRight>Billing History</HeaderRight>
                                        <BillingHistoryTables
                                            invoices={PageState.subscription.subscription.stripeSubscription.invoice}
                                        />
                                        <CreditWrapper>
                                            <AnchorTag>
                                                <AnchorTagContent
                                                    alignText
                                                    onClick={() =>
                                                        props.history.push(
                                                            `/workspace/${workspacename}/billing/history`
                                                        )
                                                    }
                                                >
                                                    View All Invoices
                                                </AnchorTagContent>
                                            </AnchorTag>
                                        </CreditWrapper>
                                    </BoxRight>
                                </BoxRightContainer>
                            </Box>
                        </Container>
                    </BoxWrapper>
                ) : null}
            </>
        )
    }
    return null as never
}

const dummyAdmins = [
    {
        id: 69,
        first_name: 'Abii',
        last_name: 'Testtttttt',
        email: 'abinaya.k@skitter.in',
        verified: true,
        WorkspaceAccess: {
            valid: true,
            accessLevel: 'user'
        }
    },
    {
        id: 70,
        first_name: 'Vinod',
        last_name: 'Manda',
        email: 'vinod.m@workativ.com',
        verified: true,
        WorkspaceAccess: {
            valid: true,
            accessLevel: 'admin'
        }
    },
    {
        id: 92,
        first_name: 'Abinaya',
        last_name: 'Kumarasamy',
        email: 'abinaya.k@workativ.com',
        verified: true,
        WorkspaceAccess: {
            valid: true,
            accessLevel: 'user'
        }
    }
]

export type EstimateSummary =
    | {
          tag: 'PAY'
          planAmount: string
          addOnAmount: string
          toPay: string
          planType: string
          nextBillingDate: string
      }
    | { tag: 'NONE' }
export const SubscriptionV2 = (props: any) => {
    // console.log('SubscriptionV2', props.state)
    const { workspacename } = props.match.params
    const [PageState, setPageState] = useState<PageStateT>({ action: ActionT.Loading })
    const [userList, setUserList] = useState<User[]>([])

    useEffect(() => {
        logEvent(workspacename, {
            event:"EVENT::BILLING::VIEW_PAGE", event_meta:{}
        })

        getUsersAPI(workspacename).then((users: User[]) => {
            setUserList(users)
        })
    }, [])

    useEffect(() => {
        const nextProps = props

        if (nextProps.state.type == 'loading') {
            setPageState({ action: ActionT.Loading })
        } else if (nextProps.state.type == 'error') {
            if (nextProps.state.error.response) {
                if (nextProps.state.error.response.data.tag === 'WORKSPACE_NOT_FOUND') {
                    setPageState({ action: ActionT.CustomerNotFound })
                    return
                }
            } else if (
                nextProps.state.error.response &&
                (nextProps.state.error.response.status == 401 || nextProps.state.error.response.status == 403)
            ) {
                setPageState({ action: ActionT.Unauthorized })
                return
            } else {
                setPageState(ErrorPage)
                return
            }
        } else if (nextProps.state.type == 'success') {
            const subscriptionDetails: Unwrap<ReturnType<typeof loadCurrentSubscriptionData>> = nextProps.state.data
            if (subscriptionDetails.subscription.status === ('trialing' as TrialStatus)) {
                setPageState({ action: ActionT.CustomerInTrial, subscription: subscriptionDetails })
            } else {
                setPageState({ action: ActionT.View, subscription: subscriptionDetails } as any)
            }
        }
    }, [props.state])

    const startTrail = (e: any) => {
        getCurrentUserInfo()
        Promise.resolve({ email: 'cp1@skitter.in' })
            .then((x: any) => x.email)
            .then((email: string) => stripePost(`${workspacename}/trial/start`)({ email }))
            .then(_ => stripeGetApi(`${workspacename}/subscription/current`))
            .then(_ => location.reload())
            .then(console.log)
    }

    if (PageState.action === ActionT.CustomerInTrial) {
        props.history.push(`/workspace/${workspacename}/billing/plans`)
    }

    if (PageState.action === ActionT.Loading) {
        return <Loader.PageLoader show={true} />
    }

    if (PageState.action === ActionT.CustomerNotFound) {
        return (
            <div className="container" style={{ marginTop: '20%' }}>
                <div className="row h-50">
                    <div className="col-sm-12 h-100 d-table">
                        <div className="card card-block d-table-cell align-middle">
                            unable to find customer
                            {<button onClick={startTrail}> Start trial</button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (PageState.action === ActionT.View && PageState.subscription.subscription.status !== 'trialing') {
        // console.log(PageState.subscription)
        const upcomingInvoice = PageState.subscription.subscription.stripeSubscription.upcomingInvoice
        const email = PageState.subscription.subscription.stripeSubscription.email
        const metaForUI = PageState.subscription.metaForUI
        const user = userList.find(user => user.email === email)
        const status = PageState.subscription.subscription.status
        const currentPlanType = PageState.subscription.planDetails.billed
        const adminRosourceLimit = PageState.subscription.finiteResourceConsumption.find(
            resource => resource.resourceName === 'ADMIN'
        )

        // console.log(
        //     'PageState.subscription.finiteResourceConsumption',
        //     PageState.subscription.finiteResourceConsumption
        // )

        const planName = PageState.subscription.displayName
        const currentCycleStartDate = stripeUTCDate(PageState.subscription.subscription.current_period_start)
        const currentCycleEndDate = stripeUTCDate(PageState.subscription.subscription.current_period_end)

        const gotoPlans = (planType?: string) => {
            // console.log('planTYpe', planType)
            props.history.push(
                `/workspace/${workspacename}/billing/plans?from=billing&planType=${
                    planType ? planType.toLowerCase() : currentPlanType.toLowerCase()
                }`
            )
        }

        // const enterPriceMeta = makeEnterprisePlanUIStructure()



        // const currentPlan = PlansV2.concat(AdditionalPlans)
        //   .concat(EnterprisePlan)
        //   .find(
        //     plan =>
        //         plan.referenceName === PageState.subscription.referenceName ||
        //         (plan.referenceName === 'ENTERPRISE' && PageState.subscription.displayName === 'Enterprise')
        // )

        const resourceWithDisplayDetails: (ResourceDisplay &
            ResourceConsumptionForDisplay)[] = PageState.subscription.resourceConsumption
            .map(resource => {
                const displayDetails = ResourceDetails.find(displayDetail => displayDetail.name === resource.id)
                if (displayDetails === undefined) {
                    return undefined
                }

                return {
                    ...resource,
                    ...displayDetails
                }
            })
            .filter(x => x !== undefined && (x.name === 'SESSION' || x.name === 'AUTOMATION'))
            .filter((item): item is ResourceDisplay & ResourceConsumptionForDisplay => !!item)

        const addOnAmount = resourceWithDisplayDetails
            .map(resource => ({
                exceeded: Math.max(parseInt(resource.consumed) - resource.resourceLimit, 0)
            }))
            .reduce((acc, curr) => acc + curr.exceeded * 0.1, 0)

        const toPlanEstimate = toBillingEstimateV2(upcomingInvoice)
        // console.log('upcomingInvoice', upcomingInvoice, toPlanEstimate)

        const adminNumberToWord = (limit: number) => {
            switch (limit) {
                case 1:
                    return 'one'
                case 2:
                    return 'two'
                case 0:
                    return 'zero'
                default:
                    return limit.toString()
            }
        }

        const toPay: EstimateSummary =
            toPlanEstimate.tag == 'HAS_PLAN_AMOUNT'
                ? {
                      tag: 'PAY',
                      planAmount: PageState.subscription.planDetails.price,
                      addOnAmount: `${parseFloat(addOnAmount.toString()).toFixed(2)}`,
                      toPay: `${(parseFloat(addOnAmount.toString()) + parseFloat(toPlanEstimate.amount)).toFixed(2)}`,
                      planType: PageState.subscription.planDetails.billed,
                      nextBillingDate: formatDateInCard(currentCycleEndDate.toString())
                  }
                : {
                      tag: 'PAY',
                      planAmount: PageState.subscription.planDetails.price,
                      addOnAmount: `${parseFloat(addOnAmount.toString()).toFixed(2)}`,
                      toPay: `${parseFloat(addOnAmount.toString()).toFixed(2)}`,
                      planType: PageState.subscription.planDetails.billed,
                      nextBillingDate: formatDateInCard(currentCycleEndDate.toString())
                  }

        const { userData } = useContext(SideBarContext)

        return (
            <>
                <PaidUserWrapper>
                    <PlansHeaderV2 name={'Billing Overview'} />
                    <div className="current_plan_wrapper">
                        <BodyWrapperV2 unVerified={userData?userData.verified===false:false}>
                            <WrapperV2>
                                <ContainerV2>
                                    <BillingWrapper>
                                        <OverviewPlanV2
                                            gotoPlans={gotoPlans}
                                            currentPlan={planName}
                                            currentPeriodEnd={currentCycleEndDate}
                                            currentPeriodStart={currentCycleStartDate}
                                            pricing={toPay}
                                            planReferenceName={PageState.subscription.referenceName}
                                        />
                                        <DisplayCardsV2 resources={PageState.subscription.resourceConsumption} />
                                    </BillingWrapper>
                                    <AdminWrappers>
                                        <CardUserDetailsV2
                                            user={user}
                                            card={
                                                PageState.subscription.subscription.stripeSubscription.paymentMethods[0]
                                            }
                                            goPaymentPage={() =>
                                                props.history.push(`/workspace/${workspacename}/billing/paymentdetails`)
                                            }
                                            planReferenceName={PageState.subscription.referenceName}
                                        />
                                        <AccountWrapper>
                                            <AdminDetailWrapper>
                                                <CurrentPlanHeader>Admin Details</CurrentPlanHeader>
                                                <div className="billing_admin_detail_user">
                                                    {userList.map(user => (
                                                        <UserDetailsV2
                                                            adminIcon={user.WorkspaceAccess.accessLevel === 'admin'}
                                                            name={`${user.first_name} ${user.last_name}`}
                                                            email={user.email}
                                                        />
                                                    ))}
                                                </div>
                                                {planName !== 'Pro' ? (
                                                    <Paragraph>
                                                        Current plan includes workspace access for{' '}
                                                        {adminRosourceLimit
                                                            ? `${adminNumberToWord(
                                                                  adminRosourceLimit.resourceLimit
                                                              )} ${plurarize(
                                                                  adminRosourceLimit.resourceLimit,
                                                                  'admin'
                                                              )}`
                                                            : '0 admin'}
                                                        . Upgrade your plan to include more number of admins.
                                                    </Paragraph>
                                                ) : null}
                                            </AdminDetailWrapper>
                                        </AccountWrapper>
                                        <BillingTableWrapper>
                                            <AccountWrapper className="ddd">
                                                <CurrentPlanHeader>Payment &amp; Invoice History</CurrentPlanHeader>
                                                <BillingHistoryV2
                                                    gotoHistory={() =>
                                                        props.history.push(
                                                            `/workspace/${workspacename}/billing/history`
                                                        )
                                                    }
                                                    invoices={
                                                        PageState.subscription.subscription.stripeSubscription.invoice
                                                    }
                                                />
                                            </AccountWrapper>
                                        </BillingTableWrapper>
                                    </AdminWrappers>
                                </ContainerV2>
                            </WrapperV2>
                        </BodyWrapperV2>
                    </div>
                </PaidUserWrapper>
            </>
        )
    }
    return null as never
}
// console.log('Billing Estimate', BillEstimate, Subscription, BillingHistory)
export const SubscriptionWrapper = SubscriptionWrapperH(SubscriptionV2)
export const BillingDataWrapper = SubscriptionWrapperH(BillingHistory)
export const BillingEstimationWrapper = SubscriptionWrapperH(EstimateV2)
